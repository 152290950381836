import { NotInterested } from '@mui/icons-material';
import {
  LabelDisplayedRowsArgs,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { millisecondsToMinutes } from 'date-fns';
import React, { ReactElement, ReactNode, memo, useCallback } from 'react';
import { i18n } from '../../locales';
import { LoaderContent } from '../LoaderContent/LoaderContent';
import useClasses from './SimpleTableStyles';

type SortDirection = 'asc' | 'desc';

const delayBeforeUrgent = 20; //minutes

export type SimpleTableColumn<Item extends Parse.JSONBaseAttributes> = {
  id: string;
  label: ReactNode;
  style?: React.CSSProperties;
  isSortable?: boolean;
  // alternativeId permet d'indiquer un "id" alternatif pour faire le sort
  alternativeId?: string;
  renderCell: (data: Item) => ReactNode;
};

export type SimpleTableProps<Item extends Parse.JSONBaseAttributes> = {
  isLoading?: boolean;
  count?: number;
  columns: SimpleTableColumn<Item>[];
  data: Item[];
  page: number;
  limit: number;
  sortDirection?: SortDirection;
  size?: 'small' | 'medium';
  sortBy?: string;
  onClickRow?: (object: Item) => void;
  onChangePage: (newPage: number) => void;
  onChangeLimit: (newLimit: number) => void;
  onChangeSortBy: (newSortBy: string) => void;
  onChangeSortDirection: (newSortDirection: SortDirection) => void;
  labelNoData: string;
};

export const SimpleTable = <Item extends Parse.JSONBaseAttributes>({
  count,
  columns,
  isLoading,
  data,
  page,
  limit,
  sortBy,
  sortDirection,
  size,
  onClickRow,
  onChangePage,
  onChangeLimit,
  onChangeSortBy,
  onChangeSortDirection,
  labelNoData,
}: SimpleTableProps<Item>): ReactElement => {
  const classes = useClasses({ onClickRow: !!onClickRow });

  const getLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) =>
    `${from}-${to === -1 ? count : to} sur ${count !== -1 ? count : `plus que ${to}`}`;

  const handleChangeSort = useCallback(
    (newSortBy: string) => {
      if (newSortBy === sortBy) {
        onChangeSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        return;
      }
      onChangeSortDirection('desc');
      onChangeSortBy(newSortBy);
    },
    [sortBy, sortDirection, onChangeSortDirection, onChangeSortBy]
  );

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
    onChangePage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeLimit(Number(event.target.value));
    onChangePage(0);
  };

  const renderCell = useCallback(
    (column: SimpleTableColumn<Item>, item: Item) => (
      <TableCell className={classes.tableCell} style={column.style} key={`${item.objectId}-${column.id}`}>
        {column.renderCell(item)}
      </TableCell>
    ),
    [classes.tableCell]
  );

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table size={size}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} className={classes.tableCell} style={column.style}>
                {column.isSortable ? (
                  <TableSortLabel
                    direction={sortDirection}
                    active={column.alternativeId ? sortBy === column.alternativeId : sortBy === column.id}
                    onClick={() => handleChangeSort(column.alternativeId || column.id)}
                    className={classes.sortLabel}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading || data.length === 0 ? (
            <TableRow>
              <TableCell className={classes.tableCell} colSpan={columns.length}>
                <div className={classes.noDataContainer}>
                  {isLoading ? (
                    <LoaderContent />
                  ) : (
                    <>
                      <NotInterested className={classes.noDataIcon} />
                      <Typography variant="subtitle1">{labelNoData}</Typography>
                    </>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ) : (
            data.map((item) => {
              let isUrgent = false;
              // @ts-ignore
              if (item.wantedDate) {
                //@ts-ignore
                const diffInMinutes = millisecondsToMinutes(new Date(item.wantedDate) - new Date());

                if (diffInMinutes <= delayBeforeUrgent) {
                  isUrgent = true;
                }
              }

              return (
                <TableRow
                  classes={isUrgent ? { root: classes.rootTableRow } : {}}
                  key={item.objectId}
                  className={classes.tableRow}
                  onClick={() => onClickRow && onClickRow(item)}
                >
                  {columns.map((column) => renderCell(column, item))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      {!!count && count > data.length && (
        <TablePagination
          rowsPerPageOptions={[20, 30, 50]}
          component="div"
          labelRowsPerPage={i18n.faresPerPage}
          labelDisplayedRows={getLabelDisplayedRows}
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default memo(SimpleTable);
