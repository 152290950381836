import { PatientGender } from '@ambuliz/sabri-core';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { i18n } from 'common/locale';
import { useState } from 'react';
import { CreatePatientForm } from '../CreatePatientForm';
import { Cross, Search } from '../Icons';
import NoPatientResult from './NoPatientResult';
import PatientAutocompletePopper from './PatientAutocompletePopper';
import PatientSummary from './PatientSummary';
import usePatientSearch from './usePatientSearch';

export type PatientSearchResult = {
  id: string;
  name: string;
  birthdate: Date;
  pan: string;
  ins?: string;
  ipp: string;
  gender: PatientGender;
  lastName?: string;
  firstName?: string;
  area?: {
    id: string;
    name: string;
  };
  unit?: {
    id: string;
    name: string;
  };
};

type PatientAutocompleteProps = {
  value: PatientSearchResult | null;
  onChange: (value: PatientSearchResult | null) => void;
  error?: { message: string };
  unitId?: string;
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
};

const PatientAutocomplete = ({
  value,
  onChange,
  error,
  unitId,
  variant = 'outlined',
  size = 'medium',
}: PatientAutocompleteProps) => {
  const [search, setSearch] = useState('');
  const [displayCreatePatientForm, setDisplayCreatePatientForm] = useState(false);

  const { loading, results } = usePatientSearch(search, { unitId });

  const open = (search.length > 0 && results.length > 0) || (!loading && search.length > 0 && results.length <= 0);

  const toggleDisplayCreatePatientForm = () => {
    setDisplayCreatePatientForm(!displayCreatePatientForm);
  };

  const resetPatientForm = () => {
    setSearch('');
    toggleDisplayCreatePatientForm();
  };

  const handleCreatePatient = (value: PatientSearchResult | null) => {
    resetPatientForm();
    onChange(value);
  };

  const handleReset = () => {
    setSearch('');
    onChange(null);
  };

  return (
    <>
      <Stack spacing={2} flex={1}>
        <Stack spacing={1}>
          <Autocomplete
            size={size}
            options={results}
            filterOptions={(options) => options}
            getOptionLabel={(option) => ``}
            forcePopupIcon={false}
            autoHighlight
            open={open}
            fullWidth
            value={value}
            inputValue={search}
            clearIcon={null}
            blurOnSelect
            onInputChange={(_, search) => {
              if (value) {
                onChange(null);
              }
              setSearch(search);
            }}
            noOptionsText={<NoPatientResult onClick={toggleDisplayCreatePatientForm} />}
            onChange={(_, value) => {
              onChange(value);
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <PatientSummary {...option} search={search} withHighlight fromAutocomplete />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={variant}
                value={search}
                error={!!error}
                placeholder={value ? i18n.chooseAnOtherPatient : i18n.patientSearchPlaceholder}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Search
                      sx={{ fontSize: size === 'small' ? 12 : undefined, marginRight: size === 'small' ? 2 : 3 }}
                    />
                  ),
                  endAdornment:
                    loading || search ? (
                      <InputAdornment position="end">
                        <>
                          {loading && <CircularProgress color="inherit" size={16} sx={{ marginRight: 3 }} />}
                          {search && (
                            <IconButton onClick={handleReset} edge="end">
                              <Cross />
                            </IconButton>
                          )}
                        </>
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            )}
            PopperComponent={(props) => <PatientAutocompletePopper {...props} />}
            sx={{ '& .MuiInputBase-sizeSmall': { paddingRight: '8px !important' } }}
          />
          {!!error && (
            <Typography color="error" fontSize={12} marginY={1}>
              {error.message}
            </Typography>
          )}
        </Stack>
        {value && <PatientSummary {...value} primary onRemove={handleReset} fromAutocomplete />}
      </Stack>

      <CreatePatientForm
        onCancel={resetPatientForm}
        onPatientCreated={(value) => {
          handleCreatePatient(value);
        }}
        open={displayCreatePatientForm}
      />
    </>
  );
};

export default PatientAutocomplete;
