import InOutFlow from './InOutFlow/InOutFlow';
import Container from './Container';
import Card from './Card';
import Item from './Item/Item';
import Text from './Text';
import ItemContent from './Item/ItemContent';
export { InOutFlow };

const Statistics = { Card, Container, Item, Text, ItemContent };

export default Statistics;
