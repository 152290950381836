import { Components } from '@mui/material';
import { CheckboxChecked, CheckboxEmpty, CheckboxRemove } from 'common/components/Icons';

const MuiCheckbox: Components['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
    icon: <CheckboxEmpty />,
    checkedIcon: <CheckboxChecked />,
    indeterminateIcon: <CheckboxRemove />,
  },
  styleOverrides: {
    root: {
      padding: 4,
    },
  },
};

export default MuiCheckbox;
