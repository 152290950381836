import { Components } from '@mui/material';
import color from '../color';

const MuiInputAdornment: Components['MuiInputAdornment'] = {
  styleOverrides: {
    root: {
      color: color.grey[30],
      '& .MuiIconButton-root': {
        color: color.grey[60],
      },
    },
    positionStart: {
      marginRight: 0,
      '& .MuiIconButton-root': {
        marginRight: -4,
      },
    },
    positionEnd: {
      marginLeft: 0,
      '& .MuiIconButton-root': {
        marginLeft: -4,
      },
    },
  },
};

export default MuiInputAdornment;
