import { Area } from '@ambuliz/sabri-core';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Chip, IconButton, Paper, Stack, Typography } from '@mui/material';
import CalendarIcon from 'common/components/Icons/Calendar';
import EditIcon from 'common/components/Icons/Edit';
import { Link } from 'react-router-dom';
import { AreaTypeChip } from '../AreaTypeChip';

export type AreaManagementListItemBedProps = {
  area: Area;
  isReadOnly?: boolean;
};

export const AreaManagementListItemBed = ({ area, isReadOnly = false }: AreaManagementListItemBedProps) => {
  return (
    <Paper sx={{ p: 3 }} variant="outlined">
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          {area.externalTypeCode && <AreaTypeChip type={area.externalTypeCode} />}
          <Typography variant="h6">{area.name}</Typography>
          {area.externalIds?.map((externalId) => (
            <Chip color="default" variant="roundedAlt" key={externalId} label={externalId} />
          ))}
        </Stack>
        {!isReadOnly && (
          <Stack direction="row" spacing={1}>
            <IconButton component={Link} to={area.id} variant="filled">
              <EditIcon />
            </IconButton>
            <IconButton component={Link} to={`${area.id}/availability-periods`} variant="filled">
              <CalendarIcon />
            </IconButton>
            <IconButton component={Link} to={`${area.id}/move-bed`} variant="filled">
              <SwapHorizIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};
