import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagementModal } from 'superadmin/components/ManagementDrawer';
import { useArea } from 'superadmin/context';
import MoveBedForm from '../components/form/MoveBedForm';
import areaManagementRoutes from '../routes';

const MoveBedModal = () => {
  const { id } = useParams();
  const { area, move } = useArea(id!);

  const [open, setOpen] = useState(true);

  const handleSubmit = async (params: { parentId: string; newExternalIds?: string[]; activationDate?: Date }) => {
    try {
      await move(params);
      setOpen(false);
      toast.success(i18n.bedMovedSuccess);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <ManagementModal
      loading={!area}
      title={`${i18n.moveBed} ${area?.name}`}
      open={open}
      redirectUrl={areaManagementRoutes.areas}
    >
      {area && <MoveBedForm bed={area} onCancel={() => setOpen(false)} onSubmit={handleSubmit} />}
    </ManagementModal>
  );
};

export default MoveBedModal;
