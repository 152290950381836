import Api, { ExternalPatientTransport, PatientIdentity } from '@ambuliz/sabri-core';
import { liveQueryClient } from 'core/live-query-client';
import { useEffect, useState } from 'react';

const useTransportFares = (patientId?: string) => {
  const [fares, setFares] = useState<ExternalPatientTransport[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let liveSubscription: Parse.LiveQuerySubscription;

    const fetchFares = async (patientId?: string) => {
      const transportFares = await getFares(patientId);
      setFares(transportFares);
    };

    const fetch = async () => {
      setLoading(true);

      liveSubscription = liveQueryClient.subscribe(
        new Api.Query(ExternalPatientTransport),
        Parse.User.current()?.getSessionToken()
      );

      liveSubscription.on('open', async () => {
        await fetchFares(patientId);
        setLoading(false);
      });

      liveSubscription.on('create', () => fetchFares(patientId));
      liveSubscription.on('enter', () => fetchFares(patientId));
      liveSubscription.on('leave', () => fetchFares(patientId));
      liveSubscription.on('update', () => fetchFares(patientId));
    };

    fetch();

    return () => liveQueryClient.unsubscribe(liveSubscription);
  }, [patientId]);

  return {
    loading,
    externalTransports: fares,
  };
};

const getFares = async (patientId?: string) => {
  let fares: ExternalPatientTransport[] = [];
  try {
    const faresQuery = new Api.Query(ExternalPatientTransport).notEqualTo('status', 'CANCELED').include('patient');

    if (patientId) {
      faresQuery.equalTo('patient', PatientIdentity.createWithoutData(patientId));
    }

    fares = await faresQuery.findAll();
  } catch (err) {}
  return fares;
};

export default useTransportFares;
