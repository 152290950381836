import { alertClasses, alertTitleClasses, Components } from '@mui/material';
import { emoji } from 'common/components/Emoji/type';
import color from '../color';

const MuiAlert: Components['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      error: <img src={emoji.faceWithColdSweat.src} alt={emoji.faceWithColdSweat.alt} width={24} height={24} />,
      warning: <img src={emoji.astonishedFace.src} alt={emoji.astonishedFace.alt} width={24} height={24} />,
      info: <img src={emoji.indexPointingUp.src} alt={emoji.indexPointingUp.alt} width={24} height={24} />,
      success: <img src={emoji.okHand.src} alt={emoji.okHand.alt} width={24} height={24} />,
    },
  },
  styleOverrides: {
    root: {
      color: color.grey[60],
      alignItems: 'center',
    },
    icon: {
      alignItems: 'center',
    },
    action: {
      alignItems: 'center',
      marginRight: 0,
      padding: 0,
    },
    standardError: {
      backgroundColor: color.red[10],
      [`& .${alertTitleClasses.root}`]: {
        color: color.red[60],
      },
      [`& .${alertClasses.icon}`]: {
        color: color.red[60],
      },
    },
    standardWarning: {
      backgroundColor: color.yellow[10],
      [`& .${alertTitleClasses.root}`]: {
        color: color.yellow[70],
      },
      [`& .${alertClasses.icon}`]: {
        color: color.yellow[70],
      },
    },
    standardInfo: {
      backgroundColor: color.blue[5],
      [`& .${alertTitleClasses.root}`]: {
        color: color.blue[30],
      },
      [`& .${alertClasses.icon}`]: {
        color: color.blue[30],
      },
    },
    standardSuccess: {
      backgroundColor: color.green[10],
      [`& .${alertTitleClasses.root}`]: {
        color: color.green[60],
      },
      [`& .${alertClasses.icon}`]: {
        color: color.green[60],
      },
    },
  },
};

export default MuiAlert;
