import Api, { Patient, PatientIdentity } from '@ambuliz/sabri-core';
import { Box, Dialog, Link, Stack, Typography } from '@mui/material';
import { Alert, DialogHeader, Emoji } from 'common/components';
import { PatientSummary } from 'common/components/PatientAutocomplete';
import useParseQuery from 'common/hooks/useParseQuery';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { formatName } from 'common/utils';
import { useSupport } from 'core/support/useSupport';
import AddAccommodationForm from 'kurt/components/AddAccommodation/AddAccommodationForm';
import { AccommodationToUpsert, useAccommodation } from 'kurt/hooks';
import { useEffect, useState } from 'react';

type CreateAccommodationProps = {
  id: string;
  onClose: () => void;
};

const getAccommodationSuccessMessage = () => {
  return {
    title: i18n.planNewAdmissionSuccessToast.title,
    message: i18n.planNewAdmissionSuccessToast.accommodationMessage,
    icon: <Emoji name="okHand" size={24} />,
  };
};

const CreateAccommodation: React.FC<CreateAccommodationProps> = ({ id, onClose }) => {
  const { openSupport } = useSupport();
  const { results } = useParseQuery(
    new Api.Query(Patient).equalTo('patient', PatientIdentity.createWithoutData(id)).limit(1)
  );
  const patient = results?.[0];

  const { create, loading } = useAccommodation();
  const [error, setError] = useState(false);

  const handleSubmit = async (accommodation: AccommodationToUpsert, bedsToClose?: string[]) => {
    setError(false);
    try {
      await create(accommodation);
      toast.success(getAccommodationSuccessMessage());
      onClose();
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    setError(false);
  }, [id]);

  return (
    <Dialog open={!!id} onClose={onClose} maxWidth="md" scroll="body">
      <DialogHeader title={i18n.addAccommodationModal.title} onClose={onClose} />
      <Stack spacing={4}>
        {patient && (
          <>
            <Box paddingX={8}>
              <PatientSummary
                name={formatName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName)}
                birthdate={patient.birthdate}
                gender={patient.gender}
                ipp={patient.ipp}
                pan={patient.pan}
                ins={patient.ins}
                primary
              />
            </Box>
            <AddAccommodationForm
              initialPatient={{
                birthdate: patient.birthdate,
                gender: patient.gender,
                id: patient.id,
                ipp: patient.ipp,
                name: formatName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName),
                pan: patient.pan,
              }}
              onCancel={onClose}
              onSubmit={handleSubmit}
              loading={loading}
              error={
                error ? (
                  <Alert
                    title={i18n.dialogError.title}
                    severity="error"
                    content={
                      <Stack spacing={4}>
                        <Typography variant="body1" color="secondary">
                          {i18n.dialogError.messageStay}
                        </Typography>
                        <Typography variant="body1" color="secondary">
                          {i18n.dialogError.contactUsFirstPart}
                          <Link
                            component="button"
                            color="secondary"
                            sx={{ verticalAlign: 'baseline', fontWeight: 600 }}
                            onClick={openSupport}
                          >
                            {i18n.dialogError.contactUsLink}
                          </Link>
                          {i18n.dialogError.contactUsLastPart}
                        </Typography>
                      </Stack>
                    }
                    icon={<Emoji name="faceWithColdSweat" size={24} />}
                  />
                ) : undefined
              }
            />
          </>
        )}
      </Stack>
    </Dialog>
  );
};

export default CreateAccommodation;
