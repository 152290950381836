import { IconButton, Link, Popover, Skeleton, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DateCalendar } from '@mui/x-date-pickers';
import ArrowLeftIcon from 'common/components/Icons/ArrowLeft';
import ArrowRightIcon from 'common/components/Icons/ArrowRight';
import CalendarIcon from 'common/components/Icons/Calendar';
import CrossIcon from 'common/components/Icons/Cross';
import SearchIcon from 'common/components/Icons/Search';
import { useDebounce } from 'common/hooks';
import { i18n } from 'common/locale';
import shadows from 'common/theme/shadows';
import { filterBySearch } from 'common/utils';
import { addDays, endOfDay, format, isToday, isTomorrow, isValid, startOfDay, subDays } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PatientPlacementList from './PatientPlacementList';
import { DateRange, PatientToBePlaced } from './usePatientPlacement';
import { useDroppable } from '@dnd-kit/core';

type PatientPlacementComponentProps = {
  loading: boolean;
  patientsToBePlaced: PatientToBePlaced[];
  isReadOnly?: boolean;
  dateRange: DateRange;
  isActionInProgress?: boolean;
  onDateRangeChange: (dateRange: DateRange) => void;
  onClose?: () => void;
};

const PatientPlacementComponent = ({
  loading,
  patientsToBePlaced,
  isReadOnly = false,
  dateRange,
  isActionInProgress = false,
  onDateRangeChange,
  onClose,
}: PatientPlacementComponentProps) => {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [hiddenCardId, setHiddentCardId] = useState<string | undefined>();

  const debouncedSearch = useDebounce(search, 200);

  const { setNodeRef, over, active } = useDroppable({
    id: 'patientPlacementComponent',
  });

  const filteredPatientsToBePlaced = useMemo(() => {
    let filteredPatientesToBePlaced = [...patientsToBePlaced];
    if (debouncedSearch) {
      filteredPatientesToBePlaced = filterBySearch({
        search: debouncedSearch,
        list: patientsToBePlaced,
        keys: ['visit.firstName', 'visit.lastName', 'visit.legalName', 'visit.legalFirstName'],
        minScore: 0.5,
      });
    }

    return filteredPatientesToBePlaced;
  }, [debouncedSearch, patientsToBePlaced]);

  const { minDate, maxDate } = useMemo(() => {
    const now = new Date();
    return { minDate: startOfDay(now), maxDate: addDays(endOfDay(now), 15) };
  }, []);

  useEffect(() => {
    if (active && active.id && over?.id !== 'patientPlacementComponent') {
      setHiddentCardId(active.id as string);
    } else if (!isActionInProgress) {
      setHiddentCardId(undefined);
    }
  }, [over, active, isActionInProgress]);

  const isNextDayDisabled = useMemo(() => {
    return dateRange.end >= maxDate;
  }, [dateRange, maxDate]);

  const isPreviousDayDisabled = useMemo(() => {
    return dateRange.start <= minDate;
  }, [dateRange, minDate]);

  const handleNextDay = () => {
    onDateRangeChange({ start: addDays(dateRange.start, 1), end: addDays(dateRange.end, 1) });
  };

  const handlePreviousDay = () => {
    onDateRangeChange({ start: subDays(dateRange.start, 1), end: subDays(dateRange.end, 1) });
  };

  const handleDateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDatePickerClose = () => {
    setAnchorEl(null);
  };

  const datePickerOpen = Boolean(anchorEl);

  const handleDatePickerChange = (value: Date | null) => {
    if (value && isValid(value)) {
      onDateRangeChange({ start: startOfDay(value), end: endOfDay(value) });
      handleDatePickerClose();
    }
  };

  const navigation = useRef(useNavigate());

  const handlePatientCardClick = useCallback((id: string) => {
    navigation.current(`accommodation/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  return (
    <>
      <Stack ref={setNodeRef} flex={1}>
        <Stack
          padding="24px 24px 16px 24px"
          spacing={4}
          position="sticky"
          zIndex={1}
          top={0}
          bgcolor="white"
          boxShadow={shadows[2]}
        >
          <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
            <Typography variant="h2">{i18n.myAdmissions}</Typography>
            {onClose && (
              <IconButton edge="end" onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </Stack>
          <TextField
            placeholder={i18n.searchPatient}
            fullWidth
            variant="filled"
            size="small"
            value={search}
            InputProps={{ startAdornment: <SearchIcon /> }}
            onChange={(event) => setSearch(event.target.value)}
          />
          <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
            <IconButton onClick={handlePreviousDay} disabled={isPreviousDayDisabled} edge="start">
              <ArrowLeftIcon />
            </IconButton>

            <Stack spacing={1} alignItems="center">
              <Stack direction="row" gap={2} alignItems="center">
                <CalendarIcon color={datePickerOpen ? 'primary' : 'secondary'} />
                <Link
                  component="button"
                  onClick={(event) => handleDateClick(event)}
                  color={datePickerOpen ? 'primary' : 'secondary'}
                >
                  <Typography variant="body2" fontWeight={600} color="inherit">
                    {getDateText(dateRange.start)}
                  </Typography>
                </Link>
              </Stack>
              {loading ? (
                <Skeleton variant="rectangular" width={50} height={12} style={{ marginTop: 8, borderRadius: 6 }} />
              ) : (
                <Typography variant="body2" color="secondary" fontWeight={600}>
                  {filteredPatientsToBePlaced.length + ' '}
                  <Typography component="span" variant="body2" color="secondary">
                    {i18n[filteredPatientsToBePlaced.length > 1 ? 'visits' : 'visit'].toLowerCase()}
                  </Typography>
                </Typography>
              )}
            </Stack>

            <IconButton onClick={handleNextDay} disabled={isNextDayDisabled} edge="end">
              <ArrowRightIcon />
            </IconButton>
          </Stack>
        </Stack>

        <PatientPlacementList
          hiddenCardId={hiddenCardId}
          loading={loading}
          patientsToBePlaced={filteredPatientsToBePlaced}
          isReadOnly={isReadOnly}
          onCardClick={handlePatientCardClick}
        />
      </Stack>
      <Popover
        open={datePickerOpen}
        onClose={handleDatePickerClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ horizontal: 0, vertical: -8 }}
      >
        <DateCalendar
          minDate={minDate}
          maxDate={maxDate}
          onChange={(value) => handleDatePickerChange(value)}
          value={dateRange.start}
        />
      </Popover>
    </>
  );
};

const getDateText = (date: Date) => {
  let text = '';
  if (isToday(date)) {
    text += i18n.today + ', ' + i18n.the.toLowerCase();
  } else if (isTomorrow(date)) {
    text += i18n.tomorrow + ', ' + i18n.the.toLowerCase();
  } else {
    text += i18n.the;
  }
  const day = format(date, date.getDate() === 1 ? 'do' : 'dd', { locale: fr });
  const month = format(date, 'MMMM', { locale: fr });
  text += ` ${day} ${month}`;

  return text;
};

export default PatientPlacementComponent;
