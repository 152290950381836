import { Tab, Tabs, Typography } from '@mui/material';
import { color, transition } from 'common/theme';
import { trackEvent } from 'core/analytics/trackEvent';
import styled from 'styled-components';

type DialogTabsProps = {
  value: string;
  onChange: (value: string) => void;
  options: DialogTabOption[];
  disabled?: boolean;
};

const DialogTabs = ({ value, onChange, options, disabled }: DialogTabsProps) => {
  const firstTabSelected = options[0].value === value;
  const middleTabSelected = options.length === 3 && options[1].value === value;
  const lastTabSelected = options[options.length - 1].value === value;

  const onTabChange = (newValue: string) => {
    onChange(newValue);
    trackEvent({
      event_type: 'details_dialog_tab_changed',
      event_properties: {
        value: options.find((option) => option.value === newValue)?.value,
        label: options.find((option) => option.value === newValue)?.label,
      },
    });
  };

  return (
    <StyledTabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
      {options.map((tab) => (
        <StyledTab
          value={tab.value}
          icon={tab.Icon}
          iconPosition="start"
          label={tab.label ? <Typography variant="body2">{tab.label}</Typography> : undefined}
          key={tab.value}
          disableRipple
          disabled={disabled}
          $iconColor={tab.iconColor}
          $firstTabSelected={firstTabSelected}
          $middleTabSelected={middleTabSelected}
          $lastTabSelected={lastTabSelected}
          $totalTabs={options.length}
          $disabled={disabled}
        />
      ))}
    </StyledTabs>
  );
};

export type DialogTabOption = {
  label?: string;
  value: string;
  Icon: React.ReactElement;
  iconColor?: string;
};

const StyledTabs = styled(Tabs)`
  min-height: 0;
  .MuiTabs-indicator {
    display: none;
  }
`;

type StyledTabProps = {
  $iconColor?: string;
  $firstTabSelected?: boolean;
  $lastTabSelected?: boolean;
  $middleTabSelected?: boolean;
  $disabled?: boolean;
  $totalTabs: number;
};

const StyledTab = styled(Tab)<StyledTabProps>`
  border-width: 1px;
  border-color: ${color.grey[10]};
  border-style: solid;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  padding: 0 24px;
  text-transform: none;
  min-height: 0;
  transition:
    color ${transition},
    background-color ${transition},
    height ${transition},
    margin-top ${transition},
    border-radius ${transition};
  will-change: height margin-top background-color color;
  & .MuiSvgIcon-root {
    font-size: 12px !important;
  }
  & > .MuiTab-iconWrapper {
    margin-right: 10px;
  }
  &.Mui-selected {
    height: 40px;
    background-color: white;
    color: ${color.grey[100]};
    z-index: 3;
    box-shadow: rgba(20, 22, 28, 0.08) 0px 8px 8px;
    & > .MuiTab-iconWrapper {
      &.MuiSvgIcon-root {
        color: ${({ $iconColor }) => $iconColor || color.blue[60]};
      }
    }
  }
  &:not(.Mui-selected) {
    background-color: ${({ $disabled }) => ($disabled ? '#FAFAFE' : color.blue[5])};
    color: ${({ $disabled }) => ($disabled ? color.grey[5] : color.grey[30])};
    height: 36px;
    margin-top: 4px;
    min-width: 0;
    border-color: ${({ $disabled }) => ($disabled ? color.grey[5] : color.grey[10])};
    &.MuiSvgIcon-root {
      color: ${({ $disabled }) => ($disabled ? color.grey[5] : undefined)};
    }
    &:first-of-type {
      border-top-right-radius: ${({ $middleTabSelected, $lastTabSelected }) =>
        $middleTabSelected || $lastTabSelected ? 0 : '8px'};
      border-right-width: ${({ $lastTabSelected, $middleTabSelected }) =>
        $lastTabSelected || $middleTabSelected ? 0 : undefined};
      margin-right: ${({ $middleTabSelected, $totalTabs, $lastTabSelected }) =>
        $middleTabSelected || ($totalTabs === 2 && $lastTabSelected) ? '-1px' : undefined};
      margin-top: ${({ $lastTabSelected, $totalTabs }) => ($totalTabs > 2 && $lastTabSelected ? '8px' : undefined)};
      height: ${({ $lastTabSelected, $totalTabs }) => ($totalTabs > 2 && $lastTabSelected ? '32px' : undefined)};
    }
    &:last-of-type {
      border-top-left-radius: ${({ $firstTabSelected, $middleTabSelected }) =>
        $firstTabSelected || $middleTabSelected ? 0 : '8px'};
      border-left-width: ${({ $firstTabSelected, $middleTabSelected }) =>
        $firstTabSelected || $middleTabSelected ? 0 : undefined};
      margin-left: ${({ $middleTabSelected, $totalTabs, $firstTabSelected }) =>
        $middleTabSelected || ($totalTabs === 2 && $firstTabSelected) ? '-1px' : undefined};
      margin-top: ${({ $firstTabSelected, $totalTabs }) => ($totalTabs > 2 && $firstTabSelected ? '8px' : undefined)};
      height: ${({ $firstTabSelected, $totalTabs }) => ($totalTabs > 2 && $firstTabSelected ? '32px' : undefined)};
    }
    &:not(:first-of-type):not(:last-of-type) {
      z-index: ${({ $firstTabSelected, $lastTabSelected }) => ($firstTabSelected || $lastTabSelected ? 2 : undefined)};
      box-shadow: ${({ $firstTabSelected, $lastTabSelected }) =>
        $firstTabSelected || $lastTabSelected ? 'rgba(20, 22, 28, 0.08) 0px 8px 8px' : undefined};
      margin-left: ${({ $lastTabSelected, $firstTabSelected }) =>
        $firstTabSelected || $lastTabSelected ? '-1px' : undefined};
      margin-right: ${({ $lastTabSelected, $firstTabSelected }) =>
        $firstTabSelected || $lastTabSelected ? '-1px' : undefined};
      border-left-width: ${({ $firstTabSelected }) => ($firstTabSelected ? 0 : undefined)};
      border-top-left-radius: ${({ $firstTabSelected }) => ($firstTabSelected ? 0 : undefined)};
      border-right-width: ${({ $lastTabSelected }) => ($lastTabSelected ? 0 : undefined)};
      border-top-right-radius: ${({ $lastTabSelected }) => ($lastTabSelected ? 0 : undefined)};
    }
  }
`;

export default DialogTabs;
