import Api, { Accommodation } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { formatName } from 'common/utils';
import { useBedsFromUnit } from 'core/locations/useBedsFromUnit';
import { addDays, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { AdmissionCardProps } from './Cards/AdmissionCard';
import { DischargeCardProps } from './Cards/DischargeCard';

export type BedMovement = {
  bed: { name: string; id: string };
  discharge?: DischargeCardProps;
  admission?: AdmissionCardProps;
};

const useBedMovements = (date: Date, unitIds: string[]) => {
  const start = useMemo(() => startOfDay(date), [date]);
  const end = useMemo(() => startOfDay(addDays(date, 1)), [date]);

  const { beds, isLoading: locationsLoading } = useBedsFromUnit(unitIds);

  const { results: admissionAccommodations, isLoading: admissionAccommodationsLoading } = useParseQuery(
    findAdmissionAccommodationsQuery(unitIds, start, end)
  );
  const { results: dischargeAccommodations, isLoading: dischargeAccommodationsLoading } = useParseQuery(
    findDischargeAccommodationsQuery(unitIds, start, end)
  );

  const bedMovements = useMemo(() => {
    const bedMovements: BedMovement[] = [];
    if (beds && admissionAccommodations && dischargeAccommodations) {
      for (const bed of beds) {
        const data: BedMovement = { bed: { name: bed.name, id: bed.id } };
        const admission = admissionAccommodations.find((accommodation) => accommodation.bed?.id === bed.id);
        if (admission) {
          data.admission = {
            id: admission.id,
            start: admission.startAt,
            end: admission.endAt,
            isEstimatedEnd: admission.isEstimatedEnd,
            patient: {
              name: formatName(admission.visit!.firstName, admission.visit!.lastName),
              gender: admission.visit!.gender,
              birthdate: admission.visit!.birthdate,
            },
            specificities: admission.specificities || [],
            comment: admission.comment,
            status: admission.status,
            basedOn: admission.basedOn,
          };
        }
        const discharge = dischargeAccommodations.find((accommodation) => accommodation.bed?.id === bed.id);
        if (discharge) {
          data.discharge = {
            id: discharge.id,
            date: discharge.endAt!,
            isEstimatedEnd: discharge.isEstimatedEnd,
            patient: {
              name: formatName(discharge.visit!.firstName, discharge.visit!.lastName),
              gender: discharge.visit!.gender,
              birthdate: discharge.visit!.birthdate,
            },
            specificities: discharge.specificities || [],
            comment: discharge.comment,
            status: discharge.status,
          };
        }
        bedMovements.push(data);
      }
    }
    return bedMovements.sort((a, b) => a.bed.name.localeCompare(b.bed.name));
  }, [admissionAccommodations, dischargeAccommodations, beds]);

  return {
    bedMovements,
    loading: admissionAccommodationsLoading || dischargeAccommodationsLoading || locationsLoading,
  };
};

const findAdmissionAccommodationsQuery = (unitIds: string[], start: Date, end: Date) => {
  return new Api.Query(Accommodation)
    .containedIn('unit', unitIds)
    .notEqualTo('status', 'CANCELLED')
    .exists('bed')
    .exists('patient')
    .greaterThanOrEqualTo('startAt', start)
    .lessThan('startAt', end)
    .include('visit', 'bed', 'basedOn');
};

const findDischargeAccommodationsQuery = (unitIds: string[], start: Date, end: Date) => {
  return new Api.Query(Accommodation)
    .containedIn('unit', unitIds)
    .notEqualTo('status', 'CANCELLED')
    .exists('bed')
    .exists('endAt')
    .exists('patient')
    .greaterThanOrEqualTo('endAt', start)
    .lessThan('endAt', end)
    .include('visit', 'bed');
};

export default useBedMovements;
