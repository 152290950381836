import { color } from 'common/theme';
import { SVGAttributes } from 'react';

type TimelineDividerProps = {
  end?: 'filled' | 'dashed';
  start?: 'filled' | 'dashed';
} & Omit<SVGAttributes<SVGSVGElement>, 'end' | 'viewBox'>;

const TimelineDivider = ({ start = 'filled', end = 'dashed', width = 6, ...props }: TimelineDividerProps) => {
  const timelineColor = color.grey[30];

  return (
    <svg viewBox="0 0 6 26" width={width} {...props}>
      <circle
        cx="3"
        cy="3"
        r="2.5"
        fill={start === 'dashed' ? 'none' : timelineColor}
        stroke={timelineColor}
        strokeWidth={1}
      />
      <line
        x1="3"
        y1="8"
        x2="3"
        y2="13"
        stroke={timelineColor}
        strokeWidth={1}
        strokeDasharray={start === 'dashed' ? '1.5 2' : undefined}
      />
      <line
        x1="3"
        y1="13"
        x2="3"
        y2="18"
        stroke={timelineColor}
        strokeWidth={1}
        strokeDasharray={end === 'dashed' ? '1.5 2' : undefined}
      />
      <circle
        cx="3"
        cy="23"
        r="2.5"
        fill={end === 'dashed' ? 'none' : timelineColor}
        stroke={timelineColor}
        strokeWidth={1}
      />
    </svg>
  );
};

export default TimelineDivider;
