import { getAvailableBeds } from '@ambuliz/sabri-core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { progressValue } from 'common/utils';
import { getThresholdedColor } from 'kurt/const';
import { NormalizedUnit } from 'kurt/hooks/useDepartments';

const DownstreamBedsDetailsDialog = ({
  open = false,
  onClose,
  units = [],
}: {
  open: boolean;
  onClose: () => void;
  units?: NormalizedUnit[];
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" scroll="body">
      <DialogHeader onClose={onClose} title={i18n.waitingForDownstreamBedDetails} />
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unité</TableCell>
              <TableCell>En attente d'aval</TableCell>
              <TableCell>Lits disponibles</TableCell>
              <TableCell>Taux d'occupation global</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map(({ id, name, occupancy, capacityRate }) => (
              <TableRow key={id}>
                <TableCell>
                  <Typography fontWeight={600}>{name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{occupancy?.waitingForDownstreamBed ?? '-'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {getAvailableBeds(
                      occupancy?.dDayAvailabilities?.male,
                      occupancy?.dDayAvailabilities?.female,
                      occupancy?.dDayAvailabilities?.indifferent
                    ) ?? '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <LinearProgress
                      style={{ width: 48 }}
                      value={progressValue(capacityRate)}
                      color={getThresholdedColor(capacityRate)}
                    />
                    <Typography>{capacityRate ? `${capacityRate}%` : '-'}</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{i18n.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownstreamBedsDetailsDialog;
