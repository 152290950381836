import { FarePosition } from '@ambuliz/sabri-core';
import { i18n } from '../locales';

type Position = {
  id: FarePosition;
  label: string;
};

export const positionsFilter: Position[] = [
  {
    id: 'LYING_DOWN_BED',
    label: i18n.farePosition.LYING_DOWN_BED,
  },
  {
    id: 'LYING_DOWN',
    label: i18n.farePosition.LYING_DOWN,
  },
  {
    id: 'SITTING_UP',
    label: i18n.farePosition.SITTING_UP,
  },
  {
    id: 'WALKING',
    label: i18n.farePosition.WALKING,
  },
];

export const yesNoFilter = [
  { id: 'yes', label: i18n.yes },
  { id: 'no', label: i18n.no },
];

export const statusFilter = [
  { id: 'DONE', label: i18n.fareStatus.DONE },
  { id: 'CANCELED', label: i18n.fareStatus.CANCELED },
];
