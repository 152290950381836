import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useRefreshOnNavigate = (refetch: () => void) => {
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.refresh) {
      setShouldRefetch(true);
    }
  }, [location?.state?.refresh]);

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      refetch();
    }
  }, [shouldRefetch, refetch]);
};
