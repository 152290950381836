import { TextField } from '@mui/material';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

type CommentInputProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

const CommentContainer = styled.div`
  background: ${palette.background.default};
  border-radius: 8px;
  padding: 16px;
`;

const Comment = styled(TextField)`
  background: white;
`;

const CommentInput: React.FC<CommentInputProps> = ({ value, onChange }) => {
  return (
    <CommentContainer>
      <Comment
        value={value || ''}
        placeholder={i18n.commentInput.placeholder}
        multiline
        fullWidth
        variant="outlined"
        minRows={3}
        maxRows={10}
        onChange={onChange}
      />
    </CommentContainer>
  );
};

export default CommentInput;
