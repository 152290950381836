import { Card, CardContent, CardHeader, Skeleton, Stack } from '@mui/material';
import { CARD_CONTENT_PAPER_HEIGHT } from './cardContentPaperHeight';

const SkeletonCard = () => {
  return (
    <Card>
      <CardHeader
        component="div"
        title={
          <Stack spacing={2} direction="row" alignItems="center">
            <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={40} height={20} />
            <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={100} height={20} />
          </Stack>
        }
      />
      <CardContent>
        <Stack spacing={3}>
          <Skeleton variant="rectangular" sx={{ borderRadius: 1 }} width="100%" height={CARD_CONTENT_PAPER_HEIGHT} />
          <Stack spacing={4} direction="row" justifyContent="space-between" alignItems="center" sx={{ height: 32 }}>
            <Stack>
              <Skeleton variant="text" width={65}></Skeleton>
              <Skeleton variant="text" width={40}></Skeleton>
            </Stack>
            <Stack>
              <Skeleton variant="text" width={50}></Skeleton>
              <Skeleton variant="text" width={70}></Skeleton>
            </Stack>

            <Skeleton variant="circular" width={20} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SkeletonCard;
