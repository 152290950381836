import Api, { Accommodation, AccommodationRequest, PerformerRequest, Unit } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { addDays, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { MovementRow, mapAccommodationToRow, mapRequestToRow } from '../MovementRow';
import useOutdatedAnomalies from '../useOutdatedAnomalies';

const useAdmissions = (date: Date, unitIds: string[]) => {
  const start = useMemo(() => startOfDay(date), [date]);
  const end = useMemo(() => startOfDay(addDays(date, 1)), [date]);

  const { accommodationRequests, loading: accommodationRequestsLoading } = useAccommodationRequests(unitIds);
  const { results: accommodations, isLoading: accommodationsLoading } = useParseQuery(
    findAccommodationsQuery(unitIds, start, end)
  );

  const { findByMovement } = useOutdatedAnomalies(
    accommodations.map(({ id }) => id),
    'start'
  );

  const movements = useMemo(() => {
    const movements: MovementRow[] = [];
    if (accommodationRequests) {
      for (const request of accommodationRequests) {
        const row = mapRequestToRow(request);
        if (row) {
          movements.push(row);
        }
      }
    }

    if (accommodations) {
      for (const accommodation of accommodations) {
        if (accommodation.visit) {
          movements.push(mapAccommodationToRow(accommodation, findByMovement(accommodation.id) || []));
        }
      }
    }

    return movements;
  }, [accommodations, accommodationRequests, findByMovement]);

  return { movements, loading: accommodationRequestsLoading || accommodationsLoading };
};

const useAccommodationRequests = (unitIds: string[]) => {
  const { results: performerRequests, isLoading: loading } = useParseQuery(findPerformerRequestsQuery(unitIds));

  const accommodationRequests = useMemo(() => {
    const requests: Record<string, AccommodationRequest> = {};

    for (const performerRequest of Object.values(performerRequests)) {
      const request = performerRequest.request;
      request.performerRequests = [performerRequest];
      requests[request.id] = request;
    }

    return Object.values(requests);
  }, [performerRequests]);

  return {
    accommodationRequests,
    loading,
  };
};

const findPerformerRequestsQuery = (unitIds: string[]) =>
  new Api.Query(PerformerRequest)

    .containedIn(
      'performer',
      unitIds.map((unitId) => Unit.createWithoutData(unitId))
    )
    .include('request', 'request.visit.patient')
    .equalTo('status', 'REQUESTED');

const findAccommodationsQuery = (unitIds: string[], start: Date, end: Date) =>
  new Api.Query(Accommodation)
    .notEqualTo('status', 'CANCELLED')
    .greaterThanOrEqualTo('startAt', start)
    .lessThan('startAt', end)
    .containedIn(
      'unit',
      unitIds.map((unitId) => Unit.createWithoutData(unitId))
    )
    .include('visit', 'bed', 'unit', 'basedOn');

export default useAdmissions;
