import { AnomalyType } from '@ambuliz/sabri-core';
import { Stack, StackProps, Typography, TypographyProps, TypographyVariant } from '@mui/material';
import { Emoji } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { i18n } from 'common/locale';
import { palette, theme } from 'common/theme';
import BedAnomaliesTooltip from 'kurt/components/AnomaliesTooltips/BedAnomaliesTooltip';
import { useState } from 'react';

type BedNameTooltipProps = {
  name: string;
};

const BedName = ({
  name,
  anomalies,
  variant,
  containerProps,
}: {
  name: string;
  anomalies?: { type: AnomalyType; accommodation?: { id: string } }[];
  variant?: TypographyVariant;
  containerProps?: StackProps & TypographyProps;
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const emojiSize = variant ? (theme.typography[variant].fontSize as number) : 14;

  return !!anomalies?.length ? (
    <BedAnomaliesTooltip anomalies={anomalies} bedName={isOverflowing ? name : undefined}>
      <Stack direction="row" spacing={1} width="100%" alignItems="center" {...containerProps}>
        <Emoji name="warning" size={emojiSize} />

        <EllipsisTypography
          noWrap
          fontWeight={600}
          variant={variant}
          color={palette.warning.main}
          overflow="hidden"
          textOverflow="ellipsis"
          setIsOverflowing={setIsOverflowing}
          disableTooltip
        >
          {name}
        </EllipsisTypography>
      </Stack>
    </BedAnomaliesTooltip>
  ) : (
    <Stack direction="row" spacing={1} width="100%" alignItems="center" {...containerProps}>
      <EllipsisTypography
        noWrap
        fontWeight={600}
        variant={variant}
        tooltipProps={{ title: <BedNameTooltip name={name} /> }}
      >
        {name}
      </EllipsisTypography>
    </Stack>
  );
};

export const BedNameTooltip = ({ name }: BedNameTooltipProps) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography variant="body2">{i18n.bed}</Typography>
    <Typography variant="body2" fontWeight={700}>
      {name}
    </Typography>
  </Stack>
);

export default BedName;
