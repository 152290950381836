import { Components } from '@mui/material';
import SortDescIcon from 'common/components/Icons/SortDesc';

const MuiTableSortLabel: Components['MuiTableSortLabel'] = {
  defaultProps: {
    IconComponent: SortDescIcon,
  },

  styleOverrides: {
    root: {
      '&.MuiTableSortLabel-root .MuiSvgIcon-root': {
        transition: '0ms',
      },
    },
  },
};

export default MuiTableSortLabel;
