import { SvgIcon, SvgIconProps } from '@mui/material';

const GridView: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 1.756C5.5 1.61462 5.38539 1.5 5.244 1.5H1.756C1.61462 1.5 1.5 1.61462 1.5 1.756V5.244C1.5 5.38539 1.61462 5.5 1.756 5.5H5.244C5.38539 5.5 5.5 5.38539 5.5 5.244V1.756ZM0.5 0C0.223858 0 0 0.223858 0 0.5V6.5C0 6.77614 0.223858 7 0.5 7H6.5C6.77614 7 7 6.77614 7 6.5V0.5C7 0.223858 6.77614 0 6.5 0H0.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 10.75C5.5 10.6119 5.38807 10.5 5.25 10.5H1.75C1.61193 10.5 1.5 10.6119 1.5 10.75V14.25C1.5 14.3881 1.61193 14.5 1.75 14.5H5.25C5.38807 14.5 5.5 14.3881 5.5 14.25V10.75ZM0.5 9C0.223858 9 0 9.22386 0 9.5V15.5C0 15.7761 0.223858 16 0.5 16H6.5C6.77614 16 7 15.7761 7 15.5V9.5C7 9.22386 6.77614 9 6.5 9H0.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 1.75C14.5 1.61193 14.3881 1.5 14.25 1.5H10.75C10.6119 1.5 10.5 1.61193 10.5 1.75V5.25C10.5 5.38807 10.6119 5.5 10.75 5.5H14.25C14.3881 5.5 14.5 5.38807 14.5 5.25V1.75ZM9.5 0C9.22386 0 9 0.223858 9 0.5V6.5C9 6.77614 9.22386 7 9.5 7H15.5C15.7761 7 16 6.77614 16 6.5V0.5C16 0.223858 15.7761 0 15.5 0H9.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 10.75C14.5 10.6119 14.3881 10.5 14.25 10.5H10.75C10.6119 10.5 10.5 10.6119 10.5 10.75V14.25C10.5 14.3881 10.6119 14.5 10.75 14.5H14.25C14.3881 14.5 14.5 14.3881 14.5 14.25V10.75ZM9.5 9C9.22386 9 9 9.22386 9 9.5V15.5C9 15.7761 9.22386 16 9.5 16H15.5C15.7761 16 16 15.7761 16 15.5V9.5C16 9.22386 15.7761 9 15.5 9H9.5Z"
      />
    </SvgIcon>
  );
};

export default GridView;
