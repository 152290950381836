import { PorterStatus } from '@ambuliz/sabri-core';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { WifiOff } from '@mui/icons-material';
import React from 'react';
import { colors } from '../../const/colors';
import { i18n } from '../../locales';

export type ChipPorterStatusProps = {
  status: PorterStatus;
  hasInternet: boolean;
  hasAssignations?: boolean;
};

export const ChipPorterStatus: React.FC<ChipPorterStatusProps> = ({ status, hasInternet, hasAssignations }) => {
  const isOffline = status !== 'OFF' && !hasInternet;
  const classes = useClasses({ status, isOffline });
  const chipClasses = { label: classes.text };
  const statusToDisplay =
    hasAssignations && status === 'AVAILABLE' ? i18n.porterStatus.ASSIGNED : i18n.porterStatus[status];

  return (
    <Chip
      size="small"
      label={statusToDisplay}
      className={classes.chipContainer}
      classes={chipClasses}
      icon={isOffline ? <WifiOff className={classes.icon} /> : undefined}
    />
  );
};

type ChipPorterStatusStylesProps = {
  status: PorterStatus;
  isOffline: boolean;
};

const useClasses = makeStyles(({ palette }) => ({
  icon: {
    width: 15,
    height: 15,
    color: palette.common.white,
  },
  text: ({ isOffline }: ChipPorterStatusStylesProps) => ({
    color: palette.common.white,
    fontSize: 15,
    paddingRight: 5,
    paddingLeft: isOffline ? 8 : 5,
  }),
  chipContainer: ({ status }: ChipPorterStatusStylesProps) => ({
    backgroundColor: colors.porterStatus[status],
    borderRadius: 3,
  }),
}));
