import { SvgIcon, SvgIconProps } from '@mui/material';

const AffectedBedIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_4041_15378)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5788 3.57293V6.1905C12.4547 6.21683 13.1567 6.93518 13.1567 7.8175V7.89939C13.1567 8.24515 12.8764 8.52544 12.5306 8.52544C12.1849 8.52544 11.9046 8.24515 11.9046 7.89939V7.8175C11.9046 7.61004 11.7364 7.44187 11.5289 7.44187H2.10269C1.71391 7.44187 1.50466 7.53628 1.40355 7.62033C1.31758 7.6918 1.25212 7.80191 1.25212 8.0055L1.25211 10.6684H4.63233C4.97809 10.6684 5.25839 10.9487 5.25839 11.2945C5.25839 11.6403 4.97809 11.9206 4.63233 11.9206H1.986V12.7792C1.986 13.125 1.70571 13.4053 1.35995 13.4053C1.01419 13.4053 0.733892 13.125 0.733892 12.7792V11.9206H0.726223C0.325141 11.9206 0 11.5954 0 11.1943L8.53785e-06 8.0055C8.53785e-06 7.48443 0.19147 6.99969 0.603118 6.65748C0.849967 6.45227 1.14819 6.32129 1.47664 6.25165V3.57348C1.47664 2.5362 2.31752 1.69531 3.3548 1.69531H9.70059C10.7375 1.69531 11.5788 2.53529 11.5788 3.57293ZM2.72875 3.57348C2.72875 3.22772 3.00904 2.94743 3.3548 2.94743H9.70059C10.0467 2.94743 10.3266 3.22753 10.3266 3.57293V6.18975H9.73863C9.70732 5.1799 8.87883 4.37086 7.86138 4.37086H5.2793C4.26185 4.37086 3.43336 5.1799 3.40205 6.18975H2.72875V3.57348ZM7.86138 5.62297C8.18715 5.62297 8.4548 5.87179 8.48467 6.18975H4.65601C4.68587 5.87179 4.95353 5.62297 5.2793 5.62297H7.86138Z"
        />
        <path d="M14.6584 8.11552C14.966 7.81223 15.4605 7.8131 15.7671 8.11747C16.0784 8.42654 16.0775 8.93031 15.7651 9.23827L10.5015 14.4269C10.2146 14.7097 9.7538 14.7097 9.46691 14.4269L6.58434 11.5854C6.27204 11.2775 6.27204 10.7737 6.58434 10.4658C6.89006 10.1644 7.38101 10.1641 7.68715 10.465L9.9842 12.723L14.6584 8.11552Z" />
      </g>
      <defs>
        <clipPath id="clip0_4041_15378">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default AffectedBedIcon;
