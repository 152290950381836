import { Chip } from '@mui/material';
import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';

type CovidOccupancyProps = {
  covid?: number;
  USCAvailable?: number;
  REAAvailable?: number;
  USIAvailable?: number;
  onOpenDetails: () => void;
  xs?: number | boolean | 'auto';
  sm?: number | boolean | 'auto';
  md?: number | boolean | 'auto';
  lg?: number | boolean | 'auto';
  xl?: number | boolean | 'auto';
};

const CovidOccupancy = ({
  covid,
  USIAvailable,
  REAAvailable,
  USCAvailable,
  onOpenDetails,
  lg,
  md,
  sm,
  xl,
  xs,
}: CovidOccupancyProps) => {
  return (
    <Statistics.Card
      header={{ title: i18n.covidOccupancy, option: { label: i18n.details, onClick: onOpenDetails } }}
      md={md}
      sm={sm}
      xs={xs}
      lg={lg}
      xl={xl}
    >
      <Statistics.Item title={{ label: i18n.patientTotal, color: 'default', variant: 'light' }}>
        <Statistics.ItemContent align="center">
          <Statistics.Text variant="lg" color="error" sx={{ mr: 1 }}>
            {covid}
          </Statistics.Text>
          <Chip label={i18n.covid} color="error" />
        </Statistics.ItemContent>
      </Statistics.Item>
      <Statistics.Item title={{ label: i18n.availableInUSC, color: 'default', variant: 'light' }}>
        <Statistics.ItemContent>
          <Statistics.Text variant="lg">{USCAvailable || '-'}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
      <Statistics.Item title={{ label: i18n.availableInUSI, color: 'default', variant: 'light' }}>
        <Statistics.ItemContent>
          <Statistics.Text variant="lg">{USIAvailable || '-'}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
      <Statistics.Item title={{ label: i18n.availableInREA, color: 'default', variant: 'light' }}>
        <Statistics.ItemContent>
          <Statistics.Text variant="lg">{REAAvailable || '-'}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
    </Statistics.Card>
  );
};

export default CovidOccupancy;
