import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { requestNames, RequestStatus } from '../../const/requests';
import { resetAction } from '../actions/general';

type RequestStatusState = Readonly<Record<string, RequestStatus>>;

const initialState: RequestStatusState = {
  [requestNames.getFares]: 'NOT_CALLED',
  [requestNames.getCurrentUser]: 'NOT_CALLED',
  [requestNames.getPatients]: 'NOT_CALLED',
  [requestNames.getAreas]: 'NOT_CALLED',
  [requestNames.getHealthCenter]: 'NOT_CALLED',
  [requestNames.getPatientsByName]: 'NOT_CALLED',
  [requestNames.login]: 'NOT_CALLED',
};

type SetRequestStatusPayload = {
  key: string;
  status: RequestStatus;
};

type SetObjectIdsRequestStatusPayload = {
  ids: string[];
  status: RequestStatus;
  method: string;
};

const setRequestStatus = (state: RequestStatusState, action: PayloadAction<SetRequestStatusPayload>) => ({
  ...state,
  [action.payload.key]: action.payload.status,
});

const setObjectIdsRequestStatus = (
  state: RequestStatusState,
  action: PayloadAction<SetObjectIdsRequestStatusPayload>
) => {
  const nextState = { ...state };
  const { ids, status, method } = action.payload;
  for (const id of ids) {
    nextState[`${method}_${id}`] = status;
  }
  return nextState;
};

const reset = (state: any) => initialState;

const { reducer, actions } = createSlice({
  name: 'requestStatus',
  initialState,
  reducers: {
    setRequestStatus,
    setObjectIdsRequestStatus,
  },
  extraReducers: (builder) => builder.addCase(resetAction, reset),
});

export { actions as requestStatusActions, reducer as requestStatusReducer };
