import { DelayRule, PatientGender } from '@ambuliz/sabri-core';
import { addDays, addHours, isWithinInterval, startOfToday } from 'date-fns';
import { Resource } from './useResources.types';

const getBedGender = (genders?: PatientGender[]): PatientGender => {
  if (!genders || genders.length === 0) {
    return 'UNKNOWN';
  }

  if (genders.length === 1) {
    return genders[0];
  }

  const female = genders.some((gender) => gender === 'FEMALE');
  const male = genders.some((gender) => gender === 'MALE');

  if (female === male) {
    return 'UNKNOWN';
  }
  if (female) {
    return 'FEMALE';
  }

  return 'MALE';
};

const getGender = (resource: Resource) => resource.accommodation?.visit?.gender || resource.bed.gender;

const getStatus = (resource: Resource, bookedBedDelayRule?: DelayRule) =>
  !!resource.healthcareEnvironmentalCleaning
    ? 'CLEANING'
    : !!resource.accommodation?.visit
    ? 'BUSY'
    : !!resource.accommodation
    ? 'CLOSED'
    : isAvailableOrBooked(resource.bed.nextSlotAt, bookedBedDelayRule);

const isAvailableOrBooked = (date?: Date, config?: DelayRule): 'BOOKED' | 'AVAILABLE' => {
  if (config) {
    const now = new Date();

    const rule = config?.type;
    const value = config?.value;

    let dateToCompare = rule === 'HOURS' ? addHours(now, value) : addHours(startOfToday(), value);

    if (rule === 'TOMORROW_AT') {
      dateToCompare = addDays(dateToCompare, 1);
    }

    if (date && now < dateToCompare && isWithinInterval(date, { start: now, end: dateToCompare })) {
      return 'BOOKED';
    }
  }
  return 'AVAILABLE';
};

const flatResource = (resource: Resource) => {
  const rows: Resource[] = [];

  if (resource.accommodations && resource.accommodations.length > 0) {
    for (const accommodation of resource.accommodations) {
      const anomalies = resource.anomalies?.filter(
        (anomaly) => anomaly.type === 'OVERLAPPING_STEPS' || anomaly.accommodation?.id === accommodation.id
      );
      rows.push({
        bed: resource.bed,
        accommodation,
        accommodations: [],
        anomalies,
      });
    }
  }

  if (resource.healthcareEnvironmentalCleanings && resource.healthcareEnvironmentalCleanings.length > 0) {
    for (const healthcareEnvironmentalCleaning of resource.healthcareEnvironmentalCleanings) {
      rows.push({
        bed: resource.bed,
        healthcareEnvironmentalCleaning,
        accommodations: [],
        anomalies: [],
      });
    }
  }

  if (
    (!resource.accommodations || resource.accommodations.length === 0) &&
    (!resource.healthcareEnvironmentalCleanings || resource.healthcareEnvironmentalCleanings.length === 0)
  ) {
    rows.push(resource);
  }

  return rows;
};

const flatResources = (resources: Resource[]) => {
  const rows: Resource[] = [];

  for (const resource of resources) {
    rows.push(...flatResource(resource));
  }

  return rows;
};

export { flatResource, flatResources, getBedGender, getGender, getStatus, isAvailableOrBooked };
