import { Cloud, Hl7AdtMessage, Hl7SiuMessage } from '@ambuliz/sabri-core';
import { Refresh } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Stack } from '@mui/material';
import { MultipleSelect } from 'common/components';
import { i18n } from 'common/locale';
import { useMemo, useState } from 'react';
import Hl7AdtMessageTable from './Hl7AdtMessageTable';
import Hl7SiuMessageTable from './Hl7SiuMessageTable';

const PatientHl7Messages = ({
  patientId,
  messageType,
  from,
}: {
  patientId?: string;
  messageType: 'siu' | 'adt';
  from: Date;
}) => {
  const [hl7Messages, setHl7Messages] = useState<(Hl7AdtMessage | Hl7SiuMessage)[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const [pansFilter, setPansFilter] = useState<string[]>([]);

  const pans = useMemo(
    () => new Set(hl7Messages?.map((m) => m.pan).filter((pan) => !!pan)) as Set<string>,
    [hl7Messages]
  );

  const filteredRows = useMemo(
    () =>
      hl7Messages
        ?.filter(({ pan }) => (pansFilter.length > 0 && pan ? pansFilter.includes(pan) : true))
        .sort((a, b) => a.msgId.localeCompare(b.msgId)) || [],
    [hl7Messages, pansFilter]
  );

  const fetchHl7Messages = async () => {
    if (patientId) {
      setLoading(true);
      try {
        const response =
          messageType === 'adt'
            ? await Cloud.getHl7AdtMessages({ patientId, from })
            : await Cloud.getHl7SiuMessages({ patientId, from });
        setHl7Messages(response.messages);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <Card>
      <CardHeader
        title={`${i18n.patientHl7.title} ${messageType.toUpperCase()}`}
        action={
          <Button endIcon={<Refresh />} onClick={fetchHl7Messages} disabled={!!hl7Messages || loading}>
            {i18n.patientHl7.loadData}
          </Button>
        }
      />
      <CardContent>
        {loading ? (
          <CircularProgress size={20} />
        ) : !!filteredRows.length ? (
          <Stack spacing={4} paddingTop={4}>
            <Box>
              <MultipleSelect
                searchable
                label={i18n.patientHl7.pan}
                options={Array.from(pans).map((pan) => ({ value: pan, label: pan }))}
                values={pansFilter}
                onChange={setPansFilter}
              />
            </Box>
            {messageType === 'adt' ? (
              <Hl7AdtMessageTable hl7Messages={filteredRows} />
            ) : (
              <Hl7SiuMessageTable hl7Messages={filteredRows} />
            )}
          </Stack>
        ) : (
          <div>{i18n.patientHl7.noMessage}</div>
        )}
      </CardContent>
    </Card>
  );
};

export default PatientHl7Messages;
