import { Patient, PatientGender } from '@ambuliz/sabri-core';
import { getTimelineType } from '../context/DialogContext';
import { LocationStep, StepItem } from '../context/Step';
import BedInfos from './BedInfos';
import PatientInfos from './PatientInfos';
import SkeletonInfos from './SkeletonInfos';

type InfoCardProps = {
  step?: StepItem;
  patient?: Patient;
  cardChip?: boolean;
  bedGender?: PatientGender;
};

const InfoCard = ({ step, patient, cardChip, bedGender }: InfoCardProps) => {
  if (!step) {
    return <SkeletonInfos />;
  }
  switch (getTimelineType(step)) {
    case 'patient':
      return (patient && <PatientInfos patient={patient} />) || null;

    case 'location':
      return <BedInfos step={step as LocationStep} cardChip={cardChip} bedGender={bedGender} />;

    default:
      return null;
  }
};

export default InfoCard;
