import Api, { Cloud, Fare, Journey } from '@ambuliz/sabri-core';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import useParseQuery from 'common/hooks/useParseQuery';
import useParseResource from 'common/hooks/useParseResource';
import { toast } from 'common/toast';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HeaderFareModal } from 'sabri/components/HeaderFareModal/HeaderFareModal';
import { routeNamesCommon, routeNamesCommonDynamic } from 'sabri/const/routeNames';
import { useCloseModal } from 'sabri/hooks/useCloseModal';
import { i18n } from 'sabri/locales';
import { getPatientFullName } from 'sabri/services/patient';
import { confirmFareAction } from 'sabri/store/actions/fare';
import useJourneyFares from '../../hooks/useJourneyFares';
import { CancelJourneyConfirmModal } from '../CancelJourneyConfirmModal';
import StorelessModal from '../StorelessModal/StorelessModal';
import FareDetailsModalContent from './FareDetailsModalContent';

const FareDetailsModal = () => {
  const { id } = useParams();

  const { isLoading: isFareQueryLoading, resource: fare } = useParseResource(id, Fare, {
    include: ['patient', 'incidents', 'incidents.porter', 'assignations', 'healthCenter'],
  });

  const isJourneyQueryEnabled = useMemo(() => !!fare?.journey, [fare]);
  const isGroupedFaresQueryEnabled = useMemo(() => !!fare?.groupId, [fare]);

  const { resource: journey, isLoading: isJourneyQueryLoading } = useParseResource(fare?.journey?.id, Journey, {
    include: ['startingUnit', 'startingArea'],
    enabled: isJourneyQueryEnabled,
  });

  const { journeyFares, isLoading: isJourneyFaresQueryLoading } = useJourneyFares(journey);

  const { results: groupedFares, isLoading: isGroupedFaresQueryLoading } = useParseQuery(
    new Api.Query(Fare).equalTo('groupId', fare?.groupId),
    {
      enabled: isGroupedFaresQueryEnabled,
    }
  );

  const isLoading = useMemo(
    () => isFareQueryLoading || isJourneyQueryLoading || isJourneyFaresQueryLoading || isGroupedFaresQueryLoading,
    [isFareQueryLoading, isJourneyQueryLoading, isJourneyFaresQueryLoading, isGroupedFaresQueryLoading]
  );

  return (
    <FareDetailsModalContainer
      isLoading={isLoading}
      fare={fare}
      groupedFares={groupedFares}
      journeyFares={journeyFares}
      journey={journey}
    />
  );
};

const FareDetailsModalContainer = ({
  isLoading,
  fare,
  journey,
  groupedFares,
  journeyFares,
}: {
  isLoading?: boolean;
  fare?: Fare;
  journey?: Journey;
  journeyFares: Fare[];
  groupedFares: Fare[];
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const closeModal = useCloseModal();
  const [open, setOpen] = useState(true);
  const [confirmCallPatientModalOpen, setConfirmCallPatientModalOpen] = useState<boolean>(false);
  const [cancelJourneyConfirmModalOpen, setCancelJourneyConfirmModalOpen] = useState(false);
  const [cancelJourneyLoading, setCancelJourneyLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    closeModal();
  };

  const handleConfirmCallPatient = () => {
    if (fare) {
      dispatch(confirmFareAction({ fareId: fare.id }));
      setConfirmCallPatientModalOpen(false);
    }
  };

  const handleCloseConfirmCallPatientModal = () => {
    setConfirmCallPatientModalOpen(false);
  };

  const handleCloseCancelJourneyConfirmModal = () => {
    setCancelJourneyConfirmModalOpen(false);
  };

  const handleConfirmCancelJourney = async () => {
    if (journey) {
      setCancelJourneyLoading(true);
      try {
        await Cloud.cancelJourney({ journeyId: journey.id });
        toast.success(i18n.cancelJourneySuccess);
      } catch (err) {
        toast.error(err.message);
      } finally {
        handleCloseCancelJourneyConfirmModal();
        setCancelJourneyLoading(false);
      }
    }
  };

  useEffect(() => {
    if (location.search.slice(1) === 'autoCall') {
      setConfirmCallPatientModalOpen(true);
    }
  }, [location.search]);

  const activeFare = useMemo(
    () => journeyFares.find((fare) => fare.status && ACTIVE_FARE_STATUSES.includes(fare.status)),
    [journeyFares]
  );

  const redirectToFareList = () => {
    navigate(routeNamesCommon.fareList);
  };

  const redirectToActiveFare = () => {
    if (activeFare) {
      navigate(routeNamesCommonDynamic.fareDetails(activeFare.id));
    } else {
      redirectToFareList();
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth="lg" scroll="body" onClose={closeModal}>
        {isLoading ? (
          <DialogContent>
            <Box display="flex" justifyContent="center" alignItems="center" height={300}>
              <CircularProgress size={40} />
            </Box>
          </DialogContent>
        ) : fare ? (
          <>
            {fare.status === 'QUEUED' ? (
              <>
                <DialogTitle>
                  <HeaderFareModal reference={fare.reference} onClose={redirectToFareList} status={fare.status} />
                </DialogTitle>
                <DialogContent>
                  <Typography>{i18n.queuedFareContentText}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={redirectToActiveFare}>{i18n.redirectToActiveFareLabel}</Button>
                </DialogActions>
              </>
            ) : (
              <FareDetailsModalContent
                fare={fare}
                journey={journey}
                groupedFares={groupedFares}
                journeyFares={journeyFares}
                onClose={handleClose}
                onCancelJourney={() => setCancelJourneyConfirmModalOpen(true)}
              />
            )}
          </>
        ) : (
          <DialogContent>
            <Typography>{i18n.fareDoesntExist}</Typography>
          </DialogContent>
        )}
      </Dialog>
      <StorelessModal
        onClose={handleCloseConfirmCallPatientModal}
        onConfirm={handleConfirmCallPatient}
        isOpen={confirmCallPatientModalOpen}
        params={{
          title: i18n.modalConfirmPushFare.title,
          content: (
            <>
              <Typography>
                {i18n.modalConfirmPullFare.body1}{' '}
                <span style={{ fontWeight: 600 }}>{getPatientFullName(fare?.patient)}.</span>
              </Typography>
              <Typography>{i18n.modalConfirmPullFare.body2}.</Typography>
            </>
          ),
        }}
      />
      <CancelJourneyConfirmModal
        open={cancelJourneyConfirmModalOpen}
        loading={cancelJourneyLoading}
        onClose={handleCloseCancelJourneyConfirmModal}
        onConfirm={handleConfirmCancelJourney}
      />
    </>
  );
};

export const ACTIVE_FARE_STATUSES = [
  'PENDING',
  'WAITING',
  'ASSIGNED',
  'STARTED',
  'PATIENT_CARE',
  'IN_PROGRESS',
  'DONE',
];

export default FareDetailsModal;
