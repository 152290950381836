import { Badge, Box, Divider, IconButton, Link, Paper, Stack, Switch, Tooltip, Typography } from '@mui/material';
import Emoji from 'common/components/Emoji';
import Cross from 'common/components/Icons/Cross';
import SoundOff from 'common/components/Icons/SoundOff';
import SoundOn from 'common/components/Icons/SoundOn';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { isNotificationSoundEnabled, setNotificationSound } from 'common/utils/notificationSound';
import { getContext, getSeverity, useAppBarContext } from 'core/layout';
import { rocketChatHeight } from 'core/support/rocketChat';
import { useEffect, useRef, useState } from 'react';
import { Notification } from '..';
import NotificationCard from '../NotificationCard';
import NotificationCardContent from '../NotificationCardContent';
import NotificationPanelSectionSubheader from './NotificationPanelSectionSubheader';
import NotificationsEmpty from './NotificationsEmpty';

export type NotificationsPanelProps = {
  loading?: boolean;
  notifications: Notification[];
  onClose: () => void;
  onReadAll: (notificationIds: string[]) => void;
  onToggleReadNotification: (id: string) => void;
  onClickNotification: (id: string) => void;
};

const NotificationsPanel: React.FC<NotificationsPanelProps> = ({
  notifications,
  loading = false,
  onClose,
  onReadAll,
  onToggleReadNotification,
  onClickNotification,
}) => {
  const [notificationsSound, setNotificationsSound] = useState(isNotificationSoundEnabled());
  const containerRef = useRef<HTMLDivElement>(null);
  const { appBarHeight } = useAppBarContext();
  notifications = notifications.sort((a, b) => b.date.getTime() - a.date.getTime());

  const unreadNotifications = notifications.filter(({ read }) => !read);
  const readNotifications = notifications.filter(({ read }) => !!read);

  const statistics = unreadNotifications.reduce(
    (acc, notification) => {
      const severity = getSeverity(notification.triggerEvent);
      return {
        ...acc,
        [severity]: acc[severity] + 1,
      };
    },
    { error: 0, warning: 0, info: 0 }
  );

  useEffect(() => {
    setNotificationSound(notificationsSound);
  }, [notificationsSound]);

  return (
    <Paper elevation={4}>
      <Stack
        maxHeight={`calc(100vh - ${appBarHeight + rocketChatHeight + 8 /* spacing beneath bell icon */}px)`}
        width={400}
      >
        <Box overflow="hidden auto" flex={1} ref={containerRef}>
          <Box
            bgcolor="#FFF"
            position="sticky"
            top={0}
            paddingBottom={3}
            paddingTop={5}
            paddingX={6}
            zIndex={3}
            borderRadius={1}
          >
            <Stack direction="row" justifyContent="space-between" display="flex">
              <Typography variant="h2">{i18n.notificationsPanel.title}</Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  onClick={() => setNotificationsSound(!notificationsSound)}
                >
                  {notificationsSound ? <SoundOn color="primary"></SoundOn> : <SoundOff color="secondary"></SoundOff>}
                  <Switch checked={notificationsSound} size="small"></Switch>
                </Stack>
                <IconButton onClick={onClose} edge="end">
                  <Cross color="secondary" />
                </IconButton>
              </Stack>
            </Stack>
          </Box>

          {unreadNotifications.length === 0 && readNotifications.length === 0 ? (
            <Box paddingX={6} paddingBottom={6}>
              <NotificationsEmpty />
            </Box>
          ) : null}
          {unreadNotifications.length > 0 && (
            <div style={{ paddingBottom: readNotifications.length > 0 ? 12 : 24 }}>
              <NotificationPanelSectionSubheader container={containerRef}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography variant="h4">{i18n.notificationsPanel.newAlerts}</Typography>
                  <Tooltip title={<NewAlertsTooltip {...statistics} />} placement="top">
                    <Badge
                      badgeContent={unreadNotifications.length}
                      componentsProps={{ badge: { style: { transform: 'translate(100%, -50%)' } } }}
                    />
                  </Tooltip>
                </Stack>
                <Link component="button" onClick={() => onReadAll(unreadNotifications.map(({ id }) => id))}>
                  {i18n.notificationsPanel.markAllAsRead}
                </Link>
              </NotificationPanelSectionSubheader>
              <Stack spacing={2} paddingX={6}>
                {unreadNotifications.map((notification) => (
                  <NotificationCard
                    key={notification.id}
                    content={<NotificationCardContent notification={notification} />}
                    context={getContext(notification.triggerEvent)}
                    date={notification.date}
                    onClick={() => onClickNotification(notification.id)}
                    severity={getSeverity(notification.triggerEvent)}
                    onToggleRead={() => onToggleReadNotification(notification.id)}
                    loading={loading || notification.loading}
                  />
                ))}
              </Stack>
            </div>
          )}
          {readNotifications.length > 0 && (
            <div style={{ paddingBottom: 24 }}>
              <NotificationPanelSectionSubheader container={containerRef}>
                <Typography variant="h4">{i18n.notificationsPanel.history}</Typography>
              </NotificationPanelSectionSubheader>
              <Stack spacing={2} paddingX={6}>
                {readNotifications.map((notification) => (
                  <NotificationCard
                    key={notification.id}
                    content={<NotificationCardContent notification={notification} />}
                    context={getContext(notification.triggerEvent)}
                    date={notification.date}
                    onClick={() => onClickNotification(notification.id)}
                    severity={getSeverity(notification.triggerEvent)}
                    read={!!notification.read}
                    onToggleRead={() => onToggleReadNotification(notification.id)}
                    loading={loading || notification.loading}
                  />
                ))}
              </Stack>
            </div>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};

const NewAlertsTooltip = ({
  info = 0,
  warning = 0,
  error = 0,
}: {
  info?: number;
  warning?: number;
  error?: number;
}) => {
  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="center"
      divider={<Divider flexItem orientation="vertical" sx={{ borderColor: color.grey[70] }} />}
    >
      {error > 0 && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Emoji name="noEntry" size={24} />
          <Typography variant="body2">{error}</Typography>
        </Stack>
      )}
      {warning > 0 && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Emoji name="warning" size={24} />
          <Typography variant="body2">{warning}</Typography>
        </Stack>
      )}
      {info > 0 && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Emoji name="closedMailboxWithRaisedFlag" size={24} />
          <Typography variant="body2">{info}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default NotificationsPanel;
