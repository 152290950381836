import arrival from '../images/icons/arrival.svg';
import addCircle from '../images/icons/add_circle.svg';
import departure from '../images/icons/departure.svg';
import close from '../images/icons/close.svg';
import ambuliz from '../images/pictures/ambuliz.png';
import ambulizDark from '../images/pictures/ambuliz_dark.svg';
import check from '../images/icons/check.svg';
import alarm from '../images/icons/alarm.svg';
import enter from '../images/icons/ENTERv.svg';
import enterCancel from '../images/icons/ENTERc.svg';
import exit from '../images/icons/EXITv.svg';
import exitCancel from '../images/icons/EXITc.svg';
export const icons = {
  departure,
  arrival,
  alarm,
  addCircle,
  close,
  check,
  enter,
  enterCancel,
  exit,
  exitCancel,
};

export const pictures = {
  ambuliz,
  ambulizDark,
};
