import { Paper, Stack, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { ArrowCircle, Clock } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { format } from 'date-fns';
import styled from 'styled-components';

type MutationFlowProps = {
  fromUnitName?: string;
  fromBedName?: string;
  dischargeDate?: Date;
  toUnitName?: string;
  toBedName?: string;
  admissionDate?: Date;
};

const Container = styled(Paper)`
  padding: 16px 24px;
  display: 'flex';
  flex: 1;
`;

const Circle = styled.div`
  position: absolute;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #d1d3dd;
  border-radius: 50%;
  top: calc(50% - 17px);
  left: calc(50% - 17px);
`;

export const MutationFlow: React.FC<MutationFlowProps> = ({
  fromUnitName,
  fromBedName,
  dischargeDate,
  toUnitName,
  toBedName,
  admissionDate,
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <Circle>
        <ArrowCircle />
      </Circle>
      <Stack direction="row" spacing={5}>
        <Container>
          <Stack spacing={3}>
            <Typography variant="body2" color={color.grey[60]}>
              {i18n.departure}
            </Typography>
            <Stack spacing={1}>
              <EllipsisTypography variant="h4" color={color.grey[100]} maxLines={2}>
                {fromUnitName || '-'}
              </EllipsisTypography>
              <Stack spacing={2} alignItems="center" direction="row" divider={<span>{'•'}</span>}>
                {fromBedName && (
                  <Typography variant="body2" color={color.grey[100]}>
                    {i18n.bed} : {fromBedName}
                  </Typography>
                )}
                {dischargeDate && (
                  <Stack spacing={1} alignItems="center" direction="row">
                    <Clock />
                    <Typography variant="body2" color={color.grey[100]}>
                      {format(dischargeDate, 'dd/MM - HH:mm')}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Container>
          <Stack spacing={3}>
            <Typography variant="body2" color={color.grey[60]}>
              {i18n.arrival}
            </Typography>
            <Stack spacing={1}>
              <EllipsisTypography variant="h4" color={color.grey[100]} maxLines={2}>
                {toUnitName || '-'}
              </EllipsisTypography>
              {(toBedName || admissionDate) && (
                <Stack spacing={2} alignItems="center" direction="row" divider={<span>{'•'}</span>}>
                  {toBedName && (
                    <Typography variant="body2" color={color.grey[100]}>
                      {i18n.bed} : {toBedName}
                    </Typography>
                  )}
                  <Stack spacing={1} alignItems="center" direction="row">
                    <Clock />
                    <Typography variant="body2" color={color.grey[100]}>
                      {admissionDate ? format(admissionDate, 'dd/MM - HH:mm') : '-'}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </div>
  );
};
