import { memo } from 'react';
import { AccommodationAction } from '../PatientFlowKanban/VisitActions';
import AvailableBedActions from './LocationDetails/AvailableBed/AvailableBedActions';
import ClosedBedActions from './LocationDetails/ClosedBed/ClosedBedActions';
import AccommodationActions from './VisitDetails/Accommodation/AccommodationActions';
import MutationActions from './VisitDetails/Mutation/MutationActions';
import { StepItem } from './context/Step';

type StepActionsProps = {
  step: StepItem;
  setAction: (action: AccommodationAction) => void;
  disabled?: boolean;
};

const StepActions = ({ step, setAction, disabled }: StepActionsProps) => {
  switch (step.type) {
    case 'accommodation':
      return (
        <AccommodationActions
          onClick={setAction}
          accommodation={step.step}
          mutation={step.mutation}
          disabled={disabled}
        />
      );

    case 'mutation':
      return (
        <MutationActions
          onClick={setAction}
          accommodationRequest={step.step}
          origin={step.origin}
          destination={step.destination}
          disabled={disabled}
        />
      );

    case 'closedBed':
      return <ClosedBedActions accommodation={step.step} onClick={setAction} disabled={disabled} />;

    case 'availableBed':
      return <AvailableBedActions onClick={setAction} disabled={disabled} />;

    default:
      return null;
  }
};

export default memo(StepActions);
