import { Box, Divider, Grid } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { i18n } from '../../locales';
import { sortObjects } from '../../utils/array';
import SimpleTable, { SimpleTableColumn } from '../SimpleTable/SimpleTable';
import { TableListTab, TableListTabs } from './TableListTabs/TableListTabs';

type SortDirection = 'asc' | 'desc';

export type { TableListTab };

export type TableListColumn<Item extends Parse.JSONBaseAttributes> = SimpleTableColumn<Item> & {
  sortMethod?: (itemA: Item, itemB: Item) => number;
};

const getData = <Item extends Parse.JSONBaseAttributes>({
  data,
  filterMethod,
  sortMethod,
  sortDirection,
}: {
  data: Item[];
  filterMethod?: (item: Item) => boolean;
  sortMethod?: (itemA: Item, itemB: Item) => number;
  sortDirection: 'asc' | 'desc';
}) => {
  let displayedData = data;

  if (filterMethod) {
    displayedData = displayedData.filter(filterMethod);
  }

  if (sortMethod) {
    displayedData = displayedData.sort(sortObjects(sortMethod, sortDirection));
  }

  return displayedData;
};

export type TableListProps<Item extends Parse.JSONBaseAttributes> = {
  initialSortBy: string;
  isLoading?: boolean;
  columns: TableListColumn<Item>[];
  tabs?: TableListTab<Item>[];
  data: Item[];
  size?: 'small' | 'medium';
  onClickRow?: (object: Item) => void;
};

export const TableList = <Item extends Parse.JSONBaseAttributes>({
  initialSortBy,
  columns,
  isLoading,
  tabs,
  size,
  data,
  onClickRow,
}: TableListProps<Item>): ReactElement => {
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const handleChangeTabIndex = useCallback((index: number) => setTabIndex(index), []);

  const handleChangePage = useCallback((page: number) => setPage(page), []);

  const handleChangeLimit = useCallback((limit: number) => setLimit(limit), []);

  const handleChangeSortBy = useCallback((sortBy: string) => setSortBy(sortBy), []);

  const handleChangeSortDirection = useCallback((sortDirection: 'asc' | 'desc') => setSortDirection(sortDirection), []);

  const filteredAndSortedData = getData({
    data,
    filterMethod: tabs ? tabs[tabIndex].filterMethod : undefined,
    sortMethod: columns.find((column) => column.id === sortBy)?.sortMethod,
    sortDirection,
  });

  // si le nombre d'éléments à afficher est inférieur à la page on reset la pagination
  if (filteredAndSortedData.length < page * limit) {
    setPage(0);
  }

  const displayedData = filteredAndSortedData.slice(page * limit, page * limit + limit);

  return (
    <>
      {tabs && (
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <TableListTabs onChangeTabIndex={handleChangeTabIndex} tabIndex={tabIndex} tabs={tabs} />
          </Grid>
          <Grid item xs={12}>
            <Box paddingBottom={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
      )}
      <SimpleTable
        size={size}
        count={filteredAndSortedData.length}
        columns={columns as SimpleTableColumn<Parse.JSONBaseAttributes>[]}
        isLoading={isLoading}
        data={displayedData}
        page={page}
        limit={limit}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onClickRow={onClickRow as (object: Parse.JSONBaseAttributes) => void | undefined}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        onChangeSortBy={handleChangeSortBy}
        onChangeSortDirection={handleChangeSortDirection}
        labelNoData={tabs ? tabs[tabIndex].labelEmpty : i18n.noData}
      />
    </>
  );
};
