import { PatientGender } from '@ambuliz/sabri-core';
import { Box, Chip, Divider, IconButton, Stack, Typography } from '@mui/material';
import { color } from 'common/theme';
import { formatAge, getPatientAge, getPatientEmojiIcon } from 'common/utils';
import getEmojiSize from 'common/utils/getEmojiSize';
import { format } from 'date-fns';
import { i18n } from 'sabri/locales';
import Emoji from '../Emoji';
import { Cross } from '../Icons';
import HighlightedSearchResult from './HighlightedSearchResult';
import PatientStack from './PatientStack';

type PatientSummaryProps = {
  name: string;
  birthdate: Date;
  pan: string;
  ins?: string | undefined;
  ipp: string;
  gender: PatientGender;
  area?: {
    id: string;
    name: string;
  };
  unit?: {
    id: string;
    name: string;
  };
  withHighlight?: boolean;
  search?: string;
  primary?: boolean;
  large?: boolean;
  onRemove?: () => void;
  fromAutocomplete?: boolean;
};

const PatientSummary: React.FC<PatientSummaryProps> = ({
  name,
  pan,
  ipp,
  birthdate,
  gender,
  search = '',
  unit,
  area,
  ins,
  withHighlight,
  primary,
  large,
  fromAutocomplete,
  onRemove,
}) => {
  const age = getPatientAge(birthdate);

  return (
    <PatientStack large={large} primary={primary}>
      <Stack direction="row" justifyContent="space-between" gap={1} alignItems="flex-start">
        <Typography
          variant="h4"
          color={primary ? 'white' : color.grey[100]}
          fontWeight={600}
          lineHeight={`${getEmojiSize('normal')}px`}
        >
          <Emoji name={getPatientEmojiIcon(gender, age)} bgColor="white" inline />
          <span style={{ marginLeft: 8, wordBreak: 'break-all' }}>
            {withHighlight ? <HighlightedSearchResult text={name} search={search} /> : name}
          </span>
        </Typography>
        <Box paddingTop="2px">
          <Stack direction="row" gap={4} alignItems="center">
            <Chip
              sx={{ backgroundColor: primary ? color.blue[50] : undefined }}
              color={primary ? undefined : 'default'}
              variant={primary ? 'filled' : 'light'}
              label={
                <Typography variant="body2" fontWeight={600}>
                  {formatAge(birthdate) + ' '}
                  <Typography variant="body2" fontWeight={400} component="span">
                    ({birthdate ? format(birthdate, 'dd/MM/yyyy') : '-'})
                  </Typography>
                </Typography>
              }
            />
            {onRemove && (
              <IconButton onClick={onRemove} size="small" edge="end" sx={{ color: 'white' }}>
                <Cross />
              </IconButton>
            )}
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={3} divider={<Divider sx={{ opacity: primary ? 0.3 : 1 }} />}>
        <Stack spacing={3} direction="row" justifyContent={ins ? 'space-between' : undefined}>
          <Stack spacing={1} minWidth={109}>
            <Typography color={primary ? 'white' : 'textSecondary'}>{`${i18n.panShort} : `}</Typography>
            <Typography color={primary ? 'white' : color.grey[100]} fontWeight={primary ? 600 : 400}>
              {withHighlight ? <HighlightedSearchResult text={pan} search={search} /> : pan}
            </Typography>
          </Stack>
          <Stack spacing={1} minWidth={109}>
            <Typography color={primary ? 'white' : 'textSecondary'}>{`${i18n.ippShort} : `}</Typography>
            <Typography color={primary ? 'white' : color.grey[100]} fontWeight={primary ? 600 : 400}>
              {withHighlight ? <HighlightedSearchResult text={ipp} search={search} /> : ipp}
            </Typography>
          </Stack>
          <Stack spacing={1} flex={1}>
            {ins && (
              <>
                <Typography color={primary ? 'white' : 'textSecondary'}>{`${i18n.ins} : `}</Typography>
                <Typography color={primary ? 'white' : color.grey[100]} fontWeight={primary ? 600 : 400}>
                  {withHighlight ? <HighlightedSearchResult text={ins} search={search} /> : ins}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        {fromAutocomplete && (
          <Typography color={primary ? 'white' : 'textSecondary'}>
            {`${i18n.location} : `}
            <Typography color={primary ? 'white' : color.grey[100]} fontWeight={primary ? 600 : 400} component="span">
              {getLocationText(unit, area)}
            </Typography>
          </Typography>
        )}
      </Stack>
    </PatientStack>
  );
};

const getLocationText = (unit?: { id: string; name: string }, area?: { id: string; name: string }) => {
  return unit ? `${unit.name}${`, ${area?.name || '-'}`}` : '-';
};

export default PatientSummary;
