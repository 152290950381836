import { Box, Button, ButtonGroup, Skeleton, Stack } from '@mui/material';
import { color } from 'common/theme';

const SkeletonActions = () => {
  return (
    <>
      <ButtonGroup orientation="vertical" variant="action" disabled={true}>
        <SkeletonActionButton />
        <SkeletonActionButton />
        <SkeletonActionButton />
      </ButtonGroup>
    </>
  );
};

const SkeletonActionButton = () => (
  <Button>
    <Stack direction="row" spacing={3} alignItems="center">
      <Box borderRadius={1} bgcolor={color.grey[10]} width={28} height={28} />
      <Skeleton variant="rectangular" sx={{ borderRadius: 4, width: 145, height: 8 }} />
    </Stack>
  </Button>
);

export default SkeletonActions;
