import {
  Badge,
  Box,
  Checkbox,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Link,
  Popover,
  TextField,
  Typography,
  filledInputClasses,
} from '@mui/material';
import Autocomplete, { AutocompleteCloseReason, autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import { ChevronSmallDown, ChevronSmallUp } from 'common/components/Icons';
import CrossIcon from 'common/components/Icons/Cross';
import SearchIcon from 'common/components/Icons/Search';
import { color, theme } from 'common/theme';
import {
  FC,
  HTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { i18n } from 'sabri/locales';
import { AreaStored } from 'sabri/store/reducers/entity';
import styled from 'styled-components';

type MultipleSelectFilterProps = {
  options: AreaStored[];
  value: AreaStored[];
  onValueChanged: (value: AreaStored[]) => void;
};

const MultipleSelectFilter: FC<MultipleSelectFilterProps> = ({ options, value, onValueChanged }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <SelectButton open={open} count={value.length} onClick={(event) => handleClick(event)} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 0, vertical: -8 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Stack>
            <StyledAutocomplete
              multiple
              open
              PopperComponent={PopperComponent}
              ListboxComponent={ListboxComponent}
              value={value}
              onChange={(_, newValue) => {
                onValueChanged(newValue);
              }}
              disableCloseOnSelect
              disablePortal
              inputValue={inputValue}
              onInputChange={(event, value, reason) => {
                if (event && event.type === 'blur') {
                  setInputValue('');
                } else if (reason !== 'reset') {
                  setInputValue(value);
                }
              }}
              renderTags={() => null}
              noOptionsText={i18n.noPlaceFound}
              renderOption={(props, option, state) => [props, option, state.index] as ReactNode}
              getOptionLabel={(option) => option.name}
              options={options}
              onClose={(_, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  ref={params.InputProps.ref}
                  placeholder={i18n.search}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        {inputValue.length > 0 ? (
                          <IconButton onClick={() => setInputValue('')}>
                            <CrossIcon />
                          </IconButton>
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: '100%', padding: 3 }}
                />
              )}
            />
            {value.length > 0 && (
              <Stack padding={3} borderTop={`1px solid ${color.grey[10]}`} alignItems="flex-end">
                <Link component="button" onClick={() => onValueChanged([])}>
                  {i18n.clear}
                </Link>
              </Stack>
            )}
          </Stack>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

const SelectButton = ({
  open,
  count,
  onClick,
}: {
  open: boolean;
  count: number;
  onClick: (event: MouseEvent<HTMLElement>) => void;
}) => {
  const isHighlighted = open || count > 0;
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      padding="11px 8px 11px 12px"
      onClick={onClick}
      sx={{
        userSelect: 'none',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: isHighlighted ? color.blue[60] : color.grey[30],
        borderRadius: 1,
        color: isHighlighted ? color.blue[60] : color.grey[30],
        cursor: 'pointer',

        '&:hover': {
          borderColor: color.blue[60],
          '& .MuiSvgIcon-root': {
            color: color.blue[60],
          },
          '& .MuiTypography-root': {
            color: color.blue[60],
          },
        },
      }}
    >
      <Typography color={isHighlighted ? 'primary' : 'secondary'} fontWeight={count > 0 ? 600 : undefined}>
        {i18n.areaLabel}
      </Typography>
      {count > 0 && (
        <Box width={6} padding="0 4px 0 8px" display="flex" alignItems="center">
          <Badge badgeContent={count} color="primary" />
        </Box>
      )}
      {open ? (
        <ChevronSmallUp color={isHighlighted ? 'primary' : 'secondary'} />
      ) : (
        <ChevronSmallDown color={isHighlighted ? 'primary' : 'secondary'} />
      )}
    </Stack>
  );
};

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const isSelected = dataSet[0]['aria-selected'];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      <Checkbox checked={isSelected} sx={{ marginRight: 1 }} />
      {dataSet[1].name}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: ReactElement[] = [];
  (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = () => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 20) {
      return 20 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => getChildSize()}
          overscanCount={5}
          itemCount={itemCount}
          style={{ maxHeight: '52vh' }}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

type PopperComponentProps = {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
};

const PopperComponent = (props: PopperComponentProps) => {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
};

const StyledAutocomplete = styled(Autocomplete)`
  width: 250px;
  & .${filledInputClasses.root} .${filledInputClasses.input} {
    padding: 0 !important;
  }
  &.${autocompleteClasses.hasPopupIcon} {
    &.${autocompleteClasses.hasClearIcon} .${filledInputClasses.root} {
      padding-right: 12px;
    }
    & .${filledInputClasses.root} {
      padding-right: 12px;
    }
  }
  & .${autocompleteClasses.inputRoot} {
    flex-wrap: nowrap;
  }
` as typeof Autocomplete;

const StyledAutocompletePopper = styled.div`
  & .${autocompleteClasses.paper} {
    box-shadow: none;
    margin: 0;
    border: none;
    border-top: 1px solid ${color.grey[10]};
    border-radius: 0px 0px 8px 8px;
  }
  & .${autocompleteClasses.listbox} {
    padding: 0;
    & ul {
      margin: 0;
    }
    & .${autocompleteClasses.option} {
      padding: 12px;
      &.Mui-focused {
        background-color: ${color.blue[5]};
      }
      &[aria-selected='true'] {
        color: ${color.blue[60]};
        background-color: white;
        font-weight: 600;
        &.Mui-focused {
          background-color: ${color.blue[5]};
        }
      }
    }
  }
  &.${autocompleteClasses.popperDisablePortal} {
    position: relative;
  }
`;

export default MultipleSelectFilter;
