import { Button, CircularProgress, Stack } from '@mui/material';
import CrossIcon from 'common/components/Icons/Cross';
import PencilIcon from 'common/components/Icons/Pencil';
import SaveIcon from 'common/components/Icons/Save';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { useContext } from 'react';
import { DialogContext } from './context/DialogContext';
import { StepItem } from './context/Step';

type DialogEditButtonsProps = {
  currentStep?: StepItem;
};

const DialogEditButtons = ({ currentStep }: DialogEditButtonsProps) => {
  const { editMode, toggleEditMode, editModeLoading, submitEditableStep, isDirty } = useContext(DialogContext);

  const handleEditClick = () => {
    toggleEditMode(currentStep);
  };

  return (
    <Stack direction="row" alignItems="center" gap={3}>
      {editMode ? (
        <>
          {!editModeLoading && (
            <Button
              variant="rounded"
              color="secondary"
              startIcon={<CrossIcon />}
              onClick={() => handleEditClick()}
              disabled={editModeLoading}
              disableRipple
              sx={{
                backgroundColor: palette.background.default,
                border: `1px solid ${color.grey[10]}`,
              }}
              size="small"
            >
              {i18n.cancel}
            </Button>
          )}

          {isDirty() && (
            <Button
              variant="rounded"
              color="secondary"
              startIcon={editModeLoading ? <CircularProgress size={12} /> : <SaveIcon />}
              onClick={submitEditableStep}
              disabled={editModeLoading}
              disableRipple
              sx={{
                backgroundColor: palette.background.default,
                border: `1px solid ${color.grey[10]}`,
              }}
              size="small"
            >
              {i18n.saveAlt}
            </Button>
          )}
        </>
      ) : (
        <Button
          variant="rounded"
          color="secondary"
          startIcon={editModeLoading ? <CircularProgress size={12} /> : <PencilIcon />}
          onClick={() => handleEditClick()}
          disableRipple
          disabled={editModeLoading}
          sx={{
            backgroundColor: palette.background.default,
            border: `1px solid ${color.grey[10]}`,
          }}
          size="small"
        >
          {i18n.updateVerb}
        </Button>
      )}
    </Stack>
  );
};

export default DialogEditButtons;
