import { SvgIcon, SvgIconProps } from '@mui/material';

const DoubleArrow: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48809 9.56943C4.1736 9.839 3.70012 9.80257 3.43056 9.48808L0.570013 6.15078C0.249022 5.77629 0.249022 5.22368 0.570014 4.84919L3.43056 1.51189C3.70012 1.1974 4.1736 1.16098 4.48809 1.43054C4.80259 1.70011 4.83901 2.17359 4.56944 2.48808L2.63067 4.74999L9 4.74999C9.41421 4.74999 9.75 5.08577 9.75 5.49999C9.75 5.9142 9.41421 6.24999 9 6.24999L2.63067 6.24999L4.56944 8.51189C4.83901 8.82639 4.80259 9.29986 4.48809 9.56943Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5119 6.43057C11.8264 6.161 12.2999 6.19743 12.5694 6.51192L15.43 9.84922C15.751 10.2237 15.751 10.7763 15.43 11.1508L12.5694 14.4881C12.2999 14.8026 11.8264 14.839 11.5119 14.5695C11.1974 14.2999 11.161 13.8264 11.4306 13.5119L13.3693 11.25H6.5C6.08579 11.25 5.75 10.9142 5.75 10.5C5.75 10.0858 6.08579 9.75001 6.5 9.75001H13.3693L11.4306 7.48811C11.161 7.17361 11.1974 6.70014 11.5119 6.43057Z"
      />
    </SvgIcon>
  );
};

export default DoubleArrow;
