import { Box, Button, Stack, Typography } from '@mui/material';
import PlusIcon from 'common/components/Icons/Plus';
import { addMinutes } from 'date-fns';
import { ArrayField, useFormContext } from 'react-hook-form';
import { AreaAutoComplete } from 'sabri/components/AutoComplete/AreaAutoComplete';
import { UnitAutoComplete } from 'sabri/components/AutoComplete/UnitAutoComplete';
import { i18n } from 'sabri/locales';
import IsValidIcon from './IsValidIcon';
import { FormValue, StepFormValue, formKeys } from './JourneyForm';
import JourneyStep from './JourneyStep';

type JourneyStepsProps = {
  steps: Partial<ArrayField<StepFormValue, 'id'>>[];
  append: (value: Partial<StepFormValue>) => void;
  swap: (indexA: number, indexB: number) => void;
  remove: (index: number) => void;
  inProgressStepIndex?: number;
  startingPointDisabled?: boolean;
};

const JourneySteps = ({
  steps,
  append,
  swap,
  remove,
  startingPointDisabled = false,
  inProgressStepIndex,
}: JourneyStepsProps) => {
  const { watch, errors, setValue } = useFormContext<FormValue>();

  const handleSwapUp = (index: number) => {
    swap(index, index - 1);
  };

  const handleSwapDown = (index: number) => {
    swap(index, index + 1);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const fromUnitId = watch<string, string | undefined>(formKeys.fromUnitId);
  const isEmergency = watch<string, boolean | undefined>(formKeys.isEmergency);

  const handleAddStep = () => {
    if (isEmergency) {
      setValue(formKeys.isEmergency, false);
    }
    const previousStep = steps.length === 1 ? steps[0] : steps[steps.length - 1];
    append({
      toUnitId: '',
      toAreaId: '',
      wantedDate: addMinutes(previousStep.wantedDate || new Date(), 10),
    });
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Stack direction="row" gap={4}>
          <Box width={54} height={40} display="flex" alignItems="center">
            <Typography variant="overline">{i18n.departure}</Typography>
          </Box>
          <Stack direction="row" gap={4} flex={1} alignItems="center">
            <Stack direction="row" gap={2}>
              <UnitAutoComplete name={formKeys.fromUnitId} error={errors.fromUnitId} disabled={startingPointDisabled} />
              <AreaAutoComplete name={formKeys.fromAreaId} unitId={fromUnitId} disabled={startingPointDisabled} />
            </Stack>
            <Stack alignItems="flex-end" flex={1}>
              <IsValidIcon isVisible={!!fromUnitId && !startingPointDisabled} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {steps.map((step, index) => (
        <JourneyStep
          key={step.id}
          index={index}
          isInProgress={inProgressStepIndex === index}
          onSwapDown={handleSwapDown}
          onSwapUp={handleSwapUp}
          onRemove={handleRemove}
          hideActions={steps.length === 1}
        />
      ))}
      <Button
        style={{ alignSelf: 'flex-start', marginLeft: 74 }}
        variant="text"
        color="primary"
        onClick={handleAddStep}
        startIcon={<PlusIcon />}
      >
        {i18n.addJourneyStep}
      </Button>
    </Stack>
  );
};

export default JourneySteps;
