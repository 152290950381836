import { SvgIcon, SvgIconProps } from '@mui/material';

const Admission: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M6.86466 2.5C6.54492 2.5 6.28571 2.7592 6.28571 3.07895V3.07895C6.28571 3.39869 6.54492 3.65789 6.86466 3.65789H9.13534C9.45508 3.65789 9.71429 3.39869 9.71429 3.07895V3.07895C9.71429 2.7592 9.45508 2.5 9.13534 2.5H6.86466ZM8 4.23684C4.40171 4.23684 1.47086 7.03895 1.17829 10.6053H1C0.447716 10.6053 0 11.053 0 11.6053V12.5C0 13.0523 0.447715 13.5 1 13.5H15C15.5523 13.5 16 13.0523 16 12.5V11.6053C16 11.053 15.5523 10.6053 15 10.6053H14.8211C14.5297 7.03895 11.5983 4.23684 8 4.23684ZM8 5.39474C9.41811 5.38687 10.7877 5.91745 11.8388 6.88191C12.89 7.84637 13.5465 9.17475 13.6789 10.6053H2.32171C2.60286 7.66074 5.01314 5.39474 8 5.39474ZM1.14286 12.0526C1.14286 11.8928 1.27246 11.7632 1.43233 11.7632H14.5677C14.7275 11.7632 14.8571 11.8928 14.8571 12.0526V12.0526C14.8571 12.2125 14.7275 12.3421 14.5677 12.3421H1.43233C1.27246 12.3421 1.14286 12.2125 1.14286 12.0526V12.0526Z" />
    </SvgIcon>
  );
};

export default Admission;
