import { Area } from '@ambuliz/sabri-core';
import ExpandMoreIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import { Breadcrumbs, CircularProgress, IconButton, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import useParseResource from 'common/hooks/useParseResource';
import { useBreadcrumb } from './useBreadcrumb';

type SelectedAreaProps = {
  areaId: string;
  onDelete: (id: string) => void;
};

export const SelectedArea = ({ areaId, onDelete }: SelectedAreaProps) => {
  const { resource: area, isLoading } = useParseResource(areaId, Area);

  const { areas, isLoading: isBreadcrumbLoading } = useBreadcrumb({ areaId });

  const handleDelete = () => {
    onDelete(areaId);
  };

  if (isLoading || isBreadcrumbLoading) {
    return (
      <Paper elevation={0} variant="outlined">
        <Stack direction="row" alignItems="center" justifyContent="center" p={3}>
          <CircularProgress size={20} />
        </Stack>
      </Paper>
    );
  }

  if (!area) {
    return <div>Not found</div>;
  }

  return (
    <Paper elevation={0} variant="outlined">
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} p={2}>
        <Breadcrumbs separator={<ExpandMoreIcon />}>
          {areas.map((area) => (
            <Typography key={area.id}>{area.name}</Typography>
          ))}
        </Breadcrumbs>
        <IconButton onClick={handleDelete} color="error">
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
};
