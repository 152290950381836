import { AccommodationSpecificity, isIsolatedSpecificity } from '@ambuliz/sabri-core';
import { palette, color as themeColor, transition } from 'common/theme';
import styled from 'styled-components';

export type CardColors = 'success' | 'primary' | 'warning' | 'danger' | 'error' | 'secondary' | 'primaryAlt';

const BACKGROUND_COLOR: Readonly<Record<CardColors, string>> = {
  success: themeColor.green[20],
  primary: themeColor.blue[20],
  warning: themeColor.yellow[20],
  danger: themeColor.orange[20],
  error: themeColor.red[20],
  secondary: themeColor.grey[20],
  primaryAlt: themeColor.lightblue[20],
};

export const LIGHT_BACKGROUND_COLOR: Readonly<Record<CardColors, string>> = {
  success: themeColor.green[10],
  primary: themeColor.blue[5],
  warning: themeColor.yellow[10],
  danger: themeColor.orange[10],
  error: themeColor.red[10],
  secondary: themeColor.grey[10],
  primaryAlt: themeColor.lightblue[20],
};

const STRIPE_COLOR: Readonly<Record<CardColors, string>> = {
  success: themeColor.green[30],
  primary: '#C4D1FF',
  warning: '#FBE0AC',
  danger: '#F8C4B1',
  error: '#FFB5B5',
  secondary: '#B5B9C9',
  primaryAlt: '#A0D7F4',
};

export const BORDER_COLOR: Readonly<Record<CardColors, string>> = {
  success: themeColor.green[60],
  primary: themeColor.blue[30],
  warning: themeColor.yellow[70],
  danger: themeColor.orange[50],
  error: themeColor.red[60],
  secondary: themeColor.grey[60],
  primaryAlt: themeColor.lightblue[60],
};

export const getCardColor = (specificities: AccommodationSpecificity[] = []) => {
  if (specificities.includes('COVID')) {
    return 'error';
  } else if (specificities.some((specificity) => isIsolatedSpecificity(specificity))) {
    return 'danger';
  } else if (specificities.includes('ACCOMMODATED')) {
    return 'warning';
  }

  return 'primary';
};

export const getCardStripes = (color: CardColors | string) => `linear-gradient(
  135deg,
  ${BACKGROUND_COLOR[color as CardColors] || color} 25%,
  ${STRIPE_COLOR[color as CardColors] || color} 25%,
  ${STRIPE_COLOR[color as CardColors] || color} 50%,
  ${BACKGROUND_COLOR[color as CardColors] || color} 50%,
  ${BACKGROUND_COLOR[color as CardColors] || color} 75%,
  ${STRIPE_COLOR[color as CardColors] || color} 75%,
  ${STRIPE_COLOR[color as CardColors] || color} 100%
)`;

export const getInfoTextColor = (color: CardColors = 'primary') =>
  color === 'primary' ? themeColor.blue[50] : palette[color].dark;

export const CardBackground = styled.div<{
  color?: CardColors | string;
  width?: number | string;
  $striped: boolean;
  $borderLeft?: boolean;
  $borderRight?: boolean;
  height?: number | string;
  onClick?: unknown;
}>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : undefined)};
  position: ${({ width }) => (width === undefined ? 'relative' : 'absolute')};
  border-radius: 4px;
  overflow: hidden;
  will-change: width;
  transition: ${transition};
  box-sizing: border-box;
  outline: 2px solid white;
  background: ${({ color }) => BACKGROUND_COLOR[color as CardColors] || color};
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  width: ${({ width }) => (width === undefined ? '100%' : `${width}px`)};

  ${({ $striped, color }) => $striped && color && `background-image: ${getCardStripes(color)};`}
  background-size: ${({ width }) =>
    typeof width !== 'string' ? (width && width <= 48 ? `48px 80px` : '80px 80px') : width};

  ${({ $borderLeft, color }) =>
    $borderLeft &&
    `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    transition: all 0.2s ease-in-out;
    background: ${BORDER_COLOR[color as CardColors] || color};
  }`}

  ${({ $borderRight, color }) =>
    $borderRight &&
    `
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    transition: all 0.2s ease-in-out;
    background: ${BORDER_COLOR[color as CardColors] || color};
  }`}
`;
