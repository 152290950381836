import { AnomalyType } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { palette } from 'common/theme';
import { format, isValid } from 'date-fns';
import BedAnomaliesTooltip from 'kurt/components/AnomaliesTooltips/BedAnomaliesTooltip';

const MovementDate = ({
  date,
  anomalies,
}: {
  date?: Date;
  anomalies?: { type: AnomalyType; accommodation?: { id: string } }[];
}) => {
  return !!anomalies?.length ? (
    <BedAnomaliesTooltip anomalies={anomalies}>
      <Stack direction="row" alignItems="center" spacing={1} alignSelf="flex-start">
        <Emoji name="warning" size={14} />
        <Typography fontWeight={600} color={palette.warning.main} variant="body2">
          {date && isValid(date) ? format(date, 'dd/MM - HH:mm') : '-'}
        </Typography>
      </Stack>
    </BedAnomaliesTooltip>
  ) : (
    <Typography fontWeight={600} color="secondary" variant="body2">
      {date && isValid(date) ? format(date, 'dd/MM - HH:mm') : '-'}
    </Typography>
  );
};

export default MovementDate;
