import { Button, ButtonGroup } from '@mui/material';
import { Emoji } from 'common/components';
import ChevronSmallRightIcon from 'common/components/Icons/ChevronSmallRight';
import { i18n } from 'common/locale';

import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';

type AvailableBedActionsProps = {
  onClick: (action: AccommodationAction) => void;
  disabled?: boolean;
};

const AvailableBedActions = ({ onClick, disabled }: AvailableBedActionsProps) => {
  return (
    <>
      <ButtonGroup orientation="vertical" variant="action" disabled={disabled}>
        <Button
          startIcon={<Emoji name="calendar" size="extra-small" />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('ADD_ACCOMMODATION')}
        >
          {i18n.visitActions.ADD_ACCOMMODATION.action.label}
        </Button>

        <Button
          startIcon={<Emoji name="constructionOutlined" size="extra-small" />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('CLOSE_BED')}
        >
          {i18n.visitActions.CLOSE_BED.action.label}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default AvailableBedActions;
