import { Stack, Typography } from '@mui/material';
import { palette } from 'common/theme';
import { format } from 'date-fns';

const AppointmentDate = ({ date }: { date: Date }) => (
  <Stack>
    <Typography variant="body2" color={palette.secondary.main}>
      {format(date, 'dd/MM/yyyy')}
    </Typography>
    <Typography variant="h4">{format(date, 'HH:mm')}</Typography>
  </Stack>
);

export default AppointmentDate;
