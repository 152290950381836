import { Chip, ChipProps } from '@mui/material';
import CalendarIcon from 'common/components/Icons/Calendar';
import CalendarDayIcon from 'common/components/Icons/CalendarDay';
import ExitIcon from 'common/components/Icons/Exit';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';

export type DischargeStatus = 'ESTIMATED' | 'VALIDATED' | 'COMPLETED';
type Color = keyof Pick<typeof palette, 'warning' | 'success' | 'secondary'>;

export type DischargeStatusChipProps = {
  status: DischargeStatus;
} & Omit<ChipProps, 'label'>;

const properties: Record<DischargeStatus, { label: string; color: Color; icon: React.ReactElement }> = {
  ESTIMATED: {
    label: i18n.previsionalDischargeShort,
    color: 'warning',
    icon: <CalendarIcon />,
  },
  VALIDATED: {
    label: i18n.validatedDischargeShort,
    color: 'success',
    icon: <CalendarDayIcon />,
  },
  COMPLETED: {
    label: i18n.dischargedPatient,
    color: 'secondary',
    icon: <ExitIcon />,
  },
};

const DischargeStatusChip = ({ status, ...props }: DischargeStatusChipProps) => (
  <Chip
    color={properties[status].color}
    label={properties[status].label}
    icon={properties[status].icon}
    variant="light"
    {...props}
  />
);

export default DischargeStatusChip;
