import { ChevronRight } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem } from '@mui/material';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useAuthentication } from 'core/authentication';
import { ViaTrajectoireLink, ViaTrajectoireLoader } from '../PatientOutcome/ViaTrajectoireLink';
import { AccommodationAction } from './VisitActions';
import { menuActionIcons } from './VisitActions/columnActions';

const externalActions = ['VIA_TRAJECTOIRE_REDIRECT'];

const extractExternalActions = (actions: AccommodationAction[]) => {
  const extractedActions = [...actions].filter((action) => externalActions.includes(action));
  actions = actions.filter((action) => !extractedActions.includes(action));
  return { actions, externalActions: extractedActions };
};

type AccommodationCardMenuProps = {
  open: boolean;
  onClose: () => void;
  actions: AccommodationAction[];
  onActionClick: (action: AccommodationAction) => void;
  anchorEl: HTMLElement | null;
  visitId?: string;
};

const AccommodationCardMenu: React.FC<AccommodationCardMenuProps> = ({
  open,
  onClose,
  anchorEl,
  actions: inputActions,
  onActionClick,
  visitId,
}) => {
  const {
    healthCenter: { viaTrajectoireEnabled },
  } = useAuthentication();
  if (inputActions.length <= 0) {
    return null;
  }
  const { actions, externalActions } = extractExternalActions(inputActions);
  const mainAction = actions[0];
  const secondaryActions = actions.slice(1, actions.length - 1);
  const lastAction = actions.length > 1 ? actions[actions.length - 1] : undefined;

  if (!viaTrajectoireEnabled) {
    externalActions.splice(externalActions.indexOf('VIA_TRAJECTOIRE_REDIRECT'), 1);
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem
        onClick={() => onActionClick(mainAction)}
        style={{ color: mainAction !== 'CANCEL_ACCOMMODATION' ? palette.secondary.dark : palette.error.main }}
      >
        <ListItemIcon style={{ color: mainAction !== 'CANCEL_ACCOMMODATION' ? undefined : palette.error.main }}>
          {menuActionIcons[mainAction]}
        </ListItemIcon>
        <ListItemText>{i18n.visitActions[mainAction].action.label}</ListItemText>
      </MenuItem>
      {(secondaryActions.length > 0 || lastAction) && <Divider />}
      {(secondaryActions.length > 0 || lastAction) && <ListSubheader>{i18n.moreActions}</ListSubheader>}
      {secondaryActions.map((action, index) => (
        <MenuItem onClick={() => onActionClick(action)} key={action}>
          <ListItemIcon>{menuActionIcons[action]}</ListItemIcon>
          <ListItemText>{i18n.visitActions[action].action.label}</ListItemText>
        </MenuItem>
      ))}
      {lastAction && (
        <MenuItem onClick={() => onActionClick(lastAction)}>
          <ListItemIcon style={{ color: palette.error.main }}>{menuActionIcons[lastAction]}</ListItemIcon>
          <ListItemText style={{ color: palette.error.main }}>
            {i18n.visitActions[lastAction].action.label}
          </ListItemText>
        </MenuItem>
      )}
      {externalActions?.length > 0 && (
        <>
          <Divider />
          <ListSubheader>{i18n.externalActions}</ListSubheader>
          {externalActions?.map(
            (action) =>
              action === 'VIA_TRAJECTOIRE_REDIRECT' && (
                <ViaTrajectoireLink
                  openOnHover
                  visitId={visitId}
                  loader={
                    <MenuItem>
                      <ViaTrajectoireLoader textMarginLeft={3} />
                    </MenuItem>
                  }
                  button={
                    <MenuItem>
                      <ListItemIcon>{menuActionIcons['VIA_TRAJECTOIRE_REDIRECT']}</ListItemIcon>
                      <ListItemText>{i18n.visitActions['VIA_TRAJECTOIRE_REDIRECT'].action.label}</ListItemText>
                      <ChevronRight style={{ marginLeft: 8 }} color="action" />
                    </MenuItem>
                  }
                />
              )
          )}
        </>
      )}
    </Menu>
  );
};

export default AccommodationCardMenu;
