import { SvgIcon, SvgIconProps } from '@mui/material';

const Plus: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1a.75.75 0 0 1 .75.75l.01 12.5a.75.75 0 0 1-1.5 0l-.01-12.5A.75.75 0 0 1 8 1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 0 1.5H1.75A.75.75 0 0 1 1 8Z"
      />
    </SvgIcon>
  );
};

export default Plus;
