import Api, { Area, Unit } from '@ambuliz/sabri-core';

export const getUnitsWithAreas = async (unitIds: string[]) =>
  new Parse.Query(Unit)
    .select('name', 'areas')
    .include('areas')
    .containedIn('objectId', unitIds)
    .findAll({ batchSize: 500 });

export const getAreasDirectChildren = async (areas: Area[]) =>
  new Api.Query(Area).containedIn('parent', areas).findAll({ batchSize: 500 });
