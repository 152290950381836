import { Stack, Typography } from '@mui/material';
import ProcessingIcon from 'common/components/Icons/Processing';
import { i18n } from 'sabri/locales';

const InProgressStepIndicator = () => {
  return (
    <Stack gap={1} direction="row" alignItems="center" style={{ marginLeft: 70 }}>
      <ProcessingIcon sx={{ fontSize: 12 }} color="secondary" />
      <Typography color="secondary" variant="body2">
        {i18n.inProgressStepIndicatorText}
      </Typography>
    </Stack>
  );
};

export default InProgressStepIndicator;
