import { addDays } from 'date-fns';
import { RefObject, createContext, createRef } from 'react';
import { TimelineBackgroundVariant } from './TimelineBackground';

export const ROW_HEADER_WIDTH = 160;
export const DEFAULT_SCALE = 130;

export type Period = 'week' | 'day';

type TimelineContextProps = {
  start: Date;
  end: Date;
  scale: number;
  period: Period;
  rowHeaderWidth: number;
  container: RefObject<HTMLElement>;
  variant: TimelineBackgroundVariant;
};

const TimelineContext = createContext<TimelineContextProps>({
  start: new Date(),
  end: addDays(new Date(), 7),
  scale: DEFAULT_SCALE,
  period: 'week',
  rowHeaderWidth: ROW_HEADER_WIDTH,
  container: createRef(),
  variant: 'card',
});

export default TimelineContext;
