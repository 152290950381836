import { AccommodationStatus } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Button, Link, Stack, Typography } from '@mui/material';
import { DateTimeInput } from 'common/components/Form';
import { i18n } from 'common/locale';
import { isBefore, isSameDay, isValid } from 'date-fns';
import { useState } from 'react';
import { ActionProps } from '../ActionForm';

type UpdateDateProps = ActionProps & {
  dischargeDate?: Date;
  admissionDate?: Date;
  action:
    | 'DISCHARGE_PATIENT'
    | 'VALIDATE_DISCHARGE_DATE'
    | 'UPDATE_PLANNED_DISCHARGE_DATE'
    | 'UPDATE_VALIDATED_DISCHARGE_DATE'
    | 'INVALIDATE_DISCHARGE_DATE';
};

const estimatedDischargeDateActions = ['UPDATE_PLANNED_DISCHARGE_DATE', 'INVALIDATE_DISCHARGE_DATE'];

const UpdateDate: React.FC<UpdateDateProps> = ({
  admissionDate,
  dischargeDate,
  action,
  loading = false,
  onCancel,
  onSubmit,
}) => {
  const [date, setDate] = useState(dischargeDate || null);
  const [error, setError] = useState(false);

  const handleDateChange = (value: Date | null) => {
    setDate(value);
    setError(false);
  };

  const now = new Date();

  const handleSubmit = async () => {
    const error = !date || !isValid(date) || (admissionDate && isBefore(date, admissionDate));

    if (error) {
      setError(error);
    } else {
      onSubmit({
        isEndEstimated: estimatedDischargeDateActions.includes(action),
        endAt: date,
        status: getStatus(action, date, dischargeDate),
      });
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography color="secondary">{i18n.visitActions[action].form.inputLabel}</Typography>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <DateTimeInput
              onChange={handleDateChange}
              value={date}
              error={error}
              disabled={loading}
              noRange
              maxDate={action === 'DISCHARGE_PATIENT' ? now : undefined}
              minDate={admissionDate}
            />
            <Link component="button" onClick={() => handleDateChange(now)} type="button">
              <Typography variant="body2" color="primary">
                {i18n.dateNow}
              </Typography>
            </Link>
          </Stack>
          {error && (
            <Typography variant="body2" color="error">
              {i18n.addAccommodationModal.errors.end}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton onClick={handleSubmit} loading={loading} disabled={loading}>
          {i18n.visitActions[action].form.confirmLabel}
        </LoadingButton>
      </Stack>
    </>
  );
};

const getStatus = (
  action: UpdateDateProps['action'],
  newDate?: Date | null,
  initialDate?: Date | null
): AccommodationStatus | undefined => {
  if (!newDate || !initialDate) {
    return undefined;
  }
  if (action === 'DISCHARGE_PATIENT') {
    if (isSameDay(newDate, initialDate) || isBefore(newDate, initialDate)) {
      return 'COMPLETED';
    } else {
      return 'IN_PROGRESS';
    }
  }
  if (action === 'UPDATE_VALIDATED_DISCHARGE_DATE') {
    return 'IN_PROGRESS';
  }
  return undefined;
};

export default UpdateDate;
