import Api, { Accommodation, AccommodationStatus, Anomaly, Area, Practitioner, Unit } from '@ambuliz/sabri-core';
import { useBedsFromUnit } from 'core/locations/useBedsFromUnit';
import { useEffect, useState } from 'react';

export type PractitionerQueryParams = {
  unitIds: string[];
  startAt?: DateParam;
  endAt?: DateParam;
  status?: AccommodationStatus;
  hasBed?: boolean;
  hasResponsibleUnit?: boolean;
};

type UsePractitionerOptionsParams = PractitionerQueryParams & { enabled?: boolean };

const usePractitionerOptions = ({
  unitIds,
  enabled = false,
  startAt,
  endAt,
  status,
  hasBed = false,
  hasResponsibleUnit = false,
}: UsePractitionerOptionsParams) => {
  const [practitionerOptions, setPractitionerOptions] = useState<Practitioner[]>([]);
  const [loading, setLoading] = useState(false);

  // Hack to fetch beds only the first time the query is enabled
  const [isBedsQueryEnabled, setIsBedsQueryEnabled] = useState(hasBed && enabled);
  useEffect(() => setIsBedsQueryEnabled((previous) => previous || (hasBed && enabled)), [hasBed, enabled]);

  const { beds, isLoading: isBedsQueryLoading } = useBedsFromUnit(unitIds, { enabled: isBedsQueryEnabled });

  useEffect(() => {
    const fetchPractitionerOptions = async () => {
      setLoading(true);
      const bedIds = beds.map((bed) => bed.id);

      const [accommodations, anomalies] = await Promise.all([
        findAccommodations({ unitIds, startAt, endAt, status, bedIds, hasResponsibleUnit }),
        findAnomalies({ startAt, endAt, areaIds: bedIds }),
      ]);

      setPractitionerOptions(getPractitionerOptions(accommodations, anomalies));
      setLoading(false);
    };

    if (enabled && !isBedsQueryLoading) {
      fetchPractitionerOptions();
    }
  }, [beds, unitIds, enabled, startAt, endAt, status, hasResponsibleUnit, isBedsQueryLoading]);

  return { practitionerOptions, loading, beds };
};

type DateParam = {
  greaterThanOrEqualTo?: Date;
  lessThanOrEqualTo?: Date;
  greaterThan?: Date;
  lessThan?: Date;
};

const findAccommodations = async ({
  unitIds,
  startAt,
  endAt,
  status,
  bedIds,
  hasResponsibleUnit,
}: PractitionerQueryParams & { bedIds: string[] }) => {
  const query = buildAccommodationQuery({ status, bedIds, startAt, endAt }).containedIn(
    'unit',
    unitIds.map((id) => Unit.createWithoutData(id))
  );

  const [accommodations, accommodationsFromResponsibleUnit] = await Promise.all([
    query.findAll(),
    findAccommodationsFromResponsibleUnit({ unitIds, endAt, startAt, hasResponsibleUnit }),
  ]);

  return [...accommodations, ...accommodationsFromResponsibleUnit];
};

const buildAccommodationQuery = ({
  status,
  bedIds,
  startAt,
  endAt,
}: Omit<PractitionerQueryParams, 'unitIds' | 'hasResponsibleUnit' | 'hasBed'> & { bedIds: string[] }) => {
  const query = new Api.Query(Accommodation).exists('visit').exists('practitioners');

  if (status) {
    query.equalTo('status', status);
  } else {
    query.notEqualTo('status', 'CANCELLED');
  }

  if (bedIds.length > 0) {
    query.containedIn(
      'bed',
      bedIds.map((id) => Area.createWithoutData(id))
    );
  }

  if (startAt) {
    getDateQueryParam(query, startAt, 'startAt');
  }

  if (endAt) {
    getDateQueryParam(query, endAt, 'endAt');
  }

  return query;
};

const findAccommodationsFromResponsibleUnit = async ({
  unitIds,
  endAt,
  startAt,
  hasResponsibleUnit,
}: Omit<PractitionerQueryParams, 'bedIds' | 'status' | 'hasBed'>) => {
  if (!hasResponsibleUnit) {
    return [];
  }
  const query = buildAccommodationQuery({ bedIds: [], startAt, endAt })
    .containedIn(
      'responsibleUnit',
      unitIds.map((id) => Unit.createWithoutData(id))
    )
    .notContainedIn(
      'unit',
      unitIds.map((id) => Unit.createWithoutData(id))
    )
    .exists('bed');

  const accommodations = await query.findAll();
  return accommodations;
};

const getDateQueryParam = (
  query: Parse.Query<Accommodation | Anomaly>,
  dateParam: DateParam,
  key: 'startAt' | 'endAt'
) => {
  if (dateParam.greaterThanOrEqualTo) {
    query.greaterThanOrEqualTo(key, dateParam.greaterThanOrEqualTo);
  } else if (dateParam.greaterThan) {
    query.greaterThan(key, dateParam.greaterThan);
  }

  if (dateParam.lessThanOrEqualTo) {
    query.lessThanOrEqualTo(key, dateParam.lessThanOrEqualTo);
  } else if (dateParam.lessThan) {
    query.lessThan(key, dateParam.lessThan);
  }

  return query;
};

const findAnomalies = async ({
  startAt,
  endAt,
  areaIds,
}: {
  startAt?: DateParam;
  endAt?: DateParam;
  areaIds: string[];
}) => {
  if (areaIds.length === 0 || (!startAt && !endAt)) {
    return [];
  }

  const areas = areaIds.map((id) => Area.createWithoutData(id));

  const withEndQuery = new Api.Query(Anomaly).containedIn('area', areas).equalTo('status', 'OPENED').exists('endAt');

  const withoutEndQuery = new Api.Query(Anomaly)
    .containedIn('area', areas)
    .equalTo('status', 'OPENED')
    .doesNotExist('endAt');

  if (startAt) {
    getDateQueryParam(withEndQuery, startAt, 'startAt');
    getDateQueryParam(withoutEndQuery, startAt, 'startAt');
  }
  if (endAt) {
    getDateQueryParam(withEndQuery, endAt, 'endAt');
  }

  const anomalies = await Api.Query.or(withEndQuery, withoutEndQuery).include('accommodation').findAll();

  return anomalies;
};

const getPractitionerOptions = (accommodations: Accommodation[], anomalies: Anomaly[]) => {
  const practitioners = accommodations.flatMap((accommodation) => accommodation.practitioners) as Practitioner[];
  for (const anomaly of anomalies) {
    if (anomaly.accommodation && anomaly.accommodation.practitioners) {
      practitioners.push(...anomaly.accommodation.practitioners);
    }
  }

  const uniquePractitioners: Practitioner[] = practitioners.reduce((unique: Practitioner[], practitioner) => {
    if (practitioner) {
      const existingPractitioner = unique.find((p) => {
        if (practitioner.references?.practitioner && p.references?.practitioner) {
          return p.references?.practitioner === practitioner.references?.practitioner;
        } else {
          return p.name.toLowerCase() === practitioner.name.toLowerCase();
        }
      });
      if (!existingPractitioner) {
        unique.push(practitioner);
      }
    }
    return unique;
  }, []);

  uniquePractitioners.sort((a, b) => (a.name > b.name ? 1 : -1));

  return uniquePractitioners;
};

export default usePractitionerOptions;
