import { ExternalPatientTransport } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { color, palette } from 'common/theme';
import { format } from 'date-fns';

type ExternalTransportStartAtHistoryProps = {
  transport?: ExternalPatientTransport;
};

export const ExternalTransportStartAtHistory = ({ transport }: ExternalTransportStartAtHistoryProps) => {
  const startAtHistory =
    transport?.startAtHistory?.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()).slice(0, 1) || [];

  const lastHistory = startAtHistory[0];

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {lastHistory?.isUpdatedAfterValidation && (
        <Stack direction="column-reverse" alignSelf="flex-end">
          <Emoji name="bellOrange" size="extra-small" />
        </Stack>
      )}
      <Stack direction="column-reverse">
        {transport && startAtHistory.length ? (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="body2"
                color={lastHistory?.isUpdatedAfterValidation ? palette.danger.main : palette.text.primary}
                fontWeight={lastHistory?.isUpdatedAfterValidation ? 600 : 400}
                noWrap
              >
                {format(transport.startAt, 'dd/MM - HH:mm')}
              </Typography>
            </Stack>
            {lastHistory && (
              <Typography
                variant="body2"
                color={color.grey[30]}
                fontWeight={400}
                noWrap
                sx={{
                  textDecorationLine: 'line-through',
                }}
              >
                {format(lastHistory.startAt, 'dd/MM - HH:mm')}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body2" color={palette.text.primary} fontWeight={400} noWrap>
            {!!transport && format(transport?.startAt, 'dd/MM - HH:mm')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
