import { SvgIcon, SvgIconProps } from '@mui/material';

const Hashtag: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 13 12">
      <path
        fillRule="evenodd"
        d="M9.88666 1.00679C9.93297 0.752078 9.76403 0.508054 9.50933 0.461744C9.25462 0.415433 9.0106 0.584373 8.96428 0.83908L8.44685 3.685H5.70742L6.19436 1.00679C6.24067 0.752078 6.07174 0.508054 5.81703 0.461744C5.56232 0.415433 5.3183 0.584373 5.27199 0.83908L4.75455 3.685H1.78702C1.52813 3.685 1.31827 3.89486 1.31827 4.15375C1.31827 4.41263 1.52813 4.6225 1.78702 4.6225H4.58409L4.08325 7.37714H1.11779C0.858907 7.37714 0.64904 7.587 0.64904 7.84589C0.64904 8.10477 0.858907 8.31464 1.11779 8.31464H3.91279L3.42583 10.9929C3.37952 11.2476 3.54846 11.4917 3.80317 11.538C4.05788 11.5843 4.3019 11.4153 4.34821 11.1606L4.86566 8.31464H7.60509L7.11813 10.9929C7.07182 11.2476 7.24076 11.4917 7.49547 11.538C7.75017 11.5843 7.9942 11.4153 8.04051 11.1606L8.55796 8.31464H11.5255C11.7844 8.31464 11.9942 8.10477 11.9942 7.84589C11.9942 7.587 11.7844 7.37714 11.5255 7.37714H8.72842L9.22926 4.6225H12.1947C12.4536 4.6225 12.6635 4.41263 12.6635 4.15375C12.6635 3.89486 12.4536 3.685 12.1947 3.685H9.39972L9.88666 1.00679ZM7.77555 7.37714L8.27639 4.6225H5.53696L5.03612 7.37714H7.77555Z"
      />
    </SvgIcon>
  );
};

export default Hashtag;
