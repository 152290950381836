import { ReactNode, createContext, useEffect, useState } from 'react';
import { Area, NormalizedArea } from 'superadmin/types';
import AreaNormalizer from './AreaNormalizer';

type AreaContextValue = {
  areas: Record<string, NormalizedArea>;
  area?: Area;
  areaById: (id: string) => Area;
  unsetArea: (id: string) => void;
  setArea: (area: Area) => void;
  setMultipleAreas: (areas: Area[]) => void;
  getBreadcrumb: (id: string) => string[];
  loading: boolean;
};

const getBreadcrumb = (area: NormalizedArea, areas: Record<string, NormalizedArea>): string[] => {
  let crumbs: string[] = [];
  if (area) {
    crumbs.push(area.id);
    if (area.parent) {
      crumbs = [...getBreadcrumb(areas[area.parent], areas), ...crumbs];
    }
  }
  return crumbs;
};
const AreaContext = createContext({} as AreaContextValue);

const AreaContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [areas, setAreas] = useState<Record<string, NormalizedArea>>({});
  const [loading, setLoading] = useState(true);

  const initFetch = async () => {
    setLoading(true);
    setLoading(false);
  };

  useEffect(() => {
    initFetch();
  }, []);

  return (
    <AreaContext.Provider
      value={{
        areas,
        area: AreaNormalizer.denormalize(areas),
        areaById: (id) => areas[id],
        setArea: (area: Area) => setAreas({ ...areas, [area.id]: area }),
        unsetArea: (id: string) => {
          setAreas((areas) => {
            const state = { ...areas };
            delete state[id];
            return state;
          });
        },
        setMultipleAreas: (areasToAdd: Area[]) =>
          setAreas({ ...areas, ...areasToAdd.reduce((acc, area) => ({ ...acc, [area.id]: area }), {}) }),
        getBreadcrumb: (id) => {
          return getBreadcrumb(areas[id], areas);
        },
        loading,
      }}
    >
      {children}
    </AreaContext.Provider>
  );
};

export default AreaContext;
export { AreaContextProvider };
