import { Stack, SvgIconProps, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { ReactNode } from 'react';

export const DETAILS_ROW_MIN_HEIGHT = 32;
export const DETAILS_ROW_WIDTH = 200;

type DetailsRowProps = {
  Icon?: React.FC<SvgIconProps>;
  label: string;
  value?: ReactNode;
  orientation?: 'horizontal' | 'vertical';
};

const DetailsRow = ({ label, value, Icon, orientation = 'horizontal' }: DetailsRowProps) => {
  return (
    <Stack
      spacing={orientation === 'horizontal' ? 2 : 4}
      direction={orientation === 'horizontal' ? 'row' : 'column'}
      alignItems={orientation === 'horizontal' ? 'flex-start' : undefined}
    >
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        width={DETAILS_ROW_WIDTH}
        minHeight={DETAILS_ROW_MIN_HEIGHT}
        flexShrink={0}
      >
        {Icon && <Icon color="secondary" fontSize="small" />}
        <Typography variant="body2" fontWeight={600}>
          {label}
        </Typography>
      </Stack>
      {!!value ? (
        typeof value === 'string' ? (
          <Typography variant="body2" padding={2}>
            {value}
          </Typography>
        ) : (
          value
        )
      ) : (
        <Typography color="secondary.medium" variant="body2" padding={2}>
          {i18n.empty}
        </Typography>
      )}
    </Stack>
  );
};

export default DetailsRow;
