import { Cloud } from '@ambuliz/sabri-core';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Compass } from 'common/components/Images';
import { i18n } from 'common/locale';
import { EmptyContent, PageSection, useAppBarContext } from 'core/layout';
import CustomGrid from 'kurt/components/Department/Table/CustomGrid';
import UnitsTableSkeleton from 'kurt/components/Department/Table/Skeleton';
import { memo, useEffect, useRef, useState } from 'react';
import ListItem from './ListItem';

type ListProps = {
  healthCenters: Cloud.HealthCenterRegionData[];
  loading?: boolean;
};

const List: React.FC<ListProps> = ({ healthCenters, loading }) => {
  const { isFullScreen } = useAppBarContext();
  const displayUpdatedAtColumn = healthCenters.some((healthCenter) => {
    return shouldDisplayUpdatedAt(healthCenter);
  });

  const isMedium = useMediaQuery('(max-width: 1250px)');
  const ref = useRef<HTMLDivElement>(null);
  const { appBarHeight } = useAppBarContext();

  const [columns, setColumns] = useState<number[]>(getColumns(displayUpdatedAtColumn, isMedium));

  useEffect(() => {
    setColumns(getColumns(displayUpdatedAtColumn, isMedium));
  }, [displayUpdatedAtColumn, isMedium]);

  return !loading && healthCenters.length === 0 ? (
    <PageSection withBackground noGutter lastSection>
      <EmptyContent Image={Compass} {...i18n.territoryEmptyContent} />
    </PageSection>
  ) : (
    <>
      <PageSection
        withBackground
        noGutter
        sticky={{ top: appBarHeight + (!isFullScreen ? 64 : 0), withShadow: true }}
        noMargin={isFullScreen}
        paddingTop={!isFullScreen ? 24 : 40}
        ref={ref}
      >
        <Grid container item spacing={4} sx={{ paddingLeft: 4 }}>
          <CustomGrid item percentile={columns[0]}>
            <Typography variant="overline">
              {i18n.healthCenter} | {i18n.department}
            </Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[1]}>
            <Typography variant="overline">{i18n.occupancyRate}</Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[2]}>
            <Typography variant="overline">
              {i18n.admissions} | {i18n.discharges}
            </Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[3]}>
            <Typography variant="overline">{i18n.availableBedsShort}</Typography>
          </CustomGrid>

          <CustomGrid item percentile={columns[4]}>
            <Typography variant="overline">{i18n.availableBedsH24}</Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[5]}>
            <Typography variant="overline">{i18n.availableBedsH48}</Typography>
          </CustomGrid>
          {displayUpdatedAtColumn && (
            <CustomGrid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              percentile={columns[6]}
              paddingRight={4}
            >
              <Typography variant="overline">{i18n.update}</Typography>
            </CustomGrid>
          )}
        </Grid>
      </PageSection>
      <PageSection withBackground noGutter noPaddingY noMargin fullHeight lastSection>
        <Grid container spacing={4} direction="column" paddingTop={3}>
          <Grid container item>
            {loading ? (
              <UnitsTableSkeleton />
            ) : (
              <Grid container direction="column" spacing={2}>
                {healthCenters.map((healthCenter) => {
                  return (
                    <Grid item key={healthCenter.name}>
                      <ListItem
                        {...healthCenter}
                        displayUpdatedAtColumn={displayUpdatedAtColumn}
                        columns={columns}
                        isMedium={isMedium}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
      </PageSection>
    </>
  );
};

export default memo(List);

const shouldDisplayUpdatedAt = (healthCenter: Cloud.HealthCenterRegionData) =>
  healthCenter.departments.some(({ occupancy }) => occupancy?.updatedAt);

const getColumns = (isEditable: boolean, isMedium: boolean) => {
  if (isEditable) {
    return isMedium ? [32, 17, 15, 8, 8, 8, 12] : [25, 12, 10, 14, 14, 14, 11];
  }
  return isMedium ? [32, 20, 18, 10, 10, 10] : [25, 13, 11, 17, 17, 17];
};
