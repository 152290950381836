import { call, takeLatest } from 'redux-saga/effects';
import { requestNames, requests } from '../../const/requests';
import { safeSaga } from '../../utils/safeSaga';
import { toggleAutodispatchType } from '../actions/healthCenter';

function* toggleAutoDispatch() {
  yield call(requests.toggleAutodispatch);
}

export function* healthCenterRootSaga() {
  yield takeLatest(toggleAutodispatchType, safeSaga(toggleAutoDispatch, requestNames.toggleAutodispatch));
}
