import { Fare, FareStatus } from '@ambuliz/sabri-core';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { ArrowRight, Bed, Comment } from 'common/components/Icons';
import Stretcher from 'common/components/Icons/Stretcher';
import Walking from 'common/components/Icons/Walking';
import WheelChair from 'common/components/Icons/WheelChair';
import TimelineRowEmoji, { TimelineRowEmojiProps } from 'common/components/Timeline/TimelineRowEmoji';
import TimelineRowItem, { TimelineRowItemProps } from 'common/components/Timeline/TimelineRowItem';
import { i18n } from 'common/locale';
import { useRef } from 'react';
import { i18n as i18nSabri } from 'sabri/locales';

type FareCardEventProps = {
  fare: Fare;
  emoji: TimelineRowEmojiProps;
} & Pick<
  TimelineRowItemProps,
  | 'id'
  | 'start'
  | 'end'
  | 'isStartOutdated'
  | 'isEndOutdated'
  | 'striped'
  | 'color'
  | 'registerSticky'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseLeave'
>;

const FareCardEvent = ({ fare, start, end, emoji, ...props }: FareCardEventProps) => {
  const cardContainer = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <TimelineRowItem
      start={start}
      end={end}
      borderLeft={
        fare.status &&
        (['IN_PROGRESS', 'DONE', 'PATIENT_CARE', 'STARTED', 'WAITING'] as FareStatus[]).includes(fare.status)
      }
      borderRight={!!end && fare.status === 'DONE'}
      striped={fare.status && (['DONE', 'CANCELED', 'DRAFT', 'FAILED'] as FareStatus[]).includes(fare.status || '')}
      ref={cardContainer}
      cardContainerRef={cardContainer}
      contentRef={contentRef}
      {...props}
    >
      <Stack
        ref={contentRef}
        spacing={3}
        direction="row"
        alignItems="center"
        flexWrap="nowrap"
        paddingX={4}
        position="relative"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <TimelineRowEmoji {...emoji} />
          <Typography variant="h6" noWrap>
            {i18n.cardEvents.fares}
          </Typography>
        </Stack>
        <Stack
          divider={<Box bgcolor="success.dark" width={4} height={4} borderRadius={2} />}
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
            {fare.comment && (
              <Tooltip
                title={
                  <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
                    {fare.comment}
                  </Typography>
                }
                placement="top"
              >
                <div>
                  <Comment sx={{ fontSize: 12, color: 'success.dark' }} />
                </div>
              </Tooltip>
            )}
            {getFarePositionIcon(fare)}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2" color="success.dark" noWrap>
              {fare.fromArea?.name ? `${fare.fromUnit.name} ${fare.fromArea.name}` : fare.fromUnit.name}
            </Typography>
            <ArrowRight sx={{ color: 'success.dark' }} />
            <Typography variant="body2" color="success.dark" noWrap>
              {fare.toArea?.name ? `${fare.toUnit.name} ${fare.toArea.name}` : fare.toUnit.name}
            </Typography>
          </Stack>
          {fare.specificities?.map((specificity) => (
            <Typography key={specificity} variant="body2" color="success.dark" noWrap>
              {i18nSabri.fareSpecificity[specificity]}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </TimelineRowItem>
  );
};

const getFarePositionIcon = (fare: Fare) => {
  switch (fare.position) {
    case 'LYING_DOWN_BED':
      return <Bed />;
    case 'LYING_DOWN':
      return <Stretcher />;
    case 'SITTING_UP':
      return <WheelChair />;
    case 'WALKING':
      return <Walking />;
    default:
      return null;
  }
};

export default FareCardEvent;
