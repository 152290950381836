import adult from 'common/assets/images/emojis/adult.svg';
import adultOutlined from 'common/assets/images/emojis/adultOutlined.svg';
import alarmClock from 'common/assets/images/emojis/alarmClock.svg';
import ambulance from 'common/assets/images/emojis/ambulance.svg';
import astonishedFace from 'common/assets/images/emojis/astonishedFace.svg';
import backhandIndexPointingRight from 'common/assets/images/emojis/backhandIndexPointingRight.svg';
import bed from 'common/assets/images/emojis/bed.svg';
import bedDeleted from 'common/assets/images/emojis/bedDeleted.svg';
import bellOrange from 'common/assets/images/emojis/bellOrange.svg';
import bellhopBell from 'common/assets/images/emojis/bellhopBell.svg';
import boy from 'common/assets/images/emojis/boy.svg';
import boyOutlined from 'common/assets/images/emojis/boyOutlined.svg';
import bucket from 'common/assets/images/emojis/bucket.svg';
import bucketOutlined from 'common/assets/images/emojis/bucketOutlined.svg';
import calendar from 'common/assets/images/emojis/calendar.svg';
import chequeredFlag from 'common/assets/images/emojis/chequeredFlag.svg';
import clappingHands from 'common/assets/images/emojis/clappingHands.svg';
import clipboard from 'common/assets/images/emojis/clipboard.svg';
import clock from 'common/assets/images/emojis/clock.svg';
import closedMailboxWithRaisedFlag from 'common/assets/images/emojis/closedMailboxWithRaisedFlag.svg';
import confoundedFace from 'common/assets/images/emojis/confoundedFace.svg';
import confusedFace from 'common/assets/images/emojis/confusedFace.svg';
import construction from 'common/assets/images/emojis/construction.svg';
import constructionOutlined from 'common/assets/images/emojis/constructionOutlined.svg';
import crossedFingers from 'common/assets/images/emojis/crossedFingers.svg';
import faceWithColdSweat from 'common/assets/images/emojis/faceWithColdSweat.svg';
import flexedBiceps from 'common/assets/images/emojis/flexedBiceps.svg';
import girl from 'common/assets/images/emojis/girl.svg';
import girlOutlined from 'common/assets/images/emojis/girlOutlined.svg';
import grimacingFace from 'common/assets/images/emojis/grimacingFace.svg';
import grinningFace from 'common/assets/images/emojis/grinningFace.svg';
import grinningFaceWithSweat from 'common/assets/images/emojis/grinningFaceWithSweat.svg';
import handshake from 'common/assets/images/emojis/handshake.svg';
import hourglassNotDone from 'common/assets/images/emojis/hourglassNotDone.svg';
import indexPointingUp from 'common/assets/images/emojis/indexPointingUp.svg';
import kid from 'common/assets/images/emojis/kid.svg';
import kidOutlined from 'common/assets/images/emojis/kidOutlined.svg';
import locked from 'common/assets/images/emojis/locked.svg';
import man from 'common/assets/images/emojis/man.svg';
import manHealthWorker from 'common/assets/images/emojis/manHealthWorker.svg';
import manInSteamyRoom from 'common/assets/images/emojis/manInSteamyRoom.svg';
import manOutlined from 'common/assets/images/emojis/manOutlined.svg';
import manualWheelchair from 'common/assets/images/emojis/manualWheelchair.svg';
import memo from 'common/assets/images/emojis/memo.svg';
import nerdFace from 'common/assets/images/emojis/nerdFace.svg';
import noEntry from 'common/assets/images/emojis/noEntry.svg';
import okHand from 'common/assets/images/emojis/okHand.svg';
import partyingFace from 'common/assets/images/emojis/partyingFace.svg';
import pencil from 'common/assets/images/emojis/pencil.svg';
import pushpin from 'common/assets/images/emojis/pushpin.svg';
import raisedHand from 'common/assets/images/emojis/raisedHand.svg';
import raisingHands from 'common/assets/images/emojis/raisingHands.svg';
import rightCurvingLeft from 'common/assets/images/emojis/rightCurvingLeft.svg';
import roadSign from 'common/assets/images/emojis/roadSign.svg';
import seeNoEvilMonkey from 'common/assets/images/emojis/seeNoEvilMonkey.svg';
import sleepingFace from 'common/assets/images/emojis/sleepingFace.svg';
import slightlySmilingFace from 'common/assets/images/emojis/slightlySmilingFace.svg';
import smilingFace from 'common/assets/images/emojis/smilingFace.svg';
import smilingFaceWithHalo from 'common/assets/images/emojis/smilingFaceWithHalo.svg';
import smilingFaceWithSunglasses from 'common/assets/images/emojis/smilingFaceWithSunglasses.svg';
import speechBalloon from 'common/assets/images/emojis/speechBalloon.svg';
import spiralCalendar from 'common/assets/images/emojis/spiralCalendar.svg';
import star from 'common/assets/images/emojis/star.svg';
import starStruck from 'common/assets/images/emojis/starStruck.svg';
import stethoscope from 'common/assets/images/emojis/stethoscope.svg';
import taxi from 'common/assets/images/emojis/taxi.svg';
import thinkingFace from 'common/assets/images/emojis/thinkingFace.svg';
import thumbsDown from 'common/assets/images/emojis/thumbsDown.svg';
import thumbsUp from 'common/assets/images/emojis/thumbsUp.svg';
import unit from 'common/assets/images/emojis/unit.svg';
import victoryHand from 'common/assets/images/emojis/victoryHand.svg';
import waitingRoom from 'common/assets/images/emojis/waitingRoom.svg';
import walking from 'common/assets/images/emojis/walking.svg';
import warning from 'common/assets/images/emojis/warning.svg';
import wavingHand from 'common/assets/images/emojis/wavingHand.svg';
import whiteHeavyCheckMark from 'common/assets/images/emojis/whiteHeavyCheckMark.svg';
import winkingFace from 'common/assets/images/emojis/winkingFace.svg';
import woman from 'common/assets/images/emojis/woman.svg';
import womanGesturingNo from 'common/assets/images/emojis/womanGesturingNo.svg';
import womanHealthWorker from 'common/assets/images/emojis/womanHealthWorker.svg';
import womanInSteamyRoom from 'common/assets/images/emojis/womanInSteamyRoom.svg';
import womanOutlined from 'common/assets/images/emojis/womanOutlined.svg';
import writingHand from 'common/assets/images/emojis/writingHand.svg';

export type EmojiType =
  | 'adult'
  | 'adultOutlined'
  | 'alarmClock'
  | 'ambulance'
  | 'astonishedFace'
  | 'bed'
  | 'bedDeleted'
  | 'bellhopBell'
  | 'bellOrange'
  | 'backhandIndexPointingRight'
  | 'boy'
  | 'boyOutlined'
  | 'bucket'
  | 'bucketOutlined'
  | 'calendar'
  | 'chequeredFlag'
  | 'clappingHands'
  | 'clock'
  | 'closedMailboxWithRaisedFlag'
  | 'clipboard'
  | 'confoundedFace'
  | 'confusedFace'
  | 'construction'
  | 'constructionOutlined'
  | 'crossedFingers'
  | 'faceWithColdSweat'
  | 'flexedBiceps'
  | 'girl'
  | 'girlOutlined'
  | 'grimacingFace'
  | 'grinningFace'
  | 'grinningFaceWithSweat'
  | 'handshake'
  | 'hourglassNotDone'
  | 'indexPointingUp'
  | 'kid'
  | 'kidOutlined'
  | 'locked'
  | 'man'
  | 'manHealthWorker'
  | 'manInSteamyRoom'
  | 'manOutlined'
  | 'manualWheelchair'
  | 'memo'
  | 'nerdFace'
  | 'noEntry'
  | 'okHand'
  | 'partyingFace'
  | 'pencil'
  | 'pushpin'
  | 'raisedHand'
  | 'raisingHands'
  | 'rightCurvingLeft'
  | 'roadSign'
  | 'seeNoEvilMonkey'
  | 'sleepingFace'
  | 'slightlySmilingFace'
  | 'smilingFace'
  | 'smilingFaceWithHalo'
  | 'smilingFaceWithSunglasses'
  | 'speechBalloon'
  | 'spiralCalendar'
  | 'star'
  | 'starStruck'
  | 'stethoscope'
  | 'taxi'
  | 'thinkingFace'
  | 'thumbsDown'
  | 'thumbsUp'
  | 'unit'
  | 'victoryHand'
  | 'waitingRoom'
  | 'walking'
  | 'warning'
  | 'wavingHand'
  | 'whiteHeavyCheckMark'
  | 'winkingFace'
  | 'woman'
  | 'womanOutlined'
  | 'womanGesturingNo'
  | 'womanHealthWorker'
  | 'womanInSteamyRoom'
  | 'writingHand';

export type EmojiSize = 'large' | 'medium' | 'normal' | 'small' | 'extra-small' | number;
export type EmojiShape = 'circle' | 'square';
type EmojiBgPadding = Record<EmojiSize, string>;

export const defaultEmojiBgPadding: EmojiBgPadding = {
  large: '8px',
  medium: '6px',
  normal: '3px',
  small: '3px',
  'extra-small': '2px',
};
export const emoji: Record<EmojiType, { alt: string; src: string; bgPadding?: EmojiBgPadding }> = {
  adult: {
    alt: 'adult',
    src: adult,
    bgPadding: {
      large: '4px 0 0 0',
      medium: '3px 0 0 0',
      normal: '2px 0 0 0',
      small: '1px 0 0 0',
      'extra-small': '1px 0 0 0',
    },
  },
  adultOutlined: {
    alt: 'adult outlined',
    src: adultOutlined,
  },
  alarmClock: {
    alt: 'alarm clock',
    src: alarmClock,
  },
  ambulance: {
    alt: 'ambulance',
    src: ambulance,
  },
  astonishedFace: {
    alt: 'astonished face',
    src: astonishedFace,
  },
  bed: {
    alt: 'bed',
    src: bed,
  },
  bedDeleted: {
    alt: 'bed deleted',
    src: bedDeleted,
  },
  bellhopBell: {
    alt: 'bellhop bell',
    src: bellhopBell,
  },
  bellOrange: {
    alt: 'bell orange',
    src: bellOrange,
  },
  backhandIndexPointingRight: {
    alt: 'backhand index pointing right',
    src: backhandIndexPointingRight,
  },
  boy: {
    alt: 'boy',
    src: boy,
    bgPadding: {
      large: '4px 0 0 0',
      medium: '3px 0 0 0',
      normal: '2px 0 0 0',
      small: '1px 0 0 0',
      'extra-small': '1px 0 0 0',
    },
  },
  boyOutlined: {
    alt: 'boy outlined',
    src: boyOutlined,
  },
  bucket: {
    alt: 'bucket',
    src: bucket,
  },
  bucketOutlined: {
    alt: 'bucket outlined',
    src: bucketOutlined,
  },
  calendar: {
    alt: 'calendar',
    src: calendar,
  },
  chequeredFlag: {
    alt: 'chequered flag',
    src: chequeredFlag,
  },
  clappingHands: {
    alt: 'clapping hands',
    src: clappingHands,
  },
  clock: {
    alt: 'clock',
    src: clock,
  },
  closedMailboxWithRaisedFlag: {
    alt: 'closed mailbox with raised flag',
    src: closedMailboxWithRaisedFlag,
  },
  clipboard: {
    alt: 'clipboard',
    src: clipboard,
  },
  confoundedFace: {
    alt: 'confounded face',
    src: confoundedFace,
  },
  confusedFace: {
    alt: 'confused face',
    src: confusedFace,
  },
  construction: {
    alt: 'construction',
    src: construction,
  },
  constructionOutlined: {
    alt: 'construction outlined',
    src: constructionOutlined,
  },
  crossedFingers: {
    alt: 'crossed fingers',
    src: crossedFingers,
  },
  faceWithColdSweat: {
    alt: 'face with cold sweat',
    src: faceWithColdSweat,
  },
  flexedBiceps: {
    alt: 'flexed biceps',
    src: flexedBiceps,
  },
  girl: {
    alt: 'girl',
    src: girl,
    bgPadding: {
      large: '4px 0 0 0',
      medium: '3px 0 0 0',
      normal: '2px 0 0 0',
      small: '1px 0 0 0',
      'extra-small': '1px 0 0 0',
    },
  },
  girlOutlined: {
    alt: 'girl outlined',
    src: girlOutlined,
  },
  grimacingFace: {
    alt: 'grimacing face',
    src: grimacingFace,
  },
  grinningFace: {
    alt: 'grinning face',
    src: grinningFace,
  },
  grinningFaceWithSweat: {
    alt: 'grinning face with sweat',
    src: grinningFaceWithSweat,
  },
  handshake: {
    alt: 'handshake',
    src: handshake,
  },
  hourglassNotDone: {
    alt: 'hourglass not done',
    src: hourglassNotDone,
  },
  indexPointingUp: {
    alt: 'index pointing up',
    src: indexPointingUp,
  },
  kid: {
    alt: 'kid',
    src: kid,
    bgPadding: {
      large: '4px 0 0 0',
      medium: '3px 0 0 0',
      normal: '2px 0 0 0',
      small: '1px 0 0 0',
      'extra-small': '1px 0 0 0',
    },
  },
  kidOutlined: {
    alt: 'kid outlined',
    src: kidOutlined,
  },
  locked: {
    alt: 'locked',
    src: locked,
  },
  man: {
    alt: 'man',
    src: man,
    bgPadding: {
      large: '4px 0 0 0',
      medium: '3px 0 0 0',
      normal: '2px 0 0 0',
      small: '1px 0 0 0',
      'extra-small': '1px 0 0 0',
    },
  },
  manHealthWorker: {
    alt: 'man health worker',
    src: manHealthWorker,
  },
  manInSteamyRoom: {
    alt: 'man in steamy room',
    src: manInSteamyRoom,
  },
  manOutlined: {
    alt: 'man outlined',
    src: manOutlined,
  },
  manualWheelchair: {
    alt: 'manual wheelchair',
    src: manualWheelchair,
  },
  memo: {
    alt: 'memo',
    src: memo,
  },
  nerdFace: {
    alt: 'nerd face',
    src: nerdFace,
  },
  noEntry: {
    alt: 'no entry',
    src: noEntry,
  },
  okHand: {
    alt: 'ok hand',
    src: okHand,
  },
  partyingFace: {
    alt: 'partying face',
    src: partyingFace,
  },
  pencil: {
    alt: 'pencil',
    src: pencil,
  },
  pushpin: {
    alt: 'pushpin',
    src: pushpin,
  },
  raisedHand: {
    alt: 'raised hand',
    src: raisedHand,
  },
  raisingHands: {
    alt: 'raising hands',
    src: raisingHands,
  },
  rightCurvingLeft: {
    alt: 'right curving left',
    src: rightCurvingLeft,
  },
  roadSign: {
    alt: 'two signs pointing in opposit directions',
    src: roadSign,
  },
  seeNoEvilMonkey: {
    alt: 'see no evil monkey',
    src: seeNoEvilMonkey,
  },
  sleepingFace: {
    alt: 'sleeping face',
    src: sleepingFace,
  },
  slightlySmilingFace: {
    alt: 'slightly smiling face',
    src: slightlySmilingFace,
  },
  smilingFace: {
    alt: 'smiling face',
    src: smilingFace,
  },
  smilingFaceWithHalo: {
    alt: 'smiling face with halo',
    src: smilingFaceWithHalo,
  },
  smilingFaceWithSunglasses: {
    alt: 'smiling face with sunglasses',
    src: smilingFaceWithSunglasses,
  },
  speechBalloon: {
    alt: 'speech balloon',
    src: speechBalloon,
  },
  spiralCalendar: {
    alt: 'spiral calendar',
    src: spiralCalendar,
  },
  star: {
    alt: 'star',
    src: star,
  },
  starStruck: {
    alt: 'star struck',
    src: starStruck,
  },
  stethoscope: {
    alt: 'stethoscope',
    src: stethoscope,
  },
  taxi: {
    alt: 'taxi',
    src: taxi,
  },
  thinkingFace: {
    alt: 'thinking face',
    src: thinkingFace,
  },
  thumbsDown: {
    alt: 'thumbs down',
    src: thumbsDown,
  },
  thumbsUp: {
    alt: 'thumbs up',
    src: thumbsUp,
  },
  unit: {
    alt: 'unit',
    src: unit,
  },
  victoryHand: {
    alt: 'victory hand',
    src: victoryHand,
  },
  waitingRoom: {
    alt: 'waiting room',
    src: waitingRoom,
  },
  walking: {
    alt: 'walking',
    src: walking,
  },
  warning: {
    alt: 'warning',
    src: warning,
  },
  wavingHand: {
    alt: 'waving hand',
    src: wavingHand,
  },
  whiteHeavyCheckMark: {
    alt: 'white heavy check mark',
    src: whiteHeavyCheckMark,
  },
  winkingFace: {
    alt: 'winking face',
    src: winkingFace,
  },
  woman: {
    alt: 'woman',
    src: woman,
    bgPadding: {
      large: '4px 0 0 0',
      medium: '3px 0 0 0',
      normal: '2px 0 0 0',
      small: '1px 0 0 0',
      'extra-small': '1px 0 0 0',
    },
  },
  womanOutlined: {
    alt: 'woman outlined',
    src: womanOutlined,
  },
  womanGesturingNo: {
    alt: 'woman gesturing no',
    src: womanGesturingNo,
  },
  womanHealthWorker: {
    alt: 'woman health worker',
    src: womanHealthWorker,
  },
  womanInSteamyRoom: {
    alt: 'woman in steamy room',
    src: womanInSteamyRoom,
  },
  writingHand: {
    alt: 'writing hand',
    src: writingHand,
  },
};
