import { Box } from '@mui/material';
import { color } from 'common/theme';
import { addDays, addHours, differenceInDays, differenceInHours, endOfDay, startOfDay, startOfHour } from 'date-fns';
import { ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { getPosition } from './TimeMarker';
import CurrentTimeMarker from './TimeMarker/CurrentTimeMarker';
import TimelineBackground, { TimelineBackgroundVariant } from './TimelineBackground';
import TimelineContext, { DEFAULT_SCALE, Period, ROW_HEADER_WIDTH } from './TimelineContext';
import TimelineHeader from './TimelineHeader';
import { TIMELINE_ROW_TITLE_MARGIN_RIGHT } from './TimelineRow';

type TimelineProps = {
  top?: number;
  start: Date;
  end: Date;
  container?: RefObject<HTMLElement>;
  children?: ReactNode;
  period?: Period;
  scale?: number;
  title?: string;
  rowHeaderWidth?: number;
  variant?: TimelineBackgroundVariant;
  focusDate?: Date;
  loading?: boolean;
};

const Timeline = ({
  top = 0,
  start,
  end,
  children,
  scale = DEFAULT_SCALE,
  period = 'week',
  title = '',
  container: outsideContainer,
  rowHeaderWidth = ROW_HEADER_WIDTH,
  variant = 'card',
  focusDate,
  loading = false,
}: TimelineProps) => {
  const timelineContainer = useRef<HTMLElement>(null);
  const debouncedScale = scale;
  const [isFirstInit, setIsFirstInit] = useState(true);
  const [currentFocusDate, setCurrentFocusDate] = useState(focusDate || new Date());

  useEffect(() => {
    if (isFirstInit) {
      setIsFirstInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  start = useMemo(() => startOfDay(start), [start]);
  end = useMemo(() => endOfDay(end), [end]);
  const days = useMemo(() => {
    const difference = period === 'day' ? differenceInHours(end, start) : differenceInDays(end, start);
    const days: Date[] = [];

    for (let i = 0; i <= difference; i++) {
      if (period === 'day') {
        days.push(startOfHour(addHours(start, i)));
      } else {
        days.push(startOfDay(addDays(start, i)));
      }
    }
    return days;
  }, [start, end, period]);

  useEffect(() => {
    const currentPosition = getPosition({ date: currentFocusDate || new Date(), period, scale, start });
    const container = outsideContainer?.current || timelineContainer.current || document.body;

    container.scrollTo({
      left: currentPosition + TIMELINE_ROW_TITLE_MARGIN_RIGHT - container.getBoundingClientRect().width / 4,
      behavior: isFirstInit || !currentFocusDate ? 'auto' : 'smooth',
    });
  }, [period, scale, outsideContainer, start, currentFocusDate, isFirstInit]);

  // Avoid using focus date directly to prevent scroll without date time change
  useEffect(() => {
    setCurrentFocusDate((current) => (focusDate?.getTime() !== current?.getTime() ? focusDate || new Date() : current));
  }, [focusDate]);

  return (
    <TimelineContext.Provider
      value={{
        end,
        start,
        period,
        scale: debouncedScale,
        rowHeaderWidth,
        container: outsideContainer || timelineContainer,
        variant,
      }}
    >
      <Box
        ref={timelineContainer}
        position="relative"
        display="flex"
        flexDirection="column"
        borderBottom={variant === 'filled' ? '1px solid' : undefined}
        borderColor={color.grey[10]}
        sx={{
          overflowX: outsideContainer ? undefined : loading ? 'hidden' : 'scroll',
        }}
      >
        <TimelineHeader top={top} title={title} slots={days} focusDate={focusDate} loading={loading} />
        <Box
          position="relative"
          width={rowHeaderWidth + TIMELINE_ROW_TITLE_MARGIN_RIGHT + scale * days.length}
          bgcolor={variant === 'card' ? undefined : 'background.default'}
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            paddingLeft={`${rowHeaderWidth + TIMELINE_ROW_TITLE_MARGIN_RIGHT}px`}
          >
            {!loading && <CurrentTimeMarker />}
            <TimelineBackground days={days.length} scale={debouncedScale} variant={variant} />
            {variant === 'filled' && !loading && <FilledRowHeaderShaddow rowHeaderWidth={rowHeaderWidth} />}
          </Box>
          <Box paddingBottom={2} paddingTop={`${getTimelinePaddingTop(variant)}px`}>
            {children}
          </Box>
        </Box>
      </Box>
    </TimelineContext.Provider>
  );
};

export const getTimelinePaddingTop = (variant: TimelineBackgroundVariant) => (variant === 'card' ? 4 : 6);

const FilledRowHeaderShaddow = ({ rowHeaderWidth }: { rowHeaderWidth: number }) => (
  <Box
    position="sticky"
    left={rowHeaderWidth + TIMELINE_ROW_TITLE_MARGIN_RIGHT}
    height="100%"
    width={rowHeaderWidth}
    boxShadow="0px 0px 10px rgba(20, 22, 28, 0.08)"
    zIndex={2}
    sx={{
      transform: `translateX(-${rowHeaderWidth + TIMELINE_ROW_TITLE_MARGIN_RIGHT}px)`,
    }}
  />
);

export default Timeline;
