import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { Box, Link, Radio, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import useStepScheduling from 'common/hooks/useStepScheduling';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { getPatientAge } from 'common/utils';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import EllipsisTypography from '../EllipsisTypography';
import { Info } from '../Icons';
import { Mission } from '../Images';
import BedPlacementSkeleton from './BedPlacementSkeleton';
import BedSlot from './BedSlot';

type BedPlacementProps = {
  unitId: string;
  start: Date;
  end?: Date;
  onSelect: (id: string) => void;
  value?: string;
  name?: string;
  birthdate?: Date;
  gender: PatientGender;
  specificities?: AccommodationSpecificity[];
  size?: 'small' | 'medium';
};

const BedPlacement: React.FC<BedPlacementProps> = ({
  unitId,
  start,
  gender,
  end,
  birthdate,
  name,
  onSelect,
  value,
  specificities,
  size = 'medium',
}) => {
  const { loading, solutions: allSolutions } = useStepScheduling(
    unitId,
    gender,
    start,
    end,
    specificities,
    birthdate ? getPatientAge(birthdate) : undefined
  );
  const [hoverId, setHoverId] = useState('');
  const [expanded, setExpanded] = useState(false);

  const bedSolutions = useMemo(() => (expanded ? allSolutions : allSolutions.slice(0, 3)), [allSolutions, expanded]);

  useEffect(() => {
    if (!loading && !value && allSolutions && allSolutions.length > 0) {
      onSelect(allSolutions[0].id);
    }
  }, [loading, allSolutions, value, onSelect]);

  if (!birthdate || !name) {
    return null;
  }

  return (
    <Stack spacing={4}>
      {loading ? (
        <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={230} height={12} />
      ) : (
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" spacing={1}>
            <Typography variant={size === 'small' ? 'body2' : 'body1'} fontWeight={600} color="secondary">
              {allSolutions.length}
            </Typography>
            <Typography color="secondary" variant={size === 'small' ? 'body2' : 'body1'}>
              {i18n.bedPlacementSolutions(allSolutions.length)}
            </Typography>
          </Stack>
          <Tooltip title={i18n.bedPlacementTooltip}>
            <Stack>
              <Info color="secondary" fontSize="small" />
            </Stack>
          </Tooltip>
        </Stack>
      )}
      {loading ? (
        <BedPlacementSkeleton />
      ) : allSolutions.length > 0 ? (
        <Stack spacing={4}>
          {bedSolutions.map((bed, index) => (
            <Stack
              direction="row"
              spacing={2}
              key={bed.id}
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onMouseEnter={() => setHoverId(bed.id)}
              onMouseLeave={() => setHoverId('')}
              onClick={() => onSelect(bed.id)}
              style={{ marginTop: value === bed.id ? (index === 0 ? 8 : 24) : undefined }}
            >
              <Box marginLeft={-1}>
                <Stack alignItems="center" direction="row" gap={1}>
                  <Radio onChange={() => onSelect(bed.id)} checked={value === bed.id} size="small" value={bed.id} />
                  <EllipsisTypography
                    fontWeight={500}
                    color={hoverId === bed.id || value === bed.id ? 'textPrimary' : 'secondary'}
                    maxWidth={60}
                    minWidth={60}
                    variant={size === 'small' ? 'body2' : 'body1'}
                  >
                    {bed.name}
                  </EllipsisTypography>
                </Stack>
              </Box>
              <BedSlot
                value={value}
                hoverId={hoverId}
                bedId={bed.id}
                birthdate={birthdate}
                gender={gender}
                name={name}
                specificities={specificities || []}
                start={start}
                end={end}
                nextStart={bed.nextStart}
                nextEnd={bed.nextEnd}
              />
            </Stack>
          ))}
        </Stack>
      ) : (
        <Container spacing={4} alignItems="center" paddingX={7} paddingY={6}>
          <Mission height={90} />
          <Stack spacing={2} paddingX={14} alignItems="center" textAlign="center">
            <Typography>{i18n.noBedAvailableInUnit}</Typography>
            <Typography variant="body2" color="secondary">
              {i18n.noBedAvailableSubtitle}
            </Typography>
          </Stack>
        </Container>
      )}

      <Box alignSelf="flex-end">
        {loading ? (
          <Skeleton sx={{ borderRadius: 0.5 }} variant="rectangular" height={12} width={145} />
        ) : (
          allSolutions.length > 3 &&
          !expanded && (
            <Link component="button" onClick={() => setExpanded(true)}>
              <Typography variant="body2" color="primary">
                {i18n.expandResults}
              </Typography>
            </Link>
          )
        )}
      </Box>
    </Stack>
  );
};

const Container = styled(Stack)`
  background-color: ${color.blue[5]};
  border-radius: 8px;
`;

export default BedPlacement;
