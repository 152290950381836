import { Cloud } from '@ambuliz/sabri-core';
import { MoreVert, PersonAdd } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import CancelIcon from 'common/components/Icons/Cancel';
import PencilIcon from 'common/components/Icons/Pencil';
import WarningIcon from 'common/components/Icons/Warning';
import { palette } from 'common/theme';
import { toast } from 'common/toast';
import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CancelJourneyConfirmModal } from 'sabri/modals/CancelJourneyConfirmModal';
import { useReadOnly } from '../../../../core/authentication';
import { modalNames } from '../../../const/modalNames';
import { routeNamesCommonDynamic } from '../../../const/routeNames';
import { EntityNames } from '../../../const/schemas';
import { useSelectFetch } from '../../../hooks/useSelectFetch';
import { i18n } from '../../../locales';
import * as fareService from '../../../services/fare';
import { getPatientFullName } from '../../../services/patient';
import { cancelConfirmFareAction, confirmFareAction } from '../../../store/actions/fare';
import { modalActions } from '../../../store/reducers/modal';
import { selectIsAutodispatchEnabled } from '../../../store/selectors/fareAssignation';
import { selectUserRole, selectUserUnitId } from '../../../store/selectors/general';
import FareListCancelFareConfirmationAction from './FareListCancelFareConfirmationAction';
import FareListConfirmFareAction from './FareListConfirmFareAction';

export type FareListActionsProps = {
  fareId: string;
  journeyId?: string;
};

export const FareListActions: React.FC<FareListActionsProps> = ({ fareId, journeyId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);
  const [fare] = useSelectFetch(EntityNames.fares, fareId);
  const [patient] = useSelectFetch(EntityNames.patients, fare?.patientId);
  const userUnitId = useSelector(selectUserUnitId);
  const isMenuDisabled = !fareService.isFareEditable(fare) && !fareService.isFareDeletable(fare);
  const isAutodispatchEnabled = useSelector(selectIsAutodispatchEnabled);
  const [cancelJourneyConfirmModalOpen, setCancelJourneyConfirmModalOpen] = useState(false);
  const [cancelJourneyLoading, setCancelJourneyLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isDisabled = isAutodispatchEnabled || !fareService.isFareAssignable(fare);

  const { isReadOnly } = useReadOnly();

  if (!fare) {
    return null;
  }

  const isButtonAddPorterVisible = role === 'REGULATOR' || role === 'ADMIN_REGULATOR';

  const isFareConfirmable = fare ? fareService.isFareConfirmable(fare) : false;
  const isFareConfirmationCancellable = fare ? fareService.isFareConfirmationCancellable(fare) : false;

  const isFareGoingToMyUnit = fare.toUnitId === userUnitId;

  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (event: MouseEvent<Element>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAddPorterClick = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(routeNamesCommonDynamic.fareDetails(fareId));
  };

  const handleConfirmPullFareModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (patient) {
      dispatch(
        modalActions.showModal({
          modalName: modalNames.confirmPullFare,
          params: {
            onConfirm: () => dispatch(confirmFareAction({ fareId })),
            patientFullName: getPatientFullName(patient),
          },
        })
      );
    }
  };
  const handleConfirmPushFareModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (patient) {
      dispatch(
        modalActions.showModal({
          modalName: modalNames.confirmPushFare,
          params: {
            onConfirm: () => dispatch(confirmFareAction({ fareId })),
            patientFullName: getPatientFullName(patient),
          },
        })
      );
    }
  };

  const handleCancelPullFareModel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (patient) {
      dispatch(
        modalActions.showModal({
          modalName: modalNames.cancelPullFare,
          params: {
            onConfirm: () => dispatch(cancelConfirmFareAction({ fareId })),
            patientFullName: getPatientFullName(patient),
          },
        })
      );
    }
  };

  const handleCancelPushFareModel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (patient) {
      dispatch(
        modalActions.showModal({
          modalName: modalNames.cancelPushFare,
          params: {
            onConfirm: () => dispatch(cancelConfirmFareAction({ fareId })),
            patientFullName: getPatientFullName(patient),
          },
        })
      );
    }
  };

  const handleEditJourneyClick = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    navigate(routeNamesCommonDynamic.fareEditDraft(fareId));
    handleCloseMenu(event);
  };

  const handleCancelJourneyClick = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setCancelJourneyConfirmModalOpen(true);
    handleCloseMenu(event);
  };

  const handleConfirmCancelJourney = async (event: MouseEvent) => {
    event.stopPropagation();
    if (journeyId) {
      setCancelJourneyLoading(true);
      try {
        await Cloud.cancelJourney({ journeyId });
        toast.success(i18n.cancelJourneySuccess);
      } catch (err) {
        toast.error(err.message);
      } finally {
        handleCloseCancelJourneyConfirmModal(event);
        setCancelJourneyLoading(false);
      }
    }
  };

  const handleCloseCancelJourneyConfirmModal = (event: MouseEvent) => {
    event.stopPropagation();
    setCancelJourneyConfirmModalOpen(false);
  };

  const handleCreateIncidentClick = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    navigate(routeNamesCommonDynamic.fareNewIncident(fareId));
    handleCloseMenu(event);
  };

  return isReadOnly ? null : (
    <>
      <Stack direction="row" justifyContent="flex-end" gap={1} alignItems="center">
        {isFareConfirmable && (
          <FareListConfirmFareAction
            onConfirmFare={isFareGoingToMyUnit ? handleConfirmPullFareModal : handleConfirmPushFareModal}
            isFareGoingToUnit={isFareGoingToMyUnit}
          />
        )}
        {isFareConfirmationCancellable && (
          <FareListCancelFareConfirmationAction
            onCancelFareConfirmation={isFareGoingToMyUnit ? handleCancelPullFareModel : handleCancelPushFareModel}
            isFareGoingToUnit={isFareGoingToMyUnit}
          />
        )}
        {isButtonAddPorterVisible && (
          <Tooltip title={i18n.addPorter} arrow={false} placement="top">
            <span>
              <IconButton disabled={isDisabled} onClick={handleAddPorterClick} size="large">
                <PersonAdd color={isDisabled ? 'disabled' : 'primary'} />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={i18n.otherActions} arrow={false} placement="top">
          <span>
            <IconButton disabled={isMenuDisabled} onClick={handleOpenMenu} size="large">
              <MoreVert color={isMenuDisabled ? 'disabled' : 'primary'} />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
      {cancelJourneyConfirmModalOpen && (
        <CancelJourneyConfirmModal
          open={cancelJourneyConfirmModalOpen}
          onClose={handleCloseCancelJourneyConfirmModal}
          onConfirm={handleConfirmCancelJourney}
          loading={cancelJourneyLoading}
        />
      )}
      <Menu
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleEditJourneyClick}>
          <ListItemIcon>
            <PencilIcon />
          </ListItemIcon>
          <ListItemText>{i18n.editJourney}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCreateIncidentClick}>
          <ListItemIcon>
            <WarningIcon />
          </ListItemIcon>
          <ListItemText>{i18n.createAnIncident}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCancelJourneyClick}>
          <ListItemIcon>
            <CancelIcon color="error" />
          </ListItemIcon>
          <ListItemText sx={{ color: palette.error.main }}>{i18n.cancelJourney}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
