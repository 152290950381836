import { ExternalPatientTransport } from '@ambuliz/sabri-core';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ExternalPatientTransportTableRow } from './ExternalPatientTransportTableRow';
import { ExternalPatientTransportTableSkeleton } from './ExternalPatientTransportTableSkeleton';

type ExternalPatientTransportsTableProps = {
  externalPatientTransports: ExternalPatientTransport[];
  isLoading?: boolean;
  count: number;
  page: number;
  onPageChange: (page: number) => void;
  rowsPerPage: number;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  onRowClick?: (id: string) => void;
  onRowUpdate?: (id: string) => void;
};

export const ExternalPatientTransportTable = ({
  externalPatientTransports,
  isLoading,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  onRowClick,
  onRowUpdate,
}: ExternalPatientTransportsTableProps) => {
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Référence</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Date Départ</TableCell>
              <TableCell>Date arrivée</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Commentaire</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <ExternalPatientTransportTableSkeleton count={rowsPerPage} />
            ) : (
              externalPatientTransports.map((externalPatientTransport) => (
                <ExternalPatientTransportTableRow
                  key={externalPatientTransport.id}
                  externalPatientTransport={externalPatientTransport}
                  onClick={onRowClick}
                  onUpdate={onRowUpdate}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={count}
        component="div"
        onPageChange={(_, newPage) => onPageChange(newPage)}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
      />
    </Paper>
  );
};
