import { IArea, IFare, IHealthCenter, IIncident, IPatient, IPorter, IUnit } from '@ambuliz/sabri-core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dissoc } from 'ramda';
import { EntityNames } from '../../const/schemas';
import { unique } from '../../utils/array';
import { AddObjectToFetchPayload } from '../actions/entity';
import { resetAction } from '../actions/general';

export type HealthCenterStored = Parse.JSONBaseAttributes &
  Pick<IHealthCenter, 'name' | 'qrCodeKey' | 'autodispatchActivated' | 'portersLimitByFarePosition'>;

export type PatientStored = Parse.JSONBaseAttributes &
  Pick<IPatient, 'gender' | 'firstName' | 'lastName' | 'ipp' | 'fullTextSearch' | 'pan'> & {
    healthCenterId: string;
    unitId?: string;
    areaId?: string;
    birthdate: string;
  };

export type FareStored = Parse.JSONBaseAttributes &
  Pick<
    IFare,
    | 'position'
    | 'specificities'
    | 'roundTrip'
    | 'comment'
    | 'reference'
    | 'status'
    | 'cancellationReason'
    | 'isEmergency'
    | 'isArchived'
    | 'duration'
    | 'startDelay'
    | 'needUnitConfirmation'
    | 'estimatedPathDurationInSeconds'
    | 'groupId'
  > & {
    assignationsIds?: string[];
    patientId: string;
    fromAreaId?: string;
    toAreaId?: string;
    fromUnitId: string;
    toUnitId: string;
    incidentsIds?: string[];
    healthCenterId: string;
    canceledById?: string;
    lastUpdateById?: string;
    isReturnTripFromFareId?: string;
    createdById?: string;
    portersIds?: string[];
    wantedDate?: string;
    canceledAt?: string;
    startedAt?: string;
    scheduledAt?: string;
    patientCareAt?: string;
    endedAt?: string;
    journeyId?: string;
  };

export type AreaStored = Parse.JSONBaseAttributes &
  Pick<IArea, 'name' | 'isSearchable'> & {
    parentId?: string;
    healthCenterId?: string;
  };

export type PorterStored = Parse.JSONBaseAttributes &
  Pick<
    IPorter,
    'firstName' | 'lastName' | 'fareAssignedCount' | 'fareDoneCount' | 'status' | 'hasInternet' | 'breakType'
  > & {
    lastAreaId?: string;
    nextAreaId?: string;
    lastUnitId?: string;
    nextUnitId?: string;
    userId?: string;
    lastActivityAt?: string;
    lastAreaAt?: string;
  };

export type UnitStored = Parse.JSONBaseAttributes &
  Pick<IUnit, 'name' | 'externalId'> & {
    areasIds: string[];
    healthCenterId: string;
  };

export type IncidentStored = Parse.JSONBaseAttributes &
  Pick<IIncident, 'reason' | 'comment' | 'delayInMinutes' | 'helpRequired' | 'adequateTransport'> & {
    fareId: string;
    porterId?: string;
    createdById?: string;
  };

export type FareAssignationStored = Parse.JSONBaseAttributes & {
  fareId: string;
  porterId: string;
  acknowledgedAt?: string;
};

export type ObjectsToFetch = {
  [EntityNames.patients]?: string[];
  [EntityNames.areas]?: string[];
  [EntityNames.fares]?: string[];
  [EntityNames.units]?: string[];
  [EntityNames.healthCenters]?: string[];
  [EntityNames.porters]?: string[];
  [EntityNames.fareAssignations]?: string[];
  [EntityNames.incidents]?: string[];
};

export type EntityState = Readonly<{
  [EntityNames.patients]: Readonly<Record<string, PatientStored>>;
  [EntityNames.fares]: Readonly<Record<string, FareStored>>;
  [EntityNames.healthCenters]: Readonly<Record<string, HealthCenterStored>>;
  [EntityNames.porters]: Readonly<Record<string, PorterStored>>;
  [EntityNames.units]: Readonly<Record<string, UnitStored>>;
  [EntityNames.areas]: Readonly<Record<string, AreaStored>>;
  [EntityNames.fareAssignations]: Readonly<Record<string, FareAssignationStored>>;
  [EntityNames.incidents]: Readonly<Record<string, IncidentStored>>;
  objectsToFetch: Readonly<ObjectsToFetch>;
}>;

const initialState: EntityState = {
  [EntityNames.patients]: {},
  [EntityNames.fares]: {},
  [EntityNames.units]: {},
  [EntityNames.areas]: {},
  [EntityNames.healthCenters]: {},
  [EntityNames.porters]: {},
  [EntityNames.fareAssignations]: {},
  [EntityNames.incidents]: {},
  objectsToFetch: {},
};

const setEntities = (state: EntityState, action: PayloadAction<EntityState>) => {
  const nextState = { ...state };
  for (const key in action.payload) {
    // @ts-ignore
    nextState[key] = { ...nextState[key], ...action.payload[key] };
  }
  return nextState;
};

const updateEntity = (state: EntityState, action: PayloadAction<{ entityName: EntityNames; newObject: any }>) => {
  const { entityName, newObject } = action.payload;
  const nextState = { ...state };
  nextState[entityName] = {
    ...nextState[entityName],
    ...newObject,
  };
  return nextState;
};

const removeEntity = (state: EntityState, action: PayloadAction<{ entityName: EntityNames; objectId: string }>) => {
  const { entityName, objectId } = action.payload;
  const nextState = { ...state };
  const nextEntity = dissoc(objectId, nextState[entityName]);
  return {
    ...nextState,
    [entityName]: nextEntity,
  };
};

const resetObjectsToFetch = (state: EntityState) => ({ ...state, objectsToFetch: {} });

const reset = () => initialState;

const addObjectToFetch = (state: EntityState, action: PayloadAction<AddObjectToFetchPayload>) => {
  const nextState = { ...state };
  const { entityName, ids } = action.payload;
  const objectsToFetchForEntity = nextState.objectsToFetch[entityName];

  if (!objectsToFetchForEntity) {
    nextState.objectsToFetch = {
      ...nextState.objectsToFetch,
      [entityName]: [...ids],
    };
    return nextState;
  }
  nextState.objectsToFetch = {
    ...nextState.objectsToFetch,
    [entityName]: [...objectsToFetchForEntity, ...ids].filter(unique),
  };
  return nextState;
};

const { reducer, actions } = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntities,
    updateEntity,
    addObjectToFetch,
    removeEntity,
    resetObjectsToFetch,
  },
  extraReducers: (builder) => builder.addCase(resetAction, reset),
});

export { actions as entityActions, reducer as entityReducer };
