import { AccommodationSpecificity, AccommodationStatus, PatientGender } from '@ambuliz/sabri-core';
import { Box, Divider, IconButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem } from '@mui/material';
import { Bed, Cancel, ChevronRight, Exit, MoreVertical, Path } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useAuthentication } from 'core/authentication';
import { SetBedDialog } from 'kurt/pages/Unit/Dialogs';
import { useRef, useState } from 'react';
import CancelAccommodationDialog from '../CancelAccommodation';
import { ViaTrajectoireLink, ViaTrajectoireLoader } from '../PatientOutcome/ViaTrajectoireLink';
import ReportPatientDischargedDialog from '../ReportPatientDischarged';
import SendAccommodationRequestDialog from '../SendAccommodationRequest';
import { menuActionIcons } from './VisitActions/columnActions';

type Props = {
  id: string;
  unitId: string;
  visitId: string;
  bedId?: string;
  comment?: string;
  status?: AccommodationStatus;
  isCompleted?: boolean;
  specificities?: AccommodationSpecificity[];
  start?: Date;
  end?: Date;
  patient?: {
    name?: string;
    birthdate?: Date;
    gender?: PatientGender;
    isDischarged?: boolean;
  };
  onCancelAccommodation?: () => void;
  onOpenChange?: (open: boolean) => void;
  onDialogOpenChange?: (open: boolean) => void;
};

const AdmissionAccommodationAdmittedMenu = ({
  id,
  unitId,
  visitId,
  bedId,
  comment,
  status,
  start,
  end,
  patient,
  isCompleted,
  specificities = [],
  onCancelAccommodation,
  onOpenChange,
  onDialogOpenChange,
}: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const [action, setAction] = useState<Action>();

  const {
    healthCenter: { viaTrajectoireEnabled },
  } = useAuthentication();

  const handleMenuClose = () => {
    setOpen(false);
    onOpenChange?.(false);
  };

  const handleMenuItemClick = (action: Action) => {
    handleMenuClose();
    setAction(action);
    onDialogOpenChange?.(true);
  };

  const handleDialogClose = () => {
    setAction(undefined);
    onDialogOpenChange?.(false);
  };

  const handleSuccess = () => {
    if (action === 'CANCEL_ACCOMMODATION' && onCancelAccommodation) {
      handleDialogClose();
      handleMenuClose();
      return onCancelAccommodation();
    }
    handleDialogClose();
    handleMenuClose();
  };

  return (
    <Box display="flex" onClick={(event) => event.stopPropagation()}>
      <IconButton
        onClick={() => {
          onOpenChange?.(!open);
          setOpen(!open);
        }}
        ref={ref}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!isCompleted && (
          <MenuItem onClick={() => handleMenuItemClick('REQUEST_MOVEMENT')}>
            <ListItemIcon>
              <Path />
            </ListItemIcon>
            <ListItemText>{i18n.requestMovement.actionLabel}</ListItemText>
          </MenuItem>
        )}
        {!isCompleted && <Divider />}
        {!isCompleted && <ListSubheader>{i18n.moreActions}</ListSubheader>}
        {!isCompleted && !bedId && (
          <MenuItem onClick={() => handleMenuItemClick('SET_BED')}>
            <ListItemIcon>
              <Bed />
            </ListItemIcon>
            <ListItemText>{i18n.visitActions.SET_BED.form.title}</ListItemText>
          </MenuItem>
        )}
        {!isCompleted && (
          <MenuItem onClick={() => handleMenuItemClick('REPORT_PATIENT_DISCHARGED')}>
            <ListItemIcon>
              <Exit />
            </ListItemIcon>
            <ListItemText>{i18n.reportPatientDischarged.actionLabel}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuItemClick('CANCEL_ACCOMMODATION')}>
          <ListItemIcon sx={{ color: palette.error.main }}>
            <Cancel />
          </ListItemIcon>
          <ListItemText sx={{ color: palette.error.main }}>{i18n.cancelAccommodation.actionLabel}</ListItemText>
        </MenuItem>
        {!patient?.isDischarged && viaTrajectoireEnabled && (
          <>
            <Divider />
            <ListSubheader>{i18n.externalActions}</ListSubheader>
            <ViaTrajectoireLink
              openOnHover
              visitId={visitId}
              loader={
                <MenuItem>
                  <ViaTrajectoireLoader textMarginLeft={3} />
                </MenuItem>
              }
              button={
                <MenuItem>
                  <ListItemIcon>{menuActionIcons['VIA_TRAJECTOIRE_REDIRECT']}</ListItemIcon>
                  <ListItemText>{i18n.visitActions['VIA_TRAJECTOIRE_REDIRECT'].action.label}</ListItemText>
                  <ChevronRight style={{ marginLeft: 8 }} color="action" />
                </MenuItem>
              }
            />
          </>
        )}
      </Menu>
      {action === 'REPORT_PATIENT_DISCHARGED' && (
        <ReportPatientDischargedDialog
          open={action === 'REPORT_PATIENT_DISCHARGED'}
          onClose={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
        />
      )}
      {action === 'CANCEL_ACCOMMODATION' && (
        <CancelAccommodationDialog
          open={action === 'CANCEL_ACCOMMODATION'}
          onClose={handleDialogClose}
          onCancel={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
        />
      )}
      {action === 'REQUEST_MOVEMENT' && (
        <SendAccommodationRequestDialog
          open={action === 'REQUEST_MOVEMENT'}
          unitId={unitId}
          visitId={visitId}
          onClose={handleDialogClose}
          comment={comment}
          specificities={specificities}
        />
      )}
      {action === 'SET_BED' && (
        <SetBedDialog
          id={id}
          open={action === 'SET_BED'}
          initialUnitId={unitId}
          specificities={specificities}
          start={start}
          end={end}
          patient={patient}
          onClose={handleDialogClose}
          onSuccess={handleSuccess}
        />
      )}
    </Box>
  );
};

type Action = 'REPORT_PATIENT_DISCHARGED' | 'CANCEL_ACCOMMODATION' | 'REQUEST_MOVEMENT' | 'SET_BED';

export default AdmissionAccommodationAdmittedMenu;
