import { useContext } from 'react';
import TimelineContext from '../TimelineContext';
import TimeMarker from './TimeMarker';
import useTimeMarker from './useTimeMarker';

const CurrentTimeMarker = () => {
  const { start, end } = useContext(TimelineContext);
  const { position, time } = useTimeMarker();
  const now = new Date();

  if (now < start || now > end) {
    return null;
  }
  return <TimeMarker color="error" position={position} time={time} />;
};

export default CurrentTimeMarker;
