import {
  Box,
  Button,
  Checkbox,
  Divider,
  FilledInput,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  SvgIconProps,
  Typography,
} from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Cross } from 'common/components/Icons';
import HealthCenterIcon from 'common/components/Icons/HealthCenter';
import SearchIcon from 'common/components/Icons/Search';
import { i18n } from 'common/locale';
import { color as PaletteColor } from 'common/theme';
import { APP_BAR_SELECT_OPTIONS_WIDTH, appBarSelectStyles } from 'core/layout/components/AppBar/AppBarSelect';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const NavigationColor = {
  primary: PaletteColor.blue[30],
  warning: PaletteColor.yellow[60],
};

const MAX_HEIGHT = 385;

type NavigationInputProps = {
  title?: string;
  subtitle?: string;
  Icon?: (props: SvgIconProps) => JSX.Element;
  color?: 'primary' | 'warning';
};

const NavBarSelectContent = ({ title, subtitle, Icon, color = 'primary' }: NavigationInputProps) => {
  return (
    <Stack direction="row" spacing={3} alignItems="center" maxWidth="100%" overflow="hidden">
      {Icon && (
        <Box
          bgcolor={NavigationColor[color]}
          flexShrink={0}
          borderRadius={0.5}
          height={32}
          width={32}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Icon fill="white" />
        </Box>
      )}
      <Stack overflow="hidden">
        <EllipsisTypography variant="h6" color="secondary.dark" disableTooltip>
          {title}
        </EllipsisTypography>
        <EllipsisTypography variant="body2" color="secondary" disableTooltip>
          {subtitle}
        </EllipsisTypography>
      </Stack>
    </Stack>
  );
};

const normalizeString = (str: string) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const UnitNavigation = ({ value, options, onChange, label }: UnitNavigationProps) => {
  const [initialValue, setInitialValue] = useState(value);

  const [search, setSearch] = useState('');

  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const allOptions = useMemo(() => [...options.map((option) => option.children).flat()], [options]);
  const isAllSelected = useMemo(() => selectedUnits.length === allOptions.length, [selectedUnits, allOptions]);

  const selectTitle = useMemo(() => {
    if (initialValue.length === allOptions.length) {
      return i18n.healthCenter;
    }
    if (initialValue.length === 1) {
      return allOptions.find((option) => option.id === initialValue[0])?.name;
    }

    return `${initialValue.length} ${i18n.selectedUnits}`;
  }, [initialValue, allOptions]);

  useEffect(() => {
    setInitialValue(value);
    setSelectedUnits(value);
  }, [value]);

  const handleChange = (newValue: string[]) => {
    for (const option of options) {
      const children = option.children.map((child) => child.id);
      if (newValue.includes(option.id)) {
        newValue = newValue.filter((value) => value !== option.id);
        if (children.some((id) => selectedUnits.includes(id))) {
          newValue = newValue.filter((value) => !children.includes(value));
        } else {
          newValue = Array.from(new Set([...newValue, ...children]));
        }
      }
    }

    setSelectedUnits((old) => {
      const oldUnits = old.sort((a, b) => a.localeCompare(b));
      const newUnits = (newValue as string[]).filter(Boolean).sort((a, b) => a.localeCompare(b));

      if (JSON.stringify(oldUnits) === JSON.stringify(newUnits)) {
        return old;
      }

      return newUnits;
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    onChange(selectedUnits);
    setOpen(false);
  };

  const toggleSelectAll = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    if (selectedUnits.length === allOptions.length) {
      setSelectedUnits([]);
    } else {
      setSelectedUnits(allOptions.map((option) => option.id));
    }
  };

  const renderOption = ({
    id,
    name,
    children = [],
  }: {
    id: string;
    name: string;
    children?: { id: string; name: string }[];
  }) => {
    const isAllSelected = children.length > 0 && children.every((child) => selectedUnits.includes(child.id));
    const isSomeSelected =
      children.length > 0 && children.some((child) => selectedUnits.includes(child.id)) && !isAllSelected;

    if (children && children.length) {
      const elements = [
        <MenuItem value={id} key={id} className={isAllSelected || isSomeSelected ? 'Mui-selected' : undefined}>
          <Checkbox checked={isAllSelected} indeterminate={isSomeSelected} />
          <ListItemText primary={<Typography whiteSpace="normal">{name}</Typography>} />
        </MenuItem>,
      ];

      children.forEach(({ id, name }) => {
        const element = (
          <MenuItem value={id} key={`child-${id}`} style={{ paddingLeft: 24 }}>
            <Checkbox checked={selectedUnits.includes(id)} />
            <ListItemText primary={<Typography whiteSpace="normal">{name}</Typography>} />
          </MenuItem>
        );
        elements.push(element);
      });

      return elements;
    } else {
      const element = (
        <MenuItem value={id} key={`child-${id}`} style={{ paddingLeft: 24 }}>
          <Checkbox checked={selectedUnits.includes(id)} />
          <ListItemText primary={name} />
        </MenuItem>
      );
      return element;
    }
  };

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }

    const filteredOptions = [];

    for (const option of options) {
      if (normalizeString(option.name).includes(normalizeString(search))) {
        const filteredChildren = option.children.filter((child) =>
          normalizeString(child.name).includes(normalizeString(search))
        );
        filteredOptions.push({ ...option, children: filteredChildren });
      } else {
        for (const child of option.children) {
          if (!normalizeString(child.name).includes(normalizeString(search))) {
            continue;
          }
          filteredOptions.push(child);
        }
      }
    }

    return filteredOptions;
  }, [options, search]);

  return (
    <Select
      variant="filled"
      value={selectedUnits}
      multiple
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      inputProps={{ style: { border: 'none !important' } }}
      onChange={(event) => handleChange(event.target.value as string[])}
      renderValue={() => <NavBarSelectContent title={label} subtitle={selectTitle} Icon={HealthCenterIcon} />}
      sx={appBarSelectStyles}
      MenuProps={{
        TransitionProps: {
          onExited: () => {
            setSearch('');
            setSelectedUnits(initialValue);
          },
        },
        sx: {
          '& .MuiPaper-root': {
            maxHeight: MAX_HEIGHT,
            maxWidth: APP_BAR_SELECT_OPTIONS_WIDTH,
          },
        },
        MenuListProps: {
          autoFocusItem: false,
          style: { paddingTop: 0, paddingBottom: 0 },
        },
      }}
    >
      <Box
        position="sticky"
        top={0}
        bgcolor="white"
        zIndex={1}
        paddingX={3}
        paddingTop={3}
        paddingBottom={2}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
      >
        <FilledInput
          fullWidth
          endAdornment={
            search ? (
              <IconButton edge="end" onClick={() => setSearch('')}>
                <Cross />
              </IconButton>
            ) : (
              <SearchIcon />
            )
          }
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          placeholder={i18n.search}
        />
      </Box>
      <CustomMenuItem onClick={toggleSelectAll} className={isAllSelected ? 'Mui-selected' : undefined}>
        <Checkbox checked={isAllSelected} onClick={toggleSelectAll} />
        <Label onClick={toggleSelectAll}>{i18n.selectAll}</Label>
      </CustomMenuItem>
      <Divider />
      {filteredOptions.length > 0 ? filteredOptions.map(renderOption) : <MenuItem disabled>{i18n.noResult}</MenuItem>}

      <Stack
        direction="row"
        justifyContent="flex-end"
        padding={2}
        position="sticky"
        bottom={0}
        bgcolor="white"
        zIndex={1}
        spacing={4}
        borderTop={1}
        borderColor={PaletteColor.grey[10]}
      >
        {!!selectedUnits.length && (
          <Link
            variant="link2"
            color={PaletteColor.grey[80]}
            component="button"
            onClick={() => {
              setSelectedUnits([]);
            }}
          >
            {i18n.clear}
          </Link>
        )}
        <Button size="small" onClick={handleSubmit} disabled={selectedUnits.length === 0}>
          {i18n.access} ({selectedUnits.length})
        </Button>
      </Stack>
    </Select>
  );
};
type UnitNavigationProps = {
  label?: string;
  value: string[];
  options: { id: string; name: string; children: { id: string; name: string }[] }[];
  onChange: (ids: string[]) => void;
};

const Label = styled(ListItemText)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const CustomMenuItem = styled(MenuItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

export default UnitNavigation;
