import { Box, DialogContent, Stack } from '@mui/material';

import { Accommodation, Anomaly, HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useReadOnly } from 'core/authentication';
import { useContext } from 'react';
import DetailsDialogTimeline from './DetailsDialogTimeline';
import InfoCard from './InfoCard';
import { AvailableBedDetails } from './LocationDetails/AvailableBed/AvailableBedDetails';
import ClosedBedDetails from './LocationDetails/ClosedBed/ClosedBedDetails';
import HealthcareEnvironmentalCleaningDetails from './LocationDetails/HealthcareEnvironmentalCleaning/HealthcareEnvironmentalCleaningDetails';
import StepActions from './StepActions';
import AccommodationDetails from './VisitDetails/Accommodation/AccommodationDetails';
import FareDetails from './VisitDetails/Fare/FareDetails';
import MutationDetails from './VisitDetails/Mutation/MutationDetails';
import { DialogContext } from './context/DialogContext';
import { Step, StepItem, StepType } from './context/Step';

type DetailsDialogContentProps = {
  onClose: () => void;
};

const DetailsDialogContent = ({ onClose }: DetailsDialogContentProps) => {
  const {
    initialStep,
    stepItems,
    editMode,
    editableStep,
    onStepChange,
    visit,
    setAction,
    currentStep,
    onCurrentStepChange,
  } = useContext(DialogContext);

  const { isReadOnly } = useReadOnly();

  return (
    <>
      <DialogHeader
        onClose={onClose}
        title={getDialogTitle(initialStep)}
        dialogTitleProps={{ bgcolor: palette.background.default }}
        iconVariant="filled"
      />
      <DetailsDialogTimeline step={initialStep} stepItems={stepItems} />
      <DialogContent>
        <Stack direction="row" spacing={8}>
          <Box flexGrow={1}>
            <StepDetails
              step={editableStep?.type === 'closedBed' ? editableStep : initialStep}
              editable={editMode}
              onChange={(step) => onStepChange({ step })}
              onDestinationChange={(destination) => onStepChange({ destination })}
              onTabChange={onCurrentStepChange}
              currentTab={currentStep?.step.id}
              isReadOnly={isReadOnly}
            />
          </Box>
          <Stack width={260} flexShrink={0} spacing={6} justifyContent="space-between">
            {initialStep && <InfoCard step={initialStep} patient={visit} />}
            {currentStep && !isReadOnly && <StepActions step={currentStep} setAction={setAction} disabled={editMode} />}
          </Stack>
        </Stack>
      </DialogContent>
    </>
  );
};

const StepDetails = ({
  step,
  editable,
  currentTab,
  isReadOnly = false,
  onChange,
  onTabChange,
  onDestinationChange,
}: {
  step?: StepItem;
  editable: boolean;
  currentTab?: string;
  isReadOnly?: boolean;
  onChange: (step: Step) => void;
  onDestinationChange?: (destination: Accommodation) => void;
  onTabChange: (tab: string) => void;
}) => {
  switch (step?.type) {
    case 'accommodation':
      return (
        <AccommodationDetails
          step={step}
          editable={editable}
          currentTab={currentTab}
          onChange={onChange}
          onDestinationChange={onDestinationChange}
          onTabChange={onTabChange}
          isReadOnly={isReadOnly}
        />
      );
    case 'mutation':
      return (
        <MutationDetails
          step={step}
          currentTab={currentTab}
          editable={editable}
          onChange={onChange}
          onDestinationChange={onDestinationChange}
          onTabChange={onTabChange}
          isReadOnly={isReadOnly}
        />
      );
    case 'healthCareEnvironmentalCleaning':
      return <HealthcareEnvironmentalCleaningDetails healthcareEnvironmentalCleaning={step.step} />;
    case 'closedBed':
      return (
        <ClosedBedDetails accommodation={step.step} editable={editable} onChange={onChange} isReadOnly={isReadOnly} />
      );
    case 'fare':
      return <FareDetails fare={step.step} />;
    case 'availableBed':
      return <AvailableBedDetails availableBedStep={step} />;
    default:
      return null;
  }
};

export function getDialogTitle(step: StepType): string;
export function getDialogTitle(step?: StepItem): string;
export function getDialogTitle(step?: StepItem | StepType): string {
  if (!step) {
    return '';
  }

  if (
    ['closedBed', 'healthCareEnvironmentalCleaning', 'availableBed'].includes(
      typeof step !== 'string' ? step.type : step
    )
  ) {
    return i18n.detailsDialog.locationTitle;
  } else {
    return i18n.detailsDialog.visitTitle;
  }
}

export const getStepSchedule = (
  step: Accommodation | HealthcareEnvironmentalCleaning,
  anomalies: Anomaly[]
): 'past' | 'current' | 'future' => {
  const now = new Date();

  if (now < step.startAt) {
    return 'future';
  }

  if (step.endAt && now > step.endAt) {
    if (anomalies.some((anomaly) => ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END'].includes(anomaly.type))) {
      return 'current';
    }
    return 'past';
  }
  return 'current';
};
export default DetailsDialogContent;
