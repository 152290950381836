import { ReadOnlyContext } from 'core/authentication';
import { useContext } from 'react';

const useReadOnly = () => {
  const { isReadOnly } = useContext(ReadOnlyContext);

  return { isReadOnly };
};

export default useReadOnly;
