import { SvgIcon, SvgIconProps } from '@mui/material';

const ExpandIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_97_14395)">
      <path
        d="M1 0.375C0.654822 0.375 0.375 0.654822 0.375 1V4.2C0.375 4.54518 0.654822 4.825 1 4.825C1.34518 4.825 1.625 4.54518 1.625 4.2V2.50888L4.55806 5.44194C4.80214 5.68602 5.19786 5.68602 5.44194 5.44194C5.68602 5.19786 5.68602 4.80214 5.44194 4.55806L2.50888 1.625H4.2C4.54518 1.625 4.825 1.34518 4.825 1C4.825 0.654822 4.54518 0.375 4.2 0.375H1ZM11.8 0.375C11.4548 0.375 11.175 0.654822 11.175 1C11.175 1.34518 11.4548 1.625 11.8 1.625H13.4911L10.5581 4.55806C10.314 4.80214 10.314 5.19786 10.5581 5.44194C10.8021 5.68602 11.1979 5.68602 11.4419 5.44194L14.375 2.50888V4.2C14.375 4.54518 14.6548 4.825 15 4.825C15.3452 4.825 15.625 4.54518 15.625 4.2V1C15.625 0.654822 15.3452 0.375 15 0.375H11.8ZM5.44194 10.5581C5.68602 10.8021 5.68602 11.1979 5.44194 11.4419L2.50888 14.375H4.2C4.54518 14.375 4.825 14.6548 4.825 15C4.825 15.3452 4.54518 15.625 4.2 15.625H1C0.654822 15.625 0.375 15.3452 0.375 15V11.8C0.375 11.4548 0.654822 11.175 1 11.175C1.34518 11.175 1.625 11.4548 1.625 11.8V13.4911L4.55806 10.5581C4.80214 10.314 5.19786 10.314 5.44194 10.5581ZM11.4419 10.5581C11.1979 10.314 10.8021 10.314 10.5581 10.5581C10.314 10.8021 10.314 11.1979 10.5581 11.4419L13.4911 14.375H11.8C11.4548 14.375 11.175 14.6548 11.175 15C11.175 15.3452 11.4548 15.625 11.8 15.625H15C15.3452 15.625 15.625 15.3452 15.625 15V11.8C15.625 11.4548 15.3452 11.175 15 11.175C14.6548 11.175 14.375 11.4548 14.375 11.8V13.4911L11.4419 10.5581Z"
        fill="#666D8E"
      />
    </g>
    <defs>
      <clipPath id="clip0_97_14395">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ExpandIcon;
