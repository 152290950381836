import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalNames } from '../../const/modalNames';
import { modalActions } from '../../store/reducers/modal';
import { selectModalIsVisible, selectModalParams } from '../../store/selectors/modal';

type ModalContainerProps = {
  modalName: ModalNames;
  children: ({ isVisible, params, onClose }: { isVisible: boolean; params: any; onClose: () => void }) => void;
};

export const ModalContainer: React.FC<ModalContainerProps> = ({ modalName, children }) => {
  const dispatch = useDispatch();
  const isVisible = useSelector(selectModalIsVisible(modalName));
  const params = useSelector(selectModalParams(modalName));

  const handleCloseModal = () => dispatch(modalActions.hideModal(modalName));

  return <>{children({ isVisible, params, onClose: handleCloseModal })}</>;
};
