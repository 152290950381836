import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { i18n } from 'sabri/locales';
import { FormValue, formKeys } from './JourneyForm';

const JourneyEmergency = () => {
  const { watch } = useFormContext<FormValue>();
  const isEmergency = watch<string, boolean>(formKeys.isEmergency);
  return (
    <Stack direction="row" gap={4}>
      <Box width={54} height={40} display="flex" alignItems="center">
        <Typography variant="overline">{i18n.emergency}</Typography>
      </Box>
      <Controller
        name={formKeys.isEmergency}
        defaultValue={isEmergency}
        render={({ onChange, value }) => (
          <FormControlLabel
            control={<Checkbox color="primary" checked={value} onChange={(event) => onChange(event.target.checked)} />}
            label={i18n.urgent}
          />
        )}
      />
    </Stack>
  );
};

export default JourneyEmergency;
