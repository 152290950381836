import { HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import {
  TimelineRowEmoji,
  TimelineRowEmojiProps,
  TimelineRowItem,
  TimelineRowItemProps,
} from 'common/components/Timeline';
import { i18n } from 'common/locale';
import { useRef } from 'react';

type HealthcareEnvironmentalCleaningEventProps = {
  healthcareEnvironmentalCleaning: HealthcareEnvironmentalCleaning;
  emoji: TimelineRowEmojiProps;
} & TimelineRowItemProps;

const HealthcareEnvironmentalCleaningEvent = ({
  healthcareEnvironmentalCleaning,
  emoji,
  size,
  ...props
}: HealthcareEnvironmentalCleaningEventProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <TimelineRowItem
      borderLeft={['IN_PROGRESS', 'DONE'].includes(healthcareEnvironmentalCleaning.status)}
      borderRight={healthcareEnvironmentalCleaning.status === 'DONE'}
      striped={healthcareEnvironmentalCleaning.status === 'DONE'}
      ref={containerRef}
      cardContainerRef={containerRef}
      contentRef={contentRef}
      {...props}
    >
      <Stack spacing={3} direction="row" alignItems="center" flexWrap="nowrap" paddingX={4} ref={contentRef}>
        <TimelineRowEmoji {...emoji} />
        <Typography fontWeight={600} noWrap>
          {i18n.healthcareEnvironmentalCleaning}
        </Typography>
      </Stack>
    </TimelineRowItem>
  );
};

export default HealthcareEnvironmentalCleaningEvent;
