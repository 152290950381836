import { Components } from '@mui/material';
import color from '../color';
import shadows from '../shadows';

const MuiSwitch: Components['MuiSwitch'] = {
  defaultProps: { color: 'primary', disableRipple: true },
  styleOverrides: {
    root: {
      padding: 0,
      '&.MuiSwitch-root .MuiSwitch-switchBase': {
        padding: 0,
      },
      '&.MuiSwitch-sizeMedium .Mui-checked': {
        transform: 'translateX(16px)',
      },
      '&.MuiSwitch-sizeSmall .Mui-checked': {
        transform: 'translateX(11px)',
      },
    },
    switchBase: {
      padding: 0,
      margin: 1,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled': {
          color: 'white',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.4,
        },
      },
    },
    sizeMedium: {
      width: 40,
      height: 24,
      padding: 0,
      '.MuiSwitch-thumb': {
        width: 20,
        height: 20,
        margin: 1,
      },
    },
    sizeSmall: {
      width: 27,
      height: 16,
      padding: 0,
      '.MuiSwitch-thumb': {
        width: 14,
        height: 14,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.4,
        backgroundColor: color.grey[10],
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: color.grey[30],
      },
    },
    thumb: {
      boxSizing: 'border-box',
      border: `1px solid ${color.blue[10]}`,
      boxShadow: shadows[1],
      color: '#ffffff',
    },
    track: {
      borderRadius: 24 / 2,
      backgroundColor: color.grey[5],
      opacity: 1,
    },
  },
};

export default MuiSwitch;
