import { Accommodation, Anomaly } from "@ambuliz/sabri-core";
import { ClosedBedStep } from "../Step";

export const mapClosedBedStep = (closedBed: Accommodation, anomalies: Anomaly[] = []): ClosedBedStep => ({
    type: 'closedBed',
    step: closedBed,
    start: closedBed.startAt,
    end: closedBed.endAt,
    anomalies: anomalies.filter((anomaly) => anomaly.accommodation?.id === closedBed.id),
  });
