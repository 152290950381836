import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';
import { FormEvent } from 'react';

type DischargePatientDialogContentProps = {
  accommodationId: string;
  onClose: () => void;
  onBack?: () => void;
};

export const DischargePatientDialogContent = ({
  accommodationId,
  onClose,
  onBack,
}: DischargePatientDialogContentProps) => {
  const { update } = useAccommodation(accommodationId);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await update({ endAt: new Date(), status: 'COMPLETED', isEndEstimated: false });
      toast.success({ ...i18n.accommodationPatientDischargedSuccess, icon: <Emoji size={24} name="pencil" /> });
      onClose();
    } catch (error: any) {
      toast.error(error?.message || i18n.accommodationPatientDischargedError);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogHeader onClose={onClose} onBack={onBack} title={i18n.reportPatientDischarged.title} />
      <DialogContent>
        {i18n.reportPatientDischarged.content.map((text, index) => (
          <Typography color="secondary" key={index}>
            {text}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onBack || onClose}>
          {i18n.cancel}
        </Button>
        <Button type="submit">{i18n.save}</Button>
      </DialogActions>
    </form>
  );
};

type DischargePatientDialogProps = {
  accommodationId: string;
  open: boolean;
  onClose: () => void;
};

export const DischargePatientDialog = ({ open, onClose, accommodationId }: DischargePatientDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DischargePatientDialogContent accommodationId={accommodationId} onClose={onClose} />
    </Dialog>
  );
};
