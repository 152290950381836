"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateUserPassword = exports.generateRandomUserPassword = exports.generateRandomPassword = exports.isPasswordValid = exports.generateRandomPin = exports.isPinValid = void 0;
const generatePassword = (length = 20, characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$') => Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => characters[x % characters.length])
    .join('');
const isPinValid = (pin) => {
    const is4Digits = pin.length === 4;
    const hasOnlyDigits = /^\d+$/.test(pin);
    return {
        valid: is4Digits && hasOnlyDigits,
        is4Digits,
        hasOnlyDigits,
    };
};
exports.isPinValid = isPinValid;
const generateRandomPin = () => generatePassword(4, '0123456789');
exports.generateRandomPin = generateRandomPin;
const isPasswordValid = (password) => {
    const isAtLeast12Characters = password.length >= 12;
    const hasAtLeastOneUppercaseLetter = /[A-Z]/.test(password);
    const hasAtLeastOneDigit = /[0-9]/.test(password);
    const hasAtLeastOneSpecialCharacter = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/.test(password);
    return {
        valid: isAtLeast12Characters && hasAtLeastOneUppercaseLetter && hasAtLeastOneDigit && hasAtLeastOneSpecialCharacter,
        isAtLeast12Characters,
        hasAtLeastOneUppercaseLetter,
        hasAtLeastOneDigit,
        hasAtLeastOneSpecialCharacter,
    };
};
exports.isPasswordValid = isPasswordValid;
const generateRandomPassword = () => {
    const password = generatePassword(20);
    return (0, exports.isPasswordValid)(password).valid ? password : (0, exports.generateRandomPassword)();
};
exports.generateRandomPassword = generateRandomPassword;
const generateRandomUserPassword = (role) => {
    if (process.env.NODE_ENV === 'development') {
        return role === 'PORTER' ? '1234' : 'foo';
    }
    return role === 'PORTER' ? (0, exports.generateRandomPin)() : (0, exports.generateRandomPassword)();
};
exports.generateRandomUserPassword = generateRandomUserPassword;
const validateUserPassword = (password, role) => role === 'PORTER' ? (0, exports.isPinValid)(password).valid : (0, exports.isPasswordValid)(password).valid;
exports.validateUserPassword = validateUserPassword;
