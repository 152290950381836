import { Accommodation } from '@ambuliz/sabri-core';
import { Box, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { Comment } from 'common/components/Icons';
import TimelineRowEmoji, { TimelineRowEmojiProps } from 'common/components/Timeline/TimelineRowEmoji';
import TimelineRowItem, { TimelineRowItemProps } from 'common/components/Timeline/TimelineRowItem';
import { i18n } from 'common/locale';
import getShortDuration from 'common/utils/getShortDuration';
import { getInfoTextColor } from 'kurt/components/EventCard/cardColors';
import { useRef } from 'react';

type BedAccommodationCardEventProps = {
  accommodation: Accommodation;
  emoji: TimelineRowEmojiProps;
} & Pick<
  TimelineRowItemProps,
  | 'id'
  | 'start'
  | 'end'
  | 'color'
  | 'isStartOutdated'
  | 'isEndOutdated'
  | 'striped'
  | 'registerSticky'
  | 'size'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseLeave'
>;

const BedAccommodationCardEvent = ({
  accommodation,
  start,
  end,
  color,
  emoji,
  size = 'medium',
  ...props
}: BedAccommodationCardEventProps) => {
  const duration = end ? getShortDuration(start, end) : null;
  const cardContainer = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const infoTextColor = getInfoTextColor(color);
  const rowItemStackProps: StackProps =
    size === 'medium'
      ? {
          spacing: 2,
          direction: 'row',
          alignItems: 'center',
        }
      : {
          spacing: '1px',
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: `center`,
          width: `100%`,
          paddingY: '3px',
        };

  return (
    <TimelineRowItem
      start={start}
      end={end}
      borderLeft={['IN_PROGRESS', 'COMPLETED'].includes(accommodation.status)}
      borderRight={!!end && !accommodation.isEstimatedEnd}
      color={color}
      striped={accommodation.status === 'COMPLETED'}
      ref={cardContainer}
      cardContainerRef={cardContainer}
      contentRef={contentRef}
      size={size}
      {...props}
    >
      <Stack
        ref={contentRef}
        spacing={3}
        direction="row"
        alignItems="center"
        flexWrap="nowrap"
        flexShrink={0}
        paddingX={4}
        position="relative"
        flex={size === 'large' ? 1 : undefined}
      >
        <TimelineRowEmoji {...emoji} />
        <Stack {...rowItemStackProps}>
          <Typography variant="h6" noWrap>
            {accommodation.bed?.name && `${accommodation.bed?.name} -`}
            {accommodation.unit.name}
          </Typography>
          {(accommodation.comment ||
            (accommodation.specificities && accommodation.specificities?.length > 0) ||
            duration) && (
            <Stack
              divider={<Box bgcolor={infoTextColor} width={4} height={4} borderRadius={2} />}
              columnGap={2}
              alignItems="center"
              direction="row"
              flexWrap={size === 'medium' ? 'nowrap' : 'wrap'}
            >
              {accommodation.comment && (
                <Tooltip
                  title={
                    <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
                      {accommodation.comment}
                    </Typography>
                  }
                  placement="top"
                >
                  <div>
                    <Comment sx={{ fontSize: 12, color: infoTextColor }} />
                  </div>
                </Tooltip>
              )}
              {duration && (
                <Typography variant="body2" color={infoTextColor} noWrap>
                  {duration}
                </Typography>
              )}
              {accommodation.specificities?.map((specificity) => (
                <Typography key={specificity} variant="body2" color={infoTextColor} noWrap>
                  {i18n.accommodationSpecificities[specificity]}
                </Typography>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    </TimelineRowItem>
  );
};

export default BedAccommodationCardEvent;
