import Emoji, { EmojiType } from '../Emoji';
import { EmojiShape } from '../Emoji/type';

export type TimelineRowEmojiProps = {
  name: EmojiType;
  shape?: EmojiShape;
};

const TimelineRowEmoji = ({ name, shape = 'circle' }: TimelineRowEmojiProps) => {
  return <Emoji name={name} shape={shape} bgColor="white" />;
};

export default TimelineRowEmoji;
