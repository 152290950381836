import { CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

export const LoaderContent: React.FC = () => {
  const classes = useClasses();
  return (
    <Grid className={classes.container} container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress className={classes.circle} />
    </Grid>
  );
};

const useClasses = makeStyles({
  container: {
    height: '100%',
  },
  circle: {
    //Même couleur que le loader par défaut pour ne pas différencier le loader à l'init dans index.tsx
    //et le loader de l'app
    color: '#3F51B5',
  },
});
