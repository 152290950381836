import {
  DEFAULT_NOTIFICATION_CONFIGURATION,
  NotificationConfiguration,
  NotificationTriggerEvent,
  notificationTriggerEvent,
} from '@ambuliz/sabri-core';
import { FormLabel, IconButton, Stack, Switch, Typography } from '@mui/material';
import CrossIcon from 'common/components/Icons/Cross';
import PlusIcon from 'common/components/Icons/Plus';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useMemo, useState } from 'react';
import NotificationConfigDialog from './NotificationConfigDialog';

type NotificationsSettingsProps = {
  notificationConfigurations?: NotificationConfiguration[];
  onChange: (notificationConfigurations: NotificationConfiguration[]) => void;
};

export type TriggerEventOptionsGroups = 'fares' | 'porters' | 'movements' | 'others';

const NotificationsSettings = ({ notificationConfigurations, onChange }: NotificationsSettingsProps) => {
  const [notificationConfigToEdit, setNotificationConfigToEdit] = useState<NotificationConfiguration | undefined>(
    undefined
  );
  const [notificationConfigModalOpen, setNotificationConfigModalOpen] = useState(false);

  const disableAllNotifications = useMemo(
    () =>
      notificationConfigurations?.length === notificationTriggerEvent.length &&
      notificationConfigurations.every((config) => config.notificationsProviders.length === 0),
    [notificationConfigurations]
  );

  const toggleDisableAllNotifications = (disable: boolean) => {
    if (disable) {
      onChange(
        notificationTriggerEvent.map((triggerEvent) => {
          const config =
            notificationConfigurations?.find((config) => config.triggerEvent === triggerEvent) ||
            DEFAULT_NOTIFICATION_CONFIGURATION[triggerEvent];

          config.notificationsProviders = [];
          return config;
        })
      );
    } else {
      onChange([]);
    }
  };

  const onNotificationConfigChange = (newNotificationConfig: NotificationConfiguration) => {
    const newNotificationConfigurations = [
      ...(notificationConfigurations || []).filter(
        (notifConfig) => newNotificationConfig.triggerEvent !== notifConfig.triggerEvent
      ),
      newNotificationConfig,
    ];

    onChange(newNotificationConfigurations);
    setNotificationConfigModalOpen(false);
    setNotificationConfigToEdit(undefined);
  };
  const removeNotificationConfig = (notificationConfig: NotificationConfiguration) => {
    const newNotificationConfigurations = (notificationConfigurations || []).filter(
      (notifConfig) => notifConfig.triggerEvent !== notificationConfig.triggerEvent
    );

    onChange(newNotificationConfigurations);
  };
  const openNotificationConfigModal = (notificationConfigToEdit?: NotificationConfiguration) => {
    setNotificationConfigModalOpen(true);
    setNotificationConfigToEdit(notificationConfigToEdit);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" marginBottom={3}>
          {i18n.notificationsSettings}
        </Typography>
        {notificationConfigurations?.length !== notificationTriggerEvent.length && (
          <IconButton variant="filled" onClick={() => openNotificationConfigModal()}>
            <PlusIcon />
          </IconButton>
        )}
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormLabel htmlFor="disableAllNotifications">{i18n.disableAllNotifications}</FormLabel>
        <Switch
          id="disableAllNotifications"
          checked={disableAllNotifications}
          onChange={(event) => toggleDisableAllNotifications(event.target.checked)}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography color="text.secondary">{i18n.noNotificationConfigurations}</Typography>
        {notificationConfigurations &&
          notificationConfigurations.length > 0 &&
          !disableAllNotifications &&
          Object.entries(getTriggerEventOptions(notificationConfigurations.map((config) => config.triggerEvent))).map(
            ([group, triggerEvents]) => (
              <>
                {triggerEvents.length > 0 && (
                  <Typography variant="body2">
                    {getTriggerEventGroupLabel(group as TriggerEventOptionsGroups)}
                  </Typography>
                )}

                {triggerEvents.map((triggerEvent, index) => {
                  const notifConfig = notificationConfigurations.find((config) => config.triggerEvent === triggerEvent);

                  if (!notifConfig) {
                    return null;
                  }
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingX={4}
                      paddingY={1}
                      borderRadius={2}
                      sx={{
                        cursor: 'pointer',
                        ':hover': {
                          backgroundColor: palette.primary.medium,
                        },
                      }}
                      onClick={() => openNotificationConfigModal(notifConfig)}
                    >
                      <Typography>{getTriggerEventLabel(notifConfig.triggerEvent)}</Typography>

                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          removeNotificationConfig(notifConfig);
                        }}
                      >
                        <CrossIcon />
                      </IconButton>
                    </Stack>
                  );
                })}
              </>
            )
          )}
      </Stack>

      <NotificationConfigDialog
        open={notificationConfigModalOpen}
        assignedNotificationsConfig={notificationConfigurations}
        notificationConfigToEdit={notificationConfigToEdit}
        onClose={() => {
          setNotificationConfigModalOpen(false);
          setNotificationConfigToEdit(undefined);
        }}
        onSubmit={(notificationConfig) => onNotificationConfigChange(notificationConfig)}
      />
    </>
  );
};

export const getTriggerEventGroupLabel = (group: TriggerEventOptionsGroups) => {
  switch (group) {
    case 'fares':
      return i18n.missions;
    case 'porters':
      return i18n.porters;
    case 'movements':
      return i18n.movements;
    default:
      return i18n.OTHER;
  }
};

export const getTriggerEventLabel = (triggerEvent: NotificationTriggerEvent) =>
  i18n.notificationTriggerEvents[triggerEvent] || triggerEvent;

export const getTriggerEventOptions = (
  availableTriggerEvents: ReadonlyArray<NotificationTriggerEvent> = notificationTriggerEvent
) => {
  const options: Record<TriggerEventOptionsGroups, NotificationTriggerEvent[]> = {
    fares: [],
    porters: [],
    movements: [],
    others: [],
  };

  availableTriggerEvents.forEach((triggerEvent) => {
    const triggerEventGroup = getTriggerEventOptionGroup(triggerEvent);

    options[triggerEventGroup] = [...(options[triggerEventGroup] || []), triggerEvent].sort((a, b) =>
      getTriggerEventLabel(a).localeCompare(getTriggerEventLabel(b))
    );
  });
  return options;
};

export const getTriggerEventOptionGroup = (triggerEvent: NotificationTriggerEvent): TriggerEventOptionsGroups => {
  switch (triggerEvent) {
    case 'FARE_ASSIGNED':
    case 'FARE_UNASSIGNED':
    case 'FARE_UPDATED':
    case 'FARE_READY_TO_BE_ASSIGNED':
    case 'NEW_FARE_INCIDENT':
      return 'fares';
    case 'PORTER_SUPPORT_ASSIGNED':
    case 'PORTER_SUPPORT_UNASSIGNED':
    case 'PORTER_START_OF_SERVICE':
    case 'PORTER_END_OF_SERVICE':
      return 'porters';
    case 'NEW_PERFORMER_REQUEST':
    case 'PERFORMER_REQUEST_ACCEPTED':
    case 'PERFORMER_REQUEST_REJECTED':
      return 'movements';
    default:
      return 'others';
  }
};

export default NotificationsSettings;
