import { SvgIcon, SvgIconProps } from '@mui/material';

const Hourglass: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91 2.658C2.808 1.683 3.61.9 4.55.9a.6.6 0 1 1 0 1.2c-.296 0-.467.234-.446.433a.6.6 0 1 1-1.194.125ZM10.85 1.5a.6.6 0 0 1 .6-.6c.94 0 1.745.783 1.64 1.76a.6.6 0 1 1-1.192-.128c.02-.197-.15-.432-.448-.432a.6.6 0 0 1-.6-.6ZM3.575 12.808a.6.6 0 0 1 .528.664c-.022.19.145.428.448.428a.6.6 0 1 1 0 1.2c-.935 0-1.752-.78-1.64-1.764a.6.6 0 0 1 .664-.528Zm8.852 0a.6.6 0 0 1 .664.529c.111.983-.706 1.763-1.64 1.763a.6.6 0 0 1 0-1.2c.302 0 .469-.238.447-.428a.6.6 0 0 1 .529-.664Z"
      />
      <path d="M10.729 13.5H5.286c-.488 0-.625-.563-.283-.911.827-.84 2.498-1.44 2.498-2.402V7c0-.62-1.188-1.094-1.923-2.1-.12-.166-.109-.4.2-.4h4.46c.263 0 .32.232.2.398C9.714 5.906 8.5 6.376 8.5 7v3.187c0 .955 1.74 1.47 2.512 2.403.311.376.202.91-.284.91Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.95 1.5a.6.6 0 0 1 .6-.6h6.9a.6.6 0 1 1 0 1.2h-6.9a.6.6 0 0 1-.6-.6Zm-.505.499a.6.6 0 0 1 .659.534c.18 1.732.84 2.528 1.46 3.113.115.108.223.205.335.304.052.047.106.094.16.144.163.147.333.306.484.48.316.367.558.815.558 1.426 0 .608-.238 1.057-.55 1.426-.19.224-.445.455-.68.67-.104.092-.203.182-.292.267-.62.592-1.28 1.391-1.476 3.11a.6.6 0 1 1-1.192-.137c.234-2.061 1.069-3.104 1.84-3.84.149-.143.279-.26.394-.365.196-.176.35-.316.49-.48.182-.216.266-.4.266-.651 0-.248-.083-.43-.267-.642a4.338 4.338 0 0 0-.378-.373c-.043-.04-.09-.081-.14-.125a17.379 17.379 0 0 1-.376-.342c-.778-.734-1.614-1.781-1.83-3.86a.6.6 0 0 1 .535-.66Zm9.111 0a.6.6 0 0 1 .535.659c-.216 2.078-1.051 3.132-1.83 3.87-.13.125-.263.245-.383.352-.047.041-.091.08-.132.118-.157.141-.28.26-.38.374-.185.214-.265.39-.265.628 0 .24.08.42.264.636.099.116.221.235.376.377l.14.128c.116.105.245.223.371.344.77.74 1.604 1.791 1.839 3.852a.6.6 0 1 1-1.193.135c-.195-1.72-.858-2.526-1.477-3.122-.114-.11-.222-.207-.332-.307l-.16-.146a5.595 5.595 0 0 1-.478-.483C9.14 9.048 8.9 8.604 8.9 8c0-.606.244-1.051.558-1.414.15-.173.32-.331.482-.478l.151-.136c.115-.102.226-.202.343-.313.62-.59 1.282-1.393 1.463-3.126A.6.6 0 0 1 12.556 2Zm-8.605 12.5a.6.6 0 0 1 .6-.6h6.9a.6.6 0 1 1 0 1.2h-6.9a.6.6 0 0 1-.6-.6Z"
      />
    </SvgIcon>
  );
};

export default Hourglass;
