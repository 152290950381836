import { Stack, Tooltip, Typography, TypographyVariant } from '@mui/material';
import { Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { color, theme } from 'common/theme';
import styled from 'styled-components';
import { AccommodationWarningType } from './getAccommodationsWarning';

type AccommodationWarningProps = {
  label: string;
  variant?: TypographyVariant;
  warning?: AccommodationWarningType;
  emojiSize?: number;
};

const AccommodationWarning = ({ label, variant, warning, emojiSize }: AccommodationWarningProps) => {
  const rows = getRows(warning);
  return warning ? (
    <Tooltip
      title={
        rows.length > 1 ? (
          <Stack spacing={2}>
            {getRows(warning).map((row, index) => (
              <Stack key={index} direction="row" alignItems="center" spacing={2}>
                <Dot />
                {row}
              </Stack>
            ))}
          </Stack>
        ) : (
          <>{rows[0]}</>
        )
      }
      placement="top"
    >
      <Stack direction="row" alignItems="center" spacing={1} alignSelf="flex-start">
        <Emoji
          name="warning"
          size={emojiSize ? emojiSize : variant ? (theme.typography[variant].fontSize as number) : 14}
        />
        <Typography variant={variant} fontWeight={600} color={color.yellow[70]}>
          {label}
        </Typography>
      </Stack>
    </Tooltip>
  ) : (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Emoji
        name="warning"
        size={emojiSize ? emojiSize : variant ? (theme.typography[variant].fontSize as number) : 14}
      />
      <Typography variant={variant} fontWeight={600} color={color.yellow[70]}>
        {label}
      </Typography>
    </Stack>
  );
};

const getRows = (warning?: AccommodationWarningType) => {
  if (!warning) {
    return [];
  }
  const rows: React.ReactNode[] = [];
  if (warning.outdatedStartDate > 0) {
    rows.push(
      <Typography variant="body2">
        <Typography variant="body2" component="span" fontWeight={700}>
          {warning.outdatedStartDate + ' '}
        </Typography>
        {warning.outdatedStartDate > 1
          ? i18n.accommodationWarning.startDateOutdated.plural
          : i18n.accommodationWarning.startDateOutdated.singular}
      </Typography>
    );
  }
  if (warning.outdatedEndDate.previsional > 0) {
    rows.push(
      <Typography variant="body2">
        <Typography variant="body2" component="span" fontWeight={700}>
          {warning.outdatedEndDate.previsional + ' '}
        </Typography>
        {warning.outdatedEndDate.previsional > 1
          ? i18n.accommodationWarning.endDateOutdated.previsional.plural
          : i18n.accommodationWarning.endDateOutdated.previsional.singular}
      </Typography>
    );
  }
  if (warning.outdatedEndDate.validated > 0) {
    rows.push(
      <Typography variant="body2">
        <Typography variant="body2" component="span" fontWeight={700}>
          {warning.outdatedEndDate.validated + ' '}
        </Typography>
        {warning.outdatedEndDate.validated > 1
          ? i18n.accommodationWarning.endDateOutdated.validated.plural
          : i18n.accommodationWarning.endDateOutdated.validated.singular}
      </Typography>
    );
  }
  if (warning.overlapped > 0) {
    rows.push(
      <Typography variant="body2">
        <Typography variant="body2" component="span" fontWeight={700}>
          {warning.overlapped + 1 + ' '}
        </Typography>
        {i18n.accommodationWarning.bedOverlapped}
      </Typography>
    );
  }

  return rows;
};

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: white;
`;

export default AccommodationWarning;
