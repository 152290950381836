import { datadogLogs } from '@datadog/browser-logs';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import React, { ErrorInfo, ReactNode, useEffect } from 'react';

export default class ErrorBoundary extends React.Component<
  { children: JSX.Element },
  { error?: Error; errorInfo?: ErrorInfo }
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }
  render() {
    return <ErrorCatcher error={this.state.error}>{this.props.children}</ErrorCatcher>;
  }
}

const INVALID_SESSION_TOKEN = 'INVALID_SESSION_TOKEN';
const UNAUTHORIZED_IP = 'UNAUTHORIZED_IP';

const ErrorCatcher: React.FC<{ children?: ReactNode; error?: Error; errorInfo?: ErrorInfo }> = ({
  children,
  error,
  errorInfo,
}) => {
  useEffect(() => {
    // Error on promise
    const onError = (err: PromiseRejectionEvent) => {
      if (
        err.reason.code === INVALID_SESSION_TOKEN ||
        (err.reason.statusCode === 403 && err.reason.code === UNAUTHORIZED_IP)
      ) {
        toast.warning(i18n.invalidSession);
        localStorage.clear();
        window.location.reload();
      } else if (process.env.NODE_ENV === 'development') {
        console.error('Catched Error on promise : ', err);
      }
    };
    window.onunhandledrejection = onError;
    return () => {
      window.onunhandledrejection = null;
    };
  }, [error]);
  useEffect(() => {
    // Error on render
    if (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Catched Error :', { error, errorInfo });
      } else {
        datadogLogs.logger.error('Catched Error on render', { error, errorInfo });
      }
    }
  }, [error, errorInfo]);
  return <>{children}</>;
};
