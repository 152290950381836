import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type DataItemListProps = {
  children: ReactNode;
};

const DataItemList = ({ children }: DataItemListProps) => {
  return (
    <Stack spacing={3} direction="row" alignItems="center">
      {children}
    </Stack>
  );
};

export default DataItemList;
