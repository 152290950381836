import { SvgIcon, SvgIconProps } from '@mui/material';

const Save = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      d="M.52.52C.188.855 0 1.307 0 1.779v12.444A1.778 1.778 0 0 0 1.778 16h12.444C15.2 16 16 15.2 16 14.222V3.556L12.444 0H1.778C1.306 0 .854.187.52.52Zm3.98.98h4.667v3H4.5v-3ZM3 6V1.5H1.778a.278.278 0 0 0-.278.278v12.444a.278.278 0 0 0 .278.278h12.444c.15 0 .278-.128.278-.278V4.177L11.823 1.5h-1.156V6H3Zm3.5 4.3c0-.832.668-1.5 1.5-1.5s1.5.668 1.5 1.5-.668 1.5-1.5 1.5-1.5-.668-1.5-1.5Zm-1.5 0c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default Save;
