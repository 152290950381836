import { Box, Card, MenuItem, Stack, SxProps, Theme } from '@mui/material';
import { FilterSelect } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Manager } from 'common/components/Icons';
import HealthCenter from 'common/components/Icons/HealthCenter';
import { color } from 'common/theme';
import shadows from 'common/theme/shadows';
import { AppBarSelectOption, useAppBarContext } from 'core/layout';
import UnitNavigation from 'kurt/pages/Unit/UnitNavigation';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const APP_BAR_SELECT_VALUE_WIDTH = 300;
export const APP_BAR_SELECT_OPTIONS_WIDTH = 360;

export const appBarSelectStyles: SxProps<Theme> = {
  width: APP_BAR_SELECT_VALUE_WIDTH,
  '&.MuiInputBase-root': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    padding: 2,
    borderRadius: 1,
    backgroundColor: '#fff',
    transition: 'box-shadow 0.2s ease-in-out, color 0.2s ease-in-out',
    boxShadow: shadows[1],
    '&.MuiInputBase-input': {
      paddingRight: '31px',
    },
    '&:hover': {
      boxShadow: shadows[2],
    },
    '&:focus': {
      backgroundColor: '#fff',
      boxShadow: shadows[2],
      borderRadius: 1,
    },
    border: `1px solid ${color.grey[10]}`,
  },
};

const AppBarSelect = () => {
  const { appBarSelect } = useAppBarContext();

  if (appBarSelect?.multiple) {
    return <MultipleAppBarSelect />;
  }

  return <SingleAppBarSelect />;
};

const mapOptions = (options?: AppBarSelectOption[]) => {
  if (!options) {
    return [];
  }

  return options
    .map((option) => ({
      id: option.id,
      name: option.label,
      children: option.children
        .map(({ id, label }) => ({ id, name: label }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

const mapValue = (value?: string | string[]) => {
  if (!value) {
    return [];
  }

  return Array.isArray(value) ? value : [];
};

const MultipleAppBarSelect = () => {
  const { appBarSelect, setAppBarSelect } = useAppBarContext();

  const options = useMemo(() => mapOptions(appBarSelect?.options), [appBarSelect?.options]);
  const value = useMemo(() => mapValue(appBarSelect?.value), [appBarSelect?.value]);

  const handleChange = (value: string[]) => {
    setAppBarSelect({
      ...appBarSelect,
      value,
    });
  };

  return <UnitNavigation onChange={handleChange} options={options} value={value} label={appBarSelect?.label} />;
};

const SingleAppBarSelect = () => {
  const { appBarSelect, setAppBarSelect } = useAppBarContext();
  const navigate = useNavigate();

  const [value, setValue] = useState<string>();

  useEffect(() => {
    setValue(appBarSelect?.value as string);
  }, [appBarSelect]);

  const handleChange = (value: string) => {
    if (appBarSelect) {
      setValue(value);
      setAppBarSelect({ ...appBarSelect, value });

      const path = appBarSelect.options?.find((option) => option.id === value)?.path;
      if (path) {
        navigate(path);
      }
    }
  };

  return (
    <>
      {value && appBarSelect?.options && appBarSelect.options.some(({ id }) => id === value) ? (
        <FilterSelect
          value={value}
          onChange={handleChange}
          searchable
          variant="filled"
          renderValue={(value) =>
            appBarSelect.label ? (
              <RenderSelectValue
                icon={appBarSelect.icon}
                label={appBarSelect.label}
                subLabel={appBarSelect.options?.find(({ id }) => id === value)?.label || value}
              />
            ) : (
              <RenderSelectValue
                icon={appBarSelect.icon}
                label={appBarSelect.options?.find(({ id }) => id === value)?.label || value}
                subLabel={appBarSelect.subLabel}
              />
            )
          }
          sx={appBarSelectStyles}
        >
          {appBarSelect.options?.map(({ id, label }) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </FilterSelect>
      ) : (
        appBarSelect && (
          <Card sx={{ padding: '8px 12px 8px', width: APP_BAR_SELECT_VALUE_WIDTH }} elevation={1}>
            {appBarSelect.label ? (
              <RenderSelectValue
                icon={appBarSelect.icon}
                label={appBarSelect.label}
                subLabel={appBarSelect.subLabel || value}
              />
            ) : (
              <RenderSelectValue icon={appBarSelect.icon} label={value} subLabel={appBarSelect?.subLabel} />
            )}
          </Card>
        )
      )}
    </>
  );
};

const RenderSelectValue: React.FC<{
  label?: string;
  subLabel?: string;
  icon?: React.ReactNode;
}> = ({ label, subLabel, icon }) => {
  return (
    <Stack direction="row" spacing={3} alignItems="center">
      {icon || <HealthCenterIcon />}
      <Stack overflow="hidden">
        <EllipsisTypography variant="h6" color="secondary.dark" disableTooltip>
          {label}
        </EllipsisTypography>
        {subLabel && (
          <EllipsisTypography variant="body2" color="secondary" disableTooltip>
            {subLabel}
          </EllipsisTypography>
        )}
      </Stack>
    </Stack>
  );
};

const HealthCenterIcon = () => (
  <Box
    bgcolor={color.blue[30]}
    flexShrink={0}
    borderRadius={0.5}
    height={32}
    width={32}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <HealthCenter fill="white" />
  </Box>
);

export const ManagerIcon = () => (
  <Box
    bgcolor={color.yellow[60]}
    flexShrink={0}
    borderRadius={0.5}
    height={32}
    width={32}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Manager fill="white" />
  </Box>
);

export default AppBarSelect;
