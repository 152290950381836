import Api from '@ambuliz/sabri-core';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { requestNames, requests } from '../../const/requests';
import { getCookie, removeCookie, setCookie } from '../../utils/cookie';
import { safeSaga } from '../../utils/safeSaga';
import { getAreasAction } from '../actions/area';
import {
  getCurrentUserAction,
  getCurrentUserType,
  LoginPayload,
  loginType,
  logoutType,
  resetAction,
} from '../actions/general';
import { subscribeLiveQueriesAction, unsubscribeLiveQueriesAction } from '../actions/liveQuery';
import { generalActions, User } from '../reducers/general';

function* login(action: PayloadAction<LoginPayload>) {
  const { username, password } = action.payload;
  //@ts-ignore
  const currentUser = yield call(requests.login, { username, password });
  setCookie('sessionToken', requests.getCurrentUser()?.getSessionToken(), window.location.hostname, '/');
  if (currentUser) {
    yield put(getCurrentUserAction());
  }
}

// 2 façons de se connecter : soit on récupère un sessionToken présent dans les cookies et on vient modifier le currentUser du localStorage, soit on récupère directement le currentUser du localStorage
function* getCurrentUser() {
  const user = Api.User.current();
  if (!user) {
    throw Error();
  }
  yield call(init, user.toJSON() as User);
}

function* logout() {
  if (getCookie('sessionToken')) {
    removeCookie('sessionToken', window.location.hostname, '/');
  }
  yield all([call(requests.logout), put(resetAction()), put(unsubscribeLiveQueriesAction())]);
}

function* init(currentUser: User) {
  yield all([put(generalActions.userLogged(currentUser)), put(getAreasAction()), put(subscribeLiveQueriesAction())]);
}

export function* generalRootSaga() {
  yield takeLatest(getCurrentUserType, safeSaga(getCurrentUser, requestNames.getCurrentUser));
  yield put(getCurrentUserAction());
  yield takeLatest(loginType, safeSaga(login, requestNames.login));
  yield takeLatest(logoutType, safeSaga(logout, requestNames.logout));
}
