import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import React from 'react';
import { AccommodationAction } from '..';
import { ActionProps } from '../ActionForm';

type CancelBedProps = ActionProps & {
  action: AccommodationAction;
};

const CancelBed: React.FC<CancelBedProps> = ({ loading = false, action, onCancel, onSubmit }) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ bedId: null, status: 'PLANNED' });
  };

  return (
    <form onSubmit={handleSubmit}>
      {i18n.visitActions[action].form.content?.map((line, index) => (
        <Typography color="secondary" key={index}>
          {line}
        </Typography>
      ))}
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton type="submit" loading={loading} disabled={loading}>
          {i18n.visitActions[action].form.confirmLabel}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default CancelBed;
