import { Stack } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';

export type AccommodationSearchFilter = {
  date: Date;
  page: number;
  rowsPerPage: number;
};

export type AccommodationSearchFiltersProps = {
  filters: AccommodationSearchFilter;
  onFiltersChange: (filters: AccommodationSearchFilter) => void;
};

export const AccommodationSearchFilters = ({ filters, onFiltersChange }: AccommodationSearchFiltersProps) => {
  return (
    <Stack direction="row" justifyContent="space-around" width="fit-content">
      <DatePicker value={filters?.date} onChange={(date) => date && onFiltersChange({ ...filters, date })} />
    </Stack>
  );
};
