import { Stack, ToggleButtonGroup, ToggleButtonGroupProps, Typography } from '@mui/material';
import { palette } from 'common/theme';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const BORDER_WIDTH = 1;

const ActiveToggle = styled.div<{
  height: number;
  width: number;
  transform: string;
  color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'danger';
}>`
  z-index: -1;
  position: absolute;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  transform: ${({ transform }) => transform};
  will-change: transform, width, height;
  transition: transform 200ms ease 0s, width 200ms ease 0s, height 200ms ease 0s;
  margin: -1px 0 0 -1px;
  border-radius: 8px;
  border: ${({ color }) => `${BORDER_WIDTH}px solid ${palette[color].main}`};
  background-color: ${({ color }) => palette[color].light};
`;

type ToggleGroupProps = {
  onChange: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  value: string;
  label?: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  fullWidth?: boolean;
  children: React.ReactNode;
  variant?: ToggleButtonGroupProps['variant'];
  size?: ToggleButtonGroupProps['size'];
};

const ToggleGroup = ({
  label,
  children,
  onChange,
  value,
  color = 'primary',
  fullWidth = false,
  size = 'medium',
  variant = 'outlined',
}: ToggleGroupProps) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [transform, setTransform] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  children?.toString();

  const setActiveTogglePosition = (activeElt: HTMLButtonElement) => {
    if (!activeElt) {
      return;
    }
    timeoutRef.current = setTimeout(() => {
      const boudingRect = activeElt.getBoundingClientRect();
      setTransform(
        `translateX(${
          boudingRect.left -
          (ref.current?.getBoundingClientRect().left || 0) -
          (variant === 'filled' ? 2 : BORDER_WIDTH)
        }px)`
      );
      setHeight(boudingRect.height + (variant === 'filled' ? 0 : BORDER_WIDTH) * 2);
      setWidth(boudingRect.width + (variant === 'filled' ? 0 : BORDER_WIDTH) * 2);
    });
  };

  const handleOnChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
    if (value !== null) {
      setActiveTogglePosition(event.currentTarget as HTMLButtonElement);
      onChange(event, value);
    }
  };

  useEffect(() => {
    setActiveTogglePosition(ref.current?.querySelector(`.Mui-selected`) as HTMLButtonElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, value]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  return (
    <Stack direction="row" spacing={4} alignItems="center" flex={fullWidth ? 1 : undefined}>
      {label && <Typography color={color}>{label}</Typography>}
      <ToggleButtonGroup
        ref={ref}
        onChange={handleOnChange}
        value={value}
        exclusive
        color={color}
        style={{ zIndex: 1, flex: fullWidth ? 1 : undefined }}
        size={size}
        variant={variant}
      >
        {children}
        {width && variant !== 'filled' && (
          <ActiveToggle height={height} width={width} transform={transform} color={color} />
        )}
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ToggleGroup;
