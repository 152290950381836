import { useAuthentication } from 'core/authentication';
import { useContext } from 'react';
import { UserManagementComponent } from './UserManagement.component';
import { UserManagementContext } from './context';

export const UserManagement = () => {
  const { users, loading, createUser, updateUser, filters, pagination } = useContext(UserManagementContext);
  const { healthCenter, user } = useAuthentication();

  return (
    <UserManagementComponent
      connectedUser={user}
      filters={filters}
      pagination={pagination}
      users={users}
      healthCenter={healthCenter}
      onCreateUser={createUser}
      onUpdateUser={updateUser}
      usersLoading={loading}
    />
  );
};
