import { Accommodation } from '@ambuliz/sabri-core';

export const getStatus = (accommodation: Accommodation) => {
  if (accommodation.status === 'IN_PROGRESS') {
    return 'ADMITTED';
  }
  if (!accommodation.bed) {
    return 'TO_ASSIGN';
  }
  if (accommodation.status === 'PLANNED') {
    return 'ASSIGNED';
  }
  return undefined;
};
