import { Stack } from '@mui/material';
import { PatientSummary } from 'common/components/PatientAutocomplete';
import { SwitcherMenu, SwitcherMenuOptionType } from 'common/components/SwitcherMenu';
import { i18n } from 'common/locale';
import { Visit } from 'kurt/pages/Movements/useVisits';
import { FC, useEffect, useState } from 'react';
import AccommodationSummary from './AccommodationSummary';
import MutationSummary from './MutationSummary';

type VisitSummaryProps = {
  visit: Visit;
  flow: 'admission' | 'discharge';
  status: 'planned' | 'validated' | 'completed' | 'requested';
};

const VisitSummary: FC<VisitSummaryProps> = ({ visit, flow, status }) => {
  const [selectedPage, setSelectedPage] = useState<SwitcherMenuOptionType>();
  const [menuOptions, setMenuOptions] = useState<SwitcherMenuOptionType[]>([]);

  useEffect(() => {
    const options: SwitcherMenuOptionType[] = [];

    if (visit?.basedOn || (flow === 'discharge' && visit.nextAccommodation?.basedOn)) {
      options.push({ label: i18n.visitSummary.movementRequest, type: 'mutation' });
    }

    if (!visit.isMutationRequest || flow === 'discharge') {
      if (visit.status === 'PLANNED') {
        options.push({ label: i18n.visitSummary.plannedAccommodation, type: 'default' });
      }
      if (visit.status === 'IN_PROGRESS') {
        options.push({ label: i18n.visitSummary.inProgressAccommodation, type: 'default' });
      }
      if (visit.status === 'COMPLETED') {
        options.push({ label: i18n.visitSummary.completedAccommodation, type: 'default' });
      }
    }

    setMenuOptions(options);
    setSelectedPage((prev) => (options.some((option) => option.label === prev?.label) ? prev : options[0]));
  }, [visit, status, flow]);

  return (
    <Stack spacing={5}>
      <PatientSummary
        primary
        large
        birthdate={visit.patient.birthdate}
        gender={visit.patient.gender}
        name={visit.patient.fullName}
        ipp={visit.patient.ipp}
        pan={visit.pan}
        ins={visit.patient.ins}
      />

      <Stack spacing={4}>
        <SwitcherMenu options={menuOptions} onChange={setSelectedPage} selected={selectedPage?.label} />
        {visit && selectedPage?.type === 'mutation' ? (
          <MutationSummary flow={flow} accommodation={visit.currentAccommodation} />
        ) : (
          <AccommodationSummary flow={flow} status={status} visit={visit} />
        )}
      </Stack>
    </Stack>
  );
};

export default VisitSummary;
