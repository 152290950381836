import { Box, Stack, useMediaQuery } from '@mui/material';
import { theme } from 'common/theme';
import { Children, ReactNode } from 'react';

const Switchers = ({ children }: { children: ReactNode }) => {
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack gap={matchUpMd ? 4 : 2} alignItems="center" direction="row" flexWrap="wrap">
      {Children.toArray(children).map((child, index) => (
        <Box key={index}>{child}</Box>
      ))}
    </Stack>
  );
};

export default Switchers;
