import { color } from 'common/theme';
import styled from 'styled-components';

const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-image: ${() => `linear-gradient(to right, ${color.grey[10]} 50%, #fff 0%)`};
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x;
`;

export default HorizontalDivider;
