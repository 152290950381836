import { LoadingButton } from '@mui/lab';
import { Button, Link, Stack, Typography } from '@mui/material';
import { DateTimeInput } from 'common/components/Form';
import { i18n } from 'common/locale';
import { isValid } from 'date-fns';
import React, { useState } from 'react';

type ConfirmAdmissionProps = {
  onCancel: () => void;
  onSubmit: ({ startAt, status }: { startAt: Date; status: 'IN_PROGRESS' }) => void;
  loading?: boolean;
};

const ConfirmAdmission: React.FC<ConfirmAdmissionProps> = ({ onCancel, onSubmit, loading = false }) => {
  const [admissionDate, setAdmissionDate] = useState<Date | null>(new Date());
  const [error, setError] = useState(false);

  const handleChange = (date: Date | null) => {
    setError(false);
    setAdmissionDate(date);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate(admissionDate) && !!admissionDate) {
      onSubmit({ startAt: admissionDate, status: 'IN_PROGRESS' });
    } else {
      setError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Typography color="secondary">{i18n.visitActions.CONFIRM_ADMISSION.form.inputLabel}</Typography>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <DateTimeInput
              noRange
              value={admissionDate}
              onChange={(date) => handleChange(date)}
              maxDate={new Date()}
              error={error}
            />
            <Link component="button" onClick={() => handleChange(new Date())} type="button">
              <Typography variant="body2" color="primary">
                {i18n.dateNow}
              </Typography>
            </Link>
          </Stack>
          {error && (
            <Typography variant="body2" color="error">
              {i18n.visitActions.CONFIRM_ADMISSION.error?.inputError}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton type="submit" loading={loading} disabled={loading}>
          {i18n.visitActions.CONFIRM_ADMISSION.form.confirmLabel}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default ConfirmAdmission;

const validate = (admissionDate: Date | null) => !!admissionDate && isValid(admissionDate);
