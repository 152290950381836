import { FareStatus } from '@ambuliz/sabri-core';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { colors } from '../../const/colors';
import { i18n } from '../../locales';

export type ChipFareStatusProps = {
  status: FareStatus | 'PULLED';
  isReturn?: boolean;
  size?: 'small' | 'medium';
};

export const ChipFareStatus: React.FC<ChipFareStatusProps> = ({ status, isReturn, size }) => {
  const classes = useClasses({ status });
  const chipClasses = { label: classes.text };

  const getLabel = () => {
    if (isReturn) {
      return `${i18n.fareStatus[status]} | ${i18n.return}`;
    }
    return i18n.fareStatus[status];
  };

  return (
    <Chip
      size={size || 'small'}
      label={size === 'medium' ? getLabel().toUpperCase() : getLabel()}
      className={classes.chipContainer}
      classes={chipClasses}
    />
  );
};

const useClasses = makeStyles(({ palette }) => ({
  text: {
    color: palette.common.white,
  },
  chipContainer: ({ status }: ChipFareStatusProps) => ({
    backgroundColor: colors.fareStatus[status],
  }),
}));
