import * as React from 'react';
import { SVGProps } from 'react';

const Mission = (props: SVGProps<SVGSVGElement>) => (
  <svg width="110" height="90" viewBox="0 0 110 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.671875 46.2603C0.671875 33.3152 7.42746 29.126 11.0506 23.3894C11.8431 22.1062 12.7112 20.8608 13.6547 19.6908C18.9006 13.3504 31.808 0.518555 43.6586 0.518555C57.4717 0.518555 61.0193 9.01022 70.8696 13.5391C80.7199 18.068 90.7967 18.8605 97.4768 19.8418C104.195 20.8231 109.327 25.9558 109.327 32.2585C109.327 38.5612 105.78 49.6192 97.892 56.1106C90.0042 62.602 81.5125 65.7723 67.5107 67.1687C53.5089 68.5651 53.6976 80.7931 48.3761 85.8881C43.0547 91.0208 29.6567 93.5872 25.0901 79.7741C21.467 68.1122 13.6169 67.5083 7.50294 61.7718C1.38895 56.0352 0.671875 50.4495 0.671875 46.2603Z"
      fill="#E6EDFF"
    />
    <path
      d="M58.1128 81.2842C59.4714 83.1335 63.0568 82.7939 65.3967 81.0578C67.7367 79.3217 68.0763 76.7176 66.7177 74.8683C65.359 73.019 62.7549 72.5661 60.415 74.3022C58.075 76.076 56.7164 79.4349 58.1128 81.2842Z"
      fill="#E6EDFF"
    />
    <path
      d="M72.7572 4.02891C71.9647 7.19913 75.55 10.86 79.5506 11.8412C84.4191 13.0112 87.5516 10.5958 88.1932 7.87847C88.797 5.34984 87.1364 1.46254 82.4566 0.292577C78.4561 -0.688681 73.5498 0.858692 72.7572 4.02891Z"
      fill="#E6EDFF"
    />
    <path
      d="M75.6243 72.8684C75.0959 71.623 73.2466 71.2456 71.6992 71.9249C70.1519 72.6042 69.6235 74.0006 70.1519 75.2461C70.6802 76.4915 72.0766 77.0576 73.624 76.3783C75.1714 75.699 76.1526 74.0761 75.6243 72.8684Z"
      fill="#E6EDFF"
    />
    <path
      d="M53.0181 80.4536L18.4854 54.3747C18.1834 54.1483 18.1457 53.7331 18.3721 53.4312L56.1883 3.31153C56.4148 3.0096 56.8299 2.97186 57.1319 3.19831L91.6269 29.2771C91.9288 29.5036 91.9665 29.9187 91.7401 30.2207L53.9616 80.3026C53.7352 80.6045 53.32 80.68 53.0181 80.4536Z"
      fill="#B5CAFF"
    />
    <path d="M58.4068 7.80115L23.2754 54.3379L51.7095 75.8033L86.8409 29.2666L58.4068 7.80115Z" fill="#89A5ED" />
    <path
      d="M86.909 29.3147C86.909 29.3147 61.4717 36.5986 62.8304 52.8649C64.1891 69.1311 51.7346 75.849 51.7346 75.849L22.2969 51.544C22.2969 51.544 34.7513 44.8261 33.3926 28.5598C32.034 12.2936 58.4902 7.87793 58.4902 7.87793L86.909 29.3147Z"
      fill="white"
    />
    <path d="M65.2743 4.30999L60.4082 10.7559L83.4507 28.151L88.3168 21.7052L65.2743 4.30999Z" fill="#3561D3" />
    <path
      d="M17.2771 21.4653C21.0081 21.4653 24.0327 18.4407 24.0327 14.7097C24.0327 10.9787 21.0081 7.9541 17.2771 7.9541C13.5461 7.9541 10.5215 10.9787 10.5215 14.7097C10.5215 18.4407 13.5461 21.4653 17.2771 21.4653Z"
      fill="#89A5ED"
    />
    <path
      d="M16.3712 14.4074L12.0687 13.728C11.9177 13.6903 11.8045 13.8035 11.8045 13.9545C11.7668 14.5583 11.9177 15.0867 12.2197 15.5773C12.5971 16.1057 13.1255 16.4454 13.8048 16.5586C14.4841 16.6718 15.088 16.5208 15.6163 16.1434C16.0692 15.8038 16.4089 15.3509 16.5221 14.747C16.5976 14.5583 16.4844 14.4451 16.3712 14.4074Z"
      fill="white"
    />
    <path
      d="M88.2672 68.6407C91.4771 68.6407 94.0792 66.0386 94.0792 62.8287C94.0792 59.6188 91.4771 57.0166 88.2672 57.0166C85.0572 57.0166 82.4551 59.6188 82.4551 62.8287C82.4551 66.0386 85.0572 68.6407 88.2672 68.6407Z"
      fill="#B5CAFF"
    />
    <path
      d="M89.0974 62.8288L92.796 63.2817C92.9092 63.2817 92.9847 63.3949 92.9847 63.5081C92.8715 64.0365 92.645 64.4139 92.2299 64.7158C91.777 65.0555 91.2863 65.2065 90.6825 65.131C90.0786 65.0555 89.6257 64.7913 89.2861 64.3384C88.9841 63.961 88.8332 63.4704 88.8709 62.9797C88.8709 62.9043 88.9841 62.791 89.0974 62.8288Z"
      fill="white"
    />
  </svg>
);

export default Mission;
