import { PatientGender } from '@ambuliz/sabri-core';
import { formatFirstname, getPatientEmojiIcon } from 'common/utils';
import Emoji from '../Emoji';

type InlinePatientNameWithEmojiProps = {
  firstName: string;
  lastName: string;
  age: number;
  gender: PatientGender;
  bgColor?: string;
};

const InlinePatientNameWithEmoji: React.FC<InlinePatientNameWithEmojiProps> = ({
  firstName,
  lastName,
  age,
  gender,
  bgColor = 'white',
}) => {
  return (
    <>
      <span style={{ wordBreak: 'break-all', fontWeight: 600 }}>
        {<Emoji name={getPatientEmojiIcon(gender, age)} bgColor={bgColor} size="extra-small" inline />}&nbsp;
        {lastName.toUpperCase()}
      </span>
      <span style={{ fontWeight: 600 }}> {formatFirstname(firstName)} </span>
    </>
  );
};

export default InlinePatientNameWithEmoji;
