import { WebUserRole } from '@ambuliz/sabri-core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAction } from '../actions/general';

export type User = {
  createdAt: string;
  objectId: string;
  sessionToken: string;
  updatedAt: string;
  username: string;
  role: WebUserRole;
  unit?: Parse.Pointer;
};

export type UserStored = {
  objectId: string;
  sessionToken: string;
  username: string;
  role: WebUserRole;
  unitId?: string;
};

type GeneralState = Readonly<{
  currentUser?: UserStored;
}>;

const initialState: GeneralState = {};

const reset = () => initialState;

const userLogged = (state: GeneralState, action: PayloadAction<User>) => {
  const { sessionToken, objectId, username, role, unit } = action.payload;
  const currentUser = {
    sessionToken,
    objectId,
    username,
    role,
    unitId: unit?.objectId,
  };
  return { ...state, currentUser };
};

const { reducer, actions } = createSlice({
  name: 'general',
  initialState,
  reducers: {
    userLogged,
  },
  extraReducers: (builder) => builder.addCase(resetAction, reset),
});

export { reducer as generalReducer, actions as generalActions };
