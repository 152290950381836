import Api from '@ambuliz/sabri-core';
import useParseQuery from './useParseQuery';

type UseParseResourceOptions = {
  include?: string[];
  enabled?: boolean;
};

const useParseResource = <T extends new (...args: any[]) => Parse.Object<Parse.Attributes>>(
  id?: string,
  resourceType?: T,
  options?: UseParseResourceOptions
) => {
  const isEnabled = !!id && !!resourceType && (options?.enabled === undefined || options?.enabled);

  const query = isEnabled ? new Api.Query(resourceType).equalTo('objectId', id).limit(1) : null;

  if (options?.include) {
    query?.include(options?.include);
  }

  const { results, isLoading } = useParseQuery(query, {
    enabled: isEnabled,
  });

  return {
    resource: results[0] as InstanceType<T> | undefined,
    isLoading,
  };
};

export default useParseResource;
