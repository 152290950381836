import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ListPagination, SortableTableCell } from 'common/components';
import { Search } from 'common/components/Images';
import { usePagination } from 'common/hooks';
import { i18n } from 'common/locale';
import { formatName } from 'common/utils';
import { useAuthentication } from 'core/authentication';
import { EmptyContent, PageSection, useAppBarContext } from 'core/layout';
import {
  BED_CELL_MIN_WIDTH,
  EDITABLE_TEXT_CELL_MIN_WIDTH,
  PATIENT_CELL_MIN_WIDTH,
  PRACTITIONERS_CELL_MAX_WIDTH,
} from 'kurt/const';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Resource, UnitManagementFilter } from '../../UnitManagement';
import { filterResources, flatResources } from '../../UnitManagement/useResources';
import { AvailableBedRow, BusyBedRow, ClosedBedRow, SkeletonRow } from './Rows';
import CleaningRow from './Rows/CleaningRow';
import useFilteredRows from './useFilteredRows';

const List = ({
  resources,
  loading,
  displayUnitColumn,
  filter,
  onFiltered,
  isReadOnly = false,
}: {
  resources: Resource[];
  loading?: boolean;
  displayUnitColumn: boolean;
  filter: UnitManagementFilter;
  onFiltered?: (filteredRows: Resource[]) => void;
  isReadOnly?: boolean;
}) => {
  const { healthCenter } = useAuthentication();
  const { isFullScreen } = useAppBarContext();
  const navigation = useRef(useNavigate());

  const rows = useMemo(() => flatResources(resources), [resources]);
  const filteredRows = useMemo(() => {
    const result = filterResources(rows, filter, healthCenter.bookedBedDelayRule);

    if (onFiltered) {
      onFiltered(result);
    }
    return result;
  }, [rows, filter, healthCenter.bookedBedDelayRule, onFiltered]);
  const { page, changePage, rowsPerPage, changeRowsPerPage } = usePagination(filteredRows.length);

  const { filteredRows: sortedRows, order, orderBy, handleSort } = useFilteredRows(filteredRows);

  const paginatedRows = useMemo(
    () => sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [sortedRows, page, rowsPerPage]
  );

  const handleClick = useCallback((id: string) => {
    navigation.current(`accommodation/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  const handleClickOnCleaningRow = useCallback((id: string) => {
    navigation.current(`cleaning/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  const handleClickOnAvailableBedRow = useCallback((id: string) => {
    navigation.current(`available-bed/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  return (
    <>
      <PageSection
        withBackground
        noGutter
        noMargin={isFullScreen}
        paddingTop={!isFullScreen ? 8 : 10}
        fullHeight
        lastSection
      >
        {!loading && filteredRows.length === 0 ? (
          <EmptyContent Image={Search} {...i18n.bedListEmptyContent} />
        ) : (
          <Paper>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={28} sx={{ paddingRight: 0 }}></TableCell>
                    <SortableTableCell
                      order={order}
                      name="bedName"
                      orderBy={orderBy}
                      onSort={handleSort('bedName')}
                      sx={{ paddingLeft: 1, minWidth: `${BED_CELL_MIN_WIDTH}px` }}
                    >
                      {i18n.listViewHeads.bed}
                    </SortableTableCell>
                    {displayUnitColumn && (
                      <SortableTableCell name="unit" order={order} orderBy={orderBy} onSort={handleSort('unit')}>
                        {i18n.unit}
                      </SortableTableCell>
                    )}
                    <SortableTableCell name="status" order={order} orderBy={orderBy} onSort={handleSort('status')}>
                      {i18n.listViewHeads.status}
                    </SortableTableCell>
                    <SortableTableCell
                      name="patientName"
                      order={order}
                      orderBy={orderBy}
                      onSort={handleSort('patientName')}
                      sx={{ minWidth: `${PATIENT_CELL_MIN_WIDTH}px` }}
                    >
                      {i18n.listViewHeads.patient}
                    </SortableTableCell>
                    <SortableTableCell
                      name="patientBirthdate"
                      order={order}
                      orderBy={orderBy}
                      onSort={handleSort('patientBirthdate')}
                    >
                      {i18n.listViewHeads.age}
                    </SortableTableCell>
                    <TableCell width={PRACTITIONERS_CELL_MAX_WIDTH}>{i18n.practitioners}</TableCell>
                    <SortableTableCell order={order} name="startAt" orderBy={orderBy} onSort={handleSort('startAt')}>
                      {i18n.listViewHeads.from}
                    </SortableTableCell>
                    <SortableTableCell
                      align="center"
                      name="duration"
                      order={order}
                      orderBy={orderBy}
                      onSort={handleSort('duration')}
                    >
                      {i18n.listViewHeads.duration}
                    </SortableTableCell>
                    <SortableTableCell order={order} name="endAt" orderBy={orderBy} onSort={handleSort('endAt')}>
                      {i18n.listViewHeads.to}
                    </SortableTableCell>
                    <SortableTableCell
                      name="specificities"
                      order={order}
                      orderBy={orderBy}
                      onSort={handleSort('specificities')}
                      width={100}
                    >
                      {i18n.listViewHeads.specificities}
                    </SortableTableCell>
                    <TableCell sx={{ minWidth: EDITABLE_TEXT_CELL_MIN_WIDTH, width: '15%' }}>{i18n.comments}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? paginatedRows.map((_, index) => <SkeletonRow key={index} displayUnitColumn={displayUnitColumn} />)
                    : paginatedRows.map(({ bed, accommodation, anomalies, healthcareEnvironmentalCleaning }) =>
                        !accommodation && !healthcareEnvironmentalCleaning ? (
                          <AvailableBedRow
                            key={bed.id}
                            name={bed.name}
                            bedId={bed.id}
                            unitId={bed.unitId}
                            unitName={bed.unitName}
                            gender={bed.gender}
                            displayUnitColumn={displayUnitColumn}
                            nextSlotAt={bed.nextSlotAt}
                            status={bed.status}
                            isReadOnly={isReadOnly}
                            onClick={handleClickOnAvailableBedRow}
                          />
                        ) : accommodation && accommodation.visit?.id ? (
                          <BusyBedRow
                            key={bed.id + accommodation.id}
                            accommodationId={accommodation.id}
                            bedId={bed.id}
                            unitId={bed.unitId}
                            unitName={bed.unitName}
                            status={accommodation.status}
                            name={bed.name}
                            patient={{
                              name: formatName(
                                accommodation.visit.firstName,
                                accommodation.visit.lastName,
                                accommodation.visit.legalName,
                                accommodation.visit.legalFirstName
                              ),
                              gender: accommodation.visit.gender,
                              birthdate: accommodation.visit.birthdate,
                            }}
                            startAt={accommodation.startAt}
                            endAt={accommodation.endAt}
                            specificities={accommodation.specificities}
                            practitioners={accommodation.practitioners}
                            comment={accommodation.comment}
                            visitId={accommodation.visit.id}
                            isEstimatedEnd={accommodation.isEstimatedEnd}
                            anomalies={anomalies}
                            displayUnitColumn={displayUnitColumn}
                            onClick={handleClick}
                            isReadOnly={isReadOnly}
                          />
                        ) : healthcareEnvironmentalCleaning ? (
                          <CleaningRow
                            key={healthcareEnvironmentalCleaning.id}
                            id={healthcareEnvironmentalCleaning.id}
                            startAt={healthcareEnvironmentalCleaning.startAt}
                            endAt={healthcareEnvironmentalCleaning.endAt}
                            name={bed?.name || '-'}
                            bedGender={bed?.gender || 'UNKNOWN'}
                            unitName={bed?.unitName || '-'}
                            displayUnitColumn={displayUnitColumn}
                            onClick={handleClickOnCleaningRow}
                          />
                        ) : (
                          accommodation && (
                            <ClosedBedRow
                              id={accommodation.id}
                              status={accommodation.status}
                              key={bed.id + accommodation.id}
                              name={bed.name}
                              unitName={bed.unitName}
                              startAt={accommodation.startAt}
                              endAt={accommodation.endAt}
                              specificities={accommodation.specificities}
                              comment={accommodation.comment}
                              gender={bed.gender}
                              anomalies={anomalies}
                              displayUnitColumn={displayUnitColumn}
                              onClick={handleClick}
                              isReadOnly={isReadOnly}
                            />
                          )
                        )
                      )}
                </TableBody>
              </Table>
            </TableContainer>
            <ListPagination
              count={filteredRows.length}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={i18n.accommodationsByPage}
            />
          </Paper>
        )}
      </PageSection>
    </>
  );
};

export default List;
