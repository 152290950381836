import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckCirle: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 13 13">
      <g clipPath="url(#clip0_1850_89875)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8206 6.5C11.8206 9.29594 9.554 11.5625 6.75806 11.5625C3.96212 11.5625 1.69556 9.29594 1.69556 6.5C1.69556 3.70406 3.96212 1.4375 6.75806 1.4375C9.554 1.4375 11.8206 3.70406 11.8206 6.5ZM12.7581 6.5C12.7581 9.81371 10.0718 12.5 6.75806 12.5C3.44435 12.5 0.758057 9.81371 0.758057 6.5C0.758057 3.18629 3.44435 0.5 6.75806 0.5C10.0718 0.5 12.7581 3.18629 12.7581 6.5ZM10.1604 5.37504C10.3435 5.19198 10.3435 4.89518 10.1604 4.71212C9.97737 4.52906 9.68057 4.52906 9.49751 4.71212L5.84735 8.36228L4.01861 6.53347C3.83556 6.35041 3.53876 6.3504 3.3557 6.53346C3.17264 6.71651 3.17263 7.01331 3.35569 7.19637L5.3833 9.22406C5.63958 9.48035 6.0551 9.48036 6.31139 9.22407L10.1604 5.37504Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1850_89875">
          <rect width="12" height="12" fill="white" transform="translate(0.758057 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CheckCirle;
