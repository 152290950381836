"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnitFromArea = exports.encodeExternalIdToUtf8 = void 0;
const Area_1 = require("../models/Area");
const Unit_1 = require("../models/Unit");
const encodeExternalIdToUtf8 = (stringToEncode) => Buffer.from(stringToEncode, 'utf8').toString();
exports.encodeExternalIdToUtf8 = encodeExternalIdToUtf8;
const getUnitFromArea = (area) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!area.healthCenter) {
        return;
    }
    const unit = yield new Parse.Query(Unit_1.Unit).equalTo('healthCenter', area.healthCenter).equalTo('areas', area).first();
    if (unit) {
        return unit;
    }
    if (!((_a = area.parent) === null || _a === void 0 ? void 0 : _a.id)) {
        return;
    }
    const parent = yield new Parse.Query(Area_1.Area)
        .equalTo('objectId', area.parent.id)
        .select(['healthCenter', 'parent'])
        .first();
    if (!parent) {
        return;
    }
    return (0, exports.getUnitFromArea)(parent);
});
exports.getUnitFromArea = getUnitFromArea;
