import { SVGProps } from 'react';

const Bed = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 164 151" fill="none" {...props}>
    <path
      d="M62.53 15.908c-16.667 1.275-20.386 2.82-30.924 10.73C14.126 39.76 1.912 61.482 4.346 83.377c1.722 15.5 10.393 29.471 21.032 40.789 6.54 6.959 14.02 13.223 22.734 17.05 9.774 4.3 20.715 5.34 31.334 4.543 11.41-.856 22.797-3.854 32.593-9.816 13.584-8.262 23.584-22.138 27.706-37.61 4.123-15.477 2.434-32.384-4.198-46.968-1.856-4.08-4.106-8.019-7.051-11.384-3.267-3.722-7.314-6.65-11.521-9.235-16.28-10.026-35.414-15.24-54.445-14.837Z"
      fill="#E6EDFF"
    />
    <path
      d="M104.449 90.378c-3.351 0-6.07-2.729-6.07-6.077V74.075c0-4.736-3.864-8.6-8.6-8.6H79.555c-3.349 0-6.077-2.718-6.077-6.07V32.732a6.087 6.087 0 0 1 6.077-6.077H89.78c4.735 0 8.599-3.854 8.599-8.59V7.84c0-3.352 2.719-6.08 6.07-6.08h26.673c3.349 0 6.071 2.728 6.071 6.08v10.226c0 4.735 3.86 8.589 8.596 8.589h10.226a6.087 6.087 0 0 1 6.08 6.077v26.673c0 3.352-2.728 6.07-6.08 6.07h-10.226c-4.736 0-8.596 3.864-8.596 8.6v10.226c0 3.348-2.722 6.077-6.071 6.077"
      fill="#799AF1"
    />
    <path d="M43.57 101.873V81.497h81.348c11.502 0 20.94 9.032 21.593 20.376" fill="#fff" />
    <path
      d="M124.918 80.237H42.31v22.886h105.496c0-12.633-10.252-22.886-22.888-22.886Zm0 2.52c10.376 0 18.966 7.808 20.209 17.847H44.829V82.756"
      fill="#fff"
    />
    <path d="m28.818 102.637 12.082 6.372 17.91-33.97-12.092-6.372-17.9 33.97Z" fill="#799AF1" />
    <path
      d="M145.009 140.005V110.22H25.116v29.785h-6.303v-33.859L2.23 72.714a3.153 3.153 0 1 1 5.65-2.794l16.202 32.675h120.959l.279.009a6.117 6.117 0 0 1 5.995 6.146v31.255"
      fill="#B5CAFF"
    />
    <path
      d="M5.05 66.903a4.413 4.413 0 0 0-3.945 6.376l16.448 33.163v34.823h8.822v-29.786h117.374v29.786h8.826V108.75a7.407 7.407 0 0 0-7.222-7.405v-.01H24.866l-15.86-31.98a4.395 4.395 0 0 0-3.955-2.453Zm0 2.518c.732 0 1.378.404 1.706 1.05l15.857 31.983.689 1.4h121.641l.344.01a4.86 4.86 0 0 1 4.769 4.886v29.996h-3.788V108.96H23.856v29.786h-3.785v-32.895l-.262-.528L3.362 72.16a1.902 1.902 0 0 1 .85-2.545c.268-.125.553-.194.839-.194Z"
      fill="#B5CAFF"
    />
    <path
      d="M38.591 137.001c0 7.455-6.044 13.499-13.499 13.499-7.458 0-13.502-6.044-13.502-13.499 0-7.454 6.044-13.502 13.502-13.502 7.455 0 13.499 6.048 13.499 13.502ZM161.488 140.559c0 5.494-4.447 9.941-9.93 9.941a9.939 9.939 0 0 1-9.941-9.941c0-5.49 4.451-9.94 9.941-9.94 5.483 0 9.93 4.45 9.93 9.94Z"
      fill="#5470B8"
    />
  </svg>
);

export default Bed;
