import { AnomalyStatus, AnomalyType } from '@ambuliz/sabri-core';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useAnomaliesPageFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const status = useMemo(() => (searchParams.get('status') ?? '') as AnomalyStatus, [searchParams]);
  const type = useMemo(() => (searchParams.get('type') ?? '') as AnomalyType, [searchParams]);
  const areaId = useMemo(() => (searchParams.get('areaId') ?? '') as string, [searchParams]);

  const resetFilters = () => setSearchParams({});

  return {
    status,
    type,
    areaId,
    resetFilters,
  };
};

export default useAnomaliesPageFilters;
