import { ExternalPatientTransportStatus } from '@ambuliz/sabri-core';
import { Chip, Stack } from '@mui/material';
import { i18n } from 'common/locale';
import { color } from 'common/theme';

type ExternalTransportStatusChipProps = {
  status: ExternalPatientTransportStatus;
};

const ExternalTransportStatusChip: React.FC<ExternalTransportStatusChipProps> = ({ status }) => {
  return (
    <Stack direction="row" sx={{ bgcolor: getColors(status).background, borderRadius: 0.5, minWidth: 100 }}>
      <Chip
        label={status ? i18n.externalTransportStatus[status] : ''}
        color={statusColor[status]}
        variant="light"
        sx={{ flex: 1 }}
      />
    </Stack>
  );
};

type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

const statusColor: Record<ExternalPatientTransportStatus, ChipColor> = {
  VALIDATED: 'primary',
  IN_PROGRESS: 'primary',
  PLANNED: 'primary',
  DONE: 'secondary',
  CANCELED: 'warning',
};

const getColors = (status: ExternalPatientTransportStatus): { icon: string; background: string } => {
  switch (status) {
    case 'DONE':
      return { icon: color.grey[40], background: color.grey[10] };

    case 'VALIDATED':
    case 'IN_PROGRESS':
    case 'PLANNED':
    default:
      return { icon: color.blue[30], background: color.blue[20] };
  }
};

export default ExternalTransportStatusChip;
