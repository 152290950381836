import { Box, Stack, Typography } from '@mui/material';
import NoResultsImage from './NoResults.svg';

const NoResults = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <Stack spacing={4}>
      <img src={NoResultsImage} alt="empty results" />
      <Stack spacing={2} alignItems="center">
        <Typography>Aucun mouvement trouvé</Typography>
        <Typography variant="body2" color="secondary">
          Essayez autre chose !
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export default NoResults;
