import { SVGProps } from 'react';

const CheckboxCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={11} height={11} rx={1.5} fill="#0D088F" stroke="#0D088F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.78 3.47a.75.75 0 0 1 0 1.06L5.641 8.67a.2.2 0 0 1-.282 0l-3.14-3.14A.75.75 0 0 1 3.28 4.47l2.15 2.149a.1.1 0 0 0 .14 0l3.15-3.15a.75.75 0 0 1 1.06 0Z"
      fill="#fff"
    />
  </svg>
);

export default CheckboxCheckedIcon;
