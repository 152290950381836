import { EmergencyOccupancy } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { FormContainer, FormSection, InputIncrement } from 'common/components';
import { ArrowLeft } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import CommentInput from 'kurt/components/Department/EditModal/CommentInput';
import { EmergencyOccupancyMaybeEmpty } from '../List/EmergencyOccupancyList';

const EMERGENCY_OCCUPANCY_FORM_MIN_WIDTH = 574;

type EditEmergencyOccupancyFormProps = {
  occupancy: EmergencyOccupancyMaybeEmpty;
  loading?: boolean;
  isDirty: boolean;
  onChange?: (field: keyof EmergencyOccupancy) => (value: EmergencyOccupancy[typeof field]) => void;
  onClose: () => void;
  onSubmit: () => void;
};

const EditEmergencyOccupancyForm = ({
  occupancy,
  loading,
  isDirty,
  onChange,
  onClose,
  onSubmit,
}: EditEmergencyOccupancyFormProps) => {
  const handleChange = (field: keyof EmergencyOccupancy) => (value: EmergencyOccupancy[typeof field]) => {
    onChange?.(field)(value);
  };

  return (
    <Container sx={{ minWidth: EMERGENCY_OCCUPANCY_FORM_MIN_WIDTH }}>
      <FormContainer label={i18n.emergencyOccupancy.modal.title}>
        <FormSection
          label={
            occupancy.service === 'adults'
              ? i18n.emergencyOccupancy.modal.adultsOccupancy
              : i18n.emergencyOccupancy.modal.pediatricOccupancy
          }
        >
          <FormNumberInput
            name={i18n.emergencyOccupancy.modal.currentPatients}
            value={occupancy.currentPatients}
            onChange={handleChange('currentPatients')}
          />
          <FormNumberInput
            name={i18n.emergencyOccupancy.modal.availableBeds}
            value={occupancy.availableBeds}
            onChange={handleChange('availableBeds')}
          />
        </FormSection>

        <FormSection label={i18n.emergencyOccupancy.modal.waitingPatientsBySector}>
          <Stack display="grid" gridTemplateColumns="repeat(auto-fill, minmax(180px, 1fr))" gap={6}>
            {Object.entries(occupancy.waitingPatientsBySector || {}).map(([sector, value]) => (
              <FormNumberInput
                key={sector}
                name={sector}
                value={value}
                onChange={(value) =>
                  handleChange('waitingPatientsBySector')({ ...occupancy.waitingPatientsBySector, [sector]: value })
                }
              ></FormNumberInput>
            ))}
          </Stack>
        </FormSection>

        <FormSection label={i18n.commentInput.label}>
          <Box style={{ marginBottom: 24 }}>
            <CommentInput value={occupancy.comment} onChange={(event) => handleChange('comment')(event.target.value)} />
          </Box>
        </FormSection>
      </FormContainer>

      <Box
        padding="16px 0 40px 0"
        position="sticky"
        bottom={0}
        bgcolor="white"
        borderTop={'1px solid ' + color.grey[20]}
      >
        <Stack justifyContent="flex-end" spacing={4} direction="row">
          <Button variant="outlined" startIcon={<ArrowLeft />} onClick={onClose}>
            {i18n.goBack}
          </Button>
          <LoadingButton loading={loading} disabled={loading || !isDirty} onClick={onSubmit}>
            {i18n.save}
          </LoadingButton>
        </Stack>
      </Box>
    </Container>
  );
};

const FormNumberInput = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value?: number;
  onChange: (value: number) => void;
}) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
    <Typography>{name}</Typography>
    <InputIncrement value={value} onChange={onChange} allowNegativeValue />
  </Stack>
);

export default EditEmergencyOccupancyForm;
