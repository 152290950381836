import { SvgIcon, SvgIconProps } from '@mui/material';

const SprayIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          d="M7.706 6.672a1.207 1.207 0 0 0-.638-.334V4.57a.622.622 0 0 0-1.244 0v1.747H3.202V4.571a.621.621 0 0 0-.622-.621h-.478a.453.453 0 1 1 0-.906h7.124v.906c-.213.003-.397 0-.557-.002l-.082-.001a12.33 12.33 0 0 0-.354-.003 1.768 1.768 0 0 0-.336.03.589.589 0 0 0-.293.146c-.163.156-.186.393-.194.614-.004.133-.002.301 0 .502.002.155.004.328.003.52v.004a.622.622 0 0 0 1.244 0v-.567h.605a1.208 1.208 0 0 0 1.207-1.206v-.98A1.208 1.208 0 0 0 9.263 1.8H2.11a1.698 1.698 0 0 0-.152 3.392v1.146a1.21 1.21 0 0 0-.992 1.187v.623a1.202 1.202 0 0 0 .438.925L.253 12.532l-.002.004a.622.622 0 0 0 1.18.393l1.19-3.575h3.784l1.887 5.637a.622.622 0 0 0 1.18-.395l-1.85-5.523a1.209 1.209 0 0 0 .437-.925v-.623c0-.32-.127-.627-.353-.853ZM6.54 8.111H2.485a.275.275 0 0 1 0-.55h4.056a.275.275 0 0 1 0 .55Z"
          clipRule="evenodd"
        />
        <path d="M12.014 2.483a.626.626 0 1 0 .001 1.25.626.626 0 0 0-.001-1.25ZM13.558 1.535a.626.626 0 1 0 .002 1.25.626.626 0 0 0-.002-1.25ZM15.104.584a.626.626 0 1 0 .002 1.251.626.626 0 0 0-.002-1.25ZM15.104 2.483a.625.625 0 1 0 .001 1.25.625.625 0 0 0-.001-1.25ZM13.558 3.43a.626.626 0 1 0 .002 1.252.626.626 0 0 0-.002-1.251ZM15.104 4.379a.625.625 0 1 0 .001 1.25.625.625 0 0 0-.001-1.25Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default SprayIcon;
