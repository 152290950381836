import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { Box } from '@mui/material';
import { getPatientAge, getPatientEmojiIcon } from 'common/utils';
import AccommodationCardEvent from 'kurt/components/EventCard/Variants/AccommodationCardEvent';
import { getCardColor } from 'kurt/components/EventCard/cardColors';

const PlacementSimulation = ({
  start,
  end,
  name,
  gender,
  birthdate,
  specificities,
  width,
}: {
  start: Date;
  end?: Date;
  name: string;
  gender: PatientGender;
  birthdate: Date;
  width?: number | string;
  specificities?: AccommodationSpecificity[];
}) => (
  <Box width={width} overflow="hidden">
    <AccommodationCardEvent
      emoji={{ name: getPatientEmojiIcon(gender, getPatientAge(birthdate)), shape: 'circle' }}
      patient={{ name, birthdate, gender }}
      start={start}
      status="PLANNED"
      borderRight={!!end}
      specificities={specificities}
      isEstimatedEnd
      color={getCardColor(specificities)}
    />
  </Box>
);

export default PlacementSimulation;
