import { Cloud, UnitSector, unitSectors } from '@ambuliz/sabri-core';
import { IconButton, LinearProgress, Link, Stack, ToggleButton, Typography } from '@mui/material';
import { MultipleSelect, ToggleGroup } from 'common/components';
import { List, Map } from 'common/components/Icons';
import CollapseIcon from 'common/components/Icons/Collapse';
import ExpandIcon from 'common/components/Icons/Expand';
import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { progressValue, sortListBy } from 'common/utils';
import { useAuthentication } from 'core/authentication';
import { PageContent, PageHeader, PageSection, PageTitle, useAppBarContext } from 'core/layout';
import { HealthCenterList, HealthCenterMap, OccupancyCard } from 'kurt/components';
import FilterSection, { FilterSectionItem } from 'kurt/components/FilterSection';
import useStatisticsBySector from 'kurt/components/Territory/ReportingStatistics/useStatisticsBySector';
import { ORANGE_THRESHOLD, OccupancyFilter, RED_THRESHOLD, getThresholdedColor } from 'kurt/const';
import { useEffect, useRef, useState } from 'react';
import TerritoryEmergency from './TerritoryEmergency';

type View = 'map' | 'list';

const tensionOptions: { value: OccupancyFilter; label: string }[] = [
  { value: 'LOWER_THAN_ORANGE_THRESHOLD', label: i18n.LOWER_THAN_ORANGE_THRESHOLD },
  { value: 'BETWEEN_ORANGE_THRESHOLD_AND_RED_THRESHOLD', label: i18n.BETWEEN_ORANGE_THRESHOLD_AND_RED_THRESHOLD },
  { value: 'GREATER_THAN_RED_THRESHOLD', label: i18n.GREATER_THAN_RED_THRESHOLD },
];

const filterTension = (healthCenter: Cloud.HealthCenterRegionData, filter: OccupancyFilter[]) =>
  (filter.includes('LOWER_THAN_ORANGE_THRESHOLD') &&
    healthCenter.occupancy?.rate !== undefined &&
    healthCenter.occupancy?.rate < ORANGE_THRESHOLD) ||
  (filter.includes('BETWEEN_ORANGE_THRESHOLD_AND_RED_THRESHOLD') &&
    healthCenter.occupancy?.rate &&
    healthCenter.occupancy?.rate >= ORANGE_THRESHOLD &&
    healthCenter.occupancy?.rate < RED_THRESHOLD) ||
  (filter.includes('GREATER_THAN_RED_THRESHOLD') &&
    healthCenter.occupancy?.rate &&
    healthCenter.occupancy?.rate >= RED_THRESHOLD);

const TerritoryPage = () => {
  const { healthCenter, isLoading: loading } = useAuthentication();
  const { setAppBarSelect, appBarHeight, isFullScreen, setFullScreen } = useAppBarContext();

  const [healthCentersLoading, setHealthCentersLoading] = useState(false);
  const [skeletonDisplay, setSkeletonDisplay] = useState(true);
  const timer = useRef<NodeJS.Timeout>();

  const [healthCenters, setHealthCenters] = useState<Cloud.HealthCenterRegionData[]>([]);

  const [regionOccupancy, setRegionOccupancy] = useState<number>();
  const [regionTotalOfBeds, setRegionTotalOfBeds] = useState<number>();
  const [regionOccupatedBeds, setRegionOccupatedBeds] = useState<number>();
  const [regionAvailableBeds, setRegionAvailableBeds] = useState<number>();

  const [healthCenterFilter, setHealthCenterFilter] = useState<string[]>([]);
  const [sectorFilter, setSectorFilter] = useState<UnitSector[]>([]);
  const [tensionFilter, setTensionFilter] = useState<OccupancyFilter[]>([]);
  const { loading: loadingSectors, statistics } = useStatisticsBySector();
  const [view, setView] = useState<View>('list');
  const [flow, setFlow] = useState<'departments' | 'emergency'>('departments');

  const handleChangeView = (_event: React.MouseEvent<HTMLElement>, view: View) => {
    setView(view);
  };

  useEffect(() => {
    const fetchRegionsStatistics = async () => {
      setHealthCentersLoading(true);
      const statistics = await Cloud.getRegionStatistics({
        sectors: sectorFilter.length > 0 ? sectorFilter : undefined,
      });
      setHealthCenters(sortHealthCenters(statistics.healthCenters));
      setRegionOccupancy(statistics.occupancy?.rate);
      setRegionOccupatedBeds(statistics.occupancy?.occupied);
      setRegionTotalOfBeds(statistics.occupancy?.open);
      setRegionAvailableBeds(statistics.occupancy?.available);
      setHealthCentersLoading(false);
    };

    fetchRegionsStatistics();
  }, [sectorFilter]);

  useEffect(() => {
    setSkeletonDisplay(true);
    timer.current = setTimeout(() => {
      if (!loading && !healthCentersLoading) {
        setSkeletonDisplay(false);
      }
    }, 500);

    return () => timer.current && clearTimeout(timer.current);
  }, [healthCentersLoading, loading, view]);

  useEffect(() => {
    setAppBarSelect({ value: i18n.territory, subLabel: healthCenter?.name });
  }, [healthCenter?.name, setAppBarSelect]);

  const healthCenterOptions = healthCenters.map((healthCenter) => ({
    value: healthCenter.name,
    label: healthCenter.name,
  }));

  const filteredHealthCenters = healthCenters
    .filter(({ name }) => healthCenterFilter.length === 0 || healthCenterFilter.includes(name))
    .filter((healthCenter) => tensionFilter.length === 0 || filterTension(healthCenter, tensionFilter));

  const hasFilters = healthCenterFilter.length > 0 || sectorFilter.length > 0 || tensionFilter.length > 0;

  const resetFilters = () => {
    setHealthCenterFilter([]);
    setSectorFilter([]);
    setTensionFilter([]);
  };

  return (
    <>
      <PageTitle title={i18n.territory} />
      <PageContent>
        {!isFullScreen && (
          <>
            <PageHeader title={i18n.territorialReporting} subheader={i18n.pageSubtitles.territory} />

            <PageSection>
              <Statistics.Container loading={loading || loadingSectors}>
                <OccupancyCard.Global
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl="auto"
                  occupancyRate={regionOccupancy}
                  totalOfOccupatedBeds={regionOccupatedBeds}
                  totalOfBeds={regionTotalOfBeds}
                  totalOfAvailableBeds={regionAvailableBeds}
                />
                <Statistics.Card xs={12} sm={6} md={8} lg={8} xl="auto" header={{ title: i18n.sectorOccupancy }}>
                  {statistics.map(({ name, value }) => (
                    <Statistics.Item key={name} title={{ label: name, color: 'default', variant: 'light' }}>
                      {value ? (
                        <Statistics.ItemContent align="center">
                          <Statistics.Text color="secondary">{value}%</Statistics.Text>
                          <LinearProgress
                            value={progressValue(value)}
                            size="small"
                            color={getThresholdedColor(value)}
                            sx={{ width: 24 }}
                          />
                        </Statistics.ItemContent>
                      ) : (
                        <Statistics.ItemContent>
                          <Statistics.Text>-</Statistics.Text>
                        </Statistics.ItemContent>
                      )}
                    </Statistics.Item>
                  ))}
                </Statistics.Card>
              </Statistics.Container>
            </PageSection>

            <PageSection>
              <Typography variant="h3" marginBottom={-2}>
                {flow === 'departments' ? i18n.viewByHealthCenters : i18n.viewByEmergencies}
              </Typography>
            </PageSection>
            <PageSection sticky={{ top: appBarHeight, withShadow: view === 'map' }}>
              <FilterSection>
                <FilterSectionItem.Filters>
                  <MultipleSelect
                    label={i18n.healthCenters}
                    options={healthCenterOptions}
                    values={healthCenterFilter}
                    onChange={setHealthCenterFilter}
                    searchable
                  />
                  {flow === 'departments' && (
                    <>
                      <MultipleSelect
                        label={i18n.sectors}
                        options={unitSectors.map((sector) => ({ value: sector, label: sector }))}
                        values={sectorFilter}
                        onChange={setSectorFilter}
                        searchable
                      />
                      <MultipleSelect
                        label={i18n.tension}
                        options={tensionOptions}
                        values={tensionFilter}
                        onChange={setTensionFilter}
                      />
                    </>
                  )}
                  {hasFilters && (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Link component="button" onClick={resetFilters}>
                        <Typography color={color.grey[80]}>{i18n.clearFilters}</Typography>
                      </Link>
                      <Typography sx={{ color: color.grey[60] }}>
                        {i18n.nbOfDepartments(filteredHealthCenters.flatMap(({ departments }) => departments).length)}
                      </Typography>
                    </Stack>
                  )}
                </FilterSectionItem.Filters>
                <FilterSectionItem.Switchers>
                  {view === 'list' && (
                    <ToggleGroup value={flow} onChange={(_, value: 'departments' | 'emergency') => setFlow(value)}>
                      <ToggleButton value="departments">{i18n.healthCenters}</ToggleButton>
                      <ToggleButton value="emergency">{i18n.emergencies}</ToggleButton>
                    </ToggleGroup>
                  )}

                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography color={palette.primary.main}>{i18n.territorialView(view)}</Typography>
                    <ToggleGroup onChange={handleChangeView} value={view}>
                      <ToggleButton value="map">
                        <Map />
                      </ToggleButton>
                      <ToggleButton value="list">
                        <List />
                      </ToggleButton>
                    </ToggleGroup>
                  </Stack>

                  <IconButton variant="outlined" size="large" shape="rounded" onClick={() => setFullScreen(true)}>
                    <ExpandIcon />
                  </IconButton>
                </FilterSectionItem.Switchers>
              </FilterSection>
            </PageSection>
          </>
        )}

        {view === 'map' ? (
          <PageSection fullHeight lastSection>
            <HealthCenterMap
              userHealthCenter={healthCenter}
              healthCenters={filteredHealthCenters}
              loading={skeletonDisplay}
            />
          </PageSection>
        ) : flow === 'departments' ? (
          <HealthCenterList healthCenters={filteredHealthCenters} loading={skeletonDisplay} />
        ) : (
          <TerritoryEmergency
            userHealthCenter={healthCenter}
            healthCenters={filteredHealthCenters}
            loading={skeletonDisplay}
          />
        )}

        {isFullScreen && (
          <IconButton
            variant="outlined"
            size="large"
            shape="rounded"
            sx={{
              position: 'absolute',
              top: 24,
              right: 24,
              zIndex: 50,
            }}
            onClick={() => setFullScreen(false)}
          >
            <CollapseIcon />
          </IconButton>
        )}
      </PageContent>
    </>
  );
};

export default TerritoryPage;

const sortHealthCenters = (healthCenters: Cloud.HealthCenterRegionData[]) => {
  const sortedHealthCenters = sortListBy(healthCenters, 'name');
  for (const healthCenter of sortedHealthCenters) {
    healthCenter.departments = sortListBy(healthCenter.departments, 'name');
  }
  return sortedHealthCenters;
};
