import { TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import styled from 'styled-components';

type FareHistoryEventListItemProps = {
  date: Date;
  content: string;
};

const FareHistoryTableCell = styled(TableCell)`
  border-top: 1px solid rgba(224, 224, 224, 1);
  white-space: nowrap;
  border-bottom: 0;
  padding-left: 16px !important;
  padding-right: 16px !important;
`;

const DATE_FORMAT = 'dd/MM HH:mm';

const FareHistoryEventListItem: React.FC<FareHistoryEventListItemProps> = ({ date, content }) => (
  <TableRow>
    <FareHistoryTableCell padding="checkbox">
      <Typography color="textSecondary" variant="body2">
        {format(date, DATE_FORMAT)}
      </Typography>
    </FareHistoryTableCell>
    <FareHistoryTableCell>
      <Typography>{content}</Typography>
    </FareHistoryTableCell>
  </TableRow>
);

export default FareHistoryEventListItem;
