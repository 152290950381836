import { Cloud, Practitioner } from '@ambuliz/sabri-core';
import {
  Autocomplete,
  Chip,
  FilterOptionsState,
  Popper,
  Skeleton,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import CrossSmallIcon from 'common/components/Icons/CrossSmall';
import { useDebounce } from 'common/hooks';
import { i18n } from 'common/locale';
import { useEffect, useRef, useState } from 'react';

const INPUT_MAX_LENGTH = 50;

type PractitionersAutocompleteProps = {
  value: Practitioner[];
  onChange: (value: Practitioner[]) => void;
};

const PractitionersAutocomplete = ({ value, onChange }: PractitionersAutocompleteProps) => {
  const [practitionerOptions, setPractitionerOptions] = useState<PractitionerOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const abortController = useRef(new AbortController());
  const debouncedSearch = useDebounce(search, 500);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    if (value.length > 50) {
      return;
    }
    setSearch(value);
  };

  const handleChange = (value: (string | PractitionerOption)[]) => {
    const practitioners: PractitionerOption[] = value.map((practitioner) => {
      if (typeof practitioner === 'string') {
        return { name: practitioner };
      } else if (practitioner.inputValue) {
        return { name: practitioner.inputValue };
      }
      return practitioner;
    });

    abortController.current.abort('New value selected');

    setPractitionerOptions([]);
    onChange(practitioners);
  };

  const filterOptions = (options: PractitionerOption[], params: FilterOptionsState<PractitionerOption>) => {
    let filtered = filter(options, params);

    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.name);
    const loadingOptions = Array.from({ length: 3 }, (_, i) => ({
      inputValue,
      loading: true,
      name: `Loading${i + 1}`,
    }));

    if (inputValue !== '') {
      if (loading) {
        filtered = loadingOptions;
      } else {
        filtered = filtered.filter((option) => !option.loading);
      }

      if (!isExisting) {
        filtered.push({
          inputValue,
          name: `${i18n.add} "${inputValue}"`,
        });
      }
    }
    return filtered;
  };

  useEffect(() => {
    if (search === '' && practitionerOptions.length > 0) {
      setPractitionerOptions([]);
    }
  }, [search, practitionerOptions]);

  useEffect(() => {
    const fetchPractitioners = async () => {
      setLoading(true);
      setPractitionerOptions([]);
      try {
        abortController.current.abort('New request');

        const newAbortController = new AbortController();
        abortController.current = newAbortController;

        const practitioners = await Cloud.getAnsPractitioners({
          search: debouncedSearch,
        });
        setPractitionerOptions(practitioners);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    if (debouncedSearch) {
      fetchPractitioners();
    }

    return () => abortController.current.abort('Component unmounted');
  }, [debouncedSearch]);

  return (
    <Stack spacing={2}>
      <Autocomplete
        fullWidth
        multiple
        value={value}
        options={practitionerOptions}
        size="small"
        filterOptions={filterOptions}
        onChange={(_, value) => handleChange(value)}
        onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, maxLength: INPUT_MAX_LENGTH }}
            placeholder={i18n.practitionersAutocomplete.placeholder}
            variant="filled"
          />
        )}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        clearOnEscape
        selectOnFocus
        clearOnBlur
        freeSolo
        getOptionDisabled={(option) => !!option.loading}
        PopperComponent={(props) => <Popper {...props} placement="top" />}
        renderOption={(props, option) => {
          return (
            <li {...props} style={{ opacity: 1 }} key={option.references?.practitioner || option.name}>
              <Stack direction="column" spacing={loading ? 2 : 0}>
                {option.loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      width="140px"
                      height="12px"
                      variant="rounded"
                      style={{ marginTop: '4px' }}
                    />
                    <Skeleton animation="wave" width="48px" height="6px" variant="rounded" />
                  </>
                ) : (
                  <Typography variant="body2">{option.name}</Typography>
                )}
                {!option.inputValue && !option.loading && (
                  <Typography variant="body2" color="grey">
                    {option.specialty || '-'}
                  </Typography>
                )}
              </Stack>
            </li>
          );
        }}
        clearIcon={<CrossSmallIcon />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={typeof option === 'string' ? option : option.name}
              variant="roundedAlt"
              color="default"
              sx={{ backgroundColor: 'white' }}
              {...getTagProps({ index })}
            />
          ))
        }
      />
      <Typography variant="caption" color="secondary">
        {i18n.practitionersAutocomplete.helper}
      </Typography>
    </Stack>
  );
};

type PractitionerOption = Practitioner & { inputValue?: string; loading?: boolean };

const filter = createFilterOptions<PractitionerOption>();

export default PractitionersAutocomplete;
