import { AvailabilityPeriod, AvailabilityPeriodStatus } from '@ambuliz/sabri-core';
import { Box, Card, Stack, Typography } from '@mui/material';
import { TimeFlowLine } from 'common/components';
import { Calendar } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { format } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagementModal } from 'superadmin/components/ManagementDrawer';
import { useArea } from 'superadmin/context';
import AreaAvailibilityPeriodForm from '../components/form/AreaAvailabilityPeriodForm';
import areaManagementRoutes from '../routes';

const AreaAvailibilityPeriodsModal: React.FC = () => {
  const { id } = useParams();
  const { area, updateAvailability } = useArea(id!);
  const [open] = useState(true);

  const handleSubmit = async (availabilityPeriod: { start: Date; end?: Date; status: AvailabilityPeriodStatus }) => {
    if (area) {
      try {
        await updateAvailability({ ...area, availabilityPeriod: availabilityPeriod });
        toast.success(i18n.messageSuccessAreaUpdate);
      } catch (err) {
        toast.error(i18n.messageErrorAreaAction);
      }
    }
  };

  return (
    <ManagementModal
      loading={!area}
      title={`${i18n.enableOrDisableArea} ${area?.name}`}
      open={open}
      redirectUrl={areaManagementRoutes.areas}
    >
      {area && (
        <Stack spacing={5} flex={1}>
          <AreaAvailibilityPeriodForm onSubmit={handleSubmit} />
          {sortAvailabilityPeriods(area.availabilityPeriods).map((availabilityPeriod, index) => (
            <AvailabilityItem key={index} {...availabilityPeriod} />
          ))}
        </Stack>
      )}
    </ManagementModal>
  );
};

const sortAvailabilityPeriods = (availabilityPeriods?: AvailabilityPeriod[]) =>
  availabilityPeriods ? availabilityPeriods.sort((a, b) => a.start.getTime() - b.start.getTime()) : [];

const AvailabilityItem = ({ start, end, status }: AvailabilityPeriod) => {
  return (
    <Card
      sx={{
        padding: 1,
      }}
    >
      <Stack direction="row">
        <Box
          bgcolor={status === 'ACTIVE' ? 'success.main' : 'error.main'}
          width={6}
          maxWidth={6}
          borderRadius={0.5}
          flex={1}
        />
        <Stack spacing={3} padding={3}>
          <Typography fontWeight={600}>{status === 'ACTIVE' ? i18n.active : i18n.inactive}</Typography>
          <Stack direction="row" spacing={4} alignItems="center">
            <Stack spacing={1}>
              <Typography color="secondary" variant="body2">
                {i18n.startDate}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Calendar color="secondary" />
                <Typography>{format(start, 'dd/MM/yyyy - HH:mm')}</Typography>
              </Stack>
            </Stack>
            <TimeFlowLine start="filled" end="filled" size="small" />
            <Stack spacing={1} color="secondary">
              <Typography color="secondary" variant="body2">
                {i18n.endDate}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Calendar color="secondary" />
                <Typography>{format(end, 'dd/MM/yyyy - HH:mm')}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default AreaAvailibilityPeriodsModal;
