import Fuse, { IFuseOptions } from 'fuse.js';

const filterBySearch = <T>({
  search,
  list,
  keys,
  minScore = 1.0,
}: {
  search: string;
  list: T[];
  keys: string[];
  minScore?: number;
}): T[] => {
  const options: IFuseOptions<T> = {
    keys,
    includeScore: true,
  };

  const fuse = new Fuse(list, options);

  return fuse
    .search(search)
    .filter((result) => !result.score || result.score <= minScore)
    .map((result) => result.item);
};

export default filterBySearch;
