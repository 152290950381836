import { computeOccupancy, getAvailableBeds } from '@ambuliz/sabri-core';
import { Chip, Hidden } from '@mui/material';
import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { useMyHealthCenter } from 'core/healthcenter';
import { CovidOccupancyModal, OccupancyCard } from 'kurt/components';
import AccommodatedDetailsDialog from 'kurt/components/OccupancyStatistics/AccommodatedDetailsDialog';
import DownstreamBedsDetailsDialog from 'kurt/components/OccupancyStatistics/DownstreamBedsDetailsDialog';
import { NormalizedDepartment } from 'kurt/hooks/useDepartments';
import { useState } from 'react';

type MyHealthCenterProps = {
  departments: NormalizedDepartment[];
  loading?: boolean;
};

const MyHealthCenter: React.FC<MyHealthCenterProps> = ({ departments, loading }) => {
  const { myHealthCenter } = useMyHealthCenter();
  const [openCovidDetails, setOpenCovidDetails] = useState(false);
  const [openDownStreamBedDetails, setOpenDownStreamBedDetails] = useState(false);
  const [openAccommodatedDetails, setOpenAccommodatedDetails] = useState(false);
  const toggleCovidDetails = () => setOpenCovidDetails(!openCovidDetails);
  const toggleDownStreamBedDetails = () => setOpenDownStreamBedDetails(!openDownStreamBedDetails);
  const toggleAccommodatedDetails = () => setOpenAccommodatedDetails(!openAccommodatedDetails);
  const { open, occupied, rate, available, covid, waitingForDownstreamBed, accomodated } = computeOccupancy(
    departments.map((department) => department.occupancy)
  );

  const USCOccupancy = computeOccupancy(departments.map((department) => department.specialties?.USC));
  const USCAvailable = getAvailableBeds(
    USCOccupancy.dDayAvailabilities?.male,
    USCOccupancy.dDayAvailabilities?.female,
    USCOccupancy.dDayAvailabilities?.indifferent
  );

  const USIOccupancy = computeOccupancy(departments.map((department) => department.specialties?.USI));
  const USIAvailable = getAvailableBeds(
    USIOccupancy.dDayAvailabilities?.male,
    USIOccupancy.dDayAvailabilities?.female,
    USIOccupancy.dDayAvailabilities?.indifferent
  );

  const REAOccupancy = computeOccupancy(departments.map((department) => department.specialties?.REA));
  const REAAvailable = getAvailableBeds(
    REAOccupancy.dDayAvailabilities?.male,
    REAOccupancy.dDayAvailabilities?.female,
    REAOccupancy.dDayAvailabilities?.indifferent
  );
  const waitingForDownstreamBedUnits = departments.flatMap(({ units }) =>
    units.filter((unit) => !!unit.occupancy?.waitingForDownstreamBed)
  );
  const accommodatedUnits = departments.flatMap(({ units }) => units.filter((unit) => !!unit.occupancy?.accomodated));
  const healthCenter = myHealthCenter
    ? {
        ...myHealthCenter,
        occupancyRate: rate,
        totalOfOccupatedBeds: occupied,
        totalOfAvailableBeds: available,
        totalOfBeds: open,
      }
    : undefined;

  return (
    <Statistics.Container loading={loading}>
      <OccupancyCard.Global xs={6} lg="auto" md={4} sm={6} {...healthCenter} />
      <Hidden mdDown>
        <OccupancyCard.Covid
          md={5}
          xs={12}
          lg="auto"
          onOpenDetails={toggleCovidDetails}
          REAAvailable={REAAvailable}
          USCAvailable={USCAvailable}
          USIAvailable={USIAvailable}
          covid={covid}
        />
      </Hidden>
      <Statistics.Card
        header={{
          title: i18n.waitingForDownstream,
          option: { label: i18n.details, onClick: toggleDownStreamBedDetails },
        }}
        md={3}
        lg="auto"
      >
        <Statistics.Item title={{ label: i18n.patientTotal, color: 'default', variant: 'light' }}>
          <Statistics.ItemContent align="center">
            <Statistics.Text variant="lg" color="primary" sx={{ mr: 1 }}>
              {waitingForDownstreamBed}
            </Statistics.Text>
            <Chip label={i18n.waitingForDownstream} color="primary" />
          </Statistics.ItemContent>
        </Statistics.Item>
      </Statistics.Card>
      <Statistics.Card
        header={{
          title: i18n.accommodatedAccommodations,
          option: { label: i18n.details, onClick: toggleAccommodatedDetails },
        }}
        md={3}
        lg="auto"
      >
        <Statistics.Item title={{ label: i18n.patientTotal, color: 'default', variant: 'light' }}>
          <Statistics.ItemContent align="center">
            <Statistics.Text variant="lg" color="warning" sx={{ mr: 1 }}>
              {accomodated}
            </Statistics.Text>
            <Chip label={i18n.accommodatedAccommodations} color="warning" />
          </Statistics.ItemContent>
        </Statistics.Item>
      </Statistics.Card>
      <Hidden mdUp>
        <OccupancyCard.Covid
          md={5}
          xs={12}
          lg={6}
          onOpenDetails={toggleCovidDetails}
          REAAvailable={REAAvailable}
          USCAvailable={USCAvailable}
          USIAvailable={USIAvailable}
          covid={covid}
        />
      </Hidden>
      <CovidOccupancyModal
        open={openCovidDetails}
        units={departments.flatMap(({ units }) => units.filter((unit) => !!unit.occupancy?.covid))}
        onClose={toggleCovidDetails}
      />
      <DownstreamBedsDetailsDialog
        open={openDownStreamBedDetails}
        onClose={toggleDownStreamBedDetails}
        units={waitingForDownstreamBedUnits}
      />
      <AccommodatedDetailsDialog
        open={openAccommodatedDetails}
        onClose={toggleAccommodatedDetails}
        units={accommodatedUnits}
      />
    </Statistics.Container>
  );
};
export default MyHealthCenter;
