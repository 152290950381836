import { useMemo } from 'react';
import { useBedsFromUnit } from './useBedsFromUnit';

export const useBedOptions = (unitId?: string) => {
  const { beds, isLoading } = useBedsFromUnit(unitId || '');
  const bedOptions = useMemo(() => beds.map((bed) => ({ value: bed.id, label: bed.name })), [beds]).sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  return { bedOptions, isLoading };
};
