import { Components } from '@mui/material';
import color from '../color';

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    danger: true;
  }

  interface LinearProgressProps {
    size?: 'small' | 'medium';
  }
}

const MuiLinearProgress: Components['MuiLinearProgress'] = {
  defaultProps: {
    size: 'medium',
    variant: 'determinate',
  },
  styleOverrides: {
    root: {
      borderRadius: 8,
      backgroundColor: color.blue[10],
    },
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        height: 6,
      },
    },
  ],
};

export default MuiLinearProgress;
