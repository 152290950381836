import { AreaTree } from '@ambuliz/sabri-core';
import { requests } from '../const/requests';
import { routeNamesCommon } from '../const/routeNames';

export const getLinkerRedirectTo = async (searchParams: URLSearchParams) => {
  let routeName = searchParams.get('routeName');
  routeName = routeName?.includes('brancardage') ? routeName : `/brancardage${routeName}`;
  console.log(routeName);

  if (routeName && routeName in routeNameToSearchParams) {
    try {
      const nextSearchParams = await routeNameToSearchParams[routeName](searchParams);
      if (nextSearchParams) {
        return { pathname: routeName, search: nextSearchParams.toString() };
      }
    } catch {}
  }
};

const getSearchParamsFareCreation = async (searchParams: URLSearchParams) => {
  const pan = searchParams.get('pan');
  const externalId = searchParams.get('locationId');
  const ipp = searchParams.get('ipp');
  const fromArea = searchParams.get('fromArea');
  const fromUnit = searchParams.get('fromUnit');
  const toArea = searchParams.get('toArea');
  const toUnit = searchParams.get('toUnit');
  searchParams.delete('ipp');
  searchParams.delete('fromArea');
  searchParams.delete('toArea');
  searchParams.delete('fromUnit');
  searchParams.delete('toUnit');

  const areas = await requests.getAreas();
  const areaTree = new AreaTree(areas);
  if (ipp) {
    try {
      const [patient] = await requests.getPatientsByIpp({ ipp });
      if (patient.unit && patient.unit.id) {
        searchParams.append('fromUnitId', patient.unit.id);
      }
      if (patient.area && patient.area.id) {
        searchParams.append('fromAreaId', patient.area.id);
      }

      searchParams.append('patientId', patient.id);
    } catch {
      if (pan) {
        try {
          const [patient] = await requests.getPatientsByPan({ pan });
          searchParams.append('patientId', patient.id);
        } catch {}
      }
    }
  } else if (pan) {
    try {
      const [patient] = await requests.getPatientsByPan({ pan });
      searchParams.append('patientId', patient.id);
    } catch {}
  }
  if (fromUnit) {
    try {
      const [unit] = await requests.getUnitsByName({ name: fromUnit });
      searchParams.append('fromUnitId', unit.id);
      if (fromArea) {
        areaTree.getAllChildren((unit.areas || []).map((area) => area.id));
        const fromAreas = areaTree.search(fromArea);
        if (fromAreas.length > 0) {
          searchParams.append('fromAreaId', fromAreas[0].id);
        }
      }
    } catch {}
  }
  if (toUnit) {
    try {
      const [unit] = await requests.getUnitsByName({ name: toUnit });
      searchParams.append('toUnitId', unit.id);
      if (toArea) {
        areaTree.getAllChildren((unit.areas || []).map((area) => area.id));
        const toAreas = areaTree.search(toArea);
        if (toAreas.length > 0) {
          searchParams.append('toAreaId', toAreas[0].id);
        }
      }
    } catch {}
  }
  if (externalId) {
    try {
      const area = await requests.getAreaByExternalId({ externalId });
      if (area) {
        searchParams.append('fromAreaId', area.id);
      }
    } catch {}
  }
  return searchParams;
};

const getSearchParamsCallPatient = async (searchParams: URLSearchParams) => {
  const ipp = searchParams.get('ipp');
  searchParams.delete('ipp');
  if (ipp) {
    const [patient] = await requests.getPatientsByIpp({ ipp });
    const [fare] = await requests.getFareFromPatient({ patient });
    searchParams.append('fareId', fare.id);
    return searchParams;
  }
};

const routeNameToSearchParams: Record<string, (searchParams: URLSearchParams) => Promise<URLSearchParams | undefined>> =
  {
    [routeNamesCommon.fareCreation]: getSearchParamsFareCreation,
    [routeNamesCommon.callPatient]: getSearchParamsCallPatient,
  };
