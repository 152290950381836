import Timeline, { TimelineRow } from 'common/components/Timeline';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import { DEFAULT_SCALE } from '../DetailsDialogTimeline';

const SkeletonDetailsTimeline = () => {
  const now = new Date();
  const start = startOfDay(subDays(now, 1));
  const end = endOfDay(addDays(now, 1));

  return (
    <Timeline start={start} end={end} rowHeaderWidth={40} scale={DEFAULT_SCALE} variant="filled" loading>
      <TimelineRow isFirstRow isLastRow />
    </Timeline>
  );
};

export default SkeletonDetailsTimeline;
