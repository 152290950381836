import { Area } from '@ambuliz/sabri-core';
import { AreaManagementListItemBed } from './AreaManagementListItemBed';
import { AreaManagementListItemNode } from './AreaManagementListItemNode';

export type AreaManagementListItemProps = {
  area: Area;
  isReadOnly?: boolean;
};

export const AreaManagementListItem = ({ area, isReadOnly = false }: AreaManagementListItemProps) =>
  area.externalTypeCode === 'bed' ? (
    <AreaManagementListItemBed area={area} isReadOnly={isReadOnly} />
  ) : (
    <AreaManagementListItemNode area={area} isReadOnly={isReadOnly} />
  );
