import { PatientGender } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import { formatAge, formatName } from 'common/utils';

type CardSummaryPatientProps = {
  firstName?: string;
  lastName?: string;
  legalName?: string;
  legalFirstName?: string;
  fullName?: string;
  birthdate: Date;
  gender: PatientGender;
};

const CardSummaryPatient = ({
  firstName,
  lastName,
  legalName,
  legalFirstName,
  fullName,
  birthdate,
  gender,
}: CardSummaryPatientProps) => (
  <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={3}>
    <PatientNameWithEmoji
      name={fullName || formatName(firstName, lastName, legalName, legalFirstName)}
      birthdate={birthdate}
      gender={gender}
    />
    <Typography variant="body2" color="secondary" noWrap flexShrink={0}>
      {formatAge(birthdate)}
    </Typography>
  </Stack>
);

export default CardSummaryPatient;
