import { Card, Collapse } from '@mui/material';
import { toast } from 'common/toast';
import { useEffect, useState } from 'react';
import { i18n } from 'sabri/locales';
import FareHistoryEventList from './FareHistoryEventList';
import FareHistoryHeader from './FareHistoryHeader';
import useFareHistory from './useFareHistory';

export type FareHistoryEvent = {
  date: Date;
  content: string;
};

type FareHistoryProps = {
  fareId: string;
};

const FareHistory: React.FC<FareHistoryProps> = ({ fareId }) => {
  const [expanded, setExpanded] = useState(false);
  const { events, loading, error } = useFareHistory(fareId);

  useEffect(() => {
    if (error) {
      toast.error(i18n.fareHistory.errorMessage);
    }
  }, [error]);

  return (
    <Card variant="outlined" elevation={0}>
      <FareHistoryHeader onClick={() => setExpanded(!expanded)} open={expanded} loading={loading} />
      <Collapse in={expanded}>
        <FareHistoryEventList events={events} />
      </Collapse>
    </Card>
  );
};

export default FareHistory;
