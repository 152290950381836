import shadows from 'common/theme/shadows';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledToastContainer = styled(ToastContainer)`
  margin-bottom: 70px;
  margin-top: 80px;
  padding: 0;
  width: 354px;
  .Toastify__toast--error {
    background: transparent;
  }
  .Toastify__toast--warning {
    background: transparent;
  }
  .Toastify__toast--success {
    background: transparent;
  }
  .Toastify__toast--info {
    background: transparent;
  }
  .Toastify__toast--default {
    background: transparent;
  }
  .Toastify__toast {
    box-shadow: ${shadows[3]};
    border-radius: 8px;
    padding: 0;
    min-height: 0;
    align-items: center;
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
`;

const Snackbar = () => {
  return <StyledToastContainer closeButton={false} position="bottom-right" hideProgressBar icon={false} closeOnClick />;
};

export default Snackbar;
