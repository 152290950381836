import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { IconButton, Link, Stack, ToggleButton } from '@mui/material';
import { AccommodationSpecificitiesSelect, PatientGenderSelect, ToggleGroup } from 'common/components';
import ExpandIcon from 'common/components/Icons/Expand';
import { i18n } from 'common/locale';
import { PageSection, useAppBarContext } from 'core/layout';
import { PractitionersFilter } from 'kurt/components/Practitioners';
import { PractitionerQueryParams } from 'kurt/components/Practitioners/usePractitionerOptions';
import { SearchPatient } from 'kurt/components/SearchPatient';
import { memo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useEmergencyPageFilters from './useEmergencyPageFilters';
import useEmergencyRouteParams from './useEmergencyRouteParams';

const EmergencyPageFilters = ({ practitionerQueryParams }: { practitionerQueryParams?: PractitionerQueryParams }) => {
  const { setFullScreen } = useAppBarContext();
  let [searchParams, setSearchParams] = useSearchParams();
  const appBarContext = useAppBarContext();

  const { page } = useEmergencyRouteParams();

  const { search, genders, specificities, practitioners, resetFilters } = useEmergencyPageFilters();

  const navigate = useNavigate();

  const displayPractitionersFilter = practitionerQueryParams && page === 'present-patients';

  const hasActiveFilters =
    search.length > 0 || genders.length > 0 || specificities.length > 0 || practitioners.length > 0;

  const handleSearchChange = (search: string) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), search });
  };
  const handleSpecificitiesChange = (specificities: AccommodationSpecificity[]) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), specificities });
  };
  const handleGenderChange = (genders: PatientGender[]) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), genders });
  };
  const handlePractitionersChange = (practitioners: string[]) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), practitioners });
  };

  return (
    <PageSection sticky={{ top: appBarContext.appBarHeight, withShadow: true }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={4}>
          <SearchPatient value={search} onChange={handleSearchChange} />
          <AccommodationSpecificitiesSelect value={specificities} onChange={handleSpecificitiesChange} />
          <PatientGenderSelect value={genders} onChange={handleGenderChange} />
          {displayPractitionersFilter && (
            <PractitionersFilter
              value={practitioners}
              onChange={handlePractitionersChange}
              practitionerQueryParams={practitionerQueryParams}
            />
          )}
          {hasActiveFilters && (
            <Link component="button" variant="body1" onClick={resetFilters}>
              {i18n.clearFilters}
            </Link>
          )}
        </Stack>
        <Stack direction="row" spacing={4}>
          <ToggleGroup
            value={page!}
            onChange={(_, page) => navigate({ pathname: page, search: searchParams.toString() })}
          >
            <ToggleButton value="present-patients">{i18n.presentPatients.title}</ToggleButton>
            <ToggleButton value="hospitalizations">{i18n.hospitalizationRequests}</ToggleButton>
          </ToggleGroup>

          <IconButton variant="outlined" size="large" shape="rounded" onClick={() => setFullScreen(true)}>
            <ExpandIcon />
          </IconButton>
        </Stack>
      </Stack>
    </PageSection>
  );
};

export default memo(EmergencyPageFilters);
