import { badgeClasses, Components } from '@mui/material';
import color from '../color';

const MuiBadge: Components['MuiBadge'] = {
  defaultProps: { color: 'error' },
  styleOverrides: {
    badge: {
      height: 18,
      minWidth: 18,
      borderRadius: 16,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
      padding: '1px 5px',
    },
    root: {
      '&.Mui-withoutChildren': {
        [`& .${badgeClasses.badge}`]: {
          right: 'auto',
          transform: 'scale(1) translate(0, -50%)',
        },
      },
    },
    colorSecondary: {
      backgroundColor: color.grey[40],
    },
  },
};

export default MuiBadge;
