import { Accommodation } from '@ambuliz/sabri-core';
import { Chip, ChipProps, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';

type AccommodationBedHistoryProps = {
  accommodation?: Accommodation;
  chipProps?: ChipProps;
};

export const AccommodationBedHistory = ({ accommodation, chipProps }: AccommodationBedHistoryProps) => {
  if (!accommodation) {
    return null;
  }
  const history =
    accommodation.bedHistory?.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()).slice(0, 2) || [];

  return (
    <Stack direction="column-reverse" gap={1}>
      {history.length ? (
        <>
          {accommodation.bed && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Emoji name="bellOrange" size="extra-small" />
              <Chip color="danger" variant="lightRounded" size="x-small" label={accommodation.bed.name} />
            </Stack>
          )}
          {history.map((history, index) => (
            <Chip
              color="secondary"
              variant="lightRounded"
              size="x-small"
              label={
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    textDecorationLine: 'line-through',
                  }}
                >
                  {history.bed.name}
                </Typography>
              }
              {...chipProps}
              sx={{
                opacity: index === 0 ? 0.4 : 0.2,
                ...chipProps?.sx,
              }}
            />
          ))}
        </>
      ) : (
        !!accommodation.bed?.name && <Chip color="secondary" variant="rounded" label={accommodation.bed.name} />
      )}
    </Stack>
  );
};
