import { Box, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cls from 'classnames';
import React, { ReactElement, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export type TableListTab<Item extends Parse.JSONBaseAttributes> = {
  id: string;
  routeName: string;
  label: string;
  labelEmpty: string;
  filterMethod?: (data: Item) => boolean;
};

export type TableListTabsProps<Item extends Parse.JSONBaseAttributes> = {
  onChangeTabIndex: (index: number) => any;
  tabIndex: number;
  tabs: TableListTab<Item>[];
};

export const TableListTabs = <Item extends Parse.JSONBaseAttributes>({
  tabIndex,
  onChangeTabIndex,
  tabs,
}: TableListTabsProps<Item>): ReactElement => {
  const location = useLocation();
  const classes = useClasses();
  const tabsClasses = { indicator: classes.tabIndicator, fixed: classes.tabFixed };

  useEffect(() => {
    const indexFound = tabs.findIndex((tab: TableListTab<Item>) => tab.routeName === location.pathname);
    if (indexFound >= 0) {
      onChangeTabIndex(indexFound);
    }
  }, [location.pathname, tabs.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderTabLabel = (label: string, index: number) => (
    <Box className={cls(classes.tabLabel, tabIndex === index && classes.tabLabelSelected)}>{label}</Box>
  );

  return (
    <Tabs value={tabIndex} classes={tabsClasses}>
      {tabs.map((tab, index) => (
        <Tab key={tab.id} component={Link} to={tab.routeName} label={renderTabLabel(tab.label, index)} />
      ))}
    </Tabs>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  tabFixed: {
    overflow: 'auto !important',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabLabel: {
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  tabLabelSelected: {
    color: palette.secondary.main,
  },
  tabIndicator: {
    backgroundColor: palette.secondary.main,
  },
}));
