import formatFirstname from './formatFirstname';

const formatName = (firstName = '', lastName = '', legalName?: string, legalFirstName?: string) => {
  const name = `${lastName.toUpperCase()} ${formatFirstname(firstName)}`;
  const shouldDisplayLegalName = legalName?.toLocaleLowerCase() !== lastName?.toLocaleLowerCase();
  const displayLegalFirstName =
    legalFirstName && legalFirstName?.toLocaleLowerCase() !== firstName?.toLocaleLowerCase()
      ? ` (${formatFirstname(legalFirstName)})`
      : undefined;
  const fullName =
    legalName && shouldDisplayLegalName
      ? `${lastName.toUpperCase()} (${legalName.toUpperCase()}) ${formatFirstname(firstName)}`
      : name;
  return displayLegalFirstName ? fullName + displayLegalFirstName : fullName;
};

export default formatName;
