import { Area } from '@ambuliz/sabri-core';
import { Autocomplete, Button, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { DateTimeInput } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { FormEvent, useState } from 'react';
import { AreaSelect } from 'superadmin/components/AreaSelect';

type MoveBedFormProps = {
  bed: Area;
  onSubmit: (values: { parentId: string; newExternalIds?: string[]; activationDate?: Date }) => void;
  onCancel: () => void;
};

const MoveBedForm = ({ bed, onSubmit, onCancel }: MoveBedFormProps) => {
  const [newExternalIds, setNewExternalIds] = useState<string[]>([]);
  const [activationDate, setActivationDate] = useState<Date | null>(null);
  const [parentAreaId, setParentAreaId] = useState(bed.parent?.id || '');

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (parentAreaId) {
      if (parentAreaId === bed.parent?.id) {
        toast.error(i18n.parentDidNotChange);
      } else {
        onSubmit({
          parentId: parentAreaId,
          newExternalIds: newExternalIds.length > 0 ? newExternalIds : undefined,
          activationDate: activationDate || undefined,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Stack spacing={6}>
        <Stack spacing={4}>
          <Autocomplete
            options={['']}
            value={newExternalIds}
            multiple
            open={false}
            freeSolo
            autoSelect
            onChange={(_, externalIds) => setNewExternalIds(externalIds)}
            renderInput={(params) => (
              <Stack spacing={2}>
                <Typography color="secondary">{i18n.newExternalIds}</Typography>
                <TextField {...params} fullWidth variant="outlined" />
              </Stack>
            )}
          />
          <Stack spacing={2}>
            <Typography color="secondary">{i18n.activationDate}</Typography>
            <DateTimeInput value={activationDate} onChange={setActivationDate} minDate={new Date()} noRange />
          </Stack>
        </Stack>

        <Stack>
          <FormLabel>{i18n.newParent}</FormLabel>
          <AreaSelect value={parentAreaId} onChange={(id: string) => setParentAreaId(id)} />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button onClick={onCancel} variant="outlined">
            {i18n.cancel}
          </Button>
          <Button type="submit">{i18n.move}</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default MoveBedForm;
