"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ParseInstance = typeof document !== 'undefined' ? require('parse') : require('parse/node');
// Si Parse est déjà déclaré globalement (c'est le cas de sabri-parse-server).
// On peut directement utiliser son instance
const Api = global.Parse || ParseInstance;
// On tente un auto-connect si on trouve des variables d'environnements
if (!global.Parse) {
    Api.serverURL = process.env.PARSE_URL || 'http://localhost:1337/parse';
    if (process.env.PARSE_APP_ID && process.env.PARSE_MASTER_KEY) {
        Api.initialize(process.env.PARSE_APP_ID, undefined, process.env.PARSE_MASTER_KEY);
    }
}
// The function below rewrites the handleError to be able to return JSON from the backend
// @ts-ignore
const RESTController = Api.CoreManager.getRESTController();
RESTController.handleError = (response) => {
    // Transform the error into an instance of ParseError by trying to parse
    // the error string as JSON
    let error;
    if (response && response.responseText) {
        try {
            error = JSON.parse(response.responseText);
        }
        catch (e) {
            // If we fail to parse the error text, that's okay.
            error = 'Received an error with invalid JSON from Parse';
        }
    }
    else {
        error = 'XMLHttpRequest failed';
    }
    return Promise.reject(Object.assign(Object.assign({}, error), { statusCode: response.status }));
};
// @ts-ignore
Api.CoreManager.setRESTController(RESTController);
// @ts-ignore
Api.Object.disableSingleInstance();
exports.default = Api;
