import { Accommodation } from '@ambuliz/sabri-core';
import { Button, ButtonGroup } from '@mui/material';
import { Emoji } from 'common/components';
import ChevronSmallRightIcon from 'common/components/Icons/ChevronSmallRight';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { isPast } from 'date-fns';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';

type BedClosingProps = {
  accommodation: Accommodation;
  onClick: (action: AccommodationAction) => void;
  disabled?: boolean;
};

const CloseBedActions = ({ accommodation, onClick, disabled }: BedClosingProps) => {
  const { unit, user } = useAuthentication();

  const hasUnitAccess =
    ['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER'].includes(user.role) ||
    (user.role === 'MANAGER' && unit?.id === accommodation.unit?.id);

  if (!hasUnitAccess) return null;

  const isOpenable = isPast(accommodation.startAt);

  return (
    <>
      <ButtonGroup orientation="vertical" variant="action" disabled={disabled}>
        {isOpenable && (
          <Button
            startIcon={<Emoji name="whiteHeavyCheckMark" size="extra-small" />}
            endIcon={<ChevronSmallRightIcon />}
            onClick={() => onClick('REOPEN_BED')}
          >
            {i18n.makeBedAvailable.actionLabel}
          </Button>
        )}
        <Button
          startIcon={<Emoji name="noEntry" size="extra-small" />}
          endIcon={<ChevronSmallRightIcon />}
          color="error"
          onClick={() => onClick('CANCEL_CLOSED_BED')}
        >
          {i18n.cancelBedClosed.actionLabel}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default CloseBedActions;
