const NOTIFICATIONS_SOUND_KEY = 'notifications-sound';

export const isNotificationSoundEnabled = (): boolean => {
  const soundSetting = localStorage.getItem(NOTIFICATIONS_SOUND_KEY);

  return !soundSetting || soundSetting === 'true';
};

export const setNotificationSound = (enabled: boolean): void => {
  localStorage.setItem(NOTIFICATIONS_SOUND_KEY, enabled.toString());
};
