"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBedSnapshot = exports.getBedGender = exports.getBedStatus = void 0;
const getBedStatus = (accommodation) => {
    if (accommodation) {
        const hasVisit = !!accommodation.visit;
        return hasVisit ? 'BUSY' : 'CLOSED';
    }
    return 'AVAILABLE';
};
exports.getBedStatus = getBedStatus;
const getBedGender = (accommodationsRoom, accommodation) => {
    if (!accommodationsRoom.length && !accommodation) {
        return 'UNKNOWN';
    }
    if (accommodation === null || accommodation === void 0 ? void 0 : accommodation.visit) {
        return accommodation.visit.gender;
    }
    const female = accommodationsRoom.some((accommodation) => { var _a; return ((_a = accommodation.visit) === null || _a === void 0 ? void 0 : _a.gender) === 'FEMALE'; });
    const male = accommodationsRoom.some((accommodation) => { var _a; return ((_a = accommodation.visit) === null || _a === void 0 ? void 0 : _a.gender) === 'MALE'; });
    if (female === male) {
        return 'UNKNOWN';
    }
    if (female) {
        return 'FEMALE';
    }
    return 'MALE';
};
exports.getBedGender = getBedGender;
const getBedSnapshot = ({ id, excludedFromOccupancy, accommodationsRoom, accommodation, }) => ({
    id,
    excludedFromOccupancy,
    gender: (0, exports.getBedGender)(accommodationsRoom, accommodation),
    specificities: (accommodation === null || accommodation === void 0 ? void 0 : accommodation.specificities) || [],
    unit: accommodation === null || accommodation === void 0 ? void 0 : accommodation.unit,
    responsibleUnit: accommodation === null || accommodation === void 0 ? void 0 : accommodation.responsibleUnit,
    status: (0, exports.getBedStatus)(accommodation),
});
exports.getBedSnapshot = getBedSnapshot;
