import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import ActionButton from 'common/components/ActionButton';
import EllipsisTypography from 'common/components/EllipsisTypography';
import Exit from 'common/components/Icons/Exit';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { formatAge, getPatientAge } from 'common/utils';
import AccommodationSpecificityBadges from 'kurt/components/AccommodationSpecificityBadges';
import EditableTableCell from 'kurt/components/EditableTableCell/EditableTableCell';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import DischargeStatusChip from 'kurt/components/StepStatus/DischargeStatusChip';
import { useAccommodation } from 'kurt/hooks';
import React, { useEffect, useState } from 'react';
import { MovementRow } from '../MovementRow';
import MovementDate from './MovementDate';
import MovementsActionForm, { DischargeMovementActionMenu } from './MovementsActions';

const AdmittedRow = ({
  movement,
  onClick,
  showUnit,
  isReadOnly = false,
}: {
  movement: MovementRow;
  showUnit?: boolean;
  isReadOnly?: boolean;
  onClick: (id: string, type: 'accommodation' | 'mutation') => void;
}) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [action, setAction] = useState<AccommodationAction>('DISCHARGE_PATIENT');
  const [comment, setComment] = useState(movement.comment || '');
  const { update, loading } = useAccommodation(movement.id);

  useEffect(() => setComment(movement.comment || ''), [movement.comment]);

  const age = getPatientAge(movement.patientBirthdate);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setDetailsOpen(true);
  };

  const handleClose = () => {
    setDetailsOpen(false);
    setAction('DISCHARGE_PATIENT');
  };

  const handleCommentSubmit = (value: string) => {
    if (value !== movement.comment) {
      update({ comment: value });
    }
  };

  return (
    <>
      <TableRow
        onClick={() => onClick(movement.id, 'accommodation')}
        hover
        sx={{
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }}
      >
        <TableCell width={14} sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Box display="flex">
            <MovementDate date={movement.date} anomalies={movement.anomalies} />
          </Box>
        </TableCell>
        <TableCell>
          <DischargeStatusChip status="VALIDATED" />
        </TableCell>
        <TableCell>
          <EllipsisTypography tooltipProps={{ title: 'Lit ' + movement.bed || '-' }} fontWeight={600}>
            {movement.bed || '-'}
          </EllipsisTypography>
        </TableCell>
        {showUnit && (
          <TableCell>
            <Typography variant="body2" color="secondary">
              {movement.unitName || '-'}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <PatientNameWithEmoji
            name={movement.patientName}
            gender={movement.patientGender}
            bgcolor={color.blue[5]}
            age={age}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary">
            {formatAge(movement.patientBirthdate)}
          </Typography>
        </TableCell>
        <TableCell>
          <EllipsisTypography variant="body2" color="secondary" maxLines={3}>
            {movement.reason}
          </EllipsisTypography>
        </TableCell>
        <TableCell>
          <Box display="flex" sx={{ cursor: 'pointer' }}>
            <AccommodationSpecificityBadges specificities={movement.specificities}></AccommodationSpecificityBadges>
          </Box>
        </TableCell>
        <EditableTableCell
          value={comment}
          onChange={setComment}
          onSubmit={handleCommentSubmit}
          loading={loading}
          isReadOnly={isReadOnly}
        />
        {!isReadOnly && (
          <TableCell>
            <ActionButton icon={<Exit />} tooltip={i18n.visitActions[action].form.title} onClick={handleOpen} />
          </TableCell>
        )}
        {!isReadOnly && (
          <TableCell width={16} sx={{ paddingLeft: 2 }}>
            <DischargeMovementActionMenu movementId={movement.id} />
          </TableCell>
        )}
      </TableRow>

      {action && detailsOpen && (
        <MovementsActionForm action={action} open={detailsOpen} onCancel={handleClose} {...movement} />
      )}
    </>
  );
};

export default AdmittedRow;
