import { AvailabilityPeriodStatus } from '@ambuliz/sabri-core';
import { Box, Button, Card, CardContent, CardHeader, Stack, Switch, Typography } from '@mui/material';
import { DateTimeInput } from 'common/components';
import { Plus } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette, transition } from 'common/theme';
import { isAfter, isValid, startOfMinute } from 'date-fns';
import { useState } from 'react';

type AreaAvailabilityPeriodFormProps = {
  onSubmit: (availibilityPeriod: { start: Date; end?: Date; status: AvailabilityPeriodStatus }) => void;
};

type AvailabilityPeriodValue = {
  start: Date | null;
  end: Date | null;
  status: 'ACTIVE' | 'INACTIVE';
};

const AreaAvailibilityPeriodForm = ({ onSubmit }: AreaAvailabilityPeriodFormProps) => {
  const [availabilityPeriod, setAvailabilityPeriod] = useState<AvailabilityPeriodValue>({
    start: null,
    end: null,
    status: 'ACTIVE',
  });
  const [errors, setErrors] = useState({
    start: false,
    end: false,
  });

  const handleDateChange = (date: Date | null, key: 'start' | 'end') => {
    setErrors((prev) => ({ ...prev, [key]: false }));
    setAvailabilityPeriod((prev) => ({ ...prev, [key]: date }));
  };

  const handleSubmit = () => {
    const errors = validate(availabilityPeriod);
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      setErrors(errors);
    } else {
      onSubmit({
        ...availabilityPeriod,
        start: startOfMinute(availabilityPeriod.start as Date),
        end: availabilityPeriod.end ? startOfMinute(availabilityPeriod.end as Date) : undefined,
      });
      setErrors({
        start: false,
        end: false,
      });
    }
  };

  return (
    <Card elevation={0} sx={{ backgroundColor: palette.background.default }}>
      <CardHeader title={i18n.addAvailabilityPeriod} />
      <CardContent>
        <Stack spacing={4}>
          <Card
            elevation={0}
            sx={{
              padding: 1,
            }}
          >
            <Stack direction="row">
              <Box
                bgcolor={availabilityPeriod.status === 'ACTIVE' ? 'success.main' : 'error.main'}
                width={6}
                maxWidth={6}
                borderRadius={0.5}
                flex={1}
                sx={{ transition: `background-color ${transition}` }}
              />
              <Stack spacing={4} padding={3}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography color={availabilityPeriod.status === 'ACTIVE' ? 'secondary' : undefined}>
                    {i18n.inactive}
                  </Typography>
                  <Switch
                    value={availabilityPeriod.status === 'ACTIVE'}
                    defaultChecked
                    onChange={(_, checked) => {
                      setAvailabilityPeriod((prev) => ({ ...prev, status: checked ? 'ACTIVE' : 'INACTIVE' }));
                    }}
                  />
                  <Typography color={availabilityPeriod.status === 'INACTIVE' ? 'secondary' : undefined}>
                    {i18n.active}
                  </Typography>
                </Stack>

                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <Typography color="secondary">{i18n.startDate}</Typography>
                    <Stack spacing={1}>
                      <DateTimeInput
                        value={availabilityPeriod.start}
                        onChange={(value) => handleDateChange(value, 'start')}
                        error={errors.start}
                        maxDate={availabilityPeriod.end || undefined}
                        noRange
                      />
                      {errors.start && (
                        <Typography variant="caption" color="error">
                          {i18n.startDateMandadatory}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <Stack spacing={2}>
                    <Typography color="secondary">{i18n.endDate}</Typography>
                    <Stack spacing={1}>
                      <DateTimeInput
                        value={availabilityPeriod.end}
                        onChange={(value) => handleDateChange(value, 'end')}
                        error={errors.end}
                        minDate={availabilityPeriod.start || undefined}
                        noRange
                      />
                      {errors.end && (
                        <Typography variant="caption" color="error">
                          {i18n.endDateMustBeAfterStartDate}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Button onClick={handleSubmit} startIcon={<Plus />}>
            {i18n.add}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

const validate = (availabilityPeriod: AvailabilityPeriodValue) => ({
  start: !availabilityPeriod.start && !isValid(availabilityPeriod.start),
  end:
    !!availabilityPeriod.end &&
    !!availabilityPeriod.start &&
    !isAfter(availabilityPeriod.end, availabilityPeriod.start),
});

export default AreaAvailibilityPeriodForm;
