import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { Box, Stack, Typography } from '@mui/material';
import { color } from 'common/theme';
import { addSeconds, differenceInSeconds, format } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import EllipsisTypography from '../EllipsisTypography';
import PlaceholderSlot from './PlaceholderSlot';
import PlacementSimulation from './PlacementSimulation';

type BedSlotProps = {
  value?: string;
  hoverId?: string;
  bedId: string;
  start: Date;
  end?: Date;
  nextStart?: Date;
  nextEnd?: Date;
  name: string;
  birthdate: Date;
  gender: PatientGender;
  specificities: AccommodationSpecificity[];
};

const BedSlot = ({
  value,
  hoverId,
  bedId,
  start,
  end,
  nextStart,
  nextEnd,
  name,
  birthdate,
  gender,
  specificities,
}: BedSlotProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const nextSlotRef = useRef<HTMLDivElement>(null);
  const nextSlotDateRef = useRef<HTMLDivElement>(null);
  const [needsEllipsis, setNeedsEllipsis] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
    }
  }, []);

  const { gap, width: nextSlotWidth } = useMemo(
    () =>
      getNextSlotDimensions({
        containerWidth,
        start,
        end,
        nextStart,
        nextEnd,
      }),
    [start, end, containerWidth, nextStart, nextEnd]
  );

  const placementSimulationWidth = useMemo(
    () => (!end ? '100%' : containerWidth / 2 - BORDER - PADDING),
    [end, containerWidth]
  );

  useEffect(() => {
    if (gap && nextSlotWidth) {
      const nextSlotDateWidth = nextSlotDateRef.current?.getBoundingClientRect().width;
      const nextSlotWidth = nextSlotRef.current?.getBoundingClientRect().width;
      if (
        nextSlotWidth &&
        nextSlotDateWidth &&
        nextSlotDateWidth > nextSlotWidth &&
        containerRef.current &&
        nextSlotDateRef.current &&
        containerRef.current.getBoundingClientRect().right < nextSlotDateRef.current.getBoundingClientRect().right
      ) {
        setNeedsEllipsis(true);
      }
    }
  }, [gap, nextSlotWidth, value, nextSlotDateRef, nextSlotRef, containerRef]);

  return (
    <Stack
      padding={1}
      border={`1px solid ${value === bedId || hoverId === bedId ? color.blue[60] : color.grey[30]}`}
      direction="row"
      spacing={1}
      borderRadius={1}
      flex={1}
      position="relative"
      ref={containerRef}
    >
      {value === bedId ? (
        <PlacementSimulation
          start={start}
          end={end}
          name={name}
          birthdate={birthdate}
          gender={gender}
          specificities={specificities}
          width={placementSimulationWidth}
        />
      ) : (
        <PlaceholderSlot />
      )}
      {value === bedId && (
        <Typography position="absolute" top={-16} variant="caption" style={{ marginLeft: 0 }}>
          {format(start, 'dd/MM - HH:mm')}
        </Typography>
      )}
      {nextStart && end && gap && (
        <NextSlot width={nextSlotWidth} gap={gap} ref={nextSlotRef}>
          {value === bedId && gap > 0 && (
            <Box ref={nextSlotDateRef} position="absolute" top={needsEllipsis ? -20 : -26}>
              {needsEllipsis ? (
                <EllipsisTypography variant="caption" color="secondary" noWrap>
                  {format(nextStart, 'dd/MM - HH:mm')}
                </EllipsisTypography>
              ) : (
                <Typography variant="caption" color="secondary" noWrap>
                  {format(nextStart, 'dd/MM - HH:mm')}
                </Typography>
              )}
            </Box>
          )}
        </NextSlot>
      )}
    </Stack>
  );
};

const NextSlot = styled(Box)<{ gap: number; width?: number }>`
  background-image: linear-gradient(
    135deg,
    ${color.grey[10]} 25%,
    #daddea 25%,
    #daddea 50%,
    ${color.grey[10]} 50%,
    ${color.grey[10]} 75%,
    #daddea 75%,
    #daddea 100%
  );
  transform: ${({ gap }) => `translateX(${gap}px)`};
  height: 32px;
  position: relative;
  width: ${({ width }) => (width ? width : 0)}px;
  border-radius: 4px;
`;

const getNextSlotDimensions = ({
  start,
  end,
  nextStart,
  nextEnd,
  containerWidth,
}: {
  start: Date;
  end?: Date;
  nextStart?: Date;
  nextEnd?: Date;
  containerWidth: number;
}) => {
  if (!end || !nextStart) {
    return { gap: undefined, width: undefined };
  }

  const duration = differenceInSeconds(end, start);
  const containerDuration = duration * 2;

  const gap = Math.ceil((differenceInSeconds(nextStart, end) * containerWidth) / containerDuration);
  if (gap >= containerWidth / 2) {
    return { gap: undefined, width: undefined };
  }

  const containerEnd = addSeconds(start, containerDuration);
  if (nextStart >= containerEnd) {
    return { gap: undefined, width: undefined };
  }

  const width = nextEnd
    ? Math.ceil((differenceInSeconds(nextEnd, nextStart) * containerWidth) / containerDuration)
    : containerWidth / 2 - gap - PADDING - SPACING - BORDER;

  if (width && width >= containerWidth / 2 + gap) {
    return { gap, width: containerWidth / 2 - gap - PADDING - SPACING - BORDER };
  }

  return { gap, width };
};

const PADDING = 4;
const SPACING = 4;
const BORDER = 1;

export default BedSlot;
