import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagementModal } from 'superadmin/components/ManagementDrawer';
import { useArea } from 'superadmin/context';
import areaManagementRoutes from '../routes';

const DeleteAreaModal = () => {
  const { id } = useParams();
  const { area, deleteArea } = useArea(id!);

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  if (!id) {
    setOpen(false);
    return <></>;
  }

  const onDelete = async () => {
    try {
      setLoading(true);
      await deleteArea(id);
      setOpen(false);
      toast.success(i18n.bedDeletedSuccess);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ManagementModal
      loading={!area}
      title={`${i18n.deleteArea} - ${area?.name}`}
      open={open}
      redirectUrl={areaManagementRoutes.areas}
    >
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
        >
          {i18n.cancel}
        </Button>

        <LoadingButton loading={loading} type="submit" onClick={onDelete}>
          {i18n.confirm}
        </LoadingButton>
      </Stack>
    </ManagementModal>
  );
};

export default DeleteAreaModal;
