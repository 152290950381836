import { PatientGender } from '@ambuliz/sabri-core';
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { TimeFlowLine } from 'common/components';
import getShortDuration from 'common/utils/getShortDuration';
import { format } from 'date-fns';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import BedName from 'kurt/pages/Unit/BedName';

type CleaningRowProps = {
  id: string;
  name: string;
  unitName: string;
  bedGender: PatientGender;
  startAt: Date;
  endAt?: Date;
  displayUnitColumn: boolean;
  onClick: (cleaningId: string) => void;
};

const CleaningRow = ({
  id,
  name,
  unitName,
  startAt,
  endAt,
  bedGender,
  displayUnitColumn,
  onClick,
}: CleaningRowProps) => {
  return (
    <>
      <TableRow
        onClick={() => onClick(id)}
        hover
        sx={{
          cursor: 'pointer',
        }}
      >
        <TableCell sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Box display="flex">
            <BedName name={name} />
          </Box>
        </TableCell>
        {displayUnitColumn && (
          <TableCell>
            <Typography variant="body2" color="secondary">
              {unitName}
            </Typography>
          </TableCell>
        )}
        <TableCell width={100}>
          <BedStatusChip status="CLEANING" gender={bedGender} />
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary" fontWeight={600}>
            -
          </Typography>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell width={80}>
          {startAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(startAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 156, paddingX: 0 }}>
          <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
            <TimeFlowLine start="dashed" size="small" />
            <Box width={60}>
              <Typography noWrap variant="body2" color="secondary">
                {startAt && endAt ? getShortDuration(endAt, startAt) : '-'}
              </Typography>
            </Box>
            <TimeFlowLine end="dashed" size="small" />
          </Stack>
        </TableCell>
        <TableCell>
          {endAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(endAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell></TableCell>
        <TableCell width={16} sx={{ paddingRight: 2 }}></TableCell>
        <TableCell width={16} sx={{ paddingLeft: 2 }}></TableCell>
      </TableRow>
    </>
  );
};

export default CleaningRow;
