import { useDraggable } from '@dnd-kit/core';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  cardHeaderClasses,
} from '@mui/material';
import CommentIcon from 'common/components/Icons/Comment';
import MoreVertical from 'common/components/Icons/MoreVertical';
import { i18n } from 'common/locale';
import { formatDateTime } from 'common/utils';
import AccommodationSpecificityBadges from 'kurt/components/AccommodationSpecificityBadges';
import { getAccommodationIcon } from 'kurt/components/DetailsDialog/DetailsDialog';
import { ColumnStatus } from 'kurt/pages/Movements/MovementsKanban';
import { Visit } from 'kurt/pages/Movements/useVisits';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import AccommodationCardMenu from '../AccommodationCardMenu';
import AccommodationHeaderTitle from '../AccommodationHeaderTitle';
import AdmissionAccommodationAdmittedMenu from '../AdmissionAccommodationAdmittedMenu';
import getColumnActions, { AccommodationAction } from '../VisitActions/columnActions';
import { VisitDetails } from '../VisitDetails';
import CardHeaderIcon from './CardHeaderIcon';
import CardSummary from './CardSummary';
import CardSummaryPatient from './CardSummaryPatient';
import { DataItem, DataItemList } from './DataItem';
import { getMouvementDateLabel } from './KanbanCardVisitSummary';

type KanbanCardProps = {
  flow: 'admission' | 'discharge';
  visit: Visit;
  status: ColumnStatus;
  isVisitActive?: boolean;
  isReadOnly?: boolean;
  onClick?: (id: string, type: 'accommodation' | 'mutation') => void;
};

const KanbanCard: React.FC<KanbanCardProps> = ({
  visit,
  flow,
  status,
  isVisitActive = false,
  isReadOnly = false,
  onClick,
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [over, setOver] = useState(false);
  const [menuActionName, setMenuActionName] = useState<AccommodationAction>();
  const [dragDisabled, setDragDisabled] = useState(isReadOnly);

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: visit.id,
    data: { status, data: visit },
    disabled: dragDisabled,
  });

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const handleCloseConfirmDialog = () => {
    setMenuActionName(undefined);
    setDragDisabled(false);
    setConfirmDialogOpen(false);
  };

  const handleMenuItemClick = (actionName: AccommodationAction) => {
    setMenuOpen(false);
    setMenuActionName(actionName);
    setConfirmDialogOpen(true);
  };

  const requestType = visit.basedOn?.requestType;
  const mutationReason = visit.basedOn?.reason || visit?.nextAccommodation?.basedOn?.reason || '';

  const isMutation = visit.isMutationRequest || (flow === 'admission' && visit.basedOn);
  const isDirectAdmission = requestType === 'DIRECT_ADMISSION';

  const actions = getColumnActions({
    flow,
    type: isMutation ? 'mutation' : 'default',
    status,
    requestType,
    isDischarged: visit.isVisitDischarged,
  });

  const cardHeaderIcon = getAccommodationIcon(isDirectAdmission, !!isMutation);

  const handleClick = () => {
    onClick?.(visit.id, 'accommodation');
  };

  return (
    <>
      <Card
        ref={setNodeRef}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
        elevation={over || menuOpen ? 4 : 2}
        style={{ cursor: isVisitActive ? undefined : 'pointer', opacity: isDragging ? 0.4 : 1 }}
        {...attributes}
        {...listeners}
        onClick={handleClick}
      >
        <CardHeader
          avatar={<CardHeaderIcon Icon={cardHeaderIcon.icon} color={cardHeaderIcon.color} hasBorder />}
          title={
            <AccommodationHeaderTitle
              direction="column"
              status={status}
              from={
                ('admission' === flow
                  ? visit.basedOn?.directAdmissionOrigin || visit.previousAccommodation?.unit.name
                  : visit.unit.name) || i18n.defaultOrigin
              }
              fromBed={(flow !== 'admission' ? visit.bed : visit.previousAccommodation?.bed?.name) || undefined}
              to={
                ('admission' === flow ? visit.unit.name : visit.nextAccommodation?.unit.name) || i18n.defaultDestination
              }
              toBed={
                (flow === 'admission' && visit.bed) ||
                (!!visit.nextAccommodation?.bed && visit.nextAccommodation?.bed.name) ||
                undefined
              }
            ></AccommodationHeaderTitle>
          }
          action={
            <Stack direction="row" spacing={1} alignItems="center">
              {visit.comment && (
                <Tooltip
                  title={
                    <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
                      {visit.comment}
                    </Typography>
                  }
                  placement="top"
                >
                  <TooltipIconContainer container margin={(actions.length === 0 && '4px 8px 0 0') || '0'}>
                    <CommentIcon color="secondary" />
                  </TooltipIconContainer>
                </Tooltip>
              )}
              {!isReadOnly && actions.length > 0 && (
                <IconButton
                  ref={menuButtonRef}
                  onMouseDown={(event) => event.stopPropagation()}
                  onClick={(event) => {
                    event.stopPropagation();
                    setMenuOpen(true);
                    setDragDisabled(true);
                  }}
                >
                  <MoreVertical />
                </IconButton>
              )}
              {flow === 'admission' && status === 'completed' && (
                <AdmissionAccommodationAdmittedMenu
                  id={visit.id}
                  unitId={visit.unit.id}
                  visitId={visit.patient.id}
                  bedId={visit.bedId}
                  specificities={visit.specificities}
                  comment={visit.comment}
                  isCompleted={visit.status === 'COMPLETED'}
                  start={visit.admissionDate}
                  end={visit.dischargeDate}
                  patient={{
                    name: visit.patient?.name,
                    gender: visit.patient?.gender,
                    birthdate: visit.patient?.birthdate,
                    isDischarged: visit.isVisitDischarged,
                  }}
                  onOpenChange={(open) => setDragDisabled(open)}
                  onDialogOpenChange={(open) => setDragDisabled(open)}
                />
              )}
            </Stack>
          }
          sx={isReadOnly ? { [`& .${cardHeaderClasses.action}`]: { margin: '0 !important' } } : undefined}
        />
        <CardContent sx={{ paddingTop: 3 }}>
          <Stack spacing={3}>
            <CardSummary>
              <CardSummaryPatient
                birthdate={visit.patient.birthdate}
                fullName={visit.patient.fullName}
                gender={visit.patient.gender}
              />
            </CardSummary>
            <DataItemList>
              <DataItem
                label={getMouvementDateLabel(flow, visit)}
                value={formatDateTime(flow === 'admission' ? visit.admissionDate : visit.dischargeDate)}
                minWidth={100}
              />
              <DataItem label={i18n.mutation.reason} value={mutationReason} />
              <Stack flex={1} alignItems="end">
                <Box>
                  <AccommodationSpecificityBadges specificities={visit.specificities} />
                </Box>
              </Stack>
            </DataItemList>
          </Stack>
        </CardContent>
      </Card>
      <AccommodationCardMenu
        open={menuOpen}
        anchorEl={menuButtonRef.current}
        onClose={() => {
          setMenuOpen(false);
          setDragDisabled(false);
        }}
        actions={actions}
        onActionClick={handleMenuItemClick}
        visitId={visit.patient.id}
      />
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog} maxWidth="sm" scroll="body">
        <VisitDetails
          onClose={handleCloseConfirmDialog}
          visit={visit}
          flow={flow}
          status={status}
          menuActionName={menuActionName}
        />
      </Dialog>
    </>
  );
};

const TooltipIconContainer = styled(Grid)`
  cursor: pointer;
  justify-content: flex-end;
  width: auto;
`;

export default KanbanCard;
