import Api, { Appointment } from '@ambuliz/sabri-core';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { DialogHeader } from 'common/components';
import { PatientSummary } from 'common/components/PatientAutocomplete';
import useParseQuery from 'common/hooks/useParseQuery';
import { formatName } from 'common/utils';
import { ContentLoader } from 'core/layout';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const AppointmentDetailsDialog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { appointment, loading } = useAppointment(id!);

  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionProps={{ onExited: () => navigate((state as any)?.from || '../') }}
        scroll="body"
      >
        <DialogHeader title="Appointment" onClose={handleClose} />
        <DialogContent>
          <AppointmentDetails loading={loading} appointment={appointment} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const AppointmentDetails = ({ loading = false, appointment }: { loading?: boolean; appointment?: Appointment }) => {
  if (loading) {
    return <ContentLoader />;
  }

  return appointment ? (
    <Stack spacing={4}>
      <PatientSummary
        ipp=""
        pan=""
        name={formatName(
          appointment.patient.firstName,
          appointment.patient.lastName,
          appointment.patient.legalName,
          appointment.patient.legalFirstName
        )}
        birthdate={appointment.patient.birthdate}
        gender={appointment.patient.gender}
        primary
      />
      <Stack direction="row" spacing={4}>
        <Typography>Début :</Typography>
        <Typography>{format(appointment.start, 'dd/MM - HH:mm')}</Typography>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Typography>Fin :</Typography>
        <Typography>{format(appointment.end, 'dd/MM - HH:mm')}</Typography>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Typography>Localisation :</Typography>
        <Typography>{appointment.location ? appointment.location.name : ''}</Typography>
      </Stack>
    </Stack>
  ) : null;
};

const useAppointment = (id: string) => {
  const { results, isLoading } = useParseQuery(
    new Api.Query(Appointment).include('patient', 'location').equalTo('objectId', id).limit(1)
  );

  return { appointment: results?.[0], loading: isLoading };
};

export default AppointmentDetailsDialog;
