import { SvgIcon, SvgIconProps } from '@mui/material';

const ExitIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.62503 0.00219727C0.727552 0.0021826 0 0.729725 0 1.6272V14.3749C0 15.2723 0.727521 15.9999 1.62497 15.9999L8.27213 15.9999C9.16961 15.9999 9.89716 15.2724 9.89716 14.3749V11.6661C9.89716 11.3209 9.61734 11.0411 9.27216 11.0411C8.92698 11.0411 8.64716 11.3209 8.64716 11.6661V14.3749C8.64716 14.582 8.47926 14.7499 8.27215 14.7499L1.62499 14.7499C1.41789 14.7499 1.25 14.582 1.25 14.3749V1.6272C1.25 1.42009 1.4179 1.25219 1.62501 1.2522H8.27216C8.47927 1.2522 8.64716 1.42009 8.64716 1.6272V4.21926C8.64716 4.56443 8.92698 4.84426 9.27216 4.84426C9.61734 4.84426 9.89716 4.56443 9.89716 4.21926V1.6272C9.89716 0.729745 9.16964 0.00221199 8.27219 0.00219727H1.62503ZM12.7359 4.46236C12.4919 4.21828 12.0961 4.21828 11.8521 4.46236C11.608 4.70643 11.608 5.10216 11.8521 5.34624L13.8661 7.3603H3.60608C3.2609 7.3603 2.98108 7.64012 2.98108 7.9853C2.98108 8.33048 3.2609 8.6103 3.60608 8.6103H13.9276L11.8391 10.8275C11.6024 11.0787 11.6142 11.4743 11.8654 11.7109C12.1167 11.9476 12.5123 11.9358 12.7489 11.6846L15.8299 8.41385C16.0616 8.16788 16.0559 7.7823 15.8169 7.54336L12.7359 4.46236Z"
    />
  </SvgIcon>
);

export default ExitIcon;
