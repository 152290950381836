import { Notification, NotificationCardContent, NotificationToastContent } from 'common/components';
import { color, palette } from 'common/theme';
import { toast } from 'react-toastify';
import { getPathFromNotification, getSeverity } from './triggerEventUtils';

const showNotificationToast = (notification: Notification, onClick: () => void, onClose: () => void) => {
  return toast.info(
    <NotificationToastContent
      content={NotificationCardContent({ notification })}
      severity={getSeverity(notification.triggerEvent)}
      path={getPathFromNotification(notification)}
      id={notification.id}
    />,
    {
      position: 'top-right',
      toastId: notification.id,
      onClick,
      onClose,
      style: {
        backgroundColor: color.blue[5],
        color: palette.text.primary,
        border: `1px solid ${color.grey[10]}`,
        borderRadius: 8,
      },
      draggable: false,
    }
  );
};

export default showNotificationToast;
