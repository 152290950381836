import { WebUserRole } from '@ambuliz/sabri-core';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useAuthentication, useReadOnly } from 'core/authentication';
import { PageContent, PageHeader, PageSection } from 'core/layout';
import { isValid } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { requestNames } from '../../const/requests';
import { routeNamesCommon, routeNamesCommonDynamic } from '../../const/routeNames';
import { i18n } from '../../locales';
import { getDefaultFareListRoute, getFareListColumns } from '../../services/fare';
import { AreaStored, FareStored } from '../../store/reducers/entity';
import { FareFilterStatus, selectFilteredFares } from '../../store/selectors/fare';
import { selectUserRole } from '../../store/selectors/general';
import { selectRequestStatus } from '../../store/selectors/requestStatus';
import { TableList } from '../TableList/TableList';
import FareListFilter, { fareListTabsByRole, isFilterStatusRoute } from './FareListFilter';

const EDIT_ROLES: WebUserRole[] = ['REGULATOR', 'MANAGER', 'ADMIN_REGULATOR', 'BED_MANAGER'];

const getStatusFromPathName = (pathname: string) => {
  const paths = pathname.split('/');
  const lastPath = paths[paths.length - 1];
  return (lastPath === 'fares' ? 'active' : lastPath) as FareFilterStatus;
};

const isRouteAuthorized = (route: string, role: WebUserRole) =>
  fareListTabsByRole(role)
    .map((tab) => tab.route)
    .includes(route);

const getFilterStatus = (route: string, role: WebUserRole) => {
  if (route === routeNamesCommon.fareList) {
    route = getDefaultFareListRoute(role);
  }

  return isFilterStatusRoute(route) && isRouteAuthorized(route, role) ? getStatusFromPathName(route) : null;
};

export const FareList: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, units } = useAuthentication();

  const { isReadOnly } = useReadOnly();
  const displayCreateFareButton = !isReadOnly && EDIT_ROLES.includes(user.role);

  const role = useSelector(selectUserRole);
  const columns = useMemo(() => (role ? getFareListColumns(role) : []), [role]);

  const [inputFilter, setInputFilter] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<FareFilterStatus>('active');
  const [areaFilter, setAreaFilter] = useState<AreaStored[]>([]);
  const [dateFilter, setDateFilter] = useState<Date>(new Date());

  const faresSelected = useSelector(selectFilteredFares(inputFilter, filterStatus, dateFilter, areaFilter, units));
  const getFaresStatus = useSelector(selectRequestStatus(requestNames.getFares));

  // On met à jour le statut seulement quand l'url est une URL de filtrage valide (un FareListStatus)
  useEffect(() => {
    const status = getFilterStatus(pathname, user.role);
    if (status) {
      setFilterStatus(status);
    }
  }, [pathname, navigate, user.role]);

  useEffect(() => {
    const storedPreferences = localStorage.getItem(`filters-preferences-${user.id}`);
    if (storedPreferences) {
      setAreaFilter(JSON.parse(storedPreferences) as AreaStored[]);
    }
  }, [user.id]);

  const handleClickFare = (fare: FareStored) => {
    navigate(
      fare.status === 'DRAFT'
        ? routeNamesCommonDynamic.fareEditDraft(fare.objectId)
        : routeNamesCommonDynamic.fareDetails(fare.objectId)
    );
  };

  const handleInputFilterChange = (search: string) => {
    setInputFilter(search || '');
  };

  const handleAreaFilterChange = (areas: AreaStored[]) => {
    setAreaFilter(areas || []);
    if (areas) {
      localStorage.setItem(`filters-preferences-${user.id}`, JSON.stringify(areas || []));
    } else localStorage.remove(`filters-preferences-${user.id}`);
  };

  const handleDateFilterChange = (date: Date | null) => {
    if (date && isValid(date)) {
      setDateFilter(date);
    }
  };

  return (
    <PageContent>
      <PageHeader title={i18n.fares}>
        {displayCreateFareButton && (
          <Button
            variant="outlined"
            size="large"
            endIcon={<AddCircleOutline />}
            onClick={() => navigate(routeNamesCommon.fareCreation)}
          >
            {i18n.orderJourneyCTA}
          </Button>
        )}
      </PageHeader>
      <PageSection>
        <FareListFilter
          inputFilter={inputFilter}
          onInputFilterChange={handleInputFilterChange}
          status={filterStatus}
          resultsCount={faresSelected.length}
          areaFilter={areaFilter}
          onAreaFilterChange={handleAreaFilterChange}
          onDateFilterChange={handleDateFilterChange}
          dateFilter={dateFilter}
        />
        <TableList
          size="small"
          data={faresSelected}
          columns={columns}
          initialSortBy="wantedDate"
          isLoading={['LOADING', 'NOT_CALLED'].includes(getFaresStatus)}
          onClickRow={handleClickFare}
        />
        <Box height={100} width="100%"></Box>
      </PageSection>
      <Outlet />
    </PageContent>
  );
};
