import { Skeleton, TableCell, TableRow } from '@mui/material';

export const ThesaurusTableSkeleton = ({ count }: { count: number }) => {
  return Array.from({ length: count }).map((_, index) => (
    <TableRow key={index}>
      {Array.from({ length: 7 }).map((_, index) => (
        <TableCell key={index}>
          <Skeleton variant="rounded" />
        </TableCell>
      ))}
    </TableRow>
  ));
};
