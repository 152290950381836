import { SVGProps } from 'react';

const SilboThemeImage = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="158" height="100" viewBox="0 0 158 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M100.661 -50.2158C86.19 -45.6162 78.7204 -30.5744 78.3554 -19.4365C77.8781 -8.18342 93.7536 8.38168 102.471 13.2488C111.194 18.0891 112.82 18.0483 121.285 26.2573C129.863 34.351 137.138 57.6692 148.06 66.4023C158.87 75.2505 172.211 75.9106 187.458 65.5137C202.677 55.1104 205.571 42.2745 200.719 22.6187C195.847 2.92982 175.733 -19.4558 175.733 -19.4558C170.197 -26.2635 157.443 -38.5193 141.554 -46.7544C124.377 -55.776 110.775 -53.2949 100.661 -50.2158Z"
        fill="#4EB7EB"
      />
      <path
        d="M80.4466 68.5281C57.7395 70.2604 52.6574 72.3174 38.3844 83.036C14.596 100.792 -2.05595 130.025 1.18793 159.582C3.45864 180.478 15.2447 199.316 29.734 214.582C38.6006 224.002 48.7648 232.447 60.659 237.535C73.9588 243.274 88.8807 244.681 103.262 243.598C118.832 242.407 134.295 238.401 147.703 230.281C166.193 219.13 179.817 200.399 185.44 179.503C191.063 158.608 188.792 135.871 179.817 116.166C177.33 110.645 174.195 105.339 170.194 100.792C165.761 95.8117 160.246 91.8058 154.515 88.3412C132.349 75.0242 106.289 67.9867 80.4466 68.5281Z"
        fill="#F7C565"
      />
      <path
        d="M87.273 48.2284C96.4052 41.3072 109.382 43.1336 116.304 52.2657C121.783 59.4753 121.783 69.0881 117.073 76.2016C115.823 78.1241 114.189 79.8544 112.266 81.2963C103.134 88.2175 90.1569 86.3911 83.2357 77.2589C76.3145 68.1268 78.1409 55.1496 87.273 48.2284Z"
        fill="#F28966"
      />
      <path
        d="M100.426 64.3794L94.6581 63.4181L83.1228 61.5916C82.546 61.4955 82.0654 61.88 81.9693 62.4568C81.777 64.86 82.3538 67.0709 83.6996 68.8973C85.2376 71.0121 87.3524 72.3579 90.1401 72.7424C92.9278 73.2231 95.331 72.6463 97.4458 71.1083C99.3684 69.7625 100.618 67.8399 101.195 65.5328C101.387 65.0522 101.003 64.4755 100.426 64.3794Z"
        fill="#0D088F"
      />
      <path
        d="M59.0597 -19.1163L56.3595 -17.6405C53.6228 -16.1296 50.1198 -17.0432 48.5508 -19.6434L47.0182 -22.2436C45.4492 -24.8789 41.9463 -25.7925 39.2096 -24.2464L28.1898 -18.1325C25.4531 -16.6216 24.5044 -13.2483 26.1099 -10.613L27.6425 -8.01282C29.2115 -5.37749 28.2628 -2.00426 25.5626 -0.493341L22.8624 0.982447C20.1257 2.49337 19.177 5.86658 20.7825 8.5019L27.1317 19.1135C28.7007 21.7488 32.2036 22.6624 34.9403 21.1163L37.6405 19.6406C40.3772 18.1296 43.8802 19.0432 45.4492 21.6434L46.9818 24.2436C48.5508 26.8789 52.0537 27.7925 54.7904 26.2464L65.8102 20.1325C68.5468 18.6216 69.4956 15.2483 67.89 12.613L66.3575 10.0128C64.7885 7.37749 65.7372 4.00428 68.4374 2.49336L71.1376 1.01757C73.8743 -0.493347 74.823 -3.86658 73.2175 -6.5019L66.8683 -17.1135C65.2993 -19.7488 61.7964 -20.6624 59.0597 -19.1163Z"
        fill="#44B278"
      />
      <path
        d="M151.437 -108.552C136.04 -107.378 132.593 -105.985 122.915 -98.7264C106.784 -86.7015 95.4922 -66.9044 97.6918 -46.8873C99.2316 -32.7361 107.224 -19.978 117.049 -9.63949C123.061 -3.26042 129.954 2.45875 138.019 5.90491C147.038 9.791 157.156 10.7442 166.908 10.011C177.467 9.20444 187.952 6.49148 197.044 0.992287C209.582 -6.55994 218.821 -19.2447 222.634 -33.396C226.446 -47.5472 224.907 -62.945 218.821 -76.2897C217.134 -80.0291 215.008 -83.6219 212.295 -86.7015C209.289 -90.0743 205.549 -92.7873 201.663 -95.1336C186.632 -104.152 168.961 -108.918 151.437 -108.552Z"
        fill="#FFF7E9"
      />
    </svg>
  );
};

export default SilboThemeImage;
