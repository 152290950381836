import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { modalNames } from '../../const/modalNames';
import { i18n } from '../../locales';
import { ModalContainer } from '../ModalContainer/ModalContainer';

export const CancelPullFareModal: React.FC = () => {
  return (
    <ModalContainer modalName={modalNames.cancelPullFare}>
      {({ isVisible, params, onClose }) =>
        isVisible &&
        params?.patientFullName &&
        params?.onConfirm && (
          <Dialog open onClose={onClose}>
            <DialogTitle>{i18n.modalCancelPullFare.title}</DialogTitle>
            <DialogContent>
              <Box display="inline" fontSize={14}>
                {i18n.modalCancelPullFare.body1}
              </Box>
              <Box display="inline" fontSize={14} fontWeight="bold">
                {` ${params?.patientFullName}`}
              </Box>
              <Box display="inline" fontSize={14}>
                {`. ${i18n.modalCancelPullFare.body2}`}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={onClose}>
                {i18n.cancel}
              </Button>
              <Box marginLeft={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    params.onConfirm();
                    onClose();
                  }}
                >
                  {i18n.modalCancelPullFare.buttonConfirmation}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )
      }
    </ModalContainer>
  );
};
