import { createSelector } from '@reduxjs/toolkit';
import { EntityNames } from '../../const/schemas';
import { AppState } from '../store';
import { selectEntitiesValues } from './entity';

export const getUnits = (state: AppState) => state.entity.units;

export const selectUnits = createSelector(selectEntitiesValues(EntityNames.units), (units) =>
  units.filter((unit) => !!unit.name).sort((a, b) => -b.name.localeCompare(a.name))
);

export const selectUnit = (id?: string) =>
  createSelector([selectUnits], (units) => units.find((unit) => unit.objectId === id));
