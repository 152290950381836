import { Divider, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import { ChevronRight } from 'common/components/Icons';
import { color, theme } from 'common/theme';
import { Children, ReactNode, cloneElement, isValidElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type CardProps = {
  children?: ReactNode;
  header?: {
    title: string;
    option?: {
      label: string;
      onClick: () => void;
    };
  };
  subheader?: string;
  xs?: number | boolean | 'auto';
  sm?: number | boolean | 'auto';
  md?: number | boolean | 'auto';
  lg?: number | boolean | 'auto';
  xl?: number | boolean | 'auto';
};

const Card = ({ children, lg, md, header, sm, xl, xs, subheader }: CardProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const pageContainerRef = useRef<HTMLDivElement>(null);
  const childrenRefs = Children.map<React.MutableRefObject<HTMLDivElement>, ReactNode>(children, useRef) || [];

  const [shouldDisplayRightButton, setShouldDisplayRightButton] = useState(false);
  const [shouldDisplayLeftButton, setShouldDisplayLeftButton] = useState(false);

  useEffect(() => {
    const pageContainer = pageContainerRef.current;
    const container = containerRef.current;
    const handleScroll = () => {
      if (container && pageContainer) {
        setShouldDisplayLeftButton(pageContainer.scrollLeft >= 1);
        setShouldDisplayRightButton(
          Math.ceil(pageContainer.scrollLeft) < pageContainer.scrollWidth - container.scrollWidth &&
            pageContainer.scrollWidth > container.offsetWidth
        );
      }
    };

    pageContainer?.addEventListener('scroll', handleScroll);
    return () => {
      pageContainer?.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    const pageContainer = pageContainerRef.current;
    const container = containerRef.current;
    const handleLayout = () => {
      if (container && pageContainer) {
        setShouldDisplayRightButton(
          Math.ceil(pageContainer.scrollLeft) < pageContainer.scrollWidth - container.scrollWidth &&
            pageContainer.scrollWidth > container.offsetWidth
        );
      }
    };
    handleLayout();
    window.addEventListener('resize', handleLayout);
    return () => window.removeEventListener('resize', handleLayout);
  });

  const childrenWithRefs = Children.map(children, (child, index) =>
    isValidElement(child) && !!child
      ? cloneElement(child, {
          ...child.props,
          ref: childrenRefs[index],
        })
      : null
  )?.filter((child) => !!child);

  const handleScroll = (to: number) => {
    if (pageContainerRef.current) {
      pageContainerRef.current.scrollTo({ left: pageContainerRef.current.scrollLeft + to });
    }
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Stack component={Paper} direction="column" ref={containerRef} height={116} overflow="hidden">
        {header && (
          <Stack direction="row" justifyContent="space-between" paddingX={4} paddingTop={4} spacing={2}>
            <Typography variant="h4">{header.title}</Typography>
            {header.option && (
              <Link component="button" onClick={header.option.onClick} variant="body2" fontWeight={600}>
                {header.option.label}
              </Link>
            )}
          </Stack>
        )}
        <Stack position="relative" height="100%">
          <Stack
            padding={4}
            ref={pageContainerRef}
            direction="row"
            divider={<Divider flexItem orientation="vertical" />}
            spacing={4}
            sx={{
              overflowX: 'auto',
              overflowY: 'hidden',
              [`&::-webkit-scrollbar`]: { display: 'none' },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              scrollBehavior: 'smooth',
              height: '100%',
              alignItems: 'center',
            }}
          >
            {childrenWithRefs}
          </Stack>
          {shouldDisplayLeftButton && (
            <LeftPageButton onClick={() => handleScroll(-SCROLL_AMOUNT)}>
              <IconContainer>
                <ChevronRight sx={{ fontSize: 12 }} />
              </IconContainer>
            </LeftPageButton>
          )}
          {shouldDisplayRightButton && (
            <RightPageButton onClick={() => handleScroll(SCROLL_AMOUNT)}>
              <IconContainer>
                <ChevronRight sx={{ fontSize: 12 }} />
              </IconContainer>
            </RightPageButton>
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};

const MINIMUM_CARD_WIDTH = 100;
const CARD_SPACING = 33; // 32px + 1px divider
const SCROLL_AMOUNT = MINIMUM_CARD_WIDTH + CARD_SPACING;

export default Card;

const LeftPageButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  position: absolute;
  width: 50px;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.default.main};
  background: linear-gradient(270deg, #ffffff 14.56%, rgba(255, 255, 255, 0.81) 56.69%, rgba(255, 255, 255, 0) 94.05%);
  transform: rotate(-180deg);
  border-radius: 8px;
`;
const RightPageButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  position: absolute;
  width: 50px;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.default.main};
  background: linear-gradient(270deg, #ffffff 14.56%, rgba(255, 255, 255, 0.81) 56.69%, rgba(255, 255, 255, 0) 94.05%);
  border-radius: 8px;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${color.blue[5]};
  border: solid 1px ${color.grey[10]};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(20, 22, 28, 0.08);
  border-radius: 4px;
`;
