import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { color } from 'common/theme';
import { formatAge, getPatientAge } from 'common/utils';
import { format, isValid } from 'date-fns';
import AccommodationSpecificityBadges from 'kurt/components/AccommodationSpecificityBadges';
import EditableTableCell from 'kurt/components/EditableTableCell/EditableTableCell';
import MutationStatusChip from 'kurt/components/StepStatus/MutationStatusChip';
import { useAccommodation } from 'kurt/hooks';
import { useEffect, useState } from 'react';
import { MovementRow } from '../MovementRow';
import { MovementActionMenu } from './MovementsActions';

const AdmittedRow = ({
  movement,
  showUnit,
  isReadOnly = false,
  onClick,
}: {
  movement: MovementRow;
  showUnit?: boolean;
  isReadOnly?: boolean;
  onClick: (id: string, type: 'accommodation' | 'mutation') => void;
}) => {
  const [comment, setComment] = useState(movement.comment || '');
  const { update, loading } = useAccommodation(movement.id);

  useEffect(() => setComment(movement.comment || ''), [movement.comment]);

  const age = getPatientAge(movement.patientBirthdate);

  const handleCommentSubmit = (value: string) => {
    if (value !== movement.comment) {
      update({ comment: value });
    }
  };

  return (
    <>
      <TableRow
        onClick={() => onClick(movement.id, 'mutation')}
        hover
        sx={{
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }}
      >
        <TableCell width={14} sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Typography fontWeight={600} color="secondary" variant="body2">
            {movement.date && isValid(movement.date) ? format(movement.date, 'dd/MM - HH:mm') : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <MutationStatusChip status="BLOCKED" />
        </TableCell>
        <TableCell>
          <EllipsisTypography tooltipProps={{ title: 'Lit ' + movement.bed || '-' }} fontWeight={600}>
            {movement.bed || '-'}
          </EllipsisTypography>
        </TableCell>
        {showUnit && (
          <TableCell>
            <Typography variant="body2" color="secondary">
              {movement.unitName || '-'}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <PatientNameWithEmoji
            name={movement.patientName}
            gender={movement.patientGender}
            bgcolor={color.blue[5]}
            age={age}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary">
            {formatAge(movement.patientBirthdate)}
          </Typography>
        </TableCell>
        <TableCell>
          <EllipsisTypography variant="body2" color="secondary" maxLines={3}>
            {movement.reason}
          </EllipsisTypography>
        </TableCell>
        <TableCell>
          <Box display="flex" sx={{ cursor: 'pointer' }}>
            <AccommodationSpecificityBadges specificities={movement.specificities}></AccommodationSpecificityBadges>
          </Box>
        </TableCell>
        <EditableTableCell
          value={comment}
          onChange={setComment}
          onSubmit={handleCommentSubmit}
          loading={loading}
          isReadOnly={isReadOnly}
        />
        {!isReadOnly && <TableCell></TableCell>}
        {!isReadOnly && (
          <TableCell width={16} sx={{ paddingLeft: 2 }}>
            <MovementActionMenu flow="discharge" type="mutation" status="blocked" movement={movement} />
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default AdmittedRow;
