import Api, { FareAssignation, Porter } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useAvailablePorters } from './useAvailablePorters';

export const usePorterAssignations = (fareAssignations: FareAssignation[], fetchAssignablePorters?: boolean) => {
  const assignedPorterIds = fareAssignations.map((assignation) => assignation.porter.id);

  const { results: assignedPorters, isLoading: assignedPortersLoading } = useParseQuery(
    new Api.Query(Porter).containedIn('objectId', assignedPorterIds),
    { enabled: assignedPorterIds.length > 0 }
  );

  const { availablePorters, isLoading: availablePortersLoading } = useAvailablePorters(fetchAssignablePorters);

  return {
    assignedPorters: fareAssignations
      .map((fareAssignation) => ({
        fareAssignationId: fareAssignation.id,
        porter: assignedPorters.find(({ id }) => id === fareAssignation.porter.id),
      }))
      .filter(({ porter }) => !!porter) as { fareAssignationId: string; porter: Porter }[],
    availablePorters: availablePorters.filter(({ id }) => !assignedPorterIds.includes(id)),
    isLoading: assignedPortersLoading || availablePortersLoading,
  };
};
