import { ExternalTypeCode } from '@ambuliz/sabri-core';
import { Chip, ChipOwnProps } from '@mui/material';
import { i18n } from 'common/locale';

const AreaTypeColor: Record<ExternalTypeCode, ChipOwnProps['color']> = {
  hospital: 'info',
  unit: 'danger',
  bed: 'error',
  room: 'primary',
  service: 'primaryAlt',
};

export const AreaTypeChip = ({ type }: { type: ExternalTypeCode }) => {
  return <Chip label={i18n[type] || 'inconnu'} color={AreaTypeColor[type]} />;
};
