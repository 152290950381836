import { Area, Unit } from '@ambuliz/sabri-core';
import { useEffect, useMemo, useState } from 'react';
import { getBedsByUnit } from './getBedsByUnit';

type UseBedsFromUnitsOptions = {
  enabled?: boolean;
  filterInactive?: boolean;
};

export const useBedsFromUnit = (unitId: string | string[], options?: UseBedsFromUnitsOptions) => {
  const { enabled = true, filterInactive = true } = options || {};

  const [isLoading, setIsLoading] = useState(true);
  const [bedsWithUnits, setBedsWithUnits] = useState<{ unit: Unit; bed: Area }[]>([]);

  useEffect(() => {
    const fetchBeds = async () => {
      setIsLoading(true);
      const unitIds = unitId instanceof Array ? unitId : [unitId];

      setBedsWithUnits(await getBedsByUnit(unitIds, filterInactive));
      setIsLoading(false);
    };

    if (enabled && unitId && unitId.length > 0) {
      fetchBeds();
    } else {
      setBedsWithUnits((old) => (old.length ? [] : old));
      setIsLoading(false);
    }
  }, [unitId, enabled, filterInactive]);

  return {
    beds: useMemo(() => bedsWithUnits.map(({ bed }) => bed), [bedsWithUnits]),
    bedsWithUnits,
    isLoading,
  };
};
