import { Stack, Typography } from '@mui/material';
import { ConfirmDialog, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';

type ReportPatientAdmittedDialogProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const ReportPatientAdmittedDialog = ({ id, open, onClose, onSuccess }: ReportPatientAdmittedDialogProps) => {
  const { update } = useAccommodation(id);

  const handleConfirm = async () => {
    await update({ startAt: new Date(), status: 'IN_PROGRESS' });
    toast.success({
      ...i18n.patientAdmittedReportSuccess,
      icon: <Emoji size={24} name="backhandIndexPointingRight" />,
    });
    onSuccess();
  };

  return (
    <ConfirmDialog
      onConfirm={handleConfirm}
      onClose={onClose}
      open={open}
      title={
        <Stack spacing={2} alignItems="center" direction="row">
          <Emoji size={24} name="wavingHand" />
          <Typography variant="h2">{i18n.reportPatientAdmitted.title}</Typography>
        </Stack>
      }
      confirmLabel={i18n.reportPatientAdmitted.confirmLabel}
    >
      {i18n.reportPatientAdmitted.content.map((text, index) => (
        <Typography color="secondary" key={index}>
          {text}
        </Typography>
      ))}
    </ConfirmDialog>
  );
};

export default ReportPatientAdmittedDialog;
