import {
  endOfWeek,
  endOfYesterday,
  format,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { DateFilters } from '../const/date';

export const getDisplayDate = (date: string | undefined) => (date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : '');

export const getStartDateFromDateFilter = (dateFilter: DateFilters) => {
  const now = new Date();
  switch (dateFilter) {
    case 'TODAY': {
      return { start: startOfToday(), end: now };
    }
    case 'YESTERDAY': {
      return { start: startOfYesterday(), end: endOfYesterday() };
    }
    case 'THIS_WEEK': {
      return { start: startOfWeek(now, { weekStartsOn: 1 }), end: now };
    }
    case 'LAST_WEEK': {
      return {
        start: startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }),
        end: endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }),
      };
    }
    case 'LAST_THIRTY_DAYS': {
      return { start: subDays(now, 30), end: now };
    }
    case 'LAST_NINETY_DAYS': {
      return { start: subDays(now, 90), end: now };
    }
    case 'LAST_SIX_MONTHS': {
      return { start: subMonths(now, 6), end: now };
    }
    case 'LAST_TWELVE_MONTHS': {
      return { start: subMonths(now, 12), end: now };
    }
    case 'THIS_YEAR': {
      return { start: startOfYear(now), end: now };
    }
    default:
      return { end: now };
  }
};
