import { FareStatus } from '@ambuliz/sabri-core';
import { ExitToApp } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import ChevronRightIcon from 'common/components/Icons/ChevronRight';
import CrossSmallIcon from 'common/components/Icons/CrossSmall';
import EditIcon from 'common/components/Icons/Pencil';
import WarningIcon from 'common/components/Icons/Warning';
import { useReadOnly } from 'core/authentication';
import { i18n } from 'sabri/locales';
import styled from 'styled-components';

type FareDetailsActionsProps = {
  status?: FareStatus;
  onDelete?: () => void;
  onEdit?: () => void;
  onTick?: () => void;
  onCreateIncident?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const FareDetailsActions: React.FC<FareDetailsActionsProps> = ({
  status,
  onDelete,
  onEdit,
  onTick,
  onCreateIncident,
  onCancel,
  onConfirm,
}) => {
  const { isReadOnly } = useReadOnly();

  return isReadOnly ? null : (
    <Stack direction="row" gap={2} flexWrap="wrap" justifyContent="flex-end">
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={onDelete}
        disabled={!onDelete}
        startIcon={<CrossSmallIcon color={onDelete ? 'error' : 'inherit'} />}
      >
        {i18n.cancelJourney}
      </Button>

      <StyledButton
        size="small"
        variant="outlined"
        color="secondary"
        onClick={onEdit}
        disabled={!onEdit}
        startIcon={<EditIcon />}
      >
        {i18n.editJourney}
      </StyledButton>
      <StyledButton
        size="small"
        variant="outlined"
        color="secondary"
        onClick={onTick}
        disabled={!onTick}
        startIcon={<ChevronRightIcon />}
      >
        {getNextTickLabel(status || 'PENDING')}
      </StyledButton>
      <StyledButton
        size="small"
        variant="outlined"
        color="secondary"
        onClick={onCreateIncident}
        disabled={!onCreateIncident}
        startIcon={<WarningIcon color={onCreateIncident ? 'error' : 'inherit'} />}
      >
        {i18n.createAnIncident}
      </StyledButton>
      {onConfirm && (
        <Tooltip title={i18n.tooltipFareConfirmation}>
          <span>
            <StyledButton
              size="small"
              startIcon={<ExitToApp />}
              onClick={onConfirm}
              variant="outlined"
              color="secondary"
            >
              {i18n.pushPatient}
            </StyledButton>
          </span>
        </Tooltip>
      )}
      {onCancel && (
        <Tooltip title={i18n.cancelPushFare}>
          <span>
            <StyledButton
              size="small"
              startIcon={<ExitToApp color="error" />}
              onClick={onCancel}
              variant="outlined"
              color="secondary"
            >
              {i18n.cancelPushFare}
            </StyledButton>
          </span>
        </Tooltip>
      )}
    </Stack>
  );
};

const StyledButton = styled(Button)`
  & svg {
    font-size: 12px !important;
  }
`;

const getNextTickLabel = (status: FareStatus) => {
  switch (status) {
    case 'STARTED':
      return i18n.declarePatientCare;
    case 'PATIENT_CARE':
      return i18n.endFare;
    default:
      return i18n.startFare;
  }
};

export default FareDetailsActions;
