import { SVGProps } from 'react';

const Desk = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 122 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.43.016c-11.166.86-13.668 1.888-20.736 7.142C6.964 15.88-1.246 30.293.371 44.821c1.142 10.278 6.946 19.536 14.07 27.036 4.378 4.614 9.389 8.762 15.224 11.295 6.55 2.841 13.879 3.526 21.002 2.985 7.654-.58 15.284-2.575 21.862-6.537 9.109-5.493 15.826-14.706 18.602-24.975 2.778-10.27 1.658-21.481-2.776-31.152-1.239-2.705-2.744-5.314-4.72-7.542-2.181-2.465-4.898-4.406-7.712-6.121C65.015 3.175 52.193-.267 39.429.016Z"
      fill="#E6EDFF"
    />
    <path
      d="M16.695 89.188c-.92 0-1.666-.748-1.666-1.668V61.958h3.339V87.52c0 .92-.753 1.668-1.673 1.668Z"
      fill="#B5CAFF"
    />
    <path
      d="M19.181 61.146H14.22V87.52A2.48 2.48 0 0 0 16.697 90a2.48 2.48 0 0 0 2.484-2.48V61.146Zm-1.624 1.624v24.75a.858.858 0 0 1-1.714 0V62.77M98.89 89.188c-.92 0-1.673-.748-1.673-1.668V61.958h3.338V87.52c0 .92-.746 1.668-1.666 1.668Z"
      fill="#B5CAFF"
    />
    <path
      d="M101.367 61.146h-4.963V87.52c0 1.37 1.115 2.48 2.485 2.48 1.37 0 2.478-1.11 2.478-2.48V61.146Zm-1.624 1.624v24.75a.856.856 0 0 1-1.715 0V62.77"
      fill="#B5CAFF"
    />
    <path
      d="M85.509 72.284H60.274a2.804 2.804 0 0 1-2.803-2.804v-6.82h30.842v6.82a2.805 2.805 0 0 1-2.804 2.804Z"
      fill="#89A5ED"
    />
    <path
      d="M8.75 63.406c-.839 0-1.52-.683-1.52-1.522v-4.316c0-.84.681-1.522 1.52-1.522h98.085c.839 0 1.52.683 1.52 1.522v4.316c0 .839-.681 1.522-1.52 1.522"
      fill="#B5CAFF"
    />
    <path
      d="M106.834 55.234H8.75a2.333 2.333 0 0 0-2.332 2.334v4.316a2.333 2.333 0 0 0 2.332 2.334h98.084a2.333 2.333 0 0 0 2.332-2.334v-4.316a2.333 2.333 0 0 0-2.332-2.334Zm0 1.624c.389 0 .708.32.708.71v4.316a.71.71 0 0 1-.708.71H8.75a.71.71 0 0 1-.708-.71v-4.316c0-.39.319-.71.708-.71"
      fill="#B5CAFF"
    />
    <path d="M39.748 51.6h11.745v-4.724H39.748V51.6Z" fill="#5470B8" />
    <path d="M52.308 46.064H38.94v6.347h13.368v-6.347Zm-1.623 4.724H40.563v-3.1h10.122v3.1Z" fill="#5470B8" />
    <path
      d="M58.332 55.234H32.914c-.878 0-1.586-.71-1.586-1.586 0-.877.708-1.588 1.586-1.588h25.418c.878 0 1.586.71 1.586 1.588 0 .876-.708 1.586-1.586 1.586Z"
      fill="#5470B8"
    />
    <path
      d="M21.314 46.904a2.63 2.63 0 0 1-2.627-2.624V17.675a2.63 2.63 0 0 1 2.627-2.624h49.64a2.629 2.629 0 0 1 2.624 2.624V44.28a2.629 2.629 0 0 1-2.624 2.624"
      fill="#fff"
    />
    <path
      d="M70.953 14.24h-49.64a3.44 3.44 0 0 0-3.438 3.435V44.28a3.44 3.44 0 0 0 3.438 3.435h49.64a3.436 3.436 0 0 0 3.436-3.435V17.675a3.436 3.436 0 0 0-3.436-3.436Zm0 1.623c1 0 1.812.814 1.812 1.812V44.28c0 .998-.812 1.812-1.812 1.812h-49.64a1.815 1.815 0 0 1-1.814-1.812V17.675c0-.998.812-1.812 1.814-1.812"
      fill="#5470B8"
    />
    <path
      d="M58.76 24.546H39.12c-.678 0-1.225-.55-1.225-1.227 0-.679.547-1.228 1.225-1.228h19.64c.678 0 1.225.55 1.225 1.228 0 .677-.547 1.227-1.225 1.227ZM56.442 29.454H38.984c-.603 0-1.09-.549-1.09-1.226 0-.679.487-1.228 1.09-1.228h17.458c.602 0 1.089.55 1.089 1.228 0 .677-.487 1.226-1.09 1.226ZM57.987 34.364H39.074c-.652 0-1.18-.55-1.18-1.227 0-.678.528-1.228 1.18-1.228h18.913c.653 0 1.18.55 1.18 1.228 0 .677-.527 1.227-1.18 1.227ZM53.35 39.273H38.803c-.502 0-.907-.55-.907-1.227 0-.678.405-1.228.907-1.228h14.549c.502 0 .907.55.907 1.228 0 .677-.405 1.227-.907 1.227Z"
      fill="#E6EDFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.212 21.273a2.046 2.046 0 1 0 .001 4.092 2.046 2.046 0 0 0-.002-4.092Zm-.41 3.068-1.022-1.023.288-.288.734.732 1.553-1.553.288.291-1.84 1.84ZM34.212 26.182a2.046 2.046 0 1 0 0 4.092 2.046 2.046 0 0 0 0-4.092Zm-.41 3.068-1.022-1.023.288-.288.734.732 1.553-1.552.288.29-1.84 1.84ZM34.212 31.09a2.046 2.046 0 1 0 .001 4.093 2.046 2.046 0 0 0-.002-4.092Zm-.41 3.07-1.022-1.024.288-.288.734.732 1.553-1.552.288.29-1.84 1.841ZM34.212 36a2.046 2.046 0 1 0 .001 4.093A2.046 2.046 0 0 0 34.211 36Zm-.41 3.068-1.022-1.023.288-.288.734.732 1.553-1.552.288.29-1.84 1.841Z"
      fill="#E6EDFF"
    />
  </svg>
);

export default Desk;
