import { Helmet, HelmetProps } from 'react-helmet';
import { useNotifications } from '../NotificationCenter';

type PageTitleProps = HelmetProps & {
  title: string;
};

const PageTitle: React.FC<PageTitleProps> = ({ title, ...rest }) => {
  const { badgeCount } = useNotifications();

  return (
    <Helmet {...rest}>
      <title>
        {badgeCount > 0 && badgeCount < 10 ? `(${badgeCount}) ${title}` : badgeCount >= 10 ? `(9+) ${title}` : title}
      </title>
    </Helmet>
  );
};

export default PageTitle;
