import { Grid, Stack, Typography } from '@mui/material';
import { Accommodated, Bed, Consultation, End, List } from 'common/components/Icons';
import { i18n } from 'common/locale';
import BedCommentSummary from 'kurt/components/AccommodationDetails/BedCommentSummary';
import { Visit } from 'kurt/pages/Movements/useVisits';
import { SpecificityChip } from '../../SpecifityChip';
import AccommodationStatusChip from '../AccommodationStatusChip';
import FlowSummary from '../VisitDetails/FlowSummary/NewVisitDetailsFlowSummary';
import DetailsSummaryRow from './DetailsSummaryRow';

type AccommodationSummaryProps = {
  visit: Visit;
  flow: 'admission' | 'discharge';
  status: 'planned' | 'validated' | 'completed' | 'requested';
};
const AccommodationSummary = ({ visit, flow, status }: AccommodationSummaryProps) => {
  return (
    <Stack spacing={5}>
      <FlowSummary
        admissionDate={visit.admissionDate}
        dischargeDate={visit.dischargeDate}
        isDischargeDateValidated={visit.isDischargeDateValidated}
        isDischarged={visit.isDischarged}
        location={flow === 'admission' ? visit.previousAccommodation?.unit.name : visit.unit.name}
        visitStatus={visit.status}
      />
      <Stack spacing={4}>
        <DetailsSummaryRow label={i18n.bed} Icon={Bed} value={visit.bed} />
        <DetailsSummaryRow
          label={i18n.unit}
          Icon={Consultation}
          value={
            <Typography variant="body2" color="secondary">
              {visit.unit?.name}
            </Typography>
          }
        />
        <DetailsSummaryRow
          label={i18n.accommodationStatus}
          Icon={End}
          value={visit.status && <AccommodationStatusChip visitStatus={visit.status} status={status} />}
        />
        <DetailsSummaryRow
          label={i18n.specificities}
          Icon={List}
          value={
            !!visit.specificities?.length && (
              <Grid container spacing={1}>
                {visit.specificities.map((specificity) => (
                  <Grid item>
                    <SpecificityChip key={specificity} specificity={specificity} />
                  </Grid>
                ))}
              </Grid>
            )
          }
        />
        {visit.currentAccommodation.responsibleUnit &&
          visit.currentAccommodation.responsibleUnit.id !== visit.currentAccommodation.unit.id && (
            <DetailsSummaryRow
              label={i18n.responsibleUnit}
              Icon={Accommodated}
              value={visit.currentAccommodation.responsibleUnit.name}
            />
          )}
        <BedCommentSummary accommodationId={visit.id} comment={visit.comment} size="small" />
      </Stack>
    </Stack>
  );
};

export default AccommodationSummary;
