import Api, { Config } from '@ambuliz/sabri-core';
import { liveQueryClient } from 'core/live-query-client';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

const minMinutes = 5;
const maxMinutes = 10;

const VersionUpdate = () => {
  const location = useLocation();

  const { version, lastVersion } = useVersion();
  const hasNewVersion = useMemo(() => {
    if (version && lastVersion && version !== lastVersion) {
      return true;
    }
  }, [version, lastVersion]);

  const [remainingTime, setRemainingTime] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false);

  const [currentLocation, setCurrentLocation] = useState('');
  const [lastLocation, setLastLocation] = useState('');

  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    if (!hasNewVersion) {
      return;
    }

    const randomMinutes = Math.floor(Math.random() * (maxMinutes - minMinutes + 1)) + minMinutes * 60 * 1000;
    setRemainingTime(randomMinutes);
    setTimerStarted(true);

    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [hasNewVersion]);

  useEffect(() => {
    if (!hasNewVersion) {
      return;
    }

    setCurrentLocation((prev) => {
      setLastLocation(prev || location.pathname);
      return location.pathname;
    });
  }, [location.pathname, hasNewVersion]);

  useEffect(() => {
    if (hasNewVersion) {
      if (currentLocation !== lastLocation) {
        setShouldReload(true);
      } else if (timerStarted && remainingTime <= 0) {
        setShouldReload(true);
      }
    }
  }, [remainingTime, hasNewVersion, currentLocation, lastLocation, timerStarted]);

  useEffect(() => {
    if (shouldReload) {
      document.location.reload();
    }
  }, [shouldReload]);

  return null;
};

const useVersion = () => {
  const [version, setVersion] = useState<string>('');
  const [lastVersion, setLastVersion] = useState<string>('');

  useEffect(() => {
    let subscription: Parse.LiveQuerySubscription;

    const getAppVersion = async () => {
      const config = await new Api.Query(Config).equalTo('key', 'appVersion').first();
      if (config && config.value.version) {
        setVersion((prev) => {
          setLastVersion(prev || config.value.version);
          return config.value.version;
        });
      }
    };

    const subscribe = async () => {
      subscription = liveQueryClient.subscribe(new Api.Query(Config), Parse.User.current()?.getSessionToken());
      subscription.on('open', () => {
        getAppVersion();
      });
      subscription.on('create', () => {
        getAppVersion();
      });
      subscription.on('update', () => {
        getAppVersion();
      });
    };

    subscribe();

    return () => liveQueryClient.unsubscribe(subscription);
  }, []);
  return { version, lastVersion };
};

export default VersionUpdate;
