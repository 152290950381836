import { Fare, Journey } from '@ambuliz/sabri-core';
import { Divider, Stack } from '@mui/material';
import { color } from 'common/theme';
import JourneyListItem from './JourneyListItem';
import useJourney from './useJourney';

type JourneyListProps = { fares: Fare[]; journey: Journey };

const JourneyList = ({ journey: parseJourney, fares }: JourneyListProps) => {
  const { journey } = useJourney(parseJourney, fares);

  return (
    <Stack divider={<Divider sx={{ borderColor: color.grey[10] }} />} borderTop={`1px solid ${color.grey[10]}`}>
      <JourneyListItem status="past" unitName={journey.startingUnitName} areaName={journey.startingAreaName} />
      {journey.fares.map((fare) => (
        <JourneyListItem key={fare.id} {...fare} />
      ))}
    </Stack>
  );
};

export default JourneyList;
