import { Card, CardContent, Stack, Typography } from '@mui/material';
import ArrowRight from 'common/components/Icons/ArrowRight';
import { i18n } from 'common/locale';
import { theme } from 'common/theme';
import { format } from 'date-fns';
import styled from 'styled-components';
import { EmergencyOccupancyMaybeEmpty } from '../List/EmergencyOccupancyList';

const TAB_CARD_MIN_WIDTH = 186;

type EditEmergencyOccupancyCardProps = {
  occupancy: EmergencyOccupancyMaybeEmpty;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const EditEmergencyOccupancyCard = ({ occupancy, selected, disabled, onClick }: EditEmergencyOccupancyCardProps) => {
  return (
    <TabCard
      onClick={() => !disabled && onClick?.()}
      className={selected ? 'selected' : ''}
      elevation={1}
      disabled={disabled}
    >
      <CardContent>
        <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={4}>
          <Stack spacing={1}>
            <Typography fontWeight={600}>{occupancy.key}</Typography>
            {occupancy.updatedAt && (
              <Typography variant="body2" color="textSecondary">
                {i18n.updateShortened}
                <Typography fontWeight={600} variant="body2" component="span" color={theme.palette.text.secondary}>
                  {format(occupancy.updatedAt, 'dd/MM')}
                  <Typography component="span" variant="body2">
                    {' - ' + format(occupancy.updatedAt, 'HH:mm')}
                  </Typography>
                </Typography>
              </Typography>
            )}
          </Stack>
          <ArrowRight color="secondary" />
        </Stack>
      </CardContent>
    </TabCard>
  );
};

const TabCard = styled(Card)<{ disabled?: boolean }>`
  min-width: ${TAB_CARD_MIN_WIDTH}px;
  &.selected {
    background: ${theme.palette.primary.light};
    border: 1px solid ${theme.palette.primary.main};
    box-shadow: 'none';
  }

  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        box-shadow: ${theme.shadows[3]};
      }

    cursor: pointer;
    transition: all 0.25s ease-in;

    `}
`;

export default EditEmergencyOccupancyCard;
