import { FarePosition } from '@ambuliz/sabri-core';
import { SvgIcon } from '@mui/material';
import {
  AirlineSeatFlat,
  AirlineSeatReclineNormalOutlined,
  DirectionsWalkOutlined,
  AirlineSeatIndividualSuite,
} from '@mui/icons-material';

const farePositionMap: Record<FarePosition, typeof SvgIcon> = {
  LYING_DOWN_BED: AirlineSeatIndividualSuite,
  LYING_DOWN: AirlineSeatFlat,
  SITTING_UP: AirlineSeatReclineNormalOutlined,
  WALKING: DirectionsWalkOutlined,
};

export const getFarePositionIcon = (position: FarePosition) => farePositionMap[position];
