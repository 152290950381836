import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';

type CancelBedClosedDialogProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onBack?: () => void;
  onCancel?: () => void;
};

type CancelBedClosedDialogContentProps = {
  id: string;
  onClose: () => void;
  onSuccess: () => void;
  onCancel: () => void;
  onBack?: () => void;
};

export const CancelBedClosedDialogContent = ({
  id,
  onClose,
  onCancel,
  onSuccess,
  onBack,
}: CancelBedClosedDialogContentProps) => {
  const { cancel } = useAccommodation(id);

  const handleConfirm = async () => {
    await cancel();
    toast.success({
      ...i18n.accommodationBedClosedCancelledSuccess,
      icon: <Emoji size={24} name="writingHand" />,
    });
    onSuccess();
  };

  return (
    <>
      <DialogHeader
        Icon={<Emoji size={24} name="thinkingFace" />}
        title={i18n.cancelBedClosed.title}
        onBack={onBack}
        onClose={onClose}
      />
      <DialogContent>
        <Typography color="secondary">{i18n.cancelBedClosed.content[0]}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          {i18n.cancel}
        </Button>
        <Button onClick={handleConfirm}>{i18n.cancelBedClosed.title}</Button>
      </DialogActions>
    </>
  );
};

const CancelBedClosedDialog = ({ id, open, onClose, onBack, onCancel, onSuccess }: CancelBedClosedDialogProps) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <CancelBedClosedDialogContent
        id={id}
        onClose={onClose}
        onSuccess={onSuccess}
        onCancel={onCancel || onClose}
        onBack={onBack}
      />
    </Dialog>
  );
};

export default CancelBedClosedDialog;
