import MuiAlert from './MuiAlert';
import MuiAppBar from './MuiAppBar';
import MuiAutocomplete from './MuiAutocomplete';
import MuiAvatar from './MuiAvatar';
import MuiAvatarGroup from './MuiAvatarGroup';
import MuiBadge from './MuiBadge';
import MuiButton from './MuiButton';
import MuiButtonGroup from './MuiButtonGroup';
import MuiCard from './MuiCard';
import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiDatePicker from './MuiDatePicker';
import MuiDialog from './MuiDialog';
import MuiDrawer from './MuiDrawer';
import MuiFilledInput from './MuiFilledInput';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiIconButton from './MuiIconButton';
import MuiInput from './MuiInput';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiLinearProgress from './MuiLinearProgress';
import MuiLink from './MuiLink';
import MuiListItemIcon from './MuiListItemIcon';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaginationItem from './MuiPaginationItem';
import MuiPaper from './MuiPaper';
import MuiRadio from './MuiRadio';
import MuiSelect from './MuiSelect';
import MuiSkeleton from './MuiSkeleton';
import MuiSlider from './MuiSlider';
import MuiSvgIcon from './MuiSvgIcon';
import MuiSwitch from './MuiSwitch';
import MuiTableCell from './MuiTableCell';
import MuiTablePagination from './MuiTablePagination';
import MuiTableRow from './MuiTableRow';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTextField from './MuiTextField';
import MuiTimePicker from './MuiTimePicker';
import MuiTimelineConnector from './MuiTimelineConnector';
import MuiToggleButton from './MuiToggleButton';
import MuiToggleButtonGroup from './MuiToggleButtonGroup';
import MuiToolbar from './MuiToolbar';
import MuiTooltip from './MuiTooltip';

const components = {
  MuiAlert,
  MuiAppBar,
  MuiAutocomplete,
  MuiAvatar,
  MuiAvatarGroup,
  MuiBadge,
  MuiButton,
  MuiButtonGroup,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiCheckbox,
  MuiChip,
  MuiDatePicker,
  MuiDialog,
  MuiDrawer,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiIconButton,
  MuiInput,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiLinearProgress,
  MuiLink,
  MuiListItemIcon,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaginationItem,
  MuiPaper,
  MuiRadio,
  MuiSelect,
  MuiSlider,
  MuiSkeleton,
  MuiSvgIcon,
  MuiSwitch,
  MuiTableCell,
  MuiTablePagination,
  MuiTableRow,
  MuiTableSortLabel,
  MuiTextField,
  MuiTimelineConnector,
  MuiTimePicker,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiToolbar,
  MuiTooltip,
};

export default components;
