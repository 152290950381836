import { PayloadAction } from '@reduxjs/toolkit';
import { buffers } from 'redux-saga';
import { actionChannel, call, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';
import { requests } from '../../const/requests';
import { EntityNames, entityNameToClassName, entityNameToSchema } from '../../const/schemas';
import { cleanNormalize } from '../../utils/cleanNormalize';
import { safeSaga } from '../../utils/safeSaga';
import { GetEntitiesPayload, getEntitiesType, getObjectByIdType } from '../actions/entity';
import { entityActions } from '../reducers/entity';
import { requestStatusActions } from '../reducers/requestStatus';
import { selectObjectsToFetch } from '../selectors/entity';

function* getObjectsByIds() {
  //@ts-ignore
  const objectsToFetch = yield select(selectObjectsToFetch);
  for (const entityName in objectsToFetch) {
    const ids = objectsToFetch[entityName];
    yield put(requestStatusActions.setObjectIdsRequestStatus({ ids, status: 'LOADING', method: 'get' }));
    const className = entityNameToClassName[entityName as EntityNames];
    try {
      // @ts-ignore
      const objects: Parse.Object[] = yield call(requests.getObjectByIds, ids, className);
      if (objects.length > 0) {
        const objectsToJSON = objects.map((object) => object.toJSON());
        const schema = entityNameToSchema[entityName as EntityNames];
        const { entities } = cleanNormalize(objectsToJSON, [schema]);
        yield put(entityActions.setEntities(entities));
      }
      yield put(requestStatusActions.setObjectIdsRequestStatus({ ids, status: 'SUCCESS', method: 'get' }));
    } catch (e) {
      yield put(requestStatusActions.setObjectIdsRequestStatus({ ids, status: 'FAILED', method: 'get' }));
    }
  }
}

export function* getEntities(action: PayloadAction<GetEntitiesPayload>) {
  const { requestName, entityName } = action.payload;
  const request = requests[requestName];
  const response: Parse.Object[] = yield call(request);
  if (response) {
    const schema = entityNameToSchema[entityName];
    const responseToJSON = response.map((object: Parse.Object) => object.toJSON());
    const { entities } = cleanNormalize(responseToJSON, [schema]);
    yield put(entityActions.setEntities(entities));
    return responseToJSON;
  }
}

export function* entityRootSaga() {
  const buffer = buffers.expanding<any>();
  //@ts-ignore
  const getObjectByIdChannel = yield actionChannel(getObjectByIdType, buffer);

  //Attention ici, bien utiliser takeEvery
  yield takeEvery(getEntitiesType, safeSaga(getEntities));

  while (true) {
    const { payload } = yield take(getObjectByIdChannel);
    yield putResolve(entityActions.addObjectToFetch(payload));
    if (buffer.isEmpty()) {
      yield call(getObjectsByIds);
      yield put(entityActions.resetObjectsToFetch());
    }
  }
}
