import { RequestType } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import ActionButton from './ActionButton';
import getColumnActions, { AccommodationAction, actionsIcons } from './columnActions';

type ActionsListProps = {
  flow: 'admission' | 'discharge';
  status: 'planned' | 'validated' | 'requested' | 'blocked';
  actionType: 'mutation' | 'default';
  minimal?: boolean;
  disabled?: boolean;
  requestType?: RequestType;
  onClick: (actionName: AccommodationAction) => void;
};

const ActionsList: React.FC<ActionsListProps> = ({
  flow,
  status,
  minimal,
  actionType = 'default',
  disabled = false,
  requestType,
  onClick,
}) => {
  return (
    <Stack spacing={4}>
      <Typography variant="body1" fontWeight={600}>
        {i18n.visitActionsTitle}
      </Typography>
      <Stack spacing={2}>
        {getColumnActions({ flow, type: actionType, status, requestType }).map((actionName) => (
          <ActionButton
            disabled={disabled}
            key={actionName}
            {...i18n.visitActions[actionName].action}
            label={i18n.visitActions[actionName].action.label}
            onClick={() => onClick(actionName)}
            Icon={actionsIcons[actionName]}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ActionsList;
