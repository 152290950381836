import { Box } from '@mui/material';
import { startOfMinute } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { Plus } from '../Icons';
import TimelineContext from './TimelineContext';

const TimelineAddButton = ({
  containerRef,
  onClick,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  onClick: (start: Date, end: Date) => void;
}) => {
  const { start: timelineStart, period, scale } = useContext(TimelineContext);

  const buttonRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rowContainer = containerRef.current;
    const button = buttonRef.current;

    if (rowContainer && button) {
      const handleMouseEnter = () => {
        button.style.opacity = '1';
      };

      const handleMouseMove = (event: MouseEvent) => {
        const position = event.x - rowContainer.getBoundingClientRect().x - scale / 2;
        const offset = Math.ceil(position / (scale / 2)) * (scale / 2);

        button.style.transform = `translate3d(${offset}px, 0, 0)`;
      };

      const handleMouseLeave = () => {
        button.style.opacity = '0';
      };

      rowContainer.addEventListener('mousemove', handleMouseMove);
      rowContainer.addEventListener('mouseenter', handleMouseEnter);
      rowContainer.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        rowContainer.removeEventListener('mousemove', handleMouseMove);
        rowContainer.removeEventListener('mouseenter', handleMouseEnter);
        rowContainer.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [containerRef, buttonRef, scale]);

  const handleButtonClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const left = rect.left - containerRef.current!.getBoundingClientRect().left;
      const right = rect.right - containerRef.current!.getBoundingClientRect().left;

      const factor = 1000 * 60 * 60 * (period === 'day' ? 1 : 24);

      const startOffset = (left * factor) / scale;
      const endOffset = (right * factor) / scale;

      const start = startOfMinute(new Date(timelineStart.getTime() + startOffset));
      const end = startOfMinute(new Date(timelineStart.getTime() + endOffset));

      onClick(start, end);
    }
  };

  return (
    <Box
      width={scale / 2}
      position="absolute"
      top={0}
      left={0}
      ref={buttonRef}
      sx={{ opacity: 0 }}
      paddingX={1}
      marginTop={2}
      height={32}
    >
      <Box
        bgcolor="success.light"
        height="100%"
        width="100%"
        onClick={handleButtonClick}
        borderRadius={1}
        sx={{ cursor: 'pointer' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="success.main"
      >
        <Plus />
      </Box>
    </Box>
  );
};

export default TimelineAddButton;
