import { Paper, Stack, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { getPatientAge } from 'common/utils';
import getShortDuration from 'common/utils/getShortDuration';
import { format } from 'date-fns';
import { Visit } from 'kurt/pages/Movements/useVisits';
import AccommodationWarning, { AccommodationWarningType } from '../../AccommodationWarning';

type KanbanCardVisitSummaryProps = {
  flow: 'admission' | 'discharge';
  visit: Visit;
};

const KanbanCardVisitSummary: React.FC<KanbanCardVisitSummaryProps> = ({ flow, visit }) => {
  const {
    admissionDate,
    dischargeDate,
    patient,
    showAdmissionDateWarning,
    showDischargeDateWarning,
    isDischargeDateValidated,
  } = visit;

  const dateToDisplay = getDateToDisplay(flow, admissionDate, dischargeDate);
  const isLinkToMutation = !!(
    ('admission' === flow && visit.basedOn) ||
    ('discharge' === flow && visit?.nextAccommodation?.basedOn)
  );

  const warning = getWarning(flow, showAdmissionDateWarning, showDischargeDateWarning, isDischargeDateValidated);

  const mutationReason = visit.basedOn?.reason || visit?.nextAccommodation?.basedOn?.reason || '';
  const accommodationDuration = admissionDate ? getShortDuration(admissionDate, dischargeDate) : undefined;

  return (
    <Paper elevation={0} sx={{ backgroundColor: color.blue[5], padding: 2 }}>
      <Stack spacing={3}>
        <PatientNameWithEmoji
          name={patient.fullName}
          gender={patient.gender}
          age={getPatientAge(patient.birthdate)}
          disableTooltip
        ></PatientNameWithEmoji>
        <Stack direction="row" spacing={6}>
          <Stack flex={1}>
            <Typography variant="body2" color="secondary">
              {getMouvementDateLabel(flow, visit)}
            </Typography>
            {warning ? (
              <AccommodationWarning
                label={dateToDisplay ? format(dateToDisplay, 'dd/MM - HH:mm') : '-'}
                warning={warning}
                variant="body2"
                emojiSize={14}
              />
            ) : (
              <Typography variant="body2" fontWeight={600} color="secondary">
                {dateToDisplay ? format(dateToDisplay, 'dd/MM - HH:mm') : '-'}
              </Typography>
            )}
          </Stack>
          <Stack flex={1}>
            {isLinkToMutation ? (
              <>
                <Typography variant="body2" color="secondary">
                  {i18n.mutation.reason}
                </Typography>
                <EllipsisTypography variant="body2" fontWeight={600} color="secondary" disableTooltip>
                  {mutationReason || '-'}
                </EllipsisTypography>
              </>
            ) : (
              <>
                <Typography variant="body2" color="secondary">
                  {i18n.duration.long.label}
                </Typography>
                <Typography variant="body2" fontWeight={600} color="secondary">
                  {accommodationDuration || '-'}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export const getMouvementDateLabel = (flow: 'admission' | 'discharge', visit: Visit) => {
  const visitStatus = visit.status;

  if ('admission' === flow) {
    if (visit.basedOn && visit.basedOn.requestType !== 'DIRECT_ADMISSION') {
      if (['IN_PROGRESS', 'COMPLETED'].includes(visit.status)) {
        return i18n.effectiveMutation;
      }
      return i18n.previsionalMutationSort;
    }
    if (['IN_PROGRESS', 'COMPLETED'].includes(visit.status)) {
      return i18n.effectiveAdmission;
    }
    return i18n.previsionalAdmissionShort;
  }
  if ('discharge' === flow) {
    if (visit.nextAccommodation?.basedOn && visit.isDischargeDateValidated) {
      return visit.nextAccommodation?.status === 'IN_PROGRESS' ? i18n.effectiveMutation : i18n.validatedMutation;
    }
    if (visit.nextAccommodation?.basedOn) {
      return i18n.previsionalMutationSort;
    }
    if (visit.isDischargeDateValidated) {
      if (visitStatus === 'COMPLETED') {
        return i18n.effectiveDischarge;
      }
      return i18n.validatedDischarge;
    }
    return i18n.previsionalDischargeShort;
  }
  return '';
};

const getDateToDisplay = (flow: 'admission' | 'discharge', admissionDate?: Date, dischargeDate?: Date) => {
  return flow === 'admission' ? admissionDate : dischargeDate;
};
const getWarning = (
  flow: 'admission' | 'discharge',
  showAdmissionDateWarning?: boolean,
  showDischargeDateWarning?: boolean,
  isDischargeDateValidated?: boolean
): AccommodationWarningType | undefined => {
  if (flow === 'admission' && showAdmissionDateWarning) {
    return {
      outdatedStartDate: 1,
      outdatedEndDate: { previsional: 0, validated: 0 },
      overlapped: 0,
    };
  } else if (flow === 'discharge' && showDischargeDateWarning) {
    return {
      outdatedStartDate: 0,
      outdatedEndDate: { previsional: !isDischargeDateValidated ? 1 : 0, validated: isDischargeDateValidated ? 1 : 0 },
      overlapped: 0,
    };
  }
  return undefined;
};

export default KanbanCardVisitSummary;
