export type ColorName = 'blue' | 'grey' | 'orange' | 'yellow' | 'green' | 'red' | 'lightblue' | 'purple';

export type Shade = 5 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;

type ColorShades = Record<Shade, string>;

const color: Record<ColorName, ColorShades> = {
  blue: {
    5: '#F6F6FC',
    10: '#EDEDF9',
    20: '#E0E7FF',
    30: '#799AF1',
    40: '#3561D3',
    50: '#133EAC',
    60: '#0D088F',
    70: '#0A0672',
    80: '#080556',
    90: '#050339',
    100: '#03021D',
  },
  grey: {
    5: '#F0F0F4',
    10: '#E8EAF2',
    20: '#D1D3DD',
    30: '#B3B6C7',
    40: '#9499B0',
    50: '#757C99',
    60: '#666D8E',
    70: '#525772',
    80: '#3D4155',
    90: '#292C39',
    100: '#14161C',
  },
  orange: {
    5: '#FEF3F0',
    10: '#FEF3F0',
    20: '#FBDCD1',
    30: '#F9C4B3',
    40: '#F6AC94',
    50: '#F39575',
    60: '#F28966',
    70: '#ED7A54',
    80: '#E76438',
    90: '#D84A1A',
    100: '#A43813',
  },
  yellow: {
    5: '#FEF9F0',
    10: '#FEF9F0',
    20: '#FDEED1',
    30: '#FBE2B2',
    40: '#F9D693',
    50: '#F8CB74',
    60: '#F7C565',
    70: '#E9B249',
    80: '#E0A636',
    90: '#C68D20',
    100: '#B57D13',
  },
  green: {
    5: '#ECF7F2',
    10: '#ECF7F2',
    20: '#C7E8D7',
    30: '#A2D9BC',
    40: '#7CC9A1',
    50: '#57BA86',
    60: '#44B278',
    70: '#3E9F6C',
    80: '#36885C',
    90: '#2E6347',
    100: '#234935',
  },
  red: {
    5: '#FEF0F0',
    10: '#FEF0F0',
    20: '#FFCACA',
    30: '#F9B3B3',
    40: '#F69494',
    50: '#F37575',
    60: '#F26666',
    70: '#E05E5E',
    80: '#CE5757',
    90: '#A94747',
    100: '#853838',
  },
  lightblue: {
    5: '#F3FBFF',
    10: '#F3FBFF',
    20: '#CAE9F9',
    30: '#A7DBF5',
    40: '#83CDF1',
    50: '#60BEED',
    60: '#4EB7EB',
    70: '#30AAE6',
    80: '#2295CF',
    90: '#196388',
    100: '#08364D',
  },
  purple: {
    5: '#F6F3FF',
    10: '#F6F3FF',
    20: '#DECEFF',
    30: '#C5AAFF',
    40: '#AC8AF7',
    50: '#916EDE',
    60: '#7A5AC1',
    70: '#6447A4',
    80: '#4F3686',
    90: '#3C2769',
    100: '#2A1A4C',
  },
};

export default color;
