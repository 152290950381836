import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestNames } from '../../const/requests';
import { EntityNames } from '../../const/schemas';
import { i18n } from '../../locales';
import {
  filterIncidentsByDate,
  filterIncidentsByFilters,
  incidentFilters,
  incidentListColumns,
} from '../../services/incident';
import { getEntitiesAction } from '../../store/actions/entity';
import { selectIncidentsDenormalized } from '../../store/selectors/incident';
import { selectPortersFilters } from '../../store/selectors/porter';
import { selectRequestStatus } from '../../store/selectors/requestStatus';
import { FilterValues, FiltersController } from '../FiltersController/FiltersController';
import { LoaderContent } from '../LoaderContent/LoaderContent';
import { SelectorDate } from '../SelectorDate/SelectorDate';
import { TableList } from '../TableList/TableList';
import { TableListHeader } from '../TableListHeader/TableListHeader';

export const Incidents: React.FC = () => {
  const dispatch = useDispatch();
  const incidents = useSelector(selectIncidentsDenormalized);
  const [filters, setFilters] = useState<FilterValues[]>([]);
  const [{ start, end }, setDateFiltered] = useState<{ start?: Date; end: Date }>({ end: new Date() });
  const requestStatus = useSelector(selectRequestStatus(requestNames.getIncidents));
  const incidentsFilteredByDate = incidents.filter(filterIncidentsByDate({ start, end }));
  const incidentsFiltered = incidentsFilteredByDate.filter(filterIncidentsByFilters(filters));
  const portersFilters = useSelector(selectPortersFilters);

  useEffect(() => {
    dispatch(getEntitiesAction({ entityName: EntityNames.incidents, requestName: requestNames.getIncidents }));
  }, [dispatch]);

  const renderNumberOfIncidents = () => {
    return `${incidentsFiltered.length} ${
      incidentsFiltered.length > 1 ? i18n.incidents.toLowerCase() : i18n.incident.toLowerCase()
    }`;
  };

  return (
    <Box padding={3} paddingTop={4}>
      <TableListHeader title={i18n.incidents} />
      {requestStatus === 'LOADING' ? (
        <LoaderContent />
      ) : (
        <>
          <Grid container>
            <Box marginRight={1}>
              <SelectorDate onSubmit={(dates) => setDateFiltered(dates)} />
            </Box>
            <FiltersController
              filters={incidentFilters(portersFilters)}
              onUpdateFilters={(filters) => setFilters(filters)}
            />
          </Grid>
          <Box marginTop={2} marginBottom={2}>
            <Typography variant="h6">{renderNumberOfIncidents()}</Typography>
          </Box>
          <TableList columns={incidentListColumns} data={incidentsFiltered} initialSortBy="date" />
          <Box height={100} width="100%"></Box>
        </>
      )}
    </Box>
  );
};
