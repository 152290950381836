import { i18n } from 'common/locale';
import { kurtRoutes } from 'kurt';
import { sabriRoutes } from 'sabri';
import { superAdminRoutes } from 'superadmin';

export type Application = 'sabri' | 'kurt' | 'admin';

export type AppRoute = {
  label: string;
  path: string;
  route: Application;
};

export const appRoutes: Record<Application, AppRoute> = {
  sabri: {
    label: i18n.sabri,
    path: sabriRoutes.root,
    route: 'sabri',
  },
  kurt: {
    label: i18n.kurt,
    path: kurtRoutes.root,
    route: 'kurt',
  },
  admin: {
    label: i18n.admin,
    path: superAdminRoutes.root.path,
    route: 'admin',
  },
};
