import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement, ReactNode } from 'react';

type AlertFareDetailsProps = {
  icon: ReactElement;
  title: string;
  message?: string;
  subtitle?: string;
  color: string;
  children?: ReactNode;
};

export const AlertFareDetails: React.FC<AlertFareDetailsProps> = ({
  icon,
  title,
  message,
  color,
  subtitle,
  children,
}) => {
  const classes = useClasses({ color });
  return (
    <div className={classes.container}>
      <Grid container direction="row" wrap="nowrap">
        {icon}
        <Box marginLeft={1.5}>
          <div className={classes.title}>{title}</div>
          {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
          {message && <div className={classes.message}>{message}</div>}
          {children}
        </Box>
      </Grid>
    </div>
  );
};

const useClasses = makeStyles({
  container: ({ color }: { color: string }) => ({
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: color,
    borderRadius: 3,
  }),
  title: {
    color: '#663C00',
    fontWeight: 500,
    fontSize: 16,
  },
  subtitle: {
    color: '#663C00',
    fontSize: 14,
    marginTop: 5,
  },
  message: {
    color: '#663C00',
    fontSize: 14,
    fontStyle: 'italic',
    marginTop: 10,
  },
});
