import { SvgIcon, SvgIconProps } from '@mui/material';

const CancelCircle: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 0.708252C4.21864 0.708252 1.11022 3.58612 0.744315 7.2715C0.710212 7.61499 0.961018 7.92109 1.30451 7.95519C1.648 7.9893 1.9541 7.73849 1.9882 7.395C2.29126 4.34262 4.86757 1.95825 7.99998 1.95825C10.1218 1.95825 11.9892 3.05213 13.0673 4.70833H11.3334C10.9882 4.70833 10.7084 4.98816 10.7084 5.33333C10.7084 5.67851 10.9882 5.95833 11.3334 5.95833H14.1025C14.109 5.95844 14.1156 5.95844 14.1221 5.95833H14.2667C14.8328 5.95833 15.2917 5.4994 15.2917 4.93333V2C15.2917 1.65482 15.0119 1.375 14.6667 1.375C14.3215 1.375 14.0417 1.65482 14.0417 2V3.9164C12.7312 1.98122 10.5148 0.708252 7.99998 0.708252ZM14.7284 8.04461C15.0719 8.07871 15.3227 8.38481 15.2886 8.7283C14.9227 12.4137 11.8143 15.2915 8.03296 15.2915C5.51819 15.2915 3.30174 14.0186 1.99124 12.0834V14.0001C1.99124 14.3453 1.71142 14.6251 1.36624 14.6251C1.02106 14.6251 0.741241 14.3453 0.741241 14.0001V11.0667C0.741241 10.5006 1.20017 10.0417 1.76624 10.0417H1.89965C1.9137 10.0413 1.92773 10.0413 1.94171 10.0417H4.69957C5.04475 10.0417 5.32457 10.3216 5.32457 10.6667C5.32457 11.0119 5.04475 11.2917 4.69957 11.2917H2.96585C4.04395 12.9478 5.91123 14.0415 8.03296 14.0415C11.1654 14.0415 13.7417 11.6572 14.0447 8.6048C14.0788 8.26131 14.3849 8.01051 14.7284 8.04461Z"
      />
    </SvgIcon>
  );
};

export default CancelCircle;
