import { FareStatus, WebUserRole } from '@ambuliz/sabri-core';
import { createSelector } from '@reduxjs/toolkit';
import { compareAsc, isSameDay } from 'date-fns';
import { EntityNames } from '../../const/schemas';
import { AreaStored, FareStored, PatientStored, UnitStored } from '../reducers/entity';
import { AppState } from '../store';
import { selectEntities, selectEntitiesValues } from './entity';
import { selectUserRole, selectUserUnitId } from './general';

export type FareDenormalized = FareStored & {
  patient: PatientStored | undefined;
  fromArea: AreaStored | undefined;
  toArea: AreaStored | undefined;
  fromUnit: UnitStored | undefined;
  toUnit: UnitStored | undefined;
};

export const selectFaresDenormalized = createSelector(
  [
    selectEntitiesValues(EntityNames.fares),
    selectEntities(EntityNames.areas),
    selectEntities(EntityNames.patients),
    selectEntities(EntityNames.units),
  ],
  (fares, areas, patients, units) =>
    fares
      .filter((fare) => !fare.isArchived)
      .map((fare) => ({
        ...fare,
        patient: patients[fare.patientId],
        fromArea: fare.fromAreaId ? areas[fare.fromAreaId] : undefined,
        toArea: fare.toAreaId ? areas[fare.toAreaId] : undefined,
        fromUnit: units[fare.fromUnitId],
        toUnit: units[fare.toUnitId],
      }))
);

/*
  Renvoie la liste de fares filtrée par :
    - Nom, prénom, IPP, PAN du patient (patient.fullTextSearch)
    - Libellé des unités de départ et d'arrivée (unit.name)
    - Code des unités de départ et d'arrivée (unit.externalId)
    - Référence de mission
*/
export const selectFareSearchResults = (search: string, date: Date, filteredByArea?: AreaStored[]) =>
  createSelector([selectFaresDenormalized], (fares) => {
    search = search.trim().toLowerCase();
    const isEmptySearch = !search || search === '';
    const isEmptyAreaFilter = !filteredByArea || filteredByArea.length === 0;

    return fares.filter((fare) => {
      let isFiltered = true;

      if (fare.wantedDate) {
        isFiltered = isSameDay(new Date(fare.wantedDate), date);
      }

      if (!isEmptySearch) {
        isFiltered = isFiltered && filterFareBySearch(fare, search);
      }

      if (!isEmptyAreaFilter) {
        isFiltered = isFiltered && filterFareByAreas(fare, filteredByArea);
      }

      return isFiltered;
    });
  });

const filterFareBySearch = (fare: FareDenormalized, search: string) => {
  const searchTerms = [
    fare.patient?.fullTextSearch,
    fare.toUnit?.name?.toLowerCase(),
    fare.toUnit?.externalId?.toLowerCase(),
    fare.fromUnit?.name?.toLowerCase(),
    fare.fromUnit?.externalId?.toLowerCase(),
    fare.reference,
  ];

  return searchTerms.some((term) => term?.includes(search));
};

const filterFareByAreas = (fare: FareDenormalized, areasFiltered: AreaStored[]) => {
  for (const area of areasFiltered) {
    // if one of the area specified in the fare is included in the filter, we can display it
    if (fare.fromArea?.objectId === area.objectId || fare.toArea?.objectId === area.objectId) {
      return true;
      // else if the specified from area is not searchable but its parent match with the area's id included in the filter, we can display it
    } else if (!fare.fromArea?.isSearchable && fare.fromArea?.parentId === area.objectId) {
      return true;
      // else if the specified to area is not searchable but its parent match with the area's id included in the filter, we can display it
    } else if (!fare.toArea?.isSearchable && fare.toArea?.parentId === area.objectId) {
      return true;
    }
  }
  return false;
};

export type FareFilterStatus =
  | 'active'
  | 'pending'
  | 'in-progress'
  | 'done'
  | 'to-confirm-in'
  | 'to-confirm-out'
  | 'draft';

const fareMatchesUnitIds = (fare: FareDenormalized, unitIdsToFilter: string[]): boolean =>
  unitIdsToFilter.length === 0 || unitIdsToFilter.includes(fare.fromUnitId) || unitIdsToFilter.includes(fare.toUnitId);

const getInProgressFareStatusesByRole = (role?: WebUserRole): FareStatus[] => {
  const statuses: FareStatus[] = ['PATIENT_CARE', 'STARTED', 'ASSIGNED'];

  if (role === 'MANAGER') {
    return [...statuses, 'PENDING'];
  }
  return statuses;
};

const fareHasStatus = (fareStatus: FareStatus, statusesToCompare: FareStatus[]) =>
  statusesToCompare.includes(fareStatus);

const fareMatchesStatus = (
  fare: FareDenormalized,
  fareFilterStatus: FareFilterStatus,
  userUnitId?: string,
  userRole?: WebUserRole
) => {
  if (!fare.status) {
    return true;
  }

  switch (fareFilterStatus) {
    case 'pending':
      return fareHasStatus(fare.status, ['PENDING']);

    case 'in-progress':
      return fareHasStatus(fare.status, getInProgressFareStatusesByRole(userRole));

    case 'done':
      return fareHasStatus(fare.status, ['DONE', 'CANCELED']);

    case 'to-confirm-in':
      const fareHasToBeConfirmedIn = !!fare.needUnitConfirmation && userUnitId === fare.toUnitId;

      return fareHasStatus(fare.status, ['WAITING', 'PENDING', 'ASSIGNED']) && fareHasToBeConfirmedIn;

    case 'to-confirm-out':
      const fareHasToBeConfirmedOut = !!fare.needUnitConfirmation && userUnitId === fare.fromUnitId;

      return fareHasStatus(fare.status, ['WAITING', 'PENDING', 'ASSIGNED']) && fareHasToBeConfirmedOut;

    case 'draft':
      return fareHasStatus(fare.status, ['DRAFT']);

    default:
      return !fareHasStatus(fare.status, ['DRAFT', 'DONE', 'CANCELED']);
  }
};

export const selectFilteredFares = (
  search = '',
  status: FareFilterStatus,
  date: Date,
  areasToFilter: AreaStored[] = [],
  unitIdsToFilter: string[] = []
) =>
  createSelector(
    [selectFareSearchResults(search, date, areasToFilter), selectUserUnitId, selectUserRole],
    (fares, userUnitId, role) =>
      fares.filter(
        (fare) => fareMatchesStatus(fare, status, userUnitId, role) && fareMatchesUnitIds(fare, unitIdsToFilter)
      )
  );

const isFareDone = (status: FareStatus | undefined) =>
  status && ['CANCELED', 'DONE', 'FAILED', 'DRAFT'].includes(status);

export const selectFareListForPorter = (porterId: string) =>
  createSelector([selectEntitiesValues(EntityNames.fares)], (fares: FareStored[]) =>
    fares.filter((fare) => fare.portersIds?.includes(porterId))
  );

export const selectNextFareForPorter = (porterId: string) =>
  createSelector([selectFareListForPorter(porterId)], (fares: FareStored[]) => {
    const futureFares = fares
      .filter(
        (fare) => fare.wantedDate && compareAsc(new Date(fare.wantedDate), new Date()) >= 1 && !isFareDone(fare.status)
      )
      .sort((a, b) => (a.wantedDate && b.wantedDate ? compareAsc(new Date(a.wantedDate), new Date(b.wantedDate)) : -1));

    return futureFares.length > 0 ? futureFares[0] : null;
  });

export const selectCurrentPorterFare = (porterId: string) =>
  createSelector([selectFareListForPorter(porterId)], (fares: FareStored[]) => {
    return fares.find((fare) => ['IN_PROGRESS', 'PATIENT_CARE', 'STARTED'].includes(fare?.status || ''));
  });

const selectFareIds = (state: AppState) => state.fares.ids;
const selectFareCount = (state: AppState) => state.fares.count;
export const selectFareFilters = (state: AppState) => state.fares.filters;

export const selectFareList = createSelector(
  selectFareIds,
  selectFareCount,
  selectFareFilters,
  (ids, count, filters) => ({ ids, count, filters })
);

export const selectFaresArray = createSelector([selectEntities(EntityNames.fares)], (fares) => Object.values(fares));

export const selectFaresFromGroup = (id: string | undefined) =>
  createSelector([selectFaresArray], (fares) => {
    if (id) {
      return fares.filter((fare) => fare.groupId === id);
    }
    return [];
  });

export const selectIsFareGrouped = (groupId: string | undefined) =>
  createSelector([selectFaresFromGroup(groupId)], (fares) => fares.length > 1);
