import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from '../const/requests';
import { EntityNames } from '../const/schemas';
import { getObjectByIdAction } from '../store/actions/entity';
import { EntityState } from '../store/reducers/entity';
import { selectEntityById, selectEntitiesByIds } from '../store/selectors/entity';
import { selectRequestStatus } from '../store/selectors/requestStatus';

export const useSelectFetch = <E extends EntityNames, I extends string>(
  entityName: E,
  id: I | undefined
): [EntityState[E][I] | undefined, RequestStatus] => {
  const dispatch = useDispatch();
  const object = useSelector(selectEntityById(entityName, id));
  const requestStatus = useSelector(selectRequestStatus(`get_${id}`));

  useEffect(() => {
    if (!object && id) {
      dispatch(getObjectByIdAction({ ids: [id], entityName }));
    }
  }, [id, object, dispatch, entityName]);

  if (!id || object !== undefined) {
    return [object, 'SUCCESS'];
  }
  return [undefined, requestStatus];
};

export const useSelectFetchByIds = <E extends EntityNames, I extends string>(
  entityName: E,
  ids: I[]
): [EntityState[E][I][], RequestStatus] => {
  const dispatch = useDispatch();
  const requestStatus = useSelector(selectRequestStatus(`get_${ids && ids[0]}`));
  const objects = useSelector(selectEntitiesByIds(entityName, ids));

  useEffect(() => {
    if (objects.length !== ids.length) {
      dispatch(getObjectByIdAction({ ids, entityName }));
    }
  }, [dispatch, entityName, ids, objects.length]);

  if (objects.length === ids.length) {
    return [objects, 'SUCCESS'];
  }
  return [[], requestStatus];
};
