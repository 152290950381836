import { SVGProps } from 'react';

const MapInProgress = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 166 150" fill="none" {...props}>
    <path
      d="M73.087.032C53.573 1.555 49.203 3.351 36.843 12.499 16.33 27.688 1.945 52.766 4.74 78.01c1.97 17.862 12.082 33.939 24.499 46.958 7.645 8.007 16.382 15.204 26.57 19.589 11.438 4.926 24.24 6.094 36.683 5.138 13.37-1.029 26.709-4.518 38.203-11.42 15.931-9.572 27.688-25.604 32.564-43.457 4.881-17.854 2.949-37.345-4.764-54.14-2.16-4.7-4.783-9.232-8.23-13.099-3.811-4.279-8.545-7.647-13.452-10.614C117.779 5.457 95.385-.494 73.087.032Z"
      fill="#E6EDFF"
    />
    <path
      d="M25.687 130.291a4.117 4.117 0 0 1-3.938-2.967l-.122-.426c-.481-1.675-1.71-3.049-3.284-3.675a17.957 17.957 0 0 1-.572-.236 5.451 5.451 0 0 0-2.19-.453c-.962 0-1.921.251-2.771.724l-.354.198a4.098 4.098 0 0 1-1.986.516 4.058 4.058 0 0 1-2.889-1.195 4.098 4.098 0 0 1-.679-4.875l1.663-2.984-.22-.387a19.882 19.882 0 0 1-1.21-2.516c-.628-1.577-2.002-2.803-3.675-3.284l-.426-.122a4.116 4.116 0 0 1-2.968-3.938 4.118 4.118 0 0 1 2.968-3.941l.426-.121c1.673-.482 3.047-1.708 3.675-3.284.341-.86.749-1.708 1.21-2.517l.22-.387-1.663-2.984a4.098 4.098 0 0 1 .68-4.874 4.056 4.056 0 0 1 2.888-1.196c.694 0 1.379.18 1.986.516l2.982 1.663.386-.22c.811-.46 1.657-.868 2.517-1.21 1.576-.626 2.805-2 3.286-3.675l.122-.426a4.118 4.118 0 0 1 3.938-2.965 4.118 4.118 0 0 1 3.939 2.965l.123.426c.48 1.675 1.708 3.047 3.284 3.675.86.342 1.706.75 2.517 1.21l.387.22 2.981-1.663a4.103 4.103 0 0 1 1.988-.516 4.05 4.05 0 0 1 2.887 1.196 4.098 4.098 0 0 1 .681 4.874l-1.662 2.984.22.387a20.104 20.104 0 0 1 1.893 4.547l.117.428 3.299.947a4.118 4.118 0 0 1 2.967 3.941 4.116 4.116 0 0 1-2.967 3.938l-3.299.947-.117.428a20.094 20.094 0 0 1-1.893 4.547l-.22.387 1.662 2.984a4.102 4.102 0 0 1-.68 4.875 4.053 4.053 0 0 1-2.888 1.195c-.693 0-1.38-.179-1.988-.516l-2.981-1.663-.387.22a19.94 19.94 0 0 1-2.517 1.21c-1.576.626-2.804 2-3.284 3.675l-.123.424a4.116 4.116 0 0 1-3.939 2.969Z"
      fill="#799AF1"
    />
    <path
      d="M25.687 116.068c-.494 0-.996-.033-1.492-.094-5.04-.646-9.165-4.772-9.81-9.812-.425-3.313.55-6.512 2.746-9.008a11.412 11.412 0 0 1 10.048-3.786c5.04.646 9.165 4.771 9.811 9.809.426 3.313-.551 6.512-2.747 9.008a11.405 11.405 0 0 1-8.554 3.883"
      fill="#fff"
    />
    <path
      d="M26.558 112.752a8.111 8.111 0 0 1-8.95-8.952c.382-3.757 3.452-6.83 7.21-7.212a8.112 8.112 0 0 1 8.953 8.953c-.383 3.756-3.455 6.828-7.213 7.211Z"
      fill="#5470B8"
    />
    <path
      d="M38.73 26.213a1 1 0 0 1 1.457-.89l29.498 15.154v82.951l-29.868-15.344a1.999 1.999 0 0 1-1.087-1.779V26.213Z"
      fill="#B5CAFF"
    />
    <path
      d="m101.139 24.575 29.38 15.34a2 2 0 0 1 1.075 1.773v80.09a1 1 0 0 1-1.463.886l-28.992-15.138V24.575Z"
      fill="#799AF1"
    />
    <path d="M101.139 24.575 69.685 40.477v82.951l31.454-15.902V24.575Z" fill="#fff" />
    <path
      d="M132.038 62.02c-7.564.008-13.693 6.212-13.702 13.867 0 8.794 12.214 21.256 12.732 21.783a1.362 1.362 0 0 0 1.94 0c.518-.527 12.732-12.989 12.732-21.783-.009-7.655-6.139-13.858-13.702-13.868Z"
      fill="#5470B8"
    />
    <path
      d="M132.038 80.77a5.048 5.048 0 1 1 0-10.097 5.048 5.048 0 0 1 0 10.096ZM34.515 77.051a1.87 1.87 0 0 1-1.78-1.29l-.07-.21c-.287-.874-.97-1.56-1.872-1.884l-.152-.056a3.135 3.135 0 0 0-1.114-.205c-.543 0-1.07.145-1.525.422l-.183.11a1.872 1.872 0 0 1-2.247-.228 1.884 1.884 0 0 1-.406-2.229l.096-.191c.413-.817.417-1.783.012-2.648l-.067-.146c-.392-.875-1.128-1.504-2.022-1.723l-.215-.053a1.884 1.884 0 0 1-1.428-1.75 1.887 1.887 0 0 1 1.29-1.854l.21-.068c.874-.288 1.561-.97 1.885-1.873l.055-.153c.337-.892.258-1.854-.216-2.638l-.11-.182a1.882 1.882 0 0 1 2.457-2.654l.19.096c.41.208.853.313 1.319.313.45 0 .91-.104 1.33-.299l.144-.067c.877-.393 1.505-1.13 1.723-2.023l.053-.216c.2-.808.918-1.395 1.75-1.427l.074-.002c.81 0 1.526.519 1.781 1.291l.069.21c.288.874.969 1.561 1.872 1.885l.153.055c.36.137.735.206 1.112.206a2.94 2.94 0 0 0 1.527-.422l.18-.11a1.874 1.874 0 0 1 2.248.228c.618.573.782 1.486.407 2.229l-.097.19c-.413.817-.417 1.782-.012 2.648l.067.146c.391.876 1.128 1.505 2.023 1.724l.214.053c.81.198 1.396.917 1.428 1.748a1.886 1.886 0 0 1-1.289 1.855l-.21.069c-.874.288-1.56.97-1.886 1.872l-.055.153c-.337.893-.258 1.856.216 2.639l.11.18a1.882 1.882 0 0 1-2.457 2.655l-.19-.096a2.906 2.906 0 0 0-1.319-.313c-.45 0-.91.105-1.328.301l-.147.066c-.876.392-1.503 1.13-1.722 2.023l-.053.215a1.882 1.882 0 0 1-1.75 1.427"
      fill="#fff"
    />
    <path
      d="M33.051 69.886c4.023.749 7.41-2.91 6.356-6.864a5.481 5.481 0 0 0-4.248-3.933c-4.024-.748-7.411 2.91-6.357 6.865.533 1.993 2.219 3.556 4.25 3.933Z"
      fill="#5470B8"
    />
  </svg>
);

export default MapInProgress;
