import { Components } from '@mui/material';

const MuiCardHeader: Components['MuiCardHeader'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: '16px 16px 0',
    },
    action: {
      marginLeft: 16,
      alignSelf: 'center',
    },
    title: {
      fontWeight: 600,
    },
    avatar: {
      marginRight: 12,
    },
  },
};

export default MuiCardHeader;
