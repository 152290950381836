const root = '/lits/';

const routes = {
  root,
  territorialReporting: 'reporting/territorial',
  healthCenter: 'management/health-center',
  unit: 'management/unit',
  movements: 'unit',
  emergency: 'emergency',
};

export default routes;
