import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Autocomplete, AutocompleteGetTagProps, Chip, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormValue } from 'sabri/forms/JourneyForm/JourneyForm';
import { AreaStored } from 'sabri/store/reducers/entity';
import styled from 'styled-components';
import { i18n } from '../../locales';
import { selectAreaBreadcrumb, selectAreaChildren, selectAreasFromUnit } from '../../store/selectors/area';

export type AreaAutoCompleteProps = {
  name: string;
  unitId?: string;
  disabled?: boolean;
};

export const AreaAutoComplete: React.FC<AreaAutoCompleteProps> = ({ name, unitId, disabled }) => {
  const { clearErrors, watch, control } = useFormContext<FormValue>();

  const areaId = watch<string, string | undefined>(name);
  const areas = useSelector(areaId ? selectAreaChildren(areaId) : selectAreasFromUnit(unitId));
  const areaBreadcrumb = useSelector(selectAreaBreadcrumb(areaId));

  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setOpen(focused && areas.length > 0);
  }, [focused, areas.length]);

  const handleOpen = () => {
    if (areas.length > 0) {
      setOpen(true);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={areaId || ''}
      render={({ onChange }) => (
        <StyledAutoComplete
          multiple
          options={areas}
          disabled={disabled || !unitId}
          open={open}
          openOnFocus
          blurOnSelect={false}
          disableCloseOnSelect
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onOpen={handleOpen}
          onClose={() => setOpen(false)}
          noOptionsText={i18n.noAreaFound}
          value={areaBreadcrumb}
          onChange={(_, areas) => {
            clearErrors(name);
            onChange(areas.length > 0 ? areas[areas.length - 1].objectId : '');
          }}
          renderTags={renderTags}
          getOptionLabel={(area) => area.name}
          renderInput={(params) => <TextField {...params} label={i18n.selectARoom} />}
        />
      )}
    />
  );
};

const renderTags = (areas: AreaStored[], props: AutocompleteGetTagProps) =>
  areas.map((area, index) =>
    index < areas.length - 1 ? (
      <Chip
        label={area.name}
        {...props({ index })}
        disabled
        variant="light"
        color="default"
        deleteIcon={<KeyboardArrowRightOutlined />}
      />
    ) : (
      <Chip variant="light" color="default" label={area.name} {...props({ index })} />
    )
  );

const StyledAutoComplete = styled(Autocomplete)`
  & input {
    min-width: ${({ disabled }) => (disabled ? '0px !important' : undefined)};
  }
  & .MuiOutlinedInput-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  width: 201px;
  & .MuiFormLabel-root.MuiInputLabel-root:not(.MuiInputLabel-shrink) {
    max-width: calc(100% - 42px);
  }
` as typeof Autocomplete;
