import { NewFareIncidentNotificationData, NotificationTriggerEvent } from '@ambuliz/sabri-core';
import { i18n } from 'common/locale';
import { sabriRoutes } from 'sabri';
import { Notification } from './NotificationContext';

export const getSeverity = (triggerEvent: NotificationTriggerEvent): 'info' | 'warning' | 'error' => {
  switch (triggerEvent) {
    case 'NEW_FARE_INCIDENT':
      return 'warning';

    case 'PERFORMER_REQUEST_REJECTED':
      return 'error';

    default:
      return 'info';
  }
};

export const getContext = (triggerEvent: NotificationTriggerEvent) => {
  switch (triggerEvent) {
    case 'NEW_PERFORMER_REQUEST':
    case 'PERFORMER_REQUEST_ACCEPTED':
    case 'PERFORMER_REQUEST_REJECTED':
      return i18n.kurt;
    default:
      return i18n.sabri;
  }
};

export const getPathFromNotification = (notification: Notification) => {
  let data;
  switch (notification.triggerEvent) {
    case 'NEW_FARE_INCIDENT':
      data = notification.data as NewFareIncidentNotificationData;
      return `${sabriRoutes.fares}/details/${data.fareId}`;
    case 'PORTER_START_OF_SERVICE':
      return `${sabriRoutes.team}/available`;
    case 'PORTER_END_OF_SERVICE':
      return `${sabriRoutes.team}/all`;
    default:
      return undefined;
  }
};
