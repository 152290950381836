import { i18n } from 'common/locale';

const getFlowTitle = (flow: 'admission' | 'discharge', status: 'planned' | 'validated' | 'completed' | 'requested') => {
  if (flow === 'admission') {
    if (status === 'requested') {
      return i18n.movementRequests;
    } else if (status === 'planned') {
      return i18n.toPlace;
    } else if (status === 'validated') {
      return i18n.affectedBed;
    } else {
      return i18n.admittedPatient;
    }
  } else {
    if (status === 'requested') {
      return i18n.movementRequests;
    } else if (status === 'planned') {
      return i18n.previsionalDischarge;
    } else if (status === 'validated') {
      return i18n.validatedDischarge;
    } else {
      return i18n.dischargedPatient;
    }
  }
};

export default getFlowTitle;
