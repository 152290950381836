import { Stack, SvgIconProps, Typography } from '@mui/material';

type DialogFormRowProps = { children: React.ReactNode; Icon: React.FC<SvgIconProps<'svg'>>; label: string };

const DialogFormRow = ({ children, Icon, label }: DialogFormRowProps) => {
  return (
    <Stack direction="row" paddingY={3} gap={1} alignItems="flex-start">
      <Stack direction="row" paddingY="7px" gap={2} width={190} alignItems="center">
        <Icon color="secondary" sx={{ fontSize: 12 }} />
        <Typography fontWeight={600}>{label}</Typography>
      </Stack>
      <Stack flex={1}>{children}</Stack>
    </Stack>
  );
};

export default DialogFormRow;
