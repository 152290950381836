import { Cloud, UserRole, isPasswordValid, isPinValid } from '@ambuliz/sabri-core';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useEffect, useState } from 'react';
import UserPasswordForm from './UserPasswordForm';
import UserPinForm from './UserPinForm';

export type UserPasswordDialogProps = {
  userId: string;
  username?: string;
  role?: UserRole;
  open: boolean;
  onClose: () => void;
};

const UserPasswordDialog: React.FC<UserPasswordDialogProps> = ({ userId, username, role, open, onClose }) => {
  const [password, setPassword] = useState('');
  const [isUnsafePassword, setIsUnsafePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const isValid = role === 'PORTER' ? isPinValid(password).valid : isPasswordValid(password).valid;

  useEffect(() => {
    if (password.length > 0 && !isValid) {
      setIsUnsafePassword(false);
    }
  }, [password, isValid]);

  const displayUnsafePasswordWarning = password.length > 0 && !isValid;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    try {
      await Cloud.updateUser({
        id: userId,
        password,
        isUnsafePassword: !isValid && isUnsafePassword,
      });
      toast.success('Le mot de passe a bien été modifié');
      onClose();
    } catch (error) {
      toast.error(error?.message || 'Une erreur est survenue');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited: () => setPassword('') }}>
      <form onSubmit={handleSubmit}>
        <DialogHeader title={i18n.updatePassword} onClose={onClose} />
        <DialogContent>
          <Stack spacing={4}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography color="secondary">Compte :</Typography>
              <Typography>{username}</Typography>
              {role && <Chip label={i18n[role]} color="info" />}
            </Stack>
            {role === 'PORTER' ? (
              <UserPinForm value={password} onChange={setPassword} />
            ) : (
              <UserPasswordForm value={password} onChange={setPassword} />
            )}
            {displayUnsafePasswordWarning && (
              <FormControlLabel
                control={
                  <Checkbox checked={isUnsafePassword} onChange={() => setIsUnsafePassword(!isUnsafePassword)} />
                }
                label="J'assume la responsabilité d'avoir un mot de passe non-sécurisé"
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" disabled={loading}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" disabled={loading || (!isValid && !isUnsafePassword)}>
            Valider
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserPasswordDialog;
