import { generateRandomPassword, isPasswordValid } from '@ambuliz/sabri-core';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Cross } from 'common/components/Icons';
import Check from 'common/components/Icons/Check';
import { i18n } from 'common/locale';
import { useState } from 'react';

type UserPasswordFormProps = {
  value: string;
  onChange: (value: string) => void;
};

const UserPasswordForm = ({ value, onChange }: UserPasswordFormProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleGenerateRandomPassword = () => {
    onChange(generateRandomPassword());
  };

  const { isAtLeast12Characters, hasAtLeastOneUppercaseLetter, hasAtLeastOneDigit, hasAtLeastOneSpecialCharacter } =
    isPasswordValid(value);

  return (
    <Stack spacing={4}>
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          type={isVisible ? 'text' : 'password'}
          value={value}
          label={i18n.password}
          onChange={(e) => onChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsVisible(!isVisible)}>
                  {isVisible ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="outlined" color="primary" onClick={handleGenerateRandomPassword}>
          <LockResetRoundedIcon fontSize="inherit" />
        </Button>
      </Stack>
      <Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          color={isAtLeast12Characters ? 'success.main' : 'error.main'}
        >
          {isAtLeast12Characters ? <Check fontSize="inherit" /> : <Cross fontSize="inherit" />}
          <Typography>Le mot de passe doit faire au moins 12 caractères</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          color={hasAtLeastOneUppercaseLetter ? 'success.main' : 'error.main'}
        >
          {hasAtLeastOneUppercaseLetter ? <Check fontSize="inherit" /> : <Cross fontSize="inherit" />}
          <Typography>Le mot de passe doit contenir au moins une lettre majuscule</Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          color={hasAtLeastOneDigit ? 'success.main' : 'error.main'}
        >
          {hasAtLeastOneDigit ? <Check fontSize="inherit" /> : <Cross fontSize="inherit" />}
          <Typography>Le mot de passe doit contenir au moins un chiffre</Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          color={hasAtLeastOneSpecialCharacter ? 'success.main' : 'error.main'}
        >
          {hasAtLeastOneSpecialCharacter ? <Check fontSize="inherit" /> : <Cross fontSize="inherit" />}
          <Typography>Le mot de passe doit contenir au moins un caractère spécial</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserPasswordForm;
