import { isToday, isYesterday, differenceInCalendarDays, isTomorrow } from 'date-fns';
import { i18n } from '../locales';

export const getHumanDayDiffFromNow = ({
  date,
  shrink,
  startString,
}: {
  date: Date;
  shrink?: boolean;
  startString?: string;
}) => {
  if (isToday(date)) {
    return shrink ? i18n.todayShrink : i18n.today;
  }
  if (isYesterday(date)) {
    return i18n.yesterday;
  }
  if (isTomorrow(date)) {
    return i18n.tomorrow;
  }
  return `${startString ? `${startString} ` : ''}${Math.abs(differenceInCalendarDays(new Date(), date)).toString()} ${
    shrink ? 'jrs' : 'jours'
  }`;
};

export const convertSeconds = (timeInseconds: number): string => {
  //Get hours from milliseconds
  const hours = timeInseconds / (60 * 60);
  const absoluteHours = Math.floor(hours);

  //Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);

  if (!absoluteHours) {
    return `${absoluteMinutes} ${i18n.minutesShrinks}`;
  }

  if (!absoluteMinutes) {
    return `${absoluteHours} ${i18n.hour}`;
  }

  return `${absoluteHours} ${i18n.hour} ${absoluteMinutes} ${i18n.minutesShrinks}`;
};
