import Api, { AvailabilityPeriodStatus, Cloud, Area as ParseArea } from '@ambuliz/sabri-core';
import { Area, NormalizedArea } from 'superadmin/types';

const normalizeArea = (parseArea: ParseArea): Area => ({
  id: parseArea.id,
  name: parseArea.get('name'),
  externalIds: parseArea.get('externalIds') || [],
  isSearchable: parseArea.get('isSearchable'),
  externalTypeCode: parseArea.get('externalTypeCode') || '',
  healthCenterId: parseArea.get('healthCenter')?.id,
  parent: parseArea.get('parent')?.id,
  availabilityPeriods: parseArea.get('availabilityPeriods') || [],
  excludedFromOccupancy: parseArea.get('excludedFromOccupancy')
});

const normalizeAreas = (parseAreas: ParseArea[]) => {
  return parseAreas.reduce((areas, area) => {
    return { ...areas, [area.id]: normalizeArea(area) };
  }, {} as Record<string, NormalizedArea>);
};

const areaService = {
  findAll: async () => {
    const parseAreas = await new Api.Query(ParseArea).findAll();
    return normalizeAreas(parseAreas);
  },
  create: async (newArea: Omit<Area, 'id'>) => {
    const { area } = await Cloud.createArea({
      name: newArea.name,
      healthCenterId: newArea.healthCenterId,
      externalIds: newArea.externalIds,
      isSearchable: newArea.isSearchable,
      externalTypeCode: newArea.externalTypeCode,
      parentId: newArea.parent,
      excludedFromOccupancy: newArea.excludedFromOccupancy,
    });
    return normalizeArea(area);
  },
  update: async (updatedArea: Area) => {
    const { area } = await Cloud.updateArea({
      id: updatedArea.id,
      name: updatedArea.name,
      healthCenterId: updatedArea.healthCenterId,
      isSearchable: updatedArea.isSearchable,
      externalIds: updatedArea.externalIds,
      externalTypeCode: updatedArea.externalTypeCode,
      excludedFromOccupancy: updatedArea.excludedFromOccupancy,
    });
    return normalizeArea(area);
  },
  delete: async (id: string) => Cloud.deleteArea({ id }),
  updateAvailability: async ({
    id,
    availabilityPeriod,
  }: {
    id: string;
    availabilityPeriod: { start: Date; end?: Date; status: AvailabilityPeriodStatus };
  }) => {
    await Cloud.updateAreaAvailabilityPeriods({ id, ...availabilityPeriod });
    const area = await new Api.Query(ParseArea).get(id);
    return normalizeArea(area);
  },
  move: async (params: { id: string; parentId: string; newExternalIds?: string[]; activationDate?: Date }) => {
    const { id } = await Cloud.moveArea(params);
    const newArea = await new Api.Query(ParseArea).get(id);
    const oldArea = await new Api.Query(ParseArea).get(params.id);
    return { oldArea: normalizeArea(oldArea), newArea: normalizeArea(newArea) };
  },
};

export default areaService;
