import { Box, CircularProgress, Drawer, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useClasses = makeStyles({
  drawer: {
    minWidth: '500px',
  },
});

type ManagementModalProps = {
  title?: string;
  open?: boolean;
  redirectUrl?: string;
  loading?: boolean;
  children?: ReactNode;
};

const ManagementModal: React.FC<ManagementModalProps> = ({
  children,
  title,
  open: parentOpen = true,
  redirectUrl,
  loading,
}) => {
  const classes = useClasses();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (!parentOpen) {
      setOpen(false);
    }
  }, [parentOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="right"
      SlideProps={{ onExited: () => (redirectUrl ? navigate(redirectUrl) : navigate(-1)) }}
      classes={{ paper: classes.drawer }}
    >
      {loading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <Box margin={8}>
          <Grid container direction="column" spacing={8}>
            <Grid item>
              <Typography variant="h3">{title}</Typography>
            </Grid>
            <Grid item container>
              {children}
            </Grid>
          </Grid>
        </Box>
      )}
    </Drawer>
  );
};

export default ManagementModal;
