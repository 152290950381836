import { useContext, useEffect, useState } from 'react';
import TimelineContext from '../TimelineContext';
import getPosition from './getPosition';

const REFRESH_INTERVAL = 60 * 1000;

const useTimeMarker = () => {
  const { scale, period, start } = useContext(TimelineContext);

  const [timeMarker, setTimeMarker] = useState({
    position: getPosition({ scale, period, start, date: new Date() }),
    time: new Date(),
  });

  useEffect(() => {
    const updateTimeMarker = () => {
      setTimeMarker({ position: getPosition({ scale, period, start, date: new Date() }), time: new Date() });
    };

    const timeUntilNextMinute = 60 - new Date().getSeconds();

    const firstUpdateTimeout = setTimeout(() => {
      updateTimeMarker();
      const interval = setInterval(updateTimeMarker, REFRESH_INTERVAL);

      return () => clearInterval(interval);
    }, timeUntilNextMinute * 1000);

    updateTimeMarker();
    return () => clearTimeout(firstUpdateTimeout);
  }, [start, scale, period]);

  return timeMarker;
};

export default useTimeMarker;
