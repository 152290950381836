import { Components } from '@mui/material';
import { ChevronSmallDown, Cross } from 'common/components/Icons';
import { i18n } from 'common/locale';

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  defaultProps: {
    clearIcon: <Cross />,
    popupIcon: <ChevronSmallDown />,
    noOptionsText: i18n.noResult,
  },
  styleOverrides: {
    option: {
      '&:not(:last-child)': {
        marginBottom: '4px !important',
      },
    },
    inputRoot: {
      flexWrap: 'nowrap',
    },
    root: {
      '& .MuiOutlinedInput-root': {
        padding: '11px 12px',
      },
      '& .MuiFilledInput-root': {
        padding: '11px 12px',
      },
      '& .MuiInputBase-adornedStart': {
        '&.MuiOutlinedInput-root.MuiInputBase-root .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .MuiInputBase-adornedEnd': {
        '&.MuiOutlinedInput-root.MuiInputBase-root .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .MuiInputBase-adornedStart.MuiInputBase-adornedEnd': {
        '&.MuiOutlinedInput-root.MuiInputBase-root .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .MuiInputBase-sizeSmall': {
        height: 'auto',
        minHeight: 32,
        fontSize: 12,
        lineHeight: '14px',
        fontWeight: 400,
        paddingRight: '50px !important',
        '& .MuiAutocomplete-endAdornment': {
          '& .MuiAutocomplete-clearIndicator': {
            padding: 2,
          },
        },
      },
      '& .MuiFilledInput-root.MuiInputBase-sizeSmall': {
        padding: 8,
        '& .MuiFilledInput-input': {
          padding: 0,
        },
      },
    },
  },
  variants: [
    {
      props: {
        multiple: true,
      },
      style: {
        '& .MuiAutocomplete-inputRoot': {
          flexWrap: 'wrap',
        },
        '& .MuiAutocomplete-tag': {
          margin: '0',
        },
        '& .MuiInputBase-root': {
          height: 'unset !important',
          minHeight: '40px',
        },
        '& .MuiInputBase-sizeSmall': {
          height: 'unset !important',
          minHeight: '32px',
        },
        '& > .MuiTextField-root': {
          '& > .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            padding: '5px 7px',
            gap: '2px',
            alignItems: 'center',
            '& > .MuiFilledInput-input': {
              padding: 0,
            },
          },
        },
      },
    },
  ],
};

export default MuiAutocomplete;
