"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PORTER_SUPPORT_UNASSIGNED_DEFAULT_CONFIGURATION = exports.PORTER_SUPPORT_ASSIGNED_DEFAULT_CONFIGURATION = void 0;
exports.PORTER_SUPPORT_ASSIGNED_DEFAULT_CONFIGURATION = {
    isAcknowledgeNeeded: true,
    notificationsProviders: ['IN_APP', 'PUSH_MOBILE'],
    triggerEvent: 'PORTER_SUPPORT_ASSIGNED',
    retryPolicy: {
        retryDelayInSeconds: 10,
        maxRetryCount: 5,
    },
};
exports.PORTER_SUPPORT_UNASSIGNED_DEFAULT_CONFIGURATION = {
    isAcknowledgeNeeded: true,
    notificationsProviders: ['IN_APP', 'PUSH_MOBILE'],
    triggerEvent: 'PORTER_SUPPORT_UNASSIGNED',
    retryPolicy: {
        retryDelayInSeconds: 10,
        maxRetryCount: 5,
    },
};
