import { SvgIcon, SvgIconProps } from '@mui/material';

const Reset: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.97172 0.504407C4.0365 0.569022 4.08789 0.645782 4.12296 0.73029C4.15803 0.814799 4.17608 0.905395 4.17608 0.99689C4.17608 1.08839 4.15803 1.17898 4.12296 1.26349C4.08789 1.348 4.0365 1.42476 3.97172 1.48937L2.37602 3.08369H9.73962C11.4 3.08369 12.9923 3.74326 14.1664 4.91731C15.3404 6.09136 16 7.68371 16 9.34407C16 11.0044 15.3404 12.5968 14.1664 13.7708C12.9923 14.9449 11.4 15.6045 9.73962 15.6045H4.17483C3.99035 15.6045 3.81342 15.5312 3.68297 15.4007C3.55252 15.2703 3.47923 15.0933 3.47923 14.9089C3.47923 14.7244 3.55252 14.5474 3.68297 14.417C3.81342 14.2865 3.99035 14.2133 4.17483 14.2133H9.73962C11.031 14.2133 12.2695 13.7003 13.1827 12.7871C14.0958 11.874 14.6088 10.6355 14.6088 9.34407C14.6088 8.05268 14.0958 6.81418 13.1827 5.90103C12.2695 4.98788 11.031 4.47488 9.73962 4.47488H2.37602L3.97172 6.06919C4.03639 6.13387 4.08769 6.21064 4.12269 6.29515C4.1577 6.37965 4.17571 6.47021 4.17571 6.56168C4.17571 6.65314 4.1577 6.74371 4.12269 6.82821C4.08769 6.91271 4.03639 6.98949 3.97172 7.05416C3.90704 7.11883 3.83027 7.17014 3.74576 7.20514C3.66126 7.24014 3.5707 7.25815 3.47923 7.25815C3.38777 7.25815 3.2972 7.24014 3.2127 7.20514C3.1282 7.17014 3.05142 7.11883 2.98675 7.05416L0.204358 4.27177C0.139579 4.20715 0.0881848 4.13039 0.0531177 4.04588C0.0180506 3.96137 0 3.87078 0 3.77928C0 3.68779 0.0180506 3.59719 0.0531177 3.51268C0.0881848 3.42817 0.139579 3.35141 0.204358 3.2868L2.98675 0.504407C3.05137 0.439628 3.12813 0.388234 3.21263 0.353167C3.29714 0.318099 3.38774 0.300049 3.47923 0.300049C3.57073 0.300049 3.66133 0.318099 3.74583 0.353167C3.83034 0.388234 3.9071 0.439628 3.97172 0.504407Z"
      />
    </SvgIcon>
  );
};

export default Reset;
