import { Box, BoxProps } from '@mui/material';
import { color, transition } from 'common/theme';
import shadows from 'common/theme/shadows';
import { memo, useMemo } from 'react';
import { getTimelinePaddingTop } from './Timeline';

export type TimelineBackgroundVariant = 'card' | 'filled';

type TimelineBackgroundProps = {
  days: number;
  scale: number;
  variant?: TimelineBackgroundVariant;
};

const TimelineBackground = ({ days, scale, variant = 'card' }: TimelineBackgroundProps) => {
  const rows = useMemo(() => {
    const rows = [];
    const daysCount = days * 2;

    for (let i = 1; i < daysCount; i++) {
      rows.push(
        <Box
          key={i}
          position="absolute"
          width="1px"
          bottom={variant === 'card' ? 4 : 0}
          sx={{
            transform: `translateX(${Math.floor(scale * (i / 2))}px)`,
            transition,
            opacity: variant === 'card' || i % 2 === 0 ? undefined : 0.4,
          }}
          bgcolor={i % 2 === 0 || variant === 'filled' ? color.grey[20] : color.blue[5]}
          top={variant === 'card' ? 4 : 0}
          zIndex={1}
        ></Box>
      );
    }
    return rows;
  }, [scale, days, variant]);

  return (
    <Box position="absolute" bottom={variant === 'card' ? 8 : 0} width={scale * days} {...getBackgroundStyle(variant)}>
      {rows}
    </Box>
  );
};

const getBackgroundStyle = (variant: TimelineBackgroundVariant): BoxProps => {
  switch (variant) {
    case 'card':
      return {
        top: getTimelinePaddingTop(variant),
        bgcolor: 'white',
        boxShadow: shadows[2],
        borderRadius: 1,
        sx: {
          outline: `1px solid ${color.grey[10]}`,
        },
      };
    case 'filled':
      return {
        top: 0,
        bgcolor: 'background.default',
      };
    default:
      return {};
  }
};

export default memo(TimelineBackground);
