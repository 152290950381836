import { SvgIcon, SvgIconProps } from '@mui/material';

const Clock: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M8 0a8 8 0 1 0 8 8 8.01 8.01 0 0 0-8-8Zm0 14.667A6.667 6.667 0 1 1 14.667 8 6.674 6.674 0 0 1 8 14.667Zm.333-6.96 3.09 2.833c.27.249.29.67.041.941a.673.673 0 0 1-.942.041l-3.306-3.03A.667.667 0 0 1 7 8V4.333a.667.667 0 1 1 1.333 0v3.374Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default Clock;
