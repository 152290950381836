import {
  AccommodationRequest,
  AccommodationSpecificity,
  AccommodationStatus,
  PatientGender,
} from '@ambuliz/sabri-core';
import { Box, Card, CardActionArea, CardContent, Stack, Typography, useMediaQuery } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Comment, HeartBeat } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, theme } from 'common/theme';
import getShortDuration from 'common/utils/getShortDuration';
import { format } from 'date-fns';
import { AccommodationSpecificityBadges } from 'kurt/components';
import { useMemo } from 'react';
import styled from 'styled-components';
import PatientCard from './PatientCard';

export type AdmissionCardProps = {
  id?: string;
  start: Date;
  end?: Date;
  isEstimatedEnd?: boolean;
  patient: {
    name: string;
    gender: PatientGender;
    birthdate: Date;
  };
  specificities: AccommodationSpecificity[];
  comment?: string;
  basedOn?: AccommodationRequest | undefined;
  status: AccommodationStatus;
  onClick?: () => void;
};

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 16px;
  background-color: ${color.grey[60]};
`;

const AdmissionCard = ({
  id,
  start,
  end,
  isEstimatedEnd,
  patient,
  status,
  specificities = [],
  comment,
  basedOn,
  onClick,
}: AdmissionCardProps) => {
  const mdBreakpointMatcher = useMediaQuery(`(max-width: ${theme.breakpoints.values.md}px)`);

  const displayedComment = useMemo(() => comment?.replaceAll('\n', ' - '), [comment]);

  return (
    <Card>
      <CardActionArea disableRipple onClick={onClick}>
        <CardContent sx={{ padding: '12px 12px' }}>
          <Stack spacing={2} direction="column">
            <Stack direction="row" alignItems="center" spacing={3}>
              <Box flex={1}>
                <PatientCard
                  {...patient}
                  specificities={specificities}
                  borderLeft={['IN_PROGRESS', 'COMPLETED'].includes(status)}
                  borderRight={!!end && !isEstimatedEnd}
                  striped={status === 'COMPLETED'}
                />
              </Box>
              {specificities.length > 0 && (
                <Box display="flex">
                  <AccommodationSpecificityBadges specificities={specificities} />
                </Box>
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack alignItems="center" spacing={1.5} direction="row" flex={1}>
                <Typography variant="body2" color="secondary" noWrap>
                  {format(start, 'HH:mm')}
                </Typography>
                <Dot />
                <Typography variant="body2" color="secondary" noWrap>
                  {mdBreakpointMatcher
                    ? status === 'IN_PROGRESS'
                      ? i18n.effectiveShort
                      : i18n.previsionalShort
                    : status === 'IN_PROGRESS'
                    ? i18n.effectiveAdmission
                    : i18n.previsionalAdmission}
                </Typography>
                <Dot />
                <Typography variant="body2" color="secondary" noWrap>
                  {end ? getShortDuration(end, start) : '-'}
                </Typography>
              </Stack>

              {(displayedComment || basedOn?.reason) && (
                <Stack direction="row" spacing={3} alignItems="center" flexGrow={1} justifyContent="end">
                  {basedOn?.reason && (
                    <Stack direction="row" spacing={1} minWidth="20%" alignItems="center" justifyContent="end">
                      <HeartBeat
                        color="secondary"
                        sx={{
                          width: 12,
                          height: 12,
                        }}
                      />
                      <EllipsisTypography variant="body2" color="secondary" sx={{ whiteSpace: 'normal' }}>
                        {basedOn?.reason}
                      </EllipsisTypography>
                    </Stack>
                  )}
                  {displayedComment && (
                    <Stack direction="row" spacing={1} minWidth="20%" alignItems="center" justifyContent="end">
                      <Comment
                        color="secondary"
                        sx={{
                          width: 12,
                          height: 12,
                        }}
                      />
                      <EllipsisTypography variant="body2" color="secondary" sx={{ whiteSpace: 'normal' }}>
                        {displayedComment}
                      </EllipsisTypography>
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AdmissionCard;
