import { SVGProps } from 'react';

const Timeline = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 182 150" fill="none" {...props}>
    <path
      d="M84.307.033C63.972 1.549 59.418 3.34 46.537 12.496 25.164 27.678 10.173 52.764 13.085 78.001c2.053 17.865 12.591 33.939 25.53 46.958 7.967 8.011 17.07 15.212 27.688 19.597 11.918 4.926 25.26 6.094 38.23 5.138 13.928-1.025 27.828-4.522 39.806-11.428 16.601-9.564 28.852-25.6 33.934-43.454 5.085-17.855 3.072-37.345-4.961-54.134-2.251-4.706-4.984-9.235-8.576-13.106-3.976-4.275-8.909-7.643-14.022-10.615C130.879 5.45 107.542-.5 84.307.033Z"
      fill="#E6EDFF"
    />
    <path
      d="M7.509 135.761a6.56 6.56 0 0 1-4.659-1.964 6.558 6.558 0 0 1-1.909-4.672l.52-85.901c.02-3.614 2.986-6.557 6.6-6.557l151.313.908a6.558 6.558 0 0 1 4.659 1.964 6.525 6.525 0 0 1 1.908 4.67l-.519 85.901c-.018 3.616-2.979 6.557-6.598 6.557"
      fill="#799AF1"
    />
    <path
      d="M9.275 41.673a1.666 1.666 0 1 1-3.333-.02c0-.922.757-1.66 1.671-1.653.924 0 1.667.756 1.662 1.673ZM15.94 41.677a1.667 1.667 0 0 1-3.333-.02 1.666 1.666 0 1 1 3.333.02ZM22.609 41.677a1.666 1.666 0 1 1-3.333-.02 1.667 1.667 0 0 1 3.333.02Z"
      fill="#fff"
    />
    <rect x={10.941} y={56.667} width={143.333} height={71.667} rx={2} fill="#fff" />
    <path d="M45.941 56.667v71.666M84.275 56.667v71.666M119.275 56.667v71.666" stroke="#799AF1" strokeMiterlimit={10} />
    <path
      d="M96.225 75H32.323c-2.602 0-4.716-2.235-4.716-4.999 0-2.766 2.114-5.001 4.716-5.001h63.902c2.61 0 4.716 2.235 4.716 5.001 0 2.764-2.106 4.999-4.716 4.999Z"
      fill="#5470B8"
    />
    <path
      d="M134.559 90H70.658c-2.61 0-4.717-2.236-4.717-5 0-2.761 2.107-5 4.716-5h63.902c2.604 0 4.716 2.239 4.716 5 0 2.764-2.112 5-4.716 5Z"
      fill="#B5CAFF"
    />
    <path
      d="M97.784 105H32.43c-2.66 0-4.823-2.233-4.823-5 0-2.759 2.162-5 4.824-5h65.353c2.669 0 4.823 2.241 4.823 5 0 2.767-2.154 5-4.823 5Z"
      fill="#5470B8"
    />
    <path
      d="M142.686 105h-28.48c-2.728 0-4.931-2.233-4.931-5 0-2.759 2.203-5 4.931-5h28.48c2.72 0 4.923 2.241 4.923 5 0 2.767-2.203 5-4.923 5ZM86.226 120H58.991c-2.604 0-4.716-2.233-4.716-5 0-2.759 2.112-5 4.716-5h27.235c2.602 0 4.716 2.241 4.716 5 0 2.767-2.114 5-4.716 5Z"
      fill="#799AF1"
    />
    <path
      d="M142.664 120h-13.436c-2.733 0-4.953-2.233-4.953-5 0-2.759 2.22-5 4.953-5h13.436c2.733 0 4.945 2.241 4.945 5 0 2.767-2.212 5-4.945 5Z"
      fill="#E6EDFF"
    />
  </svg>
);

export default Timeline;
