import { Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { useEffect, useRef, useState } from 'react';

const FlowSummaryDuration: React.FC<{ since?: string; totalDuration: string }> = ({ since, totalDuration }) => {
  const durationRef = useRef<HTMLDivElement>(null);
  const [showDivider, setShowDivider] = useState(true);

  useEffect(() => {
    const durationHeight = durationRef.current?.parentElement?.clientHeight;
    // If height > 16 (line-height = 16px), duration is multiline, so we hide divider
    if (durationHeight && durationHeight > 16) {
      setShowDivider(false);
    }
  }, [durationRef]);

  return (
    <Typography component="div" variant="body2" color="secondary">
      <Stack
        ref={durationRef}
        direction="row"
        flexWrap="wrap"
        rowGap={1}
        columnGap={2}
        divider={<span>{showDivider && '•'}</span>}
      >
        {since && (
          <div>
            <Typography component="span" fontWeight={600} variant="body2">
              {`${i18n.since} : `}
            </Typography>

            <Typography component="span" variant="body2">
              {since}
            </Typography>
          </div>
        )}
        <div>
          <Typography component="span" fontWeight={600} variant="body2">
            {`${i18n.totalDuration} : `}
          </Typography>

          <Typography component="span" variant="body2">
            {totalDuration}
          </Typography>
        </div>
      </Stack>
    </Typography>
  );
};

export default FlowSummaryDuration;
