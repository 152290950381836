import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import { i18n } from 'common/locale';
import { ReactNode } from 'react';
import DialogHeader, { DialogHeaderProps } from './DialogHeader';

type ConfirmDialogProps = {
  title: ReactNode | string;
  icon?: DialogHeaderProps['Icon'];
  confirmLabel?: string;
  cancelLabel?: string;
  loading?: boolean;
  onBack?: DialogHeaderProps['onBack'];
  onClose?: () => void;
  onConfirm: () => void;
} & Omit<MuiDialogProps, 'onClose' | 'title'>;

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  children,
  icon,
  confirmLabel,
  cancelLabel,
  loading = false,
  onBack,
  onClose,
  onConfirm,
  ...props
}) => {
  return (
    <MuiDialog open={open} onClose={onClose} fullWidth {...props}>
      <DialogHeader title={title} Icon={icon} onBack={onBack} onClose={onClose} />
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onClose && (
          <Button variant="outlined" disabled={loading} onClick={onClose}>
            {cancelLabel || i18n.cancel}
          </Button>
        )}
        <Button onClick={onConfirm} disabled={loading}>
          {loading && <CircularProgress size={16} sx={{ color: 'white', marginRight: 2 }} />}
          {confirmLabel || i18n.confirm}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmDialog;
