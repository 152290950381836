import { PatientGender } from '@ambuliz/sabri-core';
import { Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { MoreVertical } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import shadows from 'common/theme/shadows';
import { format, startOfMinute } from 'date-fns';
import { AddAccommodationDialog } from 'kurt/components';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import BedName from 'kurt/pages/Unit/BedName';
import { BedStatus } from 'kurt/pages/Unit/UnitManagement/UnitManagementFilters';
import { memo, useRef, useState } from 'react';
import { CARD_CONTENT_PAPER_HEIGHT } from './cardContentPaperHeight';

const AvailableBedCard = ({
  name,
  id,
  unitId,
  gender,
  status,
  nextSlotAt,
  isReadOnly = false,
  onClick,
}: {
  name: string;
  id: string;
  unitId: string;
  gender: PatientGender;
  status: BedStatus;
  nextSlotAt?: Date;
  isReadOnly?: boolean;
  onClick: (id: string) => void;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [addAccommodationDialogOpen, setAddAccommodationDialogOpen] = useState(false);
  const anchorEl = useRef(null);
  return (
    <>
      <Card
        onClick={() => onClick(id)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            boxShadow: shadows[4],
          },
        }}
      >
        <CardHeader
          component="div"
          title={
            <Stack spacing={2} direction="row" alignItems="center">
              <BedName name={name} variant="h4" containerProps={{ width: undefined }} />
              <BedStatusChip status={status} gender={gender} />
            </Stack>
          }
          action={
            !isReadOnly && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuOpen(!menuOpen);
                }}
                ref={anchorEl}
              >
                <MoreVertical />
              </IconButton>
            )
          }
        />
        <CardContent>
          <Stack spacing={3}>
            <Paper
              sx={{ height: CARD_CONTENT_PAPER_HEIGHT, backgroundColor: palette.background.default, padding: 2 }}
              variant="outlined"
            />
            <Stack direction="row" spacing={6}>
              <Stack width="100%" maxWidth={86}>
                <Typography variant="body2" color="secondary">
                  {i18n.available}
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="secondary">
                  -
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" color="secondary">
                  {i18n.nextAccomodation}
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="secondary">
                  {nextSlotAt ? format(nextSlotAt, 'dd/MM - HH:mm') : '-'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl.current}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setAddAccommodationDialogOpen(true);
            setMenuOpen(false);
          }}
        >
          {i18n.addNewAccommodation}
        </MenuItem>
      </Menu>
      {addAccommodationDialogOpen && (
        <AddAccommodationDialog
          open={addAccommodationDialogOpen}
          onClose={() => setAddAccommodationDialogOpen(false)}
          onSuccess={() => setAddAccommodationDialogOpen(false)}
          accommodation={{ bedId: id, unitId, startAt: startOfMinute(new Date()), isEndEstimated: true }}
        />
      )}
    </>
  );
};

export default memo(AvailableBedCard);
