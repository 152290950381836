import { Components } from '@mui/material';
import color from '../color';
import palette from '../palette';

const MuiFilledInput: Components['MuiFilledInput'] = {
  styleOverrides: {
    root: {
      '&:not(.MuiInputBase-multiline):not(.MuiAutocomplete-inputRoot)': {
        height: 40,
      },
      color: palette.secondary.main,
      borderRadius: 8,
      border: `1px solid ${color.grey[10]}`,
      backgroundColor: color.blue[5],
      '&.Mui-disabled': {
        opacity: 0.4,
        backgroundColor: color.blue[5],
      },
      '&.Mui-error': {
        borderColor: palette.error.main,
      },
      ':hover:not(.Mui-disabled):not(.Mui-error), &.Mui-focused': {
        backgroundColor: color.grey[5],
        color: palette.secondary.dark,
      },
      ':before, :after': {
        borderBottom: `0 !important`,
      },
    },
    sizeSmall: {
      '&:not(.MuiInputBase-multiline):not(.MuiAutocomplete-inputRoot)': {
        height: 32,
      },
      borderRadius: 4,
      input: {
        padding: 8,
      },
      '& > .MuiSelect-filled': {
        minHeight: '18px',
        padding: 8,
      },
    },
    multiline: {
      padding: 0,
    },
    inputMultiline: {
      padding: '11px 12px',
      '&.MuiInputBase-inputSizeSmall': {
        padding: '7px 8px',
      },
    },
    input: {
      padding: '11px 12px',
      ':focus': {
        backgroundColor: 'transparent',
      },
      '&::placeholder': {
        color: color.grey[30],
        opacity: 1,
      },
    },
    adornedEnd: {
      '& > .MuiSvgIcon-root': {
        color: color.grey[30],
      },
    },
    adornedStart: {
      '& > .MuiSvgIcon-root': {
        color: color.grey[30],
      },
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
  ],
};

export default MuiFilledInput;
