import { Components } from '@mui/material';
import { ChevronSmallLeft, ChevronSmallRight } from 'common/components/Icons';
import color from '../color';

const MuiPaginationItem: Components['MuiPaginationItem'] = {
  defaultProps: {
    components: {
      previous: ChevronSmallLeft,
      next: ChevronSmallRight,
    },
  },
  styleOverrides: {
    root: {
      color: color.grey[60],
      minWidth: 24,
      height: 24,
      margin: '0 2px',
      padding: '4px 8px',
      '&:not(.MuiPaginationItem-ellipsis):hover': {
        backgroundColor: color.blue[5],
      },
      '&.Mui-selected': {
        backgroundColor: color.blue[10],
      },
    },
  },
};

export default MuiPaginationItem;
