import { Divider, Stack } from '@mui/material';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { PageSection, useAppBarContext, useSticky } from 'core/layout';
import getFlowTitle from 'kurt/components/PatientFlowKanban/getFlowTitle';
import KanbanColumnHeader from 'kurt/components/PatientFlowKanban/KanbanColumnHeader';
import { ColumnStatus } from 'kurt/pages/Movements/MovementsKanban';
import { useRef } from 'react';

const STICKY_FILTERS_HEIGHT = 64;

type KanbanColumnHeadersContainerProps = {
  loading?: boolean;
  flow: 'admission' | 'discharge';
  requestedVisitsCount: number;
  plannedVisitsCount: number;
  completedVisitsCount: number;
  validatedVisitsCount: number;
  dropAllowed: boolean;
  isDropColumnActive: (column: ColumnStatus) => boolean;
};

const KanbanColumnHeadersContainer = ({
  loading,
  flow,
  dropAllowed,
  isDropColumnActive,
  plannedVisitsCount,
  requestedVisitsCount,
  validatedVisitsCount,
  completedVisitsCount,
}: KanbanColumnHeadersContainerProps) => {
  const { appBarHeight, isFullScreen } = useAppBarContext();
  const ref = useRef<HTMLDivElement>(null);

  const columnHeadersStickyOffset = appBarHeight + (!isFullScreen ? STICKY_FILTERS_HEIGHT : 0);
  const sticky = useSticky(columnHeadersStickyOffset, ref);

  return (
    <PageSection
      sticky={{ top: columnHeadersStickyOffset, withShadow: true }}
      scrollable
      noGutter
      withBackground
      noPadding={!isFullScreen}
      paddingTop={!isFullScreen ? 0 : 16}
      noPaddingX={isFullScreen}
      noMargin={isFullScreen}
      ref={ref}
    >
      <Stack
        direction="row"
        divider={
          <Divider
            flexItem
            orientation="vertical"
            style={{ marginTop: sticky ? 12 : 24, marginBottom: sticky ? 12 : 0, borderColor: color.grey[10] }}
          />
        }
      >
        <KanbanColumnHeader
          loading={loading}
          isActive={isDropColumnActive('requested') && dropAllowed}
          header={getFlowTitle(flow, 'requested')}
          subheader={i18n.visitCount(requestedVisitsCount)}
        />
        <KanbanColumnHeader
          loading={loading}
          isActive={isDropColumnActive('planned') && dropAllowed}
          header={getFlowTitle(flow, 'planned')}
          subheader={i18n.visitCount(plannedVisitsCount)}
        />
        <KanbanColumnHeader
          loading={loading}
          isActive={isDropColumnActive('validated') && dropAllowed}
          header={getFlowTitle(flow, 'validated')}
          subheader={i18n.visitCount(validatedVisitsCount)}
        />
        <KanbanColumnHeader
          loading={loading}
          isActive={isDropColumnActive('completed') && dropAllowed}
          header={getFlowTitle(flow, 'completed')}
          subheader={i18n.visitCount(completedVisitsCount)}
        />
      </Stack>
    </PageSection>
  );
};

export default KanbanColumnHeadersContainer;
