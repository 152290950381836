import { MultipleAreaSelect } from './MultipleAreaSelect';
import { SingleAreaSelect } from './SingleAreaSelect';
type SingleAreaSelectProps = {
  value?: string;
  onChange: (id: string) => void;
};

export type MultipleAreaSelectProps = {
  value?: string[];
  onChange: (id: string[]) => void;
};

type AreaSelectProps = MultipleAreaSelectProps | SingleAreaSelectProps;

const AreaSelect: React.FC<AreaSelectProps> = ({ value, onChange }) => {
  return Array.isArray(value) ? (
    <MultipleAreaSelect value={value} onChange={onChange as (id: string[]) => void} />
  ) : (
    <SingleAreaSelect value={value || ''} onChange={onChange as (id: string) => void} />
  );
};

export default AreaSelect;
