import { SvgIcon, SvgIconProps } from '@mui/material';

const Cross: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0303 3.03033C14.3232 2.73744 14.3232 2.26256 14.0303 1.96967C13.7374 1.67678 13.2626 1.67678 12.9697 1.96967L8 6.93934L3.03033 1.96967C2.73744 1.67678 2.26256 1.67678 1.96967 1.96967C1.67678 2.26256 1.67678 2.73744 1.96967 3.03033L6.93934 8L1.96967 12.9697C1.67678 13.2626 1.67678 13.7374 1.96967 14.0303C2.26256 14.3232 2.73744 14.3232 3.03033 14.0303L8 9.06066L12.9697 14.0303C13.2626 14.3232 13.7374 14.3232 14.0303 14.0303C14.3232 13.7374 14.3232 13.2626 14.0303 12.9697L9.06066 8L14.0303 3.03033Z"
      />
    </SvgIcon>
  );
};

export default Cross;
