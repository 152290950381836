import Api, { ExternalPatientTransport } from '@ambuliz/sabri-core';
import { Dialog } from '@mui/material';
import { DialogHeader } from 'common/components';
import useParseQuery from 'common/hooks/useParseQuery';
import { ENVIRONMENT } from 'configuration';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ExternalTransportDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  const { externalTransport } = useExternalTransport(id!);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionProps={{ onExited: () => navigate((state as any)?.from || '../') }}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogHeader title="Transport sanitaire" onClose={handleClose} />
        {externalTransport && (
          <iframe
            title="external-transport-fare-detail"
            width={902}
            height={700}
            src={`${ENVIRONMENT.MARCUS_EMBED_WEB_URL || 'https://etablissement.stage.ambuliz.com'}/embed/fare/${
              externalTransport.externalId
            }`}
          />
        )}
      </Dialog>
    </>
  );
};

const useExternalTransport = (id: string) => {
  const { results: externalTransport, isLoading } = useParseQuery(
    new Api.Query(ExternalPatientTransport).equalTo('objectId', id).limit(1)
  );

  return {
    externalTransport: externalTransport?.[0],
    loading: isLoading,
  };
};

export default ExternalTransportDetails;
