import { useDraggable } from '@dnd-kit/core';
import { ReactNode } from 'react';

type DraggableData = {
  status?: string;
  data?: any;
};

type DraggableProps = {
  id: string;
  data?: DraggableData;
  disabled?: boolean;
  children?: ReactNode;
};

const Draggable: React.FC<DraggableProps> = ({ id, children, data, disabled }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data,
    disabled: disabled,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={{ opacity: isDragging ? 0.4 : 1 }}>
      {children}
    </div>
  );
};

export default Draggable;
