import { incidentReasons } from '@ambuliz/sabri-core';
import { Box, Button, Typography } from '@mui/material';
import { routerActions } from 'connected-react-router';
import { format, isWithinInterval } from 'date-fns';
import React from 'react';
import { FilterItem, FilterValues } from '../components/FiltersController/FiltersController';
import { SelectorCheckbox } from '../components/SelectorCheckbox/SelectorCheckbox';
import { SelectorRadio } from '../components/SelectorRadio/SelectorRadio';
import { TableListColumn } from '../components/TableList/TableList';
import { routeNamesCommonDynamic } from '../const/routeNames';
import { i18n } from '../locales';
import { IncidentDenormalized } from '../store/selectors/incident';
import { store } from '../store/store';
import { yesNoFilter } from './filters';

const sortIncidentsByDate = (incidentA: IncidentDenormalized, incidentB: IncidentDenormalized) =>
  new Date(incidentB.createdAt).getTime() - new Date(incidentA.createdAt).getTime();

const sortIncidentsByFareReference = (incidentA: IncidentDenormalized, incidentB: IncidentDenormalized) => {
  if (incidentA.fare?.reference && incidentB.fare?.reference) {
    const referenceA = incidentA.fare.reference && incidentA.fare.reference.split('-').join('');
    const referenceB = incidentB.fare.reference && incidentB.fare.reference.split('-').join('');
    if (referenceA && referenceB) {
      return Number(referenceB) - Number(referenceA);
    }
    return 0;
  }
  return -1;
};

export const incidentListColumns: TableListColumn<IncidentDenormalized>[] = [
  {
    id: 'reference',
    label: i18n.fare,
    renderCell: (incident) =>
      incident?.fare && (
        <Button
          onClick={() =>
            store.dispatch(routerActions.push(routeNamesCommonDynamic.incidentFareDetails(incident.fare!.objectId)))
          }
        >
          {incident.fare?.reference}
        </Button>
      ),
    sortMethod: sortIncidentsByFareReference,
  },
  {
    id: 'date',
    label: i18n.date,
    renderCell: (incident) => (
      <Typography variant="body2">{format(new Date(incident.createdAt), 'dd/MM/yyyy HH:mm')}</Typography>
    ),
    sortMethod: sortIncidentsByDate,
  },
  {
    id: 'reason',
    label: i18n.reason,
    renderCell: (incident) => <Typography variant="body2">{i18n.incidentReasons[incident.reason]}</Typography>,
  },
  {
    id: 'porter',
    label: i18n.employee,
    renderCell: (incident) => (
      <Typography variant="body2">
        {incident.porter?.firstName} {incident.porter?.lastName}
      </Typography>
    ),
  },
  {
    id: 'comment',
    label: i18n.comment,
    renderCell: (incident) =>
      incident.comment ? (
        <Typography variant="body2">{incident.comment}</Typography>
      ) : (
        <Box fontStyle="italic">{i18n.notFilled}</Box>
      ),
  },
  {
    id: 'delay',
    label: i18n.filters.delay,
    renderCell: (incident) => {
      return incident && incident.delayInMinutes ? (
        <Typography variant="body2">
          {incident.delayInMinutes > 60 ? i18n.moreThanOneHour : `${incident.delayInMinutes} ${i18n.minutesShrinks}`}
        </Typography>
      ) : (
        <Box fontStyle="italic">{i18n.noDelay}</Box>
      );
    },
  },
];

const incidentReasonsFilter: FilterItem[] = incidentReasons.map((incidentReason) => ({
  id: incidentReason,
  label: i18n.incidentReasons[incidentReason],
}));

export const incidentFilters = (porters: FilterItem[]) => {
  return [
    {
      id: 'reason',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorCheckbox onSubmit={onSubmit} title="Motif" items={incidentReasonsFilter} onClose={onClose} />
      ),
    },
    {
      id: 'porter',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorCheckbox searchField onSubmit={onSubmit} title="Agents" items={porters} onClose={onClose} />
      ),
    },
    {
      id: 'comment',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorRadio onSubmit={onSubmit} title="Commentaire" items={yesNoFilter} onClose={onClose} />
      ),
    },
  ];
};

export const filterIncidentsByDate =
  ({ start, end }: { start?: Date; end: Date }) =>
  (incident: IncidentDenormalized) => {
    if (!start) {
      return true;
    }
    return isWithinInterval(new Date(incident.createdAt), { start, end });
  };

export const filterIncidentsByFilters = (filterValues: FilterValues[]) => (incident: IncidentDenormalized) =>
  filterValues.every((filterValue) => {
    if (filterValue.id === 'reason') {
      return filterValue.values.includes(incident.reason);
    }
    if (filterValue.id === 'porter' && incident.porterId) {
      return filterValue.values.includes(incident.porterId);
    }
    if (filterValue.id === 'comment') {
      if (filterValue.values[0] === 'yes' && incident.comment) {
        return true;
      }
      if (filterValue.values[0] === 'no' && !incident.comment) {
        return true;
      }
    }
    return false;
  });
