import { NavigationRoute } from '@ambuliz/sabri-core';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { hasAccessToRoute } from 'core/authentication/navigationACL';
import { Tab, useAppBarContext } from 'core/layout';
import { ManagerIcon } from 'core/layout/components/AppBar';
import { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import BrowserNotification from './components/BrowserNotification/BrowserNotification';
import { CallPatient } from './components/CallPatient/CallPatient';
import { FareArchived } from './components/FareArchived/FareArchived';
import { FareList } from './components/FareList/FareList';
import { Incidents } from './components/Incidents/Incidents';
import { Linker } from './components/Linker/Linker';
import { LinkerTest } from './components/LinkerTest/LinkerTest';
import { LoaderContent } from './components/LoaderContent/LoaderContent';
import NavigateWithId from './components/NavigateWithId';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { ALL_WEB_USER_ROLES } from './const/allWebUserRoles';
import { CAN_REPORT_INCIDENT_ROLES } from './const/canReportIncidentRoles';
import { HistoryContextProvider } from './context/HistoryContext';
import { ConfirmModal } from './modals/ConfirmModal/ConfirmModal';
import FareDetailsModal from './modals/FareDetailsModal/FareDetailsModal';
import JourneyFormModal from './modals/JourneyFormModal';
import { CancelPullFareModal } from './modals/PullAndPushFareModal/CancelPullFareModal';
import { CancelPushFareModal } from './modals/PullAndPushFareModal/CancelPushFareModal';
import { ConfirmPullFareModal } from './modals/PullAndPushFareModal/ConfirmPullFareModal';
import { ConfirmPushFareModal } from './modals/PullAndPushFareModal/ConfirmPushFareModal';
import { RealTimeErrorModal } from './modals/RealTimeErrorModal/RealTimeErrorModal';
import { ReloadModal } from './modals/ReloadModal/ReloadModal';
import routes from './routes';
import { getDefaultFareListRoute } from './services/fare';
import { resetAction } from './store/actions/general';
import { unsubscribeLiveQueriesAction } from './store/actions/liveQuery';
import { rootSaga } from './store/sagas/rootSaga';
import { sagaMiddleware } from './store/store';

const FareIncidentFormModal = lazy(() =>
  import('./modals/FareIncidentFormModal/FareIncidentFormModal').then((module) => ({
    default: module.FareIncidentFormModal,
  }))
);
const Team = lazy(() =>
  import('./components/Team/Team').then((module) => ({
    default: module.Team,
  }))
);

const App = () => {
  const dispatch = useDispatch();
  const { user, healthCenter, unit, navigationACL } = useAuthentication();
  const { setAppBarSelect, setTabs } = useAppBarContext();

  useEffect(() => {
    setAppBarSelect({
      value: user.role === 'MANAGER' ? unit?.name || '' : healthCenter.name,
      subLabel: user.role === 'MANAGER' ? `${i18n.unit} • ${healthCenter?.name}` : i18n.healthCenter,
      icon: user.role === 'MANAGER' ? <ManagerIcon /> : undefined,
    });
  }, [setAppBarSelect, user, healthCenter, unit]);

  useEffect(() => {
    const userTabs = tabs;

    if (navigationACL.length > 0) {
      for (const tab in userTabs) {
        if (!hasAccessToRoute(navigationACL, userTabs[tab].route)) {
          delete userTabs[tab];
        }
      }
    } else if (user.role === 'MANAGER') {
      delete userTabs.team;
    }

    setTabs(Object.values(userTabs));
  }, [setTabs, user.role, navigationACL]);

  useEffect(() => {
    const saga = sagaMiddleware.run(rootSaga);

    return () => {
      dispatch(unsubscribeLiveQueriesAction());
      dispatch(resetAction());
      saga.cancel();
    };
  }, [dispatch]);

  return (
    <HistoryContextProvider>
      <Suspense fallback={<LoaderContent />}>
        <Routes>
          <Route path="linker/*" element={<Linker />} />
          <Route path="linkerTest/*" element={<LinkerTest />} />
          <Route
            path="fares/*"
            element={
              <PrivateRoute routeACL="sabri.fares">
                <FareList />
              </PrivateRoute>
            }
          >
            <Route
              path="create"
              element={
                <PrivateRoute roles={ALL_WEB_USER_ROLES}>
                  <JourneyFormModal />
                </PrivateRoute>
              }
            />
            <Route
              path="details/:id/edit"
              element={
                <PrivateRoute roles={ALL_WEB_USER_ROLES}>
                  <JourneyFormModal />
                </PrivateRoute>
              }
            />
            <Route
              path="details/:id/new-incident"
              element={
                <PrivateRoute roles={CAN_REPORT_INCIDENT_ROLES}>
                  <FareIncidentFormModal />
                </PrivateRoute>
              }
            />
            <Route path="details/:id" element={<FareDetailsModal />} />
            <Route path="edit/:id" element={<NavigateWithId to="details/:id/edit" />} />
            <Route path="new-incident/:id" element={<NavigateWithId to="details/:id/new-incident" />} />
          </Route>
          <Route
            path="team/*"
            element={
              <PrivateRoute roles={['ADMIN', 'REGULATOR', 'ADMIN_REGULATOR']} routeACL="sabri.team">
                <Team />
              </PrivateRoute>
            }
          />
          <Route
            path="callPatient"
            element={
              <PrivateRoute roles={['MANAGER', 'ADMIN_REGULATOR', 'REGULATOR']}>
                <CallPatient />
              </PrivateRoute>
            }
          />
          <Route
            path="incidents"
            element={
              <PrivateRoute roles={['ADMIN', 'ADMIN_REGULATOR']}>
                <Incidents />
              </PrivateRoute>
            }
          >
            <Route path="details/:id" element={<FareDetailsModal />} />
          </Route>
          <Route path="archives" element={<FareArchived />}>
            <Route path="details/:id" element={<FareDetailsModal />} />
          </Route>
          <Route path="*" element={<Navigate to={getDefaultFareListRoute(user.role, navigationACL)} />} />
        </Routes>
      </Suspense>
      <RealTimeErrorModal />
      <ConfirmModal />
      <CancelPullFareModal />
      <CancelPushFareModal />
      <ConfirmPullFareModal />
      <ConfirmPushFareModal />
      <BrowserNotification />
      <ReloadModal />
    </HistoryContextProvider>
  );
};

const tabs: Record<string, Tab & { route: NavigationRoute }> = {
  fares: { route: 'sabri.fares', label: i18n.fareList, path: routes.fares, isDefault: true },
  team: { route: 'sabri.team', label: i18n.team, path: routes.team },
};

export default App;
