import Api, { Fare } from '@ambuliz/sabri-core';
import { ArrowForward } from '@mui/icons-material';
import {
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DialogHeader, TimeFlowLine } from 'common/components';
import { DoubleArrow, LyingDown } from 'common/components/Icons';
import { PatientSummary } from 'common/components/PatientAutocomplete';
import useParseQuery from 'common/hooks/useParseQuery';
import { formatName } from 'common/utils';
import { ContentLoader } from 'core/layout';
import { format, formatDuration } from 'date-fns';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { i18n } from 'sabri/locales';
import { FareHistoryEvent } from 'sabri/modals/FareDetailsModal/FareHistory/FareHistory';
import FareHistoryEventList from 'sabri/modals/FareDetailsModal/FareHistory/FareHistoryEventList';
import FareHistoryHeader from 'sabri/modals/FareDetailsModal/FareHistory/FareHistoryHeader';
import useFareHistory from 'sabri/modals/FareDetailsModal/FareHistory/useFareHistory';

const FareDetailsDialog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { fare, loading } = useFare(id!);
  const { events, loading: historyLoading } = useFareHistory(id!);

  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionProps={{ onExited: () => navigate((state as any)?.from || '../') }}
        scroll="body"
        maxWidth="md"
      >
        <>
          <DialogHeader
            title={
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={4}>
                  <Typography variant="h4">Détails de la mission</Typography>
                </Stack>
              </Stack>
            }
            onClose={handleClose}
          />
          <DialogContent>
            <FareDetails fare={fare} loading={loading} events={events} historyLoading={historyLoading} />
          </DialogContent>
        </>
      </Dialog>
    </>
  );
};

const FareDetails = ({
  fare,
  loading = false,
  events,
  historyLoading = false,
}: {
  fare?: Fare;
  loading?: boolean;
  events: FareHistoryEvent[];
  historyLoading?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  const visit = fare?.patient;
  const patient = fare?.patient.patient;

  if (loading) {
    return <ContentLoader />;
  }

  return fare ? (
    <Stack spacing={4}>
      {patient && (
        <PatientSummary
          name={formatName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName)}
          birthdate={patient.birthdate}
          pan={visit?.pan || '-'}
          ipp={visit?.ipp || '-'}
          gender={patient.gender}
          primary
        />
      )}
      <Paper>
        <Stack p={6} direction="row" alignItems="center" justifyContent="center" spacing={4}>
          {fare.fromUnit?.name && (
            <Stack>
              <Typography color="secondary">Départ</Typography>
              <Typography variant="h3">{fare.fromUnit.name}</Typography>
              <Stack direction="row">
                <Typography>
                  {fare.fromArea &&
                    `${fare.fromArea.externalTypeCode === 'bed' ? 'Lit ' : ''}${
                      fare.fromArea?.name && fare.fromArea.name
                    }`}
                </Typography>
              </Stack>
            </Stack>
          )}
          <TimeFlowLine start="dashed" end="dashed" />
          {fare.toUnit?.name && (
            <Stack>
              <Typography color="secondary">Arrivée</Typography>
              <Typography variant="h3">{fare.toUnit.name}</Typography>
              <Stack direction="row">
                <Typography>
                  {fare.toArea &&
                    `${fare.toArea.externalTypeCode === 'bed' ? 'Lit ' : ''}${fare.toArea?.name && fare.toArea.name}`}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Paper>
      <Stack spacing={2}>
        <Typography variant="h4">Informations sur la mission</Typography>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography>Heure d'arrivée :</Typography>
          <Typography>
            {fare.wantedDate && `${format(fare.wantedDate, 'HH:MM')} (${format(fare.wantedDate, 'dd/MM')})`}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography>Durée :</Typography>
          <Typography>
            {fare.duration ? formatDuration({ seconds: fare.duration * 1000 }, { format: ['minutes'] }) : 'non défini'}
          </Typography>
        </Stack>
        {fare.position && (
          <Stack direction="row" alignItems="center" spacing={4}>
            <Typography>Position :</Typography>
            <Chip color="default" label={i18n.farePosition[fare.position]} icon={<LyingDown />}></Chip>
          </Stack>
        )}

        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography>Statut :</Typography>
          {fare.status && <Chip label={i18n.fareStatus[fare.status]} />}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography>Référence :</Typography>
          {fare.reference && <Typography>{fare.reference}</Typography>}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography>Type :</Typography>
          <Chip
            variant="outlined"
            color="default"
            label={fare.roundTrip ? i18n.roundTrip : i18n.outward}
            icon={fare.roundTrip ? <DoubleArrow /> : <ArrowForward />}
          ></Chip>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h4">Brancardiers assignés</Typography>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Brancardiers</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Dernière position</TableCell>
                <TableCell>Se dirige vers</TableCell>
                <TableCell>Mission terminées</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fare.porters && fare.porters.length > 0 ? (
                fare.porters.map((porter) => (
                  <TableRow>
                    <TableCell>{formatName(porter.firstName, porter.lastName)}</TableCell>
                    <TableCell>{porter.status && <Chip label={i18n.porterStatus[porter.status]}></Chip>}</TableCell>
                    <TableCell>
                      {porter.lastArea?.name} - {porter.lastAreaAt && format(porter.lastAreaAt, 'HH:MM')}
                    </TableCell>
                    <TableCell>{porter.nextArea?.name}</TableCell>
                    <TableCell>{porter.fareDoneCount}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>Aucun brancardier assigné à cette mission</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Stack>
      <Stack spacing={2}>
        <Paper>
          <FareHistoryHeader onClick={() => setExpanded(!expanded)} open={expanded} loading={historyLoading} />
          <Collapse in={expanded}>
            <FareHistoryEventList events={events} />
          </Collapse>
        </Paper>
      </Stack>
    </Stack>
  ) : null;
};

const useFare = (id: string) => {
  const { results, isLoading } = useParseQuery(
    new Api.Query(Fare)
      .include(
        'patient',
        'patient.patient',
        'fromArea',
        'toArea',
        'fromUnit',
        'toUnit',
        'fromArea.parent',
        'toArea.parent',
        'porters',
        'porters.lastArea'
      )
      .equalTo('objectId', id!)
      .limit(1)
  );

  return { fare: results?.[0], loading: isLoading };
};

export default FareDetailsDialog;
