import { color as themeColor } from 'common/theme';

const TimeFlowLine = ({
  start,
  end,
  size = 'medium',
  direction = 'horizontal',
}: {
  start?: boolean | 'dashed' | 'filled';
  end?: boolean | 'dashed' | 'filled';
  size?: 'small' | 'medium';
  direction?: 'vertical' | 'horizontal';
}) => {
  if (!end && !start) {
    start = 'filled';
  }
  const double = start && end;
  const color = themeColor.grey[30];
  const width = size === 'small' ? 32 : 48;
  const height = size === 'small' ? 13 : 26;

  return direction === 'vertical' ? (
    <svg width={8} height={double ? height * 2 : height}>
      {start && (
        <>
          <circle cx={4} cy={4} r={3.5} fill={start === 'dashed' ? 'none' : color} stroke={color} strokeWidth={1} />
          <line
            x1={4}
            y1={10}
            x2={4}
            y2={height}
            strokeWidth={1}
            stroke={color}
            strokeDasharray={start === 'dashed' ? 4 : 0}
          />
        </>
      )}
      {end && !double && (
        <>
          <circle
            cx={4}
            cy={height - 4}
            r={3.5}
            fill={end === 'dashed' ? 'none' : color}
            stroke={color}
            strokeWidth={1}
          />
          <line
            x1={4}
            y1={0}
            x2={4}
            y2={height - 10}
            strokeWidth={1}
            stroke={color}
            strokeDasharray={end === 'dashed' ? 4 : 0}
          />
        </>
      )}
      {double && (
        <>
          <circle
            cx={4}
            cy={height * 2 - 4}
            r={3.5}
            fill={end === 'dashed' ? 'none' : color}
            stroke={color}
            strokeWidth={1}
          />
          <line
            x1={4}
            y1={height}
            x2={4}
            y2={height * 2 - 10}
            strokeWidth={1}
            stroke={color}
            strokeDashoffset={-2}
            strokeDasharray={end === 'dashed' ? 4 : 0}
          />
        </>
      )}
    </svg>
  ) : (
    <svg width={double ? width * 2 : width} height={8}>
      {start && (
        <>
          <circle cx={4} cy={4} r={3.5} fill={start === 'dashed' ? 'none' : color} stroke={color} strokeWidth={1} />
          <line
            x1={10}
            y1={4}
            x2={width}
            y2={4}
            strokeWidth={1}
            stroke={color}
            strokeDasharray={start === 'dashed' ? 4 : 0}
          />
        </>
      )}
      {end && !double && (
        <>
          <circle
            cx={width - 4}
            cy={4}
            r={3.5}
            fill={end === 'dashed' ? 'none' : color}
            stroke={color}
            strokeWidth={1}
          />
          <line
            x1={0}
            y1={4}
            x2={width - 10}
            y2={4}
            strokeWidth={1}
            stroke={color}
            strokeDasharray={end === 'dashed' ? 4 : 0}
          />
        </>
      )}
      {double && (
        <>
          <circle
            cx={width * 2 - 4}
            cy={4}
            r={3.5}
            fill={end === 'dashed' ? 'none' : color}
            stroke={color}
            strokeWidth={1}
          />
          <line
            x1={width}
            y1={4}
            x2={width * 2 - 10}
            y2={4}
            strokeWidth={1}
            stroke={color}
            strokeDashoffset={-2}
            strokeDasharray={end === 'dashed' ? 4 : 0}
          />
        </>
      )}
    </svg>
  );
};

export default TimeFlowLine;
