import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { UpdateSpecificities } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';
import { useAccommodation } from 'kurt/hooks';
import { useState } from 'react';

type UpdateSpecificitiesDialogContentProps = {
  id: string;
  specificities?: AccommodationSpecificity[];
  onClose: () => void;
  onSuccess: () => void;
  onBack?: () => void;
};

export const UpdateSpecificitiesDialogContent = ({
  id,
  specificities = [],
  onClose,
  onSuccess,
  onBack,
}: UpdateSpecificitiesDialogContentProps) => {
  const [loading, setLoading] = useState(false);
  const { update } = useAccommodation(id);

  const handleConfirm = async (specificities: AccommodationSpecificity[] = []) => {
    try {
      setLoading(true);
      await update({ specificities });
      toast.success(i18n.accommodationBedSpecificitiesUpdatedSuccess);
      onSuccess();
    } catch (error: any) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <DialogHeader title={i18n.visitActions.UPDATE_SPECIFICITIES.action.label} onClose={onClose} onBack={onBack} />
      <DialogContent>
        <UpdateSpecificities
          initialSpecificities={specificities}
          onCancel={onBack || onClose}
          onSubmit={(accommodation) => {
            handleConfirm(accommodation?.specificities);
          }}
          loading={loading}
        />
      </DialogContent>
    </>
  );
};

type UpdateSpecificitiesDialogProps = {
  id: string;
  open: boolean;
  specificities?: AccommodationSpecificity[];
  onClose: () => void;
};

export const UpdateSpecificitiesDialog = ({
  specificities = [],
  open,
  onClose,
  id,
}: UpdateSpecificitiesDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} title={i18n.visitActions.UPDATE_SPECIFICITIES.action.label}>
      <UpdateSpecificitiesDialogContent id={id} specificities={specificities} onClose={onClose} onSuccess={onClose} />
    </Dialog>
  );
};
