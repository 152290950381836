import { Components } from '@mui/material';
import color from '../color';

const MuiDrawer: Components['MuiDrawer'] = {
  defaultProps: {
    PaperProps: {
      elevation: 2,
    },
  },
  styleOverrides: {
    paperAnchorDockedRight: {
      borderLeftColor: color.grey[10],
    },
  },
};

export default MuiDrawer;
