import Api, { AccommodationSpecificity, Patient } from '@ambuliz/sabri-core';
import { Dialog, Link, Stack, Typography } from '@mui/material';
import { Alert, DialogHeader, Emoji } from 'common/components';
import { PatientSearchResult } from 'common/components/PatientAutocomplete';
import useParseQuery from 'common/hooks/useParseQuery';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { formatName } from 'common/utils';
import { useSupport } from 'core/support/useSupport';
import { useAccommodationRequest } from 'kurt/hooks';
import { AccommodationRequestToCreate } from 'kurt/hooks/useAccommodationRequest';
import { useState } from 'react';
import SendAccommodationRequestForm from './SendAccommodationRequestForm';

type SendAccommodationRequestDialogProps = {
  unitId: string;
  open: boolean;
  onClose: () => void;
  visitId?: string;
  comment?: string;
  specificities?: AccommodationSpecificity[];
};

const SendAccommodationRequestDialog: React.FC<SendAccommodationRequestDialogProps> = ({
  unitId,
  open,
  onClose,
  visitId,
  comment,
  specificities = [],
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" scroll="body">
      <DialogHeader title={i18n.sendAccommodationRequestModal.title} onClose={onClose} />
      <SendAccommodationRequestModal
        visitId={visitId}
        unitId={unitId}
        onClose={onClose}
        comment={comment}
        specificities={specificities}
      />
    </Dialog>
  );
};

export const SendAccommodationRequestModal = ({
  visitId,
  unitId,
  onClose,
  comment,
  specificities = [],
}: {
  visitId?: string;
  unitId: string;
  onClose: () => void;
  comment?: string;
  specificities?: AccommodationSpecificity[];
}) => {
  const { openSupport } = useSupport();
  const { create, loading } = useAccommodationRequest();
  const [error, setError] = useState(false);
  const patient = usePatient(visitId);

  const handleSubmit = async (accommodationRequest: AccommodationRequestToCreate) => {
    setError(false);
    try {
      await create(accommodationRequest);
      toast.success({
        title: i18n.planNewAdmissionSuccessToast.title,
        message:
          i18n.planNewAdmissionSuccessToast[
            accommodationRequest.requestType === 'BED_MUTATION'
              ? 'accommodationMessage'
              : 'externalAccommodationRequestMessage'
          ],
        icon: <Emoji name="okHand" size={24} />,
      });
      onClose();
    } catch (error) {
      setError(true);
    }
  };

  if (visitId && !patient) {
    return null;
  }

  return (
    <SendAccommodationRequestForm
      unitId={unitId}
      onCancel={onClose}
      onSubmit={handleSubmit}
      loading={loading}
      initialPatient={patient}
      comment={comment}
      specificities={specificities}
      error={
        error ? (
          <Alert
            title={i18n.dialogError.title}
            severity="error"
            content={
              <Stack spacing={4}>
                <Typography variant="body1" color="secondary">
                  {i18n.dialogError['messageMovement']}
                </Typography>
                <Typography variant="body1" color="secondary">
                  {i18n.dialogError.contactUsFirstPart}
                  <Link
                    component="button"
                    color="secondary"
                    sx={{ verticalAlign: 'baseline', fontWeight: 600 }}
                    onClick={openSupport}
                  >
                    {i18n.dialogError.contactUsLink}
                  </Link>
                  {i18n.dialogError.contactUsLastPart}
                </Typography>
              </Stack>
            }
            icon={<Emoji name="faceWithColdSweat" size={24} />}
          />
        ) : undefined
      }
    />
  );
};

const usePatient = (id?: string): PatientSearchResult | undefined => {
  const { results } = useParseQuery(new Api.Query(Patient).equalTo('objectId', id).limit(1), { enabled: !!id });

  const patientResult = results?.[0];

  if (patientResult) {
    return {
      id: patientResult.id,
      name: formatName(
        patientResult.firstName,
        patientResult.lastName,
        patientResult.legalName,
        patientResult.legalFirstName
      ),
      birthdate: patientResult.birthdate,
      gender: patientResult.gender,
      ipp: patientResult.ipp,
      pan: patientResult.pan,
    };
  }

  return undefined;
};

export default SendAccommodationRequestDialog;
