import { Divider, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

export type InOutFlowProps = {
  admission?: number;
  discharge?: number;
};

const InOutFlow: React.FC<InOutFlowProps> = ({ admission, discharge }) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={2}
      divider={<Divider orientation="vertical" flexItem style={{ height: 20 }} />}
    >
      <DataText admission={admission} discharge={discharge} data="ADMISSION">
        {admission !== undefined ? admission : ' - '}
      </DataText>
      <DataText admission={admission} discharge={discharge} data="DISCHARGE">
        {discharge !== undefined ? discharge : ' - '}
      </DataText>
    </Stack>
  );
};

type Data = 'ADMISSION' | 'DISCHARGE';

const DataText = styled(Typography)<InOutFlowProps & { data: Data }>`
  font-weight: ${({ admission, discharge, data }) =>
    !!admission && !!discharge && getFontWeight(admission, discharge, data)};
  min-width: 16px;
  text-align: center;
`;

const getFontWeight = (admission: number, discharge: number, data: Data) => {
  if (data === 'ADMISSION') {
    return admission > discharge ? 'bold' : 'normal';
  } else {
    return admission < discharge ? 'bold' : 'normal';
  }
};

export default InOutFlow;
