import { Box, Skeleton, Stack } from '@mui/material';

const BedPlacementSkeleton = () => {
  return (
    <Stack spacing={3}>
      {[1, 2, 3].map((i) => (
        <Stack direction="row" spacing={4} key={i} alignItems="center">
          <Stack alignItems="center" direction="row" spacing={2} paddingLeft={1}>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rectangular" width={20} height={8} sx={{ borderRadius: 0.5 }} />
          </Stack>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            sx={{ borderRadius: 1, padding: 1, '&.MuiSkeleton-root>*': { visibility: 'visible' } }}
          >
            <Box borderRadius={0.5} bgcolor="white" height="100%" width="50%" />
          </Skeleton>
        </Stack>
      ))}
    </Stack>
  );
};

export default BedPlacementSkeleton;
