import { Components } from '@mui/material';

const MuiAvatar: Components['MuiAvatar'] = {
  styleOverrides: {
    root: {
      color: 'white',
    },
  },
};

export default MuiAvatar;
