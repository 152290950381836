import { Components } from '@mui/material';
import color from '../color';

const MuiMenu: Components['MuiMenu'] = {
  defaultProps: {
    disableAutoFocusItem: true,
  },
  styleOverrides: {
    root: {
      marginTop: 8,
    },
    paper: {
      border: `1px solid ${color.grey[5]}`,
    },
  },
};

export default MuiMenu;
