import { Components } from '@mui/material';
import { color } from '..';

const MuiTablePagination: Components['MuiTablePagination'] = {
  defaultProps: {
    SelectProps: {
      variant: 'filled',
    },
  },
  styleOverrides: {
    spacer: {
      flex: 1,
    },
    selectLabel: {
      color: color.grey[60],
    },
    select: {
      alignItems: 'center',
    },
  },
};

export default MuiTablePagination;
