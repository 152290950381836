import { AnomalyType } from '@ambuliz/sabri-core';
import { Stack, Tooltip, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { BedNameTooltip } from 'kurt/pages/Unit/BedName/BedName';

type BedAnomaliesTooltipProps = {
  bedName?: string;
  anomalies: { type: AnomalyType; accommodation?: { id: string } }[];
  children: ReactElement;
};

const getOverlappingAnomaliesCount = (anomalies: { type: AnomalyType; accommodation?: { id: string } }[]) => {
  const overlappingAnomalies = anomalies.filter((anomaly) => anomaly.type === 'OVERLAPPING_STEPS');
  const overlappedAccommodations = overlappingAnomalies.map((anomaly) => anomaly.accommodation?.id);

  return new Set(overlappedAccommodations).size;
};

const BedAnomaliesTooltip = ({ bedName, anomalies, children }: BedAnomaliesTooltipProps) => {
  const anomaliesByType = {
    outdatedStart: anomalies.filter((anomaly) => anomaly.type === 'OUTDATED_START').length,
    outdatedPrevisionalEnd: anomalies.filter((anomaly) => anomaly.type === 'OUTDATED_PREVISIONAL_END').length,
    outdatedValidatedEnd: anomalies.filter((anomaly) => anomaly.type === 'OUTDATED_VALIDATED_END').length,
    overlapping: getOverlappingAnomaliesCount(anomalies),
    anomaliesType: new Set(anomalies.map((anomaly) => anomaly.type)).size,
  };

  return (
    <Tooltip
      title={
        <Stack spacing={2}>
          {bedName && <BedNameTooltip name={bedName} />}
          <Stack spacing={2}>
            {anomaliesByType.outdatedStart > 0 && (
              <AnomalyLine
                showDot={anomaliesByType.anomaliesType > 1}
                count={anomaliesByType.outdatedStart}
                label={
                  anomaliesByType.outdatedStart > 1
                    ? i18n.accommodationWarning.startDateOutdated.plural
                    : i18n.accommodationWarning.startDateOutdated.singular
                }
              />
            )}
            {anomaliesByType.outdatedPrevisionalEnd > 0 && (
              <AnomalyLine
                showDot={anomaliesByType.anomaliesType > 1}
                count={anomaliesByType.outdatedPrevisionalEnd}
                label={
                  anomaliesByType.outdatedPrevisionalEnd > 1
                    ? i18n.accommodationWarning.endDateOutdated.previsional.plural
                    : i18n.accommodationWarning.endDateOutdated.previsional.singular
                }
              />
            )}
            {anomaliesByType.outdatedValidatedEnd > 0 && (
              <AnomalyLine
                showDot={anomaliesByType.anomaliesType > 1}
                count={anomaliesByType.outdatedValidatedEnd}
                label={
                  anomaliesByType.outdatedValidatedEnd > 1
                    ? i18n.accommodationWarning.endDateOutdated.validated.plural
                    : i18n.accommodationWarning.endDateOutdated.validated.singular
                }
              />
            )}
            {anomaliesByType.overlapping > 0 && (
              <AnomalyLine
                showDot={anomaliesByType.anomaliesType > 1}
                count={anomaliesByType.overlapping}
                label={i18n.accommodationWarning.bedOverlapped}
              />
            )}
          </Stack>
        </Stack>
      }
      sx={{ cursor: 'pointer' }}
      placement="top"
    >
      {children}
    </Tooltip>
  );
};

const AnomalyLine = ({ showDot, count, label }: { showDot: boolean; count: number; label: ReactNode }) => {
  if (count === 0) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {showDot && <Dot />}
      <Typography variant="body2">
        <Typography variant="body2" component="span" fontWeight={700}>
          {count + ' '}
        </Typography>
        {label}
      </Typography>
    </Stack>
  );
};

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: white;
`;

export default BedAnomaliesTooltip;
