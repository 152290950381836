import { Divider, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import CancelCircleIcon from 'common/components/Icons/CancelCircle';
import CheckCircleIcon from 'common/components/Icons/CheckCircle';
import ClockIcon from 'common/components/Icons/Clock';
import ExitIcon from 'common/components/Icons/Exit';
import ListIcon from 'common/components/Icons/List';
import MoreVerticalIcon from 'common/components/Icons/MoreVertical';
import PathIcon from 'common/components/Icons/Path';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useRef, useState } from 'react';
import { HospitalizationRequestAction } from './HospitalizationsTableRow';
import { HospitalizationRequestStatus } from './useHospitalizationRequests';

type HospitalizationRequestActionMenuProps = {
  onActionClick: (action: HospitalizationRequestAction) => void;
  status: HospitalizationRequestStatus;
};

export const HospitalizationRequestActionMenu = ({ onActionClick, status }: HospitalizationRequestActionMenuProps) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen((open) => !open);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, action: HospitalizationRequestAction) => {
    event.stopPropagation();
    onActionClick(action);
    setOpen(false);
  };

  const menuItems = [
    <MenuItem key="delete_request" onClick={(event) => handleMenuItemClick(event, 'delete_request')}>
      <ListItemIcon sx={{ color: palette.error.main }}>
        <CancelCircleIcon />
      </ListItemIcon>
      <ListItemText sx={{ color: palette.error.main }}>
        {i18n.visitActions.CANCEL_MUTATION_REQUEST.action.label}
      </ListItemText>
    </MenuItem>,
  ];

  if (status === 'rejected') {
    menuItems.unshift(
      <MenuItem key="create_new_request" onClick={(event) => handleMenuItemClick(event, 'create_new_request')}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText>{i18n.visitActions.NEW_INTERNAL_MUTATION_REQUEST.action.label}</ListItemText>
      </MenuItem>
    );
  } else if (status === 'to_address') {
    menuItems.unshift(
      <MenuItem key="address_request" onClick={(event) => handleMenuItemClick(event, 'address_request')}>
        <ListItemIcon>
          <PathIcon />
        </ListItemIcon>
        <ListItemText>{i18n.visitActions.ADDRESS_MUTATION_REQUEST.action.label}</ListItemText>
      </MenuItem>
    );
  } else {
    menuItems.unshift(
      <Divider key="divider" />,
      <MenuItem key="reject_request" onClick={(event) => handleMenuItemClick(event, 'reject_request')}>
        <ListItemIcon>
          <CancelCircleIcon />
        </ListItemIcon>
        <ListItemText>{i18n.visitActions.REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN.action.label}</ListItemText>
      </MenuItem>
    );
  }

  if (status === 'pending') {
    menuItems.unshift(
      <MenuItem key="accept_request" onClick={(event) => handleMenuItemClick(event, 'accept_request')}>
        <ListItemIcon>
          <CheckCircleIcon />
        </ListItemIcon>
        <ListItemText>{i18n.visitActions.ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN.action.label}</ListItemText>
      </MenuItem>
    );
  }
  if (status === 'accepted') {
    menuItems.unshift(
      <MenuItem key="update_request_date" onClick={(event) => handleMenuItemClick(event, 'update_request_date')}>
        <ListItemIcon>
          <ClockIcon />
        </ListItemIcon>
        <ListItemText>{i18n.visitActions.UPDATE_MUTATION_DATE.action.label}</ListItemText>
      </MenuItem>
    );
  }
  if (status === 'admitted') {
    menuItems.unshift(
      <MenuItem key="discharge_patient" onClick={(event) => handleMenuItemClick(event, 'discharge_patient')}>
        <ListItemIcon>
          <ExitIcon />
        </ListItemIcon>
        <ListItemText>{i18n.visitActions.DISCHARGE_PATIENT.action.label}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <>
      <IconButton ref={buttonRef} onClick={toggleOpen} color="secondary">
        <MoreVerticalIcon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={buttonRef.current}
        onClose={toggleOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
};
