import { compose, prop, sortBy as sortByRamda, toLower, toUpper } from 'ramda';

type SortOrder = 'asc' | 'desc';

const sortListBy: <T>(array: T[], by: string, order?: SortOrder) => T[] = (array, by, order = 'asc') => {
  const sort = sortByRamda<any>(compose(order === 'asc' ? toLower : toUpper, prop(by)));
  return sort(array);
};

export default sortListBy;
