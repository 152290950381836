import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { PatientSearchResult } from 'common/components/PatientAutocomplete';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { isAfter, isValid } from 'date-fns';
import React, { useState } from 'react';
import { useCreatePatient } from '.';
import Emoji from '../Emoji';
import { Cross } from '../Icons';

type CreatePatientFormProps = {
  onPatientCreated: (value: PatientSearchResult | null) => void;
  onCancel: () => void;
  open: boolean;
};

const CreatePatientForm: React.FC<CreatePatientFormProps> = ({ onPatientCreated, onCancel, open }) => {
  const [patient, setPatient] = useState<FormValue>(initialPatient);
  const [errors, setErrors] = useState(initialErrors);
  const { loading, create } = useCreatePatient();

  const submit = async () => {
    const { hasErrors, errors } = validateForm(patient);
    if (hasErrors) {
      setErrors(errors);
    } else {
      const createdPatient = await create({ ...patient, birthdate: patient.birthdate as Date });
      if (createdPatient) {
        toast.success({
          ...i18n.createPatientForm.successMessage,
          icon: <Emoji size={24} name="victoryHand" />,
        });
        onPatientCreated(createdPatient);
        setPatient(initialPatient);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatient({ ...patient, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };

  const handleDateChange = (date: Date | null) => {
    setPatient({ ...patient, birthdate: date });
    setErrors({ ...errors, birthdate: false });
  };

  const handleClose = () => {
    onCancel();
    setPatient(initialPatient);
    setErrors(initialErrors);
  };

  return (
    <Dialog
      open={open}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          submit();
        }
      }}
      onClose={handleClose}
      maxWidth="md"
    >
      <CloseButton edge="end" onClick={handleClose} color="secondary">
        <Cross />
      </CloseButton>
      <DialogTitle>{i18n.createPatientForm.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{i18n.createPatientForm.helperText}</DialogContentText>
        <Box paddingY={2}>
          <Grid container spacing={4}>
            <Grid item>
              <RadioGroup name="gender" value={patient.gender} onChange={handleChange} row>
                <FormControlLabel control={<Radio color="primary" />} label={i18n.patientGenders.MALE} value="MALE" />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={i18n.patientGenders.FEMALE}
                  value="FEMALE"
                />
              </RadioGroup>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={patient.lastName}
                  name="lastName"
                  onChange={handleChange}
                  label={i18n.lastName}
                  error={errors.lastName}
                  helperText={errors.lastName && i18n.formErrors.required}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={patient.firstName}
                  name="firstName"
                  onChange={handleChange}
                  label={i18n.firstName}
                  error={errors.firstName}
                  helperText={errors.firstName && i18n.formErrors.required}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  value={patient.birthdate}
                  slotProps={{
                    textField: {
                      error: errors.birthdate,
                      helperText: errors.birthdate && i18n.formErrors.wrongDateFormat,
                      fullWidth: true,
                    },
                  }}
                  label={i18n.createPatientForm.birthdate}
                  onChange={handleDateChange}
                  openTo="year"
                  disableFuture
                  format="dd/MM/yyyy"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={patient.pan}
                  name="pan"
                  onChange={handleChange}
                  label={i18n.createPatientForm.pan}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextField
                  value={patient.ipp}
                  name="ipp"
                  onChange={handleChange}
                  label={i18n.createPatientForm.ipp}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          {i18n.cancel}
        </Button>
        <LoadingButton onClick={submit} loading={loading}>
          {i18n.createPatientForm.buttonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

type FormValue = {
  gender: 'FEMALE' | 'MALE';
  firstName: string;
  lastName: string;
  birthdate: Date | null;
  ipp: string;
  pan: string;
};

const validateForm = ({ firstName, lastName, birthdate }: FormValue) => {
  const errors = {
    firstName: !firstName.trim(),
    lastName: !lastName.trim(),
    birthdate:
      birthdate === null ||
      !isValid(birthdate) ||
      // If year is < 1000, user didn't fill the year with 4 digits
      birthdate.getFullYear() < 1000 ||
      isAfter(birthdate, new Date()),
  };
  return {
    errors,
    hasErrors: errors.firstName || errors.lastName || errors.birthdate,
  };
};

const initialPatient: FormValue = { gender: 'MALE', firstName: '', lastName: '', birthdate: null, ipp: '', pan: '' };
const initialErrors = { firstName: false, lastName: false, birthdate: false };

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 32px;
`;

export default CreatePatientForm;
