import { Box, Grid, Stack, Typography } from '@mui/material';
import { Ambulance } from 'common/components/Images';
import { i18n } from 'common/locale';
import { EmptyContent } from 'core/layout';
import { useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AdmissionCard, BedCard, DischargeCard } from './Cards';
import SkeletonRow from './Cards/SkeletonRow';
import PlacePatientButton from './PlacePatient';
import useBedMovements from './useBedMovements';

type MovementSheetsProps = {
  unitIds: string[];
  date: Date;
  isReadOnly?: boolean;
};

const MovementSheets = ({ unitIds, date, isReadOnly = false }: MovementSheetsProps) => {
  const { bedMovements, loading } = useBedMovements(date, unitIds);

  const { ids } = useParams();
  const navigate = useRef(useNavigate());

  const navigateTo = useCallback((to: string) => {
    navigate.current(to, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  return (
    <Box justifyContent="center" alignContent="center" height="100%">
      <Stack marginX="auto" spacing={4} paddingX={10} paddingTop={8} paddingBottom={6} height="100%">
        <Grid container columnGap={4} direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
          <Grid item flex={1} minWidth={280} maxWidth={720}>
            <Typography variant="h3">{i18n.discharges}</Typography>
          </Grid>
          <Grid item width={80}></Grid>
          <Grid item flex={1} minWidth={280} maxWidth={720}>
            <Typography variant="h3">{i18n.admissions}</Typography>
          </Grid>
        </Grid>
        {loading ? (
          <>
            {bedMovements.map((_, index) => (
              <SkeletonRow key={index} />
            ))}
          </>
        ) : bedMovements.filter(({ discharge, admission }) => admission || discharge).length === 0 ? (
          <EmptyContent Image={Ambulance} {...i18n.movementSheetsEmptyContent} />
        ) : (
          <Stack spacing={4} justifyContent="center" alignContent="center">
            {bedMovements.map(
              ({ bed, discharge, admission }) =>
                (admission || discharge) && (
                  <Box key={bed.id}>
                    <Grid
                      container
                      columnGap={4}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item flex={1} minWidth={280} maxWidth={720}>
                        {discharge && (
                          <DischargeCard
                            {...discharge}
                            onClick={() => navigateTo(`../${ids}/discharge/accommodation/${discharge.id}`)}
                          />
                        )}
                      </Grid>
                      <Grid item justifyContent="center">
                        <Box width={80}>
                          <BedCard name={bed.name} />
                        </Box>
                      </Grid>
                      <Grid item flex={1} minWidth={280} maxWidth={720}>
                        {admission ? (
                          <AdmissionCard
                            {...admission}
                            onClick={() => navigateTo(`../${ids}/admission/accommodation/${admission.id}`)}
                          />
                        ) : !isReadOnly ? (
                          <PlacePatientButton bedId={bed.id} date={date} />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Box>
                )
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default MovementSheets;
