import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowCircle: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4711 3.55806C10.7152 3.31398 11.1109 3.31398 11.355 3.55806L15.355 7.55806C15.4149 7.61798 15.4601 7.68704 15.4906 7.76076C15.5205 7.83278 15.5373 7.91159 15.538 7.99423L15.5381 8L15.538 8.00577C15.5373 8.08841 15.5205 8.16722 15.4906 8.23924C15.4601 8.31296 15.4149 8.38202 15.355 8.44194L11.355 12.4419C11.1109 12.686 10.7152 12.686 10.4711 12.4419C10.2271 12.1979 10.2271 11.8021 10.4711 11.5581L13.4042 8.625H6.99736C6.65218 8.625 6.37236 8.34518 6.37236 8C6.37236 7.65482 6.65218 7.375 6.99736 7.375H13.4042L10.4711 4.44194C10.2271 4.19786 10.2271 3.80214 10.4711 3.55806ZM1.71001 8C1.71001 7.37868 2.21369 6.875 2.83501 6.875C3.45633 6.875 3.96001 7.37868 3.96001 8C3.96001 8.62132 3.45633 9.125 2.83501 9.125C2.21369 9.125 1.71001 8.62132 1.71001 8ZM2.83501 5.625C1.52333 5.625 0.460007 6.68832 0.460007 8C0.460007 9.31168 1.52333 10.375 2.83501 10.375C4.14668 10.375 5.21001 9.31168 5.21001 8C5.21001 6.68832 4.14668 5.625 2.83501 5.625Z"
      />
    </SvgIcon>
  );
};

export default ArrowCircle;
