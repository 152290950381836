import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { color } from 'common/theme';
import React from 'react';

type HighlightedSearchResultProps = {
  search: string;
  text: string;
  textTransform?: 'none' | 'uppercase' | 'capitalize';
};

// Retourne une chaine de caracteres avec les occurences de 'search' surlignées
const HighlightedSearchResult: React.FC<HighlightedSearchResultProps> = ({ search, text }) => {
  const matches = match(text, search);
  const parts = parse(text, matches);

  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          style={{
            backgroundColor: part.highlight ? color.yellow[20] : undefined,
            borderRadius: part.highlight ? 2 : undefined,
          }}
        >
          {part.text}
        </span>
      ))}
    </>
  );
};

export default HighlightedSearchResult;
