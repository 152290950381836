import { Box, Card, CardActionArea, CardContent, Stack, Typography } from '@mui/material';
import { Plus } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { useState } from 'react';
import PlacePatientModal from './PlacePatientModal';

const PlacePatientButton = ({ bedId, date }: { bedId: string; date: Date }) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen(!open);

  return (
    <>
      <Card elevation={0}>
        <CardActionArea onClick={toggleModal}>
          <CardContent sx={{ bgcolor: color.blue[10], boxShadow: 'inset 0px 3px 2px rgba(20, 22, 28, 0.06)' }}>
            <Stack direction="row" spacing={3} alignItems="center" paddingX={2}>
              <Box
                bgcolor="white"
                width={20}
                height={20}
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Plus color="secondary" sx={{ fontSize: 12 }} />
              </Box>
              <Typography color="secondary">{i18n.placePatient}</Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      {open && (
        <PlacePatientModal open={open} onClose={toggleModal} onSuccess={toggleModal} bedId={bedId} date={date} />
      )}
    </>
  );
};

export default PlacePatientButton;
