import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const Male: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M9.76471 2V3.41176H11.5901L9.89459 5.10729C9.04369 4.46548 8.00699 4.1181 6.94118 4.11765C4.21647 4.11765 2 6.33412 2 9.05882C2 11.7835 4.21647 14 6.94118 14C9.66588 14 11.8824 11.7835 11.8824 9.05882C11.8824 7.95059 11.5111 6.93059 10.8927 6.10541L12.5882 4.40988V6.23529H14V2H9.76471ZM6.94118 12.5882C4.99506 12.5882 3.41176 11.0049 3.41176 9.05882C3.41176 7.11271 4.99506 5.52941 6.94118 5.52941C8.88729 5.52941 10.4706 7.11271 10.4706 9.05882C10.4706 11.0049 8.88729 12.5882 6.94118 12.5882Z" />
    </SvgIcon>
  );
};

export default Male;
