import { Box, Card, Skeleton, Stack } from '@mui/material';
import { color } from 'common/theme';

const SkeletonInfos = () => {
  return (
    <Card
      sx={{
        padding: 4,
      }}
    >
      <Stack spacing={6}>
        <Box width={40} height={40} bgcolor={color.grey[10]} borderRadius={1} />

        <Skeleton
          variant="rounded"
          width={120}
          height={12}
          sx={{
            marginTop: '28px !important',
          }}
        ></Skeleton>
        <Stack direction="row" gap={2}>
          <Skeleton variant="rounded" width={60} height={20} />
          <Skeleton variant="rounded" width={120} height={20} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton variant="rounded" width={50} height={8} />
          <Skeleton variant="rounded" width={90} height={12} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton variant="rounded" width={30} height={8} />
          <Skeleton variant="rounded" width={50} height={12} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default SkeletonInfos;
