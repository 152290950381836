import { Components } from '@mui/material';

const MuiRadio: Components['MuiRadio'] = {
  variants: [
    {
      props: {
        size: 'small',
      },
      style: {
        padding: 4,
        '& .MuiSvgIcon-root': {
          width: 18,
          height: 18,
        },
      },
    },
  ],
};

export default MuiRadio;
