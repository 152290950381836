import { SvgIcon, SvgIconProps } from '@mui/material';

const Check: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 12" {...props}>
      <path d="M4.53738 8.31108L1.09666 5.12607C0.976184 5.01454 0.812785 4.95189 0.642407 4.95189C0.47203 4.95189 0.308631 5.01454 0.188156 5.12607C0.0676811 5.23759 -1.06574e-06 5.38884 -1.06574e-06 5.54656C-1.06574e-06 5.62465 0.0166155 5.70198 0.0488995 5.77413C0.0811836 5.84628 0.128503 5.91183 0.188156 5.96705L4.08635 9.57554C4.33764 9.80815 4.74356 9.80815 4.99485 9.57554L11.8118 3.26516C11.9323 3.15364 12 3.00238 12 2.84467C12 2.68695 11.9323 2.5357 11.8118 2.42417C11.6914 2.31265 11.528 2.25 11.3576 2.25C11.1872 2.25 11.0238 2.31265 10.9033 2.42417L4.53738 8.31108Z" />
    </SvgIcon>
  );
};

export default Check;
