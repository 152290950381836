import Api, { Cloud, Patient, PatientGender } from '@ambuliz/sabri-core';
import { toast } from 'common/toast';
import { useState } from 'react';

type CreatePatientParams = {
  gender: PatientGender;
  firstName: string;
  lastName: string;
  birthdate: Date;
  ipp?: string;
  pan?: string;
};

const useCreatePatient = () => {
  const [loading, setLoading] = useState(false);

  const create = async (value: CreatePatientParams) => {
    setLoading(true);
    try {
      const createPatientResponse = await Cloud.createPartialPatient(value);
      const patientCreated = await new Api.Query(Patient).get(createPatientResponse.id);
      return {
        id: patientCreated.id,
        name: `${patientCreated.lastName.toUpperCase()} ${patientCreated.firstName}`,
        birthdate: patientCreated.birthdate,
        ipp: patientCreated.ipp,
        pan: patientCreated.pan,
        gender: patientCreated.gender,
      };
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, create };
};

export default useCreatePatient;
