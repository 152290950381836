import { Button, ButtonProps, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { sizeStringToNumber } from '../../const/materialUi';

export type ButtonLoaderProps = {
  isLoading: boolean;
  colorLoader?: string;
};

export const ButtonLoader: React.FC<ButtonLoaderProps & ButtonProps> = ({
  isLoading,
  colorLoader,
  children,
  ...props
}) => {
  const sizeLoader = sizeStringToNumber[props.size || 'medium'];
  const classes = useClasses({ backgroundColor: props.color, colorLoader });

  return (
    <Button {...props} disabled={isLoading || props.disabled}>
      {children}
      {isLoading && (
        <div className={classes.loaderContent}>
          <CircularProgress size={sizeLoader} className={classes.loader} />
        </div>
      )}
    </Button>
  );
};

type ButtonLoaderStyleProps = {
  backgroundColor?: 'inherit' | 'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning';
  colorLoader?: string;
};

const useClasses = makeStyles(({ palette }) => ({
  loaderContent: ({ backgroundColor }: ButtonLoaderStyleProps) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 15,
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    backgroundColor:
      backgroundColor === 'primary' || backgroundColor === 'secondary' ? palette[backgroundColor].main : undefined,
  }),
  loader: ({ colorLoader }: ButtonLoaderStyleProps) => ({
    color: colorLoader || 'white',
  }),
}));
