import { Box, Stack } from '@mui/material';
import { color } from 'common/theme';

const PlaceholderSlot = () => {
  return (
    <Box width="50%">
      <Stack
        padding="4px 12px"
        spacing={3}
        direction="row"
        alignItems="center"
        border={`1px dashed ${color.grey[20]}`}
        borderRadius={0.5}
        width="100%"
        borderRight={`4px solid ${color.grey[10]}`}
      >
        <Box width={22} height={22} borderRadius="50%" bgcolor={color.grey[10]}></Box>
        <Box height={8} borderRadius={0.5} bgcolor={color.grey[10]} flex={1}></Box>
      </Stack>
    </Box>
  );
};

export default PlaceholderSlot;
