import { Components } from '@mui/material';

const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      lineHeight: '18px',
    },
    sizeSmall: {
      lineHeight: '16px',
    },
    input: {
      height: 'unset',
    },
    adornedStart: {
      '&.MuiOutlinedInput-root': {
        paddingLeft: 12,
        input: {
          paddingLeft: 8,
        },
        '&.MuiInputBase-sizeSmall': {
          paddingLeft: 8,
        },
      },
      '&.MuiFilledInput-root': {
        paddingLeft: 12,
        input: {
          paddingLeft: 8,
        },
        '&.MuiInputBase-sizeSmall': {
          paddingLeft: 8,
          input: {
            paddingLeft: 8,
          },
        },
      },
    },
    adornedEnd: {
      '&.MuiOutlinedInput-root': {
        paddingRight: 12,
        input: {
          paddingRight: 8,
        },
        '&.MuiInputBase-sizeSmall': {
          paddingRight: 8,
        },
      },
      '&.MuiFilledInput-root': {
        paddingRight: 12,
        input: {
          paddingRight: 8,
        },
        '&.MuiInputBase-sizeSmall': {
          paddingRight: 8,
        },
      },
    },
  },
};

export default MuiInputBase;
