import { RequestType } from '@ambuliz/sabri-core';
import { ConstructionOutlined } from '@mui/icons-material';
import { Emoji } from 'common/components';
import { EmojiType } from 'common/components/Emoji';
import Admission from 'common/components/Icons/Admission';
import Bed from 'common/components/Icons/Bed';
import CalendarIcon from 'common/components/Icons/Calendar';
import Cancel from 'common/components/Icons/Cancel';
import CheckCircle from 'common/components/Icons/CheckCircle';
import Clock from 'common/components/Icons/Clock';
import Edit from 'common/components/Icons/Edit';
import ExitIcon from 'common/components/Icons/Exit';
import List from 'common/components/Icons/List';
import Reset from 'common/components/Icons/Reset';
import RoadSign from 'common/components/Icons/RoadSign';
import TrashCanIcon from 'common/components/Icons/TrashCan';
import Unit from 'common/components/Icons/Unit';
import { color } from 'common/theme';
import { ReactElement } from 'react';

export type AccommodationAction =
  | 'SET_BED'
  | 'UPDATE_SPECIFICITIES'
  | 'UPDATE_DATES'
  | 'CANCEL_ADMISSION'
  | 'CANCEL_ACCOMMODATION'
  | 'UPDATE_BED'
  | 'UPDATE_UNIT'
  | 'CONFIRM_ADMISSION'
  | 'CANCEL_BED'
  | 'VALIDATE_DISCHARGE_DATE'
  | 'INVALIDATE_DISCHARGE_DATE'
  | 'UPDATE_PLANNED_DISCHARGE_DATE'
  | 'UPDATE_VALIDATED_DISCHARGE_DATE'
  | 'DISCHARGE_PATIENT'
  | 'CANCEL_DISCHARGE'
  | 'ADDRESS_MUTATION_REQUEST'
  | 'EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST'
  | 'ACCEPT_INTERNAL_MUTATION_REQUEST'
  | 'REJECT_INTERNAL_MUTATION_REQUEST'
  | 'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN'
  | 'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN'
  | 'NEW_INTERNAL_MUTATION_REQUEST'
  | 'REQUEST_MUTATION'
  | 'CANCEL_MUTATION_REQUEST'
  | 'SET_MUTATION_BED'
  | 'CANCEL_MUTATION_BED'
  | 'UPDATE_MUTATION_DATE'
  | 'REOPEN_BED'
  | 'CANCEL_CLOSED_BED'
  | 'ADD_ACCOMMODATION'
  | 'CLOSE_BED'
  | 'VIA_TRAJECTOIRE_REDIRECT';

const getColumnActions = ({
  flow,
  type,
  status,
  requestType,
  isDischarged,
}: {
  flow: 'admission' | 'discharge';
  type: 'mutation' | 'default';
  status: 'planned' | 'validated' | 'completed' | 'requested' | 'blocked';
  requestType?: RequestType;
  isDischarged?: boolean;
}) => {
  if (requestType === 'DIRECT_ADMISSION') {
    return columnActions[flow]['default'][status];
  }
  if (!isDischarged && flow === 'discharge' && type === 'default' && status === 'completed') {
    return ['CANCEL_ACCOMMODATION', 'VIA_TRAJECTOIRE_REDIRECT'] as AccommodationAction[];
  }
  return columnActions[flow][type][status];
};

const columnActions: Record<
  'admission' | 'discharge',
  Record<
    'mutation' | 'default',
    Record<'planned' | 'validated' | 'completed' | 'requested' | 'blocked', AccommodationAction[]>
  >
> = {
  admission: {
    mutation: {
      planned: [
        'SET_MUTATION_BED',
        'UPDATE_SPECIFICITIES',
        'UPDATE_DATES',
        'VIA_TRAJECTOIRE_REDIRECT',
        'REJECT_INTERNAL_MUTATION_REQUEST',
      ],
      validated: [
        'CONFIRM_ADMISSION',
        'UPDATE_BED',
        'CANCEL_MUTATION_BED',
        'VIA_TRAJECTOIRE_REDIRECT',
        'REJECT_INTERNAL_MUTATION_REQUEST',
      ],
      completed: [],
      requested: ['ACCEPT_INTERNAL_MUTATION_REQUEST', 'REJECT_INTERNAL_MUTATION_REQUEST', 'VIA_TRAJECTOIRE_REDIRECT'],
      blocked: [],
    },
    default: {
      planned: [
        'SET_BED',
        'UPDATE_UNIT',
        'UPDATE_SPECIFICITIES',
        'UPDATE_DATES',
        'CANCEL_ADMISSION',
        'VIA_TRAJECTOIRE_REDIRECT',
      ],
      validated: ['CONFIRM_ADMISSION', 'UPDATE_BED', 'CANCEL_BED', 'CANCEL_ADMISSION', 'VIA_TRAJECTOIRE_REDIRECT'],
      completed: [],
      requested: ['ACCEPT_INTERNAL_MUTATION_REQUEST', 'CANCEL_MUTATION_REQUEST'],
      blocked: [],
    },
  },
  discharge: {
    mutation: {
      planned: [
        'UPDATE_MUTATION_DATE',
        'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN',
        'VIA_TRAJECTOIRE_REDIRECT',
        'CANCEL_MUTATION_REQUEST',
      ],
      validated: [
        'DISCHARGE_PATIENT',
        'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN',
        'VIA_TRAJECTOIRE_REDIRECT',
        'CANCEL_MUTATION_REQUEST',
      ],
      completed: [],
      blocked: ['NEW_INTERNAL_MUTATION_REQUEST', 'VIA_TRAJECTOIRE_REDIRECT', 'CANCEL_MUTATION_REQUEST'],
      requested: [
        'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN',
        'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN',
        'VIA_TRAJECTOIRE_REDIRECT',
        'CANCEL_MUTATION_REQUEST',
      ],
    },
    default: {
      planned: [
        'VALIDATE_DISCHARGE_DATE',
        'UPDATE_PLANNED_DISCHARGE_DATE',
        'DISCHARGE_PATIENT',
        'VIA_TRAJECTOIRE_REDIRECT',
        'CANCEL_DISCHARGE',
      ],
      validated: [
        'DISCHARGE_PATIENT',
        'UPDATE_VALIDATED_DISCHARGE_DATE',
        'INVALIDATE_DISCHARGE_DATE',
        'VIA_TRAJECTOIRE_REDIRECT',
        'CANCEL_DISCHARGE',
      ],
      completed: ['CANCEL_ACCOMMODATION'],
      blocked: ['NEW_INTERNAL_MUTATION_REQUEST', 'VIA_TRAJECTOIRE_REDIRECT', 'CANCEL_MUTATION_REQUEST'],
      requested: [
        'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN',
        'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN',
        'VIA_TRAJECTOIRE_REDIRECT',
        'CANCEL_MUTATION_REQUEST',
      ],
    },
  },
};

export const actionEmoji: Record<AccommodationAction, EmojiType> = {
  SET_BED: 'bed',
  UPDATE_SPECIFICITIES: 'clipboard',
  UPDATE_DATES: 'spiralCalendar',
  CONFIRM_ADMISSION: 'bellhopBell',
  UPDATE_BED: 'bed',
  UPDATE_UNIT: 'unit',
  CANCEL_BED: 'bedDeleted',
  CANCEL_ADMISSION: 'noEntry',
  VALIDATE_DISCHARGE_DATE: 'calendar',
  INVALIDATE_DISCHARGE_DATE: 'hourglassNotDone',
  UPDATE_PLANNED_DISCHARGE_DATE: 'spiralCalendar',
  UPDATE_VALIDATED_DISCHARGE_DATE: 'spiralCalendar',
  DISCHARGE_PATIENT: 'chequeredFlag',
  CANCEL_DISCHARGE: 'noEntry',
  ADDRESS_MUTATION_REQUEST: 'pencil',
  EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST: 'pencil',
  ACCEPT_INTERNAL_MUTATION_REQUEST: 'handshake',
  REJECT_INTERNAL_MUTATION_REQUEST: 'womanGesturingNo',
  ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: 'handshake',
  REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: 'womanGesturingNo',
  NEW_INTERNAL_MUTATION_REQUEST: 'pencil',
  CANCEL_MUTATION_REQUEST: 'noEntry',
  CANCEL_MUTATION_BED: 'rightCurvingLeft',
  SET_MUTATION_BED: 'bed',
  UPDATE_MUTATION_DATE: 'clock',
  REQUEST_MUTATION: 'walking',
  CANCEL_ACCOMMODATION: 'noEntry',
  REOPEN_BED: 'whiteHeavyCheckMark',
  CANCEL_CLOSED_BED: 'noEntry',
  CLOSE_BED: 'constructionOutlined',
  ADD_ACCOMMODATION: 'calendar',
  VIA_TRAJECTOIRE_REDIRECT: 'roadSign',
};

export const actionsIcons: Record<AccommodationAction, ReactElement> = {
  SET_BED: <Emoji name="bed" size="medium" shape="square" bgColor={color.blue[5]} />,
  UPDATE_SPECIFICITIES: <Emoji name="clipboard" size="medium" shape="square" bgColor={color.blue[5]} />,
  UPDATE_DATES: <Emoji name="spiralCalendar" size="medium" shape="square" bgColor={color.blue[5]} />,
  CANCEL_ACCOMMODATION: <Emoji name="noEntry" size="medium" shape="square" bgColor={color.blue[5]} />,
  CONFIRM_ADMISSION: <Emoji name="bellhopBell" size="medium" shape="square" bgColor={color.blue[5]} />,
  UPDATE_BED: <Emoji name="bed" size="medium" shape="square" bgColor={color.blue[5]} />,
  UPDATE_UNIT: <Emoji name="unit" size="medium" shape="square" bgColor={color.blue[5]} />,
  CANCEL_BED: <Emoji name="bedDeleted" size="medium" shape="square" bgColor={color.blue[5]} />,
  CANCEL_ADMISSION: <Emoji name="noEntry" size="medium" shape="square" bgColor={color.blue[5]} />,
  VALIDATE_DISCHARGE_DATE: <Emoji name="calendar" size="medium" shape="square" bgColor={color.blue[5]} />,
  INVALIDATE_DISCHARGE_DATE: <Emoji name="hourglassNotDone" size="medium" shape="square" bgColor={color.blue[5]} />,
  UPDATE_PLANNED_DISCHARGE_DATE: <Emoji name="spiralCalendar" size="medium" shape="square" bgColor={color.blue[5]} />,
  UPDATE_VALIDATED_DISCHARGE_DATE: <Emoji name="spiralCalendar" size="medium" shape="square" bgColor={color.blue[5]} />,
  DISCHARGE_PATIENT: <Emoji name="ambulance" size="medium" shape="square" bgColor={color.blue[5]} />,
  CANCEL_DISCHARGE: <Emoji name="noEntry" size="medium" shape="square" bgColor={color.blue[5]} />,
  ADDRESS_MUTATION_REQUEST: <Emoji name="pencil" size="medium" shape="square" bgColor={color.blue[5]} />,
  EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST: (
    <Emoji name="pencil" size="medium" shape="square" bgColor={color.blue[5]} />
  ),
  ACCEPT_INTERNAL_MUTATION_REQUEST: <Emoji name="handshake" size="medium" shape="square" bgColor={color.blue[5]} />,
  REJECT_INTERNAL_MUTATION_REQUEST: (
    <Emoji name="womanGesturingNo" size="medium" shape="square" bgColor={color.blue[5]} />
  ),
  ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: (
    <Emoji name="handshake" size="medium" shape="square" bgColor={color.blue[5]} />
  ),
  REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: (
    <Emoji name="womanGesturingNo" size="medium" shape="square" bgColor={color.blue[5]} />
  ),
  NEW_INTERNAL_MUTATION_REQUEST: <Emoji name="pencil" size="medium" shape="square" bgColor={color.blue[5]} />,
  CANCEL_MUTATION_REQUEST: <Emoji name="noEntry" size="medium" shape="square" bgColor={color.blue[5]} />,
  CANCEL_MUTATION_BED: <Emoji name="rightCurvingLeft" size="medium" shape="square" bgColor={color.blue[5]} />,
  SET_MUTATION_BED: <Emoji name="bed" size="medium" shape="square" bgColor={color.blue[5]} />,
  UPDATE_MUTATION_DATE: <Emoji name="clock" size="medium" shape="square" bgColor={color.blue[5]} />,
  REQUEST_MUTATION: <Emoji name="walking" size="medium" shape="square" bgColor={color.blue[5]} />,
  REOPEN_BED: <Emoji name="whiteHeavyCheckMark" size="medium" shape="square" bgColor={color.blue[5]} />,
  CANCEL_CLOSED_BED: <Emoji name="noEntry" size="medium" shape="square" bgColor={color.blue[5]} />,
  CLOSE_BED: <Emoji name="constructionOutlined" size="medium" shape="square" bgColor={color.blue[5]} />,
  ADD_ACCOMMODATION: <Emoji name="calendar" size="medium" shape="square" bgColor={color.blue[5]} />,
  VIA_TRAJECTOIRE_REDIRECT: <Emoji name="roadSign" size="medium" shape="square" bgColor={color.blue[5]} />,
};
export const menuActionIcons: Record<AccommodationAction, ReactElement> = {
  SET_BED: <Bed />,
  UPDATE_SPECIFICITIES: <List />,
  UPDATE_DATES: <CalendarIcon />,
  CONFIRM_ADMISSION: <Admission />,
  UPDATE_BED: <Bed />,
  UPDATE_UNIT: <Unit />,
  CANCEL_BED: <Reset />,
  CANCEL_ADMISSION: <Cancel />,
  VALIDATE_DISCHARGE_DATE: <CheckCircle />,
  INVALIDATE_DISCHARGE_DATE: <Reset />,
  UPDATE_PLANNED_DISCHARGE_DATE: <CalendarIcon />,
  UPDATE_VALIDATED_DISCHARGE_DATE: <CalendarIcon />,
  DISCHARGE_PATIENT: <ExitIcon />,
  CANCEL_DISCHARGE: <Cancel />,
  ADDRESS_MUTATION_REQUEST: <Edit />,
  EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST: <Edit />,
  ACCEPT_INTERNAL_MUTATION_REQUEST: <CheckCircle />,
  REJECT_INTERNAL_MUTATION_REQUEST: <Cancel />,
  ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: <CheckCircle />,
  REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: <Cancel />,
  NEW_INTERNAL_MUTATION_REQUEST: <Edit />,
  CANCEL_MUTATION_REQUEST: <TrashCanIcon />,
  SET_MUTATION_BED: <Bed />,
  CANCEL_MUTATION_BED: <Reset />,
  UPDATE_MUTATION_DATE: <Clock />,
  REQUEST_MUTATION: <Edit />,
  CANCEL_ACCOMMODATION: <Cancel />,
  REOPEN_BED: <CheckCircle />,
  CANCEL_CLOSED_BED: <Cancel />,
  CLOSE_BED: <ConstructionOutlined />,
  ADD_ACCOMMODATION: <CalendarIcon />,
  VIA_TRAJECTOIRE_REDIRECT: <RoadSign />,
};

export default getColumnActions;
