import { SVGProps } from 'react';

const Ambulance = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 136 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.1651 2.01664C30.0519 2.82094 27.5653 3.7646 20.5261 8.58643C8.84464 16.5817 0.65184 29.787 2.23699 43.0838C3.36497 52.4926 9.12236 60.9576 16.1936 67.8141C20.5474 72.0327 25.5251 75.8193 31.3273 78.1347C37.841 80.7287 45.1301 81.3399 52.2184 80.8402C59.8323 80.2947 67.4269 78.4592 73.9747 74.8219C83.0477 69.7811 89.7408 61.3399 92.5202 51.9371C95.2974 42.5343 94.1993 32.2695 89.807 23.4222C88.5765 20.9496 87.0832 18.5606 85.1199 16.5259C82.9494 14.2743 80.2534 12.5004 77.4527 10.9356C66.6172 4.8755 53.8655 1.7419 41.1651 2.01664Z"
      fill="#E6EDFF"
    />
    <path
      d="M92.3306 24.3913H86.4709V16.654C86.4709 15.0353 87.783 13.7251 89.4017 13.7251C91.0204 13.7251 92.3306 15.0353 92.3306 16.654"
      fill="#3561D3"
    />
    <mask id="path-3-inside-1_807_25847" fill="white">
      <path d="M29.9978 81.2894C26.3605 81.2894 23.4031 78.33 23.4031 74.6984V25.7886C23.4031 22.4749 26.0894 19.7886 29.4031 19.7886H100.547C101.985 19.7886 103.369 20.3942 104.344 21.4511L115.555 33.556L126.766 45.661C128.529 47.5044 129.501 49.9134 129.501 52.4576V75.8334C129.501 78.8423 127.053 81.2894 124.041 81.2894" />
    </mask>
    <path
      d="M104.344 21.4511L101.404 24.1633L101.409 24.169L104.344 21.4511ZM126.766 45.661L123.831 48.379L123.853 48.4027L123.875 48.426L126.766 45.661ZM115.555 33.556L112.62 36.274V36.274L115.555 33.556ZM29.9978 77.2894C28.5689 77.2894 27.4031 76.1201 27.4031 74.6984H19.4031C19.4031 80.5399 24.1521 85.2894 29.9978 85.2894V77.2894ZM27.4031 74.6984V25.7886H19.4031V74.6984H27.4031ZM29.4031 23.7886H100.547V15.7886H29.4031V23.7886ZM100.547 23.7886C100.869 23.7886 101.187 23.9283 101.404 24.1633L107.284 18.7388C105.551 16.86 103.1 15.7886 100.547 15.7886V23.7886ZM123.875 48.426C124.931 49.5296 125.501 50.9499 125.501 52.4576H133.501C133.501 48.8769 132.127 45.4792 129.656 42.896L123.875 48.426ZM125.501 52.4576V75.8334H133.501V52.4576H125.501ZM125.501 75.8334C125.501 76.6313 124.846 77.2894 124.041 77.2894V85.2894C129.261 85.2894 133.501 81.0532 133.501 75.8334H125.501ZM101.409 24.169L112.62 36.274L118.49 30.8381L107.279 18.7331L101.409 24.169ZM112.62 36.274L123.831 48.379L129.701 42.943L118.49 30.8381L112.62 36.274ZM27.4031 25.7886C27.4031 24.684 28.2985 23.7886 29.4031 23.7886V15.7886C23.8802 15.7886 19.4031 20.2657 19.4031 25.7886H27.4031Z"
      fill="white"
      mask="url(#path-3-inside-1_807_25847)"
    />
    <path
      d="M32.2166 81.5952C32.2166 86.0019 35.791 89.5744 40.1977 89.5744C44.6043 89.5744 48.173 86.0019 48.173 81.5952"
      fill="#3561D3"
    />
    <path
      d="M86.1492 81.5332C86.1492 85.9399 89.7236 89.5124 94.1303 89.5124C98.5369 89.5124 102.111 85.9399 102.111 81.5332"
      fill="#3561D3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9978 81.2894H129.768V77.2894C130.574 77.2894 131.228 76.6313 131.228 75.8334V52.4576C131.228 50.9499 130.659 49.5296 129.603 48.426L129.581 48.4027L129.559 48.379L118.348 36.274L107.137 24.169L107.132 24.1633C106.915 23.9283 106.597 23.7886 106.274 23.7886H29.4031C28.2985 23.7886 27.4031 24.684 27.4031 25.7886V74.6984C27.4031 76.1201 28.5689 77.2894 29.9978 77.2894V81.2894Z"
      fill="#B5CAFF"
    />
    <path
      d="M92.5585 51.0068C91.7187 51.0068 91.0408 50.3251 91.0408 49.491V31.5178C91.0408 30.6837 91.7187 30 92.5585 30H101.787C102.853 30 103.872 30.4532 104.584 31.2492L120.688 49.1444C120.988 49.4814 121.063 49.9309 120.878 50.3441C120.691 50.7593 120.312 51.0068 119.859 51.0068H119.77"
      fill="white"
    />
    <path
      d="M52.78 59.9782C51.7995 59.9782 51.0043 59.1801 51.0043 58.2006V55.2095C51.0043 53.8242 49.8742 52.6942 48.489 52.6942H45.4978C44.5184 52.6942 43.7202 51.8989 43.7202 50.9185V43.1164C43.7202 42.1369 44.5184 41.3388 45.4978 41.3388H48.489C49.8742 41.3388 51.0043 40.2116 51.0043 38.8263V35.8352C51.0043 34.8548 51.7995 34.0566 52.78 34.0566H60.5821C61.5615 34.0566 62.3577 34.8548 62.3577 35.8352V38.8263C62.3577 40.2116 63.4869 41.3388 64.8721 41.3388H67.8632C68.8437 41.3388 69.6418 42.1369 69.6418 43.1164V50.9185C69.6418 51.8989 68.8437 52.6942 67.8632 52.6942H64.8721C63.4869 52.6942 62.3577 53.8242 62.3577 55.2095V58.2006C62.3577 59.1801 61.5615 59.9782 60.5821 59.9782"
      fill="white"
    />
    <path
      d="M23.0408 78C23.0408 80.2091 24.8316 82 27.0408 82H128.041C130.25 82 132.041 80.2091 132.041 78V70C132.041 69.4477 131.593 69 131.041 69H23.0408V78Z"
      fill="#799AF1"
    />
    <path
      d="M59.8009 73.6143C55.3943 73.6143 51.8198 77.1887 51.8198 81.5954C51.8198 86.002 55.3943 89.5746 59.8009 89.5746C64.2076 89.5746 67.7801 86.002 67.7801 81.5954C67.7801 77.1887 64.2076 73.6143 59.8009 73.6143Z"
      fill="#B5CAFF"
    />
    <path
      d="M113.738 73.5522C109.331 73.5522 105.758 77.1267 105.758 81.5334C105.758 85.94 109.331 89.5126 113.738 89.5126C118.146 89.5126 121.713 85.94 121.713 81.5334C121.713 77.1267 118.146 73.5522 113.738 73.5522Z"
      fill="#B5CAFF"
    />
  </svg>
);

export default Ambulance;
