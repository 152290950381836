import { SvgIcon, SvgIconProps } from '@mui/material';

const List: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M1.75 4.25C2.7165 4.25 3.5 3.4665 3.5 2.5C3.5 1.5335 2.7165 0.75 1.75 0.75C0.783502 0.75 0 1.5335 0 2.5C0 3.4665 0.783502 4.25 1.75 4.25Z" />
      <path d="M1.75 9.75C2.7165 9.75 3.5 8.9665 3.5 8C3.5 7.0335 2.7165 6.25 1.75 6.25C0.783502 6.25 0 7.0335 0 8C0 8.9665 0.783502 9.75 1.75 9.75Z" />
      <path d="M1.75 15.25C2.7165 15.25 3.5 14.4665 3.5 13.5C3.5 12.5335 2.7165 11.75 1.75 11.75C0.783502 11.75 0 12.5335 0 13.5C0 14.4665 0.783502 15.25 1.75 15.25Z" />
      <rect x="5" y="1.75" width="11" height="1.5" rx="0.75" />
      <rect x="5" y="7.25" width="11" height="1.5" rx="0.75" />
      <rect x="5" y="12.75" width="11" height="1.5" rx="0.75" />
    </SvgIcon>
  );
};

export default List;
