import { Card, CardContent, CardHeader, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Emoji } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import CommentIcon from 'common/components/Icons/Comment';
import { i18n } from 'common/locale';
import shadows from 'common/theme/shadows';
import { formatDateTime, formatName } from 'common/utils';
import PatientCard from 'kurt/pages/Movements/MovementSheets/Cards/PatientCard';
import { AdmissionMovementActionMenu } from 'kurt/pages/Movements/MovementsList/MovementsListRow/MovementsActions';
import AccommodationSpecificityBadges from '../AccommodationSpecificityBadges';
import { getAccommodationIcon } from '../DetailsDialog/DetailsDialog';
import CardHeaderIcon from '../PatientFlowKanban/KanbanCard/CardHeaderIcon';
import { PatientToBePlaced } from './usePatientPlacement';

type PatientPlacementCardProps = {
  patientToBePlaced: PatientToBePlaced;
  isReadOnly?: boolean;
  isDragged?: boolean;
  onClick: (id: string) => void;
};

const PatientPlacementCard = ({
  patientToBePlaced,
  isReadOnly = false,
  isDragged = false,
  onClick,
}: PatientPlacementCardProps) => {
  if (!patientToBePlaced.visit) {
    return null;
  }
  const { firstName, lastName, legalName, legalFirstName, birthdate, gender } = patientToBePlaced.visit;

  const isMutation = !!patientToBePlaced.basedOn;
  const isDirectAdmission = isMutation && patientToBePlaced.basedOn!.requestType === 'DIRECT_ADMISSION';
  const hasFromUnit = !!patientToBePlaced.fromUnit;
  const hasFromBed = !!patientToBePlaced.fromBed;
  const isStartOutdated = patientToBePlaced.startOutdated;
  const reason = patientToBePlaced.basedOn?.reason;

  const fromText = hasFromUnit
    ? patientToBePlaced.fromUnit!.name
    : isDirectAdmission
      ? patientToBePlaced.basedOn!.directAdmissionOrigin
      : i18n.defaultOrigin;

  const cardHeaderIcon = getAccommodationIcon(isDirectAdmission, isMutation);

  return (
    <Card
      onClick={() => onClick(patientToBePlaced.id)}
      sx={{
        cursor: isDragged ? 'grabbing' : 'pointer',
        '&:hover': {
          boxShadow: shadows[4],
        },
      }}
    >
      <CardHeader
        avatar={<CardHeaderIcon Icon={cardHeaderIcon.icon} color={cardHeaderIcon.color} hasBorder />}
        title={
          <Stack direction="row" gap={1} alignItems="center">
            <EllipsisTypography variant="body2" color="secondary">
              {fromText}
            </EllipsisTypography>
            {hasFromBed && <Chip label={patientToBePlaced.fromBed!.name} variant="rounded" size="x-small" />}
          </Stack>
        }
        action={
          <Stack direction="row" alignItems="center" gap={2}>
            {patientToBePlaced.comment && (
              <Tooltip title={patientToBePlaced.comment}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CommentIcon color="secondary" />
                </Box>
              </Tooltip>
            )}
            {!isReadOnly && (
              <AdmissionMovementActionMenu movementId={patientToBePlaced.id} unitId={patientToBePlaced.unit.id} />
            )}
          </Stack>
        }
      />
      <CardContent>
        <Stack spacing={3}>
          <PatientCard
            birthdate={birthdate}
            gender={gender}
            name={formatName(firstName, lastName, legalName, legalFirstName)}
            specificities={patientToBePlaced.specificities}
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={4}>
            <Stack direction="row" gap={1} alignItems="center" minWidth={100}>
              {isStartOutdated && <Emoji name="warning" />}
              <Typography variant="body2" color={isStartOutdated ? 'warning' : 'secondary'} fontWeight={500}>
                {formatDateTime(patientToBePlaced.startAt)}
              </Typography>
            </Stack>
            {reason && (
              <EllipsisTypography variant="body2" color="secondary" fontWeight={500} flex={1}>
                {reason}
              </EllipsisTypography>
            )}

            <AccommodationSpecificityBadges specificities={patientToBePlaced.specificities} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PatientPlacementCard;
