export const unique = <T>(value: T, index: number, self: T[]) => {
  return self.indexOf(value) === index;
};

type SortOrder = 'asc' | 'desc';

export const sortObjects =
  <T>(sortMethod: (objectA: T, objectB: T) => number, order: SortOrder) =>
  (objectA: T, objectB: T) => {
    return order === 'asc' ? sortMethod(objectA, objectB) : sortMethod(objectA, objectB) * -1;
  };
