import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { PageHeader, PageTitle, useAppBarContext } from 'core/layout';
import { useDepartments } from 'kurt/hooks';
import kurtRoutes from 'kurt/routes';
import { useEffect } from 'react';
import Departments from './Departments';

const BASE_PATH = kurtRoutes.root + kurtRoutes.healthCenter;

const HealthCenterPage: React.FC = () => {
  const { healthCenter } = useAuthentication();
  const { departments, loading } = useDepartments();
  const { appBarSelect, isFullScreen, setAppBarSelect } = useAppBarContext();

  useEffect(() => {
    if (appBarSelect?.basePath !== BASE_PATH) {
      setAppBarSelect({
        basePath: BASE_PATH,
        value: healthCenter.name,
        subLabel: i18n.healthCenter,
      });
    }
  }, [appBarSelect, setAppBarSelect, healthCenter]);

  return (
    <>
      {!isFullScreen && (
        <>
          <PageTitle title={`${i18n.healthCenter} - ${healthCenter.name}`} />

          <PageHeader title={healthCenter.name} subheader={i18n.pageSubtitles.healthCenter} />
        </>
      )}

      <Departments departments={departments} loading={loading} />
    </>
  );
};

export default HealthCenterPage;
