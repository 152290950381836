import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { trackEvent } from 'core/analytics/trackEvent';
import { BedStatus } from '../../UnitManagement/UnitManagementFilters';

type StatusProps = {
  AVAILABLE: number;
  BOOKED?: number;
  CLOSED: number;
  BUSY: number;
  CLEANING: number;
  statusesFilter?: BedStatus[];
  onStatusClick: (status: BedStatus) => void;
};
const Status = ({ AVAILABLE, CLOSED, BUSY, CLEANING, BOOKED, statusesFilter = [], onStatusClick }: StatusProps) => {
  const handleStatucClick = (status: BedStatus) => {
    const isActive = statusesFilter.includes(status);
    trackEvent({
      event_type: 'unit_management_filter_statistics_changed',
      event_properties: {
        value: status,
        label: i18n.unitStatistics[status],
        action: isActive ? 'removed' : 'added',
      },
    });
    onStatusClick(status);
  };

  return (
    <Statistics.Card xs={12} sm={4.5} md={5} lg="auto">
      <Statistics.Item
        title={{ label: i18n.unitStatistics.AVAILABLE, color: 'success', variant: 'chip' }}
        isActive={statusesFilter.includes('AVAILABLE')}
        onClick={() => handleStatucClick('AVAILABLE')}
      >
        <Statistics.ItemContent justify="center">
          <Statistics.Text variant="lg">{AVAILABLE}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
      {BOOKED !== undefined && (
        <Statistics.Item
          title={{ label: i18n.unitStatistics.BOOKED, color: 'success', variant: 'chip' }}
          isActive={statusesFilter.includes('BOOKED')}
          onClick={() => handleStatucClick('BOOKED')}
        >
          <Statistics.ItemContent justify="center">
            <Statistics.Text variant="lg">{BOOKED}</Statistics.Text>
          </Statistics.ItemContent>
        </Statistics.Item>
      )}
      <Statistics.Item
        onClick={() => handleStatucClick('CLEANING')}
        isActive={statusesFilter.includes('CLEANING')}
        title={{ label: i18n.unitStatistics.CLEANING, color: 'primaryAlt', variant: 'chip' }}
      >
        <Statistics.ItemContent justify="center">
          <Statistics.Text variant="lg">{CLEANING}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
      <Statistics.Item
        title={{ label: i18n.unitStatistics.BUSY, color: 'primary', variant: 'chip' }}
        isActive={statusesFilter.includes('BUSY')}
        onClick={() => handleStatucClick('BUSY')}
      >
        <Statistics.ItemContent justify="center">
          <Statistics.Text variant="lg">{BUSY}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
      <Statistics.Item
        title={{ label: i18n.unitStatistics.CLOSED, color: 'secondary', variant: 'chip' }}
        isActive={statusesFilter.includes('CLOSED')}
        onClick={() => handleStatucClick('CLOSED')}
      >
        <Statistics.ItemContent justify="center">
          <Statistics.Text variant="lg">{CLOSED}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
    </Statistics.Card>
  );
};

export default Status;
