import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { UpdateMutationDate } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';
import { useAccommodation } from 'kurt/hooks';

type UpdateAccommodationDialogProps = {
  open: boolean;
  onClose: () => void;
  accommodationId: string;
};

export const UpdateAccommodationStartDateDialogForm = ({
  onClose,
  onBack,
  accommodationId,
}: {
  onClose: () => void;
  onBack?: () => void;
  accommodationId: string;
}) => {
  const { update } = useAccommodation(accommodationId);

  const handleSubmit = async ({ startAt }: { startAt: Date }) => {
    await update({ startAt });
    onClose();
  };

  return (
    <>
      <DialogHeader onClose={onClose} onBack={onBack} title={i18n.visitActions.UPDATE_MUTATION_DATE.action.label} />
      <DialogContent>
        <UpdateMutationDate onCancel={onBack || onClose} onSubmit={handleSubmit} />
      </DialogContent>
    </>
  );
};

export const UpdateAccommodationStartDateDialog = ({
  open,
  onClose,
  accommodationId,
}: UpdateAccommodationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <UpdateAccommodationStartDateDialogForm accommodationId={accommodationId} onClose={onClose} />
    </Dialog>
  );
};
