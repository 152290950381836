import Api, { Accommodation, AccommodationSpecificity, AccommodationStatus, PatientGender } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Paper, Radio, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { DialogHeader, Emoji } from 'common/components';
import { Mission } from 'common/components/Images';
import useParseQuery from 'common/hooks/useParseQuery';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { toast } from 'common/toast';
import { formatName } from 'common/utils';
import { ContentLoader, EmptyContent } from 'core/layout';
import { addDays, startOfDay } from 'date-fns';
import { useAccommodation } from 'kurt/hooks';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AdmissionCard } from '../Cards';

type PlacePatientModalProps = {
  open: boolean;
  bedId: string;
  date: Date;
  onClose: () => void;
  onSuccess: () => void;
};

const PlacePatientModal = ({ open, bedId, date, onClose, onSuccess }: PlacePatientModalProps) => {
  const [admissionSelected, setAdmissionSelected] = useState<string | undefined>();

  const { ids } = useParams() as { ids: string };

  const { admissions, loading: fetching } = useAdmissions(ids.split('-'), date);

  const { update, loading } = useAccommodation(admissionSelected);

  const handlePlacePatient = async () => {
    if (admissionSelected) {
      try {
        await update({ bedId });
        toast.success({ ...i18n.placePatientSuccess, icon: <Emoji name="thumbsUp" size={24} /> });
        setAdmissionSelected(undefined);
        onSuccess();
      } catch (err) {
        toast.error(i18n.placePatientError);
      }
    }
  };

  const handleClose = () => {
    onClose();
    setAdmissionSelected(undefined);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogHeader onClose={handleClose} title={i18n.placePatient} />
      <DialogContent>
        {fetching ? (
          <Box p={2}>
            <ContentLoader />
          </Box>
        ) : (
          <>
            {admissions.length > 0 ? (
              <Stack spacing={3}>
                {admissions.map((admission) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    key={admission.id}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setAdmissionSelected(admission.id)}
                  >
                    <Box marginLeft={-2}>
                      <Radio checked={!!admissionSelected && admissionSelected === admission.id} />
                    </Box>
                    <Box flex={1}>
                      <AdmissionCard
                        start={admission.start}
                        end={admission.end}
                        patient={admission.patient}
                        specificities={admission.specificities}
                        status={admission.status}
                        isEstimatedEnd={admission.isEstimatedEnd}
                      />
                    </Box>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Paper sx={{ backgroundColor: color.blue[5], padding: '48px 10px' }} variant="outlined">
                <EmptyContent Image={Mission} {...i18n.placePatientEmpryContent} />
              </Paper>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {i18n.cancel}
        </Button>
        <LoadingButton onClick={() => handlePlacePatient()} loading={loading}>
          {i18n.placePatient}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const useAdmissions = (unitIds: string[], date: Date) => {
  const start = useMemo(() => startOfDay(date), [date]);
  const end = useMemo(() => startOfDay(addDays(date, 1)), [date]);

  const { results, isLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .containedIn('unit', unitIds)
      .notEqualTo('status', 'CANCELLED')
      .doesNotExist('bed')
      .exists('patient')
      .greaterThanOrEqualTo('startAt', start)
      .lessThan('startAt', end)
      .include('visit')
  );

  const admissions = useMemo(() => {
    const admissions: Admission[] = [];
    for (const accommodation of results) {
      admissions.push({
        id: accommodation.id,
        start: accommodation.startAt,
        end: accommodation.endAt,
        isEstimatedEnd: accommodation.isEstimatedEnd,
        patient: {
          name: formatName(accommodation.visit!.firstName, accommodation.visit!.lastName),
          gender: accommodation.visit!.gender,
          birthdate: accommodation.visit!.birthdate,
        },
        specificities: accommodation.specificities || [],
        comment: accommodation.comment,
        reason: '',
        status: accommodation.status,
      });
    }

    return admissions;
  }, [results]);

  return { loading: isLoading, admissions };
};

type Admission = {
  id: string;
  start: Date;
  end?: Date;
  isEstimatedEnd?: boolean;
  duration?: string;
  patient: {
    name: string;
    gender: PatientGender;
    birthdate: Date;
  };
  specificities: AccommodationSpecificity[];
  comment?: string;
  status: AccommodationStatus;
  reason?: string;
};

export default PlacePatientModal;
