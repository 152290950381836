import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import React, { useState } from 'react';

type RejectMutationRequestProps = {
  onCancel: () => void;
  onSubmit: (_: undefined, { reason, status }: { reason?: string; status: 'REJECTED' }) => void;
  loading?: boolean;
};

const RejectMutationRequest: React.FC<RejectMutationRequestProps> = ({ onCancel, onSubmit, loading = false }) => {
  const [reason, setReason] = useState<string>();
  const [error, setError] = useState(false);

  const handleChange = (reason: string) => {
    setError(false);
    setReason(reason);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(undefined, { reason, status: 'REJECTED' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Stack>
          {i18n.visitActions.REJECT_INTERNAL_MUTATION_REQUEST.form.content?.map((line, index) => (
            <Typography color={color.grey[100]} key={index} fontWeight={600}>
              {line}
            </Typography>
          ))}
        </Stack>

        <Stack spacing={1}>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <TextField
                multiline
                fullWidth
                minRows={3}
                maxRows={3}
                placeholder={i18n.visitActions.REJECT_INTERNAL_MUTATION_REQUEST.form.inputLabel}
                value={reason}
                onChange={(event) => {
                  const hasLineBreak = event.target.value.match(/$/gm);

                  if (hasLineBreak && hasLineBreak.length > 3) {
                    return;
                  }

                  handleChange(event.target.value);
                }}
              ></TextField>
            </Stack>
            {error && (
              <Typography variant="body2" color="error">
                {i18n.visitActions.REJECT_INTERNAL_MUTATION_REQUEST.error?.inputError}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton type="submit" loading={loading} disabled={loading}>
          {i18n.visitActions.REJECT_INTERNAL_MUTATION_REQUEST.form.confirmLabel}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default RejectMutationRequest;
