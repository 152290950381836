import { useDroppable } from '@dnd-kit/core';
import { Stack } from '@mui/material';
import { color } from 'common/theme';
import { ReactNode } from 'react';
import KanbanCardSkeleton from './KanbanCard/KanbanCardSkeleton';

type DroppableData = {
  accept?: string[];
};
type KanbanColumnProps = {
  loading?: boolean;
  id?: string;
  data?: DroppableData;
  isDropForbidden?: boolean;
  children?: ReactNode;
};

const KanbanColumn: React.FC<KanbanColumnProps> = ({ loading = false, children, id, data, isDropForbidden }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: id || 'default',
    data,
  });

  return (
    <Stack
      ref={setNodeRef}
      flex={1}
      minWidth={330 - 48}
      bgcolor={isOver && !isDropForbidden ? color.blue[10] : 'background.default'}
      padding="12px 24px 32px"
      spacing={4}
      boxSizing="content-box"
      sx={{
        '&:first-of-type': {
          paddingLeft: 10,
        },
        '&:last-of-type': {
          paddingRight: 10,
        },
      }}
    >
      {loading ? (
        <>
          <KanbanCardSkeleton />
          <KanbanCardSkeleton />
        </>
      ) : (
        children
      )}
    </Stack>
  );
};

export default KanbanColumn;
