import { Patient } from '@ambuliz/sabri-core';
import { Order } from 'common/types';

export const compareValues = (a: any, b: any, order: Order) => {
  if (a === undefined) {
    return order === 'asc' ? -1 : 1;
  }

  if (b === undefined) {
    return order === 'asc' ? 1 : -1;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
  }

  if (a instanceof Date && b instanceof Date) {
    return order === 'asc' ? a.getTime() - b.getTime() : b.getTime() - a.getTime();
  }

  return order === 'asc' ? a - b : b - a;
};

export const comparePatients = (a: Patient, b: Patient, order: Order) => {
  const nameA = `${a.lastName} ${a.firstName}`;
  const nameB = `${b.lastName} ${b.firstName}`;
  return compareValues(nameA, nameB, order);
};
