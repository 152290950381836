import Api, { Accommodation, Area, Unit } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { isAfter, isBefore } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { UnitManagementFilter } from '.';
import { Resource } from './useResources';

const useResourcesByResponsibleUnits = ({
  unitIds,
  start,
  end,
  view,
}: {
  unitIds: string[];
  start: Date;
  end: Date;
  view: UnitManagementFilter['view'];
}) => {
  const [loading, setLoading] = useState(true);

  const [previousView, setPreviousView] = useState(view);
  useEffect(() => {
    if (previousView !== view && (previousView === 'timeline' || view === 'timeline')) {
      setPreviousView(view);
      setLoading(true);
    }
  }, [view, previousView]);

  const units = unitIds.map((id) => Unit.createWithoutData(id));

  const { results: accommodations, isLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .containedIn('responsibleUnit', units)
      .notContainedIn('unit', units)
      .greaterThan('endAt', start)
      .lessThan('startAt', end)
      .notEqualTo('status', 'CANCELLED')
      .exists('patient')
      .exists('bed')
      .include('unit', 'bed', 'responsibleUnit', 'visit'),
    { enabled: units.length > 0 }
  );

  const resourcesByUnit = useMemo(() => {
    const accommodationsByBed: Record<string, { unit: Unit; accommodations: Accommodation[]; bed: Area }> = {};

    for (const accommodation of accommodations) {
      if (!accommodation.bed || accommodation.unit.id === accommodation.responsibleUnit?.id) {
        continue;
      }

      if (!accommodationsByBed[accommodation.bed.id]) {
        accommodationsByBed[accommodation.bed.id] = {
          unit: accommodation.unit,
          bed: accommodation.bed,
          accommodations: [],
        };
      }

      accommodationsByBed[accommodation.bed.id].accommodations.push(accommodation);
    }

    const resourcesByUnit: Record<string, { unit: Unit; resources: Resource[] }> = {};

    for (const { accommodations, unit, bed } of Object.values(accommodationsByBed)) {
      if (!resourcesByUnit[unit.id]) {
        resourcesByUnit[unit.id] = { unit, resources: [] };
      }

      resourcesByUnit[unit.id].resources.push(mapResource({ unit, accommodations, bed }));
    }

    return Object.values(resourcesByUnit);
  }, [accommodations]);

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [isLoading]);

  return {
    resourcesByUnit,
    loading,
  };
};

const mapResource = ({ unit, accommodations, bed }: { unit: Unit; accommodations: Accommodation[]; bed: Area }) => {
  const accommodation = accommodations
    .sort((a, b) => a.startAt.getTime() - b.startAt.getTime())
    .find(({ startAt, endAt }) => isBefore(startAt, new Date()) && (endAt ? isAfter(endAt, new Date()) : true));

  return {
    bed: {
      id: bed.id,
      name: bed.name,
      gender: accommodation?.visit?.gender || 'UNKNOWN',
      status: 'BUSY',
      unitId: unit.id,
      unitName: unit.name,
    },
    anomalies: [],
    accommodations,
    healthcareEnvironmentalCleanings: [],
    accommodation,
  } as Resource;
};

export default useResourcesByResponsibleUnits;
