import { Table, TableBody } from '@mui/material';
import { FareHistoryEvent } from './FareHistory';
import FareHistoryEventListItem from './FareHistoryEventListItem';

type FareHistoryEventListProps = {
  events: FareHistoryEvent[];
};

const FareHistoryEventList: React.FC<FareHistoryEventListProps> = ({ events = [] }) => (
  <Table size="small">
    <TableBody>
      {events.map(({ date, content }, index) => (
        <FareHistoryEventListItem key={index} date={date} content={content} />
      ))}
    </TableBody>
  </Table>
);

export default FareHistoryEventList;
