import Api, { Accommodation, AccommodationRequest } from '@ambuliz/sabri-core';
import { Skeleton, TableCell, TableRow } from '@mui/material';
import useParseQuery from 'common/hooks/useParseQuery';
import { formatName } from 'common/utils';
import { useMemo } from 'react';

type MutationRowProps = {
  request: AccommodationRequest;
};

const MutationRow = ({ request }: MutationRowProps) => {
  const { results: nextAccommodations, isLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .notEqualTo('status', 'CANCELLED')
      .greaterThan('startAt', request.createdAt)
      .equalTo('basedOn', request)
      .ascending('startAt')
      .include(
        'unit',
        'visit',
        'basedOn',
        'bed',
        'basedOn.performerRequests.performer',
        'basedOn.performerRequests.fulfilledBy',
        'basedOn.performerRequests.createdBy'
      )
      .limit(1)
  );

  const nextAccommodation = useMemo(
    () => (!!nextAccommodations?.length ? nextAccommodations[0] : undefined),
    [nextAccommodations]
  );

  return (
    <TableRow>
      <TableCell>{request.id}</TableCell>
      <TableCell>{formatName(request.visit?.firstName, request.visit?.lastName)}</TableCell>
      <TableCell>
        {request.performerRequests
          ? request.performerRequests[request.performerRequests.length - 1]?.performer.name
          : '-'}
      </TableCell>
      <TableCell>{request.status}</TableCell>
      <TableCell>
        {isLoading ? (
          <Skeleton variant="rectangular" height={14} width={100} sx={{ borderRadius: 0.5 }} />
        ) : (
          getStatus(nextAccommodation)
        )}
      </TableCell>
    </TableRow>
  );
};

const getStatus = (nextAccommodation?: Accommodation) => {
  if (nextAccommodation) {
    if (nextAccommodation.status === 'PLANNED') {
      if (nextAccommodation.bed) {
        return 'Lit affecté';
      } else {
        return 'À placer';
      }
    } else if (nextAccommodation.status === 'IN_PROGRESS') {
      return 'Patient admis';
    } else if (nextAccommodation.status === 'COMPLETED') {
      return 'Patient sorti';
    }
  }
  return '-';
};

export default MutationRow;
