import { MultipleSelect } from 'common/components';
import { Calendar, EntryDashed, Unit } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { ReactNode } from 'react';
import { FlowType, MovementTypeFilter, movementTypeFilters } from '../useMovementsPageSearchParams';

type MovementTypeSelectProps = {
  value: MovementTypeFilter[];
  onChange: (values: MovementTypeFilter[]) => void;
  flow: FlowType;
};

const MovementTypeSelect = ({ value, onChange, flow }: MovementTypeSelectProps) => {
  return (
    <MultipleSelect
      onChange={(value) => onChange(value as MovementTypeFilter[])}
      options={getOptions(flow)}
      values={value}
      label={i18n[flow === 'admission' ? 'entryType' : 'dischargeType'].label}
    />
  );
};

const getOptions = (flow: 'admission' | 'discharge') => {
  const admissionOptions = movementTypeFilters.filter((type) => type !== 'SCHEDULED_DISCHARGE');
  const dischargeOptions = movementTypeFilters.filter(
    (type) => !['SCHEDULED_ADMISSION', 'DIRECT_ADMISSION', 'EMERGENCY'].includes(type)
  );

  const options = flow === 'admission' ? admissionOptions : dischargeOptions;
  return options.map((type) => {
    return { value: type, label: i18n.movementTypes[type], Icon: movementTypeFilterIcons[type] };
  });
};

const movementTypeFilterIcons: Record<MovementTypeFilter, ReactNode> = {
  DIRECT_ADMISSION: <EntryDashed color="tertiary" />,
  INTERNAL_MUTATION: <Unit color="info" />,
  SCHEDULED_DISCHARGE: <Calendar color="success" />,
  SCHEDULED_ADMISSION: <Calendar color="success" />,
};

export default MovementTypeSelect;
