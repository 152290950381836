import { UnitType } from '@ambuliz/sabri-core';
import { Chip, ChipTypeMap } from '@mui/material';
import { i18n } from 'common/locale';

type UnitTypeChipProps = {
  type: UnitType;
};

const color: Record<UnitType, ChipTypeMap['props']['color']> = {
  ACCOMMODATION: 'info',
  EMERGENCY: 'error',
  MEDICAL: 'success',
  OTHER: 'secondary',
};

export const UnitTypeChip = ({ type }: UnitTypeChipProps) => {
  return <Chip variant="rounded" color={color[type]} label={i18n.unitType[type]} />;
};
