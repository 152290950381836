import { Area, PatientGender } from '@ambuliz/sabri-core';
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { useUnitFromBed } from 'core/locations';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import { BedStatus } from 'kurt/pages/Unit/UnitManagement/UnitManagementFilters';
import { LocationStep } from '../context/Step';
import bgSmileAvailableBed from './Backgrounds/bgSmileAvailableBed.svg';
import bgSmileCleaning from './Backgrounds/bgSmileCleaning.svg';
import bgSmileClosed from './Backgrounds/bgSmileClosed.svg';

export type BedInfosProps = {
  step: LocationStep;
  cardChip?: boolean;
  bedGender?: PatientGender;
};

const BedInfos = ({ step, bedGender, cardChip }: BedInfosProps) => {
  const bed = getStepBed(step);

  if (!bed) {
    return null;
  }
  return (
    <Card
      sx={{
        padding: 4,
        bgcolor: getCardLightColor(step.type),
        backgroundImage: `url(${getCardBackground(step.type)})`,
        backgroundPosition: 'right 0px bottom 0px',
        backgroundRepeat: 'no-repeat, no-repeat',
      }}
    >
      <Stack spacing={4} justifyContent="space-between">
        <Stack spacing={3}>
          <Stack direction="row" spacing={3} justifyContent="space-between" width="100%">
            <Box width={40} height={40} bgcolor="#ffffff66" borderRadius={1} position="relative">
              <Box
                sx={{
                  transform: 'rotate(-7.174deg)',
                  position: 'absolute',
                  right: -7.375,
                  bottom: -7.339,
                }}
              >
                <Emoji name={getCardIcon(step.type)} size="large" shape="square" />
              </Box>
            </Box>

            {cardChip && <BedStatusChip status={getChipStatus(step.type)} gender={bedGender} variant="outlined" />}
          </Stack>

          <Typography variant="h4" style={{ marginTop: 24 }}>
            {i18n.bed} {bed.name}
          </Typography>
        </Stack>

        <BedLocation type={step.type} bed={bed} />
      </Stack>
    </Card>
  );
};

type BedLocationProps = {
  type: LocationStep['type'];
  bed: Area;
};

const BedLocation = ({ type, bed }: BedLocationProps) => {
  const { unit, isLoading } = useUnitFromBed(bed);

  if (isLoading) {
    return (
      <Stack spacing={2}>
        <Typography color={color.grey[90]}>{i18n.location} :</Typography>
        <Skeleton
          variant="rounded"
          width={120}
          height={14}
          sx={{
            bgcolor: getSkeletonColor(type),
          }}
        />
      </Stack>
    );
  } else if (unit) {
    return (
      <Stack spacing={1}>
        <Typography color={color.grey[90]}>{i18n.location} :</Typography>
        <Typography variant="h6">{unit.name}</Typography>
      </Stack>
    );
  } else {
    return null;
  }
};

const getStepBed = (step: LocationStep): Area | undefined => {
  switch (step.type) {
    case 'closedBed':
      return step.step.bed;

    case 'healthCareEnvironmentalCleaning':
      return step.step.location;

    case 'availableBed':
      return step.step;
  }
};

const getCardLightColor = (type: BedInfosProps['step']['type']) => {
  switch (type) {
    case 'closedBed':
      return color.grey[10];

    case 'healthCareEnvironmentalCleaning':
      return palette.primaryAlt.medium;

    case 'availableBed':
      return palette.success.medium;

    default:
      return 'white';
  }
};

const getCardIcon = (type: LocationStep['type']) => {
  switch (type) {
    case 'closedBed':
      return 'constructionOutlined';

    case 'healthCareEnvironmentalCleaning':
      return 'bucketOutlined';

    case 'availableBed':
      return 'bed';
  }
};

const getChipStatus = (type: LocationStep['type']): BedStatus => {
  switch (type) {
    case 'closedBed':
      return 'CLOSED';

    case 'healthCareEnvironmentalCleaning':
      return 'CLEANING';

    case 'availableBed':
      return 'AVAILABLE';
  }
};

const getCardBackground = (type: LocationStep['type']): string => {
  switch (type) {
    case 'closedBed':
      return bgSmileClosed;

    case 'healthCareEnvironmentalCleaning':
      return bgSmileCleaning;

    case 'availableBed':
      return bgSmileAvailableBed;
  }
};

const getSkeletonColor = (type: LocationStep['type']) => {
  switch (type) {
    case 'closedBed':
      return 'secondary.medium';

    case 'healthCareEnvironmentalCleaning':
      return color.lightblue[30];

    case 'availableBed':
      return color.green[30];

    default:
      return 'white';
  }
};

export default BedInfos;
