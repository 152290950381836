import { FareAssignation, FarePosition, FareStatus, HealthCenter } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import { getPortersLimitByPosition } from 'sabri/services/porter';
import { i18n } from '../../locales';
import { AssignablePortersTable } from '../TablePortersAssignation/assignable-porters/AssignablePortersTable';
import { AssignedPortersTable } from '../TablePortersAssignation/assigned-porters/AssignedPortersTable';
import { usePorterAssignations } from './usePorterAssignations';

type PorterAssignationProps = {
  fareId: string;
  healthCenter: HealthCenter;
  farePosition: FarePosition;
  fareStatus?: FareStatus;
  fareAssignations?: FareAssignation[];
  isReadOnly?: boolean;
};

export const PorterAssignation = ({
  fareId,
  farePosition,
  fareStatus,
  fareAssignations = [],
  healthCenter,
  isReadOnly,
}: PorterAssignationProps) => {
  const { availablePorters, assignedPorters, isLoading } = usePorterAssignations(fareAssignations, !isReadOnly);
  const portersLimit = getPortersLimitByPosition(healthCenter.portersLimitByFarePosition, farePosition);

  return (
    <Stack spacing={10}>
      <Stack spacing={4}>
        <Typography variant="h5">{i18n.tablePorterAssigned}</Typography>
        {portersLimit && (
          <Typography variant="subtitle2" color="textSecondary">
            {i18n.portersLimitRecommendedByHealthCenter(portersLimit, i18n.farePosition[farePosition].toLowerCase())}
          </Typography>
        )}
        <AssignedPortersTable
          fareId={fareId}
          fareStatus={fareStatus}
          assignedPorters={assignedPorters}
          isReadOnly={isReadOnly}
          isLoading={isLoading}
        />
      </Stack>
      {!isReadOnly && (
        <Stack spacing={4}>
          <Typography variant="h5">{i18n.tablePorterUnassigned}</Typography>
          <AssignablePortersTable fareId={fareId} assignablePorters={availablePorters} />
        </Stack>
      )}
    </Stack>
  );
};
