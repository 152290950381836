import { UnitType, unitTypes } from '@ambuliz/sabri-core';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { i18n } from 'common/locale';

type UnitTypeSelectProps = {
  value?: string;
  onChange: (value: UnitType) => void;
  disabled?: boolean;
};

const UnitTypeSelect: React.FC<UnitTypeSelectProps> = ({ value = '', onChange, disabled }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="typeLabel">{i18n.type}</InputLabel>
      <Select
        name="type"
        value={value}
        disabled={disabled}
        variant="outlined"
        labelId="typeLabel"
        label={i18n.type}
        onChange={(event) => onChange(event.target.value as UnitType)}
      >
        {unitTypes.map((unitType: UnitType) => (
          <MenuItem key={unitType} value={unitType}>
            {i18n[unitType]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default UnitTypeSelect;
