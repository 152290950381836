import { PatientGender } from '@ambuliz/sabri-core';
import { Chip, Stack, Tooltip } from '@mui/material';
import { Female, Male, UnknownGender } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import styled from 'styled-components';

type BedStatus = 'AVAILABLE' | 'BUSY' | 'CLOSED' | 'CLEANING' | 'BOOKED';

type BedStatusChipProps = {
  status: BedStatus;
  gender?: PatientGender;
  variant?: 'normal' | 'outlined';
};

const BedStatusChip: React.FC<BedStatusChipProps> = ({ gender, status, variant = 'normal' }) => {
  return (
    <Stack
      direction="row"
      sx={{
        bgcolor: getColors(status).background,
        borderRadius: 0.5,
        minWidth: 100,
        height: 20,
        outline: variant === 'outlined' ? '2px solid white' : undefined,
      }}
    >
      {gender && (
        <Tooltip title={bedGenderTooltip[gender]} placement="top">
          <IconContainer color={getColors(status).icon}>{getIconByGender(gender)}</IconContainer>
        </Tooltip>
      )}
      <Chip
        label={status ? i18n.bedStatuses[status] : ''}
        color={statusColor[status]}
        variant="light"
        sx={{ flex: 1 }}
      />
    </Stack>
  );
};

type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'primaryAlt';

const IconContainer = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${({ color }) => color};
  color: white;
`;

const bedGenderTooltip: Record<PatientGender, string> = {
  MALE: i18n.bedMale,
  FEMALE: i18n.bedFemale,
  UNKNOWN: i18n.bedIndifferent,
};

const statusColor: Record<BedStatus, ChipColor> = {
  AVAILABLE: 'success',
  BUSY: 'primary',
  CLOSED: 'secondary',
  CLEANING: 'primaryAlt',
  BOOKED: 'success',
};

const getColors = (status: BedStatus): { icon: string; background: string } => {
  switch (status) {
    case 'AVAILABLE':
    case 'BOOKED':
      return { icon: color.green[50], background: color.green[20] };

    case 'CLOSED':
      return { icon: color.grey[40], background: color.grey[10] };
    case 'CLEANING':
      return { icon: color.lightblue[60], background: color.lightblue[20] };

    case 'BUSY':
    default:
      return { icon: color.blue[30], background: color.blue[20] };
  }
};

const getIconByGender = (gender?: PatientGender) => {
  switch (gender) {
    case 'FEMALE':
      return <Female />;
    case 'MALE':
      return <Male />;
    case 'UNKNOWN':
      return <UnknownGender />;
    default:
      return undefined;
  }
};

export default BedStatusChip;
