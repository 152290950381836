import { AccommodationStatus } from '@ambuliz/sabri-core';
import { Paper, Stack } from '@mui/material';
import { TimeFlowLine } from 'common/components';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { getDuration } from 'common/utils';
import styled from 'styled-components';
import { FlowSummaryDate, FlowSummaryDuration } from '.';

type KanbanCardFlowSummaryProps = {
  admissionDate?: Date;
  showAdmissionDateWarning?: boolean;
  dischargeDate?: Date;
  showDischargeDateWarning?: boolean;
  isDischargeDateValidated?: boolean;
  isDischarged?: boolean;
  location?: string;
  visitStatus: AccommodationStatus;
};

const Card = styled.div`
  position: relative;
`;

const CardTop = styled(Card)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
`;

const CardBottom = styled(Card)`
  width: 100%;
  background-image: linear-gradient(to right, rgb(234, 236, 243) 50%, rgba(234, 236, 243, 0) 0%);
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;

    height: 16px;
    background: #fff;
    border-radius: 50%;
    top: -8px;
  }
  &:before {
    left: -1px;
    width: 9px;
    border-radius: 0px 8px 8px 0px;
    border-right: 1px solid ${color.grey[10]};
    border-top: 1px solid ${color.grey[10]};
    border-bottom: 1px solid ${color.grey[10]};
    box-shadow: inset rgb(20 22 28 / 5%) -3px 0px 4px, rgb(255 255 255) -4px 0px 4px;
    box-sizing: border-box;
  }
  &:after {
    right: -1px;
    width: 9px;
    border-radius: 8px 0px 0px 8px;
    border-left: 1px solid ${color.grey[10]};
    border-top: 1px solid ${color.grey[10]};
    border-bottom: 1px solid ${color.grey[10]};
    box-shadow: inset rgb(20 22 28 / 5%) 3px 0px 4px, rgb(255 255 255) 4px 0px 4px;
    box-sizing: border-box;
  }
`;

const KanbanCardFlowSummary: React.FC<KanbanCardFlowSummaryProps> = ({
  admissionDate,
  dischargeDate,
  isDischarged,
  isDischargeDateValidated = false,
  visitStatus,
  showAdmissionDateWarning = false,
  showDischargeDateWarning = false,
}) => {
  return (
    <Paper>
      <CardTop>
        <Stack padding={4} direction="row" alignItems="center" overflow="hidden" justifyContent="center" spacing={4}>
          <FlowSummaryDate
            label={
              ['IN_PROGRESS', 'COMPLETED'].includes(visitStatus) ? i18n.effectiveAdmission : i18n.previsionalAdmission
            }
            alignItems="flex-end"
            date={admissionDate}
            showWarning={showAdmissionDateWarning}
          />

          <TimeFlowLine
            start={['IN_PROGRESS', 'COMPLETED'].includes(visitStatus) ? 'filled' : 'dashed'}
            end={isDischargeDateValidated && isDischarged ? 'filled' : 'dashed'}
          />
          <FlowSummaryDate
            alignItems="flex-start"
            label={
              isDischargeDateValidated && visitStatus === 'COMPLETED'
                ? i18n.effectiveDischarge
                : isDischargeDateValidated
                ? i18n.validatedDischarge
                : i18n.previsionalDischarge
            }
            date={dischargeDate}
            showWarning={showDischargeDateWarning}
          />
        </Stack>
      </CardTop>
      <CardBottom>
        {admissionDate && (
          <Stack direction="row" padding={4} spacing={2} alignItems="center">
            <FlowSummaryDuration
              since={getDuration(admissionDate, new Date())}
              totalDuration={getDuration(admissionDate, dischargeDate)}
            />
          </Stack>
        )}
      </CardBottom>
    </Paper>
  );
};

export default KanbanCardFlowSummary;
