import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DialogHeader } from 'common/components';
import { DateTimeInput, FilterSelect } from 'common/components/Form';
import BedIcon from 'common/components/Icons/Bed';
import CalendarIcon from 'common/components/Icons/Calendar';
import CalendarDayIcon from 'common/components/Icons/CalendarDay';
import CommentIcon from 'common/components/Icons/Comment';

import StandIcon from 'common/components/Icons/Stand';
import { PatientAutocomplete, PatientSearchResult } from 'common/components/PatientAutocomplete';
import { i18n } from 'common/locale';
import { useBedOptions } from 'core/locations/useBedOptions';
import { getHours, getMinutes, isValid } from 'date-fns';
import { AccommodationToUpsert } from 'kurt/hooks';
import { ReactElement, useEffect, useState } from 'react';
import DialogFormRow from '../DialogFormRow';

type CreateEmergencyAdmissionFormProps = {
  onSubmit: (accommodation: AccommodationToUpsert, bedsToClose?: string[]) => void;
  onCancel?: () => void;
  onBack?: () => void;
  initialAccommodation?: Partial<AccommodationToUpsert>;
  loading?: boolean;
  error?: ReactElement;
};

const AddAccommodationForm: React.FC<CreateEmergencyAdmissionFormProps> = ({
  initialAccommodation,
  onSubmit,
  onCancel,
  onBack,
  loading,
  error,
}) => {
  const [patient, setPatient] = useState<PatientSearchResult | null>(null);
  const [errors, setErrors] = useState(initialErrors);

  const [accommodation, setAccommodation] = useState<Partial<EmergencyAdmissionForm>>({ ...initialAccommodation });
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  const { bedOptions, isLoading: areBedsLoading } = useBedOptions(accommodation.unitId);

  const handleChange =
    <T extends keyof EmergencyAdmissionForm>(field: T) =>
    (value?: EmergencyAdmissionForm[T]) => {
      setAccommodation((prev) => ({ ...prev, [field]: value }));
      setErrors((prev) => ({ ...prev, [field]: false }));
    };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = formValidation(accommodation);

    if (validationErrors && Object.values(validationErrors).includes(true)) {
      setErrors((prev) => ({ ...prev, ...validationErrors }));
    } else {
      setHasBeenCalled(true);

      if (!hasBeenCalled) {
        onSubmit({
          isEndEstimated: accommodation.isEndEstimated,
          comment: accommodation.comment,
          startAt: accommodation.startAt,
          endAt: accommodation.endAt,
          unitId: accommodation.unitId,
          visitId: accommodation.visitId,
          bedId: accommodation.bedId,
          status: 'IN_PROGRESS',
        } as AccommodationToUpsert);
      }
    }
  };

  useEffect(() => {
    if (!!error && !loading) {
      setHasBeenCalled(false);
    }
  }, [error, loading]);

  return (
    <>
      <DialogHeader title={i18n.createAdmission.title} onClose={onCancel} onBack={onBack} />
      <DialogContent>
        <form onSubmit={handleSubmit} id="add-emergency-admission-form">
          <Stack divider={<Divider />}>
            <DialogFormRow label={i18n.addAccommodationSteps('PATIENT_STAY').patientChoice.label} Icon={StandIcon}>
              <PatientAutocomplete
                onChange={(patient) => {
                  setPatient(patient);
                  handleChange('visitId')(patient?.id);
                }}
                value={patient}
                error={errors.visitId ? { message: i18n.addAccommodationModal.errors.patient } : undefined}
                variant="filled"
                size="small"
              />
            </DialogFormRow>

            <DialogFormRow label={i18n.addAccommodationSteps('PATIENT_STAY').dates.startAt} Icon={CalendarIcon}>
              <Stack spacing={1}>
                <DateTimeInput
                  variant="filled"
                  size="small"
                  value={accommodation.startAt}
                  onChange={handleChange('startAt')}
                  maxDate={accommodation.endAt || undefined}
                  defaultTime={
                    !!accommodation.endAt
                      ? {
                          hour: getHours(accommodation.endAt),
                          minute: getMinutes(accommodation.endAt),
                        }
                      : undefined
                  }
                  error={errors.startAt}
                />
                {errors.startAt && (
                  <Typography fontSize={12} color="error">
                    {i18n.addAccommodationModal.errors.start}
                  </Typography>
                )}
              </Stack>
            </DialogFormRow>
            <DialogFormRow label={i18n.addAccommodationSteps('PATIENT_STAY').dates.endAt} Icon={CalendarIcon}>
              <Stack gap={2} direction="row" justifyContent="space-between">
                <DateTimeInput
                  variant="filled"
                  size="small"
                  value={accommodation.endAt}
                  onChange={handleChange('endAt')}
                  minDate={accommodation.startAt || undefined}
                  defaultTime={
                    !!accommodation.startAt
                      ? {
                          hour: getHours(accommodation.startAt),
                          minute: getMinutes(accommodation.startAt),
                        }
                      : undefined
                  }
                  error={errors.endAt}
                />

                <Select
                  variant="filled"
                  size="small"
                  value={accommodation.isEndEstimated}
                  onChange={(event) => handleChange('isEndEstimated')(event.target.value === 'true')}
                >
                  <MenuItem value="true">
                    <Chip
                      sx={{ cursor: 'pointer' }}
                      icon={<CalendarIcon />}
                      label={i18n.previsionalDischargeShort}
                      variant="light"
                      color="warning"
                    />
                  </MenuItem>
                  <MenuItem value="false">
                    <Chip
                      sx={{ cursor: 'pointer' }}
                      icon={<CalendarDayIcon />}
                      label={i18n.validatedDischargeShort}
                      variant="light"
                      color="success"
                    />
                  </MenuItem>
                </Select>
              </Stack>
              {errors.endAt && (
                <Typography fontSize={12} color="error">
                  {i18n.addAccommodationModal.errors.end}
                </Typography>
              )}
            </DialogFormRow>
            <DialogFormRow label={i18n.addAccommodationSteps('PATIENT_STAY').comment.label} Icon={CommentIcon}>
              <TextField
                multiline
                fullWidth
                minRows={1}
                maxRows={3}
                variant="filled"
                size="small"
                placeholder={i18n.empty}
                value={accommodation.comment}
                onChange={(event) => {
                  const hasLineBreak = event.target.value.match(/$/gm);

                  if (hasLineBreak && hasLineBreak.length > 3) {
                    return;
                  }

                  handleChange('comment')(event.target.value);
                }}
              />
            </DialogFormRow>

            <DialogFormRow label={i18n.addAccommodationSteps('PATIENT_STAY').bedChoice.label} Icon={BedIcon}>
              {accommodation.unitId && (
                <>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={2}>
                      <Stack spacing={1}>
                        <Box alignSelf="flex-start">
                          <FilterSelect
                            searchable
                            label={i18n.bedNumber}
                            value={accommodation.bedId}
                            onChange={handleChange('bedId')}
                            variant="filled"
                            size="small"
                            loading={areBedsLoading}
                          >
                            {bedOptions.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </FilterSelect>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              )}
            </DialogFormRow>
          </Stack>
        </form>
        {error && <Stack paddingTop={6}>{error}</Stack>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {i18n.cancel}
        </Button>
        <LoadingButton disabled={loading} loading={loading} type="submit" form="add-emergency-admission-form">
          {i18n.addAccommodationModal.programmVisit}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AddAccommodationForm;

export type EmergencyAdmissionForm = {
  unitId?: string;
  bedId?: string;
  startAt: Date | null;
  endAt: Date | null;
  visitId?: string;
  isEndEstimated: boolean;
  comment?: string | null;
};

const initialErrors = {
  visitId: false,
  startAt: false,
  endAt: false,
};

const formValidation = (accommodation: Partial<EmergencyAdmissionForm>) => ({
  visitId: !accommodation.visitId,
  startAt: !accommodation.startAt || !isValid(accommodation.startAt),
  endAt: !!accommodation.endAt && !!accommodation.startAt && accommodation.startAt > accommodation.endAt,
});
