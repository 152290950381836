import { PerformerRequest } from '@ambuliz/sabri-core';

export const getCurrentPerformerRequest = (performerRequests: PerformerRequest[]) => {
  // Sort by descending fullfilled date or updatedAt date
  performerRequests.sort((a, b) => {
    if (a.fulfilledAt && b.fulfilledAt) {
      return b.fulfilledAt.getTime() - a.fulfilledAt.getTime();
    }

    if (a.fulfilledAt) {
      return -1;
    }
    if (b.fulfilledAt) {
      return 1;
    }

    return b.updatedAt.getTime() - a.updatedAt.getTime();
  });

  let currentPerformerRequest = performerRequests.find(({ status }) => status === 'ACCEPTED');

  if (!currentPerformerRequest) {
    currentPerformerRequest = performerRequests.find(({ status }) => status === 'REQUESTED');
  }

  if (!currentPerformerRequest) {
    currentPerformerRequest = performerRequests.find(({ status }) => status === 'REJECTED');
  }

  return currentPerformerRequest;
};
