import { Components } from '@mui/material';
import color from '../color';
import palette from '../palette';

const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      '&:not(.MuiInputBase-multiline)': {
        height: 40,
      },
      color: palette.secondary.main,
      ':hover:not(.Mui-disabled), &.Mui-focused': {
        color: palette.secondary.dark,
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: palette.primary.main,
        },
        '& .MuiSelect-select, .MuiSelect-icon, .MuiIconButton-root': {
          color: palette.primary.main,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color.grey[30],
      },
    },
    input: {
      padding: '11px 12px',
      '&::placeholder': {
        color: color.grey[30],
        opacity: 1,
      },
    },
    sizeSmall: {
      '&:not(.MuiInputBase-multiline)': {
        height: 32,
      },
      borderRadius: 4,
      input: {
        padding: 8,
      },
    },
    multiline: {
      padding: 0,
    },
    inputMultiline: {
      padding: '11px 12px',
      '&.MuiInputBase-inputSizeSmall': {
        padding: '7px 8px',
      },
    },
    notchedOutline: {
      borderWidth: 1,
    },
    adornedEnd: {
      '& > .MuiSvgIcon-root': {
        color: color.grey[30],
      },
    },
    adornedStart: {
      '& > .MuiSvgIcon-root': {
        color: color.grey[30],
      },
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
  ],
};

export default MuiOutlinedInput;
