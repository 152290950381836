import { Accommodation, AccommodationSpecificity, Cloud, RequestType } from '@ambuliz/sabri-core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimeInput, DialogHeader, FilterSelect } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useLocations } from 'core/locations';
import { useBedOptions } from 'core/locations/useBedOptions';
import BedSpecifitiesSelect from 'kurt/components/AccommodationDetails/BedSpecificitiesSelect';
import { useMemo, useState } from 'react';

type CreateAccommodationRequestModalProps = {
  open: boolean;
  onClose: () => void;
  accommodation: Accommodation;
};

const CreateAccommodationRequestModal = ({ open, onClose, accommodation }: CreateAccommodationRequestModalProps) => {
  const { units } = useLocations();
  const unitOptions = useMemo(() => units.map((unit) => ({ value: unit.id, label: unit.name })), [units]);

  const { bedOptions, isLoading: areBedsLoading } = useBedOptions(accommodation.unit.id);

  const defaultAccommodationRequest: Request = {
    requestType: 'INTERNAL_MUTATION',
    visitId: accommodation.visit?.id,
    comment: accommodation.comment,
    specificities: accommodation.specificities,
  };

  const [accommodationRequest, setAccommodationRequest] = useState<Request>(defaultAccommodationRequest);

  const handleChange =
    <T extends keyof Request>(field: T) =>
    (value?: Request[T]) => {
      setAccommodationRequest({ ...accommodationRequest, [field]: value });
    };

  const handleClose = () => {
    setAccommodationRequest(defaultAccommodationRequest);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      if (accommodationRequest.visitId) {
        await Cloud.createAccommodationRequest({
          requestType: accommodationRequest.requestType,
          visitId: accommodationRequest.visitId,
          comment: accommodationRequest.comment,
          reason: accommodationRequest.reason,
          specificities: accommodationRequest.specificities,
          performerUnitIds: [accommodationRequest.destinationId!],
          expectedAt: accommodationRequest.expectedAt !== null ? accommodationRequest.expectedAt : undefined,
          locationId: accommodationRequest.locationId,
        });
        toast.success('Demande de mutation créée');
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="body">
      <DialogHeader title="Demande de mutation" onClose={handleClose} />
      <DialogContent>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Typography variant="h4">Type de mutation</Typography>
            <RadioGroup
              value={accommodationRequest.requestType}
              onChange={(event) => handleChange('requestType')(event.target.value as RequestType)}
            >
              <FormControlLabel
                value="INTERNAL_MUTATION"
                control={<Radio />}
                label="Mutation interne"
                sx={{ marginLeft: -2 }}
              />
              <FormControlLabel
                value="BED_MUTATION"
                control={<Radio />}
                label="Changement de lit"
                sx={{ marginLeft: -2 }}
              />
            </RadioGroup>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4">Motif</Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              maxRows={3}
              placeholder="Motif"
              value={accommodationRequest.reason}
              onChange={(event) => {
                const hasLineBreak = event.target.value.match(/$/gm);
                if (hasLineBreak && hasLineBreak.length > 3) {
                  return;
                }
                handleChange('reason')(event.target.value);
              }}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4">Spécificités</Typography>
            <BedSpecifitiesSelect
              isNew
              onChange={handleChange('specificities')}
              value={accommodationRequest.specificities || []}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4">Commentaire</Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              maxRows={3}
              placeholder="Commentaire"
              value={accommodationRequest.comment}
              onChange={(event) => {
                const hasLineBreak = event.target.value.match(/$/gm);
                if (hasLineBreak && hasLineBreak.length > 3) {
                  return;
                }
                handleChange('comment')(event.target.value);
              }}
            />
          </Stack>
          {accommodationRequest.requestType === 'BED_MUTATION' ? (
            <>
              <Stack spacing={2}>
                <Typography variant="h4">Date du mouvement</Typography>
                <DateTimeInput onChange={handleChange('expectedAt')} noRange value={accommodationRequest.expectedAt} />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="h4">Nouveau lit</Typography>
                <FilterSelect
                  searchable
                  label="N° du lit"
                  value={accommodationRequest.locationId}
                  onChange={handleChange('locationId')}
                  loading={areBedsLoading}
                >
                  {bedOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FilterSelect>
              </Stack>
            </>
          ) : accommodationRequest.requestType === 'INTERNAL_MUTATION' ? (
            <Stack spacing={2}>
              <Typography variant="h4">Destination</Typography>
              <FilterSelect
                searchable
                label={i18n.unit}
                value={accommodationRequest.destinationId}
                onChange={handleChange('destinationId')}
              >
                {unitOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </FilterSelect>
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {i18n.cancel}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={
            (accommodationRequest.requestType === 'BED_MUTATION' &&
              (!accommodationRequest.locationId || !accommodationRequest.expectedAt)) ||
            (accommodationRequest.requestType === 'INTERNAL_MUTATION' && !accommodationRequest.destinationId)
          }
        >
          Créer la demande
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type Request = {
  requestType: RequestType;
  visitId?: string;
  reason?: string;
  specificities?: AccommodationSpecificity[];
  comment?: string;
  destinationId?: string;
  directAdmissionOrigin?: string;
  locationId?: string;
  expectedAt?: Date | null;
};

export default CreateAccommodationRequestModal;
