import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { color } from 'common/theme';
import { formatAge, getPatientAge } from 'common/utils';
import getShortDuration from 'common/utils/getShortDuration';
import { addMilliseconds, format, isValid } from 'date-fns';
import AccommodationSpecificityBadges from 'kurt/components/AccommodationSpecificityBadges';
import EditableTableCell from 'kurt/components/EditableTableCell/EditableTableCell';
import AdmissionAccommodationAdmittedMenu from 'kurt/components/PatientFlowKanban/AdmissionAccommodationAdmittedMenu';
import AdmissionStatusChip from 'kurt/components/StepStatus/AdmissionStatusChip';
import { useAccommodation } from 'kurt/hooks';
import { useEffect, useState } from 'react';
import { MovementRow } from '../MovementRow';

const AdmittedRow = ({
  movement,
  onClick,
  showUnit,
  isReadOnly = false,
}: {
  movement: MovementRow;
  showUnit?: boolean;
  isReadOnly?: boolean;
  onClick: (id: string, type: 'accommodation' | 'mutation') => void;
}) => {
  const [comment, setComment] = useState(movement.comment || '');
  const { update, loading } = useAccommodation(movement.id);

  useEffect(() => setComment(movement.comment || ''), [movement.comment]);

  const age = getPatientAge(movement.patientBirthdate);

  const handleCommentSubmit = (value: string) => {
    if (value !== movement.comment) {
      update({ comment: value });
    }
  };

  return (
    <TableRow
      onClick={() => onClick(movement.id, 'accommodation')}
      hover
      sx={{
        cursor: 'pointer',
        whiteSpace: 'nowrap',
      }}
    >
      <TableCell width={14} sx={{ paddingRight: 0 }}></TableCell>
      <TableCell sx={{ paddingLeft: 1 }}>
        <Typography fontWeight={600} color="secondary" variant="body2">
          {movement.date && isValid(movement.date) ? format(movement.date, 'dd/MM - HH:mm') : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <AdmissionStatusChip status="ADMITTED" />
      </TableCell>
      <TableCell sx={{ opacity: 0.6 }}>
        <EllipsisTypography tooltipProps={{ title: 'Lit ' + movement.bed || '-' }} fontWeight={600}>
          {movement.bed || '-'}
        </EllipsisTypography>
      </TableCell>
      {showUnit && (
        <TableCell>
          <Typography variant="body2" color="secondary">
            {movement.unitName || '-'}
          </Typography>
        </TableCell>
      )}
      <TableCell sx={{ opacity: 0.6 }}>
        <PatientNameWithEmoji
          name={movement.patientName}
          gender={movement.patientGender}
          bgcolor={color.blue[5]}
          age={age}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {formatAge(movement.patientBirthdate)}
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {movement.duration && getShortDuration(new Date(), addMilliseconds(new Date(), movement.duration))}
        </Typography>
      </TableCell>
      <TableCell>
        <EllipsisTypography variant="body2" color="secondary" maxLines={3}>
          {movement.reason}
        </EllipsisTypography>
      </TableCell>
      <TableCell>
        <Box display="flex" sx={{ cursor: 'pointer' }}>
          <AccommodationSpecificityBadges specificities={movement.specificities}></AccommodationSpecificityBadges>
        </Box>
      </TableCell>
      <EditableTableCell
        value={comment}
        onChange={setComment}
        onSubmit={handleCommentSubmit}
        loading={loading}
        isReadOnly={isReadOnly}
      />
      {!isReadOnly && <TableCell></TableCell>}
      {!isReadOnly && (
        <TableCell width={16} sx={{ paddingLeft: 2 }}>
          <AdmissionAccommodationAdmittedMenu
            id={movement.id}
            unitId={movement.unitId!}
            visitId={movement.visitId}
            specificities={movement.specificities}
            comment={movement.comment}
            start={movement.admissionDate}
            end={movement.dischargeDate}
            patient={{
              name: movement.patientName,
              gender: movement.patientGender,
              birthdate: movement.patientBirthdate,
              isDischarged: movement.isVisitDischarged,
            }}
            isCompleted={movement.isCompleted}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default AdmittedRow;
