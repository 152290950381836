import { Tab, Tabs } from '@mui/material';
import { useAuthentication } from 'core/authentication';
import { PageContent, PageHeader, PageSection } from 'core/layout';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AreaManagementOrphans } from './AreaManagementOrphans';
import { AreaManagementSearch } from './AreaManagementSearch';
import { AreaManagementStructure } from './AreaManagementStructure';
import { AreaAvailibilityPeriodsModal, CreateAreaModal, DeleteAreaModal, EditAreaModal, MoveBedModal } from './modal';

const AreaManagement = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { user } = useAuthentication();
  const isAdminUser = user.role === 'ADMIN' || user.role === 'ADMIN_REGULATOR';

  return (
    <PageContent>
      <PageHeader title="Gestion de la structure">
        <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)}>
          <Tab label="Structure" />
          <Tab label="Recherche" />
          <Tab label="En vrac" />
        </Tabs>
      </PageHeader>

      <PageSection lastSection>
        {tabIndex === 0 && <AreaManagementStructure isReadOnly={!isAdminUser} />}
        {tabIndex === 1 && <AreaManagementSearch isReadOnly={!isAdminUser} />}
        {tabIndex === 2 && <AreaManagementOrphans isReadOnly={!isAdminUser} />}
      </PageSection>

      {isAdminUser && (
        <Routes>
          <Route path=":id" element={<EditAreaModal />} />
          <Route path=":id/create" element={<CreateAreaModal />} />
          <Route path=":id/availability-periods" element={<AreaAvailibilityPeriodsModal />} />
          <Route path=":id/move-bed" element={<MoveBedModal />} />
          <Route path=":id/delete" element={<DeleteAreaModal />} />
        </Routes>
      )}
    </PageContent>
  );
};

export default AreaManagement;
