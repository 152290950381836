import { Box, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { TimelineRowItem, TimelineRowItemProps } from 'common/components/Timeline';

const AccommodationItem = ({ name, ...props }: { name?: string } & TimelineRowItemProps) => {
  return (
    <TimelineRowItem {...props}>
      <Stack direction="row" spacing={2} alignItems="center" paddingX={3}>
        <Box
          height={22}
          width={22}
          fontSize={12}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
        >
          <Emoji name="bed" />
        </Box>
        <Typography>{name || 'à placer'}</Typography>
      </Stack>
    </TimelineRowItem>
  );
};

export default AccommodationItem;
