import { PerformerRequest, RequestStatus } from '@ambuliz/sabri-core';
import { Collapse, Divider, Stack, Typography } from '@mui/material';
import { Cancel, UsersFilled } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

const DetailCard = styled(Stack)<{ statuscolor: string; rejected?: string }>`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 26px;
  margin-top: -20px;
  background: white;
  color: ${color.grey[100]};
  z-index: 0;
  border-left: 2px solid ${color.grey[5]};
  border-right: 2px solid ${color.grey[5]};
  border-bottom: 2px solid ${color.grey[5]};
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const requestColors: Record<RequestStatus | 'BED_ASSIGNED', string> = {
  ACCEPTED: color.green[70],
  BED_ASSIGNED: color.green[70],
  REQUESTED: color.yellow[70],
  REJECTED: color.red[70],
  CANCELLED: color.grey[70],
};

const MutationHistory: React.FC<{ performerRequest: PerformerRequest; open: boolean }> = ({
  performerRequest,
  open = false,
}) => {
  const hasDifferentFulfilledDate =
    performerRequest.fulfilledAt &&
    format(performerRequest.fulfilledAt, 'dd/MM/yy') !== format(performerRequest.createdAt, 'dd/MM/yy');

  return (
    <Collapse in={open}>
      <DetailCard
        statuscolor={requestColors[performerRequest.status]}
        divider={<Divider />}
        spacing={3}
        paddingBottom={performerRequest.fulfilledAt ? 3 : 0}
      >
        <Stack direction="row" spacing={3}>
          <Stack
            style={{ minHeight: 20 }}
            direction="column"
            spacing={2}
            padding={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="overline">
              {performerRequest.createdAt ? format(performerRequest.createdAt, 'dd/MM/yy') : ''}
            </Typography>
            {performerRequest.fulfilledAt &&
              (performerRequest.fulfilledAt && hasDifferentFulfilledDate ? (
                <Typography variant="overline">{format(performerRequest.fulfilledAt, 'dd/MM/yy')}</Typography>
              ) : (
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ flex: 1, alignSelf: 'center', width: 2, borderColor: color.grey[30] }}
                ></Divider>
              ))}
          </Stack>
          <Stack justifyContent="space-between" direction="column" spacing={2} paddingRight={2}>
            <Typography variant="body2" fontWeight={600} color="secondary">
              {performerRequest.createdAt ? format(performerRequest.createdAt, 'HH:mm') : ''}
            </Typography>
            {performerRequest.fulfilledAt && (
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ color: performerRequest.status === 'REJECTED' ? palette.error.main : palette.success.main }}
              >
                {performerRequest.fulfilledAt ? format(performerRequest.fulfilledAt, 'HH:mm') : ''}
              </Typography>
            )}
          </Stack>
          <Stack flex={1} direction="row" justifyContent="space-between">
            <Stack direction="column" justifyContent="space-between" spacing={2}>
              <Typography variant="body2" fontWeight={600}>
                {i18n.performerRequestSent}
              </Typography>
              {performerRequest.status === 'REJECTED' && performerRequest.fulfilledAt && (
                <Typography variant="body2" fontWeight={600} color="error">
                  {i18n.performerRequestRejected}
                </Typography>
              )}
              {performerRequest.status === 'ACCEPTED' && performerRequest.fulfilledAt && (
                <Typography variant="body2" fontWeight={600} style={{ color: palette.success.main }}>
                  {i18n.performerRequestAccepted}
                </Typography>
              )}
            </Stack>
            <Stack direction="column" justifyContent="space-between" spacing={2}>
              {performerRequest.createdBy && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <UsersFilled color="secondary" sx={{ fontSize: 12 }} />
                  <Typography variant="body2">{performerRequest.createdBy.username}</Typography>
                </Stack>
              )}
              {performerRequest.status === 'REJECTED' && performerRequest.fulfilledBy && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <UsersFilled color="error" sx={{ fontSize: 12 }} />
                  <Typography variant="body2" color="error">
                    {performerRequest.fulfilledBy.username}
                  </Typography>
                </Stack>
              )}
              {performerRequest.status === 'ACCEPTED' && performerRequest.fulfilledBy && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <UsersFilled color="success" sx={{ fontSize: 12 }} />
                  <Typography variant="body2" style={{ color: palette.success.main }}>
                    {performerRequest.fulfilledBy.username}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack></Stack>
          </Stack>
        </Stack>
        {performerRequest.status === 'REJECTED' && performerRequest.rejectionReason && (
          <Stack direction="row" spacing={13} alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center" fontSize={12} flexShrink={0}>
              <Cancel color="secondary" fontSize="inherit" />
              <Typography variant="body2" fontWeight={600}>
                {i18n.rejectionReason}
              </Typography>
            </Stack>
            <Typography variant="body2" whiteSpace="pre-line">
              {performerRequest.rejectionReason}
            </Typography>
          </Stack>
        )}
      </DetailCard>
    </Collapse>
  );
};

export default MutationHistory;
