import { Cloud, Fare, FarePosition, FareSpecificity, FareStatus } from '@ambuliz/sabri-core';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { DialogHeader } from 'common/components';
import { toast } from 'common/toast';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonLoader } from 'sabri/components/ButtonLoader/ButtonLoader';
import { routeNamesCommon } from 'sabri/const/routeNames';
import { getObjectParamsFromSearchParams } from 'sabri/utils/searchParams';
import { icons } from '../../const/images';
import { i18n } from '../../locales';
import JourneyEmergency from './JourneyEmergency';
import JourneyInfos from './JourneyInfos';
import JourneyPatient from './JourneyPatient';
import JourneySteps from './JourneySteps';
import ReturnFare from './ReturnFare';

type JourneyFormProps = {
  onClose: () => void;
  fare?: Fare;
  journeyFares: Fare[];
};

const JourneyForm: React.FC<JourneyFormProps> = ({ fare, journeyFares, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const mode = fare ? 'UPDATE_JOURNEY' : 'CREATE_JOURNEY';
  const isNew = mode === 'CREATE_JOURNEY';

  const searchParams = new URLSearchParams(location.search);
  const decodedSearchParams = getObjectParamsFromSearchParams(searchParams, [formKeys.specificities]);

  const defaultValues: FormValue = useMemo(() => {
    return getInitialFormValue({ fare, journeyFares, searchParams: decodedSearchParams });
  }, [fare, journeyFares, decodedSearchParams]);

  const methods = useForm<FormValue, { mode: Mode | undefined }>({
    reValidateMode: 'onSubmit',
    resolver: async (values, context) => {
      if (context?.mode === 'UPDATE_JOURNEY') {
        return yupResolver<FormValue>(Cloud.UpdateJourneyValidation)(values);
      } else if (context?.mode === 'CREATE_JOURNEY') {
        return yupResolver<FormValue>(Cloud.CreateJourneyValidation)(values);
      }
      return { values, errors: {} };
    },
    context: { mode },
    defaultValues,
  });

  const { handleSubmit, register, unregister, watch, setValue, control } = methods;
  const { fields, append, swap, remove } = useFieldArray({ name: formKeys.steps, control });
  const steps = watch(formKeys.steps) as StepFormValue[];

  const inProgressStepIndex = useMemo(
    () =>
      journeyFares?.findIndex(
        (fare) => fare.status && ['IN_PROGRESS', 'STARTED', 'PATIENT_CARE'].includes(fare.status)
      ),
    [journeyFares]
  );

  useEffect(() => {
    register(formKeys.journeyId);
    return () => {
      unregister(formKeys.journeyId);
    };
  }, [register, unregister]);

  useEffect(() => {
    journeyFares.forEach((fare, index) => {
      if (fare.status && !fare.isReturnTripFromFare) {
        if (DISABLED_STEP_ACTIONS_FROM_FARE_STATUS.includes(fare.status) && !steps[index].actionsDisabled) {
          setValue(`${formKeys.steps}[${index}].${stepFormKeys.actionsDisabled}`, true);
        }
        if (DISABLED_STEP_FROM_FARE_STATUS.includes(fare.status) && !steps[index].disabled) {
          setValue(`${formKeys.steps}[${index}].${stepFormKeys.disabled}`, true);
        }
      }
    });
  }, [journeyFares, steps, setValue]);

  const onSubmitJourney = async (value: FormValue) => {
    setLoading(true);
    if (mode === 'UPDATE_JOURNEY' && value.journeyId) {
      try {
        await Cloud.updateJourney(value as Cloud.UpdateJourneyParams);
        toast.success(i18n.journeyUpdated);
        handleCloseModal(value.steps);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await Cloud.createJourney(value);
        toast.success(i18n.journeyCreated);
        onClose();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseModal = (steps?: StepFormValue[]) => {
    const stepsHaveBeenReorganized = journeyFares
      .filter((fare) => !fare.isReturnTripFromFare)
      .some((fare, index) => {
        return fare.id !== steps?.[index]?.fareId;
      });
    if (!isNew && stepsHaveBeenReorganized) {
      navigate(routeNamesCommon.fareList);
    } else {
      onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitJourney)}>
      <DialogHeader
        onClose={() => handleCloseModal()}
        title={
          <Stack direction="row" alignItems="center" spacing={2}>
            <img alt="addCircle" src={icons.addCircle} style={{ verticalAlign: 'middle' }} />
            <Typography variant="h6">{isNew ? i18n.newJourney : i18n.journeyEdit}</Typography>
          </Stack>
        }
      />
      <DialogContent>
        <FormProvider {...methods}>
          <Stack spacing={8}>
            <JourneyPatient />
            <JourneySteps
              inProgressStepIndex={inProgressStepIndex}
              steps={fields}
              append={append}
              swap={swap}
              remove={remove}
              startingPointDisabled={
                journeyFares?.[0]?.status && DISABLED_STEP_ACTIONS_FROM_FARE_STATUS.includes(journeyFares[0].status)
              }
            />
            <ReturnFare />
            {steps.length === 1 && <JourneyEmergency />}
            <JourneyInfos />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <ButtonLoader color="primary" variant="contained" type="submit" isLoading={loading}>
          {isNew ? i18n.orderJourney : i18n.saveUpdates}
        </ButtonLoader>
      </DialogActions>
    </form>
  );
};

export type FormValue = {
  patientId: string;
  roundTrip: boolean;
  position: FarePosition;
  comment?: string;
  isEmergency?: boolean;
  journeyId?: string;
  specificities?: FareSpecificity[];
  fromUnitId: string;
  fromAreaId?: string;
  steps: StepFormValue[];
};

export type StepFormValue = {
  fareId?: string;
  toUnitId: string;
  toAreaId?: string;
  wantedDate: Date;
  disabled?: boolean;
  actionsDisabled?: boolean;
};

export const formKeys: Record<keyof FormValue, string> = {
  patientId: 'patientId',
  roundTrip: 'roundTrip',
  position: 'position',
  comment: 'comment',
  isEmergency: 'isEmergency',
  journeyId: 'journeyId',
  specificities: 'specificities',
  fromUnitId: 'fromUnitId',
  fromAreaId: 'fromAreaId',
  steps: 'steps',
};

export const stepFormKeys: Record<keyof StepFormValue, string> = {
  fareId: 'fareId',
  toUnitId: 'toUnitId',
  toAreaId: 'toAreaId',
  wantedDate: 'wantedDate',
  disabled: 'disabled',
  actionsDisabled: 'actionsDisabled',
};

type Mode = 'CREATE_JOURNEY' | 'UPDATE_JOURNEY';

const DISABLED_STEP_FROM_FARE_STATUS: FareStatus[] = ['DONE', 'CANCELED', 'FAILED'];

const DISABLED_STEP_ACTIONS_FROM_FARE_STATUS: FareStatus[] = [
  ...DISABLED_STEP_FROM_FARE_STATUS,
  'IN_PROGRESS',
  'STARTED',
  'PATIENT_CARE',
];

const getInitialFormValue = ({
  fare,
  journeyFares,
  searchParams,
}: {
  fare?: Fare;
  journeyFares: Fare[];
  searchParams: Record<string, string>;
}): FormValue => {
  const steps: StepFormValue[] = [];

  if (fare) {
    const fromUnitId = fare.journey?.startingUnit.id || fare.fromUnit.id;
    const fromAreaId = fare.journey?.startingArea?.id || fare.fromArea?.id;
    const roundTrip = fare.journey?.roundTrip || false;
    const journeyId = fare.journey?.id || '';

    const fares = journeyFares.length > 0 ? journeyFares.filter((fare) => !fare.isReturnTripFromFare) : [fare];
    for (const journeyFare of fares) {
      steps.push({
        fareId: journeyFare.id,
        toUnitId: journeyFare.toUnit.id,
        toAreaId: journeyFare.toArea?.id,
        wantedDate: journeyFare.wantedDate || new Date(),
        disabled: journeyFare.status ? DISABLED_STEP_FROM_FARE_STATUS.includes(journeyFare.status) : false,
        actionsDisabled: journeyFare.status
          ? DISABLED_STEP_ACTIONS_FROM_FARE_STATUS.includes(journeyFare.status)
          : false,
      });
    }

    return {
      fromUnitId,
      fromAreaId,
      patientId: fare.patient.id,
      roundTrip,
      position: fare.position,
      comment: fare.comment,
      isEmergency: fare.isEmergency || false,
      journeyId,
      specificities: fare.specificities,
      steps,
    };
  } else if (Object.values(searchParams).length > 0) {
    steps.push({
      fareId: searchParams.fareId,
      toUnitId: searchParams.toUnitId,
      toAreaId: searchParams.toAreaId,
      wantedDate: new Date(searchParams.wantedDate),
    });
    return {
      patientId: searchParams.patientId || '',
      fromUnitId: searchParams.fromUnitId || '',
      fromAreaId: searchParams.fromAreaId || '',
      roundTrip: searchParams.roundTrip !== undefined ? searchParams.roundTrip === 'true' : true,
      position: (searchParams.position || '') as FarePosition,
      comment: searchParams.comment,
      isEmergency: searchParams.isEmergency !== undefined,
      specificities: searchParams.specificities?.split(',') as FareSpecificity[] | undefined,
      steps,
    };
  }

  return {
    patientId: '',
    fromUnitId: '',
    fromAreaId: '',
    roundTrip: true,
    position: '' as FarePosition,
    comment: '',
    isEmergency: false,
    specificities: [],
    steps: [
      {
        toUnitId: '',
        toAreaId: '',
        wantedDate: new Date(),
      },
    ],
  };
};

export default JourneyForm;
