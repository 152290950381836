import { Occupancy } from '@ambuliz/sabri-core';
import { DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { ConfirmDialog, Emoji } from 'common/components';
import { Cross } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { toast } from 'common/toast';
import { useOccupancy } from 'kurt/hooks';
import { NormalizedUnit } from 'kurt/hooks/useDepartments';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import UnitEditModalForm from './Form';
import UnitEditModalUnitCard from './UnitCard';

export type OccupancyFormValue = Omit<Occupancy, 'comment'> & { comment: string };
export const defaultOccupancy: OccupancyFormValue = {
  covid: 0,
  isolated: 0,
  isolatedAir: 0,
  isolatedDroplet: 0,
  isolatedContact: 0,
  isolatedProtector: 0,
  accomodated: 0,
  waitingForDownstreamBed: 0,
  open: 0,
  close: 0,
  admission: 0,
  discharge: 0,
  dDayAvailabilities: { male: 0, female: 0, indifferent: 0 },
  h24Availabilities: { male: 0, female: 0, indifferent: 0 },
  h48Availabilities: { male: 0, female: 0, indifferent: 0 },
  comment: '',
};
export type InputName =
  | 'open'
  | 'close'
  | 'covid'
  | 'accomodated'
  | 'admission'
  | 'discharge'
  | 'waitingForDownstreamBed';

type UnitEditModalProps = {
  name: string;
  units: NormalizedUnit[];
  onClose: () => void;
  loading?: boolean;
};

const UnitSelectionContainer = styled.div`
  max-height: calc(100vh - 80px);
  padding: 40px 32px 40px 40px;
  position: sticky;
  top: 0;
  border-right: 1px solid ${color.grey[20]};
  overflow-y: auto;
`;

const UnitEditFormContainer = styled.div`
  padding: 40px 40px 0px 32px;
`;

const isFormDirty = (occupancy: OccupancyFormValue, initialOccupancy?: Partial<Occupancy & { updatedAt: Date }>) => {
  if (initialOccupancy) {
    return Object.keys(occupancy).some((key) => {
      if (key === 'comment') {
        if (!initialOccupancy.comment && !occupancy.comment) {
          return false;
        }
        return initialOccupancy.comment?.message !== occupancy.comment;
      }
      return initialOccupancy[key as keyof OccupancyFormValue] !== occupancy[key as keyof OccupancyFormValue];
    });
  }
  return false;
};

const getFirstEditableUnitId = (units: NormalizedUnit[]) =>
  units.find((unit) => !unit.autoComputeOccupanciesActivated)?.id || '';

const UnitEditModal: React.FC<UnitEditModalProps> = ({ name, units = [], onClose }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [selectedUnitId, setSelectedUnitId] = useState<string>(getFirstEditableUnitId(units));

  const selectedUnit = useMemo(() => {
    return units.find((unit) => unit.id === selectedUnitId) || units[0];
  }, [units, selectedUnitId]);
  const [occupancy, setOccupancy] = useState<OccupancyFormValue>({
    ...defaultOccupancy,
    ...selectedUnit.occupancy,
    comment: selectedUnit.occupancy?.comment?.message || '',
  });
  const [notSavedModal, setNotSavedModal] = useState<{ open: boolean; id?: string }>({ open: false });

  const { update, loading: updateLoading, success, error } = useOccupancy(selectedUnitId);

  const handleSubmit = async () => update(occupancy);
  const handleChange = (value: number, name: InputName) => {
    setOccupancy({ ...occupancy, [name]: value });
  };
  const handleAvailabilityChange =
    (
      name: 'dDayAvailabilities' | 'h24Availabilities' | 'h48Availabilities',
      gender: 'male' | 'female' | 'indifferent'
    ) =>
    (value: number) => {
      setOccupancy({ ...occupancy, [name]: { ...occupancy[name], [gender]: value } });
    };
  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOccupancy({ ...occupancy, comment: event.target.value });
  };

  const handleClick = (id: string) => {
    if (isFormDirty(occupancy, selectedUnit.occupancy)) {
      setNotSavedModal({ open: true, id });
    } else {
      setSelectedUnitId(id);
      if (ref.current && ref.current?.scrollHeight) {
        ref.current?.scrollTo(0, 0);
      }
    }
  };

  const handleClose = useCallback(() => {
    if (isFormDirty(occupancy, selectedUnit.occupancy)) {
      setNotSavedModal({ open: true });
    } else {
      onClose();
    }
  }, [occupancy, selectedUnit.occupancy, onClose]);

  const handleNotSaveConfirm = () => {
    setNotSavedModal({ open: false });
    if (!notSavedModal.id) {
      onClose();
    } else {
      setSelectedUnitId(notSavedModal.id);
      if (ref.current?.scrollHeight) {
        ref.current?.scrollTo(0, 0);
      }
    }
  };
  const handleSaveConfirm = async () => {
    await update(occupancy);
    handleNotSaveConfirm();
  };
  const handleCloseNotSavedModal = () => setNotSavedModal({ open: false });

  useEffect(() => {
    const editingUnit = units.find((unit) => unit.id === selectedUnitId);

    if (!editingUnit || editingUnit.autoComputeOccupanciesActivated) {
      setSelectedUnitId(getFirstEditableUnitId(units));
      handleClose();
    }
  }, [units, selectedUnitId, handleClose]);

  useEffect(() => {
    setOccupancy({
      ...defaultOccupancy,
      ...selectedUnit.occupancy,
      comment: selectedUnit.occupancy?.comment?.message || '',
    });
  }, [selectedUnit]);

  useEffect(() => {
    if (success) {
      toast.success({
        icon: <Emoji name="whiteHeavyCheckMark" size={24} />,
        message: i18n.successMessageUnitUpdated,
        title: i18n.successTitleUnitUpdated,
      });
    }

    if (error) {
      toast.error(i18n.errorUnitUpdated);
    }
  }, [success, error]);

  return (
    <>
      <IconButton
        onClick={handleClose}
        size="large"
        sx={{ position: 'absolute', top: 40, right: 40, marginTop: -2 }}
        edge="end"
      >
        <Cross />
      </IconButton>
      <DialogContent sx={{ padding: 0 }} ref={ref}>
        <Stack direction="row">
          <UnitSelectionContainer>
            <Stack spacing={4}>
              <Typography fontWeight={600}>{i18n.unitToEdit}</Typography>
              {units.map((unit) => (
                <UnitEditModalUnitCard
                  selected={unit.id === selectedUnit.id}
                  disabled={unit.autoComputeOccupanciesActivated}
                  name={unit.name}
                  onClick={() => handleClick(unit.id)}
                  updatedAt={unit.occupancy?.updatedAt}
                  key={unit.id}
                />
              ))}
            </Stack>
          </UnitSelectionContainer>
          <UnitEditFormContainer>
            <UnitEditModalForm
              loading={updateLoading}
              title={i18n.editUnitFromDivision(selectedUnit.name, name)}
              occupancy={occupancy}
              onChange={handleChange}
              onAvailabilityChange={handleAvailabilityChange}
              onCommentChange={handleCommentChange}
              onSubmit={handleSubmit}
              onClose={handleClose}
              lastCommentUpdate={selectedUnit.occupancy?.comment?.updatedAt}
            />
          </UnitEditFormContainer>
        </Stack>
      </DialogContent>
      <ConfirmDialog
        open={notSavedModal.open}
        title={
          <Stack direction="row" spacing={2} alignItems="center">
            <Emoji name="raisedHand" size={24} />
            <div>{i18n.notSavedModal.title}</div>
          </Stack>
        }
        cancelLabel={i18n.notSavedModal.cancel}
        confirmLabel={i18n.notSavedModal.confirm}
        onBack={handleCloseNotSavedModal}
        onClose={handleNotSaveConfirm}
        onConfirm={handleSaveConfirm}
      >
        <Typography color="textSecondary">{i18n.notSavedModal.content}</Typography>
      </ConfirmDialog>
    </>
  );
};

export default UnitEditModal;
