import { Area } from '@ambuliz/sabri-core';
import { Stack } from '@mui/material';
import { AreaManagementListItem } from './AreaManagementListItem';

type AreaManagementListProps = {
  areas: Area[];
  isReadOnly?: boolean;
};

export const AreaManagementList = ({ areas, isReadOnly = false }: AreaManagementListProps) => {
  return (
    <Stack spacing={2}>
      {areas.map((area) => (
        <AreaManagementListItem key={area.id} area={area} isReadOnly={isReadOnly} />
      ))}
    </Stack>
  );
};
