"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFaresDifferences = exports.FARE_UPDATED_DEFAULT_CONFIGURATION = void 0;
exports.FARE_UPDATED_DEFAULT_CONFIGURATION = {
    isAcknowledgeNeeded: true,
    notificationsProviders: ['IN_APP', 'PUSH_MOBILE'],
    triggerEvent: 'FARE_UPDATED',
    retryPolicy: {
        retryDelayInSeconds: 10,
        maxRetryCount: 5,
    },
};
const getFaresDifferences = (oldFare, newFare) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const differences = [];
    if (oldFare.patient.id !== newFare.patient.id) {
        differences.push({
            type: 'patient',
            newValue: {
                firstName: newFare.patient.firstName,
                age: newFare.patient.age,
                birthdate: newFare.patient.birthdate,
                gender: newFare.patient.gender,
                lastName: newFare.patient.lastName,
            },
            oldValue: {
                firstName: oldFare.patient.firstName,
                age: oldFare.patient.age,
                birthdate: oldFare.patient.birthdate,
                gender: oldFare.patient.gender,
                lastName: oldFare.patient.lastName,
            },
        });
    }
    if (oldFare.fromUnit.id !== newFare.fromUnit.id || ((_a = oldFare.fromArea) === null || _a === void 0 ? void 0 : _a.id) !== ((_b = newFare.fromArea) === null || _b === void 0 ? void 0 : _b.id)) {
        // Values should be overwritten by the job with full names
        differences.push({
            type: 'fromLocation',
            newValue: { unit: newFare.fromUnit.id, areasBreadcrumb: (_c = newFare.fromArea) === null || _c === void 0 ? void 0 : _c.id },
            oldValue: { unit: oldFare.fromUnit.id, areasBreadcrumb: (_d = oldFare.fromArea) === null || _d === void 0 ? void 0 : _d.id },
        });
    }
    if (oldFare.toUnit.id !== newFare.toUnit.id || ((_e = oldFare.toArea) === null || _e === void 0 ? void 0 : _e.id) !== ((_f = newFare.toArea) === null || _f === void 0 ? void 0 : _f.id)) {
        // Values should be overwritten by the job with full names
        differences.push({
            type: 'toLocation',
            newValue: { unit: newFare.toUnit.id, areasBreadcrumb: (_g = newFare.toArea) === null || _g === void 0 ? void 0 : _g.id },
            oldValue: { unit: oldFare.toUnit.id, areasBreadcrumb: (_h = oldFare.toArea) === null || _h === void 0 ? void 0 : _h.id },
        });
    }
    if (oldFare.position !== newFare.position) {
        differences.push({
            type: 'position',
            newValue: newFare.position,
            oldValue: oldFare.position,
        });
    }
    if (JSON.stringify(oldFare.specificities) !== JSON.stringify(newFare.specificities)) {
        differences.push({
            type: 'specificities',
            newValue: newFare.specificities ? newFare.specificities : [],
            oldValue: oldFare.specificities ? oldFare.specificities : [],
        });
    }
    if (oldFare.comment !== newFare.comment) {
        differences.push({
            type: 'comment',
            newValue: newFare.comment,
            oldValue: oldFare.comment,
        });
    }
    return differences;
};
exports.getFaresDifferences = getFaresDifferences;
