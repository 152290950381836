import Api, { Area, ExternalTypeCode } from '@ambuliz/sabri-core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';
import { useState } from 'react';
import { AreaTypeChip } from 'superadmin/pages/AreaManagement/AreaTypeChip';
import { AreaSelectItemChildren } from './AreaSelectItemChildren';

type AreaSelectItemProps = {
  id: string;
  name: string;
  type?: ExternalTypeCode;
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
};

export const AreaSelectItem = ({ name, id, type, value, disabled, onChange }: AreaSelectItemProps) => {
  const [open, setOpen] = useState(false);

  const isSelected = value.includes(id);
  const { count: childrenCount, isLoading: isChildrenCountLoading } = useParseQueryCount(
    new Api.Query(Area).equalTo('parent', Area.createWithoutData(id))
  );

  if (isChildrenCountLoading) {
    return null;
  }

  const toggleSelect = (event: React.MouseEvent) => {
    if (!isSelected && disabled) return;
    event.stopPropagation();
    onChange(isSelected ? value.filter((v) => v !== id) : [...value, id]);
  };

  const toggleChildren = () => setOpen(!open);
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        py={1}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'background.default',
          },
        }}
        onClick={(e) => (childrenCount > 0 ? toggleChildren() : toggleSelect(e))}
      >
        {childrenCount > 0 && (
          <IconButton onClick={toggleChildren}>
            <ExpandMoreIcon sx={{ rotate: open ? '0deg' : '-90deg', transition: '.2s' }} />
          </IconButton>
        )}
        <IconButton onClick={toggleSelect}>
          <Checkbox checked={isSelected} disabled={disabled} />
        </IconButton>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography color={disabled ? 'secondary.medium' : 'secondary'}>{`${name} (${childrenCount})`}</Typography>
          {type && <AreaTypeChip type={type} />}
        </Stack>
      </Stack>
      {open && (
        <Box pl={5}>
          <AreaSelectItemChildren id={id} value={value} onChange={onChange} disabled={disabled || isSelected} />
        </Box>
      )}
    </>
  );
};
