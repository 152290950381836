import { SvgIcon, SvgIconProps } from '@mui/material';

const Dot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" r="4" />
    </SvgIcon>
  );
};

export default Dot;
