import Api, { Cloud, PatientGender } from '@ambuliz/sabri-core';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { ENVIRONMENT } from 'configuration';
import { useAuthentication } from 'core/authentication';
import { useState } from 'react';

const useExternalTransportModal = () => {
  const [src, setSource] = useState<string>();
  const [loading, setLoading] = useState(false);

  const { healthCenter } = useAuthentication();

  const MARCUS_EMBED_FARE_URI =
    (ENVIRONMENT.MARCUS_EMBED_WEB_URL || 'https://etablissement.stage.ambuliz.com') + '/embed/fare';

  const initSession = async () => {
    setLoading(true);
    try {
      await Cloud.createExternalSession({
        healthCenterId: healthCenter.id,
      });
    } catch (err) {
      toast.error(err.message || i18n.session.createError);
      return undefined;
    } finally {
      setLoading(false);
    }

    try {
      const user = await Api.User.currentAsync();
      return user?.getSessionToken();
    } catch (err) {
      toast.error(i18n.session.notFound);
    } finally {
      setLoading(false);
    }
  };

  const create = async (patient: {
    ipp: string;
    pan: string;
    lastName: string;
    firstName: string;
    birthdate: Date;
    gender: PatientGender;
  }) => {
    const sessionToken = await initSession();
    if (!sessionToken) {
      return;
    }
    setSource(
      `${MARCUS_EMBED_FARE_URI}/create?token=${sessionToken}${`&ipp=${patient.ipp}&lname=${patient.lastName}&fname=${
        patient.firstName
      }&bdate=${patient.birthdate}&sex=${patient.gender === 'FEMALE' ? 'f' : 'm'}&nda=${patient.pan}`}`
    );
  };

  const edit = async (reference: string) => {
    const sessionToken = await initSession();
    if (!sessionToken) {
      return;
    }
    setSource(`${MARCUS_EMBED_FARE_URI}/edit/${reference}?token=${sessionToken}`);
  };

  const viewDetails = async (reference: string) => {
    const sessionToken = await initSession();
    if (!sessionToken) {
      return;
    }
    setSource(`${MARCUS_EMBED_FARE_URI}/${reference}?token=${sessionToken}`);
  };

  const close = () => {
    setSource(undefined);
    setLoading(false);
  };

  return { src, loading, create, edit, viewDetails, close };
};

export default useExternalTransportModal;
