import { SvgIcon, SvgIconProps } from '@mui/material';

const PatientReadyIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M7 6.04a2.364 2.364 0 1 0 0-4.727A2.364 2.364 0 0 0 7 6.04Zm3.677-2.363a3.677 3.677 0 1 1-7.353 0 3.677 3.677 0 0 1 7.353 0Zm-7.966 7.365c-.7.957-.918 2.648-.896 3.415a.656.656 0 1 1-1.313.037c-.028-.99.24-2.986 1.15-4.228.934-1.276 2.504-2.247 4.933-2.247 2.048 0 3.492.69 4.463 1.668.234.235.208.612-.03.844-.279.273-.733.233-1.02-.033-.721-.669-1.8-1.166-3.413-1.166-2.03 0-3.198.787-3.874 1.71ZM14.18 9.488a.787.787 0 0 1 1.104 1.12l-5.254 5.18a.736.736 0 0 1-1.033 0l-2.878-2.836a.785.785 0 0 1 1.101-1.119l2.293 2.254 4.667-4.6Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default PatientReadyIcon;
