import { Link, Stack } from '@mui/material';
import { PageSection } from 'core/layout';
import { Route, Routes } from 'react-router-dom';
import AppointmentsPage from './appointments';
import PatientsPage from './patients';

const StepsPage = () => {
  return (
    <Stack spacing={4} paddingBottom={20}>
      <PageSection>
        <Stack direction="row" spacing={4} marginTop={6}>
          <Link href="patients">Patients</Link>
          <Link href="appointments">Rendez-vous</Link>
        </Stack>
      </PageSection>

      <Routes>
        <Route path="appointments" element={<AppointmentsPage />} />
        <Route path="patients/*" element={<PatientsPage />} />
        <Route path="*" element={<AppointmentsPage />} />
      </Routes>
    </Stack>
  );
};

export default StepsPage;
