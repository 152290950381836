import { ExternalPatientTransport, ExternalPatientTransportStatus } from '@ambuliz/sabri-core';
import { Chip, ChipProps, IconButton, Link, Stack, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import AmbulanceIcon from 'common/components/Icons/Ambulance';
import CalendarDayIcon from 'common/components/Icons/CalendarDay';
import CheckCircleIcon from 'common/components/Icons/CheckCircle';
import ExternalLinkIcon from 'common/components/Icons/ExternalLink';
import PlusIcon from 'common/components/Icons/Plus';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { PartialRecord } from 'ramda';
import React from 'react';
import { DETAILS_ROW_WIDTH } from '../DetailsDialog';
import { ExternalTransportStartAtHistory } from './ExternalTransportStartAtHistory';

type UpcomingPatientTransportRowProps = {
  upcomingPatientTransport?: ExternalPatientTransport;
  onUpcomingPatientTransportClick: (id: string) => void;
  onOrderExternalTransportClick: () => void;
};

export const UpcomingPatientTransportRow = ({
  upcomingPatientTransport,
  onUpcomingPatientTransportClick,
  onOrderExternalTransportClick,
}: UpcomingPatientTransportRowProps) => {
  const handleExternalLinkClick = (event: React.MouseEvent<HTMLElement>) => {
    if (upcomingPatientTransport) {
      event.stopPropagation();
      onUpcomingPatientTransportClick(upcomingPatientTransport?.externalId);
    }
  };

  return (
    <>
      <Stack padding={4} gap={2}>
        <Stack direction="row" spacing={4} alignItems="center">
          <Stack
            direction="row"
            spacing={2}
            padding={2}
            minWidth={DETAILS_ROW_WIDTH}
            alignItems="center"
            height="fit-content"
          >
            <Typography variant="body2" color="secondary">
              {i18n.upcomingExternalTransport}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={upcomingPatientTransport ? 'flex-end' : 'space-between'}
            flex={1}
          >
            {!upcomingPatientTransport && (
              <Typography variant="body2" color={color.grey[30]} padding="7px 8px">
                {i18n.toDefine}
              </Typography>
            )}
            <Link
              color="secondary"
              sx={{ '&:hover': { color: palette.primary.main } }}
              component="button"
              onClick={onOrderExternalTransportClick}
            >
              <Stack direction="row" alignItems="center">
                <PlusIcon fontSize="small" />
                <Typography variant="body2" marginLeft={2}>
                  {i18n.orderExternalTransport}
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </Stack>
        {upcomingPatientTransport && (
          <TransportRow transport={upcomingPatientTransport} onClick={handleExternalLinkClick} />
        )}
      </Stack>
    </>
  );
};

const properties: PartialRecord<
  ExternalPatientTransportStatus,
  { color?: ChipProps['color']; icon: React.ReactElement; label: string; style?: React.CSSProperties }
> = {
  PLANNED: {
    color: 'primary',
    icon: <CalendarDayIcon sx={{ color: palette.primary.medium }} />,
    label: i18n.ordered,
    style: { backgroundColor: 'primary.medium' },
  },
  VALIDATED: {
    color: 'success',
    icon: <CheckCircleIcon />,
    label: i18n.validated,
  },
  IN_PROGRESS: {
    color: 'success',
    icon: <CheckCircleIcon />,
    label: i18n.validated,
  },
};

const TransportRow = ({
  transport,
  onClick,
}: {
  transport: ExternalPatientTransport;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => (
  <Stack
    direction="row"
    padding="4px 8px"
    gap={1}
    borderRadius={1}
    border="1px solid"
    borderColor={color.grey[10]}
    bgcolor="primary.light"
    alignItems="center"
    onClick={onClick}
    sx={{ cursor: 'pointer' }}
  >
    <Stack direction="row" spacing={4} paddingLeft={2} width="100%">
      <Stack direction="row" padding="8px 8px 8px 0" gap={2} alignItems="center" width={DETAILS_ROW_WIDTH}>
        <AmbulanceIcon fontSize="small" color="secondary" />
        <EllipsisTypography variant="body2" fontWeight={600}>
          {transport.reason || i18n.externalTransports}
        </EllipsisTypography>
      </Stack>
      <ExternalTransportStartAtHistory transport={transport} />
    </Stack>
    <Stack padding="6px 8px">
      <Chip
        variant="light"
        label={properties[transport?.status]?.label}
        color={properties[transport?.status]?.color}
        style={properties[transport?.status]?.style}
        icon={properties[transport?.status]?.icon}
      />
    </Stack>
    <IconButton onClick={onClick} size="small">
      <ExternalLinkIcon />
    </IconButton>
  </Stack>
);
