import { useAuthentication } from 'core/authentication';
import { useCallback } from 'react';
import { openRocketChat } from './rocketChat';

export const useSupport = () => {
  const {
    healthCenter: { chatSupportEnabled },
  } = useAuthentication();

  const isChatSupportEnabled = chatSupportEnabled;

  const openSupport = useCallback(() => {
    if (isChatSupportEnabled) {
      openRocketChat();
    } else {
      window.open('mailto:support@silbo.com');
    }
  }, [isChatSupportEnabled]);

  return {
    isChatSupportEnabled,
    openSupport,
  };
};
