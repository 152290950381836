import { ArrowDropDown, DateRange } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import cls from 'classnames';
import { color } from 'common/theme';
import { format } from 'date-fns';
import React, { createRef, useEffect, useState } from 'react';
import { dateFilters, DateFilters } from '../../const/date';
import { useCloseMenu } from '../../hooks/useCloseMenu';
import { i18n } from '../../locales';
import { getStartDateFromDateFilter } from '../../services/date';
import { DateRangePickerDialog } from '../DateRangePickerDialog/DateRangePickerDialog';

type SelectorDateProps = {
  onSubmit: ({ start, end }: { start?: Date; end: Date }) => void;
};

export const SelectorDate: React.FC<SelectorDateProps> = ({ onSubmit }) => {
  const [dateFilter, setDateFilter] = useState<DateFilters>('FROM_THE_BEGINNING');
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(new Date());
  const [endDateFilter, setEndDateFilter] = useState(new Date());
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const refSelector = createRef<HTMLDivElement>();
  const [minWidthHeader, setMinWidthHeader] = useState<undefined | number>();
  const classes = useClasses({ minWidthHeader, isSelectorVisible });
  const { onMouseOver, onMouseLeave } = useCloseMenu(() => setIsSelectorVisible(false));

  useEffect(() => {
    if (refSelector.current) {
      setMinWidthHeader(refSelector.current.getBoundingClientRect().width);
    }
  }, [refSelector]);

  const handleSelectDateFilter = (item: DateFilters) => {
    const { start, end } = getStartDateFromDateFilter(item);
    setDateFilter(item);
    setIsSelectorVisible(false);
    if (item === 'CUSTOM') {
      setIsDialogVisible(true);
      return;
    }
    onSubmit({ start, end });
  };

  const handleSubmit = ({ start, end }: { start: Date; end: Date }) => {
    onSubmit({ start, end });
    setStartDateFilter(start);
    setEndDateFilter(end);
    setIsDialogVisible(false);
  };

  return (
    <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <div onClick={() => setIsSelectorVisible((prevValue) => !prevValue)} className={classes.header}>
        <DateRange className={classes.icon} />
        <div className={classes.title}>
          {dateFilter === 'CUSTOM'
            ? `${format(startDateFilter, 'dd/MM/yyyy')} - ${format(endDateFilter, 'dd/MM/yyyy')}`
            : i18n.dateFilters[dateFilter]}
        </div>
        <ArrowDropDown className={cls(classes.icon, classes.iconRight)} />
      </div>
      {isSelectorVisible && (
        <div ref={refSelector} className={classes.selector}>
          {dateFilters.map((item) => (
            <div onClick={() => handleSelectDateFilter(item)} key={item} className={classes.selectItem}>
              {i18n.dateFilters[item]}
            </div>
          ))}
        </div>
      )}
      {isDialogVisible && (
        <DateRangePickerDialog
          startInit={startDateFilter}
          endInit={endDateFilter}
          onSubmit={handleSubmit}
          onClose={() => setIsDialogVisible(false)}
        />
      )}
    </div>
  );
};

type SelectorDateStyles = {
  minWidthHeader?: number;
  isSelectorVisible: boolean;
};

const useClasses = makeStyles(({ spacing }) => ({
  header: ({ minWidthHeader }: SelectorDateStyles) => ({
    borderRadius: 3,
    border: '1px solid #DDDDDD',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: minWidthHeader ? minWidthHeader : 230,
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 11,
    paddingBottom: 11,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  title: ({ isSelectorVisible }: SelectorDateStyles) => ({
    marginLeft: spacing(1),
    fontWeight: 500,
    fontSize: 14,
    color: isSelectorVisible ? undefined : '#1A73E8',
  }),
  icon: {
    color: 'rgba(0,0,0,0.54)',
  },
  iconRight: {
    marginLeft: 'auto',
  },
  selector: {
    display: 'flex',
    borderRadius: 3,
    border: '1px solid #DDDDDD',
    minWidth: 230,
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 2,
    width: 'auto !important',
    flexDirection: 'column',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
  },
  selectItem: {
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: color.grey[5],
    },
  },
}));
