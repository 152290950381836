"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findUserConfigurationOrDefault = void 0;
const notifications_1 = require("../models/notifications");
const findUserConfigurationOrDefault = (user, triggerEvent) => {
    var _a;
    return (((_a = user.notificationConfigurations) === null || _a === void 0 ? void 0 : _a.find((config) => config.triggerEvent === triggerEvent)) ||
        notifications_1.DEFAULT_NOTIFICATION_CONFIGURATION[triggerEvent]);
};
exports.findUserConfigurationOrDefault = findUserConfigurationOrDefault;
