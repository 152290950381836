import { Drawer } from '@mui/material';
import { theme } from 'common/theme';
import { useAppBarContext } from 'core/layout';
import { useState } from 'react';
import PatientPlacementContainer from './PatientPlacementContainer';

export const PATIENT_PLACEMENT_SIDEBAR_WIDTH = 372;

type PatientPlacementSidebarProps = {
  unitIds: string[];
  isReadOnly?: boolean;
  open: boolean;
  isActionInProgress?: boolean;
  onClose: () => void;
};

const PatientPlacementSidebar = ({
  unitIds,
  isReadOnly = false,
  open,
  isActionInProgress = false,
  onClose,
}: PatientPlacementSidebarProps) => {
  const [enabled, setEnabled] = useState(false);
  const { appBarHeight } = useAppBarContext();

  return (
    <Drawer
      open={open}
      variant="persistent"
      anchor="right"
      onClose={onClose}
      onTransitionEnd={() => setEnabled(open)}
      hideBackdrop
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          marginTop: `${appBarHeight - 1}px`,
          width: PATIENT_PLACEMENT_SIDEBAR_WIDTH,
          boxSizing: 'border-box',
          overflow: 'auto',
          height: `calc(100% - ${appBarHeight}px)`,
        },
        zIndex: theme.zIndex.appBar - 1,
      }}
    >
      <PatientPlacementContainer
        unitIds={unitIds}
        enabled={enabled}
        onClose={onClose}
        isReadOnly={isReadOnly}
        isActionInProgress={isActionInProgress}
      />
    </Drawer>
  );
};

export default PatientPlacementSidebar;
