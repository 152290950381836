const MuiTimePicker = {
  defaultProps: {
    localeText: {
      fieldHoursPlaceholder: () => '--',
      fieldMinutesPlaceholder: () => '--',
    },
    slotProps: {
      textField: {
        sx: {
          maxWidth: 68,
          '& .MuiInputBase-sizeSmall': {
            maxWidth: 60,
          },
          '&:not(.MuiInputBase-sizeSmall) .MuiInputBase-adornedEnd.MuiFilledInput-root input, &:not(.MuiInputBase-sizeSmall) .MuiInputBase-adornedEnd.MuiOutlinedInput-root input':
            {
              paddingRight: '12px !important',
            },
          '& .MuiFilledInput-root.MuiInputBase-sizeSmall, .MuiFilledInput-root, .MuiOutlinedInput-root.MuiInputBase-sizeSmall, .MuiOutlinedInput-root,':
            {
              paddingRight: '0px !important',
            },
        },
        inputProps: {
          style: {
            textAlign: 'center',
          },
        },
      },
      inputAdornment: {
        style: {
          display: 'none',
        },
      },
    },
  },
};

export default MuiTimePicker;
