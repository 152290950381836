import { SvgIcon, SvgIconProps } from '@mui/material';

const ErrorCircleIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_749_5126)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8.0011 9.48657C7.65592 9.48657 7.3761 9.20675 7.3761 8.86157V4.37891C7.3761 4.03373 7.65592 3.75391 8.0011 3.75391C8.34628 3.75391 8.6261 4.03373 8.6261 4.37891V8.86157C8.6261 9.20675 8.34628 9.48657 8.0011 9.48657ZM8 10.9941C7.65422 10.9941 7.3739 11.2745 7.3739 11.6202C7.3739 11.966 7.65422 12.2463 8 12.2463C8.34579 12.2463 8.6261 11.966 8.6261 11.6202C8.6261 11.2745 8.34579 10.9941 8 10.9941Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_749_5126">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ErrorCircleIcon;
