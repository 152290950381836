import { WebUserRole } from '@ambuliz/sabri-core';
import { routeNamesCommon } from '../../../const/routeNames';
import { i18n } from '../../../locales';
import { FareFilterStatus } from '../../../store/selectors/fare';
import FareListFilter from './FareListFilter';

type FareListTab = {
  label: string;
  route?: string;
};

export const fareListTabs: Record<FareFilterStatus, FareListTab> = {
  active: {
    route: routeNamesCommon.fareListActive,
    label: i18n.faresFilterActive,
  },
  pending: {
    route: routeNamesCommon.fareListPending,
    label: i18n.faresFilterPending,
  },
  'in-progress': {
    route: routeNamesCommon.fareListInProgress,
    label: i18n.faresFilterInProgress,
  },
  'to-confirm-in': {
    route: routeNamesCommon.fareListToConfirmIn,
    label: i18n.faresFilterToConfirmIn,
  },
  'to-confirm-out': {
    route: routeNamesCommon.fareListToConfirmOut,
    label: i18n.faresFilterToConfirmOut,
  },
  draft: {
    route: routeNamesCommon.fareListDraft,
    label: i18n.faresFilterDraft,
  },
  done: {
    route: routeNamesCommon.fareListDone,
    label: i18n.faresFilterDone,
  },
};

export const isFilterStatusRoute = (route: string) => !!Object.values(fareListTabs).find((tab) => tab.route === route);

export const fareListTabsByRole = (role: WebUserRole) => {
  const tabs: FareListTab[] = [fareListTabs.active];

  if (['ADMIN_REGULATOR', 'ADMIN', 'REGULATOR'].includes(role)) {
    tabs.push(fareListTabs.pending, fareListTabs['in-progress']);
  } else if (role === 'MANAGER') {
    tabs.push(fareListTabs['to-confirm-in'], fareListTabs['to-confirm-out'], fareListTabs['in-progress']);
  }

  return [...tabs, fareListTabs.done];
};

export default FareListFilter;
