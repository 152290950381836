import Api, { Accommodation, AccommodationRequest, Patient, Unit } from '@ambuliz/sabri-core';
import { Button, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import useParseQuery from 'common/hooks/useParseQuery';
import { formatName } from 'common/utils';
import { ContentLoader, PageSection } from 'core/layout';
import { startOfToday, subDays } from 'date-fns';
import { useMemo, useState } from 'react';
import CreateAccommodationRequestModal from './CreateAccommodationRequestModal';
import MutationRow from './MutationRow';

type EmergenciesListProps = {
  unitId: string;
};

const EmergenciesList = ({ unitId }: EmergenciesListProps) => {
  const [selectedAccommodation, setSelectedAccommodation] = useState<Accommodation | undefined>();

  const { accommodations, loading: accommodationsLoading } = useAccommodations(unitId);

  const visits = useMemo(
    () => accommodations.filter((accommodation) => !!accommodation.visit).map(({ visit }) => visit) as Patient[],
    [accommodations]
  );

  const { accommodationRequests, loading: accommodationRequestsLoading } = useAccommodationRequests(visits);

  const handleAccommodationRequest = (accommodation: Accommodation) => {
    setSelectedAccommodation(accommodation);
  };

  return (
    <>
      <PageSection>
        <Stack spacing={4}>
          <Typography variant="h3">Patients dans le service</Typography>

          {accommodationsLoading ? (
            <ContentLoader />
          ) : (
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accommodations?.map(
                    (accommodation) =>
                      accommodation.visit && (
                        <TableRow key={accommodation.visit.id}>
                          <TableCell>{accommodation.visit.id}</TableCell>
                          <TableCell>
                            {formatName(accommodation.visit.firstName, accommodation.visit.lastName)}
                          </TableCell>

                          <TableCell>
                            <Button onClick={() => handleAccommodationRequest(accommodation)}>
                              Demande de mutation
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Stack>
      </PageSection>
      <PageSection>
        <Stack spacing={4}>
          <Typography variant="h3">Mutations en cours</Typography>
          {accommodationRequestsLoading || accommodationsLoading ? (
            <ContentLoader />
          ) : (
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Destination</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Statut hébergement</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accommodationRequests?.map((request) => (
                    <MutationRow key={request.id} request={request} />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Stack>
      </PageSection>
      {selectedAccommodation && (
        <CreateAccommodationRequestModal
          open={!!selectedAccommodation}
          onClose={() => setSelectedAccommodation(undefined)}
          accommodation={selectedAccommodation}
        />
      )}
    </>
  );
};

const useAccommodations = (unitId: string) => {
  const { results: accommodations, isLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .equalTo('unit', Unit.createWithoutData(unitId))
      .equalTo('status', 'IN_PROGRESS')
      .greaterThanOrEqualTo('startAt', subDays(startOfToday(), 1))
      .include('visit'),
    { enabled: !!unitId }
  );

  return {
    accommodations,
    loading: isLoading,
  };
};

const useAccommodationRequests = (visits: Patient[]) => {
  const { results: accommodationRequests, isLoading } = useParseQuery(
    new Api.Query(AccommodationRequest)
      .containedIn('visit', visits)
      .include('performerRequests', 'performerRequests.performer', 'visit'),
    { enabled: visits.length > 0 }
  );

  return {
    accommodationRequests,
    loading: isLoading,
  };
};

export default EmergenciesList;
