import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ListPagination } from 'common/components';
import { SortableTableCell } from 'common/components/Table';
import { usePagination } from 'common/hooks';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { ContentLoader } from 'core/layout';
import { BED_CELL_MIN_WIDTH, EDITABLE_TEXT_CELL_MIN_WIDTH, PATIENT_CELL_MIN_WIDTH } from 'kurt/const';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { MovementsFilter } from '../../useMovementsPageSearchParams';
import {
  DischargeAccommodationApprovedRow,
  DischargeAccommodationDischargedRow,
  DischargeAccommodationPlannedRow,
  DischargeMutationBlockedRow,
  DischargeMutationPendingRow,
} from '../MovementsListRow';
import NoResults from '../NoResults';
import useFilteredMovements from '../useFilteredMovements';
import useDischarges from './useDischarges';

const DischargeList = ({
  unitIds,
  date,
  filter,
  isReadOnly = false,
  onFilterCountChange,
}: {
  unitIds: string[];
  date: Date;
  filter: MovementsFilter;
  isReadOnly?: boolean;
  onFilterCountChange: (count: number) => void;
}) => {
  const { movements, loading } = useDischarges(date, unitIds);

  const { filteredMovements, order, orderBy, handleSort } = useFilteredMovements(
    movements,
    filter,
    onFilterCountChange,
    'discharge'
  );
  const { page, rowsPerPage, changePage, changeRowsPerPage } = usePagination(filteredMovements.length);

  const navigation = useRef(useNavigate());

  const handleClick = useCallback((id: string, type: 'accommodation' | 'mutation') => {
    navigation.current(`${window.location.pathname}/${type}/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  if (loading) {
    return <ContentLoader />;
  }

  if (filteredMovements.length === 0) {
    return <NoResults />;
  }

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <SortableTableCell
                sx={{ color: palette.text.primary, paddingLeft: 1 }}
                name="date"
                order={order}
                orderBy={orderBy}
                onSort={handleSort('date')}
              >
                {i18n.movementListViewHeads.date}
              </SortableTableCell>
              <SortableTableCell name="status" order={order} orderBy={orderBy} onSort={handleSort('status')}>
                {i18n.movementListViewHeads.status}
              </SortableTableCell>
              <SortableTableCell
                name="bed"
                order={order}
                orderBy={orderBy}
                onSort={handleSort('bed')}
                sx={{ minWidth: `${BED_CELL_MIN_WIDTH}px` }}
              >
                {i18n.movementListViewHeads.bed}
              </SortableTableCell>
              {unitIds.length > 1 && (
                <SortableTableCell name="unitName" order={order} orderBy={orderBy} onSort={handleSort('unitName')}>
                  {i18n.unit}
                </SortableTableCell>
              )}
              <SortableTableCell
                name="patientName"
                order={order}
                orderBy={orderBy}
                onSort={handleSort('patientName')}
                sx={{ minWidth: `${PATIENT_CELL_MIN_WIDTH}px` }}
              >
                {i18n.movementListViewHeads.patient}
              </SortableTableCell>
              <SortableTableCell
                name="patientBirthdate"
                order={order}
                orderBy={orderBy}
                onSort={handleSort('patientBirthdate')}
              >
                {i18n.movementListViewHeads.age}
              </SortableTableCell>
              <TableCell
                sx={{
                  minWidth: EDITABLE_TEXT_CELL_MIN_WIDTH,
                  width: '15%',
                }}
              >
                {i18n.movementListViewHeads.entryReason}
              </TableCell>
              <SortableTableCell
                name="specificities"
                order={order}
                orderBy={orderBy}
                onSort={handleSort('specificities')}
              >
                {i18n.movementListViewHeads.specificities}
              </SortableTableCell>
              <TableCell sx={{ minWidth: EDITABLE_TEXT_CELL_MIN_WIDTH, width: '15%' }}>{i18n.comments}</TableCell>
              {!isReadOnly && <TableCell>{i18n.movementListViewHeads.action}</TableCell>}
              {!isReadOnly && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMovements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((movement) => {
              switch (movement.status) {
                case 'accommodation_planned_discharge':
                  return (
                    <DischargeAccommodationPlannedRow
                      key={movement.id}
                      movement={movement}
                      showUnit={unitIds.length > 1}
                      onClick={handleClick}
                      isReadOnly={isReadOnly}
                    />
                  );

                case 'accommodation_confirmed_discharge':
                  return (
                    <DischargeAccommodationApprovedRow
                      key={movement.id}
                      movement={movement}
                      showUnit={unitIds.length > 1}
                      onClick={handleClick}
                      isReadOnly={isReadOnly}
                    />
                  );

                case 'accommodation_discharged':
                  return (
                    <DischargeAccommodationDischargedRow
                      key={movement.id}
                      movement={movement}
                      showUnit={unitIds.length > 1}
                      onClick={handleClick}
                      isReadOnly={isReadOnly}
                    />
                  );

                case 'outgoing_mutation_requested':
                  return (
                    <DischargeMutationPendingRow
                      key={movement.id}
                      movement={movement}
                      showUnit={unitIds.length > 1}
                      onClick={handleClick}
                      isReadOnly={isReadOnly}
                    />
                  );

                case 'outgoing_mutation_blocked':
                  return (
                    <DischargeMutationBlockedRow
                      key={movement.id}
                      movement={movement}
                      showUnit={unitIds.length > 1}
                      onClick={handleClick}
                      isReadOnly={isReadOnly}
                    />
                  );

                default:
                  return null;
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ListPagination
        count={filteredMovements.length}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={i18n.movementsByPage}
      />
    </Paper>
  );
};

export default DischargeList;
