import { createAction } from '@reduxjs/toolkit';

export type LoginPayload = {
  username: string;
  password: string;
};

export const loginType = 'general/login';
export const loginAction = createAction(loginType, (payload: LoginPayload) => ({
  payload,
}));

export const getCurrentUserType = 'general/getCurrentUser';
export const getCurrentUserAction = createAction(getCurrentUserType);

export const logoutType = 'general/logout';
export const logoutAction = createAction(logoutType);

export const resetType = 'general/reset';
export const resetAction = createAction(resetType);
