import { Card, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { color } from 'common/theme';
import HorizontalDivider from '../HorizontalDivider';

const SkeletonContent = () => (
  <Stack height="100%">
    <Stack direction="row">
      <SkeletonTab />
    </Stack>
    <Card sx={{ borderTopLeftRadius: 0, marginTop: '-1px', height: '100%' }} elevation={1}>
      <Stack divider={<HorizontalDivider />} flexGrow={1}>
        <Stack padding={4} spacing={4}>
          <Stack direction="row" spacing={2} padding={2} justifyContent="space-between">
            <Skeleton variant="rounded" width={150} height={12} />

            <Stack direction="row" spacing={2} alignItems="center">
              <Skeleton variant="rounded" width={12} height={12} />
              <Skeleton variant="rounded" width={82} height={8} />
              <Skeleton variant="rounded" width={82} height={8} />
            </Stack>
          </Stack>
        </Stack>

        <Stack padding={4} spacing={4}>
          {Array.from({ length: 2 }).map(() => (
            <Stack direction="row" padding={2} spacing={6}>
              <Skeleton variant="rounded" width={35} height={12} />
              <Skeleton variant="rounded" width={80} height={12} />
              <Skeleton variant="rounded" width={45} height={12} />
            </Stack>
          ))}
        </Stack>

        <Stack spacing={2} paddingLeft={6} paddingRight={1} marginTop="10px" flexGrow={1}>
          {Array.from({ length: 4 }).map(() => (
            <Stack direction="row" gap={4} paddingY={2} alignItems="center">
              <Stack direction="row" spacing={2} paddingY={1} width={200} alignItems="center">
                <Skeleton variant="rounded" width={12} height={12} />
                <Skeleton variant="rounded" width={140} height={8} />
              </Stack>

              <Skeleton variant="rounded" width="30%" height={8} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Card>
  </Stack>
);

const SkeletonTab = () => (
  <Box
    border={`1px solid ${color.grey[10]}`}
    borderBottom={0}
    paddingX={6}
    paddingY={4}
    bgcolor="white"
    zIndex={1}
    sx={{
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    }}
  >
    <Skeleton variant="rounded" width={60} height={8} />
  </Box>
);
export default SkeletonContent;
