import { addHours, subHours } from 'date-fns';

type SkeletonEventProps = {
  start: Date;
  end: Date;
};

const getSkeletonEvents = (period: 'week' | 'day' = 'week', index: number) => {
  const skeletons: SkeletonEventProps[] = [];
  const factor = period === 'week' ? 24 : 1;
  switch (index) {
    case 0:
      skeletons.push(
        { start: new Date(), end: addHours(new Date(), 4 * factor) },
        { start: addHours(new Date(), 4.5 * factor), end: addHours(new Date(), 7 * factor) },
        { start: addHours(new Date(), 7.05 * factor), end: addHours(new Date(), 8 * factor) },
        { start: addHours(new Date(), 10 * factor), end: addHours(new Date(), 14 * factor) }
      );
      break;
    case 1:
      skeletons.push(
        { start: subHours(new Date(), 0.5 * factor), end: addHours(new Date(), 2 * factor) },
        { start: addHours(new Date(), 2.05 * factor), end: addHours(new Date(), 3.5 * factor) },
        { start: addHours(new Date(), 7 * factor), end: addHours(new Date(), 9.5 * factor) }
      );
      break;
    case 2:
      skeletons.push(
        { start: subHours(new Date(), 1 * factor), end: new Date() },
        { start: addHours(new Date(), 0.5 * factor), end: addHours(new Date(), 2.5 * factor) },
        { start: addHours(new Date(), 3.5 * factor), end: addHours(new Date(), 5 * factor) },
        { start: addHours(new Date(), 8.5 * factor), end: addHours(new Date(), 11.5 * factor) }
      );
      break;
    case 3:
      skeletons.push(
        { start: subHours(new Date(), 1 * factor), end: subHours(new Date(), 0.5 * factor) },
        { start: addHours(new Date(), 1 * factor), end: addHours(new Date(), 2.5 * factor) },
        { start: addHours(new Date(), 2.55 * factor), end: addHours(new Date(), 6 * factor) },
        { start: addHours(new Date(), 7 * factor), end: addHours(new Date(), 9 * factor) },
        { start: addHours(new Date(), 9.05 * factor), end: addHours(new Date(), 10 * factor) }
      );
      break;
    case 4:
      skeletons.push(
        { start: subHours(new Date(), 0.5 * factor), end: addHours(new Date(), 3 * factor) },
        { start: addHours(new Date(), 4.5 * factor), end: addHours(new Date(), 8 * factor) },
        { start: addHours(new Date(), 9.5 * factor), end: addHours(new Date(), 12 * factor) }
      );
      break;
    case 5:
      skeletons.push(
        { start: addHours(new Date(), 1.5 * factor), end: addHours(new Date(), 3 * factor) },
        { start: addHours(new Date(), 3.05 * factor), end: addHours(new Date(), 4 * factor) },
        { start: addHours(new Date(), 5.5 * factor), end: addHours(new Date(), 7.5 * factor) },
        { start: addHours(new Date(), 7.55 * factor), end: addHours(new Date(), 11 * factor) }
      );
      break;
    case 6:
      skeletons.push(
        { start: new Date(), end: addHours(new Date(), 3.5 * factor) },
        { start: addHours(new Date(), 5.5 * factor), end: addHours(new Date(), 7.5 * factor) },
        { start: addHours(new Date(), 8 * factor), end: addHours(new Date(), 9 * factor) },
        { start: addHours(new Date(), 7.55 * factor), end: addHours(new Date(), 11 * factor) }
      );
      break;
    case 7:
      skeletons.push(
        { start: addHours(new Date(), 1 * factor), end: addHours(new Date(), 4.5 * factor) },
        { start: addHours(new Date(), 6 * factor), end: addHours(new Date(), 9.5 * factor) }
      );
      break;
    case 8:
    default:
      skeletons.push(
        { start: subHours(new Date(), 0.5 * factor), end: addHours(new Date(), 3 * factor) },
        { start: addHours(new Date(), 3.05 * factor), end: addHours(new Date(), 5 * factor) },
        { start: addHours(new Date(), 6 * factor), end: addHours(new Date(), 9.5 * factor) }
      );
      break;
    case 9:
      skeletons.push(
        { start: subHours(new Date(), 1 * factor), end: addHours(new Date(), 10 * factor) },
        { start: addHours(new Date(), 10.05 * factor), end: addHours(new Date(), 14 * factor) }
      );
      break;

    case 10:
      skeletons.push(
        { start: addHours(new Date(), 1 * factor), end: addHours(new Date(), 3 * factor) },
        { start: addHours(new Date(), 3.05 * factor), end: addHours(new Date(), 5 * factor) },
        { start: addHours(new Date(), 7 * factor), end: addHours(new Date(), 8.5 * factor) }
      );
      break;
    case 11:
      skeletons.push(
        { start: addHours(new Date(), 2 * factor), end: addHours(new Date(), 4.5 * factor) },
        { start: addHours(new Date(), 5.5 * factor), end: addHours(new Date(), 7.5 * factor) },
        { start: addHours(new Date(), 7 * factor), end: addHours(new Date(), 8.5 * factor) }
      );
      break;
    case 12:
      skeletons.push({ start: subHours(new Date(), 0.5 * factor), end: addHours(new Date(), 13.5 * factor) });
      break;
  }

  return skeletons;
};

export default getSkeletonEvents;
