import { getAvailableBeds } from '@ambuliz/sabri-core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { progressValue } from 'common/utils';
import { getThresholdedColor } from 'kurt/const';
import { NormalizedUnit } from 'kurt/hooks/useDepartments';

type AccommodatedDetailsDialogProps = {
  open: boolean;
  onClose?: () => void;
  units?: NormalizedUnit[];
};

const AccommodatedDetailsDialog = ({ open, onClose, units = [] }: AccommodatedDetailsDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" scroll="body">
      <DialogHeader onClose={onClose} title={i18n.accommodatedDetails} />
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.units}</TableCell>
              <TableCell>{i18n.accommodatedAccommodations}</TableCell>
              <TableCell>{i18n.availableBeds}</TableCell>
              <TableCell>{i18n.globalOccupancyRate}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map(({ id, name, occupancy, capacityRate }) => (
              <TableRow key={id}>
                <TableCell>
                  <Typography fontWeight={600}>{name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{occupancy?.accomodated ?? '-'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {getAvailableBeds(
                      occupancy?.dDayAvailabilities?.male,
                      occupancy?.dDayAvailabilities?.female,
                      occupancy?.dDayAvailabilities?.indifferent
                    ) ?? '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <LinearProgress
                      style={{ width: 48 }}
                      value={progressValue(capacityRate)}
                      color={getThresholdedColor(capacityRate)}
                    />
                    <Typography>{capacityRate ? `${capacityRate}%` : '-'}</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{i18n.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccommodatedDetailsDialog;
