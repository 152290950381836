import Api, { Area } from '@ambuliz/sabri-core';

export const getBreadCrumb = async (areaId: string, areas: Area[]): Promise<Area[]> => {
  const area = await new Api.Query(Area).get(areaId);

  if (area.parent?.id) {
    const parent = await new Api.Query(Area).get(area.parent.id);
    if (parent?.parent) {
      return getBreadCrumb(parent?.parent.id, [parent, area, ...areas]);
    }
    return [parent, area, ...areas];
  }

  return [area, ...areas];
};
