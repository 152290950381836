import { AlertTitle, Button, IconButton, Alert as MuiAlert, Stack, Typography } from '@mui/material';
import sound from 'common/assets/audio/notification.mp3';
import { Cross } from 'common/components/Icons';
import { color } from 'common/theme';
import React, { ReactNode, useEffect } from 'react';

type Severity = 'success' | 'info' | 'warning' | 'error';

export type AlertProps = {
  content: string | ReactNode;
  severity?: Severity;
  icon?: ReactNode;
  withSound?: boolean;
  title?: string | ReactNode;
  actionLabel?: string;
  onActionClick?: () => void;
  onClose?: () => void;
};

const Alert: React.FC<AlertProps> = ({
  content,
  severity = 'success',
  icon,
  withSound,
  title,
  actionLabel,
  onClose,
  onActionClick,
}) => {
  useEffect(() => {
    if (withSound) {
      new Audio(sound).play();
    }
  }, [withSound]);

  return (
    <MuiAlert
      severity={severity}
      icon={icon ? icon : undefined}
      action={
        (onActionClick && actionLabel) || onClose ? (
          <Stack alignItems="center" direction="row" spacing={2}>
            {onActionClick && actionLabel && (
              <Button
                variant="text"
                onClick={(event) => {
                  event.stopPropagation();
                  onActionClick();
                }}
                color="secondary"
              >
                {actionLabel}
              </Button>
            )}
            {onClose && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
              >
                <Cross />
              </IconButton>
            )}
          </Stack>
        ) : undefined
      }
      sx={{ border: `1px solid ${borderColor[severity]}` }}
    >
      {title && (
        <AlertTitle>
          <Typography variant="h4">{title}</Typography>
        </AlertTitle>
      )}
      {typeof content === 'string' ? <Typography>{content}</Typography> : <div>{content}</div>}
    </MuiAlert>
  );
};

const borderColor: Record<Severity, string> = {
  error: color.red[20],
  warning: color.yellow[20],
  info: color.blue[20],
  success: color.green[20],
};

export default Alert;
