import { Accommodation, AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Box, Card, Stack, TextField, Typography } from '@mui/material';
import { DateTimeInput } from 'common/components';
import ClockIcon from 'common/components/Icons/Clock';
import CommentIcon from 'common/components/Icons/Comment';
import ConstructionIcon from 'common/components/Icons/ConstructionIcon';
import ListIcon from 'common/components/Icons/List';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { format, formatDistanceStrict, formatDistanceToNow, isPast } from 'date-fns';
import fr from 'date-fns/locale/fr';
import SpecificityAutocomplete from 'kurt/components/SpecificityAutocomplete';
import { SpecificityChip } from 'kurt/components/SpecifityChip';
import { useContext } from 'react';
import { getStepSchedule } from '../../DetailsDialogContent';
import DetailsRow, { DETAILS_ROW_MIN_HEIGHT } from '../../DetailsRow';
import DialogTabs from '../../DialogTabs';
import DialogEditButtons from '../../EditButtons';
import HorizontalDivider from '../../HorizontalDivider';
import { DialogContext } from '../../context/DialogContext';

type ClosedBedDetailsProps = {
  accommodation: Accommodation;
  editable?: boolean;
  isReadOnly?: boolean;
  onChange?: (accommodation: Accommodation) => void;
};

const ClosedBedDetails = ({ accommodation, editable, isReadOnly = false, onChange }: ClosedBedDetailsProps) => {
  const { currentStep } = useContext(DialogContext);

  const handleStartAtChange = (startAt: Date | null) => {
    if (!startAt) return;
    const clone = Accommodation.fromJSON({
      ...accommodation.toJSON(),
      className: 'Accommodation',
    }) as Accommodation;
    clone.startAt = startAt;
    onChange?.(clone);
  };

  const handleEndAtChange = (endAt: Date | null) => {
    const clone = Accommodation.fromJSON({
      ...accommodation.toJSON(),
      className: 'Accommodation',
    }) as Accommodation;
    clone.endAt = endAt || undefined;
    onChange?.(clone);
  };
  const handleCommentChange = (comment: string) => {
    const clone = Accommodation.fromJSON({
      ...accommodation.toJSON(),
      className: 'Accommodation',
    }) as Accommodation;
    clone.comment = comment;
    onChange?.(clone);
  };

  const handleSpecificitiesChange = (specificities: AccommodationSpecificity[]) => {
    const clone = Accommodation.fromJSON({
      ...accommodation.toJSON(),
      className: 'Accommodation',
    }) as Accommodation;

    clone.specificities = specificities;
    onChange?.(clone);
  };

  const isAccommodationPast = isPast(accommodation.startAt);
  const since = formatDistanceToNow(accommodation.startAt, { locale: fr, includeSeconds: true });

  const duration = accommodation.endAt
    ? formatDistanceStrict(accommodation.endAt, accommodation.startAt, { locale: fr })
    : null;

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <DialogTabs
          options={[
            {
              label: i18n.detailsDialog.tabs[getStepSchedule(accommodation, [])],
              Icon: <ConstructionIcon />,
              value: accommodation.id,
              iconColor: palette.secondary.main,
            },
          ]}
          value={accommodation.id}
          onChange={() => {}}
        />
        {!isReadOnly && (
          <Box alignSelf={'flex-start'}>
            <DialogEditButtons currentStep={currentStep} />
          </Box>
        )}
      </Stack>
      <Card sx={{ borderTopLeftRadius: 0, marginTop: '-1px' }} elevation={1}>
        <Stack divider={<HorizontalDivider />}>
          <Stack direction="row" gap={4} padding="24px 16px">
            <Stack direction="row" gap={1} alignItems="center">
              <Stack direction="row" gap={2} alignItems="center">
                <ClockIcon color="secondary" sx={{ fontSize: 12 }} />
                <Typography variant="body2" fontWeight={600} color="secondary">
                  {i18n.duration.short.label}
                </Typography>
              </Stack>
              <Typography color="secondary" variant="body2">
                {duration || '-'}
              </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography variant="body2" fontWeight={600} color="secondary">
                {isAccommodationPast ? i18n.since : i18n.in}
              </Typography>
              <Typography color="secondary" variant="body2">
                {since}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" padding={4}>
            <Stack spacing={4} flex={1}>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="body2" color="secondary" paddingX={2} minWidth={53}>
                  {i18n.start}
                </Typography>
                <Stack direction="row" gap={2} flex={1}>
                  {editable ? (
                    <DateTimeInput
                      onChange={handleStartAtChange}
                      value={accommodation.startAt}
                      maxDate={accommodation.endAt}
                      error={accommodation.endAt && accommodation.startAt > accommodation.endAt}
                      size="small"
                      variant="filled"
                    />
                  ) : (
                    <>
                      <Typography padding="7px 8px">{format(accommodation.startAt, 'dd/MM/yyyy')}</Typography>
                      <Typography padding="7px 8px">{format(accommodation.startAt, 'HH : mm')}</Typography>
                    </>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="body2" color="secondary" paddingX={2} minWidth={53}>
                  {i18n.end}
                </Typography>
                <Stack direction="row" gap={2} flex={1}>
                  {editable ? (
                    <DateTimeInput
                      onChange={handleEndAtChange}
                      value={accommodation.endAt}
                      minDate={accommodation.startAt}
                      error={accommodation.endAt && accommodation.startAt > accommodation.endAt}
                      size="small"
                      variant="filled"
                    />
                  ) : accommodation.endAt ? (
                    <>
                      <Typography padding="7px 8px">{format(accommodation.endAt, 'dd/MM/yyyy')}</Typography>
                      <Typography padding="7px 8px">{format(accommodation.endAt, 'HH : mm')}</Typography>
                    </>
                  ) : (
                    <>
                      <Typography padding="7px 8px" color="secondary.medium" minWidth={91}>
                        {i18n.toDefine}
                      </Typography>
                      <Typography padding="7px 8px" color="secondary.medium">
                        {i18n.detailsDialog.common.noEndTime}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack padding={4}>
            <Stack spacing={2} paddingLeft={2}>
              <DetailsRow
                Icon={ListIcon}
                label={i18n.specificities}
                value={
                  editable ? (
                    <SpecificityAutocomplete
                      value={accommodation.specificities || []}
                      onChange={handleSpecificitiesChange}
                    />
                  ) : (
                    accommodation.specificities?.length && (
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        flexWrap="wrap"
                        minHeight={DETAILS_ROW_MIN_HEIGHT}
                      >
                        {accommodation.specificities.map((specificity) => (
                          <SpecificityChip
                            key={specificity}
                            specificity={specificity}
                            sx={{ backgroundColor: palette.background.default }}
                          />
                        ))}
                      </Stack>
                    )
                  )
                }
              />
              <DetailsRow
                Icon={CommentIcon}
                label={i18n.comment}
                value={
                  editable ? (
                    <TextField
                      multiline
                      value={accommodation.comment}
                      onChange={(event) => handleCommentChange(event.target.value)}
                      fullWidth
                      variant="filled"
                      size="small"
                    />
                  ) : (
                    accommodation.comment && (
                      <Typography variant="body2" whiteSpace="pre-line" padding={2}>
                        {accommodation.comment}
                      </Typography>
                    )
                  )
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default ClosedBedDetails;
