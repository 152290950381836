import { DoubleArrow } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useState } from 'react';
import { i18n } from '../../locales';

type DateRangePickerDialogProps = {
  startInit?: Date;
  endInit?: Date;
  onClose: () => void;
  onSubmit: ({ start, end }: { start: Date; end: Date }) => void;
};

export const DateRangePickerDialog: React.FC<DateRangePickerDialogProps> = ({
  startInit,
  endInit,
  onClose,
  onSubmit,
}) => {
  const [start, setStart] = useState<Date>(startInit || new Date());
  const [end, setEnd] = useState<Date>(endInit || new Date());

  const handleChangeStart = (date: Date | null) => {
    if (date) {
      const start = startOfDay(date);
      setStart(start);
    }
  };

  const handleChangeEnd = (date: Date | null) => {
    if (date) {
      const end = endOfDay(date);
      setEnd(end);
    }
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open>
      <DialogContent>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <DatePicker
              maxDate={end}
              disableFuture
              value={start}
              onChange={handleChangeStart}
              localeText={{
                fieldDayPlaceholder: () => 'jj',
                fieldMonthPlaceholder: () => 'mm',
                fieldYearPlaceholder: () => 'aaaa',
              }}
            />
          </Grid>
          <Grid item>
            <DoubleArrow />
          </Grid>
          <Grid item>
            <DatePicker
              onChange={handleChangeEnd}
              disableFuture
              minDate={start}
              value={end}
              localeText={{
                fieldDayPlaceholder: () => 'jj',
                fieldMonthPlaceholder: () => 'mm',
                fieldYearPlaceholder: () => 'aaaa',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{i18n.cancel}</Button>
        <Button color="primary" variant="contained" onClick={() => onSubmit({ start, end })}>
          {i18n.apply}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
