import Api, { Fare, Patient } from '@ambuliz/sabri-core';
import { toast } from 'common/toast';
import { routerActions } from 'connected-react-router';
import { liveQueryClient } from 'core/live-query-client';
import { batch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { Action, Dispatch, MiddlewareAPI } from 'redux';
import { modalNames } from '../../const/modalNames';
import { requestNames, requests } from '../../const/requests';
import { routeNamesCommon, routeNamesCommonDynamic } from '../../const/routeNames';
import { EntityNames, entityNameToSchema, fareSchema } from '../../const/schemas';
import { i18n } from '../../locales';
import { cleanNormalize } from '../../utils/cleanNormalize';
import { subscribeLiveQueriesType, unsubscribeLiveQueriesType } from '../actions/liveQuery';
import { entityActions } from '../reducers/entity';
import { modalActions } from '../reducers/modal';
import { notificationActions } from '../reducers/notification';
import { requestStatusActions } from '../reducers/requestStatus';
import { selectIsAutodispatchEnabled } from '../selectors/fareAssignation';
import { selectCurrentUser, selectUserRole } from '../selectors/general';
import { AppState } from '../store';

let liveSubscription: Parse.LiveQuerySubscription | undefined;

const handleClickToast = (fareId: string, store: MiddlewareAPI<Dispatch, AppState>) => {
  const routeMatchFareDetails = matchPath(window.location.pathname, routeNamesCommon.fareDetails);
  if (routeMatchFareDetails) {
    //@ts-ignore
    if (routeMatchFareDetails.params.id === fareId) {
      return;
    }
    store.dispatch(
      modalActions.showModal({
        modalName: modalNames.confirm,
        params: {
          title: i18n.caution,
          content: i18n.cautionLeaveFareDetailsToastClicked,
          onConfirm: () => {
            batch(() => {
              store.dispatch(modalActions.hideModal(modalNames.confirm));
              store.dispatch(routerActions.replace(routeNamesCommonDynamic.fareDetails(fareId)));
            });
          },
        },
      })
    );
    return;
  }
  store.dispatch(routerActions.replace(routeNamesCommonDynamic.fareDetails(fareId)));
};

const onFareCreated = (fare: Fare, store: MiddlewareAPI<Dispatch, AppState>) => {
  const isAutodispatchEnabled = selectIsAutodispatchEnabled(store.getState());

  const currentUser = selectCurrentUser(store.getState());
  if (fare.createdBy?.id !== currentUser?.objectId) {
    if (window.document.hasFocus()) {
      toast.info(
        {
          message: isAutodispatchEnabled ? i18n.toastNewFareInfo : i18n.toastNewFareRequest,
        },
        { onClick: () => handleClickToast(fare.id, store) }
      );
    } else if (fare.reference) {
      store.dispatch(
        notificationActions.showNotification({
          ...i18n.notificationCreated(fare.reference),
          redirection: routeNamesCommonDynamic.fareDetails(fare.id),
        })
      );
    }
  }
};

const onCreate = (store: MiddlewareAPI<Dispatch, AppState>) => (fare: any) => {
  const { entities } = cleanNormalize(fare.toJSON(), fareSchema);
  store.dispatch(entityActions.setEntities(entities));
  const role = selectUserRole(store.getState());

  if (role === 'REGULATOR' || role === 'ADMIN_REGULATOR') {
    onFareCreated(fare, store);
  }
};

const onUpdate = (store: MiddlewareAPI<Dispatch, AppState>) => (fare: any) => {
  const { entities } = cleanNormalize(fare.toJSON(), fareSchema);
  store.dispatch(entityActions.updateEntity({ entityName: EntityNames.fares, newObject: entities.fares }));
};

const onDelete = (store: MiddlewareAPI<Dispatch, AppState>) => (fare: any) => {
  store.dispatch(entityActions.removeEntity({ entityName: EntityNames.fares, objectId: fare.id }));
};

const onOpen = (dispatch: Dispatch) => async () => {
  try {
    const fares = await requests.getFares();
    const { entities } = cleanNormalize(
      fares.map((entity) => entity.toJSON()),
      [entityNameToSchema.fares]
    );

    const patientIds = Object.values(entities.fares).map((fare: any) => fare.patientId);

    const patients = await new Api.Query(Patient).containedIn('objectId', patientIds).findAll();
    const { entities: normalizedPatients } = cleanNormalize(
      patients.map((patient) => patient.toJSON()),
      [entityNameToSchema.patients]
    );
    entities.patients = normalizedPatients.patients;

    dispatch(entityActions.setEntities(entities));

    dispatch(requestStatusActions.setRequestStatus({ key: requestNames.getFares, status: 'SUCCESS' }));
  } catch (error) {
    dispatch(requestStatusActions.setRequestStatus({ key: requestNames.getFares, status: 'FAILED' }));
  }
};

export const fareLiveQueryMiddleware =
  (store: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => async (action: Action) => {
    const result = next(action);

    if (action.type === subscribeLiveQueriesType) {
      liveSubscription = liveQueryClient.subscribe(
        new Api.Query(Fare).notEqualTo('status', 'QUEUED'),
        Parse.User.current()?.getSessionToken()
      );
      if (!liveSubscription) throw Error('liveSubscription is undefined');
      liveSubscription.on('open', onOpen(store.dispatch));
      liveSubscription.on('create', onCreate(store));
      liveSubscription.on('enter', onCreate(store));
      liveSubscription.on('update', onUpdate(store));
      liveSubscription.on('delete', onDelete(store));
      liveSubscription.on('leave', onDelete(store));
    }

    if (action.type === unsubscribeLiveQueriesType && liveSubscription) {
      liveQueryClient.unsubscribe(liveSubscription);
    }

    return result;
  };
