import { Cloud } from '@ambuliz/sabri-core';
import { useState } from 'react';

const usePerformerRequest = () => {
  const [loading, setLoading] = useState(false);

  const create = async (requestId: string, performerId: string) => {
    setLoading(true);
    try {
      await Cloud.createPerformerRequest({ requestId, performerId });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const accept = async (id: string, startAt?: Date) => {
    if (id && startAt) {
      setLoading(true);

      try {
        await Cloud.acceptPerformerRequest({ id, startAt });
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  const reject = async (id: string, reason?: string) => {
    if (id) {
      setLoading(true);
      try {
        await Cloud.rejectPerformerRequest({ id, reason });
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  return { create, accept, reject, loading };
};

export default usePerformerRequest;
