import { Components } from '@mui/material';
import color from '../color';
import palette from '../palette';

declare module '@mui/material/IconButton' {
  interface IconButtonOwnProps {
    variant?: 'contained' | 'filled' | 'outlined';
    shape?: 'rounded' | 'circle';
  }
  interface IconButtonPropsSizeOverrides {
    'extra-large': true;
  }
  interface IconButtonPropsColorOverrides {
    danger: true;
  }
}

const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    size: 'medium',
    variant: 'contained',
    shape: 'circle',
    color: 'default',
  },
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
  variants: [
    {
      props: { color: 'default' },
      style: {
        color: palette.secondary.main,
        ':hover': {
          backgroundColor: palette.primary.light,
        },
      },
    },
    {
      props: { color: 'primary' },
      style: {
        color: palette.primary.main,
        ':hover': {
          backgroundColor: palette.primary.light,
        },
      },
    },
    {
      props: { color: 'secondary' },
      style: {
        color: palette.secondary.main,
        ':hover': {
          backgroundColor: palette.secondary.light,
        },
      },
    },
    {
      props: { color: 'error' },
      style: {
        color: palette.error.main,
        ':hover': {
          backgroundColor: palette.error.light,
        },
      },
    },
    {
      props: { color: 'info' },
      style: {
        color: palette.info.main,
        ':hover': {
          backgroundColor: palette.info.light,
        },
      },
    },
    {
      props: { color: 'success' },
      style: {
        color: palette.success.main,
        ':hover': {
          backgroundColor: palette.success.light,
        },
      },
    },
    {
      props: { color: 'warning' },
      style: {
        color: palette.warning.main,
        ':hover': {
          backgroundColor: palette.warning.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'default' },
      style: {
        backgroundColor: palette.background.default,
        '&:hover': {
          backgroundColor: palette.secondary.light,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'default' },
      style: {
        border: '1px solid ' + color.grey[30],
        backgroundColor: 'white',
        opacity: 1,
        '&:hover': {
          backgroundColor: palette.primary.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'primary' },
      style: {
        backgroundColor: palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: palette.primary.dark,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'primary' },
      style: {
        backgroundColor: 'white',
        color: palette.primary.main,
        border: '1px solid ' + palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'secondary' },
      style: {
        backgroundColor: palette.secondary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: color.grey[70],
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: {
        backgroundColor: 'white',
        color: palette.secondary.main,
        border: '1px solid ' + palette.secondary.main,
        '&:hover': {
          backgroundColor: palette.secondary.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'danger' },
      style: {
        backgroundColor: palette.danger.main,
        color: 'white',
        '&:hover': {
          backgroundColor: color.orange[60],
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'danger' },
      style: {
        backgroundColor: 'white',
        color: palette.danger.main,
        border: '1px solid ' + palette.danger.main,
        '&:hover': {
          backgroundColor: palette.danger.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'warning' },
      style: {
        backgroundColor: palette.warning.main,
        color: 'white',
        '&:hover': {
          backgroundColor: color.yellow[80],
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'warning' },
      style: {
        backgroundColor: 'white',
        color: palette.warning.main,
        border: '1px solid ' + palette.warning.main,
        '&:hover': {
          backgroundColor: palette.warning.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'success' },
      style: {
        backgroundColor: palette.success.main,
        color: 'white',
        '&:hover': {
          backgroundColor: color.green[70],
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'success' },
      style: {
        backgroundColor: 'white',
        color: palette.success.main,
        border: '1px solid ' + palette.success.main,
        '&:hover': {
          backgroundColor: palette.success.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'error' },
      style: {
        backgroundColor: palette.error.main,
        color: 'white',
        '&:hover': {
          backgroundColor: color.red[70],
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'error' },
      style: {
        backgroundColor: 'white',
        color: palette.error.main,
        border: '1px solid ' + palette.error.main,
        '&:hover': {
          backgroundColor: palette.error.light,
        },
      },
    },
    {
      props: { variant: 'filled', color: 'info' },
      style: {
        backgroundColor: color.blue[30],
        color: 'white',
        '&:hover': {
          backgroundColor: color.blue[40],
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'info' },
      style: {
        backgroundColor: 'white',
        color: color.blue[30],
        border: '1px solid ' + color.blue[30],
        '&:hover': {
          backgroundColor: palette.primary.light,
        },
      },
    },
    {
      props: { shape: 'circle' },
      style: {
        borderRadius: '50%',
        '& .MuiTouchRipple-root .MuiTouchRipple-child': {
          borderRadius: '50%',
        },
      },
    },
    {
      props: { shape: 'rounded' },
      style: {
        borderRadius: '4px',
        '& .MuiTouchRipple-root .MuiTouchRipple-child': {
          borderRadius: '4px',
        },
      },
    },
    {
      props: { shape: 'rounded', size: 'large' },
      style: {
        borderRadius: '8px',
        '& .MuiTouchRipple-root .MuiTouchRipple-child': {
          borderRadius: '8px',
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: 20,
        width: 20,
        '& .MuiSvgIcon-root': {
          height: 12,
          width: 12,
        },
      },
    },
    {
      props: { size: 'small', edge: 'start' },
      style: {
        marginLeft: -4,
      },
    },
    {
      props: { size: 'small', edge: 'end' },
      style: {
        marginRight: -4,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: 28,
        width: 28,
        '& .MuiSvgIcon-root': {
          height: 16,
          width: 16,
        },
      },
    },
    {
      props: { size: 'medium', edge: 'start' },
      style: {
        marginLeft: -4,
      },
    },
    {
      props: { size: 'medium', edge: 'end' },
      style: {
        marginRight: -4,
      },
    },
    {
      props: { size: 'large' },
      style: {
        height: 40,
        width: 40,
        '& .MuiSvgIcon-root': {
          height: 16,
          width: 16,
        },
      },
    },
    {
      props: { size: 'large', edge: 'start' },
      style: {
        marginLeft: -12,
      },
    },
    {
      props: { size: 'large', edge: 'end' },
      style: {
        marginRight: -12,
      },
    },
    {
      props: { size: 'extra-large' },
      style: {
        height: 60,
        width: 60,
        '& .MuiSvgIcon-root': {
          height: 28,
          width: 28,
        },
      },
    },
    {
      props: { size: 'extra-large', edge: 'start' },
      style: {
        marginLeft: -16,
      },
    },
    {
      props: { size: 'extra-large', edge: 'end' },
      style: {
        marginRight: -16,
      },
    },
    {
      props: { size: 'extra-large', shape: 'rounded' },
      style: {
        borderRadius: '8px',
      },
    },
  ],
};

export default MuiIconButton;
