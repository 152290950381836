import Api, { Anomaly, AnomalyStatus, AnomalyType, Area, Cloud } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useEffect, useMemo, useState } from 'react';
import { Order } from 'common/types';
import { compareValues } from 'kurt/pages/Emergency/utils';
import { toast } from 'common/toast';

export type AnomaliesOrderBy = 'startAt' | 'endAt' | 'type' | 'area' | 'status';

export type AnomaliesOrderParams = {
  orderBy: AnomaliesOrderBy;
  order: Order;
};

type FilterParams = {
  status?: AnomalyStatus;
  type?: AnomalyType;
  areaId?: string;
};

type PaginationParams = {
  page: number;
  rowsPerPage: number;
};

type UseAnomaliesParams = AnomaliesOrderParams & FilterParams & PaginationParams;

const useAnomalies = (
  areas: string[],
  { orderBy, order, status = 'OPENED', type = 'OUTDATED_START', areaId, page, rowsPerPage }: UseAnomaliesParams
) => {
  const query = useMemo(() => {
    let anomalyQuery = new Api.Query(Anomaly)
      .include(['accommodation', 'area'])
      .limit(rowsPerPage)
      .skip(page * rowsPerPage);
    if (areaId) {
      anomalyQuery.equalTo('area', Area.createWithoutData(areaId));
    } else if (areas) {
      anomalyQuery.containedIn(
        'area',
        areas.map((id) => Area.createWithoutData(id))
      );
    }

    if (status) {
      anomalyQuery?.equalTo('status', status);
    }

    if (type) {
      anomalyQuery?.equalTo('type', type);
    }

    return anomalyQuery;
  }, [page, rowsPerPage, areas, areaId, status, type]);
  const { results, isLoading: loading } = useParseQuery(query || null);
  const [count, setCount] = useState(0);
  const [anomalies, setAnomalies] = useState<Anomaly[]>([]);

  useEffect(() => {
    if (results && !loading && query) {
      query.count().then(setCount);
      const resultsAnomalies = sortResults(results, { orderBy, order });
      setAnomalies(resultsAnomalies);
    }
  }, [results, loading, query, order, orderBy]);

  const clear = async (anomalyId: string) => {
    try {
      await Cloud.clearAnomaly({ anomalyId });
      toast.success('Anomalie nettoyée');
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return {
    loading,
    anomalies,
    count,
    clear,
  };
};

const sortResults = (results: Anomaly[], { orderBy, order }: AnomaliesOrderParams) => {
  return results.sort((a, b) => {
    switch (orderBy) {
      case 'startAt':
        return compareValues(a.startAt, b.startAt, order);
      case 'endAt':
        return compareValues(a.endAt, b.endAt, order);
      case 'status':
        return compareValues(a.status, b.status, order);
      case 'type':
        return compareValues(a.type, b.type, order);
      case 'area':
        return compareValues(a.area?.name, b.area?.name, order);
      default:
        return 0;
    }
  });
};

export default useAnomalies;
