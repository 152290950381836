import Api, {
  Accommodation,
  Appointment,
  ExternalPatientTransport,
  Fare,
  Patient,
  PatientIdentity,
} from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { formatName } from 'common/utils';
import { useEffect, useMemo, useState } from 'react';

type Row = {
  id: string;
  name: string;
  accommodations: Accommodation[];
  appointments: Appointment[];
  fares: Fare[];
  externalPatientTransports: ExternalPatientTransport[];
};

const usePatientRows = ({ start, end, ipps }: { start: Date; end: Date; ipps: string[] }) => {
  const [steps, setSteps] = useState<Row[]>([]);

  const { results: visits, isLoading: isPatientsLoading } = useParseQuery(
    new Api.Query(Patient).containedIn('ipp', ipps).include('patient'),
    {
      enabled: ipps && ipps.length > 0,
    }
  );
  const patients = useMemo(() => {
    if (visits && visits.length > 0) {
      const patients: PatientIdentity[] = [];

      for (const visit of visits) {
        if (visit.patient && !patients.find((patient) => patient.id === visit.patient.id)) {
          patients.push(visit.patient);
        }
      }
      return patients;
    }
    return [];
  }, [visits]);

  const { results: accommodations, isLoading: isAccommodationsLoading } = useParseQuery(
    new Api.Query(Accommodation)
      .greaterThan('endAt', start)
      .lessThan('startAt', end)
      .containedIn('patient', patients?.map(({ id }) => PatientIdentity.createWithoutData(id)) || [])
      .include('bed'),
    { enabled: !!patients && patients.length > 0 }
  );

  const { results: appointments, isLoading: isAppointmentsLoading } = useParseQuery(
    useMemo(
      () =>
        new Api.Query(Appointment)
          .include('location')
          .greaterThan('end', start)
          .lessThan('start', end)
          .containedIn('patient', patients?.map(({ id }) => PatientIdentity.createWithoutData(id)) || []),
      [patients, start, end]
    ),
    { enabled: !!patients && patients.length > 0 }
  );

  const { results: fares, isLoading: isFaresLoading } = useParseQuery(
    useMemo(
      () =>
        new Api.Query(Fare)
          .include('fromUnit', 'toUnit', 'fromArea', 'toArea')
          .greaterThan('wantedDate', start)
          .lessThan('wantedDate', end)
          .containedIn('patient', visits?.map(({ id }) => Patient.createWithoutData(id)) || []),
      [visits, start, end]
    ),
    { enabled: !!visits && visits.length > 0 }
  );

  const { results: externalPatientTransports, isLoading: isExternalPatientTransportsLoading } = useParseQuery(
    useMemo(
      () =>
        new Api.Query(ExternalPatientTransport)
          .greaterThan('endAt', start)
          .lessThan('startAt', end)
          .containedIn('patient', patients?.map(({ id }) => PatientIdentity.createWithoutData(id)) || []),
      [patients, start, end]
    ),
    { enabled: !!patients && patients.length > 0 }
  );

  useEffect(() => {
    if (patients && visits && accommodations && fares && appointments && externalPatientTransports) {
      const rows = patients.map((patient) => {
        const patientVisits = visits.filter((visit) => visit.patient?.id === patient?.id);

        return {
          id: patient.id,
          name: formatName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName),
          accommodations: accommodations.filter((accommodation) => accommodation.patient?.id === patient.id),
          appointments: appointments.filter((accommodation) => accommodation.patient?.id === patient.id),
          fares: fares.filter((fare) => patientVisits.some((visit) => visit.id === fare.patient.id)),
          externalPatientTransports: externalPatientTransports.filter(
            (externalPatientTransport) => externalPatientTransport.patient?.id === patient.id
          ),
        };
      });

      setSteps(rows);
    } else if (patients) {
      const rows = patients.map((patient) => {
        return {
          id: patient.id,
          name: formatName(patient.lastName, patient.firstName, patient.legalName, patient.legalFirstName),
          accommodations: [],
          appointments: [],
          fares: [],
          externalPatientTransports: [],
        };
      });

      setSteps(rows);
    }
  }, [visits, accommodations, fares, appointments, externalPatientTransports, patients]);

  return {
    rows: steps,
    loading:
      isAccommodationsLoading ||
      isPatientsLoading ||
      isAppointmentsLoading ||
      isFaresLoading ||
      isExternalPatientTransportsLoading,
  };
};

export default usePatientRows;
