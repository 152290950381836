import { Components } from '@mui/material';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    danger: true;
    primaryAlt: true;
    tertiary: true;
  }
}

const MuiSvgIcon: Components['MuiSvgIcon'] = {
  defaultProps: {
    fontSize: 'medium',
  },
  styleOverrides: {},
  variants: [
    {
      props: { fontSize: 'small' },
      style: {
        fontSize: 12,
      },
    },
    {
      props: { fontSize: 'medium' },
      style: {
        fontSize: 16,
      },
    },
  ],
};

export default MuiSvgIcon;
