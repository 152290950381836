import { NavigationRoute, WebUserRole } from '@ambuliz/sabri-core';
import { RootRedirect, useAuthentication } from 'core/authentication';
import { hasAccessToRoute } from 'core/authentication/navigationACL';
import { memo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

type PrivateRouteProps = {
  roles?: WebUserRole[];
  routeACL?: NavigationRoute;
  children?: React.ReactNode;
};

const LoginRedirection = memo(() => {
  const location = useLocation();
  return <Navigate to={{ pathname: '/login' }} state={{ redirectUrl: location.pathname + location.search }} />;
});

export const PrivateRoute = ({ roles, routeACL, children }: PrivateRouteProps) => {
  const { user, isLoggedIn, navigationACL } = useAuthentication();

  if (!isLoggedIn) {
    return <LoginRedirection />;
  }

  const hasNavigationACL = navigationACL.length > 0;

  const hasRoleAccess = hasNavigationACL || (roles ? roles.includes(user.role) : true);
  const hasNavigationAccess = routeACL ? hasAccessToRoute(navigationACL, routeACL) : true;

  if (hasRoleAccess && hasNavigationAccess) {
    return children ? <>{children}</> : <Outlet />;
  }

  return <RootRedirect />;
};
