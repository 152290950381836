import { Porter } from '@ambuliz/sabri-core';
import { PorterAssignationsTableRow } from '../PorterAssignationsTableRow';
import UnassignPorterButton from './UnassignPorterButton';

type AssignedPortersTableRowProps = {
  fareAssignationId: string;
  fareId: string;
  porter: Porter;
  showConfirmation: boolean;
  isReadOnly?: boolean;
};

export const AssignedPortersTableRow = ({
  fareAssignationId,
  fareId,
  porter,
  showConfirmation,
  isReadOnly,
}: AssignedPortersTableRowProps) => {
  return (
    <PorterAssignationsTableRow
      porter={porter}
      Actions={
        isReadOnly ? undefined : (
          <UnassignPorterButton
            fareId={fareId}
            fareAssignationId={fareAssignationId}
            porterId={porter.id}
            showConfirmation={showConfirmation}
          />
        )
      }
    />
  );
};
