import { createAction } from '@reduxjs/toolkit';
import { RequestName } from '../../const/requests';

export type PostFormPayload = {
  requestName: RequestName;
  params: any;
  messageSuccess?: string;
};

export const postFormType = 'form/postForm';
export const postFormAction = createAction(postFormType, (payload: PostFormPayload) => ({
  payload,
}));
