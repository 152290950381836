import { Cloud } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { RejectMutationRequest } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';

type RejectPerformerRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  performerRequestId: string;
};

export const RejectPerformerRequestDialogForm = ({
  performerRequestId,
  onClose,
  onBack,
}: {
  performerRequestId: string;
  onClose: () => void;
  onBack?: () => void;
}) => {
  const handleSubmit = async (_: any, { reason }: { reason?: string }) => {
    await Cloud.rejectPerformerRequest({
      id: performerRequestId,
      reason,
    });
    onClose();
  };
  return (
    <>
      <DialogHeader
        onClose={onClose}
        onBack={onBack}
        title={i18n.visitActions.REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN.action.label}
      />
      <DialogContent>
        <RejectMutationRequest onCancel={onBack || onClose} onSubmit={handleSubmit} />
      </DialogContent>
    </>
  );
};

export const RejectPerformerRequestDialog = ({
  open,
  onClose,
  performerRequestId,
}: RejectPerformerRequestDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <RejectPerformerRequestDialogForm performerRequestId={performerRequestId} onClose={onClose} />
    </Dialog>
  );
};
