import routes from 'superadmin/routes';

const root = routes.areas.path;

const areaManagementRoutes = {
  areas: root,
  editArea: root + '/:id',
  createArea: root + '/:id/create',
  availabilityPeriods: root + '/:id/availability-periods',
  moveBed: root + '/:id/move-bed',
  editAreaById: (id: string) => `${root}/${id}`,
  createAreaById: (id: string) => `${root}/${id}/create`,
  deleteArea: (id: string) => `${root}/${id}/delete`,
  availabilityPeriodsById: (id: string) => `${root}/${id}/availability-periods`,
  moveBedById: (id: string) => `${root}/${id}/move-bed`,
};

export default areaManagementRoutes;
