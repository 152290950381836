import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { modalNames } from '../../const/modalNames';
import { routeNamesCommon } from '../../const/routeNames';
import { i18n } from '../../locales';
import { ModalContainer } from '../ModalContainer/ModalContainer';

export const RealTimeErrorModal: React.FC = () => {
  const handleClick = () => window.location.replace(routeNamesCommon.fareList);

  return (
    <ModalContainer modalName={modalNames.realTimeError}>
      {({ isVisible }) =>
        isVisible && (
          <Dialog open>
            <DialogTitle>{i18n.globalError}</DialogTitle>
            <DialogContent>{i18n.clickToReload}</DialogContent>
            <DialogActions>
              <Button onClick={handleClick} variant="contained" color="primary">
                {i18n.reload}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </ModalContainer>
  );
};
