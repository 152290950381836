import { PatientOutcome, PatientOutcomeType, patientOutcomeTypes } from '@ambuliz/sabri-core';
import { MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { DETAILS_ROW_WIDTH } from '../DetailsDialog';

type EditablePatientOutcomeRowProps = {
  patientOutcome?: PatientOutcome;
  handlePatientOutcomeChange: (patientOutcome: PatientOutcome) => void;
};

const EditablePatientOutcomeRow = ({ patientOutcome, handlePatientOutcomeChange }: EditablePatientOutcomeRowProps) => {
  return (
    <Stack direction="row" padding={4} spacing={2}>
      <Stack width={DETAILS_ROW_WIDTH + 8} height="fit-content" direction="row" spacing={2} alignItems="center">
        <Typography variant="body2" color="secondary" paddingLeft={2}>
          {i18n.outcome}
        </Typography>
        <Select
          fullWidth
          variant="filled"
          size="small"
          value={patientOutcome?.type || undefined}
          placeholder={i18n.toDefine}
          onChange={(e) => {
            const type = (e.target.value || null) as PatientOutcomeType | null;
            const newPatientOutcome: PatientOutcome = { ...patientOutcome, type };

            handlePatientOutcomeChange(newPatientOutcome);
          }}
        >
          <MenuItem value={undefined}>
            <Typography color="secondary.main" variant="body2">
              {i18n.toDefine}
            </Typography>
          </MenuItem>
          {patientOutcomeTypes.map((outcome) => (
            <MenuItem value={outcome}>
              <Typography color="secondary.dark" variant="body2" alignItems="center">
                {outcome}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack flexGrow={2}>
        <TextField
          variant="filled"
          size="small"
          multiline
          value={patientOutcome?.comment}
          placeholder={i18n.precisions}
          onChange={(e) => {
            const comment = e.target.value || null;
            const newPatientOutcome: PatientOutcome = { ...patientOutcome, comment };

            handlePatientOutcomeChange(newPatientOutcome);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default EditablePatientOutcomeRow;
