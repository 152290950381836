import { Cloud, computeOccupancy, UnitSpecialty } from '@ambuliz/sabri-core';
import { GetHealthCenterEmergencyOccupanciesResponse } from '@ambuliz/sabri-core/dist/cloud';
import { IconButton, Link, Stack, ToggleButton, Typography } from '@mui/material';
import { MultipleSelect, ToggleGroup } from 'common/components';
import CollapseIcon from 'common/components/Icons/Collapse';
import ExpandIcon from 'common/components/Icons/Expand';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { useAuthentication, useReadOnly } from 'core/authentication';
import { PageSection, useAppBarContext } from 'core/layout';
import { DepartmentsTable } from 'kurt/components';
import EmergencyOccupancyList from 'kurt/components/EmergencyOccupancy/List/EmergencyOccupancyList';
import FilterSection, { FilterSectionItem } from 'kurt/components/FilterSection';
import { OccupancyFilter, ORANGE_THRESHOLD, RED_THRESHOLD } from 'kurt/const';
import { NormalizedDepartment } from 'kurt/hooks/useDepartments';
import { FC, useEffect, useState } from 'react';
import MyHealthCenter from './MyHealthCenter';

const occupancyOptions: { value: string; label: string }[] = [
  { value: 'LOWER_THAN_ORANGE_THRESHOLD', label: i18n.LOWER_THAN_ORANGE_THRESHOLD },
  { value: 'BETWEEN_ORANGE_THRESHOLD_AND_RED_THRESHOLD', label: i18n.BETWEEN_ORANGE_THRESHOLD_AND_RED_THRESHOLD },
  { value: 'GREATER_THAN_RED_THRESHOLD', label: i18n.GREATER_THAN_RED_THRESHOLD },
];

type DepartmentsProps = {
  departments: NormalizedDepartment[];
  loading?: boolean;
};

const Departments: FC<DepartmentsProps> = ({ departments, loading }) => {
  const { appBarHeight, isFullScreen, setFullScreen } = useAppBarContext();
  const { healthCenter } = useAuthentication();
  const { isReadOnly } = useReadOnly();
  const [unitFilter, setUnitFilter] = useState<string[]>([]);
  const [occupancyFilter, setOccupancyFilter] = useState<string[]>([]);
  const [specialtyFilter, setSpecialtyFilter] = useState<string[]>([]);
  const [flow, setFlow] = useState<'departments' | 'emergency'>('departments');
  const {
    isLoading: isEmergencyOccupancyLoading,
    emergencyOccupancies,
    fetchHealthCenterEmergencyOccupancies,
  } = useEmergencyOccupancyTable(healthCenter.id, flow === 'emergency');

  const handleResetFilter = () => {
    setUnitFilter([]);
    setOccupancyFilter([]);
    setSpecialtyFilter([]);
  };

  const specialtyOptions = getSpecialtiesFromDepartments(departments);
  const unitOptions = departments.map(({ units, name }) => ({
    value: name,
    label: name,
    children: units.map(({ id, name }) => ({ value: id, label: name })),
  }));

  const hasFilters = unitFilter.length > 0 || occupancyFilter.length > 0 || specialtyFilter.length > 0;
  const filteredDepartments = hasFilters
    ? filterDepartments(departments, unitFilter, occupancyFilter, specialtyFilter)
    : departments;

  return (
    <>
      {!isFullScreen && (
        <>
          <PageSection>
            <MyHealthCenter departments={filteredDepartments} loading={loading} />
          </PageSection>

          <PageSection>
            <Typography variant="h3">{flow === 'departments' ? i18n.poleView : i18n.emergencyView}</Typography>
          </PageSection>

          <PageSection sticky={{ top: appBarHeight }}>
            <FilterSection justifyContent={flow === 'departments' ? 'space-between' : 'end'}>
              {flow === 'departments' && (
                <FilterSectionItem.Filters>
                  <MultipleSelect
                    values={unitFilter}
                    onChange={setUnitFilter}
                    options={unitOptions}
                    label={i18n.units}
                    searchable
                  />
                  <MultipleSelect
                    values={specialtyFilter}
                    onChange={setSpecialtyFilter}
                    options={specialtyOptions}
                    label={i18n.specialty}
                    searchable
                  />
                  <MultipleSelect
                    values={occupancyFilter}
                    onChange={setOccupancyFilter}
                    options={occupancyOptions}
                    label={i18n.occupancyRate}
                  />
                  {hasFilters && (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Link component="button" onClick={handleResetFilter}>
                        <Typography color={color.grey[80]}>{i18n.clearFilters}</Typography>
                      </Link>
                      <Typography sx={{ color: color.grey[60] }}>
                        {i18n.nbOfUnits(filteredDepartments.flatMap(({ units }) => units).length)}
                      </Typography>
                    </Stack>
                  )}
                </FilterSectionItem.Filters>
              )}

              <FilterSectionItem.Filters>
                {healthCenter.computeEmergencyOccupancies && (
                  <FilterSectionItem.Switchers>
                    <ToggleGroup value={flow} onChange={(_, value: 'departments' | 'emergency') => setFlow(value)}>
                      <ToggleButton value="departments">{i18n.departments}</ToggleButton>
                      <ToggleButton value="emergency">{i18n.emergencies}</ToggleButton>
                    </ToggleGroup>
                  </FilterSectionItem.Switchers>
                )}

                <IconButton variant="outlined" size="large" shape="rounded" onClick={() => setFullScreen(true)}>
                  <ExpandIcon />
                </IconButton>
              </FilterSectionItem.Filters>
            </FilterSection>
          </PageSection>
        </>
      )}

      {flow === 'departments' && (
        <DepartmentsTable departments={filteredDepartments} loading={loading} isReadOnly={isReadOnly} />
      )}
      {flow === 'emergency' && healthCenter.computeEmergencyOccupancies && (
        <PageSection withBackground noGutter noMargin={isFullScreen} paddingTop={10} fullHeight lastSection>
          <Stack spacing={10}>
            <Stack spacing={2}>
              {healthCenter.hasPediatricService && <Typography variant="h6">{i18n.adults}</Typography>}
              <EmergencyOccupancyList
                healthCenterId={healthCenter.id}
                service={'adults'}
                hasPediatricService={healthCenter.hasPediatricService}
                occupancyMorning={emergencyOccupancies.adults.occupancyMorning}
                occupancyMidday={emergencyOccupancies.adults.occupancyMidday}
                occupancyAfternoon={emergencyOccupancies.adults.occupancyAfternoon}
                loading={isEmergencyOccupancyLoading}
                isReadOnly={isReadOnly || healthCenter.computeEmergencyOccupancies === 'AUTO'}
                onEmergencyOccupancyModalClose={fetchHealthCenterEmergencyOccupancies}
              />
            </Stack>
            {healthCenter.hasPediatricService && (
              <Stack spacing={2}>
                <Typography variant="h6">{i18n.pediatric}</Typography>
                <EmergencyOccupancyList
                  healthCenterId={healthCenter.id}
                  service={'pediatric'}
                  hasPediatricService={healthCenter.hasPediatricService}
                  occupancyMorning={emergencyOccupancies.pediatric.occupancyMorning}
                  occupancyMidday={emergencyOccupancies.pediatric.occupancyMidday}
                  occupancyAfternoon={emergencyOccupancies.pediatric.occupancyAfternoon}
                  loading={isEmergencyOccupancyLoading}
                  isReadOnly={isReadOnly || healthCenter.computeEmergencyOccupancies === 'AUTO'}
                  onEmergencyOccupancyModalClose={fetchHealthCenterEmergencyOccupancies}
                />
              </Stack>
            )}
          </Stack>
        </PageSection>
      )}

      {isFullScreen && (
        <IconButton
          variant="outlined"
          size="large"
          shape="rounded"
          sx={{
            position: 'absolute',
            top: 24,
            right: 24,
            zIndex: 50,
          }}
          onClick={() => setFullScreen(false)}
        >
          <CollapseIcon />
        </IconButton>
      )}
    </>
  );
};

const filterDepartments = (
  departments: NormalizedDepartment[],
  unitFilter: string[],
  occupancyFilter: string[],
  specialtyFilter: string[]
) =>
  departments
    .map((department) => {
      const units = department.units.filter(({ id, specialties, capacityRate }) => {
        return (
          (unitFilter.length === 0 || unitFilter.includes(id)) &&
          (specialtyFilter.length === 0 ||
            specialtyFilter.some((specialty) => specialties?.includes(specialty as UnitSpecialty))) &&
          (occupancyFilter.length === 0 || filterOccupancyUnit(occupancyFilter as OccupancyFilter[], capacityRate))
        );
      });
      const occupancy = computeOccupancy(units.map((unit) => unit.occupancy));
      return { ...department, units, occupancy };
    })
    .filter((department) => department.units.length > 0);

const filterOccupancyUnit = (filter: OccupancyFilter[], rate?: number) =>
  !!rate &&
  ((filter.includes('LOWER_THAN_ORANGE_THRESHOLD') && rate < ORANGE_THRESHOLD) ||
    (filter.includes('BETWEEN_ORANGE_THRESHOLD_AND_RED_THRESHOLD') &&
      rate >= ORANGE_THRESHOLD &&
      rate < RED_THRESHOLD) ||
    (filter.includes('GREATER_THAN_RED_THRESHOLD') && rate >= RED_THRESHOLD));

const getSpecialtiesFromDepartments = (departments: NormalizedDepartment[] = []) => {
  const specialties = new Set<UnitSpecialty>();
  for (let department of departments) {
    for (let unit of department.units || []) {
      for (let specialty of unit.specialties || []) {
        specialties.add(specialty);
      }
    }
  }
  return Array.from(specialties).map((specialty) => ({ value: specialty, label: specialty }));
};

const useEmergencyOccupancyTable = (healthCenterId: string, enabled: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const [healthCenterEmergencyOccupanciesResponse, setHealthCenterEmergencyOccupanciesResponse] = useState<
    GetHealthCenterEmergencyOccupanciesResponse | undefined
  >(undefined);
  const emergencyOccupancies = healthCenterEmergencyOccupanciesResponse?.emergencyOccupancies || [];
  const fetchHealthCenterEmergencyOccupancies = async () => {
    if (enabled) {
      setIsLoading(true);
      const healthCenterEmergencyOccupancies = await Cloud.getHealthCenterEmergencyOccupancies({
        healthCenter: healthCenterId,
      });

      setIsLoading(false);
      setHealthCenterEmergencyOccupanciesResponse(healthCenterEmergencyOccupancies);
    }
  };

  useEffect(() => {
    fetchHealthCenterEmergencyOccupancies();
    // fetchHealthCenterEmergencyOccupancies is marked as a missing dependency but it's a false positive because it's recreated on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthCenterId, enabled]);

  return {
    emergencyOccupancies: {
      adults: {
        occupancyMorning: emergencyOccupancies.find(
          (occupancy) => occupancy.service === 'adults' && occupancy.key === '9H'
        ),
        occupancyMidday: emergencyOccupancies.find(
          (occupancy) => occupancy.service === 'adults' && occupancy.key === '14H'
        ),
        occupancyAfternoon: emergencyOccupancies.find(
          (occupancy) => occupancy.service === 'adults' && occupancy.key === '17H'
        ),
      },
      pediatric: {
        occupancyMorning: emergencyOccupancies.find(
          (occupancy) => occupancy.service === 'pediatric' && occupancy.key === '9H'
        ),
        occupancyMidday: emergencyOccupancies.find(
          (occupancy) => occupancy.service === 'pediatric' && occupancy.key === '14H'
        ),
        occupancyAfternoon: emergencyOccupancies.find(
          (occupancy) => occupancy.service === 'pediatric' && occupancy.key === '17H'
        ),
      },
    },
    isLoading,
    fetchHealthCenterEmergencyOccupancies,
  };
};

export default Departments;
