import { SvgIcon, SvgIconProps } from '@mui/material';

const EntryDashed: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1.747a.25.25 0 0 1 .25-.25h1.563a.75.75 0 1 0 0-1.497H1.75C.784 0 0 .782 0 1.747v1.575a.75.75 0 0 0 1.5 0V1.747ZM6.438 0a.75.75 0 1 0 0 1.497h3.125a.75.75 0 1 0 0-1.497H6.437Zm6.25 0a.75.75 0 1 0 0 1.497h1.562a.25.25 0 0 1 .25.25v1.575a.75.75 0 0 0 1.5 0V1.747C16 .782 15.216 0 14.25 0h-1.563ZM16 6.44a.75.75 0 0 0-1.5 0v3.152a.75.75 0 0 0 1.5 0V6.441ZM1.5 12.679a.75.75 0 0 0-1.5 0v1.575C0 15.218.784 16 1.75 16h1.563a.75.75 0 1 0 0-1.497H1.75a.25.25 0 0 1-.25-.25v-1.575Zm14.5 0a.75.75 0 0 0-1.5 0v1.575a.25.25 0 0 1-.25.25h-1.563a.75.75 0 1 0 0 1.497h1.563c.966 0 1.75-.782 1.75-1.747v-1.575Zm-9.563 1.825a.75.75 0 1 0 0 1.497h3.125a.75.75 0 1 0 0-1.497H6.438ZM8.14 4.439a.626.626 0 0 0-.883 0 .623.623 0 0 0 0 .882l2.014 2.01H.65a.624.624 0 1 0 0 1.248h8.681l-2.088 2.213a.623.623 0 0 0 .026.881.626.626 0 0 0 .883-.026l3.081-3.264a.623.623 0 0 0-.013-.869L8.14 4.439Z"
      />
    </SvgIcon>
  );
};

export default EntryDashed;
