import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { hasAccessToRoute } from 'core/authentication/navigationACL';
import { Tab, useAppBarContext } from 'core/layout';
import { useLocations } from 'core/locations';
import { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'sabri/components/PrivateRoute/PrivateRoute';
import { UnitPage } from './pages';
import EmergencyPage from './pages/Emergency';
import HealthCenterPage from './pages/HealthCenter';
import MovementsPage from './pages/Movements';
import TerritoryPage from './pages/Territory';
import routes from './routes';

export const kurtPages = ['territory', 'healthCenter', 'unit', 'movements', 'emergency'] as const;
export type KurtPage = (typeof kurtPages)[number];

const KurtTabs: Record<KurtPage, Tab> = {
  territory: { label: i18n.territory, path: routes.root + routes.territorialReporting },
  healthCenter: { label: i18n.healthCenter, path: routes.root + routes.healthCenter },
  unit: { label: i18n.myBeds, basePath: routes.root + routes.unit, path: routes.root + routes.unit },
  movements: {
    label: i18n.myMovements,
    basePath: routes.root + routes.movements,
    path: routes.root + routes.movements,
  },
  emergency: { label: i18n.emergencies, path: routes.root + routes.emergency },
};

const useKurtNavigableTabs = () => {
  const { healthCenter, user, unitType, unit, navigationACL } = useAuthentication();
  const { units } = useLocations();

  const { tabs, defaultPage } = useMemo(() => {
    const tabs: Partial<Record<KurtPage, Tab>> = {};

    const hasNavigationACL = navigationACL.length > 0;
    const hasEmergencyUnit = units.some(
      (unit) => unit.type === 'EMERGENCY' || (unit.type === 'ACCOMMODATION' && unit.isEmergency)
    );

    const isEmergencyUnit =
      unitType === 'EMERGENCY' ||
      (!!unit && unitType === 'ACCOMMODATION' && !!units.find(({ id }) => id === unit.id)?.isEmergency);

    if (hasNavigationACL) {
      if (hasAccessToRoute(navigationACL, 'kurt.territory')) {
        tabs.territory = KurtTabs.territory;
      }

      if (hasAccessToRoute(navigationACL, 'kurt.healthCenter')) {
        tabs.healthCenter = KurtTabs.healthCenter;
        tabs.healthCenter.path = routes.root + routes.healthCenter + '/' + healthCenter.id;
      }

      if (hasAccessToRoute(navigationACL, 'kurt.unit')) {
        tabs.unit = KurtTabs.unit;
      }

      if (hasAccessToRoute(navigationACL, 'kurt.movements')) {
        tabs.movements = KurtTabs.movements;
      }

      if (hasAccessToRoute(navigationACL, 'kurt.emergency') && hasEmergencyUnit) {
        tabs.emergency = KurtTabs.emergency;
      }
    } else {
      const isAdmin = ['ADMIN', 'ADMIN_REGULATOR'].includes(user.role);
      const isBedManager = user.role === 'BED_MANAGER';
      const isManager = user.role === 'MANAGER';

      const hasAutoComputeOccupancies = healthCenter.autoComputeOccupanciesActivated;

      const isAccommodationUnit = unitType === 'ACCOMMODATION';

      if (isAdmin || isBedManager || (isManager && !hasAutoComputeOccupancies)) {
        tabs.territory = KurtTabs.territory;
        tabs.healthCenter = KurtTabs.healthCenter;
        tabs.healthCenter.path = routes.root + routes.healthCenter + '/' + healthCenter.id;
      }

      if (isAdmin || isBedManager || (isManager && isAccommodationUnit && hasAutoComputeOccupancies)) {
        tabs.unit = KurtTabs.unit;
        tabs.movements = KurtTabs.movements;
      }

      if (
        hasEmergencyUnit &&
        (isAdmin || isBedManager || (isManager && isEmergencyUnit && hasAutoComputeOccupancies))
      ) {
        tabs.emergency = KurtTabs.emergency;
      }
    }

    let defaultPage = undefined;

    switch (user.role) {
      case 'ADMIN':
      case 'ADMIN_REGULATOR':
      case 'BED_MANAGER':
      case 'REGULATOR':
        defaultPage = tabs.healthCenter;
        break;

      case 'MANAGER':
        defaultPage = isEmergencyUnit ? tabs.emergency : tabs.unit;
        break;
    }

    if (!defaultPage) {
      defaultPage = tabs.healthCenter || tabs.unit || Object.values(tabs)[0];
    }

    return { tabs, defaultPage };
  }, [units, unitType, user.role, healthCenter, navigationACL, unit]);

  return { tabs, defaultPage };
};

const Kurt: React.FC = () => {
  const { tabs, defaultPage } = useKurtNavigableTabs();

  const { setTabs } = useAppBarContext();
  useEffect(() => setTabs(Object.values(tabs)), [tabs, setTabs]);

  return (
    <Routes>
      {tabs.territory && (
        <Route
          path={routes.territorialReporting}
          element={
            <PrivateRoute roles={['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER']} routeACL="kurt.territory">
              <TerritoryPage />
            </PrivateRoute>
          }
        />
      )}

      {tabs.healthCenter && (
        <Route
          path={`${routes.healthCenter}/:id`}
          element={
            <PrivateRoute roles={['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER']} routeACL="kurt.healthCenter">
              <HealthCenterPage />
            </PrivateRoute>
          }
        />
      )}

      {!!tabs.emergency && <Route path={`${routes.emergency}/*`} element={<EmergencyPage />} />}
      {!!tabs.unit && <Route path={`${routes.unit}/*`} element={<UnitPage />} />}
      {!!tabs.movements && <Route path={`${routes.movements}/*`} element={<MovementsPage />} />}

      <Route path="*" element={<Navigate to={defaultPage?.path || '/'} />} />
    </Routes>
  );
};

export default Kurt;
export { routes as kurtRoutes };
