import { Stack, Typography } from '@mui/material';
import { ConfirmDialog, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';

type ReportPatientDischargedDialogProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const ReportPatientDischargedDialog = ({ id, open, onClose, onSuccess }: ReportPatientDischargedDialogProps) => {
  const { update } = useAccommodation(id);

  const handleConfirm = async () => {
    try {
      await update({ endAt: new Date(), status: 'COMPLETED', isEndEstimated: false });
      toast.success({ ...i18n.accommodationPatientDischargedSuccess, icon: <Emoji size={24} name="pencil" /> });
    } catch (error: any) {
      toast.error(error?.message || i18n.accommodationPatientDischargedError);
    }
    onSuccess();
  };

  return (
    <ConfirmDialog
      onConfirm={handleConfirm}
      onClose={onClose}
      open={open}
      title={
        <Stack spacing={2} alignItems="center" direction="row">
          <Emoji size={24} name="wavingHand" />
          <Typography variant="h2">{i18n.reportPatientDischarged.title}</Typography>
        </Stack>
      }
      confirmLabel={i18n.reportPatientDischarged.confirmLabel}
    >
      {i18n.reportPatientDischarged.content.map((text, index) => (
        <Typography color="secondary" key={index}>
          {text}
        </Typography>
      ))}
    </ConfirmDialog>
  );
};

export default ReportPatientDischargedDialog;
