import { Box, CircularProgress, Skeleton, SvgIconProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { progressValue } from 'common/utils';
import { useEffect, useState } from 'react';
import { StatisticsColor } from '../Statistics/Item/ItemTitle';

const useStyles = makeStyles(({ palette }) => ({
  circle: {
    transitionDuration: '0.8s',
  },

  icon: {
    backgroundColor: ({ color }: { color?: StatisticsColor }) => (color ? palette[color].light : 'inherit'),
    color: ({ color }: { color?: StatisticsColor }) => (color ? palette[color].main : 'inherit'),
    borderRadius: '50%',
    padding: 6,
    height: 36,
    boxSizing: 'border-box',
    width: 36,
  },
  circularProgress: {
    position: 'absolute',
    color: ({ color }: { color?: StatisticsColor }) => (color ? palette[color].main : 'inherit'),
  },
}));

export type CircularProgressIconProps = {
  Icon: React.FC<SvgIconProps>;
  color?: StatisticsColor;
  loading?: boolean;
  value: number;
};

const CircularProgressIcon: React.FC<CircularProgressIconProps> = ({ color, value = 0, Icon, loading }) => {
  const styles = useStyles({ color });
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => setProgress(value), 10);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <Box position="relative" display="flex" justifyContent="center" alignItems="center" width={48} height={48}>
      {loading ? (
        <Skeleton variant="circular" width={36} height={36} />
      ) : (
        <>
          <Icon className={styles.icon} fontSize="large" color="inherit" />
          <CircularProgress
            size={48}
            classes={{ circle: styles.circle }}
            variant="determinate"
            value={progressValue(progress)}
            className={styles.circularProgress}
          />
        </>
      )}
    </Box>
  );
};

export default CircularProgressIcon;
