import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Hidden } from '@mui/material';
import Statistics from 'common/components/Statistics';
import { BedStatus, UnitManagementFilter } from '../UnitManagement/UnitManagementFilters';
import UnitStatisticsCards from './StatisticsCards';

export type StatisticsProps = {
  statistics: UnitStatisticsType;
  filter?: UnitManagementFilter;
  loading: boolean;
  onStatusClick: (status: BedStatus) => void;
  onSpecificityClick: (specificity: AccommodationSpecificity) => void;
};

const UnitStatistics: React.FC<StatisticsProps> = ({
  statistics,
  filter,
  loading,
  onSpecificityClick,
  onStatusClick,
}) => {
  return (
    <Statistics.Container loading={loading}>
      <UnitStatisticsCards.Occupancy {...statistics} />
      <Hidden smDown>
        <UnitStatisticsCards.Status {...statistics} statusesFilter={filter?.statuses} onStatusClick={onStatusClick} />
      </Hidden>
      <UnitStatisticsCards.Specificities
        statistics={statistics}
        specificitiesFilter={filter?.specificities}
        onSpecificityClick={onSpecificityClick}
      />
      <Hidden smUp>
        <UnitStatisticsCards.Status {...statistics} statusesFilter={filter?.statuses} onStatusClick={onStatusClick} />
      </Hidden>
    </Statistics.Container>
  );
};

export type UnitStatisticsType = {
  AVAILABLE: number;
  BOOKED?: number;
  BUSY: number;
  CLOSED: number;
  CLEANING: number;
  COVID: number;
  ISOLATED: number;
  ISOLATED_AIR: number;
  ISOLATED_DROPLET: number;
  ISOLATED_CONTACT: number;
  ISOLATED_PROTECTOR: number;
  WAITING_FOR_DOWNSTREAM_BED: number;
  ACCOMMODATED: number;
  ACCOMMODATED_OTHER_UNIT: number;
  PRIVATE_ROOM: number;
  TOTAL: number;
};

export default UnitStatistics;
