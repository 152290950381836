import { SvgIconProps } from '@mui/material';
import * as React from 'react';

const UnknownGender: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <svg viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.30435 5.21736C7.30435 3.20345 5.66609 1.56519 3.65217 1.56519C1.63826 1.56519 0 3.20345 0 5.21736C0 7.05336 1.3633 8.57319 3.13043 8.82779V9.91301H2.08696V10.9565H3.13043V12H4.17391V10.9565H5.21739V9.91301H4.17391V8.82779C5.94104 8.57319 7.30435 7.05336 7.30435 5.21736ZM1.04348 5.21736C1.04348 3.77892 2.21374 2.60866 3.65217 2.60866C5.09061 2.60866 6.26087 3.77892 6.26087 5.21736C6.26087 6.65579 5.09061 7.82606 3.65217 7.82606C2.21374 7.82606 1.04348 6.65579 1.04348 5.21736Z"
        fill="white"
      />
      <path
        d="M9.39123 0V1.04348H10.7404L9.48723 2.2967C8.8583 1.82231 8.09205 1.56555 7.30427 1.56522C5.29036 1.56522 3.6521 3.20348 3.6521 5.21739C3.6521 7.23131 5.29036 8.86957 7.30427 8.86957C9.31819 8.86957 10.9564 7.23131 10.9564 5.21739C10.9564 4.39826 10.682 3.64435 10.225 3.03444L11.4782 1.78122V3.13043H12.5217V0H9.39123ZM7.30427 7.82609C5.86584 7.82609 4.69558 6.65583 4.69558 5.21739C4.69558 3.77896 5.86584 2.6087 7.30427 2.6087C8.74271 2.6087 9.91297 3.77896 9.91297 5.21739C9.91297 6.65583 8.74271 7.82609 7.30427 7.82609Z"
        fill="white"
      />
    </svg>
  );
};

export default UnknownGender;
