import { AccommodationSpecificity, AccommodationStatus, Anomaly, PatientGender } from '@ambuliz/sabri-core';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Tooltip,
  Typography,
  cardHeaderClasses,
} from '@mui/material';
import { Comment } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import shadows from 'common/theme/shadows';
import { format } from 'date-fns';
import { AccommodationSpecificityBadges } from 'kurt/components';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import BedName from 'kurt/pages/Unit/BedName';
import { ClosedBedActionsMenu } from 'kurt/pages/Unit/Menus';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CARD_CONTENT_PAPER_HEIGHT } from './cardContentPaperHeight';

const ClosedBedCard = ({
  bedName,
  bedGender,
  accommodationId,
  accommodationStatus,
  accommodationSpecificities,
  comment,
  end,
  anomalies = [],
  isReadOnly = false,
}: {
  bedName: string;
  bedGender: PatientGender;
  accommodationId: string;
  accommodationStatus: AccommodationStatus;
  accommodationSpecificities?: AccommodationSpecificity[];
  comment?: string;
  end?: Date;
  anomalies?: Anomaly[];
  isReadOnly?: boolean;
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Card
        onClick={() => navigate(`accommodation/${accommodationId}`, { state: { from: pathname + search } })}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            boxShadow: shadows[4],
          },
        }}
      >
        <CardHeader
          component="div"
          title={
            <Stack spacing={2} direction="row" alignItems="center">
              <BedName name={bedName} anomalies={anomalies} variant="h4" containerProps={{ width: undefined }} />
              <BedStatusChip status="CLOSED" gender={bedGender} />
            </Stack>
          }
          action={
            <Stack direction="row" spacing={2} alignItems="center">
              {comment && (
                <Tooltip title={comment}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Comment color="secondary" />
                  </Box>
                </Tooltip>
              )}
              {!isReadOnly && <ClosedBedActionsMenu id={accommodationId} status={accommodationStatus} />}
            </Stack>
          }
          sx={isReadOnly ? { [`& .${cardHeaderClasses.action}`]: { margin: '0 !important' } } : undefined}
        />
        <CardContent>
          <Stack spacing={3}>
            <Paper
              sx={{
                height: CARD_CONTENT_PAPER_HEIGHT,
                backgroundImage: `linear-gradient(
                  135deg,
                  ${color.blue[5]} 25%,
                  #E5E5EA 25%,
                  #E5E5EA 50%,
                  ${color.blue[5]} 50%,
                  ${color.blue[5]} 75%,
                  #E5E5EA 75%,
                  #E5E5EA 100%
                )`,
                backgroundSize: '150px 150px',
              }}
              variant="outlined"
            />
            <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
              <Stack>
                <Typography variant="body2" color="secondary">
                  {i18n.availableAgain}
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="secondary">
                  {end ? format(end, 'dd/MM - HH:mm') : '-'}
                </Typography>
              </Stack>
              {accommodationSpecificities && (
                <AccommodationSpecificityBadges specificities={accommodationSpecificities} />
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default memo(ClosedBedCard);
