import { Button, Tooltip } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getToastableErrorMessage from 'sabri/utils/getToastableErrorMessage';
import { i18n } from '../../../locales';
import { assignFareAction } from '../../../store/actions/fare';
import { selectIsAutodispatchEnabled } from '../../../store/selectors/fareAssignation';
import { promiseDispatch } from '../../../utils/promiseDispatch';

type AssignPorterButtonProps = {
  porterId: string;
  fareId: string;
};

const AssignPorterButton: React.FC<AssignPorterButtonProps> = ({ porterId, fareId }) => {
  const dispatch = useDispatch();

  const isAutodispatchEnabled = useSelector(selectIsAutodispatchEnabled);
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await promiseDispatch(dispatch, assignFareAction({ fareId, porterIds: [porterId] }));
    } catch (error) {
      setLoading(false);
      toast.error(getToastableErrorMessage(error.message));
    }
  };

  const isDisabled = isAutodispatchEnabled || isLoading;

  return (
    <Tooltip title={i18n.addPorter}>
      <span>
        <Button variant="outlined" onClick={handleClick} disabled={isDisabled}>
          <PersonAdd color={isDisabled ? 'disabled' : 'primary'} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default AssignPorterButton;
