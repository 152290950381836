import { decodeArrayUriParam } from './uriParams';

export const getObjectParamsFromSearchParams = (searchParams: URLSearchParams, optArrayKeys?: string[]) => {
  const objectParams: Record<string, string> = {};
  //@ts-ignore
  for (const key of searchParams.keys()) {
    const currentParam = searchParams.get(key);
    //@ts-ignore
    objectParams[key] = optArrayKeys?.includes(key) ? decodeArrayUriParam(currentParam) : currentParam;
  }
  return objectParams;
};
