import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Comment } from 'common/components/Icons';
import {
  TimelineRowEmoji,
  TimelineRowEmojiProps,
  TimelineRowItem,
  TimelineRowItemProps,
} from 'common/components/Timeline';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import getShortDuration from 'common/utils/getShortDuration';
import { useRef } from 'react';

type CloseBedCardEventProps = {
  specificities?: AccommodationSpecificity[];
  comment?: string;
  emoji: TimelineRowEmojiProps;
} & TimelineRowItemProps;

const CloseBedCardEvent = ({
  id,
  start,
  end,
  onClick,
  top,
  specificities = [],
  comment,
  emoji,
  color = 'secondary',
  striped = true,
  size = 'medium',
  ...props
}: CloseBedCardEventProps) => {
  const duration = end ? getShortDuration(start, end) : null;
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const rowItemStackProps =
    size === 'medium'
      ? {
          spacing: 3,
          direction: 'row' as const,
          alignItems: 'center',
        }
      : {
          direction: 'column' as const,
          alignItems: 'flex-start',
          justifyContent: `center`,
          width: `100%`,
        };

  return (
    <TimelineRowItem
      id={id}
      onClick={onClick ? () => onClick(id) : undefined}
      start={start}
      end={end}
      color={color}
      striped={striped}
      top={top}
      borderLeft
      borderRight={!!end}
      size={size}
      ref={containerRef}
      cardContainerRef={containerRef}
      contentRef={contentRef}
      {...props}
    >
      <Stack ref={contentRef} direction="row" spacing={3} paddingX={4} alignItems="center" flexWrap="nowrap">
        <TimelineRowEmoji {...emoji} />
        <Stack {...rowItemStackProps}>
          {size === 'medium' ? (
            <Typography variant="body1" fontWeight={600} noWrap>
              {i18n.bedStatuses.CLOSED}
            </Typography>
          ) : (
            <EllipsisTypography variant="body1" fontWeight={600} noWrap>
              {i18n.bedStatuses.CLOSED}
            </EllipsisTypography>
          )}
          <Stack
            divider={<Box bgcolor={palette.secondary.dark} width={4} height={4} borderRadius={2} />}
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {comment && (
              <Tooltip
                title={
                  <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
                    {comment}
                  </Typography>
                }
                placement="top"
              >
                <div>
                  <Comment sx={{ fontSize: 12, color: palette.secondary.dark }} />
                </div>
              </Tooltip>
            )}
            {duration && (
              <Typography variant="body2" color={palette.secondary.dark} noWrap>
                {duration}
              </Typography>
            )}

            {specificities.map((specificity) => (
              <Typography key={specificity} variant="body2" color={palette.secondary.dark} noWrap>
                {i18n.accommodationSpecificities[specificity]}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </TimelineRowItem>
  );
};

export default CloseBedCardEvent;
