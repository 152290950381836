import { SvgIcon, SvgIconProps } from '@mui/material';

const UsersFilled: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 4.5a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0ZM2.5 14.625C2.505 11.52 4.966 9.003 8 9c3.037.003 5.498 2.52 5.5 5.625a.37.37 0 0 1-.366.375H2.867a.37.37 0 0 1-.366-.375Z"
      />
    </SvgIcon>
  );
};

export default UsersFilled;
