import { Cloud } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Download } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import CsvDownload from 'react-csv-downloader';

const csvColumns = [
  {
    id: 'unit',
    displayName: 'Unité',
  },
  {
    id: 'room',
    displayName: 'Chambre',
  },
  {
    id: 'bed',
    displayName: 'Lit',
  },
];

const ExportStructureButton = ({ username }: { username?: string }) => {
  const [loading, setLoading] = useState(false);

  const exportStructure = async () => {
    setLoading(true);
    const { locations } = await Cloud.getHl7Locations();
    setLoading(false);
    return locations as any;
  };

  return (
    <CsvDownload
      disabled={loading}
      filename={`structure-${username}.csv`}
      columns={csvColumns}
      datas={exportStructure}
      handleError={() => {
        toast.error(i18n.globalError);
        setLoading(false);
      }}
    >
      <LoadingButton startIcon={<Download />} loading={loading}>
        Exporter la structure
      </LoadingButton>
    </CsvDownload>
  );
};

export default ExportStructureButton;
