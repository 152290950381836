import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { ArrowRight } from 'common/components/Icons';
import { PatientAutocomplete, PatientSearchResult } from 'common/components/PatientAutocomplete';
import { useState } from 'react';

type SelectPatientFormProps = {
  open: boolean;
  onSubmit: (patient?: PatientSearchResult) => void;
  onCancel: () => void;
};

const SelectPatientModal: React.FC<SelectPatientFormProps> = ({ open, onSubmit, onCancel }) => {
  const [patient, setPatient] = useState<PatientSearchResult>();

  const handleSubmit = () => {
    onSubmit(patient);
    setPatient(undefined);
  };
  const handleClose = () => {
    onCancel();
    setPatient(undefined);
  };
  return (
    <Dialog fullWidth maxWidth={'lg'} open={open} onClose={handleClose}>
      <DialogContent>
        <Stack spacing={4}>
          <Typography variant="h2" color="primary">
            Pour quel patient souhaitez-vous effectuer la demande ?
          </Typography>
          <PatientAutocomplete
            onChange={(patient) => {
              setPatient(patient || undefined);
            }}
            value={patient || null}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Annuler
        </Button>
        <Button disabled={!patient} endIcon={<ArrowRight />} onClick={handleSubmit}>
          Suivant
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectPatientModal;
