import { Cloud } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { CancelAccommodation } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';

type CancelAccommodationRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  accommodationRequestId: string;
};

export const CancelAccommodationRequestDialogForm = ({
  onClose,
  onBack,
  accommodationRequestId,
}: {
  onClose: () => void;
  onBack?: () => void;
  accommodationRequestId: string;
}) => {
  const handleSubmit = async () => {
    await Cloud.cancelAccommodationRequest({
      requestId: accommodationRequestId,
    });
    onClose();
  };

  return (
    <>
      <DialogHeader onClose={onClose} onBack={onBack} title={i18n.visitActions.CANCEL_MUTATION_REQUEST.action.label} />
      <DialogContent>
        <CancelAccommodation onCancel={onBack || onClose} onSubmit={handleSubmit} action="CANCEL_MUTATION_REQUEST" />
      </DialogContent>
    </>
  );
};

export const CancelAccommodationRequestDialog = ({
  open,
  onClose,
  accommodationRequestId,
}: CancelAccommodationRequestDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <CancelAccommodationRequestDialogForm accommodationRequestId={accommodationRequestId} onClose={onClose} />
    </Dialog>
  );
};
