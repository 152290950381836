import { DoneAll } from '@mui/icons-material';
import { Box, Chip, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cls from 'classnames';
import { useReadOnly } from 'core/authentication';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { modalNames } from '../../../const/modalNames';
import { EntityNames } from '../../../const/schemas';
import { useSelectFetch, useSelectFetchByIds } from '../../../hooks/useSelectFetch';
import { i18n } from '../../../locales';
import { isFareDone } from '../../../services/fare';
import { removeFareAssignationAction } from '../../../store/actions/fare';
import { modalActions } from '../../../store/reducers/modal';
import { selectIsAutodispatchEnabled } from '../../../store/selectors/fareAssignation';
import { selectUserRole } from '../../../store/selectors/general';
import { SkeletonSimple } from '../../SkeletonSimple/SkeletonSimple';

type FareAssignationChipProps = {
  fareAssignationId: string;
  isDisabled?: boolean;
};

const FareAssignationChip: React.FC<FareAssignationChipProps> = ({ fareAssignationId, isDisabled }) => {
  const classes = useClasses();
  const dispatch = useDispatch();
  const [fareAssignation] = useSelectFetch(EntityNames.fareAssignations, fareAssignationId);
  const [porter] = useSelectFetch(EntityNames.porters, fareAssignation?.porterId);
  const [fare] = useSelectFetch(EntityNames.fares, fareAssignation?.fareId);

  if (!fareAssignation || !porter || !fare) {
    return null;
  }

  const handleDeletePorter = () => {
    batch(() => {
      dispatch(
        removeFareAssignationAction({ fareId: fare.objectId, fareAssignationId, porterId: fareAssignation.porterId })
      );
      dispatch(modalActions.hideModal(modalNames.confirm));
    });
  };

  const handleClickDeletePorter = () => {
    if (fare?.assignationsIds && fare.assignationsIds.length === 1) {
      dispatch(
        modalActions.showModal({
          modalName: modalNames.confirm,
          params: {
            title: i18n.caution,
            content: i18n.cautionDeleteLastPorter,
            onConfirm: handleDeletePorter,
          },
        })
      );
      return;
    }
    handleDeletePorter();
  };

  if (porter.status === 'OFF') {
    return (
      <Tooltip title={i18n.porterStatus.OFF}>
        <Chip
          icon={fareAssignation.acknowledgedAt ? <DoneAll className={classes.iconDoneAll} /> : undefined}
          label={`${porter.firstName} ${porter.lastName.slice(0, 1)}`}
          classes={{
            deleteIcon: classes.chipDeleteIcon,
            icon: classes.chipCheckIcon,
            root: cls(classes.chipOff, classes.chip),
          }}
          variant="outlined"
          onDelete={!isDisabled ? handleClickDeletePorter : undefined}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={isDisabled ? '' : i18n.deleteAssignation}>
      <Chip
        className={classes.chip}
        icon={fareAssignation.acknowledgedAt ? <DoneAll className={classes.iconDoneAll} /> : undefined}
        label={`${porter.firstName} ${porter.lastName.slice(0, 1)}`}
        onDelete={!isDisabled ? handleClickDeletePorter : undefined}
      />
    </Tooltip>
  );
};

const useClasses = makeStyles(({ palette }) => ({
  iconDoneAll: {
    color: palette.primary.main,
  },
  chipOff: {
    color: palette.secondary.dark,
    borderColor: palette.secondary.dark,
  },
  chip: {
    paddingTop: 2,
    paddingBottom: 2,
    height: 'auto',
  },
  chipDeleteIcon: {
    '&:hover': {
      color: palette.secondary.main,
    },
    color: palette.secondary.dark,
  },
  chipCheckIcon: {
    color: palette.secondary.dark,
  },
}));

type FareListAssignationProps = {
  fareId: string;
};

export const FareListAssignation: React.FC<FareListAssignationProps> = ({ fareId }) => {
  const [fare] = useSelectFetch(EntityNames.fares, fareId);
  const [fareAssignations] = useSelectFetchByIds(EntityNames.fareAssignations, fare?.assignationsIds || []);
  const isAutodispatchEnabled = useSelector(selectIsAutodispatchEnabled);
  const role = useSelector(selectUserRole);

  const { isReadOnly } = useReadOnly();
  const isDisabled = isReadOnly || isAutodispatchEnabled || isFareDone(fare) || role === 'ADMIN' || role === 'MANAGER';

  if (fare?.assignationsIds && fareAssignations.length < fare.assignationsIds.length) {
    return <SkeletonSimple variant="rectangular" height={32} />;
  }

  const renderFareAssignationButton = (fareAssignationId: string) => (
    <Box marginRight={1} key={fareAssignationId}>
      <FareAssignationChip fareAssignationId={fareAssignationId} isDisabled={isDisabled} />
    </Box>
  );

  if (fare?.assignationsIds) {
    return (
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box display="flex" flexDirection="row">
          {fare.assignationsIds.map(renderFareAssignationButton)}
        </Box>
      </Box>
    );
  }
  return null;
};
