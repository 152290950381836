import { FareStatus } from '@ambuliz/sabri-core';
import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';
import { ChipFareStatus } from '../ChipFareStatus/ChipFareStatus';

type HeaderFareModalProps = {
  onClose: () => void;
  reference?: string;
  status?: FareStatus | 'PULLED';
  isReturn?: boolean;
  children?: ReactNode;
};

export const HeaderFareModal: React.FC<HeaderFareModalProps> = ({ onClose, reference, status, isReturn, children }) => {
  const classes = useClasses();
  return (
    <Box display="flex" alignItems="center" marginBottom={8}>
      <Box display="flex" alignItems="center" flex={1}>
        {reference && (
          <Typography variant="h6" className={classes.reference}>
            #{reference}
          </Typography>
        )}
        {status && <ChipFareStatus status={status} isReturn={isReturn} size="medium" />}
        {children}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Close onClick={onClose} className={classes.closeIcon} fontSize="large" />
      </Box>
    </Box>
  );
};

const useClasses = makeStyles({
  closeIcon: {
    cursor: 'pointer',
  },
  reference: {
    marginRight: 30,
  },
});
