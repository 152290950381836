import { Box, Popover, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { color as themeColor, transition } from 'common/theme';
import { format } from 'date-fns';
import EventCard from 'kurt/components/EventCard';
import { Children, ReactElement, ReactNode, cloneElement, isValidElement, useRef, useState } from 'react';
import TimelineRowEmoji, { TimelineRowEmojiProps } from './TimelineRowEmoji';
import { TimelineRowItemProps } from './TimelineRowItem';

export const TIMELINE_GROUP_WIDTH = 28;
const TIMELINE_GROUPED_MENU_ITEM_WIDTH = 350;

type TimelineGroupedRowItemProps = {
  left: number;
  width: number;
  fillWidth?: number;
  fillLeft?: number;
  top?: number;
  children: ReactElement<TimelineRowItemProps>[];
  title?: ReactNode;
  emoji?: TimelineRowEmojiProps;
  color?: 'primary' | 'warning' | 'danger' | 'error' | 'secondary' | 'success' | 'primaryAlt';
  striped?: boolean;
  onMouseEnter?: (ids: string[]) => void;
  onMouseLeave?: () => void;
};

const TimelineGroupedRowItem = ({
  left,
  width,
  fillWidth,
  fillLeft,
  top,
  children,
  title,
  emoji,
  color = 'primary',
  striped,
  onMouseEnter,
  ...props
}: TimelineGroupedRowItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const childrenArray = Children.toArray(children);

  const handleClick = childrenArray.some(
    (child) =>
      isValidElement<{ onClick: (id?: string) => void }>(child) && (child.props.onClick || childrenArray.length > 1)
  )
    ? () => {
        if (childrenArray.length > 1) {
          setOpen(true);
        } else if (childrenArray.length === 1) {
          const child = childrenArray[0];
          if (isValidElement<{ onClick: (id?: string) => void; id?: string }>(child)) {
            child.props.onClick?.(child.props.id);
          }
        }
      }
    : undefined;

  if (handleClick) {
    delete (props as any).onClick;
  }
  const items: { start: Date; element: ReactElement<TimelineRowItemProps> }[] = [];
  for (const child of childrenArray) {
    if (isValidElement<TimelineRowItemProps>(child)) {
      const handleClick = child.props.onClick
        ? () => {
            setOpen(false);
            if (child.props.onClick) {
              child.props.onClick(child.props.id);
            }
          }
        : undefined;

      items.push({
        start: child.props.start,
        element: cloneElement(child, {
          size: 'large',
          cardHeight: null,
          onClick: handleClick,
        }),
      });
    }
  }

  return (
    <>
      <EventCard
        left={left}
        width={width}
        fillWidth={fillWidth}
        fillLeft={fillLeft}
        top={top}
        color={children.length > 1 ? themeColor.grey[5] : color}
        striped={children.length > 1 ? false : striped}
        onClick={handleClick}
        ref={ref}
        onMouseEnter={() =>
          onMouseEnter?.(
            children
              .map((child: ReactElement<TimelineRowItemProps>) => child.props.id)
              .filter((id?: string) => id) as string[]
          )
        }
        {...props}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          flex={1}
          width={width}
          sx={{
            willChange: 'width',
            transition: `width ${transition}`,
          }}
        >
          {children.length > 1 ? (
            <Typography color="secondary">{children.length}</Typography>
          ) : (
            emoji?.name && <TimelineRowEmoji {...emoji} />
          )}
        </Stack>
      </EventCard>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
      >
        <Box p={4}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="h4"> - {i18n.timelineGroupedItemsTitle}</Typography>
          </Stack>

          <Stack spacing={3} marginTop={6}>
            {items.map(({ element, start }, index) => (
              <Stack direction="row" spacing={6}>
                <Stack spacing={3} alignItems="center">
                  <Typography variant="body2" color="secondary" width={34} textAlign="center">
                    {format(start, 'HH:mm')}
                  </Typography>
                  <Box height="100%" width={2} bgcolor={themeColor.grey[30]} />
                </Stack>
                <Box width={TIMELINE_GROUPED_MENU_ITEM_WIDTH}>{element}</Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default TimelineGroupedRowItem;
