import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { requestNames, requests } from 'sabri/const/requests';
import { safeSaga } from 'sabri/utils/safeSaga';
import { LogoutPorterPayload, logoutPorterType } from '../actions/porter';

function* logoutPorter({ payload: { porterId } }: PayloadAction<LogoutPorterPayload>) {
  yield call(requests.logoutPorter, { porterId });
}

export function* porterRootSaga() {
  yield takeLatest(logoutPorterType, safeSaga(logoutPorter, requestNames.logoutPorter));
}
