import { ReactNode, createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type HistoryContextValue = {
  length: number;
};

export const HistoryContext = createContext({} as HistoryContextValue);

export const HistoryContextProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [length, setLength] = useState(0);
  useEffect(() => {
    setLength((prevLength) => prevLength + 1);
  }, [location.pathname]);

  const value = {
    length,
  };

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
};
