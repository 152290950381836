import { SvgIcon, SvgIconProps } from '@mui/material';

const Stand: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8 2C8.8 2.44183 8.44183 2.8 8 2.8C7.55817 2.8 7.2 2.44183 7.2 2C7.2 1.55817 7.55817 1.2 8 1.2C8.44183 1.2 8.8 1.55817 8.8 2ZM10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.895431 6.89543 0 8 0C9.10457 0 10 0.895431 10 2ZM8 4C10.2091 4 12 5.79086 12 8V10.8C12 10.9105 11.9105 11 11.8 11H10.6927C10.5367 11 10.4067 11.1196 10.3937 11.2751L10.0306 15.6332C10.0133 15.8405 9.83998 16 9.63195 16H6.36805C6.16002 16 5.98671 15.8405 5.96943 15.6332L5.60626 11.2751C5.5933 11.1196 5.46332 11 5.30729 11H4.2C4.08954 11 4 10.9105 4 10.8V8C4 5.79086 5.79086 4 8 4ZM6.8868 9.78339C6.87816 9.67973 6.79151 9.6 6.68749 9.6H5.6C5.48954 9.6 5.4 9.51046 5.4 9.4V8C5.4 6.56406 6.56406 5.4 8 5.4C9.43594 5.4 10.6 6.56406 10.6 8V9.4C10.6 9.51046 10.5105 9.6 10.4 9.6H9.31251C9.20849 9.6 9.12184 9.67973 9.1132 9.78339L8.7271 14.4166C8.71846 14.5203 8.6318 14.6 8.52779 14.6H7.47221C7.36819 14.6 7.28154 14.5203 7.2729 14.4166L6.8868 9.78339Z"
      />
    </SvgIcon>
  );
};

export default Stand;
