import { Dispatch, combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { Middleware, UnknownAction } from 'redux-saga';
import { requestNames, requests } from '../const/requests';
import { routeNamesCommon } from '../const/routeNames';
import { EntityNames } from '../const/schemas';
import { createDefaultLiveQueryMiddleware } from './middlewares/defaultLiveQuery';
import { fareLiveQueryMiddleware } from './middlewares/fareLiveQuery';
import { patientLiveQueryMiddleware } from './middlewares/patientLiveQuery';
import { entityReducer } from './reducers/entity';
import { faresReducer } from './reducers/fares';
import { generalReducer } from './reducers/general';
import { modalReducer } from './reducers/modal';
import { notificationReducer } from './reducers/notification';
import { requestStatusReducer } from './reducers/requestStatus';

export const sagaMiddleware = createSagaMiddleware({
  // Si erreur globale des sagas, on recharge la page entièrement (évite le crash)
  onError: () => window.location.replace(routeNamesCommon.fareList),
});

export const history = createBrowserHistory();

const reducers = combineReducers({
  router: connectRouter(history),
  general: generalReducer,
  entity: entityReducer,
  fares: faresReducer,
  modal: modalReducer,
  requestStatus: requestStatusReducer,
  notification: notificationReducer,
});

export type AppState = ReturnType<typeof reducers>;

const middleware = [
  routerMiddleware(history),
  fareLiveQueryMiddleware,
  patientLiveQueryMiddleware,
  createDefaultLiveQueryMiddleware(
    requests.subscribeFareAssignationLiveQuery,
    EntityNames.fareAssignations,
    requestNames.getFareAssignations
  ),
  createDefaultLiveQueryMiddleware(
    requests.subscribeHealthCenterLiveQuery,
    EntityNames.healthCenters,
    requestNames.getHealthCenter
  ),
  createDefaultLiveQueryMiddleware(requests.subscribeUnitLiveQuery, EntityNames.units, requestNames.getUnits),
  sagaMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true });
  middleware.push(logger as Middleware<{}, any, Dispatch<UnknownAction>>);
}

export const store = configureStore({
  reducer: reducers,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});
