import { ClickAwayListener, Grow, MenuList, Paper, Popper, PopperPlacementType } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

type DropdownProps = {
  children: React.ReactNode[];
  button: JSX.Element;
  disabled?: boolean;
  openOnHover?: boolean;
  placement?: PopperPlacementType;
  transformOrigin?: string;
  dropdownMargin?: number | string | undefined;
};

export const Dropdown = (props: DropdownProps) => {
  const anchorRef = useRef(null);
  const [hoverState, setHoverState] = useState(false);
  const [clickState, setClickState] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleBtnClick = () => setClickState((clickState) => !clickState);
  const handleMouseEnter = () => setHoverState(true);
  const handleMouseLeave = () => setHoverState(false);

  useEffect(() => {
    if (props.disabled) {
      setMenuOpen(false);
      setClickState(false);
    }
  }, [props.disabled]);

  useEffect(
    () => (props.openOnHover ? setMenuOpen(clickState || hoverState) : setMenuOpen(clickState)),
    [hoverState, clickState, props.openOnHover]
  );

  return (
    <ClickAwayListener onClickAway={() => setClickState(false)}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div onClick={handleBtnClick} ref={anchorRef}>
          {props.button}
        </div>
        <Popper
          open={menuOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          placement={props.placement || 'bottom'}
          transition
          sx={{ zIndex: 15000 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: props.transformOrigin || 'top left',
              }}
            >
              <Paper sx={{ margin: props.dropdownMargin || 0 }}>
                <MenuList autoFocusItem={menuOpen} id="composition-menu" aria-labelledby="composition-button">
                  {props.children}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
