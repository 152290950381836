import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useNotifications } from 'core/layout/components/NotificationCenter';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Emoji from '../Emoji';
import { Cross } from '../Icons';

type NotificationToastContentProps = {
  severity: 'info' | 'warning' | 'error';
  content: React.ReactNode;
  path?: string;
  id: string;
};

const NotificationToastContent: React.FC<NotificationToastContentProps> = ({ severity, content, path, id }) => {
  const navigate = useNavigate();
  const { read } = useNotifications();

  const handleClick = async () => {
    await read(id);
    if (path) {
      navigate(path);
    }
  };

  const handleClose = () => {
    toast.dismiss(id);
  };

  return (
    <Stack spacing={3} direction="row" alignItems="center" onClick={handleClick} padding="16px 10px 16px 16px">
      <Stack spacing={4} direction="row" alignItems="center">
        {getIcon(severity)}
        <Box flex={1}>
          <Stack spacing={2}>
            <Stack
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                lineHeight: '19px',
                whiteSpace: 'pre-wrap',
              }}
            >
              <Typography>{content}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleClose();
        }}
        sx={{ padding: 1.5 }}
      >
        <Cross />
      </IconButton>
    </Stack>
  );
};

const getIcon = (severity: 'info' | 'warning' | 'error') => {
  switch (severity) {
    case 'error':
      return <Emoji name="noEntry" size={24} />;

    case 'warning':
      return <Emoji name="warning" size={24} />;

    case 'info':
    default:
      return <Emoji name="closedMailboxWithRaisedFlag" size={24} />;
  }
};

export default NotificationToastContent;
