import { Skeleton, Stack, TableCell, TableRow } from '@mui/material';
import { TimeFlowLine } from 'common/components';

const SkeletonRow = ({ displayUnitColumn }: { displayUnitColumn: boolean }) => {
  return (
    <TableRow sx={{ height: 41 }}>
      <TableCell sx={{ paddingRight: 0 }}></TableCell>
      <TableCell sx={{ paddingLeft: 1 }} width={50}>
        <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={50} height={20} />
      </TableCell>
      {displayUnitColumn && (
        <TableCell width={100}>
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={80} height={20} />
        </TableCell>
      )}
      <TableCell width={100}>
        <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={100} height={20} />
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" height={22} width={22} />
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={160} height={18} />
        </Stack>
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={40} height={16} />
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={144} height={20} />
      </TableCell>
      <TableCell width={80}>
        <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={80} height={16} />
      </TableCell>
      <TableCell align="center" sx={{ width: 156, paddingX: 0 }}>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
          <TimeFlowLine start="dashed" size="small" />
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={60} height={16} />
          <TimeFlowLine end="dashed" size="small" />
        </Stack>
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={80} height={16} />
      </TableCell>
      <TableCell>
        <Skeleton variant="circular" height={20} width={20} />
      </TableCell>
      <TableCell width={16} sx={{ paddingRight: 2 }}></TableCell>
      <TableCell width={16} sx={{ paddingLeft: 2 }}></TableCell>
    </TableRow>
  );
};

export default SkeletonRow;
