import { Card, Typography } from '@mui/material';

const BedCard = ({ name }: { name: string }) => {
  return (
    <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, minHeight: 44 }}>
      <Typography fontWeight={600} textAlign="center">
        {name}
      </Typography>
    </Card>
  );
};

export default BedCard;
