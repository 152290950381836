import { Area, Unit } from "@ambuliz/sabri-core";
import { AvailableBedStep, StepItem } from "../Step";


export const mapAvailableBedStep = (bed: Area, stepItems: StepItem[], unit?: Unit): AvailableBedStep => {
  // add step types such as 'healthCareEnvironmentalCleaning' to the array to use them to deduce the start and end of the bed availability
  const stepsDeducingDates = stepItems.filter((step) => ['accommodation', 'closedBed'].includes(step.type));

  const availabilityAt = Date.now();
  const stepsDeducingStartDate = stepsDeducingDates.filter(
    (stepItem) => stepItem.end && stepItem.end.getTime() <= availabilityAt
  );
  const stepsDeducingEndDate = stepsDeducingDates.filter((stepItem) => stepItem.start.getTime() >= availabilityAt);

  const start: Date =
    stepsDeducingStartDate.reduce(
      (mostRecentEnded: StepItem | undefined, current) =>
        !mostRecentEnded?.end || (current.end && current.end.getTime() > mostRecentEnded.end.getTime())
          ? current
          : mostRecentEnded,
      undefined
    )?.end || new Date();

  const end: Date | undefined = stepsDeducingEndDate.reduce(
    (soonest: StepItem | undefined, current) =>
      !soonest || current.start.getTime() < soonest.start.getTime() ? current : soonest,
    undefined
  )?.start;

  return {
    type: 'availableBed',
    step: bed,
    unitId: unit?.id,
    start,
    end,
  };
};
