import {
  NavigationACL,
  NotificationConfiguration,
  UserRole,
  getAccessibleRoles,
  isPasswordValid,
  isPinValid,
} from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { MultipleSelect } from 'common/components/Form';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAuthentication } from 'core/authentication';
import { useLocations } from 'core/locations';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { NormalizedUser, UserCreateFormState, UserUpdateFormState } from 'superadmin/pages/UserManagement/context';
import NavigationAclSelect from './NavigationAclSelect';
import NotificationsSettings from './Notifications/NotificationsSettings';
import UserPasswordForm from './UserPasswordForm';
import UserPinForm from './UserPinForm';

export type UserFormProps = Partial<NormalizedUser> & {
  onCancel: () => void;
  onCreateSubmit?: (user: UserCreateFormState) => Promise<void | NormalizedUser>;
  onUpdateSubmit?: (user: UserUpdateFormState) => Promise<void | NormalizedUser>;
};

type FormState = {
  firstName?: string;
  lastName?: string;
  unit?: string;
  role?: UserRole;
  username?: string;
  units?: string[];
  notificationConfigurations?: NotificationConfiguration[];
  navigationACL: NavigationACL;
  whitelistedIPs?: string[];
  isReadOnly?: boolean;
};

const UserForm: React.FC<UserFormProps> = ({
  onCancel,
  onCreateSubmit,
  onUpdateSubmit,
  objectId,
  firstName,
  lastName,
  unit,
  notificationConfigurations,
  navigationACL,
  units: navigableUnits,
  role,
  isReadOnly,
  whitelistedIPs,
  username,
}) => {
  const { units } = useLocations();
  const { healthCenter, user } = useAuthentication();
  const [state, setState] = useState<FormState>({
    firstName,
    lastName,
    unit,
    username: undefined,
    role,
    units: navigableUnits,
    notificationConfigurations,
    navigationACL: navigationACL || [],
    whitelistedIPs: whitelistedIPs || [],
    isReadOnly: isReadOnly || false,
  });

  const formType = !!objectId ? 'update' : 'create';

  const [password, setPassword] = useState('');
  const [isUnsafePassword, setIsUnsafePassword] = useState(false);
  const [expandNavigationOptions, setExpandNavigationOptions] = useState(state.navigationACL.length !== 0);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!!objectId && onUpdateSubmit) {
        await onUpdateSubmit({
          ...state,
          objectId,
          healthCenterId: healthCenter.id,
        });
        toast.success(i18n.messageSuccessUserUpdate);
      } else if (onCreateSubmit) {
        await onCreateSubmit({
          ...state,
          password,
          healthCenterId: healthCenter.id,
          role: state.role as UserRole,
          isUnsafePassword: !isUserPasswordValid && isUnsafePassword,
        });
        toast.success(i18n.messageSuccessUserCreate);
      }
      setLoading(false);

      onCancel();
    } catch (err) {
      setLoading(false);
      toast.error(err?.message);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>, key: string) => {
    setState({ ...state, [key]: event.target.value });
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    setState({ ...state, [key]: event.target.checked });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleFieldChange = (key: keyof FormState) => (value: FormState[typeof key]) => {
    setState({ ...state, [key]: value });
  };

  const handleExpandNavigationOptions = (checked: boolean) => {
    setExpandNavigationOptions(!expandNavigationOptions);
    if (checked) {
      setState({ ...state, navigationACL: [] });
    }
  };

  const isUserPasswordValid = state.role === 'PORTER' ? isPinValid(password).valid : isPasswordValid(password).valid;
  const displayUnsafePasswordWarning = password.length > 0 && !isUserPasswordValid;

  useEffect(() => {
    if (!isUserPasswordValid) {
      setIsUnsafePassword(false);
    }
  }, [password, isUserPasswordValid]);

  const accessibleRoles = getAccessibleRoles(user.role);

  return (
    <Stack padding={8} spacing={4} component="form" onSubmit={handleSubmit}>
      {!!objectId ? (
        <Typography variant="h3">{i18n.editUser + ' ' + username}</Typography>
      ) : (
        <Typography variant="h3">{i18n.createUser}</Typography>
      )}
      {formType === 'create' && (
        <>
          <TextField
            onChange={handleChange}
            label={i18n.username}
            value={state.username || ''}
            name="username"
            helperText={i18n.usernameHelper}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="roleLabel">{i18n.userRole}</InputLabel>
            <Select
              name="role"
              value={state.role || ''}
              variant="outlined"
              labelId="roleLabel"
              label={i18n.userRole}
              onChange={(event) => handleSelectChange(event, 'role')}
            >
              {accessibleRoles.map((key: UserRole) => (
                <MenuItem key={key} value={key}>
                  {i18n[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {state.role === 'PORTER' ? (
            <UserPinForm value={password} onChange={setPassword} />
          ) : (
            <UserPasswordForm value={password} onChange={setPassword} />
          )}
          {displayUnsafePasswordWarning && (
            <FormControlLabel
              control={<Checkbox checked={isUnsafePassword} onChange={() => setIsUnsafePassword(!isUnsafePassword)} />}
              label="J'assume la responsabilité d'avoir un mot de passe non-sécurisé"
            />
          )}
        </>
      )}

      {state.role === 'MANAGER' && (
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="unitLabel">{i18n.unit}</InputLabel>
          <Select
            name="unit"
            value={state.unit || ''}
            variant="outlined"
            labelId="unitLabel"
            label={i18n.unit}
            onChange={(event) => handleSelectChange(event, 'unit')}
          >
            {units.map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {state.role === 'PORTER' && (
        <>
          <TextField onChange={handleChange} label={i18n.lastName} value={state.lastName || ''} name="lastName" />
          <TextField onChange={handleChange} label={i18n.firstName} value={state.firstName || ''} name="firstName" />
        </>
      )}

      <Typography variant="h5">Unités accessibles</Typography>
      <MultipleSelect
        label="Sélectionner les unités"
        onChange={(value) => setState({ ...state, units: value })}
        options={units.map(({ id, name }) => ({ label: name, value: id }))}
        values={state.units || []}
        searchable
        fullWidth
      />

      <Typography variant="h5">Pages accessibles</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormLabel htmlFor="defaultNavigation">{`Navigation par défaut du rôle ${
          state.role ? i18n[state.role] : ''
        }`}</FormLabel>
        <Switch
          id="defaultNavigation"
          checked={!expandNavigationOptions}
          onChange={(_, checked) => handleExpandNavigationOptions(checked)}
        />
      </Stack>
      <Collapse in={expandNavigationOptions}>
        <NavigationAclSelect
          value={state.navigationACL}
          role={user.role}
          onChange={(value) => setState({ ...state, navigationACL: value })}
        />
      </Collapse>

      <Typography variant="h5">Mode</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FormLabel htmlFor="isReadOnly">{i18n.isReadOnly}</FormLabel>
        <Switch
          id="isReadOnly"
          checked={state.isReadOnly}
          onChange={(event) => handleSwitchChange(event, 'isReadOnly')}
        />
      </Stack>

      <Typography variant="h5">{i18n.whitelistedIPs}</Typography>
      <Autocomplete
        freeSolo
        open={false}
        options={[]}
        renderInput={(params) => <TextField {...params} label={i18n.ipAdresses} />}
        disablePortal
        multiple
        value={state.whitelistedIPs || []}
        onChange={(_, value) => setState({ ...state, whitelistedIPs: value })}
        sx={{ '& .MuiAutocomplete-popper': { display: 'none !important' } }}
      />

      {role && (['ADMIN', 'ADMIN_REGULATOR'] as UserRole[]).includes(user.role) && (
        <NotificationsSettings
          notificationConfigurations={state.notificationConfigurations}
          onChange={handleFieldChange('notificationConfigurations')}
        />
      )}

      <Stack direction="row" gap={4} justifyContent="flex-end">
        <Button variant="outlined" onClick={onCancel}>
          {i18n.cancel}
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          disabled={formType === 'create' ? !isUserPasswordValid && !isUnsafePassword : false}
        >
          {!!objectId ? i18n.save : i18n.create}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default UserForm;
