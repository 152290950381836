import { Accommodation } from '@ambuliz/sabri-core';
import { Box, Stack } from '@mui/material';
import { ExternalPatientTransportModal } from 'common/components';
import ComfortBedIcon from 'common/components/Icons/ComfortBed';
import PathIcon from 'common/components/Icons/Path';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { useAuthentication } from 'core/authentication';
import { useContext, useMemo } from 'react';
import { getStepSchedule } from '../../DetailsDialogContent';
import DialogTabs from '../../DialogTabs';
import { DialogTabOption } from '../../DialogTabs/DialogTabs';
import DialogEditButtons from '../../EditButtons';
import { DialogContext } from '../../context/DialogContext';
import { AccommodationStep, MutationStep, Step, StepItem } from '../../context/Step';
import AccommodationDetailsContent from '../Accommodation/AccommodationDetailsContent';
import useUpcomingPatientTransport from '../useUpcomingPatientTransport';
import MutationDetailsContent from './MutationDetailsContent';

type MutationDetailsProps = {
  step: MutationStep;
  editable?: boolean;
  currentTab?: string;
  isReadOnly?: boolean;
  onChange?: (step: Step) => void;
  onDestinationChange?: (accommodation: Accommodation) => void;
  onTabChange: (tab: string) => void;
};

const MutationDetails = ({
  step,
  editable,
  currentTab,
  isReadOnly = false,
  onChange,
  onDestinationChange,
  onTabChange,
}: MutationDetailsProps) => {
  const { user, unit } = useAuthentication();

  const currentRequest = step.step.performerRequests?.find((request) =>
    ['REQUESTED', 'ACCEPTED'].includes(request.status)
  );
  const destinationUnit = step.destination?.unit || currentRequest?.performer;

  const hasOriginAccess =
    ['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER'].includes(user?.role) ||
    (user.role === 'MANAGER' && step.origin?.unit?.id === unit?.id);

  const hasDestinationAccess =
    ['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER'].includes(user?.role) ||
    (user.role === 'MANAGER' && unit?.id === destinationUnit?.id);

  const { stepItems, editableStep, currentStep } = useContext(DialogContext);

  const options = useMemo(
    () => getOptions({ step, stepItems, hasOriginAccess, hasDestinationAccess, editable, currentTab }),
    [editable, stepItems, step, hasOriginAccess, hasDestinationAccess, currentTab]
  );

  const {
    src,
    upcomingPatientTransport,
    handleUpcomingPatientTransportClick,
    handleOrderExternalTransportClick,
    onModalClose,
  } = useUpcomingPatientTransport(stepItems);

  return (
    <>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <DialogTabs
            options={options}
            value={currentTab ? currentTab : step.step.id}
            onChange={(id) => onTabChange(id)}
            disabled={editable}
          />
          {!isReadOnly && (
            <Box alignSelf={'flex-start'}>
              <DialogEditButtons currentStep={currentStep} />
            </Box>
          )}
        </Stack>
        {currentStep?.type === 'mutation' ? (
          <MutationDetailsContent
            step={editable ? (editableStep as MutationStep) : currentStep}
            editable={editable}
            onChange={(step) => onChange && onChange(step)}
            onDestinationChange={(destination) => onDestinationChange && onDestinationChange(destination)}
          />
        ) : currentStep?.type === 'accommodation' ? (
          <AccommodationDetailsContent
            step={editable ? (editableStep as AccommodationStep) : currentStep}
            editable={editable}
            onChange={(step) => onChange && onChange(step)}
            onUpcomingPatientTransportClick={handleUpcomingPatientTransportClick}
            onOrderExternalTransportClick={handleOrderExternalTransportClick}
            upcomingPatientTransport={upcomingPatientTransport}
          />
        ) : null}
      </Stack>
      <ExternalPatientTransportModal src={src} onClose={onModalClose} />
    </>
  );
};

export default MutationDetails;
const getOptions = ({
  step,
  stepItems,
  hasOriginAccess,
  hasDestinationAccess,
  editable,
  currentTab,
}: {
  step: MutationStep;
  stepItems: StepItem[];
  hasOriginAccess: boolean;
  hasDestinationAccess: boolean;
  editable?: boolean;
  currentTab?: string;
}) => {
  let options: DialogTabOption[] = [
    {
      label: !editable || currentTab === step.step.id ? i18n.detailsDialog.tabs.movement : undefined,
      value: step.step.id,
      Icon: <PathIcon />,
      iconColor: color.blue[30],
    },
  ];

  const pastStep = stepItems.find((item) => item.step.id === step.origin?.id) as AccommodationStep;
  if (pastStep && hasOriginAccess) {
    options.unshift({
      label:
        !editable || currentTab === pastStep.step.id
          ? i18n.detailsDialog.tabs[getStepSchedule(pastStep.step, pastStep.anomalies || [])]
          : undefined,
      value: pastStep.step.id,
      Icon: <ComfortBedIcon />,
      iconColor: color.blue[40],
    });
  }

  const futureStep = stepItems.find((item) => item.step.id === step.destination?.id) as AccommodationStep;
  if (futureStep && hasDestinationAccess) {
    options.push({
      label:
        !editable || currentTab === futureStep.step.id
          ? i18n.detailsDialog.tabs[getStepSchedule(futureStep.step, futureStep.anomalies || [])]
          : undefined,
      value: futureStep.step.id,
      Icon: <ComfortBedIcon />,
      iconColor: color.blue[40],
    });
  }

  if (
    options.length > 2 &&
    options.some((option) => option.value === pastStep.step.id) &&
    step.step.status === 'ACCEPTED'
  ) {
    options = options.filter((option) => option.value !== pastStep.step.id);
  }

  return options;
};
