import { ThesaurusItem } from '@ambuliz/sabri-core';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { ThesaurusTableSkeleton } from './ThesaurusTableSkeleton';
import { ThesaurusTableRow } from './ThesaurusTableRow';

type ThesaurusTableProps = {
  thesaurus: ThesaurusItem[];
  isLoading?: boolean;
  count: number;
  page: number;
  onPageChange: (page: number) => void;
  rowsPerPage: number;
  onRowsPerPageChange: (rowsPerPage: number) => void;
};

export const ThesaurusTable = ({
  thesaurus,
  isLoading,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: ThesaurusTableProps) => {
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Motif d'admission</TableCell>
              <TableCell>DMS</TableCell>
              <TableCell>Orientation 1</TableCell>
              <TableCell>Orientation 2</TableCell>
              <TableCell>Orientation 3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <ThesaurusTableSkeleton count={rowsPerPage} />
            ) : (
              thesaurus.map((item) => <ThesaurusTableRow key={item.id} thesaurusItem={item} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        count={count}
        component="div"
        onPageChange={(_, newPage) => onPageChange(newPage)}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
      />
    </Paper>
  );
};
