import { AccommodationSpecificity, AccommodationStatus } from '@ambuliz/sabri-core';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { ChevronRight, MoreVertical } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useAuthentication } from 'core/authentication';
import CancelAccommodationDialog from 'kurt/components/CancelAccommodation';
import { ViaTrajectoireLink, ViaTrajectoireLoader } from 'kurt/components/PatientOutcome/ViaTrajectoireLink';
import ReportPatientDischargedDialog from 'kurt/components/ReportPatientDischarged';
import SendAccommodationRequestDialog from 'kurt/components/SendAccommodationRequest';
import { useRef, useState } from 'react';
import { ReportPatientAdmittedDialog, UpdateBedDialog } from '../Dialogs';

type BusyBedActionsProps = {
  id: string;
  status: AccommodationStatus;
  bedId: string;
  unitId: string;
  visitId: string;
  comment?: string;
  specificities?: AccommodationSpecificity[];
  onCancelAccommodation?: () => void;
  onOpenChange?: (open: boolean) => void;
};

const BusyBedActionsMenu = ({
  id,
  status,
  bedId,
  unitId,
  visitId,
  comment,
  specificities = [],
  onCancelAccommodation,
  onOpenChange,
}: BusyBedActionsProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const [action, setAction] = useState<Action>();

  const {
    healthCenter: { viaTrajectoireEnabled },
  } = useAuthentication();

  const handleMenuClose = () => {
    setOpen(false);
    onOpenChange?.(false);
  };

  const handleMenuItemClick = (action: Action) => {
    handleMenuClose();
    setAction(action);
  };

  const handleDialogClose = () => {
    setAction(undefined);
  };

  const handleSuccess = () => {
    if (action === 'CANCEL_ACCOMMODATION' && onCancelAccommodation) {
      handleDialogClose();
      handleMenuClose();
      return onCancelAccommodation();
    }
    handleDialogClose();
    handleMenuClose();
  };

  return (
    <Box display="flex" onClick={(event) => event.stopPropagation()}>
      <IconButton
        onClick={() => {
          onOpenChange?.(!open);
          setOpen(!open);
        }}
        ref={ref}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {status === 'PLANNED' && (
          <MenuItem onClick={() => handleMenuItemClick('REPORT_PATIENT_ADMITTED')}>
            {i18n.reportPatientAdmitted.actionLabel}
          </MenuItem>
        )}
        {status === 'PLANNED' && (
          <MenuItem onClick={() => handleMenuItemClick('UPDATE_BED')}>{i18n.updateBed.actionLabel}</MenuItem>
        )}
        {status === 'IN_PROGRESS' && (
          <MenuItem onClick={() => handleMenuItemClick('REQUEST_MOVEMENT')}>
            {i18n.requestMovement.actionLabel}
          </MenuItem>
        )}
        {status === 'IN_PROGRESS' && (
          <MenuItem onClick={() => handleMenuItemClick('REPORT_PATIENT_DISCHARGED')}>
            {i18n.reportPatientDischarged.actionLabel}
          </MenuItem>
        )}
        {(status === 'IN_PROGRESS' || status === 'PLANNED') && viaTrajectoireEnabled && (
          <ViaTrajectoireLink
            openOnHover
            visitId={visitId}
            loader={
              <MenuItem>
                <ViaTrajectoireLoader textMarginLeft={3} />
              </MenuItem>
            }
            button={
              <MenuItem>
                <Typography>{i18n.visitActions['VIA_TRAJECTOIRE_REDIRECT'].action.label}</Typography>
                <ChevronRight style={{ marginLeft: 8 }} fontSize="small" color="action" />
              </MenuItem>
            }
          />
        )}
        <MenuItem onClick={() => handleMenuItemClick('CANCEL_ACCOMMODATION')} sx={{ color: palette.error.main }}>
          {i18n.cancelAccommodation.actionLabel}
        </MenuItem>
      </Menu>
      {action === 'REPORT_PATIENT_ADMITTED' && (
        <ReportPatientAdmittedDialog
          open={action === 'REPORT_PATIENT_ADMITTED'}
          onClose={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
        />
      )}
      {action === 'REPORT_PATIENT_DISCHARGED' && (
        <ReportPatientDischargedDialog
          open={action === 'REPORT_PATIENT_DISCHARGED'}
          onClose={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
        />
      )}
      {action === 'CANCEL_ACCOMMODATION' && (
        <CancelAccommodationDialog
          open={action === 'CANCEL_ACCOMMODATION'}
          onClose={handleDialogClose}
          onCancel={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
        />
      )}
      {action === 'UPDATE_BED' && (
        <UpdateBedDialog
          open={action === 'UPDATE_BED'}
          onClose={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
          bedId={bedId}
          unitId={unitId}
        />
      )}
      {action === 'REQUEST_MOVEMENT' && (
        <SendAccommodationRequestDialog
          open={action === 'REQUEST_MOVEMENT'}
          unitId={unitId}
          visitId={visitId}
          onClose={handleDialogClose}
          comment={comment}
          specificities={specificities}
        />
      )}
    </Box>
  );
};

type Action =
  | 'REPORT_PATIENT_ADMITTED'
  | 'UPDATE_BED'
  | 'REPORT_PATIENT_DISCHARGED'
  | 'CANCEL_ACCOMMODATION'
  | 'REQUEST_MOVEMENT';

export default BusyBedActionsMenu;
