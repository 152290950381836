import { IconButton, Tab, Tabs } from '@mui/material';
import { ChevronSmallLeft, ChevronSmallRight } from 'common/components/Icons';
import { palette } from 'common/theme';
import { Tab as TabType, useAppBarContext } from 'core/layout';
import { useEffect, useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const AppBarTabs = () => {
  const { tabs } = useAppBarContext();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<TabType>();
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(tabs.find((tab) => tab.path === newValue));
    // Sometimes scroll is not reset after tab change
    if (window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const defaultTab = tabs.find((tab) => tab.isDefault);
    if (tabs) {
      if (defaultTab && location.pathname.includes(defaultTab.basePath || defaultTab.path)) {
        setCurrentTab(defaultTab);
      } else {
        setCurrentTab(tabs.find((tab) => location.pathname.includes(tab.basePath || tab.path)));
      }
    }
  }, [tabs, location.pathname]);

  if (!currentTab) {
    return null;
  }
  return (
    <StyledTabs
      value={currentTab.path}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
      variant="scrollable"
      scrollButtons="auto"
      sx={{ flexShrink: 1 }}
      onChange={handleTabChange}
      ScrollButtonComponent={(props) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton {...props} sx={{ margin: props.direction === 'left' ? '0 8px 0 0' : '0 0 0 8px' }}>
            {props.direction === 'left' ? <ChevronSmallLeft /> : <ChevronSmallRight />}
          </IconButton>
        </div>
      )}
    >
      {tabs.map(({ path, label }) => {
        return (
          <StyledTab
            key={path}
            component={Link}
            to={path}
            value={path}
            label={label}
            disableRipple
            disabled={path === currentTab.path}
          />
        );
      })}
    </StyledTabs>
  );
};

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    display: flex;
    justify-content: center;
    background-color: transparent;
    height: 4px;
  }

  & .MuiTabs-indicatorSpan {
    max-width: 32px;
    width: 100%;
    height: 100%;
    background-color: ${palette.primary.main};
    border-radius: 2px;
  }
`;

type StyledTabProps = {
  component: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
  to: string;
};

const StyledTab = styled(Tab)<StyledTabProps>`
  font-size: 14px;
  padding: 12px;
  text-transform: none;
  &.Mui-selected {
    font-weight: 600;
  }
  &.Mui-disabled {
    color: ${palette.primary.main};
  }
  min-width: 0px;
`;

export default AppBarTabs;
