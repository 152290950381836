import { Card, Skeleton, Stack } from '@mui/material';

const KanbanCardSkeleton: React.FC = () => {
  return (
    <Card>
      <Stack spacing={3} padding={4}>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rectangular" sx={{ borderRadius: 1 }} height={78} />
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={70} height={20} />
          <Skeleton variant="circular" width={20} height={20} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default KanbanCardSkeleton;
