import { getSectorsBySpecialties, unitSpecialties, UnitSpecialty } from '@ambuliz/sabri-core';
import { Autocomplete, Chip, FormLabel, Grid, TextField } from '@mui/material';
import { i18n } from 'common/locale';

type UnitSpecialtiesSelectProps = {
  value: UnitSpecialty[];
  onChange: (value: UnitSpecialty[]) => void;
};

const UnitSpecialtiesSelect: React.FC<UnitSpecialtiesSelectProps> = ({ value = [], onChange }) => {
  const sectors = getSectorsBySpecialties(value);

  return (
    <Grid item container spacing={2} direction="column">
      <Grid item>
        <Autocomplete
          multiple
          value={value}
          onChange={(_, value) => {
            onChange(value);
          }}
          options={[...unitSpecialties]}
          noOptionsText={i18n.noResult}
          renderInput={(params) => <TextField {...params} variant="outlined" label={i18n.specialties} />}
        />
      </Grid>
      {sectors.length > 0 && (
        <Grid container item spacing={1}>
          <Grid item>
            <FormLabel>{i18n.sectors}</FormLabel>
          </Grid>
          {sectors.map((sector) => (
            <Grid item key={sector}>
              <Chip label={sector} color="primary" />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default UnitSpecialtiesSelect;
