import { Link, Stack } from '@mui/material';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { PageHeader, PageSection } from 'core/layout';
import { Route, Routes } from 'react-router-dom';
import routes from 'superadmin/routes';
import PatientDetailsDialog from '../steps/patients/PatientDetailsDialog';
import CreatePatientForm from './CreatePatientForm';
import PatientSearchPage from './PatientSearchPage';
import PatientTimeline from './PatientTimeline';
import usePatient from './usePatient';

const PatientManagement = () => {
  const { create, loading, error, success } = usePatient();

  if (error) {
    toast.error(i18n.globalError);
  }

  if (success) {
    toast.success(i18n.messageSuccessPatientCreate);
  }

  return (
    <>
      <PageSection>
        <Stack direction="row" spacing={4} marginTop={6}>
          <Link href="">Patients</Link>
          <Link href="create">Créer un patient</Link>
          <Link href="timeline">Timeline</Link>
        </Stack>
      </PageSection>

      <Routes>
        <Route path="/" element={<PatientSearchPage />}>
          <Route path=":id" element={<PatientDetailsDialog returnUrl={routes.patients.path} />} />
        </Route>
        <Route path="timeline/*" element={<PatientTimeline />} />
        <Route
          path="create"
          element={
            <>
              <PageHeader title={i18n.patientManagementTitle} />
              <PageSection>
                <CreatePatientForm onSubmit={create} loading={loading} reset={success} />
              </PageSection>
            </>
          }
        />
      </Routes>
    </>
  );
};

export default PatientManagement;
