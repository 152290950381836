import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Chip, ChipProps, Tooltip, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { specificityColor, specificityIcon } from './SpecificityChipConfig';

type SpecificityCounterChipProps = ChipProps & {
  specificity: AccommodationSpecificity;
  value: number;
};

const SpecificityCounterChip: React.FC<SpecificityCounterChipProps> = ({ specificity, value, ...rest }) => (
  <Tooltip
    placement="top"
    title={
      <Typography variant="body2" fontWeight={600}>
        {i18n.accommodationSpecificities[specificity]}
      </Typography>
    }
  >
    <Chip color={specificityColor[specificity]} icon={specificityIcon[specificity]} label={value || 0} {...rest} />
  </Tooltip>
);

export default SpecificityCounterChip;
