import { Divider, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { Bed, Desk, Timeline } from 'common/components/Images';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { ColumnStatus } from 'kurt/pages/Movements/MovementsKanban';
import { Visit } from 'kurt/pages/Movements/useVisits';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import KanbanCard from './KanbanCard/KanbanCard';
import KanbanCardAdmissionRequest from './KanbanCard/KanbanCardAdmissionRequest';
import KanbanCardDischargeRequest from './KanbanCard/KanbanCardDischargeRequest';
import KanbanColumn from './KanbanColumn';
import KanbanEmptyContent from './KanbanEmptyContent';
import getKanbanColumnsConditions from './getKanbanColumnsConditions';
import HourglassImage from './images/HourglassImage';

type PatientFlowKanbanProps = {
  loading?: boolean;
  flow: 'admission' | 'discharge';
  requestedVisits?: Visit[];
  plannedVisits?: Visit[];
  validatedVisits?: Visit[];
  completedVisits?: Visit[];
  dropAllowed?: boolean;
  isDropColumnActive: (column: ColumnStatus) => boolean;
  activeVisit?: Visit & { visitStatus?: ColumnStatus };
  showErrorMessage?: boolean;
  isReadOnly?: boolean;
};

const PatientFlowKanban: React.FC<PatientFlowKanbanProps> = ({
  loading = false,
  flow,
  activeVisit,
  requestedVisits = [],
  plannedVisits = [],
  validatedVisits = [],
  completedVisits = [],
  dropAllowed,
  showErrorMessage = false,
  isDropColumnActive,
  isReadOnly = false,
}) => {
  const navigation = useRef(useNavigate());

  const isActiveVisitMutation =
    flow === 'discharge' &&
    !!activeVisit?.nextAccommodation?.basedOn &&
    !!activeVisit?.nextAccommodation?.basedOn?.performerRequests;

  const handleClick = useCallback((id: string, type: 'accommodation' | 'mutation') => {
    navigation.current(`${window.location.pathname}/${type}/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  return (
    <Stack
      direction="row"
      divider={<Divider flexItem orientation="vertical" sx={{ borderColor: color.grey[10] }} />}
      flex={1}
    >
      <KanbanColumn
        loading={loading}
        id="requested"
        data={{
          accept: getKanbanColumnsConditions('requested', flow, isActiveVisitMutation),
        }}
        isDropForbidden={!dropAllowed}
      >
        {requestedVisits.length > 0 ? (
          <>
            {requestedVisits.map((visit) =>
              flow === 'admission' ? (
                <KanbanCardAdmissionRequest
                  key={`${visit.basedOn?.id}-${visit.previousAccommodation?.admissionDate}`}
                  visit={visit}
                  isReadOnly={isReadOnly}
                  isVisitActive={activeVisit && activeVisit.id === visit.id}
                  onClick={handleClick}
                />
              ) : (
                <KanbanCardDischargeRequest
                  key={`${visit.basedOn?.id}-${visit?.admissionDate}`}
                  visit={visit}
                  isReadOnly={isReadOnly}
                  draggable={visit?.basedOn?.status !== 'REJECTED'}
                  isVisitActive={activeVisit && activeVisit.id === visit.id}
                  onClick={handleClick}
                />
              )
            )}
          </>
        ) : (
          <KanbanEmptyContent
            Image={HourglassImage}
            title={i18n.noPendingRequest}
            subtitle={i18n.everythingIsCalmHere}
          />
        )}
      </KanbanColumn>

      <KanbanColumn
        loading={loading}
        id="planned"
        data={{
          accept: getKanbanColumnsConditions('planned', flow, isActiveVisitMutation),
        }}
        isDropForbidden={!dropAllowed}
      >
        {isDropColumnActive('planned') && dropAllowed && <ActiveIndicator />}
        {plannedVisits.length > 0 ? (
          <>
            {plannedVisits.map((visit) => (
              <KanbanCard
                isReadOnly={isReadOnly}
                key={visit.id}
                visit={visit}
                flow={flow}
                status="planned"
                isVisitActive={activeVisit && activeVisit.id === visit.id}
                onClick={handleClick}
              />
            ))}
          </>
        ) : (
          !(isDropColumnActive('planned') && dropAllowed) && (
            <KanbanEmptyContent
              Image={Timeline}
              title={flow === 'admission' ? i18n.noStayToBePlaced : i18n.noPlannedDischarge}
              subtitle={flow === 'admission' ? i18n.thatsAllForNow : i18n.nothingAtTheHorizon}
            />
          )
        )}
      </KanbanColumn>

      <KanbanColumn
        loading={loading}
        id="validated"
        data={{
          accept: getKanbanColumnsConditions('validated', flow, isActiveVisitMutation),
        }}
        isDropForbidden={!dropAllowed}
      >
        {isDropColumnActive('validated') && dropAllowed && <ActiveIndicator />}
        {validatedVisits.length > 0 ? (
          <>
            {validatedVisits.map((visit) => (
              <KanbanCard
                isReadOnly={isReadOnly}
                key={visit.id}
                visit={visit}
                flow={flow}
                status="validated"
                isVisitActive={activeVisit && activeVisit.id === visit.id}
                onClick={handleClick}
              />
            ))}
          </>
        ) : (
          !(isDropColumnActive('validated') && dropAllowed) && (
            <KanbanEmptyContent
              Image={Bed}
              title={flow === 'admission' ? i18n.noStayWaitingForAdmission : i18n.noValidatedDischarge}
              subtitle={flow === 'admission' ? i18n.everythingIsCalmHere : i18n.certitudeCitation}
            />
          )
        )}
      </KanbanColumn>

      <KanbanColumn
        loading={loading}
        id="completed"
        data={{
          accept: getKanbanColumnsConditions('completed', flow, isActiveVisitMutation),
        }}
        isDropForbidden={!dropAllowed}
      >
        {showErrorMessage && (
          <Stack spacing={3} direction="row" alignItems="center">
            <Emoji name="warning" />
            <Typography color={palette.warning.main} variant="body2" fontWeight={600}>
              {i18n.unableToDragFromColumn[flow]}
            </Typography>
          </Stack>
        )}
        {isDropColumnActive('completed') && dropAllowed && <ActiveIndicator />}
        {completedVisits.length > 0 ? (
          <>
            {completedVisits.map((visit) => (
              <KanbanCard
                isReadOnly={isReadOnly}
                key={visit.id}
                visit={visit}
                flow={flow}
                status="completed"
                isVisitActive={activeVisit && activeVisit.id === visit.id}
                onClick={handleClick}
              />
            ))}
          </>
        ) : (
          !(isDropColumnActive('completed') && dropAllowed) && (
            <KanbanEmptyContent
              Image={Desk}
              title={flow === 'admission' ? i18n.noAdmissionToday : i18n.noDischargeToday}
              subtitle={flow === 'admission' ? i18n.nothingToSignal : i18n.itsCalmForNow}
            />
          )
        )}
      </KanbanColumn>
    </Stack>
  );
};

const ActiveIndicator = styled.div`
  width: 100%;
  height: 16px;
  border: 1px dashed ${color.blue[40]};
  background-color: ${color.blue[20]};
  border-radius: 4px;
  box-sizing: border-box;
`;

export default PatientFlowKanban;
