import { Link, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';

type NoPatientResultProps = {
  onClick: () => void;
};

const NoPatientResult: React.FC<NoPatientResultProps> = ({ onClick }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Typography>{i18n.noPatientFound}</Typography>
    <Link component="button" onClick={onClick}>
      <Typography fontWeight={600} color="primary">
        {i18n.createPatientForm.linkLabel}
      </Typography>
    </Link>
  </Stack>
);

export default NoPatientResult;
