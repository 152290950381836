import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';

type IconInfoProps = {
  color: string;
  icon: ReactElement;
};

export const IconInfo: React.FC<IconInfoProps> = ({ color, icon }) => {
  const classes = useClasses({ color });
  return <div className={classes.container}>{React.cloneElement(icon, { className: classes.icon })}</div>;
};

const useClasses = makeStyles({
  container: ({ color }: { color: string }) => ({
    backgroundColor: color,
    borderRadius: 14,
    width: 28,
    height: 28,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
  icon: {
    color: 'white',
  },
});
