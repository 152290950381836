import { PatientGender } from '@ambuliz/sabri-core';
import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { FilterSelect } from 'common/components/Form';
import { i18n } from 'common/locale';
import * as React from 'react';

type PatientGenderSelectProps = {
  value: PatientGender[];
  onChange: (value: PatientGender[]) => void;
};

const PatientGenderSelect: React.FC<PatientGenderSelectProps> = ({ value = [], onChange }) => {
  return (
    <FilterSelect value={value} multiple onChange={onChange} label={i18n.patientGender}>
      <MenuItem value="FEMALE">
        <Checkbox checked={value.includes('FEMALE')} />
        <ListItemText primary={i18n.woman} />
      </MenuItem>
      <MenuItem value="MALE">
        <Checkbox checked={value.includes('MALE')} />
        <ListItemText primary={i18n.man} />
      </MenuItem>
    </FilterSelect>
  );
};

export default PatientGenderSelect;
