import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ListPagination, SortableTableCell } from 'common/components';
import { AnomaliesOrderBy } from './useAnomalies';
import AnomaliesPageFilters from './AnomaliesPageFilters';
import { Order } from 'common/types';

type AnomaliesListProps = {
  children?: React.ReactNode;
  unitId: string;
  order: Order;
  orderBy: AnomaliesOrderBy;
  page: number;
  rowsPerPage: number;
  totalCount: number;
  onSort: (name: AnomaliesOrderBy) => void;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
};

const AnomalyList = ({
  unitId,
  children,
  order,
  orderBy,
  page,
  rowsPerPage,
  totalCount,
  onSort,
  onPageChange,
  onRowsPerPageChange,
}: AnomaliesListProps) => {
  return (
    <Stack paddingY={4} paddingBottom={20}>
      <AnomaliesPageFilters unitId={unitId} />
      <Paper>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <SortableTableCell name="status" order={order} orderBy={orderBy} onSort={() => onSort('status')}>
                  Statut
                </SortableTableCell>
                <SortableTableCell name="type" order={order} orderBy={orderBy} onSort={() => onSort('type')}>
                  Type
                </SortableTableCell>
                <SortableTableCell name="startAt" order={order} orderBy={orderBy} onSort={() => onSort('startAt')}>
                  Début
                </SortableTableCell>
                <SortableTableCell name="endAt" order={order} orderBy={orderBy} onSort={() => onSort('endAt')}>
                  Fin
                </SortableTableCell>
                <SortableTableCell name="area" order={order} orderBy={orderBy} onSort={() => onSort('area')}>
                  Lit
                </SortableTableCell>
                <TableCell>Hébergement</TableCell>
                <TableCell>Anomalie liée</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
        <ListPagination
          count={totalCount}
          onPageChange={(_event, newPage) => onPageChange(newPage)}
          onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
          page={page}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Anomalies par page"
        />
      </Paper>
    </Stack>
  );
};

export default AnomalyList;
