import { Fare } from '@ambuliz/sabri-core';
import { Grid, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { i18n } from '../../locales';
import FareDetailsGroupedFare from './FareDetailsGroupedFare';

type FareDetailsGroupedFaresProps = {
  fares: Fare[];
};

const FareDetailsGroupedFares: React.FC<FareDetailsGroupedFaresProps> = ({ fares }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Typography variant="h5">{i18n.faresGroup}</Typography>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.reference}</TableCell>
              <TableCell>{i18n.patient}</TableCell>
              <TableCell>{i18n.date}</TableCell>
            </TableRow>
          </TableHead>
          {fares.map((fare) => (
            <FareDetailsGroupedFare fare={fare} key={fare.id} />
          ))}
        </Table>
      </TableContainer>
    </Grid>
  );
};

const useStyles = makeStyles({
  table: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '0',
    borderRadius: 6,
    marginTop: 8,
    '& .MuiTableCell-root': {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  },
});
export default FareDetailsGroupedFares;
