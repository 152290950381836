import { SvgIcon, SvgIconProps } from '@mui/material';

const Info: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.875 6.31234L6.25 6.59359V7.15609H6.83333C7.125 7.15609 7.125 7.36978 7.125 7.72024V11.3748C7.125 11.7797 6.96436 11.8467 6.25 11.9373V12.4998H9.75V11.9373C9.03564 11.8467 8.875 11.7797 8.875 11.3748V6.31234ZM8 5.1875C8.48325 5.1875 8.875 4.80974 8.875 4.34375C8.875 3.87776 8.48325 3.5 8 3.5C7.51675 3.5 7.125 3.87776 7.125 4.34375C7.125 4.80974 7.51675 5.1875 8 5.1875Z"
      />
    </SvgIcon>
  );
};

export default Info;
