import { Components } from '@mui/material';
import color from '../color';

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsVariantOverrides {
    action: true;
  }
}

const MuiButtonGroup: Components['MuiButtonGroup'] = {
  styleOverrides: {
    root: {
      '&:empty': {
        border: 0,
      },
    },
  },
  variants: [
    {
      props: { variant: 'action' },
      style: {
        // Remove shadow from button
        border: `1px solid ${color.grey[10]}`,
        boxShadow: '0px 1px 2px 0px rgba(20, 22, 28, 0.06)',
        '& .MuiButtonGroup-groupedVertical': {
          border: 0,
          '&:not(:first-of-type):after': {
            position: 'absolute',
            content: "''",
            borderTop: `1px solid ${color.grey[10]}`,
            height: '100%',
            left: 48,
            right: 0,
          },
        },
      },
    },
  ],
};

export default MuiButtonGroup;
