import { Area } from '@ambuliz/sabri-core';
import { useEffect, useState } from 'react';
import { getBreadCrumb } from './getBreadCrumb';

export const useBreadcrumb = ({ areaId }: { areaId: string }) => {
  const [areas, setAreas] = useState<Area[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const areas = await getBreadCrumb(areaId, []);
      setAreas(areas);
      setIsLoading(false);
    };
    fetch();
  }, [areaId]);

  return { areas, isLoading };
};
