import { ChangeEvent, useEffect, useState } from 'react';

const usePagination = (count: number, defaultRowsPerPage = 50) => {
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const lastPage = Math.ceil(count / rowsPerPage);

  useEffect(() => {
    if (lastPage > 0 && page + 1 > lastPage) {
      setPage(lastPage - 1);
    }
  }, [count, page, lastPage]);

  return {
    page,
    rowsPerPage,
    changePage: handleChangePage,
    changeRowsPerPage: handleChangeRowsPerPage,
  };
};

export default usePagination;
