import { Box } from '@mui/material';
import { FlowType, MovementsFilter } from 'kurt/pages/Movements/useMovementsPageSearchParams';
import AdmissionList from './admission/AdmissionList';
import DischargeList from './discharge/DischargeList';

const MovementsList = ({
  unitIds,
  date,
  flow,
  filter,
  isReadOnly = false,
  onFilterCountChange,
}: {
  unitIds: string[];
  date: Date;
  flow: FlowType;
  filter: MovementsFilter;
  isReadOnly?: boolean;
  onFilterCountChange: (count: number) => void;
}) => {
  return (
    <Box height="100%">
      {flow === 'admission' ? (
        <AdmissionList
          unitIds={unitIds}
          date={date}
          filter={filter}
          onFilterCountChange={onFilterCountChange}
          isReadOnly={isReadOnly}
        />
      ) : (
        <DischargeList
          unitIds={unitIds}
          date={date}
          filter={filter}
          onFilterCountChange={onFilterCountChange}
          isReadOnly={isReadOnly}
        />
      )}
    </Box>
  );
};

export default MovementsList;
