import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useEmergencyPageFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const search = useMemo(() => searchParams.get('search') ?? '', [searchParams]);
  const genders = useMemo(() => searchParams.getAll('genders') as PatientGender[], [searchParams]);
  const specificities = useMemo(
    () => searchParams.getAll('specificities') as AccommodationSpecificity[],
    [searchParams]
  );
  const practitioners = useMemo(() => searchParams.getAll('practitioners'), [searchParams]);

  const resetFilters = () => setSearchParams({});

  return {
    search,
    genders,
    specificities,
    practitioners,
    resetFilters,
  };
};

export default useEmergencyPageFilters;
