import { SVGProps } from 'react';

const Hospital = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 86 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M30.434.012C21.776.68 19.84 1.476 14.361 5.548 5.27 12.307-1.095 23.48.157 34.74c.887 7.966 5.385 15.143 10.905 20.956 3.396 3.577 7.278 6.793 11.803 8.754 5.077 2.204 10.759 2.734 16.277 2.316 5.934-.45 11.85-1.998 16.947-5.069 7.06-4.256 12.266-11.398 14.418-19.356 2.152-7.96 1.285-16.65-2.15-24.147-.962-2.097-2.127-4.12-3.658-5.845-1.695-1.911-3.797-3.417-5.98-4.745C50.266 2.458 40.328-.206 30.434.013Z"
      fill="#E6EDFF"
    />
    <path d="M1.463 89.37V43.33c0-.508.413-.923.921-.923h23.868v46.962" fill="#B5CAFF" />
    <path
      d="M26.975 55.873H8.55a.653.653 0 0 1-.653-.654v-6.983c0-.36.292-.654.653-.654h18.425M26.975 67.865H8.55a.653.653 0 0 1-.653-.654v-6.983c0-.36.292-.654.653-.654h18.425M26.975 79.715H8.55a.652.652 0 0 1-.653-.654v-6.983c0-.36.292-.652.653-.652h18.425"
      fill="#fff"
    />
    <path
      d="m60.338 51.363-10.352-2.075.419-35.955 9.826 4.195.107 33.835ZM85.461 89.395H75.46a.172.172 0 0 1-.172-.172V30.235c0-.113.108-.196.218-.165l9.902 2.724c.074.02.126.088.126.165l.102 56.263a.175.175 0 0 1-.174.173Z"
      fill="#5470B8"
    />
    <path
      d="M50.405 29.914V13.426a.095.095 0 0 0-.095-.093H25.906a.095.095 0 0 0-.095.093V89.3c0 .053.043.095.094.095h49.287a.095.095 0 0 0 .095-.095V30.104a.095.095 0 0 0-.095-.095H50.5a.095.095 0 0 1-.095-.095Z"
      fill="#89A5ED"
    />
    <path d="M32.48 89.37V72.82c0-.562.457-1.02 1.02-1.02h9.538c.564 0 1.022.458 1.022 1.02v16.55" fill="#5470B8" />
    <path
      d="m57.501 44.044-.024-7.007 17.82-.023v7.03H57.501ZM57.501 67.886l-.024-7.006 17.82-.025v7.03H57.501ZM57.501 79.808l-.024-7.007 17.82-.025v7.032H57.501ZM57.501 55.965l-.024-7.008 17.82-.023v7.03H57.501Z"
      fill="#B5CAFF"
    />
    <path
      d="M45.044 24.629h-1.802c-.715 0-1.293-.58-1.293-1.293v-1.803c0-.714-.579-1.293-1.293-1.293h-4.7c-.715 0-1.294.579-1.294 1.293v1.803c0 .713-.578 1.293-1.292 1.293h-1.803c-.715 0-1.293.578-1.293 1.293v4.7c0 .715.578 1.293 1.293 1.293h1.803c.714 0 1.293.579 1.293 1.293v1.803c0 .714.578 1.293 1.293 1.293h4.7c.714 0 1.293-.579 1.293-1.293v-1.803c0-.714.578-1.293 1.293-1.293h1.802c.715 0 1.294-.578 1.294-1.293v-4.7c0-.715-.579-1.293-1.294-1.293Z"
      fill="#fff"
    />
  </svg>
);

export default Hospital;
