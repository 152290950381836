import { SvgIcon, SvgIconProps } from '@mui/material';

const SidebarIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path d="M10.883 3.00001C10.883 2.65483 11.1629 2.37501 11.508 2.37501H12.9017C13.2468 2.37501 13.5267 2.65483 13.5267 3.00001C13.5267 3.34519 13.2468 3.62501 12.9017 3.62501H11.508C11.1629 3.62501 10.883 3.34519 10.883 3.00001Z" />
    <path d="M11.508 4.37502C11.1629 4.37502 10.883 4.65484 10.883 5.00002C10.883 5.34519 11.1629 5.62502 11.508 5.62502H12.9017C13.2468 5.62502 13.5267 5.34519 13.5267 5.00002C13.5267 4.65484 13.2468 4.37502 12.9017 4.37502H11.508Z" />
    <path d="M10.883 7.00002C10.883 6.65484 11.1629 6.37502 11.508 6.37502H12.9017C13.2468 6.37502 13.5267 6.65484 13.5267 7.00002C13.5267 7.34519 13.2468 7.62502 12.9017 7.62502H11.508C11.1629 7.62502 10.883 7.34519 10.883 7.00002Z" />
    <path d="M11.508 8.37501C11.1629 8.37501 10.883 8.65483 10.883 9.00001C10.883 9.34519 11.1629 9.62501 11.508 9.62501H12.9017C13.2468 9.62501 13.5267 9.34519 13.5267 9.00001C13.5267 8.65483 13.2468 8.37501 12.9017 8.37501H11.508Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.00875854 1.99219C-0.00875854 0.887618 0.886672 -0.0078125 1.99124 -0.0078125H14C15.1046 -0.0078125 16 0.887618 16 1.99219V13.9849C16 15.0894 15.1046 15.9849 14 15.9849H1.99124C0.886672 15.9849 -0.00875854 15.0894 -0.00875854 13.9849V1.99219ZM9.65784 1.24219H14C14.4142 1.24219 14.75 1.57797 14.75 1.99219V13.9849C14.75 14.3991 14.4142 14.7349 14 14.7349H9.65784L9.65784 1.24219ZM8.40784 1.24219H1.99124C1.57703 1.24219 1.24124 1.57797 1.24124 1.99219L1.24124 13.9849C1.24124 14.3991 1.57703 14.7349 1.99124 14.7349H8.40784L8.40784 1.24219Z"
    />
  </SvgIcon>
);

export default SidebarIcon;
