import { Stack } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { isDate, isValid } from 'date-fns';
import { useMemo, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { StepFormValue, formKeys } from './JourneyForm';

type JourneyStepDateInputProps = {
  name: string;
  error?: FieldError;
  disabled?: boolean;
};

const JourneyStepDateInput = ({ name, error, disabled: initialDisabled = false }: JourneyStepDateInputProps) => {
  const { watch, clearErrors, control } = useFormContext();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const isEmergency = watch<string, boolean>(formKeys.isEmergency);
  const steps = watch<string, StepFormValue[]>(formKeys.steps);
  const wantedDate = watch<string, Date | undefined>(name);

  const disabled = useMemo(() => {
    return initialDisabled || (isEmergency && steps.length === 1);
  }, [initialDisabled, isEmergency, steps]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={wantedDate}
      render={({ value, onChange }) => (
        <Stack direction="row" gap={2}>
          <DatePicker
            disabled={disabled}
            disablePast
            localeText={{
              fieldDayPlaceholder: () => 'jj',
              fieldMonthPlaceholder: () => 'mm',
              fieldYearPlaceholder: () => 'aaaa',
            }}
            value={value}
            onChange={(event) => {
              clearErrors(name);
              onChange(event);
              setCalendarOpen(false);
            }}
            open={calendarOpen}
            onClose={() => setCalendarOpen(false)}
            slotProps={{
              textField: {
                sx: {
                  width: 132,
                  '& input': {
                    paddingRight: '0px !important',
                  },
                },
                onClick: () => setCalendarOpen(true),
              },
              openPickerButton: {
                onClick: () => setCalendarOpen(true),
              },
            }}
          />
          <TimePicker
            disabled={disabled}
            value={value || null}
            onChange={(value) => {
              if (value && isValid(value)) {
                const nextDate = isDate(value) ? value : new Date();
                clearErrors(name);
                onChange(nextDate);
              }
            }}
            slotProps={{
              textField: {
                error: !!error,
              },
            }}
          />
        </Stack>
      )}
    />
  );
};

export default JourneyStepDateInput;
