import { PageHeader, PageSection } from 'core/layout';
import HealthcareEnvironmentalCleaningList from './HealthcareEnvironmentalCleaningList';

const HealthCenterEnvironmentalCleaning = () => {
  return (
    <>
      <PageHeader title="Bionettoyage"></PageHeader>
      <PageSection>
        <HealthcareEnvironmentalCleaningList />
      </PageSection>
    </>
  );
};

export default HealthCenterEnvironmentalCleaning;
