import { HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import ExternalTransportStatusChip from 'superadmin/pages/ExternalTransport/ExternalTransportStatusChip';

type HealtcareEnvironmentalListItemProps = HealthcareEnvironmentalCleaning;

const HealtcareEnvironmentalListItem = ({
  externalId,
  status,
  endAt,
  startAt,
  location,
}: HealtcareEnvironmentalListItemProps) => {
  return (
    <>
      <TableRow>
        <TableCell width={14} sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Typography noWrap fontWeight={600}>
            {externalId}
          </Typography>
        </TableCell>
        <TableCell>
          <Box display="flex">
            <ExternalTransportStatusChip status={status} />
          </Box>
        </TableCell>

        <TableCell>
          <Typography fontWeight={600}>{location?.name || '-'}</Typography>
        </TableCell>

        <TableCell width={80}>
          {startAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(startAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>

        <TableCell>
          {endAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(endAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default HealtcareEnvironmentalListItem;
