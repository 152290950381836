import { Components } from '@mui/material';
import { CrossSmall } from 'common/components/Icons';
import color from '../color';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    danger: true;
    primaryAlt: true;
  }

  interface ChipPropsVariantOverrides {
    rounded: true;
    roundedAlt: true;
    light: true;
    lightRounded: true;
  }

  interface ChipPropsSizeOverrides {
    'x-small': true;
  }
}

const MuiChip: Components['MuiChip'] = {
  defaultProps: {
    deleteIcon: <CrossSmall />,
    color: 'secondary',
    size: 'small',
  },
  styleOverrides: {
    root: {
      fontWeight: 400,
      borderRadius: 4,
      fontSize: 14,
      lineHeight: '18px',
    },
    deleteIcon: {
      fontSize: 16,
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
        opacity: 0.6,
      },
    },
    label: {
      overflow: 'visible',
      paddingRight: 8,
      paddingLeft: 8,
    },
    labelSmall: {
      lineHeight: '16px',
      fontSize: 12,
    },
    icon: {
      marginLeft: 8,
      marginRight: -4,
      fontSize: 12,
    },
    iconSmall: {},
    sizeSmall: {
      height: 20,
    },
    sizeMedium: {
      height: 24,
    },
  },
  variants: [
    {
      props: { size: 'x-small' },
      style: {
        height: 16,
        fontSize: 12,
        lineHeight: '16px',
        '& .MuiChip-label': {
          paddingLeft: 6,
          paddingRight: 6,
        },
      },
    },
    {
      props: { variant: 'roundedAlt', size: 'small' },
      style: {
        border: `1px solid ${color.grey[10]}`,
        borderRadius: 10,
        padding: 0,
        backgroundColor: color.blue[5],
        '& .MuiChip-deleteIcon': {
          color: color.grey[60],
          '&:hover': {
            color: color.grey[90],
          },
          '&:active': {
            color: color.grey[90],
          },
        },
        '& .MuiChip-icon': {
          color: 'white',
          padding: 4,
          borderRadius: '50%',
          display: 'flex',
          aliginItems: 'center',
          justifyContent: 'center',
          width: 20,
          height: 20,
          margin: 0,
        },
      },
    },
    {
      props: { color: 'default', variant: 'filled' },
      style: {
        backgroundColor: color.grey[90],
        color: '#FFF',
        '& .MuiChip-icon': {
          color: '#FFF',
        },
      },
    },
    {
      props: { color: 'default', variant: 'rounded' },
      style: {
        backgroundColor: color.grey[90],
        color: '#FFF',
        '& .MuiChip-icon': {
          color: '#FFF',
        },
      },
    },
    {
      props: { color: 'default', variant: 'outlined' },
      style: {
        borderColor: color.grey[90],
        color: color.grey[90],
        '& .MuiChip-icon': {
          color: color.grey[90],
        },
      },
    },

    {
      props: { color: 'primary', variant: 'roundedAlt' },
      style: {
        color: color.blue[50],
        '& .MuiChip-icon': {
          backgroundColor: color.blue[50],
        },
      },
    },
    {
      props: { color: 'info', variant: 'roundedAlt' },
      style: {
        color: color.blue[30],
        '& .MuiChip-icon': {
          backgroundColor: color.blue[30],
        },
      },
    },
    {
      props: { color: 'warning', variant: 'roundedAlt' },
      style: {
        color: color.yellow[90],
        '& .MuiChip-icon': {
          backgroundColor: color.yellow[70],
        },
      },
    },
    {
      props: { color: 'danger', variant: 'roundedAlt' },
      style: {
        color: color.orange[100],
        '& .MuiChip-icon': {
          backgroundColor: color.orange[50],
        },
      },
    },
    {
      props: { color: 'error', variant: 'roundedAlt' },
      style: {
        color: color.red[90],
        '& .MuiChip-icon': {
          backgroundColor: color.red[60],
        },
      },
    },
    {
      props: { color: 'default', variant: 'roundedAlt' },
      style: {
        color: color.grey[100],
        '& .MuiChip-icon': {
          backgroundColor: color.grey[100],
        },
      },
    },
    {
      props: { color: 'secondary', variant: 'filled' },
      style: {
        backgroundColor: color.grey[40],
        color: '#FFF',
        '& .MuiChip-icon': {
          color: '#FFF',
        },
      },
    },
    {
      props: { color: 'secondary', variant: 'rounded' },
      style: {
        backgroundColor: color.grey[10],
        color: color.grey[70],
        '& .MuiChip-icon': {
          color: color.grey[70],
        },
      },
    },
    {
      props: { color: 'secondary', variant: 'light' },
      style: {
        backgroundColor: color.grey[5],
        color: color.grey[5],
        '& .MuiChip-icon': {
          color: color.grey[60],
        },
      },
    },
    {
      props: { color: 'secondary', variant: 'outlined' },
      style: {
        borderColor: color.grey[40],
        color: color.grey[40],
        '& .MuiChip-icon': {
          color: color.grey[40],
        },
      },
    },

    {
      props: { variant: 'rounded' },
      style: {
        borderRadius: '16px',
      },
    },
    {
      props: { variant: 'rounded', size: 'medium' },
      style: {
        padding: '0 3px 0 4px',
        '& .MuiChip-deleteIcon': {
          margin: '0 5px 0 -3px',
        },
      },
    },
    {
      props: { variant: 'rounded', size: 'small' },
      style: {
        borderRadius: '16px',
      },
    },
    {
      props: { variant: 'light', color: 'primary' },
      style: {
        backgroundColor: color.blue[20],
        color: color.blue[50],
      },
    },
    {
      props: { variant: 'light', color: 'info' },
      style: {
        backgroundColor: color.blue[5],
        color: color.blue[40],
      },
    },
    {
      props: { variant: 'light', color: 'default' },
      style: {
        backgroundColor: color.grey[20],
        color: color.grey[90],
      },
    },
    {
      props: { variant: 'light', color: 'warning' },
      style: {
        backgroundColor: color.yellow[20],
        color: color.yellow[90],
      },
    },
    {
      props: { variant: 'light', color: 'danger' },
      style: {
        backgroundColor: color.orange[20],
        color: color.orange[90],
      },
    },
    {
      props: { variant: 'light', color: 'error' },
      style: {
        backgroundColor: color.red[20],
        color: color.red[90],
      },
    },
    {
      props: { variant: 'light', color: 'success' },
      style: {
        backgroundColor: color.green[20],
        color: color.green[100],
      },
    },
    {
      props: { variant: 'light', color: 'secondary' },
      style: {
        backgroundColor: color.grey[10],
        color: color.grey[70],
      },
    },
    {
      props: { variant: 'light', color: 'primaryAlt' },
      style: {
        backgroundColor: color.lightblue[20],
        color: color.lightblue[90],
      },
    },
    {
      props: { variant: 'lightRounded' },
      style: {
        borderRadius: 16,
      },
    },
    {
      props: { variant: 'lightRounded', size: 'x-small' },
      style: {
        padding: '0px !important',
      },
    },
    {
      props: { variant: 'lightRounded', color: 'primary' },
      style: {
        backgroundColor: color.blue[20],
        color: color.blue[50],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'info' },
      style: {
        backgroundColor: color.blue[5],
        color: color.blue[40],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'default' },
      style: {
        backgroundColor: color.grey[20],
        color: color.grey[90],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'warning' },
      style: {
        backgroundColor: color.yellow[20],
        color: color.yellow[90],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'danger' },
      style: {
        backgroundColor: color.orange[20],
        color: color.orange[90],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'error' },
      style: {
        backgroundColor: color.red[20],
        color: color.red[90],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'success' },
      style: {
        backgroundColor: color.green[20],
        color: color.green[100],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'secondary' },
      style: {
        backgroundColor: color.grey[10],
        color: color.grey[70],
      },
    },
    {
      props: { variant: 'lightRounded', color: 'primaryAlt' },
      style: {
        backgroundColor: color.lightblue[20],
        color: color.lightblue[90],
      },
    },
  ],
};

export default MuiChip;
