import {
  Badge,
  Box,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  Divider,
  FilledInput,
  IconButton,
  InputAdornment,
  Link,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  PopoverOrigin,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { Cross, Search } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import React, { Children, ReactNode, isValidElement, useEffect, useState } from 'react';

// Add an "onSearch" callback returning a list of values to be able to customize the search behavior

export type FilterSelectProps<T> = Omit<SelectProps<T>, 'onChange' | 'error'> & {
  searchable?: boolean;
  loading?: boolean;
  error?: string | boolean;
  onChange: (value: T) => void;
  placement?: PopoverOrigin;
  noResultText?: string;
  notDefinedOption?: { value: string; label: string };
};

const FilterSelect = <T extends string | string[]>({
  children,
  label,
  loading,
  onChange,
  searchable,
  error,
  placement = { vertical: 'bottom', horizontal: 'left' },
  noResultText = i18n.noResult,
  notDefinedOption,
  ...props
}: FilterSelectProps<T>) => {
  const [search, setSearch] = useState('');
  const [topSticky, setTopSticky] = useState(false);
  const [bottomSticky, setBottomSticky] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [notDefinedOptionSelected, setNotDefinedOptionSelected] = useState(false);

  const filteredChildren = filterChildren(children, search);

  const { vertical, horizontal } = placement;
  const transformOrigin: PopoverOrigin = {
    vertical: vertical === 'top' ? 'bottom' : vertical === 'center' ? 'center' : 'top',
    horizontal,
  };

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    if (checked) {
      const values = Children.toArray(children)
        .filter((child) => isValidElement(child))
        .map((child) => {
          if (isValidElement(child)) {
            return child.props.value;
          }
          return '';
        }) as string[];
      if (notDefinedOption) {
        values.push(notDefinedOption?.value as string);
      }
      onChange(values as unknown as T);
    } else {
      onChange([] as unknown as T);
    }
  };

  const handleSelectNotDefined = (checked: boolean) => {
    if (!notDefinedOption) {
      return;
    }
    if (checked) {
      onChange([...(props.value as T), notDefinedOption?.value] as unknown as T);
    } else {
      onChange([...(props.value as T)].filter((value) => value !== notDefinedOption.value) as unknown as T);
    }
    setNotDefinedOptionSelected(checked);
  };

  useEffect(() => {
    const isEverythingChecked = () => {
      let everyValueSelected = Children.toArray(children)
        .filter((child) => isValidElement(child))
        .every((child) => isValidElement(child) && props.value && props.value.includes(child.props.value));
      if (notDefinedOption && props.value) {
        everyValueSelected = everyValueSelected && (props.value as string[]).includes(notDefinedOption.value);
      }

      return everyValueSelected;
    };

    const isNotDefinedOptionSelected = () => {
      if (notDefinedOption && props.value) {
        return (props.value as string[]).includes(notDefinedOption.value);
      }
      return false;
    };

    setSelectAll(isEverythingChecked());
    setNotDefinedOptionSelected(isNotDefinedOptionSelected());
  }, [props.value, children, notDefinedOption]);

  const hasChildren = Children.toArray(filteredChildren).length > 0;

  const displaySelectAll =
    searchable &&
    props.multiple &&
    hasChildren &&
    Children.toArray(filteredChildren).every(
      (child) => isValidElement(child) && !child.props.disabled && child.props.className !== 'loading-skeleton'
    );

  return (
    <Select
      className={props.value && props.value.length > 0 ? 'Mui-focused' : ''}
      displayEmpty
      disabled={props.disabled || loading}
      renderValue={(value) => (
        <Stack direction="row" spacing={2} flex={1} my={1} alignItems="center" justifyContent="space-between">
          <Typography
            fontWeight={value?.length > 0 && props.variant !== 'filled' ? 600 : undefined}
            fontSize={props.size === 'small' ? 12 : undefined}
            color={value?.length > 0 && props.variant !== 'filled' ? palette.primary.main : undefined}
          >
            {!value || value.length <= 0 || props.multiple ? label : filterChildrenByValue(children, value)}
          </Typography>
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            value?.length > 0 &&
            props.multiple && (
              <Box width={6} padding="0 4px 0 8px" display="flex" alignItems="center">
                <Badge
                  color={props.variant === 'filled' ? 'secondary' : 'primary'}
                  badgeContent={getBadgeValue(filteredChildren, notDefinedOptionSelected)}
                />
              </Box>
            )
          )}
        </Stack>
      )}
      onChange={(event: SelectChangeEvent<T>) => onChange(event.target.value as T)}
      onAnimationEnd={() => setSearch('')}
      MenuProps={{
        anchorOrigin: placement,
        transformOrigin,
        disableAutoFocusItem: true,
        PaperProps: {
          onScroll: (event: any) => {
            const container = event.target as HTMLDivElement;
            setTopSticky(container.scrollTop > 0);
            setBottomSticky(container.offsetHeight + container.scrollTop <= container.scrollHeight);
          },
          style: { maxHeight: SELECT_MAX_HEIGHT, minWidth: 200 },
        },
      }}
      error={!!error}
      {...props}
    >
      {searchable && <SelectSearch search={search} onChange={setSearch} sticky={topSticky} />}
      {displaySelectAll && (
        <div>
          <MenuItem onClick={() => handleSelectAll(!selectAll)}>
            <Checkbox checked={selectAll} />
            <ListItemText
              primary={
                <Typography color={selectAll ? 'primary' : undefined} fontWeight={selectAll ? 600 : undefined}>
                  {i18n.selectAll}
                </Typography>
              }
            />
          </MenuItem>
          {notDefinedOption && (
            <MenuItem onClick={() => handleSelectNotDefined(!notDefinedOptionSelected)}>
              <Checkbox checked={notDefinedOptionSelected} />
              <ListItemText
                primary={
                  <Typography
                    color={notDefinedOptionSelected ? 'primary' : undefined}
                    fontWeight={notDefinedOptionSelected ? 600 : undefined}
                  >
                    {notDefinedOption?.label}
                  </Typography>
                }
              />
            </MenuItem>
          )}
          <Divider style={{ margin: '0 10px' }} />
        </div>
      )}
      {hasChildren ? filteredChildren : <MenuItem disabled>{noResultText}</MenuItem>}
      {props.multiple && props.value && props.value.length > 0 && (
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          padding="8px 12px 12px 12px"
          position="sticky"
          bgcolor="#FFF"
          borderTop={bottomSticky ? `1px solid ${color.grey[10]}` : 'none'}
          bottom={0}
          marginBottom={-2}
          zIndex={2}
        >
          <MultipleResetButton onClick={() => onChange([] as unknown as T)} />
        </Box>
      )}
    </Select>
  );
};

const getBadgeValue = (node: React.ReactNode, notDefinedOptionSelected?: boolean) => {
  let badgeValue = 0;
  if (notDefinedOptionSelected) {
    badgeValue++;
  }
  const children = Children.toArray(node);

  const keys: Record<string, { key: string; checked: boolean }[]> = {};

  children.forEach((child) => {
    if (isValidElement(child) && child.props.children && Array.isArray(child.props.children) && child.key) {
      const key = child.key.toString().substring(1);
      const checked = child.props.children.find((c: any) => 'checked' in c.props).props.checked;
      if (Object.keys(keys).some((k) => k[0] === key[0])) {
        const matchKey = Object.keys(keys).find((k) => k[0] === key[0]);
        if (matchKey) {
          keys[matchKey].push({
            key,
            checked,
          });
        }
      } else {
        keys[key] = [{ key, checked }];
      }
    }
  });

  Object.keys(keys).forEach((key) => {
    if (keys[key].some((k) => k.key.includes('child'))) {
      keys[key] = keys[key].filter((k) => k.key.includes('child'));
    }
  });

  Object.keys(keys).forEach((key) => {
    keys[key].forEach((k) => {
      if (k.checked) {
        badgeValue += 1;
      }
    });
  });

  return badgeValue;
};

const MultipleResetButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Link component="button" onClickCapture={onClick}>
      {i18n.clear}
    </Link>
  );
};

const filterChildrenByValue = (children: ReactNode, value: string | string[]) => {
  if (!value) {
    return children;
  }

  if (Array.isArray(value)) {
    return Children.toArray(children)
      .filter((child) => isValidElement(child) && value.includes(child.props.value))
      .map((child) => isValidElement(child) && child.props.children);
  }
  return Children.toArray(children)
    .filter((child) => isValidElement(child) && child.props.value === value)
    .map((child) => isValidElement(child) && child.props.children);
};
const SELECT_MAX_HEIGHT = 385 + 30;
const SelectSearch = ({
  search,
  onChange,
  sticky,
}: {
  search: string;
  onChange: (search: string) => void;
  sticky: boolean;
}) => {
  return (
    <Box
      padding={'12px 12px 8px'}
      marginTop={-2}
      position="sticky"
      top={0}
      bgcolor="#FFF"
      zIndex={2}
      boxShadow={sticky ? 1 : undefined}
      borderBottom={sticky ? `1px solid ${color.grey[10]}` : 'none'}
    >
      <FilledInput
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        placeholder={i18n.search}
        sx={{ minWidth: 234 }}
        fullWidth
        endAdornment={
          search ? (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  onChange('');
                }}
                edge="end"
              >
                <Cross />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          )
        }
        value={search}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    </Box>
  );
};

const containsSearch = (child: React.ReactNode, search: string): boolean => {
  search = search
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ');
  if (typeof child === 'string' || typeof child === 'number') {
    return child
      .toString()
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ')
      .includes(search);
  } else if (
    React.isValidElement<{ children: React.ReactElement<ListItemTextProps | CheckboxProps>[] }>(child) &&
    child.props &&
    child.props.children
  ) {
    const children = React.Children.toArray(child.props.children);
    return children
      .map((child) =>
        React.isValidElement<ListItemTextProps>(child)
          ? child.props.primary && containsSearch(child.props.primary, search)
          : typeof child === 'string'
          ? containsSearch(child, search)
          : false
      )
      .includes(true);
  }
  return false;
};

const filterChildren = (children: ReactNode, search: string) => {
  if (search) {
    const results = React.Children.toArray(children).filter((child) => {
      return containsSearch(child, search);
    });

    return results.length > 0 ? results : null;
  }

  return children;
};

export default FilterSelect;
