import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { Box, Stack, Typography } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import { formatAge, getPatientAge } from 'common/utils';
import { CardBackground, getCardColor, getInfoTextColor } from 'kurt/components/EventCard/cardColors';

type PatientCardProps = {
  name: string;
  birthdate: Date;
  gender: PatientGender;
  specificities?: AccommodationSpecificity[];
  borderLeft?: boolean;
  borderRight?: boolean;
  striped?: boolean;
};

const PatientCard = ({
  name,
  birthdate,
  gender,
  specificities = [],
  striped,
  borderLeft,
  borderRight,
}: PatientCardProps) => {
  const color = getCardColor(specificities);

  return (
    <CardBackground color={color} $borderLeft={borderLeft} $borderRight={borderRight} $striped={striped || false}>
      <Stack padding="5px 16px" direction="row" alignItems="center">
        <Box flex={1}>
          <PatientNameWithEmoji name={name} gender={gender} age={getPatientAge(birthdate)} />
        </Box>
        <Typography variant="body2" color={getInfoTextColor(color)}>
          {formatAge(birthdate)}
        </Typography>
      </Stack>
    </CardBackground>
  );
};

export default PatientCard;
