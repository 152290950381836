import { Box, Collapse, AppBar as MuiAppBar, Stack, Toolbar } from '@mui/material';
import logo from 'common/assets/images/logo.svg';
import { useAuthentication } from 'core/authentication';
import React, { useState } from 'react';
import NotificationCenter from '../NotificationCenter';
import QRCodeModal from '../qrCode/QRCodeModal';
import { useAppBarContext } from './AppBarContext';
import AppBarSelect from './AppBarSelect';
import AppBarTabs from './AppBarTabs';
import AppBarUserMenu from './AppBarUserMenu';
import Avatar from './Avatar';

const AppBar = () => {
  const { healthCenter } = useAuthentication();
  const { isFullScreen } = useAppBarContext();

  const [anchorUserMenuEl, setAnchorUserMenuEl] = useState<null | HTMLElement>(null);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);

  const userMenuOpen = Boolean(anchorUserMenuEl);

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserMenuEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorUserMenuEl(null);
  };

  const handleOpenQrCodeModal = () => {
    handleUserMenuClose();
    setQrCodeModalOpen(true);
  };

  return (
    <>
      <MuiAppBar position="sticky" sx={{ left: 0, width: '100%' }}>
        <Collapse in={!isFullScreen}>
          <Toolbar>
            <Stack direction="row" spacing={8} alignItems="center" flexGrow={1} marginRight={5} flexShrink={0}>
              <img src={logo} alt="Silbo logo" height={32} />
              <AppBarSelect />
            </Stack>
            <AppBarTabs />
            <Box sx={{ marginLeft: 5, flexShrink: 0 }}>
              <NotificationCenter />
            </Box>
            <Box
              sx={{ marginLeft: 4, width: 40, height: 'auto', cursor: 'pointer', flexShrink: 0 }}
              onClick={handleUserMenuClick}
            >
              <Avatar />
            </Box>
          </Toolbar>
        </Collapse>
      </MuiAppBar>

      <QRCodeModal onClose={() => setQrCodeModalOpen(false)} open={qrCodeModalOpen} healthCenter={healthCenter} />
      <AppBarUserMenu
        open={userMenuOpen}
        anchorEl={anchorUserMenuEl}
        onClose={handleUserMenuClose}
        onQrCodeMenuItemClick={handleOpenQrCodeModal}
      />
    </>
  );
};

export default AppBar;
