import { Box, Stack, Typography } from '@mui/material';
import { SVGProps } from 'react';

type EmptyContentProps = {
  title: string;
  subtitle: string;
  Image: React.ComponentType<SVGProps<SVGSVGElement>>;
};

const EmptyContent: React.FC<EmptyContentProps> = ({ title, subtitle, Image }) => {
  return (
    <Box
      display="flex"
      height="100%"
      flex={1}
      padding={5}
      boxSizing="border-box"
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={6} justifyContent="center" alignItems="center">
        <Image height={90} width="100%" />

        <Stack spacing={2}>
          <Typography align="center">{title}</Typography>
          <Typography align="center" variant="body2" color="secondary">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EmptyContent;
