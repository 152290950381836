import Api, {
  Cloud,
  FarePosition,
  farePositions,
  fareSpecificities,
  FareSpecificity,
  Patient,
  PatientIdentity,
} from '@ambuliz/sabri-core';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DialogHeader, FilterSelect } from 'common/components';
import { PatientSummary } from 'common/components/PatientAutocomplete';
import useParseQuery from 'common/hooks/useParseQuery';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { formatName } from 'common/utils';
import { useLocations } from 'core/locations';
import { useBedOptions } from 'core/locations/useBedOptions';
import { isValid } from 'date-fns';
import { useState } from 'react';
import { i18n as sabriI18n } from 'sabri/locales';

const initialFormValue = {
  fromUnitId: '',
  toUnitId: '',
  fromAreaId: '',
  roundTrip: true,
  isEmergency: undefined,
  toAreaId: '',
  position: undefined,
  wantedDate: new Date(),
  comment: '',
  specificities: [],
};

const CreateFare = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { units } = useLocations();
  const { results } = useParseQuery(
    new Api.Query(Patient).equalTo('patient', PatientIdentity.createWithoutData(id)).limit(1)
  );
  const patient = results?.[0];

  const [formValue, setFormValue] = useState<FareFormValue>(initialFormValue);

  const handleChange = (value: string | boolean | Date, key: keyof FareFormValue) => {
    setFormValue({ ...formValue, [key]: value });
  };

  const handleSubmit = async () => {
    if (formValue.fromUnitId && formValue.toUnitId && formValue.wantedDate && patient) {
      await Cloud.createJourney({
        fromUnitId: formValue.fromUnitId,
        fromAreaId: formValue.fromAreaId,
        patientId: patient.id,
        position: formValue.position || 'LYING_DOWN',
        comment: formValue.comment,
        isEmergency: formValue.isEmergency || false,
        roundTrip: formValue.roundTrip || false,
        steps: [
          {
            toUnitId: formValue.toUnitId,
            toAreaId: formValue.toAreaId,
            wantedDate: formValue.wantedDate || new Date(),
          },
        ],
      });
      toast.success('La mission a bien été créée');
      handleClose();
    }
  };

  const handleSpecificitiesChange = (checked: boolean, value: FareSpecificity) => {
    if (checked) {
      const copy = [...formValue.specificities];
      copy.push(value);
      setFormValue({ ...formValue, specificities: copy });
    } else {
      setFormValue({
        ...formValue,
        specificities: [...formValue.specificities].filter((specificity) => specificity !== value),
      });
    }
  };

  const handleChangeEmergency = (checked: boolean) => {
    if (checked) {
      setFormValue({ ...formValue, wantedDate: new Date(), isEmergency: true });
    } else {
      setFormValue({ ...formValue, isEmergency: false });
    }
  };

  const handleClose = () => {
    setFormValue(initialFormValue);
    onClose();
  };

  const { bedOptions: originBedOptions, isLoading: areOriginBedsLoading } = useBedOptions(formValue?.fromUnitId);
  const { bedOptions: destinationBedOptions, isLoading: areDestinationBedsLoading } = useBedOptions(
    formValue?.toUnitId
  );

  return (
    <Dialog open={!!id} onClose={handleClose} scroll="body" maxWidth="md">
      <DialogHeader title="Nouvelle mission" onClose={handleClose} />
      <DialogContent>
        <Stack spacing={6}>
          {patient && (
            <PatientSummary
              name={formatName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName)}
              birthdate={patient.birthdate}
              gender={patient.gender}
              ipp={patient.ipp}
              pan={patient.pan}
              ins={patient.ins}
              primary
            />
          )}
          <Stack spacing={4} direction="row" alignItems="center">
            <Typography variant="h4" minWidth={70}>
              Départ
            </Typography>
            <Stack direction="row" justifyContent="space-between" spacing={6}>
              <FilterSelect
                searchable
                label={i18n.unit}
                value={formValue?.fromUnitId}
                onChange={(value) => handleChange(value, 'fromUnitId')}
              >
                {units.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </FilterSelect>
              <FilterSelect
                searchable
                label={i18n.bed}
                value={formValue?.fromAreaId}
                onChange={(value) => handleChange(value, 'fromAreaId')}
                loading={areOriginBedsLoading}
                disabled={!formValue?.fromUnitId}
              >
                {originBedOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </FilterSelect>
            </Stack>
          </Stack>
          <Stack spacing={4} direction="row" alignItems="center">
            <Typography variant="h4" minWidth={70}>
              Arrivée
            </Typography>
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between" spacing={6}>
                <FilterSelect
                  searchable
                  label={i18n.unit}
                  value={formValue?.toUnitId}
                  onChange={(value) => handleChange(value, 'toUnitId')}
                >
                  {units.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </FilterSelect>
                <FilterSelect
                  searchable
                  label={i18n.bed}
                  value={formValue?.toAreaId}
                  onChange={(value) => handleChange(value, 'toAreaId')}
                  loading={areDestinationBedsLoading}
                  disabled={!formValue?.toUnitId}
                >
                  {destinationBedOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FilterSelect>
              </Stack>
              <FormControlLabel
                label="Aller/retour"
                control={
                  <Checkbox
                    checked={formValue.roundTrip}
                    onChange={(_, checked) => handleChange(checked, 'roundTrip')}
                  />
                }
              />
            </Stack>
          </Stack>

          <Stack spacing={4} direction="row" alignItems="center">
            <Typography variant="h4" minWidth={70}>
              Quand ?
            </Typography>
            <Stack spacing={2} direction="row" alignItems="center">
              <DateTimePicker
                value={formValue.wantedDate}
                onChange={(date) => {
                  if (date && isValid(date)) {
                    handleChange(date, 'wantedDate');
                  }
                }}
                disabled={formValue.isEmergency}
              />
              <FormControlLabel
                label="Urgent"
                control={
                  <Checkbox checked={formValue.isEmergency} onChange={(_, checked) => handleChangeEmergency(checked)} />
                }
              />
            </Stack>
          </Stack>
          <Stack spacing={4} direction="row">
            <Typography variant="h4" minWidth={70}>
              Infos
            </Typography>
            <Stack spacing={2} flex={1}>
              <FilterSelect
                value={formValue.position}
                onChange={(position) => handleChange(position, 'position')}
                label="Mode de transport"
                sx={{ maxWidth: 280 }}
              >
                {farePositions.map((position) => (
                  <MenuItem key={position} value={position}>
                    {sabriI18n.farePosition[position]}
                  </MenuItem>
                ))}
              </FilterSelect>
              <Grid container>
                <Grid container spacing={1}>
                  {fareSpecificities.map((specificity) => (
                    <Grid item key={specificity}>
                      <FormControlLabel
                        label={sabriI18n.fareSpecificity[specificity]}
                        control={
                          <Checkbox
                            checked={formValue.specificities.includes(specificity)}
                            onChange={(_, checked) => handleSpecificitiesChange(checked, specificity)}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Box flex={1}>
                <TextField
                  multiline
                  minRows={4}
                  value={formValue?.comment}
                  label={i18n.comment}
                  onChange={(event) => handleChange(event.target.value, 'comment')}
                  fullWidth
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Annuler
        </Button>
        <Button onClick={handleSubmit}>Commander le transport</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFare;

type FareFormValue = {
  fromUnitId?: string;
  toUnitId?: string;
  fromAreaId?: string;
  toAreaId?: string;
  roundTrip?: boolean;
  isEmergency?: boolean;
  position?: FarePosition;
  wantedDate?: Date;
  comment?: string;
  specificities: FareSpecificity[];
};
