export type DateFilters =
  | 'FROM_THE_BEGINNING'
  | 'TODAY'
  | 'YESTERDAY'
  | 'THIS_WEEK'
  | 'LAST_WEEK'
  | 'LAST_THIRTY_DAYS'
  | 'LAST_NINETY_DAYS'
  | 'LAST_SIX_MONTHS'
  | 'LAST_TWELVE_MONTHS'
  | 'THIS_YEAR'
  | 'CUSTOM';

export const dateFilters: DateFilters[] = [
  'FROM_THE_BEGINNING',
  'TODAY',
  'YESTERDAY',
  'THIS_WEEK',
  'LAST_WEEK',
  'LAST_THIRTY_DAYS',
  'LAST_NINETY_DAYS',
  'LAST_SIX_MONTHS',
  'LAST_TWELVE_MONTHS',
  'THIS_YEAR',
  'CUSTOM',
];
