import { anomalyType, AnomalyType } from '@ambuliz/sabri-core';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { FilterSelect } from 'common/components/Form';
import { i18n } from 'common/locale';

type AnomalyTypesSelectProps = {
  value: AnomalyType;
  onChange: (value: AnomalyType) => void;
  loading?: boolean;
};

const AnomalyTypesSelect: React.FC<AnomalyTypesSelectProps> = ({ value, onChange, loading }) => {
  return (
    <FilterSelect value={value} onChange={onChange} label="Type" loading={loading}>
      {anomalyType.map((name) => (
        <MenuItem key={name} value={name}>
          <ListItemText primary={i18n.anomalyTypes[name]} />
        </MenuItem>
      ))}
    </FilterSelect>
  );
};

export default AnomalyTypesSelect;
