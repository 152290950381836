import { FormControlLabel, Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { color } from 'common/theme';
import React from 'react';
import { FilterItem } from '../FiltersController/FiltersController';
import { SelectorController } from '../SelectorController/SelectorController';

type SelectorRadioProps = {
  title: string;
  items: FilterItem[];
  onSubmit: (items: FilterItem[]) => void;
  onClose: () => void;
};

export const SelectorRadio: React.FC<SelectorRadioProps> = ({ title, items, onSubmit, onClose }) => {
  const classes = useClasses();

  return (
    <SelectorController title={title} onClose={onClose}>
      {({ itemsSelected, setItemsSelected, setIsSelectorVisible }) =>
        items.map((item) => (
          <FormControlLabel
            className={classes.item}
            key={item.id}
            control={
              <Radio
                color="primary"
                checked={itemsSelected.findIndex((itemSelected) => itemSelected.id === item.id) > -1}
                value={item}
                onClick={() => {
                  setIsSelectorVisible(false);
                  setItemsSelected([item]);
                  onSubmit([item]);
                }}
              />
            }
            label={item.label}
          />
        ))
      }
    </SelectorController>
  );
};

const useClasses = makeStyles({
  item: {
    paddingLeft: 8,
    paddingRight: 15,
    marginRight: 0,
    marginLeft: 0,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: color.grey[5],
    },
  },
});
