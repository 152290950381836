import { HospitalBedroom } from 'common/components/Icons';
import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { getThresholdedColor } from 'kurt/const';

type GlobalOccupancyProps = {
  occupancyRate?: number;
  totalOfOccupatedBeds?: number;
  totalOfBeds?: number;
  totalOfAvailableBeds?: number;
  xs?: number | boolean | 'auto';
  sm?: number | boolean | 'auto';
  md?: number | boolean | 'auto';
  lg?: number | boolean | 'auto';
  xl?: number | boolean | 'auto';
};

const GlobalOccupancy = ({
  occupancyRate = 0,
  totalOfAvailableBeds = 0,
  totalOfBeds = 0,
  totalOfOccupatedBeds = 0,
  lg,
  md,
  sm,
  xl,
  xs,
}: GlobalOccupancyProps) => {
  const occupancyColor = getThresholdedColor(occupancyRate);
  return (
    <Statistics.Card md={md} sm={sm} xs={xs} lg={lg} xl={xl} header={{ title: i18n.bedsTotal }}>
      <Statistics.Item
        title={{ label: i18n.occupancyRate, color: 'default', variant: 'light' }}
        progress={{
          icon: HospitalBedroom,
          color: occupancyColor,
          value: occupancyRate || 0,
        }}
      >
        <Statistics.ItemContent>
          <Statistics.Text color={occupancyColor} sx={{ mr: 2 }} variant="lg">
            {occupancyRate}%
          </Statistics.Text>
          <Statistics.Text variant="lg">
            {totalOfOccupatedBeds}
            <Statistics.Text variant="sm" span>
              {` /${totalOfBeds}`}
            </Statistics.Text>
          </Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
      <Statistics.Item title={{ label: i18n.bedsAvailable, color: 'default', variant: 'light' }}>
        <Statistics.ItemContent>
          <Statistics.Text variant="lg">{totalOfAvailableBeds}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
    </Statistics.Card>
  );
};

export default GlobalOccupancy;
