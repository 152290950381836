import { Area, HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

type UseHealthcareEnvironmentalCleaningsProps = {
  locationId?: string | string[];
};

const useHealthcareEnvironmentalCleanings = ({ locationId }: UseHealthcareEnvironmentalCleaningsProps) => {
  const enabled = !!locationId && locationId.length > 0;
  const query = enabled ? new Parse.Query(HealthcareEnvironmentalCleaning).include('location') : null;

  if (locationId) {
    if (Array.isArray(locationId)) {
      query?.containedIn('location', locationId);
    } else {
      query?.equalTo('location', Area.createWithoutData(locationId));
    }
  }

  const { results, isLoading, isEnabled } = useParseQuery(query, {
    enabled,
  });

  return {
    healthcareEnvironmentalCleanings: results,
    isLoading,
    isEnabled,
  };
};

export default useHealthcareEnvironmentalCleanings;
