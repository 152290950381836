import Api from '@ambuliz/sabri-core';
import { initLiveQueryClient } from 'core/live-query-client';

export const ENVIRONMENT = {
  APPLICATION_ENV: '',
  PARSE_SERVER_URL: '',
  PARSE_SERVER_APP_ID: '',
  MARCUS_EMBED_WEB_URL: '',
  AMPLITUDE_API_KEY: '',
  HTTP_PROXY: '',
};

export const loadConfiguration = async () => {
  const response = await fetch('/config.json');
  const configAsJson = await response.json();

  ENVIRONMENT.PARSE_SERVER_URL = configAsJson.PARSE_SERVER_URL || '';
  ENVIRONMENT.PARSE_SERVER_APP_ID = configAsJson.PARSE_SERVER_APP_ID || '';
  ENVIRONMENT.APPLICATION_ENV = configAsJson.APPLICATION_ENV || '';
  ENVIRONMENT.MARCUS_EMBED_WEB_URL = configAsJson.MARCUS_EMBED_WEB_URL || '';
  ENVIRONMENT.AMPLITUDE_API_KEY = configAsJson.AMPLITUDE_API_KEY || '';
  ENVIRONMENT.HTTP_PROXY = getProxyUrl(ENVIRONMENT.APPLICATION_ENV);

  globalThis.Parse = Api;
  global.Parse = Api;

  Api.initialize(ENVIRONMENT.PARSE_SERVER_APP_ID);
  Api.enableLocalDatastore();
  Api.serverURL = ENVIRONMENT.PARSE_SERVER_URL;

  initLiveQueryClient(ENVIRONMENT.PARSE_SERVER_URL, ENVIRONMENT.PARSE_SERVER_APP_ID);
};

const getProxyUrl = (environnement: string) => {
  if (environnement === 'production') {
    return 'https://proxy.ambuliz.com';
  }
  if (environnement === 'stage') {
    return 'https://stage.proxy.ambuliz.com';
  }
  return '';
};
