import { i18n } from 'sabri/locales';

const getToastableErrorMessage = (message: string) => {
  if (message) {
    try {
      return JSON.parse(message).message;
    } catch (error) {
      return message;
    }
  }
  return i18n.globalError;
};

export default getToastableErrorMessage;
