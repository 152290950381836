import { fork } from 'redux-saga/effects';
import { areaRootSaga } from './area';
import { entityRootSaga } from './entity';
import { fareRootSaga } from './fare';
import { formRootSaga } from './form';
import { generalRootSaga } from './general';
import { healthCenterRootSaga } from './healthCenter';
import { patientRootSaga } from './patient';
import { porterRootSaga } from './porter';

export function* rootSaga() {
  yield fork(generalRootSaga);
  yield fork(entityRootSaga);
  yield fork(formRootSaga);
  yield fork(fareRootSaga);
  yield fork(healthCenterRootSaga);
  yield fork(patientRootSaga);
  yield fork(areaRootSaga);
  yield fork(porterRootSaga);
}
