import { Box, ClickAwayListener, TableCell, TextField, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { EDITABLE_TEXT_CELL_MIN_WIDTH } from 'kurt/const';
import { FocusEvent, MouseEvent, useState } from 'react';

type EditableTableCellProps = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  loading?: boolean;
  isReadOnly?: boolean;
};

const EditableTableCell = ({ value, onChange, onSubmit, loading, isReadOnly }: EditableTableCellProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (
    event: globalThis.MouseEvent | TouchEvent | FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isEditing) {
      event.stopPropagation();
      setIsEditing(false);
      onSubmit(value);
    }
  };

  const handleEdit = (event: MouseEvent) => {
    event.stopPropagation();
    setIsEditing(true);
  };

  if (isEditing || loading) {
    return (
      <ClickAwayListener onClickAway={handleSubmit}>
        <TableCell onClick={(event) => event.stopPropagation()}>
          <TextField
            disabled={loading}
            multiline
            value={value}
            onChange={(event) => onChange(event.target.value)}
            onBlur={handleSubmit}
            maxRows={3}
            autoFocus
            onFocus={(e) => {
              e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
              e.currentTarget.scrollTo(0, e.currentTarget.scrollHeight);
            }}
            inputProps={{
              style: {
                display: 'flex',
                fontSize: 12,
                lineHeight: '16px',
                padding: '9px 8px 9px 9px',
                background: palette.primary.light,
                borderRadius: 8,
              },
            }}
            sx={{
              width: '100%',
              margin: '-4px -8px',
            }}
          />
        </TableCell>
      </ClickAwayListener>
    );
  }

  return (
    <TableCell>
      {isReadOnly ? (
        !!value ? (
          <EllipsisTypography variant="body2" color="secondary" maxLines={3} width={EDITABLE_TEXT_CELL_MIN_WIDTH}>
            {value}
          </EllipsisTypography>
        ) : (
          <Typography variant="body2" color="secondary.medium">
            {i18n.empty}
          </Typography>
        )
      ) : (
        <Box
          sx={{
            width: '100%',
            borderRadius: 1,
            margin: '-4px -8px',
            padding: 2,
            border: loading ? '1px solid #BDBDBD' : '1px solid transparent',
            '&:hover': {
              background: palette.primary.light,
              border: `1px solid ${color.grey[10]}`,
            },
          }}
          onClick={handleEdit}
        >
          {value ? (
            <EllipsisTypography variant="body2" maxLines={3}>
              {value}
            </EllipsisTypography>
          ) : (
            <Typography variant="body2" color={color.grey[30]}>
              {i18n.empty}
            </Typography>
          )}
        </Box>
      )}
    </TableCell>
  );
};

export default EditableTableCell;
