import { InteropMessageStatus } from '@ambuliz/sabri-core';
import { Chip, ChipTypeMap } from '@mui/material';

type InteropMessageStatusChipProps = {
  status: InteropMessageStatus;
};

const InteropMessageStatusChipColor: Record<InteropMessageStatus, ChipTypeMap['props']['color']> = {
  pending: 'info',
  skipped: 'secondary',
  processed: 'success',
  failed: 'error',
  'non-applicable': 'danger',
};

export const InteropMessageStatusChip = ({ status }: InteropMessageStatusChipProps) => {
  return <Chip label={status} color={InteropMessageStatusChipColor[status]} />;
};
