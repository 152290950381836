import { createSelector } from '@reduxjs/toolkit';
import { formatFirstname } from 'common/utils';
import { EntityNames } from '../../const/schemas';
import { i18n } from '../../locales';
import { AppState } from '../store';
import { selectEntitiesValues } from './entity';

const selectPatients = (state: AppState) => state.entity.patients;
const selectPatient = (id: string) => createSelector([selectPatients], (patients) => patients[id]);

export const selectPatientsSortedByName = createSelector(selectEntitiesValues(EntityNames.patients), (patients) =>
  patients
    .filter((patient) => patient.firstName && patient.lastName)
    .sort((a, b) => -`${b.firstName} ${b.lastName}`.localeCompare(`${a.firstName} ${b.lastName}`))
);

export const selectPatientDisplayName = (id: string) =>
  createSelector([selectPatient(id)], (patient) =>
    patient
      ? `${i18n.patientGender[patient.gender] || ''}${patient.lastName ? ` ${patient.lastName.toUpperCase()}` : ''} ${
          patient.firstName ? ` ${formatFirstname(patient.firstName)}` : ''
        }`
      : ''
  );

export const selectPatientsIds = createSelector([selectPatients], (patients) => Object.keys(patients));
