import { AccommodationSpecificity, Area, Cloud, PatientGender } from '@ambuliz/sabri-core';
import { useEffect, useState } from 'react';

export type Bed = Area & { name: string; nextStart?: Date; nextEnd?: Date };

const useStepScheduling = (unitId: string, gender: PatientGender, start?: Date, end?: Date, specificities?: AccommodationSpecificity[], age?: number) => {
  const [loading, setLoading] = useState(false);
  const [solutions, setSolutions] = useState<Bed[]>([]);

  useEffect(() => {
    const getSolutions = async (start: Date, end?: Date) => {
      setLoading(true);
      try {
        const optimizedBedOptions = await Cloud.stepScheduling({
          unitId,
          startAt: start,
          endAt: end,
          gender,
          age: age,
          specificities: specificities,
        });

        setSolutions(optimizedBedOptions.beds);
      } catch (error) {
        setSolutions([]);
      } finally {
        setLoading(false);
      }
    };
    if (start && unitId) {
      getSolutions(start, end);
    }
  }, [start, end, unitId, gender, specificities, age]);

  return { solutions, loading };
};

export default useStepScheduling;
