import { ExternalPatientTransport } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import {
  TimelineRowEmoji,
  TimelineRowEmojiProps,
  TimelineRowItem,
  TimelineRowItemProps,
} from 'common/components/Timeline';
import { i18n } from 'common/locale';
import { useRef } from 'react';

type ExternalTransportCardEventProps = {
  externalTransport: ExternalPatientTransport;
  start: Date;
  end: Date;
  emoji: TimelineRowEmojiProps;
} & TimelineRowItemProps;

export const ExternalTransportCardEvent = ({
  externalTransport,
  start,
  end,
  emoji,
  ...props
}: ExternalTransportCardEventProps) => {
  const cardContainer = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <TimelineRowItem
      start={start}
      end={end}
      ref={cardContainer}
      cardContainerRef={cardContainer}
      contentRef={contentRef}
      {...props}
    >
      <Stack
        ref={contentRef}
        spacing={3}
        direction="row"
        alignItems="center"
        flexWrap="nowrap"
        paddingX="3px"
        position="relative"
      >
        <TimelineRowEmoji {...emoji} />
        <Typography variant="h6" noWrap>
          {externalTransport.reason || i18n.externalTransports}
        </Typography>
      </Stack>
    </TimelineRowItem>
  );
};
