import { LoadingButton } from '@mui/lab';
import { Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DateTimeInput } from 'common/components/Form';
import { i18n } from 'common/locale';
import { isAfter, isValid } from 'date-fns';
import { useState } from 'react';
import { ActionProps } from '../ActionForm';

type UpdateDatesProps = VisitDatesFormType & ActionProps;

const UpdateDates: React.FC<UpdateDatesProps> = ({
  admissionDate,
  dischargeDate,
  isDischargeDateValidated,
  loading = false,
  onCancel,
  onSubmit,
}) => {
  const [accommodation, setAccommodation] = useState<VisitDatesFormType>({
    admissionDate,
    dischargeDate,
    isDischargeDateValidated,
  });
  const [errors, setErrors] = useState<VisitDatesErrors>(initialErros);

  const handleDateChange = (field: 'admissionDate' | 'dischargeDate', value: Date | null) => {
    setAccommodation({ ...accommodation, [field]: value });
    setErrors({ ...errors, [field]: false });
  };

  const handleChange = (value: boolean) => {
    setAccommodation({ ...accommodation, isDischargeDateValidated: value });
  };

  const handleSubmit = async () => {
    const errors = validate(accommodation);
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      setErrors(errors);
    }
    if (!hasErrors) {
      onSubmit({
        startAt: accommodation.admissionDate,
        endAt: accommodation.dischargeDate,
        isEndEstimated: !accommodation.isDischargeDateValidated,
      });
    }
  };

  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h4">{i18n.visitActions.UPDATE_DATES.form.question}</Typography>
        <Stack spacing={2}>
          <Typography color="secondary">{i18n.addAccommodationSteps('PATIENT_STAY').dates.startAt}</Typography>
          <Stack spacing={1}>
            <DateTimeInput
              onChange={(value) => handleDateChange('admissionDate', value)}
              value={accommodation.admissionDate}
              maxDate={accommodation.dischargeDate || undefined}
              error={errors?.admissionDate}
              disabled={loading}
            />
            {errors.admissionDate && (
              <Typography variant="body2" color="error">
                {i18n.addAccommodationModal.errors.start}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={1} direction="row">
            <Typography color="secondary">{i18n.addAccommodationSteps('PATIENT_STAY').dates.endAt}</Typography>
            <Select
              variant="standard"
              onChange={(event) => handleChange(event.target.value === 'true')}
              value={accommodation.isDischargeDateValidated ? 'true' : 'false'}
            >
              <MenuItem value="true">{i18n.dateStatuses.VALIDATED}</MenuItem>
              <MenuItem value="false">{i18n.dateStatuses.ESTIMATED}</MenuItem>
            </Select>
          </Stack>
          <Stack spacing={1}>
            <DateTimeInput
              onChange={(value) => handleDateChange('dischargeDate', value)}
              value={accommodation.dischargeDate}
              minDate={accommodation.admissionDate || undefined}
              error={errors?.dischargeDate}
              disabled={loading}
            />
            {errors.dischargeDate && (
              <Typography variant="body2" color="error">
                {i18n.addAccommodationModal.errors.end}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton onClick={handleSubmit} loading={loading} disabled={loading}>
          {i18n.visitActions.UPDATE_DATES.form.confirmLabel}
        </LoadingButton>
      </Stack>
    </>
  );
};

export default UpdateDates;

type VisitDatesFormType = {
  admissionDate?: Date | null;
  dischargeDate?: Date | null;
  isDischargeDateValidated?: boolean;
};

type VisitDatesErrors = {
  admissionDate: boolean;
  dischargeDate: boolean;
};

const initialErros: VisitDatesErrors = {
  admissionDate: false,
  dischargeDate: false,
};

const validate = (accommodation: VisitDatesFormType) => ({
  admissionDate: !accommodation.admissionDate || !isValid(accommodation.admissionDate),
  dischargeDate:
    !!accommodation.dischargeDate &&
    !!accommodation.admissionDate &&
    !isAfter(accommodation.dischargeDate, accommodation.admissionDate),
});
