import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({ palette }) => ({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  addButtonText: {
    color: palette.common.white,
    fontSize: 15,
    fontWeight: 500,
  },
  addIcon: {
    color: palette.common.white,
    width: 24,
    height: 24,
  },
}));
