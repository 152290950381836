import { Box, Grow, IconButton, Skeleton, Typography } from '@mui/material';
import { color, transition } from 'common/theme';
import shadows from 'common/theme/shadows';
import { format, isSameDay, isSameMonth } from 'date-fns';
import { fr } from 'date-fns/locale';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowLeft, ArrowRight } from '../Icons';
import { getPosition, useTimeMarker } from './TimeMarker';
import TimelineContext, { Period } from './TimelineContext';
import { TIMELINE_ROW_TITLE_MARGIN_RIGHT } from './TimelineRow';

export const HEADER_HEIGHT = 40;
const HEADER_BORDER_WIDTH = 1;

type TimelineHeaderProps = {
  title: string;
  top: number;
  slots: Date[];
  focusDate?: Date;
  loading?: boolean;
};

const TimelineHeader = ({ title, top, slots, focusDate, loading = false }: TimelineHeaderProps) => {
  const { container: refContainer, period, scale, start, rowHeaderWidth } = useContext(TimelineContext);
  const container = refContainer.current || document.body;
  const { position: markerPosition } = useTimeMarker();
  const initPosition = useMemo(
    () => (focusDate ? getPosition({ date: focusDate, period, scale, start }) : markerPosition),
    [focusDate, period, scale, start, markerPosition]
  );

  const [displayLeftButton, setDisplayLeftButton] = useState(shouldDisplayLeftButton(container, initPosition));
  const [displayRightButton, setDisplayRightButton] = useState(
    shouldDisplayRightButton(container, initPosition, rowHeaderWidth)
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState(0);

  const handleScrollToCurrent = useCallback(
    (behavior: ScrollOptions['behavior']) => {
      container.scrollTo({
        left: initPosition + TIMELINE_ROW_TITLE_MARGIN_RIGHT - container.getBoundingClientRect().width / 4,
        behavior,
      });
    },
    [container, initPosition]
  );

  useEffect(() => {
    setDisplayLeftButton(shouldDisplayLeftButton(container, initPosition));
    setDisplayRightButton(shouldDisplayRightButton(container, initPosition, rowHeaderWidth));

    const handleScroll = () => {
      setDisplayLeftButton(shouldDisplayLeftButton(container, initPosition));
      setDisplayRightButton(shouldDisplayRightButton(container, initPosition, rowHeaderWidth));

      if (headerRef.current && titleRef.current && !!container.scrollTop) {
        if (top >= titleRef.current.getBoundingClientRect().top) {
          titleRef.current.style.borderBottom = `${HEADER_BORDER_WIDTH}px solid ${color.grey[10]}`;
          headerRef.current.style.borderBottom = `${HEADER_BORDER_WIDTH}px solid ${color.grey[10]}`;
          headerRef.current.style.boxShadow = shadows[1];
        } else {
          titleRef.current.style.borderBottom = `${HEADER_BORDER_WIDTH}px solid transparent`;
          headerRef.current.style.borderBottom = `${HEADER_BORDER_WIDTH}px solid transparent`;
          headerRef.current.style.boxShadow = 'none';
        }
      }
    };

    container.addEventListener('scroll', handleScroll);

    setContainerWidth(container.clientWidth);
    const handleResize = () => {
      setContainerWidth(container.clientWidth);
    };

    document.addEventListener('resize', handleResize);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      document.removeEventListener('resize', handleResize);
    };
  }, [initPosition, container, top, rowHeaderWidth]);

  const slotsElements = useMemo(() => {
    const slotsElements = [];
    for (let i = 0; i < slots.length; i++) {
      const previousSlot = slots[i - 1];
      const isStartOfPeriod =
        !!previousSlot &&
        (period === 'week' ? !isSameMonth(previousSlot, slots[i]) : !isSameDay(previousSlot, slots[i]));

      slotsElements.push(
        <Slot
          date={slots[i]}
          key={slots[i].toISOString()}
          isStartOfPeriod={isStartOfPeriod}
          scale={scale}
          period={period}
          loading={loading}
        />
      );
    }
    return slotsElements;
  }, [slots, period, scale, loading]);

  return (
    <>
      <Box position="sticky" left={0} width={containerWidth} top={top} height={HEADER_HEIGHT} zIndex={6}>
        <Box
          position="sticky"
          left={0}
          display="inline-flex"
          width={rowHeaderWidth}
          height={HEADER_HEIGHT}
          bgcolor="background.default"
          paddingLeft={10}
          alignItems="center"
          ref={titleRef}
        >
          <Typography variant="overline">{title}</Typography>
        </Box>

        <Grow in={displayLeftButton}>
          <IconButton
            size="small"
            variant="outlined"
            shape="rounded"
            onClick={() => handleScrollToCurrent('smooth')}
            style={{ position: 'sticky', top: 10, left: rowHeaderWidth + 8, boxShadow: shadows[2] }}
          >
            <ArrowLeft sx={{ fontSize: 12 }} />
          </IconButton>
        </Grow>

        <Grow in={displayRightButton}>
          <IconButton
            size="small"
            variant="outlined"
            shape="rounded"
            onClick={() => handleScrollToCurrent('smooth')}
            style={{ position: 'absolute', top: 10, right: 8, boxShadow: shadows[2] }}
          >
            <ArrowRight sx={{ fontSize: 12 }} />
          </IconButton>
        </Grow>
      </Box>

      <Box
        position="sticky"
        display="inline-flex"
        top={top + HEADER_HEIGHT}
        zIndex={4}
        alignItems="center"
        sx={{ transform: `translateY(-${HEADER_HEIGHT}px)` }}
        paddingLeft={`${rowHeaderWidth + TIMELINE_ROW_TITLE_MARGIN_RIGHT}px`}
        marginBottom={`-${HEADER_HEIGHT + HEADER_BORDER_WIDTH}px`}
        paddingRight={2}
        marginRight={-2}
        bgcolor="background.default"
        ref={headerRef}
      >
        {slotsElements}
      </Box>
    </>
  );
};

const Slot = ({
  date,
  period,
  isStartOfPeriod,
  scale = 130,
  loading,
}: {
  date: Date;
  period: Period;
  isStartOfPeriod?: boolean;
  scale?: number;
  loading?: boolean;
}) => {
  const dateFormat = period === 'day' ? 'HH:mm' : 'EEE dd/MM';
  const startOfPeriodFormat = period === 'day' ? 'EEE dd/MM' : 'LLL';

  return (
    <Box
      width={scale}
      sx={{ transition: transition }}
      minWidth={scale}
      height={HEADER_HEIGHT}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="background.default"
    >
      <Typography variant="overline" color="text.primary">
        {isStartOfPeriod && !loading ? (
          <Typography component="span" variant="overline">{`${format(date, startOfPeriodFormat, {
            locale: fr,
          })} - `}</Typography>
        ) : null}
        {!loading ? format(date, dateFormat, { locale: fr }) : <Skeleton variant="rounded" width={50} height={12} />}
      </Typography>
    </Box>
  );
};

const shouldDisplayLeftButton = (container: HTMLElement, position: number) => container.scrollLeft >= position;
const shouldDisplayRightButton = (container: HTMLElement, position: number, rowHeaderWidth: number) =>
  container.scrollLeft + container.clientWidth <= position + rowHeaderWidth;

export default memo(TimelineHeader);
