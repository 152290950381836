import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalNames } from '../../const/modalNames';
import { i18n } from '../../locales';
import { modalActions } from '../../store/reducers/modal';
import { selectModalIsVisible, selectModalParams } from '../../store/selectors/modal';

export const ReloadModal: React.FC = () => {
  const dispatch = useDispatch();
  const modalName = modalNames.reload;
  const isVisible = useSelector(selectModalIsVisible(modalName));
  const params = useSelector(selectModalParams(modalName));
  if (!params) {
    return null;
  }

  const handleCloseModal = () => {
    dispatch(modalActions.hideModal(modalName));
    params.onClose();
  };

  return (
    <Dialog open={isVisible} onClose={handleCloseModal}>
      <DialogTitle>{params.title}</DialogTitle>
      <DialogContent>
        <Box fontSize={14}>{params.content}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>{i18n.cancel}</Button>
        <Button onClick={params.onConfirm} color="primary">
          {i18n.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
