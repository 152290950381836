const formatFirstname = (value?: string): string => {
  if (!value) {
    return '';
  }
  return (
    value
      .trim()
      .toLowerCase()
      // Match first letter of each word (unicode with \p{L})  separated by a space or "-"
      .replace(/(^\p{L}{1})|((\s+|-)\p{L}{1})/gu, (letter) => letter.toUpperCase())
  );
};

export default formatFirstname;
