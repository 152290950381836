import { FilterValues } from '../components/FiltersController/FiltersController';

export const createWhereRequestWithFilters = (
  filters: FilterValues[],
  filtersMongoWhere: { id: string; request: any }[]
) =>
  filters.reduce((acc, filter) => {
    const where = filtersMongoWhere.find((item) => item.id === filter.id)?.request(filter.values);
    if (where) {
      return {
        ...acc,
        ...where,
      };
    }
    return acc;
  }, {} as any);
