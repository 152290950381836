import { PerformerRequest, RequestStatus } from '@ambuliz/sabri-core';
import { ChevronRight } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Info } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { format } from 'date-fns';
import React, { useState } from 'react';
import styled from 'styled-components';
import MutationHistory from './MutationHistory';

const Card = styled(Stack)<{ statuscolor: string; rejected?: string }>`
  padding: 12px;

  background: ${({ rejected }) => (rejected === 'true' ? color.blue[5] : 'white')};
  color: ${({ rejected }) => (rejected === 'true' ? color.grey[30] : color.grey[100])};
  border: 2px solid ${({ statuscolor, rejected }) => (rejected === 'true' ? color.grey[5] : statuscolor)};
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
  z-index: 1;
`;

const requestColors: Record<RequestStatus, string> = {
  ACCEPTED: color.green[70],
  REQUESTED: color.yellow[70],
  REJECTED: color.red[70],
  CANCELLED: color.grey[70],
};

const CardTooltip: React.FC<{ label: string; date: Date; by: string }> = ({ label, date, by }) => {
  return (
    <Stack>
      <Typography variant="body2">{label}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" fontWeight={600}>
          {format(date, 'dd/MM/yyyy')}
        </Typography>
        <Typography variant="body2">{` - ${format(date, 'HH:mm')} ${i18n.performerRequestTooltip.by}`}</Typography>
      </Stack>
      <Typography variant="body2" fontWeight={600}>
        {by}
      </Typography>
    </Stack>
  );
};

const getTooltip = (request: PerformerRequest) => {
  switch (request.status) {
    case 'ACCEPTED':
    case 'REJECTED':
      if (request.fulfilledBy && request.fulfilledAt) {
        return (
          <CardTooltip
            label={i18n.performerRequestTooltip[request.status === 'ACCEPTED' ? 'accepted' : 'rejected']}
            date={request.fulfilledAt}
            by={request.fulfilledBy.username}
          />
        );
      }
      break;
    default:
      if (request.createdBy) {
        return (
          <CardTooltip
            label={i18n.performerRequestTooltip.sent}
            date={request.createdAt}
            by={request.createdBy.username}
          />
        );
      }
  }
};

const PerformerCard: React.FC<{ performerRequest: PerformerRequest }> = ({ performerRequest }) => {
  const [expanded, setExpanded] = useState(false);
  const tooltip = getTooltip(performerRequest);

  return (
    <>
      <Card
        direction="row"
        justifyContent="space-between"
        statuscolor={requestColors[performerRequest.status]}
        rejected={performerRequest.status === 'REJECTED' ? 'true' : undefined}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <div style={{ transform: expanded ? 'rotate(90deg)' : undefined, height: '16px' }}>
            <ChevronRight color={performerRequest.status === 'REJECTED' ? undefined : 'secondary'}></ChevronRight>
          </div>

          <EllipsisTypography variant="body1" fontWeight={600} disableTooltip>
            {performerRequest.performer?.name}
          </EllipsisTypography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" color={requestColors[performerRequest.status]} whiteSpace="nowrap">
            {i18n.guidanceStatus[performerRequest.status]}
          </Typography>

          {!!tooltip && (
            <Tooltip title={tooltip}>
              <Typography fontSize={12} alignItems="center" display="flex">
                <Info
                  color={
                    performerRequest.status === 'ACCEPTED'
                      ? 'success'
                      : performerRequest.status === 'REJECTED'
                      ? 'error'
                      : 'warning'
                  }
                  fontSize="inherit"
                />
              </Typography>
            </Tooltip>
          )}
        </Stack>
      </Card>
      <MutationHistory open={expanded} performerRequest={performerRequest} />
    </>
  );
};

export default PerformerCard;
