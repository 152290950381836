import { Components } from '@mui/material';
import color from '../color';

const MuiSkeleton: Components['MuiSkeleton'] = {
  defaultProps: {
    animation: 'wave',
  },
  styleOverrides: {
    root: {
      backgroundColor: color.grey[10],
      border: 'none',
    },
  },
  variants: [
    {
      props: { variant: 'rounded' },
      style: {
        borderRadius: 4,
      },
    },
  ],
};

export default MuiSkeleton;
