import { Components } from '@mui/material';

const MuiToolbar: Components['MuiToolbar'] = {
  styleOverrides: {
    root: {
      '@media (max-width: 600px)': {
        minHeight: 64,
        paddingLeft: 24,
        paddingRight: 24,
      },
      '@media (min-width: 600px)': {
        minHeight: 80,
        paddingLeft: 40,
        paddingRight: 40,
      },
    },
  },
};

export default MuiToolbar;
