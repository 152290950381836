import { Paper, Stack } from '@mui/material';
import { ReactNode } from 'react';

type CardSummaryProps = {
  children?: ReactNode;
};

const CardSummary = ({ children }: CardSummaryProps) => {
  return (
    <Paper variant="outlined" sx={{ bgcolor: 'background.default', minHeight: 78 }}>
      <Stack spacing={1} padding={2}>
        {children}
      </Stack>
    </Paper>
  );
};

export default CardSummary;
