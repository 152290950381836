import MultipleSelect, { SelectOption } from 'common/components/Form/Inputs/FilterSelect/MultipleSelect';
import { i18n } from 'common/locale';
import { MovementStatus } from '../MovementsList/MovementRow';
import { FlowType } from '../useMovementsPageSearchParams';

type MovementStatusSelectProps = {
  value: MovementStatus[];
  onChange: (values: MovementStatus[]) => void;
  flow: FlowType;
};

const MovementStatusSelect = ({ value, onChange, flow }: MovementStatusSelectProps) => {
  return (
    <MultipleSelect
      onChange={(value) => onChange(value as MovementStatus[])}
      options={getOptions(flow)}
      values={value}
      label={i18n.status}
    />
  );
};

const getOptions = (flow: FlowType): SelectOption<MovementStatus>[] => {
  const admissionOptions: MovementStatus[] = [
    'incoming_mutation_requested',
    'accommodation_to_assign',
    'accommodation_assigned',
    'accommodation_admitted',
  ];
  const dischargeOptions: MovementStatus[] = [
    'outgoing_mutation_requested',
    'outgoing_mutation_blocked',
    'accommodation_planned_discharge',
    'accommodation_confirmed_discharge',
    'accommodation_discharged',
  ];
  const options = flow === 'admission' ? admissionOptions : dischargeOptions;

  return options.map((status) => ({
    value: status,
    label: i18n.movementStatuses[status],
  }));
};

export default MovementStatusSelect;
