import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ENVIRONMENT } from './configuration';

export const registerConfiguration = () => {
  const environment = ENVIRONMENT.APPLICATION_ENV;
  if (['production', 'stage'].includes(environment)) {
    const proxy = ENVIRONMENT.HTTP_PROXY ? `${ENVIRONMENT.HTTP_PROXY}/datadog` : undefined;

    datadogLogs.init({
      version: process.env.REACT_APP_VERSION,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: 'silbo-web',
      proxy,
    });

    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
      site: 'datadoghq.com',
      env: environment,
      service: 'silbo-web',
      version: process.env.REACT_APP_VERSION,
      sessionSampleRate: 100,
      trackSessionAcrossSubdomains: true,
      allowedTracingUrls: [ENVIRONMENT.PARSE_SERVER_URL],
      proxy,
    });
  }
};
