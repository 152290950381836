import { Components } from '@mui/material';
import color from '../color';

const MuiPaper: Components['MuiPaper'] = {
  defaultProps: { elevation: 2 },
  styleOverrides: {
    root: {
      border: `1px solid ${color.grey[10]}`,
      transition: 'box-shadow 0.25s ease-in-out',
    },
  },
};

export default MuiPaper;
