import { Components } from '@mui/material';

const MuiCardActions: Components['MuiCardActions'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      marginLeft: 32,
    },
  },
};

export default MuiCardActions;
