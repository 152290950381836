import { Patient } from '@ambuliz/sabri-core';
import { formatFirstname } from 'common/utils';
import { i18n } from '../locales';
import { PatientStored } from '../store/reducers/entity';

export const getPatientFullName = (patient?: PatientStored | Patient) =>
  patient
    ? `${i18n.patientGender[patient.gender] || ''}${patient.lastName ? ` ${patient.lastName.toUpperCase()}` : ''} ${
        patient.firstName ? ` ${formatFirstname(patient.firstName)}` : ''
      }`
    : '';

export const getPatientAge = (birthdate?: string) =>
  birthdate ? Math.floor((new Date().getTime() - new Date(birthdate).getTime()) / (1000 * 60 * 60 * 24 * 365.25)) : '';
