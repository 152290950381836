import { Stack, Typography } from '@mui/material';
import { Desk } from 'common/components/Images';
import { i18n } from 'common/locale';
import { useReadOnly } from 'core/authentication';
import { ContentLoader, EmptyContent, PageSection, useAppBarContext } from 'core/layout';
import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useEmergencyPageFilters from '../useEmergencyPageFilters';
import PresentPatientsTable from './PresentPatientsTable';
import PresentPatientsTableRow from './PresentPatientsTableRow';
import usePresentPatients, { PresentPatientsOrderBy, PresentPatientsOrderParams } from './usePresentPatients';

const EmergencyPresentPatients = () => {
  const { isFullScreen } = useAppBarContext();
  const [order, setOrder] = useState<PresentPatientsOrderParams>({ orderBy: 'startAt', order: 'desc' });

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const { unitId } = useParams();

  const { isReadOnly } = useReadOnly();

  const { search, genders, specificities, practitioners } = useEmergencyPageFilters();
  const { presentPatients, totalCount, loading } = usePresentPatients(unitId!, {
    page,
    rowsPerPage,
    search,
    genders,
    specificities,
    practitioners,
    ...order,
  });

  const handleSort = (name: PresentPatientsOrderBy) => {
    setOrder((prevOrder) => ({
      orderBy: name,
      order: prevOrder.orderBy === name ? (prevOrder.order === 'desc' ? 'asc' : 'desc') : prevOrder.order,
    }));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <>
      <PageSection
        withBackground
        noGutter
        noMargin={isFullScreen}
        paddingTop={!isFullScreen ? 4 : 6}
        fullHeight={isFullScreen}
        lastSection
      >
        <Stack spacing={6} marginTop={4} height="100%">
          <Typography variant="h4">{i18n.presentPatients.title}</Typography>
          {loading ? (
            <ContentLoader />
          ) : presentPatients.length === 0 ? (
            <EmptyContent Image={Desk} title={i18n.presentPatients.noPatient} subtitle={i18n.nothingToSignal} />
          ) : (
            <PresentPatientsTable
              {...order}
              page={page}
              rowsPerPage={rowsPerPage}
              totalCount={totalCount}
              isReadOnly={isReadOnly}
              onSort={handleSort}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            >
              {presentPatients.map((presentPatient) => (
                <PresentPatientsTableRow
                  key={presentPatient.id}
                  presentPatient={presentPatient}
                  onClick={() => navigate(presentPatient.id)}
                  isReadOnly={isReadOnly}
                />
              ))}
            </PresentPatientsTable>
          )}
          <Outlet />
        </Stack>
      </PageSection>
    </>
  );
};

export default EmergencyPresentPatients;
