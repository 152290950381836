import CheckFilledIcon from 'common/components/Icons/CheckFilledIcon';

type IsValidIconProps = {
  isVisible: boolean;
};

const IsValidIcon = ({ isVisible }: IsValidIconProps) => {
  return <CheckFilledIcon sx={{ opacity: isVisible ? 1 : 0, fontSize: 20, color: '#00C853' }} />;
};

export default IsValidIcon;
