import { Checkbox, ListItemText, MenuItem, PopoverOrigin, SelectProps, Stack } from '@mui/material';
import { ReactNode } from 'react';
import FilterSelect from './FilterSelect';

export type SelectOption<T> = { value: T; label: string; children?: SelectOption<T>[]; Icon?: React.ReactNode };

type NestedMultipleSelectProps<T> = {
  options: SelectOption<T>[];
  values: T[];
  label: string;
  onChange: (value: T[]) => void;
  loading?: boolean;
  searchable?: boolean;
  placement?: PopoverOrigin;
  variant?: SelectProps<T>['variant'];
  size?: SelectProps<T>['size'];
  error?: boolean;
  fullWidth?: boolean;
  renderOption?: (value: T) => ReactNode;
};

const NestedMultipleSelect = <T extends string>({
  options = [],
  values,
  onChange,
  label,
  searchable,
  placement,
  variant = 'outlined',
  size = 'medium',
  error = false,
  loading = false,
  fullWidth,
  renderOption: valueRenderOption,
}: NestedMultipleSelectProps<T>) => {
  const handleChange = (newValues: T[]) => {
    for (const option of options) {
      if (option.children) {
        newValues = updateValue(
          option.value,
          option.children.map(({ value }) => value),
          values,
          newValues
        );
      }
    }
    onChange(newValues);
  };

  const renderOption = ({ value, label, children = [], Icon }: SelectOption<T>) => {
    const isAllSelected = children.every((child) => values.includes(child.value));
    const isSomeSelected = children.some((child) => values.includes(child.value)) && !isAllSelected;

    const elements = [
      <MenuItem value={value} key={value}>
        <Checkbox checked={values.includes(value)} indeterminate={isSomeSelected} />
        {Icon ? (
          <Stack direction="row" spacing={1} paddingLeft={1} alignItems="center">
            {Icon}
            {valueRenderOption ? valueRenderOption(value) : <ListItemText primary={label} />}
          </Stack>
        ) : valueRenderOption ? (
          valueRenderOption(value)
        ) : (
          <ListItemText primary={label} />
        )}
      </MenuItem>,
    ];

    children.forEach(({ value, label, Icon }) => {
      const element = (
        <MenuItem value={value} key={`child-${value}`} style={{ paddingLeft: 24 }}>
          <Checkbox checked={values.includes(value)} />
          {Icon ? (
            <Stack direction="row" spacing={1} paddingLeft={1} alignItems="center">
              {Icon}
              {valueRenderOption ? valueRenderOption(value) : <ListItemText primary={label} />}
            </Stack>
          ) : valueRenderOption ? (
            valueRenderOption(value)
          ) : (
            <ListItemText primary={label} />
          )}
        </MenuItem>
      );
      elements.push(element);
    });

    return elements;
  };

  return (
    <FilterSelect
      multiple
      value={values}
      onChange={handleChange}
      label={label}
      searchable={searchable}
      placement={placement}
      disabled={options.length === 0}
      variant={variant}
      size={size}
      error={error}
      fullWidth={fullWidth}
      loading={loading}
    >
      {options.map(renderOption)}
    </FilterSelect>
  );
};

const updateValue = <T extends string>(parent: T, children: T[], value: T[], newValue: T[]) => {
  const values = [parent, ...children];
  if (newValue.includes(parent) && !value.includes(parent)) {
    newValue = newValue.concat(values);
  } else if (
    children.every((value) => !newValue.includes(value)) ||
    (!newValue.includes(parent) && value.includes(parent))
  ) {
    newValue = newValue.filter((item) => !values.includes(item));
  }
  if (children.some((value) => newValue.includes(value))) {
    newValue.push(parent);
  }
  return Array.from(new Set(newValue));
};

export default NestedMultipleSelect;
