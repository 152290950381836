import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { getHumanDayDiffFromNow } from '../../utils/date';

type DateCellProps = {
  date: Date;
  displaySchedule?: boolean;
  diffFromNow?: boolean;
};

export const DateCell: React.FC<DateCellProps> = ({ date, displaySchedule, diffFromNow }) => {
  const schedule = format(date, 'HH:mm');
  const dayInfo = diffFromNow ? getHumanDayDiffFromNow({ date, shrink: true }) : format(date, 'dd/MM');
  return (
    <Grid container wrap="nowrap" alignItems="center" justifyContent="center" spacing={1}>
      {displaySchedule && (
        <Grid item component={Box} whiteSpace="nowrap">
          {schedule}
        </Grid>
      )}
      <Grid item component={Typography} variant="caption" color="textSecondary">
        {dayInfo}
      </Grid>
    </Grid>
  );
};
