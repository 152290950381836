"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasUserAccessToRole = exports.getAccessibleRoles = exports.ACCESSIBLE_ROLES = void 0;
exports.ACCESSIBLE_ROLES = {
    PORTER: [],
    REGULATOR: ['PORTER', 'REGULATOR', 'MANAGER'],
    MANAGER: ['PORTER', 'REGULATOR', 'MANAGER'],
    BED_MANAGER: ['PORTER', 'REGULATOR', 'MANAGER', 'BED_MANAGER'],
    ADMIN_REGULATOR: ['PORTER', 'REGULATOR', 'MANAGER', 'BED_MANAGER', 'ADMIN_REGULATOR', 'ADMIN'],
    ADMIN: ['PORTER', 'REGULATOR', 'MANAGER', 'BED_MANAGER', 'ADMIN_REGULATOR', 'ADMIN'],
};
const getAccessibleRoles = (role) => exports.ACCESSIBLE_ROLES[role];
exports.getAccessibleRoles = getAccessibleRoles;
const hasUserAccessToRole = (user, role) => (0, exports.getAccessibleRoles)(user === null || user === void 0 ? void 0 : user.role).includes(role);
exports.hasUserAccessToRole = hasUserAccessToRole;
