import { Box, Skeleton } from '@mui/material';
import { transition } from 'common/theme';
import shadows from 'common/theme/shadows';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { BORDER_COLOR, CardBackground, CardColors, LIGHT_BACKGROUND_COLOR } from './cardColors';

export type EventCardProps = {
  color?: CardColors | string;
  striped?: boolean;
  width?: number;
  borderLeft?: boolean;
  borderRight?: boolean;
  fillWidth?: number;
  fillLeft?: number;
  loading?: boolean;
  children?: React.ReactNode;
  top?: number;
  left?: number;
  size?: 'medium' | 'large';
  scrollOffset?: number;
  cardHeight?: number | null;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const EventCard = forwardRef<HTMLDivElement, EventCardProps>(
  (
    {
      color,
      width = 0,
      striped = false,
      borderLeft = false,
      borderRight = false,
      fillWidth = 0,
      fillLeft = 0,
      loading = false,
      top,
      left,
      children,
      size = 'medium',
      scrollOffset = 0,
      cardHeight: initialCardHeight,
      onClick,
      onMouseEnter,
      onMouseLeave,
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const isAbsolute = useMemo(() => top !== undefined && left !== undefined, [top, left]);
    const cardHeight = useMemo(() => {
      if (initialCardHeight === undefined) {
        return getEventCardHeight(size);
      }
      if (initialCardHeight === null) {
        return undefined;
      }
      return initialCardHeight;
    }, [size, initialCardHeight]);

    if (left === 0) {
      left = 4;
      fillLeft = 4;
      width = width - 4;
      fillWidth = fillWidth - 4;
    }

    useEffect(() => {
      if (isAbsolute && contentRef.current) {
        contentRef.current.parentElement!.style.width = `${width}px`;
      }
    }, [width, contentRef, isAbsolute]);

    const handleMouseEnter = () => {
      if (onMouseEnter) {
        onMouseEnter();
      }
      if (isAbsolute) {
        const element = contentRef.current;
        const container = containerRef.current;

        if (element) {
          if (element.scrollWidth > width) {
            const maxWidth = element.scrollWidth;
            element.parentElement!.style.width = `${maxWidth}px`;
          } else {
            element.parentElement!.style.width = `${width}px`;
          }
          element.parentElement!.style.boxShadow = shadows[3];
        }

        if (left && container && element) {
          const offset = container.getBoundingClientRect().left;
          // overflow left
          if (offset < scrollOffset) {
            const stickyContainers = container?.getElementsByClassName('sticky-container');
            if (stickyContainers) {
              const nameContainer = stickyContainers[0] as HTMLDivElement;
              element!.style.width = `${element.style.width + nameContainer?.getBoundingClientRect().width}px`;
            } else {
              container.style.transform = `translate3D(${left - scrollOffset - offset + 8}px, ${top}px,0)`;
            }
            // overflow right
          } else {
            const width = element.getBoundingClientRect().width;
            const containerParentRight = container.parentElement?.getBoundingClientRect().right || window.innerWidth;

            if (offset + width > containerParentRight) {
              container.style.transform = `translate3D(${
                left - (offset + width - containerParentRight)
              }px, ${top}px,0)`;
            }
          }
        }
      }
    };

    const handleMouseLeave = () => {
      if (onMouseLeave) {
        onMouseLeave();
      }
      if (isAbsolute) {
        if (contentRef.current) {
          contentRef.current.parentElement!.style.width = `${width}px`;
          contentRef.current.parentElement!.style.boxShadow = 'none';
        }
        if (containerRef.current) {
          containerRef.current.style.transform = `translate3D(${left}px, ${top}px, 0)`;
        }
      }
    };

    return loading ? (
      <Box
        style={{
          transform: `translate3D(${left}px,${top}px,0)`,
          transition: `transform ${transition}`,
          position: 'relative',
        }}
      >
        <Box
          position={width === undefined ? 'relative' : 'absolute'}
          borderRadius={0.5}
          overflow="hidden"
          width={width === undefined ? '100%' : `${width}px`}
        >
          <Box height={cardHeight} display="inline-flex" whiteSpace="nowrap">
            <Skeleton width={width} height={cardHeight} variant="rectangular" style={{ borderRadius: 4 }} />
          </Box>
        </Box>
      </Box>
    ) : (
      <EventCardContainer
        ref={containerRef}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={
          isAbsolute
            ? {
                transform: `translate3D(${left}px,${top}px,0)`,
                transition: `transform ${transition}`,
                position: 'relative',
              }
            : { height: '100%' }
        }
      >
        {fillWidth > 0 && (fillLeft !== left || fillWidth > width) && (
          <Box
            position="absolute"
            width={fillWidth}
            bgcolor={LIGHT_BACKGROUND_COLOR[color as CardColors] || color}
            height={cardHeight}
            borderRadius="4px"
            boxSizing="border-box"
            border={`1px dashed ${BORDER_COLOR[color as CardColors] || color}`}
            sx={{
              '&:hover': onClick ? { cursor: 'pointer' } : undefined,
              transform: `translate3D(${fillLeft - (left || 0)}px, 0, 0)`,
              transition: `transform ${transition}`,
            }}
          />
        )}

        <CardBackground
          ref={ref}
          color={color}
          width={top !== undefined && left !== undefined ? width : undefined}
          $striped={striped || false}
          $borderLeft={borderLeft}
          $borderRight={borderRight}
          height={isAbsolute ? undefined : '100%'}
          onClick={onClick}
        >
          <Box ref={contentRef} height={cardHeight} display="inline-flex" whiteSpace="nowrap" paddingY="5px">
            {children}
          </Box>
        </CardBackground>
      </EventCardContainer>
    );
  }
);

const EventCardContainer = styled.div`
  z-index: 1;
  &:hover {
    z-index: 2;
  }
`;

export const getEventCardHeight = (size: 'medium' | 'large' = 'medium') => (size === 'medium' ? 32 : 48);

export default EventCard;
