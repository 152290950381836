import { intervalToDuration } from 'date-fns';

/**
 * Returns a string representing the duration between two dates in a short format.
 * The duration is rounded to the closest unit.
 * This method is better than `formatDistanceStrict` to display '-' instead of '0 secondes' and '23 heures' instead of '24 heures'.
 *
 * @example 1h25 -> 1 heure
 * @example 1h45 -> 2 heures
 * @example 23h45 -> 23 heures
 * @example 1 jour et 8 heures -> 1 jour
 * @example 2 jours et 16 heures -> 3 jours
 * @example 2 mois et 11 jours -> 2 mois
 * @example 4 mois et 24 jours -> 5 mois
 * @param start The start date.
 * @param end The end date. Defaults to the current date.
 * @returns The duration with the number and the unit.
 */
const getShortDuration = (start: Date, end: Date = new Date()): string => {
  const partialDuration = intervalToDuration({ start, end });
  const duration = {
    years: partialDuration.years || 0,
    months: partialDuration.months || 0,
    days: partialDuration.days || 0,
    hours: partialDuration.hours || 0,
    minutes: partialDuration.minutes || 0,
    seconds: partialDuration.seconds || 0,
  };
  duration.minutes += duration.seconds > 30 ? 1 : 0;
  duration.hours += duration.minutes > 30 ? 1 : 0;
  duration.days += duration.hours > 12 ? 1 : 0;
  duration.months += duration.days > 15 ? 1 : 0;
  duration.years += duration.months > 6 ? 1 : 0;

  if (duration.years > 0) {
    return duration.years + (duration.years > 1 ? ' ans' : ' an');
  }
  if (duration.months > 0) {
    return duration.months + ' mois';
  }
  if (duration.days > 0) {
    if (duration.hours === 24) {
      return '23 heures';
    }
    return duration.days + (duration.days > 1 ? ' jours' : ' jour');
  }
  if (duration.hours > 0) {
    return duration.hours + (duration.hours > 1 ? ' heures' : ' heure');
  }
  if (duration.minutes > 0) {
    return duration.minutes + (duration.minutes > 1 ? ' minutes' : ' minute');
  }
  if (duration.seconds > 0) {
    return duration.seconds + (duration.seconds > 1 ? ' secondes' : ' seconde');
  }
  return '-';
};

export default getShortDuration;
