import { AccommodationSpecificity, AccommodationStatus, PerformerRequest, RequestStatus } from '@ambuliz/sabri-core';
import { DialogContent, Divider, Stack, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { toast } from 'common/toast';
import { useAccommodation, useAccommodationRequest } from 'kurt/hooks';
import usePerformerRequest from 'kurt/hooks/usePerfomerRequest';
import { Visit } from 'kurt/pages/Movements/useVisits';
import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AccommodationHeaderTitle from '../AccommodationHeaderTitle';
import { AccommodationAction, ActionForm, ActionsList } from '../VisitActions';
import getColumnActions from '../VisitActions/columnActions';
import getFlowTitle from '../getFlowTitle';
import VisitSummary from './VisitSummary';

type VisitDetailsDialogProps = {
  onClose: () => void;
  visit: Visit;
  flow: 'admission' | 'discharge';
  status: 'planned' | 'validated' | 'completed' | 'requested';
  menuActionName?: AccommodationAction;
  disabled?: boolean;
  onConfirm?: () => void;
};

const REDIRECT_TO_DETAILS_ACTIONS: AccommodationAction[] = [
  'UPDATE_DATES',
  'UPDATE_SPECIFICITIES',
  'UPDATE_PLANNED_DISCHARGE_DATE',
  'UPDATE_VALIDATED_DISCHARGE_DATE',
];

const toastEmojis: Record<AccommodationAction, React.ReactElement> = {
  SET_BED: <Emoji name="victoryHand" size={24} />,
  UPDATE_SPECIFICITIES: <Emoji name="okHand" size={24} />,
  UPDATE_DATES: <Emoji name="pencil" size={24} />,
  CANCEL_ADMISSION: <Emoji name="thumbsUp" size={24} />,
  CONFIRM_ADMISSION: <Emoji name="clappingHands" size={24} />,
  UPDATE_BED: <Emoji name="victoryHand" size={24} />,
  UPDATE_UNIT: <Emoji name="okHand" size={24} />,
  CANCEL_BED: <Emoji name="indexPointingUp" size={24} />,
  VALIDATE_DISCHARGE_DATE: <Emoji name="writingHand" size={24} />,
  INVALIDATE_DISCHARGE_DATE: <Emoji name="writingHand" size={24} />,
  CANCEL_DISCHARGE: <Emoji name="thumbsUp" size={24} />,
  DISCHARGE_PATIENT: <Emoji name="slightlySmilingFace" size={24} />,
  UPDATE_PLANNED_DISCHARGE_DATE: <Emoji name="nerdFace" size={24} />,
  UPDATE_VALIDATED_DISCHARGE_DATE: <Emoji name="nerdFace" size={24} />,
  ADDRESS_MUTATION_REQUEST: <Emoji name="pencil" size={24} />,
  EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST: <Emoji name="pencil" size={24} />,
  ACCEPT_INTERNAL_MUTATION_REQUEST: <Emoji name="handshake" size={24} />,
  REJECT_INTERNAL_MUTATION_REQUEST: <Emoji name="raisedHand" size={24} />,
  ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: <Emoji name="handshake" size={24} />,
  REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: <Emoji name="raisedHand" size={24} />,
  NEW_INTERNAL_MUTATION_REQUEST: <Emoji name="thumbsUp" size={24} />,
  CANCEL_MUTATION_REQUEST: <Emoji name="thumbsUp" size={24} />,
  SET_MUTATION_BED: <Emoji name="victoryHand" size={24} />,
  CANCEL_MUTATION_BED: <Emoji name="indexPointingUp" size={24} />,
  UPDATE_MUTATION_DATE: <Emoji name="pencil" size={24} />,
  REQUEST_MUTATION: <Emoji name="thumbsUp" size={24} />,
  CANCEL_ACCOMMODATION: <Emoji name="thumbsUp" size={24} />,
  REOPEN_BED: <Emoji name="thumbsUp" size={24} />,
  CANCEL_CLOSED_BED: <Emoji name="thumbsUp" size={24} />,
  CLOSE_BED: <Emoji name="thumbsUp" size={24} />,
  ADD_ACCOMMODATION: <Emoji name="thumbsUp" size={24} />,
  VIA_TRAJECTOIRE_REDIRECT: <Emoji name="thumbsUp" size={24} />,
};

const getCurrentPerformerRequestId = (visit: Visit, unitId: string, flow: 'admission' | 'discharge') => {
  const performerRequests =
    visit.basedOn?.performerRequests || visit.nextAccommodation?.basedOn?.performerRequests || [];
  const firstRequest = performerRequests.find(
    (request: PerformerRequest) => request.status === 'REQUESTED' || request.status === 'ACCEPTED'
  );
  const requestLinkedToUnit =
    flow === 'admission'
      ? performerRequests.find((request) => request.performer.id === unitId && request.status !== 'REJECTED')
      : firstRequest;

  return requestLinkedToUnit?.id;
};

const VisitDetailsDialogHeaderTitle: React.FC<{
  flow: 'admission' | 'discharge';
  status: 'planned' | 'validated' | 'completed' | 'requested';
  visit: Visit;
}> = ({ status, flow, visit }) => {
  return (
    <Stack direction="row" alignItems="center" flexWrap="nowrap" rowGap={2} spacing={4}>
      <Typography variant="h2" whiteSpace="nowrap">
        {getFlowTitle(flow, status)}
      </Typography>

      {'admission' === flow && (
        <AccommodationHeaderTitle
          flow={flow}
          from={visit.basedOn?.directAdmissionOrigin || visit?.previousAccommodation?.unit.name}
          to={visit.unit.name}
          fromBed={visit?.previousAccommodation?.bed?.name}
          toBed={visit.bed}
          requestType={visit.basedOn?.requestType}
        />
      )}
      {'discharge' === flow && (
        <AccommodationHeaderTitle
          isMutationRequest={visit.isMutationRequest}
          targetUnits={visit.basedOn?.performerRequests}
          numberOfRejections={visit.numberOfRejections}
          isRejected={visit.isRejected}
          flow={flow}
          from={visit.unit.name}
          to={visit.nextAccommodation?.unit.name}
          fromBed={visit.bed}
          toBed={visit?.nextAccommodation?.bed?.name}
        />
      )}
    </Stack>
  );
};

const VisitDetailsDialog: React.FC<VisitDetailsDialogProps> = ({
  visit,
  onClose,
  flow,
  status,
  menuActionName,
  disabled = false,
  onConfirm,
}) => {
  const { ids: unitIds } = useParams();

  const unitId = useMemo(() => (unitIds ? unitIds.split('-')[0] : undefined), [unitIds]);

  const { update, cancel, loading } = useAccommodation(visit.id);
  const { accept, reject, create, loading: requestLoading } = usePerformerRequest();
  const { cancel: cancelRequest, loading: accommodationRequestLoading } = useAccommodationRequest(
    flow === 'discharge' && !visit.isMutationRequest ? visit.nextAccommodation?.basedOn?.id : visit.basedOn?.id
  );

  const displayOnlyAction = useRef(!!menuActionName).current;
  const [displayDetails, setDisplayDetails] = useState(true);
  const [activeAction, setActiveAction] = useState<AccommodationAction | undefined>(menuActionName);

  const actionStatus = status === 'requested' && visit.basedOn?.status === 'REJECTED' ? 'blocked' : status;

  const actionType = visit.isMutationRequest || visit.basedOn ? 'mutation' : 'default';

  const isActionListVisible = !!getColumnActions({
    flow,
    type: actionType,
    status: actionStatus,
  }).length;

  if (!unitId) {
    onClose();
    return null;
  }

  const resetAction = () => {
    setDisplayDetails(true);
  };

  const handleChangeAction = (action: AccommodationAction) => {
    setDisplayDetails(false);
    setActiveAction(action);
  };

  const handleSubmit = async (
    accommodation?: {
      specificities?: AccommodationSpecificity[];
      startAt?: Date | null;
      endAt?: Date | null;
      isEndEstimated?: boolean;
      bedId?: string | null;
      unitId?: string | null;
      status?: AccommodationStatus;
    },
    request?: {
      startAt?: Date | null;
      reason?: string;
      status?: RequestStatus;
      performer?: string;
    }
  ) => {
    if (activeAction) {
      try {
        if (activeAction === 'CANCEL_ADMISSION' || activeAction === 'CANCEL_ACCOMMODATION') {
          await cancel();
        } else if (
          ['ACCEPT_INTERNAL_MUTATION_REQUEST', 'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN'].includes(activeAction)
        ) {
          const requestId = getCurrentPerformerRequestId(visit, unitId, flow);
          if (requestId && request?.startAt) await accept(requestId, request?.startAt);
        } else if (
          ['REJECT_INTERNAL_MUTATION_REQUEST', 'REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN'].includes(activeAction)
        ) {
          const requestId = getCurrentPerformerRequestId(visit, unitId, flow);
          if (requestId) await reject(requestId, request?.reason);
        } else if (activeAction === 'NEW_INTERNAL_MUTATION_REQUEST') {
          if (visit.basedOn && request?.performer) await create(visit.basedOn.id, request.performer);
        } else if (activeAction === 'CANCEL_MUTATION_REQUEST') {
          await cancelRequest();
        } else if (accommodation && activeAction === 'SET_MUTATION_BED' && visit.previousAccommodation) {
          await update(accommodation);

          await update({ isEndEstimated: false }, visit.previousAccommodation?.id);
        } else if (accommodation && activeAction === 'CANCEL_MUTATION_BED' && visit.previousAccommodation) {
          await update(accommodation);
          await update({ isEndEstimated: true }, visit.previousAccommodation?.id);
        } else if (activeAction === 'UPDATE_MUTATION_DATE') {
          if (visit.nextAccommodation?.id) {
            await update({ startAt: accommodation?.startAt }, visit.nextAccommodation?.id);
          }
        } else if (accommodation) {
          await update(accommodation);
        }
        toast.success({ ...i18n.visitActions[activeAction].success, icon: toastEmojis[activeAction] });
        if (!displayOnlyAction && REDIRECT_TO_DETAILS_ACTIONS.includes(activeAction)) {
          resetAction();
        } else {
          onConfirm ? onConfirm() : onClose();
        }
      } catch (error) {
        toast.error(i18n.visitActions[activeAction].error?.popupError || i18n.globalError);
      }
    } else {
      onClose();
    }
  };

  const showEmoji = (activeAction: string) =>
    ['CANCEL_ADMISSION', 'CANCEL_DISCHARGE', 'CANCEL_BED', 'CANCEL_MUTATION_REQUEST'].includes(activeAction);

  return (
    <div style={{ width: !displayOnlyAction && displayDetails ? 900 - BORDER_WIDTH : 520 - BORDER_WIDTH }}>
      <DialogHeader
        title={
          displayDetails && !displayOnlyAction ? (
            <VisitDetailsDialogHeaderTitle flow={flow} status={status} visit={visit} />
          ) : (
            activeAction && (
              <Typography variant="h2">
                {showEmoji(activeAction) && (
                  <Emoji name={activeAction === 'CANCEL_BED' ? 'sleepingFace' : 'thinkingFace'} size={24} inline />
                )}
                <span style={{ marginLeft: showEmoji(activeAction) ? 8 : undefined }}>
                  {i18n.visitActions[activeAction].form.title}
                </span>
              </Typography>
            )
          )
        }
        onBack={!displayDetails && !displayOnlyAction ? resetAction : undefined}
        onClose={onClose}
      />
      <DialogContent>
        {!displayOnlyAction && displayDetails ? (
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" style={{ height: 'auto', borderColor: color.grey[5] }}></Divider>}
            spacing={6}
          >
            <Stack spacing={6} flex={2}>
              <VisitSummary flow={flow} visit={visit} status={status} />
            </Stack>
            {isActionListVisible && (
              <Stack spacing={6} flex={1}>
                <Stack flex={1} />
                {actionStatus !== 'completed' && (
                  <ActionsList
                    disabled={disabled}
                    minimal
                    flow={flow}
                    status={actionStatus}
                    actionType={actionType}
                    onClick={handleChangeAction}
                    requestType={visit.basedOn?.requestType}
                  />
                )}
              </Stack>
            )}
          </Stack>
        ) : (
          <ActionForm
            action={activeAction}
            onCancel={onClose}
            visit={visit}
            onSubmit={handleSubmit}
            loading={loading || requestLoading || accommodationRequestLoading}
          />
        )}
      </DialogContent>
    </div>
  );
};

const BORDER_WIDTH = 1 * 2;

export default VisitDetailsDialog;
