import { Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

type EllipsisTypographyProps = TypographyProps & {
  maxLines?: number;
  disableTooltip?: boolean;
  tooltipProps?: Partial<Omit<TooltipProps, 'children'>>;
  setIsOverflowing?: (isOverflowing: boolean) => void;
};

const defaultTooltipProps: EllipsisTypographyProps['tooltipProps'] = {
  placement: 'top',
  PopperProps: {
    style: {
      whiteSpace: 'pre-wrap',
    },
  },
};

const isEllipsisActive = (element?: HTMLElement | null) => !!element && element.offsetHeight < element.scrollHeight;

const EllipsisTypography = ({
  maxLines = 1,
  disableTooltip,
  tooltipProps,
  setIsOverflowing: initialSetIsOverflowing,
  children,
  sx,
  ...props
}: EllipsisTypographyProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  tooltipProps = !disableTooltip && isOverflowing && children ? { ...defaultTooltipProps, ...tooltipProps } : {};

  useEffect(() => {
    const handleOverflowing = () => {
      if (ref.current) {
        setIsOverflowing(isEllipsisActive(ref.current));
      }
    };

    handleOverflowing();

    window.addEventListener('resize', handleOverflowing);
    return () => {
      window.removeEventListener('resize', handleOverflowing);
    };
  }, []);

  useEffect(() => {
    initialSetIsOverflowing?.(isOverflowing);
  }, [initialSetIsOverflowing, isOverflowing]);

  return (
    <Tooltip title={!disableTooltip && isOverflowing && children ? children : ''} {...tooltipProps}>
      <Typography
        ref={ref}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'pre-wrap',
          wordBreak: maxLines > 1 ? 'break-word' : 'break-all',
          ...sx,
        }}
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default EllipsisTypography;
