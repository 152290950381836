import { WebUserRole } from '@ambuliz/sabri-core';
import { AppRoute, Application, appRoutes } from 'core/routes';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from './AuthenticationContext';
import { hasAccessToRoute } from './navigationACL';

const routesByRole: Record<WebUserRole, AppRoute> = {
  ADMIN: appRoutes.admin,
  ADMIN_REGULATOR: appRoutes.sabri,
  REGULATOR: appRoutes.sabri,
  BED_MANAGER: appRoutes.kurt,
  MANAGER: appRoutes.kurt,
};

const routesByACL: Record<Application, AppRoute> = {
  kurt: appRoutes.kurt,
  admin: appRoutes.admin,
  sabri: appRoutes.sabri,
};

const RootRedirect: React.FC = () => {
  const { isLoggedIn, user, navigationACL } = useAuthentication();

  const redirectionPath = useMemo(() => {
    const defaultRoute = routesByRole[user.role];

    if (defaultRoute && hasAccessToRoute(navigationACL, defaultRoute.route)) {
      return defaultRoute.path;
    }

    for (const route of Object.values(routesByACL)) {
      if (hasAccessToRoute(navigationACL, route.route)) {
        return route.path;
      }
    }

    return '/';
  }, [navigationACL, user.role]);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <Navigate to={redirectionPath} />;
};
export default RootRedirect;
