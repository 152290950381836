import { SvgIcon, SvgIconProps } from '@mui/material';

const LyingDown: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.06851 3.01555C3.28348 1.97035 1.75339 1.86176 0.828229 2.7856C0.0716614 3.54109 -0.01346 4.73712 0.628509 5.59185L2 7.41788V10.6632H0.748924C0.335305 10.6632 0 10.9985 0 11.4121C0 11.8257 0.335305 12.161 0.748924 12.161H1.27525C0.951117 12.4782 0.75 12.9203 0.75 13.4092C0.75 14.3744 1.5335 15.1567 2.5 15.1567C3.4665 15.1567 4.25 14.3744 4.25 13.4092C4.25 12.9203 4.04888 12.4782 3.72475 12.161H12.2753C11.9511 12.4782 11.75 12.9203 11.75 13.4092C11.75 14.3744 12.5335 15.1567 13.5 15.1567C14.4665 15.1567 15.25 14.3744 15.25 13.4092C15.25 12.9203 15.0489 12.4782 14.7247 12.161H15.2511C15.6647 12.161 16 11.8257 16 11.4121C16 10.9985 15.6647 10.6632 15.2511 10.6632H14V9.90056C15.125 9.77636 16 8.82397 16 7.6675C16 6.42664 14.9926 5.42073 13.75 5.42073H5.87498L4.06851 3.01555ZM13 9.91427H3.87498L3 8.7493V10.6632H13V9.91427ZM1.88889 3.84474C2.16859 3.56544 2.63117 3.59827 2.86851 3.91426L5.12498 6.91857H13.75C14.1642 6.91857 14.5 7.25388 14.5 7.6675C14.5 8.08112 14.1642 8.41642 13.75 8.41642H4.62498L1.82851 4.69314C1.63443 4.43474 1.66016 4.07314 1.88889 3.84474ZM3.25 13.4092C3.25 13.8229 2.91421 14.1582 2.5 14.1582C2.08579 14.1582 1.75 13.8229 1.75 13.4092C1.75 12.9956 2.08579 12.6603 2.5 12.6603C2.91421 12.6603 3.25 12.9956 3.25 13.4092ZM13.5 14.1582C13.9142 14.1582 14.25 13.8229 14.25 13.4092C14.25 12.9956 13.9142 12.6603 13.5 12.6603C13.0858 12.6603 12.75 12.9956 12.75 13.4092C12.75 13.8229 13.0858 14.1582 13.5 14.1582Z"
      />
    </SvgIcon>
  );
};

export default LyingDown;
