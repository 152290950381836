"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBedOccupancy = void 0;
const getBedOccupancy = (dDaySnapshot, h24Snapshot, h48Snapshot, dDayAdmission, dDayDischarge, pendingH24Snapshot) => {
    var _a, _b;
    const dDayAvailabilities = getDDayGenderAvailability(dDaySnapshot, pendingH24Snapshot);
    return {
        covid: dDaySnapshot.specificities.includes('COVID') ? 1 : 0,
        accomodated: dDaySnapshot.specificities.includes('ACCOMMODATED') ||
            (((_a = dDaySnapshot.responsibleUnit) === null || _a === void 0 ? void 0 : _a.id) && ((_b = dDaySnapshot.unit) === null || _b === void 0 ? void 0 : _b.id) !== dDaySnapshot.responsibleUnit.id)
            ? 1
            : 0,
        isolated: dDaySnapshot.specificities.includes('ISOLATED') ? 1 : 0,
        isolatedAir: dDaySnapshot.specificities.includes('ISOLATED_AIR') ? 1 : 0,
        isolatedDroplet: dDaySnapshot.specificities.includes('ISOLATED_DROPLET') ? 1 : 0,
        isolatedContact: dDaySnapshot.specificities.includes('ISOLATED_CONTACT') ? 1 : 0,
        isolatedProtector: dDaySnapshot.specificities.includes('ISOLATED_PROTECTOR') ? 1 : 0,
        close: dDaySnapshot.status === 'CLOSED' && !dDaySnapshot.excludedFromOccupancy ? 1 : 0,
        waitingForDownstreamBed: dDaySnapshot.specificities.includes('WAITING_FOR_DOWNSTREAM_BED') ? 1 : 0,
        open: dDaySnapshot.status !== 'CLOSED' && !dDaySnapshot.excludedFromOccupancy ? 1 : 0,
        dDayAvailabilities,
        h24Availabilities: getGenderAvailability(h24Snapshot),
        h48Availabilities: getGenderAvailability(h48Snapshot),
        admission: dDayAdmission,
        discharge: dDayDischarge,
        unavailableExcluded: (dDaySnapshot.status !== 'AVAILABLE' || pendingH24Snapshot.status !== 'AVAILABLE') &&
            dDaySnapshot.excludedFromOccupancy
            ? 1
            : 0,
        updatedAt: new Date(),
    };
};
exports.getBedOccupancy = getBedOccupancy;
const getGenderAvailability = (snapshot) => {
    if (!snapshot.excludedFromOccupancy) {
        return {
            female: snapshot.gender === 'FEMALE' && snapshot.status === 'AVAILABLE' ? 1 : 0,
            indifferent: snapshot.gender === 'UNKNOWN' && snapshot.status === 'AVAILABLE' ? 1 : 0,
            male: snapshot.gender === 'MALE' && snapshot.status === 'AVAILABLE' ? 1 : 0,
        };
    }
    return {
        male: 0,
        female: 0,
        indifferent: 0,
    };
};
const getDDayGenderAvailability = (dDaySnapshot, pendingH24Snapshot) => {
    const isTomorrowAvailable = pendingH24Snapshot.status === 'AVAILABLE' && !pendingH24Snapshot.excludedFromOccupancy;
    const isDDayAvailable = dDaySnapshot.status === 'AVAILABLE' && !dDaySnapshot.excludedFromOccupancy;
    if (!(isTomorrowAvailable && isDDayAvailable)) {
        return {
            female: 0,
            male: 0,
            indifferent: 0,
        };
    }
    else if (!isTomorrowAvailable) {
        return {
            female: pendingH24Snapshot.gender === 'FEMALE' ? 1 : 0,
            indifferent: pendingH24Snapshot.gender === 'UNKNOWN' ? 1 : 0,
            male: pendingH24Snapshot.gender === 'MALE' ? 1 : 0,
        };
    }
    return {
        female: dDaySnapshot.gender === 'FEMALE' ? 1 : 0,
        indifferent: dDaySnapshot.gender === 'UNKNOWN' ? 1 : 0,
        male: dDaySnapshot.gender === 'MALE' ? 1 : 0,
    };
};
