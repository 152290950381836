import { Stack, Typography } from '@mui/material';
import { SVGProps } from 'react';

type KanbanEmptyContentProps = {
  title: string;
  subtitle: string;
  Image: React.ComponentType<SVGProps<SVGSVGElement>>;
};
const KanbanEmptyContent: React.FC<KanbanEmptyContentProps> = ({ title, subtitle, Image }) => (
  <Stack paddingTop={14} spacing={4} alignItems="center">
    <Image height={90} />
    <Stack spacing={2} alignItems="center" textAlign="center">
      <Typography>{title}</Typography>
      <Typography variant="body2" color="secondary">
        {subtitle}
      </Typography>
    </Stack>
  </Stack>
);

export default KanbanEmptyContent;
