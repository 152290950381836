import { Button } from '@mui/material';
import { color } from 'common/theme';
import React from 'react';
import { i18n } from 'sabri/locales';
import styled from 'styled-components';

type ClearFilterButtonProps = {
  visible: boolean;
  onClick: () => void;
};

const ClearFilterButtonContainer = styled(Button)`
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  color: ${color.grey[80]};
`;

const ClearFilterButton: React.FC<ClearFilterButtonProps> = ({ visible, onClick }) => {
  return (
    <ClearFilterButtonContainer variant="text" onClick={onClick} color="inherit" disabled={!visible}>
      {i18n.clearFilter}
    </ClearFilterButtonContainer>
  );
};

export default ClearFilterButton;
