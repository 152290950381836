import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { FormContainer, FormSection, InputIncrement } from 'common/components/Form';
import { ArrowLeft } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, theme } from 'common/theme';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import CommentInput from './CommentInput';
import { defaultOccupancy, InputName, OccupancyFormValue } from './EditModal';
import OccupancyInput from './OccupancyInput';

type UnitEditModalFormProps = {
  title: string;
  occupancy: OccupancyFormValue;
  onSubmit: () => void;
  onClose: () => void;
  onChange: (value: number, name: InputName) => void;
  onAvailabilityChange: (
    name: 'dDayAvailabilities' | 'h24Availabilities' | 'h48Availabilities',
    gender: 'male' | 'female' | 'indifferent'
  ) => (value: number) => void;
  onCommentChange: (event: ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  lastCommentUpdate?: Date;
};

const Container = styled.div`
  min-width: 550px;
`;

const AvailabilityInputContainer = styled.div`
  &:not(:last-child) {
    margin-right: 24px;
  }
`;

const AvailabilityInputLabel = styled.div`
  margin-bottom: 8px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${theme.palette.text.secondary};
`;

const LastCommentUpdateText = styled(Typography)`
  margin-top: 8px;
  color: ${theme.palette.text.secondary};
  font-size: 12px;
  line-height: 16px;
`;

const ActionsContainer = styled.div`
  padding: 16px 0 40px 0;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid ${color.grey[20]};
`;

const UnitEditModalForm: React.FC<UnitEditModalFormProps> = ({
  title,
  occupancy = defaultOccupancy,
  onSubmit,
  onClose,
  onChange,
  onCommentChange,
  onAvailabilityChange,
  loading = false,
  lastCommentUpdate,
}) => {
  return (
    <Container>
      <FormContainer label={title}>
        <FormSection label={i18n.unitEditForm.informations.title}>
          <Stack justifyContent="space-between" spacing={6} direction="row">
            <OccupancyInput
              label={i18n.unitEditForm.informations.beds.open}
              value={occupancy.open}
              onChange={onChange}
              name="open"
            />
            <OccupancyInput
              label={i18n.unitEditForm.informations.beds.close}
              value={occupancy.close}
              onChange={onChange}
              name="close"
            />
          </Stack>
          <Stack justifyContent="space-between" spacing={6} direction="row">
            <OccupancyInput
              label={i18n.unitEditForm.informations.patients.accomodated}
              value={occupancy.accomodated}
              onChange={onChange}
              name="accomodated"
            />
            <OccupancyInput
              label={i18n.unitEditForm.informations.patients.covid}
              value={occupancy.covid}
              onChange={onChange}
              name="covid"
            />
          </Stack>
          <Stack justifyContent="space-between" spacing={6} direction="row">
            <OccupancyInput
              label={i18n.unitEditForm.informations.patients.waitingForDownstreamBed}
              value={occupancy.waitingForDownstreamBed}
              onChange={onChange}
              name="waitingForDownstreamBed"
            />
            <div style={{ flex: 1 }} />
          </Stack>
        </FormSection>
        <FormSection label={i18n.unitEditForm.inputOutput.title}>
          <Stack justifyContent="space-between" spacing={6} direction="row">
            <OccupancyInput
              label={i18n.unitEditForm.inputOutput.input}
              value={occupancy.admission}
              onChange={onChange}
              name="admission"
            />
            <OccupancyInput
              label={i18n.unitEditForm.inputOutput.output}
              value={occupancy.discharge}
              onChange={onChange}
              name="discharge"
            />
          </Stack>
        </FormSection>
        <FormSection label={i18n.unitEditForm.availabilities.title}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={3}>
              <Typography style={{ marginTop: 20 }}>{i18n.unitEditForm.availabilities.dDay}</Typography>
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <AvailabilityInputContainer>
                <AvailabilityInputLabel>{i18n.unitEditForm.availabilities.labels.male}</AvailabilityInputLabel>
                <InputIncrement
                  value={occupancy.dDayAvailabilities?.male}
                  onChange={onAvailabilityChange('dDayAvailabilities', 'male')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
              <AvailabilityInputContainer>
                <AvailabilityInputLabel>{i18n.unitEditForm.availabilities.labels.female}</AvailabilityInputLabel>
                <InputIncrement
                  value={occupancy.dDayAvailabilities?.female}
                  onChange={onAvailabilityChange('dDayAvailabilities', 'female')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
              <AvailabilityInputContainer>
                <AvailabilityInputLabel>{i18n.unitEditForm.availabilities.labels.indifferent}</AvailabilityInputLabel>
                <InputIncrement
                  value={occupancy.dDayAvailabilities?.indifferent}
                  onChange={onAvailabilityChange('dDayAvailabilities', 'indifferent')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={3}>
              <Typography>{i18n.unitEditForm.availabilities.h24}</Typography>
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <AvailabilityInputContainer>
                <InputIncrement
                  value={occupancy.h24Availabilities?.male}
                  onChange={onAvailabilityChange('h24Availabilities', 'male')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
              <AvailabilityInputContainer>
                <InputIncrement
                  value={occupancy.h24Availabilities?.female}
                  onChange={onAvailabilityChange('h24Availabilities', 'female')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
              <AvailabilityInputContainer>
                <InputIncrement
                  value={occupancy.h24Availabilities?.indifferent}
                  onChange={onAvailabilityChange('h24Availabilities', 'indifferent')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={3}>
              <Typography>{i18n.unitEditForm.availabilities.h48}</Typography>
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <AvailabilityInputContainer>
                <InputIncrement
                  value={occupancy.h48Availabilities?.male}
                  onChange={onAvailabilityChange('h48Availabilities', 'male')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
              <AvailabilityInputContainer>
                <InputIncrement
                  value={occupancy.h48Availabilities?.female}
                  onChange={onAvailabilityChange('h48Availabilities', 'female')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
              <AvailabilityInputContainer>
                <InputIncrement
                  value={occupancy.h48Availabilities?.indifferent}
                  onChange={onAvailabilityChange('h48Availabilities', 'indifferent')}
                  allowNegativeValue
                />
              </AvailabilityInputContainer>
            </Grid>
          </Grid>
        </FormSection>
        <FormSection label={i18n.commentInput.label}>
          <div style={{ marginBottom: 24 }}>
            <CommentInput value={occupancy.comment} onChange={onCommentChange} />
            {lastCommentUpdate && (
              <LastCommentUpdateText>{i18n.lastCommentUpdate.long(lastCommentUpdate)}</LastCommentUpdateText>
            )}
          </div>
        </FormSection>
      </FormContainer>
      <ActionsContainer>
        <Stack justifyContent="flex-end" spacing={4} direction="row">
          <Button variant="outlined" startIcon={<ArrowLeft />} onClick={onClose}>
            {i18n.goBack}
          </Button>
          <LoadingButton loading={loading} disabled={loading} onClick={onSubmit}>
            {i18n.save}
          </LoadingButton>
        </Stack>
      </ActionsContainer>
    </Container>
  );
};
export default UnitEditModalForm;
