import { SvgIcon, SvgIconProps } from '@mui/material';

const DoctorIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        d="M5.42081 0C5.09637 0 4.81946 0.234496 4.766 0.554502L4.29548 3.37135C4.28672 3.42375 4.28437 3.47648 4.28812 3.52848C4.16878 3.90253 4.10451 4.30066 4.10451 4.71278C4.10451 6.86289 5.84752 8.6059 7.99763 8.6059C10.1477 8.6059 11.8907 6.86289 11.8907 4.71278C11.8907 4.27813 11.8193 3.85906 11.6871 3.46738C11.6867 3.44869 11.6855 3.42995 11.6836 3.41121L11.387 0.594361C11.3514 0.25649 11.0665 0 10.7267 0H5.42081ZM10.4432 4.14461H5.55202C5.50991 4.32681 5.4876 4.51692 5.4876 4.71278C5.4876 6.09904 6.61138 7.22281 7.99763 7.22281C9.38388 7.22281 10.5077 6.09904 10.5077 4.71278C10.5077 4.51692 10.4853 4.32681 10.4432 4.14461ZM5.73426 2.81685L5.983 1.32776H10.1291L10.2859 2.81685H5.73426ZM5.75775 9.22315C5.81395 9.20641 5.8706 9.19714 5.92663 9.19478C6.18775 9.14128 6.46652 9.24919 6.61898 9.48702L8.01155 11.6594L9.37305 9.49214C9.5266 9.24772 9.81325 9.13773 10.0798 9.19716C10.124 9.2013 10.1686 9.20979 10.2128 9.22292C13.6381 10.2392 14.4923 13.4757 14.4682 15.1787C14.4628 15.5606 14.1488 15.8658 13.7669 15.8604C13.385 15.855 13.0798 15.541 13.0852 15.1592C13.0997 14.1359 12.7228 12.5438 11.5023 11.4664V12.9466C12.1001 13.1706 12.5256 13.7473 12.5256 14.4233C12.5256 15.294 11.8198 15.9999 10.949 15.9999C10.0783 15.9999 9.37249 15.294 9.37249 14.4233C9.37249 13.7473 9.79801 13.1706 10.3958 12.9465V10.7603C10.3308 10.7315 10.2643 10.7039 10.1964 10.6775L8.57937 13.2515C8.45847 13.444 8.24752 13.5612 8.02025 13.5622C7.79297 13.5633 7.58096 13.448 7.45831 13.2567L5.79943 10.6689C3.53116 11.5416 2.92307 13.8198 2.9607 15.1494C2.9715 15.5311 2.67077 15.8494 2.289 15.8602C1.90722 15.871 1.58897 15.5703 1.57816 15.1885C1.53001 13.4871 2.33835 10.242 5.75775 9.22315ZM10.949 13.9532C10.6894 13.9532 10.479 14.1637 10.479 14.4233C10.479 14.6829 10.6894 14.8934 10.949 14.8934C11.2087 14.8934 11.4191 14.6829 11.4191 14.4233C11.4191 14.1637 11.2087 13.9532 10.949 13.9532Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default DoctorIcon;
