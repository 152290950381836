import { HealthCenterRegionData } from '@ambuliz/sabri-core/dist/cloud';
import { Button } from '@mui/material';
import { MapNavigator } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, theme } from 'common/theme';
import { LatLngBounds, LatLngTuple } from 'leaflet';
import { useEffect } from 'react';
import { TileLayer, useMap } from 'react-leaflet';
import styled from 'styled-components';
import HealthCenterTooltip from './HealthCenterTooltip';
import useHealthCentersWithinBounds from './useHealthCentersWithinBounds';

const ReCenterButton = styled(Button)`
  position: absolute;
  top: 10px;
  left: 55px;
  z-index: 800;
  color: ${theme.palette.text.secondary};
  border-color: ${color.grey[20]};
  background-color: white;
  &:hover {
    background-color: ${theme.palette.secondary.light};
    border-color: ${color.grey[20]};
    box-shadow: ${theme.shadows[3]};
  }
  box-shadow: ${theme.shadows[3]};
`;

type MapLayerProps = {
  healthCenters: HealthCenterRegionData[];
  bounds: LatLngBounds | undefined;
  loading?: boolean;
  onRecenterClick: () => void;
};
export const defaultLocation: LatLngTuple = [48.8629, 2.3567];

const MapLayer: React.FC<MapLayerProps> = ({ healthCenters = [], bounds, loading = false, onRecenterClick }) => {
  const leafletMap = useMap();

  const allHealthCentersWithinBounds = useHealthCentersWithinBounds(healthCenters, leafletMap);

  useEffect(() => {
    if (!loading) {
      if (bounds && bounds.isValid()) {
        leafletMap.fitBounds(bounds);
      } else {
        leafletMap.fitBounds([defaultLocation], { maxZoom: 6 });
      }
    }
  }, [loading, leafletMap, bounds]);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
      />
      {healthCenters.map((healthCenter) => (
        <HealthCenterTooltip
          key={healthCenter.name}
          healthCenterName={healthCenter.name}
          occupancyRate={healthCenter.occupancy?.rate}
          occupatedBeds={healthCenter.occupancy?.occupied}
          groupNames={healthCenter.groups ? healthCenter.groups.map((group) => group.name) : []}
          totalOfBeds={healthCenter.occupancy?.open}
          loading={false}
          location={healthCenter.location}
          availableBeds={healthCenter.occupancy?.available}
        />
      ))}
      {!allHealthCentersWithinBounds && (
        <ReCenterButton variant="outlined" onClick={onRecenterClick} endIcon={<MapNavigator fontSize="small" />}>
          {i18n.reCenterMap}
        </ReCenterButton>
      )}
    </>
  );
};

export default MapLayer;
