import Api, { ExternalPatientTransport, Patient } from '@ambuliz/sabri-core';
import { useParseQuery } from '../../../../common/hooks/useParseQuery';

type UseExternalTransportsOptions = {
  visit?: Patient;
};

export const useExternalPatientTransports = (options: UseExternalTransportsOptions) => {
  const query = new Api.Query(ExternalPatientTransport)
    .equalTo('visit', options.visit)
    .notEqualTo('status', 'CANCELED')
    .include('visit');

  const { results, isLoading } = useParseQuery(query, {
    enabled: !!options.visit,
  });

  results.sort((a, b) => a.startAt.getTime() - b.startAt.getTime());
  return { externalTransports: results, isLoading };
};
