import { Chip, Stack, Typography } from '@mui/material';
import { getPatientAge } from 'common/utils';
import { format } from 'date-fns';
import { i18n } from 'sabri/locales';

type FareDetailsPatientSummaryProps = {
  ipp: string;
  pan: string;
  birthdate: Date;
  name: string;
};

const FareDetailsPatientSummary: React.FC<FareDetailsPatientSummaryProps> = ({ name, ipp, pan, birthdate }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h2">{name}</Typography>
      <Stack direction="row" spacing={2}>
        <>
          <Chip
            label={
              <>
                {format(birthdate, 'dd/MM/yyyy')}
                <Typography color="secondary" component="span" variant="body2">{` (${getPatientAge(birthdate)} ${
                  i18n.years
                })`}</Typography>
              </>
            }
            variant="outlined"
            color="default"
          />
          <Chip label={`${i18n.ippShort}: ${ipp}`} variant="outlined" color="default" />
          <Chip label={`${i18n.panShort}: ${pan}`} variant="outlined" color="default" />
        </>
      </Stack>
    </Stack>
  );
};

export default FareDetailsPatientSummary;
