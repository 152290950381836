import Api, { HealthCenter } from '@ambuliz/sabri-core';
import { useAuthentication } from 'core/authentication';
import { liveQueryClient } from 'core/live-query-client';
import { LiveQuerySubscription } from 'parse';
import { useEffect, useState } from 'react';
import NormalizedHealthCenter, { normalizeHealthCenter } from './types/NormalizedHealthCenter';

const useMyHealthCenter = () => {
  const { healthCenter } = useAuthentication();

  const [myHealthCenter, setMyHealthCenter] = useState<NormalizedHealthCenter>();
  const [loading, setLoading] = useState(true);

  const [subscription, setSubscription] = useState<LiveQuerySubscription>();

  useEffect(() => {
    setSubscription(
      liveQueryClient.subscribe(
        new Api.Query(HealthCenter).equalTo('objectId', healthCenter.id),
        Parse.User.current()?.getSessionToken()
      )
    );
  }, [healthCenter.id]);

  useEffect(() => {
    if (subscription) {
      subscription.on('open', async () => {
        const myHealthCenter = await new Api.Query(HealthCenter).get(healthCenter.id);
        if (healthCenter) {
          setMyHealthCenter(normalizeHealthCenter(myHealthCenter));
          setLoading(false);
        }
      });

      subscription.on('update', (healthCenter: Parse.Object) => {
        setMyHealthCenter(normalizeHealthCenter(healthCenter as HealthCenter));
      });
    }

    return () => liveQueryClient.unsubscribe(subscription);
  }, [subscription, healthCenter]);

  return {
    loading,
    myHealthCenter,
  };
};

export default useMyHealthCenter;
