import { Chip, ChipProps } from '@mui/material';
import CheckCircleIcon from 'common/components/Icons/CheckCircle';
import EndIcon from 'common/components/Icons/End';
import ErrorCircleIcon from 'common/components/Icons/ErrorCircleIcon';
import HourglassIcon from 'common/components/Icons/Hourglass';
import PathIcon from 'common/components/Icons/Path';
import PatientReadyIcon from 'common/components/Icons/PatientReadyIcon';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';

type Color = keyof Pick<typeof palette, 'success' | 'primary' | 'error' | 'secondary' | 'warning'>;
export type MutationStatus = 'TO_ADDRESS' | 'VALIDATED' | 'PENDING' | 'BLOCKED' | 'COMPLETED' | 'ACCEPTED';

type MutationStatusChipProps = {
  status: MutationStatus;
} & Omit<ChipProps, 'label'>;

const properties: Record<MutationStatus, { label: string; color: Color; icon: React.ReactElement }> = {
  TO_ADDRESS: {
    label: i18n.mutationStatus.toAddress,
    color: 'secondary',
    icon: <PathIcon />,
  },
  ACCEPTED: {
    label: i18n.mutationStatus.accepted,
    color: 'warning',
    icon: <PatientReadyIcon />,
  },
  VALIDATED: {
    label: i18n.mutationStatus.validated,
    color: 'success',
    icon: <CheckCircleIcon />,
  },
  COMPLETED: {
    label: i18n.mutationStatus.ended,
    color: 'secondary',
    icon: <EndIcon />,
  },
  PENDING: {
    label: i18n.mutationStatus.pending,
    color: 'primary',
    icon: <HourglassIcon />,
  },
  BLOCKED: {
    label: i18n.mutationStatus.blocked,
    color: 'error',
    icon: <ErrorCircleIcon />,
  },
};

const MutationStatusChip = ({ status, ...props }: MutationStatusChipProps) => (
  <Chip
    color={properties[status].color}
    label={properties[status].label}
    icon={properties[status].icon}
    variant="light"
    {...props}
  />
);

export default MutationStatusChip;
