import { Box, DialogContent, Skeleton, Stack } from '@mui/material';
import { DialogHeader } from 'common/components';
import { palette } from 'common/theme';
import { getDialogTitle } from '../DetailsDialogContent';
import InfoCard from '../InfoCard';
import { StepType } from '../context/Step';
import SkeletonActions from './SkeletonActions';
import SkeletonContent from './SkeletonContent';
import SkeletonDetailsTimeline from './SkeletonDetailsTimeline';

type SkeletonDetailsProps = {
  type?: StepType;
  onClose: () => void;
};

const SkeletonDetails = ({ type, onClose }: SkeletonDetailsProps) => {
  return (
    <Stack>
      <DialogHeader
        onClose={onClose}
        title={type ? getDialogTitle(type) : <Skeleton variant="rounded" width={200} height={30} />}
        dialogTitleProps={{ bgcolor: palette.background.default }}
        iconVariant="filled"
      />
      <SkeletonDetailsTimeline />
      <DialogContent>
        <Stack direction="row" spacing={8}>
          <Box flexGrow={1}>
            <SkeletonContent />
          </Box>
          <Stack width={260} flexShrink={0} spacing={6} justifyContent="space-between">
            <InfoCard />
            <SkeletonActions />
          </Stack>
        </Stack>
      </DialogContent>
    </Stack>
  );
};

export default SkeletonDetails;
