import { Accommodation, AccommodationRequest, Patient } from '@ambuliz/sabri-core';
import { MutationStep } from '../Step';

export const mapMutationStep = (
  mutation: AccommodationRequest,
  accommodations: Accommodation[],
  visit?: Patient
): MutationStep => {
  accommodations.sort((a, b) => (a.startAt < b.startAt ? 1 : -1));
  if (visit && mutation.visit?.id === visit.id) {
    mutation.visit = visit;
  }

  let origin: Accommodation | undefined = undefined;
  const destination = accommodations.find((accommodation) => accommodation.basedOn?.id === mutation.id);

  if (mutation.requestType !== 'DIRECT_ADMISSION') {
    const mutationDate = destination?.startAt || mutation.createdAt;

    origin = accommodations.find(
      (accommodation) => accommodation.id !== destination?.id && accommodation.startAt < mutationDate
    );
  }

  let start: Date = origin?.endAt || destination?.startAt || mutation.createdAt;
  let end: Date | undefined = destination?.startAt || origin?.endAt || mutation.createdAt;

  if (end && start > end) {
    const tmpStart = start;

    start = end;
    end = tmpStart;
  }
  return {
    type: 'mutation',
    step: mutation,
    start,
    end,
    origin,
    destination,
  };
};
