import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import React, { createRef, ReactNode, useEffect, useState } from 'react';
import { FilterItem } from '../FiltersController/FiltersController';

type SelectorControllerProps = {
  title: string;
  children: ({
    itemsSelected,
    setIsSelectorVisible,
    setItemsSelected,
  }: {
    itemsSelected: FilterItem[];
    setIsSelectorVisible: (value: boolean) => void;
    setItemsSelected: React.Dispatch<React.SetStateAction<FilterItem[]>>;
  }) => ReactNode;
  onClose: () => void;
};

export const SelectorController: React.FC<SelectorControllerProps> = ({ title, onClose, children }) => {
  const [itemsSelected, setItemsSelected] = useState<FilterItem[]>([]);
  const [isSelectorVisible, setIsSelectorVisible] = useState(true);
  const refSelector = createRef<HTMLDivElement>();
  const [minWidthHeader, setMinWidthHeader] = useState<undefined | number>();
  const classes = useClasses({ isSelectorVisible, minWidthHeader });

  useEffect(() => {
    if (refSelector.current) {
      setMinWidthHeader(refSelector.current.getBoundingClientRect().width);
    }
  }, [refSelector]);

  const renderItemSelectedLabels = () => {
    if (itemsSelected.length > 0 && !isSelectorVisible) {
      const fullLabels = itemsSelected
        .map(({ label }) => label)
        .slice(0, 2)
        .join(', ');
      if (itemsSelected.length > 2) {
        return `${fullLabels}, +${itemsSelected.length - 2}`;
      }
      return fullLabels;
    }
  };

  return (
    <div className={classes.container}>
      <div onClick={() => setIsSelectorVisible(true)} className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <div>{title} :</div>
          <div className={classes.itemNames}>{renderItemSelectedLabels()}</div>
        </div>
        <div className={classes.closeContainer} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </div>
      </div>
      {isSelectorVisible && (
        <div ref={refSelector} className={classes.selector}>
          {children({ itemsSelected, setIsSelectorVisible, setItemsSelected })}
        </div>
      )}
    </div>
  );
};

type SelectorControllerStyles = {
  isSelectorVisible: boolean;
  minWidthHeader?: number;
};

const useClasses = makeStyles({
  container: {
    backgroundColor: 'white',
  },
  itemNames: {
    fontSize: 14,
    color: '#1A73E8',
    fontWeight: 500,
    marginLeft: 4,
  },
  headerContainer: ({ isSelectorVisible, minWidthHeader }: SelectorControllerStyles) => ({
    borderRadius: 3,
    border: '1px solid',
    borderColor: isSelectorVisible ? '#1A73E8' : '#DDDDDD',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 45,
    backgroundColor: isSelectorVisible ? 'rgba(26,115,232,0.1)' : 'white',
    minWidth: minWidthHeader,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  titleContainer: ({ isSelectorVisible }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: isSelectorVisible ? '#1A73E8' : undefined,
  }),
  closeContainer: {
    display: 'flex',
    marginLeft: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
  selector: {
    display: 'flex',
    borderRadius: 3,
    border: '1px solid #DDDDDD',
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 2,
    width: 'auto !important',
    flexDirection: 'column',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
  },
});
