import { Box, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { color } from 'common/theme';

import { format } from 'date-fns';

const FlowSummaryDate: React.FC<{ label: string; date?: Date; alignItems?: string; showWarning?: boolean }> = ({
  label,
  date,
  alignItems = 'flex-start',
  showWarning = false,
}) => {
  return (
    <Box flex={1} style={{ display: 'flex', flexDirection: 'column', alignItems: alignItems }}>
      <Typography variant="body2" color="secondary">
        {label}
      </Typography>

      {date ? (
        <>
          {showWarning ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Emoji name="warning" size={14} />
              <Typography fontWeight={600} color={color.yellow[70]}>
                {format(date, 'dd/MM/yyyy')}
                <Typography component="span" color={color.yellow[70]}>
                  {' '}
                  - {format(date, 'HH:mm')}
                </Typography>
              </Typography>
            </Stack>
          ) : (
            <Typography fontWeight={600}>
              {format(date, 'dd/MM/yyyy')}
              <Typography component="span"> - {format(date, 'HH:mm')}</Typography>
            </Typography>
          )}
        </>
      ) : (
        <Typography fontWeight={600} color="secondary">
          -
        </Typography>
      )}
    </Box>
  );
};

export default FlowSummaryDate;
