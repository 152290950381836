import { SvgIcon, SvgIconProps } from '@mui/material';

const Search: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M15.6839 14.0379L15.6642 14.0182L11.4568 9.81865C12.1877 8.77369 12.563 7.53157 12.563 6.26974C12.563 2.81941 9.73827 0 6.28148 0C2.82469 0 0 2.81941 0 6.26974C0 9.72007 2.82469 12.5395 6.28148 12.5395C7.54568 12.5395 8.79012 12.1452 9.83704 11.4354L14.0444 15.6349C14.479 16.0884 15.2099 16.1081 15.6642 15.6546C16.0988 15.2209 16.1185 14.4914 15.6839 14.0379ZM6.28148 10.8439C3.75309 10.8439 1.69877 8.79341 1.69877 6.26974C1.69877 3.74607 3.75309 1.69559 6.28148 1.69559C8.80988 1.69559 10.8642 3.74607 10.8642 6.26974C10.8642 8.79341 8.80988 10.8439 6.28148 10.8439Z" />
    </SvgIcon>
  );
};

export default Search;
