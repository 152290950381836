import { Contact, ContactPoint } from '@ambuliz/sabri-core';
import { Add as AddIcon, Clear as ClearIcon } from '@mui/icons-material';
import { FormLabel, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { i18n } from 'common/locale';
import { ChangeEvent } from 'react';

type TelecomInputType = {
  value: Contact[];
  onChange: (contacts: Contact[]) => void;
  loading?: boolean;
};

const TelecomInput: React.FC<TelecomInputType> = ({ value = [], onChange, loading }) => {
  const handleAdd = () => {
    const telecom: ContactPoint = { value: '', system: 'phone' };
    onChange([...value, { name: '', telecoms: [telecom] }]);
  };

  const handleRemove = (index: number) => () => {
    const updatedTelecom = [...value];
    updatedTelecom.splice(index, 1);
    onChange(updatedTelecom);
  };

  const handleNameChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const updatedContact = [...value];

    updatedContact[index] = { ...updatedContact[index], name: event.target.value };

    onChange(updatedContact);
  };

  const handlePhoneNumberChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const updatedContact = [...value];
    const updatedTelecom = [...updatedContact[index].telecoms];
    updatedTelecom[0] = { ...updatedTelecom[0], value: event.target.value };
    updatedContact[index] = { ...updatedContact[index], telecoms: updatedTelecom };
    onChange(updatedContact);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid container item alignItems="center" justifyContent="space-between">
        <Grid item>
          <FormLabel>{i18n.unitContact}</FormLabel>
        </Grid>
        <Grid item>
          <Tooltip title={i18n.addUnitContact}>
            <IconButton color="primary" onClick={handleAdd} disabled={loading} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {value.map((contact, index) => (
        <Grid item container spacing={2} alignItems="center" key={index}>
          <Grid item xs={5}>
            <TextField
              label={i18n.contactName}
              disabled={loading}
              value={contact.name}
              fullWidth
              onChange={handleNameChange(index)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={i18n.phoneNumber}
              disabled={loading}
              value={contact.telecoms[0]?.value}
              fullWidth
              onChange={handlePhoneNumberChange(index)}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleRemove(index)} size="large">
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default TelecomInput;
