import { Area, Unit } from '@ambuliz/sabri-core';
import isAreaCurrentlyActive from '../isAreaCurrentlyActive';
import { getAreasDirectChildren, getUnitsWithAreas } from './getBedsByUnit.queries';

type AreaWithUnit = {
  unit: Unit;
  area: Area;
};

/*
 * Retrieve recursively all children of the given areas with their respective unit
 * @param areasWithUnits - List of areas with their respective unit
 * @returns Areas with their respective unit and children - { unit: Unit, area: Area }[]
 */
const getAreaWithUnitChildren = async (areasWithUnits: AreaWithUnit[]): Promise<AreaWithUnit[]> => {
  if (areasWithUnits.length === 0) {
    return [];
  }

  const areas = areasWithUnits.map(({ area }) => area);
  const areasWithPotentialChildren = areas.filter(({ externalTypeCode }) => externalTypeCode !== 'bed');

  if (areasWithPotentialChildren.length === 0) {
    return areasWithUnits;
  }

  const children = await getAreasDirectChildren(areasWithPotentialChildren);

  if (children.length === 0) {
    return areasWithUnits;
  }

  const childrenWithUnits = children.reduce((acc, child) => {
    const parent = areasWithUnits.find(({ area }) => area.id === child.parent?.id);
    return parent?.unit ? [...acc, { unit: parent.unit, area: child }] : acc;
  }, [] as AreaWithUnit[]);

  return [...areasWithUnits, ...(await getAreaWithUnitChildren(childrenWithUnits))];
};

/**
 * Retrieve an array of areas with their respective unit
 * @param unitIds - List of unit ids
 * @param filterInactive - Filter out currently inactive areas
 * @returns Areas by unit - { unit: Unit, area: Area }[]
 */
const getAreasByUnit = async (unitIds: string[], filterInactive = true) => {
  const units = await getUnitsWithAreas(unitIds);
  const areasWithUnits = units.flatMap((unit) => unit.areas?.map((area) => ({ unit, area })) || []);

  const areasByUnit = await getAreaWithUnitChildren(areasWithUnits);
  return filterInactive
    ? areasByUnit.filter(({ area }) => isAreaCurrentlyActive(area.availabilityPeriods))
    : areasByUnit;
};

/**
 * Retrieve an array of beds with their respective unit
 * @param unitIds - List of unit ids
 * @param filterInactive - Filter out currently inactive areas
 * @returns Beds by unit - { unit: Unit, bed: Area }[]
 */
export const getBedsByUnit = async (unitIds: string[], filterInactive = true) => {
  const areasWithUnits = await getAreasByUnit(unitIds, filterInactive);
  return areasWithUnits
    .filter(({ area }) => area.externalTypeCode === 'bed')
    .map(({ unit, area }) => ({ unit, bed: area }));
};
