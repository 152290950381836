import { Skeleton, Stack } from '@mui/material';

const NotificationCardSkeleton = () => (
  <Stack spacing={4} direction="row" alignItems="center" marginRight={8}>
    <Skeleton variant="circular" width={24} height={24} />
    <Stack spacing={2} flex={1}>
      <Skeleton height={16} variant="rectangular" style={{ borderRadius: 4 }} />
      <Skeleton height={16} variant="rectangular" style={{ borderRadius: 4 }} />
      <Skeleton height={16} variant="rectangular" style={{ borderRadius: 4 }} width="85%" />
    </Stack>
  </Stack>
);

export default NotificationCardSkeleton;
