import { SVGProps } from 'react';

const CheckboxRemoveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={11} height={11} rx={1.5} fill="#0D088F" stroke="#0D088F" />
    <rect x={2} y={5} width={8} height={2} rx={1} fill="#fff" />
  </svg>
);

export default CheckboxRemoveIcon;
