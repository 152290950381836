import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowDownIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M13.541 8.68a.75.75 0 0 1-.022 1.061l-5 4.8a.75.75 0 0 1-1.038 0l-5-4.8a.75.75 0 1 1 1.038-1.082L7.25 12.24V2a.75.75 0 0 1 1.5 0v10.24l3.73-3.581a.75.75 0 0 1 1.061.022Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default ArrowDownIcon;
