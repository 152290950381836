import { SvgIcon, SvgIconProps } from '@mui/material';

const Cancel: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.424 16 16 12.424 16 8C16 3.576 12.424 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4ZM11.436 4.564C11.1245 4.25251 10.6195 4.25251 10.308 4.564L8 6.872L5.692 4.564C5.38051 4.25251 4.87549 4.25251 4.564 4.564C4.25251 4.87549 4.25251 5.38051 4.564 5.692L6.872 8L4.564 10.308C4.25251 10.6195 4.25251 11.1245 4.564 11.436C4.87549 11.7475 5.38051 11.7475 5.692 11.436L8 9.128L10.308 11.436C10.6195 11.7475 11.1245 11.7475 11.436 11.436C11.7475 11.1245 11.7475 10.6195 11.436 10.308L9.128 8L11.436 5.692C11.7475 5.38051 11.7475 4.87549 11.436 4.564Z" />
    </SvgIcon>
  );
};

export default Cancel;
