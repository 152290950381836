import { Box, Divider, IconButton, ListItemText, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { PatientNameWithEmoji } from 'common/components';
import ActionButton from 'common/components/ActionButton';
import EllipsisTypography from 'common/components/EllipsisTypography';
import CancelCircleIcon from 'common/components/Icons/CancelCircle';
import ExitIcon from 'common/components/Icons/Exit';
import ListIcon from 'common/components/Icons/List';
import MoreVerticalIcon from 'common/components/Icons/MoreVertical';
import PathIcon from 'common/components/Icons/Path';
import PlusIcon from 'common/components/Icons/Plus';
import ProcessingIcon from 'common/components/Icons/Processing';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { formatAge, getPatientAge, getPatientName } from 'common/utils';
import { formatDistanceToNowStrict } from 'date-fns';
import { fr } from 'date-fns/locale';
import { AccommodationSpecificityBadges } from 'kurt/components';
import { PractitionersChip } from 'kurt/components/Practitioners';
import { useAccommodation } from 'kurt/hooks';
import { useEffect, useRef, useState } from 'react';
import EditableTableCell from '../../../components/EditableTableCell/EditableTableCell';
import {
  CancelAccommodationDialog,
  CreateHospitalizationRequestDialog,
  DischargePatientDialog,
  UpdateSpecificitiesDialog,
} from '../actions';
import { PresentPatient } from './usePresentPatients';

type PresentPatientsTableRowProps = {
  presentPatient: PresentPatient;
  isReadOnly?: boolean;
  onClick?: () => void;
};

export type PresentPatientAction =
  | 'CANCEL_ACCOMMODATION'
  | 'DISCHARGE_PATIENT'
  | 'REQUEST_HOSPITALIZATION'
  | 'UPDATE_SPECIFICITIES'
  | null;

type PresentPatientActionMenuProps = {
  hasActiveHospitalizationRequest?: boolean;
  onActionClick: (action: PresentPatientAction) => void;
};

const PresentPatientActionMenu = ({
  onActionClick,
  hasActiveHospitalizationRequest,
}: PresentPatientActionMenuProps) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen((open) => !open);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, action: PresentPatientAction) => {
    event.stopPropagation();
    onActionClick(action);
    setOpen(false);
  };

  return (
    <>
      <IconButton ref={buttonRef} onClick={toggleOpen}>
        <MoreVerticalIcon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={buttonRef.current}
        onClose={toggleOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!hasActiveHospitalizationRequest && (
          <MenuItem onClick={(event) => handleMenuItemClick(event, 'REQUEST_HOSPITALIZATION')}>
            <ListItemIcon>
              <PathIcon />
            </ListItemIcon>
            <ListItemText>{i18n.presentPatients.requestHospitalization}</ListItemText>
          </MenuItem>
        )}
        {!hasActiveHospitalizationRequest && <Divider />}
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'UPDATE_SPECIFICITIES')}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText>{i18n.visitActions.UPDATE_SPECIFICITIES.action.label}</ListItemText>
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'DISCHARGE_PATIENT')}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText>{i18n.visitActions.DISCHARGE_PATIENT.action.label}</ListItemText>
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'CANCEL_ACCOMMODATION')}>
          <ListItemIcon sx={{ color: palette.error.main }}>
            <CancelCircleIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: palette.error.main }}>{i18n.cancelAccommodation.actionLabel}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const PresentPatientsTableRow = ({
  presentPatient: { patient, accommodation, accommodationRequest },
  isReadOnly = false,
  onClick,
}: PresentPatientsTableRowProps) => {
  const [activeAction, setActiveAction] = useState<PresentPatientAction>(null);
  const hasActiveHospitalizationRequest = !!accommodationRequest;

  const { update, loading } = useAccommodation(accommodation.id);
  const [comment, setComment] = useState(accommodation.comment || '');

  useEffect(() => setComment(accommodation?.comment || ''), [accommodation?.comment]);

  const handleRequestHospitalizationClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setActiveAction('REQUEST_HOSPITALIZATION');
  };

  const handleActionClick = (action: PresentPatientAction) => {
    setActiveAction(action);
  };

  const handleCloseDialog = () => {
    setActiveAction(null);
  };

  const handleCommentSubmit = (value: string) => {
    if (value !== accommodation?.comment) {
      update({ comment: value });
    }
  };

  return (
    <>
      <TableRow hover sx={{ cursor: 'pointer' }} onClick={onClick}>
        <TableCell>
          <PatientNameWithEmoji
            age={getPatientAge(patient.birthdate)}
            name={getPatientName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName)}
            gender={patient.gender}
            bgcolor="primary.light"
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary">
            {formatAge(patient.birthdate)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary" fontWeight={600}>
            {formatDistanceToNowStrict(accommodation.startAt, { locale: fr })}
          </Typography>
        </TableCell>
        <TableCell>
          <EllipsisTypography tooltipProps={{ title: 'Lit ' + accommodation.bed?.name || '-' }} fontWeight={600}>
            {accommodation.bed?.name || '-'}
          </EllipsisTypography>
        </TableCell>
        <TableCell>
          <PractitionersChip practitioners={accommodation.practitioners?.map(({ name }) => name) || []} />
        </TableCell>
        <TableCell>
          <Box display="flex" sx={{ cursor: 'pointer' }}>
            <AccommodationSpecificityBadges specificities={accommodation.specificities || []} />
          </Box>
        </TableCell>
        <EditableTableCell
          value={comment}
          onChange={setComment}
          onSubmit={handleCommentSubmit}
          loading={loading}
          isReadOnly={isReadOnly}
        />

        <TableCell>
          {hasActiveHospitalizationRequest ? (
            <ActionButton icon={<ProcessingIcon />} disabled>
              {i18n.presentPatients.requestBeingProcessed}
            </ActionButton>
          ) : (
            !isReadOnly && (
              <ActionButton icon={<PlusIcon />} onClick={handleRequestHospitalizationClick}>
                {i18n.presentPatients.requestHospitalization}
              </ActionButton>
            )
          )}
        </TableCell>
        {!isReadOnly && (
          <TableCell>
            <PresentPatientActionMenu
              onActionClick={handleActionClick}
              hasActiveHospitalizationRequest={hasActiveHospitalizationRequest}
            />
          </TableCell>
        )}
      </TableRow>

      <CancelAccommodationDialog
        id={accommodation.id}
        open={activeAction === 'CANCEL_ACCOMMODATION'}
        onClose={handleCloseDialog}
      />
      <DischargePatientDialog
        accommodationId={accommodation.id}
        open={activeAction === 'DISCHARGE_PATIENT'}
        onClose={handleCloseDialog}
      />
      <UpdateSpecificitiesDialog
        id={accommodation.id}
        open={activeAction === 'UPDATE_SPECIFICITIES'}
        onClose={handleCloseDialog}
        specificities={accommodation.specificities}
      />
      <CreateHospitalizationRequestDialog
        open={activeAction === 'REQUEST_HOSPITALIZATION'}
        onClose={handleCloseDialog}
        visit={accommodation.visit}
        specificities={accommodation.specificities}
        comment={accommodation.comment}
      />
    </>
  );
};

export default PresentPatientsTableRow;
