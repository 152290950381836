import { Box, Stack, Typography } from '@mui/material';
import { PatientAutocomplete, PatientSearchResult } from 'common/components/PatientAutocomplete';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { i18n } from 'sabri/locales';
import IsValidIcon from './IsValidIcon';
import { formKeys } from './JourneyForm';
import usePatientSearchResult from './usePatientSearchResult';

const JourneyPatient: React.FC = () => {
  const { watch, setValue, errors, clearErrors } = useFormContext();
  const patient = usePatientSearchResult(watch<string, string | undefined>(formKeys.patientId));

  const handleChange = (value: PatientSearchResult | null) => {
    clearErrors(formKeys.patientId);
    setValue(formKeys.patientId, value?.id || '');
    setValue(formKeys.fromUnitId, value?.unit?.id || '');
    setValue(formKeys.fromAreaId, value?.area?.id || '');
  };

  return (
    <Controller
      name={formKeys.patientId}
      render={() => (
        <Stack direction="row" gap={4}>
          <Box width={54} height={40} display="flex" alignItems="center">
            <Typography variant="overline">{i18n.patient}</Typography>
          </Box>
          <Stack direction="row" justifyContent="space-between" flex={1} gap={2}>
            <PatientAutocomplete value={patient} onChange={handleChange} error={errors.patientId} />
            <Box style={{ marginLeft: 40, marginTop: 11 }}>
              <IsValidIcon isVisible={!!patient} />
            </Box>
          </Stack>
        </Stack>
      )}
    />
  );
};

export default JourneyPatient;
