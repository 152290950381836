import { Fare, Journey as ParseJourney } from '@ambuliz/sabri-core';
import { useMemo } from 'react';
import { i18n } from 'sabri/locales';
import { getJourneyFareChipLabel } from './getJourneyFareChipLabel';

const useJourney = (parseJourney: ParseJourney, fares: Fare[]) => {
  const journey: Journey = useMemo(() => {
    const journey: Journey = {
      roundTrip: parseJourney.roundTrip,
      startingUnitName: parseJourney.startingUnit.name,
      startingAreaName: parseJourney.startingArea?.name,
      fares: fares.map((fare, index) => ({
        id: fare.id,
        unitName: fare.toUnit.name,
        areaName: fare.toArea?.name,
        date: fare.endedAt,
        isOutwardFare: index === 0,
        isReturnFare: !!fare.isReturnTripFromFare,
        status: getJourneyFareStatus(fare),
        incident: getIncident(fare),
        chipLabel: '',
      })),
    };

    const total = journey.fares.filter((fare) => !fare.isOutwardFare && !fare.isReturnFare).length;
    for (let i = 0; i < journey.fares.length; i++) {
      const fare = journey.fares[i];
      fare.chipLabel = getJourneyFareChipLabel({
        isOutwardFare: fare.isOutwardFare,
        isReturnFare: fare.isReturnFare,
        index: i,
        total,
      });
    }

    return journey;
  }, [parseJourney, fares]);

  return { journey };
};

const getIncident = (fare: Fare) => {
  if (!fare.incidents || fare.incidents.length === 0) {
    return undefined;
  }

  return i18n.incidentReasons[fare.incidents[fare.incidents.length - 1].reason];
};

export type JourneyFareStatus = 'current' | 'past' | 'future';

const getJourneyFareStatus = (fare: Fare) => {
  if (fare.status) {
    if (['STARTED', 'PATIENT_CARE'].includes(fare.status)) {
      return 'current';
    }
    if (fare.status === 'DONE') {
      return 'past';
    }
  }
  return 'future';
};

type Journey = {
  roundTrip: boolean;
  startingUnitName: string;
  startingAreaName?: string;
  fares: JourneyFare[];
};

type JourneyFare = {
  id: string;
  status: JourneyFareStatus;
  date?: Date;
  unitName: string;
  areaName?: string;
  isReturnFare?: boolean;
  isOutwardFare?: boolean;
  incident?: string;
  chipLabel: string;
};

export default useJourney;
