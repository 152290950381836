import { HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import { Card, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import CopyIcon from 'common/components/Icons/Copy';
import EditIcon from 'common/components/Icons/Edit';
import HashtagIcon from 'common/components/Icons/Hashtag';
import SprayIcon from 'common/components/Icons/SprayIcon';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { format, formatDistanceToNow, isPast } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffect, useRef, useState } from 'react';
import { getStepSchedule } from '../../DetailsDialogContent';
import DetailsRow from '../../DetailsRow';
import DialogTabs from '../../DialogTabs';
import HorizontalDivider from '../../HorizontalDivider';

type HealthcareEnvironmentalCleaningDetailsProps = {
  healthcareEnvironmentalCleaning: HealthcareEnvironmentalCleaning;
};

const HealthcareEnvironmentalCleaningDetails = ({
  healthcareEnvironmentalCleaning: cleaning,
}: HealthcareEnvironmentalCleaningDetailsProps) => {
  const [requestReferenceTooltip, setRequestReferenceTooltip] = useState(i18n.copy);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      const timeout = timeoutRef.current;

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeoutRef]);

  const onRequestReferenceCopy = async () => {
    if (!cleaning.externalId || !navigator.clipboard) {
      return;
    }

    await navigator.clipboard.writeText(cleaning.externalId);
    setRequestReferenceTooltip(i18n.copied);
  };

  const isCleaningPast = isPast(cleaning.startAt);
  const since = formatDistanceToNow(cleaning.startAt, { locale: fr, includeSeconds: true });

  return (
    <Stack>
      <DialogTabs
        options={[
          {
            label: i18n.detailsDialog.tabs[getStepSchedule(cleaning, [])],
            Icon: <SprayIcon />,
            value: cleaning.id,
            iconColor: palette.primaryAlt.main,
          },
        ]}
        value={cleaning.id}
        onChange={() => {}}
      />
      <Card sx={{ borderTopLeftRadius: 0, marginTop: '-1px' }} elevation={1}>
        <Stack divider={<HorizontalDivider />}>
          <Stack direction="row" padding="24px 16px" gap={4} alignItems="center">
            <Stack direction="row" gap={2} alignItems="center">
              <EditIcon sx={{ fontSize: 12 }} color="secondary" />
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="body2" color="secondary" fontWeight={600}>
                  {i18n.createdAt}
                </Typography>
                <Typography variant="body2" color="secondary">
                  {format(cleaning.createdAt, 'dd/MM/yyyy')}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography variant="body2" fontWeight={600} color="secondary">
                {isCleaningPast ? i18n.since : i18n.in}
              </Typography>
              <Typography color="secondary" variant="body2">
                {since}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" padding={4}>
            <Stack spacing={4} flex={1}>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="body2" color="secondary" paddingX={2} minWidth={53}>
                  {i18n.start}
                </Typography>
                <Stack direction="row" gap={2} flex={1}>
                  <Typography padding="7px 8px">{format(cleaning.startAt, 'dd/MM/yyyy')}</Typography>
                  <Typography padding="7px 8px">{format(cleaning.startAt, 'HH : mm')}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="body2" color="secondary" paddingX={2} minWidth={53}>
                  {i18n.end}
                </Typography>
                <Stack direction="row" gap={2} flex={1}>
                  {cleaning.endAt ? (
                    <>
                      <Typography padding="7px 8px">{format(cleaning.endAt, 'dd/MM/yyyy')}</Typography>
                      <Typography padding="7px 8px">{format(cleaning.endAt, 'HH : mm')}</Typography>
                    </>
                  ) : (
                    <Typography padding="7px 8px">-</Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack padding={4}>
            <Stack spacing={2} paddingLeft={2}>
              <DetailsRow
                Icon={HashtagIcon}
                label={i18n.requestReference}
                value={
                  <Stack direction="row" alignItems="center" width="100%">
                    <Typography variant="body2" width="100%" padding={2}>
                      {cleaning.externalId}
                    </Typography>
                    {cleaning.externalId && (
                      <Tooltip
                        title={requestReferenceTooltip}
                        placement="top"
                        arrow={false}
                        onClose={() => {
                          timeoutRef.current = setTimeout(() => {
                            setRequestReferenceTooltip(i18n.copy);
                          }, 250);
                        }}
                      >
                        <IconButton onClick={onRequestReferenceCopy} variant="filled">
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default HealthcareEnvironmentalCleaningDetails;
