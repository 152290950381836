import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { i18n } from 'sabri/locales';

type StorelessModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  params: {
    title: string;
    content: React.ReactElement;
  };
};

const StorelessModal: React.FC<StorelessModalProps> = ({ isOpen, onClose, onConfirm, params }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle style={{ paddingBottom: 11 }}>{params.title}</DialogTitle>
      <DialogContent>
        <Box fontSize={14}>{params.content}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {i18n.cancel}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {i18n.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StorelessModal;
