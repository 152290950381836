import { Paper, Stack, Typography } from '@mui/material';
import { ArrowRight } from 'common/components/Icons';
import shadows from 'common/theme/shadows';
import { FC, ReactElement } from 'react';

type VisitActionButtonProps = {
  Icon?: ReactElement;
  label: string;
  disabled?: boolean;
  onClick: () => void;
};

const VisitActionButton: FC<VisitActionButtonProps> = ({ Icon, label, disabled = false, onClick }) => {
  return (
    <Paper
      onClick={() => !disabled && onClick()}
      sx={{ cursor: 'pointer', [`&:hover`]: { boxShadow: shadows[3] } }}
      elevation={1}
    >
      <Stack direction="row" spacing={4} alignItems="center" padding={2}>
        {Icon}
        <Typography variant="body2" align="left" flex={1}>
          {label}
        </Typography>
        <ArrowRight color="secondary" />
      </Stack>
    </Paper>
  );
};

export default VisitActionButton;
