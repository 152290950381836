import { Accommodation, Anomaly, DelayRule, HealthcareEnvironmentalCleaning, PatientGender } from '@ambuliz/sabri-core';
import { Area } from 'core/locations';
import { getBedGender, isAvailableOrBooked } from './useResources.helpers';
import { Resource } from './useResources.types';

export const mapResources = (
  beds: Area[],
  accommodations: Record<string, Accommodation>,
  anomalies: Record<string, Anomaly>,
  healthcareEnvironmentalCleanings: Record<string, HealthcareEnvironmentalCleaning>,
  date: Date,
  bookedBedDelayRule?: DelayRule
) => {
  const resources: Record<string, Resource> = {};
  for (const bed of beds) {
    resources[bed.id] = {
      bed: {
        id: bed.id,
        name: bed.name,
        gender: 'UNKNOWN',
        parentId: bed.parentId,
        status: isAvailableOrBooked(bed.nextSlotAt, bookedBedDelayRule),
        unitId: bed.unitId,
        unitName: bed.unitName,
        nextSlotAt: bed.nextSlotAt,
        excludedFromOccupancy: bed.excludedFromOccupancy,
      },
      anomalies: [],
      accommodations: [],
      healthcareEnvironmentalCleanings: [],
    };
  }

  for (const healthcareEnvironmentalCleaning of Object.values(healthcareEnvironmentalCleanings)) {
    const area = healthcareEnvironmentalCleaning.location;
    if (area && resources[area.id]) {
      const resource = resources[area.id];
      if (
        healthcareEnvironmentalCleaning.startAt < date &&
        (!healthcareEnvironmentalCleaning.endAt || healthcareEnvironmentalCleaning.endAt > date)
      ) {
        resource.healthcareEnvironmentalCleaning = healthcareEnvironmentalCleaning;
        resource.bed.status = 'CLEANING';
      }
      resource.healthcareEnvironmentalCleanings?.push(healthcareEnvironmentalCleaning);
    }
  }

  for (const accommodation of Object.values(accommodations)) {
    const bed = accommodation.bed;
    if (bed && resources[bed.id]) {
      const resource = resources[bed.id];

      if (accommodation.startAt < date && (!accommodation.endAt || accommodation.endAt > date)) {
        let currentAccommodation = resource.accommodation;
        if (!currentAccommodation || currentAccommodation.startAt > accommodation.startAt) {
          resource.accommodation = accommodation;
          resource.bed.gender = accommodation.visit?.gender || 'UNKNOWN';
          resource.bed.status = accommodation.visit ? 'BUSY' : 'CLOSED';
        }
      }
      resource.accommodations.push(accommodation);
    }
  }

  for (const anomaly of Object.values(anomalies)) {
    if (anomaly.area?.id && resources[anomaly.area.id]) {
      const resource = resources[anomaly.area.id];
      resource.anomalies = [...(resource.anomalies || []), anomaly];

      const accommodation = anomaly.accommodation;
      if (accommodation && accommodation.status !== 'CANCELLED') {
        if (accommodation.startAt < date && (!anomaly.endAt || anomaly.endAt > date)) {
          let currentAccommodation = resource.accommodation;
          if (!currentAccommodation || currentAccommodation.startAt > anomaly.startAt) {
            resource.accommodation = accommodation;
            resource.bed.gender = accommodation.visit?.gender || 'UNKNOWN';
            resource.bed.status = accommodation.visit ? 'BUSY' : 'CLOSED';
          }
        }
        if (
          !resource.accommodations.find((accommodation) => accommodation.id === anomaly.accommodation?.id) &&
          anomaly.area.id === anomaly.accommodation?.bed?.id
        ) {
          resource.accommodations.push(accommodation);
        }
      }
    }
  }

  const gendersByRoom: Record<string, PatientGender[]> = {};

  for (const resource of Object.values(resources)) {
    const accommodation = resource.accommodation;
    const roomId = resource.bed.parentId;
    const gender = accommodation?.visit?.gender;

    if (roomId && gender) {
      const accommodations = gendersByRoom[roomId] || [];
      gendersByRoom[roomId] = [...accommodations, gender];
    }
  }

  for (const resource of Object.values(resources)) {
    if (resource.bed.gender === 'UNKNOWN') {
      const roomId = resource.bed.parentId;
      if (roomId) {
        resource.bed.gender = getBedGender(gendersByRoom[roomId]);
      }
    }
  }

  return Object.values(resources).sort((a, b) => a.bed.name.localeCompare(b.bed.name, undefined, { numeric: true }));
};
