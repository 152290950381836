import { InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search } from '@mui/icons-material';
import React, { useState } from 'react';
import { i18n } from '../../locales';
import { FilterItem } from '../FiltersController/FiltersController';
import { SelectorController } from '../SelectorController/SelectorController';

type SelectorTextFieldProps = {
  title: string;
  onSubmit: (items: FilterItem[]) => void;
  onClose: () => void;
};

export const SelectorTextField: React.FC<SelectorTextFieldProps> = ({ title, onSubmit, onClose }) => {
  const [filterSearch, setFilterSearch] = useState('');
  const classes = useClasses();

  const renderSelector = ({
    setItemsSelected,
    setIsSelectorVisible,
  }: {
    setIsSelectorVisible: (value: boolean) => void;
    setItemsSelected: React.Dispatch<React.SetStateAction<FilterItem[]>>;
  }) => {
    const handleClickSubmit = () => {
      setItemsSelected([{ id: filterSearch, label: filterSearch }]);
      setIsSelectorVisible(false);
      setFilterSearch('');
      onSubmit([{ id: filterSearch, label: filterSearch }]);
    };

    return (
      <>
        <TextField
          placeholder={i18n.search}
          onChange={(e) => setFilterSearch(e.target.value)}
          InputProps={{
            className: classes.textField,
            startAdornment: (
              <InputAdornment position="start">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.button} onClick={handleClickSubmit}>
          {i18n.submit}
        </div>
      </>
    );
  };

  return (
    <SelectorController title={title} onClose={onClose}>
      {renderSelector}
    </SelectorController>
  );
};

const useClasses = makeStyles({
  button: {
    width: '100%',
    color: '#1A73E8',
    fontSize: 14,
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: '1px solid #EEEEEE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textField: {
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  searchIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
});
