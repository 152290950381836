import { Directions, DoneAll, PersonPinCircle } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';
import { i18n } from '../../locales';

type PorterAssignationsTableProps = {
  isReadOnly?: boolean;
  isEmpty?: boolean;
  emptyCaption?: string;
  children?: ReactNode;
};

export const PorterAssignationsTable: React.FC<PorterAssignationsTableProps> = ({
  children,
  isReadOnly,
  isEmpty,
  emptyCaption,
}) => {
  const classes = useClasses();
  return (
    <TableContainer className={classes.table}>
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>{i18n.porters}</TableCell>
            <TableCell>{i18n.status}</TableCell>
            <TableCell>
              <Tooltip title={i18n.lastArea}>
                <PersonPinCircle />
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title={i18n.nextArea}>
                <Directions />
              </Tooltip>
            </TableCell>
            <TableCell>
              <Tooltip title={i18n.porterFaresDoneAndAssigned}>
                <DoneAll />
              </Tooltip>
            </TableCell>
            {!isReadOnly && <TableCell>{i18n.actions}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
        {isEmpty && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="body1" color="textSecondary">
                  {emptyCaption}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

const useClasses = makeStyles({
  table: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    '& .MuiTableCell-root': {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  },
});
