import { SvgIcon, SvgIconProps } from '@mui/material';

const HospitalBedroom: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M4.66672 8.78992C5.43378 8.78992 6.05561 8.1681 6.05561 7.40103C6.05561 6.63397 5.43378 6.01215 4.66672 6.01215C3.89966 6.01215 3.27783 6.63397 3.27783 7.40103C3.27783 8.1681 3.89966 8.78992 4.66672 8.78992Z" />
      <path d="M14.1113 7.12302C13.8044 7.12302 13.5557 7.37175 13.5557 7.67858V9.48413C13.5557 9.56084 13.4935 9.62302 13.4168 9.62302H2.5835C2.50679 9.62302 2.44461 9.56084 2.44461 9.48413V6.28969C2.44461 5.98286 2.19588 5.73413 1.88905 5.73413C1.58223 5.73413 1.3335 5.98286 1.3335 6.28969V11.7064C1.3335 12.0132 1.58223 12.2619 1.88905 12.2619C2.19588 12.2619 2.44461 12.0132 2.44461 11.7064V11.4286C2.44461 11.3519 2.50679 11.2897 2.5835 11.2897H13.4168C13.4935 11.2897 13.5557 11.3519 13.5557 11.4286V11.7064C13.5557 12.0132 13.8044 12.2619 14.1113 12.2619C14.4181 12.2619 14.6668 12.0132 14.6668 11.7064V7.67858C14.6668 7.37175 14.4181 7.12302 14.1113 7.12302Z" />
      <path d="M6.88916 7.12337V8.51225C6.88916 8.66567 7.01353 8.79003 7.16694 8.79003H12.4447C12.5981 8.79003 12.7225 8.66567 12.7225 8.51225V7.9567C12.7225 7.58834 12.5762 7.23507 12.3157 6.97461C12.0552 6.71414 11.702 6.56781 11.3336 6.56781H7.44472C7.13789 6.56781 6.88916 6.81654 6.88916 7.12337Z" />
    </SvgIcon>
  );
};

export default HospitalBedroom;
