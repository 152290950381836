import { Cloud, getAvailableBeds } from '@ambuliz/sabri-core';
import { Collapse, Grid, IconButton, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { ChevronSmallDown, ChevronSmallRight, Clock } from 'common/components/Icons';
import { InOutFlow } from 'common/components/Statistics';
import { color } from 'common/theme';
import shadows from 'common/theme/shadows';
import { hyphenIfUndefined, progressValue } from 'common/utils';
import { isBefore } from 'date-fns';
import BedsAvailabilities from 'kurt/components/Department/Table/BedsAvailabilities';
import CustomGrid from 'kurt/components/Department/Table/CustomGrid';
import OccupancyInfos from 'kurt/components/Department/Table/OccupancyInfos';
import { OUTDATED_UPDATED_AT, getThresholdedColor } from 'kurt/const';
import { memo, useState } from 'react';
import NestedListItem from './NestedListItem';

type ListItemProps = Cloud.HealthCenterRegionData & {
  displayUpdatedAtColumn: boolean;
  columns: number[];
  isMedium: boolean;
};

const ListItem: React.FC<ListItemProps> = ({
  name,
  occupancy,
  departments,
  displayUpdatedAtColumn,
  columns,
  isMedium,
}) => {
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <Grid
      style={{ padding: '16px 0 16px 16px' }}
      component={Paper}
      container
      sx={{
        [`&:hover`]: {
          boxShadow: shadows[4],
        },
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        onClick={(event) => {
          event.stopPropagation();
          setOpenDetails(!openDetails);
        }}
        style={{ cursor: 'pointer' }}
        spacing={4}
      >
        <CustomGrid item percentile={columns[0]}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item xs>
              <Stack direction="row" gap={1} alignItems="center">
                <IconButton onClick={() => setOpenDetails(!openDetails)} edge="start">
                  {openDetails ? <ChevronSmallDown /> : <ChevronSmallRight />}
                </IconButton>
                <EllipsisTypography fontWeight={600} maxLines={2}>
                  {name}
                </EllipsisTypography>
              </Stack>
            </Grid>
          </Grid>
        </CustomGrid>
        <CustomGrid item percentile={columns[1]}>
          <Grid container alignItems="center" spacing={2}>
            <Grid sx={{ width: 48 }} item>
              <LinearProgress
                value={progressValue(occupancy?.rate)}
                color={getThresholdedColor(occupancy?.rate, 'secondary')}
              />
            </Grid>
            <Grid item sx={{ minWidth: 36 }}>
              <Typography>{hyphenIfUndefined(occupancy?.rate)}%</Typography>
            </Grid>
            <Grid item>
              <OccupancyInfos occupancy={occupancy} />
            </Grid>
          </Grid>
        </CustomGrid>

        <CustomGrid item percentile={columns[2]}>
          <InOutFlow admission={occupancy?.admission} discharge={occupancy?.discharge} />
        </CustomGrid>
        <CustomGrid item percentile={columns[3]}>
          <BedsAvailabilities
            {...occupancy?.dDayAvailabilities}
            total={getAvailableBeds(
              occupancy?.dDayAvailabilities?.male,
              occupancy?.dDayAvailabilities?.female,
              occupancy?.dDayAvailabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>

        <CustomGrid item percentile={columns[4]}>
          <BedsAvailabilities
            {...occupancy?.h24Availabilities}
            total={getAvailableBeds(
              occupancy?.h24Availabilities?.male,
              occupancy?.h24Availabilities?.female,
              occupancy?.h24Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        <CustomGrid item percentile={columns[5]}>
          <BedsAvailabilities
            {...occupancy?.h48Availabilities}
            total={getAvailableBeds(
              occupancy?.h48Availabilities?.male,
              occupancy?.h48Availabilities?.female,
              occupancy?.h48Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        {displayUpdatedAtColumn && (
          <CustomGrid item percentile={columns[6]} container justifyContent="flex-end" paddingRight={4}>
            {hasOutdatedUpdatedAt(departments) && <Clock color="warning" />}
          </CustomGrid>
        )}
      </Grid>

      {departments.length && (
        <Collapse in={openDetails} style={{ width: '100%' }}>
          <Grid
            container
            direction="column"
            spacing={4}
            style={{ borderTop: `1px solid ${color.grey[5]}`, marginTop: 14, marginBottom: -16 }}
          >
            {departments?.map((department) => {
              return (
                <NestedListItem
                  {...department}
                  key={department.name}
                  units={[]}
                  displayUpdatedAtColumn={displayUpdatedAtColumn}
                  columns={columns}
                  isMedium={isMedium}
                />
              );
            })}
          </Grid>
        </Collapse>
      )}
    </Grid>
  );
};

export default memo(ListItem);

const hasOutdatedUpdatedAt = (departments: Cloud.DepartmentRegionData[]) =>
  departments.some(({ occupancy }) => occupancy?.updatedAt && isBefore(occupancy.updatedAt, OUTDATED_UPDATED_AT));
