import { ButtonBase, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/system';
import { AddIcon, RemoveIcon } from 'common/components/Icons';
import { theme } from 'common/theme';
import React from 'react';

const IncrementButton = styled(ButtonBase)`
  background-color: ${theme.palette.primary.light};
  color: ${theme.palette.secondary.main};
  border-radius: 4px;
  padding: 4px;
`;

const StyledTextField = styled(TextField)`
  width: 104px;
  text-align: center;
`;

type UsedTextFieldProps = Pick<TextFieldProps, 'disabled' | 'name'>;

export type InputIncrementProps = {
  value?: number;
  onChange: (value: number) => void;
  allowNegativeValue?: boolean;
  min?: number;
  max?: number;
} & UsedTextFieldProps;

const InputIncrement: React.FC<InputIncrementProps> = ({
  value,
  onChange,
  allowNegativeValue = false,
  min,
  max,
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
  };
  const increment = () => {
    const newValue = (value || 0) + 1;

    if (newValue <= (max || Number.MAX_SAFE_INTEGER)) {
      onChange(newValue);
    }
  };
  const decrement = () => {
    const newValue = (value || 0) - 1;

    if ((allowNegativeValue || newValue >= 0) && newValue >= (min || Number.MIN_SAFE_INTEGER)) {
      onChange(newValue);
    }
  };
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();
  return (
    <StyledTextField
      onFocus={handleFocus}
      type="number"
      variant="outlined"
      value={value || 0}
      fullWidth
      onChange={handleChange}
      inputProps={{ style: { textAlign: 'center' } }}
      InputProps={{
        startAdornment: (
          <IncrementButton onClick={decrement}>
            <RemoveIcon style={{ fontSize: 11 }} />
          </IncrementButton>
        ),
        endAdornment: (
          <IncrementButton onClick={increment}>
            <AddIcon style={{ fontSize: 11 }} />
          </IncrementButton>
        ),
      }}
      {...rest}
    />
  );
};

export default InputIncrement;
