import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { useAppBarContext } from 'core/layout';
import { ManagerIcon } from 'core/layout/components/AppBar';
import useNavigableUnits from 'core/locations/useNavigableUnits';
import { useEffect, useMemo } from 'react';

const useMovementsPageAppBar = (unitIds: string[]) => {
  const { setAppBarSelect } = useAppBarContext();
  const { healthCenter, user } = useAuthentication();
  const { units, loading, departments } = useNavigableUnits();

  const label = useMemo(
    () => (units.length > 1 ? healthCenter?.name || '' : units[0]?.name || ''),
    [units, healthCenter]
  );

  const subLabel = useMemo(() => {
    if (units.length > 1) {
      const unit = units.find((u) => u.id === unitIds[0]);
      if (unitIds.length > 1) {
        return `${unitIds.length} ${i18n.selectedUnits}`;
      }
      return unit?.name || '';
    }
    return healthCenter?.name || '';
  }, [units, unitIds, healthCenter]);

  useEffect(() => {
    if (units.length === 0 || loading) {
      setAppBarSelect(undefined);
    } else if (units.length === 1) {
      setAppBarSelect({
        value: units[0].name,
        subLabel: user.role === 'MANAGER' ? `${i18n.unit} • ${healthCenter?.name}` : label,
        icon: user.role === 'MANAGER' ? <ManagerIcon /> : undefined,
      });
    } else {
      setAppBarSelect({
        multiple: true,
        options: departments
          .filter(({ units }) => units.some(({ type }) => type === 'ACCOMMODATION'))
          .map(({ name, units }) => ({
            id: name,
            label: name,
            children: units
              .filter(({ type }) => type === 'ACCOMMODATION')
              .map(({ id, name }) => ({ id, label: name, children: [] })),
          })),
        value: unitIds,
        label,
      });
    }
  }, [units, unitIds, setAppBarSelect, healthCenter, label, subLabel, loading, departments, user.role]);
};

export default useMovementsPageAppBar;
