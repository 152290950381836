import { Components } from '@mui/material';

const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

export default MuiFormControlLabel;
