import { Box, Typography } from '@mui/material';
import { color as themeColor } from 'common/theme';
import { format } from 'date-fns';
import { useContext, useEffect, useRef, useState } from 'react';
import TimelineContext from '../TimelineContext';

const CIRCLE_MARKER_SIZE = 16;
const LINE_MARKER_SIZE = 2;

const TimeMarker = ({
  color = 'error',
  position = 0,
  time,
}: {
  color?: 'error' | 'primary';
  position?: number;
  time?: Date;
}) => {
  const { variant } = useContext(TimelineContext);
  const paletteColor = color === 'error' ? themeColor.red : themeColor.blue;
  const ref = useRef<HTMLDivElement>(null);

  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setTextWidth(ref.current.getBoundingClientRect().width);
    }
  }, [position]);

  return (
    <>
      {time && (
        <Typography
          variant="overline"
          color={color}
          position="absolute"
          zIndex={5}
          bgcolor={themeColor.blue[5]}
          sx={{
            transform: `translate3d(${position - textWidth / 2 + LINE_MARKER_SIZE / 2}px,${
              -CIRCLE_MARKER_SIZE - 10
            }px, 0)`,
          }}
          top={0}
          padding="0 2px"
          ref={ref}
        >
          {format(time, 'HH:mm')}
        </Typography>
      )}
      <Box
        position="absolute"
        top={0}
        width={CIRCLE_MARKER_SIZE}
        height={CIRCLE_MARKER_SIZE}
        zIndex={5}
        borderRadius="50%"
        sx={{ transform: `translate3d(${position - CIRCLE_MARKER_SIZE / 2 + LINE_MARKER_SIZE / 2}px,4px, 0)` }}
        bgcolor={paletteColor[60]}
        border={`2px solid ${paletteColor[30]}`}
        marginY={-2}
      />
      <Box
        position="absolute"
        sx={{ transform: `translate3d(${position}px,0, 0)` }}
        top={0}
        bottom={variant === 'card' ? 12 : 0}
        width={LINE_MARKER_SIZE}
        bgcolor={paletteColor[30]}
        zIndex={2}
      ></Box>
    </>
  );
};

export default TimeMarker;
