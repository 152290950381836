import { Accommodation } from '@ambuliz/sabri-core';
import { Stack, Typography, TypographyProps } from '@mui/material';
import { Emoji } from 'common/components';
import { color, palette } from 'common/theme';
import { format } from 'date-fns';

type AccommodationStartAtHistoryProps = {
  accommodation?: Accommodation;
  typographyProps?: TypographyProps;
};

export const AccommodationStartAtHistory = ({ accommodation, typographyProps }: AccommodationStartAtHistoryProps) => {
  const history =
    accommodation?.startAtHistory?.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()).slice(0, 2) || [];

  return (
    <Stack direction="column-reverse" gap={1}>
      {accommodation && history.length ? (
        <>
          <Stack direction="row" spacing={1} alignItems="center">
            <Emoji name="bellOrange" size="extra-small" />
            <Typography variant="body2" color={palette.danger.main} fontWeight={600} noWrap>
              {format(accommodation.startAt, 'dd/MM - HH:mm')}
            </Typography>
          </Stack>
          {history.map((history, index) => (
            <Typography
              variant="body2"
              color={index === 0 ? color.grey[20] : color.grey[10]}
              fontWeight={600}
              noWrap
              sx={{
                textDecorationLine: 'line-through',
              }}
              {...typographyProps}
            >
              {format(history.startAt, 'dd/MM - HH:mm')}
            </Typography>
          ))}
        </>
      ) : (
        <Typography variant="body2" color="secondary" fontWeight={600} noWrap {...typographyProps}>
          {!!accommodation && format(accommodation?.startAt, 'dd/MM - HH:mm')}
        </Typography>
      )}
    </Stack>
  );
};
