"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PORTER_END_OF_SERVICE_DEFAULT_CONFIGURATION = exports.PORTER_START_OF_SERVICE_DEFAULT_CONFIGURATION = void 0;
exports.PORTER_START_OF_SERVICE_DEFAULT_CONFIGURATION = {
    isAcknowledgeNeeded: false,
    notificationsProviders: ['WEB'],
    triggerEvent: 'PORTER_START_OF_SERVICE',
};
exports.PORTER_END_OF_SERVICE_DEFAULT_CONFIGURATION = {
    isAcknowledgeNeeded: false,
    notificationsProviders: ['WEB'],
    triggerEvent: 'PORTER_END_OF_SERVICE',
};
