import { useContext } from 'react';
import { LayoutContext } from './LayoutContext';

const useLayout = () => {
  const context = useContext(LayoutContext);

  return { container: context?.containerRef?.current || document.body };
};

export default useLayout;
