import { SvgIcon, SvgIconProps } from '@mui/material';

const CollapseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_97_14561)">
      <path
        d="M15.4419 0.558058C15.686 0.802136 15.686 1.19786 15.4419 1.44194L12.5089 4.375H14.2C14.5452 4.375 14.825 4.65482 14.825 5C14.825 5.34518 14.5452 5.625 14.2 5.625H11C10.6548 5.625 10.375 5.34518 10.375 5V1.8C10.375 1.45482 10.6548 1.175 11 1.175C11.3452 1.175 11.625 1.45482 11.625 1.8V3.49112L14.5581 0.558058C14.8021 0.313981 15.1979 0.313981 15.4419 0.558058ZM1.8 10.375C1.45482 10.375 1.175 10.6548 1.175 11C1.175 11.3452 1.45482 11.625 1.8 11.625H3.49112L0.558058 14.5581C0.313981 14.8021 0.313981 15.1979 0.558058 15.4419C0.802136 15.686 1.19786 15.686 1.44194 15.4419L4.375 12.5089V14.2C4.375 14.5452 4.65482 14.825 5 14.825C5.34518 14.825 5.625 14.5452 5.625 14.2V11C5.625 10.6548 5.34518 10.375 5 10.375H1.8ZM11 10.375C10.6548 10.375 10.375 10.6548 10.375 11V14.2C10.375 14.5452 10.6548 14.825 11 14.825C11.3452 14.825 11.625 14.5452 11.625 14.2V12.5089L14.5581 15.4419C14.8021 15.686 15.1979 15.686 15.4419 15.4419C15.686 15.1979 15.686 14.8021 15.4419 14.5581L12.5089 11.625H14.2C14.5452 11.625 14.825 11.3452 14.825 11C14.825 10.6548 14.5452 10.375 14.2 10.375H11ZM1.44194 0.558058C1.19786 0.313981 0.802136 0.313981 0.558058 0.558058C0.313981 0.802136 0.313981 1.19786 0.558058 1.44194L3.49112 4.375H1.8C1.45482 4.375 1.175 4.65482 1.175 5C1.175 5.34518 1.45482 5.625 1.8 5.625H5C5.34518 5.625 5.625 5.34518 5.625 5V1.8C5.625 1.45482 5.34518 1.175 5 1.175C4.65482 1.175 4.375 1.45482 4.375 1.8V3.49112L1.44194 0.558058Z"
        fill="#666D8E"
      />
    </g>
    <defs>
      <clipPath id="clip0_97_14561">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CollapseIcon;
