import { SVGProps } from 'react';

const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.03 3.03a.75.75 0 0 0-1.06-1.06L8 6.94 3.03 1.97a.75.75 0 0 0-1.06 1.06L6.94 8l-4.97 4.97a.75.75 0 1 0 1.06 1.06L8 9.06l4.97 4.97a.75.75 0 1 0 1.06-1.06L9.06 8l4.97-4.97Z"
      fill="#666D8E"
    />
  </svg>
);

export default Close;
