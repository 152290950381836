import { useAuthentication } from 'core/authentication';
import { useLocations } from 'core/locations';
import { useMemo } from 'react';
import { Unit } from 'superadmin/types';

const useEmergencyUnits = () => {
  const { user, unit, unitType, healthCenter } = useAuthentication();
  const { loading, units: allUnits } = useLocations();

  const units = useMemo(
    () =>
      user.role === 'MANAGER'
        ? unitType === 'EMERGENCY' ||
          (unitType === 'ACCOMMODATION' && !!unit && !!allUnits.find(({ id }) => id === unit.id)?.isEmergency)
          ? [{ ...unit, type: unitType } as Unit]
          : []
        : allUnits.filter(({ type, isEmergency }) => type === 'EMERGENCY' || (type === 'ACCOMMODATION' && isEmergency)),
    [user.role, unitType, unit, allUnits]
  );

  return {
    loading,
    units,
    healthCenter,
  };
};

export default useEmergencyUnits;
