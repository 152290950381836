import Api, { Fare, FareAssignation, User, WebUserRole } from '@ambuliz/sabri-core';
import { liveQueryClient } from 'core/live-query-client';
import { useEffect, useState } from 'react';
import { i18n } from 'sabri/locales';
import { FareHistoryEvent } from './FareHistory';

const useFareHistory = (fareId: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [events, setEvents] = useState<FareHistoryEvent[]>([]);

  useEffect(() => {
    let fareSubscription: Parse.LiveQuerySubscription;
    let assignationsSubscription: Parse.LiveQuerySubscription;

    const updateFareHistory = async () => {
      setError(false);
      try {
        setEvents(await getFareHistory(fareId));
      } catch (error) {
        setError(true);
      }
    };

    const subscribe = async () => {
      setLoading(true);
      setError(false);

      try {
        fareSubscription = liveQueryClient.subscribe(
          new Api.Query(Fare).equalTo('objectId', fareId),
          Parse.User.current()?.getSessionToken()
        );
        const fare = new Fare();
        fare.id = fareId;
        assignationsSubscription = liveQueryClient.subscribe(
          new Api.Query(FareAssignation).equalTo('fare', fare),
          Parse.User.current()?.getSessionToken()
        );

        fareSubscription.on('open', updateFareHistory);
        fareSubscription.on('update', updateFareHistory);
        assignationsSubscription.on('enter', updateFareHistory);
        assignationsSubscription.on('leave', updateFareHistory);
        assignationsSubscription.on('update', updateFareHistory);
      } catch (error) {
        setError(true);
      }

      setLoading(false);
    };

    subscribe();

    return () => {
      liveQueryClient.unsubscribe(fareSubscription);
      liveQueryClient.unsubscribe(assignationsSubscription);
    };
  }, [fareId]);

  return {
    events,
    loading,
    error,
  };
};

const getFareHistory = async (fareId: string) => {
  const events: FareHistoryEvent[] = [];
  const fare = await new Api.Query(Fare)
    .include('createdBy', 'canceledBy', 'incidents', 'incidents.createdBy')
    .get(fareId);

  events.push(createEvent(fare.createdAt, i18n.fareHistory.fareCreatedBy(fare.createdBy?.username)));
  if (fare.patientCareAt) {
    events.push(createEvent(fare.patientCareAt, i18n.fareHistory.patientCare));
  }
  if (fare.startedAt) {
    events.push(createEvent(fare.startedAt, i18n.fareHistory.fareStarted));
  }
  if (fare.canceledAt) {
    events.push(createEvent(fare.canceledAt, i18n.fareHistory.fareCanceledBy(getRoleName(fare.canceledBy))));
  }
  if (fare.endedAt) {
    events.push(createEvent(fare.endedAt, i18n.fareHistory.fareCompleted));
  }

  if (fare.incidents) {
    fare.incidents.forEach((incident) => {
      const name = incident.createdByName
        ? incident.createdByName
        : incident.createdBy
          ? getRoleName(incident.createdBy)
          : undefined;
      events.push(createEvent(incident.createdAt, i18n.fareHistory.incidentReportedBy(name)));
    });
  }

  const assignations = await new Api.Query(FareAssignation).include('porter').equalTo('fare', fare).findAll();
  assignations.forEach((assignation) => {
    const porterName = `${assignation.porter.firstName} ${assignation.porter.lastName[0]}.`;
    events.push(createEvent(assignation.createdAt, i18n.fareHistory.fareAssignedTo(porterName)));
    if (assignation.acknowledgedAt) {
      events.push(createEvent(assignation.acknowledgedAt, i18n.fareHistory.fareAcknowledgedBy(porterName)));
    }
  });

  events.sort((a, b) => (a.date < b.date ? 1 : -1));
  return events;
};

const getRoleName = (user?: User) => {
  if (user && user.role) {
    const role = user.role as WebUserRole;
    const roleName = i18n.roles[role];
    return roleName ? roleName.toLowerCase() : '';
  }
  return '';
};

const createEvent = (date: Date, content: string) => {
  return { date, content };
};

export default useFareHistory;
