import { Dialog, Link, Stack, Typography } from '@mui/material';
import { Alert, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useSupport } from 'core/support/useSupport';
import { startOfMinute } from 'date-fns';
import { AccommodationToUpsert, useAccommodation } from 'kurt/hooks';
import { useState } from 'react';
import CreateEmergencyAdmissionForm from './CreateEmergencyAdmissionForm';

type CreateEmergencyAdmissionDialogContentProps = {
  onClose: () => void;
  onSuccess: () => void;
  onBack?: () => void;
  accommodation?: {
    id?: string;
    startAt?: Date;
    endAt?: Date | null;
    bedId?: string;
    unitId?: string;
    isEndEstimated?: boolean;
  };
};

type CreateEmergencyAdmissionDialogProps = CreateEmergencyAdmissionDialogContentProps & {
  open: boolean;
};

const getAccommodationSuccessMessage = () => {
  return {
    title: i18n.planNewAdmissionSuccessToast.title,
    message: i18n.planNewAdmissionSuccessToast.accommodationMessage,
    icon: <Emoji name="okHand" size={24} />,
  };
};

const AddAccommodationDialogContent = ({
  onClose,
  onSuccess,
  onBack,
  accommodation,
}: CreateEmergencyAdmissionDialogContentProps) => {
  const initialAccommodation = {
    startAt: startOfMinute(new Date()),
    isEndEstimated: true,
    ...accommodation,
  };

  const { openSupport } = useSupport();
  const { create, loading } = useAccommodation();
  const [error, setError] = useState(false);

  const handleSubmit = async (accommodation: AccommodationToUpsert) => {
    setError(false);
    try {
      await create(accommodation);

      toast.success(getAccommodationSuccessMessage());
      onSuccess();
    } catch (error) {
      setError(true);
    }
  };

  return (
    <CreateEmergencyAdmissionForm
      initialAccommodation={initialAccommodation}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onBack={onBack}
      loading={loading}
      error={
        error ? (
          <Alert
            title={i18n.dialogError.title}
            severity="error"
            content={
              <Stack spacing={4}>
                <Typography variant="body1" color="secondary">
                  {i18n.dialogError['messageStay']}
                </Typography>
                <Typography variant="body1" color="secondary">
                  {i18n.dialogError.contactUsFirstPart}
                  <Link
                    component="button"
                    color="secondary"
                    sx={{ verticalAlign: 'baseline', fontWeight: 600 }}
                    onClick={openSupport}
                  >
                    {i18n.dialogError.contactUsLink}
                  </Link>
                  {i18n.dialogError.contactUsLastPart}
                </Typography>
              </Stack>
            }
            icon={<Emoji name="faceWithColdSweat" size={24} />}
          />
        ) : undefined
      }
    />
  );
};

const AddAccommodationDialog: React.FC<CreateEmergencyAdmissionDialogProps> = ({
  open,
  onClose,
  onBack,
  accommodation,
  onSuccess,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <AddAccommodationDialogContent
        accommodation={accommodation}
        onSuccess={onSuccess}
        onClose={onClose}
        onBack={onBack}
      />
    </Dialog>
  );
};

export default AddAccommodationDialog;
