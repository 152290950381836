import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ComfortBedIcon from 'common/components/Icons/ComfortBed';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { format } from 'date-fns';
import DialogTabs from '../../DialogTabs';
import { DialogTabOption } from '../../DialogTabs/DialogTabs';
import HorizontalDivider from '../../HorizontalDivider';
import { AvailableBedStep } from '../../context/Step';

type AvailableBedDetailsProps = {
  availableBedStep: AvailableBedStep;
};

export const AvailableBedDetails = ({ availableBedStep }: AvailableBedDetailsProps) => {
  const { end, step: availableBed } = availableBedStep;

  const options: DialogTabOption[] = [
    {
      label: i18n.detailsDialog.tabs.current,
      value: availableBed.id,
      Icon: <ComfortBedIcon />,
      iconColor: color.green[50],
    },
  ];

  return (
    <Stack>
      <Stack direction="row">
        <DialogTabs options={options} value={availableBed.id} onChange={() => {}} />
      </Stack>
      <Card sx={{ borderTopLeftRadius: 0, marginTop: '-1px', height: '100%' }} elevation={1}>
        <Stack divider={<HorizontalDivider />} flexGrow={1}>
          <Stack padding={4} spacing={4}>
            <Stack direction="row" spacing={2} padding={2} justifyContent="space-between">
              <Typography variant="body2" fontWeight={600} color="secondary">
                {i18n.until}
              </Typography>
              {end ? (
                <Stack direction="row" spacing={6} alignItems="center">
                  <Typography color="black" variant="body1">
                    {format(end, 'dd/MM/yyyy')}
                  </Typography>
                  <Typography color="black" variant="body1">
                    {format(end, 'HH : mm')}
                  </Typography>
                </Stack>
              ) : (
                '-'
              )}
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
