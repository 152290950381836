import { Area, NormalizedArea } from 'superadmin/types';

const denormalizeArea = (parent: NormalizedArea, areas: NormalizedArea[]) => {
  const area: Area = parent;

  const children = areas
    .filter((area) => {
      return parent.id === area.parent;
    })
    .map((area) => {
      return denormalizeArea(area, areas);
    });

  area.children = children;
  return area;
};

const denormalize = (areas: Record<string, NormalizedArea>) => {
  const areasArray = Object.values(areas).sort((a, b) => {
    if (!a.name || !b.name) {
      return 0;
    }
    return a.name.localeCompare(b.name, undefined, { numeric: true });
  });
  const rootAreas = areasArray.filter((area) => !area.parent);
  if (rootAreas.length) {
    return denormalizeArea(rootAreas[0], areasArray);
  }
  return undefined;
};

const normalizer = {
  denormalize,
};

export default normalizer;
