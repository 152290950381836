import { UnitSector, unitSectors } from '@ambuliz/sabri-core';
import { getRegionStatistics } from '@ambuliz/sabri-core/dist/cloud';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

const getStatisticsBySector = async () => {
  const statisticsBySector = await Promise.all(
    unitSectors.map((sector) => getRegionStatistics({ sectors: [sector as UnitSector] }))
  );
  return statisticsBySector.map((statistics, index) => ({
    name: unitSectors[index],
    value: statistics.occupancy?.rate,
  }));
};

export type StatisticsBySector = {
  name: UnitSector;
  value?: number;
};

const useStatisticsBySector = () => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<StatisticsBySector[]>(unitSectors.map((sector) => ({ name: sector })));

  useEffect(() => {
    const fetchStatistics = async () => {
      setLoading(true);
      const statistics = await getStatisticsBySector();
      unstable_batchedUpdates(() => {
        setStatistics(statistics);
        setLoading(false);
      });
    };

    fetchStatistics();
  }, []);

  return {
    loading,
    statistics,
  };
};

export default useStatisticsBySector;
