import { Box, Grid, Typography } from '@mui/material';
import { routerActions } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { requestNames } from '../../const/requests';
import { routeNamesCommonDynamic } from '../../const/routeNames';
import { EntityNames } from '../../const/schemas';
import { i18n } from '../../locales';
import { archivedFaresColumns, fareArchivedFilters, fareArchivedFiltersMongoWhere } from '../../services/fare';
import { getArchivedFaresAction } from '../../store/actions/fare';
import { FareStored } from '../../store/reducers/entity';
import { faresActions } from '../../store/reducers/fares';
import { selectAreasFilters } from '../../store/selectors/area';
import { selectEntitiesByIds } from '../../store/selectors/entity';
import { selectFareList } from '../../store/selectors/fare';
import { selectPortersFilters } from '../../store/selectors/porter';
import { selectRequestStatus } from '../../store/selectors/requestStatus';
import { createWhereRequestWithFilters } from '../../utils/parse';
import { FilterValues, FiltersController } from '../FiltersController/FiltersController';
import { SelectorDate } from '../SelectorDate/SelectorDate';
import { SimpleTable } from '../SimpleTable/SimpleTable';
import { TableListHeader } from '../TableListHeader/TableListHeader';

export const FareArchived: React.FC = () => {
  const dispatch = useDispatch();
  const getFaresStatus = useSelector(selectRequestStatus(requestNames.getArchivedFares));
  const fareList = useSelector(selectFareList);
  const fares = useSelector(selectEntitiesByIds(EntityNames.fares, fareList.ids));
  const portersFilters = useSelector(selectPortersFilters);
  const areasFilters = useSelector(selectAreasFilters);

  const handleClickFare = useCallback(
    (fare: FareStored) => {
      dispatch(routerActions.push(routeNamesCommonDynamic.archivedFareDetails(fare.objectId)));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getArchivedFaresAction());
    return () => {
      dispatch(faresActions.setWhere());
    };
  }, [dispatch]);

  const handleChangeFilters = useCallback(
    (filters: FilterValues[]) => {
      const newWhere = createWhereRequestWithFilters(filters, fareArchivedFiltersMongoWhere);
      batch(() => {
        dispatch(faresActions.setWhere(newWhere));
        dispatch(getArchivedFaresAction());
      });
    },
    [dispatch]
  );

  const handleChangeDateRange = ({ start, end }: { start?: Date; end: Date }) => {
    const prevWhere = fareList.filters.where;
    const newWhere = {
      ...prevWhere,
      createdAt: {
        $gte: {
          iso: start?.toString(),
          __type: 'Date',
        },
        $lte: {
          iso: end.toString(),
          __type: 'Date',
        },
      },
    };
    batch(() => {
      dispatch(faresActions.setWhere(newWhere));
      dispatch(getArchivedFaresAction());
    });
  };

  const handleChangePage = useCallback(
    (newPage: number) => {
      batch(() => {
        dispatch(faresActions.setPage(newPage));
        dispatch(getArchivedFaresAction());
      });
    },
    [dispatch]
  );

  const handleChangeLimit = useCallback(
    (newLimit: number) => {
      batch(() => {
        dispatch(faresActions.setLimit(newLimit));
        dispatch(getArchivedFaresAction());
      });
    },
    [dispatch]
  );

  const handleChangeSortBy = useCallback(
    (sortBy: string) => {
      batch(() => {
        dispatch(faresActions.setSortBy(sortBy));
        dispatch(getArchivedFaresAction());
      });
    },
    [dispatch]
  );

  const handleChangeSortDirection = useCallback(
    (sortDirection: 'asc' | 'desc') => {
      batch(() => {
        dispatch(faresActions.setSortDirection(sortDirection));
        dispatch(getArchivedFaresAction());
      });
    },
    [dispatch]
  );

  const renderNumberOfIncidents = () => {
    if (fareList.count) {
      return `${fareList.count} ${fareList.count > 1 ? i18n.fares.toLowerCase() : i18n.fare.toLowerCase()}`;
    }
  };

  return (
    <>
      <Box padding={3} paddingTop={4}>
        <TableListHeader title={i18n.faresArchived} />
        <Grid container>
          <Box marginRight={1}>
            <SelectorDate onSubmit={handleChangeDateRange} />
          </Box>
          <FiltersController
            filters={fareArchivedFilters(portersFilters, areasFilters)}
            onUpdateFilters={handleChangeFilters}
          />
        </Grid>
        <Box marginTop={2} marginBottom={2}>
          <Typography variant="h6">{renderNumberOfIncidents()}</Typography>
        </Box>
        <SimpleTable
          count={fareList.count}
          size="small"
          data={fares}
          columns={archivedFaresColumns}
          page={fareList.filters.page}
          limit={fareList.filters.limit}
          sortBy={fareList.filters.sortBy}
          sortDirection={fareList.filters.sortDirection}
          isLoading={getFaresStatus === 'LOADING'}
          onClickRow={handleClickFare}
          onChangePage={handleChangePage}
          onChangeLimit={handleChangeLimit}
          onChangeSortBy={handleChangeSortBy}
          onChangeSortDirection={handleChangeSortDirection}
          labelNoData={i18n.noFares}
        />
      </Box>
      <Box height={100} width="100%"></Box>
    </>
  );
};
