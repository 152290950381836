import Api, { EmergencyOccupancy, HealthCenter } from '@ambuliz/sabri-core';
import { MenuItem, Stack } from '@mui/material';
import { FilterSelect } from 'common/components';
import Statistics from 'common/components/Statistics';
import useParseQuery from 'common/hooks/useParseQuery';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { ContentLoader, PageHeader, PageSection } from 'core/layout';
import { useLocations } from 'core/locations';
import { useEffect, useMemo, useState } from 'react';
import EmergencyOccupancyCard from 'superadmin/components/EmergencyOccupancyCard';
import EmergenciesList from './EmergenciesList';

const Emergencies = () => {
  const { healthCenter } = useAuthentication();
  const { units, loading } = useLocations();
  // À remplacer quand on pourra modifier une accommodation dans une unité type Urgences
  // const { units, loading } = useEmergencyUnits();
  const [unitId, setUnitId] = useState<string>('');

  useEffect(() => {
    if (units && units.length > 0) {
      setUnitId(units[0].id);
    }
  }, [units]);

  const unitOptions = useMemo(() => units.map((unit) => ({ value: unit.id, label: unit.name })), [units]);

  const { results: emergencyOccupancies, isLoading: isLoadingOccupancies } = useParseQuery(
    new Api.Query(EmergencyOccupancy)
      .equalTo('healthCenter', HealthCenter.createWithoutData(healthCenter.id))
      .descending('date')
      .limit(1)
  );
  const emergencyOccupancy = useMemo(
    () => emergencyOccupancies.sort((a, b) => b.date.getTime() - a.date.getTime())[0],
    [emergencyOccupancies]
  );

  return (
    <>
      <PageHeader title="Urgences"></PageHeader>
      {loading ? (
        <ContentLoader />
      ) : (
        <>
          {(isLoadingOccupancies || emergencyOccupancies) && (
            <PageSection>
              <Statistics.Container loading={isLoadingOccupancies}>
                {emergencyOccupancy && (
                  <EmergencyOccupancyCard emergencyOccupancy={emergencyOccupancy} xs={true} sm="auto" />
                )}
              </Statistics.Container>
            </PageSection>
          )}
          <PageSection>
            <Stack spacing={1}>
              <div style={{ alignSelf: 'flex-start' }}>
                <FilterSelect searchable label={i18n.unit} value={unitId} onChange={(unitId) => setUnitId(unitId)}>
                  {unitOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FilterSelect>
              </div>
            </Stack>
          </PageSection>
          {unitId && <EmergenciesList unitId={unitId} />}{' '}
        </>
      )}
    </>
  );
};

// const useEmergencyUnits = () => {
//   const [units, setUnits] = useState<Unit[]>([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const fetch = async () => {
//       setLoading(true);
//       const units = await new Api.Query(Unit).equalTo('type', 'EMERGENCY').findAll();

//       setUnits(units);
//       setLoading(false);
//     };

//     fetch();
//   }, []);

//   return { units, loading };
// };

export default Emergencies;
