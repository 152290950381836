import { SvgIcon, SvgIconProps } from '@mui/material';

const SortDescIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="9.5" fill="none" stroke="#E8EAF2" />
    <path
      d="M5.27792 11.2555C5.6484 10.9488 6.24906 10.9488 6.61953 11.2555L10.0596 14.1037L13.4997 11.2555C13.8702 10.9488 14.4708 10.9488 14.8413 11.2555C15.2118 11.5622 15.2118 12.0596 14.8413 12.3663L10.7304 15.7699C10.3599 16.0767 9.75928 16.0767 9.3888 15.7699L5.27792 12.3663C4.90744 12.0596 4.90744 11.5622 5.27792 11.2555Z"
      fill="#666D8E"
    />
    <path
      d="M14.8394 8.74449C14.4689 9.05123 13.8683 9.05123 13.4978 8.74449L10.0577 5.89625L6.61762 8.74449C6.24714 9.05123 5.64648 9.05123 5.276 8.74449C4.90552 8.43775 4.90552 7.94043 5.276 7.63369L9.38689 4.23005C9.75737 3.92332 10.358 3.92332 10.7285 4.23005L14.8394 7.63369C15.2099 7.94043 15.2099 8.43775 14.8394 8.74449Z"
      fill="#E8EAF2"
    />
  </SvgIcon>
);

export default SortDescIcon;
