import { Shadows } from '@mui/material/styles/shadows';

const shadows: Shadows = [
  'none',
  '0px 1px 2px rgba(20, 22, 28, 0.06)',
  '0px 0px 10px rgba(20, 22, 28, 0.08)',
  '0px 2px 14px rgba(20, 22, 28, 0.12)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
  '0px 6px 25px rgba(20, 22, 28, 0.15)',
];

export default shadows;
