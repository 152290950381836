import { Stack, Typography } from '@mui/material';
import { InputIncrement } from 'common/components/Form';
import { InputName } from './EditModal';

type OccupancyInputProps = {
  value?: number;
  name: InputName;
  onChange: (value: number, name: InputName) => void;
  label?: string;
};

const OccupancyInput: React.FC<OccupancyInputProps> = ({ value, onChange, label, name }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
      {!!label && <Typography>{label}</Typography>}
      <InputIncrement value={value} onChange={(value) => onChange(value, name)} allowNegativeValue />
    </Stack>
  );
};
export default OccupancyInput;
