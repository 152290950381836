import { FareStatus, PorterStatus } from '@ambuliz/sabri-core';

const fareStatus: Record<FareStatus | 'PULLED', string> = {
  PENDING: '#FF9100',
  DRAFT: '#039DE6',
  IN_PROGRESS: '#00C854',
  DONE: '#039DE6',
  ASSIGNED: '#039DE6',
  PATIENT_CARE: '#00C854',
  STARTED: '#00C854',
  CANCELED: '#EF5350',
  FAILED: '#EF5350',
  WAITING: '#FFBA49',
  PULLED: '#FF9100',
  QUEUED: '#FF9100',
};

const porterStatus: Record<PorterStatus, string> = {
  AVAILABLE: '#00C854',
  BUSY: '#039DE6',
  OFF: '#EF5350',
  OFFLINE: '#EF5350',
  ON_BREAK: '#FF9100',
};

export const colors = {
  fareStatus,
  porterStatus,
};
