import { Box } from '@mui/material';
import { UsersOutline } from 'common/components/Icons';
import { palette } from 'common/theme';
import styled from 'styled-components';

const Avatar = () => {
  return (
    <AvatarContainer>
      <UsersOutline style={{ width: '100%', height: '100%' }} />
    </AvatarContainer>
  );
};

const AvatarContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${palette.danger.main};
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  padding: 8px;
  box-sizing: border-box;
`;

export default Avatar;
