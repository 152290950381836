import { Unit } from '@ambuliz/sabri-core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TuneIcon from '@mui/icons-material/Tune';
import { Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { kurtRoutes } from 'kurt';
import { UnitTypeChip } from './UnitTypeChip';

type UnitResultListItemProps = {
  onClick: () => void;
  unit: Unit;
};

const buildUnitKurtLink = (unitId: string) => `${kurtRoutes.root}${kurtRoutes.unit}/${unitId}`;

export const UnitResultListItem = ({ unit, onClick }: UnitResultListItemProps) => {
  return (
    <TableRow onClick={onClick} hover sx={{ cursor: 'pointer' }}>
      <TableCell sx={{ fontWeight: 600 }}>{unit.name}</TableCell>
      <TableCell>
        <UnitTypeChip type={unit.type} />
      </TableCell>
      <TableCell>{unit.department}</TableCell>
      <TableCell>
        <Grid container spacing={1}>
          {unit.specialties?.map((specialty) => (
            <Grid item>
              <Chip variant="lightRounded" key={specialty} label={specialty} />
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          {unit.areas?.map((area) => (
            <Grid item>
              <Chip variant="lightRounded" color="primary" key={area.id} label={area.name} />
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          {unit.responsibleUnits?.map((responsibleUnit) => (
            <Grid item>
              <Chip variant="lightRounded" key={responsibleUnit} label={responsibleUnit} />
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Tooltip title="Modifier" placement="top">
            <IconButton onClick={onClick} color="secondary">
              <TuneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Voir sur la GDL" placement="top">
            <IconButton
              onClick={(event) => event.stopPropagation()}
              color="secondary"
              href={buildUnitKurtLink(unit.id)}
              target="_blank"
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
