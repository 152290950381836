import { Components } from '@mui/material';

const MuiDialog: Components['MuiDialog'] = {
  styleOverrides: {
    paperWidthXs: {
      maxWidth: 450,
    },
    paperWidthSm: {
      maxWidth: 520,
    },
    paperWidthMd: {
      maxWidth: 700,
    },
    paperWidthLg: {
      maxWidth: 900,
    },
  },
  defaultProps: {
    PaperProps: {
      elevation: 4,
    },
    fullWidth: true,
  },
};

export default MuiDialog;
