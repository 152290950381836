import Api, { Fare, Journey } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useMemo } from 'react';

const useJourneyFares = (journey?: Journey) => {
  const isJourneyQueryEnabled = !!journey;

  const { results, isLoading } = useParseQuery(
    new Api.Query(Fare)
      .equalTo('journey', journey)
      .include('fromUnit', 'fromArea', 'toUnit', 'toArea', 'incidents', 'incidents.porter'),
    {
      enabled: isJourneyQueryEnabled,
    }
  );

  const journeyFaresSorted: Fare[] = useMemo(() => {
    const fares = [];

    for (const fare of journey?.fares || []) {
      const foundFare = results.find((f) => f.id === fare.id);

      if (foundFare) {
        fares.push(foundFare);
      }
    }

    return fares;
  }, [journey, results]);

  return {
    journeyFares: journeyFaresSorted,
    isLoading,
  };
};

export default useJourneyFares;
