import { i18n } from 'common/locale';
import { PageTitle } from 'core/layout';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'url-search-params-polyfill';
import App from './App';
import routes from './routes';
import { store } from './store/store';

const Sabri = () => {
  return (
    <Provider store={store}>
      <PageTitle title={i18n.appTitle.sabri} defer={false} />
      <App />
    </Provider>
  );
};

export default Sabri;
export { routes as sabriRoutes };
