import { Stack, StackProps } from '@mui/material';
import { ReactNode } from 'react';

type FilterSectionProps = {
  children: ReactNode;
} & StackProps;

const FilterSection = ({ children, ...stackProps }: FilterSectionProps) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap" gap={3} {...stackProps}>
    {children}
  </Stack>
);

export default FilterSection;
