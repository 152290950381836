import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAreaBreadCrumb } from 'sabri/store/selectors/area';
import { selectUnit } from 'sabri/store/selectors/unit';

type LocationDetailsProps = {
  unitId?: string;
  areaId?: string;
  size?: 'small' | 'large';
};

const LocationDetails: React.FC<LocationDetailsProps> = ({ unitId, areaId, size = 'small' }) => {
  const unit = useSelector(selectUnit(unitId));
  const areaBreadcrumbs = useSelector(selectAreaBreadCrumb(areaId));

  const fontWeight = size === 'small' ? 400 : 600;
  return (
    <>
      <Typography fontSize={size === 'small' ? 13 : 14} fontWeight={fontWeight}>
        {unit?.name}
      </Typography>
      <Breadcrumbs separator={<NavigateNextIcon />}>
        {areaBreadcrumbs.map((name) => (
          <Typography fontWeight={fontWeight} key={name}>
            {name}
          </Typography>
        ))}
      </Breadcrumbs>
    </>
  );
};

export default LocationDetails;
