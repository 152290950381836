import { Accommodation } from '@ambuliz/sabri-core';
import { CircularProgress, Stack } from '@mui/material';
import { Draggable } from 'common/components';
import PatientPlacementCard from './PatientPlacementCard';

type PatientPlacementComponentProps = {
  patientsToBePlaced: Accommodation[];
  loading: boolean;
  isReadOnly?: boolean;
  hiddenCardId?: string;
  onCardClick: (id: string) => void;
};

const PatientPlacementList = ({
  loading,
  patientsToBePlaced,
  onCardClick,
  hiddenCardId,
  isReadOnly = false,
}: PatientPlacementComponentProps) => {
  return (
    <Stack spacing={2} flex={1} padding="16px 24px 100px 24px">
      {loading ? (
        <Stack alignItems="center" flex={1} justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        patientsToBePlaced.map((patientToBePlaced) => {
          return hiddenCardId === patientToBePlaced.id ? undefined : (
            <Draggable id={patientToBePlaced.id} data={{ data: patientToBePlaced }} disabled={isReadOnly}>
              <PatientPlacementCard
                key={patientToBePlaced.id}
                patientToBePlaced={patientToBePlaced}
                isReadOnly={isReadOnly}
                onClick={onCardClick}
              />
            </Draggable>
          );
        })
      )}
    </Stack>
  );
};

export default PatientPlacementList;
