import { useState } from 'react';
import { useMatch } from 'react-router-dom';

type PageType = 'present-patients' | 'hospitalizations';

const useEmergencyRouteParams = () => {
  const match = useMatch('/lits/emergency/:unitId/:page/*');
  const [page, setPage] = useState<PageType>((match?.params.page as PageType) || 'present-patients');
  const [unitId, setUnitId] = useState<string | undefined>(match?.params.unitId);

  if (match) {
    if (match.params.unitId !== unitId) {
      setUnitId(match.params.unitId);
    }
    if (match.params.page !== page) {
      setPage(match.params.page as PageType);
    }
  }

  return {
    page,
    unitId,
  };
};

export default useEmergencyRouteParams;
