import { SvgIcon, SvgIconProps } from '@mui/material';

const Warning: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_749_5132)">
        <path d="M8 3.18381L13.8186 13.3693H2.18136L8 3.18381ZM8.86824 1.57948C8.48435 0.907667 7.51565 0.907669 7.13176 1.57948L0.354935 13.4389C-0.0260113 14.1056 0.455357 14.9351 1.22318 14.9351H14.7768C15.5446 14.9351 16.026 14.1056 15.6451 13.4389L8.86824 1.57948ZM8.77273 11.7933C8.77273 11.3665 8.42677 11.0206 8 11.0206C7.57323 11.0206 7.22727 11.3665 7.22727 11.7933V11.8136C7.22727 12.2404 7.57323 12.5864 8 12.5864C8.42677 12.5864 8.77273 12.2404 8.77273 11.8136V11.7933ZM8.77273 7.09594C8.77273 6.66918 8.42677 6.32322 8 6.32322C7.57323 6.32322 7.22727 6.66918 7.22727 7.09594V9.199C7.22727 9.62576 7.57323 9.97172 8 9.97172C8.42677 9.97172 8.77273 9.62576 8.77273 9.199V7.09594Z" />
      </g>
      <defs>
        <clipPath id="clip0_749_5132">
          <rect width="16" height="16" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Warning;
