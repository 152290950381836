import { SvgIcon, SvgIconProps } from '@mui/material';

const Bell: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 10.6667C13 11.219 13.4477 11.6667 14 11.6667C14.3682 11.6667 14.6666 11.9651 14.6666 12.3333C14.6666 12.7015 14.3682 13 14 13H1.99998C1.63179 13 1.33331 12.7015 1.33331 12.3333C1.33331 11.9651 1.63179 11.6667 1.99998 11.6667C2.55226 11.6667 2.99998 11.219 2.99998 10.6667V7.42533C2.92044 4.80958 4.76037 2.52665 7.33331 2.04867V0.666667C7.33331 0.298477 7.63179 0 7.99998 0C8.36817 0 8.66665 0.298477 8.66665 0.666667V2.04867C11.2396 2.52665 13.0795 4.80958 13 7.42533V10.6667ZM6.50932 14H9.49065C9.5756 13.9995 9.64708 14.0635 9.65598 14.148C9.66325 14.2095 9.66681 14.2714 9.66665 14.3333C9.66665 15.2538 8.92046 16 7.99998 16C7.07951 16 6.33332 15.2538 6.33332 14.3333C6.33316 14.2714 6.33672 14.2095 6.34398 14.148C6.35347 14.0638 6.4246 14.0001 6.50932 14Z"
      />
    </SvgIcon>
  );
};

export default Bell;
