import { Autocomplete, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { Search } from 'common/components/Icons';
import PatientAutocompletePopper from 'common/components/PatientAutocomplete/PatientAutocompletePopper';
import { i18n } from 'common/locale';
import React from 'react';

type UnitAutocompleteProps = {
  options: Option[];
  selected: Option | null;
  onChange: (_: any, value: Option | null) => void;
  error?: { message: string };
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
  disabled?: boolean;
};

const UnitAutocomplete: React.FC<UnitAutocompleteProps> = ({
  options,
  selected,
  onChange,
  error,
  variant = 'outlined',
  size = 'medium',
  disabled = false,
}) => {
  return (
    <Stack spacing={2}>
      <Autocomplete
        options={options}
        disabled={disabled}
        size={size}
        blurOnSelect
        autoHighlight
        openOnFocus
        clearIcon={null}
        value={selected}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={onChange}
        noOptionsText={i18n.unitSearchNoOptions}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Typography fontWeight={600} variant={size === 'small' ? 'body2' : undefined} padding={3}>
              {option.label}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={i18n.searchUnit}
            fullWidth
            variant={variant}
            error={!!error}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Search sx={{ fontSize: size === 'small' ? 12 : undefined, marginRight: size === 'small' ? 2 : 3 }} />
              ),
            }}
          />
        )}
        PopperComponent={(props) => <PatientAutocompletePopper {...props} />}
      />
      {!!error && (
        <Typography color="error" fontSize={12} marginY={1}>
          {error.message}
        </Typography>
      )}
    </Stack>
  );
};

export type Option = { value: string; label: string };

export default UnitAutocomplete;
