import { useState, useEffect } from 'react';

export const useCloseMenu = (onClose?: () => void) => {
  const [isSelectorUsed, setIsSelectorUsed] = useState(false);

  useEffect(() => {
    const closeMenu = () => {
      onClose && onClose();
    };

    const onMouseDown = () => {
      if (!isSelectorUsed) {
        onClose && onClose();
      }
    };

    window.addEventListener('resize', closeMenu);
    window.addEventListener('mousedown', onMouseDown);
    //On supprime le menu lorsque le container principal est scrollé (voir Layout.tsx)
    document.getElementById('container')?.addEventListener('scroll', closeMenu);
    return () => {
      window.removeEventListener('resize', closeMenu);
      window.removeEventListener('mousedown', onMouseDown);
      document.getElementById('container')?.removeEventListener('scroll', closeMenu);
    };
  }, [isSelectorUsed, onClose]);

  const onMouseOver = () => {
    setIsSelectorUsed(true);
  };

  const onMouseLeave = () => {
    setIsSelectorUsed(false);
  };
  return { onMouseOver, onMouseLeave };
};
