import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { routeNamesCommonDynamic } from '../../const/routeNames';
import { getObjectParamsFromSearchParams } from '../../utils/searchParams';

export const CallPatient: React.FC = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const { fareId } = getObjectParamsFromSearchParams(searchParams);

  return (
    <Navigate
      to={{
        pathname: routeNamesCommonDynamic.fareDetails(fareId),
        search: '?autoCall',
      }}
    />
  );
};
