import {
  AccommodationSpecificity,
  AccommodationStatus,
  AnomalyType,
  PatientGender,
  Practitioner,
} from '@ambuliz/sabri-core';
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { PatientNameWithEmoji, TimeFlowLine } from 'common/components';
import { color, palette } from 'common/theme';
import { formatAge, getPatientAge } from 'common/utils';
import getShortDuration from 'common/utils/getShortDuration';
import { format } from 'date-fns';
import { AccommodationSpecificityBadges } from 'kurt/components';
import EditableTableCell from 'kurt/components/EditableTableCell/EditableTableCell';
import { PractitionersChip } from 'kurt/components/Practitioners';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import { useAccommodation } from 'kurt/hooks';
import BedName from 'kurt/pages/Unit/BedName';
import { BusyBedActionsMenu } from 'kurt/pages/Unit/Menus';
import { useEffect, useState } from 'react';

type BusyBedRowProps = {
  accommodationId: string;
  bedId: string;
  unitId: string;
  unitName: string;
  name: string;
  status: AccommodationStatus;
  patient: { name: string; gender: PatientGender; birthdate: Date };
  visitId: string;
  startAt: Date;
  endAt?: Date;
  specificities?: AccommodationSpecificity[];
  practitioners?: Practitioner[];
  comment?: string;
  isEstimatedEnd: boolean;
  anomalies?: { type: AnomalyType; accommodation?: { id: string } }[];
  displayUnitColumn: boolean;
  isReadOnly?: boolean;
  onClick: (accommodationId: string) => void;
};

export const BusyBedRow = ({
  accommodationId,
  bedId,
  unitId,
  unitName,
  status,
  name,
  patient,
  visitId,
  startAt,
  endAt,
  specificities = [],
  practitioners = [],
  comment: initialComment,
  isEstimatedEnd,
  anomalies = [],
  displayUnitColumn,
  isReadOnly = false,
  onClick,
}: BusyBedRowProps) => {
  const [comment, setComment] = useState(initialComment || '');
  const { update, loading } = useAccommodation(accommodationId);

  useEffect(() => setComment(initialComment || ''), [initialComment]);

  const patientAge = getPatientAge(patient.birthdate);

  const handleCommentSubmit = (value: string) => {
    if (value !== initialComment) {
      update({ comment: value });
    }
  };

  return (
    <>
      <TableRow
        onClick={() => onClick(accommodationId)}
        hover
        sx={{
          cursor: 'pointer',
        }}
      >
        <TableCell sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Box display="flex" sx={{ marginLeft: anomalies.length > 0 ? '-18px' : 0 }}>
            <BedName name={name} anomalies={anomalies} />
          </Box>
        </TableCell>
        {displayUnitColumn && (
          <TableCell>
            <Typography variant="body2" color="secondary">
              {unitName}
            </Typography>
          </TableCell>
        )}
        <TableCell width={100}>
          <BedStatusChip status="BUSY" gender={patient.gender} />
        </TableCell>
        <TableCell>
          <PatientNameWithEmoji
            name={patient.name}
            gender={patient.gender}
            age={patientAge}
            bgcolor={color.blue[5]}
          ></PatientNameWithEmoji>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary">
            {formatAge(patient.birthdate)}
          </Typography>
        </TableCell>
        <TableCell>
          <PractitionersChip practitioners={practitioners.map(({ name }) => name)} />
        </TableCell>
        <TableCell width={80}>
          {startAt ? (
            <Typography
              noWrap
              variant="body2"
              color={anomalies.find((a) => a.type === 'OUTDATED_START') ? palette.warning.main : 'secondary'}
              fontWeight={600}
            >
              {format(new Date(startAt), 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 156, paddingX: 0 }}>
          <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
            <TimeFlowLine start={['IN_PROGRESS', 'COMPLETED'].includes(status) ? 'filled' : 'dashed'} size="small" />
            <Box width={60}>
              <Typography noWrap variant="body2" color="secondary">
                {startAt && endAt ? getShortDuration(new Date(endAt), new Date(startAt)) : '-'}
              </Typography>
            </Box>
            <TimeFlowLine end={status === 'COMPLETED' || !isEstimatedEnd ? 'filled' : 'dashed'} size="small" />
          </Stack>
        </TableCell>
        <TableCell>
          {endAt ? (
            <Typography
              noWrap
              variant="body2"
              color={
                anomalies.find((a) => ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END'].includes(a.type))
                  ? color.yellow[70]
                  : 'secondary'
              }
              fontWeight={600}
            >
              {format(new Date(endAt), 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <Box display="flex">
            <AccommodationSpecificityBadges specificities={specificities} />
          </Box>
        </TableCell>
        <EditableTableCell
          value={comment}
          onChange={setComment}
          onSubmit={handleCommentSubmit}
          loading={loading}
          isReadOnly={isReadOnly}
        />
        <TableCell width={16} sx={{ paddingLeft: 2 }}>
          {!isReadOnly && (
            <BusyBedActionsMenu
              id={accommodationId}
              status={status}
              bedId={bedId}
              unitId={unitId}
              visitId={visitId}
              comment={comment}
              specificities={specificities}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default BusyBedRow;
