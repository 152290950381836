import { Cloud } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { AcceptMutationRequest } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';

type AcceptPerformerRequestDialogFormProps = {
  performerRequestId: string;
  onClose: () => void;
  onBack?: () => void;
};

export const AcceptPerformerRequestDialogForm = ({
  performerRequestId,
  onClose,
  onBack,
}: AcceptPerformerRequestDialogFormProps) => {
  const handleSubmit = async (_: undefined, { startAt }: { startAt: Date }) => {
    await Cloud.acceptPerformerRequest({
      id: performerRequestId,
      startAt,
    });
    onClose();
  };

  return (
    <>
      <DialogHeader
        onClose={onClose}
        onBack={onBack}
        title={i18n.visitActions.ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN.action.label}
      />
      <DialogContent>
        <AcceptMutationRequest
          locale="ACCEPT_INTERNAL_MUTATION_REQUEST"
          onCancel={onBack || onClose}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </>
  );
};

type AcceptPerformerRequestProps = {
  open: boolean;
  onClose: () => void;
  performerRequestId: string;
};

export const AcceptPerformerRequestDialog = ({ open, onClose, performerRequestId }: AcceptPerformerRequestProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <AcceptPerformerRequestDialogForm onClose={onClose} performerRequestId={performerRequestId} />
    </Dialog>
  );
};
