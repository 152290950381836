import { Divider, Stack, StackProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

type FormContainerProps = {
  label?: string;
  children?: ReactNode;
  sx?: StackProps['sx'];
};

const FormContainer: React.FC<FormContainerProps> = ({ label, children, sx }) => {
  return (
    <Stack spacing={5} divider={<Divider flexItem />} sx={sx}>
      {!!label && <Typography variant="h2">{label}</Typography>}
      {children}
    </Stack>
  );
};

export default FormContainer;
