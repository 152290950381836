import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowCircle: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.583.622a.773.773 0 0 1 .834 0l7.207 4.595c.373.237.488.74.257 1.124a.78.78 0 0 1-1.091.265L8 2.277l-6.79 4.33A.78.78 0 0 1 .119 6.34a.831.831 0 0 1 .257-1.124L7.583.622ZM8 4.677c.438 0 .793.366.793.817V8A.805.805 0 0 1 8 8.817.805.805 0 0 1 7.207 8V5.494c0-.451.355-.817.793-.817ZM2.394 8.019c.438 0 .793.365.793.816v5.031h9.626v-5.03c0-.452.355-.817.792-.817.438 0 .793.365.793.816v5.347c0 .346-.131.68-.37.928-.237.249-.562.39-.903.39H2.875c-.34 0-.666-.141-.904-.39a1.342 1.342 0 0 1-.37-.928V8.835c0-.45.356-.816.793-.816Zm6.144 2.714a.834.834 0 0 1 .06 1.153l-.008.01a.777.777 0 0 1-1.12.062.834.834 0 0 1-.06-1.154l.008-.009a.777.777 0 0 1 1.12-.062Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ArrowCircle;
