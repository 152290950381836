import { EmergencyOccupancy } from '@ambuliz/sabri-core';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { CircularProgressIcon } from 'common/components';
import Hourglass from 'common/components/Icons/Hourglass';
import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { palette, theme } from 'common/theme';
import { formatDuration, intervalToDuration } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { getThresholdedColor } from 'kurt/const';

type EmergencyOccupancyCardProps = {
  emergencyOccupancy: EmergencyOccupancy;
  xs?: number | boolean | 'auto';
  sm?: number | boolean | 'auto';
  md?: number | boolean | 'auto';
  lg?: number | boolean | 'auto';
  xl?: number | boolean | 'auto';
};

const EmergencyOccupancyCard = ({ emergencyOccupancy, xs, md, sm, lg, xl }: EmergencyOccupancyCardProps) => {
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Statistics.Card md={md} sm={sm} xs={xs} lg={lg} xl={xl} header={{ title: i18n.emergencyOccupancy.card.title }}>
      <Statistics.Item>
        <Statistics.ItemContent>
          <Stack alignItems="center" direction="row" spacing={3}>
            {matchUpSm && (
              <Stack spacing={1}>
                <Typography variant="body2" color={palette.text.secondary}>
                  {i18n.emergencyOccupancy.card.present}
                </Typography>
                <Statistics.Text variant="lg">
                  {emergencyOccupancy.currentPatients + (emergencyOccupancy.waitingPatients ?? 0)}
                </Statistics.Text>
              </Stack>
            )}
            <CircularProgressIcon
              Icon={Hourglass}
              value={getOccupancyRate(emergencyOccupancy)}
              color={getThresholdedColor(getOccupancyRate(emergencyOccupancy), 'success', { red: 75, orange: 50 })}
            />
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" color={palette.text.secondary}>
                  {i18n.emergencyOccupancy.card.waitingPatients}
                </Typography>
                <Typography variant="h5">{emergencyOccupancy.waitingPatients}</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" color={palette.text.secondary}>
                  {i18n.emergencyOccupancy.card.currentPatients}
                </Typography>
                <Typography variant="h5">{emergencyOccupancy.currentPatients}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Statistics.ItemContent>
      </Statistics.Item>

      <Statistics.Item
        title={{ label: i18n.emergencyOccupancy.card.averageWaitingTime, color: 'default', variant: 'light' }}
      >
        <Statistics.ItemContent>
          <Statistics.Text variant="lg">{getAverageWaitingTime(emergencyOccupancy.averageWaitingTime)}</Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
    </Statistics.Card>
  );
};

function getOccupancyRate(emergencyOccupancy: EmergencyOccupancy): number {
  const total = emergencyOccupancy.currentPatients + (emergencyOccupancy.waitingPatients ?? 0);

  if (!total) {
    return 0;
  }
  return ((emergencyOccupancy.waitingPatients ?? 0) * 100) / total;
}

function getAverageWaitingTime(averageWaitingTime?: number): string {
  if (!averageWaitingTime) {
    return '';
  }
  const waitingTimeDate = new Date(averageWaitingTime);

  return (
    (formatDuration(intervalToDuration({ start: new Date(0), end: waitingTimeDate }), {
      format: ['days', 'hours'],
      locale: fr,
    }) || '') +
    (' ' + (waitingTimeDate.getMinutes() > 0 ? waitingTimeDate.getMinutes() : '')) +
    (!(waitingTimeDate.getHours() - 1) ? ' ' + i18n.minutes : '')
  );
}

export default EmergencyOccupancyCard;
