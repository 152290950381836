import Api, { Area } from '@ambuliz/sabri-core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge, Chip, IconButton, Link as MuiLink, Paper, Skeleton, Stack, Typography } from '@mui/material';
import EditIcon from 'common/components/Icons/Edit';
import AddIcon from 'common/components/Icons/Plus';
import { useParseQueryCount } from 'common/hooks/useParseQueryCount';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AreaTypeChip } from '../AreaTypeChip';
import { useAreaDirectUnit } from '../hooks/useAreaDirectUnit';
import { AreaManagementListItemChildren } from './AreaManagementListItemChildren';

export type AreaManagementListItemNodeProps = {
  area: Area;
  isReadOnly?: boolean;
};

export const AreaManagementListItemNode = ({ area, isReadOnly = false }: AreaManagementListItemNodeProps) => {
  const { count: childrenCount, isLoading: isChildrenCountLoading } = useParseQueryCount(
    new Api.Query(Area).equalTo('parent', area)
  );
  const { unit, isLoading: isUnitLoading } = useAreaDirectUnit({ area });

  const [open, setOpen] = useState(false);

  const hasChildren = childrenCount > 0;
  const isLoading = isChildrenCountLoading || isUnitLoading;

  if (isLoading) {
    return <Skeleton variant="rounded" height={53} animation="wave"></Skeleton>;
  }

  return (
    <Paper sx={{ p: 3 }} variant="outlined">
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          {area.externalTypeCode && <AreaTypeChip type={area.externalTypeCode} />}
          <Typography variant="h6">{area.name}</Typography>
          {area.externalIds?.map((externalId) => (
            <Chip color="default" variant="roundedAlt" key={externalId} label={externalId} />
          ))}
          {hasChildren && (
            <Badge badgeContent={childrenCount} color="secondary" max={9999}>
              <IconButton onClick={() => setOpen(!open)} variant="filled">
                <ExpandMoreIcon sx={{ rotate: open ? '180deg' : '0deg', transition: '.2s' }} />
              </IconButton>
            </Badge>
          )}
        </Stack>
        {unit && !isReadOnly && <MuiLink href={`/admin/units/${unit.id}`}>{unit.name}</MuiLink>}
        {unit && isReadOnly && <Typography>{unit.name}</Typography>}

        <Stack direction="row" spacing={1}>
          <IconButton
            component={Link}
            to={area.id}
            variant="filled"
            disabled={isReadOnly}
            sx={{ opacity: isReadOnly ? 0 : undefined }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            component={Link}
            to={`${area.id}/create`}
            variant="filled"
            disabled={isReadOnly}
            sx={{ opacity: isReadOnly ? 0 : undefined }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      </Stack>
      {hasChildren && <AreaManagementListItemChildren area={area} open={open} isReadOnly={isReadOnly} />}
    </Paper>
  );
};
