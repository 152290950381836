import { AccommodationSpecificity, accommodationSpecificities, specificitiesSorter } from '@ambuliz/sabri-core';
import { Autocomplete, TextField } from '@mui/material';
import CrossSmallIcon from 'common/components/Icons/CrossSmall';
import { i18n } from 'common/locale';
import { SpecificityChip } from '../SpecifityChip';

type SpecificityAutocompleteProps = {
  value: AccommodationSpecificity[];
  onChange: (value: AccommodationSpecificity[]) => void;
  options?: AccommodationSpecificity[];
};

const defaultSpecificities = accommodationSpecificities.filter((specificity) => specificity !== 'ISOLATED'); // ISOLATED is now deprecated and no more available

const SpecificityAutocomplete = ({ value, onChange, options = defaultSpecificities }: SpecificityAutocompleteProps) => {
  return (
    <Autocomplete
      multiple
      value={value}
      options={options}
      size="small"
      renderInput={(params) => (
        <TextField {...params} variant="filled" placeholder={value.length > 0 ? undefined : i18n.empty} />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          <SpecificityChip
            specificity={option}
            sx={{ backgroundColor: props['aria-selected'] ? 'white' : undefined }}
          />
        </li>
      )}
      onChange={(_, value) => onChange(value.sort(specificitiesSorter))}
      fullWidth
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <SpecificityChip specificity={option} {...getTagProps({ index })} sx={{ backgroundColor: 'white' }} />
        ));
      }}
      clearIcon={<CrossSmallIcon />}
      disableCloseOnSelect
    />
  );
};

export default SpecificityAutocomplete;
