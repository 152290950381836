import { AccommodationSpecificity, AccommodationStatus, PatientGender } from '@ambuliz/sabri-core';
import { Box, Card, CardActionArea, CardContent, Stack, Typography, useMediaQuery } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Comment } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, theme } from 'common/theme';
import { format } from 'date-fns';
import { AccommodationSpecificityBadges } from 'kurt/components';
import { useMemo } from 'react';
import PatientCard from './PatientCard';

export type DischargeCardProps = {
  id: string;
  date: Date;
  patient: {
    name: string;
    gender: PatientGender;
    birthdate: Date;
  };
  isEstimatedEnd: boolean;
  status: AccommodationStatus;
  specificities?: AccommodationSpecificity[];
  comment?: string;
  onClick?: () => void;
};

const DischargeCard = ({
  id,
  date,
  patient,
  isEstimatedEnd,
  status,
  specificities = [],
  comment,
  onClick,
}: DischargeCardProps) => {
  const mdBreakpointMatcher = useMediaQuery(`(max-width: ${theme.breakpoints.values.md}px)`);

  const displayedComment = useMemo(() => comment?.replaceAll('\n', ' - '), [comment]);

  return (
    <Card>
      <CardActionArea disableRipple onClick={onClick}>
        <CardContent sx={{ padding: '12px 12px' }}>
          <Stack spacing={2} direction="column">
            <Stack direction="row" alignItems="center" spacing={3}>
              <Box flex={1}>
                <PatientCard
                  {...patient}
                  specificities={specificities}
                  borderLeft={['IN_PROGRESS', 'COMPLETED'].includes(status)}
                  borderRight={!isEstimatedEnd}
                  striped={status === 'COMPLETED'}
                />
              </Box>
              {specificities.length > 0 && (
                <Box display="flex">
                  <AccommodationSpecificityBadges specificities={specificities} />
                </Box>
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack alignItems="center" spacing={1.5} direction="row" flex={1}>
                <Typography variant="body2" color="secondary" noWrap>
                  {format(date, 'HH:mm')}
                </Typography>
                <div style={{ width: 4, height: 4, borderRadius: 16, backgroundColor: color.grey[60] }} />
                <Typography variant="body2" color="secondary" noWrap>
                  {mdBreakpointMatcher
                    ? isEstimatedEnd
                      ? i18n.previsionalShort
                      : status === 'COMPLETED'
                      ? i18n.effectiveShort
                      : i18n.validatedShort
                    : isEstimatedEnd
                    ? i18n.previsionalDischarge
                    : status === 'COMPLETED'
                    ? i18n.effectiveDischarge
                    : i18n.validatedDischarge}
                </Typography>
              </Stack>

              {displayedComment && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Comment
                    color="secondary"
                    sx={{
                      width: 12,
                      height: 12,
                    }}
                  />
                  <EllipsisTypography variant="body2" color="secondary" sx={{ whiteSpace: 'normal' }}>
                    {displayedComment}
                  </EllipsisTypography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default DischargeCard;
