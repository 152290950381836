import Api, { ComputedOccupancy, computeOccupancy, Contact, Occupancy, Unit, UnitSpecialty } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { sortListBy } from 'common/utils';
import { useLocations } from 'core/locations';
import { useEffect, useMemo, useState } from 'react';

const useDepartments = () => {
  const { healthCenter } = useLocations();

  const {
    results: units,
    isLoading,
    isSyncing,
  } = useParseQuery(new Api.Query(Unit).equalTo('type', 'ACCOMMODATION'), {
    enabled: !!healthCenter,
  });
  const departments = useMemo(() => sortDepartments(normalizeDepartments(units || [])), [units]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoading && !isSyncing) {
      setLoading(false);
    }
  }, [isLoading, isSyncing]);

  return { departments, loading };
};

const sortDepartments = (departments: NormalizedDepartment[]) => {
  const sortedDepartments = sortListBy(departments, 'name');
  for (const department of sortedDepartments) {
    department.units = sortListBy(department.units, 'name');
  }
  return sortedDepartments;
};

const REASpecialties = ['Réanimation polyvalente'];
const USCSpecialties = ['USC - Unité de Surveillance Continue'];
const USISpecialties = ['USI - Unité de Soins Intensifs'];

export type NormalizedUnit = {
  id: string;
  name: string;
  specialties: UnitSpecialty[];
  occupancy?: Occupancy & { updatedAt?: Date };
  capacityRate?: number;
  autoComputeOccupanciesActivated?: boolean;
  contacts?: Contact[];
};
export type NormalizedDepartment = {
  name: string;
  units: NormalizedUnit[];
  occupancy?: ComputedOccupancy;
  specialties?: {
    USC?: ComputedOccupancy;
    USI?: ComputedOccupancy;
    REA?: ComputedOccupancy;
  };
};

const normalizeUnit = (unit: Unit): NormalizedUnit => ({
  id: unit.id,
  name: unit.name,
  occupancy: unit.occupancy,
  capacityRate: unit.occupancy ? computeOccupancy([unit.occupancy]).rate : undefined,
  specialties: unit.specialties || [],
  contacts: unit.contacts,
  autoComputeOccupanciesActivated: unit.autoComputeOccupanciesActivated || false,
});

export const normalizeDepartments = (units: Unit[]) => {
  let departments: Record<string, NormalizedUnit[]> = {};

  for (const unit of units) {
    const normalizedUnit = normalizeUnit(unit);
    if (unit.department) {
      if (departments[unit.department]) {
        departments[unit.department].push(normalizedUnit);
      } else {
        departments[unit.department] = [normalizedUnit];
      }
    } else {
      if (departments['Autres']) {
        departments['Autres'].push(normalizedUnit);
      } else {
        departments['Autres'] = [normalizedUnit];
      }
    }
  }

  const normalizedDepartments: NormalizedDepartment[] = [];
  for (const department in departments) {
    const units = departments[department];
    const occupancy = computeOccupancy(units.map((unit) => unit.occupancy));

    const specialties = {
      USC: computeOccupancy(
        units
          .filter(({ specialties }) => specialties.some((specialty) => USCSpecialties.includes(specialty)))
          .map((unit) => unit.occupancy)
      ),
      REA: computeOccupancy(
        units
          .filter(({ specialties }) => specialties.some((specialty) => REASpecialties.includes(specialty)))
          .map((unit) => unit.occupancy)
      ),
      USI: computeOccupancy(
        units
          .filter(({ specialties }) => specialties.some((specialty) => USISpecialties.includes(specialty)))
          .map((unit) => unit.occupancy)
      ),
    };

    normalizedDepartments.push({
      name: department,
      units,
      occupancy,
      specialties,
    });
  }

  return normalizedDepartments;
};

export default useDepartments;
