import { Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { differenceInHours, differenceInSeconds, formatDistanceToNowStrict, isValid } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Clock } from '../Icons';

const NotificationCardDate = ({ date }: { date?: Date }) => {
  if (!date || !isValid(date)) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Clock color="secondary" />
      <Typography variant="body2" color="secondary" sx={{ '&:first-letter': { textTransform: 'uppercase' } }}>
        {formatDate(date)}
      </Typography>
    </Stack>
  );
};

const formatDate = (date: Date): string => {
  let formattedDate: string;

  if (differenceInSeconds(new Date(), date) < 60) {
    formattedDate = i18n.justNow;
  } else if (differenceInHours(new Date(), date) >= 24 && differenceInHours(new Date(), date) < 48) {
    formattedDate = i18n.yesterday;
  } else if (differenceInHours(new Date(), date) >= 48 && differenceInHours(new Date(), date) < 72) {
    formattedDate = i18n.dayBeforeYesterday;
  } else {
    formattedDate = formatDistanceToNowStrict(date, { addSuffix: true, locale: fr, roundingMethod: 'floor' });
  }

  return formattedDate;
};

export default NotificationCardDate;
