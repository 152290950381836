import { ExternalPatientTransport } from '@ambuliz/sabri-core';
import { Chip, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { PatientNameWithEmoji } from 'common/components';
import CommentIcon from 'common/components/Icons/Comment';
import EditIcon from 'common/components/Icons/Edit';
import { i18n } from 'common/locale';
import { formatDateTime, formatName, getPatientAge } from 'common/utils';
import ExternalTransportStatusChip from 'superadmin/pages/ExternalTransport/ExternalTransportStatusChip';

type ExternalPatientTransportTableRowProps = {
  externalPatientTransport: ExternalPatientTransport;
  onClick?: (id: string) => void;
  onUpdate?: (id: string) => void;
};

export const ExternalPatientTransportTableRow = ({
  externalPatientTransport: { externalId, status, patient, startAt, endAt, farePosition, comment },
  onClick,
  onUpdate,
}: ExternalPatientTransportTableRowProps) => {
  return (
    <TableRow hover sx={{ cursor: 'pointer' }} onClick={() => onClick?.(externalId)}>
      <TableCell>{externalId}</TableCell>
      <TableCell>
        <ExternalTransportStatusChip status={status} />
      </TableCell>
      <TableCell>
        {patient && (
          <PatientNameWithEmoji
            gender={patient.gender}
            age={getPatientAge(patient.birthdate)}
            name={formatName(patient.firstName, patient.lastName)}
          />
        )}
      </TableCell>
      <TableCell>{formatDateTime(startAt) || '-'}</TableCell>
      <TableCell>{formatDateTime(endAt) || '-'}</TableCell>
      <TableCell>{farePosition && <Chip label={i18n.externalFarePosition[farePosition]} color="info" />}</TableCell>
      <TableCell>
        {comment && (
          <Tooltip title={comment}>
            <IconButton color="primary" sx={{ margin: -1 }}>
              <CommentIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        {onUpdate && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onUpdate?.(externalId);
            }}
            size="small"
          >
            <EditIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};
