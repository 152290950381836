import { Anomaly } from '@ambuliz/sabri-core';
import { Emoji } from 'common/components';
import TicketIcon from 'common/components/Icons/Ticket';
import Timeline, { TimelineRow } from 'common/components/Timeline';
import { formatName, getPatientAge, getPatientEmojiIcon } from 'common/utils';
import { addDays, endOfDay, isAfter, isBefore, startOfDay, subDays, subMinutes } from 'date-fns';
import { memo, useEffect, useMemo, useState } from 'react';
import StepsAnomaliesTooltip from '../AnomaliesTooltips/StepsAnomaliesTooltip';
import AccommodationCardEvent from '../EventCard/Variants/AccommodationCardEvent';
import BedAccommodationCardEvent from '../EventCard/Variants/BedAccommodationCardEvent';
import CloseBedCardEvent from '../EventCard/Variants/CloseBedCardEvent';
import { ExternalTransportCardEvent } from '../EventCard/Variants/ExternalTransportCardEvent';
import FareCardEvent from '../EventCard/Variants/FareCardEvent';
import HealthcareEnvironmentalCleaningEvent from '../EventCard/Variants/HealthcareEnvironmentalCleaningEvent';
import { getCardColor } from '../EventCard/cardColors';
import { getTimelineType } from './context/DialogContext';
import { AccommodationStep, ClosedBedStep, HealthcareEnvironmentalCleaningStep, StepItem } from './context/Step';

export const DEFAULT_SCALE = 300;

type DetailsDialogTimelineProps = {
  stepItems: StepItem[];
  step: StepItem | undefined;
};

const DetailsDialogTimeline = ({ stepItems, step }: DetailsDialogTimelineProps) => {
  const [start, setStart] = useState(getStartDate(step));

  const focusDate = useMemo(() => getFocusDate(step), [step]);

  const end = useMemo(() => endOfDay(addDays(focusDate, 15)), [focusDate]);

  const anomalies = useMemo(() => {
    const anomalies = [];

    for (const stepItem of stepItems) {
      if (['accommodation', 'closedBed', 'healthCareEnvironmentalCleaning'].includes(stepItem.type)) {
        const step = stepItem as AccommodationStep | HealthcareEnvironmentalCleaningStep | ClosedBedStep;
        if (step.anomalies) {
          anomalies.push(...step.anomalies);
        }
      }
    }

    return anomalies.flat();
  }, [stepItems]);

  // Avoid useMemo to prevent start update without date time change and timeline scroll to focusDate
  useEffect(() => {
    const newStart = getStartDate(step);

    setStart((prev) => (prev.getTime() !== newStart.getTime() ? newStart : prev));
  }, [step]);

  return (
    <Timeline start={start} end={end} rowHeaderWidth={40} scale={DEFAULT_SCALE} variant="filled" focusDate={focusDate}>
      <TimelineRow
        title={anomalies.length ? <AnomaliesIcon anomalies={anomalies} /> : <TicketIcon fontSize="small" />}
        selected
        isFirstRow
        isLastRow
      >
        {stepItems.map((stepItem) => {
          const timelineType = getTimelineType(step);

          switch (stepItem.type) {
            case 'accommodation':
              return timelineType === 'patient' ? (
                <BedAccommodationCardEvent
                  id={stepItem.step.id}
                  key={stepItem.step.id}
                  accommodation={stepItem.step}
                  start={stepItem.step.startAt}
                  end={stepItem.step.endAt}
                  color={getCardColor(stepItem.step.specificities)}
                  emoji={{
                    name: 'bed',
                  }}
                  isStartOutdated={stepItem.anomalies?.some((anomaly) => anomaly.type === 'OUTDATED_START')}
                  isEndOutdated={stepItem.anomalies?.some((anomaly) =>
                    ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END'].includes(anomaly.type)
                  )}
                  striped={stepItem.step.status === 'COMPLETED'}
                  registerSticky
                />
              ) : (
                <AccommodationCardEvent
                  registerSticky
                  id={stepItem.step.id}
                  key={stepItem.step.id}
                  start={stepItem.step.startAt}
                  end={stepItem.step.endAt}
                  specificities={stepItem.step.specificities}
                  comment={stepItem.step.comment}
                  patient={{
                    name: formatName(
                      stepItem.step.visit?.firstName,
                      stepItem.step.visit?.lastName,
                      stepItem.step.visit?.legalName,
                      stepItem.step.visit?.legalFirstName
                    ),
                    gender: stepItem.step.visit?.gender || 'UNKNOWN',
                    birthdate: stepItem.step.visit?.birthdate || new Date(),
                  }}
                  status={stepItem.step.status}
                  isEstimatedEnd={stepItem.step.isEstimatedEnd}
                  isStartOutdated={stepItem.anomalies?.some((anomaly) => anomaly.type === 'OUTDATED_START')}
                  isEndOutdated={stepItem.anomalies?.some((anomaly) =>
                    ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END'].includes(anomaly.type)
                  )}
                  emoji={{
                    name: getPatientEmojiIcon(
                      stepItem.step.visit?.gender || 'UNKNOWN',
                      getPatientAge(stepItem.step.visit?.birthdate || new Date())
                    ),
                    shape: 'circle',
                  }}
                  color={getCardColor(stepItem.step.specificities)}
                  striped={stepItem.step.status === 'COMPLETED'}
                />
              );

            case 'healthCareEnvironmentalCleaning':
              return timelineType === 'patient' ? null : (
                <HealthcareEnvironmentalCleaningEvent
                  id={stepItem.step.id}
                  key={stepItem.step.id}
                  healthcareEnvironmentalCleaning={stepItem.step}
                  start={stepItem.step.startAt}
                  end={stepItem.step.endAt}
                  color="primaryAlt"
                  emoji={{
                    name: 'bucket',
                    shape: 'square',
                  }}
                  striped={stepItem.step.status === 'DONE'}
                  registerSticky
                />
              );

            case 'closedBed':
              return (
                <CloseBedCardEvent
                  id={stepItem.step.id}
                  key={stepItem.step.id}
                  start={stepItem.start}
                  end={stepItem.end}
                  color="secondary"
                  specificities={stepItem.step.specificities}
                  comment={stepItem.step.comment}
                  emoji={{ name: 'construction' }}
                  striped
                  registerSticky
                />
              );

            case 'fare':
              return (
                <FareCardEvent
                  id={stepItem.step.id}
                  key={stepItem.step.id}
                  fare={stepItem.step}
                  start={
                    stepItem.step.startedAt ||
                    subMinutes(
                      stepItem.step.wantedDate ||
                        stepItem.step.scheduledAt ||
                        stepItem.step.endedAt ||
                        (stepItem.step.isEmergency ? stepItem.step.createdAt : new Date()),
                      10
                    )
                  }
                  end={stepItem.step.wantedDate || stepItem.step.scheduledAt || stepItem.step.endedAt || new Date()}
                  striped={stepItem.step.status === 'DONE'}
                  color="success"
                  emoji={{
                    name: 'manualWheelchair',
                    shape: 'square',
                  }}
                  registerSticky
                />
              );
            case 'externalTransport':
              return (
                <ExternalTransportCardEvent
                  key={stepItem.step.id}
                  start={stepItem.start}
                  end={stepItem.end}
                  externalTransport={stepItem.step}
                  color="success"
                  striped={stepItem.step?.status === 'DONE'}
                  emoji={{
                    name: 'ambulance',
                    shape: 'square',
                  }}
                  registerSticky
                />
              );
            default:
              return null;
          }
        })}
      </TimelineRow>
    </Timeline>
  );
};

const AnomaliesIcon = ({ anomalies }: { anomalies: Anomaly[] }) => (
  <StepsAnomaliesTooltip anomalies={anomalies}>
    <div>
      <Emoji name="warning" size={14} />
    </div>
  </StepsAnomaliesTooltip>
);

const getStartDate = (step?: StepItem): Date => {
  const fifteenDaysAgo = startOfDay(subDays(new Date(), 15));

  if (!step) {
    return fifteenDaysAgo;
  }

  return isInProgress(step) ? fifteenDaysAgo : startOfDay(subDays(step.start, 15));
};

const getFocusDate = (step?: StepItem): Date => {
  const now = new Date();

  if (!step) {
    return now;
  }

  return isInProgress(step) ? now : startOfDay(step.start);
};

const isInProgress = (step?: StepItem): boolean => {
  if (!step) {
    return false;
  }

  switch (step.type) {
    case 'accommodation':
    case 'fare':
      return step.step.status === 'IN_PROGRESS';

    case 'closedBed':
    case 'healthCareEnvironmentalCleaning':
      return step.step.status === 'IN_PROGRESS' || isCurrent(step.start, step.end);

    default:
      return false;
  }
};

const isCurrent = (start: Date, end?: Date) => {
  const now = new Date();
  return end ? isAfter(now, start) && isBefore(now, end) : isAfter(now, start);
};

export default memo(DetailsDialogTimeline);
