import { Components } from '@mui/material';
import palette from '../palette';

const MuiListItemIcon: Components['MuiListItemIcon'] = {
  styleOverrides: {
    root: {
      minWidth: 26,
      alignItems: 'center',
      color: palette.secondary.main,
    },
  },
};

export default MuiListItemIcon;
