"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FARE_READY_TO_BE_ASSIGNED_DEFAULT_CONFIGURATION = void 0;
exports.FARE_READY_TO_BE_ASSIGNED_DEFAULT_CONFIGURATION = {
    isAcknowledgeNeeded: false,
    notificationsProviders: ['WEB'],
    triggerEvent: 'FARE_READY_TO_BE_ASSIGNED',
    retryPolicy: {
        retryDelayInSeconds: 10,
        maxRetryCount: 5,
    },
};
