import { useEffect, useState } from 'react';

export const useParseQueryCount = (query: Parse.Query) => {
  const queryAsString = JSON.stringify(query.toJSON());
  const className = query.className;

  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const query = Parse.Query.fromJSON(className, JSON.parse(queryAsString));
    query.count().then((count) => {
      setCount(count);
      setIsLoading(false);
    });

    return () => {
      query.cancel();
    };
  }, [queryAsString, className]);

  return { count, isLoading };
};
