import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';
import { FormEvent } from 'react';

type CancelAccommodationDialogContentProps = {
  id: string;
  onClose: () => void;
  onSuccess: () => void;
  onBack?: () => void;
};

export const CancelAccommodationDialogContent = ({
  id,
  onClose,
  onBack,
  onSuccess,
}: CancelAccommodationDialogContentProps) => {
  const { cancel } = useAccommodation(id);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await cancel();
      toast.success({ ...i18n.accommodationCancelledSuccess, icon: <Emoji size={24} name="winkingFace" /> });
      onSuccess();
    } catch (error: any) {
      toast.error(error?.message || i18n.accommodationCancelledError);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogHeader onClose={onClose} onBack={onBack} title={i18n.cancelAccommodation.title} />
      <DialogContent>
        {i18n.cancelAccommodation.content.map((text, index) => (
          <Typography color="secondary" key={index}>
            {text}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onBack || onClose}>
          {i18n.cancel}
        </Button>
        <Button type="submit">{i18n.save}</Button>
      </DialogActions>
    </form>
  );
};

type CancelAccommodationDialogProps = {
  id: string;
  open: boolean;
  onClose: () => void;
};

export const CancelAccommodationDialog = ({ id, open, onClose }: CancelAccommodationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <CancelAccommodationDialogContent id={id} onClose={onClose} onSuccess={onClose} />
    </Dialog>
  );
};
