import { SvgIcon, SvgIconProps } from '@mui/material';

const Covid: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M6 7.996c0 .44-.36.8-.8.8-.44 0-.8-.36-.8-.8 0-.44.36-.8.8-.8.44 0 .8.36.8.8Zm3.4-1.6c.44 0 .8-.359.8-.799 0-.44-.36-.8-.8-.8-.44 0-.8.36-.8.8 0 .44.36.8.8.8Zm-2.8 0c.44 0 .8-.359.8-.799 0-.44-.36-.8-.8-.8-.44 0-.8.36-.8.8 0 .44.36.8.8.8Zm0 3.2c-.44 0-.8.359-.8.799 0 .44.36.8.8.8.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8Zm9.4-2.2v1.2c0 .328-.272.6-.6.6a.604.604 0 0 1-.6-.6h-1.232a5.578 5.578 0 0 1-1.216 2.918l.872.872.008-.008a.604.604 0 0 1 .848 0 .603.603 0 0 1 0 .847l-.848.848a.604.604 0 0 1-.848 0 .6.6 0 0 1-.008-.84l-.872-.871a5.613 5.613 0 0 1-2.912 1.207v1.232H8.6c.328 0 .6.271.6.6 0 .327-.272.599-.6.599H7.4a.604.604 0 0 1-.6-.6c0-.328.264-.591.592-.6v-1.239a5.56 5.56 0 0 1-2.904-1.207l-.872.871.008.008a.603.603 0 0 1 0 .848.604.604 0 0 1-.848 0l-.856-.856a.603.603 0 0 1 0-.847.602.602 0 0 1 .84-.008l.872-.872a5.507 5.507 0 0 1-1.2-2.902H1.2c0 .328-.272.6-.6.6a.604.604 0 0 1-.6-.6v-1.2c0-.328.272-.6.6-.6.328 0 .6.272.6.6h1.232a5.638 5.638 0 0 1 1.2-2.902l-.872-.872a.602.602 0 0 1-.84-.008.603.603 0 0 1 0-.847l.848-.848a.604.604 0 0 1 .848 0 .603.603 0 0 1 0 .848l-.008.008.872.871A5.587 5.587 0 0 1 7.384 2.44v-1.24a.603.603 0 0 1-.592-.6C6.8.273 7.072 0 7.4 0h1.2c.328 0 .6.272.6.6 0 .328-.272.6-.6.6h-.008v1.23a5.548 5.548 0 0 1 2.912 1.208l.872-.871a.593.593 0 0 1 .008-.84.604.604 0 0 1 .848 0l.848.848a.603.603 0 0 1 0 .847.604.604 0 0 1-.848 0l-.008-.008-.872.864a5.616 5.616 0 0 1 1.216 2.918H14.8c0-.328.272-.6.6-.6.328 0 .6.272.6.6Zm-4 .6a4 4 0 0 0-8 0 4 4 0 0 0 8 0Zm-4-.8c-.44 0-.8.36-.8.8 0 .44.36.8.8.8.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8Zm2.8 0c-.44 0-.8.36-.8.8 0 .44.36.8.8.8.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8Zm-1.4 2.4c-.44 0-.8.359-.8.799 0 .44.36.8.8.8.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8Z" />{' '}
    </SvgIcon>
  );
};

export default Covid;
