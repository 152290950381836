import { DialogTitle, DialogTitleProps, IconButton, IconButtonProps, Stack, Typography } from '@mui/material';
import { ArrowLeft, Close } from 'common/components/Icons';
import { MouseEvent, ReactNode } from 'react';

export type DialogHeaderProps = {
  title?: ReactNode;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  Icon?: ReactNode;
  iconVariant?: IconButtonProps['variant'];
  onBack?: () => void;
  dialogTitleProps?: DialogTitleProps;
  children?: ReactNode;
};

const DialogHeader = ({
  title = '',
  iconVariant = 'contained',
  onClose,
  onBack,
  Icon,
  dialogTitleProps,
  children,
}: DialogHeaderProps) => {
  return (
    <DialogTitle {...dialogTitleProps}>
      <Stack direction="row" justifyContent="space-between" gap={8}>
        <Stack direction="row" alignItems="center" gap={2}>
          {onBack && (
            <IconButton edge="start" onClick={onBack} variant={iconVariant}>
              <ArrowLeft />
            </IconButton>
          )}
          {Icon && Icon}
          <Typography variant="h2">{title}</Typography>
        </Stack>
        {children}
        {onClose && (
          <IconButton edge="end" onClick={onClose} variant={iconVariant}>
            <Close />
          </IconButton>
        )}
      </Stack>
    </DialogTitle>
  );
};

export default DialogHeader;
