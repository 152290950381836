import { SvgIcon, SvgIconProps } from '@mui/material';

const PrivateRoom: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#a)">
        <path d="M10.549 10.09a.722.722 0 1 0-1.31.607l.69 1.49a.723.723 0 0 0 .503.404h.004l.02.005.086.02c.076.02.187.049.32.089.27.08.622.2.968.361.352.165.66.354.869.558.205.2.266.362.266.496 0 .123-.03.189-.076.235a.525.525 0 0 1-.37.154 1.141 1.141 0 0 1-.096.004l-.04.001-.053.001-3.082.001a1.66 1.66 0 0 1-.807-.21l-.96-.568-.003-.002c-.266-.148-.457-.038-.619.177a.805.805 0 0 0-.17.216.783.783 0 0 0-.094.261c-.093.246-.083.46.178.607l.96.569.003.001c.462.258.983.393 1.512.393H12.343l.066-.003a2.816 2.816 0 0 0 .86-.19c.244-.098.532-.263.76-.541.24-.291.38-.664.38-1.106 0-.652-.326-1.163-.703-1.53-.372-.363-.841-.634-1.265-.832a8.618 8.618 0 0 0-1.347-.49l-.545-1.177Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.357 10.09a.722.722 0 0 1 1.31.608l-.694 1.497a.722.722 0 0 1-.492.4 7.906 7.906 0 0 0-.468.13c-.29.089-.671.222-1.045.397-.382.178-.718.383-.95.601-.23.218-.29.385-.29.508 0 .117.029.16.04.173a.293.293 0 0 0 .118.084.848.848 0 0 0 .288.068h1.583c.315 0 .622-.09.888-.257l1.047-.662L8.48 12.47a.722.722 0 0 1 .788 1.21l-1.793 1.168a6.085 6.085 0 0 1-.008.006l-1.051.665a3.104 3.104 0 0 1-1.66.48H3.144l-.062-.003a2.292 2.292 0 0 1-.785-.19 1.728 1.728 0 0 1-.687-.539 1.701 1.701 0 0 1-.327-1.037c0-.668.354-1.189.744-1.557.39-.369.882-.65 1.329-.86a9.747 9.747 0 0 1 1.45-.535l.55-1.189Z"
        />
        <path d="M8.03 4.98h-.038c-.675 0-1.749 0-2.773.746-.97.708-1.778 1.992-2.317 4.207-.4.12-.828.234-1.19.325-.24.06-.68.164-.828.199a.722.722 0 0 0 .317 1.409l.246-.057c.154-.036.37-.088.618-.15.493-.125 1.13-.296 1.673-.474a.722.722 0 0 0 .48-.533c.501-2.305 1.229-3.305 1.852-3.76.619-.45 1.265-.468 1.94-.468.677 0 1.323.018 1.942.469.623.454 1.35 1.454 1.851 3.76.055.25.237.452.48.532.572.188 1.242.32 1.751.406a19.87 19.87 0 0 0 .833.12l.072.009a.723.723 0 0 0 .16-1.435l-.23-.029a18.29 18.29 0 0 1-.596-.09c-.36-.06-.774-.14-1.155-.24-.54-2.211-1.346-3.493-2.316-4.2-1.023-.747-2.097-.747-2.773-.747Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.01 0a2.327 2.327 0 1 0 0 4.653A2.327 2.327 0 0 0 8.01 0Zm-.92 2.327a.92.92 0 1 1 1.841 0 .92.92 0 0 1-1.84 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PrivateRoom;
