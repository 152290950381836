import { Popper, PopperProps } from '@mui/material';
import { color } from 'common/theme';
import styled from 'styled-components';

type PatientAutocompletePopperProps = PopperProps;

const PatientAutocompletePopper: React.FC<PatientAutocompletePopperProps> = (props) => {
  return <AutocompletePopper {...props} />;
};

export default PatientAutocompletePopper;

const AutocompletePopper = styled(Popper)`
  padding: 12px;
  & .MuiAutocomplete-listbox {
    padding: 8px;
    & > .MuiAutocomplete-option {
      border-radius: 8px;
      padding: 0px;
    }
    & > .Mui-focused {
      background-color: ${color.blue[5]};
    }
  }
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;
