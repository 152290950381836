import { PatientGender } from '@ambuliz/sabri-core';
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import ActionButton from 'common/components/ActionButton';
import { MoreVertical, Plus } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { format } from 'date-fns';
import { AddAccommodationDialog } from 'kurt/components';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import { BedStatus } from 'kurt/pages/Unit/UnitManagement/UnitManagementFilters';
import { useRef, useState } from 'react';

const AvailableBedRow = ({
  name,
  bedId,
  unitId,
  unitName,
  gender,
  displayUnitColumn,
  nextSlotAt,
  status,
  isReadOnly = false,
  onClick,
}: {
  name: string;
  bedId: string;
  unitId: string;
  unitName: string;
  gender: PatientGender;
  displayUnitColumn: boolean;
  nextSlotAt?: Date;
  status: BedStatus;
  isReadOnly?: boolean;
  onClick: (id: string) => void;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [addAccommodationDialogOpen, setAddAccommodationDialogOpen] = useState(false);

  const handleOpenModal = () => {
    setAddAccommodationDialogOpen(true);
    setMenuOpen(false);
  };

  const anchorRef = useRef(null);
  return (
    <>
      <TableRow
        onClick={() => onClick(bedId)}
        hover
        sx={{
          cursor: 'pointer',
        }}
      >
        <TableCell sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Typography noWrap fontWeight={600}>
            {name}
          </Typography>
        </TableCell>
        {displayUnitColumn && (
          <TableCell>
            <Typography variant="body2" color="secondary">
              {unitName}
            </Typography>
          </TableCell>
        )}
        <TableCell width={100}>
          <BedStatusChip status={status} gender={gender} />
        </TableCell>
        <TableCell>
          {!isReadOnly && (
            <ActionButton
              icon={<Plus />}
              onClick={(event) => {
                event.stopPropagation();
                handleOpenModal();
              }}
            >
              {i18n.placePatient}
            </ActionButton>
          )}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          {nextSlotAt && (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(new Date(nextSlotAt), 'dd/MM - HH:mm')}
            </Typography>
          )}
        </TableCell>
        <TableCell></TableCell>
        <TableCell width={16} sx={{ paddingRight: 2 }}></TableCell>
        <TableCell width={16} sx={{ paddingLeft: 2 }}>
          {!isReadOnly && (
            <Box display="flex">
              <IconButton
                ref={anchorRef}
                onClick={(event) => {
                  event.stopPropagation();
                  setMenuOpen(true);
                }}
              >
                <MoreVertical />
              </IconButton>
            </Box>
          )}
        </TableCell>
      </TableRow>
      <Menu
        open={menuOpen}
        anchorEl={anchorRef.current}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleOpenModal}>{i18n.addNewAccommodation}</MenuItem>
      </Menu>
      {addAccommodationDialogOpen && (
        <AddAccommodationDialog
          open={addAccommodationDialogOpen}
          onClose={() => setAddAccommodationDialogOpen(false)}
          onSuccess={() => setAddAccommodationDialogOpen(false)}
          accommodation={{ bedId: bedId, unitId, startAt: new Date(), isEndEstimated: true }}
        />
      )}
    </>
  );
};

export default AvailableBedRow;
