import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type ItemContentProps = {
  children?: ReactNode;
  justify?: 'left' | 'center' | 'right';
  align?: 'center' | 'flex-end' | 'baseline';
};

const ItemContent = ({ children, justify = 'left', align = 'flex-end' }: ItemContentProps) => {
  return (
    <Stack
      alignItems={align}
      direction="row"
      spacing={2}
      width="100%"
      justifyContent={justify === 'left' ? 'flex-start' : justify === 'center' ? 'center' : 'flex-end'}
    >
      {children}
    </Stack>
  );
};

export default ItemContent;
