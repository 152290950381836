import { getAvailableBeds } from '@ambuliz/sabri-core';
import { Grid, LinearProgress, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { InOutFlow } from 'common/components/Statistics';
import { palette } from 'common/theme';
import { hyphenIfUndefined, progressValue } from 'common/utils';
import { format, isBefore } from 'date-fns';
import BedsAvailabilities from 'kurt/components/Department/Table/BedsAvailabilities';
import CustomGrid from 'kurt/components/Department/Table/CustomGrid';
import OccupancyInfos from 'kurt/components/Department/Table/OccupancyInfos';
import { OUTDATED_UPDATED_AT, getThresholdedColor } from 'kurt/const';
import { NormalizedDepartment } from 'kurt/hooks/useDepartments';
import React from 'react';

export type NestedListItemProps = NormalizedDepartment & {
  displayUpdatedAtColumn: boolean;
  columns: number[];
  isMedium: boolean;
};

const NestedListItem: React.FC<NestedListItemProps> = ({
  name,
  occupancy,
  displayUpdatedAtColumn,
  columns,
  isMedium,
}) => {
  return (
    <Grid item container>
      <Grid container spacing={4} alignItems="center">
        <CustomGrid item percentile={columns[0]} paddingBottom={4}>
          <EllipsisTypography fontWeight={600} maxLines={2}>
            {name}
          </EllipsisTypography>
        </CustomGrid>
        <CustomGrid item percentile={columns[1]} paddingBottom={4}>
          <Grid container alignItems="center" spacing={2}>
            <Grid sx={{ width: 48 }} item>
              {occupancy?.rate && (
                <LinearProgress
                  value={progressValue(occupancy.rate)}
                  color={getThresholdedColor(occupancy.rate, 'secondary')}
                />
              )}
            </Grid>
            <Grid item sx={{ minWidth: 36 }}>
              <Typography>{hyphenIfUndefined(occupancy?.rate)}%</Typography>
            </Grid>
            <Grid item>
              <OccupancyInfos occupancy={occupancy} />
            </Grid>
          </Grid>
        </CustomGrid>

        <CustomGrid item percentile={columns[2]} paddingBottom={4}>
          <InOutFlow admission={occupancy?.admission} discharge={occupancy?.discharge} />
        </CustomGrid>
        <CustomGrid item percentile={columns[3]} paddingBottom={4}>
          <BedsAvailabilities
            {...occupancy?.dDayAvailabilities}
            total={getAvailableBeds(
              occupancy?.dDayAvailabilities?.male,
              occupancy?.dDayAvailabilities?.female,
              occupancy?.dDayAvailabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>

        <CustomGrid item percentile={columns[4]} paddingBottom={4}>
          <BedsAvailabilities
            {...occupancy?.h24Availabilities}
            total={getAvailableBeds(
              occupancy?.h24Availabilities?.male,
              occupancy?.h24Availabilities?.female,
              occupancy?.h24Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        <CustomGrid item percentile={columns[5]} paddingBottom={4}>
          <BedsAvailabilities
            {...occupancy?.h48Availabilities}
            total={getAvailableBeds(
              occupancy?.h48Availabilities?.male,
              occupancy?.h48Availabilities?.female,
              occupancy?.h48Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        {displayUpdatedAtColumn && (
          <CustomGrid
            item
            container
            justifyContent="flex-end"
            percentile={columns[6]}
            paddingBottom={4}
            paddingRight={4}
          >
            {occupancy?.updatedAt ? (
              <Typography
                variant="body2"
                color={isOutdated(occupancy.updatedAt) ? palette.warning.main : palette.text.secondary}
              >
                <Typography fontWeight={600} component="span" variant="body2">
                  {getDateText(occupancy.updatedAt)}
                </Typography>
                {' - ' + format(occupancy.updatedAt, 'HH:mm')}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                -
              </Typography>
            )}
          </CustomGrid>
        )}
      </Grid>
    </Grid>
  );
};

export default NestedListItem;

const isOutdated = (date: Date) => isBefore(date, OUTDATED_UPDATED_AT);
const getDateText = (date: Date) => (isOutdated(date) ? '⚠ ' : '') + format(date, 'dd/MM');
