import { AnomalyStatus, AnomalyType } from '@ambuliz/sabri-core';
import { Link, ListItemText, MenuItem, Stack } from '@mui/material';

import { FilterSelect } from 'common/components';
import { i18n } from 'common/locale';
import { PageSection, useAppBarContext } from 'core/layout';
import { useBedOptions } from 'core/locations/useBedOptions';
import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import AnomalyStatusSelect from './AnomalyStatusSelect';
import AnomalyTypesSelect from './AnomalyTypesSelect';
import useAnomaliesPageFilters from './useAnomaliesPageFilters';

const AnomaliesPageFilters = ({ unitId }: { unitId: string }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const appBarContext = useAppBarContext();

  const { status, type, areaId, resetFilters } = useAnomaliesPageFilters();

  const { bedOptions, isLoading: areBedsLoading } = useBedOptions(unitId);

  const hasActiveFilters = status || type || areaId;

  const handleStatusChange = (status: AnomalyStatus) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), status });
  };
  const handleTypeChange = (type: AnomalyType) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), type });
  };
  const handleAreaIdChange = (areaId: string) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), areaId });
  };

  return (
    <PageSection sticky={{ top: appBarContext.appBarHeight, withShadow: true }}>
      <Stack direction="row" alignItems="center" spacing={4}>
        <AnomalyStatusSelect value={status} onChange={handleStatusChange} />
        <AnomalyTypesSelect value={type} onChange={handleTypeChange} />
        <FilterSelect
          searchable
          label={i18n.bedNumber}
          value={areaId}
          onChange={handleAreaIdChange}
          loading={areBedsLoading}
        >
          {bedOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </FilterSelect>

        {hasActiveFilters && (
          <Link component="button" variant="body1" onClick={resetFilters}>
            {i18n.clearFilters}
          </Link>
        )}
      </Stack>
    </PageSection>
  );
};

export default memo(AnomaliesPageFilters);
