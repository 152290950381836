import { Journey } from '@ambuliz/sabri-core';
import { Chip, CircularProgress } from '@mui/material';
import useParseResource from 'common/hooks/useParseResource';
import { memo } from 'react';
import { i18n } from 'sabri/locales';

type FareListStepCellProps = {
  fareId: string;
  journeyId?: string;
  isReturnTrip?: boolean;
};

const FareListStepCell = ({ fareId, journeyId, isReturnTrip = false }: FareListStepCellProps) => {
  const { resource: journey, isLoading } = useParseResource(journeyId, Journey, {
    enabled: !!journeyId,
  });

  let label = '';
  if (!isLoading && journey) {
    const currentFareIndex = journey.fares.findIndex((journeyFare) => journeyFare.id === fareId);
    const journeySteps = journey.roundTrip ? journey.fares.length - 2 : journey.fares.length - 1;

    if (currentFareIndex === 0 || journey.fares.length === 1) {
      label = i18n.outward;
    } else if (currentFareIndex === journey.fares.length - 1 && journey.roundTrip) {
      label = i18n.return;
    } else {
      label = `${i18n.step} ${currentFareIndex} / ${journeySteps}`;
    }
  } else {
    label = isReturnTrip ? i18n.return : i18n.outward;
  }

  return isLoading ? <CircularProgress size={20} /> : <Chip variant="outlined" label={label} color="default" />;
};

export default memo(FareListStepCell);
