import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagementModal } from 'superadmin/components/ManagementDrawer';
import { useArea } from 'superadmin/context';
import { Area } from 'superadmin/types';
import AreaForm from '../components/form/AreaForm';
import areaManagementRoutes from '../routes';

const AreaCreateModal: React.FC = () => {
  const { id = '' } = useParams();

  const { area, create } = useArea(id);
  const [open, setOpen] = useState(true);

  const handleSubmit = async (newArea: Partial<Area>) => {
    try {
      if (newArea.name) {
        await create({
          externalIds: newArea.externalIds || [],
          healthCenterId: area?.healthCenter.id || '',
          externalTypeCode: newArea.externalTypeCode,
          isSearchable: newArea.isSearchable || false,
          name: newArea.name,
          parent: id,
          excludedFromOccupancy: newArea.excludedFromOccupancy,
        });
        setOpen(false);
        toast.success(i18n.messageSuccessAreaCreate);
      }
    } catch (error: any) {
      toast.error(error.message || i18n.messageErrorUnitAction);
    }
  };

  return (
    <ManagementModal
      title={`${i18n.createChildArea} ${area?.name}`}
      open={open}
      redirectUrl={areaManagementRoutes.areas}
      loading={!area}
    >
      <AreaForm parent={id} isSearchable onSubmit={handleSubmit} onCancel={() => setOpen(false)} />
    </ManagementModal>
  );
};

export default AreaCreateModal;
