import { i18n } from 'sabri/locales';

export const getJourneyFareChipLabel = ({
  index,
  total,
  isOutwardFare,
  isReturnFare,
}: {
  isReturnFare?: boolean;
  isOutwardFare?: boolean;
  index: number;
  total: number;
}) => {
  if (isOutwardFare) {
    return i18n.outward;
  }
  if (isReturnFare) {
    return i18n.return;
  }
  return `${i18n.step} ${index}/${total}`;
};
