import { Fare, Patient } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

type UseFaresProps = {
  visitId?: string;
  locationId?: string;
};

const useFares = ({ visitId }: UseFaresProps) => {
  const enabled = !!visitId;
  const query = enabled ? new Parse.Query(Fare) : null;

  if (visitId) {
    query?.equalTo('patient', Patient.createWithoutData(visitId));
  }

  const { results, isLoading, isEnabled } = useParseQuery(query, { enabled });

  return {
    fares: results,
    isLoading,
    isEnabled,
  };
};

export default useFares;
