import { Stack } from '@mui/material';
import { color } from 'common/theme';
import shadows from 'common/theme/shadows';
import { useEffect, useRef, useState } from 'react';

export const HEADER_HEIGHT = 62;
const NotificationPanelSectionSubheader = ({
  children,
  container,
}: {
  children: React.ReactNode;
  container: React.RefObject<HTMLDivElement>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    const element = container.current;

    if (element) {
      const handleScroll = () => {
        if (ref.current) {
          setStuck(
            element.scrollTop > 0 &&
              ref.current.getBoundingClientRect().top - element.getBoundingClientRect().top <= HEADER_HEIGHT
          );
        }
      };

      handleScroll();
      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  });

  return (
    <Stack
      ref={ref}
      direction="row"
      justifyContent="space-between"
      position="sticky"
      top={HEADER_HEIGHT}
      bgcolor="#FFF"
      paddingX={8}
      paddingY={2}
      zIndex={2}
      marginX={-2}
      boxShadow={stuck ? shadows[1] : 'none'}
      borderBottom={stuck ? `1px solid ${color.grey[10]}` : 'none'}
      style={{
        transition: 'box-shadow 0.2s ease-out',
      }}
    >
      {children}
    </Stack>
  );
};

export default NotificationPanelSectionSubheader;
