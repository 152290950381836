import { IconButton, Stack } from '@mui/material';
import ArrowDownIcon from 'common/components/Icons/ArrowDownIcon';
import ArrowUpIcon from 'common/components/Icons/ArrowUpIcon';
import TrashCanIcon from 'common/components/Icons/TrashCan';
import { transition } from 'common/theme';
import { useFormContext } from 'react-hook-form';
import { FormValue, StepFormValue, formKeys } from './JourneyForm';

type JourneyStepActionsProps = {
  index: number;
  hideActions?: boolean;
  onSwapUp: (index: number) => void;
  onSwapDown: (index: number) => void;
  onRemove: (index: number) => void;
};

const JourneyStepActions = ({
  index,
  hideActions = false,
  onSwapUp,
  onSwapDown,
  onRemove,
}: JourneyStepActionsProps) => {
  const { watch } = useFormContext<FormValue>();

  const steps = watch(formKeys.steps) as StepFormValue[];

  const swapUpDisabled =
    index === 0 ||
    hideActions ||
    steps[index]?.disabled ||
    (index !== 0 && (steps[index - 1]?.disabled || steps[index - 1]?.actionsDisabled));
  const swapDownDisabled = index === steps.length - 1 || hideActions || steps[index]?.disabled;
  const removeDisabled = hideActions || steps[index]?.disabled;

  return (
    <Stack direction="row" flex={1}>
      <IconButton
        onClick={() => onSwapUp(index)}
        disabled={swapUpDisabled}
        sx={{ transition: `opacity ${transition}`, opacity: swapUpDisabled ? 0 : 1 }}
      >
        <ArrowUpIcon />
      </IconButton>
      <IconButton
        onClick={() => onSwapDown(index)}
        disabled={swapDownDisabled}
        sx={{ transition: `opacity ${transition}`, opacity: swapDownDisabled ? 0 : 1 }}
      >
        <ArrowDownIcon />
      </IconButton>
      <IconButton
        onClick={() => onRemove(index)}
        disabled={removeDisabled}
        sx={{ transition: `opacity ${transition}`, opacity: removeDisabled ? 0 : 1 }}
      >
        <TrashCanIcon />
      </IconButton>
    </Stack>
  );
};

export default JourneyStepActions;
