import { Components } from '@mui/material';
import color from '../color';

const MuiAvatarGroup: Components['MuiAvatarGroup'] = {
  styleOverrides: {
    root: {
      '& .MuiAvatar-root': {
        border: 'none',
        outline: '1px solid white',
        marginLeft: -3,
      },
      '& .MuiAvatarGroup-avatar': {
        height: '20px',
        width: '20px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        'background-color': 'white',
        color: color.grey[40],
        marginLeft: '1px !important',
      },
    },
  },
};

export default MuiAvatarGroup;
