import { useInterval } from 'common/utils';
import { useState } from 'react';

const calculateNextRefreshInterval = (minutes: number = 1) => {
  const oneMinuteInMilliseconds = minutes * 60 * 1000;
  return oneMinuteInMilliseconds - (new Date().getTime() % oneMinuteInMilliseconds);
};

export const useEveryMinutes = (minutes: number = 1) => {
  const [date, setDate] = useState(new Date());
  const [nextRefreshInterval, setNextRefreshInterval] = useState(calculateNextRefreshInterval(minutes));

  useInterval(() => {
    setNextRefreshInterval(calculateNextRefreshInterval(minutes));
    setDate(new Date());
  }, nextRefreshInterval);

  return date;
};
