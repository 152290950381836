import { accommodationSpecificities, AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Box, Checkbox, Grid, ListItemText, MenuItem } from '@mui/material';
import { FilterSelect } from 'common/components/Form';
import { i18n } from 'common/locale';
import React from 'react';
import { SpecificityChip } from '../SpecifityChip';

type BedSpecifitiesSelectProps = {
  value: AccommodationSpecificity[];
  onChange: (value: AccommodationSpecificity[]) => void;
  loading?: boolean;
  isBedClosed?: boolean;
  isNew?: boolean;
};

const BedSpecifitiesSelect: React.FC<BedSpecifitiesSelectProps> = ({
  value,
  onChange,
  loading,
  isBedClosed,
  isNew,
}) => {
  const deleteSpecificity = (specificityToDelete: AccommodationSpecificity) =>
    onChange(value.filter((specificity) => specificity !== specificityToDelete));

  // When the bed is closed, no need to display "ACCOMMODATED" or "PRIVATE_ROOM"
  const specificities = accommodationSpecificities.filter(
    (specificity) =>
      !(isNew && ['WAITING_FOR_DOWNSTREAM_BED']?.includes(specificity)) &&
      !(isBedClosed && ['ACCOMMODATED', 'PRIVATE_ROOM', 'WAITING_FOR_DOWNSTREAM_BED'].includes(specificity))
  );

  return (
    <>
      <FilterSelect multiple value={value} onChange={onChange} loading={loading} label={i18n.bedSpecificities.title}>
        {specificities.map((specificity) => (
          <MenuItem key={specificity} value={specificity} disabled={loading}>
            <Checkbox checked={value.includes(specificity)} />
            <ListItemText primary={i18n.bedSpecificities.options[specificity]} />
          </MenuItem>
        ))}
      </FilterSelect>
      {value.length > 0 && (
        <Box style={{ marginTop: 8 }}>
          <Grid container spacing={1}>
            {value.map((specificity) => (
              <Grid item key={specificity}>
                <SpecificityChip
                  disabled={loading}
                  onDelete={() => deleteSpecificity(specificity)}
                  specificity={specificity}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BedSpecifitiesSelect;
