import { SvgIcon, SvgIconProps } from '@mui/material';

const Kanban: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13039 1.756C3.13039 1.61462 3.01578 1.5 2.87439 1.5H1.756C1.61462 1.5 1.5 1.61461 1.5 1.756V11.244C1.5 11.3854 1.61462 11.5 1.756 11.5H2.87439C3.01578 11.5 3.13039 11.3854 3.13039 11.244V1.756ZM0.5 0C0.223858 0 0 0.223858 0 0.5V12.5C0 12.7761 0.223858 13 0.5 13H4.13039C4.40653 13 4.63039 12.7761 4.63039 12.5V0.5C4.63039 0.223858 4.40653 0 4.13039 0L0.5 0Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82078 1.756C8.82078 1.61462 8.70617 1.5 8.56478 1.5H7.44639C7.30501 1.5 7.19039 1.61461 7.19039 1.756V7.744C7.19039 7.88539 7.30501 8 7.44639 8H8.56478C8.70617 8 8.82078 7.88539 8.82078 7.744V1.756ZM6.19039 0C5.91425 0 5.69039 0.223857 5.69039 0.5V9C5.69039 9.27614 5.91425 9.5 6.19039 9.5H9.82078C10.0969 9.5 10.3208 9.27614 10.3208 9V0.5C10.3208 0.223858 10.0969 0 9.82078 0L6.19039 0Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5112 1.756C14.5112 1.61462 14.3966 1.5 14.2552 1.5H13.1368C12.9954 1.5 12.8808 1.61461 12.8808 1.756V14.244C12.8808 14.3854 12.9954 14.5 13.1368 14.5H14.2552C14.3966 14.5 14.5112 14.3854 14.5112 14.244V1.756ZM11.8808 0C11.6046 0 11.3808 0.223858 11.3808 0.5V15.5C11.3808 15.7761 11.6046 16 11.8808 16H15.5112C15.7873 16 16.0112 15.7761 16.0112 15.5L16.0112 0.5C16.0112 0.223858 15.7873 0 15.5112 0L11.8808 0Z"
      />
    </SvgIcon>
  );
};

export default Kanban;
