import { Area, Fare, FareAssignation, HealthCenter, Incident, Patient, Porter, Unit } from '@ambuliz/sabri-core';
import { schema } from 'normalizr';
import { omit } from 'ramda';

export enum EntityNames {
  fares = 'fares',
  patients = 'patients',
  areas = 'areas',
  porters = 'porters',
  healthCenters = 'healthCenters',
  fareAssignations = 'fareAssignations',
  incidents = 'incidents',
  units = 'units',
}

//SCHEMAS

export const getPointerSchema = (entityName: EntityNames) =>
  new schema.Entity(entityName, undefined, {
    idAttribute: 'objectId',
    processStrategy: (value: Parse.Object.ToJSON<Parse.Object> & Parse.JSONBaseAttributes, parent, key) => {
      if (parent[key]) {
        return undefined;
      }
      return value;
    },
  });

export const areaSchema = new schema.Entity(EntityNames.areas, undefined, {
  idAttribute: 'objectId',
  processStrategy: (value: Parse.Object.ToJSON<Area> & Parse.JSONBaseAttributes) => ({
    parentId: value.parent?.objectId,
    healthCenterId: value.healthCenter?.objectId,
    ...omit(['parent', 'healthCenter'], value),
  }),
});

export const unitSchema = new schema.Entity(EntityNames.units, undefined, {
  idAttribute: 'objectId',
  processStrategy: (value: Parse.Object.ToJSON<Unit> & Parse.JSONBaseAttributes) => ({
    areasIds: (value.areas || []).map((area) => area.objectId),
    healthCenterId: value.healthCenter?.objectId,
    ...omit(['areas', 'healthCenter'], value),
  }),
});

export const fareSchema = new schema.Entity(EntityNames.fares, undefined, {
  idAttribute: 'objectId',
  processStrategy: (value: Parse.Object.ToJSON<Fare> & Parse.JSONBaseAttributes) => ({
    fromAreaId: value.fromArea?.objectId,
    healthCenterId: value.healthCenter?.objectId,
    toAreaId: value.toArea?.objectId,
    fromUnitId: value.fromUnit?.objectId,
    wantedDate: value.wantedDate?.iso,
    startedAt: value.startedAt?.iso,
    scheduledAt: value.scheduledAt?.iso,
    patientCareAt: value.patientCareAt?.iso,
    canceledAt: value.canceledAt?.iso,
    endedAt: value.endedAt?.iso,
    isReturnTripFromFareId: value.isReturnTripFromFare?.objectId,
    patientId: value.patient?.objectId,
    toUnitId: value.toUnit?.objectId,
    assignationsIds: value.assignations?.map((assignation) => assignation.objectId) || [],
    portersIds: value.porters?.map((porter) => porter.objectId) || [],
    incidentsIds: value.incidents?.map((incident) => incident.objectId) || [],
    lastUpdateById: value.lastUpdateBy?.objectId,
    createdById: value.createdBy?.objectId,
    journeyId: value.journey?.objectId,
    ...omit(
      [
        'fromArea',
        'toArea',
        'fromUnit',
        'toUnit',
        'patient',
        'healthCenter',
        'wantedDate',
        'startedAt',
        'scheduledAt',
        'patientCareAt',
        'canceledAt',
        'endedAt',
        'assignations',
        'porters',
        'incidents',
        'lastUpdateBy',
        'createdBy',
        'journey',
      ],
      value
    ),
  }),
});

const fareAssignationSchema = new schema.Entity(EntityNames.fareAssignations, undefined, {
  idAttribute: 'objectId',
  processStrategy: (value: Parse.Object.ToJSON<FareAssignation> & Parse.JSONBaseAttributes) => ({
    porterId: value.porter?.objectId,
    fareId: value.fare?.objectId,
    acknowledgedAt: value.acknowledgedAt?.iso,
    ...omit(['porter', 'fare', 'acknowledgedAt'], value),
  }),
});

export const incidentSchema = new schema.Entity(EntityNames.incidents, undefined, {
  idAttribute: 'objectId',
  processStrategy: (value: Parse.Object.ToJSON<Incident> & Parse.JSONBaseAttributes) => ({
    porterId: value.porter?.objectId,
    fareId: value.fare?.objectId,
    ...omit(['porter', 'fare'], value),
  }),
});

export const patientSchema = new schema.Entity(EntityNames.patients, undefined, {
  idAttribute: 'objectId',
  processStrategy: (value: Parse.Object.ToJSON<Patient> & Parse.JSONBaseAttributes) => ({
    healthCenterId: value.healthCenter.objectId,
    unitId: value.unit?.objectId,
    areaId: value.area?.objectId,
    birthdate: value.birthdate.iso,
    ...omit(['healthCenter', 'birthdate', 'unit', 'area'], value),
  }),
});

export const porterSchema = new schema.Entity(EntityNames.porters, undefined, {
  idAttribute: 'objectId',
  processStrategy: (value: Parse.Object.ToJSON<Porter> & Parse.JSONBaseAttributes) => ({
    nextAreaId: value.nextArea?.objectId,
    lastAreaId: value.lastArea?.objectId,
    nextUnitId: value.nextUnit?.objectId,
    lastUnitId: value.lastUnit?.objectId,
    userId: value.user?.objectId,
    lastActivityAt: value.lastActivityAt?.iso,
    lastAreaAt: value.lastAreaAt?.iso,
    ...omit(['nextArea', 'lastArea', 'user', 'lastActivityAt', 'lastAreaAt', 'lastUnit', 'nextUnit'], value),
  }),
});

//Entity name to className converter
export const entityNameToClassName: Record<EntityNames, Object> = {
  [EntityNames.fares]: Fare,
  [EntityNames.units]: Unit,
  [EntityNames.patients]: Patient,
  [EntityNames.areas]: Area,
  [EntityNames.porters]: Porter,
  [EntityNames.healthCenters]: HealthCenter,
  [EntityNames.fareAssignations]: FareAssignation,
  [EntityNames.incidents]: Incident,
};

//Entity name to schema converter
export const entityNameToSchema: Record<EntityNames, schema.Entity> = {
  [EntityNames.fares]: fareSchema,
  [EntityNames.patients]: patientSchema,
  [EntityNames.areas]: areaSchema,
  [EntityNames.units]: unitSchema,
  [EntityNames.porters]: porterSchema,
  [EntityNames.healthCenters]: getPointerSchema(EntityNames.healthCenters),
  [EntityNames.fareAssignations]: fareAssignationSchema,
  [EntityNames.incidents]: incidentSchema,
};
