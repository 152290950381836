import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckFilledIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M0 8c0-4.416 3.584-8 8-8s8 3.584 8 8-3.584 8-8 8-8-3.584-8-8Zm11.104-2.968L6.4 9.736 4.093 7.435a.798.798 0 0 0-1.128 1.13l2.728 2.728a1 1 0 0 0 1.414 0l5.13-5.129a.8.8 0 0 0-1.133-1.132Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default CheckFilledIcon;
