import { cancellableConfirmedFareStatus, Fare, WebUserRole } from '@ambuliz/sabri-core';
import { ArrowRightAlt, ChatBubbleRounded, Group, Place, SwapHoriz, WarningRounded } from '@mui/icons-material';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { hasAccessToRoute } from 'core/authentication/navigationACL';
import { useSelector } from 'react-redux';
import FareListStepCell from 'sabri/components/FareList/FareListStepCell';
import { FilterItem } from 'sabri/components/FiltersController/FiltersController';
import LocationDetails from 'sabri/components/LocationDetails/LocationDetails';
import { TableListColumn } from 'sabri/components/TableList/TableList';
import { CAN_REPORT_INCIDENT_ROLES } from 'sabri/const/canReportIncidentRoles';
import { CAN_TICK_FARE_ROLES } from 'sabri/const/canTickFareRoles';
import { routeNamesCommon } from 'sabri/const/routeNames';
import { ChipFareStatus } from '../components/ChipFareStatus/ChipFareStatus';
import { DateCell } from '../components/DateCell/DateCell';
import { FareListActions } from '../components/FareList/FareListActions/FareListActions';
import { FareListAssignation } from '../components/FareList/FareListAssignation/FareListAssignation';
import { IconInfo } from '../components/IconInfo/IconInfo';
import { PatientName } from '../components/PatientName/PatientName';
import { SelectorCheckbox } from '../components/SelectorCheckbox/SelectorCheckbox';
import { SelectorRadio } from '../components/SelectorRadio/SelectorRadio';
import { SelectorTextField } from '../components/SelectorTextField/SelectorTextField';
import { getFarePositionIcon } from '../const/fare';
import { i18n } from '../locales';
import { FareStored } from '../store/reducers/entity';
import { FareDenormalized, selectIsFareGrouped } from '../store/selectors/fare';
import { positionsFilter, statusFilter, yesNoFilter } from './filters';
export const isFareDone = (fare?: FareStored | Fare) => {
  return fare?.status ? ['CANCELED', 'DONE', 'FAILED'].includes(fare.status) : true;
};

export const isFareAssignableToPorter = (fare?: FareStored | Fare) => {
  return fare?.status ? !['CANCELED', 'DONE'].includes(fare.status) : true;
};

export const isFareDeletable = (fare?: FareStored | Fare): boolean => {
  return fare?.status
    ? ['DRAFT', 'WAITING', 'PENDING', 'ASSIGNED', 'PATIENT_CARE', 'STARTED', 'FAILED'].includes(fare.status)
    : false;
};

export const isFareTickable = (fare?: FareStored | Fare): boolean => {
  return fare?.status
    ? ['ASSIGNED', 'IN_PROGRESS', 'PATIENT_CARE', 'PENDING', 'WAITING', 'STARTED'].includes(fare.status)
    : false;
};

export const isFareConfirmable = (fare: FareStored | Fare): boolean =>
  !!(fare.needUnitConfirmation && fare.status === 'WAITING');

export const isFareConfirmationCancellable = (fare: FareStored | Fare): boolean => {
  return !!(fare.needUnitConfirmation && fare.status && cancellableConfirmedFareStatus.includes(fare.status));
};

export const isFareAssignable = (fare?: FareStored | Fare): boolean => {
  return fare?.status
    ? ['ASSIGNED', 'FAILED', 'IN_PROGRESS', 'PATIENT_CARE', 'PENDING', 'STARTED'].includes(fare.status)
    : false;
};

export const isFareEditable = (fare?: FareStored | Fare): boolean => {
  return fare?.status
    ? ['DRAFT', 'WAITING', 'PENDING', 'ASSIGNED', 'PATIENT_CARE', 'STARTED', 'FAILED'].includes(fare.status)
    : false;
};

export const canReportIncident = (role: WebUserRole) => CAN_REPORT_INCIDENT_ROLES.includes(role);
export const canTickFare = (role: WebUserRole) => CAN_TICK_FARE_ROLES.includes(role);

const renderFareReference = (fare: FareStored) => (
  <Typography noWrap variant="body2">
    {fare.reference}
  </Typography>
);

const renderFareStatus = (fare: FareStored) =>
  fare.status && (
    <ChipFareStatus
      status={fare.needUnitConfirmation && fare.status === 'PENDING' ? 'PULLED' : fare.status}
      isReturn={!!fare.isReturnTripFromFareId}
    />
  );

const renderFareInfos = ({ incidentsIds, comment }: FareStored) => {
  const warningStyle = { width: 22, height: 22, marginBottom: 4 };
  const chatStyle = { width: 17, height: 17 };
  return (
    <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
      {incidentsIds && incidentsIds.length > 0 ? (
        <Tooltip title={i18n.incidentWithCount(incidentsIds.length)}>
          <div>
            <IconInfo icon={<WarningRounded style={warningStyle} />} color="#F5A623" />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={i18n.anyIncidentsOnFare}>
          <div>
            <IconInfo icon={<WarningRounded style={warningStyle} />} color="#EEEEEE" />
          </div>
        </Tooltip>
      )}
      <Box marginLeft={1}>
        {comment ? (
          <Tooltip title={comment}>
            <div>
              <IconInfo icon={<ChatBubbleRounded style={chatStyle} />} color="#2196F3" />
            </div>
          </Tooltip>
        ) : (
          <IconInfo icon={<ChatBubbleRounded style={chatStyle} />} color="#EEEEEE" />
        )}
      </Box>
    </Box>
  );
};

const FarePatient = ({ fare }: { fare: FareStored }) => {
  const isGrouped = useSelector(selectIsFareGrouped(fare.groupId));

  return isGrouped ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isGrouped && <Group />}
      <PatientName style={{ marginLeft: 8 }} id={fare.patientId} />
    </div>
  ) : (
    <PatientName id={fare.patientId} />
  );
};

const renderFareWantedDate = ({ wantedDate, isEmergency }: FareStored) => {
  if (isEmergency) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Chip variant="rounded" label={i18n.urgent.toUpperCase()} color="error" size="small" />
      </Box>
    );
  }
  return wantedDate ? <DateCell displaySchedule date={new Date(wantedDate)} /> : '--';
};

const renderFarePosition = ({ position }: FareStored) => {
  if (position) {
    const Icon = getFarePositionIcon(position);
    const label = i18n.farePosition[position];
    return (
      <Box display="flex" alignItems="center" flex={1}>
        <Icon />
        <Box marginLeft={1}>{label}</Box>
      </Box>
    );
  }
};

const renderFareRoundTrip = ({ roundTrip, isReturnTripFromFareId }: FareStored) => {
  const renderIcon = () => {
    if (isReturnTripFromFareId) {
      return <Place />;
    }
    if (roundTrip) {
      return <SwapHoriz />;
    }
    return <ArrowRightAlt />;
  };

  return <Box textAlign="center">{renderIcon()}</Box>;
};

const renderFareAssignation = (fare: FareStored) => <FareListAssignation fareId={fare.objectId} />;

const renderFareActions = (fare: FareStored) => <FareListActions fareId={fare.objectId} journeyId={fare.journeyId} />;

const renderFareCreatedAt = (fare: FareStored) => (
  <DateCell diffFromNow displaySchedule date={new Date(fare.createdAt)} />
);

const sortFaresByStatus = (fareA: FareDenormalized, fareB: FareDenormalized) => {
  if (fareA.status && fareB.status) {
    const statusA = i18n.fareStatus[fareA.status];
    const statusB = i18n.fareStatus[fareB.status];
    return statusB.localeCompare(statusA);
  }
  return 0;
};

const sortFaresByPosition = (fareA: FareDenormalized, fareB: FareDenormalized) => {
  if (fareA.position && fareB.position) {
    return i18n.farePosition[fareB.position].localeCompare(i18n.farePosition[fareA.position]);
  }
  return 0;
};

const sortFaresByReference = (fareA: FareDenormalized, fareB: FareDenormalized) => {
  const referenceA = fareA.reference && fareA.reference.split('-').join('');
  const referenceB = fareB.reference && fareB.reference.split('-').join('');
  if (referenceA && referenceB) {
    return Number(referenceB) - Number(referenceA);
  }
  return 0;
};

const sortFaresByPatient = (fareA: FareDenormalized, fareB: FareDenormalized) => {
  if (fareA.patient && fareB.patient) {
    const patientNameA = `${fareA.patient.firstName} ${fareA.patient.lastName}`;
    const patientNameB = `${fareB.patient.firstName} ${fareB.patient.lastName}`;
    return patientNameB.localeCompare(patientNameA);
  }
  return 0;
};

const sortFaresByArea = (type: 'from' | 'to') => (fareA: FareDenormalized, fareB: FareDenormalized) => {
  const unitFareA = type === 'from' ? fareA.fromUnit : fareA.toUnit;
  const unitFareB = type === 'from' ? fareB.fromUnit : fareB.toUnit;
  if (unitFareA && unitFareB) {
    return unitFareB.name.localeCompare(unitFareA.name);
  }
  return 0;
};

const sortFaresByDate = (fareA: FareDenormalized, fareB: FareDenormalized) => {
  if (fareA.isEmergency && !fareB.isEmergency) {
    return 1;
  } else if (!fareA.isEmergency && fareB.isEmergency) {
    return -1;
  }
  if (fareA.wantedDate && fareB.wantedDate) {
    return new Date(fareB.wantedDate).getTime() - new Date(fareA.wantedDate).getTime();
  }
  return 0;
};

export const getDefaultFareListRoute = (role: WebUserRole, navigationACL?: string[]) => {
  if (navigationACL && navigationACL.length > 0) {
    if (hasAccessToRoute(navigationACL, 'sabri.fares')) {
      return ['REGULATOR', 'ADMIN_REGULATOR'].includes(role)
        ? routeNamesCommon.fareListPending
        : routeNamesCommon.fareListActive;
    } else if (hasAccessToRoute(navigationACL, 'sabri.team')) {
      return routeNamesCommon.team;
    }

    return '/';
  }

  return ['REGULATOR', 'ADMIN_REGULATOR'].includes(role)
    ? routeNamesCommon.fareListPending
    : routeNamesCommon.fareListActive;
};

type FareListColumn = TableListColumn<FareDenormalized> & { role?: WebUserRole[] };

const fareListColumns: Record<string, FareListColumn> = {
  reference: {
    id: 'reference',
    label: i18n.reference,
    isSortable: true,
    sortMethod: sortFaresByReference,
    renderCell: renderFareReference,
    role: ['ADMIN', 'ADMIN_REGULATOR', 'REGULATOR'],
  },
  wantedDate: {
    id: 'wantedDate',
    label: i18n.schedule,
    isSortable: true,
    sortMethod: sortFaresByDate,
    renderCell: renderFareWantedDate,
  },
  status: {
    id: 'status',
    label: i18n.status,
    isSortable: true,
    sortMethod: sortFaresByStatus,
    renderCell: renderFareStatus,
  },
  infos: {
    id: 'infos',
    label: i18n.infos,
    renderCell: renderFareInfos,
  },
  patient: {
    id: 'patient',
    label: i18n.patient,
    isSortable: true,
    sortMethod: sortFaresByPatient,
    renderCell: (fare: FareStored) => <FarePatient fare={fare} />,
  },
  position: {
    id: 'position',
    label: i18n.way,
    isSortable: true,
    sortMethod: sortFaresByPosition,
    renderCell: renderFarePosition,
  },
  from: {
    id: 'from',
    label: i18n.departure,
    isSortable: true,
    sortMethod: sortFaresByArea('from'),
    renderCell: ({ fromUnitId, fromAreaId }) => <LocationDetails unitId={fromUnitId} areaId={fromAreaId} />,
  },
  to: {
    id: 'to',
    label: i18n.destination,
    isSortable: true,
    sortMethod: sortFaresByArea('to'),
    renderCell: ({ toUnitId, toAreaId }) => <LocationDetails unitId={toUnitId} areaId={toAreaId} />,
  },
  step: {
    id: 'step',
    label: i18n.step,
    renderCell: ({ objectId, isReturnTripFromFareId, journeyId }) => (
      <FareListStepCell fareId={objectId} journeyId={journeyId} isReturnTrip={!!isReturnTripFromFareId} />
    ),
  },
  assignation: {
    id: 'assignation',
    label: i18n.assignation,
    renderCell: renderFareAssignation,
    style: {
      minWidth: 150,
      maxWidth: 150,
      overflowX: 'auto',
    },
  },
  actions: {
    id: 'actions',
    label: i18n.actions,
    renderCell: renderFareActions,
  },
};

//Attention ici, si oubli dans le tableau de sort, cela pourra créer des conflits avec la fonction getFareListColumns
export const columnsByRole = (role: WebUserRole) => {
  return role === 'MANAGER'
    ? ['wantedDate', 'patient', 'position', 'status', 'infos', 'from', 'to', 'step', 'assignation', 'actions']
    : // Other case : userole is ADMIN, ADMIN_REGULATOR, REGULATOR
      [
        'reference',
        'wantedDate',
        'status',
        'infos',
        'patient',
        'position',
        'from',
        'to',
        'step',
        'assignation',
        'actions',
      ];
};

export const getFareListColumns = (role: WebUserRole) =>
  columnsByRole(role).map((columnId) => fareListColumns[columnId]);

type ArchivedFaresColumn = TableListColumn<FareStored> & { role?: WebUserRole[] };

export const archivedFaresColumns: ArchivedFaresColumn[] = [
  {
    id: 'reference',
    label: i18n.reference,
    isSortable: true,
    renderCell: renderFareReference,
  },
  {
    id: 'createdAt',
    label: i18n.creation,
    isSortable: true,
    renderCell: renderFareCreatedAt,
  },
  {
    id: 'status',
    label: i18n.status,
    isSortable: true,
    renderCell: renderFareStatus,
  },
  {
    id: 'infos',
    label: i18n.infos,
    renderCell: renderFareInfos,
  },
  {
    id: 'wantedDate',
    label: i18n.schedule,
    isSortable: true,
    renderCell: renderFareWantedDate,
  },
  {
    id: 'patient',
    label: i18n.patient,
    renderCell: (fare: FareStored) => <FarePatient fare={fare} />,
  },
  {
    id: 'position',
    label: i18n.way,
    isSortable: true,
    renderCell: renderFarePosition,
  },
  {
    id: 'roundTrip',
    label: i18n.roundTripShrink,
    renderCell: renderFareRoundTrip,
  },
  {
    id: 'from',
    label: i18n.departure,
    renderCell: ({ fromUnitId, fromAreaId }) => <LocationDetails unitId={fromUnitId} areaId={fromAreaId} />,
  },
  {
    id: 'to',
    label: i18n.arrival,
    renderCell: ({ toUnitId, toAreaId }) => <LocationDetails unitId={toUnitId} areaId={toAreaId} />,
  },
  {
    id: 'assignation',
    label: i18n.assignation,
    renderCell: renderFareAssignation,
    style: {
      minWidth: 150,
      maxWidth: 150,
      overflowX: 'auto',
    },
  },
];

export const fareArchivedFilters = (porters: FilterItem[], areas: FilterItem[]) => {
  return [
    {
      id: 'reference',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorTextField onSubmit={onSubmit} title="Référence" onClose={onClose} />
      ),
    },
    {
      id: 'position',
      renderFilter: (onSubmit: (item: FilterItem[]) => void, onClose: () => void) => (
        <SelectorCheckbox items={positionsFilter} onClose={onClose} onSubmit={onSubmit} title="Position" />
      ),
    },
    {
      id: 'fromArea',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorCheckbox searchField onSubmit={onSubmit} title="Départ" items={areas} onClose={onClose} />
      ),
    },
    {
      id: 'toArea',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorCheckbox searchField onSubmit={onSubmit} title="Arrivée" items={areas} onClose={onClose} />
      ),
    },
    {
      id: 'porter',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorCheckbox searchField onSubmit={onSubmit} title="Agents" items={porters} onClose={onClose} />
      ),
    },
    {
      id: 'incident',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorRadio onSubmit={onSubmit} title="Incident" items={yesNoFilter} onClose={onClose} />
      ),
    },
    {
      id: 'status',
      renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => (
        <SelectorCheckbox searchField onSubmit={onSubmit} title="Statut" items={statusFilter} onClose={onClose} />
      ),
    },
  ];
};

export const fareArchivedFiltersMongoWhere = [
  {
    id: 'reference',
    request: (values: string[]) => ({
      reference: {
        $in: values,
      },
    }),
  },
  {
    id: 'position',
    request: (values: string[]) => ({
      position: {
        $in: values,
      },
    }),
  },
  {
    id: 'fromArea',
    request: (ids: string[]) => ({
      from: {
        $in: ids,
      },
    }),
  },
  {
    id: 'toArea',
    request: (ids: string[]) => ({
      to: {
        $in: ids,
      },
    }),
  },
  {
    id: 'porter',
    request: (ids: string[]) => ({
      porters: {
        $in: ids.map((id) => ({ __type: 'Pointer', className: 'Porter', objectId: id })),
      },
    }),
  },
  {
    id: 'incident',
    request: (ids: string[]) => ({
      incidents: {
        $exists: ids[0] === 'yes' ? true : false,
      },
    }),
  },
  {
    id: 'status',
    request: (values: string[]) => ({
      status: {
        $in: values,
      },
    }),
  },
];
