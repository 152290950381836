import { PerformerRequest, RequestType } from '@ambuliz/sabri-core';
import { Chip, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { Unit } from 'common/components/Icons';
import ArrowRight from 'common/components/Icons/ArrowRight';
import CalendarIcon from 'common/components/Icons/Calendar';
import EntryDashed from 'common/components/Icons/EntryDashed';
import TimelineDivider from 'common/components/Timeline/TimelineDivider';
import { i18n } from 'common/locale';
import { ColumnStatus } from 'kurt/pages/Movements/MovementsKanban';
import { FlowType } from 'kurt/pages/Movements/useMovementsPageSearchParams';

type HeaderRequestType = RequestType | 'emergency';

type AccommodationHeaderTitleProps = RowAccommodationHeaderTitleProps | ColumnAccommodationHeaderTitleProps;

type RowAccommodationHeaderTitleProps = {
  direction?: 'row';
  flow: FlowType;
  fromBed?: string;
  from?: string;
  to?: string;
  toBed?: string;
  minimal?: boolean;
  isMutationRequest?: boolean;
  targetUnits?: PerformerRequest[];
  numberOfRejections?: number;
  isRejected?: boolean;
  requestType?: HeaderRequestType;
};

type ColumnAccommodationHeaderTitleProps = {
  direction?: 'column';
  fromBed?: string;
  from?: string;
  to?: string;
  toBed?: string;
  targetUnits?: PerformerRequest[];
  numberOfRejections?: number;
  isRejected?: boolean;
  status?: ColumnStatus;
};

const AccommodationHeaderTitle = ({ direction = 'row', ...props }: AccommodationHeaderTitleProps): JSX.Element => {
  return direction === 'row' ? (
    <RowAccomodationHeaderTitle {...(props as RowAccommodationHeaderTitleProps)} />
  ) : (
    <ColumnAccomodationHeaderTitle {...(props as ColumnAccommodationHeaderTitleProps)} />
  );
};

const RowAccomodationHeaderTitle = ({
  flow,
  fromBed,
  toBed,
  from,
  to,
  minimal,
  isMutationRequest,
  targetUnits,
  numberOfRejections,
  isRejected,
  requestType,
}: Omit<RowAccommodationHeaderTitleProps, 'direction'>): JSX.Element => (
  <Stack
    direction="row"
    spacing={1}
    alignItems="center"
    flexWrap={'wrap'}
    columnGap={minimal ? 0 : 1}
    rowGap={minimal ? 0 : 1}
  >
    {flow === 'admission' ? (
      requestType === 'DIRECT_ADMISSION' ? (
        <EntryDashed color="tertiary" sx={{ fontSize: minimal ? 12 : 16 }} />
      ) : from || isMutationRequest ? (
        <Unit color="info" style={{ fontSize: minimal ? 12 : 16 }} />
      ) : (
        <CalendarIcon color="success" style={{ fontSize: minimal ? 12 : 16 }} />
      )
    ) : to || isMutationRequest ? (
      <Unit color="info" style={{ fontSize: minimal ? 12 : 16 }} />
    ) : (
      <CalendarIcon color="success" style={{ fontSize: minimal ? 12 : 16 }} />
    )}

    <Typography variant="body2" color="secondary">
      {from || i18n.defaultOrigin}
    </Typography>

    {fromBed && !minimal && <Chip label={fromBed} variant="rounded" size="x-small" />}

    <ArrowRight color="secondary" style={{ fontSize: 12 }} />
    <>
      {numberOfRejections || targetUnits ? (
        <>
          {isRejected && <Emoji name="noEntry" />}

          <Typography
            alignItems="center"
            variant="body2"
            fontWeight={isRejected ? '600' : '400'}
            color={isRejected ? 'error' : 'secondary'}
          >
            {isRejected && numberOfRejections
              ? i18n.targetRejected(numberOfRejections)
              : targetUnits?.length === 1 && targetUnits[0].performer
              ? targetUnits[0].performer.name
              : i18n.unitsTargeted(targetUnits?.length || 0)}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2" color="secondary">
            {to || i18n.defaultDestination}
          </Typography>

          {toBed && !minimal && <Chip label={toBed} variant="rounded" size="x-small" />}
        </>
      )}
    </>
  </Stack>
);

const ColumnAccomodationHeaderTitle = ({
  fromBed,
  from,
  to,
  toBed,
  targetUnits,
  numberOfRejections,
  isRejected,
  status,
}: Omit<ColumnAccommodationHeaderTitleProps, 'direction'>): JSX.Element => (
  <Stack>
    <Stack direction="row" alignItems="center" spacing={2}>
      <TimelineDivider
        start={status === 'requested' ? 'dashed' : 'filled'}
        end={status === 'completed' ? 'filled' : 'dashed'}
        style={{
          minHeight: 26,
          minWidth: 6,
        }}
      />

      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <EllipsisTypography variant="body2" color="secondary" disableTooltip>
            {from || i18n.defaultOrigin}
          </EllipsisTypography>
          {fromBed && <Chip label={fromBed} variant="rounded" size="x-small" />}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          {numberOfRejections || targetUnits ? (
            <>
              {isRejected && <Emoji name="noEntry" />}

              <Typography
                alignItems="center"
                variant="body2"
                fontWeight={isRejected ? 600 : 400}
                color={isRejected ? 'error' : 'secondary'}
              >
                {isRejected && numberOfRejections
                  ? i18n.targetRejected(numberOfRejections)
                  : targetUnits?.length === 1 && targetUnits[0].performer
                  ? targetUnits[0].performer.name
                  : i18n.unitsTargeted(targetUnits?.length || 0)}
              </Typography>
            </>
          ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
              <EllipsisTypography variant="body2" color="secondary" disableTooltip>
                {to || i18n.defaultDestination}
              </EllipsisTypography>

              {toBed && <Chip label={toBed} variant="rounded" size="x-small" />}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

export default AccommodationHeaderTitle;
