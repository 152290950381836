import Occupancy from './Occupancy';
import Status from './Status';
import Specificities from './Specificities';

const UnitStatisticsCards = {
  Occupancy,
  Specificities,
  Status,
};

export default UnitStatisticsCards;
