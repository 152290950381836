import Api, { Anomaly } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useCallback } from 'react';

const useOutdatedAnomalies = (movementIds: string[], type: 'start' | 'end') => {
  const { results: anomalies, isLoading: loading } = useParseQuery(
    new Api.Query(Anomaly)
      .equalTo('status', 'OPENED')
      .containedIn('accommodation', movementIds)
      .containedIn(
        'type',
        type === 'start' ? ['OUTDATED_START'] : ['OUTDATED_PREVISIONAL_END', 'OUTDATED_VALIDATED_END']
      ),
    { enabled: movementIds.length > 0 }
  );

  const findByMovement = useCallback(
    (movementId: string) => anomalies?.filter((anomaly) => anomaly.accommodation?.id === movementId),
    [anomalies]
  );

  return { anomalies, loading, findByMovement };
};

export default useOutdatedAnomalies;
