import { Cloud } from '@ambuliz/sabri-core';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogHeader } from 'common/components';
import { toast } from 'common/toast';
import { useMyHealthCenter } from 'core/healthcenter';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UnitForm, { UnitFormState } from '../components/UnitForm';

export const CreateUnitDialog = () => {
  const { myHealthCenter } = useMyHealthCenter();

  const [open, setOpen] = useState(true);
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState(false);
  const navigate = useNavigate();

  const [unit, setUnit] = useState<UnitFormState>({
    name: '',
    type: 'ACCOMMODATION',
    specialties: [],
    areas: [],
    externalId: '',
    responsibleUnits: [],
    autoComputeDischarge: false,
    autoComputeOccupanciesActivated: false,
    contacts: [],
    department: '',
    isEmergency: false,
    requestFareConfirmation: false,
  });

  const handleClose = (refresh = false) => {
    setOpen(false);
    setShouldRefreshOnClose(refresh);
  };

  const handleNavigateBack = () =>
    navigate('..', {
      state: {
        refresh: shouldRefreshOnClose,
      },
    });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await Cloud.createUnit({
        healthCenterId: myHealthCenter?.id || '',
        name: unit.name,
        type: unit.type,
        specialties: unit.specialties,
        areaIds: unit.areas,
        autoComputeDischarge: unit.autoComputeDischarge,
        autoComputeOccupanciesActivated: unit.autoComputeOccupanciesActivated,
        contacts: unit.contacts,
        department: unit.department,
        externalId: unit.externalId,
        isEmergency: unit.isEmergency,
        responsibleUnits: unit.responsibleUnits,
        requestFareConfirmation: unit.requestFareConfirmation,
      });
      toast.success(`L'unité ${unit.name} a été créée.`);
      handleClose(true);
    } catch (error) {
      switch (error.code) {
        case 'VALIDATION_FAILED':
          toast.error(
            `${Object.values(error.errors)
              ?.map((error: any) => error.message)
              .join(', ')}`
          );
          break;
        case 'SCRIPT_FAILED':
          toast.error(error.message);
          break;
        default:
          toast.error('Une erreur est survenue');
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      onTransitionExited={handleNavigateBack}
      maxWidth="lg"
      scroll="body"
    >
      <form onSubmit={handleSubmit}>
        <DialogHeader title="Créer une unité" onClose={() => handleClose()} />
        <DialogContent>
          <UnitForm onChange={(unit) => setUnit(unit)} unit={unit} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Annuler</Button>
          <Button type="submit">Créer</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
