import { Box, SvgIconProps } from '@mui/material';
import { palette } from 'common/theme';

type CardHeaderIconProps = {
  Icon: React.FC<SvgIconProps>;
  color: SvgIconProps['color'] & keyof typeof palette;
  hasBorder?: boolean;
};

const CardHeaderIcon = ({ Icon, color, hasBorder }: CardHeaderIconProps) => {
  const borderColor = hasBorder ? `${color}.main` : 'transparent';
  return (
    <Box
      ml="-16px"
      pl="13px"
      borderLeft="3px solid"
      borderColor={borderColor}
      fontSize={12}
      height={20}
      display="flex"
      alignItems="center"
    >
      <Icon color={color} fontSize="small" />
    </Box>
  );
};

export default CardHeaderIcon;
