import Api, { Appointment, PatientIdentity } from '@ambuliz/sabri-core';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PageHeader, PageSection } from 'core/layout';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PatientAnonymizer from '../anonymizer';
import PatientDetailsDialog from './PatientDetailsDialog';

const RESULTS_PER_PAGE = 10;

const PatientsPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { pageCount, count, patients } = usePatientsByPage(currentPage);

  return (
    <Stack spacing={4}>
      <PageHeader title="Patients avec rendez-vous" />
      <PageSection>
        <Card>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Identifiants</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {patients.map((patient) => (
                  <TableRow key={patient.id}>
                    <TableCell width="33%">{PatientAnonymizer(patient).name}</TableCell>
                    <TableCell>
                      <Grid container spacing={2}>
                        {(patient.identifiers || []).map(({ type, value }, index) => (
                          <Grid item key={index}>
                            <Chip label={`${type}: ${value}`} />
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell align="right">
                      <Button href={patient.id} LinkComponent={Link} variant="outlined">
                        <EventNoteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </PageSection>

      <PageSection>
        <Box display="flex" justifyContent="space-between" alignContent="center">
          <Typography>{count} Patients</Typography>
          <Pagination count={pageCount} onChange={(_, page) => setCurrentPage(page - 1)} />
        </Box>
      </PageSection>
      <Routes>
        <Route path="/:id" element={<PatientDetailsDialog />} />
      </Routes>
    </Stack>
  );
};

const findPatients = async (page: number): Promise<{ count: number; results: PatientIdentity[] }> => {
  return (await new Api.Query(PatientIdentity)
    .matchesKeyInQuery('objectId', 'patient.objectId', new Api.Query(Appointment))
    .skip(page * RESULTS_PER_PAGE)
    .limit(RESULTS_PER_PAGE)
    .withCount()
    .find()) as any;
};

const usePatientsByPage = (page: number) => {
  const [patients, setPatients] = useState<PatientIdentity[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const { count, results } = await findPatients(page);
      setCount(count);
      setPageCount(Math.ceil(count / RESULTS_PER_PAGE));
      setPatients(results);
    };

    fetch();
  }, [page]);

  return {
    count,
    pageCount,
    patients,
  };
};

export default PatientsPage;
