import { Grid, GridProps } from '@mui/material';
import styled from 'styled-components';

type CustomGridProps = GridProps & {
  percentile?: number;
};

const CustomGrid = styled(Grid)<CustomGridProps>`
  max-width: ${({ percentile }) => (percentile ? `${percentile}%` : undefined)};
  flex-basis: ${({ percentile }) => (percentile ? `${percentile}%` : undefined)};
`;

export default CustomGrid;
