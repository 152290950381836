import { Period } from 'common/components/Timeline';
import { useInterval } from 'common/utils';
import { addDays, addMinutes, isEqual, startOfMinute, startOfToday, subDays } from 'date-fns';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { View } from './UnitManagementFilters';

type LiveDates = {
  from: Date;
  to: Date;
};

const useLiveDates = (
  view: View,
  period: Period
): {
  refreshDates: (view?: View, period?: Period) => void;
} & LiveDates => {
  const [dates, setDates] = useState<LiveDates>(getLiveDates(view, period));

  const interval = useMemo(() => (view === 'timeline' ? null : 1000), [view]);

  useEffect(() => {
    setDates((old) => {
      const dates = getLiveDates(view, period);
      return isEqual(old.from, dates.from) && isEqual(old.to, dates.to) ? old : dates;
    });
  }, [view, period]);

  useInterval(() => {
    const { from, to } = getLiveDates(view, period);

    if (!isEqual(from, dates.from) || !isEqual(to, dates.to)) {
      setDates({ from, to });
    }
  }, interval);

  return {
    ...dates,
    refreshDates: (refreshView?: View, refreshPeriod?: Period) =>
      refreshDates(setDates, refreshView || view, refreshPeriod || period),
  };
};

const getLiveDates = (view: View, period: Period): LiveDates => {
  if (view === 'timeline') {
    const from = period === 'day' ? startOfToday() : subDays(startOfToday(), 15);
    const to = period === 'day' ? addDays(from, 2) : addDays(from, 30);
    return { from, to };
  }
  const from = startOfMinute(new Date());
  const to = addMinutes(from, 1);

  return { from, to };
};

const refreshDates = (setDates: React.Dispatch<SetStateAction<LiveDates>>, view: View, period: Period) => {
  setDates((old) => {
    const dates = getLiveDates(view, period);
    return isEqual(old.from, dates.from) && isEqual(old.to, dates.to) ? old : dates;
  });
};

export default useLiveDates;
