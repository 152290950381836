import { Alert } from 'common/components/Feedbacks';
import { toast as toastify, ToastOptions } from 'react-toastify';

type ToastParams =
  | string
  | {
      message: string;
      icon?: React.ReactElement;
      withSound?: boolean;
      title?: string | React.ReactElement;
      actionLabel?: string;
      isClosable?: boolean;
      onActionClick?: () => void;
    };

type Toast = {
  success: (params: ToastParams, options?: Omit<ToastOptions, 'closeButton'>) => void;
  error: (params: ToastParams, options?: Omit<ToastOptions, 'closeButton'>) => void;
  warning: (params: ToastParams, options?: Omit<ToastOptions, 'closeButton'>) => void;
  info: (params: ToastParams, options?: Omit<ToastOptions, 'closeButton'>) => void;
};

type Severity = 'success' | 'info' | 'warning' | 'error';

const toast: Toast = {
  success: (params, options) => generateToast('success', params, options),
  error: (params, options) => generateToast('error', params, options),
  warning: (params, options) => generateToast('warning', params, options),
  info: (params, options) => generateToast('info', params, options),
};

const generateToast = (severity: Severity, params: ToastParams, options?: ToastOptions) => {
  const id = Date.now();

  return typeof params === 'string'
    ? toastify[severity](<Alert severity={severity} content={params} />, {
        ...options,
        toastId: id,
      })
    : toastify[severity](
        <Alert
          severity={severity}
          {...params}
          content={params.message}
          onClose={params.isClosable ? () => toastify.dismiss(id) : undefined}
        />,
        {
          ...options,
          toastId: id,
        }
      );
};

export default toast;
