import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { EntityNames } from '../../const/schemas';
import { useSelectFetch } from '../../hooks/useSelectFetch';
import { getPatientFullName } from '../../services/patient';
import { SkeletonSimple } from '../SkeletonSimple/SkeletonSimple';

export type PatientNameProps = {
  id: string;
};

export const PatientName: React.FC<PatientNameProps & TypographyProps> = ({ id, ...props }) => {
  const [patient, requestStatus] = useSelectFetch(EntityNames.patients, id);

  if (requestStatus === 'LOADING') {
    return (
      <Typography>
        <SkeletonSimple />
      </Typography>
    );
  }

  if (!patient) {
    return null;
  }

  return <Typography {...props}>{getPatientFullName(patient)}</Typography>;
};
