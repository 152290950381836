import { Stack, Switch, Typography } from '@mui/material';
import { ReactNode } from 'react';

export type FormSectionProps = {
  label?: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  children?: ReactNode;
};

const FormSection: React.FC<FormSectionProps> = ({ label, children, checked, onChange, disabled }) => {
  return (
    <Stack spacing={4}>
      {!!label && (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
          <Typography variant="h4">{label}</Typography>
          {!!onChange && checked !== undefined && (
            <Switch checked={checked} onChange={(event) => onChange(event.target.checked)} disabled={disabled} />
          )}
        </Stack>
      )}
      {children}
    </Stack>
  );
};

export default FormSection;
