import { Incident } from '@ambuliz/sabri-core';
import { Warning } from '@mui/icons-material';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import React from 'react';
import { i18n } from '../../locales';
import { AlertFareDetails } from '../AlertFareDetails/AlertFareDetails';

type IncidentMessageProps = {
  incident: Incident;
};

export const IncidentMessage: React.FC<IncidentMessageProps> = ({ incident }) => {
  const classes = useClasses();

  if (!incident) {
    return null;
  }

  const { porter } = incident;

  const getSubtitle = () => {
    if (porter) {
      const createdAt = format(new Date(incident.createdAt), 'HH : mm');
      return `Par ${porter.firstName} ${porter.lastName.slice(0, 1)} - à ${createdAt}`;
    }
  };

  const getAlertFareDetailBody = () => {
    return (
      <Box display="flex" flexDirection="column">
        {!!incident.delayInMinutes && incident.delayInMinutes > 0 && (
          <div>
            <Box display="inline" fontSize={14} color="#663C00" marginTop={1}>
              {`${i18n.delayCaused} : `}
            </Box>

            <Box display="inline" fontSize={14} color="#F57C00" fontWeight="bold">
              {incident.delayInMinutes > 60
                ? i18n.moreThanOneHour
                : `${incident.delayInMinutes} ${i18n.minutesShrinks}`}
            </Box>
          </div>
        )}
        {!!incident.helpRequired && (
          <div>
            <Box display="inline" fontSize={14} color="#663C00" marginTop={1}>
              {`${i18n.helpRequired} : `}
            </Box>
            <Box display="inline" fontSize={14} color="#F57C00" fontWeight="bold">
              {incident.helpRequired}
            </Box>
          </div>
        )}
        {!!incident.adequateTransport && (
          <div>
            <Box display="inline" fontSize={14} color="#663C00" marginTop={1}>
              {`${i18n.adequateTransport} : `}
            </Box>
            <Box display="inline" fontSize={14} color="#F57C00" fontWeight="bold">
              {i18n.farePosition[incident.adequateTransport]}
            </Box>
          </div>
        )}
      </Box>
    );
  };

  return (
    <AlertFareDetails
      message={incident.comment}
      subtitle={getSubtitle()}
      title={`${i18n.incident} : ${i18n.incidentReasons[incident.reason]}`}
      color="#FFF4E5"
      icon={<Warning className={classes.incidentIcon} />}
    >
      {getAlertFareDetailBody()}
    </AlertFareDetails>
  );
};

const useClasses = makeStyles({
  incidentIcon: {
    color: '#F57C00',
  },
});
