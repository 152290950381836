import color from './color';

declare module '@mui/material/styles' {
  interface Palette {
    danger: {
      main: string;
      medium?: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    default: {
      main: string;
      medium?: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    primaryAlt: {
      main: string;
      medium?: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    tertiary: {
      main: string;
      medium?: string;
      light: string;
      dark: string;
      contrastText: string;
    };
  }
  interface PaletteOptions {
    danger?: {
      main?: string;
      medium?: string;
      light?: string;
      dark?: string;
      contrastText?: string;
    };
    default?: {
      main: string;
      medium?: string;
      light: string;
      dark: string;
      contrastText: string;
    };
    primaryAlt?: {
      main?: string;
      medium?: string;
      light?: string;
      dark?: string;
      contrastText?: string;
    };
    tertiary?: {
      main: string;
      medium?: string;
      light: string;
      dark: string;
      contrastText: string;
    };
  }
  interface PaletteColor {
    medium?: string;
  }
  interface SimplePaletteColorOptions {
    medium?: string;
  }
}

const palette = {
  primary: {
    main: color.blue[60],
    dark: color.blue[70],
    medium: color.blue[20],
    light: color.blue[5],
    contrastText: '#FFFFFF',
  },
  primaryAlt: {
    main: color.lightblue[60],
    dark: color.lightblue[70],
    medium: color.lightblue[20],
    light: color.lightblue[5],
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: color.grey[60],
    dark: color.grey[100],
    medium: color.grey[20],
    light: color.grey[5],
    contrastText: '#FFFFFF',
  },
  tertiary: {
    main: color.purple[50],
    dark: color.purple[100],
    medium: color.purple[20],
    light: color.purple[5],
    contrastText: '#FFFFFF',
  },
  text: {
    primary: color.grey[100],
    secondary: color.grey[60],
  },
  warning: {
    main: color.yellow[70],
    dark: color.yellow[100],
    medium: color.yellow[20],
    light: color.yellow[5],
    contrastText: '#FFFFFF',
  },
  success: {
    main: color.green[60],
    dark: color.green[100],
    medium: color.green[20],
    light: color.green[5],
    contrastText: '#FFFFFF',
  },
  error: {
    main: color.red[60],
    dark: color.red[100],
    medium: color.red[20],
    light: color.red[5],
    contrastText: '#FFFFFF',
  },
  danger: {
    main: color.orange[50],
    dark: color.orange[100],
    medium: color.orange[20],
    light: color.orange[5],
    contrastText: '#FFFFFF',
  },
  background: {
    default: color.blue[5],
  },
  info: {
    main: color.blue[30],
    dark: color.blue[100],
    medium: color.blue[20],
    light: color.blue[5],
    contrastText: '#FFFFFF',
  },
  default: {
    main: color.grey[60],
    dark: color.grey[100],
    medium: color.grey[20],
    light: color.grey[5],
    contrastText: '#FFFFFF',
  },
};

export default palette;
