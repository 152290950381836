import AdmissionStatusChip from 'kurt/components/StepStatus/AdmissionStatusChip';
import MutationStatusChip, { MutationStatus } from 'kurt/components/StepStatus/MutationStatusChip';
import { memo } from 'react';
import { HospitalizationRequestStatus } from './useHospitalizationRequests';

type HospitalizationRequestStatusChipProps = {
  status: HospitalizationRequestStatus;
};

const HospitalizationRequestStatusChip = ({ status }: HospitalizationRequestStatusChipProps) => {
  if (status === 'admitted') {
    return <AdmissionStatusChip status="ASSIGNED" />;
  }
  return <MutationStatusChip status={getMutationStatus(status)} />;
};

const getMutationStatus = (status: HospitalizationRequestStatus): MutationStatus => {
  switch (status) {
    case 'accepted':
      return 'ACCEPTED';
    case 'rejected':
      return 'BLOCKED';
    case 'to_address':
      return 'TO_ADDRESS';
    default:
      return 'PENDING';
  }
};

export default memo(HospitalizationRequestStatusChip);
