type LiveQueryClient = {
  open: () => void;
  subscribe: (query: Parse.Query, sessionToken?: string) => Parse.LiveQuerySubscription;
  unsubscribe: (subscription?: Parse.LiveQuerySubscription) => void;
};

const getLiveQueryURL = (serverURL: string) => {
  const liveQueryURL = new URL(serverURL);
  liveQueryURL.protocol = liveQueryURL.protocol === 'https:' ? 'wss:' : 'ws:';
  return liveQueryURL.toString();
};
/*
  LiveQueryClient is initialized in src/configuration.ts before the React app is loaded.
  Its then defined and available globally without having to use await.
*/
export let liveQueryClient: LiveQueryClient;

export const initLiveQueryClient = (serverURL: string, applicationId: string) => {
  //@ts-expect-error - Parse.LiveQueryClient is not typed
  liveQueryClient = new Parse.LiveQueryClient({
    applicationId: applicationId,
    serverURL: getLiveQueryURL(serverURL),
  });

  liveQueryClient.open();
};
