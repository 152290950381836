import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { ENVIRONMENT } from 'configuration';
import { UserHealthCenter } from 'core/authentication';
import QRCode from 'qrcode.react';

type QRCodeModalProps = {
  open: boolean;
  healthCenter?: UserHealthCenter;
  onClose: () => void;
};

const QRCodeModal: React.FC<QRCodeModalProps> = ({ open, healthCenter, onClose }) => {
  const qrCodeValue = healthCenter
    ? JSON.stringify({
        api: { key: ENVIRONMENT.PARSE_SERVER_APP_ID, url: ENVIRONMENT.PARSE_SERVER_URL },
        healthCenter: { id: healthCenter.id, key: healthCenter.qrCode },
      })
    : '';

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogHeader onClose={onClose} />
      <DialogContent>
        <Stack spacing={4} alignItems="center">
          <Box textAlign="center">
            <QRCode value={qrCodeValue} size={256} />
            <Typography variant="caption" component="div">
              {i18n.qrCodeText}
            </Typography>
          </Box>

          <Typography variant="body2">
            {i18n.version} {process.env.REACT_APP_VERSION}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default QRCodeModal;
