import { ComputedOccupancy, getAvailableBeds, Occupancy } from '@ambuliz/sabri-core';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { Info } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { hyphenIfUndefined } from 'common/utils';

type OccupancyInfosProps = { occupancy?: Partial<Occupancy & { updatedAt?: Date }> | ComputedOccupancy };

const OccupancyInfos: React.FC<OccupancyInfosProps> = ({ occupancy }) => (
  <Tooltip
    title={
      <Stack spacing={2}>
        <>
          <Typography variant="body2">
            {i18n.availableBedsShort + ' : '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {hyphenIfUndefined(
                getAvailableBeds(
                  occupancy?.dDayAvailabilities?.male,
                  occupancy?.dDayAvailabilities?.female,
                  occupancy?.dDayAvailabilities?.indifferent
                )
              )}
              /{hyphenIfUndefined(occupancy?.open)}
            </Typography>
          </Typography>
        </>
        <>
          <Typography variant="body2">
            {i18n.closedBeds + ' : '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {hyphenIfUndefined(occupancy?.close)}
            </Typography>
          </Typography>
        </>
        <>
          <Typography variant="body2">
            {i18n.accomotadedPatients + ' : '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {hyphenIfUndefined(occupancy?.accomodated)}
            </Typography>
          </Typography>
        </>
      </Stack>
    }
  >
    <Grid container alignItems="center" style={{ cursor: 'pointer' }}>
      <Info color="secondary" />
    </Grid>
  </Tooltip>
);

export default OccupancyInfos;
