import { Components } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import LinkBehavior from './LinkBehaviour';

import palette from '../palette';

const MuiLink: Components['MuiLink'] = {
  defaultProps: {
    color: palette.text.primary,
    component: LinkBehavior,
  } as LinkProps,
  styleOverrides: {
    root: {
      '&:disabled': {
        cursor: 'default',
      },
      fontFamily: 'Inter,sans-serif',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
    },
    button: {
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
};

export default MuiLink;
