import { ToggleButton, Tooltip } from '@mui/material';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';

const DisabledViewTooltip = ({ children }: { children: ReactNode }) => (
  <Tooltip title={i18n.disabledViewTooltip} placement="bottom" componentsProps={{ tooltip: { sx: { maxWidth: 150 } } }}>
    <span>
      <StyledToggleButton disabled value="">
        {children}
      </StyledToggleButton>
    </span>
  </Tooltip>
);

const StyledToggleButton = styled(ToggleButton)`
  &::after {
    content: '';
    width: 1px;
    background-color: ${color.grey[20]};
    height: calc(100% - 16px);
    position: absolute;
    top: 8px;
    right: -1px;
    z-index: -1;
  }
`;

export default DisabledViewTooltip;
