import { Area, getUnitFromArea, Unit } from '@ambuliz/sabri-core';
import { useEffect, useState } from 'react';

const useUnitFromBed = (bed?: Area) => {
  const [unit, setUnit] = useState<Unit>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUnit = async () => {
      if (bed) {
        try {
          setIsLoading(true);
          const unit = await getUnitFromArea(bed);

          setUnit(unit);
        } finally {
          setIsLoading(false);
        }
      } else {
        setUnit(undefined);
      }
    };

    fetchUnit();
  }, [bed]);

  return {
    unit,
    isLoading,
  };
};

export default useUnitFromBed;
