import { Contact, UnitSpecialty, UnitType } from '@ambuliz/sabri-core';
import { Autocomplete, FormLabel, Grid, Switch, TextField } from '@mui/material';
import { InputText } from 'common/components/Form';
import { i18n } from 'common/locale';
import { ChangeEvent } from 'react';
import { AreaSelect } from 'superadmin/components/AreaSelect';
import { useDepartments } from 'superadmin/hooks/useDepartments';
import { TelecomInput } from './TelecomInput';
import UnitSpecialtiesSelect from './UnitSpecialtiesSelect/UnitSpecialtiesSelect';
import { UnitTypeSelect } from './UnitTypeSelect';

export type UnitFormState = {
  name: string;
  type: UnitType;
  specialties: UnitSpecialty[];
  areas: string[];
  externalId: string;
  responsibleUnits: string[];
  autoComputeDischarge: boolean;
  autoComputeOccupanciesActivated: boolean;
  contacts: Contact[];
  department: string;
  isEmergency: boolean;
  requestFareConfirmation: boolean;
};

export type UnitFormProps = {
  unit: UnitFormState;
  enableAutoComputeDischarge?: boolean;
  loading?: boolean;
  onChange: (unit: UnitFormState) => void;
};

const UnitForm: React.FC<UnitFormProps> = ({ unit, onChange, enableAutoComputeDischarge = false, loading = false }) => {
  const { departments } = useDepartments();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...unit, [event.target.name]: event.target.value });
  };

  const handleToggle =
    (name: 'requestFareConfirmation' | 'autoComputeOccupanciesActivated' | 'autoComputeDischarge' | 'isEmergency') =>
    () => {
      onChange({ ...unit, [name]: !unit[name] });
    };

  return (
    <Grid container component="form" direction="column" spacing={4}>
      <Grid item>
        <InputText disabled={loading} onChange={handleChange} label={i18n.name} value={unit.name} name="name" />
      </Grid>
      <Grid item>
        <InputText
          disabled={loading}
          onChange={handleChange}
          label={i18n.externalId}
          value={unit.externalId}
          name="externalId"
        />
      </Grid>
      <Grid item>
        <UnitTypeSelect disabled={loading} value={unit.type} onChange={(type) => onChange({ ...unit, type })} />
      </Grid>
      <Grid item>
        <UnitSpecialtiesSelect
          value={unit.specialties}
          onChange={(specialties) => onChange({ ...unit, specialties })}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          options={departments}
          value={unit.department || null}
          freeSolo
          onInputChange={(_, value) => onChange({ ...unit, department: value || '' })}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={i18n.department} placeholder={i18n.department} />
          )}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          options={unit.responsibleUnits}
          value={unit.responsibleUnits}
          multiple
          open={false}
          freeSolo
          autoSelect
          onChange={(_, responsibleUnits) => onChange({ ...unit, responsibleUnits })}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={i18n.responsibleUnits}
              placeholder={i18n.responsibleUnits}
            />
          )}
        />
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <FormLabel htmlFor="autoComputeOccupanciesActivated">{i18n.occupanciesAutoCompute}</FormLabel>
        </Grid>
        <Grid item>
          <Switch
            id="autoComputeOccupanciesActivated"
            checked={unit.autoComputeOccupanciesActivated}
            disabled={loading}
            onChange={handleToggle('autoComputeOccupanciesActivated')}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <FormLabel htmlFor="requestFareConfirmation">{i18n.requestFareConfirmation}</FormLabel>
        </Grid>
        <Grid item>
          <Switch
            id="requestFareConfirmation"
            checked={unit.requestFareConfirmation}
            disabled={loading}
            onChange={handleToggle('requestFareConfirmation')}
          />
        </Grid>
      </Grid>
      {enableAutoComputeDischarge && (
        <Grid item container justifyContent="space-between">
          <Grid item>
            <FormLabel htmlFor="autoComputeDischarge">{i18n.autoComputeDischarge}</FormLabel>
          </Grid>
          <Grid item>
            <Switch
              id="autoComputeDischarge"
              checked={unit.autoComputeDischarge}
              disabled={loading}
              onChange={handleToggle('autoComputeDischarge')}
            />
          </Grid>
        </Grid>
      )}
      {unit.type === 'ACCOMMODATION' && (
        <Grid item container justifyContent="space-between">
          <Grid item>
            <FormLabel htmlFor="isEmergency">{i18n.isEmergency}</FormLabel>
          </Grid>
          <Grid item>
            <Switch
              id="isEmergency"
              checked={unit.isEmergency}
              disabled={loading}
              onChange={handleToggle('isEmergency')}
            />
          </Grid>
        </Grid>
      )}
      <Grid item>
        <TelecomInput value={unit.contacts} onChange={(contacts) => onChange({ ...unit, contacts })} />
      </Grid>
      <Grid item>
        <FormLabel>{i18n.areas}</FormLabel>
        <AreaSelect value={unit.areas} onChange={(areas: string[]) => onChange({ ...unit, areas })} />
      </Grid>
    </Grid>
  );
};

export default UnitForm;
