import { HealthCenterRegionData } from '@ambuliz/sabri-core/dist/cloud';
import { Map } from 'leaflet';
import { useState } from 'react';
import { useMapEvent } from 'react-leaflet';

const areHealthCentersWithinBounds = (healthCenters: HealthCenterRegionData[], map: Map) =>
  healthCenters.every(
    ({ location }) =>
      location?.latitude &&
      location?.longitude &&
      map.getBounds().contains({ lat: location.latitude, lng: location.longitude })
  );

const useHealthCentersWithinBounds = (healthCenters: HealthCenterRegionData[], map: Map) => {
  const [allMarkersVisible, setAllMarkersVisible] = useState(true);
  useMapEvent('move', () => {
    setAllMarkersVisible(areHealthCentersWithinBounds(healthCenters, map));
  });

  useMapEvent('zoom', () => {
    setAllMarkersVisible(areHealthCentersWithinBounds(healthCenters, map));
  });

  return allMarkersVisible;
};

export default useHealthCentersWithinBounds;
