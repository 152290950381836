import { SvgIcon, SvgIconProps } from '@mui/material';

const MapNavigator: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16" color="inherit">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3711 2.93495L2.82816 7.30657L7.4805 8.23727C7.77737 8.29666 8.00942 8.52871 8.06881 8.82558L8.9995 13.4779L13.3711 2.93495ZM13.3549 1.31784C13.822 1.12424 14.3598 1.23116 14.7174 1.58872C15.0749 1.94628 15.1818 2.48404 14.9882 2.95118L10.0702 14.812C9.85928 15.3199 9.34101 15.6307 8.79364 15.577C8.24626 15.5233 7.79828 15.1182 7.69005 14.579L6.69602 9.61005L1.72759 8.61613C1.18835 8.50789 0.78274 8.05981 0.729076 7.51244C0.675412 6.96507 0.98583 6.44693 1.49378 6.23603L13.3549 1.31784Z"
      />
    </SvgIcon>
  );
};

export default MapNavigator;
