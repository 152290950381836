import { Avatar } from '@mui/material';

type BadgeIconProps = {
  color: string;
  Icon: React.FC<any>;
};
export const BadgeIcon = ({ color, Icon }: BadgeIconProps) => {
  return (
    <Avatar
      sx={{
        bgcolor: color,
        width: 20,
        height: 20,
      }}
    >
      <Icon sx={{ fontSize: 12 }} />
    </Avatar>
  );
};
