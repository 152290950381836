import { ReactNode, createContext } from 'react';
import { useAuthentication } from './AuthenticationContext';

type ReadOnlyContextValue = {
  isReadOnly: boolean;
};

const ReadOnlyContext = createContext({} as ReadOnlyContextValue);

const ReadOnlyContextProvider = ({ children }: { children: ReactNode }) => {
  const { isReadOnly } = useAuthentication();

  const value: ReadOnlyContextValue = {
    isReadOnly: isReadOnly || false,
  };

  return <ReadOnlyContext.Provider value={value}>{children}</ReadOnlyContext.Provider>;
};

export { ReadOnlyContext, ReadOnlyContextProvider };
