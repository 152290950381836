import { PageLoader } from 'core/layout';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLinkerRedirectTo } from '../../services/linker';

export const Linker: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const getParams = async () => {
      const searchParams = new URLSearchParams(location.search);
      const path = await getLinkerRedirectTo(searchParams);
      if (path) {
        navigate(path, { replace: true });
      } else {
        navigate('/brancardage/fares', { replace: true });
      }
    };
    getParams();
  }, [location.search, navigate]);

  return <PageLoader />;
};
