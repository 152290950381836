import { Components } from '@mui/material';
import color from '../color';

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      border: 'none',
      borderBottom: `1px solid ${color.grey[10]}`,
      backgroundColor: '#fff',
      color: 'inherit',
    },
  },
};

export default MuiAppBar;
