import { Tab } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type FareListFilterTabProps = {
  value?: string;
  label: string;
};

const FareListFilterTab: React.FC<FareListFilterTabProps> = ({ label, value = '' }) => {
  return <Tab style={{ minWidth: 0 }} component={Link} to={value} value={value} label={label} />;
};

export default FareListFilterTab;
