import { HealthCenter, PortersLimitByFarePosition } from '@ambuliz/sabri-core';

type NormalizedHealthCenter = {
  id: string;
  name: string;
  autodispatchActivated: boolean;
  fareGroupActivated: boolean;
  fareGroupTimeInterval: number;
  portersLimitByFarePosition: PortersLimitByFarePosition;
  autodispatchTimeInMinutesAnticipation: number;
  location?: { latitude: number; longitude: number };
  autoComputeDischarge: boolean;
};

export const normalizeHealthCenter = (healthCenter: HealthCenter): NormalizedHealthCenter => ({
  id: healthCenter.id,
  name: healthCenter.get('name'),
  autodispatchActivated: healthCenter.get('autodispatchActivated'),
  fareGroupActivated: healthCenter.get('fareGroupActivated'),
  fareGroupTimeInterval: healthCenter.get('fareGroupTimeInterval'),
  portersLimitByFarePosition: healthCenter.get('portersLimitByFarePosition'),
  autodispatchTimeInMinutesAnticipation: healthCenter.get('autodispatchTimeInMinutesAnticipation'),
  location: healthCenter.get('location')
    ? { latitude: healthCenter.get('location').latitude, longitude: healthCenter.get('location').longitude }
    : undefined,
  autoComputeDischarge: healthCenter.get('autoComputeDischarge'),
});

export default NormalizedHealthCenter;
