import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ListPagination, SortableTableCell } from 'common/components';
import { i18n } from 'common/locale';
import { Order } from 'common/types';
import { BED_CELL_MIN_WIDTH, EDITABLE_TEXT_CELL_MIN_WIDTH, PATIENT_CELL_MIN_WIDTH } from 'kurt/const';
import { HospitalizationRequestsOrderBy } from './useHospitalizationRequests';

export const HISTORY_CELLS_PADDING_LEFT = 5;

type HospitalizationsTableProps = {
  children: React.ReactNode;
  order: Order;
  orderBy: HospitalizationRequestsOrderBy;
  page: number;
  rowsPerPage: number;
  totalCount: number;
  isReadOnly?: boolean;
  onSort: (name: HospitalizationRequestsOrderBy) => void;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
};

const HospitalizationsTable = ({
  children,
  order,
  orderBy,
  page,
  rowsPerPage,
  totalCount,
  isReadOnly = false,
  onSort,
  onPageChange,
  onRowsPerPageChange,
}: HospitalizationsTableProps) => {
  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <SortableTableCell
                name="bed"
                order={order}
                orderBy={orderBy}
                onSort={() => onSort('bed')}
                sx={{ minWidth: `${BED_CELL_MIN_WIDTH}px` }}
              >
                {i18n.hospitalizationRequestsPage.table.bed}
              </SortableTableCell>
              <SortableTableCell
                name="patient"
                order={order}
                orderBy={orderBy}
                onSort={() => onSort('patient')}
                sx={{ minWidth: `${PATIENT_CELL_MIN_WIDTH}px` }}
              >
                {i18n.hospitalizationRequestsPage.table.patient}
              </SortableTableCell>
              <SortableTableCell name="age" order={order} orderBy={orderBy} onSort={() => onSort('age')}>
                {i18n.hospitalizationRequestsPage.table.age}
              </SortableTableCell>
              <SortableTableCell name="status" order={order} orderBy={orderBy} onSort={() => onSort('status')}>
                {i18n.hospitalizationRequestsPage.table.status}
              </SortableTableCell>

              <SortableTableCell
                name="destination"
                order={order}
                orderBy={orderBy}
                onSort={() => onSort('destination')}
                sx={{ paddingLeft: HISTORY_CELLS_PADDING_LEFT }}
              >
                {i18n.hospitalizationRequestsPage.table.destination}
              </SortableTableCell>
              <SortableTableCell
                name="startAt"
                order={order}
                orderBy={orderBy}
                onSort={() => onSort('startAt')}
                sx={{ paddingLeft: 4 + HISTORY_CELLS_PADDING_LEFT }}
              >
                {i18n.hospitalizationRequestsPage.table.hour}
              </SortableTableCell>
              <TableCell
                sx={{
                  minWidth: EDITABLE_TEXT_CELL_MIN_WIDTH,
                  width: '15%',
                }}
              >
                {i18n.hospitalizationRequestsPage.table.hospitalizationReason}
              </TableCell>
              <TableCell>{i18n.hospitalizationRequestsPage.table.specificities}</TableCell>
              <TableCell sx={{ minWidth: EDITABLE_TEXT_CELL_MIN_WIDTH, width: '15%' }}>
                {i18n.hospitalizationRequestsPage.table.comment}
              </TableCell>
              {!isReadOnly && (
                <>
                  <TableCell>{i18n.hospitalizationRequestsPage.table.action}</TableCell>
                  <TableCell width={56}></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <ListPagination
        count={totalCount}
        onPageChange={(_event, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={i18n.hospitalizationRequestsPage.table.requestsPerPage}
      />
    </Paper>
  );
};

export default HospitalizationsTable;
