"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NAVIGATION_ROUTES = exports.ADMIN_ROUTES = exports.SABRI_ROUTES = exports.KURT_ROUTES = void 0;
exports.KURT_ROUTES = [
    'kurt',
    'kurt.territory',
    'kurt.healthCenter',
    'kurt.unit',
    'kurt.movements',
    'kurt.emergency',
];
exports.SABRI_ROUTES = ['sabri', 'sabri.fares', 'sabri.team'];
exports.ADMIN_ROUTES = [
    'admin',
    'admin.healthCenter',
    'admin.units',
    'admin.areas',
    'admin.users',
    'admin.patients',
    'admin.appointments',
    'admin.externalTransports',
    'admin.healthcareEnvironmentalCleaning',
    'admin.anomalies',
    'admin.emergencies',
    'admin.thesaurus',
    'admin.overview',
];
exports.NAVIGATION_ROUTES = [...exports.KURT_ROUTES, ...exports.SABRI_ROUTES, ...exports.ADMIN_ROUTES];
