import { Grid, TextField } from '@mui/material';
import React from 'react';

type TextFieldProps = React.ComponentProps<typeof TextField>;

type UsedTextFieldProps = Pick<
  TextFieldProps,
  'type' | 'label' | 'disabled' | 'onFocus' | 'value' | 'onChange' | 'name' | 'helperText' | 'InputProps'
>;

export type InputTextProps = UsedTextFieldProps & {
  error?: string;
};

const InputText: React.FC<InputTextProps> = ({ error, ...other }) => {
  return (
    <Grid item xs={12}>
      <TextField fullWidth name="username" variant="outlined" error={!!error} helperText={error} {...other} />
    </Grid>
  );
};

export default InputText;
