import { SVGProps } from 'react';

const MapInProgress = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 166 150" fill="none" {...props}>
    <path
      d="M70.7.029c-19.88 1.324-24.332 2.89-36.924 10.89C12.881 24.185-1.773 46.104 1.072 68.156c2.007 15.61 12.31 29.656 24.958 41.032 7.788 6.999 16.689 13.292 27.068 17.123 11.651 4.304 24.694 5.325 37.374 4.489 13.616-.895 27.205-3.95 38.914-9.985 16.23-8.357 28.206-22.369 33.174-37.97 4.972-15.6 3.004-32.632-4.85-47.3-2.201-4.113-4.872-8.07-8.384-11.453-3.886-3.736-8.709-6.679-13.707-9.275C116.228 4.762 93.414-.437 70.699.029Z"
      fill="#E6EDFF"
    />
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.272 18.932c36.2 0 65.534 29.334 65.534 65.534S118.472 150 82.272 150s-65.534-29.334-65.534-65.534 29.334-65.534 65.534-65.534Z"
        fill="#799AF1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.271 32.261c28.837 0 52.205 23.368 52.205 52.205s-23.368 52.205-52.205 52.205c-28.836 0-52.205-23.368-52.205-52.205s23.369-52.205 52.205-52.205Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.273 27.892c31.25 0 56.574 25.324 56.574 56.574 0 31.25-25.324 56.574-56.574 56.574-31.25 0-56.574-25.324-56.574-56.574 0-31.25 25.324-56.574 56.574-56.574Zm52.205 56.574c0-28.837-23.368-52.205-52.205-52.205S30.068 55.629 30.068 84.466s23.368 52.205 52.205 52.205 52.205-23.368 52.205-52.205Z"
        fill="#E6EDFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.272 33.495c28.141 0 50.971 22.83 50.971 50.971s-22.83 50.971-50.971 50.971S31.3 112.639 31.3 84.466s22.797-50.97 50.97-50.97Z"
        fill="#fff"
      />
      <path
        d="M86.745 79.993A6.326 6.326 0 1 0 77.8 88.94a6.326 6.326 0 0 0 8.945-8.946Z"
        fill="#5470B8"
        stroke="#5470B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m82.272 71.766 30.582-17.883-17.882 30.583a11.254 11.254 0 0 0-3.218-9.482c-2.592-2.592-6.122-3.645-9.483-3.218Z"
        fill="#799AF1"
        stroke="#799AF1"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m51.69 115.048 17.882-30.582a11.254 11.254 0 0 0 3.218 9.482c2.59 2.592 6.122 3.645 9.482 3.218l-30.583 17.882Z"
        fill="#B5CAFF"
        stroke="#B5CAFF"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(16.738 18.932)" d="M0 0h131.068v131.068H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default MapInProgress;
