import { Calendar } from 'common/components/Icons';

const MuiDatePicker = {
  defaultProps: {
    slots: { openPickerIcon: Calendar },
    slotProps: {
      paper: {
        elevation: 3,
      },
      textField: {
        sx: {
          maxWidth: 134,
          '& .MuiInputBase-sizeSmall': {
            maxWidth: 114,
          },
        },
      },
    },
    format: 'dd/MM/yyyy',
    localeText: {
      fieldDayPlaceholder: () => '__',
      fieldMonthPlaceholder: () => '__',
      fieldYearPlaceholder: () => '____',
    },
  },
};

export default MuiDatePicker;
