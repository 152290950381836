import { createSelector } from '@reduxjs/toolkit';
import { EntityNames } from '../../const/schemas';
import { AreaStored, PorterStored } from '../reducers/entity';
import { selectEntitiesValues, selectEntities } from './entity';

export const selectPortersWorking = createSelector([selectEntitiesValues(EntityNames.porters)], (porters) =>
  porters.filter((porter) => porter.status !== 'OFF')
);

export const selectPortersWorkingIds = createSelector([selectPortersWorking], (porters) =>
  porters.map((porter) => porter.objectId)
);

export type PorterDenormalized = PorterStored & {
  lastArea: AreaStored | undefined;
  nextArea: AreaStored | undefined;
};

export const selectPortersDenormalized = createSelector(
  [selectEntitiesValues(EntityNames.porters), selectEntities(EntityNames.areas)],
  (porters, areas) =>
    porters.map((porter) => ({
      ...porter,
      lastArea: porter.lastAreaId ? areas[porter.lastAreaId] : undefined,
      nextArea: porter.nextAreaId ? areas[porter.nextAreaId] : undefined,
    }))
);

export const selectPortersFilters = createSelector([selectEntitiesValues(EntityNames.porters)], (porters) =>
  porters.map((porter) => ({
    id: porter.objectId,
    label: `${porter.firstName} ${porter.lastName}`,
  }))
);

export const selectPortersNotInIds = (porterIds: string[]) =>
  createSelector([selectPortersWorking], (porters) => {
    return porters.filter((porter) => !porterIds.includes(porter.objectId));
  });
