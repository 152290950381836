import { datadogRum } from '@datadog/browser-rum';
import { Snackbar } from 'common/components';
import { Login, RootRedirect } from 'core/authentication';
import { Layout } from 'core/layout';
import { RocketChat } from 'core/support/components/RocketChat';
import Kurt from 'kurt';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Sabri from 'sabri';
import { PrivateRoute } from 'sabri/components/PrivateRoute/PrivateRoute';
import { SuperAdmin } from 'superadmin';

function App() {
  useEffect(() => {
    datadogRum.startSessionReplayRecording();
    return () => datadogRum.stopSessionReplayRecording();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Layout />}>
          <Route
            path="brancardage/*"
            element={
              <PrivateRoute roles={['ADMIN', 'ADMIN_REGULATOR', 'REGULATOR', 'MANAGER']} routeACL="sabri">
                <Sabri />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/*"
            element={
              <PrivateRoute roles={['ADMIN', 'ADMIN_REGULATOR']} routeACL="admin">
                <SuperAdmin />
              </PrivateRoute>
            }
          />

          <Route
            path="lits/*"
            element={
              <PrivateRoute roles={['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER', 'MANAGER']} routeACL="kurt">
                <Kurt />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<RootRedirect />} />
        </Route>
      </Routes>

      <Snackbar />
      <RocketChat />
    </>
  );
}

export default App;
