import { Fare } from '@ambuliz/sabri-core';
import { Check, SwapHorizOutlined } from '@mui/icons-material';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { format, isAfter } from 'date-fns';
import LocationDetails from 'sabri/components/LocationDetails/LocationDetails';
import { getFarePositionIcon } from 'sabri/const/fare';
import { i18n } from 'sabri/locales';
import { getHumanDayDiffFromNow } from 'sabri/utils/date';
import useClasses from './FareDetailsModalStyles';

const FareDetailsSummary: React.FC<{ fare: Fare; chipLabel: string }> = ({ fare, chipLabel }) => {
  const {
    isEmergency,
    wantedDate,
    position,
    toUnit,
    fromUnit,
    fromArea,
    toArea,
    roundTrip,
    isReturnTripFromFare,
    specificities,
    comment,
  } = fare;
  const classes = useClasses();
  const PositionIcon = getFarePositionIcon(position);

  const isRoundTrip = roundTrip || !!isReturnTripFromFare;

  return (
    <Grid container className={classes.infos} direction="column">
      <Grid item container className={classes.itemContainer}>
        <Grid item xs={3} className={classes.item}>
          {isEmergency ? (
            <Chip label={i18n.urgent.toLocaleUpperCase()} color="error" variant="rounded" />
          ) : (
            <>
              <Typography align="center">{i18n.arrivalTime}</Typography>
              <Typography variant="h4">{wantedDate ? format(new Date(wantedDate), 'HH:mm') : '-'}</Typography>
              {wantedDate && (
                <Typography align="center" className={classes.diffDate}>
                  {getHumanDayDiffFromNow({
                    date: new Date(wantedDate),
                    startString: isAfter(new Date(), new Date(wantedDate)) ? 'Depuis' : 'Dans',
                  })}
                </Typography>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={3} className={classes.item}>
          {position ? (
            <>
              <PositionIcon className={classes.positionIcon} />
              <Typography>{i18n.farePosition[position]}</Typography>
            </>
          ) : (
            <Typography>/</Typography>
          )}
        </Grid>

        <Grid item container xs={4} direction="column" className={classes.itemLocation}>
          <Grid item className={classes.itemColumn}>
            <LocationDetails unitId={fromUnit.id} areaId={fromArea?.id} size="large" />
          </Grid>
          <Grid item className={classes.itemColumn}>
            <LocationDetails unitId={toUnit.id} areaId={toArea?.id} size="large" />
            {isRoundTrip && (
              <Box className={classes.roundTripContainer}>
                <SwapHorizOutlined className={classes.roundTripIcon} />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.item}>
          {
            <Box marginLeft={1}>
              <Chip variant="outlined" color="default" size="medium" label={chipLabel} />
            </Box>
          }
        </Grid>
      </Grid>
      <Grid item container className={classes.itemContainer}>
        {specificities && (
          <Grid item container xs={6} className={classes.itemLeft}>
            <Typography variant="h6">{i18n.linkerParamsTrad['specificities']}</Typography>
            <Grid item className={classes.itemColumn}>
              <Box marginBottom={2}>
                {specificities?.map((specifity) => (
                  <Box key={specifity} display="flex" alignItems="center">
                    <Check className={classes.specificitiesIcon} />
                    <Typography variant="body1">{i18n.fareSpecificity[specifity]}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        )}
        {comment && (
          <Grid item container xs={6} className={classes.itemLeft}>
            <Typography variant="h6">{i18n.comments}</Typography>
            <Grid item className={classes.itemColumn}>
              <Typography variant="body2" className={classes.comment}>
                {comment ? comment : i18n.noComment}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FareDetailsSummary;
