import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Dialog, Stack } from '@mui/material';
import { theme } from 'common/theme';
import { PageContent } from 'core/layout';
import { format } from 'date-fns';
import PatientFlowKanban from 'kurt/components/PatientFlowKanban';
import KanbanCard from 'kurt/components/PatientFlowKanban/KanbanCard/KanbanCard';
import KanbanCardAdmissionRequest from 'kurt/components/PatientFlowKanban/KanbanCard/KanbanCardAdmissionRequest';
import KanbanCardDischargeRequest from 'kurt/components/PatientFlowKanban/KanbanCard/KanbanCardDischargeRequest';
import KanbanColumnHeadersContainer from 'kurt/components/PatientFlowKanban/KanbanColumnHeadersContainer';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import { VisitDetails } from 'kurt/components/PatientFlowKanban/VisitDetails';
import getKanbanColumnsConditions from 'kurt/components/PatientFlowKanban/getKanbanColumnsConditions';
import { useEffect, useMemo, useState } from 'react';
import { FlowType, MovementsFilter } from './useMovementsPageSearchParams';
import useVisits, { Visit } from './useVisits';

export type ColumnStatus = 'planned' | 'validated' | 'completed' | 'requested';

const defaultClonedState = {
  planned: [],
  validated: [],
  completed: [],
  requested: [],
};

const MovementsKanban = ({
  unitId,
  flow,
  filter,
  isReadOnly = false,
  onFilterCountChange,
}: {
  unitId: string;
  flow: FlowType;
  filter: MovementsFilter;
  isReadOnly?: boolean;
  onFilterCountChange: (count: number) => void;
}) => {
  const { loading, visits } = useVisits(flow, format(filter.date, 'yyyy-MM-dd'), unitId, onFilterCountChange, filter);

  const [clonedVisits, setClonedVisits] = useState<{
    planned: Visit[];
    validated: Visit[];
    completed: Visit[];
    requested: Visit[];
  }>(defaultClonedState);
  const [actionOpen, setActionOpen] = useState(false);
  const [actionName, setActionName] = useState<AccommodationAction>();
  const [activeColumn, setActiveColumn] = useState<ColumnStatus | undefined>();
  const [originColumn, setOriginColumn] = useState<ColumnStatus | undefined>();
  const [activeVisit, setActiveVisit] = useState<Visit & { visitStatus?: ColumnStatus }>();
  const [dropColumn, setDropColumn] = useState<ColumnStatus | undefined>();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 1,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const dropAllowed = useMemo(() => {
    if (activeColumn && activeVisit) {
      return getKanbanColumnsConditions(
        activeColumn,
        flow,
        flow === 'discharge' &&
          !!activeVisit?.nextAccommodation?.basedOn &&
          !!activeVisit?.nextAccommodation?.basedOn?.performerRequests
      ).includes(activeVisit?.visitStatus as ColumnStatus);
    }
    return true;
  }, [activeVisit, activeColumn, flow]);

  const isDropColumnActive = (column: ColumnStatus) =>
    activeColumn === column && activeColumn !== originColumn && originColumn !== 'completed';

  useEffect(() => {
    if (visits && activeVisit && dropColumn) {
      const movedSuccessfully =
        visits[dropColumn as ColumnStatus].some(
          (visit) =>
            visit.id === activeVisit.id ||
            (!!activeVisit.basedOn && !!activeVisit.basedOn.performerRequests && activeVisit.pan === visit.pan)
        ) ||
        (flow === 'discharge'
          ? dropColumn === 'completed' &&
            ![...visits.validated, ...visits.planned].some((visit) => visit.id === activeVisit.id)
          : dropColumn === 'completed' && !visits.validated.some((visit) => visit.id === activeVisit.id));

      if (movedSuccessfully) {
        setDropColumn(undefined);
        setActiveVisit(undefined);
        setActionName(undefined);
      }
    }
  }, [visits, activeColumn, flow, activeVisit, dropColumn]);

  const handleDragOver = (event: DragOverEvent) => {
    const { over } = event;
    if (over) {
      setActiveColumn(over.id as ColumnStatus);
      if (activeVisit && activeVisit.visitStatus && over.data && over.data.current && over.data.current.accept) {
        if (over.id !== activeVisit.visitStatus && over.data.current.accept.includes(activeVisit.visitStatus)) {
          setClonedVisits({
            ...clonedVisits,
            [activeVisit.visitStatus]: clonedVisits[activeVisit.visitStatus].filter(
              (visit) => visit.id !== activeVisit.id
            ),
          });
        } else {
          setClonedVisits({
            ...visits,
          });
        }
      }
    }
  };

  const handleDragMove = (event: DragMoveEvent) => {
    const { collisions } = event;
    if (!collisions || collisions.length === 0) {
      setActiveColumn(undefined);
      if (
        activeVisit &&
        activeVisit.visitStatus &&
        !clonedVisits[activeVisit.visitStatus].some((visit) => visit.id === activeVisit.id)
      ) {
        setClonedVisits({
          ...visits,
        });
      }
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    if (active && active.data && active.data.current) {
      setActiveVisit({ ...active.data.current.data, visitStatus: active.data.current.status });
      setClonedVisits({ ...visits });
      const originColumn = active.data.current.status;

      setOriginColumn(originColumn);
      setDropColumn(undefined);
    }
  };

  const handleDragCancel = () => {
    setActiveColumn(undefined);
    setOriginColumn(undefined);
    setActiveVisit(undefined);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event;
    let action: AccommodationAction | undefined = undefined;
    if (
      activeColumn &&
      activeVisit &&
      originColumn &&
      activeColumn !== originColumn &&
      over?.data?.current?.accept?.includes(activeVisit.visitStatus)
    ) {
      switch (originColumn) {
        case 'requested':
          if (flow === 'admission') {
            setClonedVisits({
              ...clonedVisits,
              planned: [activeVisit, ...clonedVisits.planned],
              requested: clonedVisits.requested.filter((visit) => visit.id !== activeVisit.id),
            });
            action = 'ACCEPT_INTERNAL_MUTATION_REQUEST';
          } else {
            action = 'ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN';
          }
          setActionName(action);
          break;
        case 'planned':
          if (flow === 'discharge' && activeColumn === 'completed') {
            setClonedVisits({
              ...clonedVisits,
              completed: [activeVisit, ...clonedVisits.completed],
              planned: clonedVisits.planned.filter((visit) => visit.id !== activeVisit.id),
            });
            action = 'DISCHARGE_PATIENT';
            setActionName(action);
          } else {
            setClonedVisits({
              ...clonedVisits,
              validated: [activeVisit, ...clonedVisits.validated],
              planned: clonedVisits.planned.filter((visit) => visit.id !== activeVisit.id),
            });
            action =
              flow === 'admission'
                ? activeVisit?.basedOn
                  ? 'SET_MUTATION_BED'
                  : 'SET_BED'
                : 'VALIDATE_DISCHARGE_DATE';
            setActionName(action);
          }
          break;

        case 'validated':
          if (activeColumn === 'planned') {
            setClonedVisits({
              ...clonedVisits,
              planned: [activeVisit, ...clonedVisits.planned],
              validated: clonedVisits.validated.filter((visit) => visit.id !== activeVisit.id),
            });
            action =
              flow === 'admission'
                ? activeVisit?.basedOn
                  ? 'CANCEL_MUTATION_BED'
                  : 'CANCEL_BED'
                : 'INVALIDATE_DISCHARGE_DATE';
            setActionName(action);
          } else {
            setClonedVisits({
              ...clonedVisits,
              completed: [activeVisit, ...clonedVisits.completed],
              validated: clonedVisits.validated.filter((visit) => visit.id !== activeVisit.id),
            });
            action = flow === 'admission' ? 'CONFIRM_ADMISSION' : 'DISCHARGE_PATIENT';
            setActionName(action);
          }
          break;

        default:
          return;
      }

      setActionOpen(true);
    }
    if (!action && !actionOpen) {
      setActiveVisit(undefined);
    }
    setDropColumn(activeColumn);
    setActiveColumn(undefined);
    setOriginColumn(undefined);
  };

  const handleClose = () => {
    setActionOpen(false);
    setActionName(undefined);
    setActiveVisit(undefined);
  };

  const handleCloseOnConfirm = () => {
    setActionOpen(false);
  };

  return (
    <>
      <PageContent>
        <DndContext
          onDragOver={handleDragOver}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <Stack style={{ flex: 1 }}>
            <KanbanColumnHeadersContainer
              loading={loading}
              flow={flow}
              requestedVisitsCount={activeVisit ? clonedVisits.requested.length : visits.requested.length}
              plannedVisitsCount={activeVisit ? clonedVisits.planned.length : visits.planned.length}
              completedVisitsCount={activeVisit ? clonedVisits.completed.length : visits.completed.length}
              validatedVisitsCount={activeVisit ? clonedVisits.validated.length : visits.validated.length}
              dropAllowed={dropAllowed}
              isDropColumnActive={isDropColumnActive}
            />
            <DragOverlay
              style={{
                cursor: activeVisit ? 'grabbing' : undefined,
                zIndex: theme.zIndex.modal - 1,
                margin: 0,
              }}
            >
              {activeVisit ? (
                <div>
                  {activeVisit.visitStatus === 'requested' ? (
                    flow === 'admission' ? (
                      <KanbanCardAdmissionRequest key={activeVisit.id} visit={activeVisit} isVisitActive />
                    ) : (
                      <KanbanCardDischargeRequest key={activeVisit.id} visit={activeVisit} isVisitActive />
                    )
                  ) : (
                    <KanbanCard
                      key={activeVisit.id}
                      visit={activeVisit}
                      flow={flow}
                      status={activeVisit.visitStatus as ColumnStatus}
                      isVisitActive
                    />
                  )}
                </div>
              ) : null}
            </DragOverlay>
            <PatientFlowKanban
              flow={flow}
              plannedVisits={activeVisit ? clonedVisits.planned : visits.planned}
              completedVisits={activeVisit ? clonedVisits.completed : visits.completed}
              validatedVisits={activeVisit ? clonedVisits.validated : visits.validated}
              requestedVisits={activeVisit ? clonedVisits.requested : visits.requested}
              loading={loading}
              dropAllowed={dropAllowed}
              isDropColumnActive={isDropColumnActive}
              activeVisit={activeVisit}
              showErrorMessage={originColumn === 'completed'}
              isReadOnly={isReadOnly}
            />
          </Stack>
        </DndContext>
      </PageContent>

      {activeVisit && activeVisit.visitStatus && actionName && (
        <Dialog open={actionOpen} onClose={handleClose} maxWidth="lg" fullWidth={false} scroll="body">
          <VisitDetails
            onClose={handleClose}
            onConfirm={handleCloseOnConfirm}
            visit={activeVisit}
            flow={flow}
            status={activeVisit.visitStatus}
            menuActionName={actionName}
            disabled={false}
          />
        </Dialog>
      )}
    </>
  );
};

export default MovementsKanban;
