import { Fare, Journey, WebUserRole } from '@ambuliz/sabri-core';
import { Box, Chip, DialogContent, DialogTitle, Stack } from '@mui/material';
import { formatName } from 'common/utils';
import { useAuthentication, useReadOnly } from 'core/authentication';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderFareModal } from 'sabri/components/HeaderFareModal/HeaderFareModal';
import { IncidentMessage } from 'sabri/components/IncidentMessage/IncidentMessage';
import { PorterAssignation } from 'sabri/components/PorterAssignation/PorterAssignation';
import { modalNames } from 'sabri/const/modalNames';
import { i18n } from 'sabri/locales';
import { getPatientFullName } from 'sabri/services/patient';
import { cancelConfirmFareAction, confirmFareAction, fareTickAction } from 'sabri/store/actions/fare';
import { modalActions } from 'sabri/store/reducers/modal';
import * as fareService from '../../services/fare';
import FareDetailsActions from './FareDetailsActions';
import FareDetailsGroupedFares from './FareDetailsGroupedFares';
import useClasses from './FareDetailsModalStyles';
import FareDetailsPatientSummary from './FareDetailsPatientSummary';
import FareDetailsSummary from './FareDetailsSummary';
import FareHistory from './FareHistory';
import JourneyDetails, { getJourneyFareChipLabel } from './Journey';

const ASSIGNATION_READ_ONLY_ROLES: WebUserRole[] = ['MANAGER', 'ADMIN'];

type FareDetailsModalContentProps = {
  fare: Fare;
  journey?: Journey;
  groupedFares?: Fare[];
  journeyFares: Fare[];
  onClose: () => void;
  onCancelJourney: () => void;
};

const FareDetailsModalContent = ({
  fare,
  groupedFares,
  journey,
  journeyFares,
  onCancelJourney,
  onClose,
}: FareDetailsModalContentProps) => {
  const classes = useClasses();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useAuthentication();

  const handleClickEdit = () => navigate('edit');
  const handleClickIncident = () => navigate('new-incident');

  const handleFareNextTick = () => {
    dispatch(fareTickAction({ fareId: fare.id }));
  };
  const handleFareUnitConfirmation = () => {
    dispatch(
      modalActions.showModal({
        modalName: modalNames.confirmPushFare,
        params: {
          onConfirm: () => dispatch(confirmFareAction({ fareId: fare.id })),
          patientFullName: getPatientFullName(fare.patient),
        },
      })
    );
  };
  const handleFareUnitCancelation = () => {
    dispatch(
      modalActions.showModal({
        modalName: modalNames.cancelPullFare,
        params: {
          onConfirm: () => dispatch(cancelConfirmFareAction({ fareId: fare.id })),
          patientFullName: getPatientFullName(fare.patient),
        },
      })
    );
  };

  const { isReadOnly } = useReadOnly();
  const isAssignationReadOnly =
    isReadOnly || ASSIGNATION_READ_ONLY_ROLES.includes(user.role) || !fareService.isFareAssignableToPorter(fare);

  const isReportIncidentButtonVisible = user.role ? fareService.canReportIncident(user.role) : false;
  const isFareConfirmable = fare ? fareService.isFareConfirmable(fare) : false;
  const isFareConfirmationCancellable = fare ? fareService.isFareConfirmationCancellable(fare) : false;
  const hasGroupedFares = !!groupedFares && groupedFares.length > 0;
  const hasIncidents = fare.incidents && fare.incidents.length > 0;
  const hasJourney = journey && journeyFares.length > 1;
  const isEditButtonVisible = !isReadOnly && fare.status && fareService.isFareEditable(fare);
  const isDeleteButtonVisible = !isReadOnly && fare.status && fareService.isFareDeletable(fare);
  const isTickButtonVisible =
    !isReadOnly && fare.status && fareService.isFareTickable(fare) && fareService.canTickFare(user.role);

  const chipLabel = useMemo(() => {
    if (!hasJourney) {
      return fare.isReturnTripFromFare ? i18n.return : i18n.outward;
    }
    const index = journeyFares.findIndex((journeyFare) => journeyFare.id === fare.id);
    const total = journeyFares.filter((fare, index) => index !== 0 && !fare.isReturnTripFromFare).length;
    return getJourneyFareChipLabel({
      isOutwardFare: index === 0,
      isReturnFare: !!fare.isReturnTripFromFare,
      index,
      total,
    });
  }, [fare, journeyFares, hasJourney]);

  return (
    <>
      <DialogTitle>
        <HeaderFareModal
          reference={fare.reference}
          onClose={onClose}
          status={fare.needUnitConfirmation && fare.status === 'PENDING' ? 'PULLED' : fare.status}
          isReturn={!!fare.isReturnTripFromFare}
        >
          {hasIncidents && (
            <Box marginLeft={1}>
              <Chip label={i18n.incidentWithCount(fare.incidents!.length)} className={classes.chipTitle} />
            </Box>
          )}
        </HeaderFareModal>
      </DialogTitle>
      <DialogContent>
        <Box marginBottom={5}>
          <Stack spacing={10}>
            <FareDetailsPatientSummary
              ipp={fare.patient.ipp}
              pan={fare.patient.pan}
              birthdate={fare.patient.birthdate}
              name={formatName(
                fare.patient.firstName,
                fare.patient.lastName,
                fare.patient.legalName,
                fare.patient.legalFirstName
              )}
            />
            <FareDetailsSummary fare={fare} chipLabel={chipLabel} />
            {hasJourney && <JourneyDetails journey={journey} fares={journeyFares} />}
            <Stack alignSelf="flex-end">
              <FareDetailsActions
                status={fare.status}
                onDelete={isDeleteButtonVisible ? onCancelJourney : undefined}
                onEdit={isEditButtonVisible ? handleClickEdit : undefined}
                onTick={isTickButtonVisible ? handleFareNextTick : undefined}
                onCreateIncident={isReportIncidentButtonVisible ? handleClickIncident : undefined}
                onConfirm={isFareConfirmable ? handleFareUnitConfirmation : undefined}
                onCancel={isFareConfirmationCancellable ? handleFareUnitCancelation : undefined}
              />
            </Stack>
            {hasIncidents && (
              <Stack spacing={2}>
                {fare.incidents!.map((incident) => (
                  <IncidentMessage incident={incident} />
                ))}
              </Stack>
            )}
            {hasGroupedFares && <FareDetailsGroupedFares fares={groupedFares} />}
            {(fare.status !== 'WAITING' || (fare.porters && fare.porters.length > 0)) && (
              <PorterAssignation
                fareId={fare.id}
                healthCenter={fare.healthCenter}
                farePosition={fare.position}
                fareStatus={fare.status}
                fareAssignations={fare.assignations}
                isReadOnly={isAssignationReadOnly}
              />
            )}
            <FareHistory fareId={fare.id} />
          </Stack>
        </Box>
      </DialogContent>
    </>
  );
};

export default FareDetailsModalContent;
