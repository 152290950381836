/**
 * Check if the user has access to the route
 *
 *  Example:
 *    hasAccessToRoute(['*'], 'sabri.team') // true
 *    hasAccessToRoute(['sabri'], 'sabri.team') // true
 *    hasAccessToRoute(['sabri.fares'], 'sabri.team') // false
 *
 * @param navigationACL
 * @param route
 * @returns {boolean}
 */
export const hasAccessToRoute = (navigationACL: string[], route: string) => {
  // If user has no navigationACL, we use his role to determine if he has access to the route
  if (navigationACL.length === 0) {
    return true;
  }
  for (const acl of navigationACL) {
    const test = acl.replace('.*', '').replace('*', '');

    if (test.length > route.length ? test.startsWith(route) : route.startsWith(test)) {
      return true;
    }
  }

  return false;
};
