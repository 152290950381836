import { IconButton, TextField, Tooltip } from '@mui/material';
import Cross from 'common/components/Icons/Cross';
import Search from 'common/components/Icons/Search';
import { i18n } from 'common/locale';
import { transition } from 'common/theme';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

type SearchPatientProps = {
  value?: string;
  onChange: (value: string) => void;
};

const SearchPatient = ({ value, onChange }: SearchPatientProps) => {
  const [open, setOpen] = useState(value && value.length > 0);
  const [focus, setFocus] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onChange('');
    if (!focus) {
      if (value) {
        setFocus(true);
      } else {
        setOpen(false);
      }
    }
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    if (!value) {
      setOpen(false);
    }
    setFocus(false);
  };

  const handleClick = () => {
    setOpen(true);
    setFocus(true);
  };

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (!focus && !value) {
      setOpen(false);
    }
  }, [value, focus]);

  return (
    <>
      <Tooltip title={i18n.searchPatient} placement="top" arrow={false} enterDelay={500}>
        <IconButton
          onClick={handleClick}
          sx={{
            width: open ? 240 : undefined,
            transition,
            opacity: open ? 0 : 1,
            zIndex: open ? -1 : 1,
          }}
          variant="filled"
          shape="rounded"
          size="large"
        >
          <Search />
        </IconButton>
      </Tooltip>
      <TextField
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        variant="filled"
        InputProps={{
          endAdornment: value ? (
            <IconButton onClick={handleClear} edge="end">
              <Cross />
            </IconButton>
          ) : (
            <Search color="secondary" />
          ),
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={i18n.searchPatient}
        sx={{ width: open ? 240 : 40, transition, opacity: open ? 1 : 0, position: 'absolute', zIndex: open ? 1 : -1 }}
        style={{ marginLeft: 0 }}
      />
    </>
  );
};

export default SearchPatient;
