import { Box } from '@mui/material';
import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar } from './components';
import { AppBarContextProvider } from './components/AppBar/AppBarContext';
import { LayoutContext } from './LayoutContext';

const Layout = () => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <AppBarContextProvider>
      <Box overflow="auto" height="100vh" display="flex" flexDirection="column" ref={ref}>
        <LayoutContext.Provider value={{ containerRef: ref }}>
          <AppBar />
          <Outlet />
        </LayoutContext.Provider>
      </Box>
    </AppBarContextProvider>
  );
};

export default Layout;
