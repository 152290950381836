import { Components } from '@mui/material';
import palette from '../palette';

const MuiToggleButton: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      border: `none !important`,
      borderRadius: '8px !important',
      color: palette.secondary.main,
      fontWeight: 400,
      backgroundColor: 'transparent',
      padding: '12px 11px 12px 12px',
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    sizeMedium: {
      lineHeight: '16px',
    },
  },
};

export default MuiToggleButton;
