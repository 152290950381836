import { Unit } from '@ambuliz/sabri-core';
import { Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { UnitResultListItem } from './UnitResultListItem';

type UnitResultListProps = {
  isLoading: boolean;
  onUnitClick: (unitId: string) => void;
  units: Unit[];
};

export const UnitResultList = ({ units, isLoading, onUnitClick }: UnitResultListProps) => {
  if (isLoading && units.length === 0) {
    return (
      <Stack spacing={2}>
        {Array.from({ length: 10 }).map((_, index) => (
          <Skeleton key={index} variant="rounded" height={53} animation="wave" />
        ))}
      </Stack>
    );
  }

  if (units.length === 0) {
    return (
      <Typography color="secondary" fontWeight={600}>
        Pas d'unité trouvée.
      </Typography>
    );
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell width="10%">Type</TableCell>
          <TableCell width="10%">{i18n.department}</TableCell>
          <TableCell width="20%">{i18n.specialties}</TableCell>
          <TableCell width="20%">Emplacements</TableCell>
          <TableCell width="20%">{i18n.responsibleUnits}</TableCell>
          <TableCell width="5%"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {units.map((unit) => (
          <UnitResultListItem key={unit.id} unit={unit} onClick={() => onUnitClick(unit.id)} />
        ))}
      </TableBody>
    </Table>
  );
};
