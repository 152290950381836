import { Cloud } from '@ambuliz/sabri-core';
import { format } from 'date-fns';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Patient } from './CreatePatientForm';

const usePatient = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const create = async (patient: Patient) => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      await Cloud.createPartialPatient({
        gender: patient.gender,
        lastName: patient.lastName,
        firstName: patient.firstName,
        birthdate: new Date(format(patient.birthdate, 'yyyy-MM-dd')),
        ipp: patient.ipp,
        pan: patient.pan,
      });
      ReactDOM.unstable_batchedUpdates(() => {
        setSuccess(true);
        setLoading(false);
      });
    } catch (error) {
      ReactDOM.unstable_batchedUpdates(() => {
        setError(true);
        setLoading(false);
      });
    }
  };

  return {
    create,
    loading,
    success,
    error,
  };
};

export default usePatient;
