import { PatientGender } from '@ambuliz/sabri-core';
import { Stack } from '@mui/material';
import { getPatientAge, getPatientEmojiIcon } from 'common/utils';
import EllipsisTypography from '../EllipsisTypography';
import Emoji from '../Emoji';

type PatientNameWithEmojiProps = {
  name: string;
  age?: number;
  birthdate?: Date;
  gender: PatientGender;
  bgcolor?: string;
  disableTooltip?: boolean;
};

const PatientNameWithEmoji: React.FC<PatientNameWithEmojiProps> = ({
  name,
  age,
  gender,
  birthdate,
  bgcolor = 'white',
  disableTooltip,
}) => {
  if (!age && birthdate) {
    age = getPatientAge(birthdate);
  }

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <Emoji name={age ? getPatientEmojiIcon(gender, age) : 'adult'} bgColor={bgcolor} />
      <EllipsisTypography fontWeight={600} noWrap disableTooltip={disableTooltip}>
        {name}
      </EllipsisTypography>
    </Stack>
  );
};

export default PatientNameWithEmoji;
