import { FareStatus, Porter } from '@ambuliz/sabri-core';
import React from 'react';
import { i18n } from '../../../locales';
import { LoaderContent } from '../../LoaderContent/LoaderContent';
import { PorterAssignationsTable } from '../PorterAssignationsTable';
import { AssignedPortersTableRow } from './AssignedPortersTableRow';

type AssignedPorter = {
  fareAssignationId: string;
  porter: Porter;
};

type AssignedPortersTableProps = {
  fareId: string;
  fareStatus?: FareStatus;
  assignedPorters: AssignedPorter[];
  isReadOnly?: boolean;
  isLoading?: boolean;
};

export const AssignedPortersTable: React.FC<AssignedPortersTableProps> = ({
  fareId,
  fareStatus,
  assignedPorters = [],
  isReadOnly,
  isLoading,
}) => {
  if (isLoading) {
    return <LoaderContent />;
  }

  const showConfirmationOnDelete = assignedPorters.length <= 1 && fareStatus !== 'WAITING';

  return (
    <>
      <PorterAssignationsTable
        isReadOnly={isReadOnly}
        isEmpty={assignedPorters.length === 0}
        emptyCaption={i18n.anyPorterAssigned}
      >
        {assignedPorters.map((assignedPorter) => (
          <AssignedPortersTableRow
            key={assignedPorter.fareAssignationId}
            fareAssignationId={assignedPorter.fareAssignationId}
            fareId={fareId}
            porter={assignedPorter.porter}
            showConfirmation={showConfirmationOnDelete}
            isReadOnly={isReadOnly}
          />
        ))}
      </PorterAssignationsTable>
    </>
  );
};
