import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowLeft: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3194 13.541C7.02059 13.8279 6.54581 13.8182 6.25896 13.5194L1.45896 8.5194C1.18035 8.22917 1.18035 7.77082 1.45896 7.4806L6.25896 2.4806C6.54582 2.18179 7.02059 2.1721 7.3194 2.45896C7.61821 2.74581 7.6279 3.22059 7.34104 3.5194L3.75966 7.25L14 7.25C14.4142 7.25 14.75 7.58579 14.75 8C14.75 8.41421 14.4142 8.75 14 8.75L3.75966 8.75L7.34104 12.4806C7.6279 12.7794 7.61821 13.2542 7.3194 13.541Z"
      />
    </SvgIcon>
  );
};

export default ArrowLeft;
