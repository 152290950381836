import { Components } from '@mui/material';
import color from '../color';
import palette from '../palette';

const MuiMenuItem: Components['MuiMenuItem'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: color.blue[5],
      },
      '&:focus': {
        backgroundColor: color.blue[5],
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        fontWeight: '600',
        color: palette.primary.main,
        '& .MuiTypography-root': {
          fontWeight: 600,
        },
        '& .MuiListItemIcon-root': {
          color: palette.primary.main,
        },
        '&:hover': {
          backgroundColor: color.blue[5],
        },
        '&:focus': {
          backgroundColor: color.blue[5],
        },
      },
      '& .MuiListItemIcon-root': {
        minWidth: 24,
      },
      '& .MuiListItemText-root': {
        paddingLeft: 4,
      },
    },
  },
};

export default MuiMenuItem;
