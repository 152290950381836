import { Area } from '@ambuliz/sabri-core';
import { call, put, takeLatest } from 'redux-saga/effects';
import { requests } from '../../const/requests';
import { areaSchema } from '../../const/schemas';
import { areaTree } from '../../services/areaTree';
import { cleanNormalize } from '../../utils/cleanNormalize';
import { safeSaga } from '../../utils/safeSaga';
import { getAreasType } from '../actions/area';
import { entityActions } from '../reducers/entity';

function* getAreas() {
  const response: Area[] = yield call(requests.getAreas);
  if (response) {
    areaTree.init(response);
    const responseToJSON = response.map((object: Parse.Object) => object.toJSON());
    const { entities } = cleanNormalize(responseToJSON, [areaSchema]);
    yield put(entityActions.setEntities(entities));
    return responseToJSON;
  }
}

export function* areaRootSaga() {
  yield takeLatest(getAreasType, safeSaga(getAreas));
}
