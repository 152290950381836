import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { format, isEqual, isValid, startOfDay, startOfToday } from 'date-fns';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MovementStatus } from './MovementsList/MovementRow';

export type FlowType = 'admission' | 'discharge';
export type ViewType = 'kanban' | 'list' | 'sheets';
export const movementTypeFilters = [
  'SCHEDULED_ADMISSION',
  'SCHEDULED_DISCHARGE',
  'INTERNAL_MUTATION',
  'DIRECT_ADMISSION',
] as const;
export type MovementTypeFilter = typeof movementTypeFilters[number];

export type MovementsFilter = {
  genders: PatientGender[];
  specificities: AccommodationSpecificity[];
  date: Date;
  view: ViewType;
  isMulti?: boolean;
  search?: string;
  movementTypes: MovementTypeFilter[];
  movementStatuses: MovementStatus[];
  practitioners: string[];
};

const useMovementsPageSearchParams = (isMulti = false) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<MovementsFilter>(getFilterFromParams(searchParams, isMulti));

  const handleFilterChange = useCallback(
    ({
      date,
      genders,
      specificities,
      view,
      search,
      movementTypes,
      movementStatuses,
      practitioners,
    }: MovementsFilter) => {
      date = startOfDay(date);
      if (date && !isEqual(date, startOfToday())) {
        searchParams.set('date', format(date, 'yyyy-MM-dd'));
      } else {
        searchParams.delete('date');
      }

      if (genders.length > 0) {
        searchParams.set('genders', genders.join(','));
      } else {
        searchParams.delete('genders');
      }

      if (specificities.length > 0) {
        searchParams.set('specificities', specificities.join(','));
      } else {
        searchParams.delete('specificities');
      }

      if (search) {
        searchParams.set('search', search);
      } else {
        searchParams.delete('search');
      }

      if (movementTypes.length > 0) {
        searchParams.set('movementTypes', movementTypes.join(','));
      } else {
        searchParams.delete('movementTypes');
      }

      if (movementStatuses.length > 0) {
        searchParams.set('movementStatuses', movementStatuses.join(','));
      } else {
        searchParams.delete('movementStatuses');
      }

      if (view !== 'kanban') {
        searchParams.set('view', view);
      } else {
        searchParams.delete('view');
      }

      setSearchParams(searchParams);
      setFilter((filter) => {
        const newFilter = { ...filter, view };

        if (!search || search !== filter.search) {
          newFilter.search = search;
        }

        if (date && !isEqual(date, newFilter.date)) {
          newFilter.date = date;
        }

        if (JSON.stringify(specificities) !== JSON.stringify(filter.specificities)) {
          newFilter.specificities = specificities;
        }

        if (JSON.stringify(genders) !== JSON.stringify(filter.genders)) {
          newFilter.genders = genders;
        }

        if (JSON.stringify(movementTypes) !== JSON.stringify(filter.movementTypes)) {
          newFilter.movementTypes = movementTypes;
        }

        if (JSON.stringify(movementStatuses) !== JSON.stringify(filter.movementStatuses)) {
          newFilter.movementStatuses = movementStatuses;
        }

        if (JSON.stringify(practitioners) !== JSON.stringify(filter.practitioners)) {
          newFilter.practitioners = practitioners;
        }

        return newFilter;
      });
      return searchParams;
    },
    [searchParams, setSearchParams]
  );

  const resetFilter = useCallback(
    () => handleFilterChange(getFilterFromParams(searchParams, isMulti)),
    [searchParams, handleFilterChange, isMulti]
  );

  return {
    filter,
    setFilter: handleFilterChange,
    resetFilter,
  };
};

export const getFilterFromParams = (params: URLSearchParams, isMulti: boolean): MovementsFilter => {
  return {
    date: params.get('date') && isValid(new Date(params.get('date')!)) ? new Date(params.get('date')!) : startOfToday(),
    view:
      (params.get('view') as ViewType) || isMulti
        ? ['list', 'sheets'].includes(params.get('view') as ViewType)
          ? (params.get('view') as ViewType)
          : ('list' as ViewType)
        : ('kanban' as ViewType),
    genders: (params.get('genders')?.split(',') || []) as PatientGender[],
    specificities: (params.get('specificities')?.split(',') || []) as AccommodationSpecificity[],
    search: (params.get('search') || '').trim(),
    movementTypes: (params.get('movementTypes')?.split(',') || []) as MovementTypeFilter[],
    movementStatuses: (params.get('movementStatuses')?.split(',') || []) as MovementStatus[],
    practitioners: [],
  };
};

export default useMovementsPageSearchParams;
