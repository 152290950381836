import { SvgIcon, SvgIconProps } from '@mui/material';

const Sheets: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.244 1.5C5.38539 1.5 5.5 1.61462 5.5 1.756V5.244C5.5 5.38539 5.38539 5.5 5.244 5.5H1.756C1.61462 5.5 1.5 5.38539 1.5 5.244V1.756C1.5 1.61462 1.61462 1.5 1.756 1.5H5.244ZM0 0.5C0 0.223858 0.223858 0 0.5 0H6.5C6.77614 0 7 0.223858 7 0.5V6.5C7 6.77614 6.77614 7 6.5 7H0.5C0.223858 7 0 6.77614 0 6.5V0.5ZM5.25 10.5C5.38807 10.5 5.5 10.6119 5.5 10.75V14.25C5.5 14.3881 5.38807 14.5 5.25 14.5H1.75C1.61193 14.5 1.5 14.3881 1.5 14.25V10.75C1.5 10.6119 1.61193 10.5 1.75 10.5H5.25ZM0 9.5C0 9.22386 0.223858 9 0.5 9H6.5C6.77614 9 7 9.22386 7 9.5V15.5C7 15.7761 6.77614 16 6.5 16H0.5C0.223858 16 0 15.7761 0 15.5V9.5ZM14.5 1.75C14.5 1.61193 14.3881 1.5 14.25 1.5H10.75C10.6119 1.5 10.5 1.61193 10.5 1.75V5.25C10.5 5.38807 10.6119 5.5 10.75 5.5H14.25C14.3881 5.5 14.5 5.38807 14.5 5.25V1.75ZM9.5 0C9.22386 0 9 0.223858 9 0.5V6.5C9 6.77614 9.22386 7 9.5 7H15.5C15.7761 7 16 6.77614 16 6.5V0.5C16 0.223858 15.7761 0 15.5 0H9.5ZM14.25 10.5C14.3881 10.5 14.5 10.6119 14.5 10.75V14.25C14.5 14.3881 14.3881 14.5 14.25 14.5H10.75C10.6119 14.5 10.5 14.3881 10.5 14.25V10.75C10.5 10.6119 10.6119 10.5 10.75 10.5H14.25ZM9 9.5C9 9.22386 9.22386 9 9.5 9H15.5C15.7761 9 16 9.22386 16 9.5V15.5C16 15.7761 15.7761 16 15.5 16H9.5C9.22386 16 9 15.7761 9 15.5V9.5Z"
      />
    </SvgIcon>
  );
};

export default Sheets;
