import { ExternalPatientTransport, Patient } from '@ambuliz/sabri-core';
import { useExternalTransportModal } from 'common/components';
import { useMemo } from 'react';
import { StepItem } from '../context/Step';

const useUpcomingPatientTransport = (stepItems: StepItem[]) => {
  const { src, create, viewDetails, close } = useExternalTransportModal();

  const upcomingPatientTransport = useMemo(() => {
    const externalTransports = filterExternalTransports(stepItems);
    return getUpcomingPatientTransport(externalTransports);
  }, [stepItems]);

  const handleUpcomingPatientTransportClick = async (id: string) => {
    await viewDetails(id);
  };

  const handleOrderExternalTransportClick = async (patient?: Patient) => {
    if (patient) {
      await create(patient);
    }
  };

  return {
    src,
    upcomingPatientTransport,
    handleUpcomingPatientTransportClick,
    handleOrderExternalTransportClick,
    onModalClose: close,
  };
};

const filterExternalTransports = (stepItems: StepItem[]) =>
  stepItems
    .filter((stepItem) => stepItem.type === 'externalTransport')
    .map((stepItem) => stepItem.step) as ExternalPatientTransport[];

const getUpcomingPatientTransport = (externalTransports: ExternalPatientTransport[]) => {
  const upcomingPatientTransport = externalTransports
    .sort((a, b) => b.startAt.getTime() - a.startAt.getTime())
    .filter(
      (transport) =>
        ['VALIDATED', 'PLANNED', 'IN_PROGRESS'].includes(transport.status) &&
        transport.endAt.getTime() > new Date().getTime()
    )[0];

  return upcomingPatientTransport;
};

export default useUpcomingPatientTransport;
