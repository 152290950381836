import { Box, Tooltip, Typography } from '@mui/material';
import { useRef } from 'react';

type DataItemProps = {
  label: string;
  value?: string;
  minWidth?: string | number;
};

const DataItem = ({ label, value, minWidth }: DataItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const isOverflowing =
    !!containerRef.current?.scrollWidth &&
    !!containerRef.current?.clientWidth &&
    containerRef.current?.scrollWidth > containerRef.current?.clientWidth;

  return (
    <Tooltip title={isOverflowing ? label + '\n' + value : ''} placement="top">
      <Box whiteSpace="nowrap" minWidth={minWidth || 0}>
        <Typography variant="body2" color="secondary" noWrap>
          {label}
        </Typography>
        <Typography variant="body2" color="secondary" fontWeight={600} noWrap ref={containerRef}>
          {value || '-'}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default DataItem;
