import { CircularProgress, Link, Stack, TextField, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { Comment } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';
import { useEffect, useState } from 'react';

type BedCommentSummaryProps = {
  accommodationId: string;
  comment?: string;
  size?: 'medium' | 'small';
};

const BedCommentSummary: React.FC<BedCommentSummaryProps> = ({ accommodationId, comment, size = 'medium' }) => {
  const { update, loading } = useAccommodation(accommodationId);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedComment, setUpdatedComment] = useState(comment);

  useEffect(() => {
    setUpdatedComment(comment);
  }, [comment]);

  const updateComment = async () => {
    let newValue: string | undefined | null = updatedComment;
    if (updatedComment !== undefined && !updatedComment?.length) {
      newValue = null;
    }
    try {
      await update({ comment: newValue });
      toast.success({
        ...i18n.accommodationCommentUpdatedSuccess,
        icon: <Emoji name="writingHand" size={24} />,
      });
      setIsEditing(false);
    } catch (error) {
      toast.error(i18n.accommodationCommentUpdatedError);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setUpdatedComment(comment);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Comment sx={{ fontSize: 12 }} color="secondary" />
          <Typography variant={size === 'medium' ? 'body1' : 'body2'} fontWeight={size === 'medium' ? 700 : 600}>
            {i18n.commentForm.label}
          </Typography>
          {loading && <CircularProgress size={16} />}
        </Stack>
        {isEditing ? (
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Link
              disabled={loading}
              component="button"
              variant="body2"
              color={color.grey[80]}
              sx={{
                fontSize: size === 'small' ? 12 : 14,
              }}
              onClick={handleCancel}
            >
              {i18n.cancel}
            </Link>
            <Link
              disabled={loading}
              component="button"
              variant="body2"
              color={palette.primary.main}
              sx={{
                fontSize: size === 'small' ? 12 : 14,
              }}
              onClick={updateComment}
            >
              {i18n.validate}
            </Link>
          </Stack>
        ) : (
          <Link
            component="button"
            variant="body2"
            color={palette.primary.main}
            sx={{
              fontSize: size === 'small' ? 12 : 14,
            }}
            onClick={() => setIsEditing(true)}
          >
            {i18n.updateVerb}
          </Link>
        )}
      </Stack>
      <TextField
        style={{ overflow: 'hidden' }}
        inputProps={{ className: updatedComment ? 'Mui-focused' : '' }}
        disabled={!isEditing}
        multiline
        minRows={isEditing ? 3 : undefined}
        maxRows={isEditing ? 3 : undefined}
        placeholder={!comment ? (isEditing ? i18n.commentForm.placeholder : i18n.commentForm.empty) : undefined}
        value={updatedComment}
        onChange={(evt) => {
          const hasLineBreak = evt.target.value.match(/$/gm);

          if (hasLineBreak && hasLineBreak.length > 3) {
            return;
          }

          setUpdatedComment(evt.target.value);
        }}
      />
    </Stack>
  );
};

export default BedCommentSummary;
