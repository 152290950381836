import { Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { hyphenIfUndefined } from 'common/utils';
import styled from 'styled-components';

type BedsAvailabilitiesProps = {
  total: number | undefined;
  male?: number;
  female?: number;
  indifferent?: number;
  showTooltip?: boolean;
};

const BedsAvailabilities: React.FC<BedsAvailabilitiesProps> = ({
  total,
  male,
  female,
  indifferent,
  showTooltip = false,
}) =>
  showTooltip ? (
    <Grid container>
      <Tooltip title={<TooltipContent male={male} female={female} indifferent={indifferent} />}>
        <Typography fontWeight={600}>{hyphenIfUndefined(total)}</Typography>
      </Tooltip>
    </Grid>
  ) : (
    <Stack spacing={2} direction="row" flexWrap="nowrap" alignItems="center">
      <DataSmall>{hyphenIfUndefined(total)}</DataSmall>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem style={{ height: 14 }} />}
      >
        <Typography variant="caption" textAlign="center" sx={{ minWidth: 24 }}>
          {hyphenIfUndefined(male)} H
        </Typography>
        <Typography variant="caption" textAlign="center" sx={{ minWidth: 24 }}>
          {hyphenIfUndefined(female)} F
        </Typography>
        <Typography variant="caption" textAlign="center" sx={{ minWidth: 24 }}>
          {hyphenIfUndefined(indifferent)} M
        </Typography>
      </Stack>
    </Stack>
  );

const TooltipContent: React.FC<{ male?: number; female?: number; indifferent?: number }> = ({
  male,
  female,
  indifferent,
}) => (
  <>
    <Typography variant="body2">
      {i18n.bedsMale + ' : '}
      <Typography variant="body2" component="span" fontWeight={600}>
        {hyphenIfUndefined(male)}
      </Typography>
    </Typography>
    <Typography variant="body2">
      {i18n.bedsFemale + ' : '}
      <Typography variant="body2" component="span" fontWeight={600}>
        {hyphenIfUndefined(female)}
      </Typography>
    </Typography>
    <Typography variant="body2">
      {i18n.bedsIndifferent + ' : '}
      <Typography variant="body2" component="span" fontWeight={600}>
        {hyphenIfUndefined(indifferent)}
      </Typography>
    </Typography>
  </>
);

export default BedsAvailabilities;

const DataSmall = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  min-width: 16px;
  text-align: center;
`;
