import { Cloud } from '@ambuliz/sabri-core';
import { createAction } from '@reduxjs/toolkit';

export const getArchivedFaresType = 'fare/getArchivedFares';
export const getArchivedFaresAction = createAction(getArchivedFaresType);

export const removeFareAssignationType = 'fare/removeFareAssignation';
export const removeFareAssignationAction = createAction(
  removeFareAssignationType,
  (payload: Cloud.RemoveFareAssignationParams) => ({
    payload,
  })
);

export const assignFareType = 'fare/assignFare';
export const assignFareAction = createAction(assignFareType, (payload: { fareId: string; porterIds: string[] }) => ({
  payload,
}));

export const deleteFareType = 'fare/deleteFare';
export const deleteFareAction = createAction(deleteFareType, (payload: { fareId: string }) => ({
  payload,
}));

export const postFareIncidentType = 'fare/postFareIncident';
export const postFareIncidentAction = createAction(postFareIncidentType, (payload: Cloud.CreateIncidentParams) => ({
  payload,
}));

export const fareTickType = 'fare/fareTick';
export const fareTickAction = createAction(fareTickType, (payload: Cloud.FareTickParams) => ({
  payload,
}));

export const confirmFareType = 'fare/confirmFare';
export const confirmFareAction = createAction(confirmFareType, (payload: Cloud.ConfirmFareParams) => ({
  payload,
}));

export const cancelConfirmFareActionType = 'fare/cancelConfirmFareAction';
export const cancelConfirmFareAction = createAction(
  cancelConfirmFareActionType,
  ({ fareId }: Cloud.CancelFareParams) => ({
    payload: {
      fareId,
    },
  })
);
