import color from '../color';

const MuiTimelineConnector = {
  styleOverrides: {
    root: {
      backgroundColor: color.grey[30],
    },
  },
};

export default MuiTimelineConnector;
