import { Fare } from '@ambuliz/sabri-core';
import { TableCell, TableRow } from '@mui/material';
import { getPatientFullName } from 'sabri/services/patient';
import { getDisplayDate } from '../../services/date';

type FareDetailsGroupedFareProps = {
  fare: Fare;
};

const FareDetailsGroupedFare: React.FC<FareDetailsGroupedFareProps> = ({ fare }) => {
  const patientName = getPatientFullName(fare.patient);
  return (
    <TableRow>
      <TableCell>{fare.reference}</TableCell>
      <TableCell>{patientName}</TableCell>
      <TableCell>{getDisplayDate(fare.wantedDate?.toDateString())}</TableCell>
    </TableRow>
  );
};

export default FareDetailsGroupedFare;
