import { mapAccommodationStep } from "./accommodationMapper";
import { mapAvailableBedStep } from './availableBedMapper';
import { mapClosedBedStep } from "./closedBedMapper";
import { mapExternalTransportStep } from "./externalTransportMapper";
import { mapMutationStep } from "./mutationMapper";

export const stepsMapper = {
  accommodation: mapAccommodationStep,
  closedBed: mapClosedBedStep,
  mutation: mapMutationStep,
  availableBed: mapAvailableBedStep,
  externalTransport: mapExternalTransportStep,
};
