import { AccommodationSpecificity, AccommodationStatus, AnomalyType, PatientGender } from '@ambuliz/sabri-core';
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { TimeFlowLine } from 'common/components';
import ActionButton from 'common/components/ActionButton';
import Unlock from 'common/components/Icons/Unlock';
import { i18n } from 'common/locale';
import getShortDuration from 'common/utils/getShortDuration';
import { format } from 'date-fns';
import { AccommodationSpecificityBadges } from 'kurt/components';
import EditableTableCell from 'kurt/components/EditableTableCell/EditableTableCell';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import { useAccommodation } from 'kurt/hooks';
import BedName from 'kurt/pages/Unit/BedName';
import { MakeBedAvailableDialog } from 'kurt/pages/Unit/Dialogs';
import { ClosedBedActionsMenu } from 'kurt/pages/Unit/Menus';
import { useEffect, useState } from 'react';

type ClosedBedRowProps = {
  id: string;
  name: string;
  unitName: string;
  status: AccommodationStatus;
  gender: PatientGender;
  startAt: Date;
  endAt?: Date;
  specificities?: AccommodationSpecificity[];
  comment?: string;
  anomalies?: { type: AnomalyType }[];
  displayUnitColumn: boolean;
  isReadOnly?: boolean;
  onClick: (accommodationId: string) => void;
};

const ClosedBedRow = ({
  id,
  name,
  unitName,
  status,
  gender,
  startAt,
  endAt,
  specificities,
  comment: initialComment,
  anomalies,
  displayUnitColumn,
  isReadOnly = false,
  onClick,
}: ClosedBedRowProps) => {
  const [action, setAction] = useState<Action>();
  const [comment, setComment] = useState(initialComment || '');
  const { update, loading } = useAccommodation(id);

  useEffect(() => setComment(initialComment || ''), [initialComment]);

  const handleClose = () => setAction(undefined);

  const handleCommentSubmit = (value: string) => {
    if (value !== initialComment) {
      update({ comment: value });
    }
  };

  return (
    <>
      <TableRow
        onClick={() => onClick(id)}
        hover
        sx={{
          cursor: 'pointer',
        }}
      >
        <TableCell sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Box display="flex" sx={{ marginLeft: anomalies?.length ? '-18px' : 0 }}>
            <BedName name={name} anomalies={anomalies} />
          </Box>
        </TableCell>
        {displayUnitColumn && (
          <TableCell>
            <Typography variant="body2" color="secondary">
              {unitName}
            </Typography>
          </TableCell>
        )}
        <TableCell width={100}>
          <BedStatusChip status="CLOSED" gender={gender} />
        </TableCell>
        <TableCell>
          {!isReadOnly && (
            <ActionButton
              icon={<Unlock />}
              onClick={(event) => {
                event.stopPropagation();
                setAction('MAKE_BED_AVAILABLE');
              }}
            >
              {i18n.openBed}
            </ActionButton>
          )}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell width={80}>
          {startAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(startAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 156, paddingX: 0 }}>
          <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
            <TimeFlowLine start="dashed" size="small" />
            <Box width={60}>
              <Typography noWrap variant="body2" color="secondary">
                {startAt && endAt ? getShortDuration(endAt, startAt) : '-'}
              </Typography>
            </Box>
            <TimeFlowLine end="dashed" size="small" />
          </Stack>
        </TableCell>
        <TableCell>
          {endAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(endAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {specificities && specificities.length > 0 && (
            <Box display="flex">
              <AccommodationSpecificityBadges specificities={specificities} />
            </Box>
          )}
        </TableCell>
        <EditableTableCell
          value={comment}
          onChange={setComment}
          onSubmit={handleCommentSubmit}
          loading={loading}
          isReadOnly={isReadOnly}
        />
        <TableCell width={16} sx={{ paddingLeft: 2 }}>
          {!isReadOnly && <ClosedBedActionsMenu id={id} status={status} />}
        </TableCell>
      </TableRow>

      {action === 'MAKE_BED_AVAILABLE' && (
        <MakeBedAvailableDialog
          open={action === 'MAKE_BED_AVAILABLE'}
          onClose={handleClose}
          onSuccess={handleClose}
          id={id}
        />
      )}
    </>
  );
};

type Action = 'MAKE_BED_AVAILABLE' | 'CANCEL_BED_CLOSED';

export default ClosedBedRow;
