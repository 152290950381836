import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { Phone } from 'common/components/Icons';
import React from 'react';

type PhoneContactsTooltipProps = {
  contacts: PhoneContact[];
};

export type PhoneContact = { name: string; phoneNumbers: string[] };

const PhoneContactsTooltip: React.FC<PhoneContactsTooltipProps> = ({ contacts = [] }) => {
  return (
    <Tooltip title={<PhoneContactsTitleTooltip contacts={contacts} />}>
      <Grid container style={{ cursor: 'pointer' }}>
        <Phone color="secondary" />
      </Grid>
    </Tooltip>
  );
};

const PhoneContactsTitleTooltip: React.FC<PhoneContactsTooltipProps> = ({ contacts = [] }) => {
  return (
    <Stack spacing={2}>
      {contacts.map((contact) => (
        <Stack spacing={1} key={contact.name}>
          <Stack spacing={1}>
            <Typography variant="body2">{contact.name} :</Typography>
            {contact.phoneNumbers.map((phoneNumber) => (
              <Typography variant="body2" fontWeight={600} key={phoneNumber}>
                {phoneNumber}
              </Typography>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default PhoneContactsTooltip;
