"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_NOTIFICATION_CONFIGURATION = void 0;
const fare_1 = require("./fare");
const porter_1 = require("./porter");
const step_1 = require("./step");
// Record de la configuration par défaut pour les notifications
exports.DEFAULT_NOTIFICATION_CONFIGURATION = {
    FARE_ASSIGNED: fare_1.FARE_ASSIGNED_DEFAULT_CONFIGURATION,
    FARE_UPDATED: fare_1.FARE_UPDATED_DEFAULT_CONFIGURATION,
    FARE_UNASSIGNED: fare_1.FARE_UNASSIGNED_DEFAULT_CONFIGURATION,
    PORTER_SUPPORT_ASSIGNED: porter_1.PORTER_SUPPORT_ASSIGNED_DEFAULT_CONFIGURATION,
    PORTER_SUPPORT_UNASSIGNED: porter_1.PORTER_SUPPORT_UNASSIGNED_DEFAULT_CONFIGURATION,
    FARE_READY_TO_BE_ASSIGNED: fare_1.FARE_READY_TO_BE_ASSIGNED_DEFAULT_CONFIGURATION,
    NEW_FARE_INCIDENT: fare_1.NEW_FARE_INCIDENT_DEFAULT_CONFIGURATION,
    PORTER_START_OF_SERVICE: porter_1.PORTER_START_OF_SERVICE_DEFAULT_CONFIGURATION,
    PORTER_END_OF_SERVICE: porter_1.PORTER_END_OF_SERVICE_DEFAULT_CONFIGURATION,
    NEW_PERFORMER_REQUEST: step_1.NEW_PERFORMER_REQUEST_DEFAULT_CONFIGURATION,
    PERFORMER_REQUEST_ACCEPTED: step_1.PERFORMER_REQUEST_ACCEPTED_DEFAULT_CONFIGURATION,
    PERFORMER_REQUEST_REJECTED: step_1.PERFORMER_REQUEST_REJECTED_DEFAULT_CONFIGURATION,
};
