import Api, { Area } from '@ambuliz/sabri-core';
import { Skeleton, Stack } from '@mui/material';
import useParseQuery from 'common/hooks/useParseQuery';
import { useMemo } from 'react';
import { AreaManagementListItem } from './AreaManagementListItem';

export type AreaManagementListItemChildrenProps = {
  open: boolean;
  area: Area;
  isReadOnly?: boolean;
};

export const AreaManagementListItemChildren = ({
  area,
  open,
  isReadOnly = false,
}: AreaManagementListItemChildrenProps) => {
  const { results, isLoading } = useParseQuery(new Api.Query(Area).equalTo('parent', area), {
    enabled: open,
  });

  const children = useMemo(() => results.sort((a, b) => a.name.localeCompare(b.name)), [results]);

  if (!open) {
    return null;
  }

  return (
    <Stack spacing={2} marginLeft={6} marginTop={4}>
      {isLoading && <Skeleton variant="rounded" height={53} animation="wave"></Skeleton>}
      {children.map((child) => (
        <AreaManagementListItem key={child.id} area={child} isReadOnly={isReadOnly} />
      ))}
    </Stack>
  );
};
