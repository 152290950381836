import { SvgIcon, SvgIconProps } from '@mui/material';

const Edit: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4959 1.37765C14.0024 0.874116 14.7993 0.874116 15.3058 1.37765C15.8055 1.89832 15.8055 2.74232 15.3058 3.26299L14.6268 3.97032C14.5018 4.10045 14.2992 4.10045 14.1743 3.97032L12.8168 2.55632C12.6919 2.42615 12.6919 2.21515 12.8168 2.08499L13.4959 1.37765ZM12.6799 9.31882C12.6799 8.95063 12.9665 8.65215 13.3199 8.65215C13.6734 8.65215 13.9599 8.95063 13.9599 9.31882V13.3258C13.9599 14.4304 13.1003 15.3258 12.0399 15.3258H2.23995C1.17956 15.3258 0.319946 14.4304 0.319946 13.3258V3.65215C0.319946 2.54758 1.17956 1.65215 2.23995 1.65215H8.63995C8.99341 1.65215 9.27995 1.95063 9.27995 2.31882C9.27995 2.68701 8.99341 2.98548 8.63995 2.98548H2.23995C1.88648 2.98548 1.59995 3.28396 1.59995 3.65215V13.3258C1.59995 13.694 1.88648 13.9925 2.23995 13.9925H12.0399C12.3934 13.9925 12.6799 13.694 12.6799 13.3258V9.31882ZM7.58947 8.00036L8.94691 9.41436C9.07187 9.54449 9.27443 9.54449 9.39939 9.41436L13.4724 5.17169C13.5325 5.10916 13.5664 5.02424 13.5664 4.93569C13.5664 4.84714 13.5325 4.76223 13.4724 4.69969L12.1143 3.28503C11.9871 3.16048 11.7889 3.16048 11.6618 3.28503L7.58947 7.52903C7.46455 7.65919 7.46455 7.87019 7.58947 8.00036ZM6.71032 8.68276C6.75157 8.58016 6.83872 8.50534 6.94328 8.48276C7.04809 8.46109 7.15638 8.49536 7.23192 8.57409L8.39736 9.78609C8.47347 9.86534 8.50626 9.97912 8.48461 10.0889C8.46296 10.1987 8.38977 10.2899 8.28984 10.3314L6.35064 11.1394C6.23141 11.1839 6.09833 11.1554 6.00568 11.0654C5.91516 10.9712 5.88722 10.8299 5.93464 10.7061L6.71032 8.68276Z"
      />
    </SvgIcon>
  );
};

export default Edit;
