import { SvgIcon, SvgIconProps } from '@mui/material';

const ComfortBed: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M2.87 3.238c.09-.09.211-.14.338-.14h9.584a.478.478 0 0 1 .479.478v3.428h-1.695v-.11A1.733 1.733 0 0 0 9.843 5.16H6.157a1.733 1.733 0 0 0-1.734 1.734v.11H2.73V3.576c0-.126.05-.248.14-.338ZM1.474 7.023V3.576a1.733 1.733 0 0 1 1.734-1.733h9.584a1.734 1.734 0 0 1 1.733 1.733v3.447A1.733 1.733 0 0 1 16 8.737v3.318a.628.628 0 0 1-.627.627h-.11v.847a.628.628 0 0 1-1.255 0v-.847H1.992v.847a.628.628 0 0 1-1.255 0v-.847h-.11A.627.627 0 0 1 0 12.055V8.737a1.733 1.733 0 0 1 1.474-1.714Zm.611 1.236h-.352a.478.478 0 0 0-.478.478v2.69h13.49v-2.69a.478.478 0 0 0-.478-.478H2.102a.62.62 0 0 1-.017 0Zm8.236-1.255H5.679v-.11a.478.478 0 0 1 .479-.479h3.686a.478.478 0 0 1 .479.479v.11Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default ComfortBed;
