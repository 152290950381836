import { CircularProgress, IconButton, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { i18n } from 'sabri/locales';
import styled from 'styled-components';

type FareHistoryHeaderProps = {
  onClick: () => void;
  open: boolean;
  loading: boolean;
};

const FareHistoryHeaderContainer = styled.div`
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

const FareHistoryIconButton = styled(IconButton)<{ open: boolean }>`
  transition: transform 0.2s ease-in-out;
  transform: rotate(${({ open }) => (open ? '180' : '0')}deg);
`;

const FareHistoryHeader: React.FC<FareHistoryHeaderProps> = ({ onClick, open, loading = false }) => (
  <FareHistoryHeaderContainer onClick={onClick}>
    <Typography variant="h5">{i18n.fareHistory.headerTitle}</Typography>
    <FareHistoryIconButton open={open}>
      {loading ? <CircularProgress size={16} /> : <ExpandMore />}
    </FareHistoryIconButton>
  </FareHistoryHeaderContainer>
);

export default FareHistoryHeader;
