import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 96 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M77.879 82.11c-16.805 4.99-29.96-5.558-34.17-8.96-4.21-3.402-9.72-9.05-20.837-4.566-11.118 4.483-14.62.032-18.242-3.164-3.6-3.24-3.87-9.719-1.943-14.715 1.928-4.996 3.509-8.554.668-18.121C.514 23.016-.933 10.328 16.804 2.2c13.084-5.678 22.163 4.173 27.958 10.307 5.806 6.17 10.797 11.133 23.666 9.943 12.869-1.19 21.68 4.087 24.972 15.885 3.292 11.797 2.832 21.735 1.452 27.865-1.38 6.13-7.666 13.145-16.973 15.908Z"
      fill="#E6EDFF"
    />
    <rect
      x={68.029}
      y={44.144}
      width={49.181}
      height={7.842}
      rx={3.921}
      transform="rotate(59.501 68.03 44.144)"
      fill="#5470B8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.193 9.016c13.927 0 25.212 11.285 25.212 25.211 0 13.926-11.285 25.211-25.212 25.211-13.926 0-25.21-11.285-25.21-25.211 0-13.926 11.284-25.211 25.21-25.211Z"
      fill="#E6EDFF"
    />
    <path
      d="M51.642 27.303 42.193 31.6c-2.322 1.056-2.35 4.344-.046 5.44l9.292 4.418a13 13 0 0 0 11.272-.052l8.994-4.378c2.271-1.106 2.243-4.351-.046-5.417l-9.148-4.26a13 13 0 0 0-10.87-.048Z"
      fill="#fff"
    />
    <path d="M63.784 34.526a6.74 6.74 0 1 0-13.48 0 6.74 6.74 0 0 0 13.48 0Z" fill="#5470B8" stroke="#5470B8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.675 29.815c.631-.66 1.683-.69 2.35-.065.667.624.696 1.666.065 2.326-.631.66-1.683.69-2.35.065a1.633 1.633 0 0 1-.065-2.326Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.194 6.906c15.091 0 27.32 12.23 27.32 27.321 0 15.091-12.229 27.321-27.32 27.321-15.092 0-27.322-12.23-27.322-27.321 0-15.091 12.23-27.321 27.322-27.321Zm25.211 27.321c0-13.926-11.285-25.211-25.211-25.211-13.927 0-25.212 11.285-25.212 25.211 0 13.926 11.285 25.211 25.212 25.211 13.926 0 25.211-11.285 25.211-25.211Z"
      fill="#89A5ED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.194 2.58c17.482 0 31.648 14.165 31.648 31.647 0 17.483-14.166 31.649-31.648 31.649S25.545 51.709 25.545 34.227 39.712 2.58 57.194 2.58Zm.054 4.54c15.109 0 27.366 12.076 27.366 26.963 0 14.886-12.257 26.963-27.366 26.963-15.108 0-27.366-12.06-27.366-26.963 0-14.904 12.24-26.964 27.366-26.964Z"
      fill="#B5CAFF"
    />
    <path
      d="M20.517 23.72a.536.536 0 0 0 .046-.66.56.56 0 0 0-.617-.229 4.47 4.47 0 0 1-4.645-1.01.531.531 0 0 0-.658-.047.573.573 0 0 0-.228.624 4.518 4.518 0 0 1-1.001 4.665.54.54 0 0 0-.047.662c.126.195.389.296.617.23a4.561 4.561 0 0 1 4.646 1.005.53.53 0 0 0 .657.047.57.57 0 0 0 .23-.619 4.62 4.62 0 0 1 1-4.668Z"
      fill="#5470B8"
    />
    <path
      d="M.842 41.174a6.251 6.251 0 0 1 5.298 3.82c.135.312.451.47.777.417a.752.752 0 0 0 .603-.643c.162-2.334 1.655-4.424 3.803-5.322a.713.713 0 0 0 .416-.785.758.758 0 0 0-.643-.604 6.249 6.249 0 0 1-5.304-3.862.696.696 0 0 0-.778-.416.752.752 0 0 0-.603.643c-.155 2.373-1.655 4.424-3.796 5.364a.709.709 0 0 0-.415.78c.053.325.303.579.642.608Z"
      fill="#89A5ED"
    />
    <path
      d="M18.723 60.076c-.591-1.682-.152-3.595 1.044-4.862.186-.17.189-.468.055-.675-.135-.21-.405-.327-.636-.23a4.705 4.705 0 0 1-4.862-1.084c-.168-.187-.466-.19-.672-.056-.209.136-.327.41-.229.639.616 1.72.188 3.575-1.02 4.9-.186.169-.19.467-.055.674.133.21.405.327.636.23 1.676-.595 3.577-.155 4.838 1.046.17.187.466.192.674.06.23-.102.29-.386.227-.642Z"
      fill="#5470B8"
    />
  </svg>
);

export default SvgComponent;
