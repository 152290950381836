import { IncidentStored, FareStored, PorterStored } from '../reducers/entity';
import { createSelector } from '@reduxjs/toolkit';
import { selectEntitiesValues, selectEntities } from './entity';
import { EntityNames } from '../../const/schemas';

export type IncidentDenormalized = Omit<IncidentStored, 'fare' | 'porter'> & {
  fare: FareStored | undefined;
  porter: PorterStored | undefined;
  fareId: string;
  porterId: string | undefined;
};

export const selectIncidentsDenormalized = createSelector(
  [selectEntitiesValues(EntityNames.incidents), selectEntities(EntityNames.fares), selectEntities(EntityNames.porters)],
  (incidents, fares, porters) =>
    incidents.map((incident) => ({
      ...incident,
      fareId: incident.fareId,
      fare: fares[incident.fareId],
      porterId: incident.porterId,
      porter: incident.porterId ? porters[incident.porterId] : undefined,
    }))
);
