import { PerformerRequest } from '@ambuliz/sabri-core';

import { Stack, Typography } from '@mui/material';

import { i18n } from 'common/locale';
import React from 'react';
import PerformerCard from './PerformerCard';

const PerformersList: React.FC<{ performerRequests: PerformerRequest[] }> = ({ performerRequests }) => {
  return (
    <Stack direction="column" spacing={1}>
      {performerRequests.length === 0 ? (
        <Typography variant="body2">{i18n.mutationStatus.toAddress}</Typography>
      ) : (
        performerRequests.map((request) => <PerformerCard key={request.id} performerRequest={request} />)
      )}
    </Stack>
  );
};

export default PerformersList;
