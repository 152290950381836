import { ZoomIn, ZoomOut } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Slider,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Search as SearchIcon } from 'common/components/Icons';
import { Search as SearchImage } from 'common/components/Images';
import Timeline, { Period, TimelineRow } from 'common/components/Timeline';
import { ContentLoader, EmptyContent, PageSection, useLayout } from 'core/layout';
import { addDays, addYears, isValid, startOfDay, startOfMinute, subDays, subMinutes } from 'date-fns';
import { DetailsDialog } from 'kurt/components';
import { useMemo, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  AppointmentDetails,
  CreateAccommodation,
  CreateExternalPatientTransport,
  CreateFare,
  ExternalTransportDetails,
  FareDetails,
} from './dialogs';
import { AccommodationItem, AppointmentItem, ExternalTransportItem, FareItem } from './items';
import usePatientRows from './usePatientRows';

const PatientTimeline = () => {
  const navigate = useNavigate();
  const [period, setPeriod] = useState<Period>('week');
  const [date, setDate] = useState<Date>();
  const layout = useLayout();
  const container = useRef(layout.container);

  const start = useMemo(
    () => (period === 'day' ? startOfDay(date || new Date()) : subDays(startOfDay(date || new Date()), 1)),
    [date, period]
  );

  const end = useMemo(() => startOfDay(addDays(start, period === 'day' ? 3 : 14)), [start, period]);
  const [ipps, setIpps] = useState<string[]>([]);

  const { rows, loading } = usePatientRows({ start, end, ipps });

  const [scale, setScale] = useState(130);

  const [open, setOpen] = useState<string>();
  const [externalTransportDialogOpen, setExternalTransportDialogOpen] = useState<string>();
  const [fareOpen, setFareOpen] = useState<string>();
  const [addAccommodationDialogOpen, setAddAccommodationDialogOpen] = useState<string>();
  const anchorRef = useRef<Element | null>(null);

  const [ippsAsString, setIppsAsString] = useState<string>('');

  const handleDateChange = (date: Date | null) => {
    setDate(date && isValid(date) ? startOfMinute(date) : undefined);
  };

  return (
    <>
      <PageSection>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <TextField
              variant="outlined"
              fullWidth
              sx={{
                width: 300,
              }}
              label="Ipp1,Ipp2"
              onChange={(event) => setIppsAsString(event.target.value)}
            />
            <Button onClick={() => setIpps(ippsAsString.split(','))} startIcon={<SearchIcon />}>
              Rechercher
            </Button>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={4}>
            <ToggleButtonGroup exclusive value={period} onChange={(_, period) => setPeriod(period)}>
              <ToggleButton value="day">Jour</ToggleButton>
              <ToggleButton value="week">Semaine</ToggleButton>
            </ToggleButtonGroup>
            <Box width={120}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton onClick={() => setScale((scale) => (scale >= 50 ? scale - 30 : scale))}>
                  <ZoomOut />
                </IconButton>
                <Slider value={scale} onChange={(_, scale) => setScale(scale as number)} step={10} min={50} max={500} />
                <IconButton onClick={() => setScale((scale) => (scale <= 500 ? scale + 30 : scale))}>
                  <ZoomIn />
                </IconButton>
              </Stack>
            </Box>
            <DateTimePicker value={date} onChange={handleDateChange} />
          </Stack>
        </Stack>
      </PageSection>
      <PageSection scrollable noPadding noGutter withBackground>
        {rows.length === 0 && !loading ? (
          <EmptyContent Image={SearchImage} title="Pas de résultat" subtitle=""></EmptyContent>
        ) : loading ? (
          <ContentLoader />
        ) : (
          <Timeline container={container} start={start} end={end} title="Patient" period={period} scale={scale}>
            {rows.map(({ id, name, accommodations, fares, appointments, externalPatientTransports }) => {
              return (
                <TimelineRow
                  title={name}
                  onButtonClick={() => {
                    setOpen(id);
                  }}
                  key={id}
                >
                  {accommodations.map((accommodation) => (
                    <AccommodationItem
                      name={accommodation.bed?.name}
                      key={accommodation.id}
                      end={accommodation.endAt || addYears(start || new Date(), 1)}
                      start={accommodation.startAt}
                      onClick={() => navigate(`accommodation/${accommodation.id}`)}
                      color="primary"
                    />
                  ))}
                  {fares.map((fare) => (
                    <FareItem
                      key={fare.id}
                      start={fare.startedAt || (fare.wantedDate && subMinutes(fare.wantedDate, 15)) || new Date()}
                      end={fare.endedAt || fare.wantedDate || addYears(fare.startedAt || new Date(), 1)}
                      fromArea={fare.fromArea?.name}
                      toArea={fare.toArea?.name}
                      fromUnit={fare.fromUnit.name}
                      toUnit={fare.toUnit.name}
                      onClick={() => navigate(`fares/${fare.id}`)}
                      color="success"
                    />
                  ))}
                  {appointments.map(({ id, location, start, end }) => (
                    <AppointmentItem
                      key={id}
                      start={start}
                      end={end}
                      location={location ? location.name : ''}
                      onClick={() => navigate(`appointments/${id}`)}
                      color="warning"
                    />
                  ))}
                  {externalPatientTransports.map(({ id, startAt, endAt }) => (
                    <ExternalTransportItem
                      key={id}
                      start={startAt}
                      end={endAt}
                      onClick={() => navigate(`external-patient-transports/${id}`)}
                      color="danger"
                    />
                  ))}
                </TimelineRow>
              );
            })}
          </Timeline>
        )}
      </PageSection>
      <Popover
        anchorEl={anchorRef.current}
        open={!!open}
        onClose={() => setOpen('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack p={4} spacing={3}>
          <Typography variant="h4" minWidth={70}>
            Programmer le parcours de mon patient
          </Typography>
          <Stack spacing={2}>
            <Button
              fullWidth
              onClick={() => {
                setExternalTransportDialogOpen(open);
                setOpen('');
              }}
            >
              Commander un transport sanitaire
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setFareOpen(open);
                setOpen('');
              }}
            >
              Commander un brancardage
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setAddAccommodationDialogOpen(open);
                setOpen('');
              }}
            >
              Programmer un hébergement
            </Button>
          </Stack>
        </Stack>
      </Popover>

      {addAccommodationDialogOpen && (
        <CreateAccommodation id={addAccommodationDialogOpen} onClose={() => setAddAccommodationDialogOpen('')} />
      )}
      {externalTransportDialogOpen && (
        <CreateExternalPatientTransport
          id={externalTransportDialogOpen}
          onClose={() => setExternalTransportDialogOpen('')}
        />
      )}
      {fareOpen && <CreateFare id={fareOpen || ''} onClose={() => setFareOpen('')} />}

      <Routes>
        <Route path="accommodation/:id" element={<DetailsDialog />} />
        <Route path="fares/:id" element={<FareDetails />} />
        <Route path="external-patient-transports/:id" element={<ExternalTransportDetails />} />
        <Route path="appointments/:id" element={<AppointmentDetails />} />
      </Routes>
    </>
  );
};

export default PatientTimeline;
