import { ADMIN_ROUTES } from '@ambuliz/sabri-core';
import { RootRedirect, useAuthentication } from 'core/authentication';
import { hasAccessToRoute } from 'core/authentication/navigationACL';
import { Navigate } from 'react-router-dom';
import { findPathByRoute } from 'superadmin/routes';

// Navigate to the first route the user has access to
export const AdminRootRedirect = () => {
  const { navigationACL } = useAuthentication();
  for (const route of ADMIN_ROUTES) {
    if (route === 'admin') {
      continue;
    }
    if (hasAccessToRoute(navigationACL, route)) {
      const path = findPathByRoute(route);
      if (!!path) {
        return <Navigate to={path} />;
      }
    }
  }
  return <RootRedirect />;
};
