import { SvgIcon, SvgIconProps } from '@mui/material';

const UsersOutline: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M8 6.75C9.24264 6.75 10.25 5.74264 10.25 4.5C10.25 3.25736 9.24264 2.25 8 2.25C6.75736 2.25 5.75 3.25736 5.75 4.5C5.75 5.74264 6.75736 6.75 8 6.75ZM11.5 4.5C11.5 6.433 9.933 8 8 8C6.067 8 4.5 6.433 4.5 4.5C4.5 2.567 6.067 1 8 1C9.933 1 11.5 2.567 11.5 4.5Z" />
      <path d="M4.2997 11.8042C3.63261 12.715 3.42708 13.8257 3.44775 14.5563C3.45752 14.9013 3.18572 15.189 2.84068 15.1987C2.49564 15.2085 2.20802 14.9367 2.19825 14.5917C2.17155 13.6481 2.4256 12.2475 3.29126 11.0656C4.18061 9.85139 5.67465 8.92651 7.98745 8.92651C10.3 8.92651 11.8034 9.85088 12.7069 11.061C13.5872 12.2402 13.8613 13.6388 13.8479 14.5828C13.843 14.928 13.5593 15.2038 13.2141 15.1989C12.869 15.194 12.5932 14.9103 12.598 14.5651C12.6084 13.835 12.3873 12.7224 11.7053 11.8088C11.0464 10.9263 9.92021 10.1765 7.98745 10.1765C6.05498 10.1765 4.94311 10.9258 4.2997 11.8042Z" />
    </SvgIcon>
  );
};

export default UsersOutline;
