import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { Hospital } from 'common/components/Images';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { EmptyContent, PageSection, useAppBarContext } from 'core/layout';
import { NormalizedDepartment } from 'kurt/hooks/useDepartments';
import { useEffect, useRef, useState } from 'react';
import CustomGrid from './CustomGrid';
import DepartmentRow from './DepartmentRow';
import Skeleton from './Skeleton';

type DepartmentsTableProps = {
  departments: NormalizedDepartment[];
  loading?: boolean;
  isReadOnly?: boolean;
};

const DepartmentsTable: React.FC<DepartmentsTableProps> = ({ departments, loading, isReadOnly }) => {
  const { healthCenter } = useAuthentication();
  const { isFullScreen } = useAppBarContext();
  const canEdit = !isReadOnly && !healthCenter.autoComputeOccupanciesActivated;

  const isMedium = useMediaQuery('(max-width: 1250px)');
  const { appBarHeight } = useAppBarContext();
  const ref = useRef<HTMLDivElement>(null);

  const [columns, setColumns] = useState<number[]>(getColumns(canEdit, isMedium));

  useEffect(() => {
    setColumns(getColumns(canEdit, isMedium));
  }, [canEdit, isMedium]);

  return !loading && departments.length === 0 ? (
    <PageSection withBackground noGutter lastSection>
      <EmptyContent Image={Hospital} {...i18n.departmentsEmptyContent} />
    </PageSection>
  ) : (
    <>
      <PageSection
        withBackground
        noGutter
        sticky={{ top: appBarHeight + (!isFullScreen ? 64 : 0), withShadow: true }}
        noMargin={isFullScreen}
        paddingTop={!isFullScreen ? 24 : 40}
        ref={ref}
      >
        <Grid container item spacing={4} sx={{ paddingLeft: 4 }}>
          <CustomGrid item percentile={columns[0]}>
            <Typography variant="overline">{i18n.departmentOrUnit}</Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[1]}>
            <Typography variant="overline">{i18n.occupancyRate}</Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[2]}>
            <Typography variant="overline">{`${i18n.admissions} | ${i18n.discharges}`}</Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[3]}>
            <Typography variant="overline">{i18n.availableBedsShort}</Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[4]}>
            <Typography variant="overline">{i18n.availableBedsH24}</Typography>
          </CustomGrid>
          <CustomGrid item percentile={columns[5]}>
            <Typography variant="overline">{i18n.availableBedsH48}</Typography>
          </CustomGrid>
          {canEdit && (
            <CustomGrid item container percentile={columns[6]} justifyContent="flex-end" alignItems="center">
              <Box paddingRight={4}>
                <Typography variant="overline">{i18n.update}</Typography>
              </Box>
            </CustomGrid>
          )}
        </Grid>
      </PageSection>
      <PageSection withBackground noGutter noPaddingY noMargin fullHeight lastSection>
        <Grid container spacing={4} direction="column" paddingTop={3} height="100%">
          <Grid container item>
            {loading ? (
              <Skeleton />
            ) : (
              <Grid container direction="column" spacing={2}>
                {departments.map((department) => (
                  <Grid item container key={department.name}>
                    <DepartmentRow {...department} canEdit={canEdit} columns={columns} isMedium={isMedium} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </PageSection>
    </>
  );
};

const getColumns = (isEditable: boolean, isMedium: boolean) => {
  if (isEditable) {
    return isMedium ? [32, 17, 15, 8, 8, 8, 12] : [25, 12, 10, 14, 14, 14, 11];
  }
  return isMedium ? [32, 20, 18, 10, 10, 10] : [25, 13, 11, 17, 17, 17];
};

export default DepartmentsTable;
