import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Chip, ChipProps } from '@mui/material';
import { i18n } from 'common/locale';
import { specificityColor, specificityIcon } from './SpecificityChipConfig';

type SpecificityChipProps = ChipProps & {
  specificity: AccommodationSpecificity;
};

const SpecificityChip: React.FC<SpecificityChipProps> = ({ specificity, onDelete, disabled, ...rest }) => (
  <Chip
    color={specificityColor[specificity]}
    label={i18n.bedSpecificities.options[specificity]}
    onDelete={onDelete}
    disabled={disabled}
    icon={specificityIcon[specificity]}
    variant="roundedAlt"
    {...rest}
  />
);

export default SpecificityChip;
