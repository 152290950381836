import { Accommodation, Anomaly, HealthcareEnvironmentalCleaning } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { useInterval } from 'common/utils';
import { useAuthentication } from 'core/authentication';
import { Area } from 'core/locations';
import { differenceInMilliseconds, isAfter } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UnitManagementFilter } from '../UnitManagementFilters';
import { mapResources } from './useResources.mapper';
import {
  getAccommodationsQuery,
  getAnomaliesQuery,
  getHealthcareEnvrionmentalCleaningQuery,
} from './useResources.queries';
import { Resource } from './useResources.types';

const useResources = (
  beds: Area[],
  start: Date,
  end: Date,
  view: UnitManagementFilter['view'],
  locationsLoading: boolean
) => {
  const { healthCenter } = useAuthentication();

  const [loading, setLoading] = useState(true);
  const [previousView, setPreviousView] = useState(view);

  const enabled = beds.length > 0 && !locationsLoading;

  const {
    results: accommodations,
    isLoading: accommodationsLoading,
    isLive: accommodationsLive,
  } = useParseQuery(getAccommodationsQuery(beds, start, end), { enabled });

  const {
    results: anomalies,
    isLoading: anomaliesLoading,
    isLive: anomaliesLive,
  } = useParseQuery(getAnomaliesQuery(beds, start, end), {
    enabled,
  });

  const {
    results: healthcareEnvironmentalCleanings,
    isLoading: healthcareEnvironmentalCleaningsLoading,
    isLive: healthcareEnvironmentalCleaningsLive,
  } = useParseQuery(getHealthcareEnvrionmentalCleaningQuery(beds, start, end), {
    enabled,
  });

  const [resources, setResources] = useState<Resource[]>([]);

  useEffect(() => {
    if (previousView !== view && (previousView === 'timeline' || view === 'timeline')) {
      setPreviousView(view);
      setLoading(true);
    }
  }, [view, previousView]);

  const nextRefreshInterval = useMemo(() => getNextRefreshInterval(resources), [resources]);

  const refreshResources = useCallback(() => {
    const date = new Date();
    setResources(
      mapResources(
        beds,
        accommodations.reduce(
          (acc, accommodation) => ({ ...acc, [accommodation.id]: accommodation }),
          {} as Record<string, Accommodation>
        ),
        anomalies.reduce((acc, anomaly) => ({ ...acc, [anomaly.id]: anomaly }), {} as Record<string, Anomaly>),
        healthcareEnvironmentalCleanings.reduce(
          (acc, healthcareEnvironmentalCleaning) => ({
            ...acc,
            [healthcareEnvironmentalCleaning.id]: healthcareEnvironmentalCleaning,
          }),
          {} as Record<string, HealthcareEnvironmentalCleaning>
        ),
        date,
        healthCenter.bookedBedDelayRule
      )
    );
  }, [beds, accommodations, anomalies, healthcareEnvironmentalCleanings, healthCenter.bookedBedDelayRule]);

  useInterval(refreshResources, nextRefreshInterval);
  useEffect(refreshResources, [refreshResources]);

  useEffect(() => {
    const isBedListEmpty = !locationsLoading && beds.length === 0;
    const isAllResourcesLoaded =
      !locationsLoading && !accommodationsLoading && !anomaliesLoading && !healthcareEnvironmentalCleaningsLoading;
    const isAllResourcesLive = accommodationsLive && anomaliesLive && healthcareEnvironmentalCleaningsLive;

    if (isBedListEmpty || (isAllResourcesLoaded && isAllResourcesLive)) {
      setLoading(false);
    }
  }, [
    locationsLoading,
    beds.length,
    accommodationsLoading,
    anomaliesLoading,
    healthcareEnvironmentalCleaningsLoading,
    accommodationsLive,
    anomaliesLive,
    healthcareEnvironmentalCleaningsLive,
    enabled,
  ]);

  return {
    resources,
    loading,
  };
};

const getNextRefreshInterval = (resources: Resource[]) => {
  const date = new Date();
  const fiveMinutesInMilliseconds = 5 * 60 * 1000;
  // Round to the next 5 minutes by default
  // This is to check if a bed is available or booked at least every 5 minutes
  let nextRefreshInterval = fiveMinutesInMilliseconds - (date.getTime() % fiveMinutesInMilliseconds);

  for (const resource of Object.values(resources)) {
    for (const accommodation of resource.accommodations) {
      const start = accommodation.startAt;

      if (isAfter(start, date)) {
        const interval = differenceInMilliseconds(start, date);
        if (interval < nextRefreshInterval) {
          nextRefreshInterval = interval;
        }
      }

      const end = accommodation.endAt;

      if (end && isAfter(end, date)) {
        const interval = differenceInMilliseconds(end, date);
        if (interval < nextRefreshInterval) {
          nextRefreshInterval = interval;
        }
      }
    }
  }

  return nextRefreshInterval;
};

export default useResources;
