import { SvgIcon, SvgIconProps } from '@mui/material';

const Ticket: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98337 3.51074C6.32855 3.51074 6.60837 3.79056 6.60837 4.13574V4.66549C6.60837 5.01067 6.32855 5.29049 5.98337 5.29049C5.63819 5.29049 5.35837 5.01067 5.35837 4.66549V4.13574C5.35837 3.79056 5.63819 3.51074 5.98337 3.51074ZM5.98337 6.84818C6.32855 6.84818 6.60837 7.128 6.60837 7.47318V8.53268C6.60837 8.87786 6.32855 9.15768 5.98337 9.15768C5.63819 9.15768 5.35837 8.87786 5.35837 8.53268V7.47318C5.35837 7.128 5.63819 6.84818 5.98337 6.84818ZM5.98337 10.7154C6.32855 10.7154 6.60837 10.9952 6.60837 11.3404V11.8701C6.60837 12.2153 6.32855 12.4951 5.98337 12.4951C5.63819 12.4951 5.35837 12.2153 5.35837 11.8701V11.3404C5.35837 10.9952 5.63819 10.7154 5.98337 10.7154Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.375 3.5C13.375 3.15482 13.6548 2.875 14 2.875C14.2984 2.875 14.5845 2.99353 14.7955 3.2045C15.0065 3.41548 15.125 3.70163 15.125 4C15.125 4.34518 14.8452 4.625 14.5 4.625C14.1939 4.625 13.9393 4.40501 13.8855 4.11453C13.595 4.06073 13.375 3.80606 13.375 3.5ZM14.4966 4.92501C14.8417 4.9231 15.1231 5.20137 15.125 5.54654C15.1264 5.80746 15.0366 6.06067 14.8711 6.26237C14.7072 6.46219 14.4792 6.59901 14.2259 6.64971C13.9179 6.71606 13.6417 6.88573 13.4434 7.13063C13.2442 7.37658 13.1355 7.68349 13.1355 8C13.1355 8.31651 13.2442 8.62342 13.4434 8.86937C13.6417 9.11427 13.9178 9.28394 14.2259 9.35028C14.4792 9.40098 14.7072 9.53781 14.8711 9.73763C15.0366 9.93933 15.1264 10.1925 15.125 10.4535C15.1231 10.7986 14.8417 11.0769 14.4966 11.075C14.188 11.0733 13.9329 10.8483 13.8836 10.554C13.3287 10.4164 12.8328 10.1016 12.472 9.65612C12.0925 9.18759 11.8855 8.60294 11.8855 8C11.8855 7.39706 12.0925 6.81241 12.472 6.34388C12.8328 5.89839 13.3287 5.58358 13.8836 5.44605C13.9329 5.15175 14.188 4.92672 14.4966 4.92501ZM14.5 11.375C14.8452 11.375 15.125 11.6548 15.125 12C15.125 12.2984 15.0065 12.5845 14.7955 12.7955C14.5845 13.0065 14.2984 13.125 14 13.125C13.6548 13.125 13.375 12.8452 13.375 12.5C13.375 12.1939 13.595 11.9393 13.8855 11.8855C13.9393 11.595 14.1939 11.375 14.5 11.375Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.375 3.5C1.375 3.15482 1.65482 2.875 2 2.875H14C14.3452 2.875 14.625 3.15482 14.625 3.5C14.625 3.84518 14.3452 4.125 14 4.125H2C1.65482 4.125 1.375 3.84518 1.375 3.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.375 12.5C1.375 12.1548 1.65482 11.875 2 11.875H14C14.3452 11.875 14.625 12.1548 14.625 12.5C14.625 12.8452 14.3452 13.125 14 13.125H2C1.65482 13.125 1.375 12.8452 1.375 12.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.11454 4.11453C2.40502 4.06073 2.62501 3.80606 2.62501 3.5C2.62501 3.15482 2.34518 2.875 2 2.875C1.70163 2.875 1.41549 2.99353 1.20451 3.2045C0.993534 3.41548 0.875008 3.70163 0.875008 4C0.875008 4.34518 1.15483 4.625 1.50001 4.625C1.80607 4.625 2.06074 4.40501 2.11454 4.11453ZM2.11641 5.44605C2.06713 5.15175 1.81203 4.92672 1.50346 4.92501C1.15829 4.9231 0.876926 5.20137 0.875017 5.54654C0.873574 5.80746 0.963371 6.06067 1.12887 6.26237C1.29283 6.46219 1.52085 6.59901 1.77415 6.64972C2.08216 6.71606 2.35829 6.88573 2.55665 7.13063C2.75586 7.37658 2.86456 7.68349 2.86456 8C2.86456 8.31651 2.75586 8.62342 2.55665 8.86937C2.35829 9.11427 2.08217 9.28394 1.77416 9.35028C1.52086 9.40098 1.29283 9.53781 1.12887 9.73763C0.963371 9.93933 0.873574 10.1925 0.875017 10.4535C0.876926 10.7986 1.15829 11.0769 1.50346 11.075C1.81203 11.0733 2.06713 10.8483 2.1164 10.554C2.67128 10.4164 3.16717 10.1016 3.528 9.65612C3.90749 9.18759 4.11456 8.60294 4.11456 8C4.11456 7.39706 3.90749 6.81241 3.528 6.34388C3.16717 5.89839 2.67128 5.58358 2.11641 5.44605ZM2.11454 11.8855C2.06074 11.595 1.80607 11.375 1.50001 11.375C1.15483 11.375 0.875008 11.6548 0.875008 12C0.875008 12.2984 0.993534 12.5845 1.20451 12.7955C1.41549 13.0065 1.70163 13.125 2 13.125C2.34518 13.125 2.62501 12.8452 2.62501 12.5C2.62501 12.1939 2.40502 11.9393 2.11454 11.8855Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3.375C1.84518 3.375 2.125 3.65482 2.125 4V5.55C2.125 5.89518 1.84518 6.175 1.5 6.175C1.15482 6.175 0.875 5.89518 0.875 5.55L0.875008 4C0.875008 3.65482 1.15482 3.375 1.5 3.375Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 9.82495C1.84518 9.82495 2.125 10.1048 2.125 10.45V12C2.125 12.3451 1.84518 12.625 1.5 12.625C1.15482 12.625 0.875008 12.3452 0.875008 12L0.875 10.45C0.875 10.1048 1.15482 9.82495 1.5 9.82495Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 9.82495C14.8452 9.82495 15.125 10.1048 15.125 10.45L15.125 12C15.125 12.3452 14.8452 12.625 14.5 12.625C14.1548 12.625 13.875 12.3451 13.875 12V10.45C13.875 10.1048 14.1548 9.82495 14.5 9.82495Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 3.375C14.8452 3.375 15.125 3.65482 15.125 4L15.125 5.55C15.125 5.89518 14.8452 6.175 14.5 6.175C14.1548 6.175 13.875 5.89518 13.875 5.55V4C13.875 3.65482 14.1548 3.375 14.5 3.375Z"
      />
    </SvgIcon>
  );
};

export default Ticket;
