import { TableCell, TableCellProps, TableSortLabel } from '@mui/material';

export type SortOrder = 'asc' | 'desc';

const SortableTableCell = ({
  children,
  order,
  orderBy,
  onSort,
  name,
  ...props
}: TableCellProps & { order: SortOrder; orderBy: string; onSort: () => void; name: string }) => {
  return (
    <TableCell {...props}>
      <TableSortLabel direction={order} active={orderBy === name} onClick={onSort}>
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableTableCell;
