"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeOccupancy = exports.getAvailableBeds = void 0;
const getAvailableBeds = (male, female, indifferent) => {
    return male === undefined && female === undefined && indifferent === undefined
        ? undefined
        : (male || 0) + (female || 0) + (indifferent || 0);
};
exports.getAvailableBeds = getAvailableBeds;
const computeOccupancy = (occupancies) => {
    var _a, _b, _c;
    let open = 0;
    let covid = 0;
    let waitingForDownstreamBed = 0;
    let accomodated = 0;
    let close = 0;
    let dDayAvailabilities = { female: 0, male: 0, indifferent: 0 };
    let h24Availabilities = { female: 0, male: 0, indifferent: 0 };
    let h48Availabilities = { female: 0, male: 0, indifferent: 0 };
    let admission = 0;
    let discharge = 0;
    let updatedAt = new Date();
    let available = 0;
    let unavailableExcluded = 0;
    for (let occupancy of occupancies) {
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.open) !== undefined) {
            open = open + occupancy.open;
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.covid) !== undefined) {
            covid = covid + occupancy.covid;
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.waitingForDownstreamBed) !== undefined) {
            waitingForDownstreamBed = waitingForDownstreamBed + occupancy.waitingForDownstreamBed;
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.unavailableExcluded) !== undefined) {
            unavailableExcluded = unavailableExcluded + occupancy.unavailableExcluded;
        }
        const availableUnitBeds = (0, exports.getAvailableBeds)((_a = occupancy === null || occupancy === void 0 ? void 0 : occupancy.dDayAvailabilities) === null || _a === void 0 ? void 0 : _a.male, (_b = occupancy === null || occupancy === void 0 ? void 0 : occupancy.dDayAvailabilities) === null || _b === void 0 ? void 0 : _b.female, (_c = occupancy === null || occupancy === void 0 ? void 0 : occupancy.dDayAvailabilities) === null || _c === void 0 ? void 0 : _c.indifferent);
        if (availableUnitBeds !== undefined) {
            available = available + availableUnitBeds;
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.accomodated) !== undefined) {
            accomodated = accomodated + occupancy.accomodated;
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.close) !== undefined) {
            close = close + occupancy.close;
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.dDayAvailabilities) !== undefined) {
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.dDayAvailabilities.male) !== undefined) {
                dDayAvailabilities.male = dDayAvailabilities.male + occupancy.dDayAvailabilities.male;
            }
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.dDayAvailabilities.female) !== undefined) {
                dDayAvailabilities.female = dDayAvailabilities.female + occupancy.dDayAvailabilities.female;
            }
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.dDayAvailabilities.indifferent) !== undefined) {
                dDayAvailabilities.indifferent = dDayAvailabilities.indifferent + occupancy.dDayAvailabilities.indifferent;
            }
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h24Availabilities) !== undefined) {
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h24Availabilities.male) !== undefined) {
                h24Availabilities.male = h24Availabilities.male + occupancy.h24Availabilities.male;
            }
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h24Availabilities.female) !== undefined) {
                h24Availabilities.female = h24Availabilities.female + occupancy.h24Availabilities.female;
            }
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h24Availabilities.indifferent) !== undefined) {
                h24Availabilities.indifferent = h24Availabilities.indifferent + occupancy.h24Availabilities.indifferent;
            }
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h48Availabilities) !== undefined) {
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h48Availabilities.male) !== undefined) {
                h48Availabilities.male = h48Availabilities.male + occupancy.h48Availabilities.male;
            }
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h48Availabilities.female) !== undefined) {
                h48Availabilities.female = h48Availabilities.female + occupancy.h48Availabilities.female;
            }
            if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.h48Availabilities.indifferent) !== undefined) {
                h48Availabilities.indifferent = h48Availabilities.indifferent + occupancy.h48Availabilities.indifferent;
            }
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.admission) !== undefined) {
            admission = admission + occupancy.admission;
        }
        if ((occupancy === null || occupancy === void 0 ? void 0 : occupancy.discharge) !== undefined) {
            discharge = discharge + occupancy.discharge;
        }
        if (occupancy === null || occupancy === void 0 ? void 0 : occupancy.updatedAt) {
            updatedAt = new Date(Math.min(updatedAt.getTime(), occupancy.updatedAt.getTime()));
        }
    }
    const occupied = open - available + unavailableExcluded;
    const rate = open ? Math.round((occupied * 100) / open) : 0;
    const total = open + close;
    return {
        covid,
        waitingForDownstreamBed,
        accomodated,
        open,
        available,
        occupied,
        rate,
        updatedAt,
        close,
        admission,
        dDayAvailabilities: dDayAvailabilities,
        h24Availabilities: h24Availabilities,
        h48Availabilities: h48Availabilities,
        discharge,
        unavailableExcluded,
        total,
    };
};
exports.computeOccupancy = computeOccupancy;
