import { useLocations } from 'core/locations';
import { useMemo } from 'react';

export const useDepartments = () => {
  const { units, loading } = useLocations();

  const departments = useMemo(() => {
    const departments = new Set<string>();
    units.forEach((unit) => departments.add(unit.department || 'Autres'));
    return Array.from(departments).sort();
  }, [units]);

  return { isLoading: loading, departments };
};
