import { Components } from '@mui/material';
import palette from '../palette';

const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: {
      '.Mui-error': {
        color: palette.error.main,
      },
      '& .MuiFilledInput-root': {
        '& input:focus::placeholder': {
          color: 'transparent',
        },
        '& textarea:focus::placeholder': {
          color: 'transparent',
        },
      },
      '& input:focus::placeholder': {
        color: 'transparent',
      },
      '& textarea:focus::placeholder': {
        color: 'transparent',
      },
    },
  },
};

export default MuiTextField;
