import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAction } from '../actions/general';

type NotificationState = Readonly<{
  visible?: boolean;
  title?: string;
  body?: string;
  redirection?: string;
}>;

const initialState: NotificationState = {};

const { reducer, actions } = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (_, action: PayloadAction<NotificationState>) => ({
      ...action.payload,
      visible: true,
    }),
    hideNotification: (state: NotificationState) => ({ ...state, visible: false }),
  },
  extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
});

export { reducer as notificationReducer, actions as notificationActions };
