import { PatientSearchResult } from 'common/components/PatientAutocomplete';
import { formatFirstname } from 'common/utils';
import { useSelector } from 'react-redux';
import { EntityNames } from 'sabri/const/schemas';
import { useSelectFetch } from 'sabri/hooks/useSelectFetch';
import { selectArea } from 'sabri/store/selectors/area';
import { selectUnit } from 'sabri/store/selectors/unit';

const usePatientSearchResult = (id: string): PatientSearchResult | null => {
  const [patient] = useSelectFetch(EntityNames.patients, id);
  const unit = useSelector(selectUnit(patient?.unitId));
  const area = useSelector(selectArea(patient?.areaId));

  if (patient) {
    const result: PatientSearchResult = {
      gender: patient.gender,
      id: patient.objectId,
      ipp: patient.ipp,
      pan: patient.pan,
      name: `${patient.lastName.toUpperCase()} ${formatFirstname(patient.firstName)}`,
      birthdate: new Date(patient.birthdate),
    };
    if (unit) {
      result.unit = {
        id: unit.objectId,
        name: unit.name,
      };
    }
    if (area) {
      result.area = {
        id: area.objectId,
        name: area.name,
      };
    }

    return result;
  }
  return null;
};

export default usePatientSearchResult;
