import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';
import { useState } from 'react';

type MakeBedAvailableDialogProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  onBack?: () => void;
  onCancel?: () => void;
  onSuccess: () => void;
};

const MakeBedAvailableDialog = ({ id, open, onClose, onBack, onCancel, onSuccess }: MakeBedAvailableDialogProps) => {
  const { update } = useAccommodation(id);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await update({ endAt: new Date(), status: 'COMPLETED', isEndEstimated: false });
      toast.success({
        ...i18n.accommodationBedClosedCancelledSuccess,
        icon: <Emoji size={24} name="writingHand" />,
      });
      onSuccess();
    } catch (err) {
      toast.error(i18n.accommodationBedClosedCancelledError);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        Icon={<Emoji size={24} name="thinkingFace" />}
        title={i18n.makeBedAvailable.title}
        onBack={onBack}
        onClose={onClose}
      />
      <DialogContent>
        <Typography color="secondary">{i18n.makeBedAvailable.content[0]}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          {i18n.cancel}
        </Button>
        <LoadingButton loading={loading} onClick={handleConfirm}>
          {i18n.makeBedAvailable.confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MakeBedAvailableDialog;
