import { SvgIcon, SvgIconProps } from '@mui/material';

const CrossSmall: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0313 5.03033C12.3242 4.73744 12.3242 4.26256 12.0313 3.96967C11.7384 3.67678 11.2635 3.67678 10.9706 3.96967L8.00098 6.93934L5.03131 3.96967C4.73841 3.67678 4.26354 3.67678 3.97065 3.96967C3.67775 4.26256 3.67775 4.73744 3.97065 5.03033L6.94032 8L3.97065 10.9697C3.67775 11.2626 3.67775 11.7374 3.97065 12.0303C4.26354 12.3232 4.73841 12.3232 5.03131 12.0303L8.00098 9.06066L10.9706 12.0303C11.2635 12.3232 11.7384 12.3232 12.0313 12.0303C12.3242 11.7374 12.3242 11.2626 12.0313 10.9697L9.06164 8L12.0313 5.03033Z"
      />
    </SvgIcon>
  );
};

export default CrossSmall;
