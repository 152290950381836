import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const Female: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M13 5.9C13 3.198 10.7571 1 8 1C5.24286 1 3 3.198 3 5.9C3 8.3633 4.86643 10.4024 7.28571 10.744V12.2H5.85714V13.6H7.28571V15H8.71429V13.6H10.1429V12.2H8.71429V10.744C11.1336 10.4024 13 8.3633 13 5.9ZM4.42857 5.9C4.42857 3.9701 6.03071 2.4 8 2.4C9.96929 2.4 11.5714 3.9701 11.5714 5.9C11.5714 7.8299 9.96929 9.4 8 9.4C6.03071 9.4 4.42857 7.8299 4.42857 5.9Z" />
    </SvgIcon>
  );
};

export default Female;
