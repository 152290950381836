import { differenceInDays, formatDuration, intervalToDuration } from 'date-fns';
import { fr } from 'date-fns/locale';

const getDuration = (start?: Date, end?: Date, format?: string[]) => {
  if (!end || !start || (start && start > end)) {
    return '-';
  }
  const lastMoreThanOneDay = differenceInDays(end, start) >= 1;
  return (
    formatDuration(intervalToDuration({ start, end }), {
      format: format || lastMoreThanOneDay ? ['days', 'hours'] : ['hours', 'minutes'],
      locale: fr,
    }) || '-'
  );
};

export default getDuration;
