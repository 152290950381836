import { AccommodationSpecificity, accommodationSpecificities } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DialogHeader, ToggleGroup } from 'common/components';
import BedPlacement from 'common/components/BedPlacement/BedPlacement';
import { DateTimeInput, FilterSelect, MultipleSelect } from 'common/components/Form';
import BedIcon from 'common/components/Icons/Bed';
import CalendarIcon from 'common/components/Icons/Calendar';
import CalendarDayIcon from 'common/components/Icons/CalendarDay';
import ChevronSmallRightIcon from 'common/components/Icons/ChevronSmallRight';
import CommentIcon from 'common/components/Icons/Comment';
import ConstructionIcon from 'common/components/Icons/Construction';
import DoubleArrowIcon from 'common/components/Icons/DoubleArrow';
import EditIcon from 'common/components/Icons/Edit';
import StandIcon from 'common/components/Icons/Stand';
import UsersOutlineIcon from 'common/components/Icons/UsersOutline';
import { PatientAutocomplete, PatientSearchResult } from 'common/components/PatientAutocomplete';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { useLocations } from 'core/locations';
import { useBedOptions } from 'core/locations/useBedOptions';
import { getHours, getMinutes, isValid } from 'date-fns';
import { AccommodationToUpsert } from 'kurt/hooks';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import DialogFormRow from '../DialogFormRow';
import SpecificityAutocomplete from '../SpecificityAutocomplete';

type AddAccommodationFormProps = {
  onSubmit: (accommodation: AccommodationToUpsert, bedsToClose?: string[]) => void;
  onCancel?: () => void;
  onBack?: () => void;
  initialAccommodation?: Partial<AccommodationToUpsert>;
  initialPatient?: PatientSearchResult;
  loading?: boolean;
  error?: ReactElement;
  availableUnits?: string[];
  initialType?: AccommodationType;
};

const AddAccommodationForm: React.FC<AddAccommodationFormProps> = ({
  initialAccommodation,
  initialPatient,
  onSubmit,
  onCancel,
  onBack,
  loading,
  error,
  availableUnits,
  initialType = 'PATIENT_STAY',
}) => {
  const [type, setType] = useState<AccommodationType>(initialType);
  const [patient, setPatient] = useState<PatientSearchResult | null>(initialPatient || null);
  const [errors, setErrors] = useState(initialErrors);
  const [expanded, setExpanded] = useState(false);

  const [accommodation, setAccommodation] = useState<Partial<AccommodationForm>>({
    ...initialAccommodation,
    isEndEstimated: initialAccommodation?.isEndEstimated === undefined ? true : initialAccommodation?.isEndEstimated,
    visitId: initialPatient?.id,
  });
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  const initialBedsToClose = initialAccommodation?.bedId ? [initialAccommodation?.bedId] : [];

  const [bedsToClose, setBedsToClose] = useState<string[]>(initialBedsToClose);

  const { units: allUnits } = useLocations();
  const units = useMemo(() => {
    if (availableUnits) {
      return allUnits.filter(({ id }) => availableUnits.includes(id));
    } else {
      return allUnits;
    }
  }, [availableUnits, allUnits]);

  const unitOptions = units.map((unit) => ({ value: unit.id, label: unit.name }));

  const { bedOptions, isLoading: areBedsLoading } = useBedOptions(accommodation.unitId || '');

  const handleChange =
    <T extends keyof AccommodationForm>(field: T) =>
    (value?: AccommodationForm[T]) => {
      setAccommodation((prev) => ({ ...prev, [field]: value }));
      setErrors((prev) => ({ ...prev, [field]: false }));
    };

  const handleUnitChange = (unitId: string) => {
    setAccommodation((prev) => ({ ...prev, unitId, bedId: undefined }));
    setBedsToClose([]);
    setErrors((prev) => ({ ...prev, bedId: false, unitId: false }));
  };

  const handleMultipleBedsChange = (bedIds: string[]) => {
    setAccommodation((prev) => ({ ...prev, bedId: bedIds[0] }));
    setBedsToClose(bedIds);
  };

  const handleTypeChange = (type: AccommodationType) => {
    setType(type);
    setErrors((prev) => ({ ...prev, bedId: false, unitId: false, visitId: false, bedsToClose: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validation = formValidation[type];
    const validationErrors = validation && validation(accommodation, bedsToClose);
    if (validationErrors && Object.values(validationErrors).includes(true)) {
      setErrors((prev) => ({ ...prev, ...validationErrors }));
    } else {
      setHasBeenCalled(true);
      if (!hasBeenCalled) {
        const commonParams = {
          isEndEstimated: accommodation.isEndEstimated,
          specificities: accommodation.specificities,
          comment: accommodation.comment,
          startAt: accommodation.startAt,
          endAt: accommodation.endAt,
          unitId: accommodation.unitId,
        };
        if (type === 'BED_CLOSURE') {
          onSubmit(
            {
              ...commonParams,
            } as AccommodationToUpsert,
            bedsToClose
          );
        } else {
          onSubmit({
            ...commonParams,
            visitId: accommodation.visitId,
            bedId: accommodation.bedId,
          } as AccommodationToUpsert);
        }
      }
    }
  };

  useEffect(() => {
    if (!!error && !loading) {
      setHasBeenCalled(false);
    }
  }, [error, loading]);

  return (
    <>
      <DialogHeader
        title={type === 'BED_CLOSURE' ? i18n.addAccommodationModal.closeBedsTitle : i18n.addAccommodationModal.title}
        onClose={onCancel}
        onBack={onBack}
      />
      <DialogContent>
        <form onSubmit={handleSubmit} id="add-accommodation-form">
          <Stack divider={<Divider />}>
            {!initialPatient && (
              <DialogFormRow label={i18n.addAccommodationSteps(type).accommodationType.label} Icon={DoubleArrowIcon}>
                <ToggleGroup
                  variant="filled"
                  size="small"
                  value={type}
                  onChange={(_, value) => handleTypeChange(value)}
                  fullWidth
                >
                  <ToggleButton value="PATIENT_STAY" sx={{ flex: 1 }}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <UsersOutlineIcon sx={{ color: color.blue[40] }} />
                      {i18n.addAccommodationModal.selectToggle.patientStay.label}
                    </Stack>
                  </ToggleButton>
                  <ToggleButton value="BED_CLOSURE" sx={{ flex: 1 }}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <ConstructionIcon color="secondary" />
                      {i18n.addAccommodationModal.selectToggle.closingBed.label}
                    </Stack>
                  </ToggleButton>
                </ToggleGroup>
              </DialogFormRow>
            )}
            {!initialPatient && type === 'PATIENT_STAY' ? (
              <DialogFormRow label={i18n.addAccommodationSteps(type).patientChoice.label} Icon={StandIcon}>
                <PatientAutocomplete
                  onChange={(patient) => {
                    setPatient(patient);
                    handleChange('visitId')(patient?.id);
                  }}
                  value={patient}
                  error={errors.visitId ? { message: i18n.addAccommodationModal.errors.patient } : undefined}
                  variant="filled"
                  size="small"
                />
              </DialogFormRow>
            ) : (
              <DialogFormRow label={i18n.addAccommodationSteps(type).bedChoice.label} Icon={BedIcon}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={2}>
                      <Stack spacing={1}>
                        <Box alignSelf="flex-start">
                          <FilterSelect
                            searchable
                            label={i18n.unit}
                            value={accommodation.unitId}
                            onChange={handleUnitChange}
                            variant="filled"
                            size="small"
                            error={errors.unitId}
                          >
                            {unitOptions.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </FilterSelect>
                        </Box>

                        {errors.unitId && !errors.bedsToClose && (
                          <Typography variant="body2" color="error">
                            {i18n.addAccommodationModal.errors.unit}
                          </Typography>
                        )}
                      </Stack>
                      <Stack spacing={1}>
                        <Box alignSelf="flex-start">
                          <MultipleSelect
                            searchable
                            label={i18n.bedNumbers}
                            values={bedsToClose}
                            onChange={handleMultipleBedsChange}
                            options={bedOptions}
                            variant="filled"
                            size="small"
                            error={errors.bedsToClose}
                            loading={areBedsLoading}
                          />
                        </Box>

                        {errors.bedsToClose && !errors.unitId && (
                          <Typography variant="body2" color="error">
                            {i18n.addAccommodationModal.errors.bed}
                          </Typography>
                        )}
                      </Stack>
                      {errors.unitId && errors.bedsToClose && (
                        <Typography variant="body2" color="error">
                          {i18n.addAccommodationModal.errors.unitAndBed}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  {bedsToClose.length > 0 && (
                    <Stack direction="row" gap={1}>
                      {bedsToClose.map((bedId) => (
                        <Chip
                          disabled={loading}
                          color="secondary"
                          variant="rounded"
                          key={bedId}
                          label={bedOptions.find(({ value }) => value === bedId)?.label}
                          onDelete={() => setBedsToClose(bedsToClose.filter((id) => id !== bedId))}
                        />
                      ))}
                    </Stack>
                  )}
                </Stack>
              </DialogFormRow>
            )}

            <DialogFormRow label={i18n.addAccommodationSteps(type).specificities.label} Icon={EditIcon}>
              <SpecificityAutocomplete
                onChange={(value) => handleChange('specificities')(value)}
                value={accommodation.specificities || []}
                options={[...accommodationSpecificities].filter((spec) => {
                  if (spec === 'ISOLATED') {
                    // ISOLATED is now deprecated and no more available
                    return false;
                  }
                  if (type === 'BED_CLOSURE') {
                    return !['WAITING_FOR_DOWNSTREAM_BED', 'ACCOMMODATED', 'PRIVATE_ROOM'].includes(spec);
                  }
                  return spec !== 'WAITING_FOR_DOWNSTREAM_BED';
                })}
              />
            </DialogFormRow>
            <DialogFormRow label={i18n.addAccommodationSteps(type).dates.startAt} Icon={CalendarIcon}>
              <Stack spacing={1}>
                <DateTimeInput
                  variant="filled"
                  size="small"
                  value={accommodation.startAt}
                  onChange={handleChange('startAt')}
                  maxDate={accommodation.endAt || undefined}
                  defaultTime={
                    !!accommodation.endAt
                      ? {
                          hour: getHours(accommodation.endAt),
                          minute: getMinutes(accommodation.endAt),
                        }
                      : undefined
                  }
                  error={errors.startAt}
                />
                {errors.startAt && (
                  <Typography fontSize={12} color="error">
                    {type === 'BED_CLOSURE'
                      ? i18n.addAccommodationModal.errors.startClosed
                      : i18n.addAccommodationModal.errors.start}
                  </Typography>
                )}
              </Stack>
            </DialogFormRow>
            <DialogFormRow label={i18n.addAccommodationSteps(type).dates.endAt} Icon={CalendarIcon}>
              <Stack gap={2} direction="row" justifyContent="space-between">
                <DateTimeInput
                  variant="filled"
                  size="small"
                  value={accommodation.endAt}
                  onChange={handleChange('endAt')}
                  minDate={accommodation.startAt || undefined}
                  defaultTime={
                    !!accommodation.startAt
                      ? {
                          hour: getHours(accommodation.startAt),
                          minute: getMinutes(accommodation.startAt),
                        }
                      : undefined
                  }
                  error={errors.endAt}
                />
                {type === 'PATIENT_STAY' && (
                  <Select
                    variant="filled"
                    size="small"
                    value={accommodation.isEndEstimated}
                    onChange={(event) => handleChange('isEndEstimated')(event.target.value === 'true')}
                  >
                    <MenuItem value="true">
                      <Chip
                        sx={{ cursor: 'pointer' }}
                        icon={<CalendarIcon />}
                        label={i18n.previsionalDischargeShort}
                        variant="light"
                        color="warning"
                      />
                    </MenuItem>
                    <MenuItem value="false">
                      <Chip
                        sx={{ cursor: 'pointer' }}
                        icon={<CalendarDayIcon />}
                        label={i18n.validatedDischargeShort}
                        variant="light"
                        color="success"
                      />
                    </MenuItem>
                  </Select>
                )}
              </Stack>
              {errors.endAt && (
                <Typography fontSize={12} color="error">
                  {type === 'BED_CLOSURE'
                    ? i18n.addAccommodationModal.errors.endClosed
                    : i18n.addAccommodationModal.errors.end}
                </Typography>
              )}
            </DialogFormRow>
            <DialogFormRow label={i18n.addAccommodationSteps(type).comment.label} Icon={CommentIcon}>
              <TextField
                multiline
                fullWidth
                minRows={1}
                maxRows={3}
                variant="filled"
                size="small"
                placeholder={i18n.empty}
                value={accommodation.comment}
                onChange={(event) => {
                  const hasLineBreak = event.target.value.match(/$/gm);

                  if (hasLineBreak && hasLineBreak.length > 3) {
                    return;
                  }

                  handleChange('comment')(event.target.value);
                }}
              />
            </DialogFormRow>
            {type === 'PATIENT_STAY' && (
              <DialogFormRow label={i18n.addAccommodationSteps(type).bedChoice.label} Icon={BedIcon}>
                {accommodation.startAt && accommodation.unitId && patient && !initialAccommodation?.bedId ? (
                  <>
                    <BedPlacement
                      unitId={accommodation.unitId}
                      start={accommodation.startAt}
                      end={accommodation.endAt || undefined}
                      gender={patient?.gender}
                      birthdate={patient?.birthdate}
                      name={patient?.name}
                      value={accommodation.bedId}
                      onSelect={handleChange('bedId')}
                      specificities={accommodation.specificities}
                      size="small"
                    />
                    <Stack
                      direction="row"
                      spacing={1}
                      onClick={() => setExpanded(!expanded)}
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                      marginY={2}
                    >
                      <ChevronSmallRightIcon
                        style={{ transform: expanded ? 'rotate(90deg)' : undefined }}
                        color="secondary"
                      />

                      <Stack direction="row" spacing={2} alignItems="baseline">
                        <Typography variant="body2" color="secondary">
                          {i18n.chooseMyBed}
                        </Typography>
                        {errors.bedId && !expanded && (
                          <Typography variant="body2" color="error">
                            {i18n.addAccommodationModal.errors.bed}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Collapse in={expanded} style={{ marginTop: 16, marginBottom: 0 }}>
                      <Stack spacing={1}>
                        <Stack direction="row" spacing={2}>
                          <Stack spacing={1}>
                            <Box alignSelf="flex-start">
                              <FilterSelect
                                searchable
                                label={i18n.unit}
                                value={accommodation.unitId}
                                onChange={handleUnitChange}
                                variant="filled"
                                size="small"
                              >
                                {unitOptions.map(({ value, label }) => (
                                  <MenuItem key={value} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </FilterSelect>
                            </Box>

                            {errors.unitId && !errors.bedId && (
                              <Typography variant="body2" color="error">
                                {i18n.addAccommodationModal.errors.unit}
                              </Typography>
                            )}
                          </Stack>
                          <Stack spacing={1}>
                            <Box alignSelf="flex-start">
                              <FilterSelect
                                searchable
                                label={i18n.bedNumber}
                                value={accommodation.bedId}
                                onChange={handleChange('bedId')}
                                error={errors.bedId}
                                variant="filled"
                                size="small"
                                loading={areBedsLoading}
                              >
                                {bedOptions.map(({ value, label }) => (
                                  <MenuItem key={value} value={value}>
                                    {label}
                                  </MenuItem>
                                ))}
                              </FilterSelect>
                            </Box>

                            {errors.bedId && !errors.unitId && (
                              <Typography variant="body2" color="error">
                                {i18n.addAccommodationModal.errors.bed}
                              </Typography>
                            )}
                          </Stack>
                          {errors.unitId && errors.bedId && (
                            <Typography variant="body2" color="error">
                              {i18n.addAccommodationModal.errors.unitAndBed}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Collapse>
                  </>
                ) : (
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={2}>
                      <Box alignSelf="flex-start">
                        <FilterSelect
                          searchable
                          label={i18n.unit}
                          value={accommodation.unitId}
                          onChange={handleUnitChange}
                          variant="filled"
                          size="small"
                          error={errors.unitId}
                        >
                          {unitOptions.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </FilterSelect>
                      </Box>
                      <Stack spacing={1}>
                        <Box alignSelf="flex-start">
                          <FilterSelect
                            searchable
                            label={i18n.bedNumber}
                            value={accommodation.bedId}
                            onChange={handleChange('bedId')}
                            error={errors.bedId}
                            variant="filled"
                            size="small"
                            loading={areBedsLoading}
                          >
                            {bedOptions.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </FilterSelect>
                        </Box>
                        {errors.bedId && !errors.unitId && (
                          <Typography variant="body2" color="error">
                            {i18n.addAccommodationModal.errors.bed}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    {errors.unitId && (
                      <Typography variant="body2" color="error">
                        {i18n.addAccommodationModal.errors[errors.bedId ? 'unitAndBed' : 'unit']}
                      </Typography>
                    )}
                  </Stack>
                )}
              </DialogFormRow>
            )}
          </Stack>
        </form>
        {error && <Stack paddingTop={6}>{error}</Stack>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {i18n.cancel}
        </Button>
        <LoadingButton disabled={loading} loading={loading} type="submit" form="add-accommodation-form">
          {type === 'PATIENT_STAY'
            ? i18n.addAccommodationModal.programmVisit
            : i18n.addAccommodationModal.confirmClosure}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AddAccommodationForm;

export type AccommodationForm = {
  unitId?: string;
  bedId?: string;
  startAt: Date | null;
  endAt: Date | null;
  visitId?: string;
  specificities: AccommodationSpecificity[];
  isEndEstimated: boolean;
  comment?: string | null;
};

const initialErrors = {
  visitId: false,
  unitId: false,
  bedId: false,
  startAt: false,
  endAt: false,
  bedsToClose: false,
};

export type AccommodationType = 'PATIENT_STAY' | 'BED_CLOSURE';

const formValidation: Record<
  AccommodationType,
  | ((
      accommodation: Partial<AccommodationForm>,
      bedsToClose?: string[]
    ) => { [key in keyof AccommodationForm]?: boolean })
  | undefined
> = {
  PATIENT_STAY: (accommodation) => ({
    visitId: !accommodation.visitId,
    startAt: !accommodation.startAt || !isValid(accommodation.startAt),
    endAt: !!accommodation.endAt && !!accommodation.startAt && accommodation.startAt > accommodation.endAt,
    bedId: !accommodation.bedId,
    unitId: !accommodation.unitId,
  }),
  BED_CLOSURE: (accommodation, bedsToClose) => ({
    bedsToClose: !bedsToClose || bedsToClose.length === 0,
    startAt: !accommodation.startAt || !isValid(accommodation.startAt),
    endAt: !!accommodation.endAt && !!accommodation.startAt && accommodation.startAt > accommodation.endAt,
  }),
};
