import { Components } from '@mui/material';
import color from '../color';
import palette from '../palette';
import shadows from '../shadows';

declare module '@mui/material/ToggleButtonGroup' {
  interface ToggleButtonGroupProps {
    variant?: 'filled' | 'outlined' | 'standard';
  }
}

const MuiToggleButtonGroup: Components['MuiToggleButtonGroup'] = {
  styleOverrides: {
    root: {
      border: `1px solid ${color.grey[30]}`,
    },
    groupedVertical: {
      ':not(:last-of-type)': {
        ':not(.Mui-selected)::after': {
          content: '""',
          width: 'calc(100% - 16px)',
          backgroundColor: color.grey[20],
          height: 1,
          position: 'absolute',
          left: 8,
          bottom: -1,
        },
      },
      '&.Mui-selected': {
        margin: '-1px  -1px 0 -1px',
        padding: '12px 12px 11px 12px',
      },
      ':last-of-type': {
        '&.Mui-selected': {
          margin: '-1px',
          padding: '12px',
        },
      },
    },
    groupedHorizontal: {
      ':not(:last-of-type)': {
        ':not(.Mui-selected)::after': {
          content: '""',
          width: 1,
          backgroundColor: color.grey[20],
          height: 'calc(100% - 16px)',
          position: 'absolute',
          top: 8,
          right: -1,
          zIndex: -1,
        },
      },
    },
    grouped: {
      ':not(:first-of-type)': {
        marginLeft: 1,
        marginTop: '0',
      },
    },
  },
  variants: [
    {
      props: {
        size: 'small',
      },
      style: {
        '& .MuiToggleButton-root': {
          padding: 5,
          borderRadius: '4px !important',
          fontSize: 12,
          '& svg': {
            fontSize: 12,
          },
        },
      },
    },
    {
      props: { variant: 'filled' },
      style: {
        backgroundColor: color.blue[5],
        border: `1px solid ${color.grey[10]}`,
        borderRadius: 6,
        color: color.grey[30],
        padding: 1,
        '& .MuiToggleButton-root': {
          color: color.grey[30],
          boxShadow: shadows[1],
          '&:not(.Mui-selected)': {
            '& svg': {
              color: color.grey[30],
            },
          },
          '&.Mui-selected': {
            backgroundColor: 'white',
            fontWeight: 'normal',
            color: palette.text.primary,
            '&:hover': {
              backgroundColor: 'white',
            },
          },
          ':not(:last-of-type)': {
            ':not(.Mui-selected)::after': {
              width: 0,
            },
          },
          ':not(:first-of-type)': {
            marginLeft: 2,
          },
        },
      },
    },
  ],
};

export default MuiToggleButtonGroup;
