import { Fare } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import CalendarDayIcon from 'common/components/Icons/CalendarDay';
import ClockIcon from 'common/components/Icons/Clock';
import ComfortBedIcon from 'common/components/Icons/ComfortBed';
import { i18n } from 'common/locale';
import Ticket from '../../Ticket';

type FareDetailsProps = {
  fare: Fare;
};

const FareDetails = ({ fare }: FareDetailsProps) => {
  return (
    <Stack spacing={8}>
      <Stack>
        <Typography variant="h4">{i18n.detailsDialog.informations}</Typography>
      </Stack>
      <Ticket
        Icon={ComfortBedIcon}
        departure={
          <Stack spacing={1}>
            <Stack direction="row" spacing={6} alignItems="center" padding={2}>
              <Typography variant="body2" color="secondary" flexShrink={0} width={76}>
                {i18n.detailsDialog.fare.content.ticket.start}
              </Typography>
              {!!fare.fromArea?.name && <Typography fontWeight={600}>{fare.fromArea?.name}</Typography>}
              <Typography fontWeight={600}>{fare.fromUnit?.name}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={6} alignItems="center" padding={2}>
              <Stack direction="row" spacing={6}></Stack>
            </Stack>
          </Stack>
        }
        arrival={
          <Stack spacing={1}>
            <Stack direction="row" spacing={6} alignItems="center" padding={2}>
              <Typography variant="body2" color="secondary" flexShrink={0} width={76}>
                {i18n.detailsDialog.fare.content.ticket.end}
              </Typography>
              {!!fare.toArea?.name && <Typography fontWeight={600}>{fare.toArea.name}</Typography>}
              <Typography fontWeight={600}>{fare.toUnit?.name}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={6} alignItems="center" padding={2}>
              <Stack direction="row" spacing={6}>
                <Typography color="secondary.medium">{i18n.toDefine}</Typography>
                <Typography color="secondary.medium">{i18n.detailsDialog.common.noEndTime}</Typography>
              </Stack>
            </Stack>
          </Stack>
        }
        additionalInfo={
          <Stack spacing={8} justifyContent="center" height={1}>
            <Stack spacing={4}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <CalendarDayIcon color="secondary" />
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={4}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <ClockIcon color="secondary" />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        }
      />
      {/* <Stack spacing={6}>
        <DetailsRow Icon={TicketIcon} label={i18n.detailsDialog.common.hospitalizationReason} value='test' />
      </Stack> */}
    </Stack>
  );
};

export default FareDetails;
