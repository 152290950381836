import { Box } from '@mui/material';
import { Bed } from 'common/components/Images';
import { i18n } from 'common/locale';
import { formatName } from 'common/utils';
import { EmptyContent, PageSection } from 'core/layout';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Resource } from '../../UnitManagement';
import { AvailableBedCard, BusyBedCard, ClosedBedCard } from './Cards';
import HealthcareEnvironmentalCleaningCard from './Cards/HealthcareEnvironmentalCleaningCard';
import SkeletonCard from './Cards/SkeletonCard';

type GridProps = {
  resources: Resource[];
  loading?: boolean;
  isReadOnly?: boolean;
};

const Grid = ({ resources, loading, isReadOnly = false }: GridProps) => {
  const navigation = useRef(useNavigate());

  const handleAccommodationCardClick = useCallback((id: string) => {
    navigation.current(`accommodation/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  const handleCleaningCardClick = useCallback((id: string) => {
    navigation.current(`cleaning/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  const handleAvailableBedCardClick = useCallback((id: string) => {
    navigation.current(`available-bed/${id}`, {
      state: { from: window.location.pathname + window.location.search },
    });
  }, []);

  if (loading) {
    return (
      <PageSection withBackground noGutter noMargin noPadding fullHeight>
        <Box display="grid" gap={8} gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))">
          {resources.map(({ bed }) => {
            return <SkeletonCard key={bed.id} />;
          })}
        </Box>
      </PageSection>
    );
  }

  if (resources.length === 0) {
    return (
      <PageSection withBackground noGutter noMargin noPadding fullHeight>
        <EmptyContent Image={Bed} {...i18n.accommodationEmptyContent} />
      </PageSection>
    );
  }

  return (
    <Box display="grid" gap={8} gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))" paddingBottom={5}>
      {resources.map(({ bed, accommodation, anomalies, healthcareEnvironmentalCleaning }) => {
        if (accommodation?.patient) {
          return (
            <BusyBedCard
              key={bed.id}
              bedId={bed.id}
              bedName={bed.name}
              bedGender={bed.gender}
              accommodationId={accommodation.id}
              accommodationStatus={accommodation.status}
              accommodationSpecificities={accommodation.specificities}
              accommodationPractitioners={accommodation.practitioners}
              visitId={accommodation.visit?.id || ''}
              comment={accommodation.comment}
              start={accommodation.startAt}
              end={accommodation.endAt}
              patientName={formatName(
                accommodation?.visit?.firstName,
                accommodation?.visit?.lastName,
                accommodation?.visit?.legalName,
                accommodation?.visit?.legalFirstName
              )}
              patientGender={accommodation?.visit?.gender || 'UNKNOWN'}
              patientBirthdate={accommodation?.visit?.birthdate || new Date()}
              unitId={accommodation.unit.id}
              isEstimatedEnd={accommodation.isEstimatedEnd}
              anomalies={anomalies}
              isReadOnly={isReadOnly}
              onClick={handleAccommodationCardClick}
            />
          );
        } else if (accommodation) {
          return (
            <ClosedBedCard
              key={bed.id}
              accommodationId={accommodation.id}
              accommodationStatus={accommodation.status}
              bedGender={bed.gender}
              bedName={bed.name}
              anomalies={anomalies}
              accommodationSpecificities={accommodation.specificities}
              comment={accommodation.comment}
              end={accommodation.endAt}
              isReadOnly={isReadOnly}
            />
          );
        } else {
          if (healthcareEnvironmentalCleaning) {
            return (
              <HealthcareEnvironmentalCleaningCard
                id={healthcareEnvironmentalCleaning.id}
                key={bed.id}
                bedId={bed.id}
                bedName={bed.name}
                bedGender={bed.gender}
                status={healthcareEnvironmentalCleaning.status}
                start={healthcareEnvironmentalCleaning.startAt}
                end={healthcareEnvironmentalCleaning.endAt}
                onClick={handleCleaningCardClick}
              />
            );
          }
          return (
            <AvailableBedCard
              key={bed.id}
              gender={bed.gender}
              name={bed.name}
              id={bed.id}
              unitId={bed.unitId}
              status={bed.status}
              nextSlotAt={bed.nextSlotAt}
              isReadOnly={isReadOnly}
              onClick={handleAvailableBedCardClick}
            />
          );
        }
      })}
    </Box>
  );
};

export default Grid;
