import { createSelector } from '@reduxjs/toolkit';
import { FilterItem } from '../../components/FiltersController/FiltersController';
import { EntityNames } from '../../const/schemas';
import { areaTree } from '../../services/areaTree';
import { AreaStored } from '../reducers/entity';
import { AppState } from '../store';
import { selectEntitiesValues } from './entity';
import { getUnits } from './unit';

export const getAreas = (state: AppState) => state.entity.areas;

export const selectAreasFilters = createSelector([selectEntitiesValues(EntityNames.areas)], (areas) => {
  return areas.reduce((acc: FilterItem[], curr: AreaStored) => {
    if (curr.parentId) {
      const service = acc.find((x: any) => x.id === curr.parentId);
      if (service !== undefined) {
        service.ids = [...(service.ids ? service.ids : []), curr.objectId];
        return acc;
      } else {
        return [
          ...acc,
          {
            id: curr.parentId,
            label: undefined,
            ids: [curr.objectId],
          },
        ] as FilterItem[];
      }
    } else {
      const service = acc.find((x: any) => x.id === curr.objectId);
      if (service) {
        service.ids = [...(service.ids ? service.ids : []), curr.objectId];
        service.label = curr.name;
        return acc;
      } else {
        return [
          ...acc,
          {
            id: curr.objectId,
            label: curr.name,
            ids: [curr.objectId],
          },
        ];
      }
    }
  }, []);
});

export const selectAreaChildren = (areaId: string | undefined) =>
  createSelector([getAreas], (areas) => {
    if (areaId && areas[areaId]) {
      return areaTree
        .getAllChildren([areaId])
        .map((area) => areas[area.id])
        .filter((area) => area.parentId === areaId)
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
    }
    return [];
  });

export const selectAreaBreadcrumb = (areaId: string | undefined) =>
  createSelector([getAreas], (areas) => areaTree.getBreadcrumb({ areaId }).map((area) => areas[area.id]));

export const selectAreasFromUnit = (unitId: string | undefined) =>
  createSelector([getAreas, getUnits], (areas, units) => {
    if (unitId && units[unitId]) {
      return areaTree
        .getAllChildren(units[unitId].areasIds)
        .map((area) => areas[area.id])
        .filter((area) => area?.isSearchable)
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
    }
    return [];
  });

export const selectArea = (id?: string) => createSelector([getAreas], (area) => (id ? area[id] : null));
export const selectAreaBreadCrumb = (id?: string) =>
  createSelector([selectArea(id)], (area) =>
    area ? areaTree.getBreadcrumb({ areaId: area.objectId }).map(({ name }) => name) : []
  );

export const selectAreas = createSelector(selectEntitiesValues(EntityNames.areas), (areas) =>
  areas.filter((area) => area.name && area?.isSearchable).sort((a, b) => -b.name.localeCompare(a.name))
);
