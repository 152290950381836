import { Stack } from '@mui/material';
import { ReactNode } from 'react';

const Filters = ({ children }: { children: ReactNode }) => (
  <Stack direction="row" alignItems="center" gap={4} flexWrap="wrap">
    {children}
  </Stack>
);

export default Filters;
