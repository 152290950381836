import { Components } from '@mui/material';
import palette from '../palette';

type Color = 'error' | 'info' | 'primary' | 'success' | 'warning' | 'secondary';

const getVariant = (color: Color) => ({
  props: { color },
  style: {
    color: palette[color].main,
    fontWeight: 600,
    '& .MuiSvgIcon-root': {
      color: palette[color].main,
    },
    '& .MuiTypography-root': {
      color: palette[color].main,
      fontWeight: 600,
    },
  },
});

const getVariants = () => {
  return ['error', 'info', 'primary', 'success', 'warning', 'secondary'].map((color) => getVariant(color as Color));
};

const MuiInput: Components['MuiInput'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: {
      ':before, :after': {
        borderBottom: '0 !important',
      },
      '& .MuiSvgIcon-root': {
        color: palette.primary.main,
      },
      '& .MuiSelect-select': {
        minHeight: 0,
        lineHeight: '18px',
      },
    },
  },
  variants: getVariants(),
};

export default MuiInput;
