import { SvgIcon, SvgIconProps } from '@mui/material';

const TimelineView: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V14C14.5 14.2761 14.2761 14.5 14 14.5H2C1.72386 14.5 1.5 14.2761 1.5 14V2C1.5 1.72386 1.72386 1.5 2 1.5ZM0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2ZM4 4C3.58579 4 3.25 4.33579 3.25 4.75C3.25 5.16421 3.58579 5.5 4 5.5H8.5C8.91421 5.5 9.25 5.16421 9.25 4.75C9.25 4.33579 8.91421 4 8.5 4H4ZM5 8C5 7.58579 5.33579 7.25 5.75 7.25H10.25C10.6642 7.25 11 7.58579 11 8C11 8.41421 10.6642 8.75 10.25 8.75H5.75C5.33579 8.75 5 8.41421 5 8ZM7.5 10.5C7.08579 10.5 6.75 10.8358 6.75 11.25C6.75 11.6642 7.08579 12 7.5 12H12C12.4142 12 12.75 11.6642 12.75 11.25C12.75 10.8358 12.4142 10.5 12 10.5H7.5Z"
      />
    </SvgIcon>
  );
};

export default TimelineView;
