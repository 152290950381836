import to from 'await-to-js';
import { liveQueryClient } from 'core/live-query-client';
import { Action, Dispatch, MiddlewareAPI } from 'redux';
import { RequestName } from '../../const/requests';
import { EntityNames, entityNameToSchema } from '../../const/schemas';
import { cleanNormalize } from '../../utils/cleanNormalize';
import { getEntitiesAction } from '../actions/entity';
import { subscribeLiveQueriesType, unsubscribeLiveQueriesType } from '../actions/liveQuery';
import { entityActions } from '../reducers/entity';
import { AppState } from '../store';

export const createDefaultLiveQueryMiddleware = (
  liveQuery: () => Promise<Parse.LiveQuerySubscription>,
  entityName: EntityNames,
  requestName: RequestName
) => {
  const schema = entityNameToSchema[entityName];
  let liveSubscription: Parse.LiveQuerySubscription | undefined;
  let err: Error | null;
  const onCreate = (store: MiddlewareAPI<Dispatch, AppState>) => (object: any) => {
    const { entities } = cleanNormalize(object.toJSON(), schema);
    store.dispatch(entityActions.setEntities(entities));
  };

  const onUpdate = (store: MiddlewareAPI<Dispatch, AppState>) => (object: any) => {
    const { entities } = cleanNormalize(object.toJSON(), schema);
    store.dispatch(entityActions.updateEntity({ entityName, newObject: entities[entityName] }));
  };

  const onDelete = (store: MiddlewareAPI<Dispatch, AppState>) => (object: any) => {
    store.dispatch(entityActions.removeEntity({ entityName, objectId: object.id }));
  };

  const onOpen = (store: MiddlewareAPI<Dispatch, AppState>) => (object: any) => {
    store.dispatch(getEntitiesAction({ entityName, requestName }));
  };

  return (store: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => async (action: Action) => {
    const result = next(action);

    if (action.type === subscribeLiveQueriesType) {
      [err, liveSubscription] = await to(liveQuery());
      if (err) throw err;
      if (!liveSubscription) throw Error('liveSubscription is undefined');
      liveSubscription.on('open', onOpen(store));
      liveSubscription.on('create', onCreate(store));
      liveSubscription.on('enter', onCreate(store));
      liveSubscription.on('update', onUpdate(store));
      liveSubscription.on('delete', onDelete(store));
      liveSubscription.on('leave', onDelete(store));
    }

    if (action.type === unsubscribeLiveQueriesType && liveSubscription) {
      liveQueryClient.unsubscribe(liveSubscription);
    }

    return result;
  };
};
