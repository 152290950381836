import { Area } from '@ambuliz/sabri-core';
import useParseResource from 'common/hooks/useParseResource';

type useAreaProps = {
  locationId?: string;
  enabled?: boolean;
};

const useArea = ({ locationId, enabled }: useAreaProps) => {
  const { resource, isLoading } = useParseResource(locationId, Area, { enabled });
  return { isEnabled: enabled, isLoading, area: resource };
};

export default useArea;
