import Api, { Area, Unit } from '@ambuliz/sabri-core';
import { liveQueryClient } from 'core/live-query-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import isAreaCurrentlyActive from './isAreaCurrentlyActive';

const getAreaQuery = (bedIds: string[]) => new Api.Query(Area).containedIn('objectId', bedIds);

const mapBed = (area: Area, unit: Unit) => ({
  id: area.id,
  name: area.name,
  unitId: unit.id,
  unitName: unit.name,
  type: area.externalTypeCode,
  parentId: area.parent?.id,
  nextSlotAt: area.nextSlotAt,
  availabilityPeriods: area.availabilityPeriods,
  excludedFromOccupancy: area.excludedFromOccupancy,
});

const useActiveBeds = (allBeds: { unit: Unit; bed: Area }[]) => {
  const [beds, setBeds] = useState<{ unit: Unit; bed: Area }[]>(allBeds);
  const [loading, setLoading] = useState(true);

  const handleUpdateBed = useCallback((area: Area) => {
    setBeds((prevBeds) => prevBeds.map((bed) => (bed.bed.id === area.id ? { ...bed, bed: area } : bed)));
  }, []);

  const handleDeleteBed = useCallback((area: Area) => {
    setBeds((prevBeds) => prevBeds.filter(({ bed }) => bed.id !== area.id));
  }, []);

  useEffect(() => {
    setBeds(allBeds);

    if (allBeds.length === 0) {
      return;
    }

    const bedIds = allBeds.map(({ bed }) => bed.id);

    const subscription = liveQueryClient.subscribe(getAreaQuery(bedIds), Parse.User.current()?.getSessionToken());
    subscription.on('open', () => setLoading(false));
    subscription.on('update', (parseObject) => handleUpdateBed(parseObject as Area));
    subscription.on('delete', (parseObject) => handleDeleteBed(parseObject as Area));

    return () => liveQueryClient.unsubscribe(subscription);
  }, [allBeds, handleDeleteBed, handleUpdateBed]);

  const activeBeds = useMemo(
    () =>
      beds
        .filter(({ bed }) => isAreaCurrentlyActive(bed.availabilityPeriods))
        .map(({ unit, bed }) => mapBed(bed, unit)),
    [beds]
  );

  return { beds: activeBeds, loading };
};

export default useActiveBeds;
