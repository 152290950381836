import Api, { Area } from '@ambuliz/sabri-core';
import { CircularProgress } from '@mui/material';
import useParseQuery from 'common/hooks/useParseQuery';
import { useMemo } from 'react';
import { AreaSelectItem } from './AreaSelectItem';

type AreaSelectItemChildrenProps = {
  id: string;
  value: string[];
  disabled: boolean;
  onChange: (value: string[]) => void;
};

export const AreaSelectItemChildren = ({ id, value, disabled, onChange }: AreaSelectItemChildrenProps) => {
  const { results, isLoading } = useParseQuery(new Api.Query(Area).equalTo('parent', Area.createWithoutData(id)), {
    enableLiveQuery: false,
  });

  const children = useMemo(() => results.sort((a, b) => a.name.localeCompare(b.name)), [results]);

  if (isLoading) {
    return <CircularProgress size={25} />;
  }

  return (
    <>
      {children.map((area) => {
        return (
          <AreaSelectItem
            key={area.id}
            name={area.name}
            type={area.externalTypeCode}
            id={area.id}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        );
      })}
    </>
  );
};
