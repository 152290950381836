import { Cloud } from '@ambuliz/sabri-core';
import { ReportType, reportTypes } from '@ambuliz/sabri-core/dist/cloud';
import {
  CircularProgress,
  Grid,
  MenuItem,
  PopperPlacementType,
  Stack,
  Typography,
  TypographyVariant,
} from '@mui/material';
import { Dropdown } from 'common/components/DropDown';
import { i18n } from 'common/locale';
import { useState } from 'react';

export const handleViaTrajectoireRedirection = async (
  type: ReportType,
  visitId?: string,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!visitId) {
    return;
  }
  setLoading?.(true);
  try {
    const { link } = await Cloud.getPatientContextualLink({
      id: visitId,
      type,
    });
    window.open(link);
  } catch (err) {}
  setLoading?.(false);
};

type LoaderProps = { textVariant?: TypographyVariant; padding?: number | string; textMarginLeft?: number | string };

export const ViaTrajectoireLoader = ({ textVariant, padding, textMarginLeft }: LoaderProps) => (
  <Stack direction="row" height="fit-content" alignItems="center" padding={padding}>
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress size={12} color="primary" />
    </Grid>
    <Typography color="secondary" variant={textVariant} marginLeft={textMarginLeft || 2}>
      {i18n.loading}
    </Typography>
  </Stack>
);

type ViaTrajectoireSubMenuProps = {
  visitId?: string;
  dropDownMargin?: string | number;
  button: JSX.Element;
  loader: JSX.Element;
  openOnHover?: boolean;
  menuPlacement?: PopperPlacementType;
};

export const ViaTrajectoireLink = ({
  visitId,
  dropDownMargin,
  menuPlacement,
  button,
  loader,
  openOnHover,
}: ViaTrajectoireSubMenuProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dropdown
      button={loading ? loader : button}
      disabled={loading}
      openOnHover={openOnHover}
      placement={menuPlacement || 'right-start'}
      dropdownMargin={dropDownMargin}
      transformOrigin="top left"
    >
      {Object.values(reportTypes).map((reportType) => (
        <MenuItem onClick={() => handleViaTrajectoireRedirection(reportType, visitId, setLoading)}>
          {i18n.viaTrajectoire.reportTypes[reportType]}
        </MenuItem>
      ))}
    </Dropdown>
  );
};
