import Emoji from 'common/components/Emoji';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';

const toastEmojis: Record<AccommodationAction, React.ReactElement> = {
  SET_BED: <Emoji name="victoryHand" size={24} />,
  UPDATE_SPECIFICITIES: <Emoji name="okHand" size={24} />,
  UPDATE_DATES: <Emoji name="pencil" size={24} />,
  CANCEL_ADMISSION: <Emoji name="thumbsUp" size={24} />,
  CONFIRM_ADMISSION: <Emoji name="clappingHands" size={24} />,
  UPDATE_BED: <Emoji name="victoryHand" size={24} />,
  UPDATE_UNIT: <Emoji name="okHand" size={24} />,
  CANCEL_BED: <Emoji name="indexPointingUp" size={24} />,
  VALIDATE_DISCHARGE_DATE: <Emoji name="writingHand" size={24} />,
  INVALIDATE_DISCHARGE_DATE: <Emoji name="writingHand" size={24} />,
  CANCEL_DISCHARGE: <Emoji name="thumbsUp" size={24} />,
  DISCHARGE_PATIENT: <Emoji name="slightlySmilingFace" size={24} />,
  UPDATE_PLANNED_DISCHARGE_DATE: <Emoji name="nerdFace" size={24} />,
  UPDATE_VALIDATED_DISCHARGE_DATE: <Emoji name="nerdFace" size={24} />,
  ADDRESS_MUTATION_REQUEST: <Emoji name="thumbsUp" size={24} />,
  EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST: <Emoji name="thumbsUp" size={24} />,
  ACCEPT_INTERNAL_MUTATION_REQUEST: <Emoji name="handshake" size={24} />,
  REJECT_INTERNAL_MUTATION_REQUEST: <Emoji name="raisedHand" size={24} />,
  ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: <Emoji name="handshake" size={24} />,
  REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: <Emoji name="raisedHand" size={24} />,
  NEW_INTERNAL_MUTATION_REQUEST: <Emoji name="thumbsUp" size={24} />,
  CANCEL_MUTATION_REQUEST: <Emoji name="thumbsUp" size={24} />,
  SET_MUTATION_BED: <Emoji name="victoryHand" size={24} />,
  CANCEL_MUTATION_BED: <Emoji name="indexPointingUp" size={24} />,
  UPDATE_MUTATION_DATE: <Emoji name="pencil" size={24} />,
  REQUEST_MUTATION: <Emoji name="thumbsUp" size={24} />,
  CANCEL_ACCOMMODATION: <Emoji name="thumbsUp" size={24} />,
  REOPEN_BED: <Emoji name="thumbsUp" size={24} />,
  CANCEL_CLOSED_BED: <Emoji name="thumbsUp" size={24} />,
  CLOSE_BED: <Emoji name="thumbsUp" size={24} />,
  ADD_ACCOMMODATION: <Emoji name="thumbsUp" size={24} />,
  VIA_TRAJECTOIRE_REDIRECT: <Emoji name="thumbsUp" size={24} />,
};

const showEmoji = (action: string) => {
  return ['CANCEL_ADMISSION', 'CANCEL_DISCHARGE', 'CANCEL_BED', 'CANCEL_MUTATION_REQUEST'].includes(action);
};

export { showEmoji, toastEmojis };
