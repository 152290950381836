import { PatientIdentity } from '@ambuliz/sabri-core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { ListPagination } from 'common/components';
import { differenceInYears, format } from 'date-fns';
import { useNavigate } from 'react-router';
import PatientAnonymizer from '../steps/anonymizer';
type OrderByOption = {
  key: string;
  direction: 'asc' | 'desc';
};

type PatientTableProps = {
  patients: PatientIdentity[];
  orderBy: OrderByOption;
  onOrderByChange: (orderBy: OrderByOption) => void;
  page: number;
  rowsPerPage: number;
  total: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
};

const PatientTable = ({
  patients,
  orderBy,
  onOrderByChange,
  page = 0,
  onPageChange,
  total = 0,
  rowsPerPage,
  onRowsPerPageChange,
}: PatientTableProps) => {
  const handleOrderByChange = (key: string) => () => {
    onOrderByChange({
      key,
      direction: orderBy?.key === key ? (orderBy.direction === 'asc' ? 'desc' : 'asc') : 'desc',
    });
  };

  const navigate = useNavigate();

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Identifiants</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy && orderBy.key === 'birthdate'}
                    direction={orderBy && orderBy.direction === 'desc' ? 'asc' : 'desc'}
                    onClick={handleOrderByChange('birthdate')}
                  >
                    Age
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy && orderBy.key === 'createdAt'}
                    direction={orderBy && orderBy.direction === 'desc' ? 'desc' : 'asc'}
                    onClick={handleOrderByChange('createdAt')}
                  >
                    Création
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy && orderBy.key === 'updatedAt'}
                    direction={orderBy && orderBy.direction === 'desc' ? 'desc' : 'asc'}
                    onClick={handleOrderByChange('updatedAt')}
                  >
                    Mise à jour
                  </TableSortLabel>
                </TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.map((patient) => (
                <TableRow key={patient.id} onClick={() => navigate(`./${patient.id}`)} sx={{ cursor: 'pointer' }}>
                  <TableCell>{patient.id}</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      {(patient.identifiers || []).map(({ type, value }, index) => (
                        <Grid item key={index}>
                          <Chip label={`${type}: ${value}`} />
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                  <TableCell>{PatientAnonymizer(patient).name}</TableCell>
                  <TableCell>{differenceInYears(new Date(), patient.birthdate)} ans</TableCell>
                  <TableCell>{format(patient.createdAt, 'dd/MM/yyyy - HH:mm:ss')}</TableCell>
                  <TableCell>{format(patient.updatedAt, 'dd/MM/yyyy - HH:mm:ss')}</TableCell>

                  <TableCell align="right">
                    <Tooltip title="Voir les détails" arrow={false} placement="top">
                      <IconButton href={patient.id} LinkComponent={Link} variant="filled" color="primary" size="medium">
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>{total} patients</Typography>
        <ListPagination
          count={total}
          onPageChange={(_, page) => onPageChange(page)}
          onRowsPerPageChange={(e) => onRowsPerPageChange(parseInt(e.target.value, 10))}
          page={page}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Patients par page"
        />
      </Stack>
    </Stack>
  );
};

export default PatientTable;
