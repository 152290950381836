export const modalNames = {
  realTimeError: 'realTimeError',
  snackbar: 'snackbar',
  confirm: 'confirm',
  reload: 'reload',
  confirmPullFare: 'confirmPullFare',
  confirmPushFare: 'confirmPushFare',
  cancelPullFare: 'cancelPullFare',
  cancelPushFare: 'cancelPushFare',
} as const;

export type ModalNames = keyof typeof modalNames;
