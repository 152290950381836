import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { useAppBarContext } from 'core/layout';
import { ManagerIcon } from 'core/layout/components/AppBar';
import useNavigableUnits from 'core/locations/useNavigableUnits';
import { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import UnitManagement from './UnitManagement';

const useUnitPageParams = () => {
  const match = useMatch('/lits/management/unit/:ids/*');
  const [ids, setIds] = useState<string[]>([]);

  useEffect(() => {
    if (match) {
      const { ids } = match.params as { ids: string };
      setIds((oldIds) => {
        const newIds = ids.split('-');
        const hasChanged = JSON.stringify(oldIds) !== JSON.stringify(newIds);
        return hasChanged ? newIds : oldIds;
      });
    }
  }, [match]);

  return { ids };
};

const UnitPage = () => {
  const { units, loading, departments } = useNavigableUnits();
  const { healthCenter, user } = useAuthentication();

  const { appBarSelect, setAppBarSelect } = useAppBarContext();

  const navigate = useRef(useNavigate());
  const { ids } = useUnitPageParams();

  const label = healthCenter?.name || '';

  useEffect(() => {
    if (units.length === 0 || loading) {
      setAppBarSelect(undefined);
    } else if (units.length === 1) {
      setAppBarSelect({
        value: units[0].name,
        subLabel: user.role === 'MANAGER' ? `${i18n.unit} • ${healthCenter?.name}` : label,
        icon: user.role === 'MANAGER' ? <ManagerIcon /> : undefined,
      });
    } else {
      setAppBarSelect({
        multiple: true,
        options: departments
          .filter(({ units }) => units.some(({ type }) => type === 'ACCOMMODATION'))
          .map(({ name, units }) => ({
            id: name,
            label: name,
            children: units
              .filter(({ type }) => type === 'ACCOMMODATION')
              .map(({ id, name }) => ({ id, label: name, children: [] })),
          })),
        value: ids,
        label,
      });
    }
  }, [ids, units, loading, label, setAppBarSelect, departments, user.role, healthCenter?.name]);

  useEffect(() => {
    if (appBarSelect?.value && Array.isArray(appBarSelect.value) && appBarSelect.value.length > 0) {
      // Don't navigate if it is an accommodation details
      if (
        window.location.pathname.includes('accommodation') ||
        window.location.pathname.includes('mutation') ||
        window.location.pathname.includes('cleaning') ||
        window.location.pathname.includes('fare')
      ) {
        return;
      }
      navigate.current({
        pathname: `/lits/management/unit/${appBarSelect.value.join('-')}`,
        search: window.location.search,
      });
    }
  }, [appBarSelect?.value]);

  if (loading || !units || units?.length === 0) {
    return null;
  }

  return (
    <>
      <Routes>
        <Route path=":ids/*" element={<UnitManagement units={ids} key={ids.join('-')} />} />
        <Route path="*" element={<Navigate to={units[0].id} replace />} />
      </Routes>
    </>
  );
};

export default UnitPage;
