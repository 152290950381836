import { Chip, ChipProps } from '@mui/material';
import AdmissionIcon from 'common/components/Icons/Admission';
import AffectedBedIcon from 'common/components/Icons/AffectedBedIcon';
import NoBedIcon from 'common/components/Icons/NoBedIcon';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';

type Color = keyof Pick<typeof palette, 'success' | 'secondary' | 'warning'>;
type Status = 'TO_ASSIGN' | 'ASSIGNED' | 'ADMITTED';

type AdmissionStatusChipProps = {
  status: Status;
} & Omit<ChipProps, 'label'>;

const properties: Record<Status, { label: string; color: Color; icon: React.ReactElement }> = {
  ADMITTED: {
    label: i18n.admissionStatus.patientAdmitted,
    color: 'secondary',
    icon: <AdmissionIcon />,
  },
  ASSIGNED: {
    label: i18n.admissionStatus.bedAssigned,
    color: 'success',
    icon: <AffectedBedIcon />,
  },
  TO_ASSIGN: {
    label: i18n.admissionStatus.toPlace,
    color: 'warning',
    icon: <NoBedIcon />,
  },
};

const AdmissionStatusChip = ({ status, ...props }: AdmissionStatusChipProps) => (
  <Chip
    color={properties[status].color}
    label={properties[status].label}
    icon={properties[status].icon}
    variant="light"
    {...props}
  />
);

export default AdmissionStatusChip;
