import { AvailabilityPeriodStatus, Area as ParseArea } from '@ambuliz/sabri-core';
import useParseResource from 'common/hooks/useParseResource';
import { useContext } from 'react';
import AreaContext from 'superadmin/context/area/AreaContext';
import { AreaService } from 'superadmin/service';
import { Area, NormalizedArea } from 'superadmin/types';

const getBreadcrumb = (area: NormalizedArea, areas: Record<string, NormalizedArea>): string[] => {
  let crumbs: string[] = [];
  if (area) {
    crumbs.push(area.id);
    if (area.parent) {
      crumbs = [...getBreadcrumb(areas[area.parent], areas), ...crumbs];
    }
  }
  return crumbs;
};

const useArea = (id: string) => {
  const { isLoading, resource } = useParseResource(id, ParseArea);

  const { areaById, areas } = useContext(AreaContext);

  const create = async (newArea: Omit<Area, 'id'>) => {
    await AreaService.create(newArea);
  };

  const update = async (updatedArea: Area) => {
    await AreaService.update({ ...updatedArea, id });
  };

  const deleteArea = async (id: string) => {
    await AreaService.delete(id);
  };

  const updateAvailability = async (params: {
    id: string;
    availabilityPeriod: { start: Date; end?: Date; status: AvailabilityPeriodStatus };
  }) => {
    await AreaService.updateAvailability(params);
  };

  const move = async (params: { parentId: string; newExternalIds?: string[]; activationDate?: Date }) => {
    await AreaService.move({ id, ...params });
  };

  return {
    area: resource,
    isLoading,
    breadcrumb: getBreadcrumb(areaById(id), areas),
    update,
    create,
    updateAvailability,
    move,
    deleteArea,
  };
};

export default useArea;
