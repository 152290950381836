import { Warning } from '@mui/icons-material';
import { Chip, Stack, Typography } from '@mui/material';
import ProcessingIcon from 'common/components/Icons/Processing';
import { formatDateTime } from 'common/utils';
import { JourneyFareStatus } from './useJourney';

type JourneyListItemProps = {
  status: JourneyFareStatus;
  date?: Date;
  unitName: string;
  areaName?: string;
  incident?: string;
  chipLabel?: string;
};

const JourneyListItem = ({ status, date, unitName, areaName, incident, chipLabel }: JourneyListItemProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      paddingX={4}
      paddingY={1.5}
      justifyContent="space-between"
      sx={{ opacity: status === 'past' ? 0.4 : 1 }}
      gap={3}
      minHeight={36}
    >
      <Stack direction="row" alignItems="center" flex={1}>
        <Typography minWidth={102} variant="body2" color="secondary">
          {date ? (
            formatDateTime(date)
          ) : status === 'current' ? (
            <ProcessingIcon sx={{ fontSize: 12 }} color="secondary" />
          ) : (
            '-'
          )}
        </Typography>
        <Stack direction="row" justifyContent="space-between" flex={1} alignItems="center">
          <Stack direction="row" alignItems="center" flex={1} gap={2}>
            <Typography fontWeight={status === 'current' ? 500 : undefined}>{unitName}</Typography>
            {areaName && <Chip label={areaName} variant="light" color="default" />}
          </Stack>
          {incident && (
            <Stack direction="row" gap={1} alignItems="center">
              <Warning sx={{ color: '#F57C00' }} />
              <Typography color="#FD861C">{incident}</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
      {chipLabel && <Chip variant="outlined" color="default" size="medium" label={chipLabel} />}
    </Stack>
  );
};

export default JourneyListItem;
