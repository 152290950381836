import { Box, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { TimelineRowItem, TimelineRowItemProps } from 'common/components/Timeline';

const FareItem = ({
  toArea,
  fromArea,
  fromUnit,
  toUnit,
  ...props
}: { fromUnit: string; toUnit: string; toArea?: string; fromArea?: string } & TimelineRowItemProps) => {
  return (
    <TimelineRowItem {...props}>
      <Stack direction="row" spacing={1} alignItems="center" whiteSpace="nowrap" paddingX={3}>
        <Box
          height={22}
          width={22}
          fontSize={12}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
        >
          <Emoji name="manualWheelchair" />
        </Box>
        <Typography variant="body2">
          {fromUnit}
          {fromArea ? ` (${fromArea})` : ''}
        </Typography>
        <Typography variant="body2">→</Typography>
        <Typography variant="body2">
          {toUnit}
          {toArea ? ` (${toArea})` : ''}
        </Typography>
      </Stack>
    </TimelineRowItem>
  );
};

export default FareItem;
