import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SortDirection = 'asc' | 'desc';

type FaresState = {
  ids: string[];
  count: number | undefined;
  filters: {
    page: number;
    limit: number;
    sortBy: string;
    sortDirection: SortDirection;
    where?: Record<string, any>;
  };
};

const initialState: FaresState = {
  ids: [],
  count: undefined,
  filters: {
    page: 0,
    limit: 20,
    sortBy: 'createdAt',
    sortDirection: 'desc',
  },
};

const setFares = (state: FaresState, action: PayloadAction<{ ids: string[]; count: number }>) => {
  const { ids, count } = action.payload;
  return { ...state, ids, count };
};

const setPage = (state: FaresState, action: PayloadAction<number>) => {
  const page = action.payload;
  return { ...state, filters: { ...state.filters, page } };
};

const setLimit = (state: FaresState, action: PayloadAction<number>) => {
  const limit = action.payload;
  return { ...state, filters: { ...state.filters, limit } };
};

const setSortBy = (state: FaresState, action: PayloadAction<string>) => {
  const sortBy = action.payload;
  return { ...state, filters: { ...state.filters, sortBy } };
};

const setSortDirection = (state: FaresState, action: PayloadAction<SortDirection>) => {
  const sortDirection = action.payload;
  return { ...state, filters: { ...state.filters, sortDirection } };
};

const setWhere = (state: FaresState, action: PayloadAction<Record<string, any> | undefined>) => {
  const where = action.payload;
  return { ...state, filters: { ...state.filters, where } };
};

const { reducer, actions } = createSlice({
  name: 'fares',
  initialState,
  reducers: {
    setFares,
    setPage,
    setLimit,
    setSortBy,
    setSortDirection,
    setWhere,
  },
});

export { reducer as faresReducer, actions as faresActions };
