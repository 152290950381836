import { Cloud } from '@ambuliz/sabri-core';
import { OccupancyFormValue } from 'kurt/components/Department/EditModal';
import { useState } from 'react';

const useOccupancy = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const update = async (occupancy: OccupancyFormValue) => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      await Cloud.updateOccupancy({ ...occupancy, unitId: id });
      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { update, loading, success, error };
};

export default useOccupancy;
