import { useCallback, useContext } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { HistoryContext } from 'sabri/context/HistoryContext';
import { routeNamesCommon, routeNamesRedirections } from '../const/routeNames';

export const useCloseModal = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();

  const { length } = useContext(HistoryContext);

  return useCallback(() => {
    if (location.search.includes('?autoCall')) {
      navigate(routeNamesCommon.fareList);
    } else if (length > 2) {
      navigate(-1);
    } else {
      if (navigationType === 'POP' || navigationType === 'REPLACE') {
        const routeToRedirect = routeNamesRedirections[location.pathname];
        if (routeToRedirect) {
          navigate(routeToRedirect);
        } else {
          navigate(routeNamesCommon.fareList);
        }
      } else {
        navigate(-1);
      }
    }
  }, [navigate, location, length, navigationType]);
};
