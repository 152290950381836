import { SvgIcon, SvgIconProps } from '@mui/material';

const RoadSign: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...rest} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_6841_8862)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66348 0.0100098C8.00866 0.0100098 8.28848 0.289832 8.28848 0.63501V0.828568L13.3756 0.386866C13.5512 0.371621 13.7251 0.431218 13.8544 0.550976L15.6958 2.25642C15.8235 2.3747 15.8961 2.54089 15.8961 2.71497C15.8961 2.88904 15.8235 3.05523 15.6958 3.17351L13.8544 4.87896C13.7525 4.97331 13.6221 5.03105 13.4837 5.04307L8.2885 5.49415L8.2885 6.47267L12.3242 6.12222C12.4987 6.10706 12.6716 6.16583 12.8007 6.28421C12.9298 6.40259 13.0033 6.56971 13.0033 6.74487V10.1558C13.0033 10.48 12.7554 10.7504 12.4324 10.7784L8.28848 11.1383V14.7401H9.43998C9.78516 14.7401 10.065 15.02 10.065 15.3651C10.065 15.7103 9.78516 15.9901 9.43998 15.9901H5.88698C5.5418 15.9901 5.26198 15.7103 5.26198 15.3651C5.26198 15.02 5.5418 14.7401 5.88698 14.7401H7.03848L7.03848 11.2468L2.61262 11.6311C2.43702 11.6464 2.26315 11.5868 2.13384 11.467L0.292633 9.76156C0.164934 9.64328 0.0923462 9.4771 0.0923462 9.30304C0.0923462 9.12898 0.164934 8.9628 0.292633 8.84452L2.13384 7.13907C2.23572 7.0447 2.36613 6.98695 2.50448 6.97494L7.0385 6.58122L7.0385 5.60269L3.66284 5.89579C3.48834 5.91094 3.31549 5.85217 3.18638 5.73379C3.05728 5.61541 2.98378 5.44829 2.98378 5.27313V1.86225C2.98378 1.53802 3.23171 1.26763 3.55472 1.23959L7.03848 0.937102L7.03848 0.63501C7.03848 0.289832 7.3183 0.0100098 7.66348 0.0100098ZM11.7533 7.4265V9.58268L2.78022 10.3619L1.63708 9.30304L2.82608 8.20172L11.7533 7.4265ZM4.23378 4.59151V2.43533L13.208 1.65612L14.3513 2.71497L13.1622 3.81628L4.23378 4.59151Z"
          fill="#666D8E"
        />
      </g>
      <defs>
        <clipPath id="clip0_6841_8862">
          <rect width="16" height="16" fill="white" transform="translate(-0.00579834)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default RoadSign;
