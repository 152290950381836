import { SvgIcon, SvgIconProps } from '@mui/material';

const Map: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33332 10.2399C9.33552 8.47349 10.8266 7.04204 12.6667 7.03992C14.5067 7.04204 15.9978 8.47349 16 10.2399C16 12.2694 13.0287 15.1449 12.9027 15.2665C12.8401 15.3267 12.7552 15.3605 12.6667 15.3605C12.5781 15.3605 12.4932 15.3267 12.4307 15.2665C12.3047 15.1449 9.33332 12.2694 9.33332 10.2399ZM11.5 10.2398C11.5 10.8584 12.0223 11.3598 12.6667 11.3598C13.3107 11.3591 13.8326 10.8581 13.8333 10.2398C13.8333 9.62129 13.311 9.11985 12.6667 9.11985C12.0223 9.11985 11.5 9.62129 11.5 10.2398Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.40005C9.63181 6.40005 9.33333 6.11351 9.33333 5.76005L9.33933 2.11909C9.33843 2.05962 9.30324 2.00554 9.24797 1.97867C9.1927 1.9518 9.12646 1.95658 9.076 1.99109L6.14267 4.00005C6.05514 4.06019 6.00325 4.1572 6.00333 4.26053V12.4551C6.0047 12.5144 6.03995 12.5681 6.09504 12.5949C6.15013 12.6216 6.2161 12.6171 6.26667 12.5831L8.06067 11.3498C8.36016 11.1554 8.76649 11.2256 8.97661 11.508C9.18673 11.7905 9.12406 12.1822 8.83533 12.3911L5.91867 14.3917C5.56421 14.6348 5.08665 14.6296 4.738 14.3789L0.4 11.2551C0.148852 11.0733 0.000833827 10.7891 0 10.4871V0.960047C6.81762e-05 0.592529 0.218695 0.257303 0.563045 0.0967143C0.907395 -0.0638746 1.31703 -0.0216388 1.618 0.205487L5.152 2.82437C5.26888 2.9118 5.43186 2.91467 5.552 2.83141L9.41867 0.179247C9.76655 -0.059566 10.2341 -0.059566 10.582 0.179247L14.9147 3.15013C15.177 3.33083 15.3328 3.62161 15.3333 3.93157V5.76005C15.3333 6.11351 15.0349 6.40005 14.6667 6.40005C14.2985 6.40005 14 6.11351 14 5.76005V4.26117C14.0001 4.15898 13.9493 4.0629 13.8633 4.00261L10.93 1.99301C10.8795 1.9585 10.8133 1.95372 10.758 1.98059C10.7028 2.00746 10.6676 2.06154 10.6667 2.12101V5.76005C10.6667 6.11351 10.3682 6.40005 10 6.40005ZM4.57452 12.5501C4.63099 12.523 4.66665 12.4676 4.66665 12.407V4.24123C4.66665 4.14051 4.61726 4.04567 4.53332 3.98523L1.59999 1.80923C1.54949 1.77287 1.48192 1.76702 1.42545 1.79413C1.36899 1.82123 1.33332 1.87663 1.33332 1.93723V10.167C1.33332 10.2677 1.38272 10.3626 1.46666 10.423L4.39999 12.535C4.45049 12.5714 4.51806 12.5772 4.57452 12.5501Z"
      />
    </SvgIcon>
  );
};

export default Map;
