import Api, { Accommodation, AccommodationRequest, Patient, Unit } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';
import { addDays, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { MovementRow, mapAccommodationToRow, mapRequestToRow } from '../MovementRow';
import useOutdatedAnomalies from '../useOutdatedAnomalies';

const useDischarges = (date: Date, unitIds: string[]) => {
  const { start, end } = useMemo(() => {
    const start = startOfDay(date);
    return {
      start,
      end: addDays(start, 1),
    };
  }, [date]);

  const { results: accommodations, isLoading: accommodationsLoading } = useParseQuery(
    findAccommodationsQuery({ start, end, unitIds })
  );

  const { results: accommodationRequests, isLoading: accommodationRequestsLoading } = useParseQuery(
    findAccommodationRequestsQuery({ visits: findActiveVisits(accommodations) }),
    { enabled: !accommodationsLoading }
  );

  const { findByMovement } = useOutdatedAnomalies(
    accommodations.map(({ id }) => id),
    'end'
  );

  const movements: MovementRow[] = useMemo(() => {
    const movements: Record<string, MovementRow> = {};

    for (const accommodation of Object.values(accommodations)) {
      if (accommodation.visit && !!accommodation.endAt && accommodation.endAt < end) {
        movements[accommodation.id] = mapAccommodationToRow(
          accommodation,
          findByMovement(accommodation.id) || [],
          true
        );
      }
    }

    for (const request of Object.values(accommodationRequests)) {
      const visit = request.visit!;

      const movement = mapRequestToRow(request, true);
      if (!movement) {
        continue;
      }

      if (movement.status === 'outgoing_mutation_requested' && request.status === 'ACCEPTED') {
        let hasLinkedAccommodation = false;
        for (const accommodation of Object.values(accommodations)) {
          if (accommodation.visit?.id === visit.id) {
            hasLinkedAccommodation = true;
            const linkedAccommodation = movements[accommodation.id];
            if (linkedAccommodation) {
              linkedAccommodation.hasMutation = true;
              linkedAccommodation.requestType = request.requestType;
            }
          }
        }

        if (!hasLinkedAccommodation) {
          movements[movement.id] = movement;
        }
      } else {
        movements[movement.id] = movement;
      }
    }

    return Object.values(movements);
  }, [accommodations, accommodationRequests, findByMovement, end]);

  return {
    movements,
    loading: accommodationsLoading || accommodationRequestsLoading,
  };
};

const findActiveVisits = (accommodations: Accommodation[]) =>
  accommodations
    .filter(({ visit, status }) => !!visit && status === 'IN_PROGRESS')
    .map(({ visit }) => visit) as Patient[];

const findAccommodationsQuery = ({ start, end, unitIds }: { start: Date; end: Date; unitIds: string[] }) =>
  new Api.Query(Accommodation)
    .greaterThanOrEqualTo('endAt', start)
    .notEqualTo('status', 'CANCELLED')
    .lessThan('startAt', end)
    .containedIn(
      'unit',
      unitIds.map((unitId) => Unit.createWithoutData(unitId))
    )
    .include('visit', 'bed', 'unit', 'basedOn');

const findAccommodationRequestsQuery = ({ visits }: { visits: Patient[] }) => {
  return new Api.Query(AccommodationRequest)
    .containedIn('visit', visits)
    .exists('performerRequests')
    .include('visit', 'performerRequests');
};

export default useDischarges;
