import { Stack, SvgIconProps, Typography } from '@mui/material';

type DetailsSummaryRowProps = {
  Icon: React.FC<SvgIconProps<'svg'>>;
  label: string;
  value?: string | React.ReactNode;
};

const DetailsSummaryRow: React.FC<DetailsSummaryRowProps> = ({ Icon, label, value = '-' }) => {
  return (
    <Stack spacing={4} direction="row" alignItems="center">
      <Stack spacing={2} alignItems="center" minWidth={200} direction="row">
        <Icon color="secondary" style={{ fontSize: 12 }} />
        <Typography variant="body2" fontWeight={600}>
          {label}
        </Typography>
      </Stack>
      <Stack flex={1}>
        {typeof value === 'string' ? (
          <Typography variant="body2" whiteSpace="pre-line">
            {value}
          </Typography>
        ) : (
          <div>{value}</div>
        )}
      </Stack>
    </Stack>
  );
};

export default DetailsSummaryRow;
