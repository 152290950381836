import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useHospitalAreas } from 'superadmin/pages/AreaManagement/hooks/useHospitalAreas';
import { AreaSelectItem } from './AreaSelectItem';
import { SelectedAreaList } from './SelectedAreaList';

export const SingleAreaSelect = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
  const [open, setOpen] = useState(false);
  const { hospitals } = useHospitalAreas({ enabled: open });

  const handleChange = (value: string[]) => {
    onChange(value[value.length - 1]);
  };

  const handleDelete = () => {
    onChange('');
  };

  return (
    <Stack spacing={2}>
      <SelectedAreaList areas={!value ? [] : [value]} onDelete={handleDelete} />
      <Paper elevation={0} sx={{ p: 2 }}>
        <Button variant="text" onClick={() => setOpen(!open)}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <ExpandMoreIcon sx={{ rotate: open ? '0deg' : '-90deg', transition: '.2s' }} />
            <Typography>Sélectionner un emplacement</Typography>
          </Stack>
        </Button>
        <Collapse in={open}>
          <Box sx={{ py: 2 }}>
            {hospitals.map((hospital) => {
              return (
                <AreaSelectItem
                  key={hospital.id}
                  name={hospital.name}
                  type={hospital.externalTypeCode}
                  id={hospital.id}
                  value={value ? [value] : []}
                  onChange={handleChange}
                />
              );
            })}
          </Box>
        </Collapse>
      </Paper>
    </Stack>
  );
};
