import { Dialog } from '@mui/material';
import { useEffect } from 'react';

type ExternalPatientTransportModalProps = {
  src?: string;
  onClose: () => void;
};

const ExternalPatientTransportModal: React.FC<ExternalPatientTransportModalProps> = ({ src, onClose }) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.dest === 'SABRI_COM' && event.data?.action === 'close' && src) {
        onClose();
      }
    };

    window.addEventListener('message', (e) => handleMessage(e as MessageEvent));
    return () => window.removeEventListener('message', (e) => handleMessage(e as MessageEvent));
  });

  return (
    <Dialog fullWidth maxWidth="lg" open={!!src} onClose={onClose}>
      <iframe style={{ border: 0 }} title="external-transport-create-form" src={src} width="100%" height={700} />
    </Dialog>
  );
};

export default ExternalPatientTransportModal;
