import { Card, Collapse, IconButton, Stack, Typography } from '@mui/material';

import { Fare, Journey } from '@ambuliz/sabri-core';
import { ExpandMore } from '@mui/icons-material';
import { transition } from 'common/theme';
import { useState } from 'react';
import { i18n } from 'sabri/locales';
import JourneyList from './JourneyList';

type JourneyDetailsProps = {
  fares: Fare[];
  journey: Journey;
};

const JourneyDetails = ({ fares, journey }: JourneyDetailsProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card variant="outlined" elevation={0}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
        paddingX={4}
        paddingY={2}
      >
        <Typography variant="h5">{i18n.journeyDetailsTitle}</Typography>
        <IconButton onClick={handleClick} edge="end">
          <ExpandMore
            sx={{ transform: expanded ? 'rotate(180deg)' : undefined, transition: `transform ${transition}` }}
          />
        </IconButton>
      </Stack>
      <Collapse in={expanded}>
        <JourneyList fares={fares} journey={journey} />
      </Collapse>
    </Card>
  );
};

export default JourneyDetails;
