import * as React from 'react';
import { SVGProps } from 'react';

const HourglassImage = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.3529 3.17871C28.5529 3.74037 26.1135 4.66058 19.1602 9.50203C7.6182 17.5393 -0.674025 31.0792 0.511467 44.9549C1.34877 54.7725 6.7125 63.7259 13.3882 71.0357C17.498 75.5331 22.2234 79.5995 27.7923 82.1487C34.0389 85.0067 41.0939 85.8295 47.9768 85.4793C55.3778 85.1041 62.7954 83.3798 69.2389 79.757C78.1716 74.7373 84.8887 66.1155 87.8296 56.3974C90.7726 46.6813 89.983 35.9725 85.954 26.6585C84.8265 24.0533 83.4441 21.531 81.5954 19.3652C79.5498 16.9652 76.9819 15.0523 74.3104 13.3549C63.9581 6.78078 51.6742 3.20565 39.3529 3.17871Z"
      fill="#E6EDFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.1063 6.8369C36.3104 5.41306 31.2493 8.15711 29.8255 12.953L28.5343 17.297C27.4773 20.8576 27.9975 25.8193 29.7674 29.0877L36.8597 42.1716L23.7778 49.2639C20.5094 51.0338 17.3674 54.9095 16.3084 58.4742L15.0192 62.8162C13.5933 67.6141 16.3374 72.6753 21.1332 74.0991L52.8493 83.5167C57.6472 84.9406 62.7063 82.1965 64.1322 77.4007L65.4213 73.0566C66.4804 69.4939 65.9602 64.5302 64.1902 61.2639L57.098 48.1799L70.1819 41.0877C73.4483 39.3177 76.5902 35.4421 77.6493 31.8815L78.9384 27.5374C80.3623 22.7416 77.6182 17.6784 72.8224 16.2545L41.1063 6.8369Z"
      fill="#B5CAFF"
      stroke="#B5CAFF"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.8151 11.1872L71.5311 20.6048C73.9208 21.3136 75.299 23.8525 74.5881 26.2463L73.299 30.5903C72.5881 32.982 70.213 35.9084 68.0182 37.1002L54.9342 44.1924C52.7415 45.382 51.9187 48.1489 53.1104 50.3416L60.2006 63.4255C61.3902 65.6204 61.7819 69.3717 61.071 71.7654L59.7819 76.1095C59.071 78.5012 56.5322 79.8774 54.1405 79.1665L22.4265 69.7489C20.0348 69.0401 18.6565 66.5012 19.3674 64.1095L20.6586 59.7654C21.3674 57.3717 23.7446 54.4432 25.9374 53.2515L39.0213 46.1592C41.2161 44.9717 42.0389 42.2027 40.8472 40.01L33.755 26.9261C32.5674 24.7312 32.1736 20.982 32.8845 18.5882L34.1736 14.2442C34.8845 11.8525 37.4254 10.4763 39.8151 11.1872Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3768 57.2493C26.7602 57.6473 25.0483 59.2763 24.5758 60.8701L23.9333 63.0338C23.4607 64.6276 24.3789 66.3229 25.9706 66.7954L52.783 74.7581C54.3747 75.2307 56.0701 74.3105 56.5426 72.7167L57.1851 70.553C57.6597 68.9592 57.1063 66.6649 55.9561 65.4649L47.9333 57.0566C46.783 55.8524 46.2317 53.5623 46.7063 51.9685L48.6276 45.5001C49.1001 43.9063 50.6732 41.9333 52.124 41.1167L63.0338 35.8814C64.4846 35.0669 65.6369 33.4566 62.7727 33.5374L43.9727 32.6131C42.3789 32.1405 41.7136 32.9529 42.4991 34.4203L44.7706 38.6897C45.5519 40.1592 45.8048 42.6669 45.3302 44.2607L43.3613 50.8929C42.8887 52.4887 41.1768 54.1177 39.5602 54.5156"
      fill="#799AF1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.839 5.59751L83.7778 21.9105C84.9136 22.2483 86.1074 21.5996 86.4431 20.4659L86.8597 19.069C87.1954 17.9333 86.5488 16.7395 85.4131 16.4037L30.4742 0.0886978C29.3405 -0.247053 28.1467 0.399579 27.8089 1.53533L27.3944 2.93222C27.0566 4.0659 27.7053 5.25968 28.839 5.59751Z"
      fill="#5470B8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64618 73.5973L63.5871 89.9103C64.7208 90.2481 65.9146 89.6015 66.2524 88.4657L66.6669 87.0709C67.0047 85.9352 66.3581 84.7414 65.2224 84.4036L10.2814 68.0906C9.14774 67.7528 7.95396 68.3994 7.61613 69.5352L7.20162 70.9321C6.86587 72.0678 7.51251 73.2616 8.64618 73.5973Z"
      fill="#799AF1"
    />
  </svg>
);

export default HourglassImage;
