import { Components } from '@mui/material';

const MuiCard: Components['MuiCard'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      overflow: 'visible',
    },
  },
};

export default MuiCard;
