import { MenuItem, Select } from '@mui/material';
import DischargeStatusChip, { DischargeStatus } from './DischargeStatusChip';

type DischargeStatusSelectProps = {
  value: DischargeStatus;
  onChange: (value: DischargeStatus) => void;
};

const DischargeStatusSelect = ({ value, onChange }: DischargeStatusSelectProps) => {
  const options: DischargeStatus[] = ['ESTIMATED', 'VALIDATED'];

  return (
    <Select
      variant="filled"
      size="small"
      value={value}
      onChange={(event) => onChange(event.target.value as DischargeStatus)}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          <DischargeStatusChip status={option} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default DischargeStatusSelect;
