import { Box, Button, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import React from 'react';
import useClasses from './TableListHeaderStyles';

type TableListHeaderProps = {
  title: string;
  action?: () => void;
  actionTitle?: string;
};

export const TableListHeader: React.FC<TableListHeaderProps> = ({ title, action, actionTitle }) => {
  const classes = useClasses();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={6}>
      <Box className={classes.title}>{title}</Box>
      {action && (
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={action}
          startIcon={<AddCircleOutline className={classes.addIcon} />}
        >
          <Typography variant="body1" className={classes.addButtonText}>
            {actionTitle}
          </Typography>
        </Button>
      )}
    </Box>
  );
};
