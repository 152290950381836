import { Components, tableCellClasses } from '@mui/material';
import color from '../color';
import palette from '../palette';

const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    root: {
      borderBottomColor: color.grey[10],
    },
    head: {
      textTransform: 'uppercase',
      lineHeight: '12px',
      fontSize: '10px',
      color: palette.text.secondary,
      '.MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    stickyHeader: {
      backgroundColor: 'white',
    },
    sizeSmall: {
      paddingTop: 8,
      paddingBottom: 8,
      [`&.${tableCellClasses.head}`]: {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
  },
};

export default MuiTableCell;
