import { Box } from '@mui/material';
import fadeOutAnimation from 'common/assets/animations/fade-out.json';
import loaderAnimation from 'common/assets/animations/loader.json';
import { useAuthentication } from 'core/authentication';
import { useLocations } from 'core/locations';
import Lottie, { AnimationItem } from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type LoaderProps = {
  children: React.ReactNode;
};

const Loader = ({ children }: LoaderProps) => {
  const { isLoading: authenticationLoading } = useAuthentication();
  const { loading: locationsLoading } = useLocations();
  const ref = useRef<HTMLDivElement | null>(null);
  const [fadeOutComplete, setFadeOutComplete] = useState(false);
  const loader = useRef<AnimationItem | null>(null);
  const fadeOut = useRef<AnimationItem | null>(null);

  const isLoading = authenticationLoading || locationsLoading;

  useEffect(() => {
    if (ref.current) {
      loader.current = Lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
      });
    }
  }, []);

  useEffect(() => {
    if (!loader.current || fadeOutComplete) {
      return;
    }
    loader.current.addEventListener('loopComplete', () => {
      if (!isLoading && loader.current && loader.current.playCount >= 2 && ref.current) {
        loader.current.destroy();
        loader.current = null;

        fadeOut.current = Lottie.loadAnimation({
          container: ref.current,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: fadeOutAnimation,
        });

        fadeOut.current.addEventListener('complete', () => {
          if (fadeOut.current) {
            setFadeOutComplete(true);
            fadeOut.current.destroy();
            fadeOut.current = null;
          }
        });
      }
    });

    return () => loader.current?.removeEventListener('loopComplete');
  }, [isLoading, fadeOutComplete]);

  return (
    <>
      {!fadeOutComplete ? (
        <AnimationContainer>
          <Box width={180} height={140} ref={ref} />
        </AnimationContainer>
      ) : (
        <ChildrenContainer>{children}</ChildrenContainer>
      )}
    </>
  );
};

const AnimationContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const ChildrenContainer = styled.div`
  animation: fadeIn 500ms ease-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default Loader;
