import {
  Cloud,
  ComputeEmergencyOccupanciesOptions,
  EmergencyOccupancyKey,
  EmergencyOccupancyService,
} from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { HealthCenter } from 'core/authentication/types';
import { PageSection, useAppBarContext } from 'core/layout';
import EmergencyOccupancyList from 'kurt/components/EmergencyOccupancy/List/EmergencyOccupancyList';
import { useMemo } from 'react';

type TerritoryEmergencyProps = {
  userHealthCenter: HealthCenter;
  healthCenters: Cloud.HealthCenterRegionData[];
  loading?: boolean;
};

const TerritoryEmergency = ({ healthCenters, userHealthCenter, loading }: TerritoryEmergencyProps) => {
  const { isFullScreen } = useAppBarContext();

  const healthCentersToDisplay = useMemo(
    () =>
      healthCenters.filter(
        (healthCenter) =>
          doesHealthCenterHaveAnEmergencyService(healthCenter) &&
          (doesHealthCenterHaveAnEmergencyService(userHealthCenter) ||
            !doesHealthCenterIsMyHealthCenter(userHealthCenter.id, healthCenter.id))
      ),
    [healthCenters, userHealthCenter]
  );

  return (
    <PageSection withBackground noGutter noMargin={isFullScreen} paddingTop={10} fullHeight lastSection>
      <Stack spacing={10}>
        {healthCentersToDisplay.map((healthCenter) => (
          <HealthCenterEmergencyOccupanciesLists key={healthCenter.id} healthCenter={healthCenter} loading={loading} />
        ))}
      </Stack>
    </PageSection>
  );
};

const HealthCenterEmergencyOccupanciesLists = ({
  healthCenter,
  loading,
}: {
  healthCenter: Cloud.HealthCenterRegionData;
  loading?: boolean;
}) => {
  const getEmergencyOccupancyServiceKey = (service: EmergencyOccupancyService, key: EmergencyOccupancyKey) =>
    healthCenter.emergencyOccupancy?.find((occupancy) => occupancy.service === service && occupancy.key === key);

  return (
    <Stack spacing={4} key={healthCenter.id}>
      <Typography variant="h4">{healthCenter.name}</Typography>
      <Stack spacing={4}>
        <Stack spacing={2}>
          {healthCenter.hasPediatricService && <Typography variant="overline">{i18n.adults}</Typography>}
          <EmergencyOccupancyList
            healthCenterId={healthCenter.id}
            service={'adults'}
            hasPediatricService={healthCenter.hasPediatricService}
            occupancyMorning={getEmergencyOccupancyServiceKey('adults', '9H')}
            occupancyMidday={getEmergencyOccupancyServiceKey('adults', '14H')}
            occupancyAfternoon={getEmergencyOccupancyServiceKey('adults', '17H')}
            loading={loading}
            isReadOnly
          />
        </Stack>
        {healthCenter.hasPediatricService && (
          <Stack spacing={2}>
            <Typography variant="overline">{i18n.pediatric}</Typography>
            <EmergencyOccupancyList
              healthCenterId={healthCenter.id}
              service={'pediatric'}
              hasPediatricService={healthCenter.hasPediatricService}
              occupancyMorning={getEmergencyOccupancyServiceKey('pediatric', '9H')}
              occupancyMidday={getEmergencyOccupancyServiceKey('pediatric', '14H')}
              occupancyAfternoon={getEmergencyOccupancyServiceKey('pediatric', '17H')}
              loading={loading}
              isReadOnly
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

function doesHealthCenterHaveAnEmergencyService(healthCenter: {
  computeEmergencyOccupancies?: ComputeEmergencyOccupanciesOptions;
}): boolean {
  return !!healthCenter.computeEmergencyOccupancies;
}

function doesHealthCenterIsMyHealthCenter(userHealthCenterId: string, healthCenterToCompareId: string): boolean {
  return userHealthCenterId === healthCenterToCompareId;
}

export default TerritoryEmergency;
