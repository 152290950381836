import Api, { PatientIdentity } from '@ambuliz/sabri-core';
import { Dialog } from '@mui/material';
import useParseQuery from 'common/hooks/useParseQuery';
import { ENVIRONMENT } from 'configuration';

const CreateExternalPatientTransport = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { results } = useParseQuery(new Api.Query(PatientIdentity).equalTo('objectId', id).limit(1));

  const patient = results?.[0];
  const ipp = patient?.identifiers?.find(({ type }) => type === 'PI')?.value || '';

  return patient ? (
    <Dialog open={!!id} onClose={onClose} fullWidth maxWidth={'lg'}>
      <iframe
        title="external-transport-create-form"
        width={902}
        height={700}
        src={`${
          ENVIRONMENT.MARCUS_EMBED_WEB_URL || 'https://etablissement.stage.ambuliz.com'
        }/embed/fare/create?ipp=${ipp}&lname=${patient.lastName}&fname=${patient.firstName}&bdate=${
          patient.birthdate
        }&sex=${patient.gender === 'FEMALE' ? 'f' : 'm'}`}
      />
    </Dialog>
  ) : null;
};

export default CreateExternalPatientTransport;
