import { Accommodation, AccommodationRequest } from '@ambuliz/sabri-core';
import { Button, ButtonGroup } from '@mui/material';
import { Emoji } from 'common/components';
import { ChevronSmallRight } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import { actionEmoji } from 'kurt/components/PatientFlowKanban/VisitActions/columnActions';

type AccommodationActionsProps = {
  accommodation: Accommodation;
  mutation?: AccommodationRequest;
  onClick: (action: AccommodationAction) => void;
  disabled?: boolean;
};

const AccommodationActions = ({ accommodation, mutation, disabled, onClick }: AccommodationActionsProps) => {
  const { unit, user } = useAuthentication();

  const hasUnitAccess =
    ['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER'].includes(user?.role) ||
    (user?.role === 'MANAGER' && unit?.id === accommodation.unit?.id);

  const isAllowedToAssignBed =
    hasUnitAccess && ['PLANNED', 'IN_PROGRESS'].includes(accommodation.status) && !accommodation.bed;

  return (
    <>
      <ButtonGroup orientation="vertical" disabled={disabled} variant="action">
        {isAllowedToAssignBed && (
          <>
            <Button
              onClick={() => onClick('SET_BED')}
              startIcon={<Emoji name="bed" size="extra-small" />}
              endIcon={<ChevronSmallRight />}
            >
              {i18n.visitActions.SET_BED.action.label}
            </Button>
            {!accommodation.basedOn && accommodation.status === 'PLANNED' && (
              <Button
                onClick={() => onClick('UPDATE_UNIT')}
                startIcon={<Emoji name={actionEmoji.UPDATE_UNIT} size="extra-small" />}
                endIcon={<ChevronSmallRight />}
              >
                {i18n.visitActions.UPDATE_UNIT.action.label}
              </Button>
            )}
          </>
        )}
        {hasUnitAccess && accommodation.status === 'PLANNED' && !!accommodation.bed && (
          <>
            <Button
              onClick={() => onClick('CONFIRM_ADMISSION')}
              startIcon={<Emoji name={actionEmoji.CONFIRM_ADMISSION} size="extra-small" />}
              endIcon={<ChevronSmallRight />}
            >
              {i18n.visitActions.CONFIRM_ADMISSION.action.label}
            </Button>
            <Button
              onClick={() => onClick('UPDATE_BED')}
              startIcon={<Emoji name={actionEmoji.UPDATE_BED} size="extra-small" />}
              endIcon={<ChevronSmallRight />}
            >
              {i18n.visitActions.UPDATE_BED.action.label}
            </Button>
            <Button
              onClick={() => onClick('CANCEL_BED')}
              startIcon={<Emoji name={actionEmoji.CANCEL_BED} size="extra-small" />}
              endIcon={<ChevronSmallRight />}
            >
              {i18n.visitActions.CANCEL_BED.action.label}
            </Button>
          </>
        )}
        {hasUnitAccess && accommodation.status !== 'COMPLETED' && accommodation.endAt && (
          <Button
            onClick={() =>
              onClick(accommodation.isEstimatedEnd ? 'VALIDATE_DISCHARGE_DATE' : 'INVALIDATE_DISCHARGE_DATE')
            }
            startIcon={
              <Emoji
                name={
                  actionEmoji[accommodation.isEstimatedEnd ? 'VALIDATE_DISCHARGE_DATE' : 'INVALIDATE_DISCHARGE_DATE']
                }
                size="extra-small"
              />
            }
            endIcon={<ChevronSmallRight />}
          >
            {
              i18n.visitActions[accommodation.isEstimatedEnd ? 'VALIDATE_DISCHARGE_DATE' : 'INVALIDATE_DISCHARGE_DATE']
                .action.label
            }
          </Button>
        )}
        {accommodation.status === 'IN_PROGRESS' && !mutation && (
          <Button
            onClick={() => onClick('REQUEST_MUTATION')}
            startIcon={<Emoji name={actionEmoji.REQUEST_MUTATION} size="extra-small" />}
            endIcon={<ChevronSmallRight />}
          >
            {i18n.visitActions.REQUEST_MUTATION.action.label}
          </Button>
        )}
        {accommodation.status === 'IN_PROGRESS' && (
          <Button
            onClick={() => onClick('DISCHARGE_PATIENT')}
            startIcon={<Emoji name={actionEmoji.DISCHARGE_PATIENT} size="extra-small" />}
            endIcon={<ChevronSmallRight />}
          >
            {i18n.visitActions.DISCHARGE_PATIENT.action.label}
          </Button>
        )}
        {hasUnitAccess && (
          <Button
            onClick={() => onClick('CANCEL_ACCOMMODATION')}
            startIcon={<Emoji name={actionEmoji.CANCEL_ACCOMMODATION} size="extra-small" />}
            color="error"
            endIcon={<ChevronSmallRight />}
          >
            {i18n.visitActions.CANCEL_ACCOMMODATION.action.label}
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};

export default AccommodationActions;
