import { Porter } from '@ambuliz/sabri-core';
import { PorterAssignationsTableRow } from '../PorterAssignationsTableRow';
import AssignPorterButton from './AssignPorterButton';

type AssignablePortersTableRowProps = {
  porter: Porter;
  fareId: string;
};

export const AssignablePortersTableRow = ({ porter, fareId }: AssignablePortersTableRowProps) => {
  return (
    <PorterAssignationsTableRow porter={porter} Actions={<AssignPorterButton fareId={fareId} porterId={porter.id} />} />
  );
};
