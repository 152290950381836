import { FarePosition, farePositions, fareSpecificities, FareSpecificity } from '@ambuliz/sabri-core';
import { Box, Checkbox, FormControlLabel, ListItemText, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getFarePositionIcon } from '../../const/fare';
import { i18n } from '../../locales';
import IsValidIcon from './IsValidIcon';
import { formKeys } from './JourneyForm';

const JourneyInfos: React.FC = () => {
  const { watch, setValue, register, unregister, errors, clearErrors } = useFormContext();
  const currentPosition = watch<string, FarePosition | undefined>(formKeys.position);
  const currentSpecificities = watch<string, FareSpecificity[] | undefined>(formKeys.specificities) || [];

  useEffect(() => {
    register(formKeys.specificities);
    return () => {
      unregister(formKeys.specificities);
    };
  }, [register, unregister]);

  const handleChangeSpecificity = (specificity: FareSpecificity) => () => {
    const indexSpecificity = currentSpecificities.indexOf(specificity);
    if (indexSpecificity < 0) {
      const nextSpecificities = [...currentSpecificities, specificity];
      setValue(formKeys.specificities, nextSpecificities);
      return;
    }
    const nextSpecificities = [
      ...currentSpecificities.slice(0, indexSpecificity),
      ...currentSpecificities.slice(indexSpecificity + 1),
    ];
    setValue(formKeys.specificities, nextSpecificities);
  };

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
    //On empêche le retour à la ligne
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" gap={4} flex={1}>
        <Box width={54} height={40} display="flex" alignItems="center">
          <Typography variant="overline">{i18n.infos}</Typography>
        </Box>
        <Stack direction="row" justifyContent="space-between" flex={1}>
          <Controller
            name={formKeys.position}
            render={({ value, onChange }) => (
              <TextField
                error={!!errors[formKeys.position]}
                label={i18n.transportType}
                value={value}
                select
                sx={{ minWidth: 290 }}
                onChange={(...params) => {
                  onChange(...params);
                  clearErrors(formKeys.position);
                }}
              >
                {farePositions.map((farePosition) => {
                  const Icon = getFarePositionIcon(farePosition);
                  return (
                    <MenuItem key={farePosition} value={farePosition}>
                      <Stack direction="row" gap={2} alignItems="center">
                        <Icon />
                        <ListItemText primary={i18n.farePosition[farePosition]}></ListItemText>
                      </Stack>
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          />
          <Stack alignItems="flex-end" flex={1}>
            <IsValidIcon isVisible={!!currentPosition} />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} flexWrap="wrap" style={{ marginLeft: 70, marginRight: 70 }}>
        {fareSpecificities.map((specificity) => (
          <FormControlLabel
            key={specificity}
            control={
              <Checkbox
                color="primary"
                checked={currentSpecificities.includes(specificity)}
                value={specificity}
                onChange={handleChangeSpecificity(specificity)}
              />
            }
            label={i18n.fareSpecificity[specificity]}
          />
        ))}
      </Stack>
      <Box style={{ marginLeft: 70, marginRight: 70 }}>
        <Controller
          name={formKeys.comment}
          defaultValue=""
          render={({ value, onChange }) => (
            <TextField
              value={value}
              onChange={(event) => onChange(event.target.value)}
              fullWidth
              multiline
              rows={3}
              placeholder={i18n.addComment}
              onKeyDown={handleKeyPressed}
            />
          )}
        />
      </Box>
    </Stack>
  );
};

export default JourneyInfos;
