import { AccommodationSpecificity } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import BedSpecifitiesSelect from 'kurt/components/AccommodationDetails/BedSpecificitiesSelect';
import { FC, useState } from 'react';
import { ActionProps } from '../ActionForm';

type UpdateSpecificitiesProps = ActionProps & {
  initialSpecificities: AccommodationSpecificity[];
};
const UpdateSpecificities: FC<UpdateSpecificitiesProps> = ({
  initialSpecificities,
  loading = false,
  onSubmit,
  onCancel,
}) => {
  const [specificities, setSpecificities] = useState<AccommodationSpecificity[]>(initialSpecificities);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ specificities });
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <Typography variant="h4">{i18n.visitActions.UPDATE_SPECIFICITIES.form.question}</Typography>
        <div style={{ alignSelf: 'flex-start' }}>
          <BedSpecifitiesSelect value={specificities} onChange={setSpecificities} />
        </div>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button onClick={onCancel} variant="outlined" disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton loading={loading} type="submit" disabled={loading}>
          {i18n.visitActions.UPDATE_SPECIFICITIES.form.confirmLabel}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default UpdateSpecificities;
