import { SxProps, Theme, Typography } from '@mui/material';
import { theme } from 'common/theme';
import { ReactNode } from 'react';
import { StatisticsColor } from './Item/ItemTitle';

type TextProps = {
  children?: ReactNode;
  variant?: 'lg' | 'md' | 'sm';
  color?: StatisticsColor;
  sx?: SxProps<Theme>;
  span?: boolean;
};

const Text = ({ children, variant = 'sm', color = 'default', sx, span = false }: TextProps) => {
  return (
    <Typography
      variant="h3"
      lineHeight={variant === 'lg' ? '28px' : '16px'}
      fontSize={variant === 'lg' ? 22 : 'md' ? 14 : 12}
      fontWeight={variant === 'sm' ? 600 : 700}
      color={
        (variant === 'lg' || variant === 'md') && color === 'default'
          ? theme.palette.default.dark
          : color === 'secondary'
          ? theme.palette.text.primary
          : theme.palette[color].main
      }
      sx={sx}
      component={span ? 'span' : Typography}
    >
      {children}
    </Typography>
  );
};

export default Text;
