import { Components } from '@mui/material';
import color from '../color';
import palette from '../palette';
import transition from '../transition';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    action: true;
    rounded: true;
  }
}

const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    color: 'primary',
    disableElevation: true,
  },
  styleOverrides: {
    startIcon: {
      marginTop: -10,
      marginBottom: -10,
    },
    endIcon: {
      marginTop: -10,
      marginBottom: -10,
    },
    text: {
      '*': {
        transition,
      },
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.secondary.main,
        '& .MuiSvgIcon-root': {
          color: palette.secondary.main,
        },
      },
      '& > span.MuiTouchRipple-root': {
        display: 'none',
      },
    },
    textPrimary: {
      '*': {
        transition,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.primary.dark,
        '& .MuiSvgIcon-root': {
          color: palette.primary.dark,
        },
      },
    },
    textSecondary: {
      '*': {
        transition,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: color.grey[90],
        '& .MuiSvgIcon-root': {
          color: color.grey[90],
        },
      },
    },
    root: {
      padding: '10px 24px',
    },
    contained: {
      padding: '10px 24px',
      '&:disabled': {
        backgroundColor: palette.primary.main,
        color: '#fff',
        opacity: 0.4,
      },
    },
    outlined: {
      padding: '10px 24px',
      borderColor: palette.primary.main,
      '&:hover': {
        borderColor: palette.primary.dark,
      },
    },
    sizeSmall: {
      padding: '7px 16px',
    },
    sizeLarge: {
      padding: '15px 24px',
    },
    iconSizeSmall: {
      fontSize: 16,
      '& > *:first-of-type': {
        fontSize: 'inherit',
      },
    },
    iconSizeMedium: {
      fontSize: 16,
      '& > *:first-of-type': {
        fontSize: 'inherit',
      },
    },
    iconSizeLarge: {
      fontSize: 16,
      '& > *:first-of-type': {
        fontSize: 'inherit',
      },
    },
  },
  variants: [
    {
      props: { variant: 'action', size: 'medium' },
      style: {
        border: '1px solid #E8EAF2',
        textAlign: 'left',
        padding: 8,
        fontSize: 12,
        lineHeight: '16px',
        minHeight: 44,
        fontWeight: 400,
        boxShadow: '0px 1px 2px 0px rgba(20, 22, 28, 0.06)',
        justifyContent: 'inherit',
        '&.Mui-disabled': {
          '& > .MuiButton-startIcon': {
            transition,
            opacity: 0.4,
          },
        },
        '& > .MuiButton-startIcon': {
          margin: 0,
          marginRight: 12,
          backgroundColor: color.blue[5],
          borderRadius: 8,
          height: 28,
          width: 28,
          padding: 6,
        },
        '& > .MuiButton-endIcon': {
          flex: 1,
          justifyContent: 'flex-end',
          marginRight: 4,
        },
        '&.MuiButtonGroup-grouped': {
          boxShadow: 'none',
        },
        '&.MuiButtonGroup-groupedVertical': {
          '&:not(:first-of-type)': {
            borderTop: 'none',
          },
        },
        '&:hover': {
          '*': {
            transition,
          },
          '&.MuiButton-root:hover': {
            backgroundColor: color.blue[5],
          },
          '.MuiButton-startIcon': {
            backgroundColor: 'white',
          },
        },
      },
    },
    {
      props: { variant: 'action', color: 'primary' },
      style: {
        color: palette.secondary.dark,
        '&:not(.Mui-disabled) > .MuiButton-endIcon': {
          color: palette.secondary.main,
        },
      },
    },
    { props: { variant: 'action', color: 'secondary' }, style: { color: palette.secondary.main } },
    { props: { variant: 'action', color: 'warning' }, style: { color: palette.warning.main } },
    { props: { variant: 'action', color: 'error' }, style: { color: palette.error.main } },
    { props: { variant: 'action', color: 'success' }, style: { color: palette.success.main } },
    { props: { variant: 'action', color: 'info' }, style: { color: palette.info.main } },
    {
      props: { variant: 'rounded' },
      style: {
        borderRadius: '24px',
        padding: '6px 12px',
        fontWeight: 400,
        '& > .MuiButton-startIcon': {
          margin: 0,
          marginRight: 8,
          fontSize: 12,
        },
      },
    },
    {
      props: { variant: 'rounded', size: 'small' },
      style: {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
    {
      props: { variant: 'rounded', color: 'primary' },
      style: {
        color: palette.primary.main,
        backgroundColor: palette.primary.contrastText,
      },
    },
    {
      props: { variant: 'rounded', color: 'secondary' },
      style: {
        color: palette.secondary.main,
        backgroundColor: palette.secondary.contrastText,
      },
    },
    {
      props: { variant: 'rounded', color: 'warning' },
      style: {
        color: palette.warning.main,
        backgroundColor: palette.warning.contrastText,
      },
    },
    {
      props: { variant: 'rounded', color: 'error' },
      style: {
        color: palette.error.main,
        backgroundColor: palette.error.contrastText,
      },
    },
    {
      props: { variant: 'rounded', color: 'success' },
      style: {
        color: palette.success.main,
        backgroundColor: palette.success.contrastText,
      },
    },
    {
      props: { variant: 'rounded', color: 'info' },
      style: {
        color: palette.info.main,
        backgroundColor: palette.info.contrastText,
      },
    },
  ],
};

export default MuiButton;
