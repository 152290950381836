interface RocketChatWidget {
  maximizeWidget: () => void;
}

declare global {
  interface Window {
    RocketChat: (callback: (this: RocketChatWidget) => void) => void;
  }
}

export const rocketChatHeight = 86;

export function openRocketChat() {
  window.RocketChat(function () {
    this.maximizeWidget();
  });
}
