import { Button, Stack, Typography } from '@mui/material';
import ChevronRightIcon from 'common/components/Icons/ChevronRight';

import { color } from 'common/theme';

export type SwitcherMenuOption = {
  label: string;
  type?: string;
};

export type SwitcherMenuProps = {
  options: SwitcherMenuOption[];
  onChange: (value: SwitcherMenuOption) => void;
  selected?: string;
};

const SwitcherMenu = ({ options, onChange, selected = options[0]?.label }: SwitcherMenuProps) => {
  const handleChange = (selectedOption: SwitcherMenuOption) => {
    onChange(selectedOption);
  };

  return (
    <Stack direction="row" gap={3} divider={<ChevronRightIcon sx={{ color: color.grey[30] }} />} alignItems="center">
      {options.map((option) => (
        <Button key={option.label} variant="text" onClick={() => handleChange(option)}>
          <Typography color={selected === option.label ? color.grey[100] : color.grey[40]} variant="h4">
            {option.label}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
};

export default SwitcherMenu;
