import { Box, CircularProgress, IconButton, Stack, TextFieldProps } from '@mui/material';
import { DatePicker, PickersDay, PickersDayProps, TimePicker } from '@mui/x-date-pickers';
import { Delete } from 'common/components/Icons';
import { color, theme } from 'common/theme';
import { isAfter, isSameDay, isValid, isWithinInterval, setHours, setMinutes, startOfMinute } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

type DateTimeInputProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  value?: Date | null;
  onChange: (value: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  defaultTime?: { hour: number; minute: number };
  noRange?: boolean;
  loading?: boolean;
  clearButton?: boolean;
};

type DayContainerProps = {
  $dayIsBetween?: boolean;
  $isFirstDay?: boolean;
  $isLastDay?: boolean;
};

type CustomPickerDayProps = PickersDayProps<Date> & DayContainerProps;

const CustomPickersDay = styled(PickersDay)<CustomPickerDayProps>`
  border-radius: ${({ $dayIsBetween, $isLastDay, $isFirstDay }) =>
    $isFirstDay || $isLastDay ? '50%' : $dayIsBetween ? 0 : undefined};
  background-color: ${({ $dayIsBetween, $isFirstDay, $isLastDay }) =>
    $isFirstDay || $isLastDay ? theme.palette.primary.main : $dayIsBetween ? color.blue[20] : undefined} !important;
  color: ${({ $isFirstDay, $isLastDay }) => ($isFirstDay || $isLastDay ? 'white' : undefined)};
  &:hover {
    background-color: ${color.blue[30]};
  }
` as React.ComponentType<CustomPickerDayProps>;

const DayContainer = styled.div<DayContainerProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  border-top-left-radius: ${({ $isFirstDay }) => ($isFirstDay ? '50%' : undefined)};
  border-bottom-left-radius: ${({ $isFirstDay }) => ($isFirstDay ? '50%' : undefined)};
  border-top-right-radius: ${({ $isLastDay }) => ($isLastDay ? '50%' : undefined)};
  border-bottom-right-radius: ${({ $isLastDay }) => ($isLastDay ? '50%' : undefined)};
  background-color: ${color.blue[20]};
`;

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  disabled = false,
  loading = false,
  value,
  onChange,
  minDate,
  maxDate,
  noRange,
  defaultTime = { hour: 0, minute: 0 },
  error = false,
  size = 'medium',
  variant = 'outlined',
  clearButton = true,
}) => {
  const renderPickerDay = ({ day, selected, outsideCurrentMonth, ...other }: PickersDayProps<Date>) => {
    const start = minDate || value;
    const end = maxDate || value;
    const dayIsBetween = !noRange
      ? start && end && isAfter(end, start)
        ? isWithinInterval(day, { start, end })
        : false
      : false;
    const isFirstDay = !noRange ? (minDate ? isSameDay(day, minDate) : selected || false) : false;
    const isLastDay = !noRange ? (maxDate ? isSameDay(day, maxDate) : selected || false) : false;

    return (
      <Box position="relative">
        {value && (isLastDay || isFirstDay) && !outsideCurrentMonth && (
          <DayContainer $isFirstDay={isFirstDay} $isLastDay={isLastDay} />
        )}

        <CustomPickersDay
          day={day}
          selected={selected}
          outsideCurrentMonth={outsideCurrentMonth}
          disableMargin
          $dayIsBetween={dayIsBetween}
          $isFirstDay={isFirstDay}
          $isLastDay={isLastDay}
          {...other}
        />
      </Box>
    );
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <DatePicker
        disabled={disabled || loading}
        value={value || null}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            error,
            size,
            variant,
          },
        }}
        onChange={(date) => {
          if (date && isValid(date)) {
            if (value === undefined) {
              date = setHours(setMinutes(date, defaultTime.minute), defaultTime.hour);
            }
            onChange(startOfMinute(date));
          }
        }}
        slots={{
          day: noRange ? undefined : (props) => renderPickerDay(props),
        }}
      />

      <TimePicker
        disabled={disabled || loading}
        value={value || null}
        onChange={(value) => {
          if (value && isValid(value)) {
            onChange(value);
          }
        }}
        slotProps={{
          textField: {
            error,
            size,
            variant,
          },
        }}
      />

      {loading ? (
        <CircularProgress size={16} />
      ) : (
        value &&
        clearButton && (
          <IconButton onClick={() => onChange(null)}>
            <Delete />
          </IconButton>
        )
      )}
    </Stack>
  );
};

export default DateTimeInput;
