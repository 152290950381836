import { SvgIcon, SvgIconProps } from '@mui/material';

const Manager = ({ fill = 'white', ...props }: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M7.83943 3.58972C8.82782 3.58972 9.62908 2.78613 9.62908 1.79486C9.62908 0.803585 8.82782 0 7.83943 0C6.85103 0 6.04978 0.803585 6.04978 1.79486C6.04978 2.78613 6.85103 3.58972 7.83943 3.58972Z"
        fill={fill}
      />
      <path
        d="M15.2483 15.1975C15.2483 15.1975 15.2409 15.1953 15.2367 15.1953H14.982C14.927 15.1953 14.8826 15.1561 14.8826 15.1073V8.59369C14.8826 8.37212 14.6796 8.19189 14.4291 8.19189H14.094L14.0866 8.19083L1.71534 8.19401L1.26185 8.19295C1.01132 8.19295 0.808361 8.37318 0.808361 8.59475V15.1975H0.454237L0.31893 15.2218C0.214279 15.2409 0.118084 15.2961 0.0662863 15.3787C-0.123989 15.6873 0.123369 16 0.45318 16H15.2367C15.2367 16 15.2441 15.9979 15.2483 15.9979C15.4883 15.9873 15.6786 15.8134 15.6786 15.5982C15.6786 15.383 15.4872 15.2081 15.2483 15.1975ZM10.0913 14.1034C10.0913 14.2603 9.96441 14.3864 9.80902 14.3864H5.87031C5.71386 14.3864 5.58807 14.2592 5.58807 14.1034V10.1532C5.58807 9.99629 5.71492 9.87013 5.87031 9.87013H9.80902C9.96546 9.87013 10.0913 9.99735 10.0913 10.1532V14.1034Z"
        fill={fill}
      />
      <path
        d="M8.34142 10.837C8.34142 10.713 8.241 10.6133 8.11838 10.6133H7.55707C7.43339 10.6133 7.33402 10.714 7.33402 10.837V11.6236H6.54966C6.42598 11.6236 6.32662 11.7244 6.32662 11.8473V12.4103C6.32662 12.5343 6.42704 12.634 6.54966 12.634H7.33402V13.4206C7.33402 13.5447 7.43444 13.6443 7.55707 13.6443H8.11838C8.24206 13.6443 8.34142 13.5436 8.34142 13.4206V12.634H9.09407C9.21775 12.634 9.31712 12.5333 9.31712 12.4103V11.8473C9.31712 11.7233 9.21669 11.6236 9.09407 11.6236H8.34142V10.837Z"
        fill={fill}
      />
      <path
        d="M7.83981 4.46648C6.1601 4.46648 4.70449 5.43229 3.99624 6.84019C3.9381 6.9568 4.02267 7.09357 4.15269 7.09357H11.5269C11.6569 7.09357 11.7415 6.95574 11.6834 6.84019C10.9751 5.43229 9.51952 4.46648 7.83981 4.46648Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default Manager;
