import { Hl7AdtMessage } from '@ambuliz/sabri-core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ListPagination } from 'common/components';
import { usePagination } from 'common/hooks';
import { i18n } from 'common/locale';
import { format } from 'date-fns';
import { useMemo } from 'react';

const Hl7AdtMessageTable = ({ hl7Messages }: { hl7Messages: Hl7AdtMessage[] }) => {
  const { page, changePage, rowsPerPage, changeRowsPerPage } = usePagination(hl7Messages.length || 0);
  const paginatedRows = useMemo(
    () => hl7Messages?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [hl7Messages, page, rowsPerPage]
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{i18n.patientHl7.adtTable.createdAt}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.msgId}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.eventType}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.originalEventType}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.ipp}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.pan}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.patientClass}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.startMovementTime}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.movementId}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.responsibleUnit}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.unit}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.room}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.bed}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.admittedAt}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.dischargedAt}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.expectedAdmissionDate}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.expectedDischargeDate}</TableCell>
            <TableCell>{i18n.patientHl7.adtTable.hospitalizationReason}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((hl7Message) => (
            <TableRow key={hl7Message.msgId}>
              <TableCell>
                <Typography variant="body2">{format(hl7Message.createdAt, 'dd/MM/yy - HH:mm')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.msgId}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.eventType}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.originalEventType || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.ipp}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.pan || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.patientClass}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {hl7Message.startMovementTime ? format(hl7Message.startMovementTime, 'dd/MM/yy - HH:mm') : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.movementId || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.responsibleUnit || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.unit || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.room || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.bed || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {hl7Message.admittedAt ? format(hl7Message.admittedAt, 'dd/MM/yy - HH:mm') : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {hl7Message.dischargedAt ? format(hl7Message.dischargedAt, 'dd/MM/yy - HH:mm') : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {hl7Message.expectedAdmittedAt ? format(hl7Message.expectedAdmittedAt, 'dd/MM/yy - HH:mm') : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {hl7Message.expectedDischargedAt ? format(hl7Message.expectedDischargedAt, 'dd/MM/yy - HH:mm') : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.hospitalizationReason || '-'}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ListPagination
        count={hl7Messages.length}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={i18n.accommodationsByPage}
      />
    </TableContainer>
  );
};

export default Hl7AdtMessageTable;
