import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Button, Collapse, MenuItem, Stack, Typography } from '@mui/material';
import BedPlacement from 'common/components/BedPlacement/BedPlacement';
import { FilterSelect } from 'common/components/Form';
import { ChevronSmallRight } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { useLocations } from 'core/locations';
import { useBedOptions } from 'core/locations/useBedOptions';
import { FC, useState } from 'react';
import { AccommodationAction } from '..';
import { ActionProps } from '../ActionForm';

type UpdateBedProps = ActionProps & {
  initialBedId?: string;
  initialUnitId: string;
  action: AccommodationAction;
  start?: Date;
  end?: Date;
  patient?: {
    name?: string;
    birthdate?: Date;
    gender?: PatientGender;
  };
  specificities?: AccommodationSpecificity[];
};

const UpdateBed: FC<UpdateBedProps> = ({
  initialBedId,
  initialUnitId,
  action,
  loading = false,
  start,
  end,
  patient,
  specificities,
  onCancel,
  onSubmit,
}) => {
  const { units } = useLocations();
  const [bedId, setBedId] = useState(initialBedId);
  const [unitId, setUnitId] = useState(initialUnitId);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const unitOptions = units.map((unit) => ({ value: unit.id, label: unit.name }));
  const { bedOptions, isLoading: areBedsLoading } = useBedOptions(unitId);

  const handleSubmit = async () => {
    if (!bedId) {
      setError(true);
    } else {
      onSubmit({ bedId, unitId });
    }
  };

  const handleBedChange = (id: string) => {
    setError(false);
    setBedId(id);
  };

  const handleUnitChange = (id: string) => {
    setError(false);
    setUnitId(id);
  };

  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h4">{i18n.visitActions[action].form.question}</Typography>

        {start && patient && patient.gender && (
          <BedPlacement
            unitId={initialUnitId}
            start={start}
            end={end}
            gender={patient.gender}
            birthdate={patient.birthdate}
            name={patient.name}
            value={bedId}
            onSelect={handleBedChange}
            specificities={specificities}
          />
        )}
        <Stack
          direction="row"
          spacing={1}
          onClick={() => setExpanded(!expanded)}
          alignItems="center"
          style={{ cursor: 'pointer' }}
          marginY={2}
        >
          <ChevronSmallRight style={{ transform: expanded ? 'rotate(90deg)' : undefined }} color="secondary" />
          <Stack direction="row" spacing={2}>
            <Typography variant="body1" color="secondary">
              {i18n.chooseMyBed}
            </Typography>
            {error && !expanded && (
              <Typography variant="body2" color="error">
                {i18n.addAccommodationModal.errors.bed}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Collapse in={expanded} style={{ marginTop: 16, marginBottom: 0 }}>
          <Stack direction="row" spacing={2}>
            <FilterSelect searchable label={i18n.unit} value={unitId} onChange={handleUnitChange}>
              {unitOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FilterSelect>
            <Stack spacing={1}>
              <Stack width="max-content">
                <FilterSelect
                  searchable
                  label={i18n.bedNumber}
                  value={bedId || ''}
                  onChange={handleBedChange}
                  error={!!error}
                  loading={areBedsLoading}
                >
                  {bedOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FilterSelect>
              </Stack>
              {error && (
                <Typography variant="body2" color="error">
                  {i18n.addAccommodationModal.errors.bed}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Collapse>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} paddingTop={6}>
        <Button variant="outlined" onClick={onCancel} disabled={loading}>
          {i18n.cancel}
        </Button>
        <LoadingButton onClick={handleSubmit} loading={loading} disabled={loading}>
          {i18n.save}
        </LoadingButton>
      </Stack>
    </>
  );
};

export default UpdateBed;
