import { SvgIcon, SvgIconProps } from '@mui/material';

const Consultation: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.23077 2.76148C1.23077 2.57122 1.31015 2.40488 1.44022 2.29299C1.54116 2.58797 1.82081 2.80005 2.14999 2.80005C2.56421 2.80005 2.89999 2.46426 2.89999 2.05005V1.05005C2.89999 0.635835 2.56421 0.300049 2.14999 0.300049C1.75191 0.300049 1.42627 0.61019 1.40151 1.00203C0.473116 1.04997 0 1.94078 0 2.76148V8.53071L0.153846 8.70363C0.153846 8.70363 1.60308 10.4009 3.69231 10.7036V11.9922C3.69231 14.023 5.35385 15.6846 7.38462 15.6846C9.41539 15.6846 11.0769 14.023 11.0769 11.9922V8.91532C11.0769 8.22794 11.6203 7.68455 12.3077 7.68455C12.9951 7.68455 13.5385 8.22794 13.5385 8.91532V9.03102C12.8271 9.28763 12.3077 9.96579 12.3077 10.7615C12.3077 11.7738 13.1415 12.6076 14.1538 12.6076C15.1662 12.6076 16 11.7738 16 10.7615C16 9.96579 15.4806 9.28763 14.7692 9.0304V8.91532C14.7692 7.56148 13.6615 6.45378 12.3077 6.45378C10.9538 6.45378 9.84615 7.56148 9.84615 8.91532V11.9922C9.84615 13.3578 8.75015 14.4538 7.38462 14.4538C6.01908 14.4538 4.92308 13.3578 4.92308 11.9922V10.7036C7.01231 10.4009 8.46154 8.70363 8.46154 8.70363L8.61539 8.53071V2.76148C8.61539 1.93838 8.02967 1.04475 7.19767 0.99049C7.16731 0.60412 6.84416 0.300049 6.45 0.300049C6.03578 0.300049 5.7 0.635835 5.7 1.05005V2.05005C5.7 2.46426 6.03578 2.80005 6.45 2.80005C6.78292 2.80005 7.06517 2.58313 7.16314 2.28292C7.30033 2.39477 7.38462 2.56542 7.38462 2.76148V8.03102C7.16308 8.2544 5.85354 9.53071 4.30769 9.53071C2.76185 9.53071 1.45231 8.2544 1.23077 8.0304V2.76148ZM14.1538 10.1461C14.5003 10.1461 14.7692 10.4156 14.7692 10.7615C14.7692 11.1079 14.4997 11.3769 14.1538 11.3769C13.8074 11.3769 13.5385 11.1073 13.5385 10.7615C13.5385 10.415 13.808 10.1461 14.1538 10.1461Z"
      />
    </SvgIcon>
  );
};

export default Consultation;
