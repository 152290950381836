import { Patient } from '@ambuliz/sabri-core';
import { PayloadAction } from '@reduxjs/toolkit';
import { remove } from 'diacritics';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { requestNames, requests } from '../../const/requests';
import { EntityNames, entityNameToSchema } from '../../const/schemas';
import { cleanNormalize } from '../../utils/cleanNormalize';
import { GetPatientsByNamePayload, getPatientsByNameType } from '../actions/patient';
import { entityActions } from '../reducers/entity';
import { requestStatusActions } from '../reducers/requestStatus';

function* getPatientsByName({ payload: { name } }: PayloadAction<GetPatientsByNamePayload>) {
  try {
    yield put(requestStatusActions.setRequestStatus({ key: requestNames.getPatientsByName, status: 'LOADING' }));
    yield delay(500);
    const valueFormated = remove(name).trim().toLocaleLowerCase();
    //@ts-ignore
    const patients = yield call(requests.getPatientsByName, { name: valueFormated });
    const patientsToJSON = patients.map((patient: Patient) => patient.toJSON());
    const { entities } = cleanNormalize(patientsToJSON, [entityNameToSchema[EntityNames.patients]]);
    yield all([
      put(entityActions.setEntities(entities)),
      put(requestStatusActions.setRequestStatus({ key: requestNames.getPatientsByName, status: 'SUCCESS' })),
    ]);
  } catch {
    yield put(requestStatusActions.setRequestStatus({ key: requestNames.getPatientsByName, status: 'FAILED' }));
  }
}

export function* patientRootSaga() {
  yield takeLatest(getPatientsByNameType, getPatientsByName);
}
