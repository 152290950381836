import { TextField, Typography } from '@mui/material';
import SearchIcon from 'common/components/Icons/Search';
import React from 'react';
import { i18n } from '../../locales';

type SearchInputProps = {
  value: string;
  onValueChanged: (value: string) => void;
  resultsCount?: number;
};

const SearchInput: React.FC<SearchInputProps> = ({ value = '', onValueChanged, resultsCount = 0 }) => {
  return (
    <TextField
      value={value}
      onChange={(event) => onValueChanged(event.target.value)}
      InputProps={{
        startAdornment: <SearchIcon />,

        endAdornment:
          value !== '' ? <Typography color="textSecondary">{i18n.searchResultsCount(resultsCount)}</Typography> : null,
      }}
    />
  );
};

export default SearchInput;
