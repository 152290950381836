import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Notification from 'react-web-notification';
import { routeNamesCommon } from '../../const/routeNames';
import icon from '../../images/icons/favicon.png';
import { notificationActions } from '../../store/reducers/notification';
import { selectNotification } from '../../store/selectors/notification';

export default function BrowserNotification() {
  const notification = useSelector(selectNotification);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNotificationOnClick = (e: any) => {
    e.currentTarget.close();
    dispatch(notificationActions.hideNotification());
    navigate(notification.redirection || routeNamesCommon.fareList);
    window.focus();
  };

  const handleNotificationOnClose = () => {
    dispatch(notificationActions.hideNotification());
  };

  if (!notification.visible) {
    return null;
  }

  return (
    <Notification
      onClick={handleNotificationOnClick}
      onClose={handleNotificationOnClose}
      title={notification.title || ''}
      timeout={0}
      options={{
        body: notification.body,
        requireInteraction: true,
        icon,
      }}
    />
  );
}
