import {
  NewFareIncidentNotificationData,
  NewPerformerRequestNotificationData,
  PerformerRequestAcceptedNotificationData,
  PerformerRequestRejectedNotificationData,
  PorterEndOfServiceData,
  PorterStartOfServiceData,
} from '@ambuliz/sabri-core';
import { Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { formatFirstname } from 'common/utils';
import { Notification } from 'core/layout';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { InlinePatientNameWithEmoji } from '../PatientNameWithEmoji';

export const NotificationCardContent = ({ notification }: { notification: Notification }) => {
  let data;
  switch (notification.triggerEvent) {
    case 'NEW_FARE_INCIDENT':
      data = notification.data as NewFareIncidentNotificationData;
      const author = data.author.name
        ? data.author.name
        : i18n.notificationContent[notification.triggerEvent].regulation;
      const content = [
        i18n.notificationContent[notification.triggerEvent].newIncidentCreatedBy,
        author,
        i18n.notificationContent[notification.triggerEvent].onPatientFare,
      ];
      return (
        <>
          {content.join(' ')}
          <InlinePatientNameWithEmoji
            firstName={data.patient.firstName}
            lastName={data.patient.lastName}
            gender={data.patient.gender}
            age={data.patient.age}
          />
        </>
      );

    case 'PORTER_START_OF_SERVICE':
    case 'PORTER_END_OF_SERVICE':
      data = notification.data as PorterStartOfServiceData | PorterEndOfServiceData;
      return (
        <>
          <Typography component="span" fontWeight={600}>
            {formatFirstname(data.porter.firstName)} {data.porter.lastName}
          </Typography>{' '}
          {i18n.notificationContent[notification.triggerEvent]}
        </>
      );

    case 'NEW_PERFORMER_REQUEST':
      data = notification.data as NewPerformerRequestNotificationData;
      return (
        <>
          {i18n.notificationContent[notification.triggerEvent].requestForPatient + ' '}
          <InlinePatientNameWithEmoji
            firstName={data.patient.firstName}
            lastName={data.patient.lastName}
            gender={data.patient.gender}
            age={data.patient.age}
          />
          {i18n.notificationContent[notification.triggerEvent].fromUnit + ' '}
          <Typography component="span" fontWeight={600}>
            {data.fromUnit.name}
          </Typography>
        </>
      );
    case 'PERFORMER_REQUEST_ACCEPTED':
      data = notification.data as PerformerRequestAcceptedNotificationData;
      return (
        <>
          {i18n.notificationContent[notification.triggerEvent].mutationOf + ' '}
          <InlinePatientNameWithEmoji
            firstName={data.patient.firstName}
            lastName={data.patient.lastName}
            gender={data.patient.gender}
            age={data.patient.age}
          />
          {' ' + i18n.notificationContent[notification.triggerEvent].toUnit + ' '}
          <Typography component="span" fontWeight={600}>
            {data.toUnit.name}
          </Typography>
          {' ' + i18n.notificationContent[notification.triggerEvent].wasValidatedFor + ' '}
          <Typography component="span" fontWeight={600}>
            {format(data.date, 'eeee PPPp', { locale: fr })}.
          </Typography>
        </>
      );

    case 'PERFORMER_REQUEST_REJECTED':
      data = notification.data as PerformerRequestRejectedNotificationData;
      return (
        <>
          {i18n.notificationContent[notification.triggerEvent].mutationOf + ' '}
          <InlinePatientNameWithEmoji
            firstName={data.patient.firstName}
            lastName={data.patient.lastName}
            gender={data.patient.gender}
            age={data.patient.age}
          />
          {i18n.notificationContent[notification.triggerEvent].fromUnit + ' '}
          <Typography component="span" fontWeight={600}>
            {data.fromUnit.name}
          </Typography>

          {' ' + i18n.notificationContent[notification.triggerEvent].wasRejected + ' '}
          <Typography component="span" fontWeight={600}>
            {data.toUnit.name}
          </Typography>
        </>
      );

    default:
      throw new Error(`Unknown triggerEvent ${notification.triggerEvent}`);
  }
};
export default NotificationCardContent;
