"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translations = void 0;
exports.translations = {
    patientRequired: 'Veuillez sélectionner un patient',
    unitRequired: 'Veuillez sélectionner une unité',
    dateRequired: 'Veuillez sélectionner une date et une heure ou définir la mission comme "Urgente"',
    positionRequired: 'Veuillez sélectionner une position pour le transport',
    atLeastOneProperty: 'Au moins une propriété doit être préciser',
    fareIdRequired: 'Veuillez sélectionner un identifiant de mission',
    porterIdRequired: 'Veuillez sélectionner un identifiant du brancardier',
    unitIdRequired: "Veuillez sélectionner l'identifiants de l'unitée",
    breakTypeRequired: 'Veuillez sélectionner le type de pause',
    endAtMustBeAfterStartAt: 'La date de fin doit être après la date de début',
};
