import { Search } from '@mui/icons-material';
import { Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { color } from 'common/theme';
import React, { useState } from 'react';
import { i18n } from '../../locales';
import { FilterItem } from '../FiltersController/FiltersController';
import { SelectorController } from '../SelectorController/SelectorController';

type SelectorCheckboxProps = {
  title: string;
  items: FilterItem[];
  onSubmit: (items: FilterItem[]) => void;
  onClose: () => void;
  searchField?: boolean;
};

export const SelectorCheckbox: React.FC<SelectorCheckboxProps> = ({ title, items, onSubmit, onClose, searchField }) => {
  const [filterSearch, setFilterSearch] = useState('');
  const itemsFiltered = items.filter((item) => item.label.toLowerCase().indexOf(filterSearch.toLowerCase()) >= 0);
  const classes = useClasses();

  const renderSelectorCheckbox = ({
    itemsSelected,
    setItemsSelected,
    setIsSelectorVisible,
  }: {
    itemsSelected: FilterItem[];
    setIsSelectorVisible: (value: boolean) => void;
    setItemsSelected: React.Dispatch<React.SetStateAction<FilterItem[]>>;
  }) => {
    const renderSearchField = () => {
      if (searchField) {
        return (
          <TextField
            placeholder={i18n.search}
            onChange={(e) => setFilterSearch(e.target.value)}
            InputProps={{
              className: classes.textField,
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        );
      }
    };

    const handleChange = (item: FilterItem) => {
      if (itemsSelected.includes(item)) {
        setItemsSelected((prevItems: FilterItem[]) => prevItems.filter((prevItem) => prevItem !== item));
        return;
      }
      setItemsSelected((prevItems: FilterItem[]) => [...prevItems, item]);
    };

    const handleClickSubmit = () => {
      setIsSelectorVisible(false);
      setFilterSearch('');
      onSubmit(itemsSelected);
    };

    return (
      <>
        {renderSearchField()}
        <div className={classes.selectorContainer}>
          {itemsFiltered.map((item) => (
            <FormControlLabel
              className={classes.item}
              key={item.id}
              control={
                <Checkbox
                  color="primary"
                  checked={itemsSelected.findIndex((itemSelected) => itemSelected.id === item.id) > -1}
                  value={item}
                  onChange={() => handleChange(item)}
                />
              }
              label={item.label}
            />
          ))}
        </div>
        {itemsSelected.length > 0 && (
          <div className={classes.button} onClick={handleClickSubmit}>
            {i18n.submit}
          </div>
        )}
      </>
    );
  };

  return (
    <SelectorController title={title} onClose={onClose}>
      {renderSelectorCheckbox}
    </SelectorController>
  );
};

const useClasses = makeStyles({
  selectorContainer: {
    maxHeight: 200,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    paddingLeft: 8,
    paddingRight: 15,
    marginRight: 0,
    marginLeft: 0,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: color.grey[5],
    },
  },
  button: {
    width: '100%',
    color: '#1A73E8',
    fontSize: 14,
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: '1px solid #EEEEEE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textField: {
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  searchIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
});
