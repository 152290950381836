import { Box, Paper, Stack, SvgIconProps } from '@mui/material';

import { color } from 'common/theme';
import { ReactNode } from 'react';
import styled from 'styled-components';

type TicketProp = {
  Icon: React.FC<SvgIconProps>;
  departure: ReactNode;
  arrival: ReactNode;
  additionalInfo: ReactNode;
};

const Ticket = ({ Icon, departure, arrival, additionalInfo }: TicketProp) => {
  return (
    <Paper>
      <Stack direction="row" divider={<VerticalDivider />}>
        <Stack direction="row" flexGrow={1}>
          <Stack
            width={32}
            bgcolor="background.default"
            borderRadius="6px"
            margin={1}
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="secondary" />
          </Stack>
          <Stack divider={<HorizontalDivider />} flexGrow={1}>
            <Box padding={4}>{departure}</Box>
            <Box padding={4}>{arrival}</Box>
          </Stack>
        </Stack>
        <Stack paddingX={4} flexShrink={0}>
          {additionalInfo}
        </Stack>
      </Stack>
    </Paper>
  );
};

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-image: ${() => `linear-gradient(to right, ${color.grey[10]} 50%, #fff 0%)`};
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  background-image: ${() => `linear-gradient(to bottom, ${color.grey[10]} 50%, #fff 0%)`};
  background-position: top;
  background-size: 1px 8px;
  background-repeat: repeat-y;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    left: 4px;
  }
  &:before {
    width: 8px;
    border-radius: 0px 8px 8px 0px;
    border-right: 1px solid ${color.grey[10]};
    border-top: 1px solid ${color.grey[10]};
    border-bottom: 1px solid ${color.grey[10]};
    box-shadow: inset rgb(20 22 28 / 5%) -3px 0px 4px, rgb(255 255 255) -4px 0px 4px;
    box-sizing: border-box;
    top: -4px;
    transform: translate3D(-8px, 0, 0) rotate(90deg);
  }
  &:after {
    width: 8px;
    bottom: -4px;
    border-radius: 8px 0px 0px 8px;
    border-left: 1px solid ${color.grey[10]};
    border-top: 1px solid ${color.grey[10]};
    border-bottom: 1px solid ${color.grey[10]};
    box-shadow: inset rgb(20 22 28 / 5%) 3px 0px 4px, rgb(255 255 255) 4px 0px 4px;
    box-sizing: border-box;
    transform: translate3D(-8px, 0, 0) rotate(90deg);
  }
`;

export default Ticket;
