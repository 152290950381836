import { AccommodationStatus, RequestStatus } from '@ambuliz/sabri-core';
import { Chip } from '@mui/material';
import DotIcon from 'common/components/Icons/Dot';
import { i18n } from 'common/locale';

type StepStatus = 'IN_PROGRESS' | 'COMPLETED' | 'PENDING' | 'BLOCKED' | 'ACCORD' | 'BED_ASSIGNED';

type MutationStatusChipProps = {
  status: RequestStatus;
  accommodationStatus?: AccommodationStatus;
  hasBed?: boolean;
};

const getStatus = (status: RequestStatus, accommodationStatus?: AccommodationStatus, hasBed?: boolean): StepStatus => {
  if (status === 'ACCEPTED' && accommodationStatus && ['IN_PROGRESS', 'COMPLETED'].includes(accommodationStatus)) {
    return 'COMPLETED';
  }
  if (status === 'ACCEPTED' && accommodationStatus === 'PLANNED' && hasBed) {
    return 'BED_ASSIGNED';
  }
  if (status === 'ACCEPTED' && accommodationStatus === 'PLANNED') {
    return 'ACCORD';
  }
  if (status === 'REQUESTED') {
    return 'PENDING';
  }
  if (status === 'REJECTED') {
    return 'BLOCKED';
  }
  return 'IN_PROGRESS';
};

const getColor = (status: StepStatus) => {
  switch (status) {
    case 'PENDING':
      return 'danger';
    case 'ACCORD':
      return 'warning';
    case 'BLOCKED':
      return 'error';
    case 'COMPLETED':
      return 'secondary';
    default:
      return 'success';
  }
};

const MutationStatusChip = ({ status: requestStatus, accommodationStatus, hasBed }: MutationStatusChipProps) => {
  const status = getStatus(requestStatus, accommodationStatus, hasBed);

  return <Chip label={i18n.mutationStepStatus[status]} color={getColor(status)} variant="light" icon={<DotIcon />} />;
};

export default MutationStatusChip;
