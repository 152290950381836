import { formatName } from 'common/utils';
import { useAuthentication } from 'core/authentication';
import { useEffect, useState } from 'react';
import { requests } from 'sabri/const/requests';
import { PatientSearchResult } from './PatientAutocomplete';

interface PatientSearchOptions {
  unitId?: string;
}

const usePatientSearch = (search: string, options?: PatientSearchOptions) => {
  const [results, setResults] = useState<PatientSearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const { healthCenter } = useAuthentication();

  useEffect(() => {
    let debounceTimeout: NodeJS.Timeout;
    if (!search) {
      setLoading(false);
      setResults([]);
    } else {
      setLoading(true);
      debounceTimeout = setTimeout(async () => {
        try {
          const patients = await requests.getPatientsByName({
            name: search,
            unitId: options?.unitId,
            healthCenterId: healthCenter?.id,
          });
          const results: PatientSearchResult[] = patients.map((patient) => ({
            id: patient.id,
            name: formatName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName),
            gender: patient.gender,
            birthdate: patient.birthdate,
            ipp: patient.ipp,
            pan: patient.pan,
            ins: patient.ins,
            unit: patient.unit
              ? {
                  id: patient.unit.id,
                  name: patient.unit.name,
                }
              : undefined,
            area: patient.area
              ? {
                  id: patient.area.id,
                  name: patient.area.name,
                }
              : undefined,
          }));

          setResults(results);
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      }, 300);
    }

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [search, options?.unitId, healthCenter?.id]);

  return {
    loading,
    results,
  };
};

export default usePatientSearch;
