import { ExternalPatientTransport } from '@ambuliz/sabri-core';
import {
  Box,
  Chip,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ExternalPatientTransportModal, PatientNameWithEmoji, useExternalTransportModal } from 'common/components';
import { Comment, MoreVertical } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { formatName, getPatientAge } from 'common/utils';
import { format } from 'date-fns';
import { useRef, useState } from 'react';
import ExternalTransportStatusChip from 'superadmin/pages/ExternalTransport/ExternalTransportStatusChip';

type ExternalTransportListItemProps = ExternalPatientTransport & { small: boolean };

const ExternalTransportListItem = ({
  externalId,
  status,
  endAt,
  startAt,
  patient,
  comment,
  farePosition,
  small,
}: ExternalTransportListItemProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { edit, close, viewDetails, src } = useExternalTransportModal();
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <TableRow
        onClick={(event) => {
          event.preventDefault();
          viewDetails(externalId);
        }}
        hover
        sx={{
          cursor: 'pointer',
        }}
      >
        <TableCell width={14} sx={{ paddingRight: 0 }}></TableCell>
        <TableCell sx={{ paddingLeft: 1 }}>
          <Typography noWrap fontWeight={600}>
            {externalId}
          </Typography>
        </TableCell>
        <TableCell>
          <Box display="flex">
            <ExternalTransportStatusChip status={status} />
          </Box>
        </TableCell>
        {!small && (
          <TableCell>
            {patient && (
              <PatientNameWithEmoji
                gender={patient.gender}
                age={getPatientAge(patient.birthdate)}
                bgcolor={color.blue[5]}
                name={formatName(patient.firstName, patient.lastName)}
              />
            )}
          </TableCell>
        )}

        <TableCell width={80}>
          {startAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(startAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>

        <TableCell>
          {endAt ? (
            <Typography noWrap variant="body2" color="secondary" fontWeight={600}>
              {format(endAt, 'dd/MM - HH:mm')}
            </Typography>
          ) : (
            <Box width={80}>
              <Typography align="center" variant="body2" color="secondary">
                -
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {farePosition && (
            <Box display="flex">
              <Stack direction="row" sx={{ bgcolor: color.blue[5], borderRadius: 0.5, minWidth: 50 }}>
                <Chip
                  label={i18n.externalFarePosition[farePosition]}
                  color={'secondary'}
                  variant="light"
                  sx={{ flex: 1 }}
                />
              </Stack>
            </Box>
          )}
        </TableCell>
        <TableCell width={16} sx={{ paddingLeft: 2 }}>
          {comment && (
            <Tooltip title={comment}>
              <IconButton color="primary" sx={{ margin: -1 }}>
                <Comment />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell width={16} sx={{ paddingLeft: 2 }}>
          <IconButton
            sx={{ margin: -1 }}
            ref={ref}
            onClick={(event) => {
              event.stopPropagation();
              setMenuOpen(true);
            }}
          >
            <MoreVertical />
          </IconButton>
        </TableCell>
      </TableRow>
      <ExternalPatientTransportModal src={src} onClose={close} />
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={ref.current}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            edit(externalId);
          }}
          key={'edit-fare'}
        >
          Modifier
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExternalTransportListItem;
