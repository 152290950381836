import { SvgIcon, SvgIconProps } from '@mui/material';

const Phone: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <rect width="16" height="16" rx="8" />
      <path
        d="M11.2569 9.53271C11.0854 9.36109 10.9144 9.18888 10.7421 9.01803C10.4382 8.71676 10.0838 8.71618 9.78227 9.01628C9.56595 9.23154 9.34837 9.44553 9.13645 9.665C9.08052 9.72299 9.03445 9.72757 8.96397 9.69819C8.61793 9.55409 8.31152 9.34576 8.02336 9.11136C7.44422 8.64022 6.93019 8.10738 6.53134 7.47194C6.44593 7.33585 6.38375 7.18531 6.30683 7.04356C6.26729 6.97064 6.27989 6.92065 6.34021 6.86227C6.56209 6.64759 6.77908 6.42774 6.99647 6.20838C7.28111 5.92107 7.2817 5.56044 6.99588 5.27186C6.64662 4.91923 6.2957 4.56817 5.94351 4.21858C5.64813 3.92531 5.28716 3.92765 4.98963 4.22258C4.78855 4.42183 4.59771 4.63241 4.38706 4.82083C4.08046 5.09506 3.98118 5.43909 4.00285 5.83262C4.03428 6.4046 4.22405 6.93227 4.47101 7.44041C5.01989 8.56993 5.78059 9.53555 6.74199 10.3433C7.45281 10.9406 8.2264 11.4342 9.10014 11.7588C9.52271 11.9157 9.95553 12.028 10.4133 11.9938C10.6958 11.9728 10.9362 11.8666 11.1343 11.6584C11.3472 11.4348 11.5711 11.2218 11.7876 11.0016C12.0712 10.7132 12.0706 10.3525 11.7869 10.0634C11.6117 9.88495 11.4337 9.70951 11.2569 9.53271Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Phone;
