import { SvgIcon, SvgIconProps } from '@mui/material';

const Unit = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g clipPath="url(#clip0_483_4631)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4862 1.52639C11.8005 1.32991 12.1994 1.32991 12.5138 1.52639L23.5442 8.4204C23.9983 8.70417 24.1363 9.30229 23.8525 9.75633C23.5687 10.2104 22.9706 10.3484 22.5166 10.0646L12 3.49174L1.48338 10.0646C1.02935 10.3484 0.43123 10.2104 0.147457 9.75633C-0.136317 9.30229 0.00170915 8.70417 0.455747 8.4204L11.4862 1.52639ZM13.2724 17.6188H10.7276V15.0852H8.12212V12.4914H10.7276V9.89749H13.2724V12.4914H15.8779V15.0852H13.2724V17.6188ZM4.69664 13.3789C4.69664 12.8434 4.2626 12.4094 3.72717 12.4094C3.19175 12.4094 2.7577 12.8434 2.7577 13.3789V20.9426C2.7577 21.8697 3.50925 22.6212 4.43627 22.6212H19.5637C20.4908 22.6212 21.2423 21.8697 21.2423 20.9426V13.3789C21.2423 12.8434 20.8082 12.4094 20.2728 12.4094C19.7374 12.4094 19.3033 12.8434 19.3033 13.3789V20.6822H4.69664V13.3789Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_483_4631">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Unit;
