import { SvgIcon, SvgIconProps } from '@mui/material';

const Delete: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#a)">
        <path
          d="M14.667 2.28h-10c-.46 0-.82.222-1.06.559L0 7.994l3.607 5.15c.24.336.6.565 1.06.565h10c.733 0 1.333-.572 1.333-1.27v-8.89c0-.698-.6-1.269-1.333-1.269Zm0 10.159H4.713L1.6 7.994 4.707 3.55h9.96v8.889Zm-7.727-1.27 2.393-2.28 2.394 2.28.94-.895-2.394-2.28 2.394-2.279-.94-.895L9.333 7.1 6.94 4.82 6 5.715l2.393 2.28L6 10.273l.94.895Z"
          fill="#666D8E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Delete;
