import { PatientGender } from '@ambuliz/sabri-core';
import { EmojiType } from 'common/components/Emoji';

const getPatientEmojiIcon = (gender: PatientGender, age: number, outlined?: boolean): EmojiType => {
  switch (gender) {
    case 'MALE':
      return age < 18 ? `boy${outlined ? 'Outlined' : ''}` : `man${outlined ? 'Outlined' : ''}`;

    case 'FEMALE':
      return age < 18 ? `girl${outlined ? 'Outlined' : ''}` : `woman${outlined ? 'Outlined' : ''}`;

    case 'UNKNOWN':
    default:
      return age < 18 ? `kid${outlined ? 'Outlined' : ''}` : `adult${outlined ? 'Outlined' : ''}`;
  }
};

export default getPatientEmojiIcon;
