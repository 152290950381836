"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unitSpecialties = exports.unitSectors = exports.getSpecialtiesBySectors = exports.getSpecialtiesBySector = exports.getSectorsBySpecialties = exports.getSectorBySpecialty = void 0;
const sectors = [
    'Chirurgie',
    'Médecine',
    'Obstétrique',
    'Réanimation',
    'Urgences',
    'SMR',
    'PSY',
    'Pédiatrie',
    'HAD',
];
exports.unitSectors = sectors;
const specialties = [
    'Chirurgie cardiaque',
    'Chirurgie cervico-faciale',
    'Chirurgie générale',
    'Chirurgie infantile',
    'Chirurgie maxillo-faciale et stomatologie',
    'Chirurgie orthopédique et traumatologie',
    'Chirurgie plastique',
    'Chirurgie thoracique et cardio-vasculaire',
    'Chirurgie urologique',
    'Chirurgie vasculaire',
    'Chirurgie viscérale et digestive',
    'Neurochirurgie',
    'Addictologie',
    'Allergologie et immunologie',
    'Anatomie et Cytologie pathologiques',
    'Anesthésie',
    'Biologie médicale',
    'Brûlologie',
    'Cardiologie',
    'Dermatologie',
    'Endocrinologie et métabolisme',
    'Hépato-Gastro-Entérologie',
    'Génétique',
    'Gériatrie',
    'HAD',
    'Hématologie',
    'Imagerie médicale',
    'Maladies infectieuses',
    'Maladies tropicales',
    'Médecine aérospatiale-aéronautique',
    'Médecine du sport',
    'Médecine du travail',
    'Médecine générale',
    'Médecine hyperbare',
    'Médecine interne',
    'Médecine légale',
    'Médecine nucléaire',
    'Médecine pénitentiaire',
    'Médecine polyvalente',
    'Médecine vasculaire, angiologie, phlébologie',
    'Néphrologie',
    'Neurologie',
    'Neuropediatrie',
    'Oncologie',
    'Ophtalmologie',
    'ORL',
    'Pédiatrie',
    'Pneumologie',
    'Rhumatologie',
    'Santé publique et Médecine sociale',
    'Soins palliatifs',
    'Transfusion',
    'Gynécologie-obstétrique',
    'Néonatologie',
    'Maternité',
    'UHCD',
    'Urgences',
    'Médecine physique et de réadaptation adulte',
    'Médecine physique et de réadaptation enfant',
    'Soins de suite spécialisés et polyvalents',
    'Réanimation polyvalente',
    'USC - Unité de Surveillance Continue',
    'USI - Unité de Soins Intensifs',
    'Pédopsychiatrie',
    'Neuropsychiatrie',
    'Psychiatrie',
    'Court séjour gériatrique',
];
exports.unitSpecialties = specialties;
const specialtiesBySector = {
    Chirurgie: [
        'Chirurgie cardiaque',
        'Chirurgie cervico-faciale',
        'Chirurgie générale',
        'Chirurgie infantile',
        'Chirurgie maxillo-faciale et stomatologie',
        'Chirurgie orthopédique et traumatologie',
        'Chirurgie plastique',
        'Chirurgie thoracique et cardio-vasculaire',
        'Chirurgie urologique',
        'Chirurgie vasculaire',
        'Chirurgie viscérale et digestive',
        'Neurochirurgie',
    ],
    Médecine: [
        'Addictologie',
        'Allergologie et immunologie',
        'Anatomie et Cytologie pathologiques',
        'Anesthésie',
        'Biologie médicale',
        'Brûlologie',
        'Cardiologie',
        'Dermatologie',
        'Endocrinologie et métabolisme',
        'Hépato-Gastro-Entérologie',
        'Génétique',
        'Gériatrie',
        'Hématologie',
        'Imagerie médicale',
        'Maladies infectieuses',
        'Maladies tropicales',
        'Médecine aérospatiale-aéronautique',
        'Médecine du sport',
        'Médecine du travail',
        'Médecine générale',
        'Médecine hyperbare',
        'Médecine interne',
        'Médecine légale',
        'Médecine nucléaire',
        'Médecine pénitentiaire',
        'Médecine polyvalente',
        'Médecine vasculaire, angiologie, phlébologie',
        'Néphrologie',
        'Neurologie',
        'Neuropediatrie',
        'Oncologie',
        'Ophtalmologie',
        'ORL',
        'Pédiatrie',
        'Pneumologie',
        'Rhumatologie',
        'Santé publique et Médecine sociale',
        'Soins palliatifs',
        'Transfusion',
        'Court séjour gériatrique',
    ],
    Obstétrique: ['Gynécologie-obstétrique', 'Néonatologie', 'Maternité'],
    Urgences: ['UHCD', 'Urgences'],
    SMR: [
        'Médecine physique et de réadaptation adulte',
        'Médecine physique et de réadaptation enfant',
        'Soins de suite spécialisés et polyvalents',
    ],
    Réanimation: ['Réanimation polyvalente', 'USC - Unité de Surveillance Continue', 'USI - Unité de Soins Intensifs'],
    PSY: ['Pédopsychiatrie', 'Neuropsychiatrie', 'Psychiatrie'],
    Pédiatrie: [],
    HAD: ['HAD'],
};
const getSpecialtiesBySectors = (sectors) => {
    const specialties = new Set();
    sectors.forEach((sector) => {
        const sectorSpecialties = getSpecialtiesBySector(sector);
        sectorSpecialties.forEach((sectorSpecialty) => specialties.add(sectorSpecialty));
    });
    return Array.from(specialties);
};
exports.getSpecialtiesBySectors = getSpecialtiesBySectors;
const getSpecialtiesBySector = (sector) => {
    return specialtiesBySector[sector];
};
exports.getSpecialtiesBySector = getSpecialtiesBySector;
const getSectorBySpecialty = (specialty) => {
    for (const key in specialtiesBySector) {
        const sector = key;
        const sectorSpecialities = specialtiesBySector[sector];
        if (sectorSpecialities.includes(specialty)) {
            return sector;
        }
    }
    throw new Error(`No sector found for speciality ${specialty}`);
};
exports.getSectorBySpecialty = getSectorBySpecialty;
const getSectorsBySpecialties = (specialties = []) => {
    return Array.from(new Set(specialties.map(getSectorBySpecialty).filter((specialty) => specialty !== undefined)));
};
exports.getSectorsBySpecialties = getSectorsBySpecialties;
