import { Box, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import Emoji from '../Emoji';
import NotificationCardDate from './NotificationCardDate';
import NotificationCardSkeleton from './NotificationCardSkeleton';

type Severity = 'info' | 'warning' | 'error';

type NotificationCardProps = {
  loading?: boolean;
  read?: boolean;
  severity: Severity;
  content: React.ReactNode;
  date: Date;
  context: string;
  onToggleRead: () => void;
  onClick: () => void;
};

const NotificationCard: React.FC<NotificationCardProps> = ({
  read = false,
  severity = 'info',
  content,
  date,
  context,
  loading = false,
  onToggleRead,
  onClick,
}) => {
  return (
    <Paper elevation={0} sx={{ backgroundColor: palette.primary.light, padding: 4 }}>
      {loading ? (
        <NotificationCardSkeleton />
      ) : (
        <Stack spacing={3} direction="row" alignItems="center" justifyContent="space-between">
          <Stack spacing={4} direction="row" alignItems="center" onClick={onClick} sx={{ cursor: 'pointer' }}>
            {getSeverityEmoji(severity)}
            <Stack spacing={2} flex={1}>
              <Box
                sx={{
                  color: read ? palette.text.secondary : palette.text.primary,
                  transition: 'color 0.2s ease-in-out',
                }}
              >
                <Typography>{content}</Typography>
              </Box>
              <Stack
                spacing={2}
                direction="row"
                divider={<Box bgcolor={palette.secondary.main} height={4} width={4} borderRadius="50%" />}
                alignItems="center"
              >
                <NotificationCardDate date={date} />
                {context && (
                  <Typography variant="body2" color="secondary">
                    {context}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Tooltip
            title={read ? i18n.notificationCard.markAsUnread : i18n.notificationCard.markAsRead}
            arrow={false}
            placement="top"
          >
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onToggleRead();
              }}
              sx={{ padding: 1.5 }}
            >
              <Box
                bgcolor={read ? palette.secondary.medium : palette.primary.main}
                height={12}
                width={12}
                borderRadius="50%"
                style={{ transition: 'background-color 0.2s ease-in-out' }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Paper>
  );
};

const getSeverityEmoji = (severity: Severity) => {
  let img: React.ReactNode;
  switch (severity) {
    case 'warning':
      img = <Emoji name="warning" size={24} />;
      break;
    case 'error':
      img = <Emoji name="noEntry" size={24} />;
      break;

    case 'info':
    default:
      img = <Emoji name="closedMailboxWithRaisedFlag" size={24} />;
      break;
  }

  return img;
};

export default NotificationCard;
