import { LinearProgress } from '@mui/material';
import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { progressValue } from 'common/utils';
import { getThresholdedColor } from 'kurt/const';

type OccupancyProps = {
  BUSY: number;
  TOTAL: number;
};
const Occupancy = ({ BUSY, TOTAL }: OccupancyProps) => {
  const occupancyRate = BUSY > 0 && TOTAL > 0 ? Math.floor((BUSY / TOTAL) * 100) : 0;
  const occupancyColor = getThresholdedColor(occupancyRate);
  return (
    <Statistics.Card xs={4} sm={3} md={2} lg="auto">
      <Statistics.Item title={{ label: i18n.occupancy, color: 'default', variant: 'light' }}>
        <Statistics.ItemContent>
          <Statistics.Text variant="lg">
            {BUSY}
            <Statistics.Text variant="sm" span>
              {` /${TOTAL}`}
            </Statistics.Text>
          </Statistics.Text>
        </Statistics.ItemContent>
        <Statistics.ItemContent align="center">
          <LinearProgress value={progressValue(occupancyRate)} color={occupancyColor} sx={{ flex: 1 }} />
          <Statistics.Text
            color={occupancyColor}
            variant="sm"
            sx={{
              fontSize: 12,
            }}
          >
            {occupancyRate}%
          </Statistics.Text>
        </Statistics.ItemContent>
      </Statistics.Item>
    </Statistics.Card>
  );
};

export default Occupancy;
