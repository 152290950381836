import { Paper, Stack, SvgIconProps, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { color } from 'common/theme';

const NotificationsEmpty: React.FC = () => {
  return (
    <Paper sx={{ backgroundColor: color.blue[5] }} variant="outlined">
      <Stack padding="80px 0" alignItems="center" spacing={2}>
        <NotificationsEmptyImage />
        <Typography variant="body1">{i18n.notificationsPanel.empty.title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {i18n.notificationsPanel.empty.message}
        </Typography>
      </Stack>
    </Paper>
  );
};

const NotificationsEmptyImage: React.FC<SvgIconProps> = (props) => {
  return (
    <svg width="126" height="110" viewBox="0 0 126 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.9119 0.0192235C57.1132 0.933274 54.4708 2.01063 46.9974 7.49947C34.5953 16.6128 25.8971 31.6594 27.5868 46.8059C28.7776 57.5228 34.8924 67.1691 42.3999 74.9805C47.0223 79.7843 52.3048 84.1028 58.465 86.7338C65.3805 89.6892 73.1215 90.39 80.6449 89.8162C88.7284 89.1993 96.7939 87.1058 103.743 82.9642C113.376 77.2213 120.484 67.6023 123.433 56.89C126.384 46.1776 125.216 34.4832 120.552 24.406C119.246 21.5867 117.661 18.8673 115.576 16.547C113.272 13.9795 110.409 11.9586 107.443 10.1781C95.9342 3.27397 82.3936 -0.296044 68.9119 0.0192235Z"
        fill="#E6EDFF"
      />
      <path
        d="M30.5716 84.5497C29.2878 84.5497 28.249 83.5063 28.249 82.2271C28.249 72.2996 33.5859 63.0502 42.1843 58.0876C46.4166 55.6425 51.2364 54.352 56.1196 54.352C61.0097 54.352 65.8272 55.6425 70.0617 58.0876C78.6534 63.0525 83.997 72.2996 83.997 82.2271C83.997 83.5063 82.956 84.5497 81.6745 84.5497"
        fill="white"
      />
      <path
        d="M57.433 49.1467C53.3957 49.1467 49.6057 47.5749 46.7547 44.7216C43.9445 41.9137 42.3296 38.0216 42.3296 34.0456C42.3296 27.9194 45.9881 22.4442 51.6493 20.0944C53.4955 19.3323 55.4348 18.9445 57.4262 18.9445C61.4611 18.9445 65.2534 20.5163 68.1045 23.3673C72.4411 27.7017 73.7248 34.1612 71.3796 39.8247C69.0389 45.4882 63.5637 49.1467 57.433 49.1467Z"
        fill="white"
      />
      <path
        d="M24.6197 56.4046C29.7819 56.4046 34.4452 53.2905 36.4252 48.5161C38.3985 43.7417 37.3075 38.2483 33.6536 34.5921C30.0019 30.9405 24.504 29.845 19.7319 31.825C14.953 33.8028 11.8389 38.4593 11.8389 43.6306C11.8389 47.0169 13.1861 50.2694 15.5858 52.6622C17.9832 55.0574 21.2289 56.4046 24.6197 56.4046Z"
        fill="#5470B8"
      />
      <path
        d="M65.2012 31.4623L50.7555 30.8521C50.3019 30.834 49.9073 31.1992 49.9254 31.6505C50.0003 33.6646 50.6784 35.3793 51.9622 36.7969C53.4251 38.405 55.3008 39.2601 57.6007 39.3553C59.9006 39.4528 61.8512 38.7633 63.4638 37.2823C64.8814 35.9781 65.7115 34.3292 65.961 32.3287C66.0131 31.8796 65.648 31.4827 65.2012 31.4623Z"
        fill="#B5CAFF"
      />
      <path
        d="M88.9325 59.8658C87.4741 59.8681 86.0157 60.0132 84.5822 60.2945C83.2758 60.553 82.6135 62.0318 83.3348 63.1546C86.9864 68.8248 88.937 75.4432 88.9325 82.2249V83.5358C88.9325 84.5746 89.774 85.4184 90.8128 85.4184H109.411C110.45 85.4184 111.292 84.5746 111.292 83.5358V82.2249C111.292 76.296 108.94 70.6098 104.741 66.4138C100.55 62.2246 94.8591 59.8658 88.9325 59.8658Z"
        fill="#89A5ED"
      />
      <path
        d="M88.8665 56.4046C93.4844 56.4046 97.6419 53.6262 99.4064 49.3644C101.171 45.1026 100.196 40.1989 96.9319 36.9351C93.6704 33.6758 88.769 32.6983 84.5072 34.4651C80.2454 36.2297 77.4692 40.3872 77.4692 45.0028C77.4692 48.0239 78.6668 50.9271 80.8079 53.0659C82.9445 55.2025 85.8477 56.4046 88.8665 56.4046Z"
        fill="#89A5ED"
      />
      <path
        d="M23.3135 59.8658C17.3801 59.8658 11.6894 62.2246 7.50247 66.4161C3.30418 70.6098 0.952148 76.296 0.952148 82.2249V83.5358C0.952148 84.5746 1.79589 85.4184 2.83468 85.4184H21.4264C22.4652 85.4184 23.3067 84.5746 23.3067 83.5358V82.2249C23.3022 75.4432 25.2527 68.8248 28.9044 63.1546C29.6302 62.0318 28.9679 60.553 27.6569 60.2945C26.228 60.0132 24.7719 59.8681 23.3067 59.8658"
        fill="#5470B8"
      />
    </svg>
  );
};

export default NotificationsEmpty;
