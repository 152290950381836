import { Patient } from '@ambuliz/sabri-core';
import { Box, Card, Chip, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { formatAge, formatName, getPatientAge, getPatientEmojiIcon } from 'common/utils';
import { format } from 'date-fns';
import bgSmileLeft from './Backgrounds/bgSmileLeftPatient.svg';
import bgSmileRight from './Backgrounds/bgSmileRightPatient.svg';

type PatientInfosProps = {
  patient: Patient;
};

const PatientInfos = ({ patient }: PatientInfosProps) => {
  return (
    <Card
      sx={{
        padding: 4,
        bgcolor: palette.primary.main,
        backgroundImage: `url(${bgSmileLeft}), url(${bgSmileRight})`,
        backgroundPosition: 'left -20px bottom 80px, right -70px bottom -50px',
        backgroundRepeat: 'no-repeat, no-repeat',
      }}
    >
      <Stack spacing={4}>
        <Box width={40} height={40} bgcolor={color.blue[50]} borderRadius={1} position="relative">
          <Box
            sx={{
              transform: patient.gender === 'FEMALE' ? 'rotate(-4.622deg)' : 'rotate(6.061deg)',
              position: 'absolute',
              left: 7,
              top: 5,
            }}
          >
            <Emoji
              name={getPatientEmojiIcon(patient.gender, getPatientAge(patient.birthdate), true)}
              size="large"
              shape="square"
            />
          </Box>
        </Box>

        <Typography fontWeight={500} color="white" style={{ marginTop: 24 }}>
          {formatName(patient.firstName, patient.lastName, patient.legalName, patient.legalFirstName)}
        </Typography>
        <Stack direction="row" gap={1}>
          <Chip size="small" sx={{ bgcolor: color.blue[50] }} label={i18n.bedGenders[patient.gender]} />
          <Chip
            size="small"
            sx={{ bgcolor: color.blue[50] }}
            label={`${formatAge(patient.birthdate)} (${format(patient.birthdate, 'dd/MM/yyyy')})`}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography color="white">{i18n.pan} :</Typography>
          <Typography color="white" fontWeight={500}>
            {patient.pan}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography color="white">{i18n.ipp.toUpperCase()} :</Typography>
          <Typography color="white" fontWeight={500}>
            {patient.ipp}
          </Typography>
        </Stack>
        {patient.ins && (
          <Stack spacing={1}>
            <Typography color="white">{i18n.ins.toUpperCase()} :</Typography>
            <Typography color="white" fontWeight={500}>
              {patient.ins}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default PatientInfos;
