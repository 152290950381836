import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowRight: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path d="M8.6806 2.45896C8.97941 2.1721 9.45419 2.18179 9.74104 2.4806L14.541 7.4806C14.8197 7.77082 14.8197 8.22918 14.541 8.5194L9.74104 13.5194C9.45419 13.8182 8.97941 13.8279 8.6806 13.541C8.38179 13.2542 8.3721 12.7794 8.65896 12.4806L12.2403 8.75H2C1.58579 8.75 1.25 8.41421 1.25 8C1.25 7.58579 1.58579 7.25 2 7.25H12.2403L8.65896 3.5194C8.3721 3.22059 8.38179 2.74582 8.6806 2.45896Z" />
    </SvgIcon>
  );
};

export default ArrowRight;
