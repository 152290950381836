import { AccommodationStatus, AnomalyStatus, AnomalyType } from '@ambuliz/sabri-core';
import { Check } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { color } from 'common/theme';
import { format } from 'date-fns';

type Anomaly = {
  id: string;
  type: AnomalyType;
  status: AnomalyStatus;
  startAt: Date;
  endAt?: Date;
  bed?: {
    id: string;
    name: string;
  };
  accommodation?: { id: string; status: AccommodationStatus; startAt: Date; endAt?: Date };
  linkedWith?: string;
  onClear: (id: string) => void;
};

const AnomalyRow = ({ id, bed, accommodation, type, linkedWith, startAt, endAt, status, onClear }: Anomaly) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography fontWeight={600}>{id}</Typography>
        </TableCell>
        <TableCell>
          <Box display="flex">
            <AnomalyStatusChip status={status} />
          </Box>
        </TableCell>
        <TableCell>
          <Typography fontWeight={600}>{getType(type)}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary" fontWeight={600}>
            {format(startAt, 'dd/MM - HH:mm')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="secondary" fontWeight={600}>
            {endAt ? format(endAt, 'dd/MM - HH:mm') : '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight={600}>{bed?.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight={600}>{accommodation?.id}</Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight={600}>{linkedWith}</Typography>
        </TableCell>
        <TableCell width={16} sx={{ paddingLeft: 2 }}>
          {status === 'OPENED' && (
            <IconButton
              sx={{ margin: -1 }}
              onClick={(event) => {
                event.stopPropagation();
                onClear(id);
              }}
            >
              <Check />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

const AnomalyStatusChip = ({ status }: { status: AnomalyStatus }) => {
  return (
    <Stack
      direction="row"
      sx={{ bgcolor: status === 'OPENED' ? color.yellow[10] : color.green[10], borderRadius: 0.5, minWidth: 100 }}
    >
      <Chip
        label={status === 'OPENED' ? 'Ouverte' : 'Résolue'}
        color={status === 'OPENED' ? 'warning' : 'success'}
        variant="light"
        sx={{ flex: 1 }}
      />
    </Stack>
  );
};

const getType = (type: AnomalyType) => {
  switch (type) {
    case 'OUTDATED_START':
      return 'Entrée dépassée';
    case 'OUTDATED_PREVISIONAL_END':
      return 'Sortie prévisionnelle dépassée';
    case 'OUTDATED_VALIDATED_END':
      return 'Sortie confirmée dépassée';
    case 'OVERLAPPING_STEPS':
      return 'Chevauchement avec un autre séjour';
  }
};

export default AnomalyRow;
