import {
  EmergencyOccupancy,
  EmergencyOccupancyKey,
  EmergencyOccupancyService,
  HealthCenter,
  UnitSector,
  WaitingPatientsBySector,
} from '@ambuliz/sabri-core';
import {
  CircularProgress,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SortableTableCell } from 'common/components';
import { SortOrder } from 'common/components/Table/SortableTableCell';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { setHours, startOfHour } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import EmergencyOccupancyRow from './EmergencyOccupancyRow';

type EmergencyOccupancyTableProps = {
  healthCenterId: string;
  service: EmergencyOccupancyService;
  hasPediatricService?: boolean;
  occupancyMorning?: EmergencyOccupancy;
  occupancyMidday?: EmergencyOccupancy;
  occupancyAfternoon?: EmergencyOccupancy;
  loading?: boolean;
  isReadOnly?: boolean;
  onEmergencyOccupancyModalClose?: () => void;
};

export type EmergencyOccupancyMaybeEmpty = Pick<
  EmergencyOccupancy,
  'id' | 'healthCenter' | 'key' | 'date' | 'service'
> &
  Partial<EmergencyOccupancy>;

const EmergencyOccupancyList = ({
  healthCenterId,
  service,
  hasPediatricService = false,
  occupancyMorning,
  occupancyMidday,
  occupancyAfternoon,
  loading = false,
  isReadOnly = false,
  onEmergencyOccupancyModalClose,
}: EmergencyOccupancyTableProps) => {
  const [order, setOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof EmergencyOccupancy>('key');
  const occupancies = useMemo(() => {
    const defaultMorning =
      occupancyMorning || createDefaultNewOccupancy(healthCenterId, hasPediatricService, '9H', service);
    const defaultMidday =
      occupancyMidday || createDefaultNewOccupancy(healthCenterId, hasPediatricService, '14H', service);
    const defaultAfternoon =
      occupancyAfternoon || createDefaultNewOccupancy(healthCenterId, hasPediatricService, '17H', service);

    const occupancies = [
      occupancyMorning || defaultMorning,
      occupancyMidday || defaultMidday,
      occupancyAfternoon || defaultAfternoon,
    ];

    return occupancies.filter(Boolean).sort(sortOccupancies(order, orderBy));
  }, [
    occupancyMorning,
    occupancyMidday,
    occupancyAfternoon,
    order,
    orderBy,
    healthCenterId,
    hasPediatricService,
    service,
  ]);

  const waitingPatientsSectors: UnitSector[] = Array.from(
    new Set(occupancies.flatMap((occupancy) => Object.keys(occupancy?.waitingPatientsBySector || {})))
  ) as UnitSector[];

  const handleSort = useCallback(
    (name: keyof EmergencyOccupancy) => () => {
      setOrder(orderBy === name && order === 'asc' ? 'desc' : 'asc');
      setOrderBy(name);
    },
    [order, orderBy]
  );

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <SortableTableCell name="key" order={order} orderBy={orderBy} onSort={handleSort('key')}>
                <Typography variant="overline">{i18n.emergencyOccupancy.list.key.toUpperCase()}</Typography>
              </SortableTableCell>
              <SortableTableCell
                name="currentPatients"
                order={order}
                orderBy={orderBy}
                onSort={handleSort('currentPatients')}
              >
                <Typography variant="overline">{i18n.emergencyOccupancy.list.currentPatients.toUpperCase()}</Typography>
              </SortableTableCell>
              <TableCell>
                <Stack alignItems="center" gap={1}>
                  <Typography variant="overline">
                    {i18n.emergencyOccupancy.list.waitingPatientsBySector.toUpperCase()}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    gap={2}
                    divider={
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          borderColor: palette.secondary.medium,
                          borderRadius: 1,
                        }}
                      />
                    }
                  >
                    {loading
                      ? Array.from({ length: 4 }).map((_, index) => <Skeleton width={70} key={index}></Skeleton>)
                      : waitingPatientsSectors.map((sector) => (
                          <Typography variant="overline" key={sector} width={70} align="center">
                            {sector === 'PSY' ? 'PSYCHATRIE' : sector.toUpperCase()}
                          </Typography>
                        ))}
                  </Stack>
                </Stack>
              </TableCell>
              <SortableTableCell
                name="availableBeds"
                order={order}
                orderBy={orderBy}
                onSort={handleSort('availableBeds')}
              >
                <Typography variant="overline">{i18n.emergencyOccupancy.list.availableBeds.toUpperCase()}</Typography>
              </SortableTableCell>
              <SortableTableCell name="comment" order={order} orderBy={orderBy} onSort={handleSort('comment')}>
                <Typography variant="overline">{i18n.emergencyOccupancy.list.comment.toUpperCase()}</Typography>
              </SortableTableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell height={200} align="center" colSpan={6}>
                  <CircularProgress size={50} />
                </TableCell>
              </TableRow>
            ) : (
              occupancies.map((occupancy) => (
                <EmergencyOccupancyRow
                  occupancy={occupancy}
                  editableOccupancies={occupancies}
                  key={occupancy.id}
                  isReadOnly={isReadOnly}
                  onModalClose={onEmergencyOccupancyModalClose}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const defaultWaitingPatientsBySector: Readonly<WaitingPatientsBySector> = {
  Médecine: undefined,
  Chirurgie: undefined,
  PSY: undefined,
};

const createDefaultNewOccupancy = (
  healthCenterId: string,
  hasPediatricService: boolean,
  key: EmergencyOccupancyKey,
  service: EmergencyOccupancyService
): EmergencyOccupancyMaybeEmpty => {
  const date = startOfHour(setHours(new Date(), key === '9H' ? 9 : key === '14H' ? 14 : 17));
  const id = `${healthCenterId}-default-${key}-${date.toISOString()}`;
  const waitingPatientsBySector = { ...defaultWaitingPatientsBySector };

  if (!hasPediatricService) {
    waitingPatientsBySector['Pédiatrie'] = undefined;
  }
  return {
    id,
    healthCenter: HealthCenter.createWithoutData(healthCenterId) as HealthCenter,
    key,
    date,
    service,
    waitingPatientsBySector,
  };
};

const sortOccupancies =
  (order: SortOrder, orderBy: keyof EmergencyOccupancy) =>
  (a: EmergencyOccupancyMaybeEmpty, b: EmergencyOccupancyMaybeEmpty) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue, undefined, { numeric: true })
        : bValue.localeCompare(aValue, undefined, { numeric: true });
    }
    if (aValue !== undefined && bValue === undefined) {
      return order === 'asc' ? 1 : -1;
    }
    if (aValue === undefined && bValue !== undefined) {
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  };

export default EmergencyOccupancyList;
