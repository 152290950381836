import { Accommodation, Anomaly, Patient } from "@ambuliz/sabri-core";
import { AccommodationStep } from "../Step";

export const mapAccommodationStep = (accommodation: Accommodation, anomalies: Anomaly[] = [], visit?: Patient): AccommodationStep => {
  if (visit && accommodation.visit?.id === visit.id) {
    accommodation.visit = visit;
  }

  return {
    type: 'accommodation',
    step: accommodation,
    start: accommodation.startAt,
    end: accommodation.endAt,
    anomalies: anomalies.filter((anomaly) => anomaly.accommodation?.id === accommodation.id),
  };
};
