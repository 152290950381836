import { SvgIcon, SvgIconProps } from '@mui/material';

const Copy = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.883361 0C0.649079 0 0.424393 0.0930683 0.258731 0.258731C0.0930683 0.424393 0 0.649079 0 0.883361V7.65944C0 7.89372 0.0930677 8.11841 0.258731 8.28407C0.424394 8.44974 0.64908 8.5428 0.883361 8.5428H3.45711V11.1166C3.45711 11.3509 3.55017 11.5756 3.71584 11.7413C3.8815 11.9069 4.10619 12 4.34047 12H11.1165C11.3508 12 11.5755 11.9069 11.7412 11.7413C11.9068 11.5756 11.9999 11.3509 11.9999 11.1166V4.34056C11.9999 4.10628 11.9068 3.88159 11.7412 3.71593C11.5755 3.55026 11.3508 3.4572 11.1165 3.4572H8.5428V0.883361C8.5428 0.64908 8.44974 0.424394 8.28407 0.258731C8.11841 0.0930677 7.89372 0 7.65944 0H0.883361ZM7.6058 3.4572V0.936999H0.936999V7.6058H3.45711V4.34056C3.45711 4.10628 3.55017 3.88159 3.71584 3.71593C3.8815 3.55026 4.10619 3.4572 4.34047 3.4572H7.6058ZM4.39411 8.08287C4.39416 8.08002 4.39418 8.07717 4.39418 8.0743C4.39418 8.07144 4.39416 8.06859 4.39411 8.06574V4.3942H11.0629V11.063H4.39411V8.08287Z"
    />
  </SvgIcon>
);

export default Copy;
