import { Stack } from '@mui/material';
import { color } from 'common/theme';
import { ReactNode } from 'react';
import SilboThemeImage from './images/SilboThemeImage';

type PatientStackProps = {
  primary?: boolean;
  large?: boolean;
  children?: ReactNode;
};

const PatientStack: React.FC<PatientStackProps> = ({ children, primary, large }) => {
  return (
    <Stack
      flex={1}
      sx={{
        backgroundColor: primary ? color.blue[60] : 'inherit',
        borderRadius: 1,
        overflow: 'hidden',
      }}
      flexDirection="row"
    >
      <Stack
        flex={3}
        spacing={3}
        color={primary ? 'white' : 'black'}
        sx={{
          padding: 3,
        }}
      >
        {children}
      </Stack>
      {large && (
        <div style={{ flex: 1, display: 'contents' }}>
          <SilboThemeImage style={{ minHeight: '100%' }} />
        </div>
      )}
    </Stack>
  );
};

export default PatientStack;
