import Api, { Area, Unit } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

export const useAreaDirectUnit = ({ area }: { area: Area }) => {
  const { results, isLoading } = useParseQuery(new Api.Query(Unit).equalTo('areas', area));

  return {
    unit: results[0],
    isLoading,
  };
};
