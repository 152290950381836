import Api, { Accommodation, Appointment, Cloud, Patient, PatientIdentity } from '@ambuliz/sabri-core';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { DialogHeader } from 'common/components';
import { palette } from 'common/theme';
import { toast } from 'common/toast';
import { min, subMonths } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ExternalTransportListItem from 'superadmin/components/ExternalTransportList/ExternalTransportListItem';
import useTransportFares from 'superadmin/hooks/useTransportFares';
import { AppointmentDate } from '../components';
import PatientHl7Messages from './PatientHl7Messages';
import { PatientInteropMessages } from './PatientInteropMessages';

const useStepsByPatientId = (id?: string) => {
  const [appointments, setAppointments] = useState<Appointment[]>();
  const [accommodations, setAccommodations] = useState<Accommodation[]>();
  const [visits, setVisits] = useState<Patient[]>();
  const [patient, setPatient] = useState<PatientIdentity | undefined>();
  const { externalTransports } = useTransportFares(id);

  const hl7AdtFrom = useMemo(() => {
    if (patient && visits && accommodations) {
      const visitDates = visits.reduce((acc, visit) => {
        visit.admittedAt ? acc.push(visit.createdAt, visit.admittedAt) : acc.push(visit.createdAt);
        return acc;
      }, [] as Date[]);

      const accommodationDates = accommodations.reduce((acc, accommodation) => {
        acc.push(accommodation.createdAt, accommodation.startAt);
        return acc;
      }, [] as Date[]);

      const minDate = min([patient.createdAt, ...visitDates, ...accommodationDates]);

      return subMonths(minDate, 1);
    }
    return new Date();
  }, [patient, visits, accommodations]);

  const hl7SiuFrom = useMemo(() => {
    if (patient && visits && appointments) {
      const visitDates = visits.reduce((acc, visit) => {
        visit.admittedAt ? acc.push(visit.createdAt, visit.admittedAt) : acc.push(visit.createdAt);
        return acc;
      }, [] as Date[]);

      const appointmentDates = appointments.reduce((acc, accommodation) => {
        acc.push(accommodation.createdAt, accommodation.start);
        return acc;
      }, [] as Date[]);

      const minDate = min([patient.createdAt, ...visitDates, ...appointmentDates]);

      return subMonths(minDate, 1);
    }
    return new Date();
  }, [patient, visits, appointments]);

  useEffect(() => {
    if (id) {
      const fetchPatient = async () => {
        const patient = await new Api.Query(PatientIdentity).get(id);
        setPatient(patient);
      };
      const fetchAppointments = async () => {
        const appointments = await new Api.Query(Appointment)
          .include('location')
          .equalTo('patient', PatientIdentity.createWithoutData(id))
          .find();
        setAppointments(appointments);
      };
      const fetchVisits = async () => {
        const visits = await new Api.Query(Patient)
          .include('unit', 'area')
          .equalTo('patient', PatientIdentity.createWithoutData(id))
          .find();
        setVisits(visits);
      };
      const fetchAccommodations = async () => {
        const accommodations = await new Api.Query(Accommodation)
          .include('visit', 'bed', 'unit', 'createdBy')
          .equalTo('patient', PatientIdentity.createWithoutData(id))
          .find();
        setAccommodations(accommodations);
      };

      fetchPatient();
      fetchAppointments();
      fetchVisits();
      fetchAccommodations();
    }
  }, [id]);

  return {
    patient,
    appointments,
    accommodations,
    visits,
    fares: externalTransports,
    hl7AdtFrom,
    hl7SiuFrom,
  };
};

const PatientDetailsDialog = ({ returnUrl = '/admin/steps/patients' }: { returnUrl?: string }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { appointments, accommodations, visits, fares, hl7AdtFrom, hl7SiuFrom } = useStepsByPatientId(id);

  const handleClose = () => setOpen(false);

  const handleRefreshVisit = async (visitId: string) => {
    try {
      await Cloud.refreshVisit({ visitId });
      toast.success('Données du séjour rafraîchies');
    } catch (err) {
      toast.error('Erreur lors du rafraîchissement des données du séjour');
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        maxWidth={false}
        TransitionProps={{
          onExited: () => navigate(returnUrl),
        }}
      >
        <DialogHeader title="Détails" onClose={handleClose} />

        <DialogContent>
          <Stack spacing={4}>
            <Card>
              <CardHeader title="Rendez-vous" />
              <CardContent>
                {!appointments ? (
                  <CircularProgress size={20} />
                ) : appointments.length ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Début</TableCell>
                        <TableCell>Fin</TableCell>
                        <TableCell>Lieu</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointments.map((appointment) => (
                        <TableRow key={appointment.id}>
                          <TableCell>
                            <AppointmentDate date={appointment.start} />
                          </TableCell>
                          <TableCell>
                            <AppointmentDate date={appointment.end} />
                          </TableCell>
                          <TableCell>
                            {appointment.location?.name}
                            <Typography variant="body2" color={palette.secondary.main}>
                              {appointment.location?.externalIds
                                ? `(${appointment.location.externalIds.join(', ')})`
                                : null}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div> Pas de rendez-vous ...</div>
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader title="Séjours" />
              <CardContent>
                {!visits ? (
                  <CircularProgress size={20} />
                ) : visits.length ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>PAN</TableCell>
                        <TableCell>IPP</TableCell>
                        <TableCell>Admission</TableCell>
                        <TableCell>Sortie</TableCell>
                        <TableCell>Lieu</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visits.map((visit) => (
                        <TableRow key={visit.id}>
                          <TableCell>{visit?.pan}</TableCell>
                          <TableCell>{visit?.ipp}</TableCell>
                          <TableCell>{visit.admittedAt && <AppointmentDate date={visit.admittedAt} />}</TableCell>
                          <TableCell>{visit.dischargedAt && <AppointmentDate date={visit.dischargedAt} />}</TableCell>
                          <TableCell>
                            <Stack spacing={1}>
                              {visit.unit && (
                                <Typography>
                                  {!!visit.unit.type ? <Chip label={visit.unit.type} /> : null}{' '}
                                  <b>
                                    {visit.unit.name} ({visit.unit.externalId})
                                  </b>
                                </Typography>
                              )}
                              {visit.area && (
                                <Typography color="secondary">
                                  {visit.area.name}
                                  {!!visit.area.externalIds ? ` (${visit.area.externalIds.join(', ')})` : null}
                                </Typography>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Rafraîchir les données du séjour" arrow={false} placement="top">
                              <IconButton
                                variant="filled"
                                color="primary"
                                size="medium"
                                onClick={() => handleRefreshVisit(visit.id)}
                              >
                                <ReplayIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div>Pas de séjour ...</div>
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader title="Hébergements" />
              <CardContent>
                {!accommodations ? (
                  <CircularProgress size={20} />
                ) : accommodations.length ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Début</TableCell>
                        <TableCell>Fin</TableCell>
                        <TableCell>Lieu</TableCell>
                        <TableCell>Statut</TableCell>
                        <TableCell>PAN</TableCell>
                        <TableCell>Créé par</TableCell>
                        <TableCell>Détails</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accommodations.map((accommodation) => (
                        <TableRow key={accommodation.id}>
                          <TableCell>
                            {accommodation.startAt && <AppointmentDate date={accommodation.startAt} />}
                          </TableCell>
                          <TableCell>
                            {accommodation.endAt && <AppointmentDate date={accommodation.endAt} />}
                            <Typography
                              variant="body2"
                              color={accommodation.isEstimatedEnd ? palette.warning.main : palette.success.main}
                            >
                              {accommodation.isEstimatedEnd ? 'estimé' : 'validé'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Stack>
                              <Typography>{accommodation.unit?.name || '-'}</Typography>
                              <Typography>{accommodation.bed?.name || '-'}</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Chip color="primary" variant="outlined" label={accommodation.status} />
                          </TableCell>
                          <TableCell>
                            <Typography color="secondary" variant="body2">
                              {accommodation.visit?.pan}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{accommodation.createdBy?.username || 'Flux'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title="Afficher la modale de détails de l'hébergement"
                              arrow={false}
                              placement="top"
                            >
                              <IconButton
                                href={`/lits/management/unit/${accommodation.unit.id}/accommodation/${accommodation.id}`}
                                target="_blank"
                                LinkComponent={Link}
                                variant="filled"
                                color="primary"
                                size="medium"
                              >
                                <ArrowOutwardIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div>Pas d'hébergement ...</div>
                )}
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Demandes de transport" />
              <CardContent>
                {!fares ? (
                  <CircularProgress size={20} />
                ) : fares.length ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ color: palette.text.primary, paddingLeft: 1 }}>Référence</TableCell>
                        <TableCell>Statut</TableCell>
                        <TableCell>Date Départ</TableCell>
                        <TableCell>Date arrivée</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fares.map((fare) => (
                        /* @ts-ignore */
                        <ExternalTransportListItem
                          small
                          externalId={fare.externalId}
                          status={fare.status}
                          endAt={fare.endAt}
                          startAt={fare.startAt}
                          patient={fare.patient}
                          comment={fare.comment}
                          farePosition={fare.farePosition}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div>Pas de demande de transport ...</div>
                )}
              </CardContent>
            </Card>
            <PatientHl7Messages patientId={id} messageType="adt" from={hl7AdtFrom} />
            <PatientHl7Messages patientId={id} messageType="siu" from={hl7SiuFrom} />
            {visits && <PatientInteropMessages patients={visits} />}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PatientDetailsDialog;
