const getKanbanColumnsCondition = (
  column: 'planned' | 'validated' | 'completed' | 'requested',
  flow: 'admission' | 'discharge',
  isMutation?: boolean
) => {
  switch (column) {
    case 'planned':
      return flow === 'discharge' && isMutation ? ['requested'] : ['validated', 'requested'];

    case 'validated':
      return flow === 'discharge' && isMutation ? [] : ['planned'];

    case 'completed':
      return flow === 'discharge' ? (isMutation ? ['validated'] : ['planned', 'validated']) : ['validated'];

    default:
      return [];
  }
};

export default getKanbanColumnsCondition;
