import { Anomaly, HealthcareEnvironmentalCleaningStatus, PatientGender } from '@ambuliz/sabri-core';
import { Card, CardContent, CardHeader, Paper, Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import shadows from 'common/theme/shadows';
import { getDuration } from 'common/utils';
import { format } from 'date-fns';
import BedStatusChip from 'kurt/components/Unit/BedStatusChip';
import BedName from 'kurt/pages/Unit/BedName';
import { CARD_CONTENT_PAPER_HEIGHT } from './cardContentPaperHeight';

type HealthcareEnvironmentalCleaningCardProps = {
  id: string;
  bedId: string;
  bedName: string;
  bedGender: PatientGender;
  status: HealthcareEnvironmentalCleaningStatus;
  start: Date;
  end?: Date;
  anomalies?: Anomaly[];
  onClick: (id: string) => void;
};

const HealthcareEnvironmentalCleaningCard = ({
  id,
  bedId,
  bedGender,
  bedName,
  status,
  start,
  end,
  anomalies,
  onClick,
}: HealthcareEnvironmentalCleaningCardProps) => {
  return (
    <Card
      onClick={() => onClick(id)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          boxShadow: shadows[4],
        },
      }}
    >
      <CardHeader
        component="div"
        title={
          <Stack spacing={2} direction="row" alignItems="center">
            <BedName name={bedName} anomalies={anomalies} variant="h4" containerProps={{ width: undefined }} />
            <BedStatusChip status="CLEANING" gender={bedGender} />
          </Stack>
        }
      />
      <CardContent>
        <Stack spacing={3}>
          <Paper
            sx={{ height: CARD_CONTENT_PAPER_HEIGHT, backgroundColor: palette.background.default, padding: 2 }}
            variant="outlined"
          />
          <Stack>
            <Typography variant="body2" color="secondary">
              {status === 'PLANNED' ? i18n.previsionalAdmissionShort : i18n.since}
            </Typography>
            <Typography variant="body2" fontWeight="bold" color="secondary">
              {status === 'IN_PROGRESS' ? getDuration(start, new Date()) : format(start, 'dd/MM - HH:mm')}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default HealthcareEnvironmentalCleaningCard;
