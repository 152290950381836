import { Components } from '@mui/material';
import { ChevronSmallDown } from 'common/components/Icons';
import color from '../color';

const MuiSelect: Components['MuiSelect'] = {
  defaultProps: {
    IconComponent: (props) => <ChevronSmallDown {...props} />,
    displayEmpty: true,
    MenuProps: {
      disableAutoFocus: true,
      disableAutoFocusItem: true,
      PaperProps: { elevation: 3 },
    },
  },
  styleOverrides: {
    outlined: {
      '&.MuiInputBase-input': {
        paddingRight: 31,
      },
    },
    standard: {
      minHeight: 0,
      backgroundColor: 'transparent !important',
      '&.MuiInputBase-input': {
        padding: 0,
        paddingRight: 20,
        lineHeight: '18px',
      },
    },
    filled: {
      '&.MuiInputBase-input': {
        paddingRight: 31,
        '&.MuiInputBase-inputSizeSmall': {
          paddingRight: '27px !important',
        },
      },
    },
    icon: {
      color: color.grey[60],
    },
  },
};

export default MuiSelect;
