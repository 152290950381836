import { Components } from '@mui/material';
import color from '../color';
import shadows from '../shadows';

const MuiTooltip: Components['MuiTooltip'] = {
  defaultProps: { arrow: true, placement: 'right' },
  styleOverrides: {
    tooltip: {
      backgroundColor: color.grey[90],
      boxShadow: shadows[2],
      fontSize: 12,
      lineHeight: '16px',
      padding: '4px 8px',
      borderRadius: 6,
      fontWeight: 'normal',
      whiteSpace: 'pre-line',
      margin: '8px!important',
    },
    tooltipArrow: {
      padding: 12,
      borderRadius: 8,
      margin: '16px!important',
    },
    arrow: {
      color: color.grey[90],
    },
  },
};

export default MuiTooltip;
