import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dissoc } from 'ramda';
import { ModalNames } from '../../const/modalNames';
import { resetAction } from '../actions/general';

type ShowModalPayload = {
  modalName: ModalNames;
  params?: any;
};

type ModalState = Readonly<Record<string, any>>;

const initialState: ModalState = {};

const showModal = (state: ModalState, action: PayloadAction<ShowModalPayload>) => {
  const {
    payload: { modalName, params = {} },
  } = action;
  return {
    ...state,
    [modalName]: params,
  };
};

const hideModal = (state: ModalState, action: PayloadAction<ModalNames>) => {
  const { payload } = action;
  return dissoc(payload, state) as ModalState;
};

const reset = () => initialState;

const { reducer, actions } = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal,
    hideModal,
  },
  extraReducers: (builder) => builder.addCase(resetAction, reset),
});

export { reducer as modalReducer, actions as modalActions };
