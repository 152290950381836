import { Paper, Stack, Typography } from '@mui/material';
import { SelectedArea } from './SelectedArea';

type SelectedAreaListProps = {
  areas: string[];
  onDelete: (id: string) => void;
};

export const SelectedAreaList = ({ areas, onDelete }: SelectedAreaListProps) => {
  if (areas.length === 0) {
    return (
      <Paper elevation={0} variant="outlined">
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} p={3}>
          <Typography color="secondary.medium">Aucun emplacement sélectionné</Typography>
        </Stack>
      </Paper>
    );
  }

  return (
    <Stack spacing={2}>
      {areas.map((areaId) => (
        <SelectedArea key={areaId} areaId={areaId} onDelete={onDelete} />
      ))}
    </Stack>
  );
};
