import useLayout from 'core/layout/useLayout';
import { RefObject, useEffect, useState } from 'react';

const useSticky = (top: number, ref: RefObject<HTMLDivElement>) => {
  const [sticky, setSticky] = useState(false);
  const { container } = useLayout();

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= top);
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [top, ref, container]);

  return sticky;
};

export default useSticky;
