import { Stack, Tabs } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { useSelector } from 'react-redux';
import { AreaStored } from 'sabri/store/reducers/entity';
import { fareListTabs, fareListTabsByRole } from '.';
import { selectAreas, selectAreasFromUnit } from '../../../store/selectors/area';
import { FareFilterStatus } from '../../../store/selectors/fare';
import { selectUserRole, selectUserUnitId } from '../../../store/selectors/general';
import SearchInput from '../../SearchInput/SearchInput';
import ClearFilterButton from './ClearFilterButton';
import FareListFilterTab from './FareListFilterTab';
import MultipleSelectFilter from './MultipleAreasSelectFilter';

type FareListFilterProps = {
  inputFilter: string;
  dateFilter: Date;
  areaFilter: AreaStored[];
  status: FareFilterStatus;
  resultsCount?: number;
  onInputFilterChange: (search: string) => void;
  onAreaFilterChange: (search: AreaStored[]) => void;
  onDateFilterChange: (date: Date | null) => void;
};

const FareListFilter: React.FC<FareListFilterProps> = ({
  inputFilter,
  areaFilter,
  dateFilter,
  status,
  resultsCount,
  onInputFilterChange,
  onAreaFilterChange,
  onDateFilterChange,
}) => {
  const role = useSelector(selectUserRole) || 'REGULATOR';
  const unit = useSelector(selectUserUnitId) || '';
  const tabs = fareListTabsByRole(role);
  const areas = useSelector(role === 'MANAGER' ? selectAreasFromUnit(unit) : selectAreas) || [];

  const clearAll = () => {
    onInputFilterChange('');
    onAreaFilterChange([]);
    onDateFilterChange(new Date());
  };

  return (
    <Stack justifyContent="space-between" direction="row">
      <Tabs value={fareListTabs[status].route}>
        {tabs.map(({ label, route }) => (
          <FareListFilterTab key={route} label={label} value={route} />
        ))}
      </Tabs>

      <Stack alignItems="center" gap={2} direction="row">
        <SearchInput
          value={inputFilter}
          onValueChanged={(value) => onInputFilterChange(value)}
          resultsCount={resultsCount}
        />
        <DatePicker
          value={dateFilter}
          onChange={(date) => onDateFilterChange(date)}
          slotProps={{
            textField: {
              sx: {
                width: 134,
              },
            },
          }}
        />

        {['MANAGER', 'REGULATOR', 'ADMIN_REGULATOR'].includes(role) && (
          <>
            <MultipleSelectFilter value={areaFilter} onValueChanged={onAreaFilterChange} options={areas} />
            <ClearFilterButton visible={!!areaFilter.length || !!inputFilter.length} onClick={clearAll} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

//
export default FareListFilter;
