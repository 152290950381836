import { ExternalTypeCode, externalTypeCodes } from '@ambuliz/sabri-core';
import { SwapHoriz } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Calendar } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Area } from 'superadmin/types';

type AreaFormProps = {
  id?: string;
  name?: string;
  parent?: string;
  externalIds?: string[];
  isSearchable?: boolean;
  externalTypeCode?: ExternalTypeCode;
  excludedFromOccupancy?: boolean;
  onSubmit: (area: Partial<Area>) => void;
  onCancel: () => void;
};

const AreaForm: React.FC<AreaFormProps> = ({
  id,
  name,
  parent,
  externalIds = [],
  isSearchable,
  externalTypeCode,
  excludedFromOccupancy,
  onSubmit,
  onCancel,
}) => {
  const [area, setArea] = useState<Partial<Area>>({
    name,
    externalIds,
    isSearchable,
    parent,
    externalTypeCode,
    excludedFromOccupancy,
  });

  const navigate = useNavigate();

  const [nameError, setNameError] = useState(false);

  const handleSubmit = () => {
    let isValid = true;
    if (!area.name || area.name.trim().length === 0) {
      setNameError(true);
      isValid = false;
    }

    if (isValid) {
      onSubmit(area);
    }
  };

  return (
    <Grid item container direction="column" spacing={4} onSubmit={handleSubmit}>
      <Grid item>
        <TextField
          fullWidth
          variant="outlined"
          error={nameError}
          value={area.name}
          label={i18n.name}
          onChange={(event) => {
            setNameError(false);
            setArea({ ...area, name: event.target.value });
          }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          options={externalIds}
          value={area.externalIds}
          multiple
          open={false}
          freeSolo
          autoSelect
          onChange={(_, externalIds) => setArea({ ...area, externalIds })}
          renderInput={(params) => <TextField {...params} fullWidth variant="outlined" label={i18n.externalIds} />}
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel id="externalTypeCode">{i18n.externalTypeCode}</InputLabel>
          <Select
            onChange={(event) => setArea({ ...area, externalTypeCode: event.target.value as ExternalTypeCode })}
            value={area.externalTypeCode}
            labelId="externalTypeCode"
            label={i18n.externalTypeCode}
          >
            {externalTypeCodes
              .filter((type) => type !== 'hospital')
              .map((externalTypeCode) => (
                <MenuItem value={externalTypeCode} key={externalTypeCode}>
                  {i18n[externalTypeCode][0].toUpperCase() + i18n[externalTypeCode].slice(1)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <FormLabel htmlFor="isSearchable">{i18n.isSearchable}</FormLabel>
        </Grid>
        <Grid item>
          <Switch
            id="isSearchable"
            checked={area.isSearchable}
            onChange={() => {
              setArea({ ...area, isSearchable: !area.isSearchable });
            }}
          />
        </Grid>
      </Grid>
      {area.externalTypeCode === 'bed' && (
        <Grid item container justifyContent="space-between">
          <Grid item>
            <FormLabel htmlFor="isSearchable">Exclu du capacitaire</FormLabel>
          </Grid>
          <Grid item>
            <Switch
              id="isSearchable"
              checked={area.excludedFromOccupancy}
              onChange={() => {
                setArea({ ...area, excludedFromOccupancy: !area.excludedFromOccupancy });
              }}
            />
          </Grid>
        </Grid>
      )}
      {area.externalTypeCode === 'bed' && (
        <Grid item>
          <Stack spacing={4}>
            <Typography variant="h4">{i18n.moreActions}</Typography>
            <Stack spacing={3}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Calendar />
                <Link component="button" onClick={() => navigate('./availability-periods')}>
                  {i18n.enableOrDisableArea}
                </Link>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <SwapHoriz />
                <Link component="button" onClick={() => navigate('./move-bed')}>
                  {i18n.moveBed}
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      )}
      <Grid item container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button onClick={onCancel} variant="outlined">
            {i18n.cancel}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleSubmit}>{id ? i18n.save : i18n.create}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AreaForm;
