import { Box, Button } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useCloseMenu } from '../../hooks/useCloseMenu';
import { i18n } from '../../locales';
import useClasses from './FiltersControllerStyles';

export type Filter = {
  id: string;
  renderFilter: (onSubmit: (items: FilterItem[]) => void, onClose: () => void) => ReactElement;
};

export type FilterItem = {
  id: string;
  label: string;
  ids?: string[];
};

export type FilterValues = {
  id: string;
  values: string[];
};

type FiltersControllerProps = {
  filters: Filter[];
  onUpdateFilters: (filterValues: FilterValues[]) => void;
};

type FilterSelectorProps = {
  filters: Filter[];
  onSelect: (value: Filter) => void;
  onClose: () => void;
};

const FilterSelector: React.FC<FilterSelectorProps> = ({ filters, onSelect, onClose }) => {
  const classes = useClasses();
  const { onMouseOver, onMouseLeave } = useCloseMenu(onClose);

  return (
    <div onMouseLeave={onMouseLeave} onMouseOver={onMouseOver} className={classes.select}>
      {filters.map((filter) => (
        <div key={filter.id} onClick={() => onSelect(filter)} className={classes.selectItem}>
          {i18n.filters[filter.id]}
        </div>
      ))}
    </div>
  );
};

export const FiltersController: React.FC<FiltersControllerProps> = ({ filters, onUpdateFilters }) => {
  const classes = useClasses();
  const [isSelectorOpened, setIsSelectorOpened] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);
  const [filtersValues, setFiltersValues] = useState<FilterValues[]>([]);

  const inactiveFilters = filters.filter(
    (filter) => activeFilters.findIndex((activeFilter) => activeFilter.id === filter.id) === -1
  );

  const handleSelectFilter = (filter: Filter) => {
    setActiveFilters((prevFilters) => [...prevFilters, filter]);
    closeSelector();
  };

  const openSelector = () => setIsSelectorOpened(true);

  const closeSelector = () => setIsSelectorOpened(false);

  const renderFilter = (filter: Filter) => {
    const onSubmitFilter = (items: FilterItem[]) => {
      const indexItem = filtersValues.findIndex((filterValue) => filterValue.id === filter.id);
      if (indexItem > -1) {
        const nextFiltersValues = [...filtersValues];
        nextFiltersValues[indexItem] = {
          id: filter.id,
          values: items.map((item) => item.ids || item.id).flat(),
        };
        onUpdateFilters(nextFiltersValues);
        setFiltersValues(nextFiltersValues);
        return;
      }
      const nextFiltersValues = [
        ...filtersValues,
        {
          id: filter.id,
          values: items.map((item) => item.ids || item.id).flat(),
        },
      ];
      onUpdateFilters(nextFiltersValues);
      setFiltersValues(nextFiltersValues);
    };

    const onCloseFilter = () => {
      setActiveFilters((prevFilters) => prevFilters.filter((prevFilter) => prevFilter.id !== filter.id));
      const nextFilterValues = filtersValues.filter((prevFilterValues) => prevFilterValues.id !== filter.id);
      onUpdateFilters(nextFilterValues);
      setFiltersValues(nextFilterValues);
    };

    return (
      <div key={filter.id} className={classes.filterItem}>
        {filter.renderFilter(onSubmitFilter, onCloseFilter)}
      </div>
    );
  };

  const renderFilters = () => <div className={classes.filtersContainer}>{activeFilters.map(renderFilter)}</div>;

  const renderFilterSelector = () => {
    if (inactiveFilters.length > 0) {
      return (
        <Box position="relative">
          <Button onClick={openSelector} variant="text" color="primary">
            {i18n.addFilter}
          </Button>
          {isSelectorOpened && (
            <FilterSelector filters={inactiveFilters} onSelect={handleSelectFilter} onClose={closeSelector} />
          )}
        </Box>
      );
    }
  };

  return (
    <Box flexDirection="row" display="flex" alignItems="center">
      {renderFilters()}
      {renderFilterSelector()}
    </Box>
  );
};
