import { Box, MenuItem } from '@mui/material';
import { FilterSelect } from 'common/components';
import { i18n } from 'common/locale';
import { ContentLoader, PageHeader, PageSection } from 'core/layout';
import { useLocations } from 'core/locations';
import { useBedsFromUnit } from 'core/locations/useBedsFromUnit';
import { useEffect, useMemo, useState } from 'react';
import AnomalyList from './AnomalyList';
import AnomalyRow from './AnomalyRow';
import useAnomalies, { AnomaliesOrderBy, AnomaliesOrderParams } from './useAnomalies';
import useAnomaliesPageFilters from './useAnomaliesPageFilters';

const AnomaliesPage = () => {
  const { units } = useLocations();
  const { status, type, areaId } = useAnomaliesPageFilters();

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<AnomaliesOrderParams>({ orderBy: 'status', order: 'desc' });

  const [unitId, setUnitId] = useState<string | undefined>();

  const unitOptions = units.map((unit) => ({ value: unit.id, label: unit.name }));
  const { beds } = useBedsFromUnit(unitId || '');
  const areasInUnit = useMemo(() => beds.map((bed) => bed.id), [beds]);

  const { anomalies, loading, count, clear } = useAnomalies(areasInUnit, {
    page,
    rowsPerPage,
    status,
    type,
    areaId,
    ...order,
  });

  const unitName = useMemo(() => {
    return unitId ? unitOptions.find((unit) => unit.value === unitId)?.label : undefined;
  }, [unitId, unitOptions]);
  useEffect(() => {
    if (units.length) setUnitId(units[0].id);
  }, [units]);

  const handleUnitChange = (id: string) => {
    setUnitId(id);
  };

  const handleSort = (name: AnomaliesOrderBy) => {
    setOrder((prevOrder) => ({
      orderBy: name,
      order: prevOrder.orderBy === name ? (prevOrder.order === 'desc' ? 'asc' : 'desc') : prevOrder.order,
    }));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleClear = async (anomalyId: string) => {
    await clear(anomalyId);
  };

  return (
    <>
      <PageHeader title={`Anomalies ${unitName ? '- ' + unitName : ''}`}>
        {unitId && (
          <Box alignSelf="flex-start">
            <FilterSelect searchable label={i18n.unit} value={unitId} onChange={handleUnitChange}>
              {unitOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FilterSelect>
          </Box>
        )}
      </PageHeader>
      <PageSection>
        {loading || !unitId ? (
          <ContentLoader />
        ) : (
          <>
            <AnomalyList
              {...order}
              unitId={unitId}
              totalCount={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onSort={handleSort}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            >
              {anomalies?.map((anomaly) => (
                <AnomalyRow
                  key={anomaly.id}
                  id={anomaly.id}
                  bed={anomaly.area}
                  accommodation={anomaly.accommodation}
                  type={anomaly.type}
                  startAt={anomaly.startAt}
                  endAt={anomaly.endAt}
                  linkedWith={anomaly.linkedWith?.id}
                  status={anomaly.status}
                  onClear={handleClear}
                />
              ))}
            </AnomalyList>
          </>
        )}
      </PageSection>
    </>
  );
};

export default AnomaliesPage;
