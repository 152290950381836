import { createSelector } from '@reduxjs/toolkit';
import { EntityNames } from '../../const/schemas';
import { EntityState } from '../reducers/entity';
import { AppState } from '../store';

export const selectEntitiesValues = <E extends EntityNames>(entityName: E) =>
  createSelector(selectEntities<E>(entityName), (entities): EntityState[E][string][] => Object.values(entities));

export const selectEntitiesIds = <E extends EntityNames>(entityName: E) =>
  createSelector(selectEntities<E>(entityName), (entities): string[] => Object.keys(entities));

export const selectObjectsToFetch = (state: AppState) => state.entity.objectsToFetch;

export const selectEntities =
  <E extends EntityNames>(entityName: E) =>
  (state: AppState): EntityState[E] =>
    state.entity[entityName];

export const selectEntityById =
  <E extends EntityNames, I extends string>(entityName: E, id: I | undefined) =>
  (state: AppState): EntityState[E][I] | undefined =>
    id ? state.entity[entityName][id] : undefined;

export const selectEntitiesByIds = <E extends EntityNames, I extends string>(entityName: E, ids: I[] = []) =>
  createSelector(selectEntities<E>(entityName), (entities): EntityState[E][I][] =>
    ids.map((id) => entities[id]).filter(Boolean)
  );
