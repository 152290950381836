import { SvgIcon, SvgIconProps } from '@mui/material';

const ExternalLink: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.25 0.75C10.25 1.16421 10.5858 1.5 11 1.5H13.4393L5.96967 8.96967C5.67678 9.26256 5.67678 9.73744 5.96967 10.0303C6.26256 10.3232 6.73744 10.3232 7.03033 10.0303L14.5 2.56066V5C14.5 5.41421 14.8358 5.75 15.25 5.75C15.6642 5.75 16 5.41421 16 5V1C16 0.447715 15.5523 0 15 0H11C10.5858 0 10.25 0.335786 10.25 0.75ZM1.5 4.25C1.5 4.11193 1.61193 4 1.75 4H7.29545C7.70967 4 8.04545 3.66421 8.04545 3.25C8.04545 2.83579 7.70967 2.5 7.29545 2.5H1.75C0.783502 2.5 0 3.2835 0 4.25V14.25C0 15.2165 0.783502 16 1.75 16H11.75C12.7165 16 13.5 15.2165 13.5 14.25V8.70455C13.5 8.29033 13.1642 7.95455 12.75 7.95455C12.3358 7.95455 12 8.29033 12 8.70455V14.25C12 14.3881 11.8881 14.5 11.75 14.5H1.75C1.61193 14.5 1.5 14.3881 1.5 14.25V4.25Z"
    />
  </SvgIcon>
);

export default ExternalLink;
