import { AccommodationStatus } from '@ambuliz/sabri-core';
import { Stack, Typography } from '@mui/material';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import styled from 'styled-components';

type StepStatus = AccommodationStatus | ('NOT_CREATED' | 'TO_BE_PLACED');

type AccommodationStatusChipProps = {
  visitStatus: AccommodationStatus;
  status: 'planned' | 'validated' | 'completed' | 'requested';
};

const getVisitStatus = (
  visitStatus: AccommodationStatus,
  status: 'planned' | 'validated' | 'completed' | 'requested'
): StepStatus => {
  if (visitStatus === 'PLANNED' && status === 'planned') {
    return 'TO_BE_PLACED';
  } else if (visitStatus === 'PLANNED' && status === 'validated') {
    return 'PLANNED';
  }

  return visitStatus;
};

const statusColors: Record<StepStatus, string> = {
  PLANNED: color.yellow[20],
  IN_PROGRESS: color.green[20],
  CANCELLED: color.red[20],
  COMPLETED: color.grey[20],
  NOT_CREATED: color.grey[20],
  TO_BE_PLACED: color.yellow[20],
};

const secondaryStatusColor: Record<StepStatus, string> = {
  PLANNED: color.yellow[90],
  IN_PROGRESS: color.green[90],
  CANCELLED: color.red[90],
  COMPLETED: color.grey[90],
  NOT_CREATED: color.grey[90],
  TO_BE_PLACED: color.yellow[90],
};

const Circle = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

const Label = styled(Typography)<{ color: string }>`
  color: ${({ color }) => color};
`;

const Chip = styled(Stack)<{ color: string }>`
  border-radius: 4px;
  padding: 2px 8px;
  width: fit-content;
  background-color: ${({ color }) => color};
`;

const AccommodationStatusChip: React.FC<AccommodationStatusChipProps> = ({ visitStatus, status }) => {
  const localStatus = getVisitStatus(visitStatus, status);
  return (
    <Chip direction="row" alignItems="center" spacing={2} color={statusColors[localStatus]}>
      <Circle color={secondaryStatusColor[localStatus]} />
      <Label variant="body2" color={secondaryStatusColor[localStatus]}>
        {i18n.stepStatus[localStatus]}
      </Label>
    </Chip>
  );
};

export default AccommodationStatusChip;
