import { createAction } from '@reduxjs/toolkit';
import { EntityNames } from '../../const/schemas';
import { RequestName } from '../../const/requests';

export type GetEntitiesPayload = {
  entityName: EntityNames;
  requestName: RequestName;
};

export const getEntitiesType = 'entity/getEntities';
export const getEntitiesAction = createAction(getEntitiesType, (payload: GetEntitiesPayload) => ({
  payload,
}));

export type AddObjectToFetchPayload = {
  ids: string[];
  entityName: EntityNames;
};

export const getObjectByIdType = 'entity/getObjectById';
export const getObjectByIdAction = createAction(getObjectByIdType, (payload: AddObjectToFetchPayload) => ({
  payload,
}));
