import makeStyles from '@mui/styles/makeStyles';
import { color } from 'common/theme';

export default makeStyles({
  sortLabel: {
    width: '100%',
    whiteSpace: 'nowrap',
  },
  table: {
    backgroundColor: 'white',
    '& .MuiTableCell-head': {
      textTransform: 'none',
    },
  },
  tableCell: {
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    fontSize: 13,
    '&:first-of-type': {
      paddingLeft: 16,
    },
    '&:last-of-type': {
      paddingRight: 16,
    },
  },
  noDataIcon: {
    width: 42,
    height: 42,
  },
  noDataContainer: {
    backgroundColor: 'white',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  rootTableRow: {
    backgroundColor: 'rgba(255, 205, 210, 0.3)',
    '& > td:first-of-type': {
      color: '#d0021b',
      borderLeft: '8px solid #d0021b',
    },
  },
  tableRow: ({ onClickRow }: { onClickRow: boolean }) =>
    onClickRow
      ? {
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: color.grey[5],
          },
          '&:hover div': {
            cursor: 'pointer',
          },
        }
      : {},
});
