import { toast } from 'common/toast';
import { put } from 'redux-saga/effects';
import { requestStatusActions } from '../store/reducers/requestStatus';

export const safeSaga = (func: Function, optionalRequestName?: string) => {
  return function* (action: any) {
    const { payload } = action;
    //Si on passe au payload un requestName, le reducer requestStatus le ciblera pour adapter son status pendant la requête
    //Sinon on prendra le deuxième argument de safeSaga
    //Ou rien
    const requestName = payload?.requestName || optionalRequestName || '';
    try {
      yield put(requestStatusActions.setRequestStatus({ key: requestName, status: 'LOADING' }));
      const response = yield* func(action);
      yield put(requestStatusActions.setRequestStatus({ key: requestName, status: 'SUCCESS' }));
      if (payload?.resolve) {
        payload.resolve(response);
      }
      if (payload?.messageSuccess) {
        toast.success(payload.messageSuccess);
      }
      return response;
    } catch (error) {
      yield put(requestStatusActions.setRequestStatus({ key: requestName, status: 'FAILED' }));
      if (payload?.reject) {
        payload.reject(error);
      }
    }
  };
};
