import { AccommodationSpecificity, accommodationSpecificities } from '@ambuliz/sabri-core';
import { formatName } from 'common/utils';
import { useCallback, useMemo, useState } from 'react';
import { Resource } from '../../UnitManagement';
import { BedStatus } from '../../UnitManagement/UnitManagementFilters';

type Order = 'asc' | 'desc';
type OrderBy =
  | 'bedName'
  | 'startAt'
  | 'endAt'
  | 'status'
  | 'patientName'
  | 'patientBirthdate'
  | 'duration'
  | 'specificities'
  | 'unit';

const useFilteredRows = (resources: Resource[]) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<OrderBy>('bedName');

  const handleSort = useCallback(
    (property: OrderBy) => () => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const sortedResources = useMemo(() => sortRows(resources, orderBy, order), [resources, order, orderBy]);

  return {
    filteredRows: sortedResources,
    order,
    orderBy,
    handleSort,
  };
};

export const sortRows = (rows: Resource[], orderBy: OrderBy, order: Order) => {
  rows = [...rows];

  return rows.sort((a, b) => compareRows(a, b, orderBy, order));
};

const compareRows = (a: Resource, b: Resource, orderBy: OrderBy, order: Order): number => {
  switch (orderBy) {
    case 'endAt':
      if (!a.accommodation || !a.accommodation.endAt) {
        return order === 'asc' ? 1 : -1;
      } else if (!b.accommodation || !b.accommodation.endAt) {
        return order === 'asc' ? -1 : 1;
      } else {
        return order === 'asc'
          ? a.accommodation.endAt.getTime() - b.accommodation.endAt.getTime()
          : b.accommodation.endAt.getTime() - a.accommodation.endAt.getTime();
      }

    case 'startAt':
      if (!a.accommodation) {
        return order === 'asc' ? 1 : -1;
      } else if (!b.accommodation) {
        return order === 'asc' ? -1 : 1;
      } else {
        return order === 'asc'
          ? a.accommodation.startAt.getTime() - b.accommodation.startAt.getTime()
          : b.accommodation.startAt.getTime() - a.accommodation.startAt.getTime();
      }

    case 'status':
      const targetOrder: BedStatus[] = ['BUSY', 'CLEANING', 'BOOKED', 'AVAILABLE', 'CLOSED'];
      const indexA = targetOrder.indexOf(a.bed.status);
      const indexB = targetOrder.indexOf(b.bed.status);

      if (indexA === indexB) {
        return compareRows(a, b, 'bedName', order);
      }
      if (indexA === -1) {
        return order === 'asc' ? 1 : -1;
      }
      if (indexB === -1) {
        return order === 'asc' ? -1 : 1;
      }
      return order === 'asc' ? indexA - indexB : indexB - indexA;

    case 'patientName':
      const patientA = a.accommodation?.visit
        ? formatName(
            a.accommodation.visit.firstName,
            a.accommodation.visit.lastName,
            a.accommodation.visit.legalName,
            a.accommodation.visit.legalFirstName
          )
        : undefined;
      const patientB = b.accommodation?.visit
        ? formatName(
            b.accommodation.visit.firstName,
            b.accommodation.visit.lastName,
            b.accommodation.visit.legalName,
            b.accommodation.visit.legalFirstName
          )
        : undefined;

      if (patientA === patientB) {
        return compareRows(a, b, 'bedName', order);
      }
      if (!patientA) {
        return order === 'asc' ? 1 : -1;
      }
      if (!patientB) {
        return order === 'asc' ? -1 : 1;
      }
      return order === 'asc' ? patientA.localeCompare(patientB) : patientB.localeCompare(patientA);

    case 'patientBirthdate':
      const birthdateA = a.accommodation?.visit?.birthdate;
      const birthdateB = b.accommodation?.visit?.birthdate;

      if (birthdateA === birthdateB) {
        return compareRows(a, b, 'bedName', order);
      }
      if (!birthdateA) {
        return order === 'asc' ? 1 : -1;
      }
      if (!birthdateB) {
        return order === 'asc' ? -1 : 1;
      }
      return order === 'asc'
        ? birthdateB.getTime() - birthdateA.getTime()
        : birthdateA.getTime() - birthdateB.getTime();

    case 'duration':
      const startA = a.accommodation?.startAt || a.healthcareEnvironmentalCleaning?.startAt || undefined;
      const startB = b.accommodation?.startAt || b.healthcareEnvironmentalCleaning?.startAt || undefined;
      const endA = a.accommodation?.endAt || a.healthcareEnvironmentalCleaning?.endAt || undefined;
      const endB = b.accommodation?.endAt || b.healthcareEnvironmentalCleaning?.endAt || undefined;
      const durationA = startA && endA ? endA.getTime() - startA.getTime() : undefined;
      const durationB = startB && endB ? endB.getTime() - startB.getTime() : undefined;

      if (durationA === durationB) {
        return compareRows(a, b, 'bedName', order);
      }
      if (!durationA) {
        return order === 'asc' ? 1 : -1;
      }
      if (!durationB) {
        return order === 'asc' ? -1 : 1;
      }
      return order === 'asc' ? durationA - durationB : durationB - durationA;

    case 'specificities':
      return (
        compareSpecificities(order, a.accommodation?.specificities, b.accommodation?.specificities) ||
        compareRows(a, b, 'bedName', order) ||
        compareRows(a, b, 'patientName', order)
      );

    case 'unit':
      return (
        (order === 'asc'
          ? a.bed.unitName.localeCompare(b.bed.unitName)
          : b.bed.unitName.localeCompare(a.bed.unitName)) ||
        compareRows(a, b, 'bedName', order) ||
        compareRows(a, b, 'patientName', order)
      );

    case 'bedName':
    default:
      return order === 'asc'
        ? a.bed.name.localeCompare(b.bed.name, undefined, { numeric: true })
        : b.bed.name.localeCompare(a.bed.name, undefined, { numeric: true });
  }
};

const compareSpecificities = (
  order: Order,
  valueA?: AccommodationSpecificity[],
  valueB?: AccommodationSpecificity[]
): number => {
  if (valueA === valueB || (valueA && valueB && valueA.length <= 0 && valueB.length <= 0)) {
    return 0;
  }
  if (!valueA) {
    return order === 'asc' ? 1 : -1;
  }
  if (!valueB) {
    return order === 'asc' ? -1 : 1;
  }
  const indexA = (valueA as AccommodationSpecificity[]).reduce(
    (acc, specificity) =>
      accommodationSpecificities.indexOf(specificity) < acc ? accommodationSpecificities.indexOf(specificity) : acc,
    accommodationSpecificities.length
  );
  const indexB = (valueB as AccommodationSpecificity[]).reduce(
    (acc, specificity) =>
      accommodationSpecificities.indexOf(specificity) < acc ? accommodationSpecificities.indexOf(specificity) : acc,
    accommodationSpecificities.length
  );

  if (indexA !== indexB) {
    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }

    return order === 'asc' ? indexA - indexB : indexB - indexA;
  }
  return compareSpecificities(order, valueA.slice(1), valueB.slice(1));
};

export default useFilteredRows;
