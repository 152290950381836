import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import logo from 'common/assets/images/logo.svg';
import { InputText } from 'common/components/Form';
import { i18n } from 'common/locale';
import { ChangeEvent, FocusEvent, FormEvent, useState } from 'react';
import * as Yup from 'yup';
import { Schema } from 'yup';

export type LoginFormProps = {
  loading: boolean;
  onSubmit: (email: string, password: string) => void;
  error?: string;
};

type SchemaError<T> = Partial<{ [key in keyof T]: string }>;

function validate<T>(form: any, schema: Schema<T>) {
  const errors: any = {};
  Object.keys(form).forEach((key) => {
    try {
      schema.validateSyncAt(key, form);
    } catch (error) {
      errors[key] = error.message;
    }
  });
  return Object.values(errors).length > 0 ? errors : null;
}

type FormData = {
  username: string;
  password: string;
};

const FormSchema: Yup.Schema<FormData> = Yup.object().shape({
  username: Yup.string().required('requiredField'),
  password: Yup.string().required('requiredField'),
});

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, loading = false, error }) => {
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState<SchemaError<FormData>>({});

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validate(state, FormSchema);
    if (errors) {
      setErrors(errors);
    } else {
      onSubmit(state.username, state.password);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: undefined });
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [event.target.name]: undefined });
  };

  return (
    <Grid container direction="column" spacing={8} justifyContent="center" alignItems="center">
      <Grid item>
        <img src={logo} alt="Silbo logo" height={64} />
      </Grid>
      <Grid container item xs={8} md={4} lg={3} spacing={6} direction="column" component="form" onSubmit={handleSubmit}>
        <Grid item>
          <InputText
            disabled={loading}
            label={i18n.username}
            value={state.username}
            onChange={handleChange}
            onFocus={handleFocus}
            name="username"
            error={errors.username && i18n.yup[errors.username]}
          />
        </Grid>
        <Grid item>
          <InputText
            disabled={loading}
            label={i18n.password}
            type="password"
            value={state.password}
            onChange={handleChange}
            onFocus={handleFocus}
            name="password"
            error={errors.password && i18n.yup[errors.password]}
          />
        </Grid>
        <Grid item container justifyContent="flex-end">
          <LoadingButton type="submit" loading={loading}>
            {i18n.login}
          </LoadingButton>
          <Grid item xs={12}>
            <Box marginTop={1}>
              <Typography align="right" color="error">
                {error && i18n.errors[error]}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
