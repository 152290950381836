import { Clear } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { toast } from 'common/toast';
import { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import getToastableErrorMessage from 'sabri/utils/getToastableErrorMessage';
import { modalNames } from '../../../const/modalNames';
import { i18n } from '../../../locales';
import { removeFareAssignationAction } from '../../../store/actions/fare';
import { modalActions } from '../../../store/reducers/modal';
import { selectIsAutodispatchEnabled } from '../../../store/selectors/fareAssignation';
import { promiseDispatch } from '../../../utils/promiseDispatch';

type UnassignPorterButtonProps = {
  fareAssignationId: string;
  fareId: string;
  porterId: string;
  showConfirmation: boolean;
};

const UnassignPorterButton: React.FC<UnassignPorterButtonProps> = ({
  fareAssignationId,
  fareId,
  porterId,
  showConfirmation,
}) => {
  const dispatch = useDispatch();

  const isAutodispatchEnabled = useSelector(selectIsAutodispatchEnabled);
  const [isLoading, setLoading] = useState(false);

  const unassignPorter = ({ fareId, objectId, porterId }: { fareId: string; objectId: string; porterId: string }) => {
    setLoading(true);

    batch(() => {
      promiseDispatch(dispatch, removeFareAssignationAction({ fareId, fareAssignationId: objectId, porterId })).catch(
        (error) => {
          setLoading(false);
          toast.error(getToastableErrorMessage(error.message));
        }
      );
      dispatch(modalActions.hideModal(modalNames.confirm));
    });
  };

  const handleClick = () => {
    if (showConfirmation) {
      dispatch(
        modalActions.showModal({
          modalName: modalNames.confirm,
          params: {
            title: i18n.caution,
            content: i18n.cautionDeleteLastPorter,
            onConfirm: () =>
              unassignPorter({
                fareId,
                objectId: fareAssignationId,
                porterId,
              }),
          },
        })
      );
    } else {
      unassignPorter({
        fareId,
        objectId: fareAssignationId,
        porterId,
      });
    }
  };

  const isDisabled = isLoading || isAutodispatchEnabled;

  return (
    <Tooltip title={i18n.deleteAssignation}>
      <span>
        <Button variant="outlined" onClick={handleClick} disabled={isDisabled}>
          <Clear color={isDisabled ? 'disabled' : 'error'} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default UnassignPorterButton;
