import { Typography } from '@mui/material';
import { AreaManagementList } from './components/AreaManagementList';
import { useOrphanAreas } from './hooks/useOrphanAreas';

export const AreaManagementOrphans = ({ isReadOnly = false }: { isReadOnly?: boolean }) => {
  const { orphanAreas, isLoading } = useOrphanAreas();

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!orphanAreas.length) {
    return <Typography>Pas de résultats</Typography>;
  }

  return <AreaManagementList areas={orphanAreas} isReadOnly={isReadOnly} />;
};
