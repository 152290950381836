import Api, {
  Cloud,
  NotificationData,
  NotificationTriggerEvent,
  Notification as ParseNotification,
} from '@ambuliz/sabri-core';
import sound from 'common/assets/audio/notification.mp3';
import { i18n } from 'common/locale';
import { isNotificationSoundEnabled } from 'common/utils/notificationSound';
import { useAuthentication } from 'core/authentication';
import { liveQueryClient } from 'core/live-query-client';
import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import showNotificationToast from './showNotificationToast';
import { getPathFromNotification } from './triggerEventUtils';

type NotificationContextValue = {
  notifications: Notification[];
  badgeCount: number;
  loading?: boolean;
  setBadgeCount: (count: number) => void;
  readAll: () => Promise<void>;
  read: (id: string) => Promise<void>;
  unread: (id: string) => Promise<void>;
  getPath: (id: string) => string | undefined;
};

const mapNotification = (notification: ParseNotification): Notification => {
  const mappedNotification: Notification = {
    id: notification.id,
    read: !!notification.acknowledgedAt,
    date: notification.createdAt,
    triggerEvent: notification.triggerEvent,
    data: notification.data as NotificationData,
  };

  return mappedNotification;
};

const NotificationContext = createContext<NotificationContextValue>({} as NotificationContextValue);

const NotificationContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Record<string, Notification>>({});
  const [badgeCount, setBadgeCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [, setClickOnToast] = useState(false);
  const subscription = useRef<Parse.LiveQuerySubscription>();
  const { isLoggedIn } = useAuthentication();

  const subscribe = async () => {
    subscription.current = liveQueryClient.subscribe(
      new Api.Query(ParseNotification),
      Parse.User.current()?.getSessionToken()
    ) as Parse.LiveQuerySubscription;

    subscription.current.on('open', async () => {
      const fetchedNotifications = await new Api.Query(ParseNotification)
        .equalTo('sentNotifications.provider', 'WEB')
        .addDescending('createdAt')
        .find();
      const notifications = fetchedNotifications.map(mapNotification);

      setNotifications(
        notifications.reduce(
          (acc, notification) => ({
            ...acc,
            [notification.id]: notification,
          }),
          {}
        )
      );
      setBadgeCount(notifications.filter((notification) => !notification.read).length);

      setLoading(false);
    });

    const handleUpdate = (notification: any) => {
      const mappedNotification = mapNotification(notification);

      setNotifications((notifications) => ({ ...notifications, [mappedNotification.id]: mappedNotification }));
    };

    const handleDelete = (notification: any) => {
      setNotifications((notifications) => {
        delete notifications[notification.id];
        return notifications;
      });
    };

    const handleCreate = async (notification: any) => {
      handleUpdate(notification);
      const mappedNotification = mapNotification(notification);

      showNotificationToast(
        mappedNotification,
        () => setClickOnToast(true),
        () => {
          setClickOnToast((prev) => {
            if (!prev) {
              setBadgeCount((badgeCount) => badgeCount + 1);
            }
            return false;
          });
        }
      );
      if (isNotificationSoundEnabled()) {
        new Audio(sound).play();
      }
    };

    subscription.current.on('create', handleCreate);
    subscription.current.on('update', handleUpdate);
    subscription.current.on('enter', handleUpdate);
    subscription.current.on('delete', handleDelete);
    subscription.current.on('leave', handleDelete);
  };

  useEffect(() => {
    if (isLoggedIn) {
      subscribe();
    } else {
      setLoading(false);
    }

    return () => liveQueryClient.unsubscribe(subscription.current);
  }, [isLoggedIn]);

  const readAll = async () => {
    setLoading(true);
    liveQueryClient.unsubscribe(subscription.current);
    try {
      const notificationIds = Object.values(notifications)
        .filter((notification) => !notification.read)
        .map((notification) => notification.id);

      await Cloud.readAllNotifications({ notificationIds });
      setBadgeCount(0);
    } catch (err) {
      toast.error(i18n.errorReadingAllNotifications);
    } finally {
      subscribe();
    }
  };

  const read = async (id: string) => {
    try {
      await Cloud.acknowledgeNotification({ notificationId: id });
    } catch (err) {
      toast.error(i18n.errorReadingNotification);
    }
  };

  const unread = async (id: string) => {
    try {
      await Cloud.unreadNotification({ id });
    } catch (err) {
      toast.error(i18n.errorUnreadingNotification);
    }
  };

  const getPath = (id: string) => {
    const notification = notifications[id];

    return getPathFromNotification(notification);
  };

  const value: NotificationContextValue = {
    notifications: Object.values(notifications),
    badgeCount,
    loading,
    setBadgeCount: (count: number) => setBadgeCount(count),
    readAll,
    read,
    unread,
    getPath,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotifications = () => useContext(NotificationContext);

export type Notification = {
  id: string;
  read?: boolean;
  loading?: boolean;
  date: Date;
  triggerEvent: NotificationTriggerEvent;
  data: NotificationData;
};

export default NotificationContextProvider;
