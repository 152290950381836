import { Skeleton, Stack, Typography } from '@mui/material';
import { color } from 'common/theme';
type KanbanColumnHeaderProps = {
  header: string;
  subheader: string;
  loading?: boolean;
  isActive?: boolean;
};

const KanbanColumnHeader = ({ header, subheader, loading = false, isActive }: KanbanColumnHeaderProps) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      minWidth={330 - 48}
      bgcolor={isActive ? color.blue[10] : 'background.default'}
      boxSizing="content-box"
      flex={1}
      height="100%"
      paddingTop={6}
      paddingBottom={3}
      paddingX={6}
      sx={{
        '&:first-of-type': {
          paddingLeft: 10,
        },
        '&:last-of-type': {
          paddingRight: 10,
        },
      }}
    >
      <Typography variant="h3">{header}</Typography>
      {loading ? (
        <Skeleton variant="text" width={60} />
      ) : (
        <Typography variant="body2" fontWeight={600} color="secondary">
          {subheader}
        </Typography>
      )}
    </Stack>
  );
};

export default KanbanColumnHeader;
