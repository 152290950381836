import { useState } from 'react';

export const usePagination = (defaultPageSize: number = 30) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [count, setCount] = useState(0);

  return {
    currentPageIndex,
    count,
    pageSize,
    setCurrentPageIndex,
    setPageSize,
    setCount,
  };
};

export type Pagination = ReturnType<typeof usePagination>;
