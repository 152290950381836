import { Porter } from '@ambuliz/sabri-core';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentPorterFare, selectNextFareForPorter } from 'sabri/store/selectors/fare';
import { i18n } from '../../locales';
import { AvatarName } from '../AvatarName/AvatarName';
import { ChipPorterStatus } from '../ChipPorterStatus/ChipPorterStatus';
import LocationDetails from '../LocationDetails/LocationDetails';

type PorterAssignationsTableRowProps = {
  porter: Porter;
  Actions?: ReactNode;
};

export const PorterAssignationsTableRow: React.FC<PorterAssignationsTableRowProps> = ({ porter, Actions }) => {
  const hasAssignations = !!porter.fareAssignedCount && porter.fareAssignedCount > 0;

  const currentPorterFare = useSelector(selectCurrentPorterFare(porter.id));
  const nextPorterFare = useSelector(selectNextFareForPorter(porter.id));

  const nextAreaId = currentPorterFare?.toAreaId || nextPorterFare?.toAreaId;
  const nextUnitId = currentPorterFare?.toUnitId || nextPorterFare?.toUnitId;
  const nextAreaWantedDate = currentPorterFare?.wantedDate || nextPorterFare?.wantedDate;

  return (
    <TableRow>
      <TableCell>
        <AvatarName
          user={{
            firstName: porter.firstName,
            lastName: porter.lastName,
          }}
        />
      </TableCell>
      <TableCell>
        {porter.status && (
          <ChipPorterStatus status={porter.status} hasInternet={porter.hasInternet} hasAssignations={hasAssignations} />
        )}
      </TableCell>
      <TableCell>
        <LocationDetails unitId={porter.lastUnit?.id} areaId={porter.lastArea?.id} />
        {porter.lastAreaAt && (
          <Typography variant="caption" color="textSecondary">
            {i18n.thereIsDate(formatDistanceToNowStrict(new Date(porter.lastAreaAt), { locale: fr }))}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {porter.status !== 'AVAILABLE' && (
          <>
            <LocationDetails unitId={nextUnitId} areaId={nextAreaId} />
            {nextAreaWantedDate && (
              <Typography variant="caption" color="textSecondary">
                {i18n.intoDate(formatDistanceToNowStrict(new Date(nextAreaWantedDate), { locale: fr }))}
              </Typography>
            )}
          </>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          <Box component="span" fontWeight="bold">
            {porter.fareDoneCount || 0}
          </Box>
          {` (${porter.fareAssignedCount || 0})`}
        </Typography>
      </TableCell>
      <TableCell>{Actions}</TableCell>
    </TableRow>
  );
};
