import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';

const UnitsTableSkeleton: React.FC = () => {
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Skeleton variant="rectangular" height={56} style={{ borderRadius: 8 }} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rectangular" height={56} style={{ borderRadius: 8 }} animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton variant="rectangular" height={56} style={{ borderRadius: 8 }} animation="wave" />
      </Grid>
    </Grid>
  );
};

export default UnitsTableSkeleton;
