import { SvgIcon, SvgIconProps } from '@mui/material';

const Comment: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000366211 8C-0.000366211 3.58161 3.58124 0 7.99963 0C12.418 0 15.9996 3.58161 15.9996 8C15.9996 12.4182 12.4183 15.9997 8.0001 16C6.67396 16.0019 5.36958 15.6728 4.20473 15.0441L1.10849 15.6207C0.905744 15.6585 0.697416 15.5939 0.551588 15.448C0.405761 15.3022 0.341171 15.0939 0.378927 14.8911L0.955496 11.795C0.345539 10.6656 -0.000366211 9.37169 -0.000366211 8ZM2.22696 11.8017L1.77594 14.2237L4.1979 13.7727C4.3447 13.7453 4.49645 13.7714 4.6257 13.8462C5.65078 14.4392 6.81538 14.7506 7.99963 14.7488C11.727 14.7488 14.7484 11.7274 14.7484 8C14.7484 4.27263 11.727 1.25121 7.99963 1.25121C4.27226 1.25121 1.25084 4.27263 1.25084 8C1.25084 9.23005 1.57954 10.3826 2.15335 11.3738C2.2282 11.503 2.25431 11.6549 2.22696 11.8017ZM3.69302 6.35354C3.69302 6.00803 3.97311 5.72794 4.31862 5.72794H11.6961C12.0416 5.72794 12.3217 6.00803 12.3217 6.35354C12.3217 6.69906 12.0416 6.97915 11.6961 6.97915H4.31862C3.97311 6.97915 3.69302 6.69906 3.69302 6.35354ZM3.69302 9.6734C3.69302 9.32789 3.97311 9.04779 4.31862 9.04779H10.2206C10.5661 9.04779 10.8462 9.32789 10.8462 9.6734C10.8462 10.0189 10.5661 10.299 10.2206 10.299H4.31862C3.97311 10.299 3.69302 10.0189 3.69302 9.6734Z"
      />
    </SvgIcon>
  );
};

export default Comment;
