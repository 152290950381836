"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_HEALTH_CENTER_TIMEZONE = exports.minDate = exports.maxDate = exports.addMonths = exports.subMonths = void 0;
function subMonths(date, amount) {
    date.setMonth(date.getMonth() - amount);
    return date;
}
exports.subMonths = subMonths;
function addMonths(date, amount) {
    date.setMonth(date.getMonth() + amount);
    return date;
}
exports.addMonths = addMonths;
exports.maxDate = new Date(4102358400000);
exports.minDate = new Date(0);
exports.DEFAULT_HEALTH_CENTER_TIMEZONE = 'Europe/Paris';
