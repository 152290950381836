import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

export type FormRowProps = {
  label?: string;
  children?: ReactNode;
};

const FormRow: React.FC<FormRowProps> = ({ label, children }) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" style={{ minHeight: 48 }}>
      <Grid item>{!!label && <Typography>{label}</Typography>}</Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default FormRow;
