import { useMediaQuery } from '@mui/material';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

export type AppBarSelectOption = {
  id: string;
  label: string;
  children: AppBarSelectOption[];
  path?: string;
};
export type AppBarSelect = {
  basePath?: string;
  value: string | string[];
  subLabel?: string;
  options?: AppBarSelectOption[];
  label?: string;
  multiple?: boolean;
  icon?: React.ReactNode;
};

export type Tab = {
  label: string;
  path: string;
  basePath?: string;
  isDefault?: boolean;
};

type AppBarContextProps = {
  appBarSelect?: AppBarSelect;
  setAppBarSelect: (select?: AppBarSelect) => void;
  tabs: Tab[];
  appBarHeight: number;
  isFullScreen: boolean;
  setTabs: (tabs: Tab[]) => void;
  setFullScreen: (isFullScreen: boolean) => void;
};

const AppBarContext = createContext<AppBarContextProps>({} as AppBarContextProps);

export function AppBarContextProvider({ children }: PropsWithChildren<{}>) {
  const [appBarSelect, setAppBarSelect] = useState<AppBarSelect>();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [isFullScreen, setFullScreen] = useState(false);
  const isSm = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const handleKeyPressed = (event: KeyboardEvent) => {
      if (isFullScreen && event.key === 'Escape') {
        setFullScreen(false);
      }
    };

    document.addEventListener('keydown', handleKeyPressed);
    return () => document.removeEventListener('keydown', handleKeyPressed);
  }, [isFullScreen]);

  const handleTabsUpdate = (tabs: Tab[]) => {
    setTabs((old) => (JSON.stringify(old) === JSON.stringify(tabs) ? old : tabs));
  };

  const value = {
    appBarSelect,
    setAppBarSelect,
    tabs,
    appBarHeight: !isFullScreen ? (isSm ? APP_BAR_HEIGHT_MOBILE : APP_BAR_HEIGHT) : 0,
    isFullScreen,
    setTabs: handleTabsUpdate,
    setFullScreen,
  };

  return <AppBarContext.Provider value={value}>{children}</AppBarContext.Provider>;
}

const APP_BAR_HEIGHT = 81;
const APP_BAR_HEIGHT_MOBILE = 65;

type UseAppBarProps = {
  appBarSelect?: AppBarSelect;
};

export const useAppBarContext = (props?: UseAppBarProps) => {
  const { setAppBarSelect, ...context } = useContext(AppBarContext);

  useEffect(() => {
    if (!!props?.appBarSelect) {
      setAppBarSelect(props?.appBarSelect);
    }
  }, [setAppBarSelect, props?.appBarSelect]);

  return { ...context, setAppBarSelect };
};
