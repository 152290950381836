import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogHeader } from 'common/components';
import { MouseEvent } from 'react';
import { i18n } from 'sabri/locales';

type CancelJourneyConfirmModalProps = {
  open: boolean;
  loading?: boolean;
  onClose: (event: MouseEvent) => void;
  onConfirm: (event: MouseEvent) => void;
};

const CancelJourneyConfirmModal = ({ open, loading = false, onClose, onConfirm }: CancelJourneyConfirmModalProps) => {
  return (
    <Dialog
      maxWidth="sm"
      PaperProps={{ onClick: (event: MouseEvent) => event.stopPropagation() }}
      open={open}
      onClose={onClose}
    >
      <DialogHeader title={i18n.cancelJourneyModal.title} onClose={onClose} />
      <DialogContent>
        {i18n.cancelJourneyModal.content.map((text, index) => (
          <Typography key={index}>{text}</Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {i18n.cancelJourneyModal.cancelLabel}
        </Button>
        <LoadingButton onClick={onConfirm} color="primary" loading={loading}>
          {i18n.cancelJourneyModal.confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelJourneyConfirmModal;
