import routes from 'sabri/routes';

const rootPath = routes.root;

export const routeNamesCommon = {
  root: rootPath + '/',
  login: rootPath + '/login',
  logout: rootPath + '/logout',
  linker: rootPath + '/linker',
  linkerTest: rootPath + '/linkerTest',
  fareList: rootPath + '/fares',
  fareListActive: rootPath + '/fares/active',
  fareListDraft: rootPath + '/fares/draft',
  fareListDone: rootPath + '/fares/done',
  fareListPending: rootPath + '/fares/pending',
  fareListInProgress: rootPath + '/fares/in-progress',
  fareListToConfirmIn: rootPath + '/fares/to-confirm-in',
  fareListToConfirmOut: rootPath + '/fares/to-confirm-out',
  fareDetails: rootPath + '/fares/details/:id',
  fareCreation: rootPath + '/fares/create',
  fareEditDraft: rootPath + '/fares/edit/:id',
  fareNewIncident: rootPath + '/fares/new-incident/:id',
  archives: rootPath + '/archives',
  archivedFareDetails: rootPath + '/archives/details/:id',
  teamAll: rootPath + '/team/all',
  incidents: rootPath + '/incidents',
  fareDetailsIncident: rootPath + '/incidents/details/:id',
  team: rootPath + '/team',
  teamAvailable: rootPath + '/team/available',
  teamAssigned: rootPath + '/team/assigned',
  teamBusy: rootPath + '/team/busy',
  statistics: rootPath + '/statistics',
  statisticsPorter: rootPath + '/statistics/porter',
  statisticsIncident: rootPath + '/statistics/incident',
  statisticsService: rootPath + '/statistics/service',
  callPatient: rootPath + '/callPatient',
};

export const routeNamesCommonDynamic = {
  fareDetails: (fareId: string) => rootPath + `/fares/details/${fareId}`,
  fareEditDraft: (fareId: string) => rootPath + `/fares/edit/${fareId}`,
  fareNewIncident: (fareId: string) => rootPath + `/fares/new-incident/${fareId}`,
  archivedFareDetails: (fareId: string) => rootPath + `/archives/details/${fareId}`,
  incidentFareDetails: (fareId: string) => rootPath + `/incidents/details/${fareId}`,
};

export const routeNamesRedirections = {
  [routeNamesCommon.fareDetails]: routeNamesCommon.fareList,
  [routeNamesCommon.fareCreation]: routeNamesCommon.fareList,
  [routeNamesCommon.fareEditDraft]: routeNamesCommon.fareList,
  [routeNamesCommon.fareDetailsIncident]: routeNamesCommon.incidents,
};
