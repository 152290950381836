import { EmojiSize } from 'common/components/Emoji/type';

export default function getEmojiSize(size: EmojiSize): number {
  switch (size) {
    case 'large':
      return 40;
    case 'medium':
      return 28;
    case 'normal':
      return 22;
    case 'small':
      return 18;
    case 'extra-small':
      return 16;
    default:
      return size;
  }
}
