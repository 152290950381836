import { Accommodation, Anomaly } from '@ambuliz/sabri-core';
import useParseQuery from 'common/hooks/useParseQuery';

type UseAnomaliesProps = {
  accommodations: Accommodation[];
  types?: string[];
};

const useAnomalies = ({ accommodations, types = [] }: UseAnomaliesProps) => {
  const query = new Parse.Query(Anomaly).containedIn('accommodation', accommodations).equalTo('status', 'OPENED');

  if (types.length > 0) {
    query.containedIn('type', types);
  }
  const { results, isLoading, isEnabled } = useParseQuery(query, {
    enabled: accommodations.length > 0,
  });

  return {
    anomalies: results,
    isLoading,
    isEnabled,
  };
};

export default useAnomalies;
