import { Components } from '@mui/material';
import color from '../color';

const MuiSlider: Components['MuiSlider'] = {
  defaultProps: {},
  styleOverrides: {
    thumbColorPrimary: {
      backgroundColor: 'white',
      filter: 'drop-shadow(0px 1px 2px rgba(20, 22, 28, 0.06))',
      border: '1px solid ' + color.grey[10],
    },
    rail: {
      backgroundColor: color.grey[10],
    },
  },
};

export default MuiSlider;
