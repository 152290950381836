import { Components } from '@mui/material';
import shadows from '../shadows';
import transition from '../transition';

const MuiTableRow: Components['MuiTableRow'] = {
  styleOverrides: {
    root: {
      '&.MuiTableRow-hover:hover': {
        boxShadow: shadows[2],
        backgroundColor: 'transparent',
        transition: `box-shadow ${transition}`,
      },
    },
  },
};

export default MuiTableRow;
