import { User, useAuthentication } from 'core/authentication';
import { useMemo } from 'react';
import { Unit, useLocations } from './LocationContext';

const useNavigableUnits = () => {
  const { user, unit, units: whitelistUnits } = useAuthentication();
  const { loading, units: allUnits, departments } = useLocations();

  const navigableUnits = useMemo(
    () => getNavigableUnits(user, allUnits, unit, whitelistUnits),
    [allUnits, user, unit, whitelistUnits]
  );

  const navigableDepartments = useMemo(
    () =>
      whitelistUnits && whitelistUnits.length > 0
        ? departments
            .map((department) => ({
              ...department,
              units: department.units.filter((departmentUnit) => whitelistUnits.includes(departmentUnit.id)),
            }))
            .filter((department) => department.units.length > 0)
        : departments,
    [departments, whitelistUnits]
  );

  return {
    loading,
    units: navigableUnits,
    departments: navigableDepartments,
  };
};

const getNavigableUnits = (user: User, allUnits: Unit[], userUnit?: Unit, whitelistUnits?: string[]): Unit[] => {
  if (user.role === 'MANAGER') {
    if (userUnit) {
      return [userUnit];
    }
    return [];
  } else {
    if (whitelistUnits?.length) {
      return allUnits.filter((allUnit) => whitelistUnits.includes(allUnit.id));
    }
    return allUnits;
  }
};

export default useNavigableUnits;
