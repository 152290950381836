import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormValue } from 'sabri/forms/JourneyForm/JourneyForm';
import { i18n } from '../../locales';
import { UnitStored } from '../../store/reducers/entity';
import { selectUnits } from '../../store/selectors/unit';

export type UnitAutoCompleteProps = {
  name: string;
  error?: FieldError;
  disabled?: boolean;
};

export const UnitAutoComplete: React.FC<UnitAutoCompleteProps> = ({ name, error, disabled = false }) => {
  const { clearErrors, watch, setValue, control } = useFormContext<FormValue>();

  const units = useSelector(selectUnits) || [];
  const valueId = watch<string, string | undefined>(name, '');

  const correspondingAreaInputName = name.replace('Unit', 'Area');
  const correspondingAreaId = watch<string, string | undefined>(correspondingAreaInputName);

  const value = units.find((unit) => unit.objectId === valueId) || null;
  const formatOptionLabel = (option: UnitStored) =>
    option.externalId ? `${option.name} - ${option.externalId}` : option.name;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={valueId}
      render={({ onChange }) => (
        <Autocomplete
          options={units}
          blurOnSelect
          autoHighlight
          openOnFocus
          disabled={disabled}
          noOptionsText={i18n.noServiceFound}
          value={value}
          onChange={(_, nextValue) => {
            if (nextValue && typeof nextValue !== 'string') {
              onChange(nextValue.objectId);
              clearErrors(name);
            } else {
              onChange('');
            }
            if (nextValue !== value && correspondingAreaId) {
              setValue(correspondingAreaInputName, '');
              clearErrors(correspondingAreaInputName);
            }
          }}
          getOptionLabel={formatOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n.service}
              placeholder={i18n.selectService}
              error={!!error}
              sx={{ width: 200 }}
            />
          )}
        />
      )}
    />
  );
};
