import { Fare } from '@ambuliz/sabri-core';
import { CircularProgress, Dialog, DialogContent, Stack } from '@mui/material';
import useParseResource from 'common/hooks/useParseResource';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import JourneyForm from 'sabri/forms/JourneyForm';
import { useCloseModal } from '../../hooks/useCloseModal';
import useJourneyFares from '../../hooks/useJourneyFares';

const JourneyFormModal = () => {
  const { id } = useParams<{ id: string }>();

  const closeModal = useCloseModal();

  const isFareQueryEnabled = useMemo(() => !!id, [id]);
  const { resource: fare, isLoading: isFareQueryLoading } = useParseResource(id, Fare, {
    include: ['incidents', 'journey.startingUnit', 'journey.startingArea'],
    enabled: isFareQueryEnabled,
  });

  const { journeyFares, isLoading: isJourneyFaresQueryLoading } = useJourneyFares(fare?.journey);

  const isLoading = useMemo(
    () => isFareQueryLoading || isJourneyFaresQueryLoading,
    [isFareQueryLoading, isJourneyFaresQueryLoading]
  );

  return (
    <Dialog maxWidth="lg" open onClose={closeModal} scroll="body">
      {isLoading ? (
        <DialogContent>
          <Stack justifyContent="center" alignItems="center" height={300}>
            <CircularProgress size={40} />
          </Stack>
        </DialogContent>
      ) : (
        <JourneyForm onClose={closeModal} fare={fare} journeyFares={journeyFares} />
      )}
    </Dialog>
  );
};

export default JourneyFormModal;
