import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { StatisticsContext } from './Context';

type ContainerProps = {
  children?: ReactNode;
  loading?: boolean;
};

const Container = ({ children, loading }: ContainerProps) => {
  return (
    <StatisticsContext.Provider value={{ loading: loading || false }}>
      <Grid container spacing={4}>
        {children}
      </Grid>
    </StatisticsContext.Provider>
  );
};

export default Container;
