import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InfoIcon from 'common/components/Icons/Info';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { i18n } from 'sabri/locales';
import { AreaStored, UnitStored } from 'sabri/store/reducers/entity';
import { selectAreaBreadcrumb } from 'sabri/store/selectors/area';
import { selectUnits } from 'sabri/store/selectors/unit';
import styled from 'styled-components';
import IsValidIcon from './IsValidIcon';
import { FormValue, formKeys } from './JourneyForm';

const ReturnFare = () => {
  const { watch } = useFormContext<FormValue>();

  const roundTrip = watch<string, boolean>(formKeys.roundTrip);
  const fromUnitId = watch<string, string | undefined>(formKeys.fromUnitId);
  const fromAreaId = watch<string, string | undefined>(formKeys.fromAreaId);

  const units = useSelector(selectUnits) || [];
  const unit = units.find((unit) => unit.objectId === fromUnitId) || null;
  const areaBreadcrumb = useSelector(selectAreaBreadcrumb(fromAreaId));

  const formatOptionLabel = (option: UnitStored) =>
    option.externalId ? `${option.name} - ${option.externalId}` : option.name;

  return (
    <Stack spacing={2}>
      <Stack direction="row" gap={4}>
        <Box width={54} height={40} display="flex" alignItems="center">
          <Typography variant="overline">{i18n.return}</Typography>
        </Box>
        <Controller
          name={formKeys.roundTrip}
          render={({ onChange, value }) => (
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={value} onChange={(event) => onChange(event.target.checked)} />
              }
              label={i18n.returnToStartingPoint}
            />
          )}
        />
      </Stack>
      {roundTrip && (
        <Stack direction="row" gap={4} style={{ marginLeft: 70 }} flex={1}>
          <Stack direction="row" gap={4} flex={1}>
            <Stack direction="row" gap={2}>
              <Autocomplete
                value={unit}
                options={[]}
                disabled
                renderInput={(params) => <TextField {...params} label={i18n.service} sx={{ width: 200 }} />}
                getOptionLabel={formatOptionLabel}
              />
              <StyledAutoComplete
                value={areaBreadcrumb}
                options={[]}
                multiple
                disabled
                renderInput={(params) => <TextField {...params} label={i18n.selectARoom} />}
                renderTags={renderTags}
              />
            </Stack>
            <Stack direction="row" gap={2} alignItems="center" flex={1} sx={{ opacity: !fromUnitId ? 1 : 0 }}>
              <InfoIcon sx={{ fontSize: 12 }} color="secondary" />
              <Typography variant="body2" color="secondary">
                {i18n.fillStartingPointMessage}
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end">
            <IsValidIcon isVisible={!!fromUnitId} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const renderTags = (areas: AreaStored[], props: AutocompleteGetTagProps) =>
  areas.map((area, index) =>
    index < areas.length - 1 ? (
      <Chip
        label={area.name}
        {...props({ index })}
        disabled
        variant="light"
        color="default"
        deleteIcon={<KeyboardArrowRightOutlined />}
      />
    ) : (
      <Chip variant="light" color="default" label={area.name} {...props({ index })} />
    )
  );

const StyledAutoComplete = styled(Autocomplete)`
  & input {
    min-width: ${({ disabled }) => (disabled ? '0px !important' : undefined)};
  }
  & .MuiOutlinedInput-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  width: 201px;
  & .MuiFormLabel-root.MuiInputLabel-root:not(.MuiInputLabel-shrink) {
    max-width: calc(100% - 42px);
  }
` as typeof Autocomplete;

export default ReturnFare;
