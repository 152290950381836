import Statistics from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { getThresholdedColor } from 'kurt/const';
import { CircleMarker, Tooltip } from 'react-leaflet';
import styled from 'styled-components';

const TooltipContainer = styled(Tooltip)`
  border: 1px solid ${color.grey[5]};
  padding: 0;
  margin: 0;
`;

type HealthCenterTooltipProps = {
  groupNames: string[];
  healthCenterName: string;
  occupancyRate?: number;
  occupatedBeds?: number;
  totalOfBeds?: number;
  availableBeds?: number;
  location: { latitude?: number; longitude?: number };
  loading: boolean;
};

const getFillcolor = (occupancyRate?: number) => {
  const thresholdedColor = getThresholdedColor(occupancyRate);

  switch (thresholdedColor) {
    case 'success':
      return palette.success.main;
    case 'danger':
      return palette.danger.main;
    case 'error':
      return palette.error.main;
    default:
      return color.grey[40];
  }
};

const HealthCenterTooltip: React.FC<HealthCenterTooltipProps> = ({
  loading,
  healthCenterName,
  occupatedBeds,
  occupancyRate,
  groupNames,
  totalOfBeds,
  availableBeds,
  location,
}) => {
  if (!location.latitude || !location.longitude) {
    return null;
  }
  return (
    <CircleMarker
      center={[location.latitude, location.longitude]}
      radius={8}
      color="white"
      fillOpacity={1}
      fillColor={getFillcolor(occupancyRate)}
    >
      <TooltipContainer>
        <Statistics.Container loading={loading}>
          <Statistics.Card xs={12} header={{ title: healthCenterName }} subheader={groupNames.join(', ')}>
            <Statistics.Item title={{ label: i18n.occupancy, color: 'default', variant: 'light' }}>
              <Statistics.ItemContent>
                <Statistics.Text variant="lg" sx={{ mr: 8 }} color={getThresholdedColor(occupancyRate)}>
                  {occupancyRate}%
                </Statistics.Text>
                <Statistics.Text variant="lg">
                  {occupatedBeds}
                  <Statistics.Text span>{` /${totalOfBeds}`}</Statistics.Text>
                </Statistics.Text>
              </Statistics.ItemContent>
            </Statistics.Item>
            <Statistics.Item title={{ label: i18n.availableBeds, color: 'default', variant: 'light' }}>
              <Statistics.ItemContent>
                <Statistics.Text variant="lg">{availableBeds}</Statistics.Text>
              </Statistics.ItemContent>
            </Statistics.Item>
          </Statistics.Card>
        </Statistics.Container>
      </TooltipContainer>
    </CircleMarker>
  );
};

export default HealthCenterTooltip;
