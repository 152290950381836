import {
  DEFAULT_NOTIFICATION_CONFIGURATION,
  NotificationConfiguration,
  NotificationProvider,
  NotificationTriggerEvent,
  notificationProvider,
  notificationTriggerEvent,
} from '@ambuliz/sabri-core';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  ListItemText,
  ListSubheader,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { DialogHeader, FilterSelect, InputIncrement } from 'common/components';
import { i18n } from 'common/locale';
import { useEffect, useState } from 'react';
import { getTriggerEventGroupLabel, getTriggerEventLabel, getTriggerEventOptions } from './NotificationsSettings';

const DEFAULT_RETRY_DELAY = 5;
const DEFAULT_MAX_RETRY = 0;

type NotificationConfigDialogProps = {
  open: boolean;
  assignedNotificationsConfig?: ReadonlyArray<NotificationConfiguration>;
  notificationConfigToEdit?: Readonly<NotificationConfiguration>;
  onClose?: () => void;
  onSubmit?: (notificationConfig: NotificationConfiguration) => void;
};

const NotificationConfigDialog = ({
  open,
  assignedNotificationsConfig,
  notificationConfigToEdit,
  onClose,
  onSubmit,
}: NotificationConfigDialogProps) => {
  const [notifTriggerEvent, setNotifTriggerEvent] = useState<NotificationTriggerEvent | undefined>(
    notificationConfigToEdit?.triggerEvent
  );
  const [notifIsAcknowledgeNeeded, setNotifIsAcknowledgeNeeded] = useState<boolean | undefined>(
    notificationConfigToEdit?.isAcknowledgeNeeded
  );
  const [notifRetryDelay, setNotifRetryDelay] = useState<number>(
    notificationConfigToEdit?.retryPolicy?.retryDelayInSeconds || DEFAULT_RETRY_DELAY
  );
  const [notifMaxRetry, setNotifMaxRetry] = useState<number>(
    notificationConfigToEdit?.retryPolicy?.maxRetryCount || DEFAULT_MAX_RETRY
  );
  const [notifProviders, setNotifProviders] = useState<Set<NotificationProvider>>(
    new Set(notificationConfigToEdit?.notificationsProviders)
  );
  const [isNotificationDisabled, setIsNotificationDisabled] = useState<boolean>(
    notificationConfigToEdit?.notificationsProviders.length === 0
  );
  const triggerEventOptions = getTriggerEventOptions(getNotAssignedTriggerEvents(assignedNotificationsConfig));
  const isEditing = isEditingNotificationConfig(notificationConfigToEdit);
  const isFormDisabled =
    !notifTriggerEvent ||
    !!(notifIsAcknowledgeNeeded && (notifRetryDelay === undefined || notifMaxRetry === undefined)) ||
    (!isNotificationDisabled && notifProviders.size === 0);

  const onNotificationSubmit = () => {
    if (isFormDisabled) {
      return;
    }
    onSubmit?.({
      ...notificationConfigToEdit,
      triggerEvent: notifTriggerEvent,
      isAcknowledgeNeeded: notifIsAcknowledgeNeeded || false,
      retryPolicy: notifIsAcknowledgeNeeded
        ? {
            retryDelayInSeconds: notifRetryDelay ?? 0,
            maxRetryCount: notifMaxRetry ?? 0,
          }
        : undefined,
      notificationsProviders: [...notifProviders],
    });
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const newProviders = new Set(notificationConfigToEdit?.notificationsProviders);

    setNotifTriggerEvent(notificationConfigToEdit?.triggerEvent);
    setNotifIsAcknowledgeNeeded(notificationConfigToEdit?.isAcknowledgeNeeded);
    setNotifRetryDelay(notificationConfigToEdit?.retryPolicy?.retryDelayInSeconds || DEFAULT_RETRY_DELAY);
    setNotifMaxRetry(notificationConfigToEdit?.retryPolicy?.maxRetryCount || DEFAULT_MAX_RETRY);
    setNotifProviders(newProviders);
    setIsNotificationDisabled(newProviders.size === 0);
  }, [notificationConfigToEdit, open]);

  const setDefaultTriggerEventConfig = (triggerEvent: NotificationTriggerEvent) => {
    const newProviders = new Set(DEFAULT_NOTIFICATION_CONFIGURATION[triggerEvent].notificationsProviders);

    setNotifTriggerEvent(triggerEvent);
    setNotifIsAcknowledgeNeeded(DEFAULT_NOTIFICATION_CONFIGURATION[triggerEvent].isAcknowledgeNeeded);
    setNotifRetryDelay(
      DEFAULT_NOTIFICATION_CONFIGURATION[triggerEvent].retryPolicy?.retryDelayInSeconds || DEFAULT_RETRY_DELAY
    );
    setNotifMaxRetry(DEFAULT_NOTIFICATION_CONFIGURATION[triggerEvent].retryPolicy?.maxRetryCount || DEFAULT_MAX_RETRY);
    setNotifProviders(newProviders);
    setIsNotificationDisabled(newProviders.size === 0);
  };

  const toggleDisableNotification = (toggle: boolean) => {
    if (toggle) {
      setNotifProviders(new Set());
    } else if (notifTriggerEvent) {
      setNotifProviders(new Set(DEFAULT_NOTIFICATION_CONFIGURATION[notifTriggerEvent].notificationsProviders));
    }
    setIsNotificationDisabled(toggle);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        title={isEditing ? getTriggerEventLabel(notificationConfigToEdit.triggerEvent) : i18n.newNotificationConfig}
        onClose={onClose}
      />
      <DialogContent>
        <Stack spacing={4}>
          {!isEditing && (
            <FilterSelect
              label={i18n.triggerEvent}
              value={notifTriggerEvent || ''}
              onChange={(triggerEvent) => {
                if (notificationTriggerEvent.includes(triggerEvent)) {
                  setDefaultTriggerEventConfig(triggerEvent);
                }
              }}
              required
            >
              {triggerEventOptions['fares'].length > 0 && (
                <ListSubheader>{getTriggerEventGroupLabel('fares')}</ListSubheader>
              )}
              {triggerEventOptions['fares'].map((triggerEvent) => getTriggerEventSelectOption(triggerEvent))}

              {triggerEventOptions['porters'].length > 0 && (
                <ListSubheader>{getTriggerEventGroupLabel('porters')}</ListSubheader>
              )}
              {triggerEventOptions['porters'].map((triggerEvent) => getTriggerEventSelectOption(triggerEvent))}

              {triggerEventOptions['movements'].length > 0 && (
                <ListSubheader>{getTriggerEventGroupLabel('movements')}</ListSubheader>
              )}
              {triggerEventOptions['movements'].map((triggerEvent) => getTriggerEventSelectOption(triggerEvent))}

              {triggerEventOptions['others'].length > 0 && (
                <ListSubheader>{getTriggerEventGroupLabel('others')}</ListSubheader>
              )}
              {triggerEventOptions['others'].map((triggerEvent) => getTriggerEventSelectOption(triggerEvent))}
            </FilterSelect>
          )}

          {notifTriggerEvent && !isNotificationDisabled && (
            <FilterSelect
              label={i18n.platforms}
              value={[...notifProviders]}
              onChange={(value) => {
                if (value.every((provider) => notificationProvider.includes(provider))) {
                  setNotifProviders(new Set(value));
                }
              }}
              multiple
            >
              {notificationProvider
                .filter((provider) =>
                  DEFAULT_NOTIFICATION_CONFIGURATION[notifTriggerEvent]?.notificationsProviders.includes(provider)
                )
                .map((provider) => (
                  <MenuItem key={provider} value={provider}>
                    <Checkbox checked={notifProviders.has(provider)} />
                    <ListItemText primary={i18n.notificationProviders[provider]} />
                  </MenuItem>
                ))}
            </FilterSelect>
          )}

          {notifTriggerEvent && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>{i18n.disableNotification}</Typography>
              <Switch
                checked={isNotificationDisabled}
                onChange={(event) => toggleDisableNotification(event.target.checked)}
              />
            </Stack>
          )}

          {notifProviders.has('IN_APP') && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>{i18n.notificationIsAcknowledgeNeeded}</Typography>
              <Switch
                checked={notifIsAcknowledgeNeeded}
                onChange={(event) => setNotifIsAcknowledgeNeeded(event.target.checked)}
              />
            </Stack>
          )}

          {notifProviders.has('IN_APP') && notifIsAcknowledgeNeeded && (
            <Stack spacing={3}>
              <Typography variant="h5">{i18n.retryPolicy}</Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack>
                  <Typography>{i18n.retryDelay}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {i18n.inSeconds}
                  </Typography>
                </Stack>
                <InputIncrement value={notifRetryDelay} onChange={setNotifRetryDelay} />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography>{i18n.retryMaxAttempts}</Typography>
                <InputIncrement value={notifMaxRetry} min={1} onChange={setNotifMaxRetry} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {i18n.cancel}
        </Button>
        <Button onClick={onNotificationSubmit} disabled={isFormDisabled}>
          {i18n.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getTriggerEventSelectOption = (triggerEvent: NotificationTriggerEvent) => (
  <MenuItem key={triggerEvent} value={triggerEvent} sx={{ paddingLeft: 6 }}>
    <ListItemText primary={getTriggerEventLabel(triggerEvent)} />
  </MenuItem>
);

const isEditingNotificationConfig = (
  notificationConfig?: NotificationConfiguration
): notificationConfig is NotificationConfiguration => !!notificationConfig?.triggerEvent;

const getNotAssignedTriggerEvents = (assignedNotificationsConfig?: ReadonlyArray<NotificationConfiguration>) =>
  notificationTriggerEvent.filter(
    (triggerEvent) => !assignedNotificationsConfig?.some((config) => config.triggerEvent === triggerEvent)
  );

export default NotificationConfigDialog;
