import { Components } from '@mui/material';

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      transform: 'translate(12px, 11px) scale(1)',
    },
    sizeSmall: {
      transform: 'translate(8px, 7px) scale(1)',
      '& .MuiInputLabel-shrink': {
        transform: 'translate(10px, -4px) scale(0.75)',
      },
    },
    shrink: {
      transform: 'translate(14px, -8px) scale(0.75)',
    },
  },
};

export default MuiInputLabel;
