import { PatientGender } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { isValid } from 'date-fns';
import React, { useEffect, useState } from 'react';

export type Patient = {
  firstName: string;
  lastName: string;
  birthdate: Date;
  gender: PatientGender;
  ipp: string;
  pan: string;
};

type CreatePatientFormProps = {
  onSubmit: (patient: Patient) => void;
  reset: boolean;
  loading: boolean;
};

const validateForm = (patient: Patient) => {
  const error = {
    firstName: false,
    lastName: false,
    birthdate: false,
  };

  if (!patient.firstName || patient.firstName.trim().length <= 0) {
    error.firstName = true;
  }

  if (!patient.lastName || patient.lastName.trim().length <= 0) {
    error.lastName = true;
  }
  if (!patient.birthdate) {
    error.birthdate = true;
  }

  return error;
};

const initalPatientState = {
  firstName: '',
  lastName: '',
  ipp: '',
  pan: '',
  gender: 'FEMALE',
} as Patient;

const CreatePatientForm: React.FC<CreatePatientFormProps> = ({ onSubmit, loading, reset }) => {
  const [patient, setPatient] = useState<Patient>(initalPatientState);

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    birthdate: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    if (event.target.name === 'firstName') {
      setError({ ...error, firstName: false });
    } else if (event.target.name === 'lastName') {
      setError({ ...error, lastName: false });
    }
    setPatient({ ...patient, [event.target.name]: event.target.value });
  };

  const handleDateChange = (birthdate: Date | null) => {
    if (birthdate && isValid(birthdate)) {
      birthdate.setHours(0);
      birthdate.setMinutes(0);
      birthdate.setSeconds(0);
      setPatient({ ...patient, birthdate });
    }
  };

  const handleKeyboardDateChange = (value: Date | null, context: FieldChangeHandlerContext<unknown>) => {
    if (!context.validationError) {
      handleDateChange(value);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const error = validateForm(patient);
    setError(error);
    if (!error.birthdate && !error.firstName && !error.lastName) {
      onSubmit(patient);
    }
  };

  useEffect(() => {
    if (reset) {
      setPatient(initalPatientState);
    }
  }, [reset]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container item spacing={2} direction="column" md={8} lg={6}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <RadioGroup row aria-label="gender" name="gender" value={patient.gender} onChange={handleChange}>
              <FormControlLabel value="FEMALE" label="Mme" control={<Radio color="primary" />} disabled={loading} />
              <FormControlLabel value="MALE" label="M." control={<Radio color="primary" />} disabled={loading} />
              <FormControlLabel value="UNKNOWN" label="Autre" control={<Radio color="primary" />} disabled={loading} />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              value={patient.firstName}
              error={error.firstName}
              label="Prénom"
              name="firstName"
              onChange={handleChange}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              value={patient.lastName}
              error={error.lastName}
              fullWidth
              label="Nom"
              name="lastName"
              onChange={handleChange}
              disabled={loading}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={patient.birthdate || null}
              openTo="year"
              onChange={(date) => handleDateChange(date)}
              disabled={loading}
              slotProps={{
                textField: {
                  error: error.birthdate,
                },
                field: {
                  onChange: handleKeyboardDateChange,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="IPP (optionnel)"
              name="ipp"
              onChange={handleChange}
              value={patient.ipp}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="N° de séjour (optionnel)"
              name="pan"
              onChange={handleChange}
              value={patient.pan}
              disabled={loading}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <LoadingButton type="submit" fullWidth size="large" disabled={loading} loading={loading}>
              Enregistrer
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreatePatientForm;
