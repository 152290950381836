import { Components } from '@mui/material';

const MuiCardContent: Components['MuiCardContent'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  },
};

export default MuiCardContent;
