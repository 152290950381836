import { Cloud } from '@ambuliz/sabri-core';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'common/toast';
import { routerActions } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { modalNames } from '../../const/modalNames';
import { requestNames, requests } from '../../const/requests';
import { routeNamesCommon } from '../../const/routeNames';
import { fareSchema } from '../../const/schemas';
import { i18n } from '../../locales';
import { cleanNormalize } from '../../utils/cleanNormalize';
import { safeSaga } from '../../utils/safeSaga';
import {
  assignFareType,
  cancelConfirmFareActionType,
  confirmFareType,
  deleteFareType,
  fareTickType,
  getArchivedFaresType,
  postFareIncidentType,
  removeFareAssignationType,
} from '../actions/fare';
import { entityActions } from '../reducers/entity';
import { faresActions } from '../reducers/fares';
import { modalActions } from '../reducers/modal';
import { selectFareFilters } from '../selectors/fare';

function* getArchivedFares() {
  //@ts-ignore
  const filters = yield select(selectFareFilters);
  const { results, count } = yield call(requests.getArchivedFares, filters);
  if (results) {
    const resultsToJSON = results.map((object: Parse.Object) => object.toJSON());
    const { entities, result } = cleanNormalize(resultsToJSON, [fareSchema]);
    yield all([put(entityActions.setEntities(entities)), put(faresActions.setFares({ ids: result, count }))]);
  }
}

function* removeFareAssignation({
  payload: { fareId, fareAssignationId, porterId },
}: PayloadAction<{ fareId: string; fareAssignationId: string; porterId: string }>) {
  yield call(requests.removeFareAssignation, { fareId, fareAssignationId, porterId });
}

function* assignFare({ payload: { fareId, porterIds } }: PayloadAction<{ fareId: string; porterIds: string[] }>) {
  yield all(porterIds.map((porterId) => call(requests.assignFare, { fareId, porterId })));
}

function* deleteFare({ payload: { fareId, reason } }: PayloadAction<{ fareId: string; reason?: string }>) {
  yield all([call(requests.deleteFare, { fareId, reason }), put(modalActions.hideModal(modalNames.confirm))]);
  toast.success(i18n.fareCanceled);
  //@ts-ignore
  const routerPath = yield select(selectRouterPath);
  const isRouteMatchFareDetails = matchPath(routerPath, routeNamesCommon.fareDetails);
  if (isRouteMatchFareDetails) {
    yield put(routerActions.replace(routeNamesCommon.fareList));
  }
}

function* postFareIncident(action: PayloadAction<Cloud.CreateIncidentParams>) {
  const { fareId, reason, comment } = action.payload;
  yield call(requests.postFareIncident, { fareId, reason, comment });
  toast.success(i18n.incidentCreated);
}

function* fareTick(action: PayloadAction<Cloud.FareTickParams>) {
  const { fareId, date, status } = action.payload;

  yield call(requests.fareTick, { fareId, date, status });
  toast.success(i18n.fareTickUpdated);
}

function* confirmFare(action: PayloadAction<Cloud.ConfirmFareParams>) {
  const { fareId } = action.payload;
  yield call(requests.confirmFare, { fareId });
  toast.success(i18n.successConfirmFare);
}

function* cancelConfirmFare({ payload: { fareId } }: PayloadAction<{ fareId: string }>) {
  yield call(requests.cancelConfirmFare, { fareId });
}

export function* fareRootSaga() {
  yield takeLatest(getArchivedFaresType, safeSaga(getArchivedFares, requestNames.getArchivedFares));
  yield takeLatest(removeFareAssignationType, safeSaga(removeFareAssignation, requestNames.removeFareAssignation));
  yield takeLatest(assignFareType, safeSaga(assignFare, requestNames.assignFare));
  yield takeLatest(deleteFareType, safeSaga(deleteFare, requestNames.deleteFare));
  yield takeLatest(postFareIncidentType, safeSaga(postFareIncident, requestNames.postFareIncident));
  yield takeLatest(fareTickType, safeSaga(fareTick, requestNames.fareTick));
  yield takeLatest(confirmFareType, safeSaga(confirmFare, requestNames.confirmFare));
  yield takeLatest(cancelConfirmFareActionType, safeSaga(cancelConfirmFare, requestNames.cancelConfirmFare));
}
