import { IconButton, Tooltip } from '@mui/material';
import { i18n } from 'sabri/locales';
import styled from 'styled-components';
import { icons } from '../../../const/images';

type ConfirmFareProps = {
  isFareGoingToUnit: boolean;
  onConfirmFare: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const FareListConfirmFareAction: React.FC<ConfirmFareProps> = ({ isFareGoingToUnit, onConfirmFare }) => {
  const title = isFareGoingToUnit ? i18n.callPatient : i18n.pushPatient;
  const iconSrc = isFareGoingToUnit ? icons.enter : icons.exit;

  return (
    <Tooltip title={title} arrow={false} placement="top">
      <ConfirmButton onClick={onConfirmFare} shape="rounded" size="large" color="primary" variant="filled">
        <ConfirmButtonImg alt="check" src={iconSrc} />
      </ConfirmButton>
    </Tooltip>
  );
};

const ConfirmButton = styled(IconButton)`
  &:disabled {
    background-color: '#EEEEEE';
  }
`;

const ConfirmButtonImg = styled.img`
  height: 25px;
`;

export default FareListConfirmFareAction;
