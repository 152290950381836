import { SvgIcon, SvgIconProps } from '@mui/material';

const Bed: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3536 0.146447C10.1583 -0.0488155 9.84171 -0.0488155 9.64645 0.146447C9.45118 0.341709 9.45118 0.658291 9.64645 0.853553L10.5732 1.78033C10.7139 1.92098 10.9046 2 11.1036 2H11.5V2.6225H11.25C10.9034 2.6225 10.6225 2.90344 10.6225 3.25V4.75C10.6225 5.09656 10.9034 5.3775 11.25 5.3775H12.25C12.5966 5.3775 12.8775 5.09656 12.8775 4.75V3.25C12.8775 2.90344 12.5966 2.6225 12.25 2.6225H12V2H13.5V6.25H5.87498L4.06851 3.84137C3.28348 2.79467 1.75339 2.68593 0.828229 3.61109C0.0716614 4.36766 -0.01346 5.56541 0.628509 6.42137L2 8.25003V11.5H0.75C0.335786 11.5 0 11.8358 0 12.25C0 12.6642 0.335786 13 0.75 13H1.27525C0.951117 13.3176 0.75 13.7603 0.75 14.25C0.75 15.2165 1.5335 16 2.5 16C3.4665 16 4.25 15.2165 4.25 14.25C4.25 13.7603 4.04888 13.3176 3.72475 13H12.2753C11.9511 13.3176 11.75 13.7603 11.75 14.25C11.75 15.2165 12.5335 16 13.5 16C14.4665 16 15.25 15.2165 15.25 14.25C15.25 13.7603 15.0489 13.3176 14.7247 13H15.25C15.6642 13 16 12.6642 16 12.25C16 11.8358 15.6642 11.5 15.25 11.5H14V10.7363C15.125 10.6119 16 9.65813 16 8.5C16 7.52034 15.3739 6.68692 14.5 6.37804V2C14.5 1.44771 14.0523 1 13.5 1H11.2071L10.3536 0.146447ZM3.87498 10.75H13V11.5H3V9.58336L3.87498 10.75ZM2.86851 4.74137C2.63117 4.42493 2.16859 4.39205 1.88889 4.67175C1.66016 4.90048 1.63443 5.26259 1.82851 5.52137L4.62498 9.25H13.75C14.1642 9.25 14.5 8.91421 14.5 8.5C14.5 8.08579 14.1642 7.75 13.75 7.75H5.12498L2.86851 4.74137ZM2.5 15C2.91421 15 3.25 14.6642 3.25 14.25C3.25 13.8358 2.91421 13.5 2.5 13.5C2.08579 13.5 1.75 13.8358 1.75 14.25C1.75 14.6642 2.08579 15 2.5 15ZM14.25 14.25C14.25 14.6642 13.9142 15 13.5 15C13.0858 15 12.75 14.6642 12.75 14.25C12.75 13.8358 13.0858 13.5 13.5 13.5C13.9142 13.5 14.25 13.8358 14.25 14.25ZM11.3775 3.3775V4.6225H12.1225V3.3775H11.3775Z"
      />
    </SvgIcon>
  );
};

export default Bed;
