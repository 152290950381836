import { Stack, Typography } from '@mui/material';
import { Calendar } from 'common/components/Icons';
import { color } from 'common/theme';
import { ReactNode } from 'react';

type PageHeaderProps = {
  title: string;
  subheader?: ReactNode;
  children?: ReactNode;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, subheader, children }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    marginTop={6}
    paddingX={10}
    position="sticky"
    left={0}
    width="100%"
    alignItems="center"
    minHeight={54}
  >
    <Stack spacing={1}>
      <Typography variant="h1">{title.charAt(0).toUpperCase() + title.slice(1)}</Typography>
      {subheader && (
        <Stack direction="row" alignItems="center" spacing={2} fontSize="12px" color={color.grey[60]}>
          <Calendar fontSize="inherit" color="inherit" />
          <Typography variant="body2" color="inherit">
            {subheader}
          </Typography>
        </Stack>
      )}
    </Stack>
    {children}
  </Stack>
);

export default PageHeader;
