import { createTheme } from '@mui/material';
import color from './color';
import components from './components';
import palette from './palette';
import shadows from './shadows';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter,sans-serif',
    h1: {
      lineHeight: '34px',
      fontSize: '24px',
      fontWeight: 800,
    },
    h2: {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 800,
    },
    h3: {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 700,
    },
    h6: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      color: palette.text.secondary,
    },
    body1: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
    },
    overline: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '0.02em',
      textTransform: 'uppercase',
      color: palette.text.secondary,
    },
    caption: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '12px',
    },
    button: {
      textTransform: 'initial',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '18px',
    },
    link2: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: palette.secondary.main,
      textDecorationLine: 'underline',
      fontFamily: 'Inter',
    },
  },
  palette,
  spacing: 4,
  shadows,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiIcon: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiPopover: {
      defaultProps: {
        BackdropProps: {
          style: {
            background: 'transparent',
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '12px',
          paddingTop: 12,
          paddingBottom: 8,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          strokeLinecap: 'round',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(1, 13, 50, 0.4)',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 32px',
          '&.MuiDialogTitle-root': {
            fontSize: 20,
            fontWeight: 800,
            lineHeight: '24px',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px 32px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 32px 24px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: color.grey[5],
        },
      },
      variants: [
        {
          props: { orientation: 'vertical' },
          style: {
            height: 'auto',
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontSize: 13,
          fontWeight: 500,
          '&$selected': {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          '&.hidden': {
            display: 'none',
          },
          '&:hover .hidden': {
            display: 'flex',
            marginLeft: 24,
          },
        },
      },
    },
    ...components,
  },
});

const coreTheme = {
  ...theme,
  components: {
    ...theme.components,
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
  },
};

export default coreTheme;
