import { SvgIcon, SvgIconProps } from '@mui/material';

const Emergency: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 12" {...props}>
      <path d="M7.5 12H4.5C4.23486 11.9997 3.98066 11.8943 3.79319 11.7068C3.60571 11.5193 3.50026 11.2651 3.5 11V8.5H1C0.734865 8.49973 0.480665 8.39429 0.293186 8.20681C0.105707 8.01934 0.000264738 7.76514 0 7.5V4.5C0.000264738 4.23486 0.105707 3.98066 0.293186 3.79319C0.480665 3.60571 0.734865 3.50026 1 3.5H3.5V1C3.50026 0.734865 3.60571 0.480665 3.79319 0.293186C3.98066 0.105707 4.23486 0.000264738 4.5 0H7.5C7.76514 0.000264738 8.01934 0.105707 8.20681 0.293186C8.39429 0.480665 8.49973 0.734865 8.5 1V3.5H11C11.2651 3.50026 11.5193 3.60571 11.7068 3.79319C11.8943 3.98066 11.9997 4.23486 12 4.5V7.5C11.9996 7.76509 11.8941 8.01922 11.7067 8.20667C11.5192 8.39412 11.2651 8.4996 11 8.5H8.5V11C8.4996 11.2651 8.39412 11.5192 8.20667 11.7067C8.01922 11.8941 7.76509 11.9996 7.5 12ZM1 4.5V7.5H4.5V11H7.5V7.5H11V4.5H7.5V1H4.5V4.5H1Z" />
    </SvgIcon>
  );
};

export default Emergency;
