import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { ConfirmDialog, Emoji, FilterSelect } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useBedOptions } from 'core/locations/useBedOptions';
import { useAccommodation } from 'kurt/hooks';
import { useState } from 'react';

type UpdateBedDialogProps = {
  id: string;
  open: boolean;
  bedId: string;
  unitId: string;
  onClose: () => void;
  onSuccess: () => void;
};

const UpdateBedDialog = ({ id, open, bedId, unitId, onClose, onSuccess }: UpdateBedDialogProps) => {
  const [newBedId, setNewBedId] = useState<string>(bedId);

  const { update } = useAccommodation(id);
  const { bedOptions, isLoading: areBedsLoading } = useBedOptions(unitId);

  const handleConfirm = async () => {
    await update({ bedId: newBedId });
    toast.success({
      ...i18n.accommodationBedUpdatedSuccess,
      icon: <Emoji size={24} name="backhandIndexPointingRight" />,
    });
    onSuccess();
  };

  return (
    <ConfirmDialog
      onConfirm={handleConfirm}
      onClose={onClose}
      open={open}
      title={
        <Stack spacing={2} alignItems="center" direction="row">
          <Emoji size={24} name="writingHand" />
          <Typography variant="h2">{i18n.updateBed.title}</Typography>
        </Stack>
      }
      confirmLabel={i18n.updateBed.confirmLabel}
    >
      {i18n.updateBed.content.map((text, index) => (
        <Typography color="secondary" key={index}>
          {text}
        </Typography>
      ))}
      <Box marginTop={3}>
        <FilterSelect onChange={(value) => setNewBedId(value)} value={newBedId} loading={areBedsLoading}>
          {bedOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </FilterSelect>
      </Box>
    </ConfirmDialog>
  );
};

export default UpdateBedDialog;
