import { SvgIcon, SvgIconProps } from '@mui/material';

const Pencil: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#a)">
        <path
          d="M11.791.528a1.625 1.625 0 0 1 2.298 0l1.383 1.383a1.625 1.625 0 0 1 0 2.298L4.99 14.691a.625.625 0 0 1-.27.16L.803 15.969a.625.625 0 0 1-.772-.772L1.15 11.28a.625.625 0 0 1 .159-.27L11.79.528Zm-1.089 2.856L2.307 11.78l-.765 2.679 2.679-.766 8.395-8.395-1.914-1.914Zm2.798 1.03L11.586 2.5l1.09-1.088a.375.375 0 0 1 .53 0l1.383 1.383a.375.375 0 0 1 0 .53l-1.09 1.089Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Pencil;
