import { AccommodationSpecificity, PatientGender } from '@ambuliz/sabri-core';
import { Dialog, DialogContent } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { UpdateBed } from 'kurt/components/PatientFlowKanban/VisitActions/actionForms';
import { useAccommodation } from 'kurt/hooks';

type SetBedDialogProps = {
  id: string;
  open: boolean;
  initialUnitId: string;
  start?: Date;
  end?: Date;
  patient?: {
    name?: string;
    birthdate?: Date;
    gender?: PatientGender;
  };
  specificities?: AccommodationSpecificity[];
  onClose: () => void;
  onSuccess: () => void;
};

const SetBedDialog = ({
  id,
  open,
  start,
  end,
  patient,
  specificities,
  initialUnitId,
  onClose,
  onSuccess,
}: SetBedDialogProps) => {
  const { update } = useAccommodation(id);

  const handleConfirm = async (accommodation?: { bedId?: string | null }) => {
    await update({ bedId: accommodation?.bedId });
    toast.success({
      ...i18n.accommodationBedUpdatedSuccess,
      icon: <Emoji size={24} name="backhandIndexPointingRight" />,
    });
    onSuccess();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogHeader title={i18n.visitActions.SET_BED.form.title} onClose={onClose} />
      <DialogContent>
        <UpdateBed
          onCancel={onClose}
          initialUnitId={initialUnitId}
          action="SET_BED"
          onSubmit={handleConfirm}
          loading={false}
          start={start}
          end={end}
          patient={patient}
          specificities={specificities}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SetBedDialog;
