import { SvgIcon, SvgIconProps } from '@mui/material';

const CalendarDayIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_2897_7096)">
        <path d="M16 4.33333C16 3.44928 15.6488 2.60143 15.0237 1.97631C14.3986 1.35119 13.5507 1 12.6667 1H3.33333C2.44928 1 1.60143 1.35119 0.976311 1.97631C0.351189 2.60143 0 3.44928 0 4.33333V12.6667C0 13.5507 0.351189 14.3986 0.976311 15.0237C1.60143 15.6488 2.44928 16 3.33333 16H12.6667C13.5507 16 14.3986 15.6488 15.0237 15.0237C15.6488 14.3986 16 13.5507 16 12.6667V4.33333ZM1.33333 6.33333H14.6667V12.6667C14.6667 13.1971 14.456 13.7058 14.0809 14.0809C13.7058 14.456 13.1971 14.6667 12.6667 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V6.33333ZM3.33333 2.33333H12.6667C13.1971 2.33333 13.7058 2.54405 14.0809 2.91912C14.456 3.29419 14.6667 3.8029 14.6667 4.33333V5H1.33333V4.33333C1.33333 3.8029 1.54405 3.29419 1.91912 2.91912C2.29419 2.54405 2.8029 2.33333 3.33333 2.33333Z" />
        <path d="M5.72398 9.35601L7.26878 10.8813L10.276 7.915C10.3834 7.80905 10.5284 7.75 10.6788 7.75C10.8293 7.75 10.9742 7.80899 11.0816 7.915C11.189 8.02111 11.25 8.16571 11.25 8.31716C11.25 8.46861 11.189 8.6132 11.0816 8.71931L7.67308 12.0848C7.45007 12.3051 7.09053 12.3051 6.86752 12.0848L4.91843 10.1603C4.86524 10.1078 4.82287 10.0453 4.79391 9.97624C4.76495 9.9072 4.75 9.83309 4.75 9.75816C4.75 9.60671 4.81096 9.46212 4.91843 9.35601C5.02578 9.25 5.17073 9.19101 5.3212 9.19101C5.47168 9.19101 5.61662 9.25 5.72398 9.35601Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.625 0C5.97018 3.01764e-08 6.25 0.279822 6.25 0.625V3.375C6.25 3.72018 5.97018 4 5.625 4C5.27982 4 5 3.72018 5 3.375V0.625C5 0.279822 5.27982 -3.01764e-08 5.625 0Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.625 0C10.9702 3.01764e-08 11.25 0.279822 11.25 0.625V3.375C11.25 3.72018 10.9702 4 10.625 4C10.2798 4 10 3.72018 10 3.375V0.625C10 0.279822 10.2798 -3.01764e-08 10.625 0Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2897_7096">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CalendarDayIcon;
