import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { requests } from '../../const/requests';
import { safeSaga } from '../../utils/safeSaga';
import { PostFormPayload, postFormType } from '../actions/form';

export function* postForm(action: PayloadAction<PostFormPayload>) {
  const { params, requestName } = action.payload;
  const request = requests[requestName];
  //@ts-ignore
  return yield call(request, params);
}

export function* formRootSaga() {
  yield takeLatest(postFormType, safeSaga(postForm));
}
