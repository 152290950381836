import { Box, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { i18n } from '../../locales';
import { getObjectParamsFromSearchParams } from '../../utils/searchParams';

export const LinkerTest: React.FC = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const objectParams = getObjectParamsFromSearchParams(searchParams);

  return (
    <Box padding={2}>
      <Box marginBottom={1}>
        <Typography variant="h3">Description du lien contextuel</Typography>
      </Box>
      {Object.keys(objectParams)
        .filter((key) => !!i18n.linkerParamsTrad[key])
        .map((key) => (
          <Fragment key={key}>
            <li>
              <b>
                {i18n.linkerParamsTrad[key]} {`(${key})`}
              </b>{' '}
              : {objectParams[key]}
            </li>
          </Fragment>
        ))}
    </Box>
  );
};
