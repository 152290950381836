import { Cloud, EmergencyOccupancy } from '@ambuliz/sabri-core';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { Cross } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { toast } from 'common/toast';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { EmergencyOccupancyMaybeEmpty } from '../List/EmergencyOccupancyList';
import EditEmergencyOccupancyCard from './EditEmergencyOccupancyCard';
import EditEmergencyOccupancyForm from './EditEmergencyOccupancyForm';

type EditEmergencyOccupancyModalProps = {
  selectedOccupancy: EmergencyOccupancyMaybeEmpty;
  occupancies: EmergencyOccupancyMaybeEmpty[];
  onClose: () => void;
};

const EditEmergencyOccupancyModal = ({
  selectedOccupancy: initialSelectedOccupancy,
  occupancies,
  onClose,
}: EditEmergencyOccupancyModalProps) => {
  const [notSavedModal, setNotSavedModal] = useState<{ open: boolean; shouldCloseEntirely: boolean }>({
    open: false,
    shouldCloseEntirely: false,
  });
  const [selectedOccupancy, setSelectedOccupancy] = useState<EmergencyOccupancyMaybeEmpty>({
    ...initialSelectedOccupancy,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const isDirty = useMemo(() => {
    const originalOccupancy = occupancies.find(({ key }) => key === selectedOccupancy?.key);

    return JSON.stringify(originalOccupancy) !== JSON.stringify(selectedOccupancy);
  }, [occupancies, selectedOccupancy]);

  const handleClose = () => {
    if (isDirty) {
      setNotSavedModal({ open: true, shouldCloseEntirely: true });
    } else {
      onClose();
    }
  };

  const handleCloseNotSavedModal = () => {
    setNotSavedModal({ open: false, shouldCloseEntirely: false });
    if (notSavedModal.shouldCloseEntirely) {
      onClose();
    }
  };

  const handleOnBack = () => {
    setNotSavedModal({ open: false, shouldCloseEntirely: false });
  };

  const handleCardClick = (occupancy: EmergencyOccupancyMaybeEmpty) => {
    if (isDirty) {
      setNotSavedModal({ open: true, shouldCloseEntirely: false });
    } else {
      setSelectedOccupancy(occupancy);
    }
  };

  const handleSubmit = async () => {
    if (selectedOccupancy) {
      setLoading(true);

      try {
        await Cloud.updateEmergencyOccupancy({
          key: selectedOccupancy.key,
          service: selectedOccupancy.service,
          currentPatients: selectedOccupancy.currentPatients || 0,
          availableBeds: selectedOccupancy.availableBeds,
          averageWaitingTime: selectedOccupancy.averageWaitingTime,
          comment: selectedOccupancy.comment,
          waitingPatients: selectedOccupancy.waitingPatients,
          waitingPatientsBySector: Object.entries(selectedOccupancy.waitingPatientsBySector || {}).reduce(
            (acc, [sector, value]) => ({
              ...acc,
              [sector]: value ?? 0,
            }),
            {}
          ),
          healthCenterId: selectedOccupancy.healthCenter.id,
        });
        toast.success(i18n.emergencyOccupancy.toasts.saveSuccess);
        onClose();
      } catch (err) {
        toast.error(i18n.emergencyOccupancy.toasts.saveError);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSelectedOccupancyChange =
    (field: keyof EmergencyOccupancy) => (value: EmergencyOccupancy[typeof field]) => {
      setSelectedOccupancy((prev) => ({ ...prev!, [field]: value }));
    };

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isDirty]);

  return (
    <>
      <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 40, right: 40, marginTop: -2 }} edge="end">
        <Cross color="secondary" />
      </IconButton>
      <DialogContent sx={{ padding: 0 }}>
        <Stack direction="row">
          <EditEmergencyOccupancyTabs>
            <Stack spacing={4}>
              {occupancies.map((occupancy) => (
                <EditEmergencyOccupancyCard
                  occupancy={occupancy}
                  selected={
                    selectedOccupancy?.id
                      ? occupancy.id === selectedOccupancy?.id
                      : occupancy.key === selectedOccupancy?.key
                  }
                  disabled={loading}
                  key={occupancy.id}
                  onClick={() => handleCardClick(occupancy)}
                />
              ))}
            </Stack>
          </EditEmergencyOccupancyTabs>
          <EditEmergencyOccupancyContent>
            {selectedOccupancy && (
              <EditEmergencyOccupancyForm
                occupancy={selectedOccupancy}
                loading={loading}
                onChange={handleSelectedOccupancyChange}
                onClose={handleClose}
                onSubmit={handleSubmit}
                isDirty={isDirty}
              />
            )}
          </EditEmergencyOccupancyContent>
        </Stack>
      </DialogContent>

      <Dialog open={notSavedModal.open} onClose={handleCloseNotSavedModal}>
        <DialogHeader
          Icon={<Emoji name="raisedHand" size="normal" />}
          title={i18n.notSavedModal.title}
          onBack={handleOnBack}
          onClose={handleCloseNotSavedModal}
        />
        <DialogContent>
          <Typography color="textSecondary">{i18n.notSavedModal.content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotSavedModal} variant="outlined">
            {i18n.notSavedModal.cancel}
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => {
              handleCloseNotSavedModal();
              handleSubmit();
            }}
          >
            {i18n.notSavedModal.confirm}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const EditEmergencyOccupancyTabs = styled.div`
  max-height: calc(100vh - 80px);
  padding: 40px 32px 40px 40px;
  position: sticky;
  top: 0;
  border-right: 1px solid ${color.grey[20]};
  overflow-y: auto;
`;

const EditEmergencyOccupancyContent = styled.div`
  padding: 40px 40px 0px 32px;
`;

export default EditEmergencyOccupancyModal;
