import { Chip, Stack, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { color } from 'common/theme';

type PractitionersChipProps = {
  practitioners: string[];
};

const PractitionersChip = ({ practitioners }: PractitionersChipProps) => {
  const remainingPractitionerNames = practitioners.slice(1);
  return practitioners.length > 0 ? (
    <Stack direction="row" gap={1} alignItems="center">
      <Chip
        key={practitioners[0]}
        label={
          <EllipsisTypography fontSize="inherit" fontWeight="inherit" lineHeight="inherit">
            {practitioners[0]}
          </EllipsisTypography>
        }
        color="secondary"
        variant="light"
      />
      {remainingPractitionerNames.length > 0 && (
        <Typography variant="body2" color={color.grey[40]}>
          +{remainingPractitionerNames.length}
        </Typography>
      )}
    </Stack>
  ) : null;
};

export default PractitionersChip;
