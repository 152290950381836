import { Stack, Typography } from '@mui/material';
import { Timeline } from 'common/components/Images';
import { i18n } from 'common/locale';
import { useReadOnly } from 'core/authentication';
import { ContentLoader, EmptyContent, PageSection, useAppBarContext } from 'core/layout';
import { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useEmergencyPageFilters from '../useEmergencyPageFilters';
import HospitalizationsTable from './HospitalizationsTable';
import HospitalizationsTableRow from './HospitalizationsTableRow';
import useHospitalizationRequests, {
  HospitalizationRequestsOrderBy,
  HospitalizationRequestsOrderParams,
} from './useHospitalizationRequests';

const Hospitalizations = () => {
  const { isFullScreen } = useAppBarContext();
  const [order, setOrder] = useState<HospitalizationRequestsOrderParams>({ orderBy: 'startAt', order: 'asc' });

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const { isReadOnly } = useReadOnly();

  const { unitId } = useParams();

  const { search, genders, specificities } = useEmergencyPageFilters();
  const { hospitalizationRequests, totalCount, loading } = useHospitalizationRequests(unitId!, {
    page,
    rowsPerPage,
    search,
    genders,
    specificities,
    ...order,
  });

  const handleSort = (name: HospitalizationRequestsOrderBy) => {
    setOrder((prevOrder) => ({
      orderBy: name,
      order: prevOrder.orderBy === name ? (prevOrder.order === 'desc' ? 'asc' : 'desc') : prevOrder.order,
    }));
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <>
      <PageSection
        withBackground
        noGutter
        noMargin={isFullScreen}
        paddingTop={!isFullScreen ? 4 : 6}
        fullHeight={isFullScreen}
        lastSection
      >
        <Stack spacing={6} marginTop={4} height="100%">
          <Typography variant="h4">{i18n.hospitalizationRequestsPage.title}</Typography>
          {loading ? (
            <ContentLoader />
          ) : hospitalizationRequests.length === 0 ? (
            <EmptyContent
              Image={Timeline}
              title={i18n.hospitalizationRequestsPage.noRequest}
              subtitle={i18n.nothingToSignal}
            />
          ) : (
            <HospitalizationsTable
              {...order}
              page={page}
              rowsPerPage={rowsPerPage}
              totalCount={totalCount}
              isReadOnly={isReadOnly}
              onSort={handleSort}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            >
              {hospitalizationRequests.map((hospitalizationRequest) => (
                <HospitalizationsTableRow
                  key={`${hospitalizationRequest.id}-${hospitalizationRequest.destination?.id}`}
                  hospitalization={hospitalizationRequest}
                  isReadOnly={isReadOnly}
                  onClick={() => navigate(hospitalizationRequest.id)}
                />
              ))}
            </HospitalizationsTable>
          )}

          <Outlet />
        </Stack>
      </PageSection>
    </>
  );
};

export default Hospitalizations;
