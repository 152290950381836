import Api, { Patient } from '@ambuliz/sabri-core';
import { datadogLogs } from '@datadog/browser-logs';
import to from 'await-to-js';
import { liveQueryClient } from 'core/live-query-client';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { requests } from '../../const/requests';
import { EntityNames, entityNameToSchema } from '../../const/schemas';
import { cleanNormalize } from '../../utils/cleanNormalize';
import { subscribeLiveQueriesType, unsubscribeLiveQueriesType } from '../actions/liveQuery';
import { entityActions } from '../reducers/entity';
import { selectPatientsIds } from '../selectors/patient';
import { AppState } from '../store';

let subscription: Parse.LiveQuerySubscription | undefined;

const handleOpen = (store: MiddlewareAPI<Dispatch, AppState>) => async () => {
  const patientsIds = selectPatientsIds(store.getState());
  if (patientsIds.length > 0) {
    try {
      const patients = await new Api.Query(Patient).containedIn('objectId', patientsIds).findAll();
      const { entities } = cleanNormalize(
        patients.map((patient: Patient) => patient.toJSON()),
        [entityNameToSchema[EntityNames.patients]]
      );
      store.dispatch(entityActions.setEntities(entities));
    } catch (error) {
      datadogLogs.logger.error('Error fetching patients', { error });
    }
  }
};

export const patientLiveQueryMiddleware =
  (store: MiddlewareAPI<Dispatch, AppState>) => (next: Dispatch) => async (action: AnyAction) => {
    let err: Error | null;

    const result = next(action);

    switch (action.type) {
      case subscribeLiveQueriesType:
        [err, subscription] = await to(requests.subscribePatientLiveQuery());
        if (err) throw err;
        if (!subscription) throw Error('liveSubscription is undefined');
        subscription.on('open', handleOpen(store));
        break;

      case unsubscribeLiveQueriesType:
        liveQueryClient.unsubscribe(subscription);
        break;
    }

    return result;
  };
