import { Accommodation, AccommodationRequest } from '@ambuliz/sabri-core';
import AccommodationCardMenu from 'kurt/components/PatientFlowKanban/AccommodationCardMenu';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import getColumnActions from 'kurt/components/PatientFlowKanban/VisitActions/columnActions';
import useAdmissionAccommodation from 'kurt/pages/Movements/useAdmissionAccommodation';

type AdmissionAccommodationCardMenuProps = {
  open: boolean;
  onClose: () => void;
  movementId: string;
  onActionClick: (action: AccommodationAction) => void;
  onLoaded: ({
    accommodation,
    mutation,
    previousAccommodation,
  }: {
    accommodation: Accommodation;
    mutation?: AccommodationRequest;
    previousAccommodation?: Accommodation;
  }) => void;
  anchorEl: HTMLElement | null;
};

const AdmissionAccommodationCardMenu: React.FC<AdmissionAccommodationCardMenuProps> = ({
  open,
  onClose,
  anchorEl,
  onActionClick,
  movementId,
  onLoaded,
}) => {
  const { accommodation, previousAccommodation, mutation, status, loading } = useAdmissionAccommodation(movementId!);

  const actions = getColumnActions({
    flow: 'admission',
    type: mutation && mutation.performerRequests ? 'mutation' : 'default',
    status,
    requestType: mutation?.requestType,
  });

  if (!loading && accommodation) {
    onLoaded({ accommodation, previousAccommodation, mutation });
  }

  return (
    <>
      {!loading && (
        <AccommodationCardMenu
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          onActionClick={onActionClick}
          actions={actions}
          visitId={accommodation?.visit?.id}
        />
      )}
    </>
  );
};

export default AdmissionAccommodationCardMenu;
