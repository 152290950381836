import { generateRandomPin, isPinValid } from '@ambuliz/sabri-core';
import { Check } from '@mui/icons-material';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Cross } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { useState } from 'react';

type UserPinFormProps = {
  value: string;
  onChange: (value: string) => void;
};

const UserPinForm = ({ value, onChange }: UserPinFormProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleGenerateRandomPin = () => {
    onChange(generateRandomPin());
  };

  const { hasOnlyDigits, is4Digits } = isPinValid(value);

  return (
    <Stack spacing={4}>
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          type={isVisible ? 'text' : 'password'}
          value={value}
          label={i18n.password}
          onChange={(e) => onChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsVisible(!isVisible)}>
                  {isVisible ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="outlined" color="primary" onClick={handleGenerateRandomPin}>
          <LockResetRoundedIcon fontSize="inherit" />
        </Button>
      </Stack>
      <Stack>
        <Stack direction="row" spacing={2} alignItems="center" color={hasOnlyDigits ? 'success.main' : 'error.main'}>
          {hasOnlyDigits ? <Check fontSize="inherit" /> : <Cross fontSize="inherit" />}
          <Typography>Le mot de passe doit contenir seulement des chiffres</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" color={is4Digits ? 'success.main' : 'error.main'}>
          {is4Digits ? <Check fontSize="inherit" /> : <Cross fontSize="inherit" />}
          <Typography>Le pin doit contenir 4 caractères</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserPinForm;
