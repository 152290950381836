import { Hl7SiuMessage } from '@ambuliz/sabri-core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ListPagination } from 'common/components';
import { usePagination } from 'common/hooks';
import { i18n } from 'common/locale';
import { format } from 'date-fns';
import { useMemo } from 'react';

const Hl7SiuMessageTable = ({ hl7Messages }: { hl7Messages: Hl7SiuMessage[] }) => {
  const { page, changePage, rowsPerPage, changeRowsPerPage } = usePagination(hl7Messages.length || 0);
  const paginatedRows = useMemo(
    () => hl7Messages?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [hl7Messages, page, rowsPerPage]
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{i18n.patientHl7.siuTable.createdAt}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.msgId}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.eventType}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.ipp}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.pan}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.patientClass}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.service}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.unit}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.room}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.bed}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.personel}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.placerId}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.fillerId}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.startDate}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.endDate}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.duration}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.status}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.priority}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.reason}</TableCell>
            <TableCell>{i18n.patientHl7.siuTable.comment}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((hl7Message) => (
            <TableRow key={hl7Message.msgId}>
              <TableCell>
                <Typography variant="body2">{format(hl7Message.createdAt, 'dd/MM/yy - HH:mm')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.msgId}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.eventType}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.ipp}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.pan || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.patientClass}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentServiceIdentifier}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentLocationResourceUnit || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentLocationResourceRoom || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentLocationResourceBed || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentPersonnelResource?.join(', ') || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentPlacerId || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentFillerId || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {hl7Message.scheduleTimingStartDate
                    ? format(hl7Message.scheduleTimingStartDate, 'dd/MM/yy - HH:mm')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {hl7Message.scheduleTimingEndDate
                    ? format(hl7Message.scheduleTimingEndDate, 'dd/MM/yy - HH:mm')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentDuration || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.scheduleTimingStatus || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.scheduleTimingPriority || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.scheduleReason || '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{hl7Message.appointmentComment || '-'}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ListPagination
        count={hl7Messages.length}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={i18n.accommodationsByPage}
      />
    </TableContainer>
  );
};

export default Hl7SiuMessageTable;
