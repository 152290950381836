import { Patient } from '@ambuliz/sabri-core';
import { Link, Stack, Typography } from '@mui/material';
import { Plus } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { useAuthentication } from 'core/authentication';
import { DETAILS_ROW_WIDTH } from '../DetailsDialog';
import { ViaTrajectoireLink, ViaTrajectoireLoader } from './ViaTrajectoireLink';

type PatientOutcomeRowProps = { patient?: Patient; disableVtLink?: boolean };

const PatientOutcomeRow = ({ patient, disableVtLink }: PatientOutcomeRowProps) => {
  const patientOutcome = patient?.patientOutcome;
  const {
    healthCenter: { viaTrajectoireEnabled },
  } = useAuthentication();
  const isViaTrajectoireVisible = viaTrajectoireEnabled && !patient?.isDischarged && !disableVtLink;

  return (
    <Stack padding={4} direction="row" spacing={4}>
      <Stack direction="row" spacing={2} minWidth={DETAILS_ROW_WIDTH} alignItems="center" height="fit-content">
        <Typography variant="body2" color="secondary" paddingLeft={2}>
          {i18n.outcome}
        </Typography>
        <Typography
          fontWeight={patientOutcome?.type ? 600 : 400}
          color={patientOutcome?.type ? 'secondary.dark' : color.grey[30]}
          padding="7px 8px"
        >
          {patientOutcome?.type || i18n.toDefine}
        </Typography>
      </Stack>
      <Typography color="secondary.dark" variant="body2" whiteSpace="pre-line" padding={2}>
        {patientOutcome?.comment}
      </Typography>
      <Stack padding={2} style={{ marginLeft: 'auto' }}>
        {isViaTrajectoireVisible && (
          <ViaTrajectoireLink
            dropDownMargin={1}
            menuPlacement="bottom"
            visitId={patient?.id}
            button={
              <Link height="fit-content" color="secondary" sx={{ '&:hover': { color: palette.primary.main } }}>
                <Stack direction="row" height="fit-content" alignItems="center">
                  <Plus fontSize="small" />
                  <Typography variant="body2" marginLeft={2}>
                    ViaTrajectoire
                  </Typography>
                </Stack>
              </Link>
            }
            loader={<ViaTrajectoireLoader textVariant="body2" textMarginLeft={2} />}
            openOnHover={false}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default PatientOutcomeRow;
