import { getAvailableBeds } from '@ambuliz/sabri-core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DialogHeader } from 'common/components';
import { i18n } from 'common/locale';
import { progressValue } from 'common/utils';
import { getThresholdedColor } from 'kurt/const';
import { NormalizedUnit } from 'kurt/hooks/useDepartments';

export type CovidOccupancyModalProps = {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  units: NormalizedUnit[];
};

const CovidOccupancyModal: React.FC<CovidOccupancyModalProps> = ({ onClose, loading, open, units }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogHeader title={i18n.covidOccupancyDetails} onClose={onClose} />
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.unit}</TableCell>
              <TableCell>{i18n.covidPatients}</TableCell>
              <TableCell>{i18n.availableBeds}</TableCell>
              <TableCell>{i18n.globalOccupancyRate}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map((unit) => (
              <TableRow key={unit.name}>
                <TableCell>
                  <Typography fontWeight={600}>{unit.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>{unit.occupancy?.covid ?? '-'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {getAvailableBeds(
                      unit.occupancy?.dDayAvailabilities?.male,
                      unit.occupancy?.dDayAvailabilities?.female,
                      unit.occupancy?.dDayAvailabilities?.indifferent
                    ) ?? '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack alignItems="center" spacing={2} direction="row">
                    {unit.capacityRate !== undefined && (
                      <LinearProgress
                        style={{ width: 48 }}
                        value={progressValue(unit.capacityRate)}
                        color={getThresholdedColor(unit.capacityRate)}
                      />
                    )}
                    <Typography>{unit.capacityRate !== undefined ? `${unit.capacityRate}%` : '-'}</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={!!loading}>
          {i18n.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CovidOccupancyModal;
