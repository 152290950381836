import { Skeleton, Stack, Tooltip, TooltipProps } from '@mui/material';
import CircularProgressIcon from 'common/components/Progress/CircularProgressIcon';
import { palette } from 'common/theme';
import React, { ReactNode, useContext } from 'react';
import { StatisticsContext } from '../Context';
import { ItemTitle, ItemTitleProps, StatisticsColor } from './ItemTitle';

type ItemProps = {
  children: ReactNode;
  title?: ItemTitleProps;
  isActive?: boolean;
  progress?: { icon: React.FC<any>; value: number; color: StatisticsColor };
  tooltipProps?: Omit<TooltipProps, 'children'>;
  onClick?: () => void;
};

const Item = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ children, title, isActive, progress, tooltipProps, onClick }, ref) => {
    const { loading } = useContext(StatisticsContext);

    return (
      <Stack ref={ref} minWidth={100} direction="row" spacing={4} flexGrow={1} flexShrink={0} height="100%">
        {progress && (
          <CircularProgressIcon loading={loading} Icon={progress.icon} value={progress.value} color={progress.color} />
        )}

        <Tooltip {...tooltipProps} title={tooltipProps?.title || ''}>
          <Stack
            justifyContent="space-around"
            width="100%"
            height="100%"
            alignItems={title ? (title.variant === 'light' ? 'flex-start' : 'center') : undefined}
            onClick={() => onClick && !loading && onClick()}
            sx={{
              cursor: onClick ? 'pointer' : undefined,
              ':hover': {
                backgroundColor: onClick && !loading ? palette.primary.light : undefined,
              },
              backgroundColor: isActive ? palette.primary.light : undefined,
              padding: onClick ? 1 : undefined,
              borderRadius: onClick ? 1 : undefined,
            }}
          >
            {loading ? <Skeleton width={80} /> : title && <ItemTitle {...title} />}
            <Stack
              alignItems={title ? (title.variant === 'light' ? 'flex-start' : 'center') : undefined}
              spacing={1}
              width="100%"
            >
              {loading ? <Skeleton width={40} /> : children}
            </Stack>
          </Stack>
        </Tooltip>
      </Stack>
    );
  }
);

export default Item;
