import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogHeader, Emoji } from 'common/components';
import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useAccommodation } from 'kurt/hooks';
import { useState } from 'react';

type CancelAccommodationDialogProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onBack?: () => void;
  onSuccess: () => void;
};

const CancelAccommodationDialog = ({
  id,
  open,
  onClose,
  onBack,
  onCancel,
  onSuccess,
}: CancelAccommodationDialogProps) => {
  const { cancel } = useAccommodation(id);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await cancel();
      toast.success({ ...i18n.accommodationCancelledSuccess, icon: <Emoji size={24} name="winkingFace" /> });
      onSuccess();
    } catch (err) {
      toast.error(i18n.accommodationCancelledError);
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        Icon={<Emoji size={24} name="thinkingFace" />}
        title={i18n.cancelAccommodation.title}
        onBack={onBack}
        onClose={onClose}
      />
      <DialogContent>
        <Typography color="secondary">{i18n.cancelAccommodation.content[0]}</Typography>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} variant="outlined">
            {i18n.cancel}
          </Button>
        )}
        <LoadingButton loading={loading} onClick={handleConfirm}>
          {i18n.cancelAccommodation.title}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelAccommodationDialog;
