import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowUpIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M2.459 7.32a.75.75 0 0 1 .022-1.061l5-4.8a.75.75 0 0 1 1.038 0l5 4.8a.75.75 0 1 1-1.038 1.082L8.75 3.76V14a.75.75 0 0 1-1.5 0V3.76L3.52 7.34a.75.75 0 0 1-1.061-.022Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default ArrowUpIcon;
