import { Box } from '@mui/material';
import getEmojiSize from 'common/utils/getEmojiSize';
import { EmojiShape, EmojiSize, EmojiType, defaultEmojiBgPadding, emoji } from './type';

type EmojiProps = {
  name: EmojiType;
  size?: EmojiSize;
  inline?: boolean;
  bgColor?: string;
  shape?: EmojiShape;
};

/**
 * A component that displays an emoji with its background.
 *
 * @param {string} name
 * @param {EmojiSize} [size='normal'] - normal as default
 * @param {boolean} [inline=false]
 * @param {string} [bgColor] - transparent as default, if no bgColor is provided the padding will be removed to fully display the emoji
 * @param {EmojiShape} [shape='circle'] - circle as default. Border radius : circle = 50%, square = 4px, undefined = no border radius
 */
const Emoji: React.FC<EmojiProps> = ({ name, size = 'normal', inline = false, bgColor, shape = 'circle' }) => {
  const emojiObj = emoji[name];
  const emojiSize = getEmojiSize(size);

  if (!emojiObj) {
    console.warn("Emoji doesn't exist:", name);
    return null;
  }
  return inline ? (
    <img
      src={emojiObj.src}
      alt={emojiObj.alt}
      style={{
        verticalAlign: 'middle',
        marginTop: -3,
      }}
      width={emojiSize}
      height={emojiSize}
    />
  ) : (
    <Box
      display="inline-flex"
      borderRadius={
        shape
          ? shape === 'circle'
            ? '50%'
            : (['medium', 'large'] as EmojiSize[]).includes(size)
            ? '8px'
            : '4px'
          : undefined
      }
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      bgcolor={bgColor}
      width={emojiSize}
      height={emojiSize}
      minWidth={emojiSize}
      padding={
        bgColor
          ? emojiObj.bgPadding && shape === 'circle'
            ? emojiObj.bgPadding[size]
            : defaultEmojiBgPadding[size]
          : undefined
      }
    >
      <img
        src={emojiObj.src}
        alt={emojiObj.alt}
        style={{
          height: '100%',
        }}
      />
    </Box>
  );
};

export default Emoji;
