import { Card, CardContent, Stack, Typography } from '@mui/material';
import { ArrowRight } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { theme } from 'common/theme';
import { format, isBefore } from 'date-fns';
import { OUTDATED_UPDATED_AT } from 'kurt/const';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

type UnitCardProps = {
  name: string;
  updatedAt?: Date;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const UnitCard = ({ name, updatedAt, onClick, selected = false, disabled = false }: UnitCardProps) => {
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <StyledCard onClick={handleClick} className={selected ? 'selected' : ''} elevation={1} disabled={disabled}>
      <CardContent>
        <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={4}>
          <Stack spacing={1}>
            <Typography fontWeight={600}>{name}</Typography>
            {disabled ? (
              <Typography color="textSecondary" variant="body2">
                {i18n.occupanciesAutoComputeShort}
              </Typography>
            ) : (
              updatedAt && (
                <Typography color="textSecondary" variant="body2">
                  {i18n.updateShortened}
                  <Typography
                    fontWeight={600}
                    variant="body2"
                    component="span"
                    color={isOldUpdatedAt(updatedAt) ? theme.palette.warning.main : theme.palette.text.secondary}
                  >
                    {getDateText(updatedAt)}
                    <Typography component="span" variant="body2">
                      {' - ' + format(updatedAt, 'HH:mm')}
                    </Typography>
                  </Typography>
                </Typography>
              )
            )}
          </Stack>
          {!disabled && <ArrowRight color="secondary" />}
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)<{ disabled: boolean }>`
  &.selected {
    background: ${theme.palette.primary.light};
    border: 1px solid ${theme.palette.primary.main};
    box-shadow: 'none';
  }

  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        box-shadow: ${theme.shadows[3]};
      }

    cursor: pointer;
    transition: all 0.25s ease-in;

    `}
`;

const isOldUpdatedAt = (date: Date) => isBefore(date, OUTDATED_UPDATED_AT);
const getDateText = (date: Date) => (isOldUpdatedAt(date) ? '⚠ ' : '') + format(date, 'dd/MM');

export default memo(UnitCard);
