import { ButtonProps, IconButton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { color, transition } from 'common/theme';
import { ReactNode, useState } from 'react';

type ActionButtonProps = Omit<ButtonProps, 'variant'> & {
  icon?: ReactNode;
  tooltip?: string;
};

const ActionButton = ({ icon, tooltip, ...props }: ActionButtonProps) => {
  const [hover, setHover] = useState(false);

  if (props.children) {
    return (
      <Stack
        alignItems="center"
        direction="row"
        gap={2}
        onClick={(event) => props.onClick?.(event)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        component="button"
        sx={{
          cursor: 'pointer',
          transition: `color ${transition}`,
          border: 'none',
          backgroundColor: 'transparent !important',
          padding: 0,
        }}
      >
        <IconButton {...props} color="default" variant="filled" size="small">
          {icon}
        </IconButton>
        <Typography color={hover ? color.grey[90] : color.grey[60]} variant="body2">
          {props.children}
        </Typography>
      </Stack>
    );
  }

  return (
    <Tooltip title={tooltip || ''} placement="top" arrow={false} enterDelay={500}>
      <IconButton size="small" variant="filled" {...props}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default ActionButton;
