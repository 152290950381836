import { Box, IconButton, Tooltip } from '@mui/material';
import { LogoutIcon } from 'common/components/Icons';
import { toast } from 'common/toast';
import { useReadOnly } from 'core/authentication';
import { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { modalNames } from 'sabri/const/modalNames';
import { i18n } from 'sabri/locales';
import { logoutPorterAction } from 'sabri/store/actions/porter';
import { modalActions } from 'sabri/store/reducers/modal';
import { PorterDenormalized } from 'sabri/store/selectors/porter';
import getToastableErrorMessage from 'sabri/utils/getToastableErrorMessage';
import { promiseDispatch } from 'sabri/utils/promiseDispatch';

type LogoutPorterButtonProps = {
  porter: PorterDenormalized;
};

export const LogoutPorterButton: React.FC<LogoutPorterButtonProps> = ({ porter }) => {
  const { isReadOnly } = useReadOnly();

  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleLogoutPorter = () => {
    batch(() => {
      promiseDispatch(dispatch, logoutPorterAction({ porterId: porter.objectId })).catch((error) => {
        setLoading(false);
        toast.error(getToastableErrorMessage(error.message));
      });
      dispatch(modalActions.hideModal(modalNames.confirm));
    });
  };

  const handleClick = () => {
    dispatch(
      modalActions.showModal({
        modalName: modalNames.confirm,
        params: {
          title: i18n.caution,
          content:
            porter.status === 'AVAILABLE'
              ? i18n.cautionLogoutPorterAvailable(porter.firstName, porter.lastName)
              : porter.fareAssignedCount
              ? i18n.cautionLogoutPorterBusy(porter.firstName, porter.lastName)
              : i18n.cautionLogoutPorterAvailable(porter.firstName, porter.lastName),
          onConfirm: async () => handleLogoutPorter(),
        },
      })
    );
    return;
  };

  const isDisabled = isLoading;

  return isReadOnly ? null : (
    <Box display="flex" justifyContent="center">
      <Tooltip title={i18n.logoutPorterTooltip}>
        <span>
          <IconButton disabled={isDisabled} onClick={handleClick} color="primary" size="large">
            <LogoutIcon color={isDisabled ? 'disabled' : 'primary'} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};
