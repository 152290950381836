import { UnitSector } from '@ambuliz/sabri-core';
import { Divider, Drawer, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import EllipsisTypography from 'common/components/EllipsisTypography';
import Edit from 'common/components/Icons/Edit';
import { i18n } from 'common/locale';
import { color, palette } from 'common/theme';
import { format } from 'date-fns';
import { useState } from 'react';
import EditEmergencyOccupancyModal from '../EditEmergencyOccupancyModal/EditEmergencyOccupancyModal';
import { EmergencyOccupancyMaybeEmpty } from './EmergencyOccupancyList';

const EMERGENCY_OCCUPANCY_ROW_MIN_HEIGHT = 39;

type EmergencyOccupancyRowProps = {
  occupancy: EmergencyOccupancyMaybeEmpty;
  editableOccupancies?: EmergencyOccupancyMaybeEmpty[];
  isReadOnly?: boolean;
  onModalClose?: () => void;
};

const EmergencyOccupancyRow = ({
  occupancy,
  editableOccupancies,
  isReadOnly,
  onModalClose,
}: EmergencyOccupancyRowProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleEdit = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    onModalClose?.();
  };

  const waitingPatientsSectors: UnitSector[] = Array.from(
    new Set((editableOccupancies || []).flatMap((occupancy) => Object.keys(occupancy?.waitingPatientsBySector || {})))
  ) as UnitSector[];

  return (
    <>
      <TableRow sx={{ height: EMERGENCY_OCCUPANCY_ROW_MIN_HEIGHT }}>
        <TableCell>
          <Typography color="secondary" variant="body2">
            {occupancy.key}
          </Typography>
        </TableCell>
        <TableCell>{occupancy.currentPatients ?? '-'}</TableCell>
        <TableCell>
          <Stack
            direction="row"
            gap={2}
            justifyContent="center"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: palette.secondary.medium,
                  borderRadius: 1,
                }}
              />
            }
          >
            {waitingPatientsSectors.map((sector) => (
              <Typography variant="body2" key={`${occupancy.id}-${sector}`} width={70} align="center">
                {occupancy.waitingPatientsBySector?.[sector] ?? '-'}
              </Typography>
            ))}
          </Stack>
        </TableCell>
        <TableCell>{occupancy.availableBeds ?? '-'}</TableCell>
        <TableCell>
          {occupancy.comment ? (
            <EllipsisTypography>{occupancy.comment}</EllipsisTypography>
          ) : (
            <Typography color={color.grey[30]} variant="body2">
              {i18n.empty}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={4} justifyContent="end" alignItems="center">
            {occupancy.updatedAt && (
              <Typography variant="body2" color={palette.text.secondary}>
                <Typography component="span" variant="body2" fontWeight={600}>
                  {format(occupancy.updatedAt, 'dd/MM')}
                </Typography>
                {' - ' + format(occupancy.updatedAt, 'HH:mm')}
              </Typography>
            )}
            {!isReadOnly && (
              <IconButton onClick={handleEdit} color="secondary">
                <Edit />
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>

      {!isReadOnly && (
        <Drawer
          open={modalOpen}
          onClose={handleClose}
          PaperProps={{ sx: { width: '100%' } }}
          SlideProps={{ direction: 'left' }}
        >
          <EditEmergencyOccupancyModal
            selectedOccupancy={occupancy}
            occupancies={editableOccupancies || [occupancy]}
            onClose={handleClose}
          />
        </Drawer>
      )}
    </>
  );
};

export default EmergencyOccupancyRow;
