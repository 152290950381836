import { Box, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AreaAutoComplete } from 'sabri/components/AutoComplete/AreaAutoComplete';
import { UnitAutoComplete } from 'sabri/components/AutoComplete/UnitAutoComplete';
import { i18n } from 'sabri/locales';
import InProgressStepIndicator from './InProgressStepIndicator';
import IsValidIcon from './IsValidIcon';
import { FormValue, StepFormValue, formKeys, stepFormKeys } from './JourneyForm';
import JourneyStepActions from './JourneyStepActions';
import JourneyStepDateInput from './JourneyStepDateInput';

type JourneyStepProps = {
  index: number;
  isInProgress?: boolean;
  hideActions?: boolean;
  onSwapDown: (index: number) => void;
  onSwapUp: (index: number) => void;
  onRemove: (index: number) => void;
};

const JourneyStep = ({
  index,
  isInProgress = false,
  hideActions = false,
  onSwapDown,
  onSwapUp,
  onRemove,
}: JourneyStepProps) => {
  const { watch, errors, control } = useFormContext<FormValue>();

  const steps = watch<string, StepFormValue[]>(formKeys.steps);
  const unitId = watch<string, string | undefined>(`${formKeys.steps}[${index}].${stepFormKeys.toUnitId}`);
  const wantedDate = watch<string, Date | undefined>(`${formKeys.steps}[${index}].${stepFormKeys.wantedDate}`);
  const fareId = watch<string, string | undefined>(`${formKeys.steps}[${index}].${stepFormKeys.fareId}`);
  const disabled = watch<string, boolean | undefined>(`${formKeys.steps}[${index}].${stepFormKeys.disabled}`);
  const actionsDisabled = watch<string, boolean | undefined>(
    `${formKeys.steps}[${index}].${stepFormKeys.actionsDisabled}`
  );

  return (
    <Stack spacing={2}>
      {isInProgress && <InProgressStepIndicator />}
      <Stack direction="row" gap={4}>
        <Box width={54} height={40} display="flex" alignItems="center">
          <Typography variant="overline">{steps.length === 1 ? i18n.arrival : i18n.step}</Typography>
        </Box>

        <Stack direction="row" gap={4} flex={1}>
          <Stack direction="row" gap={2} flex={1}>
            <UnitAutoComplete
              name={`${formKeys.steps}[${index}].${stepFormKeys.toUnitId}`}
              disabled={disabled}
              error={errors.steps?.[index]?.toUnitId}
            />
            <AreaAutoComplete
              name={`${formKeys.steps}[${index}].${stepFormKeys.toAreaId}`}
              unitId={unitId}
              disabled={disabled}
            />
          </Stack>
          <JourneyStepDateInput
            name={`${formKeys.steps}[${index}].${stepFormKeys.wantedDate}`}
            error={errors.steps?.[index]?.wantedDate}
            disabled={disabled}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={3}>
          <JourneyStepActions
            hideActions={hideActions || isInProgress}
            index={index}
            onSwapDown={onSwapDown}
            onSwapUp={onSwapUp}
            onRemove={onRemove}
          />
          <IsValidIcon isVisible={!!unitId && !!wantedDate && !disabled} />
        </Stack>
      </Stack>
      {/* Hidden inputs */}
      <Controller
        name={`${formKeys.steps}[${index}].${stepFormKeys.fareId}`}
        control={control}
        defaultValue={fareId || ''}
        render={({ value, name }) => <input type="hidden" value={value} name={name} />}
      />
      <Controller
        name={`${formKeys.steps}[${index}].${stepFormKeys.disabled}`}
        control={control}
        defaultValue={disabled || false}
        render={({ value, name }) => <input type="hidden" value={value} name={name} />}
      />
      <Controller
        name={`${formKeys.steps}[${index}].${stepFormKeys.actionsDisabled}`}
        control={control}
        defaultValue={actionsDisabled || false}
        render={({ value, name }) => <input type="hidden" value={value} name={name} />}
      />
    </Stack>
  );
};

export default JourneyStep;
