import * as amplitude from '@amplitude/analytics-browser';

export function trackEvent(
  eventInput: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, any>,
  eventOptions?: amplitude.Types.EventOptions
) {
  return amplitude.track(eventInput, eventProperties, eventOptions);
}

export function trackMultiSelectEvent<T>({
  eventType,
  currentValues,
  newValues,
  options,
}: {
  eventType: string;
  currentValues: T[];
  newValues: T[];
  options: { value: T; label: string }[];
}) {
  const valuesRemoved = getValuesRemoved(currentValues, newValues);
  const valuesAdded = getValuesAdded(currentValues, newValues);

  valuesRemoved.forEach((value) => {
    trackEvent({
      event_type: eventType,
      event_properties: {
        action: 'removed',
        value,
        label: options.find((option) => option.value === value)?.label,
      },
    });
  });

  valuesAdded.forEach((value) => {
    trackEvent({
      event_type: eventType,
      event_properties: {
        action: 'added',
        value,
        label: options.find((option) => option.value === value)?.label,
      },
    });
  });
}

function getValuesRemoved<T>(currentValues: T[], newValues: T[]): T[] {
  return currentValues.filter((value) => !newValues.includes(value));
}

function getValuesAdded<T>(currentValues: T[], newValues: T[]): T[] {
  return newValues.filter((value) => !currentValues.includes(value));
}
