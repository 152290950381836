import { SvgIcon, SvgIconProps } from '@mui/material';

const ChevronDown: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47 1.47a.75.75 0 0 0 0 1.06L9.94 8l-5.47 5.47a.75.75 0 1 0 1.06 1.06l5.824-5.823a1 1 0 0 0 0-1.414L5.53 1.47a.75.75 0 0 0-1.06 0Z"
      />
    </SvgIcon>
  );
};

export default ChevronDown;
