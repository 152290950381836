import { SvgIcon, SvgIconProps } from '@mui/material';

const TrashCanIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_3501_1682)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3409 1.85096C10.2367 1.78495 10.1159 1.74994 9.99259 1.75L6.0078 1.75C5.88455 1.75 5.76382 1.78504 5.65972 1.85105C5.55564 1.91704 5.47247 2.01126 5.4199 2.12272C5.41991 2.1227 5.41989 2.12274 5.4199 2.12272L4.98275 3.05H11.0176L10.5808 2.12278C10.5282 2.01124 10.445 1.91697 10.3409 1.85096ZM9.9922 0.25C10.4 0.249864 10.7993 0.365681 11.1438 0.583941C11.4883 0.802271 11.7636 1.1141 11.9376 1.48303L12.8785 3.48038C12.9879 3.7127 12.9709 3.98487 12.8333 4.20174C12.6958 4.4186 12.4568 4.55 12.2 4.55H3.8C3.54315 4.55 3.30417 4.41856 3.16662 4.20165C3.02907 3.98473 3.01208 3.71251 3.12161 3.48018L4.06311 1.48308C4.237 1.11432 4.51217 0.802547 4.85649 0.584229C5.20081 0.365913 5.60011 0.249997 6.0078 0.25M9.9922 0.25C9.99216 0.25 9.99224 0.25 9.9922 0.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60001 6.55005C7.01422 6.55005 7.35001 6.88584 7.35001 7.30005V10.8C7.35001 11.2143 7.01422 11.55 6.60001 11.55C6.18579 11.55 5.85001 11.2143 5.85001 10.8V7.30005C5.85001 6.88584 6.18579 6.55005 6.60001 6.55005ZM9.40001 6.55005C9.81422 6.55005 10.15 6.88584 10.15 7.30005V10.8C10.15 11.2143 9.81422 11.55 9.40001 11.55C8.98579 11.55 8.65001 11.2143 8.65001 10.8V7.30005C8.65001 6.88584 8.98579 6.55005 9.40001 6.55005Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 3.80005C0.25 3.38584 0.585786 3.05005 1 3.05005H15C15.4142 3.05005 15.75 3.38584 15.75 3.80005C15.75 4.21426 15.4142 4.55005 15 4.55005H1C0.585786 4.55005 0.25 4.21426 0.25 3.80005Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42349 13.0085C3.83519 12.963 4.20587 13.2598 4.25143 13.6715C4.26904 13.8305 4.34469 13.9775 4.4639 14.0842C4.58312 14.191 4.73752 14.25 4.89754 14.25C5.31175 14.2499 5.64756 14.5857 5.64759 14.9999C5.64761 15.4141 5.31185 15.7499 4.89764 15.75C4.36834 15.75 3.85764 15.5548 3.46332 15.2017C3.06899 14.8487 2.81876 14.3626 2.76054 13.8365C2.71497 13.4248 3.01179 13.0541 3.42349 13.0085ZM12.5765 13.0085C12.9882 13.0541 13.285 13.4248 13.2394 13.8365C13.1812 14.3626 12.931 14.8487 12.5367 15.2017C12.1423 15.5548 11.6316 15.75 11.1023 15.75C10.6881 15.7499 10.3524 15.4141 10.3524 14.9999C10.3524 14.5857 10.6882 14.2499 11.1024 14.25C11.2625 14.25 11.4169 14.191 11.5361 14.0842C11.6553 13.9775 11.7309 13.8305 11.7485 13.6715C11.7941 13.2598 12.1648 12.963 12.5765 13.0085Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.31718 3.0547C2.72886 3.00896 3.09967 3.30561 3.14541 3.71729L4.25143 13.6715C4.29718 14.0832 4.0005 14.4538 3.58882 14.4995C3.17714 14.5453 2.80628 14.2482 2.76054 13.8365L1.65459 3.88294C1.60884 3.47126 1.9055 3.10044 2.31718 3.0547ZM13.6828 3.0547C14.0945 3.10044 14.3912 3.47126 14.3454 3.88294L13.2394 13.8365C13.1937 14.2482 12.8229 14.5453 12.4112 14.4995C11.9995 14.4538 11.7028 14.0832 11.7485 13.6715L12.8546 3.71729C12.9003 3.30561 13.2711 3.00896 13.6828 3.0547Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1476 15.0002C4.1476 14.586 4.48332 14.25 4.89754 14.25H11.1024C11.5167 14.25 11.8524 14.586 11.8524 15.0002C11.8524 15.4145 11.5165 15.75 11.1023 15.75H4.89764C4.48342 15.75 4.1476 15.4145 4.1476 15.0002Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3501_1682">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default TrashCanIcon;
