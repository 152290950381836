import { FarePosition, Porter } from '@ambuliz/sabri-core';
import { Typography } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import { fr } from 'date-fns/locale';
import LocationDetails from 'sabri/components/LocationDetails/LocationDetails';
import { porterSchema } from 'sabri/const/schemas';
import { cleanNormalize } from 'sabri/utils/cleanNormalize';
import { AvatarName } from '../components/AvatarName/AvatarName';
import { ChipPorterStatus } from '../components/ChipPorterStatus/ChipPorterStatus';
import { LogoutPorterButton } from '../components/LogoutPorterButton/LogoutPorterButton';
import { TableListColumn, TableListTab } from '../components/TableList/TableList';
import { routeNamesCommon } from '../const/routeNames';
import { i18n } from '../locales';
import { PorterDenormalized } from '../store/selectors/porter';

const sortPortersByName = (porterA: PorterDenormalized, porterB: PorterDenormalized) => {
  const porterNameA = [porterA.firstName, porterA.lastName].join(' ');
  const porterNameB = [porterB.firstName, porterB.lastName].join(' ');
  return porterNameB.localeCompare(porterNameA);
};

const sortPortersByStatus = (porterA: PorterDenormalized, porterB: PorterDenormalized) => {
  if (porterA.status && porterB.status) {
    const statusA = i18n.porterStatus[porterA.status];
    const statusB = i18n.porterStatus[porterB.status];
    return statusB.localeCompare(statusA);
  }
  return 0;
};

const sortPortersByArea =
  (property: 'lastArea' | 'nextArea') => (porterA: PorterDenormalized, porterB: PorterDenormalized) => {
    if (porterA[property] && porterB[property]) {
      const areaNameA = porterA[property]!.name;
      const areaNameB = porterB[property]!.name;
      return areaNameB.localeCompare(areaNameA);
    }
    return 0;
  };

const sortPortersByCount =
  (property: 'fareDoneCount' | 'fareAssignedCount') => (porterA: PorterDenormalized, porterB: PorterDenormalized) =>
    (porterB[property] || 0) - (porterA[property] || 0);

export const teamTabs: TableListTab<any>[] = [
  {
    id: 'working',
    routeName: routeNamesCommon.team,
    label: i18n.teamPortersWorking,
    labelEmpty: i18n.noTeamPortersWorking,
    filterMethod: (porter) => porter.status !== 'OFF',
  },
  {
    id: 'available',
    routeName: routeNamesCommon.teamAvailable,
    label: i18n.teamPortersAvailable,
    labelEmpty: i18n.noTeamPortersAvailable,
    filterMethod: (porter) => porter.status === 'AVAILABLE',
  },
  {
    id: 'busy',
    routeName: routeNamesCommon.teamBusy,
    label: i18n.teamPortersBusy,
    labelEmpty: i18n.noTeamPortersBusy,
    filterMethod: (porter) => porter.status === 'BUSY',
  },
  {
    id: 'all',
    routeName: routeNamesCommon.teamAll,
    label: i18n.teamPortersAll,
    labelEmpty: i18n.noTeamPortersAll,
  },
];

export const teamColumns: TableListColumn<PorterDenormalized>[] = [
  {
    id: 'porter',
    label: i18n.porter,
    isSortable: true,
    sortMethod: sortPortersByName,
    renderCell: (porter) => <AvatarName user={porter} />,
  },
  {
    id: 'status',
    label: i18n.status,
    isSortable: true,
    sortMethod: sortPortersByStatus,
    renderCell: (porter) =>
      porter.status && <ChipPorterStatus status={porter.status} hasInternet={porter.hasInternet} />,
  },

  {
    id: 'lastArea',
    label: i18n.lastArea,
    isSortable: true,
    sortMethod: sortPortersByArea('lastArea'),
    renderCell: (porter) => (
      <>
        <LocationDetails unitId={porter.lastUnitId} areaId={porter.lastAreaId} />
        {porter.lastAreaAt && (
          <Typography variant="caption" color="textSecondary">
            Il y a {formatDistanceToNowStrict(new Date(porter.lastAreaAt), { locale: fr })}
          </Typography>
        )}
      </>
    ),
  },
  {
    id: 'nextArea',
    label: i18n.nextArea,
    isSortable: true,
    sortMethod: sortPortersByArea('nextArea'),
    renderCell: (porter) => <LocationDetails unitId={porter.nextUnitId} areaId={porter.nextAreaId} />,
  },
  {
    id: 'fareDoneCount',
    label: i18n.faresDone,
    isSortable: true,
    sortMethod: sortPortersByCount('fareDoneCount'),
    renderCell: (porter) => <Typography variant="body2">{porter.fareDoneCount}</Typography>,
  },
  {
    id: 'fareAssignedCount',
    label: i18n.faresAssigned,
    isSortable: true,
    sortMethod: sortPortersByCount('fareAssignedCount'),
    renderCell: (porter) => <Typography variant="body2">{porter.fareAssignedCount}</Typography>,
  },
  {
    id: 'logout',
    label: i18n.logoutPorter,
    isSortable: false,
    renderCell: (porter) =>
      porter.status && ['AVAILABLE', 'BUSY', 'ON_BREAK'].includes(porter.status) ? (
        <LogoutPorterButton porter={porter} />
      ) : null,
  },
];

export const normalizePorters = (porters: Porter[]): PorterDenormalized[] => {
  const normalizedPorters = cleanNormalize(
    porters.map((porter) => porter.toJSON()),
    [porterSchema]
  ).entities.porters;

  if (!normalizedPorters) {
    return [];
  }

  return Object.values(normalizedPorters);
};

export const getPortersLimitByPosition = (
  portersLimitByFarePosition: Record<FarePosition, number> | undefined,
  position: FarePosition
) => {
  return portersLimitByFarePosition ? portersLimitByFarePosition[position] : null;
};
