import { Badge, ClickAwayListener, Fade, IconButton, Popper } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { NotificationsPanel } from 'common/components';
import { Bell } from 'common/components/Icons';
import { theme } from 'common/theme';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from './NotificationContext';

type NotificationCenterProps = {};

const NotificationCenter: React.FC<NotificationCenterProps> = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const { notifications, badgeCount, loading, setBadgeCount, read, unread, readAll, getPath } = useNotifications();
  const navigate = useNavigate();

  const toggle = () => {
    setOpen(!open);
    setBadgeCount(0);
  };

  const handleClickAway = () => {
    if (open) {
      toggle();
    }
  };

  const handleToggleRead = (id: string) => {
    const notification = notifications.find((notification) => notification.id === id);

    if (notification) {
      if (notification.read) {
        unread(id);
      } else {
        read(id);
      }
    }
  };

  const handleClickOnNotification = (id: string) => {
    read(id);
    const redirectTo = getPath(id);
    if (redirectTo) {
      toggle();
      navigate(redirectTo);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Badge badgeContent={badgeCount} max={9}>
          <IconButton variant="filled" shape="rounded" size="large" onClick={toggle} ref={buttonRef}>
            <Bell />
          </IconButton>
        </Badge>

        <Popper
          open={open}
          anchorEl={buttonRef.current}
          style={{ zIndex: theme.zIndex.appBar + 1 }}
          placement="bottom"
          transition
          modifiers={[
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [-108, 8],
              },
            },
          ]}
        >
          {({ TransitionProps }: { TransitionProps?: TransitionProps }) => (
            <Fade {...(TransitionProps || {})} timeout={350}>
              <div>
                <NotificationsPanel
                  onClose={toggle}
                  onReadAll={readAll}
                  notifications={notifications}
                  onClickNotification={(id) => {
                    handleClickOnNotification(id);
                  }}
                  onToggleReadNotification={(id) => handleToggleRead(id)}
                  loading={loading}
                />
              </div>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default NotificationCenter;
