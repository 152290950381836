import { Grid, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { ArrowCircle, Calendar, Clock, Comment, End, HeartBeat, List } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { format } from 'date-fns';
import BedCommentSummary from 'kurt/components/AccommodationDetails/BedCommentSummary';
import { Accommodation } from 'kurt/hooks/useMovementAccommodations';
import { SpecificityChip } from '../../SpecifityChip';
import MutationStatusChip from '../MutationStatusChip';
import DetailsSummaryRow from './DetailsSummaryRow';
import { MutationFlow } from './FlowSummary/MutationFlow';
import PerformersList from './PerformersList';

type MutationSummaryProps = {
  accommodation: Accommodation;
  flow: 'admission' | 'discharge';
};

const MutationSummary = ({ accommodation, flow }: MutationSummaryProps) => {
  const mutation = accommodation.nextAccommodation ? accommodation.nextAccommodation : accommodation;

  if (!mutation.basedOn) {
    return null;
  }

  const mutationTo =
    flow === 'discharge'
      ? accommodation.nextAccommodation
      : accommodation.nextAccommodation && !accommodation.previousAccommodation
        ? accommodation.nextAccommodation
        : accommodation;

  const mutationFrom = flow === 'discharge' ? accommodation : accommodation.previousAccommodation;

  const fromUnitName =
    flow === 'discharge'
      ? accommodation.unit.name
      : accommodation.basedOn?.requestType === 'DIRECT_ADMISSION'
        ? accommodation.basedOn.directAdmissionOrigin
        : accommodation.previousAccommodation?.unit.name;

  const fromBedName = flow === 'discharge' ? accommodation.bed?.name : accommodation.previousAccommodation?.bed?.name;

  const firstPerformerRequest = accommodation.basedOn?.performerRequests
    ? accommodation.basedOn?.performerRequests.find((request) => request.status !== 'REJECTED')
    : undefined;

  const getDischargeToUnitName = () => {
    return firstPerformerRequest?.performer?.name
      ? firstPerformerRequest.performer.name
      : mutation?.basedOn?.status === 'ACCEPTED'
        ? mutation.unit?.name
        : undefined;
  };

  return (
    <Stack spacing={5}>
      <MutationFlow
        fromUnitName={fromUnitName || i18n.defaultOrigin}
        fromBedName={fromBedName}
        dischargeDate={!!mutationTo?.admissionDate ? mutationFrom?.dischargeDate : undefined}
        toUnitName={
          mutation.basedOn.requestType === 'INTERNAL_MUTATION'
            ? mutationTo?.unit?.name
            : flow === 'discharge'
              ? getDischargeToUnitName()
              : mutationTo?.unit?.name
        }
        toBedName={mutationTo?.bed?.name}
        admissionDate={mutationTo?.admissionDate}
      />

      <Stack spacing={4}>
        <DetailsSummaryRow
          label={i18n.movementType}
          Icon={Calendar}
          value={mutation.basedOn.requestType ? i18n.mutationSummary.movementType[mutation.basedOn.requestType] : '-'}
        />
        <DetailsSummaryRow
          label={i18n.requestReceivedAt}
          Icon={Clock}
          value={mutation?.createdAt && format(mutation?.createdAt, 'dd/MM - HH:mm')}
        />
        <DetailsSummaryRow
          label={i18n.movementStatus}
          Icon={End}
          value={
            mutation.basedOn?.status && (
              <MutationStatusChip
                status={mutation.basedOn?.status}
                hasBed={flow === 'admission' ? !!accommodation.bed : !!accommodation.nextAccommodation?.bed}
                accommodationStatus={
                  flow === 'admission' ? accommodation.status : accommodation.nextAccommodation?.status
                }
              />
            )
          }
        />
        <DetailsSummaryRow
          label={i18n.requiredSpecificities}
          Icon={List}
          value={
            !!mutation.basedOn?.specificities?.length ? (
              <Grid container spacing={1}>
                {mutation.basedOn?.specificities.map((specificity) => (
                  <Grid item key={specificity}>
                    <SpecificityChip key={specificity} specificity={specificity} />
                  </Grid>
                ))}
              </Grid>
            ) : !!mutation.specificities?.length ? (
              <Grid container spacing={1}>
                {mutation.specificities.map((specificity) => (
                  <Grid item key={specificity}>
                    <SpecificityChip key={specificity} specificity={specificity} />
                  </Grid>
                ))}
              </Grid>
            ) : undefined
          }
        />
        <DetailsSummaryRow label={i18n.movementReason} Icon={HeartBeat} value={mutation.basedOn?.reason} />
        {mutation.basedOn?.status === 'REQUESTED' ? (
          <DetailsSummaryRow label={i18n.comment} Icon={Comment} value={mutation.basedOn?.comment} />
        ) : (
          <BedCommentSummary accommodationId={mutation.id} comment={mutation.basedOn?.comment} size="small" />
        )}

        {flow === 'discharge' && (
          <Stack spacing={3}>
            <DetailsSummaryRow label={i18n.guidance} Icon={ArrowCircle} value={<></>} />
            {accommodation.isRejected && (
              <Stack borderRadius={1} sx={{ backgroundColor: palette.error.light }} direction="row" alignItems="center">
                <Stack padding={4} alignItems="center">
                  <Emoji name="confoundedFace" />
                </Stack>
                <Stack paddingRight={4} paddingY={4} spacing={1}>
                  <Typography variant="h4" color={palette.error.main}>
                    {i18n.requestRejected.label}
                  </Typography>
                  <Stack>
                    <Typography variant="body1" color="secondary">
                      {i18n.requestRejected.description}
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {i18n.requestRejected.action}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <PerformersList
              performerRequests={
                accommodation.basedOn?.performerRequests ||
                accommodation.nextAccommodation?.basedOn?.performerRequests ||
                []
              }
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default MutationSummary;
