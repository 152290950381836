import { getAvailableBeds } from '@ambuliz/sabri-core';
import {
  Box,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import EllipsisTypography from 'common/components/EllipsisTypography';
import { ChevronSmallDown, ChevronSmallRight, Clock, Edit, List } from 'common/components/Icons';
import { InOutFlow } from 'common/components/Statistics';
import { i18n } from 'common/locale';
import { color, theme } from 'common/theme';
import shadows from 'common/theme/shadows';
import { hyphenIfUndefined, progressValue } from 'common/utils';
import { isBefore } from 'date-fns';
import { OUTDATED_UPDATED_AT, getThresholdedColor } from 'kurt/const';
import { NormalizedDepartment, NormalizedUnit } from 'kurt/hooks/useDepartments';
import React, { useCallback, useState } from 'react';
import UnitEditModal from '../EditModal/EditModal';
import BedsAvailabilities from './BedsAvailabilities';
import CustomGrid from './CustomGrid';
import OccupancyInfos from './OccupancyInfos';
import UnitRow from './UnitRow';

export type DepartmentRowProps = NormalizedDepartment & { canEdit?: boolean; columns: number[]; isMedium: boolean };

const DepartmentRow: React.FC<DepartmentRowProps> = ({
  name,
  units = [],
  occupancy,
  canEdit = false,
  columns,
  isMedium,
}) => {
  const isEditable = canEdit && units.some(({ autoComputeOccupanciesActivated }) => !autoComputeOccupanciesActivated);

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const toggleDetails = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setOpenDetails((open) => !open);
  }, []);

  return (
    <Grid
      style={{ padding: '16px 0 16px 16px' }}
      component={Paper}
      container
      sx={{
        [`&:hover`]: {
          boxShadow: shadows[4],
        },
      }}
    >
      <Grid item container alignItems="center" onClick={toggleDetails} style={{ cursor: 'pointer' }} spacing={4}>
        <CustomGrid item percentile={columns[0]}>
          <Grid item container justifyContent="space-between" alignItems="center" spacing={4}>
            <Grid item xs>
              <Stack direction="row" spacing={1} alignItems="center">
                {units && units.length !== 0 && (
                  <IconButton onClick={toggleDetails}>
                    {openDetails ? <ChevronSmallDown /> : <ChevronSmallRight />}
                  </IconButton>
                )}
                <EllipsisTypography fontWeight={600} maxLines={2}>
                  {name}
                </EllipsisTypography>
              </Stack>
            </Grid>
            {units && units.length !== 0 && (
              <Grid item>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <List color="secondary" />
                  <Typography>{units.length}</Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CustomGrid>
        <CustomGrid item percentile={columns[1]}>
          <Grid container alignItems="center" spacing={2}>
            <Grid sx={{ width: 48 }} item>
              <LinearProgress
                value={progressValue(occupancy?.rate)}
                color={getThresholdedColor(occupancy?.rate, 'secondary')}
              />
            </Grid>
            <Grid item sx={{ minWidth: 36 }}>
              <Typography>{hyphenIfUndefined(occupancy?.rate)}%</Typography>
            </Grid>
            <Grid item>
              <OccupancyInfos occupancy={occupancy} />
            </Grid>
          </Grid>
        </CustomGrid>

        <CustomGrid item percentile={columns[2]}>
          <InOutFlow admission={occupancy?.admission} discharge={occupancy?.discharge} />
        </CustomGrid>
        <CustomGrid item percentile={columns[3]}>
          <BedsAvailabilities
            {...occupancy?.dDayAvailabilities}
            total={getAvailableBeds(
              occupancy?.dDayAvailabilities?.male,
              occupancy?.dDayAvailabilities?.female,
              occupancy?.dDayAvailabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>

        <CustomGrid item percentile={columns[4]}>
          <BedsAvailabilities
            {...occupancy?.h24Availabilities}
            total={getAvailableBeds(
              occupancy?.h24Availabilities?.male,
              occupancy?.h24Availabilities?.female,
              occupancy?.h24Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        <CustomGrid item percentile={columns[5]}>
          <BedsAvailabilities
            {...occupancy?.h48Availabilities}
            total={getAvailableBeds(
              occupancy?.h48Availabilities?.male,
              occupancy?.h48Availabilities?.female,
              occupancy?.h48Availabilities?.indifferent
            )}
            showTooltip={isMedium}
          />
        </CustomGrid>
        {isEditable && (
          <CustomGrid item percentile={columns[6]}>
            <Box paddingRight={4}>
              <Grid container spacing={4} alignItems="center" justifyContent="flex-end">
                {!!units && !!numberOfOutdatedUnits(units) && (
                  <Grid item style={{ color: theme.palette.warning.main }}>
                    <Tooltip
                      title={
                        <Typography variant="body2" fontWeight={600}>
                          {numberOfOutdatedUnits(units)}
                          <Typography variant="body2" component="span">
                            {numberOfOutdatedUnits(units) > 1 ? i18n.outdatedUnits : i18n.outdatedUnit}
                          </Typography>
                        </Typography>
                      }
                    >
                      <TooltipIconContainer container alignItems="center">
                        <Clock color="inherit" />
                      </TooltipIconContainer>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setModalOpen(!modalOpen);
                    }}
                  >
                    <Edit color="secondary" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </CustomGrid>
        )}
      </Grid>

      {units && units.length && (
        <Collapse in={openDetails} style={{ width: '100%' }}>
          <Grid
            container
            direction="column"
            spacing={4}
            style={{ borderTop: `1px solid ${color.grey[5]}`, marginTop: 14, marginBottom: -16 }}
          >
            {units?.map((unit) => {
              return (
                <UnitRow {...unit} key={unit.id} displayUpdatedAt={isEditable} columns={columns} isMedium={isMedium} />
              );
            })}
          </Grid>
        </Collapse>
      )}

      {isEditable && (
        <Dialog
          open={modalOpen}
          fullWidth
          fullScreen
          maxWidth="xl"
          //@ts-ignore
          TransitionComponent={Slide}
          //@ts-ignore
          TransitionProps={{ direction: 'left' }}
          hideBackdrop
        >
          <UnitEditModal units={units} name={name} onClose={() => setModalOpen(false)} />
        </Dialog>
      )}
    </Grid>
  );
};

const numberOfOutdatedUnits = (units: NormalizedUnit[]) => {
  return units.filter(({ occupancy }) =>
    occupancy?.updatedAt ? isBefore(occupancy?.updatedAt, OUTDATED_UPDATED_AT) : false
  ).length;
};

const TooltipIconContainer = styled(Grid)`
  cursor: pointer;
`;

export default DepartmentRow;
