import { SvgIcon, SvgIconProps } from '@mui/material';

const ConstructionIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1678_389212)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33301 10.1235V12.3592H14.6674V10.1235H1.33301ZM14.8458 10.1273L14.8436 10.1268L14.8458 10.1273ZM14.6672 9.92365L14.6669 9.92057L14.6672 9.92365ZM14.6669 12.5621L14.6672 12.559L14.6669 12.5621ZM14.8436 12.3559L14.8458 12.3554L14.8436 12.3559ZM0.313405 9.22284C0.550046 8.94269 0.888357 8.87354 1.17578 8.87354H14.7886C15.0797 8.87354 15.4031 8.94377 15.6427 9.19356C15.8787 9.43975 15.9174 9.7408 15.9174 9.93424V12.5485C15.9174 12.7419 15.8787 13.0429 15.6427 13.2891C15.4031 13.5389 15.0797 13.6092 14.7886 13.6092H1.17578C0.888357 13.6092 0.550046 13.54 0.313405 13.2598C0.0952597 13.0016 0.0830078 12.6958 0.0830078 12.5485V9.93424C0.0830078 9.78685 0.0952597 9.4811 0.313405 9.22284Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33301 3.59277V5.8284H14.6674V3.59277H1.33301ZM14.8458 3.59655L14.8436 3.59609L14.8458 3.59655ZM14.6672 3.39289L14.6669 3.38981L14.6672 3.39289ZM14.6669 6.03136L14.6672 6.02828L14.6669 6.03136ZM14.8436 5.82509L14.8458 5.82462L14.8436 5.82509ZM0.313405 2.69208C0.550046 2.41193 0.888357 2.34277 1.17578 2.34277H14.7886C15.0797 2.34277 15.4031 2.41301 15.6427 2.6628C15.8787 2.90899 15.9174 3.21003 15.9174 3.40348V6.01769C15.9174 6.21114 15.8787 6.51218 15.6427 6.75837C15.4031 7.00816 15.0797 7.0784 14.7886 7.0784H1.17578C0.888357 7.0784 0.550046 7.00924 0.313405 6.72909C0.0952597 6.47083 0.0830078 6.16508 0.0830078 6.01769V3.40348C0.0830078 3.25609 0.0952597 2.95034 0.313405 2.69208Z"
      />
      <path d="M4.18865 1.31641C4.18865 0.971228 3.90882 0.691406 3.56365 0.691406C3.21847 0.691406 2.93865 0.971228 2.93865 1.31641V2.17243C2.93865 2.51761 3.21847 2.79743 3.56365 2.79743C3.90882 2.79743 4.18865 2.51761 4.18865 2.17243V1.31641Z" />
      <path d="M13.1889 1.31656C13.1889 0.971379 12.9091 0.691557 12.5639 0.691557C12.2187 0.691557 11.9389 0.971379 11.9389 1.31656V2.17258C11.9389 2.23159 11.9471 2.2887 11.9623 2.34282C11.8938 2.35788 11.8266 2.38484 11.7637 2.42438C11.4715 2.60815 11.3836 2.99399 11.5674 3.28618L13.6333 6.57105C13.8171 6.86325 14.2029 6.95114 14.4951 6.76738C14.7873 6.58361 14.8752 6.19777 14.6914 5.90557L12.7238 2.77694C12.9915 2.70631 13.1889 2.4625 13.1889 2.17258V1.31656Z" />
      <path d="M9.36204 2.63576C9.18657 2.3385 8.80335 2.23978 8.5061 2.41524C8.20885 2.59071 8.11012 2.97392 8.28559 3.27118L10.3515 6.771C10.527 7.06826 10.9102 7.16698 11.2075 6.99152C11.5047 6.81605 11.6034 6.43284 11.428 6.13558L9.36204 2.63576Z" />
      <path d="M2.30942 2.83557C2.12565 2.54338 1.73981 2.45548 1.44762 2.63925C1.15542 2.82301 1.06752 3.20886 1.25129 3.50105L2.97507 6.24189C2.95134 6.30792 2.93841 6.3791 2.93841 6.45329V9.35023C2.93841 9.69541 3.21823 9.97523 3.56341 9.97523C3.90859 9.97523 4.18841 9.69541 4.18841 9.35023V6.97623C4.47308 6.79016 4.55713 6.40948 4.37535 6.12044L2.30942 2.83557Z" />
      <path d="M5.84671 2.83568C5.66294 2.54349 5.2771 2.45559 4.9849 2.63936C4.69271 2.82313 4.60481 3.20897 4.78858 3.50116L6.85451 6.78603C7.03828 7.07823 7.42412 7.16613 7.71631 6.98236C8.00851 6.79859 8.09641 6.41275 7.91264 6.12055L5.84671 2.83568Z" />
      <path d="M13.1886 6.45344C13.1886 6.10827 12.9088 5.82844 12.5636 5.82844C12.2185 5.82844 11.9386 6.10827 11.9386 6.45344V8.89369C11.8783 8.90945 11.8193 8.93458 11.7637 8.96955C11.4715 9.15332 11.3836 9.53916 11.5674 9.83136L13.6333 13.1162C13.8171 13.4084 14.2029 13.4963 14.4951 13.3125C14.7873 13.1288 14.8752 12.7429 14.6914 12.4507L13.0141 9.78369C13.1222 9.67134 13.1886 9.51862 13.1886 9.35038V6.45344Z" />
      <path d="M9.36204 9.18093C9.18657 8.88368 8.80335 8.78495 8.5061 8.96042C8.20885 9.13588 8.11012 9.5191 8.28559 9.81635L10.3515 13.3162C10.527 13.6134 10.9102 13.7122 11.2075 13.5367C11.5047 13.3612 11.6034 12.978 11.428 12.6808L9.36204 9.18093Z" />
      <path
        d="M2.30942 9.38074C2.12565 9.08855 1.73981 9.00065 1.44761 9.18442C1.15542 9.36819 1.06752 9.75403 1.25129 10.0462L2.97511 12.7871C2.9514 12.8531 2.93848 12.9243 2.93848 12.9985V16.0141C2.93848 16.3593 3.2183 16.6391 3.56348 16.6391C3.90866 16.6391 4.18848 16.3593 4.18848 16.0141V13.5214C4.4731 13.3353 4.55712 12.9546 4.37535 12.6656L2.30942 9.38074Z"
        fill="#666D8E"
      />
      <path d="M5.84671 9.38086C5.66294 9.08866 5.2771 9.00077 4.9849 9.18453C4.69271 9.3683 4.60481 9.75414 4.78858 10.0463L6.85451 13.3312C7.03828 13.6234 7.42412 13.7113 7.71631 13.5275C8.00851 13.3438 8.09641 12.9579 7.91264 12.6657L5.84671 9.38086Z" />
      <path d="M13.1887 12.9986C13.1887 12.6534 12.9089 12.3736 12.5637 12.3736C12.2185 12.3736 11.9387 12.6534 11.9387 12.9986V16.0143C11.9387 16.3595 12.2185 16.6393 12.5637 16.6393C12.9089 16.6393 13.1887 16.3595 13.1887 16.0143V12.9986Z" />
    </g>
    <defs>
      <clipPath id="clip0_1678_389212">
        <rect width="16" height="16" fill="white" transform="translate(0 0.621094)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ConstructionIcon;
