import { Accommodation, AccommodationSpecificity } from '@ambuliz/sabri-core';
import { Box, Stack } from '@mui/material';
import { ExternalPatientTransportModal } from 'common/components';
import ComfortBedIcon from 'common/components/Icons/ComfortBed';
import PathIcon from 'common/components/Icons/Path';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { useContext, useMemo } from 'react';
import { getStepSchedule } from '../../DetailsDialogContent';
import DialogTabs from '../../DialogTabs';
import { DialogTabOption } from '../../DialogTabs/DialogTabs';
import DialogEditButtons from '../../EditButtons';
import { DialogContext } from '../../context/DialogContext';
import { AccommodationStep, MutationStep, Step, StepItem } from '../../context/Step';
import MutationDetailsContent from '../Mutation/MutationDetailsContent';
import useUpcomingPatientTransport from '../useUpcomingPatientTransport';
import AccommodationDetailsContent from './AccommodationDetailsContent';

export type EditableAccommodation = {
  specificities: AccommodationSpecificity[];
  comment: string;
  startAt: Date;
  endAt?: Date;
};

type AccommodationDetailsProps = {
  step: AccommodationStep;
  currentTab?: string;
  editable?: boolean;
  onChange?: (step: Step) => void;
  onDestinationChange?: (accommodation: Accommodation) => void;
  onTabChange: (tab: string) => void;
  isReadOnly?: boolean;
};

const AccommodationDetails = ({
  step,
  editable,
  currentTab,
  isReadOnly = false,
  onTabChange,
  onChange,
  onDestinationChange,
}: AccommodationDetailsProps) => {
  const { stepItems, editableStep, currentStep } = useContext(DialogContext);

  const options = useMemo(
    () => getOptions({ step, stepItems, editable, currentTab }),
    [step, stepItems, editable, currentTab]
  );

  const {
    src,
    upcomingPatientTransport,
    handleOrderExternalTransportClick,
    handleUpcomingPatientTransportClick,
    onModalClose,
  } = useUpcomingPatientTransport(stepItems);

  return (
    <>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <DialogTabs
            options={options}
            value={currentTab ? currentTab : step.step.id}
            onChange={onTabChange}
            disabled={editable}
          />
          {!isReadOnly && (
            <Box alignSelf={'flex-start'}>
              <DialogEditButtons currentStep={currentStep} />
            </Box>
          )}
        </Stack>
        {currentStep?.type === 'mutation' ? (
          <MutationDetailsContent
            step={editable ? (editableStep as MutationStep) : currentStep}
            editable={editable}
            onChange={(step) => onChange && onChange(step)}
            onDestinationChange={(destination) => onDestinationChange && onDestinationChange(destination)}
          />
        ) : currentStep?.type === 'accommodation' ? (
          <AccommodationDetailsContent
            step={editable ? (editableStep as AccommodationStep) : currentStep}
            editable={editable}
            onChange={(step) => onChange && onChange(step)}
            upcomingPatientTransport={upcomingPatientTransport}
            onOrderExternalTransportClick={handleOrderExternalTransportClick}
            onUpcomingPatientTransportClick={handleUpcomingPatientTransportClick}
          />
        ) : null}
      </Stack>
      <ExternalPatientTransportModal src={src} onClose={onModalClose} />
    </>
  );
};

const getOptions = ({
  step,
  stepItems,
  editable,
  currentTab,
}: {
  step: AccommodationStep;
  stepItems: StepItem[];
  editable?: boolean;
  currentTab?: string;
}) => {
  const options: DialogTabOption[] = [
    {
      label:
        !editable || currentTab === step.step.id
          ? i18n.detailsDialog.tabs[getStepSchedule(step.step, step.anomalies || [])]
          : undefined,
      value: step.step.id,
      Icon: <ComfortBedIcon />,
      iconColor: color.blue[40],
    },
  ];

  const pastStep = stepItems.find((item) => item.type === 'mutation' && item.destination?.id === step.step.id);

  if (pastStep) {
    options.unshift({
      label: !editable || currentTab === pastStep.step.id ? i18n.detailsDialog.tabs.movement : undefined,
      value: pastStep.step.id,
      Icon: <PathIcon />,
      iconColor: color.blue[30],
    });
  }

  const futureStep = stepItems.find((item) => item.type === 'mutation' && item.origin?.id === step.step.id);
  if (futureStep) {
    options.push({
      label: !editable || currentTab === futureStep.step.id ? i18n.detailsDialog.tabs.movement : undefined,
      value: futureStep.step.id,
      Icon: <PathIcon />,
      iconColor: color.blue[30],
    });
  }

  return options;
};

export default AccommodationDetails;
