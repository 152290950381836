import { AccommodationStatus } from '@ambuliz/sabri-core';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVertical } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { useRef, useState } from 'react';
import { CancelBedClosedDialog, MakeBedAvailableDialog } from '../Dialogs';

type ClosedBedActionsProps = {
  id: string;
  status: AccommodationStatus;
  onCancelBedClosed?: () => void;
};

const ClosedBedActionsMenu = ({ id, status, onCancelBedClosed }: ClosedBedActionsProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const [action, setAction] = useState<Action>();

  const handleMenuClose = () => setOpen(false);

  const handleMenuItemClick = (action: Action) => {
    handleMenuClose();
    setAction(action);
  };

  const handleDialogClose = () => {
    setAction(undefined);
  };

  const handleSuccess = () => {
    if (action === 'CANCEL_BED_CLOSED' && onCancelBedClosed) {
      handleDialogClose();
      handleMenuClose();
      return onCancelBedClosed();
    }
    handleDialogClose();
    handleMenuClose();
  };

  return (
    <Box display="flex" alignItems="center" onClick={(event) => event.stopPropagation()}>
      <IconButton
        onClick={() => {
          setOpen(!open);
        }}
        ref={ref}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {status === 'PLANNED' && (
          <MenuItem onClick={() => handleMenuItemClick('MAKE_BED_AVAILABLE')}>
            {i18n.makeBedAvailable.actionLabel}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuItemClick('CANCEL_BED_CLOSED')} sx={{ color: palette.error.main }}>
          {i18n.cancelBedClosed.actionLabel}
        </MenuItem>
      </Menu>

      {action === 'MAKE_BED_AVAILABLE' && (
        <MakeBedAvailableDialog
          open={action === 'MAKE_BED_AVAILABLE'}
          onClose={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
        />
      )}

      {action === 'CANCEL_BED_CLOSED' && (
        <CancelBedClosedDialog
          open={action === 'CANCEL_BED_CLOSED'}
          onClose={handleDialogClose}
          onSuccess={handleSuccess}
          id={id}
        />
      )}
    </Box>
  );
};

type Action = 'MAKE_BED_AVAILABLE' | 'CANCEL_BED_CLOSED';

export default ClosedBedActionsMenu;
