import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { modalNames } from '../../const/modalNames';
import { i18n } from '../../locales';
import { ModalContainer } from '../ModalContainer/ModalContainer';

export const ConfirmModal: React.FC = () => {
  return (
    <ModalContainer modalName={modalNames.confirm}>
      {({ isVisible, onClose, params }) =>
        isVisible &&
        params && (
          <Dialog open fullWidth onClose={onClose}>
            <DialogTitle>{params.title}</DialogTitle>
            <DialogContent>
              <Box fontSize={14}>{params.content}</Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{i18n.cancel}</Button>
              <Button onClick={params.onConfirm} color="primary">
                {i18n.confirm}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </ModalContainer>
  );
};
