import {
  AccommodationSpecificity,
  AccommodationStatus,
  Patient,
  PatientGender,
  PerformerRequest,
  RequestStatus,
  RequestType,
} from '@ambuliz/sabri-core';
import { filterBySearch } from 'common/utils';
import { endOfDay, isPast } from 'date-fns';
import { PRACTITIONER_NOT_DEFINED_VALUE } from 'kurt/components/Practitioners/PractitionersFilter';
import { useMovementAccommodations } from 'kurt/hooks';
import useAccommodationRequests from 'kurt/hooks/useAccommodationRequests';
import { Accommodation } from 'kurt/hooks/useMovementAccommodations';
import { useMemo } from 'react';
import { MovementTypeFilter } from './useMovementsPageSearchParams';

type VisitFilter = {
  specificities: AccommodationSpecificity[];
  genders: PatientGender[];
  search?: string;
  movementTypes: MovementTypeFilter[];
  practitioners: string[];
};

export type PatientID = {
  id: string;
  ipp: string;
  ins?: string;
  gender: PatientGender;
  name: string;
  fullName: string;
  birthdate: Date;
};

export type Visit = {
  id: string;
  status: AccommodationStatus;
  admissionDate?: Date;
  dischargeDate?: Date;
  isDischargeDateValidated?: boolean;
  isDischarged?: boolean;
  isVisitDischarged?: boolean;
  specificities: AccommodationSpecificity[];
  from?: string;
  to?: string;
  bed?: string;
  bedId?: string;
  pan: string;
  unit: {
    id: string;
    name: string;
  };
  patient: PatientID;
  comment?: string;
  isMutationRequest?: boolean;
  isRejected?: boolean;
  numberOfRejections?: number;
  basedOn?: {
    reason?: string;
    id: string;
    status: RequestStatus;
    performerRequests?: PerformerRequest[];
    specificities?: AccommodationSpecificity[];
    comment?: string;
    visit?: Patient;
    createdAt: Date;
    directAdmissionOrigin?: string;
    requestType?: RequestType;
  };

  currentAccommodation: Accommodation;
  nextAccommodation?: Accommodation;
  previousAccommodation?: Accommodation;
  isAdmissionDateOutdated?: boolean;
  showAdmissionDateWarning?: boolean;
  isDischargeDateOutdated?: boolean;
  showDischargeDateWarning?: boolean;
};

const useVisits = (
  flow: 'admission' | 'discharge',
  date: string,
  unitId: string,
  onFilterCountChange: (count: number) => void,
  filter?: VisitFilter
) => {
  const dateFilter = useMemo(() => {
    return endOfDay(new Date(date));
  }, [date]);

  let { accommodations, loading } = useMovementAccommodations({
    unitId,
    start: flow === 'admission' ? dateFilter : undefined,
    end: flow === 'discharge' ? dateFilter : undefined,
  });

  const planned: Visit[] = [];
  const validated: Visit[] = [];
  const completed: Visit[] = [];
  const requested: Visit[] = [];

  const { requestsForUnit, accommodationsWithRequest } = useAccommodationRequests({
    unitId,
    flow,
  });
  if ('admission' === flow) {
    accommodations = accommodations.concat(requestsForUnit);
  } else {
    accommodations = accommodations.concat(accommodationsWithRequest);
  }

  const filteredAccommodations = filterAccommodations(accommodations, flow, filter);
  if (filter) {
    onFilterCountChange(filteredAccommodations.length);
  }

  filteredAccommodations.forEach((accommodation) => {
    const visit: Visit = {
      id: accommodation.id,
      status: accommodation.status,
      admissionDate: accommodation.admissionDate,
      dischargeDate: accommodation.dischargeDate,
      isDischarged:
        accommodation.status === 'COMPLETED' && accommodation.dischargeDate && isPast(accommodation.dischargeDate),
      isVisitDischarged: accommodation.patient.isDischarged,
      isDischargeDateValidated: accommodation.isDischargeDateValidated,
      specificities: accommodation.specificities,
      bed: accommodation.bed?.name,
      bedId: accommodation.bed?.id,
      unit: accommodation.unit,
      pan: accommodation.pan,
      patient: accommodation.patient,
      comment: accommodation.comment,
      isMutationRequest: accommodation.isMutationRequest,
      isRejected: accommodation.isRejected,
      basedOn: accommodation.basedOn,
      nextAccommodation: accommodation.nextAccommodation,
      previousAccommodation: accommodation.previousAccommodation,
      currentAccommodation: accommodation,
      numberOfRejections: accommodation.numberOfRejections,
      isAdmissionDateOutdated: accommodation.isAdmissionDateOutdated,
      showAdmissionDateWarning: accommodation.showAdmissionDateWarning,
      isDischargeDateOutdated: accommodation.isDischargeDateOutdated,
      showDischargeDateWarning: accommodation.showDischargeDateWarning,
    };

    if (flow === 'admission') {
      if (accommodation.isMutationRequest) {
        requested.push(visit);
      } else if (['IN_PROGRESS', 'COMPLETED'].includes(accommodation.status)) {
        completed.push(visit);
      } else if (accommodation.bed) {
        validated.push(visit);
      } else {
        planned.push(visit);
      }
    } else if (flow === 'discharge') {
      if (accommodation.status === 'COMPLETED') {
        completed.push(visit);
      } else if (accommodation.isMutationRequest) {
        requested.push(visit);
      } else if (accommodation.isDischargeDateValidated) {
        validated.push(visit);
      } else {
        planned.push(visit);
      }
    }
  });

  return {
    visits: {
      planned: sortVisits(planned, flow),
      validated: sortVisits(validated, flow),
      completed: sortVisits(completed, flow),
      requested,
    },
    loading,
  };
};

const filterAccommodations = (
  accommodations: Accommodation[],
  flow: 'admission' | 'discharge',
  filter?: VisitFilter
) => {
  if (filter?.search) {
    accommodations = filterBySearch({
      search: filter.search,
      list: accommodations,
      keys: [
        'basedOn.visit.firstName',
        'basedOn.visit.lastName',
        'basedOn.visit.legalName',
        'basedOn.visit.legalFirstName',
        'patient.fullName',
      ],
    });
  }

  return accommodations.filter((accommodation) => {
    let isValid = true;

    if (filter) {
      if (filter.specificities?.length > 0) {
        isValid =
          isValid && accommodation.specificities.some((specificity) => filter.specificities.includes(specificity));
      }
      if (filter.genders?.length > 0) {
        isValid = isValid && filter.genders.includes(accommodation.patient.gender);
      }
      if (filter.movementTypes.length > 0) {
        if (accommodation.basedOn) {
          if (accommodation.basedOn.requestType === 'DIRECT_ADMISSION') {
            isValid = isValid && filter.movementTypes.includes('DIRECT_ADMISSION');
          } else {
            isValid = isValid && filter.movementTypes.includes('INTERNAL_MUTATION');
          }
        } else if (accommodation.nextAccommodation && accommodation.nextAccommodation.basedOn) {
          isValid = isValid && filter.movementTypes.includes('INTERNAL_MUTATION');
        } else {
          if (flow === 'admission') {
            isValid = isValid && filter.movementTypes.includes('SCHEDULED_ADMISSION');
          } else {
            isValid = isValid && filter.movementTypes.includes('SCHEDULED_DISCHARGE');
          }
        }
      }
      if (filter.practitioners.length > 0) {
        const accommodationWithoutPractitioners =
          accommodation.practitioners === undefined || accommodation.practitioners.length === 0 || false;

        const accommodationWithPractitionersFiltered =
          accommodation.practitioners?.some((practitioner) => filter.practitioners.includes(practitioner.name)) ||
          false;

        if (filter.practitioners.includes(PRACTITIONER_NOT_DEFINED_VALUE)) {
          if (filter.practitioners.length === 1) {
            isValid = isValid && accommodationWithoutPractitioners;
          } else {
            isValid = isValid && (accommodationWithoutPractitioners || accommodationWithPractitionersFiltered);
          }
        } else {
          isValid = isValid && accommodationWithPractitionersFiltered;
        }
      }
    }

    return isValid;
  });
};

const sortVisits = (visits: Visit[], flow: 'admission' | 'discharge') =>
  visits.sort((a, b) => {
    if (a.admissionDate && b.admissionDate) {
      if (flow === 'discharge' && a.dischargeDate && b.dischargeDate) {
        return a.dischargeDate.getTime() - b.dischargeDate.getTime();
      }
      return a.admissionDate.getTime() - b.admissionDate.getTime();
    }
    return -1;
  });

export default useVisits;
