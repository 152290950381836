import { i18n } from 'common/locale';
import { toast } from 'common/toast';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagementModal } from 'superadmin/components/ManagementDrawer';
import { useArea } from 'superadmin/context';
import { Area } from 'superadmin/types';
import AreaForm from '../components/form/AreaForm';
import areaManagementRoutes from '../routes';

const AreaEditModal: React.FC = () => {
  const { id = '' } = useParams();
  const { area, update, isLoading } = useArea(id);
  const [open, setOpen] = useState(true);
  const handleSubmit = async (updatedArea: Partial<Area>) => {
    try {
      if (updatedArea.name && area?.healthCenter?.id) {
        await update({
          id,
          name: updatedArea.name,
          healthCenterId: area?.healthCenter?.id,
          externalTypeCode: updatedArea.externalTypeCode,
          isSearchable: !!updatedArea.isSearchable,
          externalIds: updatedArea.externalIds || [],
          excludedFromOccupancy: updatedArea.excludedFromOccupancy,
        });
        setOpen(false);
        toast.success(i18n.messageSuccessAreaUpdate);
      }
    } catch (err: any) {
      toast.error(err.message || i18n.messageErrorAreaAction);
    }
  };

  return (
    <ManagementModal
      loading={isLoading}
      title={`${i18n.editArea} ${area?.name}`}
      open={open}
      redirectUrl={areaManagementRoutes.areas}
    >
      {area && (
        <AreaForm
          id={area.id}
          name={area.name}
          excludedFromOccupancy={area.excludedFromOccupancy}
          externalIds={area.externalIds}
          isSearchable={area.isSearchable}
          externalTypeCode={area.externalTypeCode}
          parent={area.parent?.id}
          onSubmit={handleSubmit}
          onCancel={() => setOpen(false)}
        />
      )}
    </ManagementModal>
  );
};

export default AreaEditModal;
