import { Porter } from '@ambuliz/sabri-core';
import React from 'react';
import { i18n } from '../../../locales';
import { PorterAssignationsTable } from '../PorterAssignationsTable';
import { AssignablePortersTableRow } from './AssignablePortersTableRow';

type AssignablePortersTableProps = {
  fareId: string;
  assignablePorters: Porter[];
};

export const AssignablePortersTable: React.FC<AssignablePortersTableProps> = ({ fareId, assignablePorters = [] }) => {
  assignablePorters = assignablePorters.sort((porterA, porterB) => {
    const statusA = porterA.status;
    const statusB = porterB.status;
    const fareAssignedCountA = porterA.fareAssignedCount || 0;
    const fareAssignedCountB = porterB.fareAssignedCount || 0;

    let result = 0;
    if (statusA === 'AVAILABLE' && statusB === 'AVAILABLE') {
      result = fareAssignedCountA - fareAssignedCountB;
    } else if (statusA === 'AVAILABLE') {
      result = -1;
    } else if (statusB === 'AVAILABLE') {
      result = 1;
    }
    return result;
  });

  return (
    <>
      {
        <PorterAssignationsTable isEmpty={assignablePorters.length === 0} emptyCaption={i18n.anyPorterToAssign}>
          {assignablePorters.map((porter) => (
            <AssignablePortersTableRow key={porter.id} porter={porter} fareId={fareId} />
          ))}
        </PorterAssignationsTable>
      }
    </>
  );
};
