import { i18n } from 'common/locale';
import { differenceInYears, differenceInMonths } from 'date-fns';

const formatAge = (birthdate: Date) => {
  return differenceInYears(new Date(), birthdate) > 0
    ? `${differenceInYears(new Date(), birthdate)} ${i18n.ageYears}`
    : `${differenceInMonths(new Date(), birthdate)} ${i18n.ageMonths}`;
};

export default formatAge;
