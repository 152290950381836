import makeStyles from '@mui/styles/makeStyles';
import { color } from 'common/theme';

export default makeStyles(({ spacing }) => ({
  select: {
    position: 'absolute',
    left: 0,
    top: 40,
    minWidth: 200,
    zIndex: 2,
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: 'white',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
  },
  selectItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: color.grey[5],
    },
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  filterItem: {
    marginRight: spacing(1),
  },
}));
