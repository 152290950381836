import { SvgIcon, SvgIconProps } from '@mui/material';

const Construction: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          d="M1.333 9.502v2.236h13.334V9.502H1.333Zm13.513.004h-.002.002Zm-.179-.204V9.3v.003Zm0 2.639v-.004.004Zm.177-.207h.002-.002ZM.314 8.601c.236-.28.574-.35.862-.35h13.613c.29.001.614.071.854.321.236.246.274.547.274.74v2.615c0 .193-.038.494-.274.74-.24.25-.563.32-.854.32H1.176c-.288 0-.626-.069-.863-.349-.218-.258-.23-.564-.23-.711V9.313c0-.148.012-.453.23-.712ZM1.333 2.971v2.236h13.334V2.97H1.333Zm13.513.004h-.002.002Zm-.179-.204v-.003.003Zm0 2.639v-.003.003Zm.177-.206.002-.001h-.002ZM.314 2.07c.236-.28.574-.35.862-.35h13.613c.29 0 .614.07.854.32.236.246.274.547.274.74v2.615c0 .194-.038.495-.274.74-.24.25-.563.32-.854.32H1.176c-.288 0-.626-.068-.863-.349-.218-.258-.23-.564-.23-.71V2.781c0-.147.012-.453.23-.712Z"
          clipRule="evenodd"
        />
        <path d="M4.189.695a.625.625 0 0 0-1.25 0v.856a.625.625 0 0 0 1.25 0V.695ZM13.189.695a.625.625 0 1 0-1.25 0v.856c0 .059.008.116.023.17a.625.625 0 0 0-.395.944l2.066 3.284a.625.625 0 1 0 1.058-.665l-1.967-3.129a.625.625 0 0 0 .465-.604V.695ZM9.362 2.014a.625.625 0 0 0-1.076.636l2.066 3.5a.625.625 0 0 0 1.076-.636l-2.066-3.5ZM2.31 2.214a.625.625 0 1 0-1.059.665L2.975 5.62a.624.624 0 0 0-.037.212v2.897a.625.625 0 1 0 1.25 0V6.355a.625.625 0 0 0 .187-.856L2.31 2.214ZM5.847 2.214a.625.625 0 0 0-1.058.666l2.066 3.284A.625.625 0 1 0 7.913 5.5L5.847 2.214ZM13.189 5.832a.625.625 0 1 0-1.25 0v2.44a.625.625 0 0 0-.372.938l2.066 3.285a.625.625 0 0 0 1.058-.666l-1.677-2.667a.623.623 0 0 0 .175-.433V5.832ZM9.362 8.56a.625.625 0 0 0-1.076.635l2.066 3.5a.625.625 0 0 0 1.076-.636l-2.066-3.5ZM2.31 8.76a.625.625 0 0 0-1.059.665l1.724 2.74a.624.624 0 0 0-.037.212v3.016a.625.625 0 0 0 1.25 0V12.9a.625.625 0 0 0 .187-.856L2.31 8.759ZM5.847 8.76a.625.625 0 1 0-1.058.665l2.066 3.285a.625.625 0 1 0 1.058-.666L5.847 8.76ZM13.189 12.377a.625.625 0 0 0-1.25 0v3.016a.625.625 0 1 0 1.25 0v-3.016Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Construction;
