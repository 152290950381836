import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { theme } from 'common/theme';
import { initAmplitude } from 'core/analytics/amplitude';
import { ReadOnlyContextProvider } from 'core/authentication';
import { AuthenticationContextProvider } from 'core/authentication/AuthenticationContext';
import { msalConfig } from 'core/authentication/msalConfig';
import { ErrorBoundary } from 'core/error-boundary';
import { NotificationContextProvider } from 'core/layout';
import Loader from 'core/layout/components/Loader/Loader';
import { LocationContextProvider } from 'core/locations';
import { VersionUpdate } from 'core/version-update';
import fr from 'date-fns/locale/fr';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { loadConfiguration } from './configuration';
import './index.css';
import { registerConfiguration } from './register-configurations';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

loadConfiguration().then(() => {
  registerConfiguration();
  initAmplitude();
  root.render(
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              <ErrorBoundary>
                <MsalProvider instance={msalInstance}>
                  <AuthenticationContextProvider>
                    <ReadOnlyContextProvider>
                      <LocationContextProvider>
                        <Loader>
                          <NotificationContextProvider>
                            <App />
                            <VersionUpdate />
                          </NotificationContextProvider>
                        </Loader>
                      </LocationContextProvider>
                    </ReadOnlyContextProvider>
                  </AuthenticationContextProvider>
                </MsalProvider>
              </ErrorBoundary>
            </StylesProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
});
