import { SvgIcon, SvgIconProps } from '@mui/material';

const End: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_375_362)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.95773 3.00783C9.28611 2.51511 8.52306 1.95533 7.48259 1.66979C6.53679 1.31847 5.4966 1.25221 4.44852 1.60658C4.25528 1.67192 4.10747 1.82952 4.05464 2.02655L2.05171 9.49695C2.04887 9.50754 2.04633 9.51815 2.04408 9.52879L0.878403 13.8764C0.79114 14.2019 0.984242 14.5365 1.30971 14.6237C1.63517 14.711 1.96976 14.5179 2.05702 14.1924L3.14587 10.1313C3.74032 9.981 4.44185 10.0522 5.07645 10.2922C5.09541 10.2994 5.11472 10.3056 5.1343 10.3108C5.95366 10.5305 6.56053 10.9688 7.26043 11.4822L7.28776 11.5023C7.95938 11.995 8.72243 12.5548 9.76291 12.8403C10.7087 13.1916 11.7489 13.2579 12.797 12.9035C12.9902 12.8382 13.138 12.6806 13.1909 12.4835L15.1938 5.01314C15.2536 4.78992 15.1822 4.55205 15.0093 4.3987C14.8365 4.24535 14.5918 4.20284 14.3773 4.28889C13.7325 4.5476 12.9057 4.49653 12.169 4.21789C12.1501 4.21072 12.1308 4.2045 12.1112 4.19925C11.2918 3.97957 10.685 3.54124 9.98507 3.02788L9.95774 3.00783L9.95773 3.00783ZM3.49007 8.84419L5.14401 2.67541C5.80403 2.52067 6.45709 2.58641 7.07938 2.8218C7.09834 2.82897 7.11765 2.83519 7.13723 2.84044C7.95659 3.06013 8.56346 3.49845 9.26336 4.01182L9.29069 4.03186C9.96232 4.52458 10.7254 5.08437 11.7659 5.36991C12.3721 5.59497 13.0684 5.71818 13.7554 5.6659L12.1015 11.8347C11.4415 11.9894 10.7884 11.9237 10.1661 11.6883C10.1472 11.6811 10.1278 11.6749 10.1083 11.6696C9.28891 11.45 8.68204 11.0116 7.98213 10.4983L7.95481 10.4782C7.28318 9.98551 6.52012 9.42572 5.47963 9.14018C4.87337 8.91512 4.17711 8.79191 3.49007 8.84419Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_375_362">
          <rect width="16" height="16" fill="white" transform="translate(0.000732422)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default End;
