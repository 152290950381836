import { Pagination, TablePagination } from '@mui/material';
import { color, transition } from 'common/theme';
import { ReactNode } from 'react';

const ListPagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  labelRowsPerPage,
}: {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  labelRowsPerPage?: ReactNode;
}) => {
  return (
    <TablePagination
      rowsPerPageOptions={[30, 50, 100]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      labelRowsPerPage={labelRowsPerPage}
      labelDisplayedRows={() => null}
      SelectProps={{
        size: 'small',
        sx: {
          color: color.grey[60],
          [`& .MuiInputBase-input.MuiSelect-select`]: {
            transition: `background-color ${transition}`,
            backgroundColor: `${color.blue[5]} !important`,
            border: `1px solid ${color.grey[10]}`,
            fontSize: 12,
            [`&:hover`]: {
              backgroundColor: `${color.grey[10]} !important`,
            },
          },
          [`& .MuiInputBase-input.MuiSelect-select.MuiInputBase-input`]: {
            padding: '4px 8px',
            paddingRight: 6,
            borderRadius: 0.5,
            minWidth: 0,
          },
          [`& .MuiSvgIcon-root`]: {
            right: 6,
          },
        },
      }}
      ActionsComponent={() => (
        <Pagination
          count={Math.ceil(count / rowsPerPage)}
          page={page + 1}
          onChange={(event, page) => onPageChange(event, page - 1)}
        />
      )}
    />
  );
};

export default ListPagination;
