import { Box, Grid, Card, Skeleton, Stack } from '@mui/material';

const SkeletonRow = () => {
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <Box flex={1}>
        <Grid container columnGap={4} direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
          <Grid item flex={1} minWidth={280} maxWidth={720}>
            <SkeletonCard />
          </Grid>
          <Grid item justifyContent="center">
            <Box width={80}>
              <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
                <Skeleton variant="rectangular" sx={{ borderRadius: 0.5, width: 44, height: 12 }} />
              </Card>
            </Box>
          </Grid>
          <Grid item flex={1} minWidth={280} maxWidth={720}>
            <SkeletonCard />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

const SkeletonCard = () => (
  <Card>
    <Stack padding={3} spacing={2}>
      <Skeleton variant="rectangular" sx={{ borderRadius: 0.5, width: '100%', height: 32 }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5, width: 60, height: 8 }} />
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5, width: 60, height: 8 }} />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5, width: 60, height: 8 }} />
          <Skeleton variant="rectangular" sx={{ borderRadius: 0.5, width: 16, height: 18 }} />
        </Stack>
      </Stack>
    </Stack>
  </Card>
);

export default SkeletonRow;
