import { Cloud } from '@ambuliz/sabri-core';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import { MapInProgress } from 'common/components/Images';
import { i18n } from 'common/locale';
import { color } from 'common/theme';
import { UserHealthCenter } from 'core/authentication';
import { EmptyContent, useAppBarContext } from 'core/layout';
import { LatLngBounds, latLngBounds } from 'leaflet';
import { useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import MapLayer, { defaultLocation } from './MapLayer';

type HealthCenterMapProps = {
  userHealthCenter?: UserHealthCenter;
  healthCenters: Cloud.HealthCenterRegionData[];
  loading?: boolean;
};

const getBounds = (markers: (UserHealthCenter | Cloud.HealthCenterRegionData | undefined)[]) => {
  const bounds = latLngBounds([]);
  markers.forEach((marker) => {
    if (!!marker?.location && marker.location.latitude && marker.location.longitude) {
      bounds.extend([marker.location.latitude, marker.location.longitude]).pad(0.5);
    }
  });
  return bounds;
};

const HealthCenterMap: React.FC<HealthCenterMapProps> = ({ userHealthCenter, healthCenters = [], loading = false }) => {
  const { isFullScreen } = useAppBarContext();
  const [bounds, setBounds] = useState<LatLngBounds>();

  const handleRecenterClick = () => {
    setBounds(getBounds(healthCenters));
  };

  useEffect(() => {
    setBounds(getBounds([userHealthCenter, ...healthCenters]));
  }, [healthCenters, userHealthCenter]);

  return (
    <div style={{ height: !isFullScreen ? '80vh' : '100%', paddingBottom: 40 }}>
      <MapContainer
        style={{ height: '100%', width: '100%', borderRadius: 8 }}
        attributionControl={false}
        scrollWheelZoom={false}
        bounds={bounds?.isValid() ? bounds : undefined}
        center={[
          userHealthCenter?.location?.latitude || defaultLocation[0],
          userHealthCenter?.location?.longitude || defaultLocation[1],
        ]}
        maxZoom={14}
      >
        {loading ? (
          <LinearProgress
            variant="indeterminate"
            color="secondary"
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              [`& .${linearProgressClasses.bar}`]: { backgroundColor: color.grey[30] },
              borderRadius: 0,
            }}
          />
        ) : healthCenters.length > 0 ? (
          <MapLayer healthCenters={healthCenters} bounds={bounds} onRecenterClick={handleRecenterClick} />
        ) : (
          <EmptyContent Image={MapInProgress} {...i18n.territoryEmptyContent} />
        )}
      </MapContainer>
    </div>
  );
};

export default HealthCenterMap;
