import { Box, Stack, Typography } from '@mui/material';
import { Emoji } from 'common/components';
import { TimelineRowItem, TimelineRowItemProps } from 'common/components/Timeline';

const ExternalTransportItem = ({ ...props }: TimelineRowItemProps) => {
  return (
    <TimelineRowItem {...props}>
      <Stack direction="row" spacing={1} alignItems="center" whiteSpace="nowrap" paddingX={3}>
        <Box
          height={22}
          width={22}
          fontSize={12}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
        >
          <Emoji name="taxi" />
        </Box>
        <Typography variant="body2">Transport</Typography>
      </Stack>
    </TimelineRowItem>
  );
};

export default ExternalTransportItem;
