import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { color } from 'common/theme';
import React from 'react';

export const getInitials = ({ firstName, lastName }: { firstName: string; lastName: string }) =>
  [firstName.charAt(0), lastName.charAt(0)].join('');

type AvatarNameProps = {
  user: { firstName: string; lastName: string };
  withName?: boolean;
};

export const AvatarName: React.FC<AvatarNameProps> = ({ user, withName = true }) => {
  const classes = useClasses();
  const initials = getInitials(user);

  return (
    <Box display="flex" flex={1} alignItems="center">
      <div className={classes.container}>
        <Typography variant="subtitle1">{initials}</Typography>
      </div>
      {withName && (
        <Box marginLeft={1}>
          <Typography fontSize={13}>
            {user.firstName} {user.lastName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useClasses = makeStyles({
  container: {
    borderRadius: 20,
    height: 40,
    width: 40,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.grey[20],
    textTransform: 'uppercase',
  },
});
