import { IconButton, Tooltip } from '@mui/material';
import { theme } from 'common/theme';
import { i18n } from 'sabri/locales';
import styled from 'styled-components';
import { icons } from '../../../const/images';

type CancelFareConfirmationProps = {
  isFareGoingToUnit: boolean;
  onCancelFareConfirmation: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const FareListCancelFareConfirmationAction: React.FC<CancelFareConfirmationProps> = ({
  isFareGoingToUnit,
  onCancelFareConfirmation,
}) => {
  const title = isFareGoingToUnit ? i18n.cancelPullFare : i18n.cancelPushFare;
  const iconSrc = isFareGoingToUnit ? icons.enterCancel : icons.exitCancel;

  return (
    <Tooltip title={title} arrow={false} placement="top">
      <span>
        <CancelButton onClick={onCancelFareConfirmation} shape="rounded" size="large" color="error" variant="filled">
          <CloseButtonImg alt="check" src={iconSrc} />
        </CancelButton>
      </span>
    </Tooltip>
  );
};

const CancelButton = styled(IconButton)`
  background-color: ${theme.palette.error.main};
  padding: 5px;
  border-radius: 5px;
  &:disabled {
    background-color: '#EEEEEE';
  }
`;

const CloseButtonImg = styled.img`
  height: 31px;
`;

export default FareListCancelFareConfirmationAction;
