import EventCard from 'kurt/components/EventCard';
import { CardColors } from 'kurt/components/EventCard/cardColors';
import { forwardRef, ReactNode, RefObject, useContext, useEffect } from 'react';
import TimelineContext from './TimelineContext';
import { TIMELINE_ROW_TITLE_MARGIN_RIGHT } from './TimelineRow';

const SCROLL_PADDING_OFFSET = 4;

export type TimelineRowItemProps = {
  id?: string;
  width?: number;
  fillWidth?: number;
  fillLeft?: number;
  isEndOutdated?: boolean;
  isStartOutdated?: boolean;
  left?: number;
  start: Date;
  end?: Date;
  top?: number;
  children?: ReactNode;
  striped?: boolean;
  color?: CardColors;
  borderLeft?: boolean;
  borderRight?: boolean;
  grouped?: boolean;
  size?: 'medium' | 'large';
  cardHeight?: number | null;
  onClick?: (id?: string) => void;
  onMouseEnter?: (ids: string[]) => void;
  onMouseLeave?: () => void;
  loading?: boolean;
  registerSticky?: boolean;
  cardContainerRef?: RefObject<HTMLDivElement>;
  contentRef?: RefObject<HTMLDivElement>;
};

const TimelineRowItem = forwardRef<HTMLDivElement, TimelineRowItemProps>(
  (
    {
      id,
      color,
      children,
      top,
      striped,
      borderLeft,
      borderRight,
      size,
      width,
      left,
      fillWidth,
      fillLeft,
      onMouseEnter,
      loading,
      registerSticky,
      cardContainerRef: cardContainer,
      contentRef: content,
      ...props
    },
    ref
  ) => {
    const { rowHeaderWidth, container: containerRef } = useContext(TimelineContext);

    useEffect(() => {
      if (registerSticky) {
        const container = containerRef.current;
        const updateContentOffset = (element: HTMLDivElement | null, offset: number) => {
          if (!element || size === 'large') return;
          element.style.transform = `translateX(${offset}px)`;
        };

        const handleScroll = () => {
          if (!content?.current || !cardContainer?.current || !container) {
            return;
          }
          const offset =
            cardContainer.current.getBoundingClientRect().left -
            rowHeaderWidth -
            (container.scrollLeft >= TIMELINE_ROW_TITLE_MARGIN_RIGHT
              ? 0
              : TIMELINE_ROW_TITLE_MARGIN_RIGHT - container.scrollLeft) +
            SCROLL_PADDING_OFFSET -
            container.getBoundingClientRect().x;

          if (offset < 0) {
            requestAnimationFrame(() => updateContentOffset(content.current, -offset));
          } else if (content.current.style.transform !== 'translateX(0)') {
            requestAnimationFrame(() => updateContentOffset(content.current, 0));
          }
        };

        handleScroll();
        container?.addEventListener('scroll', handleScroll);
        return () => container?.removeEventListener('scroll', handleScroll);
      }
    }, [registerSticky, containerRef, content, cardContainer, rowHeaderWidth, size]);

    return (
      <EventCard
        ref={ref}
        width={width}
        fillWidth={fillWidth}
        fillLeft={fillLeft}
        left={left}
        top={top}
        color={color}
        striped={striped}
        borderLeft={borderLeft}
        borderRight={borderRight}
        scrollOffset={rowHeaderWidth}
        size={size}
        loading={loading}
        onMouseEnter={() => id && onMouseEnter?.([id])}
        {...props}
      >
        {children}
      </EventCard>
    );
  }
);

export default TimelineRowItem;
