import { PatientOutcomeType, patientOutcomeTypes } from '@ambuliz/sabri-core';
import { MultipleSelect } from 'common/components';
import { i18n } from 'common/locale';

export const PATIENT_OUTCOME_NOT_DEFINED_VALUE = 'to-define';
export type PatientOutcomeTypeFilter = PatientOutcomeType | typeof PATIENT_OUTCOME_NOT_DEFINED_VALUE;

type PatientOutcomeFiltersProps = {
  onChange: (patientOutcomes: PatientOutcomeTypeFilter[]) => void;
  values: PatientOutcomeTypeFilter[];
};

const patientOutcomeFilters: PatientOutcomeTypeFilter[] = [...patientOutcomeTypes, PATIENT_OUTCOME_NOT_DEFINED_VALUE];

const PatientOutcomeFilter = ({ onChange, values }: PatientOutcomeFiltersProps) => {
  const options = patientOutcomeFilters.map((type) => ({
    value: type,
    label: type === PATIENT_OUTCOME_NOT_DEFINED_VALUE ? i18n.toDefine : type,
  }));
  return <MultipleSelect values={values} onChange={onChange} label={i18n.outcomes} options={options} />;
};

export default PatientOutcomeFilter;
