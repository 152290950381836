import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  closeIcon: {
    cursor: 'pointer',
  },
  chip: {
    fontSize: 16,
    textAlign: 'center',
  },
  reference: {
    marginRight: 30,
  },
  diffDate: {
    opacity: 0.6,
    marginTop: 3,
  },
  infos: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
  },
  itemContainer: {
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  actions: {
    padding: 20,
    boxShadow: ' 0 -2px 5px -5px #333',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 20px',
    '&:not(:last-child)': {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  itemLeft: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px',
    '&:not(:last-child)': {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  itemLocation: {
    '&:not(:last-child)': {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  chipTitle: {
    backgroundColor: '#F57C00',
    color: 'white',
  },
  incidentIcon: {
    color: '#F57C00',
  },
  itemColumn: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '15px 20px',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  specificitiesIcon: {
    marginRight: 5,
  },
  positionIcon: {
    fontSize: 45,
  },
  roundTripContainer: {
    position: 'absolute',
    top: -20,
    right: 25,
    width: 40,
    height: 40,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: 'white',
  },
  roundTripIcon: {
    fontSize: 26,
  },
  dividerVertical: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: 1,
    height: 40,
    marginRight: 15,
  },
  typographyPathTime: {
    alignSelf: 'center',
    marginRight: 3,
  },
  comment: {
    whiteSpace: 'pre-line',
  },
});
