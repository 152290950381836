import { unitSpecialties, UnitSpecialty, UnitType, unitTypes } from '@ambuliz/sabri-core';
import { Button, CircularProgress, TablePagination, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { MultipleSelect } from 'common/components';
import { SelectOption } from 'common/components/Form/Inputs/FilterSelect/MultipleSelect';
import { i18n } from 'common/locale';
import { useDepartments } from 'superadmin/hooks/useDepartments';

export type UnitSearchFilter = {
  name: string;
  specialties: UnitSpecialty[];
  types: UnitType[];
  departments: string[];
  page?: number;
  pageSize?: number;
};

export const UnitSearchFilters = ({
  params,
  onParamsChange,
  count,
  isLoading,
}: {
  params: UnitSearchFilter;
  onParamsChange: (params: UnitSearchFilter) => void;
  count: number;
  isLoading: boolean;
}) => {
  const { departments } = useDepartments();

  const typeOptions: SelectOption<UnitType>[] = unitTypes.map((type) => ({
    value: type,
    label: i18n.unitType[type],
  }));

  const specialtiesOptions: SelectOption<UnitSpecialty>[] = unitSpecialties.map((type) => ({
    value: type,
    label: type,
  }));

  const handleResetFilter = () => {
    onParamsChange({
      name: '',
      types: [],
      specialties: [],
      departments: [],
    });
  };

  const isFilterActive = params.name || params.types.length > 0 || params.specialties.length > 0;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={4} alignItems="center">
        <TextField
          label="Nom"
          value={params.name}
          onChange={(e) => onParamsChange({ ...params, name: e.target.value, page: 0 })}
        />
        <MultipleSelect
          options={typeOptions}
          label="Type"
          values={params.types}
          onChange={(value) =>
            onParamsChange({
              ...params,
              types: value,
              page: 0,
            })
          }
        />
        <MultipleSelect
          options={specialtiesOptions}
          label="Spécialité"
          searchable
          values={params.specialties}
          onChange={(value) =>
            onParamsChange({
              ...params,
              specialties: value,
              page: 0,
            })
          }
        />

        <MultipleSelect
          options={departments.map((department) => ({ value: department, label: department }))}
          label="Pôle"
          searchable
          values={params.departments}
          onChange={(value) =>
            onParamsChange({
              ...params,
              departments: value,
              page: 0,
            })
          }
        />
        {isFilterActive && (
          <Button onClick={handleResetFilter} variant="text">
            {i18n.clearFilters}
          </Button>
        )}
        <Typography variant="body2" color="secondary">
          {count} unités
        </Typography>
        {isLoading && <CircularProgress size={20} />}
      </Stack>
      <Stack>
        <TablePagination
          component="div"
          count={count}
          page={params.page === undefined ? 0 : params.page}
          onPageChange={(_, page) => onParamsChange({ ...params, page })}
          rowsPerPage={params.pageSize || 10}
          onRowsPerPageChange={(e) => onParamsChange({ ...params, pageSize: parseInt(e.target.value, 10), page: 0 })}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
          labelRowsPerPage="Unités par page"
        />
      </Stack>
    </Stack>
  );
};
