export const RED_THRESHOLD = 95;
export const ORANGE_THRESHOLD = 85;

type color = 'primary' | 'secondary' | 'danger' | 'error' | 'success';

export const getThresholdedColor = (
  value?: number,
  defaultValue: color = 'success',
  thresholds = {
    red: RED_THRESHOLD,
    orange: ORANGE_THRESHOLD,
  }
) => {
  if (!value) {
    return defaultValue;
  }
  if (value >= thresholds.red) {
    return 'error';
  }
  if (value >= thresholds.orange) {
    return 'danger';
  }
  return 'success';
};
