import { Accommodation, AccommodationRequest } from '@ambuliz/sabri-core';
import { Button, ButtonGroup } from '@mui/material';
import { Emoji } from 'common/components';
import ChevronSmallRightIcon from 'common/components/Icons/ChevronSmallRight';
import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import { actionEmoji } from 'kurt/components/PatientFlowKanban/VisitActions/columnActions';

type MutationActionsProps = {
  accommodationRequest: AccommodationRequest;
  origin?: Accommodation;
  destination?: Accommodation;
  onClick: (action: AccommodationAction) => void;
  disabled?: boolean;
};

const MutationActions = ({ accommodationRequest, origin, destination, onClick, disabled }: MutationActionsProps) => {
  const { user, unit } = useAuthentication();

  const hasOriginAccess =
    ['ADMIN', 'ADMIN_REGULATOR', 'BED_MANAGER'].includes(user?.role) ||
    (user.role === 'MANAGER' && origin?.unit?.id === unit?.id);

  const isRequested = accommodationRequest.status === 'REQUESTED';
  const isRejected = accommodationRequest.status === 'REJECTED';
  const isAccepted = accommodationRequest.status === 'ACCEPTED';
  const isToAddress = isRequested && !accommodationRequest.performerRequests;
  const isInternalMutation = accommodationRequest.requestType === 'INTERNAL_MUTATION';
  const destinationAccommodationNotInProgress = destination?.status !== 'IN_PROGRESS';
  const isDirectAdmission = accommodationRequest.requestType === 'DIRECT_ADMISSION';

  return (
    <ButtonGroup orientation="vertical" fullWidth variant="action" disabled={disabled}>
      {isToAddress && hasOriginAccess && (
        <Button
          startIcon={<Emoji size="extra-small" name={actionEmoji.ADDRESS_MUTATION_REQUEST} />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('ADDRESS_MUTATION_REQUEST')}
        >
          {i18n.visitActions.ADDRESS_MUTATION_REQUEST.action.label}
        </Button>
      )}
      {isRequested && !isToAddress && isInternalMutation && hasOriginAccess && (
        <Button
          startIcon={<Emoji size="extra-small" name={actionEmoji.EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST} />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST')}
        >
          {i18n.visitActions.EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST.action.label}
        </Button>
      )}
      {isRequested && !isToAddress && (
        <Button
          startIcon={<Emoji size="extra-small" name={actionEmoji.ACCEPT_INTERNAL_MUTATION_REQUEST} />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('ACCEPT_INTERNAL_MUTATION_REQUEST')}
        >
          {i18n.visitActions.ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN.action.label}
        </Button>
      )}
      {isRejected && hasOriginAccess && (
        <Button
          startIcon={<Emoji size="extra-small" name={actionEmoji.NEW_INTERNAL_MUTATION_REQUEST} />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('NEW_INTERNAL_MUTATION_REQUEST')}
        >
          {i18n.visitActions.NEW_INTERNAL_MUTATION_REQUEST.action.label}
        </Button>
      )}
      {!isToAddress && isInternalMutation && (isRequested || isAccepted) && destinationAccommodationNotInProgress && (
        <Button
          startIcon={<Emoji size="extra-small" name={actionEmoji.REJECT_INTERNAL_MUTATION_REQUEST} />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('REJECT_INTERNAL_MUTATION_REQUEST')}
        >
          {i18n.visitActions.REJECT_INTERNAL_MUTATION_REQUEST.action.label}
        </Button>
      )}
      {(isDirectAdmission || hasOriginAccess) && destinationAccommodationNotInProgress && (
        <Button
          color="error"
          startIcon={<Emoji size="extra-small" name={actionEmoji.CANCEL_MUTATION_REQUEST} />}
          endIcon={<ChevronSmallRightIcon />}
          onClick={() => onClick('CANCEL_MUTATION_REQUEST')}
        >
          {i18n.visitActions.CANCEL_MUTATION_REQUEST.action.label}
        </Button>
      )}
    </ButtonGroup>
  );
};

export default MutationActions;
